import { ChevronRightIcon } from '@heroicons/react/20/solid';
import classNames from 'classnames';
import React, { useCallback, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import tinykeys from 'tinykeys';
export type Column<T> = {
  key: string;
  label: string;
  render?: (value: T) => React.ReactNode;
  accessor?: (value: T) => string | number | null | undefined;
  align?: 'left' | 'right';
};
type TableRow = {
  id: string;
};
type CommonTableProps<T extends {
  id: string;
}> = {
  data: T[];
  columns: Column<T>[];
  keyboardNavigable?: boolean;
  boldLastRow?: boolean;
};
type TableProps<T extends {
  id: string;
}> = CommonTableProps<T> | (CommonTableProps<T> & {
  href: (row: T) => string;
}) | (CommonTableProps<T> & {
  onRowClick: (row: T) => void;
}) | (CommonTableProps<T> & {
  canExpandRows: boolean;
  renderExpandedRow: (row: T) => React.ReactNode;
});
export const Table = <T extends TableRow,>(props: TableProps<T>) => {
  const {
    columns,
    data,
    boldLastRow
  } = props;
  const [expandedRows, setExpandedRows] = useState<Record<string, boolean>>({});
  const anyExpanded: boolean = Object.values(expandedRows).some(v => v);
  const [keyboardSelectedRow, setKeyboardSelectedRow] = useState<undefined | number>();
  const keyboardSelectedRowRef = useCallback((ref: HTMLTableRowElement) => {
    const webkit = ref as {
      scrollIntoViewIfNeeded?: () => void;
    } | null;
    if (webkit && webkit.scrollIntoViewIfNeeded) {
      webkit.scrollIntoViewIfNeeded();
    }
  }, []);
  const maybeToggleExpandedRow = useCallback((row: T) => {
    if ('canExpandRows' in props) {
      if (props.canExpandRows) {
        setExpandedRows(prev => ({
          ...prev,
          [row.id]: !prev[row.id]
        }));
      }
    }
  }, [props]);
  const maybeExpandAllRows = useCallback(() => {
    if ('canExpandRows' in props) {
      if (props.canExpandRows) {
        const all: Record<string, boolean> = {};
        props.data.forEach(row => all[row.id] = true);
        setExpandedRows(all);
      }
    }
  }, [props]);
  const collapseAllRows = useCallback(() => {
    setExpandedRows({});
  }, []);
  useEffect(() => {
    if (!props.keyboardNavigable) {
      return;
    }
    const unsubscribe = tinykeys(window, {
      j: () => {
        if (keyboardSelectedRow !== undefined) {
          setKeyboardSelectedRow(Math.min(keyboardSelectedRow + 1, data.length - 1));
        } else if (data.length > 0) {
          setKeyboardSelectedRow(0);
        }
      },
      k: () => {
        if (keyboardSelectedRow !== undefined) {
          setKeyboardSelectedRow(Math.max(0, keyboardSelectedRow - 1));
        }
      },
      o: () => {
        if (keyboardSelectedRow !== undefined) {
          maybeToggleExpandedRow(data[keyboardSelectedRow]);
        }
      },
      'Shift+:': () => {
        collapseAllRows();
      },
      ';': () => {
        maybeExpandAllRows();
      }
    });
    return () => {
      unsubscribe();
    };
  }, [props.keyboardNavigable, keyboardSelectedRow, data, maybeToggleExpandedRow, maybeExpandAllRows, collapseAllRows]);
  const handleRowClick = useCallback((e: React.MouseEvent, row: T, index: number) => {
    if (e.target instanceof HTMLAnchorElement || e.target instanceof HTMLButtonElement || e.target instanceof SVGElement) {
      return false;
    }
    if ('onRowClick' in props) {
      props.onRowClick(row);
      return;
    }
    maybeToggleExpandedRow(row);
    if (props.keyboardNavigable) {
      setKeyboardSelectedRow(index);
    }
  }, [props, maybeToggleExpandedRow]);
  const columnCount = columns.length + 1;
  return <div className="border-main rounded border shadow-sm" data-sentry-component="Table" data-sentry-source-file="Table.tsx">
      <table className="w-full table-auto border-collapse">
        <thead>
          <tr>
            {columns.map(column => <th className="text-disabled py-2 text-left text-xs font-medium uppercase first:pl-3 last:pr-3" key={column.key as string}>
                {column.label}
              </th>)}
            {'href' in props && <th className="w-4"></th>}
            {'canExpandRows' in props && props.canExpandRows && <th className="text-disabled hover:text-subtle w-12 cursor-pointer py-2 text-left text-xs font-medium uppercase first:pl-3 last:pr-3" onClick={() => {
            if (anyExpanded) {
              collapseAllRows();
            } else {
              maybeExpandAllRows();
            }
          }}>
                {anyExpanded ? 'All' : 'All'}
              </th>}
          </tr>
        </thead>
        <tbody>
          {data.map((row, idx) => {
          const isLastRow = idx === data.length - 1 && boldLastRow;
          const isClickable = 'onRowClick' in props || 'canExpandRows' in props || 'href' in props;
          const isKeyboardSelectedRow = keyboardSelectedRow !== undefined && keyboardSelectedRow === idx;
          const href = 'href' in props && props.href(row);
          return <React.Fragment key={row.id}>
                <tr onClick={e => handleRowClick(e, row, idx)} className={classNames('border-main border-t text-sm', isLastRow && 'font-bold', isClickable && 'hover:bg-main-hover cursor-pointer', isKeyboardSelectedRow && 'border-l-success bg-main-hover border-l-2')} ref={isKeyboardSelectedRow ? keyboardSelectedRowRef : undefined}>
                  {columns.map((column, columnIdx) => {
                const isFirst = columnIdx === 0;
                const value = column.accessor ? column.accessor(row) : (row as Record<string, string>)[column.key];
                const displayableValue = typeof value === 'boolean' ? String(value) : value;
                const align = column.align || 'left';
                const content = column.render ? column.render(row) : displayableValue;
                return <td key={column.key as string} className={classNames('py-1.5 first:pl-3 last:pr-3', isFirst ? 'text-strong' : 'text-subtle', align === 'right' && 'text-right')}>
                        {href ? <Link to={href} className="block">
                            {content}
                          </Link> : content}
                      </td>;
              })}
                  {href && <td className="py-3 select-none last:pr-3">
                      <Link to={href} className="block">
                        <ChevronRightIcon className={classNames('h-4 w-4 transition-all')} />
                      </Link>
                    </td>}
                  {'canExpandRows' in props && props.canExpandRows && <td className="py-3 select-none last:pr-3">
                      <ChevronRightIcon className={classNames('h-4 w-4 transition-all', expandedRows[row.id] && 'rotate-90')} />
                    </td>}
                </tr>
                {'renderExpandedRow' in props && expandedRows[row.id] && <tr>
                    <td colSpan={columnCount}>
                      {props.renderExpandedRow(row)}
                    </td>
                  </tr>}
              </React.Fragment>;
        })}
        </tbody>
      </table>
    </div>;
};