import classNames from 'classnames';
import { useGroupGet } from 'src/hooks/reactQuery';
import { Body } from 'shared/components/Text';
import { StyledLink } from 'shared/components/StyledLink';
import { Badge } from 'shared/components/Badge';
import { LockboxGetResponse } from 'src/build/operations';
import { Box } from 'shared/components/Box';
function LockboxBadge({
  lockbox
}: {
  lockbox: LockboxGetResponse;
}) {
  const color = lockbox.status === 'active' ? 'green' : 'red';
  const label = lockbox.status === 'active' ? 'Active' : 'Inactive';
  return <Badge label={label} color={color} data-sentry-element="Badge" data-sentry-component="LockboxBadge" data-sentry-source-file="lockbox.tsx" />;
}
function Group({
  id
}: {
  id: string;
}) {
  const {
    data: group
  } = useGroupGet(id);
  if (!group) {
    return null;
  }
  return <StyledLink style="underline" target="_blank" href={`/groups/${group.id}`} data-sentry-element="StyledLink" data-sentry-component="Group" data-sentry-source-file="lockbox.tsx">
      {group.name}
    </StyledLink>;
}
export function Lockbox({
  lockbox
}: {
  lockbox: LockboxGetResponse;
}) {
  return <div className="divide-color-main border-main divide-y rounded border border-solid shadow-sm" data-sentry-component="Lockbox" data-sentry-source-file="lockbox.tsx">
      <div className="flex flex-row justify-between px-4 py-2">
        <Body weight="medium" color="primary" data-sentry-element="Body" data-sentry-source-file="lockbox.tsx">
          Lockbox
        </Body>
        <Box flex gap="2" data-sentry-element="Box" data-sentry-source-file="lockbox.tsx">
          <LockboxBadge lockbox={lockbox} data-sentry-element="LockboxBadge" data-sentry-source-file="lockbox.tsx" />
          <Body color="secondary" className={classNames('text-right')} data-sentry-element="Body" data-sentry-source-file="lockbox.tsx">
            <StyledLink style="underline" target="_blank" href={`/lockboxes/${lockbox.id}`} data-sentry-element="StyledLink" data-sentry-source-file="lockbox.tsx">
              {lockbox.description}
            </StyledLink>
          </Body>
        </Box>
      </div>
      <div className="flex flex-row justify-between px-4 py-2">
        <Body weight="medium" color="primary" data-sentry-element="Body" data-sentry-source-file="lockbox.tsx">
          Group
        </Body>
        <Body color="secondary" className={classNames('text-right')} data-sentry-element="Body" data-sentry-source-file="lockbox.tsx">
          <Group id={lockbox.group_id} data-sentry-element="Group" data-sentry-source-file="lockbox.tsx" />
        </Body>
      </div>
    </div>;
}
export function LockboxNotFound() {
  return <div className="divide-color-main border-main divide-y rounded border border-solid shadow-sm" data-sentry-component="LockboxNotFound" data-sentry-source-file="lockbox.tsx">
      <div className="mb-5 max-w-[550px] px-4 py-2 text-center md:mb-0 md:text-start">
        <Body weight="bold" data-sentry-element="Body" data-sentry-source-file="lockbox.tsx">No lockbox found</Body>
        <Body color="secondary" data-sentry-element="Body" data-sentry-source-file="lockbox.tsx">
          Please check the line2 suffix and try again.
        </Body>
      </div>
    </div>;
}