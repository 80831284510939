import { compact } from 'lodash';
import { ListItem, PropertyList, PropertyListProps } from '@increase/shared/components/PropertyList';
import { formatAmount, humanize } from '@increase/shared/lib/formatting';
import { BookkeepingAccountGetResponse } from 'src/build/operations';
import { complianceCategoriesBadgeColors } from '../tables/BookkeepingAccountsTable';
import { useGroupPropertyRow } from 'src/lib/propertyListHelpers';
type BookkeepingAccountPropertyListProps = {
  bookkeepingAccount: BookkeepingAccountGetResponse;
} & Omit<PropertyListProps, 'items'>;
export const BookkeepingAccountPropertyList = (props: BookkeepingAccountPropertyListProps) => {
  const {
    bookkeepingAccount: bookkeepingAccount,
    ...rest
  } = props;
  const groupRow = useGroupPropertyRow(bookkeepingAccount.group_id);
  return <PropertyList {...rest} items={compact<ListItem>([{
    label: 'Name',
    value: bookkeepingAccount.name
  }, groupRow, {
    label: 'Compliance Category',
    value: bookkeepingAccount.compliance_category ? humanize(bookkeepingAccount.compliance_category) : 'None',
    badgeColor: bookkeepingAccount.compliance_category ? complianceCategoriesBadgeColors[bookkeepingAccount.compliance_category] : undefined
  }, {
    label: 'Created At',
    value: bookkeepingAccount.created_at,
    format: 'date'
  }, {
    label: 'Balance',
    value: formatAmount(bookkeepingAccount.balance, 'USD')
  }])} data-sentry-element="PropertyList" data-sentry-component="BookkeepingAccountPropertyList" data-sentry-source-file="BookkeepingAccountPropertyList.tsx" />;
};