import { compact } from 'lodash';
import { ListItem, PropertyList, PropertyListProps } from '@increase/shared/components/PropertyList';
import { formatBank } from 'src/lib/formatEnums';
import { formatISO8601Timestamp, humanize } from '@increase/shared/lib/formatting';
import { UnusualActivityReportGetResponse } from 'src/build/operations';
import { increaseReviewStatusBadgeColor } from 'src/components/detail_pages/UnusualActivityReportDetailPage';
const formatReferralChannel: Record<UnusualActivityReportGetResponse['referral_channel'], string> = {
  external_bank_reported: 'External Bank Reported',
  external_platform_reported: 'External Platform Reported',
  business: 'Business',
  internal_monitoring: 'Internal Monitoring'
};
type Props = {
  unusualActivityReport: UnusualActivityReportGetResponse;
} & Omit<PropertyListProps, 'items'>;
export const UnusualActivityReportPropertyList = (props: Props) => {
  const {
    unusualActivityReport,
    ...rest
  } = props;
  return <PropertyList {...rest} items={compact<ListItem>([{
    label: 'ID',
    value: unusualActivityReport.id,
    copyable: unusualActivityReport.id
  }, {
    label: 'Increase Review Status',
    value: humanize(unusualActivityReport.increase_review_status),
    badgeColor: increaseReviewStatusBadgeColor[unusualActivityReport.increase_review_status]
  }, unusualActivityReport.reference_number ? {
    label: 'Reference Number',
    value: unusualActivityReport.reference_number,
    copyable: unusualActivityReport.id
  } : {
    label: 'Reference Number',
    value: 'N/A'
  }, {
    label: 'Bank',
    value: formatBank(unusualActivityReport.bank)
  }, {
    label: 'Referral Channel',
    value: formatReferralChannel[unusualActivityReport.referral_channel]
  }, {
    label: 'Created By',
    value: unusualActivityReport.operator_email
  }, {
    label: 'Created',
    value: unusualActivityReport.created_at,
    format: 'month-day-year-hour-minute-second'
  }, {
    label: 'Activity Began At',
    value: unusualActivityReport.activity_began_on,
    format: 'date'
  }, {
    label: 'Activity Ended At',
    value: unusualActivityReport.activity_ended_on,
    format: 'date'
  }, {
    label: 'Determined At',
    value: unusualActivityReport.determined_on ? formatISO8601Timestamp(unusualActivityReport.determined_on, 'date', 'UTC') : 'N/A'
  }])} data-sentry-element="PropertyList" data-sentry-component="UnusualActivityReportPropertyList" data-sentry-source-file="UnusualActivityReportPropertyList.tsx" />;
};