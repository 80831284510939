import { CanvasImage } from 'src/lib/image-utility';
import { useCallback, useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import useMeasure from 'react-use/lib/useMeasure';
const imageTransform = ({
  imageHeight,
  imageWidth,
  canvasWidth,
  canvasHeight,
  rotation
}: {
  imageWidth: number;
  imageHeight: number;
  canvasWidth: number;
  canvasHeight: number;
  rotation: number;
}): DOMMatrixReadOnly => {
  const padding = 4;
  let matrix = new DOMMatrixReadOnly();
  matrix = matrix.translate(canvasWidth / 2, padding);
  if (imageWidth > imageHeight) {
    matrix = matrix.scale((canvasWidth - padding * 2) / imageWidth);
  } else {
    matrix = matrix.scale((canvasHeight - padding * 2) / imageHeight);
  }
  const translateToFitRotationDegrees = Math.round(rotation / 15) * 15;
  const translateToFitRotationRadians = Math.PI / 180 * translateToFitRotationDegrees;
  matrix = matrix.translate(0, imageHeight / 2 * Math.abs(Math.cos(translateToFitRotationRadians)) + imageWidth / 2 * Math.abs(Math.sin(translateToFitRotationRadians)));
  matrix = matrix.rotate(rotation);
  matrix = matrix.translate(-imageWidth / 2, -imageHeight / 2);
  return matrix;
};
const PDF = ({
  imageSource,
  className,
  overlay,
  rotation = 0,
  magnifierEnabled = false,
  magnifierSize = 200,
  magnifierZoom = 2,
  onClick
}: {
  imageSource: CanvasImage;
  className?: string;
  overlay?: React.ReactNode;
  rotation?: number;
  magnifierEnabled?: boolean;
  magnifierSize?: number;
  magnifierZoom?: number;
  onClick?: (e: React.MouseEvent<HTMLDivElement>) => void;
}) => {
  const [containerRef, {
    width,
    height
  }] = useMeasure<HTMLDivElement>();
  const [magnifierPosition, setMagnifierPosition] = useState<{
    x: number;
    y: number;
  } | null>(null);
  const [magnifierVisible, setMagnifierVisible] = useState(false);
  const handleMouseEnter = useCallback(() => {
    setMagnifierVisible(true);
  }, []);
  const handleMouseLeave = useCallback(() => {
    setMagnifierVisible(false);
  }, []);
  const magnifierCanvasRef = useRef<HTMLCanvasElement>(null);
  const canvasRef = useRef<HTMLCanvasElement>(null);
  useEffect(() => {
    if (canvasRef.current) {
      const ctx = canvasRef.current.getContext('2d');
      if (ctx) {
        ctx.clearRect(0, 0, canvasRef.current.width, canvasRef.current.height);
        ctx.fillStyle = 'rgba(0,0,0,0.1)';
        ctx.fillRect(0, 0, canvasRef.current.width, canvasRef.current.height);
        const transform = imageTransform({
          imageWidth: imageSource.width,
          imageHeight: imageSource.height,
          canvasWidth: width,
          canvasHeight: height,
          rotation: rotation
        });
        ctx.save();
        ctx.setTransform(transform);
        ctx.drawImage(imageSource, 0, 0, imageSource.width, imageSource.height);
        ctx.restore();
      }
    }
  }, [canvasRef, imageSource, width, height, rotation]);
  const handleMouseMove = useCallback((e: React.MouseEvent<HTMLCanvasElement>) => {
    const {
      clientX,
      clientY
    } = e;
    if (canvasRef.current) {
      const bounds = canvasRef.current.getBoundingClientRect();
      setMagnifierPosition({
        x: clientX - bounds.left,
        y: clientY - bounds.top
      });
    }
  }, []);
  useEffect(() => {
    if (magnifierEnabled && magnifierVisible && magnifierPosition && magnifierCanvasRef.current) {
      const ctx = magnifierCanvasRef.current.getContext('2d');
      if (ctx && canvasRef.current) {
        const {
          x,
          y
        } = magnifierPosition;
        ctx.clearRect(0, 0, magnifierSize, magnifierSize);
        ctx.fillStyle = 'gray';
        ctx.fillRect(0, 0, magnifierSize, magnifierSize);
        ctx.drawImage(canvasRef.current, x - magnifierSize / magnifierZoom / 2, y - magnifierSize / magnifierZoom / 2, magnifierSize / magnifierZoom, magnifierSize / magnifierZoom, 0, 0, magnifierSize, magnifierSize);
      }
    }
  }, [magnifierEnabled, magnifierVisible, magnifierPosition, magnifierSize, magnifierZoom, rotation]);
  return <div ref={containerRef} className={classNames('relative', 'aspect-square', className)} onClick={onClick} data-sentry-component="PDF" data-sentry-source-file="pdf.tsx">
      <canvas ref={canvasRef} width={width} height={height} onMouseMove={handleMouseMove} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} />

      {overlay}

      {magnifierEnabled && magnifierVisible && magnifierPosition && <canvas ref={magnifierCanvasRef} width={magnifierSize} height={magnifierSize} className={classNames('absolute', 'pointer-events-none', 'border-1', 'border-gray-500', 'rounded-full', 'z-10')} style={{
      top: magnifierPosition.y,
      left: magnifierPosition.x,
      borderRadius: '50%'
    }} />}
    </div>;
};
export { PDF };