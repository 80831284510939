import { useMemo } from 'react';
import { BadgeColor } from '@increase/shared/components/Badge';
import { PrestyledTableProps, Table, TableColumns } from '@increase/shared/components/Table';
import { formatInteger, formatISO8601Timestamp, humanize } from '@increase/shared/lib/formatting';
import { SanctionsScreeningReviewListResponseDataItem, SanctionsScreeningReviewListResponseDataItemScreeningHistory, SanctionsScreeningReviewListResponseDataItemStatus } from 'src/build/operations';
import { useOperatorGet } from 'src/hooks/reactQuery';
type SanctionsScreeningReviewsTableProps = {
  showRecordId: boolean;
} & PrestyledTableProps<SanctionsScreeningReviewListResponseDataItem>;
const statusBadgeColors: Record<SanctionsScreeningReviewListResponseDataItemStatus, BadgeColor> = {
  backfill: 'blue',
  false_positive: 'green',
  pending_reviewing: 'yellow',
  requires_attention: 'yellow',
  true_positive: 'red'
};
const historyBadgeColors: Record<SanctionsScreeningReviewListResponseDataItemScreeningHistory, BadgeColor> = {
  rescreen: 'yellow',
  first_screen: 'blue'
};
export const SanctionsScreeningReviewsTable = (props: SanctionsScreeningReviewsTableProps) => {
  const {
    showRecordId,
    ...rest
  } = props;
  const {
    data: operator
  } = useOperatorGet({});
  const columns = useMemo<TableColumns<SanctionsScreeningReviewListResponseDataItem>>(() => {
    return [showRecordId && {
      header: 'Record ID',
      contents: review => ({
        text: review.record_id,
        textColor: 'emphasis',
        textWeight: 'medium'
      })
    }, showRecordId && {
      header: 'Record type',
      contents: review => {
        return {
          text: humanize(review.record_type)
        };
      }
    }, !showRecordId && {
      header: 'Review ID',
      expand: 2,
      contents: review => ({
        text: review.id,
        textColor: 'emphasis',
        textWeight: 'medium'
      })
    }, operator?.multibank && {
      header: 'Reviewer',
      contents: review => ({
        text: humanize(review.reviewer)
      })
    }, {
      header: '# of hits',
      contents: review => {
        return {
          text: formatInteger(review.hits_count)
        };
      },
      align: 'right'
    }, {
      header: 'Status',
      expand: 1,
      contents: review => {
        return {
          text: humanize(review.status),
          badgeColor: statusBadgeColors[review.status]
        };
      }
    }, operator?.multibank && {
      header: 'Screening history',
      contents: review => {
        return {
          text: humanize(review.screening_history),
          badgeColor: historyBadgeColors[review.screening_history]
        };
      }
    }, {
      header: 'Created',
      contents: review => {
        return {
          text: formatISO8601Timestamp(review.created_at, 'date')
        };
      }
    }];
  }, [showRecordId, operator?.multibank]);
  return <Table columns={columns} {...rest} data-sentry-element="Table" data-sentry-component="SanctionsScreeningReviewsTable" data-sentry-source-file="SanctionScreeningReviewsTable.tsx" />;
};