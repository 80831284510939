import { ACHTransferGetResponse } from 'src/build/operations';
import { ListItem, PropertyList } from '@increase/shared/components/PropertyList';
import { formatACHReturnReasonCode, formatACHTransferFunding, formatACHTransferNotificationOfChangeCode, formatACHTransferStandardEntryClassCode, formatACHTransferSubmissionExpectedSettlementSchedule } from 'src/lib/transferHelpers';
import { compact } from 'lodash';
import { buildPath } from 'src/lib/routes';
import { ROUTES } from 'src/lib/routes';
type Props = {
  title: string;
  achTransfer: ACHTransferGetResponse;
};
export const ACHTransferDetailsPropertyList = ({
  title,
  achTransfer
}: Props) => {
  const debit = achTransfer.amount < 0;
  const effectiveDate = achTransfer.submission?.effective_date || achTransfer.preferred_effective_date?.date;
  return <PropertyList title={title} items={compact<ListItem>([achTransfer.submission && {
    label: 'Trace Number',
    value: achTransfer.submission.trace_number,
    copyable: achTransfer.submission.trace_number
  }, {
    label: 'Routing Number',
    value: achTransfer.routing_number,
    copyable: achTransfer.routing_number
  }, {
    label: 'Account Number',
    value: achTransfer.account_number,
    copyable: achTransfer.account_number
  }, achTransfer.external_account_id && {
    label: 'External Account ID',
    value: achTransfer.external_account_id,
    href: buildPath(ROUTES.EXTERNAL_ACCOUNTS_DETAILS, {
      externalAccountID: achTransfer.external_account_id
    })
  }, {
    label: 'Funding',
    value: formatACHTransferFunding(achTransfer)
  }, {
    label: 'Direction',
    value: debit ? 'Debit (funds move towards Increase)' : 'Credit (funds move away from Increase)'
  }, achTransfer.company_descriptive_date && {
    label: 'Company Descriptive Date',
    value: achTransfer.company_descriptive_date
  }, achTransfer.company_discretionary_data && {
    label: 'Company Discretionary Data',
    value: achTransfer.company_discretionary_data
  }, achTransfer.company_name && {
    label: 'Company Name',
    value: achTransfer.company_name
  }, achTransfer.individual_id && {
    label: 'Individual Identifier',
    value: achTransfer.individual_id
  }, achTransfer.individual_name && {
    label: 'Individual Name',
    value: achTransfer.individual_name
  }, effectiveDate && {
    label: 'Effective Date',
    value: effectiveDate,
    format: 'date'
  }, achTransfer.submission && {
    label: 'Settlement Schedule',
    value: formatACHTransferSubmissionExpectedSettlementSchedule(achTransfer.submission.expected_settlement_schedule)
  }, {
    label: 'Standard Entry Class Code',
    value: formatACHTransferStandardEntryClassCode(achTransfer)
  }, achTransfer.return && {
    label: 'Return Trace Number',
    value: achTransfer.return.trace_number
  }, achTransfer.return && {
    label: 'Return Reason',
    value: formatACHReturnReasonCode(achTransfer.return.return_reason_code)
  }, achTransfer.return && {
    label: 'Return Code',
    value: achTransfer.return.raw_return_reason_code,
    copyable: achTransfer.return.raw_return_reason_code
  }, ...achTransfer.ach_notifications_of_change.flatMap(notificationOfChange => [{
    label: 'Notification of Change Code',
    value: formatACHTransferNotificationOfChangeCode(notificationOfChange.change_code)
  }, {
    label: 'Corrected Data',
    value: notificationOfChange.corrected_data
  }])])} data-sentry-element="PropertyList" data-sentry-component="ACHTransferDetailsPropertyList" data-sentry-source-file="ACHTransferDetailsPropertyList.tsx" />;
};