import { TableColumns, Table, PrestyledTableProps } from '@increase/shared/components/Table';
import { useMemo } from 'react';
import { BeneficialOwnerGetResponseMicrobiltItem } from 'src/build/operations';
import { compact } from 'lodash';
import { formatISO8601Timestamp } from 'shared/lib/formatting';
import { buildPath, ROUTES } from 'src/lib/routes';
type Props = PrestyledTableProps<BeneficialOwnerGetResponseMicrobiltItem>;
export const MicrobiltSearchResultsTable = (props: Props) => {
  const {
    getRowProps,
    ...rest
  } = props;
  const columns = useMemo<TableColumns<BeneficialOwnerGetResponseMicrobiltItem>>(() => {
    return [{
      header: 'Request ID',
      expand: 1,
      contents: result => ({
        text: result.request_id
      })
    }, {
      header: 'Name',
      contents: result => ({
        text: compact([result.first_name, result.middle_name, result.last_name]).join(' ')
      })
    }, {
      header: 'Tax ID Last 4',
      contents: result => ({
        text: result.tax_id_last_4 ?? '-'
      })
    }, {
      header: 'Date of birth',
      contents: result => ({
        text: result.birth_date ?? '-'
      })
    }, {
      header: 'MicroBilt Return Code',
      contents: result => ({
        text: result.return_code,
        badgeColor: 'green'
      })
    }, {
      header: 'MicroBilt Requested At',
      contents: result => ({
        text: formatISO8601Timestamp(result.created_at, 'date'),
        tooltip: formatISO8601Timestamp(result.created_at, 'time')
      })
    }, {
      header: 'MicroBilt Raw Response',
      contents: result => ({
        text: 'view',
        href: buildPath(ROUTES.ENTITY_MICROBILT_RESULT, {
          microBiltResponseId: result.id
        })
      })
    }];
  }, []);
  const defaultRowProps = (_datum: BeneficialOwnerGetResponseMicrobiltItem) => ({
    className: 'hover:bg-main-hover cursor-pointer transition-all group'
  });
  return <Table<BeneficialOwnerGetResponseMicrobiltItem> columns={columns} getRowProps={getRowProps ?? defaultRowProps} {...rest} data-sentry-element="Table" data-sentry-component="MicrobiltSearchResultsTable" data-sentry-source-file="MicrobiltSearchResultsTable.tsx" />;
};