import React, { useCallback, useState } from 'react';
import { MultiImageTool } from './check-workflow/image-tool';
import { OperatorNotes } from './operator-notes';
import { useNavigate } from 'react-router';
import { useIdentityDocumentGet, useIdentityDocumentPatch, usePassportMachineReadableZoneExtractionPost } from 'src/hooks/reactQuery';
import { useServices } from 'src/hooks/use-services';
import { IdentityDocumentGetResponse } from 'src/build/operations';
import { PageLayout } from './deprecated/PageLayout';
import { StyledLink } from './deprecated/StyledLink';
import { Box } from '@increase/shared/components/Box';
import { SegmentedControl } from '@increase/shared/components/SegmentedControl';
import { ListItem, PropertyList } from '@increase/shared/components/PropertyList';
import { compact } from 'lodash';
import { useTypedParams } from 'react-router-typesafe-routes/dom';
import { ROUTES, buildPath } from 'src/lib/routes';
import { Button } from 'shared/components/Button';
import { Body, Heading } from 'shared/components/Text';
import { Icon } from 'shared/components/Icon';
import { Badge } from 'shared/components/Badge';
import { TextArea } from 'shared/components/TextArea';
import { humanize } from 'shared/lib/formatting';
type ActionsProps = {
  identityDocument: IdentityDocumentGetResponse;
  canVerify: boolean;
};
const nameMatches = (extracted: {
  given_names: string[];
  surname: string;
}, submitted: string): 'yes' | 'no' | 'partial' => {
  const simpleName = `${extracted.given_names[0]} ${extracted.surname}`.toUpperCase();
  const fullName = `${extracted.given_names.join(' ')} ${extracted.surname}`.toUpperCase();
  return fullName === submitted.toUpperCase() ? 'yes' : simpleName === submitted.toUpperCase() ? 'partial' : 'no';
};
const InitialActions = ({
  identityDocument,
  canVerify
}: ActionsProps) => {
  const navigate = useNavigate();
  const identityDocumentPatch = useIdentityDocumentPatch();
  const action = useCallback((approve: boolean) => {
    identityDocumentPatch.mutateAsync([identityDocument.id, {
      identity_verification_status: approve ? 'verified' : 'falsified'
    }]).then(() => navigate('/identity_documents'));
  }, [identityDocument, identityDocumentPatch, navigate]);
  return <Box gap="4" data-sentry-element="Box" data-sentry-component="InitialActions" data-sentry-source-file="identity-document-review.tsx">
      <Button text="Verify" disabled={!canVerify} data-testid="identityDocumentReview.verify" onClick={() => action(true)} keyboardShortcut="V" data-sentry-element="Button" data-sentry-source-file="identity-document-review.tsx" />

      <Button style="warning" text="Falsify" data-testid="identityDocumentReview.falsify" onClick={() => action(false)} data-sentry-element="Button" data-sentry-source-file="identity-document-review.tsx" />
    </Box>;
};
const ForceVerify = ({
  identityDocument
}: {
  identityDocument: IdentityDocumentGetResponse;
}) => {
  const [forceVerifyJustification, setForceVerifyJustification] = useState('');
  const identityDocumentPatch = useIdentityDocumentPatch();
  return <Box data-sentry-element="Box" data-sentry-component="ForceVerify" data-sentry-source-file="identity-document-review.tsx">
      <Body data-sentry-element="Body" data-sentry-source-file="identity-document-review.tsx">
        If this was falsified in error, make a note of why you're updating it
        now.
      </Body>
      <TextArea name="forceVerifyJustification" value={forceVerifyJustification} onChange={event => setForceVerifyJustification(event.target.value)} placeholder="Justification" data-sentry-element="TextArea" data-sentry-source-file="identity-document-review.tsx" />
      <Button text="Force Verify" disabled={identityDocumentPatch.isLoading || forceVerifyJustification.length === 0} onClick={() => identityDocumentPatch.mutateAsync([identityDocument.id, {
      identity_verification_status: 'verified',
      note: forceVerifyJustification
    }])} data-sentry-element="Button" data-sentry-source-file="identity-document-review.tsx" />
    </Box>;
};
const Images = (props: {
  identityDocument: NonNullable<IdentityDocumentGetResponse>;
}) => {
  const [selected, setSelected] = useState(0);
  const {
    operations
  } = useServices();
  const imageURL = (fileID: string) => `${operations}/api_files/${fileID}/view`;
  const fileId = selected === 0 ? props.identityDocument.api_file_id : props.identityDocument.api_back_file_id;
  const segments = [{
    title: 'Front',
    selected: selected === 0,
    onSelect: () => setSelected(0)
  }];
  if (props.identityDocument.api_back_file_id) {
    segments.push({
      title: 'Reverse',
      selected: selected === 1,
      onSelect: () => setSelected(1)
    });
  }
  return <Box gap="1" className="border-stronger flex-1" data-sentry-element="Box" data-sentry-component="Images" data-sentry-source-file="identity-document-review.tsx">
      <SegmentedControl segments={segments} data-sentry-element="SegmentedControl" data-sentry-source-file="identity-document-review.tsx" />
      {fileId ? <MultiImageTool selectionMode="none" url={imageURL(fileId)} size={700} /> : <div>No image available</div>}
    </Box>;
};
const Row = (props: {
  label: string;
  submitted: string;
  extracted: string | undefined;
  extractedMatch: 'yes' | 'no' | 'partial';
  match: boolean;
  setMatch: (match: boolean) => void;
  testId: string;
}) => {
  return <div className="even:bg-stronger odd:bg-subtle grid grid-cols-[30px_1fr_1fr_1fr_30px] items-center gap-2 py-1" data-sentry-component="Row" data-sentry-source-file="identity-document-review.tsx">
      <input type="checkbox" className="ml-1" checked={props.match} onChange={e => props.setMatch(e.target.checked)} data-testid={props.testId} />
      <Body data-sentry-element="Body" data-sentry-source-file="identity-document-review.tsx">{props.label}</Body>
      <Body data-sentry-element="Body" data-sentry-source-file="identity-document-review.tsx">{props.submitted}</Body>
      <Body data-sentry-element="Body" data-sentry-source-file="identity-document-review.tsx">{props.extracted}</Body>
      {props.extracted ? props.extractedMatch === 'yes' ? <Icon name="check_square" className="text-success" /> : props.extractedMatch === 'no' ? <Icon name="slash_circle" className="text-danger" /> : <Icon name="alert" className="text-warning" /> : <div />}
    </div>;
};
const formatName = (name: {
  given_names: string[];
  surname: string;
}) => name.given_names.join(' ') + ' ' + name.surname;
export const IdentityDocumentReview = () => {
  const {
    identityDocumentID
  } = useTypedParams(ROUTES.IDENTITY_DOCUMENT_REVIEW);
  const [nameMatch, setNameMatch] = useState(false);
  const [birthdateMatch, setBirthdateMatch] = useState(false);
  const [idNumberMatch, setIdNumberMatch] = useState(false);
  const [addressMatch, setAddressMatch] = useState(false);
  const {
    data: identityDocument
  } = useIdentityDocumentGet(identityDocumentID);
  const {
    mutateAsync,
    data: parsingData,
    isLoading: isParsing,
    error: parsingError
  } = usePassportMachineReadableZoneExtractionPost();
  if (!identityDocument) {
    return null;
  }
  const canVerify = nameMatch && [birthdateMatch, idNumberMatch, addressMatch].filter(match => match).length >= 2;
  const extractedName = parsingData?.parsed_passport_data ? formatName(parsingData.parsed_passport_data) : undefined;
  return <PageLayout id="application.identityDocumentReview" headline="Identity Document Review" data-sentry-element="PageLayout" data-sentry-component="IdentityDocumentReview" data-sentry-source-file="identity-document-review.tsx">
      <StyledLink to={buildPath(ROUTES.GROUPS_DETAIL, {
      groupId: identityDocument.group_id
    })} data-sentry-element="StyledLink" data-sentry-source-file="identity-document-review.tsx">
        {identityDocument.group_id}
      </StyledLink>

      <div className="flex flex-row">
        <Images identityDocument={identityDocument} data-sentry-element="Images" data-sentry-source-file="identity-document-review.tsx" />

        <Box className="flex-1" padding="6" data-sentry-element="Box" data-sentry-source-file="identity-document-review.tsx">
          <Box flex gap="2" center data-sentry-element="Box" data-sentry-source-file="identity-document-review.tsx">
            <Button className="grow" text="Parse Machine Readable Zone" style="secondary" keyboardShortcut="P" disabled={isParsing || !!parsingError || !!parsingData} onClick={async () => {
            const result = await mutateAsync([{
              entity_setup_identity_document_submission_id: identityDocumentID
            }]);
            if (result.parsed_passport_data?.date_of_birth === identityDocument.submitted_date_of_birth) {
              setBirthdateMatch(true);
            }
            if (result.parsed_passport_data?.passport_number === identityDocument.submitted_document_number) {
              setIdNumberMatch(true);
            }
            if (result.parsed_passport_data && ['yes', 'partial'].includes(nameMatches(result.parsed_passport_data, identityDocument.submitted_name))) {
              setNameMatch(true);
            }
          }} data-sentry-element="Button" data-sentry-source-file="identity-document-review.tsx" />
            {parsingData && <Icon name="check_square" />}
            {parsingError && <Icon name="slash_circle" />}
          </Box>

          {identityDocument.identity_verification_status === 'pending_verification' && <>
              <InitialActions identityDocument={identityDocument} canVerify={canVerify} />

              <div className="grid grid-cols-[30px_1fr_1fr_1fr_30px] items-center gap-2">
                <div />
                <div className="flex">
                  <Badge color="gray" size="small" label="Rule" />
                </div>
                <div className="flex">
                  <Badge color="gray" size="small" label="Submitted" />
                </div>
                <div className="flex">
                  <Badge color="gray" size="small" label="Extracted" />
                </div>
                <div />
              </div>

              <Heading size="small">The document must match name:</Heading>
              <Row label="Name" submitted={identityDocument.submitted_name} extracted={extractedName} match={nameMatch} extractedMatch={parsingData?.parsed_passport_data ? nameMatches(parsingData.parsed_passport_data, identityDocument.submitted_name) : 'no'} setMatch={setNameMatch} testId="nameMatch" />
              <h6>And two out of the following three:</h6>

              <div>
                <Row label="Birthdate" submitted={identityDocument.submitted_date_of_birth} extracted={parsingData?.parsed_passport_data?.date_of_birth} match={birthdateMatch} extractedMatch={parsingData?.parsed_passport_data?.date_of_birth === identityDocument.submitted_date_of_birth ? 'yes' : 'no'} setMatch={setBirthdateMatch} testId="birthdateMatch" />

                <Row label="ID Number" submitted={identityDocument.submitted_document_number || '?'} extracted={parsingData?.parsed_passport_data?.passport_number} match={idNumberMatch} extractedMatch={parsingData?.parsed_passport_data?.passport_number === identityDocument.submitted_document_number ? 'yes' : 'no'} setMatch={setIdNumberMatch} testId="idNumberMatch" />

                <Row label="Address" submitted={[identityDocument.submitted_address.line1, identityDocument.submitted_address.line2, identityDocument.submitted_address.city, identityDocument.submitted_address.state, identityDocument.submitted_address.zip].filter(part => !!part).join(', ')} extracted={''} extractedMatch={'no'} match={addressMatch} setMatch={setAddressMatch} testId="addressMatch" />
              </div>
            </>}

          <PropertyList title="Details" items={compact<ListItem>([{
          label: 'Status',
          value: humanize(identityDocument.identity_verification_status),
          badgeColor: identityDocument.identity_verification_status === 'falsified' ? 'red' : 'gray'
        }, {
          label: 'Document type',
          value: humanize(identityDocument.submitted_document_type)
        }, {
          label: 'Issuing country',
          value: identityDocument.submitted_country
        }, identityDocument.submitted_state && {
          label: 'Issuing state',
          value: identityDocument.submitted_state
        }])} data-sentry-element="PropertyList" data-sentry-source-file="identity-document-review.tsx" />

          {identityDocument.identity_verification_status === 'falsified' && <ForceVerify identityDocument={identityDocument} />}

          {parsingData?.parsed_passport_data && <PropertyList title="Parsed Details" items={compact<ListItem>([{
          label: 'Surname',
          value: parsingData.parsed_passport_data.surname
        }, {
          label: 'Given name',
          value: parsingData.parsed_passport_data.given_names.join(' ')
        }, {
          label: 'Date of birth',
          value: parsingData.parsed_passport_data.date_of_birth
        }, {
          label: 'Passport number',
          value: parsingData.parsed_passport_data.passport_number
        }, {
          label: 'Expiration date',
          value: parsingData.parsed_passport_data.expiration_date
        }, {
          label: 'Issuing country',
          value: parsingData.parsed_passport_data.issuing_country
        }])} />}
        </Box>
      </div>

      <OperatorNotes modelId={identityDocument.id} data-sentry-element="OperatorNotes" data-sentry-source-file="identity-document-review.tsx" />
    </PageLayout>;
};