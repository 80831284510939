import React, { useMemo } from 'react';
import { useOperatorGet, useRoleListInfinite } from 'src/hooks/reactQuery';
import { PageLayout } from '../deprecated/PageLayout';
import { TableStateWrapper } from '@increase/shared/components/TableStateWrapper';
import { compact } from 'lodash';
import { TableFilter } from '@increase/shared/components/Table/filters';
import { makeEnumFilter, makeGroupFilter, makeModelIdFilter } from 'src/lib/tableFilterHelpers';
import { RolesTable } from '../tables/RolesTable';
import { RoleListResponseDataItemTitle, RoleListStatusesItem } from 'src/build/operations';
import { ROUTES } from 'src/lib/routes';
import { useSearchParamsState } from '@increase/shared/hooks/useTypedSearchParamsState';
export const RoleListPage = () => {
  const {
    data: operator
  } = useOperatorGet({});
  const [groupFilter, setGroupFilter] = useSearchParamsState(ROUTES.ROLES_LIST, 'group');
  const [userFilter, setUserFilter] = useSearchParamsState(ROUTES.ROLES_LIST, 'user');
  const [titleFilter, setTitleFilter] = useSearchParamsState(ROUTES.ROLES_LIST, 'title');
  const [statusFilter, setStatusFilter] = useSearchParamsState(ROUTES.ROLES_LIST, 'status');
  const filters: TableFilter[] = useMemo(() => compact([makeGroupFilter(groupFilter, setGroupFilter), makeEnumFilter(titleFilter, setTitleFilter, 'Title', RoleListResponseDataItemTitle, 'account'), makeEnumFilter(statusFilter, setStatusFilter, 'Status', RoleListStatusesItem, 'info'), makeModelIdFilter(userFilter, setUserFilter, 'User', 'user')]), [groupFilter, setGroupFilter, setTitleFilter, titleFilter, statusFilter, setStatusFilter, userFilter, setUserFilter]);
  const listResult = useRoleListInfinite({
    group_ids: groupFilter.length > 0 ? groupFilter : undefined,
    titles: titleFilter.length > 0 ? titleFilter : undefined,
    statuses: statusFilter.length > 0 ? statusFilter : undefined,
    user_ids: userFilter.length > 0 ? userFilter : undefined
  });
  return <PageLayout headline="Roles" data-sentry-element="PageLayout" data-sentry-component="RoleListPage" data-sentry-source-file="RoleListPage.tsx">
      <TableStateWrapper table={RolesTable} {...listResult} style="primary" emptyTitle="No Roles" emptySubtitle="There are no roles to display" filters={filters} showGroup showActions={!!operator && operator.entitlements.includes('user_impersonate')} data-sentry-element="TableStateWrapper" data-sentry-source-file="RoleListPage.tsx" />
    </PageLayout>;
};