import React, { useMemo } from 'react';
import { PageLayout } from '../deprecated/PageLayout';
import { useResultStatusList, useResultSummaryGet } from 'src/hooks/reactQuery';
import { TableStateWrapper } from '@increase/shared/components/TableStateWrapper';
import { makeDescriptionFilter, makeEnumFilter, makeStatusFilter } from 'src/lib/tableFilterHelpers';
import { TableFilter } from '@increase/shared/components/Table/filters';
import { ResultsTable } from '../tables/ResultsTable';
import { useSearchParamsState } from '@increase/shared/hooks/useTypedSearchParamsState';
import { buildPath, ROUTES } from 'src/lib/routes';
import { Loading } from '@increase/shared/components/Loading';
import { formatInteger } from '@increase/shared/lib/formatting';
import { StyledLink } from 'shared/components/StyledLink';
import { Box } from 'shared/components/Box';
const resultStatuses = {
  succeeding: 'Succeeding',
  failing: 'Failing',
  retired: 'Retired'
} as const;
const suppressionStatuses = {
  true: 'true',
  false: 'false'
} as const;
const Summary = () => {
  const {
    data
  } = useResultSummaryGet({});
  if (!data) {
    return <Loading />;
  }
  return <Box flex className="text-xs" data-sentry-element="Box" data-sentry-component="Summary" data-sentry-source-file="ResultListPage.tsx">
      <Box className="flex-col gap-y-1" data-sentry-element="Box" data-sentry-source-file="ResultListPage.tsx">
        <StyledLink style="underline" href={buildPath(ROUTES.RESULTS_V2, {}, {
        statuses: ['succeeding'],
        suppression_status: ['false']
      })} data-sentry-element="StyledLink" data-sentry-source-file="ResultListPage.tsx">
          Succeeding
        </StyledLink>

        <StyledLink style="underline" href={buildPath(ROUTES.RESULTS_V2, {}, {
        statuses: ['failing'],
        suppression_status: ['false']
      })} data-sentry-element="StyledLink" data-sentry-source-file="ResultListPage.tsx">
          Failing
        </StyledLink>

        <StyledLink style="underline" href={buildPath(ROUTES.RESULTS_V2, {}, {
        statuses: ['succeeding'],
        suppression_status: ['true']
      })} data-sentry-element="StyledLink" data-sentry-source-file="ResultListPage.tsx">
          Suppressed & Succeeding
        </StyledLink>

        <StyledLink style="underline" href={buildPath(ROUTES.RESULTS_V2, {}, {
        statuses: ['failing'],
        suppression_status: ['true']
      })} data-sentry-element="StyledLink" data-sentry-source-file="ResultListPage.tsx">
          Suppressed & Failing
        </StyledLink>
      </Box>
      <Box className="flex-col gap-y-1" data-sentry-element="Box" data-sentry-source-file="ResultListPage.tsx">
        <div>{formatInteger(data.succeeding)}</div>
        <div>{formatInteger(data.failing)}</div>
        <div>{formatInteger(data.suppressed_succeeding)}</div>
        <div>{formatInteger(data.suppressed_failing)}</div>
      </Box>
    </Box>;
};
export const ResultListPage = () => {
  const [statuses, setStatuses] = useSearchParamsState(ROUTES.RESULTS_V2, 'statuses');
  const [suppressionStatus, setSuppressionStatus] = useSearchParamsState(ROUTES.RESULTS_V2, 'suppression_status');
  const [descriptionFilter, setDescriptionFilter] = useSearchParamsState(ROUTES.RESULTS_V2, 'description');
  const filters: TableFilter[] = useMemo(() => [makeStatusFilter(statuses, setStatuses, resultStatuses), makeEnumFilter(suppressionStatus, setSuppressionStatus, 'Suppressed', suppressionStatuses, 'calendar'), makeDescriptionFilter(descriptionFilter, setDescriptionFilter)], [descriptionFilter, setDescriptionFilter, setStatuses, statuses, suppressionStatus, setSuppressionStatus]);
  const listResult = useResultStatusList({
    statuses: statuses.length > 0 ? statuses : undefined,
    suppressed: suppressionStatus.length > 0 ? suppressionStatus[0] === 'true' : undefined,
    description_filter: descriptionFilter === null ? undefined : descriptionFilter
  });
  return <PageLayout headline={'Results'} action={<Summary />} data-sentry-element="PageLayout" data-sentry-component="ResultListPage" data-sentry-source-file="ResultListPage.tsx">
      <TableStateWrapper table={ResultsTable} style="primary" {...listResult} emptyTitle="No Results" emptySubtitle="There are no results to display" filters={filters} data-sentry-element="TableStateWrapper" data-sentry-source-file="ResultListPage.tsx" />
    </PageLayout>;
};