import { ReactNode } from 'react';
import { RequireEntitlements } from './require-entitlements';
type Props = {
  children: ReactNode;
};
export const InternalOperatorOnly = ({
  children
}: Props) => {
  return <RequireEntitlements entitlements={['internal_read_write']} data-sentry-element="RequireEntitlements" data-sentry-component="InternalOperatorOnly" data-sentry-source-file="internal-operator-only.tsx">
      {children}
    </RequireEntitlements>;
};