import 'react-pdf/dist/Page/AnnotationLayer.css';
import 'react-pdf/dist/Page/TextLayer.css';
import { PageLayout } from '../deprecated/PageLayout';
import { loadImages } from 'src/lib/image-utility';
import { useCallback, useEffect, useState } from 'react';
import { useKeyPressEvent, useMeasure } from 'react-use';
import { PDF } from './pdf';
import { Check, Image } from './types';
import { Sidebar } from './sidebar';
import { Box } from 'shared/components/Box';
import classNames from 'classnames';
import { useTypedParams } from 'react-router-typesafe-routes/dom';
import { ROUTES } from 'src/lib/routes';
import { useInboundMailItemGet } from 'src/hooks/reactQuery';
import { StyledLink } from 'shared/components/StyledLink';
import { InboundMailItemGetResponse } from 'src/build/operations';
import { Shortcut } from './shortcut';
import { Caption } from 'shared/components/Text';
import { useServices } from 'src/hooks/use-services';
import { useNavigate } from 'react-router-dom';
import { Document, Page } from 'react-pdf';
import { uniqueId } from 'lodash';
const DOCUMENT_OPTIONS = {
  withCredentials: true
};
function OldManualTaskLink({
  inboundMailItem
}: {
  inboundMailItem: InboundMailItemGetResponse;
}) {
  const params = new URLSearchParams({
    stable_mailbox_mail_item_id: inboundMailItem.id,
    pay_to_the_order_of: inboundMailItem.payee ?? '',
    amounts: inboundMailItem.amounts.join(',')
  }).toString();
  const url = `/manual_tasks/Operations::ManualTask::AllocateStableMailboxMailItem?${params}`;
  return <StyledLink style="underline" href={url} data-sentry-element="StyledLink" data-sentry-component="OldManualTaskLink" data-sentry-source-file="index.tsx">
      Manual task
    </StyledLink>;
}
export const InboundMailItemReview = () => {
  const navigate = useNavigate();
  const {
    operations
  } = useServices();
  const {
    inboundMailItemID
  } = useTypedParams(ROUTES.INBOUND_MAIL_ITEM_REVIEW);
  const [pdfViewerRef, {
    width: pdfViewerWidth
  }] = useMeasure<HTMLDivElement>();
  const [images, setImages] = useState<Image[]>([]);
  const [checks, setChecks] = useState<Check[]>([]);
  const [line2Suffix, setLine2Suffix] = useState<string>('');
  const [selectedImageIndex, setSelectedImageIndex] = useState<number>(0);
  const [magnifierEnabled, setMagnifierEnabled] = useState<boolean>(false);
  const {
    data
  } = useInboundMailItemGet(inboundMailItemID ?? '');
  useEffect(() => {
    loadImages(`${operations}/internal-files/${data?.internal_file_id}/view`).then(loadedImages => {
      setImages(loadedImages.map((image, index) => ({
        index,
        image,
        rotation: 0
      })));
      setSelectedImageIndex(loadedImages.length > 1 ? 1 : 0);
    });
  }, [data?.internal_file_id, operations]);
  useEffect(() => {
    if (data?.checks) {
      setChecks(data.checks.map(check => ({
        id: check.id,
        amount: check.amount,
        front: null,
        back: null
      })));
    }
  }, [data]);
  const handleUpdateCheck = useCallback((id: string, amount: number | undefined, front: Image | null, back: Image | null) => {
    setChecks(checks.map(c => c.id === id ? {
      ...c,
      amount,
      front,
      back
    } : c));
  }, [checks]);
  const handleAddCheck = useCallback(() => {
    setChecks([...checks, {
      id: uniqueId(),
      amount: 0,
      front: null,
      back: null
    }]);
  }, [checks]);
  const handleDeleteCheck = useCallback((id: string) => {
    setChecks(checks.filter(c => c.id !== id));
  }, [checks]);
  const redirectToQueue = useCallback(() => {
    navigate(ROUTES.INBOUND_MAIL_ITEM_REVIEW_LIST.path);
  }, [navigate]);
  const page = (offset: number) => {
    const currentIndex = selectedImageIndex;
    if (currentIndex + offset < 0) {
      setSelectedImageIndex(images.length - 1);
    } else if (currentIndex + offset >= images.length) {
      setSelectedImageIndex(0);
    } else {
      setSelectedImageIndex(currentIndex + offset);
    }
  };
  useKeyPressEvent('ArrowRight', () => {
    page(1);
  });
  useKeyPressEvent('ArrowLeft', () => {
    page(-1);
  });
  useKeyPressEvent('r', e => {
    if (e.target instanceof HTMLInputElement) {
      return;
    }
    setImages(images.map(image => image.index === selectedImageIndex ? {
      ...image,
      rotation: image.rotation + 90
    } : image));
  });
  useKeyPressEvent('m', e => {
    if (e.target instanceof HTMLInputElement) {
      return;
    }
    setMagnifierEnabled(!magnifierEnabled);
  });
  useKeyPressEvent('f', e => {
    if (e.target instanceof HTMLInputElement || checks.length !== 1) {
      return;
    }
    const check = checks[0];
    handleUpdateCheck(check.id, check.amount, images[selectedImageIndex], check.back);
  });
  useKeyPressEvent('b', e => {
    if (e.target instanceof HTMLInputElement || checks.length !== 1) {
      return;
    }
    const check = checks[0];
    handleUpdateCheck(check.id, check.amount, check.front, images[selectedImageIndex]);
  });
  const selectedImage = images[selectedImageIndex] ?? null;
  return <PageLayout headline={`Inbound mail item`} action={data && <OldManualTaskLink inboundMailItem={data} />} data-sentry-element="PageLayout" data-sentry-component="InboundMailItemReview" data-sentry-source-file="index.tsx">
      <div className="flex flex-col gap-4">
        <div className="flex flex-col gap-2 lg:flex-row lg:items-center lg:justify-between">
          <div>
            <Caption className="font-mono" data-sentry-element="Caption" data-sentry-source-file="index.tsx">{inboundMailItemID}</Caption>
          </div>
          <Box flex gap="2" data-sentry-element="Box" data-sentry-source-file="index.tsx">
            <Shortcut keys={['r']} action="Rotate" data-sentry-element="Shortcut" data-sentry-source-file="index.tsx" />
            <Shortcut keys={['←']} action="Previous image" data-sentry-element="Shortcut" data-sentry-source-file="index.tsx" />
            <Shortcut keys={['→']} action="Next image" data-sentry-element="Shortcut" data-sentry-source-file="index.tsx" />
            {checks.length === 1 && <>
                <Shortcut keys={['f']} action="Set front" />
                <Shortcut keys={['b']} action="Set back" />
              </>}
          </Box>
        </div>

        <hr className="border-gray-200" />

        <div className="grid grid-cols-12 gap-4">
          <div className="col-span-4">
            <div className="flex-column space-y-1">
              <Box gap="6" data-sentry-element="Box" data-sentry-source-file="index.tsx">
                {images.length > 0 && <div className={`grid grid-cols-3 gap-4`}>
                    {images.map(image => {
                  return <PDF key={image.index} imageSource={image.image} className={classNames('border-2', selectedImageIndex === image.index ? 'border-blue-500' : 'border-gray-200')} rotation={image.rotation} onClick={() => {
                    setSelectedImageIndex(image.index);
                  }} />;
                })}
                  </div>}
              </Box>
            </div>
          </div>
          <div className="col-span-5">
            <Box gap="6" className="h-full w-full" data-sentry-element="Box" data-sentry-source-file="index.tsx">
              {data?.internal_file_id && selectedImage && <div ref={pdfViewerRef} className="overflow-hidden rounded-lg border-1 border-gray-200">
                  <Document file={`${operations}/internal-files/${data?.internal_file_id}/view`} options={DOCUMENT_OPTIONS}>
                    <Page pageNumber={selectedImage.index + 1} width={pdfViewerWidth} rotate={selectedImage.rotation} />
                  </Document>
                </div>}
            </Box>
          </div>
          <div className="col-span-3">
            <Sidebar inboundMailItemId={inboundMailItemID} checks={checks} line2Suffix={line2Suffix} onUpdate={setLine2Suffix} onUpdateCheck={handleUpdateCheck} onDeleteCheck={handleDeleteCheck} onAddCheck={handleAddCheck} selectedImage={selectedImage} onAllocate={redirectToQueue} onReject={redirectToQueue} data-sentry-element="Sidebar" data-sentry-source-file="index.tsx" />
          </div>
        </div>
      </div>
    </PageLayout>;
};