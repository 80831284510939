import { useState } from 'react';
import { Box } from 'shared/components/Box';
import { Button } from 'shared/components/Button';
import { DrawerLayout } from 'shared/components/DrawerLayout';
import { FormRowStyling } from 'shared/components/FormRowStyling';
import { Note } from 'shared/components/Note';
import { Select } from 'shared/components/Select';
import { TextInput } from 'shared/components/TextInput';
import { humanize } from 'shared/lib/formatting';
import { ProgramGetResponse, ProgramInterestRatePostParametersInterestRatePlan } from 'src/build/operations';
import { useProgramInterestRatePost } from 'src/hooks/reactQuery';
type Props = {
  program: ProgramGetResponse;
  onSuccess: () => void;
};
export const SetProgramInterestRateDrawer = ({
  program,
  onSuccess
}: Props) => {
  const [delta, setDelta] = useState('0.0');
  const [interestRatePlan, setInterestRatePlan] = useState<ProgramInterestRatePostParametersInterestRatePlan>('no_interest');
  const setInterestRateMutation = useProgramInterestRatePost();
  const handleSubmit = async () => {
    setInterestRateMutation.mutateAsync([program.id, {
      delta,
      interest_rate_plan: interestRatePlan
    }]);
    onSuccess();
  };
  return <DrawerLayout title="Update program interest rate" body={<Box>
          <Note>
            Changes are effective today. Interest is accrued daily at midnight
            UTC.
          </Note>
          <FormRowStyling label="Interest rate plan" labelPlacement="above" field={<Select value={interestRatePlan} onChange={e => setInterestRatePlan(e.target.value as ProgramInterestRatePostParametersInterestRatePlan)} sections={[{
      options: Object.values(ProgramInterestRatePostParametersInterestRatePlan).map(plan => ({
        label: humanize(plan),
        value: plan
      }))
    }]} />} />
          <FormRowStyling label="Delta" labelPlacement="above" detailRight="As a fraction; for instance, 0.01 means 1%." field={<TextInput value={delta} onChange={e => setDelta(e.target.value)} />} />
        </Box>} rightButton={<Button icon="tick" text="Set interest rate" type="submit" disabled={setInterestRateMutation.isLoading} onClick={handleSubmit} />} data-sentry-element="DrawerLayout" data-sentry-component="SetProgramInterestRateDrawer" data-sentry-source-file="SetProgramInterestRateDrawer.tsx" />;
};