import { formatAmount } from '@increase/shared/lib/formatting';
import { getDataURL } from '../../lib/image-utility';
import { CHECK_IMAGE_MINIMUM_HEIGHT, CHECK_IMAGE_MINIMUM_WIDTH } from '../check-deposit-review';
import { Button } from '@increase/shared/components/Button';
import { ImageVerify } from './image-verify';
import { CheckDepositContextGetResponse, CheckDepositGetResponse } from 'src/build/operations';
import { useCheckDepositApprove, useCheckDepositContextGet, useRoutingNumberList } from 'src/hooks/reactQuery';
import { StyledLink } from '@increase/shared/components/StyledLink';
import { ROUTES, buildPath } from 'src/lib/routes';
import { useState } from 'react';
import { Body, Subheading } from '@increase/shared/components/Text';
import { Box } from '@increase/shared/components/Box';
import { PropertyList } from '@increase/shared/components/PropertyList';
import { useNavigate } from 'react-router';
import { Badge } from 'shared/components/Badge';
import _ from 'lodash';
type Props = {
  checkDeposit: CheckDepositGetResponse;
  front: ImageData;
  rear: ImageData;
  micr: {
    accountNumber: string;
    routingNumber: string;
    auxiliaryOnUs?: string;
    serialNumber?: string;
  };
};
const removeEmpty = (s: string | undefined) => s && s !== '' ? s : undefined;
const SubmitReviewInner: React.FC<Props & {
  checkDepositContext: CheckDepositContextGetResponse;
}> = props => {
  const {
    checkDepositContext,
    checkDeposit,
    front,
    rear,
    micr
  } = props;
  const checkDepositApprove = useCheckDepositApprove();
  const frontSrc = front && getDataURL(front);
  const rearSrc = rear && getDataURL(rear);
  const navigate = useNavigate();
  const {
    data: routingNumbers
  } = useRoutingNumberList({
    routing_number: micr.routingNumber,
    needs_icon: false,
    needs_website: false
  });
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD'
  });
  const formatted = formatter.format(checkDeposit.amount / 100);
  const issues = _.compact([{
    id: 'checkAmount',
    description: `Check is made out in the amount of ${formatted}`
  }, {
    id: 'checkMadeOutTo',
    description: <span>
          Check is made out to{' '}
          {checkDepositContext.account_entity_names.join(' or ')}
        </span>
  }, routingNumbers && !routingNumbers.data[0] && {
    id: 'routingNumberDoubleChecked',
    description: <span>
            Routing number {micr.routingNumber} has been double-checked to match
            check
          </span>
  }, ...checkDepositContext.potential_duplicates.map(duplicate => ({
    id: duplicate.id,
    description: <>
          Not a duplicate of{' '}
          <span onClick={e => e.stopPropagation()}>
            <StyledLink href={buildPath(ROUTES.CHECK_DEPOSIT_REVIEW, {
          checkDepositID: duplicate.id
        })} target="_blank" style="underline">
              {duplicate.id}
            </StyledLink>
          </span>
        </>
  }))]);
  const [acknowledgedIssues, setAcknowledgedIssues] = useState<Record<string, boolean>>(issues.reduce((acc, issue) => ({
    ...acc,
    [issue.id]: false
  }), {}));
  const submit = () => {
    checkDepositApprove.mutateAsync([checkDeposit.id, {
      account_number: micr.accountNumber,
      routing_number: micr.routingNumber,
      auxiliary_on_us: removeEmpty(micr.auxiliaryOnUs),
      serial_number: removeEmpty(micr.serialNumber),
      processed_front_image_data_url: frontSrc,
      processed_rear_image_data_url: rearSrc
    }]).then(() => {
      navigate(ROUTES.CHECK_DEPOSIT_REVIEW_LIST.path);
    }).catch(() => {});
  };
  return <Box data-sentry-element="Box" data-sentry-component="SubmitReviewInner" data-sentry-source-file="submit-review.tsx">
      <Subheading contents="Confirmations" data-sentry-element="Subheading" data-sentry-source-file="submit-review.tsx" />
      <div className="ml-8">
        {issues.map(issue => <div className="hover:bg-strong cursor-pointer p-1" key={issue.id} onClick={() => setAcknowledgedIssues({
        ...acknowledgedIssues,
        [issue.id]: !acknowledgedIssues[issue.id]
      })}>
            <input id={issue.id} data-testid={issue.id} className="rounded" type="checkbox" readOnly={true} checked={acknowledgedIssues[issue.id]} />
            <span className="mx-1">{issue.description}</span>
          </div>)}
      </div>

      <div className="border-main h-px border-t" />

      <Subheading contents="Front" data-sentry-element="Subheading" data-sentry-source-file="submit-review.tsx" />
      {frontSrc && <ImageVerify minimumWidth={CHECK_IMAGE_MINIMUM_WIDTH} minimumHeight={CHECK_IMAGE_MINIMUM_HEIGHT} src={frontSrc} alt="Front of check" />}

      <br />
      <PropertyList items={[{
      label: 'Amount',
      value: formatAmount(checkDeposit.amount, 'USD')
    }, {
      label: 'Auxiliary on-us',
      value: micr.auxiliaryOnUs || 'N/A'
    }, {
      label: 'Routing number',
      valueNode: <Box flex center className="w-full grow">
                <Body>{micr.routingNumber}</Body>
                {!routingNumbers ? <Badge color="gray" label="loading" /> : routingNumbers.data[0] ? <Badge color="green" label={routingNumbers.data[0].name} /> : <Badge color="yellow" label="unknown routing number" />}
              </Box>
    }, {
      label: 'Account number',
      value: micr.accountNumber
    }, {
      label: 'Serial number',
      value: micr.serialNumber || 'N/A'
    }]} data-sentry-element="PropertyList" data-sentry-source-file="submit-review.tsx" />

      <div className="border-main h-px border-t" />

      <Subheading contents="Back" data-sentry-element="Subheading" data-sentry-source-file="submit-review.tsx" />
      {rearSrc && <ImageVerify minimumWidth={CHECK_IMAGE_MINIMUM_WIDTH} minimumHeight={CHECK_IMAGE_MINIMUM_HEIGHT} src={rearSrc} alt="Rear of check" />}

      {checkDepositApprove.error && <div data-testid="SubmitReviewError">
          {checkDepositApprove.error.response?.data.message}
        </div>}

      <div className="border-main h-px border-t" />
      <div className="mt-3">
        <Button size="xlarge" disabled={checkDepositApprove.isLoading || !Object.values(acknowledgedIssues).every(value => value)} onClick={() => {
        submit();
      }} text="Approve Check Deposit" data-sentry-element="Button" data-sentry-source-file="submit-review.tsx" />
      </div>
    </Box>;
};
export const SubmitReview: React.FC<Props> = props => {
  const {
    checkDeposit,
    micr
  } = props;
  const {
    data: checkDepositContext
  } = useCheckDepositContextGet({
    check_deposit_id: checkDeposit.id,
    auxiliary_on_us: removeEmpty(micr.auxiliaryOnUs),
    serial_number: removeEmpty(micr.serialNumber),
    account_number: micr.accountNumber,
    routing_number: micr.routingNumber
  });
  if (!checkDepositContext) {
    return <div>Loading...</div>;
  }
  return <SubmitReviewInner {...props} checkDepositContext={checkDepositContext} data-sentry-element="SubmitReviewInner" data-sentry-component="SubmitReview" data-sentry-source-file="submit-review.tsx" />;
};