import { PropertyList, ListItem } from 'shared/components/PropertyList';
import { humanize } from 'shared/lib/formatting';
import { ACHTransferGetResponse, ACHTransferGetResponseReview } from 'src/build/operations';
import { compact } from 'lodash';
import { buildPath, ROUTES } from 'src/lib/routes';
import { canReviewTransfer } from 'src/lib/transferHelpers';
import { useOperatorGet } from 'src/hooks/reactQuery';
type Props = {
  review: Exclude<ACHTransferGetResponseReview, null>;
  manual_approval: ACHTransferGetResponse['transfer_reviewing_manual_approval'];
  manual_denial: ACHTransferGetResponse['transfer_reviewing_manual_denial'];
};
export const TransferReviewPropertyList = ({
  review,
  manual_approval,
  manual_denial
}: Props) => {
  const {
    data: operator
  } = useOperatorGet({});
  if (!operator) {
    return null;
  }
  return <PropertyList title={`Transfer review by ${humanize(review.reviewer)}`} items={compact<ListItem>([{
    label: 'Review reason',
    value: humanize(review.reason)
  }, {
    label: 'Held for review at',
    value: review.created_at,
    format: 'month-day-year-hour-minute-second'
  }, {
    label: 'Outcome',
    value: manual_approval ? 'Approved' : manual_denial ? 'Denied' : 'Pending',
    badgeColor: manual_approval ? 'green' : manual_denial ? 'red' : 'yellow'
  }, !(manual_approval || manual_denial) && canReviewTransfer(operator, review) && {
    label: 'Review',
    value: 'View queue',
    href: buildPath(ROUTES.TRANSFER_REVIEWING, {}, {
      reviewer: [review.reviewer]
    })
  }, manual_approval && {
    label: 'Approved by',
    value: manual_approval.operator_email
  }, manual_approval && {
    label: 'Approved at',
    value: manual_approval.created_at,
    format: 'month-day-year-hour-minute-second'
  }, manual_denial && {
    label: 'Denied by',
    value: manual_denial.operator_email
  }, manual_denial && {
    label: 'Denied at',
    value: manual_denial.created_at,
    format: 'month-day-year-hour-minute-second'
  }])} data-sentry-element="PropertyList" data-sentry-component="TransferReviewPropertyList" data-sentry-source-file="TransferReviewPropertyList.tsx" />;
};