import { ReactNode, useState } from 'react';
import { Body, BodyStylingProps, Heading } from './Text';
import { Box } from './Box';
import { CopyButton } from './CopyButton';
import { StyledLink } from './StyledLink';
import classNames from 'classnames';
import { Badge, BadgeColor } from './Badge';
import { Icon, IconType } from './Icon';
import { TextInput } from './TextInput';
import { TextButton } from './TextButton';
import { Select, SelectSection } from './Select';
import { Switch } from './Switch';
import { AmountInput } from './AmountInput';
import { Currency, DateFormat, formatAmount, formatISO8601Timestamp } from '../lib/formatting';
import { Tooltip, TooltipDirection } from './Tooltip';
import { FormattedTimestamp } from './FormattedTimestamp';
type SimpleListItem = {
  label: string;
  value: string;
  weight?: BodyStylingProps['weight'];
};
type TimestampListItem = {
  label: string;
  value: string;
  format: DateFormat;
  showTooltip?: boolean;
};
type TooltipListItem = {
  label: string;
  value: string;
  tooltip: string;
  tooltipDirection?: TooltipDirection;
  href?: string;
};
type LinkListItem = {
  label: string;
  value: string;
  href: string;
};
type BadgeListItem = {
  label: string;
  value: string;
  badgeColor: BadgeColor;
  badgeIcon?: IconType;
  tooltip?: string;
};
type CopyableListItem = {
  label: string;
  value: string;
  copyable: string;
};
type NodeListItem = {
  label: string;
  valueNode: ReactNode;
};
type EditableTextItem = {
  label: string;
  value: string;
  disabled?: boolean;
  onEdit: (newValue: string) => Promise<unknown>;
};
type EditableAmountItem = {
  label: string;
  value: number;
  disabled?: boolean;
  currency: Currency;
  minimum?: number;
  onEdit: (newValue: number) => Promise<unknown>;
};
type EditableOptionalAmountItem = {
  label: string;
  value: number | null;
  disabled?: boolean;
  optional: true;
  currency: Currency;
  minimum?: number;
  onEdit: (newValue: number) => Promise<unknown>;
  onRemove: () => Promise<unknown>;
};
export type SelectItem<T extends string> = {
  label: string;
  value: T;
  disabled?: boolean;
  sections: SelectSection<T>[];
  onSelect: (newValue: T) => Promise<unknown>;
};
type ToggleItem = {
  label: string;
  value: boolean;
  valueLabel?: string;
  labelDirection?: 'right' | 'left';
  disabled?: boolean;
  onToggle: (newValue: boolean) => Promise<unknown>;
  tooltip?: string;
};
export type ListItem = SimpleListItem | LinkListItem | CopyableListItem | NodeListItem | BadgeListItem | EditableTextItem | EditableAmountItem | EditableOptionalAmountItem | TimestampListItem
// eslint-disable-next-line @typescript-eslint/no-explicit-any
| SelectItem<any> | ToggleItem | TooltipListItem;
type PropertyListItemProps = ListItem & {};
const EditableTextListItem = (props: Omit<EditableTextItem, 'label'>) => {
  const [textToEdit, setTextToEdit] = useState(props.value);
  const [editing, setEditing] = useState(false);
  const [updating, setUpdating] = useState(false);
  const triggerSave = async () => {
    setUpdating(true);
    try {
      return await props.onEdit(textToEdit);
    } finally {
      setUpdating(false);
      setEditing(false);
    }
  };
  return <div className={classNames('flex w-full items-center space-x-2')} data-sentry-component="EditableTextListItem" data-sentry-source-file="PropertyList.tsx">
      {editing ? <TextInput value={textToEdit} onChange={e => setTextToEdit(e.target.value)} onKeyDown={e => {
      if (e.key === 'Enter') {
        return triggerSave();
      }
    }} autoFocus className="-my-1 focus:ring-0" /> : <Body color="primary" contents={props.value} />}
      {editing && <>
          <TextButton style="warning" size="small" icon="close" disabled={updating} onClick={() => {
        setEditing(false);
      }} />
          <TextButton style="default" size="small" icon="tick" disabled={updating || textToEdit === ''} onClick={() => {
        return triggerSave();
      }} />
        </>}
      {!editing && !props.disabled && <TextButton style="default" size="small" icon="edit" onClick={() => setEditing(true)} />}
    </div>;
};
const EditableAmountListItem = (props: Omit<EditableAmountItem, 'label'>) => {
  const [amountToEdit, setAmountToEdit] = useState<number | undefined>(props.value);
  const [editing, setEditing] = useState(false);
  const [updating, setUpdating] = useState(false);
  const triggerSave = async () => {
    if (amountToEdit === undefined) {
      return;
    }
    setUpdating(true);
    try {
      return await props.onEdit(amountToEdit);
    } finally {
      setUpdating(false);
      setEditing(false);
    }
  };
  return <div className={classNames('flex w-full items-center space-x-2')} data-sentry-component="EditableAmountListItem" data-sentry-source-file="PropertyList.tsx">
      {editing ? <AmountInput value={amountToEdit !== undefined ? amountToEdit / 100.0 : undefined} onValueChange={e => setAmountToEdit(e.floatValue !== undefined ? e.floatValue * 100 : undefined)} onKeyDown={e => {
      if (e.key === 'Enter') {
        return triggerSave();
      }
    }} autoFocus className="-my-1 focus:ring-0" disabled={props.disabled} /> : <Body color="primary" contents={formatAmount(props.value, props.currency)} />}
      {editing && <>
          <TextButton style="warning" size="small" icon="close" disabled={updating} onClick={() => {
        setEditing(false);
      }} />
          <TextButton style="default" size="small" icon="tick" disabled={updating || amountToEdit === undefined} onClick={() => {
        return triggerSave();
      }} />
        </>}
      {!editing && !props.disabled && <TextButton style="default" size="small" icon="edit" onClick={() => setEditing(true)} />}
    </div>;
};
const EditableOptionalAmountListItem = (props: Omit<EditableOptionalAmountItem, 'label'>) => {
  const [amountToEdit, setAmountToEdit] = useState<number | undefined>(props.value ?? 0);
  const [editing, setEditing] = useState(false);
  const [updating, setUpdating] = useState(false);
  const triggerSave = async () => {
    if (amountToEdit === undefined) {
      return;
    }
    setUpdating(true);
    try {
      return await props.onEdit(amountToEdit);
    } finally {
      setUpdating(false);
      setEditing(false);
    }
  };
  return <div className={'flex w-full items-center space-x-2'} data-sentry-component="EditableOptionalAmountListItem" data-sentry-source-file="PropertyList.tsx">
      {editing ? <AmountInput value={amountToEdit !== undefined ? amountToEdit / 100.0 : undefined} onValueChange={e => setAmountToEdit(e.floatValue !== undefined ? e.floatValue * 100 : undefined)} onKeyDown={e => {
      if (e.key === 'Enter') {
        return triggerSave();
      }
    }} autoFocus className="-my-1 focus:ring-0" disabled={props.disabled} /> : <Body color="primary" contents={props.value !== null ? formatAmount(props.value, props.currency) : 'Not set'} />}
      {editing && <>
          <TextButton style="warning" size="small" icon="close" disabled={updating} onClick={() => {
        setEditing(false);
      }} />
          <TextButton style="default" size="small" icon="tick" disabled={updating || amountToEdit === undefined} onClick={() => {
        return triggerSave();
      }} />
        </>}
      {!editing && !props.disabled && <TextButton style="default" size="small" icon="edit" onClick={() => setEditing(true)} />}
      {!editing && !props.disabled && props.value !== null && <TextButton style="warning" size="small" icon="trash" onClick={() => props.onRemove()} />}
    </div>;
};
const PropertyListItem = (props: PropertyListItemProps) => {
  const value = 'value' in props ? props.value : props.valueNode;
  let valueNode: ReactNode = null;
  if ('valueNode' in props) {
    valueNode = props.valueNode;
  } else if ('format' in props) {
    const actuallyShowTooltip = props.showTooltip ?? true;
    if (actuallyShowTooltip) {
      valueNode = <FormattedTimestamp timestamp={props.value} format={props.format} />;
    } else {
      valueNode = <Body color="primary">
          {formatISO8601Timestamp(props.value, props.format)}
        </Body>;
    }
  } else if ('href' in props && props.href) {
    valueNode = <Body color="primary">
        <StyledLink style="underline" href={props.href}>
          {props.value}
        </StyledLink>
      </Body>;
  } else if ('copyable' in props && props.copyable != null) {
    valueNode = <CopyButton valueToDisplay={value} valueToCopy={props.copyable} />;
  } else if ('badgeColor' in props && props.badgeColor) {
    valueNode = <div className={classNames('@md:mt-0', 'mt-1 flex items-center')}>
        <Badge color={props.badgeColor} icon={props.badgeIcon} label={props.value} size="medium" />
      </div>;
  } else if ('currency' in props && 'optional' in props) {
    valueNode = <EditableOptionalAmountListItem {...props} />;
  } else if ('currency' in props) {
    valueNode = <EditableAmountListItem {...props} />;
  } else if ('onEdit' in props && typeof props.value === 'string') {
    valueNode = <EditableTextListItem {...props} />;
  } else if ('sections' in props) {
    valueNode = <Select value={props.value} sections={props.sections} onUpdate={props.onSelect} disabled={props.disabled} />;
  } else if ('onToggle' in props) {
    valueNode = <Switch label={props.valueLabel} checked={props.value} onChange={props.onToggle} disabled={props.disabled} />;
  } else {
    const weight = 'weight' in props ? props.weight : 'normal';
    valueNode = <Body color="primary" weight={weight}>
        {props.value}
      </Body>;
  }
  if ('tooltip' in props && props.tooltip) {
    valueNode = <Box flex center gap="1">
        {valueNode}
        <Tooltip content={props.tooltip} direction={'tooltipDirection' in props ? props.tooltipDirection : 'top'} size="large">
          <Icon name="info" />
        </Tooltip>
      </Box>;
  }
  return <div className={classNames(':last-of-type:border-b-0 border-main flex flex-col flex-wrap items-start gap-x-2 border-t py-2.5', '@md:flex-row @md:flex-nowrap @md:py-2')} data-sentry-component="PropertyListItem" data-sentry-source-file="PropertyList.tsx">
      <div className="w-full shrink-0 basis-1/3">
        <div className="flex w-full items-center space-x-2">
          <Body color="secondary" weight="normal" className="grow" data-sentry-element="Body" data-sentry-source-file="PropertyList.tsx">
            {props.label}
          </Body>
        </div>
      </div>
      <div className="w-full">{valueNode}</div>
    </div>;
};
export type PropertyListProps = {
  title?: string;
  action?: ReactNode;
  items: ListItem[];
  className?: string;
  layoutHorizontallyWhenPossible?: boolean;
};
export const PropertyList = ({
  title,
  action,
  items,
  className
}: PropertyListProps) => {
  return <Box className={classNames('@container', className)} data-sentry-element="Box" data-sentry-component="PropertyList" data-sentry-source-file="PropertyList.tsx">
      {title && <div className="flex items-center justify-between space-x-3">
          <div className="grow">
            <Heading contents={title} />
          </div>
          <div className="flex shrink-0 items-center space-x-3">{action}</div>
        </div>}
      <div>
        {items.map((item, index) => <PropertyListItem key={index} {...item} />)}
      </div>
    </Box>;
};