import React, { useMemo } from 'react';
import { useUnusualActivityReportListInfinite } from 'src/hooks/reactQuery';
import { StyledLink } from 'src/components/deprecated/StyledLink';
import { ROUTES, buildPath } from 'src/lib/routes';
import { RequireEntitlements } from 'src/components/require-entitlements';
import { TableStateWrapper } from '@increase/shared/components/TableStateWrapper';
import { UnusualActivityReportsTable } from 'src/components/tables/UnusualActivityReportsTable';
import { compact } from 'lodash';
import { TableFilter } from '@increase/shared/components/Table/filters';
import { makeTimeRangeFilter, makeStatusFilter } from 'src/lib/tableFilterHelpers';
import { DateTime } from 'luxon';
import { useTypedCreatedAtRangeParams } from 'src/hooks/timeSearchParameters';
import { UnusualActivityReportListResponseDataItemIncreaseReviewStatus } from 'src/build/operations';
import { useSearchParamsState } from '@increase/shared/hooks/useTypedSearchParamsState';
const ListTab = () => {
  const [{
    startAt,
    endAt
  }, setTimeRange] = useTypedCreatedAtRangeParams(ROUTES.UNUSUAL_ACTIVITY_REPORTS.DIRECT);
  const [statusFilter, setStatusFilter] = useSearchParamsState(ROUTES.UNUSUAL_ACTIVITY_REPORTS.DIRECT, 'status');
  const startAtDateTime = startAt ? DateTime.fromISO(startAt, {
    zone: 'utc'
  }) : undefined;
  const endAtDateTime = endAt ? DateTime.fromISO(endAt, {
    zone: 'utc'
  }) : undefined;
  const filters: TableFilter[] = useMemo(() => compact([makeTimeRangeFilter({
    startAt,
    endAt
  }, setTimeRange, 'Created'), makeStatusFilter(statusFilter, setStatusFilter, UnusualActivityReportListResponseDataItemIncreaseReviewStatus)]), [endAt, setTimeRange, startAt, statusFilter, setStatusFilter]);
  const listResult = useUnusualActivityReportListInfinite({
    created_at_or_after: startAtDateTime?.startOf('day').toISO() ?? undefined,
    created_at_or_before: endAtDateTime?.endOf('day').toISO() ?? undefined,
    status: statusFilter.length > 0 ? statusFilter : []
  });
  return <TableStateWrapper table={UnusualActivityReportsTable} style="primary" {...listResult} emptyTitle="No unusual activity reports" emptySubtitle="There are no unusual activity reports to display" filters={filters} action={<RequireEntitlements entitlements={['run_manual_task']}>
          <StyledLink to={buildPath(ROUTES.MANUAL_TASK, {
      taskName: 'Operations::ManualTask::CreateUnusualActivityReport'
    })}>
            New Report
          </StyledLink>
        </RequireEntitlements>} data-sentry-element="TableStateWrapper" data-sentry-component="ListTab" data-sentry-source-file="UnusualActivityReportsListTab.tsx" />;
};
export const UnusualActivityReportsListTab = () => {
  return <RequireEntitlements entitlements={['unusual_activity_report_read']} data-sentry-element="RequireEntitlements" data-sentry-component="UnusualActivityReportsListTab" data-sentry-source-file="UnusualActivityReportsListTab.tsx">
      <ListTab data-sentry-element="ListTab" data-sentry-source-file="UnusualActivityReportsListTab.tsx" />
    </RequireEntitlements>;
};