import { compact } from 'lodash';
import { ListItem, PropertyList, PropertyListProps } from '@increase/shared/components/PropertyList';
import { formatAmount } from '@increase/shared/lib/formatting';
import { TransactionGetResponse } from 'src/build/operations';
import { useGroupPropertyRow, useAccountPropertyRow } from 'src/lib/propertyListHelpers';
import { ROUTES, buildPath } from 'src/lib/routes';
type TransactionPropertyListProps = {
  transaction: TransactionGetResponse;
} & Omit<PropertyListProps, 'items'>;
export const TransactionPropertyList = (props: TransactionPropertyListProps) => {
  const {
    transaction,
    ...rest
  } = props;
  const groupRow = useGroupPropertyRow(transaction.group_id);
  const accountRow = useAccountPropertyRow(transaction.account_id);
  const dashboardUrl = `https://dashboard.increase.com/transactions/${transaction.id}`;
  return <PropertyList {...rest} items={compact<ListItem>([{
    label: 'ID',
    value: transaction.id,
    copyable: transaction.id
  }, {
    label: 'Type',
    value: transaction.source_type.replaceAll('API::', '')
  }, {
    label: 'Amount',
    value: formatAmount(transaction.amount, transaction.currency)
  }, {
    label: 'Sign',
    value: transaction.amount > 0 ? 'Positive. Account balance increases.' : 'Negative. Account balance decreases.'
  }, {
    label: 'Date',
    value: transaction.date,
    format: 'month-day-year-hour-minute-second'
  }, {
    label: 'Created at',
    value: transaction.created_at,
    format: 'month-day-year-hour-minute-second'
  }, groupRow, accountRow, transaction.route && transaction.route.type === 'card' && {
    label: 'Card',
    value: transaction.route.description || transaction.route.id,
    href: buildPath(ROUTES.CARD, {
      cardID: transaction.route.id
    })
  }, transaction.route && transaction.route.type === 'account_number' && {
    label: 'Account Number',
    value: transaction.route.description || transaction.route.id,
    href: buildPath(ROUTES.ACCOUNT_NUMBERS_DETAILS, {
      accountNumberId: transaction.route.id
    })
  }, {
    label: 'Dashboard URL',
    value: 'Copy',
    copyable: dashboardUrl
  }])} data-sentry-element="PropertyList" data-sentry-component="TransactionPropertyList" data-sentry-source-file="TransactionPropertyList.tsx" />;
};