import { absurd } from 'shared/lib/absurd';
import {
  ACHTransferGetResponse,
  ACHTransferGetResponseAchNotificationsOfChangeItemChangeCode,
  ACHTransferGetResponseReturnAnyOfReturnReasonCode,
  ACHTransferGetResponseSubmissionAnyOfExpectedSettlementSchedule,
  OperatorGetResponse,
} from 'src/build/operations';

import { ACHTransferGetResponseReviewAnyOf } from 'src/build/operations';

export const canReviewTransfer = (
  operator: OperatorGetResponse,
  transferReview: ACHTransferGetResponseReviewAnyOf
): boolean => {
  if (!operator.entitlements.includes('partner_program_write')) {
    return false;
  }

  switch (transferReview.reviewer) {
    case 'increase':
      return operator.bank === null;
    case 'bank':
      return operator.bank === transferReview.bank;
    default:
      absurd(transferReview.reviewer);
  }
  throw new Error('Unreachable');
};

export function formatACHReturnReasonCode(
  source: NonNullable<ACHTransferGetResponseReturnAnyOfReturnReasonCode>
): string {
  switch (source) {
    case 'insufficient_fund':
      return 'Insufficient Funds';
    case 'no_account':
      return 'No such account';
    case 'account_closed':
      return 'Account closed';
    case 'invalid_account_number_structure':
      return 'Invalid account number';
    case 'account_frozen_entry_returned_per_ofac_instruction':
      return 'Account frozen';
    case 'credit_entry_refused_by_receiver':
      return 'Credit refused by receiver';
    case 'unauthorized_debit_to_consumer_account_using_corporate_sec_code':
      return 'Unauthorized debit to consumer account';
    case 'corporate_customer_advised_not_authorized':
      return 'Corporate customer advised not authorized';
    case 'payment_stopped':
      return 'Payment stopped';
    case 'non_transaction_account':
      return 'Non-transaction account';
    case 'uncollected_funds':
      return 'Uncollected funds';
    case 'routing_number_check_digit_error':
      return 'Invalid routing number';
    case 'customer_advised_unauthorized_improper_ineligible_or_incomplete':
      return 'Unauthorized';
    case 'amount_field_error':
      return 'Amount field error';
    case 'authorization_revoked_by_customer':
      return 'Authorization revoked by customer';
    case 'invalid_ach_routing_number':
      return 'Invalid routing number';
    case 'file_record_edit_criteria':
      return 'File record edit criteria';
    case 'enr_invalid_individual_name':
      return 'Invalid individual name';
    case 'returned_per_odfi_request':
      return 'Returned per ODFI request';
    case 'addenda_error':
      return 'Addenda error';
    case 'limited_participation_dfi':
      return 'Limited participation DFI';
    case 'incorrectly_coded_outbound_international_payment':
      return 'Incorrectly coded outbound international payment';
    default:
      return 'other';
  }
}

export function formatACHTransferFunding(
  achTransfer: ACHTransferGetResponse
): string {
  switch (achTransfer.funding) {
    case 'checking':
      return 'Checking Account';
    case 'savings':
      return 'Savings Account';
    default:
      return absurd(achTransfer.funding);
  }
}

export function formatACHTransferNotificationOfChangeCode(
  code: ACHTransferGetResponseAchNotificationsOfChangeItemChangeCode
): string {
  switch (code) {
    case 'incorrect_account_number':
      return 'Incorrect account number';
    case 'incorrect_routing_number':
      return 'Incorrect routing number';
    case 'incorrect_routing_number_and_account_number':
      return 'Incorrect routing number and account number';
    case 'incorrect_transaction_code':
      return 'Incorrect transaction code';
    case 'incorrect_account_number_and_transaction_code':
      return 'Incorrect account number and transaction_code';
    case 'incorrect_routing_number_account_number_and_transaction_code':
      return 'Incorrect routing number account number and transaction code';
    case 'incorrect_receiving_depository_financial_institution_identification':
      return 'Incorrect receiving depository financial institution identification';
    case 'incorrect_individual_identification_number':
      return 'Incorrect individual identification number';
    case 'addenda_format_error':
      return 'Addenda format error';
    case 'incorrect_standard_entry_class_code_for_outbound_international_payment':
      return 'Incorrect standard entry class code for outbound international payment';
    case 'misrouted_notification_of_change':
      return 'Misrouted notification of change';
    case 'incorrect_trace_number':
      return 'Incorrect trace number';
    case 'incorrect_company_identification_number':
      return 'Incorrect company identification number';
    case 'incorrect_identification_number':
      return 'Incorrect identification number';
    case 'incorrectly_formatted_corrected_data':
      return 'Incorrectly formatted corrected data';
    case 'incorrect_discretionary_data':
      return 'Incorrect discretionary data';
    case 'routing_number_not_from_original_entry_detail_record':
      return 'Routing number not from original entry detail record';
    case 'depository_financial_institution_account_number_not_from_original_entry_detail_record':
      return 'Depository financial institution account number not from original entry detail record';
    case 'incorrect_transaction_code_by_originating_depository_financial_institution':
      return 'Incorrect transaction code by originating depository financial institution';
    default:
      return absurd(code);
  }
}

export function formatACHTransferStandardEntryClassCode(
  achTransfer: ACHTransferGetResponse
): string {
  switch (achTransfer.standard_entry_class_code) {
    case 'corporate_credit_or_debit':
      return 'Corporate Credit or Debit (CCD)';
    case 'corporate_trade_exchange':
      return 'Corporate Trade Exchange (CTX)';
    case 'internet_initiated':
      return 'Internet Initiated (WEB)';
    case 'prearranged_payments_and_deposit':
      return 'Prearranged Payments and Deposit (PPD)';
    default:
      return absurd(achTransfer.standard_entry_class_code);
  }
}

export function formatACHTransferSubmissionExpectedSettlementSchedule(
  expectedSettlementSchedule: ACHTransferGetResponseSubmissionAnyOfExpectedSettlementSchedule
): string {
  switch (expectedSettlementSchedule) {
    case 'same_day':
      return 'Same Day';
    case 'future_dated':
      return 'Future Dated';
    default:
      return absurd(expectedSettlementSchedule);
  }
}
