import { useMemo } from 'react';
import { PrestyledTableProps, Table, TableColumns } from '@increase/shared/components/Table';
import { formatISO8601Timestamp, humanize } from '@increase/shared/lib/formatting';
import { PlatformVendorListResponseDataItem } from 'src/build/operations';
import { buildPath, ROUTES } from 'src/lib/routes';
type PlatformVendorTableProps = PrestyledTableProps<PlatformVendorListResponseDataItem>;
export const PlatformVendorsTable = (props: PlatformVendorTableProps) => {
  const {
    getRowProps,
    ...rest
  } = props;
  const columns = useMemo<TableColumns<PlatformVendorListResponseDataItem>>(() => {
    return [{
      header: 'Group',
      contents: vendor => {
        return {
          text: vendor.group_id,
          href: buildPath(ROUTES.GROUPS_DETAIL, {
            groupId: vendor.group_id
          })
        };
      }
    }, {
      header: 'Vendor',
      expand: 1,
      contents: vendor => {
        return {
          text: vendor.name,
          textColor: 'primary',
          textWeight: 'medium'
        };
      }
    }, {
      header: 'Website',
      contents: vendor => ({
        text: vendor.website,
        href: vendor.website
      })
    }, {
      header: 'Risk Level',
      contents: vendor => ({
        text: humanize(vendor.risk_level)
      })
    }, {
      header: 'Diligence status',
      contents: vendor => ({
        text: vendor.removed_on ? 'Removed' : humanize(vendor.diligence_review_result)
      })
    }, {
      header: 'Added on',
      contents: vendor => ({
        text: formatISO8601Timestamp(vendor.added_on, 'date', 'UTC')
      })
    }, {
      header: 'Last reviewed on',
      align: 'right',
      contents: vendor => ({
        text: formatISO8601Timestamp(vendor.last_reviewed_on, 'date', 'UTC')
      })
    }];
  }, []);
  return <Table {...rest} getRowProps={getRowProps} columns={columns} data-sentry-element="Table" data-sentry-component="PlatformVendorsTable" data-sentry-source-file="PlatformVendorsTable.tsx" />;
};