import { Annotation, ImageDataView } from './image-data-view';
import { ForwardedRef, forwardRef, useCallback, useEffect, useRef, useState } from 'react';
import { useKeyPressEvent } from 'react-use';
import { TextInput } from 'shared/components/TextInput';
import { useMagneticInkCharacterExtractionPost } from 'src/hooks/reactQuery';
import { getBlob } from 'src/lib/image-utility';
import { Button } from 'shared/components/Button';
import { MagneticInkCharacterExtractionPostResponseMagneticInkCharacterLineAnyOf } from 'src/build/operations';
import _ from 'lodash';
import { AlertList } from 'shared/components/AlertList';
import { must } from 'shared/lib/must';
import { Body, Heading } from 'shared/components/Text';
export type MICRResult = {
  accountNumber: string;
  routingNumber: string;
  auxiliaryOnUs?: string;
  serialNumber?: string;
};
type Props = {
  recordID: string;
  micrPixels: ImageData;
  micrResult: MICRResult | null;
  onUpdate: (result: MICRResult) => void;
};
const annotationsFromBackendOCR = (line: MagneticInkCharacterExtractionPostResponseMagneticInkCharacterLineAnyOf): Annotation[] => {
  return _.compact([{
    id: 'routingNumber',
    text: line.routing_number.text,
    boundingPolygon: line.routing_number.bounding_polygon
  }, {
    id: 'accountNumber',
    text: line.account_number.text,
    boundingPolygon: line.account_number.bounding_polygon
  }, line.auxiliary_on_us && {
    id: 'auxiliaryOnUs',
    text: line.auxiliary_on_us.text,
    boundingPolygon: line.auxiliary_on_us.bounding_polygon
  }, line.serial_number && {
    id: 'serialNumber',
    text: line.serial_number.text,
    boundingPolygon: line.serial_number.bounding_polygon
  }]);
};
const annotationById = (line: MagneticInkCharacterExtractionPostResponseMagneticInkCharacterLineAnyOf, id: string): Annotation | undefined => {
  return annotationsFromBackendOCR(line).find(a => a.id === id);
};
const pixelsForAnnotation = (micrPixels: ImageData, annotation: Annotation): ImageData => {
  const canvas = document.createElement('canvas');
  canvas.width = micrPixels.width;
  canvas.height = micrPixels.height;
  const upperLeftX = Math.min(...annotation.boundingPolygon.map(p => p.x));
  const upperLeftY = Math.min(...annotation.boundingPolygon.map(p => p.y));
  const bottomRightX = Math.max(...annotation.boundingPolygon.map(p => p.x));
  const bottomRightY = Math.max(...annotation.boundingPolygon.map(p => p.y));
  const context = must(canvas.getContext('2d'));
  context.putImageData(micrPixels, 0, 0);
  return context.getImageData(upperLeftX, upperLeftY, bottomRightX - upperLeftX, bottomRightY - upperLeftY);
};
const Field = forwardRef(function Field({
  id,
  value,
  setValue,
  placeholder,
  wrapperClassName,
  setEditingAnnotationID,
  disabled
}: {
  id: string;
  value: string;
  setValue: (value: string) => void;
  placeholder: string;
  wrapperClassName: string;
  setEditingAnnotationID: (id: string | null) => void;
  disabled: boolean;
}, ref: ForwardedRef<HTMLDivElement>) {
  return <div className={wrapperClassName} ref={ref} onFocus={() => setEditingAnnotationID(id)}>
      <TextInput disabled={disabled} name={id} value={value} onChange={e => setValue(e.target.value)} placeholder={placeholder} padding="small" />
    </div>;
});
const OCRMatchPreview = ({
  pixels,
  postionTo
}: {
  pixels: ImageData;
  postionTo: HTMLDivElement;
}) => {
  const nudge = 4;
  const [offsetLeft, setOffsetLeft] = useState<number | null>(null);
  const ref = useCallback((node: HTMLDivElement | null) => {
    if (!node) {
      return;
    }
    const us = node.getBoundingClientRect();
    const them = postionTo.getBoundingClientRect();
    setOffsetLeft(them.left - us.left + nudge);
  }, [postionTo]);
  return <div ref={ref} data-sentry-component="OCRMatchPreview" data-sentry-source-file="transcribe-micr.tsx">
      {offsetLeft !== null && <div style={{
      position: 'relative',
      left: offsetLeft || 0
    }}>
          <ImageDataView pixels={pixels} maxHeight={12.5} />
        </div>}
    </div>;
};
export const TranscribeMICR = ({
  recordID,
  micrPixels,
  micrResult,
  onUpdate
}: Props) => {
  const [auxiliaryOnUs, setAuxiliaryOnUs] = useState(micrResult?.auxiliaryOnUs ?? '');
  const [routingNumber, setRoutingNumber] = useState(micrResult?.routingNumber ?? '');
  const [accountNumber, setAccountNumber] = useState(micrResult?.accountNumber ?? '');
  const [serialNumber, setSerialNumber] = useState(micrResult?.serialNumber ?? '');
  const [editingAnnotationID, setEditingAnnotationID] = useState<string | null>(null);
  const [selectedAnnotationID, setSelectedAnnotationID] = useState<string | null>(null);
  const fields = [{
    id: 'auxiliaryOnUs',
    value: auxiliaryOnUs,
    setValue: setAuxiliaryOnUs,
    placeholder: 'Auxiliary on-us',
    wrapperClassName: 'w-[15em]',
    ref: useRef<HTMLDivElement>(null)
  }, {
    id: 'routingNumber',
    value: routingNumber,
    setValue: setRoutingNumber,
    placeholder: 'Routing number',
    wrapperClassName: 'w-[7.5em]',
    ref: useRef<HTMLDivElement>(null)
  }, {
    id: 'accountNumber',
    value: accountNumber,
    setValue: setAccountNumber,
    placeholder: 'Account number',
    wrapperClassName: 'w-[15em]',
    ref: useRef<HTMLDivElement>(null)
  }, {
    id: 'serialNumber',
    value: serialNumber,
    setValue: setSerialNumber,
    placeholder: 'Serial',
    wrapperClassName: 'w-[6em]',
    ref: useRef<HTMLDivElement>(null)
  }];
  const firstFormField = fields[0].ref;
  const backendOCR = useMagneticInkCharacterExtractionPost();
  const backendOCRMutate = backendOCR.mutateAsync;
  const doBackendOCR = useCallback(async () => {
    const result = await backendOCRMutate([{
      file: await getBlob(micrPixels),
      record_id: recordID
    }]);
    if (result.magnetic_ink_character_line) {
      if (result.magnetic_ink_character_line.auxiliary_on_us) {
        setAuxiliaryOnUs(result.magnetic_ink_character_line.auxiliary_on_us.text);
      }
      setRoutingNumber(result.magnetic_ink_character_line.routing_number.text);
      setAccountNumber(result.magnetic_ink_character_line.account_number.text);
      if (result.magnetic_ink_character_line.serial_number) {
        setSerialNumber(result.magnetic_ink_character_line.serial_number.text);
      }
      setTimeout(() => {
        const input = firstFormField.current?.getElementsByTagName('input')[0];
        input?.focus();
        input?.setSelectionRange(0, result.magnetic_ink_character_line?.auxiliary_on_us?.text?.length ?? 0);
      }, 0);
    }
  }, [backendOCRMutate, micrPixels, recordID, firstFormField]);
  useEffect(() => {
    doBackendOCR();
  }, [doBackendOCR]);
  useKeyPressEvent('Enter', () => {
    onUpdate({
      accountNumber,
      routingNumber,
      auxiliaryOnUs,
      serialNumber
    });
  });
  const setSelectedAnnotationIDFromHover = useCallback((annotationID: string | null) => setSelectedAnnotationID(annotationID || editingAnnotationID), [setSelectedAnnotationID, editingAnnotationID]);
  const selectedAnnotation = backendOCR.data?.magnetic_ink_character_line && selectedAnnotationID && annotationById(backendOCR.data.magnetic_ink_character_line, selectedAnnotationID);
  const selectedField = fields.find(f => f.id === selectedAnnotationID);
  const errorMessage = backendOCR.error?.message || backendOCR.data?.error;
  return <div className="flex flex-col space-y-2" data-sentry-component="TranscribeMICR" data-sentry-source-file="transcribe-micr.tsx">
      <ImageDataView pixels={micrPixels} maxWidth={800} annotations={backendOCR.data?.magnetic_ink_character_line ? annotationsFromBackendOCR(backendOCR.data.magnetic_ink_character_line) : []} selectedAnnotationID={selectedAnnotationID} onHoveredAnnotationChange={setSelectedAnnotationIDFromHover} data-sentry-element="ImageDataView" data-sentry-source-file="transcribe-micr.tsx" />
      <div className="flex items-center space-x-2" style={{
      fontFamily: "'MICR', var(--font-sans)",
      fontSizeAdjust: 'from-font'
    }} onBlur={() => setEditingAnnotationID(null)}>
        ⑈
        <Field {...fields[0]} setEditingAnnotationID={setEditingAnnotationID} disabled={backendOCR.isLoading} data-sentry-element="Field" data-sentry-source-file="transcribe-micr.tsx" />
        {'⑈ ⑆'}
        <Field {...fields[1]} setEditingAnnotationID={setEditingAnnotationID} disabled={backendOCR.isLoading} data-sentry-element="Field" data-sentry-source-file="transcribe-micr.tsx" />
        ⑆
        <Field {...fields[2]} setEditingAnnotationID={setEditingAnnotationID} disabled={backendOCR.isLoading} data-sentry-element="Field" data-sentry-source-file="transcribe-micr.tsx" />
        ⑈
        <Field {...fields[3]} setEditingAnnotationID={setEditingAnnotationID} disabled={backendOCR.isLoading} data-sentry-element="Field" data-sentry-source-file="transcribe-micr.tsx" />
      </div>

      {backendOCR.data?.magnetic_ink_character_line && selectedAnnotation && selectedField && selectedField.ref.current && <OCRMatchPreview pixels={pixelsForAnnotation(micrPixels, selectedAnnotation)} postionTo={selectedField.ref.current} />}

      <div>
        {!backendOCR.data && <Button size="small" text={backendOCR.isLoading ? 'OCR Loading...' : 'Try OCR'} onClick={doBackendOCR} disabled={backendOCR.isLoading} />}
        {errorMessage && <div className="mt-2 flex-col space-y-4">
            <AlertList tasks={[{
          icon: 'info',
          title: 'Error with OCR',
          body: errorMessage,
          key: '0',
          style: 'error'
        }]} />

            {backendOCR.data && <div>
                <Heading size="small" contents="Raw OCR Results" />
                <div className="flex space-x-2 select-text">
                  <div>
                    {backendOCR.data.raw_annotations.map((r, i) => <Body key={r.text + '-' + i} className="bg-stronger inline-block rounded p-1" weight="mono">
                        {r.text}
                      </Body>)}
                  </div>
                </div>
              </div>}
          </div>}
      </div>
    </div>;
};