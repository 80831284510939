import { useMemo } from 'react';
import { PrestyledTableProps, Table, TableColumns } from '@increase/shared/components/Table';
import { formatAmount, formatISO8601Timestamp } from '@increase/shared/lib/formatting';
import { CompletedTransactionListResponseDataItem } from 'src/build/operations';
import { buildPath, ROUTES } from 'src/lib/routes';
export type CompletedTransactionsTableProps = PrestyledTableProps<CompletedTransactionListResponseDataItem> & {
  showAccount?: boolean;
  showGroup?: boolean;
  showRoute?: boolean;
};
const defaultRowProps = (datum: CompletedTransactionListResponseDataItem) => ({
  href: datum.is_declined_transaction ? buildPath(ROUTES.DECLINED_TRANSACTION_DETAIL, {
    declinedTransactionID: datum.id
  }) : buildPath(ROUTES.TRANSACTION_DETAIL, {
    transactionID: datum.id
  }),
  className: 'hover:bg-main-hover cursor-pointer transition-all group'
});
const hrefForRoute = (txn: CompletedTransactionListResponseDataItem): string | undefined => {
  if (!txn.route) {
    return undefined;
  }
  if (txn.route.type === 'account_number') {
    return buildPath(ROUTES.ACCOUNT_NUMBERS_DETAILS, {
      accountNumberId: txn.route.id
    });
  }
  if (txn.route.type === 'card') {
    return buildPath(ROUTES.CARD, {
      cardID: txn.route.id
    });
  }
  return undefined;
};
export const CompletedTransactionsTable = (props: CompletedTransactionsTableProps) => {
  const {
    getRowProps
  } = props;
  const columns = useMemo<TableColumns<CompletedTransactionListResponseDataItem>>(() => {
    return [{
      header: 'Created',
      mobileHeader: 'Details',
      contents: transaction => ({
        statusIndicator: transaction.is_declined_transaction ? 'red' : 'blue',
        text: formatISO8601Timestamp(transaction.date, 'month-day-year-hour-minute-second', 'UTC')
      }),
      mobileContents: transaction => ({
        text: transaction.description,
        textWeight: 'medium',
        textColor: 'emphasis',
        caption: transaction.source_type.replaceAll('API::', '')
      })
    }, {
      header: 'Description',
      expand: 1,
      contents: transaction => ({
        text: transaction.description,
        textWeight: 'medium',
        textColor: 'emphasis'
      })
    }, {
      header: 'Category',
      expand: 1,
      contents: transaction => ({
        text: transaction.source_type.replaceAll('API::', '')
      })
    }, props.showGroup && {
      header: 'Group',
      expand: 1,
      hideBelowTableWidth: 'md',
      contents: transaction => {
        return {
          text: transaction.group_name || transaction.group_id,
          href: buildPath(ROUTES.GROUPS_DETAIL, {
            groupId: transaction.group_id
          })
        };
      }
    }, props.showAccount && {
      header: 'Account',
      expand: 1,
      hideBelowTableWidth: 'lg',
      contents: transaction => ({
        text: transaction.account_id,
        href: buildPath(ROUTES.ACCOUNTS_DETAIL, {
          accountId: transaction.account_id
        })
      })
    }, props.showRoute && {
      header: 'Route',
      expand: 1,
      hideBelowTableWidth: 'xl',
      contents: transaction => ({
        text: transaction.route?.id ?? '',
        href: hrefForRoute(transaction)
      })
    }, {
      header: 'Amount',
      align: 'right',
      contents: transaction => {
        return {
          text: formatAmount(transaction.amount, 'USD'),
          textColor: transaction.is_declined_transaction ? 'secondary' : 'emphasis',
          textWeight: 'bold',
          strikeThrough: transaction.is_declined_transaction
        };
      },
      mobileContents: transaction => {
        return {
          text: formatAmount(transaction.amount, 'USD'),
          textColor: transaction.is_declined_transaction ? 'secondary' : 'emphasis',
          textWeight: 'bold',
          strikeThrough: transaction.is_declined_transaction,
          caption: formatISO8601Timestamp(transaction.date, 'month-day-year-hour-minute-second', 'UTC')
        };
      }
    }];
  }, [props.showAccount, props.showGroup, props.showRoute]);
  return <Table {...props} getRowProps={getRowProps ?? defaultRowProps} columns={columns} data-sentry-element="Table" data-sentry-component="CompletedTransactionsTable" data-sentry-source-file="CompletedTransactionTable.tsx" />;
};