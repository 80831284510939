import { useCallback, useEffect, useState } from 'react';
import { getPixels, loadImages, getMouseLocationInCanvas } from '../../lib/image-utility';
import { useKeyPressEvent } from 'react-use';
import { Button } from '@increase/shared/components/Button';
import { Body } from '@increase/shared/components/Text';
import { Box } from '@increase/shared/components/Box';
import { ErrorMessage } from 'shared/components/ErrorMessage';
import { StyledLink } from 'shared/components/StyledLink';
export type Point = {
  x: number;
  y: number;
};
const smallRotateStep = 0.25;
type SharedProps = {
  minimumWidth?: number;
  minimumHeight?: number;
  size?: number;
} & ({
  selectionMode: 'none';
} | {
  selectionMode: 'area';
  onPixelsExtracted: (pixels: ImageData) => void;
  initialBoundsInImageSpace?: Rect;
} | {
  selectionMode: 'point';
  onPointSelected: (point: Point) => void;
  onHoveredPointChanged: (point: Point) => void;
});
type Props = {
  url: string;
} & SharedProps;
type Rect = {
  top: number;
  left: number;
  width: number;
  height: number;
};
function drawGrid(ctx: CanvasRenderingContext2D, width: number, height: number, spacing: number) {
  ctx.strokeStyle = 'rgba(200,100,100,0.4)';
  ctx.beginPath();
  for (let x = 0; x < width; x += spacing) {
    ctx.moveTo(x, 0);
    ctx.lineTo(x, height);
  }
  for (let y = 0; y < height; y += spacing) {
    ctx.moveTo(0, y);
    ctx.lineTo(width, y);
  }
  ctx.stroke();
}
function boundsFrom(a: Point, b: Point) {
  return {
    left: Math.min(a.x, b.x),
    width: Math.abs(a.x - b.x),
    top: Math.min(a.y, b.y),
    height: Math.abs(a.y - b.y)
  };
}
function drawBounds(ctx: CanvasRenderingContext2D, canvas: HTMLCanvasElement, start: Point, end: Point | null) {
  ctx.save();
  if (!end) {
    return;
  }
  const bounds = boundsFrom(start, end);
  ctx.beginPath();
  ctx.rect(0, 0, canvas.width, canvas.height);
  ctx.rect(bounds.left, bounds.top, bounds.width, bounds.height);
  ctx.fillStyle = 'rgba(128,128,128,0.6)';
  ctx.fill('evenodd');
  ctx.restore();
}
function drawBoundsStartHint(ctx: CanvasRenderingContext2D, boundsStart: Point, mouseLocation: Point) {
  ctx.save();
  ctx.fillStyle = 'red';
  ctx.fillRect(boundsStart.x - 2, boundsStart.y - 2, 4, 4);
  ctx.beginPath();
  ctx.moveTo(boundsStart.x, mouseLocation.y);
  ctx.lineTo(boundsStart.x, boundsStart.y);
  ctx.lineTo(mouseLocation.x, boundsStart.y);
  ctx.strokeStyle = 'red';
  ctx.stroke();
  ctx.restore();
}
function drawCursor(ctx: CanvasRenderingContext2D, canvas: HTMLCanvasElement, position: Point | null) {
  if (!position) {
    return;
  }
  ctx.fillStyle = 'red';
  ctx.fillRect(position.x - 2, position.y - 2, 4, 4);
  ctx.fillRect(0, position.y, canvas.width, 1);
  ctx.fillRect(position.x, 0, 1, canvas.height);
}
type InnerProps = {
  imageSource: CanvasImageSource & {
    width: number;
    height: number;
  };
} & SharedProps;
const imageTransform = ({
  imageHeight,
  imageWidth,
  canvasWidth,
  canvasHeight,
  rotation
}: {
  imageWidth: number;
  imageHeight: number;
  canvasWidth: number;
  canvasHeight: number;
  rotation: number;
}): DOMMatrixReadOnly => {
  const padding = 20;
  let matrix = new DOMMatrixReadOnly();
  matrix = matrix.translate(canvasWidth / 2, padding);
  if (imageWidth > imageHeight) {
    matrix = matrix.scale((canvasWidth - padding * 2) / imageWidth);
  } else {
    matrix = matrix.scale((canvasHeight - padding * 2) / imageHeight);
  }
  const translateToFitRotationDegrees = Math.round(rotation / 15) * 15;
  const translateToFitRotationRadians = Math.PI / 180 * translateToFitRotationDegrees;
  matrix = matrix.translate(0, imageHeight / 2 * Math.abs(Math.cos(translateToFitRotationRadians)) + imageWidth / 2 * Math.abs(Math.sin(translateToFitRotationRadians)));
  matrix = matrix.rotate(rotation);
  matrix = matrix.translate(-imageWidth / 2, -imageHeight / 2);
  return matrix;
};
const imageCornersInCanvasSpace = (imageTransform: DOMMatrixReadOnly, imageWidth: number, imageHeight: number): {
  topLeft: DOMPoint;
  topRight: DOMPoint;
  bottomRight: DOMPoint;
  bottomLeft: DOMPoint;
} => {
  return {
    topLeft: imageTransform.transformPoint(new DOMPoint(0, 0)),
    topRight: imageTransform.transformPoint(new DOMPoint(imageWidth, 0)),
    bottomRight: imageTransform.transformPoint(new DOMPoint(imageWidth, imageHeight)),
    bottomLeft: imageTransform.transformPoint(new DOMPoint(0, imageHeight))
  };
};
const imageBoundsInCanvasSpace = (imageTransform: DOMMatrixReadOnly, imageWidth: number, imageHeight: number): Rect => {
  const corners = imageCornersInCanvasSpace(imageTransform, imageWidth, imageHeight);
  return boundsFrom(corners.topLeft, corners.bottomRight);
};
const imageExtentsInCanvasSpace = (imageTransform: DOMMatrixReadOnly, imageWidth: number, imageHeight: number): {
  minX: number;
  minY: number;
  maxX: number;
  maxY: number;
} => {
  const corners = imageCornersInCanvasSpace(imageTransform, imageWidth, imageHeight);
  return {
    minX: Math.min(corners.topLeft.x, corners.topRight.x, corners.bottomRight.x, corners.bottomLeft.x),
    minY: Math.min(corners.topLeft.y, corners.topRight.y, corners.bottomRight.y, corners.bottomLeft.y),
    maxX: Math.max(corners.topLeft.x, corners.topRight.x, corners.bottomRight.x, corners.bottomLeft.x),
    maxY: Math.max(corners.topLeft.y, corners.topRight.y, corners.bottomRight.y, corners.bottomLeft.y)
  };
};
export const ImageToolInner = (props: InnerProps) => {
  const {
    selectionMode,
    minimumWidth,
    minimumHeight,
    imageSource,
    size = 800
  } = props;
  const [rotation, setRotation] = useState(0);
  const [mouseLocation, setMouseLocation] = useState<Point | null>(null);
  const [boundsStart, setBoundsStart] = useState<Point | null>(null);
  const [boundsEnd, setBoundsEnd] = useState<Point | null>(null);
  const [mouseDown, setMouseDown] = useState(false);
  const [canvas, setCanvas] = useState<HTMLCanvasElement | null>(null);
  const [initialBoundsSet, setInitialBoundsSet] = useState(false);
  let initialBoundsInImageSpace = null;
  if (props.selectionMode === 'area' && props.initialBoundsInImageSpace) {
    initialBoundsInImageSpace = props.initialBoundsInImageSpace;
  }
  const canvasRef = useCallback((canvasNode: HTMLCanvasElement | null) => {
    if (canvasNode) {
      setCanvas(canvasNode);
      const ctx = canvasNode.getContext('2d');
      if (ctx) {
        ctx.clearRect(0, 0, canvasNode.width, canvasNode.height);
        ctx.fillStyle = 'rgba(0,0,0,0.1)';
        ctx.fillRect(0, 0, canvasNode.width, canvasNode.height);
        const transform = imageTransform({
          imageWidth: imageSource.width,
          imageHeight: imageSource.height,
          canvasWidth: canvasNode.width,
          canvasHeight: canvasNode.height,
          rotation
        });
        ctx.save();
        ctx.setTransform(transform);
        ctx.drawImage(imageSource, 0, 0, imageSource.width, imageSource.height);
        ctx.restore();
        if (selectionMode === 'area') {
          if (initialBoundsInImageSpace && !initialBoundsSet) {
            const initialStartInCanvasSpace = transform.transformPoint(new DOMPoint(initialBoundsInImageSpace.left, initialBoundsInImageSpace.top));
            const initialEndInCanvasSpace = transform.transformPoint(new DOMPoint(initialBoundsInImageSpace.left + initialBoundsInImageSpace.width, initialBoundsInImageSpace.top + initialBoundsInImageSpace.height));
            setBoundsStart(initialStartInCanvasSpace);
            setBoundsEnd(initialEndInCanvasSpace);
            setInitialBoundsSet(true);
          }
          drawGrid(ctx, canvasNode.width, canvasNode.height, 20);
          drawCursor(ctx, canvasNode, mouseLocation);
          if (boundsStart) {
            drawBounds(ctx, canvasNode, boundsStart, boundsEnd);
            if (!boundsEnd && mouseLocation) {
              drawBoundsStartHint(ctx, boundsStart, mouseLocation);
            }
          }
        }
      }
    }
  }, [boundsStart, boundsEnd, imageSource, rotation, mouseLocation, selectionMode, initialBoundsInImageSpace, initialBoundsSet]);
  const selectThird = (which: 1 | 2 | 3) => {
    if (!canvas) {
      return;
    }
    const transform = imageTransform({
      imageWidth: imageSource.width,
      imageHeight: imageSource.height,
      canvasWidth: canvas.width,
      canvasHeight: canvas.height,
      rotation
    });
    const extents = imageExtentsInCanvasSpace(transform, imageSource.width, imageSource.height);
    const height = extents.maxY - extents.minY;
    const aThird = height / 3;
    setBoundsStart({
      x: extents.minX,
      y: extents.minY + aThird * (which - 1)
    });
    setBoundsEnd({
      x: extents.maxX,
      y: extents.minY + aThird * which
    });
  };
  const selectTopHalf = () => {
    if (!canvas) {
      return;
    }
    const transform = imageTransform({
      imageWidth: imageSource.width,
      imageHeight: imageSource.height,
      canvasWidth: canvas.width,
      canvasHeight: canvas.height,
      rotation
    });
    const extents = imageExtentsInCanvasSpace(transform, imageSource.width, imageSource.height);
    const height = extents.maxY - extents.minY;
    const halfway = height / 2;
    setBoundsStart({
      x: extents.minX,
      y: extents.minY
    });
    setBoundsEnd({
      x: extents.maxX,
      y: extents.minY + halfway
    });
  };
  const updateRotation = (amount: number) => {
    setRotation((rotation + amount) % 360);
  };
  const rotateLeft = (e: KeyboardEvent) => {
    updateRotation(e.shiftKey ? -smallRotateStep : -90);
  };
  const rotateRight = (e: KeyboardEvent) => {
    updateRotation(e.shiftKey ? smallRotateStep : 90);
  };
  const nudgeBounds = (nudgeY: number) => {
    if (boundsStart) {
      setBoundsStart({
        x: boundsStart.x,
        y: boundsStart.y + nudgeY
      });
    }
    if (boundsEnd) {
      setBoundsEnd({
        x: boundsEnd.x,
        y: boundsEnd.y + nudgeY
      });
    }
  };
  const shiftBoundsUp = (e: KeyboardEvent) => {
    nudgeBounds(e.shiftKey ? -5 : -40);
  };
  const shiftBoundsDown = (e: KeyboardEvent) => {
    nudgeBounds(e.shiftKey ? 5 : 40);
  };
  useKeyPressEvent('ArrowLeft', rotateLeft);
  useKeyPressEvent('ArrowRight', rotateRight);
  useKeyPressEvent('a', rotateLeft);
  useKeyPressEvent('d', rotateRight);
  useKeyPressEvent('A', rotateLeft);
  useKeyPressEvent('D', rotateRight);
  useKeyPressEvent('r', () => setRotation(0));
  useKeyPressEvent('Escape', () => {
    setBoundsEnd(null);
    setBoundsStart(null);
  });
  useKeyPressEvent('t', () => {
    selectThird(1);
  });
  useKeyPressEvent('b', () => {
    selectThird(3);
  });
  useKeyPressEvent('t', () => {
    selectThird(1);
  });
  useKeyPressEvent('h', selectTopHalf);
  useKeyPressEvent('w', shiftBoundsUp);
  useKeyPressEvent('W', shiftBoundsUp);
  useKeyPressEvent('s', shiftBoundsDown);
  useKeyPressEvent('S', shiftBoundsDown);
  const extractImage = () => {
    if (!canvas) {
      return;
    }
    const transform = imageTransform({
      imageWidth: imageSource.width,
      imageHeight: imageSource.height,
      canvasWidth: canvas.width,
      canvasHeight: canvas.height,
      rotation
    });
    let bounds = null;
    if (boundsStart && boundsEnd) {
      bounds = boundsFrom(boundsStart, boundsEnd);
    } else {
      bounds = imageBoundsInCanvasSpace(transform, imageSource.width, imageSource.height);
    }
    if (bounds.width === 0 || bounds.height === 0) {
      return;
    }
    const onPixelsExtracted = props.selectionMode === 'area' ? props.onPixelsExtracted : null;
    getPixels(imageSource, {
      bounds: bounds,
      imageTransform: transform,
      minimumWidth: minimumWidth,
      minimumHeight: minimumHeight
    }).then(pixels => onPixelsExtracted?.(pixels));
  };
  useKeyPressEvent('Enter', () => extractImage());
  const clipLocationToImage = (canvas: HTMLCanvasElement, l: Point): Point => {
    const transform = imageTransform({
      imageWidth: imageSource.width,
      imageHeight: imageSource.height,
      canvasWidth: canvas.width,
      canvasHeight: canvas.height,
      rotation
    });
    const extents = imageExtentsInCanvasSpace(transform, imageSource.width, imageSource.height);
    if (l.x < extents.minX) {
      l.x = extents.minX;
    }
    if (l.x > extents.maxX) {
      l.x = extents.maxX;
    }
    if (l.y < extents.minY) {
      l.y = extents.minY;
    }
    if (l.y > extents.maxY) {
      l.y = extents.maxY;
    }
    return l;
  };
  const onMouseDown = (e: React.MouseEvent<HTMLCanvasElement, MouseEvent>) => {
    const l = clipLocationToImage(e.currentTarget, getMouseLocationInCanvas(e));
    if (boundsStart === null) {
      setBoundsStart(l);
    } else if (boundsEnd === null) {
      setBoundsEnd(l);
    } else {
      setBoundsStart(l);
      setBoundsEnd(null);
    }
    setMouseDown(true);
  };
  const callPointCallbackIfInImage = (e: React.MouseEvent<HTMLCanvasElement, MouseEvent>, callback: (point: Point) => void) => {
    const l = getMouseLocationInCanvas(e);
    const transform = imageTransform({
      imageWidth: imageSource.width,
      imageHeight: imageSource.height,
      canvasWidth: e.currentTarget.width,
      canvasHeight: e.currentTarget.height,
      rotation
    });
    const locationInImageSpace = transform.inverse().transformPoint(new DOMPoint(l.x, l.y));
    if (locationInImageSpace.x >= 0 && locationInImageSpace.x < imageSource.width && locationInImageSpace.y >= 0 && locationInImageSpace.y < imageSource.height) {
      callback(locationInImageSpace);
    }
  };
  const onMouseMove = (e: React.MouseEvent<HTMLCanvasElement, MouseEvent>) => {
    const l = clipLocationToImage(e.currentTarget, getMouseLocationInCanvas(e));
    setMouseLocation(l);
    if (mouseDown && boundsStart) {
      setBoundsEnd(l);
    }
    if (props.selectionMode === 'point') {
      callPointCallbackIfInImage(e, props.onHoveredPointChanged);
    }
  };
  const onMouseUp = (e: React.MouseEvent<HTMLCanvasElement, MouseEvent>) => {
    if (props.selectionMode === 'point') {
      callPointCallbackIfInImage(e, props.onPointSelected);
    }
    setMouseDown(false);
  };
  const useMouse = selectionMode === 'area' || selectionMode === 'point' ? true : undefined;
  return <div data-testid="image-tool" data-sentry-component="ImageToolInner" data-sentry-source-file="image-tool.tsx">
      {selectionMode === 'area' && <div className="space-y-0.5 text-xs"></div>}
      {selectionMode === 'none' && <>
          <button onClick={() => updateRotation(-90)}>↶</button>
          <button onClick={() => updateRotation(90)}>↷</button>
        </>}
      <canvas ref={canvasRef} width={size} height={size} style={{
      border: '1px solid gray',
      position: 'relative'
    }} onMouseDown={useMouse && onMouseDown} onMouseMove={useMouse && onMouseMove} onMouseUp={useMouse && onMouseUp} />
      <div className="flex flex-row space-x-2 text-xs">
        <div>
          Image Dimensions: {imageSource.width}x{imageSource.height}
        </div>
      </div>
    </div>;
};
export const MultiImageTool = (props: Props) => {
  const {
    url
  } = props;
  const [imageSources, setImageSources] = useState<(CanvasImageSource & {
    width: number;
    height: number;
  })[] | null>(null);
  const [error, setError] = useState<Error | null>(null);
  const [page, setPage] = useState(0);
  const incrementPage = () => setPage(Math.min(page + 1, (imageSources?.length ?? 1) - 1));
  const decrementPage = () => setPage(Math.max(page - 1, 0));
  useEffect(() => {
    loadImages(url).then(i => {
      setImageSources(i);
    }).catch(e => {
      setError(e);
    });
  }, [url]);
  useKeyPressEvent('j', decrementPage);
  useKeyPressEvent('k', incrementPage);
  if (error) {
    return <>
        <h3>Error loading image.</h3>
        <ErrorMessage message={error.message} />
        <StyledLink style="underline" href={url} target="_blank">
          Open image in new tab
        </StyledLink>
      </>;
  }
  if (!imageSources) {
    return <h3>Loading image</h3>;
  }
  return <div data-sentry-component="MultiImageTool" data-sentry-source-file="image-tool.tsx">
      {imageSources.length > 1 && <div className="mb-1">
          <Box flex gap="2">
            <Body contents={`This PDF contains ${imageSources.length} pages`} />
            <Button size="small" onClick={decrementPage} disabled={page <= 0} text="Previous page (j)" />
            <Button size="small" onClick={incrementPage} disabled={page === imageSources.length - 1} text="Next page (k)" />
          </Box>
        </div>}
      <ImageToolInner key={page} imageSource={imageSources[page]} {...props} data-sentry-element="ImageToolInner" data-sentry-source-file="image-tool.tsx" />
    </div>;
};