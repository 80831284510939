import classNames from 'classnames';
import { ReactNode } from 'react';
type Props = {
  left: ReactNode;
  right: ReactNode;
  mobilePriority?: 'left' | 'right' | 'none';
  mode: 'centered' | 'leftDrawer' | 'rightDrawer';
};
export const SideBySide = ({
  left,
  right,
  mode,
  mobilePriority = 'none'
}: Props) => <div className="flex flex-col flex-nowrap gap-y-10 xl:flex-row xl:gap-y-0 xl:space-x-12" data-sentry-component="SideBySide" data-sentry-source-file="SideBySide.tsx">
    <div className={classNames('space-y-10', {
    centered: 'flex-1',
    leftDrawer: 'xl:card-sm w-auto flex-initial xl:h-fit xl:w-1/3 xl:max-w-[350px] xl:flex-none xl:rounded-lg xl:p-4',
    rightDrawer: 'flex-auto xl:max-w-[calc(100%-350px-2.5rem)]'
  }[mode], {
    left: 'order-0 xl:order-0',
    right: 'order-1 xl:order-0',
    none: ''
  }[mobilePriority])}>
      {left}
    </div>
    <div className={classNames('space-y-10', {
    centered: 'md:flex-1',
    leftDrawer: 'flex-auto xl:max-w-[calc(100%-350px-2.5rem)]',
    rightDrawer: 'xl:card-sm w-auto flex-initial xl:h-fit xl:w-1/3 xl:max-w-[350px] xl:flex-none xl:rounded-lg xl:p-4'
  }[mode], {
    left: 'order-1 xl:order-1',
    right: 'order-0 xl:order-1',
    none: ''
  }[mobilePriority])}>
      {right}
    </div>
  </div>;