import React, { useMemo } from 'react';
import { OperatorManualQueueItemListResponseDataItem, OperatorManualQueueItemListStatusItem } from 'src/build/operations';
import { useOperatorManualQueueItemListInfinite, useOperatorManualQueueItemPatch } from 'src/hooks/reactQuery';
import { PageLayout } from './deprecated/PageLayout';
import { TableStateWrapper } from '@increase/shared/components/TableStateWrapper';
import { PrestyledTableProps, Table } from '@increase/shared/components/Table';
import { buildPath, ROUTES } from 'src/lib/routes';
import { formatISO8601Timestamp } from '@increase/shared/lib/formatting';
import { useSearchParamsState } from '@increase/shared/hooks/useTypedSearchParamsState';
import { makeStatusFilter } from 'src/lib/tableFilterHelpers';
const GroupQueueTable = (props: PrestyledTableProps<OperatorManualQueueItemListResponseDataItem>) => {
  const operatorManualQueueItemPatch = useOperatorManualQueueItemPatch();
  return <Table {...props} getRowProps={() => ({
    className: 'hover:bg-main-hover transition-all group'
  })} columns={[{
    header: 'Group',
    contents: r => ({
      text: r.record_id,
      href: buildPath(ROUTES.GROUPS_DETAIL, {
        groupId: r.record_id
      })
    })
  }, {
    header: 'Cause',
    contents: r => ({
      text: r.cause || 'Unknown'
    })
  }, {
    header: 'Description',
    contents: r => ({
      text: r.description
    })
  }, {
    header: 'Created at',
    contents: r => ({
      text: formatISO8601Timestamp(r.created_at, 'month-day-year-hour-minute-second')
    })
  }, {
    header: 'Actions',
    contents: r => ({
      menuItems: [{
        key: 'close',
        icon: 'close_circle',
        title: 'Mark actioned',
        onClick: () => {
          operatorManualQueueItemPatch.mutateAsync([r.id, {
            status: 'actioned'
          }]);
        }
      }]
    })
  }]} data-sentry-element="Table" data-sentry-component="GroupQueueTable" data-sentry-source-file="group-queue-items.tsx" />;
};
export const GroupQueueItems = () => {
  const [statusFilter, setStatusFilter] = useSearchParamsState(ROUTES.GROUP_QUEUE, 'status');
  const filters = useMemo(() => [makeStatusFilter(statusFilter, setStatusFilter, OperatorManualQueueItemListStatusItem)], [statusFilter, setStatusFilter]);
  const listResponse = useOperatorManualQueueItemListInfinite({
    queue: ['group_review'],
    status: statusFilter.length > 0 ? statusFilter : undefined
  });
  return <PageLayout id="application.groupQueueItems" headline="Group review queue items" data-sentry-element="PageLayout" data-sentry-component="GroupQueueItems" data-sentry-source-file="group-queue-items.tsx">
      <TableStateWrapper {...listResponse} table={GroupQueueTable} emptyTitle="No reviews" emptySubtitle="There are no reviews to display" style="primary" filters={filters} data-sentry-element="TableStateWrapper" data-sentry-source-file="group-queue-items.tsx" />
    </PageLayout>;
};