import React, { useMemo } from 'react';
import { usePendingTransactionListInfinite } from 'src/hooks/reactQuery';
import { TableStateWrapper } from '@increase/shared/components/TableStateWrapper';
import { makeAccountFilter, makeAccountNumberFilter, makeAmountFilter, makeCardFilter, makeGroupFilter, makeLockboxFilter, makeTimeRangeFilter } from 'src/lib/tableFilterHelpers';
import { compact } from 'lodash';
import { TableFilter } from '@increase/shared/components/Table/filters';
import { PendingTransactionsTable } from '../tables/PendingTransactionsTable';
import { DateTime } from 'luxon';
import { ROUTES } from 'src/lib/routes';
import { useSearchParamsState } from '@increase/shared/hooks/useTypedSearchParamsState';
import { useTypedRangeParams } from 'src/hooks/timeSearchParameters';
import { useTypedSearchParams } from 'react-router-typesafe-routes/dom';
export const PendingTransactionListPage = () => {
  const [groupFilter, setGroupFilter] = useSearchParamsState(ROUTES.PENDING_TRANSACTIONS_LIST, 'group');
  const [accountFilter, setAccountFilter] = useSearchParamsState(ROUTES.PENDING_TRANSACTIONS_LIST, 'account');
  const [cardFilter, setCardFilter] = useSearchParamsState(ROUTES.PENDING_TRANSACTIONS_LIST, 'card');
  const [lockboxFilter, setLockboxFilter] = useSearchParamsState(ROUTES.PENDING_TRANSACTIONS_LIST, 'lockbox');
  const [accountNumberFilter, setAccountNumberFilter] = useSearchParamsState(ROUTES.PENDING_TRANSACTIONS_LIST, 'account_number');
  const [{
    startAt,
    endAt
  }, setTimeRange] = useTypedRangeParams(ROUTES.PENDING_TRANSACTIONS_LIST);
  const [amountOrGreater] = useSearchParamsState(ROUTES.PENDING_TRANSACTIONS_LIST, 'amount_or_greater');
  const [amountOrLess] = useSearchParamsState(ROUTES.PENDING_TRANSACTIONS_LIST, 'amount_or_less');
  const [exactAmount] = useSearchParamsState(ROUTES.PENDING_TRANSACTIONS_LIST, 'exact_amount');
  const setManyParameters = useTypedSearchParams(ROUTES.PENDING_TRANSACTIONS_LIST)[1];
  const startAtDateTime = startAt ? DateTime.fromISO(startAt, {
    zone: 'utc'
  }) : undefined;
  const endAtDateTime = endAt ? DateTime.fromISO(endAt, {
    zone: 'utc'
  }) : undefined;
  const filters: TableFilter[] = useMemo(() => compact([makeGroupFilter(groupFilter, setGroupFilter), makeAccountFilter(accountFilter, setAccountFilter), makeCardFilter(cardFilter, setCardFilter), makeAccountNumberFilter(accountNumberFilter, setAccountNumberFilter), makeLockboxFilter(lockboxFilter, setLockboxFilter), makeTimeRangeFilter({
    startAt,
    endAt
  }, setTimeRange, 'Date'), makeAmountFilter(amountOrGreater ?? null, amountOrLess ?? null, exactAmount ?? null, ({
    newAmountOrGreater,
    newAmountOrLess,
    newExactAmount
  }) => setManyParameters(current => ({
    ...current,
    amount_or_greater: newAmountOrGreater ?? undefined,
    amount_or_less: newAmountOrLess ?? undefined,
    exact_amount: newExactAmount ?? undefined
  })))]), [accountFilter, accountNumberFilter, amountOrGreater, amountOrLess, cardFilter, endAt, exactAmount, groupFilter, setAccountFilter, setAccountNumberFilter, setCardFilter, setGroupFilter, setManyParameters, setTimeRange, startAt, lockboxFilter, setLockboxFilter]);
  const routeIds = [...accountNumberFilter, ...cardFilter, ...lockboxFilter];
  const listResult = usePendingTransactionListInfinite({
    group_ids: groupFilter.length > 0 ? groupFilter : undefined,
    account_ids: accountFilter.length > 0 ? accountFilter : undefined,
    date_at_or_after: startAtDateTime?.startOf('day').toISO() ?? undefined,
    date_at_or_before: endAtDateTime?.endOf('day').toISO() ?? undefined,
    route_ids: routeIds.length > 0 ? routeIds : undefined,
    amount_or_greater: amountOrGreater,
    amount_or_less: amountOrLess,
    exact_amount: exactAmount
  });
  return <TableStateWrapper table={PendingTransactionsTable} style="primary" {...listResult} emptyTitle="No Pending Transactions" emptySubtitle="There are no pending transactions to display" filters={filters} showAccount={accountFilter.length !== 1} showGroup={groupFilter.length !== 1 && accountFilter.length !== 1} showRoute={accountFilter.length === 1} data-sentry-element="TableStateWrapper" data-sentry-component="PendingTransactionListPage" data-sentry-source-file="PendingTransactionListTab.tsx" />;
};