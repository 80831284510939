import { TableColumns, Table, PrestyledTableProps } from '@increase/shared/components/Table';
import { useMemo } from 'react';
import { GroupListResponseDataItem } from 'src/build/operations';
import { formatISO8601Timestamp, humanize } from '@increase/shared/lib/formatting';
import { BadgeColor } from '@increase/shared/components/Badge';
import { buildPath, ROUTES } from 'src/lib/routes';
type Props = PrestyledTableProps<GroupListResponseDataItem>;
export const groupStatusBadgeColors: Record<GroupListResponseDataItem['status'], BadgeColor> = {
  active: 'green',
  offboarded: 'gray',
  rejected: 'gray',
  pending_activating: 'yellow'
};
export const GroupsTable = (props: Props) => {
  const {
    getRowProps,
    ...rest
  } = props;
  const columns = useMemo<TableColumns<GroupListResponseDataItem>>(() => {
    return [{
      header: 'Name',
      expand: 1,
      contents: group => ({
        text: group.name ?? group.friendly_nickname,
        textWeight: 'medium',
        textColor: 'emphasis'
      })
    } as const, {
      header: 'Status',
      contents: group => ({
        text: humanize(group.status),
        badgeColor: groupStatusBadgeColors[group.status]
      })
    }, {
      header: 'Created',
      align: 'right',
      contents: group => ({
        text: formatISO8601Timestamp(group.created_at, 'date')
      })
    }];
  }, []);
  const defaultRowProps = (datum: GroupListResponseDataItem) => ({
    href: buildPath(ROUTES.GROUPS_DETAIL, {
      groupId: datum.id
    }),
    className: 'hover:bg-main-hover cursor-pointer transition-all group'
  });
  return <Table<GroupListResponseDataItem> columns={columns} getRowProps={getRowProps ?? defaultRowProps} {...rest} data-sentry-element="Table" data-sentry-component="GroupsTable" data-sentry-source-file="GroupsTable.tsx" />;
};