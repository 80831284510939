import { compact } from 'lodash';
import { useTypedParams } from 'react-router-typesafe-routes/dom';
import { Box } from '@increase/shared/components/Box';
import { ListItem, PropertyList } from '@increase/shared/components/PropertyList';
import { SideBySide } from '@increase/shared/components/SideBySide';
import { Body, Heading } from '@increase/shared/components/Text';
import { ROUTES } from 'src/lib/routes';
import { useMicroBiltResultGet } from 'src/hooks/reactQuery';
import { PageLayout } from '../deprecated/PageLayout';
export const EntityMicroBiltResultDetailPage = () => {
  const {
    microBiltResponseId
  } = useTypedParams(ROUTES.ENTITY_MICROBILT_RESULT);
  const {
    data
  } = useMicroBiltResultGet(microBiltResponseId);
  if (!data) {
    return <></>;
  }
  return <PageLayout headline={`MicroBilt ${data.request_id}`} data-sentry-element="PageLayout" data-sentry-component="EntityMicroBiltResultDetailPage" data-sentry-source-file="EntityMicroBiltResultDetailPage.tsx">
      <SideBySide mode="rightDrawer" right={<Box>
            <Heading>Response Details</Heading>
            <PropertyList items={compact<ListItem>([{
        label: 'Request ID',
        value: data.request_id
      }, {
        label: 'Return Code',
        value: data.return_code
      }, {
        label: 'First Name',
        value: data.first_name
      }, {
        label: 'Last Name',
        value: data.last_name
      }, {
        label: 'Middle Name',
        value: data.middle_name || '<no data>'
      }, {
        label: 'Birth Date',
        value: data.birth_date || '<no data>'
      }, {
        label: 'Death Date',
        value: data.death_date || '<no data>'
      }, {
        label: 'Tax ID Last 4',
        value: data.tax_id_last_4 || '<no data>'
      }, {
        label: 'Tax ID Type',
        value: data.tax_id_type || '<no data>'
      }])} />
          </Box>} left={<Box>
            <Heading>Raw Response</Heading>
            <Box className="border p-4">
              <Body weight="mono">{data.response}</Body>
            </Box>
          </Box>} data-sentry-element="SideBySide" data-sentry-source-file="EntityMicroBiltResultDetailPage.tsx" />
    </PageLayout>;
};