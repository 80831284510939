import { PageLayout } from '../deprecated/PageLayout';
import { SegmentedControl } from '@increase/shared/components/SegmentedControl';
import { Outlet, useLocation } from 'react-router';
import { buildPath, ROUTES } from 'src/lib/routes';
import { compact } from 'lodash';
import { Box } from '@increase/shared/components/Box';
import { useSearchParamsState } from '@increase/shared/hooks/useTypedSearchParamsState';
import { Select } from '@increase/shared/components/Select';
import { DateInput } from '@increase/shared/components/DateInput';
import { ManagementInformationSystemBalancesListPeriod } from 'src/build/operations';
const options = [{
  value: ManagementInformationSystemBalancesListPeriod.day,
  label: 'Day'
}, {
  value: ManagementInformationSystemBalancesListPeriod.week,
  label: 'Week'
}, {
  value: ManagementInformationSystemBalancesListPeriod.month,
  label: 'Month'
}, {
  value: ManagementInformationSystemBalancesListPeriod.quarter,
  label: 'Quarter'
}, {
  value: ManagementInformationSystemBalancesListPeriod.year,
  label: 'Year'
}];
export const ManagementInformationSystemWrapperPage = () => {
  const segments = compact([{
    slug: buildPath(ROUTES.MANAGEMENT_INFORMATION_SYSTEM.OVERVIEW, {}),
    title: 'Overview'
  }, {
    slug: buildPath(ROUTES.MANAGEMENT_INFORMATION_SYSTEM.VOLUME, {}),
    title: 'Volume'
  }, {
    slug: buildPath(ROUTES.MANAGEMENT_INFORMATION_SYSTEM.ACCOUNTS, {}),
    title: 'Accounts'
  }, {
    slug: buildPath(ROUTES.MANAGEMENT_INFORMATION_SYSTEM.COMPLIANCE, {}),
    title: 'Compliance'
  }]);
  const [atTime, setAtTime] = useSearchParamsState(ROUTES.MANAGEMENT_INFORMATION_SYSTEM, 'at_time');
  const [period, setPeriod] = useSearchParamsState(ROUTES.MANAGEMENT_INFORMATION_SYSTEM, 'lookback_period');
  const location = useLocation();
  const segmentIndex = segments.findIndex(s => location.pathname.includes(s.slug)) || 0;
  const cleanSegments = segments.map(({
    title,
    slug
  }, i) => ({
    title,
    href: slug,
    selected: i === segmentIndex
  }));
  return <PageLayout headline={`Management Information System • ${segments[segmentIndex]?.title}`} segmentedControl={<Box flex>
          <SegmentedControl segments={cleanSegments} />
          <Box flex>
            <DateInput value={atTime} onChange={e => setAtTime(e.target.value)} />

            <Select<ManagementInformationSystemBalancesListPeriod> className="w-64" value={period} onUpdate={setPeriod} sections={[{
        options
      }]} />
          </Box>
        </Box>} data-sentry-element="PageLayout" data-sentry-component="ManagementInformationSystemWrapperPage" data-sentry-source-file="ManagementInformationSystemWrapperPage.tsx">
      <Outlet data-sentry-element="Outlet" data-sentry-source-file="ManagementInformationSystemWrapperPage.tsx" />
    </PageLayout>;
};