import { useEntitySupplementalDocumentSubmissionEvaluationGet } from 'src/hooks/reactQuery';
import { CheckIcon, XMarkIcon } from '@heroicons/react/20/solid';
import { EntitySupplementalDocumentSubmissionEvaluationGetResponseRequiredChecksItem } from 'src/build/operations';
import { Box } from 'shared/components/Box';
import { Body, Caption } from 'shared/components/Text';
import { InternalOperatorOnly } from './internal-operator-only';
import { StyledLink } from 'shared/components/StyledLink';
type Props = {
  entitySetupSupplementalDocumentSubmissionId: string;
};
const description = (name: EntitySupplementalDocumentSubmissionEvaluationGetResponseRequiredChecksItem): string => ({
  correct_format: 'The uploaded file matches the expected JSON format.',
  expected_entity_structure: 'The entity type (eg Corporation) matches the expected type.',
  entity_lookup_performed: 'The platform looked up the entity in an identification database.',
  beneficial_owner_lookup_performed: 'The platform looked up beneficial owner(s) in an identification database.',
  entity_data_matches: 'The platform looked up the same entity data they submitted to Increase.',
  beneficial_owner_data_matches: 'The platform looked up the same beneficial owner data they submitted to Increase.',
  evaluation_successful: 'The rules used by the platform accepted this as a valid entity.'
})[name];
export const EntitySetupSupplementalDocumentSubmissionEvaluation = ({
  entitySetupSupplementalDocumentSubmissionId
}: Props) => {
  const {
    data,
    isLoading
  } = useEntitySupplementalDocumentSubmissionEvaluationGet(entitySetupSupplementalDocumentSubmissionId);
  if (isLoading) {
    return <Box padding="6">
        <Caption weight="mono">
          {entitySetupSupplementalDocumentSubmissionId}
        </Caption>
        <Body>Loading...</Body>
      </Box>;
  }
  return <Box padding="6" data-sentry-element="Box" data-sentry-component="EntitySetupSupplementalDocumentSubmissionEvaluation" data-sentry-source-file="entity-setup-supplemental-document-submission-evaluation.tsx">
      <Caption weight="mono" data-sentry-element="Caption" data-sentry-source-file="entity-setup-supplemental-document-submission-evaluation.tsx">
        {entitySetupSupplementalDocumentSubmissionId}
      </Caption>
      <Box gap="4" data-sentry-element="Box" data-sentry-source-file="entity-setup-supplemental-document-submission-evaluation.tsx">
        {data && data.required_checks.map(check_name => <>
              <div className="flex flex-row items-center gap-1">
                {data.successful_checks.includes(check_name) ? <CheckIcon className="text-success mr-2 flex h-5 w-5 shrink-0 grow-0" /> : <XMarkIcon className="text-danger mr-2 flex h-5 w-5 shrink-0 grow-0" />}
                <div>
                  <Body weight="medium">{check_name}</Body>
                  <Caption>{description(check_name)}</Caption>
                </div>
              </div>
            </>)}
        {(!data || data.required_checks.length === 0) && <Body>Checks not performed.</Body>}
      </Box>
      <InternalOperatorOnly data-sentry-element="InternalOperatorOnly" data-sentry-source-file="entity-setup-supplemental-document-submission-evaluation.tsx">
        <StyledLink style="underline" href={`/manual_tasks/Operations::ManualTask::SetEntitySetupSupplementalDocumentManualReview?entity_setup_supplemental_document_submission_id=${entitySetupSupplementalDocumentSubmissionId}`} data-sentry-element="StyledLink" data-sentry-source-file="entity-setup-supplemental-document-submission-evaluation.tsx">
          <Body data-sentry-element="Body" data-sentry-source-file="entity-setup-supplemental-document-submission-evaluation.tsx">Manually mark as accepted</Body>
        </StyledLink>
      </InternalOperatorOnly>
    </Box>;
};