import { compact } from 'lodash';
import { ListItem, PropertyList, PropertyListProps } from '@increase/shared/components/PropertyList';
import { LateReturnRequestGetResponse } from 'src/build/operations';
import { documentRequestStatusBadgeColors } from '../tables/LateReturnRequestsTable';
import { useServices } from 'src/hooks/use-services';
import { useGroupPropertyRow } from 'src/lib/propertyListHelpers';
type LateReturnRequestPropertyListProps = {
  lateReturnRequest: LateReturnRequestGetResponse;
} & Omit<PropertyListProps, 'items'>;
export const LateReturnRequestPropertyList = (props: LateReturnRequestPropertyListProps) => {
  const {
    lateReturnRequest,
    ...rest
  } = props;
  const {
    operations
  } = useServices();
  const groupRow = useGroupPropertyRow(lateReturnRequest.group_id);
  return <PropertyList {...rest} items={compact<ListItem>([{
    label: 'ID',
    value: lateReturnRequest.id,
    copyable: lateReturnRequest.id
  }, groupRow, {
    label: 'Contact Email',
    value: lateReturnRequest.contact_email ?? '-'
  }, {
    label: 'Contact Fax',
    value: lateReturnRequest.contact_fax ?? '-'
  }, {
    label: 'Contact Name',
    value: lateReturnRequest.contact_name ?? '-'
  }, {
    label: 'Claim Number',
    value: lateReturnRequest.claim_number ?? '-'
  }, {
    label: 'Status',
    value: lateReturnRequest.document_request.status,
    badgeColor: documentRequestStatusBadgeColors[lateReturnRequest.document_request.status]
  }, {
    label: 'Created At',
    value: lateReturnRequest.created_at,
    format: 'date'
  }, ...(lateReturnRequest.document_request.source as {
    documentation: {
      id: string;
      filename: string;
    }[];
  }).documentation.map(file => ({
    label: 'Documentation',
    value: file.filename,
    href: `${operations}/api_files/${file.id}/view`
  }))])} data-sentry-element="PropertyList" data-sentry-component="LateReturnRequestPropertyList" data-sentry-source-file="LateReturnRequestPropertyList.tsx" />;
};