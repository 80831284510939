import { createContext, Fragment, ReactNode, useContext, useEffect, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import classNames from 'classnames';
type DialogState = 'none' | 'modal' | 'drawer' | 'full';
type DialogProps = {
  state: DialogState;
  onClose: () => void;
  children: ReactNode;
};

// You probably don't need to use this directly
const DialogContext = createContext({
  modalState: 'none' as DialogState,
  setModalState: (_open: DialogState) => {},
  modalChild: null as ReactNode,
  setModalChild: (_child: ReactNode) => {},
  searchOpen: false as boolean,
  setSearchOpen: (_open: boolean) => {}
});
export const Modal = ({
  state,
  onClose,
  children
}: DialogProps) => {
  const {
    setModalState
  } = useContext(DialogContext);
  useEffect(() => {
    setModalState(state);
  }, [setModalState, state]);
  return <Transition.Root show={state === 'modal'} as={Fragment} data-sentry-element="unknown" data-sentry-component="Modal" data-sentry-source-file="Modal.tsx">
      <Dialog as="div" className="relative z-50" onClose={onClose} data-sentry-element="Dialog" data-sentry-source-file="Modal.tsx">
        <div className="fixed inset-0 overflow-y-visible">
          <div className="flex h-full items-start justify-center sm:p-4 md:items-center md:p-0">
            <Transition.Child as={Fragment} enter="ease-out-quint duration-700" enterFrom="opacity-0 translate-y-full sm:scale-75 sm:translate-y-0" enterTo="opacity-100 scale-100 translate-y-0" leave="ease-out-quint duration-700" leaveFrom="opacity-100 scale-100 translate-y-0" leaveTo="opacity-0 translate-y-full sm:scale-75 sm:translate-y-0" data-sentry-element="unknown" data-sentry-source-file="Modal.tsx">
              <Dialog.Panel className="border-main bg-main no-scrollbar absolute inset-x-0 top-12 bottom-0 w-full overflow-y-scroll rounded-t-lg border shadow-xl transition-all sm:relative sm:inset-x-auto sm:h-auto sm:max-h-[90vh] sm:w-full sm:max-w-lg sm:rounded-lg" data-sentry-element="unknown" data-sentry-source-file="Modal.tsx">
                {children}
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>;
};
export const Drawer = ({
  state,
  onClose,
  children
}: DialogProps) => {
  const {
    setModalState
  } = useContext(DialogContext);
  useEffect(() => {
    setModalState(state);
  }, [setModalState, state]);
  return <Transition.Root show={state === 'drawer'} as={Fragment} data-sentry-element="unknown" data-sentry-component="Drawer" data-sentry-source-file="Modal.tsx">
      <Dialog as="div" className="relative z-50" onClose={onClose} data-sentry-element="Dialog" data-sentry-source-file="Modal.tsx">
        <div className="fixed inset-0 overflow-y-visible">
          <Transition.Child as={Fragment} enter="ease-out-quint duration-700" enterFrom="translate-y-full sm:translate-y-0 sm:translate-x-[calc(100%+24px)]" enterTo="translate-y-0 translate-x-0" leave="ease-out-quint duration-700" leaveFrom="translate-y-0 translate-x-0" leaveTo="translate-y-full sm:translate-y-0 sm:translate-x-[calc(100%+24px)]" data-sentry-element="unknown" data-sentry-source-file="Modal.tsx">
            <Dialog.Panel className={classNames('bg-main absolute inset-x-0 top-12 bottom-0 w-full overflow-hidden rounded-t-lg shadow-2xl transition-all sm:inset-y-[12px] sm:right-[12px] sm:left-auto sm:w-[calc(100vw-24px)] sm:max-w-[500px] sm:rounded-lg')} data-sentry-element="unknown" data-sentry-source-file="Modal.tsx">
              {children}
            </Dialog.Panel>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>;
};
export const FullscreenForm = ({
  state,
  onClose,
  children
}: DialogProps) => {
  const {
    setModalState
  } = useContext(DialogContext);
  useEffect(() => {
    setModalState(state);
  }, [setModalState, state]);
  return <Transition.Root show={state === 'full'} as={Fragment} data-sentry-element="unknown" data-sentry-component="FullscreenForm" data-sentry-source-file="Modal.tsx">
      <Dialog as="div" className="relative z-50" onClose={onClose} data-sentry-element="Dialog" data-sentry-source-file="Modal.tsx">
        <div className="fixed inset-0 overflow-y-visible">
          <Transition.Child as={Fragment} enter="ease-out-quint duration-700" enterFrom="translate-y-full" enterTo="translate-y-0" leave="ease-out-quint duration-700" leaveFrom="translate-y-0" leaveTo="translate-y-full" data-sentry-element="unknown" data-sentry-source-file="Modal.tsx">
            <Dialog.Panel className={classNames('bg-main absolute inset-0 h-full w-full shadow-2xl transition-all md:inset-x-3 md:top-8 md:bottom-3 md:h-[calc(100vh-44px)] md:w-[calc(100vw-24px)] md:overflow-hidden md:rounded-lg')} data-sentry-element="unknown" data-sentry-source-file="Modal.tsx">
              {children}
            </Dialog.Panel>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>;
};
const useModal = () => {
  const {
    setModalState,
    setModalChild,
    modalState
  } = useContext(DialogContext);
  const modalOpen = modalState === 'modal';
  const showModal = (child: ReactNode) => {
    setModalChild(child);
    setModalState('modal');
  };
  const closeModal = () => {
    setModalState('none');
  };
  return {
    modalOpen,
    showModal,
    closeModal
  };
};
const useDrawer = () => {
  const {
    setModalState,
    setModalChild,
    modalState
  } = useContext(DialogContext);
  const modalOpen = modalState === 'drawer';
  const showDrawer = (child: ReactNode) => {
    setModalChild(child);
    setModalState('drawer');
  };
  const closeDrawer = () => {
    setModalState('none');
  };
  return {
    modalOpen,
    showDrawer,
    closeDrawer
  };
};
const useFullscreenForm = () => {
  const {
    setModalState,
    setModalChild,
    modalState
  } = useContext(DialogContext);
  const modalOpen = modalState === 'full';
  const showFullscreenForm = (child: ReactNode) => {
    setModalChild(child);
    setModalState('full');
  };
  const closeFullscreenForm = () => {
    setModalState('none');
  };
  return {
    modalOpen,
    showFullscreenForm,
    closeFullscreenForm
  };
};
const SearchBar = ({
  searchOpen,
  setSearchOpen,
  children
}: {
  searchOpen: boolean;
  setSearchOpen: (_open: boolean) => void;
  children: ReactNode;
}) => {
  return <Transition.Root show={!!searchOpen} as={Fragment} data-sentry-element="unknown" data-sentry-component="SearchBar" data-sentry-source-file="Modal.tsx">
      <Dialog as="div" className="relative z-50" onClose={() => {
      setSearchOpen(false);
    }} data-sentry-element="Dialog" data-sentry-source-file="Modal.tsx">
        <div className="fixed inset-0 overflow-y-visible">
          <div className="flex h-full items-start justify-center sm:p-4 md:p-0">
            <Transition.Child as={Fragment} enter="ease-out-quint duration-50" enterFrom="opacity-0 translate-y-2" enterTo="opacity-100 translate-y-0" leave="ease-out-quint duration-300" leaveFrom="opacity-100" leaveTo="opacity-0" data-sentry-element="unknown" data-sentry-source-file="Modal.tsx">
              <Dialog.Panel className="border-strong bg-subtle shadow-3xl no-scrollbar absolute inset-x-0 top-12 bottom-0 h-[calc(100%-48px)] w-full overflow-hidden rounded-t-lg border transition-all sm:relative sm:inset-x-auto sm:top-[15vh] sm:h-auto sm:w-full sm:max-w-[680px] sm:rounded-lg" data-sentry-element="unknown" data-sentry-source-file="Modal.tsx">
                {children}
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>;
};
const ModalContainer = ({
  children,
  search,
  searchOpen,
  setSearchOpen
}: {
  children: ReactNode;
  search?: ReactNode;
  searchOpen: boolean;
  setSearchOpen: (_open: boolean) => void;
}) => {
  const [modalState, setModalState] = useState<DialogState>('none');
  const [modalChild, setModalChild] = useState<ReactNode>(null);
  return <DialogContext.Provider value={{
    modalState,
    setModalState,
    modalChild,
    setModalChild,
    searchOpen,
    setSearchOpen
  }} data-sentry-element="unknown" data-sentry-component="ModalContainer" data-sentry-source-file="Modal.tsx">
      <div className="bg-canvas">
        <div className={classNames('origin-[50%_50vh]', 'ease-out-quint overflow-hidden transition-all duration-700', modalState !== 'none' && 'scale-95 rounded-lg opacity-30 sm:scale-95')}>
          {children}
        </div>
        <SearchBar searchOpen={searchOpen} setSearchOpen={setSearchOpen} data-sentry-element="SearchBar" data-sentry-source-file="Modal.tsx">
          {search}
        </SearchBar>
        <Modal state={modalState} onClose={() => setModalState('none')} data-sentry-element="Modal" data-sentry-source-file="Modal.tsx">
          {modalChild}
        </Modal>
        <Drawer state={modalState} onClose={() => setModalState('none')} data-sentry-element="Drawer" data-sentry-source-file="Modal.tsx">
          {modalChild}
        </Drawer>
        <FullscreenForm state={modalState} onClose={() => setModalState('none')} data-sentry-element="FullscreenForm" data-sentry-source-file="Modal.tsx">
          {modalChild}
        </FullscreenForm>
      </div>
    </DialogContext.Provider>;
};
const useSearchBar = () => {
  const {
    searchOpen,
    setSearchOpen
  } = useContext(DialogContext);
  return [searchOpen, setSearchOpen] as const;
};
export { ModalContainer, useModal, useDrawer, useFullscreenForm, useSearchBar, DialogContext };