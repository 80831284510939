import moment from 'moment';
import React, { useCallback, useState } from 'react';
import { Button } from './deprecated/Button';
import { useResultDelete, useResultList, useResultRetryPost, useResultSuppressPost } from 'src/hooks/reactQuery';
import { groupBy } from 'lodash';
import { ResultListResponseDataItem, ResultListResponseDataItemActivity, ResultListResponseDataItemService } from 'src/build/operations';
import { PageLayout } from './deprecated/PageLayout';
import { Section } from './deprecated/Section';
import { ArrowPathIcon, BellSlashIcon, TrashIcon } from '@heroicons/react/20/solid';
import { Table } from './deprecated/Table';
import { CopyButton } from './deprecated/CopyButton';
import { Dialog } from '@headlessui/react';
import { StyledLink } from 'shared/components/StyledLink';
import { buildPath, ROUTES } from 'src/lib/routes';
export const SuppressButton = ({
  activity,
  name,
  service
}: {
  activity: ResultListResponseDataItemActivity;
  name: string;
  service: ResultListResponseDataItemService;
}) => {
  const resultSuppressPost = useResultSuppressPost();
  const [expiresAt, setExpiresAt] = useState('');
  const [maintainSuppressionThroughReasonChange, setMaintainSuppressionThroughReasonChange] = useState(true);
  const [modalShowing, setModalShowing] = useState(false);
  const suppressFailure = useCallback(() => {
    resultSuppressPost.mutate([{
      activity,
      name,
      service,
      expires_at: expiresAt,
      reraise_on_failure_reason_change: !maintainSuppressionThroughReasonChange
    }]);
    setModalShowing(false);
  }, [activity, name, service, resultSuppressPost, expiresAt, maintainSuppressionThroughReasonChange]);
  const setExpiresAtFromNow = useCallback((transform: (d: Date) => Date) => () => {
    setExpiresAt(transform(new Date()).toISOString().substring(0, 10));
  }, [setExpiresAt]);
  return <>
      <Button data-testid="suppressButton" onClick={() => setModalShowing(true)} data-sentry-element="Button" data-sentry-source-file="results.tsx">
        <BellSlashIcon className="h-4 w-4" data-sentry-element="BellSlashIcon" data-sentry-source-file="results.tsx" />
      </Button>

      <Dialog open={modalShowing} onClose={() => setModalShowing(false)} className={'relative z-50'} data-sentry-element="Dialog" data-sentry-source-file="results.tsx">
        <div className="bg-inverse/50 fixed inset-0 flex items-center justify-center p-4">
          <Dialog.Panel className="bg-main rounded p-4" data-sentry-element="unknown" data-sentry-source-file="results.tsx">
            <p>
              <span className="text-xs font-bold">{name}</span>
            </p>
            <div className="mt-4">
              <span className="text-main text-sm">
                <span>Suppress until: </span>
                <input data-testid="suppressUntilDate" type="date" value={expiresAt} onChange={e => setExpiresAt(e.target.value)} className="border-main w-32 rounded border px-2 py-0.5 text-sm" />
              </span>
            </div>
            <div className="mt-4 flex items-center space-x-2">
              {[{
              text: 'One week',
              transform: (d: Date) => moment(d).add(7, 'days').toDate()
            }, {
              text: 'One month',
              transform: (d: Date) => moment(d).add(1, 'month').toDate()
            }, {
              text: 'Three months',
              transform: (d: Date) => moment(d).add(3, 'months').toDate()
            }].map(({
              text,
              transform
            }) => <Button key={text} className="bg-info" onClick={setExpiresAtFromNow(transform)}>
                  <span className="text-xs">{text}</span>
                </Button>)}
            </div>
            <div className="mt-4 flex items-center space-x-2">
              <span className="text-sm">
                Maintain suppression through reason change
              </span>
              <input className="border-stronger rounded border p-1" type="checkbox" checked={maintainSuppressionThroughReasonChange} onChange={e => setMaintainSuppressionThroughReasonChange(e.target.checked)} />
            </div>
            <div className="mt-4 flex items-center space-x-2">
              <Button onClick={() => setModalShowing(false)} disabled={resultSuppressPost.isLoading} data-sentry-element="Button" data-sentry-source-file="results.tsx">
                Cancel
              </Button>
              <Button onClick={suppressFailure} data-testid="suppressSubmit" disabled={expiresAt === '' || resultSuppressPost.isLoading} data-sentry-element="Button" data-sentry-source-file="results.tsx">
                Suppress
              </Button>
            </div>

            {resultSuppressPost.isError && <div className="bg-danger text-danger mt-4 flex items-center space-x-2 p-2">
                {resultSuppressPost.error.message}
              </div>}
          </Dialog.Panel>
        </div>
      </Dialog>
    </>;
};
const ResultTable = ({
  results,
  keyboardNavigable,
  showingFailures
}: {
  keyboardNavigable: boolean;
  results: ResultListResponseDataItem[];
  showingFailures: boolean;
}) => {
  const resultRows = results.map(result => ({
    ...result
  }));
  const resultDelete = useResultDelete();
  const resultRetryPost = useResultRetryPost();
  const deleteHistory = useCallback((activity: ResultListResponseDataItemActivity, name: string, service: ResultListResponseDataItemService) => resultDelete.mutate([{
    activity,
    name,
    service
  }]), [resultDelete]);
  const triggerRetry = useCallback((result_id: string) => resultRetryPost.mutate([{
    result_id
  }]), [resultRetryPost]);
  const showSuppressButton = showingFailures;
  const columns = [];
  columns.push({
    key: 'name',
    label: 'Name',
    render: (result: ResultListResponseDataItem) => <span className="flex items-center">
        <CopyButton text={result.name} />
        <span className="ml-2">{result.name}</span>
      </span>
  });
  if (showingFailures) {
    columns.push({
      key: 'failing_since',
      label: 'Failed at',
      render: (result: ResultListResponseDataItem) => result.failing_since && moment(result.failing_since).fromNow()
    });
  }
  columns.push({
    key: 'latest_created_at',
    label: 'Last run',
    render: (result: ResultListResponseDataItem) => <StyledLink style="underline" href={buildPath(ROUTES.RESULT_STATUS, {
      resultStatusID: result.result_status_id
    })}>
        {moment(result.latest_created_at).fromNow()}
      </StyledLink>
  });
  if (!showingFailures) {
    columns.push({
      key: 'suppressed_until',
      label: 'Suppressed Until',
      render: (result: ResultListResponseDataItem) => result.suppression_expires_at
    });
  }
  columns.push({
    key: 'actions',
    label: 'Actions',
    render: (result: ResultListResponseDataItem) => {
      return <div className="flex items-center space-x-1">
          {showSuppressButton && <SuppressButton activity={result.activity} name={result.name} service={result.service} />}
          <Button data-testid="refreshButton" onClick={() => triggerRetry(result.id)}>
            <ArrowPathIcon className="h-4 w-4" />
          </Button>
          <Button data-testid="deleteButton" onClick={() => deleteHistory(result.activity, result.name, result.service)}>
            <TrashIcon className="h-4 w-4" />
          </Button>
        </div>;
    }
  });
  return <Table data={resultRows} keyboardNavigable={keyboardNavigable} canExpandRows renderExpandedRow={result => <div className="bg-stronger">
          <div className="text-main p-4 font-mono text-xs whitespace-pre-wrap">
            {result.latest_failure_reason ?? '(No failure reason given.)'}
          </div>
        </div>} columns={columns} data-sentry-element="Table" data-sentry-component="ResultTable" data-sentry-source-file="results.tsx" />;
};
export const Results = () => {
  const [showSuppressedResults, setShowSuppressedResults] = useState(false);
  const [showPassingResults, setShowPassingResults] = useState(false);
  const {
    data: results
  } = useResultList({});
  const grouped = groupBy(results?.data ?? [], r => r.latest_success ? 'passingResults' : r.suppression_expires_at ? 'suppressedResults' : 'failingResults');
  const passingResults = grouped['passingResults'] ?? [];
  const suppressedResults = grouped['suppressedResults'] ?? [];
  const failingResults = grouped['failingResults'] ?? [];
  return <PageLayout id="application.results" headline="Results" data-sentry-element="PageLayout" data-sentry-component="Results" data-sentry-source-file="results.tsx">
      <Section header={<div className="flex items-center justify-between">
            <div>Failures ({failingResults.length})</div>
          </div>} data-sentry-element="Section" data-sentry-source-file="results.tsx">
        <ResultTable keyboardNavigable={true} results={failingResults} showingFailures={true} data-sentry-element="ResultTable" data-sentry-source-file="results.tsx" />
      </Section>

      <Section header={<div className="flex items-center justify-between">
            <div>Suppressed results ({suppressedResults.length})</div>
            <Button onClick={() => setShowSuppressedResults(s => !s)}>
              {showSuppressedResults ? 'Hide suppressed results' : 'Show suppressed results'}
            </Button>
          </div>} data-sentry-element="Section" data-sentry-source-file="results.tsx">
        {showSuppressedResults && <ResultTable keyboardNavigable={false} results={suppressedResults} showingFailures={false} />}
      </Section>

      <Section header={<div className="flex items-center justify-between">
            <div>Passing results ({passingResults.length})</div>
            <Button onClick={() => setShowPassingResults(s => !s)}>
              {showPassingResults ? 'Hide passing results' : 'Show passing results'}
            </Button>
          </div>} data-sentry-element="Section" data-sentry-source-file="results.tsx">
        {showPassingResults && <ResultTable keyboardNavigable={false} results={passingResults} showingFailures={false} />}
      </Section>
    </PageLayout>;
};