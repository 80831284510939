import { TableColumns, Table, PrestyledTableProps } from '@increase/shared/components/Table';
import { useMemo } from 'react';
import { OAuthApplicationListResponseDataItem } from 'src/build/operations';
import { formatISO8601Timestamp } from '@increase/shared/lib/formatting';
import { buildPath, ROUTES } from 'src/lib/routes';
type Props = PrestyledTableProps<OAuthApplicationListResponseDataItem>;
export const OAuthApplicationsTable = (props: Props) => {
  const {
    getRowProps,
    ...rest
  } = props;
  const columns = useMemo<TableColumns<OAuthApplicationListResponseDataItem>>(() => {
    return [{
      header: 'Name',
      expand: 1,
      contents: oauthApplication => ({
        text: oauthApplication.name,
        textWeight: 'medium',
        textColor: 'emphasis'
      })
    }, {
      header: 'Group',
      expand: 1,
      contents: oauthApplication => ({
        text: oauthApplication.group_id,
        href: buildPath(ROUTES.GROUPS_DETAIL, {
          groupId: oauthApplication.group_id
        })
      })
    }, {
      header: 'Created',
      align: 'right',
      contents: oauthApplication => ({
        text: formatISO8601Timestamp(oauthApplication.created_at, 'date')
      })
    }];
  }, []);
  const defaultRowProps = (datum: OAuthApplicationListResponseDataItem) => ({
    href: buildPath(ROUTES.OAUTH_APPLICATION_DETAIL, {
      oauthApplicationID: datum.id
    }),
    className: 'hover:bg-main-hover cursor-pointer transition-all group'
  });
  return <Table<OAuthApplicationListResponseDataItem> columns={columns} getRowProps={getRowProps ?? defaultRowProps} {...rest} data-sentry-element="Table" data-sentry-component="OAuthApplicationsTable" data-sentry-source-file="OAuthApplicationsTable.tsx" />;
};