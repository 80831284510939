import React, { useMemo, useState } from 'react';
import { useOperatorGet, useTransferReviewListInfinite, useTransferReviewingRejectDirectivePost, useTransferReviewingReleaseDirectivePost } from 'src/hooks/reactQuery';
import { PageLayout } from './deprecated/PageLayout';
import { TextInput } from './deprecated/TextInput';
import { TableStateWrapper } from '@increase/shared/components/TableStateWrapper';
import { TransferReviewsTable } from './tables/TransferReviewsTable';
import { useToast } from './layout';
import { formatAmount, humanize } from '@increase/shared/lib/formatting';
import { Subheading } from '@increase/shared/components/Text';
import { Button } from '@increase/shared/components/Button';
import { TransferReviewListReasonsItem, TransferReviewListResponseDataItem, TransferReviewListResponseDataItemTransferType, TransferReviewListReviewersItem } from 'src/build/operations';
import { makeBankFilter, makeEnumFilter, makeGroupFilter } from 'src/lib/tableFilterHelpers';
import { useSearchParamsState } from '@increase/shared/hooks/useTypedSearchParamsState';
import { ROUTES } from 'src/lib/routes';
import { compact } from 'lodash';
const BULK_BATCH_SIZE = 100;
const TransferReviewSummaryToast = ({
  selectedReviews,
  onReject,
  onRelease
}: {
  selectedReviews: TransferReviewListResponseDataItem[];
  onReject: () => void;
  onRelease: () => void;
}) => {
  const {
    data: operator
  } = useOperatorGet({});
  const releaseHandler = useTransferReviewingReleaseDirectivePost();
  const rejectHandler = useTransferReviewingRejectDirectivePost();
  const [note, setNote] = useState('');
  const [acting, setActing] = useState(false);
  const release = async () => {
    setActing(true);
    for (let i = 0; i < selectedReviews.length; i++) {
      const batch = selectedReviews.slice(i, i + BULK_BATCH_SIZE);
      await Promise.all(batch.map(review => releaseHandler.mutateAsync([review.transfer_id, note ? {
        note: note
      } : {}])));
    }
    onRelease();
    setActing(false);
  };
  const reject = async () => {
    setActing(true);
    await Promise.all(selectedReviews.map(review => rejectHandler.mutateAsync([review.transfer_id, note ? {
      note: note
    } : {}])));
    onReject();
    setActing(false);
  };
  if (!operator) {
    return null;
  }
  const canEditLimits = operator.entitlements.includes('partner_program_write');
  return <div className="flex items-center space-x-3" data-sentry-component="TransferReviewSummaryToast" data-sentry-source-file="transfer-reviewing.tsx">
      <div>
        <Subheading size="small" color="primary" data-sentry-element="Subheading" data-sentry-source-file="transfer-reviewing.tsx">
          {selectedReviews.length} transfer
          {selectedReviews.length > 1 ? 's' : ''} selected, totaling{' '}
          {formatAmount(selectedReviews.reduce((sum, r) => sum + r.amount, 0), 'USD')}{' '}
        </Subheading>
      </div>
      <div className="grow" />
      <TextInput placeholder="Optional note" value={note} onChange={setNote} disabled={acting || !canEditLimits} data-sentry-element="TextInput" data-sentry-source-file="transfer-reviewing.tsx" />
      <Button style="warning" text="Reject" keyboardShortcut="⌘J" disabled={acting || !canEditLimits} onClick={reject} data-sentry-element="Button" data-sentry-source-file="transfer-reviewing.tsx" />
      <Button style="primary" text="Release" keyboardShortcut="⌘K" disabled={acting || !canEditLimits} onClick={release} data-sentry-element="Button" data-sentry-source-file="transfer-reviewing.tsx" />
    </div>;
};
const labeledTransferTypes: Record<TransferReviewListResponseDataItemTransferType, string> = Object.entries(TransferReviewListResponseDataItemTransferType).reduce((acc, [key, value]) => ({
  ...acc,
  [value]: humanize(key)
}), {} as Record<TransferReviewListResponseDataItemTransferType, string>);
const labeledReasons: Record<TransferReviewListReasonsItem, string> = {
  transfer_amount_absolute_value: 'Transfer amount absolute value',
  combined_rolling_24_hour_transfer_volume: 'Combined rolling 24 hour transfer volume',
  rolling_24_hour_volume_for_transfer_type: 'Rolling 24 hour volume for transfer type',
  credit_exposure: 'Credit exposure'
};
const labeledReviewers: Record<TransferReviewListReviewersItem, string> = {
  bank: 'Bank',
  increase: 'Increase'
};
export const TransferReviewing = () => {
  const {
    data: operator
  } = useOperatorGet({});
  const [groupFilter, setGroupFilter] = useSearchParamsState(ROUTES.TRANSFER_REVIEWING, 'group');
  const [transferTypeFilter, setTransferTypeFilter] = useSearchParamsState(ROUTES.TRANSFER_REVIEWING, 'transfer_type');
  const [reasonFilter, setReasonFilter] = useSearchParamsState(ROUTES.TRANSFER_REVIEWING, 'reason');
  const [reviewerFilter, setReviewerFilter] = useSearchParamsState(ROUTES.TRANSFER_REVIEWING, 'reviewer');
  const [bankFilter, setBankFilter] = useSearchParamsState(ROUTES.TRANSFER_REVIEWING, 'bank');
  const transferReviewListResult = useTransferReviewListInfinite({
    groups: groupFilter.length > 0 ? groupFilter : undefined,
    transfer_types: transferTypeFilter.length > 0 ? transferTypeFilter : undefined,
    reasons: reasonFilter.length > 0 ? reasonFilter : undefined,
    reviewers: reviewerFilter.length > 0 ? reviewerFilter : undefined,
    banks: bankFilter.length > 0 ? bankFilter : undefined
  });
  const [selections, setSelections] = useState<string[]>([]);
  const {
    showToast,
    closeToast
  } = useToast();
  const transferReviews = transferReviewListResult.data?.pages.flatMap(page => page.data);
  const handleRowSelection = (selected: string[]) => {
    if (!transferReviews) {
      return;
    }
    const selectedReviews = transferReviews.filter(review => selected.includes(review.id));
    if (selectedReviews.length > 0) {
      showToast(<TransferReviewSummaryToast selectedReviews={selectedReviews} onReject={() => {
        closeToast();
        setSelections([]);
      }} onRelease={() => {
        closeToast();
        setSelections([]);
      }} />);
    } else {
      closeToast();
    }
    setSelections(selected);
  };
  const filters = useMemo(() => compact([makeGroupFilter(groupFilter, setGroupFilter), operator?.multibank && makeBankFilter(bankFilter, setBankFilter), makeEnumFilter(transferTypeFilter, setTransferTypeFilter, 'Transfer Type', labeledTransferTypes, 'transfer'), makeEnumFilter(reasonFilter, setReasonFilter, 'Reason Type', labeledReasons, 'info'), operator?.multibank && makeEnumFilter(reviewerFilter, setReviewerFilter, 'Reviewer', labeledReviewers, 'user')]), [groupFilter, setGroupFilter, operator?.multibank, bankFilter, setBankFilter, transferTypeFilter, setTransferTypeFilter, reasonFilter, setReasonFilter, reviewerFilter, setReviewerFilter]);
  const selectable = useMemo(() => (row: TransferReviewListResponseDataItem): boolean => !operator?.bank ? row.reviewer === 'increase' : operator.entitlements.includes('partner_program_write'), [operator]);
  return <PageLayout headline="Transfers pending manual review" data-sentry-element="PageLayout" data-sentry-component="TransferReviewing" data-sentry-source-file="transfer-reviewing.tsx">
      <TableStateWrapper {...transferReviewListResult} style="primary" table={TransferReviewsTable} emptyTitle="No transfers pending review" emptySubtitle="There are no transfers pending review." filters={filters} selectedRows={selections} onSelectedRowsChange={handleRowSelection} selectable={selectable} data-sentry-element="TableStateWrapper" data-sentry-source-file="transfer-reviewing.tsx" />
    </PageLayout>;
};