import classNames from 'classnames';
import { Button, ButtonProps } from './Button';
import { ChevronRightIcon } from '@heroicons/react/20/solid';
import { ForwardRefExoticComponent, SVGProps, RefAttributes } from 'react';
type Item = {
  id: string;
  text: string;
  detail?: string;
  rightText?: string;
  rightDetail?: string;
  icon?: ForwardRefExoticComponent<Omit<SVGProps<SVGSVGElement>, 'ref'> & {
    title?: string | undefined;
    titleId?: string | undefined;
  } & RefAttributes<SVGSVGElement>>;
  iconClassName?: string;
  href?: string;
  rightButton?: ButtonProps;
};
type ItemListProps = {
  items: Item[];
};
export const ItemList = ({
  items
}: ItemListProps) => {
  return <div className="divide-color-main border-main flex flex-col divide-y rounded border shadow-sm" data-sentry-component="ItemList" data-sentry-source-file="ItemList.tsx">
      {items.map(item => {
      const content = <div className={classNames('flex flex-row items-center px-3 py-2', item.href && 'hover:bg-main-hover cursor-pointer')}>
            {item.icon && <item.icon className={classNames('mr-2 flex h-5 w-5 shrink-0 grow-0', item.iconClassName ?? 'text-disabled')} />}
            <div className="flex grow flex-col">
              <div className="text-strong text-sm">{item.text}</div>
              <div className="text-subtle text-xs">{item.detail}</div>
            </div>
            <div className="flex shrink-0 grow-0 flex-col">
              <div className="text-subtle text-right text-sm">
                {item.rightText}
              </div>
              {item.rightDetail && <div className="text-subtle text-right text-xs">
                  {item.rightDetail}
                </div>}
            </div>
            {item.rightButton && <Button className="self-center" {...item.rightButton} />}
            {item.href && <ChevronRightIcon className="text-disabled ml-3 h-5 w-5 shrink-0 grow-0" />}
          </div>;
      return item.href ? <a href={item.href} key={item.id}>
            {content}
          </a> : content;
    })}
    </div>;
};