import { ReactNode, createContext, useCallback, useContext, useEffect, useState } from 'react';
import { Transition } from '@headlessui/react';
import classNames from 'classnames';
import { Outlet, useNavigate } from 'react-router';
import { useOperatorGet, useSessionDelete } from 'src/hooks/reactQuery';
import { OperatorGetResponse, OperatorGetResponseEntitlementsItem, ProgramListBanksItem } from 'src/build/operations';
import { SegmentedControl } from '@increase/shared/components/SegmentedControl';
import { HrefSegment, SidebarSegmentedControl } from '@increase/shared/components/SidebarSegmentedControl';
import { Icon, IconType } from '@increase/shared/components/Icon';
import { MenuLayout } from '@increase/shared/components/MenuLayout';
import { Menu } from '@increase/shared/components/Menu';
import { TextButton } from '@increase/shared/components/TextButton';
import { getBankIconString } from 'src/lib/getBankIconString';
import { InternalOperatorOnly } from './internal-operator-only';
import { Button } from '@increase/shared/components/Button';
import { ROUTES, buildPath } from 'src/lib/routes';
import { ModalContainer } from '@increase/shared/components/Modal';
import { uniq } from 'lodash';
import { SplitLayout } from '@increase/shared/components/SplitLayout';
import { useLocalStorage } from 'react-use';
type Section = 'Metrics' | 'Data' | 'Queues' | 'Operations';
const SECTIONS: readonly {
  title: Section;
}[] = [{
  title: 'Metrics'
}, {
  title: 'Data'
}, {
  title: 'Queues'
}, {
  title: 'Operations'
}] as const;
type Route = {
  name: string;
  href: string;
  additionalRoutes?: string[];
  iconType: IconType;
  requiredEntitlements: OperatorGetResponseEntitlementsItem[];
  sectionBreak?: boolean;
  banks?: ProgramListBanksItem[];
};
const withSectionName = (routes: Route[], section: Section): (Route & {
  section: Section;
})[] => routes.map(route => ({
  ...route,
  section
}));
const getAllRoutes = (operator: OperatorGetResponse) => {
  const METRICS: Route[] = [{
    name: 'Metrics Overview',
    href: buildPath(ROUTES.MANAGEMENT_INFORMATION_SYSTEM.OVERVIEW, {}),
    additionalRoutes: [buildPath(ROUTES.MANAGEMENT_INFORMATION_SYSTEM, {})],
    iconType: 'home',
    requiredEntitlements: ['partner_overview_read']
  }, {
    name: 'ACH return rates',
    href: buildPath(ROUTES.ACH_RETURN_RATES_LIST, {}),
    iconType: 'table',
    requiredEntitlements: ['partner_overview_read']
  }];
  const DATA: Route[] = [{
    name: 'Bank programs',
    href: buildPath(ROUTES.BANK_PROGRAMS_LIST, {}),
    iconType: 'bank',
    requiredEntitlements: ['partner_group_read']
  }, {
    name: 'Groups',
    href: buildPath(ROUTES.GROUPS_LIST, {}, {
      statuses: ['active']
    }),
    iconType: 'cube',
    requiredEntitlements: ['partner_group_read']
  }, {
    name: 'Accounts',
    href: buildPath(ROUTES.ACCOUNTS_LIST, {}, {
      statuses: ['open']
    }),
    iconType: 'account',
    requiredEntitlements: ['partner_group_read']
  }, {
    name: 'Programs',
    href: buildPath(ROUTES.PROGRAMS_LIST, {}),
    additionalRoutes: ['/document_request_schedule'],
    iconType: 'folder',
    requiredEntitlements: ['partner_group_read']
  }, {
    name: 'Transactions',
    href: buildPath(ROUTES.TRANSACTIONS_LIST, {}),
    additionalRoutes: ['/pending_transactions', '/transfers', '/ach_transfers', '/card_payments'],
    iconType: 'transaction',
    requiredEntitlements: ['partner_group_read']
  }, {
    name: 'Account numbers',
    href: buildPath(ROUTES.ACCOUNT_NUMBERS_LIST, {}, {
      statuses: ['active', 'disabled']
    }),
    iconType: 'route',
    requiredEntitlements: ['partner_group_read']
  }, {
    name: 'Cards',
    href: buildPath(ROUTES.CARDS_LIST, {}, {
      statuses: ['active', 'disabled']
    }),
    iconType: 'card',
    requiredEntitlements: ['partner_group_read']
  }, {
    name: 'Lockboxes',
    href: buildPath(ROUTES.LOCKBOX_LIST, {}),
    iconType: 'lockbox',
    requiredEntitlements: ['internal_read_write']
  }, {
    name: 'Entities',
    href: buildPath(ROUTES.ENTITIES_LIST, {}),
    additionalRoutes: ['/beneficial_owners', '/entity_clusters'],
    iconType: 'user',
    requiredEntitlements: ['partner_group_read']
  }, {
    name: 'Entity clusters',
    href: buildPath(ROUTES.ENTITY_CLUSTERS_LIST, {}),
    iconType: 'users',
    requiredEntitlements: ['entity_cluster_manage'],
    sectionBreak: true
  }, {
    name: 'Late return requests',
    href: buildPath(ROUTES.LATE_RETURN_REQUESTS_LIST, {}),
    additionalRoutes: ['/late_return_request_create'],
    iconType: 'arrow_reverse',
    requiredEntitlements: ['late_return_request_read']
  }, {
    name: 'Document requests',
    href: buildPath(ROUTES.DOCUMENT_REQUESTS_LIST, {}),
    iconType: 'page',
    requiredEntitlements: ['document_request_read']
  }, {
    name: 'Bookkeeping accounts',
    href: buildPath(ROUTES.BOOKKEEPING_ACCOUNTS_LIST, {}),
    additionalRoutes: ['/bookkeeping_entry_sets'],
    iconType: 'table',
    requiredEntitlements: ['bookkeeping_read']
  }, {
    name: 'Compliance submissions',
    href: buildPath(ROUTES.PLATFORM_COMPLIANCE_SUBMISSIONS, {}),
    additionalRoutes: [buildPath(ROUTES.PLATFORM_VENDOR_LIST_SUBMISSIONS_LIST, {}), buildPath(ROUTES.PLATFORM_COMPLIANCE_METRICS_SUBMISSIONS_LIST, {}), buildPath(ROUTES.PLATFORM_COMPLAINT_LIST_SUBMISSIONS_LIST, {})],
    iconType: 'file_check',
    requiredEntitlements: ['document_request_read']
  }, {
    name: 'Complaints',
    href: buildPath(ROUTES.COMPLAINTS, {}),
    additionalRoutes: ['/platform_complaints'],
    iconType: 'message_alert',
    requiredEntitlements: ['document_request_read']
  }, {
    name: 'Vendors',
    href: buildPath(ROUTES.PLATFORM_VENDORS, {}),
    additionalRoutes: ['/platform_vendors'],
    iconType: 'building',
    requiredEntitlements: ['document_request_read']
  }, {
    name: 'Onboarding surveys',
    href: buildPath(ROUTES.ONBOARDING_SURVEYS_INDEX, {}),
    iconType: 'move_money',
    requiredEntitlements: ['document_request_read'],
    banks: ['first_internet_bank'],
    additionalRoutes: [ROUTES.RAMP_BUSINESS_ACCOUNT_ONBOARDING_SURVEYS_LIST.path, ROUTES.CONSUMER_ONBOARDING_SURVEYS_LIST.path, ROUTES.COMMERCIAL_ONBOARDING_SURVEYS_LIST.path]
  }, {
    name: 'Reserve accounts',
    href: buildPath(ROUTES.RESERVE_ACCOUNT_OVERVIEW, {}),
    iconType: 'bank',
    requiredEntitlements: ['document_request_read']
  }, {
    name: 'Controls',
    href: buildPath(ROUTES.CONTROLS_LIST, {}),
    iconType: 'lock',
    additionalRoutes: ['/control_records'],
    requiredEntitlements: ['document_request_read']
  }, {
    name: 'Unusual activity reports',
    href: buildPath(ROUTES.UNUSUAL_ACTIVITY_REPORTS, {}),
    additionalRoutes: ['/platform_unusual_activity_reports'],
    iconType: 'shield',
    requiredEntitlements: ['unusual_activity_report_read']
  }, {
    name: 'FinCEN 314(a)',
    href: buildPath(ROUTES.FINANCIAL_CRIMES_ENFORCEMENT_NETWORK_THREE_FOURTEEN_A_FILE_LIST, {}),
    additionalRoutes: [buildPath(ROUTES.FINANCIAL_CRIMES_ENFORCEMENT_NETWORK_THREE_FOURTEEN_A_PERSON_DETAIL, {
      entryId: ''
    }), buildPath(ROUTES.FINANCIAL_CRIMES_ENFORCEMENT_NETWORK_THREE_FOURTEEN_A_BUSINESS_DETAIL, {
      entryId: ''
    })],
    iconType: 'file_check',
    requiredEntitlements: ['three_fourteen_a_manage'],
    banks: ['grasshopper_bank', 'core_bank']
  }, {
    name: 'CIP Testing',
    href: '/customer_identification_program_testing_batches',
    additionalRoutes: ['/customer_identification_program_testing_entries'],
    iconType: 'user_circle',
    requiredEntitlements: ['partner_group_read'],
    sectionBreak: true
  }, {
    name: 'User sessions',
    href: buildPath(ROUTES.USER_SESSIONS_LIST, {}),
    iconType: 'activity',
    requiredEntitlements: ['internal_read_write']
  }, {
    name: 'Partner operators',
    href: buildPath(ROUTES.PARTNERS, {}),
    iconType: 'user_circle',
    requiredEntitlements: ['operator_read']
  }, {
    name: 'OAuth applications',
    href: buildPath(ROUTES.OAUTH_APPLICATION_LIST, {}),
    iconType: 'application',
    requiredEntitlements: ['internal_read_write']
  }, {
    name: 'OAuth connections',
    href: buildPath(ROUTES.OAUTH_CONNECTION_LIST, {}),
    iconType: 'key',
    requiredEntitlements: ['internal_read_write']
  }, {
    name: 'Roles',
    href: buildPath(ROUTES.ROLES_LIST, {}, {
      status: ['active']
    }),
    iconType: 'users',
    requiredEntitlements: ['internal_read_write']
  }, {
    name: 'Users',
    href: buildPath(ROUTES.USERS_LIST, {}),
    iconType: 'user',
    requiredEntitlements: ['internal_read_write']
  }];
  const QUEUES: Route[] = [{
    name: 'Group review queue',
    href: buildPath(ROUTES.GROUP_QUEUE, {}, {
      status: ['pending_actioning']
    }),
    iconType: 'cube',
    requiredEntitlements: ['internal_read_write']
  }, {
    name: 'Address reviewing',
    href: buildPath(ROUTES.ADDRESS_REVIEWING, {}),
    iconType: 'home',
    requiredEntitlements: ['entity_cluster_manage']
  }, {
    name: 'Identity documents',
    href: buildPath(ROUTES.IDENTITY_DOCUMENTS_LIST, {}),
    iconType: 'image_check',
    requiredEntitlements: ['identity_document_queue_management']
  }, {
    name: 'Transfer reviewing',
    href: buildPath(ROUTES.TRANSFER_REVIEWING, {}, operator.bank ? {} : {
      reviewer: ['increase']
    }),
    iconType: 'transfer',
    requiredEntitlements: ['partner_overview_read'],
    banks: ['grasshopper_bank', 'core_bank'],
    sectionBreak: true
  }, {
    name: 'Check deposits',
    href: buildPath(ROUTES.CHECK_DEPOSIT_REVIEW_LIST, {}),
    iconType: 'check_deposit',
    requiredEntitlements: ['check_review']
  }, {
    name: 'Inbound mail items',
    href: buildPath(ROUTES.INBOUND_MAIL_ITEM_REVIEW_LIST, {}),
    iconType: 'lockbox',
    requiredEntitlements: ['check_review']
  }, {
    name: 'Inbound check allocating',
    href: buildPath(ROUTES.CHECK_ITEM_ALLOCATING_LIST, {}),
    iconType: 'checks',
    requiredEntitlements: ['check_review']
  }, {
    name: 'Check payee names',
    href: buildPath(ROUTES.CHECK_TRANSFER_PAYEE_NAME_LIST, {}),
    iconType: 'dataflow',
    requiredEntitlements: ['check_review'],
    sectionBreak: true
  }, {
    name: 'Pending sanctions reviews',
    href: buildPath(ROUTES.PENDING_SANCTIONS_SCREENING_REVIEWS_LIST, {}),
    additionalRoutes: ['sanctions_screening_reviews'],
    iconType: 'users',
    banks: ['grasshopper_bank', 'core_bank'],
    requiredEntitlements: ['office_of_foreign_asset_control_queue_management']
  }, {
    name: 'Only entities',
    href: buildPath(ROUTES.PENDING_ENTITY_SANCTIONS_SCREENING_REVIEWS_LIST, {}),
    iconType: 'arrow_right',
    requiredEntitlements: ['office_of_foreign_asset_control_hit_read'],
    banks: ['grasshopper_bank', 'core_bank']
  }, {
    name: 'Only transfers',
    href: buildPath(ROUTES.PENDING_TRANSFER_SANCTIONS_SCREENING_REVIEWS_LIST, {}),
    iconType: 'arrow_right',
    requiredEntitlements: ['office_of_foreign_asset_control_hit_read'],
    banks: ['grasshopper_bank', 'core_bank']
  }, {
    name: 'Sanctions reviews history',
    href: buildPath(ROUTES.SANCTIONS_SCREENING_REVIEWS_LIST, {}, {}),
    additionalRoutes: ['sanctions_screening_reviews'],
    iconType: 'clock',
    requiredEntitlements: ['office_of_foreign_asset_control_queue_management', 'partner_group_read']
  }, {
    name: 'Sanctions blocklist',
    href: buildPath(ROUTES.SANCTIONS_SCREENING_TRIGGER_TOKENS_LIST, {}),
    iconType: 'settings',
    requiredEntitlements: ['office_of_foreign_asset_control_hit_read'],
    banks: ['grasshopper_bank', 'core_bank']
  }, {
    name: 'Sanctions allowlist',
    href: buildPath(ROUTES.SANCTIONS_SCREENING_ALLOWLIST_TOKENS_LIST, {}),
    iconType: 'settings',
    requiredEntitlements: ['office_of_foreign_asset_control_hit_read'],
    banks: ['grasshopper_bank', 'core_bank']
  }];
  const OPERATIONS: Route[] = [{
    name: 'Tasks',
    href: buildPath(ROUTES.TASK_STATUS_LIST, {}),
    iconType: 'folder',
    requiredEntitlements: ['internal_read_write']
  }, {
    name: 'Results',
    href: buildPath(ROUTES.RESULTS, {}),
    iconType: 'copy',
    requiredEntitlements: ['internal_read_write']
  }, {
    name: 'Results V2',
    href: buildPath(ROUTES.RESULTS_V2, {}, {
      suppression_status: ['false'],
      statuses: ['failing']
    }),
    iconType: 'copy',
    requiredEntitlements: ['internal_read_write']
  }, {
    name: 'Outbound ACH files',
    href: buildPath(ROUTES.OUTBOUND_ACH_FILES_LIST, {}),
    iconType: 'arrow_circle_out_right',
    requiredEntitlements: ['internal_read_write']
  }, {
    name: 'Cash reconciliation',
    href: buildPath(ROUTES.CASH_RECONCILIATION, {}),
    iconType: 'arrow_circle_out_left',
    requiredEntitlements: ['internal_read_write']
  }, {
    name: 'Manual tasks',
    href: buildPath(ROUTES.MANUAL_TASKS_LIST, {}),
    additionalRoutes: ['/manual_task_runs'],
    iconType: 'tick_circle',
    requiredEntitlements: ['run_manual_task']
  }, {
    name: 'ACH origination volume',
    href: buildPath(ROUTES.ACH_ORIGINATION_VOLUME_MONITORING, {}),
    iconType: 'table',
    requiredEntitlements: ['internal_read_write']
  }, {
    name: 'Card disputes',
    href: buildPath(ROUTES.CARD_DISPUTES_LIST, {}),
    iconType: 'card',
    requiredEntitlements: ['internal_read_write']
  }, {
    name: 'Card profile images',
    href: buildPath(ROUTES.CARD_PROFILE_IMAGES, {}),
    iconType: 'card',
    requiredEntitlements: ['internal_read_write']
  }, {
    name: 'Physical card images',
    href: buildPath(ROUTES.CARD_PRINTER_CARD_PROFILE_IMAGES, {}),
    iconType: 'card',
    requiredEntitlements: ['card_printer_read']
  }, {
    name: 'Real Time Payments Prefunded Position',
    href: buildPath(ROUTES.REAL_TIME_PAYMENTS_PREFUNDED_POSITION, {}),
    iconType: 'table',
    requiredEntitlements: ['partner_overview_read']
  }, {
    name: 'Fedwire messages',
    href: buildPath(ROUTES.FEDWIRE_INBOUND, {}),
    iconType: 'table',
    requiredEntitlements: ['internal_read_write']
  }, {
    name: 'Federal Reserve limits',
    href: buildPath(ROUTES.FEDERAL_RESERVE_LIMITS_LIST, {}),
    iconType: 'path',
    requiredEntitlements: ['partner_overview_read'],
    banks: ['grasshopper_bank', 'core_bank']
  }, {
    name: 'Flags',
    href: buildPath(ROUTES.FLAGS_LIST, {}),
    iconType: 'fingerprint',
    requiredEntitlements: ['internal_read_write']
  }, {
    name: 'Compliance documents',
    href: buildPath(ROUTES.COMPLIANCE_DOCUMENTS, {}),
    iconType: 'folder',
    requiredEntitlements: ['increase_compliance_documents_read']
  }, {
    name: 'Obligation documents',
    href: buildPath(ROUTES.OBLIGATION_DOCUMENTS, {}),
    iconType: 'folder',
    requiredEntitlements: ['internal_read_write']
  }, {
    name: 'Attestations',
    href: buildPath(ROUTES.ATTESTATIONS_LIST, {}),
    iconType: 'percentage',
    requiredEntitlements: ['internal_read_write']
  }, {
    name: 'External accounts',
    href: buildPath(ROUTES.EXTERNAL_ACCOUNTS_LIST, {}),
    iconType: 'arrow_circle_out_right',
    requiredEntitlements: ['internal_read_write']
  }];
  const allRoutes = [...withSectionName(METRICS, 'Metrics'), ...withSectionName(DATA, 'Data'), ...withSectionName(QUEUES, 'Queues'), ...withSectionName(OPERATIONS, 'Operations')];
  return allRoutes;
};
const ToastContext = createContext({
  toastContents: null as React.ReactNode,
  setToastContents: (_child: React.ReactNode) => {},
  toastOpen: false as boolean,
  setToastOpen: (_open: boolean) => {}
});
export const useToast = () => {
  const {
    toastOpen,
    setToastOpen,
    setToastContents
  } = useContext(ToastContext);
  const showToast = (child: ReactNode) => {
    setToastOpen(true);
    setToastContents(child);
  };
  const closeToast = () => {
    setToastOpen(false);
    setToastContents(null);
  };
  return {
    toastOpen,
    showToast,
    closeToast
  };
};
const ViewRawButton = () => {
  const navigate = useNavigate();
  const pathSegments = location.href.split('/');
  const objectIdRegex = /^((([a-z]+)_)+)([a-zA-Z0-9]{20})$/;
  const objectIdSegments = pathSegments.filter(segment => objectIdRegex.test(segment));
  const objectId = objectIdSegments.length === 1 ? objectIdSegments[0] : null;
  if (!objectId) {
    return <div />;
  }
  return <InternalOperatorOnly data-sentry-element="InternalOperatorOnly" data-sentry-component="ViewRawButton" data-sentry-source-file="layout.tsx">
      {objectId && <Button icon="code" style="secondary" size="small" text={`Raw`} onClick={() => navigate(buildPath(ROUTES.OBJECT_VIEWER, {
      objectID: objectId
    }, {
      raw: 'true'
    }))} keyboardShortcut="⌘J" />}
    </InternalOperatorOnly>;
};
const DesktopSidebar = (props: {
  routeSegments: HrefSegment[];
  selectedRouteIdx: number;
}) => {
  return <div className={classNames('border-main h-full shrink-0 space-y-4 overflow-y-scroll border-r px-3.5 pt-8 pb-16')} data-sentry-component="DesktopSidebar" data-sentry-source-file="layout.tsx">
      <div className="space-y-8">
        <SidebarSegmentedControl segments={props.routeSegments} selectedIndex={props.selectedRouteIdx} data-sentry-element="SidebarSegmentedControl" data-sentry-source-file="layout.tsx" />
      </div>
    </div>;
};
const sidebarShrinkLocalStorageKey = 'shrinkSidebar';
export function Layout() {
  const [shrink, setShrink] = useLocalStorage(sidebarShrinkLocalStorageKey, false);
  const [isMobileView, setIsMobileView] = useState(false);

  // Detect window size
  useEffect(() => {
    const handleResize = () => {
      setIsMobileView(window.innerWidth < 1024); // for example, 640px for mobile
    };

    // Add event listener
    window.addEventListener('resize', handleResize);

    // Call the function to set initial state
    handleResize();

    // Remove event listener on cleanup
    return () => window.removeEventListener('resize', handleResize);
  }, []);
  const [toastOpen, setToastOpen] = useState<boolean>(false);
  const [toastContents, setToastContents] = useState<ReactNode>(null);
  const {
    data: operator
  } = useOperatorGet({});
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const navigate = useNavigate();
  const sessionDelete = useSessionDelete();
  const logOut = useCallback(() => {
    sessionDelete.mutateAsync([{}]).then(() => window.location.assign('/'));
  }, [sessionDelete]);
  if (!operator) {
    return <div>Loading...</div>;
  }
  const allRoutes = getAllRoutes(operator);
  const routesVisibleToOperator = allRoutes.map(route => ({
    ...route,
    current: location.pathname.startsWith(route.href)
  })).filter(route => {
    const roleCanView = route.requiredEntitlements.every(entitlement => operator.entitlements.includes(entitlement));
    const bankCanView = !operator.bank || !route.banks || route.banks.includes(operator.bank);
    return roleCanView && bankCanView;
  });
  const visibleSectionNames = uniq(routesVisibleToOperator.map(route => route.section));
  const visibleSections = SECTIONS.filter(section => visibleSectionNames.includes(section.title));

  // Find the selected segment and route
  let selectedSegmentIdx = 0;
  let selectedRouteIdx = 0;
  for (const route of allRoutes) {
    const a = route.additionalRoutes ? [route.href, ...route.additionalRoutes] : [route.href];
    const allRoutesWithoutQueries = a.map(r => r.split('?')[0]);
    const anyRouteMatches = allRoutesWithoutQueries.some(r => location.pathname.startsWith(r));
    if (anyRouteMatches) {
      selectedSegmentIdx = visibleSections.findIndex(segment => segment.title === route.section);
      const routesInThisSegment = routesVisibleToOperator.filter(r => r.section === visibleSections[selectedSegmentIdx].title);
      selectedRouteIdx = routesInThisSegment.findIndex(r => r.name === route.name);
      break;
    }
  }
  const routesInCurrentSegment = routesVisibleToOperator.filter(route => route.section === visibleSections[selectedSegmentIdx].title);
  const routeSegments: HrefSegment[] = routesInCurrentSegment.map(route => ({
    href: route.href,
    label: route.name,
    key: `${route.href}_${route.name}`,
    icon: route.iconType,
    sectionBreak: route.sectionBreak
  }));
  const cleanSegments = visibleSections.map((s, i) => {
    const firstRouteInThisSection = routesVisibleToOperator.find(r => r.section === s.title);
    return {
      title: s.title,
      href: firstRouteInThisSection?.href || '/',
      selected: i === selectedSegmentIdx
    };
  });
  const bankIconString = getBankIconString(operator.bank);
  return <ModalContainer searchOpen={false} setSearchOpen={() => {}} data-sentry-element="ModalContainer" data-sentry-component="Layout" data-sentry-source-file="layout.tsx">
      <ToastContext.Provider value={{
      toastContents,
      toastOpen,
      setToastOpen,
      setToastContents
    }} data-sentry-element="unknown" data-sentry-source-file="layout.tsx">
        <div className="bg-main overflow-hidden">
          <div className="dark border-main bg-main fixed inset-x-0 top-0 z-20 flex w-full items-center space-x-4 border-b px-4 py-3">
            <Menu button={<div className="size-7 shrink-0 cursor-pointer overflow-hidden rounded">
                  <img className="h-auto w-full" alt="Bank Icon" src={bankIconString} />
                </div>} align="left" data-sentry-element="Menu" data-sentry-source-file="layout.tsx">
              <MenuLayout items={[{
              key: 'identity',
              icon: 'user',
              textColor: 'secondary',
              title: operator.email
            }, {
              key: 'signout',
              icon: 'signout',
              textColor: 'primary',
              title: 'Sign out',
              onClick: logOut
            }]} data-sentry-element="MenuLayout" data-sentry-source-file="layout.tsx" />
            </Menu>
            <div className="hidden md:block">
              <SegmentedControl segments={cleanSegments} style="ghost" data-sentry-element="SegmentedControl" data-sentry-source-file="layout.tsx" />
            </div>
            <div className="grow" />
            <ViewRawButton data-sentry-element="ViewRawButton" data-sentry-source-file="layout.tsx" />
            <TextButton icon="search" text="Search" onClick={() => navigate('/search')} data-sentry-element="TextButton" data-sentry-source-file="layout.tsx" />

            <TextButton icon={sidebarOpen ? 'close' : 'menu'} className={classNames('md:hidden', 'transition-all')} onClick={() => setSidebarOpen(!sidebarOpen)} data-sentry-element="TextButton" data-sentry-source-file="layout.tsx" />
          </div>

          {/* Mobile menu, hidden on desktop */}
          <div className={classNames('border-main bg-main fixed inset-y-0 z-30 h-screen w-[calc(100%-3rem)] max-w-xs space-y-6 overflow-y-scroll border-r px-4 pt-4 pb-16 shadow-lg transition-all md:hidden', sidebarOpen ? '' : '-translate-x-full')}>
            <SegmentedControl segments={cleanSegments} data-sentry-element="SegmentedControl" data-sentry-source-file="layout.tsx" />
            <SidebarSegmentedControl segments={routeSegments} selectedIndex={selectedRouteIdx} data-sentry-element="SidebarSegmentedControl" data-sentry-source-file="layout.tsx" />
          </div>

          {/* Backdrop behind mobile menu */}
          <Transition show={sidebarOpen} enter="transition-opacity duration-75" enterFrom="opacity-0" enterTo="opacity-100" leave="transition-opacity duration-150" leaveFrom="opacity-100" leaveTo="opacity-0" data-sentry-element="Transition" data-sentry-source-file="layout.tsx">
            <div className={classNames('bg-canvas/50 fixed inset-0 z-20')} onClick={() => {
            if (sidebarOpen) {
              setSidebarOpen(false);
            }
          }} />
          </Transition>

          {/* Desktop menu, hidden on mobile */}
          <div className="relative z-0">
            <SplitLayout left={<div className="h-full min-h-screen pt-[57px]">
                  <DesktopSidebar routeSegments={routeSegments} selectedRouteIdx={selectedRouteIdx} />
                </div>} right={<div className="relative min-h-screen pt-[57px]">
                  <Outlet />
                  {toastOpen && <div className="border-main bg-subtle absolute inset-x-0 bottom-0 z-50 border-t px-8 pt-8 pb-12">
                      {toastContents}
                    </div>}
                </div>} size={isMobileView ? 'minimized' : shrink ? 'minimized' : 'x-large'} data-sentry-element="SplitLayout" data-sentry-source-file="layout.tsx" />
            <div onClick={() => setShrink(!shrink)} className="fixed bottom-0 left-0 z-40 hidden w-[61px] bg-transparent px-2 pb-2 md:block">
              <div className="bg-stronger hover:bg-strong flex flex-col items-center justify-center overflow-hidden rounded pt-2">
                <Icon name={shrink ? 'caret_right' : 'caret_left'} data-sentry-element="Icon" data-sentry-source-file="layout.tsx" />
                <Button onClick={() => setShrink(!shrink)} keyboardShortcut="⌘B" style="transparent" size="small" className="px-0" data-sentry-element="Button" data-sentry-source-file="layout.tsx" />
              </div>
            </div>
          </div>
        </div>
      </ToastContext.Provider>
    </ModalContainer>;
}