import { usePlatformVendorListSubmissionGet } from 'src/hooks/reactQuery';
import { PageLayout } from './deprecated/PageLayout';
import { Table } from './deprecated/Table';
import { StyledLink } from './deprecated/StyledLink';
import { useTypedParams } from 'react-router-typesafe-routes/dom';
import { ROUTES } from 'src/lib/routes';
export const PlatformVendorListSubmission = () => {
  const {
    submissionID
  } = useTypedParams(ROUTES.PLATFORM_VENDOR_LIST_SUBMISSIONS_DETAIL);
  const {
    data: submission
  } = usePlatformVendorListSubmissionGet(submissionID);
  if (!submission) {
    return <></>;
  }
  return <PageLayout id="platform-vendor-list-submission" headline="Platform Vendor List Submission" data-sentry-element="PageLayout" data-sentry-component="PlatformVendorListSubmission" data-sentry-source-file="platform-vendor-list-submission.tsx">
      <Table data={submission.vendors} columns={[{
      key: 'name',
      label: 'Name',
      render: row => <StyledLink to={row.website}>{row.name}</StyledLink>
    }, {
      key: 'added_on',
      label: 'Added On'
    }, {
      key: 'removed_on',
      label: 'Removed On'
    }, {
      key: 'last_reviewed_on',
      label: 'Last Reviewed On'
    }, {
      key: 'risk_level',
      label: 'Risk Level'
    }, {
      key: 'diligence_review_result',
      label: 'Diligence Review Result'
    }]} canExpandRows={true} renderExpandedRow={row => <div className="bg-strong space-y-2 p-4 text-sm">
            {row.description}
          </div>} data-sentry-element="Table" data-sentry-source-file="platform-vendor-list-submission.tsx" />
    </PageLayout>;
};