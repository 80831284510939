import { TableColumns, Table, PrestyledTableProps } from '@increase/shared/components/Table';
import { useMemo } from 'react';
import { EntityGetResponseSetupSubmissionStructureCorporationAnyOfCobaltIntelligenceSecretaryOfStateSearchIntentsItem as SearchIntentItem, EntityGetResponseSetupSubmissionStructureCorporationAnyOfCobaltIntelligenceSecretaryOfStateSearchIntentsItemOutcome as Outcome } from 'src/build/operations';
import { humanize, formatISO8601Timestamp } from '@increase/shared/lib/formatting';
import { buildPath, ROUTES } from 'src/lib/routes';
import { BadgeColor } from '@increase/shared/components/Badge';
type Props = PrestyledTableProps<SearchIntentItem>;
const badgeColor: Record<NonNullable<Outcome>, BadgeColor> = {
  found: 'green',
  not_found: 'gray'
};
export const CobaltIntelligenceSecretaryOfStateSearchIntentsTable = (props: Props) => {
  const {
    getRowProps,
    ...rest
  } = props;
  const columns = useMemo<TableColumns<SearchIntentItem>>(() => {
    return [{
      header: 'ID',
      contents: result => ({
        text: result.id,
        textColor: 'emphasis',
        textWeight: 'medium'
      })
    }, {
      header: 'Outcome',
      contents: result => ({
        text: result.outcome ? humanize(result.outcome) : '-',
        badgeColor: result.outcome ? badgeColor[result.outcome] : null
      })
    }, {
      header: 'Requested At',
      contents: result => ({
        text: formatISO8601Timestamp(result.created_at, 'date'),
        tooltip: formatISO8601Timestamp(result.created_at, 'time')
      })
    }, {
      header: 'Link',
      contents: result => ({
        text: 'view',
        href: buildPath(ROUTES.ENTITY_SECRETARY_OF_STATE_SEARCH_INTENT, {
          secretaryOfStateSearchIntentId: result.id
        })
      })
    }];
  }, []);
  const defaultRowProps = (_datum: SearchIntentItem) => ({
    className: 'hover:bg-main-hover cursor-pointer transition-all group'
  });
  return <Table<SearchIntentItem> columns={columns} getRowProps={getRowProps ?? defaultRowProps} {...rest} data-sentry-element="Table" data-sentry-component="CobaltIntelligenceSecretaryOfStateSearchIntentsTable" data-sentry-source-file="CobaltIntelligenceSecretaryOfStateSearchIntentsTable.tsx" />;
};