import React from 'react';
import { useCompletedTransactionListInfinite, useLockboxGet, usePendingTransactionListInfinite } from 'src/hooks/reactQuery';
import { PageLayout } from '../deprecated/PageLayout';
import { useTypedParams } from 'react-router-typesafe-routes/dom';
import { buildPath, ROUTES } from 'src/lib/routes';
import { PropertyList } from 'shared/components/PropertyList';
import { humanize } from 'shared/lib/formatting';
import { SideBySide } from 'shared/components/SideBySide';
import { TableStateWrapper } from 'shared/components/TableStateWrapper';
import { CompletedTransactionsTable } from '../tables/CompletedTransactionTable';
import { PendingTransactionsTable } from '../tables/PendingTransactionsTable';
export const LockboxDetailPage = () => {
  const {
    lockboxId
  } = useTypedParams(ROUTES.LOCKBOX_DETAIL);
  const {
    data: lockbox
  } = useLockboxGet(lockboxId);
  const transactionList = useCompletedTransactionListInfinite({
    lockboxes: [lockboxId]
  });
  const pendingTransactionList = usePendingTransactionListInfinite({
    route_ids: [lockboxId],
    limit: 10
  });
  if (!lockbox) {
    return <div>Loading...</div>;
  }
  return <PageLayout headline={lockbox.description || lockbox.id} data-sentry-element="PageLayout" data-sentry-component="LockboxDetailPage" data-sentry-source-file="LockboxDetailPage.tsx">
      <SideBySide mode="rightDrawer" right={<PropertyList items={[{
      label: 'ID',
      value: lockbox.id
    }, {
      label: 'Created at',
      value: lockbox.created_at,
      format: 'month-day-year-hour-minute-second'
    }, {
      label: 'Status',
      value: humanize(lockbox.status)
    }, {
      label: 'Description',
      value: lockbox.description || ''
    }]} title="Details" />} left={<>
            <TableStateWrapper table={PendingTransactionsTable} style="detail" {...pendingTransactionList} title="Pending Transactions" emptyTitle="No pending transactions" emptySubtitle="There are no pending transactions to display" viewMoreHref={buildPath(ROUTES.PENDING_TRANSACTIONS_LIST, {}, {
        lockbox: [lockboxId]
      })} showAccount={false} showGroup={false} showRoute={false} />
            <TableStateWrapper table={CompletedTransactionsTable} style="detail" {...transactionList} title="Completed Transactions" emptyTitle="No transactions" emptySubtitle="There are no transactions to display" viewMoreHref={buildPath(ROUTES.TRANSACTIONS_LIST, {}, {
        lockbox: [lockboxId]
      })} showAccount={false} showGroup={false} showRoute={false} />
          </>} data-sentry-element="SideBySide" data-sentry-source-file="LockboxDetailPage.tsx" />
    </PageLayout>;
};