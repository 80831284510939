import React, { useMemo, useState } from 'react';
import { PageLayout } from '../deprecated/PageLayout';
import { useOperatorGet, useSanctionsScreeningAllowlistTokenListInfinite, useSanctionsScreeningAllowlistTokenPost } from 'src/hooks/reactQuery';
import { TableStateWrapper } from '@increase/shared/components/TableStateWrapper';
import { SanctionsScreeningAllowlistTokensTable } from '../tables/SanctionsScreeningAllowlistTokensTable';
import { Button } from '@increase/shared/components/Button';
import { useDrawer } from '@increase/shared/components/Modal';
import { DrawerLayout } from '@increase/shared/components/DrawerLayout';
import { Box } from '@increase/shared/components/Box';
import { Body } from '@increase/shared/components/Text';
import { TextInput } from '@increase/shared/components/TextInput';
import { ROUTES } from 'src/lib/routes';
import { useSearchParamsState } from 'shared/hooks/useTypedSearchParamsState';
import { makeEnumFilter } from 'src/lib/tableFilterHelpers';
import { compact } from 'lodash';
import { TableFilter } from 'shared/components/Table/filters';
import { AlertList } from 'shared/components/AlertList';
import { TextArea } from 'shared/components/TextArea';
const Drawer = (props: {
  onClose: () => void;
}) => {
  const mutate = useSanctionsScreeningAllowlistTokenPost();
  const [token, setToken] = useState('');
  const [note, setNote] = useState('');
  return <DrawerLayout title="Create new word or phrase" body={<Box>
          <Body color="secondary">
            Adding a word or phrase to the list will cause future screening to{' '}
            <b>not alert on</b> any transaction that matches exactly the word or
            phrase. Capitalization does not matter.
          </Body>

          <TextInput value={token} onChange={e => setToken(e.target.value)} placeholder="Allowed word or phrase" />
          <TextArea value={note} onChange={e => setNote(e.target.value)} placeholder="Note" />
        </Box>} rightButton={<Button icon="create" text="Save" onClick={async () => {
    await mutate.mutateAsync([{
      token,
      note
    }]);
    props.onClose();
  }} style="warning" disabled={mutate.isLoading || token.length === 0 || note.length === 0} />} data-sentry-element="DrawerLayout" data-sentry-component="Drawer" data-sentry-source-file="SanctionsScreeningAllowlistTokenListPage.tsx" />;
};
export const SanctionsScreeningAllowlistTokenListPage = () => {
  const [reviewers, setReviewers] = useSearchParamsState(ROUTES.SANCTIONS_SCREENING_ALLOWLIST_TOKENS_LIST, 'reviewer');
  const {
    data: operator
  } = useOperatorGet({});
  const multibank = !operator?.bank;
  const filters: TableFilter[] = useMemo(() => {
    return compact([multibank && makeEnumFilter(reviewers, setReviewers, 'Reviewer', {
      grasshopper: 'Grasshopper Bank',
      core_bank: 'Core Bank',
      increase: 'Increase'
    }, 'users')]);
  }, [reviewers, setReviewers, multibank]);
  const listResult = useSanctionsScreeningAllowlistTokenListInfinite({
    reviewers: reviewers.length > 0 ? reviewers : undefined
  });
  const {
    showDrawer,
    closeDrawer
  } = useDrawer();
  return <PageLayout headline={'OFAC Ignored Words'} data-sentry-element="PageLayout" data-sentry-component="SanctionsScreeningAllowlistTokenListPage" data-sentry-source-file="SanctionsScreeningAllowlistTokenListPage.tsx">
      <AlertList tasks={compact([operator?.bank === 'grasshopper_bank' && {
      icon: 'alert',
      title: 'Feature not active',
      body: 'This feature is not active for Grasshopper Bank.',
      style: 'warning',
      key: '0'
    }, {
      icon: 'info',
      title: 'About',
      body: ['Use this tool to ignore consistent false positives.', 'This is called a "good guy list" in other tools.', 'If a screened field exactly matches the token and would have otherwise triggered an alert, this configuration will silence it.'].join(' '),
      style: 'informational',
      key: '0'
    }])} data-sentry-element="AlertList" data-sentry-source-file="SanctionsScreeningAllowlistTokenListPage.tsx" />

      <TableStateWrapper action={<Button style="primary" text="Create" icon="edit" disabled={operator?.bank === 'grasshopper_bank'} onClick={() => showDrawer(<Drawer onClose={closeDrawer} />)} />} table={SanctionsScreeningAllowlistTokensTable} style="primary" {...listResult} emptyTitle="No OFAC ignored phrases" emptySubtitle="Phrases here will not trigger OFAC alerts. Use this to ignore consistent false positives." filters={filters} data-sentry-element="TableStateWrapper" data-sentry-source-file="SanctionsScreeningAllowlistTokenListPage.tsx" />
    </PageLayout>;
};