import { ReactNode } from 'react';
import { OperatorGetResponseEntitlementsItem } from 'src/build/operations';
import { useOperatorGet } from 'src/hooks/reactQuery';
type Props = {
  entitlements: OperatorGetResponseEntitlementsItem[];
  children: ReactNode;
};
export const RequireEntitlements = ({
  entitlements,
  children
}: Props) => {
  const {
    data: operator
  } = useOperatorGet({});
  if (operator) {
    const hasAllEntitlements = entitlements.every(entitlement => operator.entitlements.includes(entitlement));
    if (hasAllEntitlements) {
      return <>{children}</>;
    }
  }
  return null;
};