import React from 'react';
import { PageLayout } from './deprecated/PageLayout';
import { useRoutingNumberListInfinite } from 'src/hooks/reactQuery';
import { Table } from './deprecated/Table';
import { StyledLink } from './deprecated/StyledLink';
import { CheckIcon, XMarkIcon } from '@heroicons/react/20/solid';
import { useSearchParams } from 'react-router-dom';
import { Switch } from '@increase/shared/components/Switch';
import { IntersectionObserver } from '@increase/shared/components/IntersectionObserver';
export const RoutingNumberList = () => {
  const [queryParams] = useSearchParams();
  const [showOnlyNeedsWebsite, setShowOnlyNeedsWebsite] = React.useState(false);
  const [showOnlyNeedsIcon, setShowOnlyNeedsIcon] = React.useState(false);
  const [filterByRoutingNumber, setFilterByRoutingNumber] = React.useState(queryParams.get('routing_number') ?? '');
  const {
    data: routingNumberResponse,
    fetchNextPage
  } = useRoutingNumberListInfinite({
    needs_website: showOnlyNeedsWebsite,
    needs_icon: showOnlyNeedsIcon,
    // ignore empty strings
    routing_number: filterByRoutingNumber ? filterByRoutingNumber : undefined
  });
  if (!routingNumberResponse) {
    return <></>;
  }
  const routingNumbers = routingNumberResponse.pages.map(p => p.data).flat();
  return <PageLayout headline="Routing Numbers" data-sentry-element="PageLayout" data-sentry-component="RoutingNumberList" data-sentry-source-file="routing-number-list.tsx">
      <div className="space-y-1">
        <div className="font-medium">Filters</div>
        <div className="grid grid-cols-4 gap-4">
          <div>
            <input key="routing_number" type="text" className="border-strong w-full rounded border" placeholder="Routing number" value={filterByRoutingNumber} onChange={e => setFilterByRoutingNumber(e.target.value)} />
          </div>
          <div className="flex items-center space-x-2">
            <Switch checked={showOnlyNeedsWebsite} onChange={setShowOnlyNeedsWebsite} data-sentry-element="Switch" data-sentry-source-file="routing-number-list.tsx" />
            <div>Needs website</div>
          </div>
          <div className="flex items-center space-x-2">
            <Switch checked={showOnlyNeedsIcon} onChange={setShowOnlyNeedsIcon} data-sentry-element="Switch" data-sentry-source-file="routing-number-list.tsx" />
            <div>Needs icon</div>
          </div>
        </div>
      </div>
      <Table data={routingNumbers} columns={[{
      key: 'routing_number',
      label: 'Routing Number'
    }, {
      key: 'financial_institution_icon_url',
      label: 'Icon',
      render: record => {
        if (!record.financial_institution_icon_url) {
          return null;
        }
        return <img src={record.financial_institution_icon_url} className="h-4 w-4" alt="icon" />;
      }
    }, {
      key: 'name',
      label: 'Name'
    }, {
      key: 'financial_institution_website',
      label: 'Website',
      render: record => {
        if (!record.financial_institution_website) {
          return null;
        }
        return <StyledLink to={record.financial_institution_website} target="_blank">
                  {record.financial_institution_website}
                </StyledLink>;
      }
    }, {
      key: 'fedach_enabled',
      label: 'ACH',
      render: record => {
        return record.fedach_enabled ? <CheckIcon className="h-4 w-4" /> : <XMarkIcon className="h-4 w-4" />;
      }
    }, {
      key: 'fedwire_enabled',
      label: 'Wire',
      render: record => {
        return record.fedwire_enabled ? <CheckIcon className="h-4 w-4" /> : <XMarkIcon className="h-4 w-4" />;
      }
    }, {
      key: 'real_time_payments_enabled',
      label: 'RTP',
      render: record => {
        return record.real_time_payments_enabled ? <CheckIcon className="h-4 w-4" /> : <XMarkIcon className="h-4 w-4" />;
      }
    }]} data-sentry-element="Table" data-sentry-source-file="routing-number-list.tsx" />
      <IntersectionObserver onVisible={fetchNextPage} data-sentry-element="IntersectionObserver" data-sentry-source-file="routing-number-list.tsx" />
    </PageLayout>;
};