import { usePlatformFinancialReportSubmissionGet } from 'src/hooks/reactQuery';
import { PageLayout } from './deprecated/PageLayout';
import { formatAmount } from '@increase/shared/lib/formatting';
import { useTypedParams } from 'react-router-typesafe-routes/dom';
import { ROUTES } from 'src/lib/routes';
import { ListItem, PropertyList } from '@increase/shared/components/PropertyList';
import { useGroupPropertyRow } from 'src/lib/propertyListHelpers';
import { compact } from 'lodash';
export const PlatformFinancialReportSubmission = () => {
  const {
    submissionID
  } = useTypedParams(ROUTES.PLATFORM_FINANCIAL_REPORT_SUBMISSIONS_DETAIL);
  const {
    data: submission
  } = usePlatformFinancialReportSubmissionGet(submissionID);
  const groupRow = useGroupPropertyRow(submission?.group_id);
  if (!submission) {
    return <></>;
  }
  return <PageLayout headline="Platform Financial Report Submission" data-sentry-element="PageLayout" data-sentry-component="PlatformFinancialReportSubmission" data-sentry-source-file="platform-financial-report-submission.tsx">
      <PropertyList items={compact<ListItem>([{
      label: 'Submission ID',
      value: submissionID
    }, groupRow, {
      label: 'Submission at',
      value: submission.created_at,
      format: 'month-day-year-hour-minute-second'
    }, submission.created_by_email && {
      label: 'Created by',
      value: submission.created_by_email
    }, {
      label: 'Period',
      value: `${submission.period_start} - ${submission.period_end}`
    }, {
      label: 'Balance sheet',
      value: submission.period_balance_sheet_file_id,
      href: `https://operations.increase.com/api_files/${submission.period_balance_sheet_file_id}/view`
    }, {
      label: 'Income statement',
      value: submission.period_income_statement_file_id,
      href: `https://operations.increase.com/api_files/${submission.period_income_statement_file_id}/view`
    }, {
      label: 'Cash on hand',
      value: formatAmount(submission.cash_on_hand_last_day_of_period, 'USD')
    }, {
      label: 'Period net income',
      value: formatAmount(submission.total_net_income_for_period, 'USD')
    }, {
      label: 'Fewer than 4 quarters of runway left?',
      value: submission.fewer_than_four_quarters_of_runway ? 'Yes' : 'No',
      badgeColor: submission.fewer_than_four_quarters_of_runway ? 'red' : undefined
    }])} data-sentry-element="PropertyList" data-sentry-source-file="platform-financial-report-submission.tsx"></PropertyList>
    </PageLayout>;
};