import { BadgeColor } from '@increase/shared/components/Badge';
import { TableColumns, Table, PrestyledTableProps } from '@increase/shared/components/Table';
import { useMemo } from 'react';
import { RoleListResponseDataItem, RoleListResponseDataItemTitle, userImpersonatePost } from 'src/build/operations';
import { formatISO8601Timestamp, humanize } from '@increase/shared/lib/formatting';
import { useServices } from 'src/hooks/use-services';
import { buildPath, ROUTES } from 'src/lib/routes';
type Props = PrestyledTableProps<RoleListResponseDataItem> & {
  showGroup: boolean;
  showActions: boolean;
};
const formattedTitle: Record<RoleListResponseDataItemTitle, string> = {
  administrator: 'Administrator',
  owner: 'Owner',
  developer: 'Developer',
  controller: 'Controller',
  clerk: 'Clerk',
  compliance_v2: 'Compliance',
  accountant: 'Accountant',
  viewer: 'Viewer'
};
const roleEmailVerificationStatusBadgeColors: Record<RoleListResponseDataItem['email_verification_status'], BadgeColor> = {
  pending_verification: 'yellow',
  requires_attention: 'yellow',
  ignored: 'yellow',
  verified: 'green'
};
export const RolesTable = (props: Props) => {
  const {
    getRowProps,
    showGroup,
    showActions,
    ...rest
  } = props;
  const services = useServices();
  const columns = useMemo<TableColumns<RoleListResponseDataItem>>(() => [{
    header: 'Email',
    expand: 1,
    contents: role => ({
      text: role.email,
      textWeight: 'medium',
      textColor: 'emphasis',
      href: '/users/' + role.user_id
    })
  }, {
    header: 'Title',
    expand: 1,
    contents: role => ({
      text: formattedTitle[role.title]
    })
  }, showGroup && {
    header: 'Group',
    contents: role => ({
      text: role.group_name || role.group_id,
      href: buildPath(ROUTES.GROUPS_DETAIL, {
        groupId: role.group_id
      })
    })
  }, {
    header: 'Email verification',
    contents: role => ({
      text: humanize(role.email_verification_status),
      badgeColor: roleEmailVerificationStatusBadgeColors[role.email_verification_status]
    })
  }, {
    header: 'Created',
    contents: role => ({
      text: formatISO8601Timestamp(role.created_at, 'date')
    })
  }, {
    header: 'Deleted',
    contents: role => ({
      badgeColor: role.deleted_at ? 'red' : undefined,
      text: role.deleted_at ? formatISO8601Timestamp(role.deleted_at, 'date') : ''
    })
  }, {
    header: 'Latest session',
    contents: role => ({
      text: role.latest_session ? formatISO8601Timestamp(role.latest_session.created_at, 'date') : '-'
    })
  }, showActions && {
    header: 'Actions',
    align: 'right',
    contents: role => ({
      menuItems: [{
        title: 'View as user',
        key: 'impersonate',
        onClick: role.deleted_at ? undefined : () => {
          userImpersonatePost(role.user_id).then(() => window.open(services.dashboard, '_blank'));
        }
      }]
    })
  }], [services.dashboard, showActions, showGroup]);
  return <Table<RoleListResponseDataItem> columns={columns} getRowProps={getRowProps} {...rest} data-sentry-element="Table" data-sentry-component="RolesTable" data-sentry-source-file="RolesTable.tsx" />;
};