import React from 'react';
import { useExternalAccountListInfinite, useOperatorGet, useSanctionsScreeningReviewListInfinite, useTransferGet } from 'src/hooks/reactQuery';
import { PageLayout } from '../deprecated/PageLayout';
import { CompletedTransactionListResponseDataItem, OperatorGetResponse, SanctionsScreeningReviewListResponseDataItem, TransferGetResponse, TransferGetResponseTransferReviewAnyOf, TransferGetResponseTransferType } from 'src/build/operations';
import { absurd } from 'src/lib/absurd';
import { SideBySide } from '@increase/shared/components/SideBySide';
import { TransferPropertyList } from '../property-lists/TransferPropertyList';
import { TableStateWrapper } from '@increase/shared/components/TableStateWrapper';
import { CompletedTransactionsTable, CompletedTransactionsTableProps } from '../tables/CompletedTransactionTable';
import { Note } from '@increase/shared/components/Note';
import { Body } from '@increase/shared/components/Text';
import { Icon } from '@increase/shared/components/Icon';
import { Box } from '@increase/shared/components/Box';
import { ListItem, PropertyList } from '@increase/shared/components/PropertyList';
import { formatAmount, humanize } from '@increase/shared/lib/formatting';
import { compact } from 'lodash';
import { buildPath, ROUTES } from 'src/lib/routes';
import { useTypedParams } from 'react-router-typesafe-routes/dom';
import { OperatorNotes } from '../operator-notes';
import { SanctionsScreeningReviewsTable } from '../tables/SanctionScreeningReviewsTable';
import { AssociatedManualTasks } from '../associated-manual-tasks';
import { InternalOperatorOnly } from '../internal-operator-only';
import { useServices } from 'src/hooks/use-services';
import { extractSourceFields } from 'src/lib/sourceHelpers';
import { useNavigate } from 'react-router-dom';
const ExternalAccountDetails = (props: {
  routingNumber: string;
  accountNumber: string;
}) => {
  const {
    data
  } = useExternalAccountListInfinite({
    routing_number: props.routingNumber,
    account_number: props.accountNumber,
    limit: 1
  });
  const externalAccount = data?.pages[0]?.data?.[0];
  return <PropertyList title="External account" items={[{
    label: 'Routing number',
    value: props.routingNumber
  }, {
    label: 'Account number',
    value: props.accountNumber
  }, {
    label: 'Details',
    value: externalAccount ? 'See details' : 'Not available',
    href: externalAccount ? buildPath(ROUTES.EXTERNAL_ACCOUNTS_DETAILS, {
      externalAccountID: externalAccount.id
    }) : undefined
  }]} data-sentry-element="PropertyList" data-sentry-component="ExternalAccountDetails" data-sentry-source-file="TransferDetailPage.tsx" />;
};
const SanctionsHitList = ({
  transferId
}: {
  transferId: string;
}) => {
  const sanctionsListResult = useSanctionsScreeningReviewListInfinite({
    record_ids: [transferId],
    limit: 10
  });
  const getRowProps = (datum: SanctionsScreeningReviewListResponseDataItem) => ({
    href: buildPath(ROUTES.SANCTIONS_SCREENING_REVIEW_DETAIL, {
      reviewID: datum.id
    }),
    className: 'hover:bg-main-hover cursor-pointer transition-all group'
  });
  return <TableStateWrapper {...sanctionsListResult} table={SanctionsScreeningReviewsTable} style="detail" title="Sanctions screening reviews" emptyTitle="No sanctions screening manual review" emptySubtitle="No manual review required. Did not raise a hit." viewMoreHref={buildPath(ROUTES.SANCTIONS_SCREENING_REVIEWS_LIST, {}, {
    records: [transferId]
  })} showRecordId={false} getRowProps={getRowProps} data-sentry-element="TableStateWrapper" data-sentry-component="SanctionsHitList" data-sentry-source-file="TransferDetailPage.tsx" />;
};
const isSanctionsScreenedTransfer = (transfer: TransferGetResponse): boolean => transfer.transfer_type === 'wire_transfer' || transfer.transfer_type === 'inbound_wire_transfer' || transfer.transfer_type === 'inbound_ach_transfer' && (transfer.transfer_instruction as {
  standard_entry_class_code: string;
}).standard_entry_class_code === 'international_ach_transaction';
const summarize = (type: TransferGetResponseTransferType, amount: number): string => {
  switch (type) {
    case 'account_transfer':
      return 'A transfer between two Increase accounts.';
    case 'check_deposit':
      return 'A remote check deposit. The Increase User uploaded a photo of a check.';
    case 'check_transfer':
      return 'A printed and mailed check sent from an Increase User to their counterparty.';
    case 'ach_transfer':
      return amount > 0 ? 'An ACH credit originated by Increase. Funds are leaving Increase.' : 'An ACH debit originated by Increase. Funds are entering Increase.';
    case 'inbound_ach_transfer':
      return amount > 0 ? 'An ACH credit originated by another bank, received at Increase. Funds are entering Increase.' : 'An ACH debit originated by another bank, received at Increase. Funds are leaving Increase';
    case 'real_time_payments_transfer':
      return 'A Real Time Payments transfer originated by Increase.';
    case 'inbound_real_time_payments_transfer':
      return 'An Real Time Payments transfer originated by another bank, received at Increase. Funds are entering Increase.';
    case 'inbound_wire_transfer':
      return 'An Wire Transfer originated by another bank, received at Increase. Funds are entering Increase.';
    case 'wire_transfer':
      return 'A Wire Transfer originated by Increase.';
    default:
      absurd(type);
  }
};
const canReviewTransfer = (operator: OperatorGetResponse, transfer: TransferGetResponse, transferReview: TransferGetResponseTransferReviewAnyOf): boolean => {
  if (!operator.entitlements.includes('partner_program_write')) {
    return false;
  }
  switch (transferReview.reviewer) {
    case 'increase':
      return operator.bank === null;
    case 'bank':
      return operator.bank === transfer.bank;
    default:
      absurd(transferReview.reviewer);
  }
};
export const TransferDetailPage = () => {
  const {
    transferID
  } = useTypedParams(ROUTES.TRANSFER_DETAIL);
  const {
    operations
  } = useServices();
  const navigate = useNavigate();
  const {
    data: transfer
  } = useTransferGet(transferID);
  const {
    data: operator
  } = useOperatorGet({});
  if (!transfer || !operator) {
    return <>Loading...</>;
  }
  if (transfer.transfer_type === 'ach_transfer') {
    navigate(buildPath(ROUTES.ACH_TRANSFER_DETAIL, {
      achTransferID: transferID
    }), {
      replace: true
    });
  }
  const externalAccountDetails = transfer.transfer_instruction as {
    routing_number?: string;
    account_number?: string;
  };
  return <PageLayout id="application.transferDetails" headline={transferID} data-sentry-element="PageLayout" data-sentry-component="TransferDetailPage" data-sentry-source-file="TransferDetailPage.tsx">
      <SideBySide right={<>
            <TransferPropertyList title="Details" id={transferID} transfer={transfer} />
            <AssociatedManualTasks objectId={transferID} />
          </>} left={<>
            <Note>
              <Box flex center gap="2">
                <Icon name="info" />
                <Body>
                  {summarize(transfer.transfer_type, transfer.amount)}
                </Body>
              </Box>
            </Note>

            <PropertyList title="Transfer instruction" items={extractSourceFields({
        object: transfer.transfer_instruction as Record<string, string>,
        operations
      })} />

            {transfer.created_by && <InternalOperatorOnly>
                <PropertyList title="Created by" items={[transfer.created_by?.email ? {
          label: 'Email',
          value: transfer.created_by.email,
          href: buildPath(ROUTES.USERS_DETAIL, {
            userId: transfer.created_by.id
          })
        } : {
          label: 'API key',
          value: transfer.created_by.id
        }]} />
              </InternalOperatorOnly>}

            <InternalOperatorOnly>
              {externalAccountDetails.account_number && externalAccountDetails.routing_number && ([TransferGetResponseTransferType.ach_transfer, TransferGetResponseTransferType.check_deposit, TransferGetResponseTransferType.real_time_payments_transfer, TransferGetResponseTransferType.wire_transfer] as TransferGetResponseTransferType[]).includes(transfer.transfer_type) && <ExternalAccountDetails routingNumber={externalAccountDetails.routing_number} accountNumber={externalAccountDetails.account_number} />}
            </InternalOperatorOnly>

            {transfer.inbound_funds_hold && <PropertyList title="Inbound funds hold" items={compact<ListItem>([{
        label: 'Id',
        value: transfer.inbound_funds_hold.id
      }, {
        label: 'Status',
        value: humanize(transfer.inbound_funds_hold.status)
      }, {
        label: 'Held amount',
        value: formatAmount(transfer.inbound_funds_hold.amount, 'USD')
      }, {
        label: 'Automatically releases at',
        value: transfer.inbound_funds_hold.automatically_releases_at,
        format: 'month-day-year-hour-minute-second'
      }, transfer.inbound_funds_hold.released_at && {
        label: 'Released at',
        value: transfer.inbound_funds_hold.released_at,
        format: 'month-day-year-hour-minute-second'
      }])} />}

            {transfer.transfer_approval && <PropertyList title="Transfer approval by User" items={[{
        label: 'Approved by',
        value: transfer.transfer_approval.approved_by_email || transfer.transfer_approval.approved_by_id,
        href: transfer.transfer_approval.approved_by_email && transfer.transfer_approval.approved_by_id ? buildPath(ROUTES.USERS_DETAIL, {
          userId: transfer.transfer_approval.approved_by_id
        }) : undefined
      }, {
        label: 'Approved at',
        value: transfer.transfer_approval.created_at,
        format: 'month-day-year-hour-minute-second'
      }]} />}

            {transfer.transfer_cancellation && <PropertyList title="Transfer cancellation by User" items={[{
        label: 'Canceled by',
        value: transfer.transfer_cancellation.canceled_by_email || transfer.transfer_cancellation.canceled_by_id || '-',
        href: transfer.transfer_cancellation.canceled_by_email && transfer.transfer_cancellation.canceled_by_id ? buildPath(ROUTES.USERS_DETAIL, {
          userId: transfer.transfer_cancellation.canceled_by_id
        }) : undefined
      }, {
        label: 'Canceled at',
        value: transfer.transfer_cancellation.created_at,
        format: 'month-day-year-hour-minute-second'
      }]} />}

            {transfer.transfer_review && <PropertyList title={`Transfer review by ${humanize(transfer.transfer_review.reviewer)}`} items={compact<ListItem>([{
        label: 'Review reason',
        value: humanize(transfer.transfer_review.reason)
      }, {
        label: 'Held for review at',
        value: transfer.transfer_review.created_at,
        format: 'month-day-year-hour-minute-second'
      }, {
        label: 'Outcome',
        value: transfer.transfer_reviewing_manual_approval ? 'Approved' : transfer.transfer_reviewing_manual_denial ? 'Denied' : 'Pending',
        badgeColor: transfer.transfer_reviewing_manual_approval ? 'green' : transfer.transfer_reviewing_manual_denial ? 'red' : 'yellow'
      }, !(transfer.transfer_reviewing_manual_approval || transfer.transfer_reviewing_manual_approval) && canReviewTransfer(operator, transfer, transfer.transfer_review) && {
        label: 'Review',
        value: 'View queue',
        href: buildPath(ROUTES.TRANSFER_REVIEWING, {}, {
          reviewer: [transfer.transfer_review.reviewer]
        })
      }, transfer.transfer_reviewing_manual_approval && {
        label: 'Approved by',
        value: transfer.transfer_reviewing_manual_approval.operator_email
      }, transfer.transfer_reviewing_manual_approval && {
        label: 'Approved at',
        value: transfer.transfer_reviewing_manual_approval.created_at,
        format: 'month-day-year-hour-minute-second'
      }, transfer.transfer_reviewing_manual_denial && {
        label: 'Denied by',
        value: transfer.transfer_reviewing_manual_denial.operator_email
      }, transfer.transfer_reviewing_manual_denial && {
        label: 'Denied at',
        value: transfer.transfer_reviewing_manual_denial.created_at,
        format: 'month-day-year-hour-minute-second'
      }])} />}

            {transfer.check_deposit_rejection && <PropertyList title="Check deposit rejected by Increase" items={[{
        label: 'Reason',
        value: humanize(transfer.check_deposit_rejection.reason)
      }, {
        label: 'Reject at',
        value: transfer.check_deposit_rejection.created_at,
        format: 'month-day-year-hour-minute-second'
      }]} />}

            <TableStateWrapper<CompletedTransactionListResponseDataItem, CompletedTransactionsTableProps> table={CompletedTransactionsTable} error={null} viewMoreHref={''} style="detail" title="Transactions & Declined Transactions" emptyTitle="No transactions" emptySubtitle="No money has moved." data={transfer.completed_transactions} showAccount={transfer.transfer_type === 'account_transfer'} />

            {transfer.wire_transfer_return_requests?.map(returnRequest => <PropertyList key={returnRequest.id} title="Return Request" items={[{
        label: 'ID',
        value: returnRequest.id
      }, {
        label: 'Created at',
        value: returnRequest.created_at,
        format: 'month-day-year-hour-minute-second'
      }, {
        label: 'Reason',
        value: returnRequest.reason
      }]} />)}

            {isSanctionsScreenedTransfer(transfer) && <SanctionsHitList transferId={transferID} />}

            <OperatorNotes modelId={transferID} />
          </>} mode="rightDrawer" data-sentry-element="SideBySide" data-sentry-source-file="TransferDetailPage.tsx" />
    </PageLayout>;
};