import { pdfjs } from 'react-pdf';
import { must } from './must';
import { absurd } from './absurd';

if (typeof window !== 'undefined') {
  pdfjs.GlobalWorkerOptions.workerSrc = new URL(
    'pdfjs-dist/build/pdf.worker.min.mjs',
    import.meta.url
  ).toString();
}

export type CanvasImage = CanvasImageSource & { width: number; height: number };

async function imageFromBlob(blob: Blob): Promise<CanvasImage> {
  return new Promise((res, reject) => {
    const image = document.createElement('img');

    image.onerror = (err) => {
      reject(err);
    };

    image.onload = () => {
      res(image);
    };

    image.src = URL.createObjectURL(blob);
  });
}

function createImageData(width: number, height: number): ImageData {
  const canvas = document.createElement('canvas');
  const context = must(canvas.getContext('2d'));

  return context.createImageData(width, height);
}

const DEFAULT_OPTIONS = { maxPixels: 500000 };

export function limitMaxPixels(
  image: { width: number; height: number },
  options: { maxPixels?: number; minimumWidth?: number; minimumHeight?: number }
): { width: number; height: number; scale: number } {
  const { maxPixels, minimumWidth, minimumHeight } = {
    ...DEFAULT_OPTIONS,
    ...options,
  };
  const aspect = image.width / image.height;

  let height = Math.sqrt(maxPixels / aspect);

  if (minimumHeight && height < minimumHeight) {
    height = minimumHeight;
  }

  let width = aspect * height;

  if (minimumWidth && width < minimumWidth) {
    width = minimumWidth;
    height = width / aspect;
  }

  return {
    scale: height / image.height,
    width: Math.floor(width),
    height: Math.floor(height),
  };
}

export function getDataURL(image: ImageData): string {
  const canvas = document.createElement('canvas');
  const ctx = must(canvas.getContext('2d'));

  canvas.width = image.width;
  canvas.height = image.height;

  ctx.putImageData(image, 0, 0);

  return canvas.toDataURL('image/png');
}

export async function getBlob(image: ImageData): Promise<Blob> {
  const canvas = document.createElement('canvas');
  const ctx = must(canvas.getContext('2d'));

  canvas.width = image.width;
  canvas.height = image.height;

  ctx.putImageData(image, 0, 0);

  return new Promise((resolve, reject) => {
    canvas.toBlob((blob) => {
      if (blob) {
        resolve(blob);
      } else {
        reject(new Error('Failed to convert canvas to blob.'));
      }
    }, 'image/png');
  });
}

export async function getPixels(
  img: CanvasImageSource,
  options: {
    bounds: { width: number; height: number; left: number; top: number };
    imageTransform: DOMMatrixReadOnly;
    minimumWidth?: number;
    minimumHeight?: number;
    maxPixels?: number;
  }
): Promise<ImageData> {
  if (
    !('width' in img && 'height' in img) ||
    typeof img.width !== 'number' ||
    typeof img.height !== 'number'
  ) {
    throw new Error('static images only');
  }

  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d');
  if (!ctx) {
    throw new Error('Failed to get 2D context from canvas.');
  }

  const bounds = options.bounds;
  const imageTransform = options.imageTransform;

  const target = limitMaxPixels(
    {
      width: bounds.width,
      height: bounds.height,
    },
    options
  );

  canvas.width = target.width;
  canvas.height = target.height;

  let boundsMatrix = new DOMMatrixReadOnly();
  boundsMatrix = boundsMatrix.scale(target.scale);
  boundsMatrix = boundsMatrix.translate(-bounds.left, -bounds.top);

  ctx.save();
  ctx.setTransform(boundsMatrix.multiply(imageTransform));
  ctx.drawImage(img, 0, 0, img.width, img.height);
  ctx.restore();

  return ctx.getImageData(0, 0, target.width, target.height);
}

function rgbToGray(r: number, g: number, b: number): number {
  return 0.2126 * r + 0.7152 * g + 0.0722 * b;
}

export function applyThreshold(
  pixels: ImageData,
  threshold: number
): ImageData {
  const output = createImageData(pixels.width, pixels.height);
  const data = pixels.data;

  for (let i = 0; i < data.length; i += 4) {
    const r = data[i];
    const g = data[i + 1];
    const b = data[i + 2];

    const luminance = rgbToGray(r, g, b);

    output.data[i] =
      output.data[i + 1] =
      output.data[i + 2] =
        luminance > threshold ? 255 : 0;
    output.data[i + 3] = 255;
  }

  return output;
}

export function grayHistogram(pixels: ImageData): number[] {
  const output = new Array<number>(256).fill(0);
  const data = pixels.data;

  for (let i = 0; i < data.length; i += 4) {
    const r = data[i];
    const g = data[i + 1];
    const b = data[i + 2];

    output[Math.round(rgbToGray(r, g, b))] += 1;
  }

  return output;
}

export function imageDataToCanvasImageSource(
  imageData: ImageData
): CanvasImageSource & { width: number; height: number } {
  const canvas = document.createElement('canvas');
  canvas.width = imageData.width;
  canvas.height = imageData.height;

  const ctx = canvas.getContext('2d');
  if (ctx) {
    ctx.putImageData(imageData, 0, 0);
  } else {
    throw new Error('Failed to get 2D context from canvas.');
  }

  return canvas;
}

export const getMouseLocationInCanvas = (
  e: React.MouseEvent<HTMLCanvasElement, MouseEvent>
): { x: number; y: number } => {
  const canvasElement = e.currentTarget;
  const rect = canvasElement.getBoundingClientRect(); // abs. size of element
  const scaleX = canvasElement.width / rect.width; // relationship bitmap vs. element for X
  const scaleY = canvasElement.height / rect.height; // relationship bitmap vs. element for Y

  return {
    x: (e.clientX - rect.left) * scaleX,
    y: (e.clientY - rect.top) * scaleY,
  };
};

export function addText(
  imageData: ImageData,
  text: string,
  font: string,
  action: 'stroke' | 'fill',
  color: string,
  point: { x: number; y: number }
): ImageData {
  const { x, y } = point;
  const canvas = document.createElement('canvas');
  canvas.width = imageData.width;
  canvas.height = imageData.height;

  const ctx = canvas.getContext('2d');
  if (ctx) {
    const angle = imageData.width > imageData.height ? 90 : 0;
    ctx.putImageData(imageData, 0, 0);
    ctx.save();
    ctx.translate(x, y);
    ctx.rotate((angle * Math.PI) / 180);
    ctx.font = font;
    ctx.textAlign = 'center';
    ctx.textBaseline = 'middle';

    if (action === 'fill') {
      ctx.fillStyle = color;
      ctx.fillText(text, 0, 0);
    } else if (action === 'stroke') {
      ctx.strokeStyle = color;
      ctx.strokeText(text, 0, 0);
    } else {
      absurd(action);
    }

    ctx.restore();
  } else {
    throw new Error('Failed to get 2D context from canvas.');
  }

  return ctx.getImageData(0, 0, canvas.width, canvas.height);
}

async function heicFromResponse(response: Response): Promise<CanvasImage> {
  const heic2any = (await import('heic2any')).default;
  const png = await heic2any({ blob: await response.blob() });
  if (Array.isArray(png)) {
    const blob = await imageFromBlob(png[0]);
    return blob;
  } else {
    const blog = await imageFromBlob(png);
    return blog;
  }
}

async function pdfFromResponse(
  response: Response
): Promise<pdfjs.PDFDocumentProxy> {
  return pdfjs.getDocument({
    isEvalSupported: false,
    data: new Uint8Array(await response.arrayBuffer()),
  }).promise;
}

export async function loadImages(url: string): Promise<CanvasImage[]> {
  const response = await fetch(url, { credentials: 'include' });
  const type = response.headers?.get('content-type');

  if (type?.includes('image/heic')) {
    const heic = await heicFromResponse(response);
    return [heic];
  } else if (type?.includes('pdf')) {
    const pdfDoc = await pdfFromResponse(response);
    const bitmaps: ImageBitmap[] = [];

    for (let pageNum = 1; pageNum <= pdfDoc.numPages; pageNum++) {
      const page = await pdfDoc.getPage(pageNum);
      const viewport = page.getViewport({ scale: 3 });
      const canvas = document.createElement('canvas');
      const context = must(canvas.getContext('2d'));
      canvas.width = viewport.width;
      canvas.height = viewport.height;
      context.save();
      await page.render({
        canvasContext: context,
        viewport,
      }).promise;
      const pixels = context.getImageData(0, 0, canvas.width, canvas.height);
      const bitmap = await createImageBitmap(pixels);
      bitmaps.push(bitmap);
    }

    return bitmaps;
  } else {
    const blob = await response.blob();
    const image = await imageFromBlob(blob);
    return [image];
  }
}
