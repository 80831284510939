import React, { useMemo, useState } from 'react';
import { OperatorGetResponseEntitlementsItem, SanctionsScreeningHitListResponseDataItem, SanctionsScreeningHitListResponseDataItemFuzzySearchHit, SanctionsScreeningHitListResponseDataItemInternationalTradeAdministrationHit, SanctionsScreeningHitListResponseDataItemStatus, SanctionsScreeningHitListResponseDataItemTriggerTokenHit, SanctionsScreeningHitPatchResponseFuzzySearchHitAnyOfField, SanctionsScreeningHitPatchResponseTriggerTokenHitAnyOfField, SanctionsScreeningReviewGetResponseReviewer, SanctionsScreeningReviewRecordGetResponse, SanctionsScreeningReviewRecordGetResponseInboundAchInternationalAddenda11, SanctionsScreeningReviewRecordGetResponseInboundWireAnyOf, SanctionsScreeningReviewRecordGetResponseRecordType, SanctionsScreeningReviewRecordGetResponseWireTransferInstruction } from 'src/build/operations';
import { useOperatorGet, useTransferGet, useSanctionsScreeningReviewGet, useSanctionsScreeningHitListInfinite, useSanctionsScreeningReviewRecordGet, useSanctionsScreeningHitPatch, useSanctionsScreeningReviewPatch } from 'src/hooks/reactQuery';
import dynamic from 'next/dynamic';
import { PageLayout } from '../deprecated/PageLayout';
import { EntityCorporationPropertyList } from '../property-lists/EntityCorporationPropertyList';
import { EntityNaturalPersonPropertyList } from '../property-lists/EntityNaturalPersonPropertyList';
import { Loading } from '@increase/shared/components/Loading';
import { TableStateWrapper } from '@increase/shared/components/TableStateWrapper';
import { ListItem, PropertyList } from '@increase/shared/components/PropertyList';
import { formatAmount, formatInteger, humanize } from '@increase/shared/lib/formatting';
import { Box } from '@increase/shared/components/Box';
import { Body, Caption, Heading } from '@increase/shared/components/Text';
import { Badge, BadgeColor } from '@increase/shared/components/Badge';
import { TextArea } from '@increase/shared/components/TextArea';
import { Button } from '@increase/shared/components/Button';
import { OperatorNotes } from '../operator-notes';
import { useTypedParams } from 'react-router-typesafe-routes/dom';
import { ROUTES, buildPath } from 'src/lib/routes';
import { compact, sum, uniq, values } from 'lodash';
import { Note } from '@increase/shared/components/Note';
import { Icon } from '@increase/shared/components/Icon';
import { IntersectionObserver } from '@increase/shared/components/IntersectionObserver';
import { useGroupPropertyRow } from 'src/lib/propertyListHelpers';
import { PrestyledTableProps, Table, TableColumns } from '@increase/shared/components/Table';
import { useDrawer } from '@increase/shared/components/Modal';
import { DrawerLayout } from '@increase/shared/components/DrawerLayout';
import { EntityBeneficialOwnerPropertyList } from '../property-lists/EntityBeneficialOwnerPropertyList';
import { Section } from '../deprecated/Section';
import { EntitySetupIdentityDocumentSubmission } from '../entity-setup-identity-document-submission';
import { absurd } from '@increase/shared/lib/absurd';
import { CommonWordHighlight, splitForHighlights } from '../common-word-highlight';
const JSONViewer = dynamic(() => import('react-json-view'), {
  ssr: false
});
const recordTypeDescription = {
  inbound_wire: 'An inbound wire that was initiated at another bank and is headed to an Increase account.',
  outbound_wire: 'An outbound wire transfer is initiated via Increase and headed to another financial institution.',
  international_ach_entry: 'An International ACH Entry (IAT) is a payment transaction that is part of the ACH network and involves a financial agency that is not located in the United States.',
  entity_setup_beneficial_owner: 'The beneficial owner or controller of an entity.',
  entity_setup_corporation: 'A corporation.',
  entity_setup_natural_person: 'An individual person.',
  entity_setup_government_authority_person: 'An "authorized person" representing a government authority.',
  entity_setup_government_authority: 'A government authority, like a municipality or county.'
};
const ScreenedField = (props: {
  field: string | null;
  isHit: boolean;
}) => {
  return <Box flex center className="w-full grow justify-between" data-sentry-element="Box" data-sentry-component="ScreenedField" data-sentry-source-file="SanctionsScreeningReviewDetailPage.tsx">
      <Body color={props.field ? undefined : 'disabled'} data-sentry-element="Body" data-sentry-source-file="SanctionsScreeningReviewDetailPage.tsx">
        {props.field || 'No data'}
      </Body>
      {props.field && (props.isHit ? <Icon name="alert" className="stroke-warning" /> : <Icon name="tick_circle" className="stroke-success" />)}
    </Box>;
};
const InboundAchInternationalAddenda11Detail = (props: {
  record: NonNullable<SanctionsScreeningReviewRecordGetResponseInboundAchInternationalAddenda11>;
}) => {
  return <PropertyList items={[{
    label: 'Addenda type code',
    value: props.record.addenda_type_code
  }, {
    label: 'Originator name',
    value: props.record.originator_name
  }, {
    label: 'Originator street address',
    value: props.record.originator_street_address
  }, {
    label: 'Created at',
    value: props.record.created_at
  }]} data-sentry-element="PropertyList" data-sentry-component="InboundAchInternationalAddenda11Detail" data-sentry-source-file="SanctionsScreeningReviewDetailPage.tsx" />;
};
const WireTransferInstructionDetail = (props: {
  record: NonNullable<SanctionsScreeningReviewRecordGetResponseWireTransferInstruction>;
  wireTransferId: string;
  hitFieldsContext: SanctionsScreeningHitPatchResponseFuzzySearchHitAnyOfField[];
}) => {
  const {
    data: wireTransfer
  } = useTransferGet(props.wireTransferId);
  const groupRow = useGroupPropertyRow(wireTransfer?.group_id);
  return <PropertyList title="Wire Details" items={compact<ListItem>([{
    label: 'Amount',
    value: formatAmount(props.record.amount_usd, 'USD')
  }, {
    label: 'Message',
    valueNode: <ScreenedField field={props.record.message_to_recipient} isHit={props.hitFieldsContext.includes('originator_to_beneficiary_message')} />
  }, {
    label: 'Beneficiary',
    valueNode: <ScreenedField field={props.record.beneficiary_name} isHit={props.hitFieldsContext.includes('beneficiary_name')} />
  }, {
    label: 'Beneficiary Address',
    valueNode: <ScreenedField field={compact([props.record.beneficiary_address_line1, props.record.beneficiary_address_line2, props.record.beneficiary_address_line3]).join(', ')} isHit={props.hitFieldsContext.includes('beneficiary_address')} />
  }, {
    label: 'Destination routing number',
    value: props.record.destination_routing_number
  }, {
    label: 'Destination bank',
    value: props.record.destination_bank_name || 'Unknown.'
  }, {
    label: 'Originator',
    valueNode: <ScreenedField field={props.record.originator_name} isHit={props.hitFieldsContext.includes('originator_name')} />
  }, {
    label: 'Originator Address',
    valueNode: <ScreenedField field={compact([props.record.originator_address_line1, props.record.originator_address_line2, props.record.originator_address_line3]).join(', ')} isHit={props.hitFieldsContext.includes('originator_address')} />
  }, {
    label: 'Date',
    value: props.record.created_at,
    format: 'month-day-year-hour-minute-second'
  }, {
    label: 'Transfer details',
    value: props.wireTransferId,
    href: buildPath(ROUTES.TRANSFER_DETAIL, {
      transferID: props.wireTransferId
    })
  }, wireTransfer && {
    label: 'Originating Account',
    value: wireTransfer.account_id,
    href: buildPath(ROUTES.ACCOUNTS_DETAIL, {
      accountId: wireTransfer.account_id
    })
  }, wireTransfer && groupRow])} data-sentry-element="PropertyList" data-sentry-component="WireTransferInstructionDetail" data-sentry-source-file="SanctionsScreeningReviewDetailPage.tsx" />;
};
const InboundWireTransferDetail = (props: {
  record: NonNullable<SanctionsScreeningReviewRecordGetResponseInboundWireAnyOf>;
  hitFieldsContext: SanctionsScreeningHitPatchResponseFuzzySearchHitAnyOfField[];
}) => {
  return <PropertyList title="Wire Details" items={[{
    label: 'Amount',
    value: formatAmount(props.record.amount, 'USD')
  }, {
    label: 'Date',
    value: props.record.created_at,
    format: 'month-day-year-hour-minute-second'
  }, {
    label: 'Message',
    valueNode: <ScreenedField field={props.record.originator_to_beneficiary_information} isHit={props.hitFieldsContext.includes('originator_to_beneficiary_message')} />
  }, {
    label: 'Beneficiary',
    valueNode: <ScreenedField field={props.record.beneficiary_name} isHit={props.hitFieldsContext.includes('beneficiary_name')} />
  }, {
    label: 'Beneficiary Address',
    valueNode: <ScreenedField field={props.record.beneficiary_address} isHit={props.hitFieldsContext.includes('beneficiary_address')} />
  }, {
    label: 'Originator',
    valueNode: <ScreenedField field={props.record.originator_name} isHit={props.hitFieldsContext.includes('originator_name')} />
  }, {
    label: 'Originator Address',
    valueNode: <ScreenedField field={props.record.originator_address} isHit={props.hitFieldsContext.includes('originator_address')} />
  }, {
    label: "Originator's routing number",
    value: props.record.originating_routing_number
  }, {
    label: "Originator's bank",
    value: props.record.originating_bank_name || 'Unknown.'
  }]} data-sentry-element="PropertyList" data-sentry-component="InboundWireTransferDetail" data-sentry-source-file="SanctionsScreeningReviewDetailPage.tsx" />;
};
const PrimaryRecordInfo = (props: {
  reviewId: string;
  hitFieldsContext: SanctionsScreeningHitPatchResponseFuzzySearchHitAnyOfField[];
}) => {
  const {
    data,
    isLoading
  } = useSanctionsScreeningReviewRecordGet({
    review_id: props.reviewId
  });
  if (isLoading) {
    return <Loading />;
  }
  if (!data) {
    return <Box flex center>
        <Badge label="Data restricted since review is complete." color="red" />
      </Box>;
  }
  return <Box gap="6" data-sentry-element="Box" data-sentry-component="PrimaryRecordInfo" data-sentry-source-file="SanctionsScreeningReviewDetailPage.tsx">
      {data.entity_setup_corporation_submission && data.record_type === 'entity_setup_corporation' && <EntityCorporationPropertyList entityId={data.entity_id} corporation={data.entity_setup_corporation_submission} />}
      {data.entity_setup_natural_person_submission && <EntityNaturalPersonPropertyList entityId={data.entity_id} naturalPerson={data.entity_setup_natural_person_submission} />}
      {data.entity_setup_beneficial_owner_submission && <EntityBeneficialOwnerPropertyList title="Basics" entityId={data.entity_id} beneficialOwner={data.entity_setup_beneficial_owner_submission} />}
      {data.inbound_ach_international_addenda11 && <InboundAchInternationalAddenda11Detail record={data.inbound_ach_international_addenda11} />}
      {data.wire_transfer_instruction && <WireTransferInstructionDetail record={data.wire_transfer_instruction} wireTransferId={data.record_id} hitFieldsContext={props.hitFieldsContext} />}
      {data.inbound_wire && <InboundWireTransferDetail record={data.inbound_wire} hitFieldsContext={props.hitFieldsContext} />}
    </Box>;
};
const SupportingRecordInfo = (props: {
  reviewId: string;
}) => {
  const {
    data,
    isLoading
  } = useSanctionsScreeningReviewRecordGet({
    review_id: props.reviewId
  });
  if (isLoading) {
    return <Loading />;
  }
  if (!data) {
    return <Box flex center>
        <Badge label="Data restricted since review is complete." color="red" />
      </Box>;
  }
  const idDocuments = data.entity_setup_beneficial_owner_submission?.identity_document_submissions || [];
  return <>
      <Heading data-sentry-element="Heading" data-sentry-source-file="SanctionsScreeningReviewDetailPage.tsx">Supplemental information</Heading>

      {data.entity_setup_corporation_submission && data.record_type === 'entity_setup_beneficial_owner' && <EntityCorporationPropertyList title="Beneficial owner of..." corporation={data.entity_setup_corporation_submission} />}

      {idDocuments.length > 0 && <Section header="Identity Document Submissions">
          {idDocuments.map(submission => <EntitySetupIdentityDocumentSubmission key={submission.id} entity_setup_identity_document_submission={submission} />)}
        </Section>}

      {!!data.supplemental_documentation && <JSONViewer displayDataTypes={false} indentWidth={2} displayObjectSize={false} quotesOnKeys={false} src={data.supplemental_documentation} collapsed />}
    </>;
};
type ExpectedHitShape = {
  name: string;
  type: string;
  alt_names?: string[];
  dates_of_birth?: string[];
  addresses?: [{
    address?: null;
    city?: null;
    state?: null;
    postal_code?: null;
    country?: string;
  }];
};
const isExpectedHitShape = (value: unknown): value is ExpectedHitShape => {
  return typeof value === 'object' && value !== null && 'name' in value && typeof value['name'] === 'string' && ('alt_names' in value ? Array.isArray(value['alt_names']) : true) && ('dates_of_birth' in value ? Array.isArray(value['dates_of_birth']) : true);
};
const summarize = (value: string[]): string => {
  const first = value[0];
  if (!first) {
    return '-';
  }
  const suffix = value.length > 1 ? ` ... (+${value.length - 1})` : '';
  return first + suffix;
};
const OfficeOfForeignAssetControlHitDetails = (props: {
  matchingPhrase: string;
  hit: NonNullable<SanctionsScreeningHitListResponseDataItemInternationalTradeAdministrationHit>;
}) => {
  const {
    matchingPhrase,
    hit
  } = props;
  const highlights = splitForHighlights(matchingPhrase);
  return <Box data-sentry-element="Box" data-sentry-component="OfficeOfForeignAssetControlHitDetails" data-sentry-source-file="SanctionsScreeningReviewDetailPage.tsx">
      {isExpectedHitShape(hit.hit) && <PropertyList title="IATA screening details" items={compact<ListItem>([{
      label: 'Name',
      valueNode: <CommonWordHighlight text={hit.hit.name} highlights={highlights} />
    }, ...(hit.hit.alt_names || []).map(n => ({
      label: 'Alternate name',
      valueNode: <CommonWordHighlight text={n} highlights={highlights} />
    }))])} />}
      <Box gap="1" data-sentry-element="Box" data-sentry-source-file="SanctionsScreeningReviewDetailPage.tsx">
        <Caption color="secondary" data-sentry-element="Caption" data-sentry-source-file="SanctionsScreeningReviewDetailPage.tsx">
          This is the raw information from the International Trade
          Administration.
        </Caption>
        <div className="border-strong bg-strong text-main relative min-h-[40px] rounded border p-2 font-mono text-xs whitespace-pre-wrap transition-all">
          {JSON.stringify(hit, null, 2)}
        </div>
      </Box>
    </Box>;
};
const TriggerTokenHitDetails = (props: {
  matchingPhrase: string;
  hit: NonNullable<SanctionsScreeningHitListResponseDataItemTriggerTokenHit>;
}) => {
  const {
    matchingPhrase,
    hit
  } = props;
  return <PropertyList title="Trigger word details" items={compact<ListItem>([{
    label: 'Name',
    valueNode: <CommonWordHighlight text={matchingPhrase} highlights={[hit.token]} />
  }, {
    label: 'Matching field',
    value: matchingPhrase,
    weight: 'bold'
  }, {
    label: 'Field type',
    value: humanize(hit.field)
  }, {
    label: 'View token details',
    value: hit.token_id,
    href: buildPath(ROUTES.SANCTIONS_SCREENING_TRIGGER_TOKENS_LIST, {})
  }])} data-sentry-element="PropertyList" data-sentry-component="TriggerTokenHitDetails" data-sentry-source-file="SanctionsScreeningReviewDetailPage.tsx" />;
};
const FuzzyMatchHitDetails = (props: {
  matchingPhrase: string;
  hit: NonNullable<SanctionsScreeningHitListResponseDataItemFuzzySearchHit>;
}) => {
  const {
    matchingPhrase,
    hit
  } = props;
  return <PropertyList title="Consolidated screening list details" items={compact<ListItem>([{
    label: 'Matching name',
    valueNode: <CommonWordHighlight text={hit.name} highlights={splitForHighlights(matchingPhrase)} />
  }, {
    label: 'Matching field',
    value: matchingPhrase,
    weight: 'bold'
  }, {
    label: 'Field type',
    value: humanize(hit.field)
  }, {
    label: 'Score',
    value: formatInteger(+hit.score * 100)
  }, {
    label: 'Matching algorithm',
    value: humanize(hit.algorithm)
  }, {
    label: 'Screening list',
    value: hit.entry_source
  }, {
    label: 'Type',
    value: hit.entry_type || ''
  }, ...hit.other_names.map(value => ({
    label: 'Alternative name',
    value
  })), ...hit.addresses.map(value => ({
    label: 'Address',
    value
  })), ...hit.date_of_births.map(value => ({
    label: 'Date of Birth',
    value
  })), ...hit.place_of_births.map(value => ({
    label: 'Place of Birth',
    value
  })), {
    label: 'OFAC ID',
    value: hit.entry_external_id,
    href: `https://sanctionssearch.ofac.treas.gov/Details.aspx?id=${hit.entry_external_id}`
  }])} data-sentry-element="PropertyList" data-sentry-component="FuzzyMatchHitDetails" data-sentry-source-file="SanctionsScreeningReviewDetailPage.tsx" />;
};
type HitsTableProps = PrestyledTableProps<SanctionsScreeningHitListResponseDataItem>;
const statusBadgeColors: Record<SanctionsScreeningHitListResponseDataItemStatus, BadgeColor> = {
  backfill: 'blue',
  false_positive: 'green',
  pending_reviewing: 'yellow',
  requires_attention: 'yellow',
  true_positive: 'red'
};
const hitName = (hit: SanctionsScreeningHitListResponseDataItem): string => {
  if (hit.international_trade_administration_hit && isExpectedHitShape(hit.international_trade_administration_hit.hit)) {
    return hit.international_trade_administration_hit.hit.name;
  }
  if (hit.trigger_token_hit) {
    return hit.trigger_token_hit.token;
  }
  if (hit.fuzzy_search_hit) {
    return hit.fuzzy_search_hit.name;
  }
  return hit.id;
};
const hitDateOfBirth = (hit: SanctionsScreeningHitListResponseDataItem): string => {
  if (hit.international_trade_administration_hit && isExpectedHitShape(hit.international_trade_administration_hit.hit)) {
    return summarize(hit.international_trade_administration_hit.hit.dates_of_birth || []);
  }
  if (hit.fuzzy_search_hit) {
    return summarize(hit.fuzzy_search_hit.date_of_births);
  }
  return '';
};
const hitLocation = (hit: SanctionsScreeningHitListResponseDataItem): string => {
  if (hit.international_trade_administration_hit && isExpectedHitShape(hit.international_trade_administration_hit.hit)) {
    return summarize(hit.international_trade_administration_hit.hit.addresses?.map(a => compact([a.address, a.city, a.state, a.postal_code, a.country]).join(', ')) || []);
  }
  if (hit.fuzzy_search_hit) {
    return summarize(hit.fuzzy_search_hit.addresses);
  }
  return '';
};
const hitType = (hit: SanctionsScreeningHitListResponseDataItem): string => {
  if (hit.international_trade_administration_hit && isExpectedHitShape(hit.international_trade_administration_hit.hit)) {
    return hit.international_trade_administration_hit.hit.type;
  }
  if (hit.trigger_token_hit) {
    return 'Trigger word';
  }
  if (hit.fuzzy_search_hit) {
    return hit.fuzzy_search_hit.entry_type || '';
  }
  return '';
};
const HitsTable = (props: HitsTableProps) => {
  const {
    ...rest
  } = props;
  const columns = useMemo<TableColumns<SanctionsScreeningHitListResponseDataItem>>(() => {
    return [{
      header: 'Name',
      expand: 1,
      contents: hit => ({
        text: hitName(hit)
      })
    }, {
      header: 'OFAC ID',
      hideBelowTableWidth: 'lg',
      contents: hit => ({
        text: hit.fuzzy_search_hit?.entry_external_id || ''
      })
    }, {
      header: 'Date of Birth',
      contents: hit => ({
        text: hitDateOfBirth(hit)
      })
    }, {
      header: 'Location',
      expand: 1,
      contents: hit => ({
        text: hitLocation(hit)
      })
    }, {
      header: 'Type',
      contents: hit => ({
        text: hitType(hit)
      })
    }, {
      header: 'Score',
      contents: hit => ({
        text: hit.fuzzy_search_hit ? formatInteger(+hit.fuzzy_search_hit.score * 100) : ''
      })
    }, {
      header: 'Status',
      alight: 'right',
      contents: hit => {
        return {
          text: humanize(hit.status),
          badgeColor: statusBadgeColors[hit.status]
        };
      }
    }];
  }, []);
  return <Table columns={columns} {...rest} data-sentry-element="Table" data-sentry-component="HitsTable" data-sentry-source-file="SanctionsScreeningReviewDetailPage.tsx" />;
};
const highlightPhrase = (record: SanctionsScreeningReviewRecordGetResponse, type: SanctionsScreeningReviewRecordGetResponseRecordType): Partial<Record<SanctionsScreeningHitPatchResponseTriggerTokenHitAnyOfField, string | undefined | null>> => {
  switch (type) {
    case 'inbound_wire':
      return {
        originator_name: record.inbound_wire?.originator_name,
        originator_to_beneficiary_message: record.inbound_wire?.originator_to_beneficiary_information,
        beneficiary_name: record.inbound_wire?.beneficiary_name,
        beneficiary_address: record.inbound_wire?.beneficiary_address,
        originator_address: record.inbound_wire?.originator_address
      };
    case 'outbound_wire':
      return {
        originator_name: record.wire_transfer_instruction?.originator_name,
        originator_to_beneficiary_message: record.wire_transfer_instruction?.message_to_recipient,
        beneficiary_name: record.wire_transfer_instruction?.beneficiary_name,
        beneficiary_address: compact([record.wire_transfer_instruction?.beneficiary_address_line1, record.wire_transfer_instruction?.beneficiary_address_line2, record.wire_transfer_instruction?.beneficiary_address_line3]).join(' '),
        originator_address: compact([record.wire_transfer_instruction?.originator_address_line1, record.wire_transfer_instruction?.originator_address_line2, record.wire_transfer_instruction?.originator_address_line3]).join(' ')
      };
    case 'international_ach_entry':
      return {
        originator_name: record.inbound_ach_international_addenda11?.originator_name
      };
    case 'entity_setup_corporation':
      return {
        name: record.entity_setup_corporation_submission?.submitted_name
      };
    case 'entity_setup_beneficial_owner':
      return {
        name: record.entity_setup_beneficial_owner_submission?.submitted_name
      };
    case 'entity_setup_natural_person':
      return {
        name: record.entity_setup_natural_person_submission?.submitted_name
      };
    case 'entity_setup_government_authority_person':
    case 'entity_setup_government_authority':
      return {};
    default:
      return absurd(type);
  }
};
const BulkClearDrawer = (props: {
  close: () => void;
  reviewId: string;
  count: number;
}) => {
  const mutate = useSanctionsScreeningReviewPatch();
  const [explanation, setExplanation] = useState('');
  return <DrawerLayout title="Review all hits" cancelText="Close" body={<Box>
          <Body weight="medium">
            This will mark {formatInteger(props.count)}{' '}
            {props.count === 1 ? 'hit as a false positive' : 'hits as false positives'}
            .
          </Body>
          <TextArea value={explanation} onChange={s => setExplanation(s.target.value)} className="grow" placeholder={'Explanation note (Required.)'} />
        </Box>} rightButton={<Button text="Clear all" disabled={explanation.length === 0 || mutate.isLoading} onClick={async () => {
    await mutate.mutateAsync([props.reviewId, {
      explanation,
      status: 'false_positive'
    }]);
    props.close();
  }} />} data-sentry-element="DrawerLayout" data-sentry-component="BulkClearDrawer" data-sentry-source-file="SanctionsScreeningReviewDetailPage.tsx" />;
};
const DetailsDrawer = (props: {
  hitData: SanctionsScreeningHitListResponseDataItem;
  close: () => void;
}) => {
  const {
    data: record,
    isLoading: recordLoading
  } = useSanctionsScreeningReviewRecordGet({
    review_id: props.hitData.review_id
  });
  const {
    data: operator
  } = useOperatorGet({});
  const [explanation, setExplanation] = useState('');
  const [confirming, setConfirming] = useState(false);
  const ofacHitPatch = useSanctionsScreeningHitPatch();
  const submitReview = async (status: 'true_positive' | 'false_positive') => {
    await ofacHitPatch.mutateAsync([props.hitData.id, {
      explanation,
      status
    }]);
    props.close();
  };
  if (!operator || !record) {
    return <Loading />;
  }
  const canSubmit = operator.entitlements.includes(OperatorGetResponseEntitlementsItem.office_of_foreign_asset_control_queue_management);
  const pending = props.hitData.status === 'pending_reviewing';
  const matchingPhrase = highlightPhrase(record, record.record_type) || '';
  const isLoading = ofacHitPatch.isLoading || recordLoading;
  return <DrawerLayout title="Hit details" cancelText="Close" body={<Box>
          {props.hitData.international_trade_administration_hit && <OfficeOfForeignAssetControlHitDetails hit={props.hitData.international_trade_administration_hit} matchingPhrase={values(matchingPhrase).join(' ')} />}
          {props.hitData.trigger_token_hit && <TriggerTokenHitDetails hit={props.hitData.trigger_token_hit} matchingPhrase={matchingPhrase[props.hitData.trigger_token_hit.field] || ''} />}
          {props.hitData.fuzzy_search_hit && <FuzzyMatchHitDetails hit={props.hitData.fuzzy_search_hit} matchingPhrase={matchingPhrase[props.hitData.fuzzy_search_hit.field] || ''} />}
          {pending && <>
              <Heading size="small">Add reviewer notes</Heading>
              <TextArea name="explanation" value={explanation} onChange={s => setExplanation(s.target.value)} className="grow" placeholder={canSubmit ? 'Explanation note (Required.)' : "You don't have permission to clear an OFAC review."} disabled={!canSubmit} />
            </>}
          {confirming && <Body color="red">Are you sure? True positives are very rare.</Body>}
          <Box flex center justifyCenter>
            <Button className="grow basis-1/4" style="warning" text={confirming ? 'Click again to confirm' : 'True positive'} disabled={!canSubmit || explanation.length === 0 || isLoading} onClick={() => confirming ? submitReview('true_positive') : setConfirming(true)} />
            <Button className="grow basis-3/4" style="primary" text={'False positive'} disabled={!canSubmit || explanation.length === 0 || isLoading} onClick={() => submitReview('false_positive')} />
          </Box>

          <Box>
            <Heading>Notes</Heading>
            <OperatorNotes modelId={props.hitData.id} readOnly />
          </Box>
        </Box>} data-sentry-element="DrawerLayout" data-sentry-component="DetailsDrawer" data-sentry-source-file="SanctionsScreeningReviewDetailPage.tsx" />;
};
const HistoricalReviews = (props: {
  recordId: string;
  reviewId: string;
  reviewer: SanctionsScreeningReviewGetResponseReviewer;
}) => {
  const {
    data,
    ...rest
  } = useSanctionsScreeningHitListInfinite({
    record_id: props.recordId,
    status: ['true_positive', 'false_positive']
  });
  const cleanData = data?.pages.map(page => ({
    ...page,
    data: page.data.filter(x => x.review_id !== props.reviewId)
  }));
  const {
    showDrawer,
    closeDrawer
  } = useDrawer();
  const defaultRowProps = (datum: SanctionsScreeningHitListResponseDataItem) => ({
    onClick: () => showDrawer(<DetailsDrawer hitData={datum} close={closeDrawer} />),
    className: 'transition-all group hover:bg-main-hover cursor-pointer'
  });
  if (cleanData != null && sum(cleanData.map(x => x.data.length)) === 0) {
    return <Box>
        <Heading>Prior hits</Heading>
        <Body>None.</Body>
      </Box>;
  }
  return <>
      <Heading data-sentry-element="Heading" data-sentry-source-file="SanctionsScreeningReviewDetailPage.tsx">Other hits</Heading>
      <div>
        <TableStateWrapper table={HitsTable} data={cleanData ? {
        pages: cleanData
      } : []} {...rest} filters={[]} style="primary" emptySubtitle="None" emptyTitle="None" getRowProps={defaultRowProps} data-sentry-element="TableStateWrapper" data-sentry-source-file="SanctionsScreeningReviewDetailPage.tsx" />
      </div>
    </>;
};
export const SanctionsScreeningReviewDetailPage = () => {
  const {
    reviewID
  } = useTypedParams(ROUTES.SANCTIONS_SCREENING_REVIEW_DETAIL);
  const {
    data: review
  } = useSanctionsScreeningReviewGet(reviewID);
  const hitsList = useSanctionsScreeningHitListInfinite({
    review_id: reviewID
  });
  const {
    data: operator
  } = useOperatorGet({});
  const {
    showDrawer,
    closeDrawer
  } = useDrawer();
  if (!review || !operator) {
    return <Loading />;
  }
  const canBulkAction = review.status !== 'false_positive' && review.status !== 'true_positive' && operator.entitlements.includes(OperatorGetResponseEntitlementsItem.office_of_foreign_asset_control_queue_management);
  const count = sum(hitsList.data?.pages.map(x => x.data.length) || []);
  const hitFieldsContext = uniq(compact(hitsList.data?.pages.flatMap(x => x.data)?.map(x => x.fuzzy_search_hit?.field))) || [];
  const defaultRowProps = (datum: SanctionsScreeningHitListResponseDataItem) => ({
    onClick: () => showDrawer(<DetailsDrawer hitData={datum} close={closeDrawer} />),
    className: 'transition-all group hover:bg-main-hover cursor-pointer'
  });
  const openBulkClear = () => showDrawer(<BulkClearDrawer count={count} reviewId={reviewID} close={closeDrawer} />);
  return <PageLayout headline={`OFAC Review (${review.record_id})`} action={<Badge size="large" color={statusBadgeColors[review.status]} label={humanize(review.status)} />} data-sentry-element="PageLayout" data-sentry-component="SanctionsScreeningReviewDetailPage" data-sentry-source-file="SanctionsScreeningReviewDetailPage.tsx">
      <Box gap="6" data-sentry-element="Box" data-sentry-source-file="SanctionsScreeningReviewDetailPage.tsx">
        <Note data-sentry-element="Note" data-sentry-source-file="SanctionsScreeningReviewDetailPage.tsx">
          <Box gap="2" data-sentry-element="Box" data-sentry-source-file="SanctionsScreeningReviewDetailPage.tsx">
            <Box flex center data-sentry-element="Box" data-sentry-source-file="SanctionsScreeningReviewDetailPage.tsx">
              <Icon name="info" data-sentry-element="Icon" data-sentry-source-file="SanctionsScreeningReviewDetailPage.tsx" />
              <Body weight="bold" data-sentry-element="Body" data-sentry-source-file="SanctionsScreeningReviewDetailPage.tsx">{humanize(review.record_type)}</Body>
            </Box>

            <Body data-sentry-element="Body" data-sentry-source-file="SanctionsScreeningReviewDetailPage.tsx">{recordTypeDescription[review.record_type]}</Body>
          </Box>
        </Note>

        <PrimaryRecordInfo reviewId={reviewID} hitFieldsContext={hitFieldsContext} data-sentry-element="PrimaryRecordInfo" data-sentry-source-file="SanctionsScreeningReviewDetailPage.tsx" />

        <TableStateWrapper action={canBulkAction && <Button text="Clear all" style="secondary" size="medium" onClick={openBulkClear} />} table={HitsTable} {...hitsList} title={`Hits on this object ${count ? `(${count})` : ''}`} emptyTitle="No hits on this object" emptySubtitle="" viewMoreHref="" style="detail" getRowProps={defaultRowProps} data-sentry-element="TableStateWrapper" data-sentry-source-file="SanctionsScreeningReviewDetailPage.tsx" />
        <IntersectionObserver onVisible={hitsList.fetchNextPage} data-sentry-element="IntersectionObserver" data-sentry-source-file="SanctionsScreeningReviewDetailPage.tsx" />

        <SupportingRecordInfo reviewId={reviewID} data-sentry-element="SupportingRecordInfo" data-sentry-source-file="SanctionsScreeningReviewDetailPage.tsx" />

        <HistoricalReviews recordId={review.record_id} reviewId={reviewID} reviewer={review.reviewer} data-sentry-element="HistoricalReviews" data-sentry-source-file="SanctionsScreeningReviewDetailPage.tsx" />
      </Box>
    </PageLayout>;
};