import { TableColumns, Table, PrestyledTableProps } from '@increase/shared/components/Table';
import { useMemo } from 'react';
import { IdentityDocumentGetResponse } from 'src/build/operations';
import { formatISO8601Timestamp, humanize } from '@increase/shared/lib/formatting';
import { useServices } from 'src/hooks/use-services';
import { ROUTES, buildPath } from 'src/lib/routes';
import { useOperatorGet } from 'src/hooks/reactQuery';
type Props = PrestyledTableProps<IdentityDocumentGetResponse>;
export const IdentityDocumentsTable = (props: Props) => {
  const {
    getRowProps,
    ...rest
  } = props;
  const {
    operations
  } = useServices();
  const {
    data: operator
  } = useOperatorGet({});
  const columns = useMemo<TableColumns<IdentityDocumentGetResponse>>(() => {
    return [{
      header: 'Type',
      contents: doc => ({
        text: humanize(doc.submitted_document_type)
      })
    }, {
      header: 'Number',
      expand: 1,
      contents: doc => ({
        text: doc.submitted_document_number ?? ''
      })
    }, {
      header: 'Expiration',
      expand: 1,
      contents: doc => ({
        text: doc.submitted_expiration_date ?? ''
      })
    }, {
      header: 'Front',
      contents: doc => ({
        text: doc.api_file_id,
        href: `${operations}/api_files/${doc.api_file_id}/view`
      })
    }, {
      header: 'Back',
      expand: 1,
      contents: doc => ({
        text: doc.api_back_file_id ?? '',
        href: doc.api_back_file_id ? `${operations}/api_files/${doc.api_back_file_id}/view` : undefined
      })
    }, {
      header: 'Submitted',
      align: 'right',
      contents: doc => ({
        text: formatISO8601Timestamp(doc.created_at, 'date')
      })
    }];
  }, [operations]);
  const defaultRowProps = (datum: IdentityDocumentGetResponse) => ({
    href: operator?.bank ? undefined : buildPath(ROUTES.IDENTITY_DOCUMENT_REVIEW, {
      identityDocumentID: datum.id
    }),
    className: 'hover:bg-main-hover cursor-pointer transition-all group'
  });
  return <Table<IdentityDocumentGetResponse> columns={columns} getRowProps={getRowProps ?? defaultRowProps} {...rest} data-sentry-element="Table" data-sentry-component="IdentityDocumentsTable" data-sentry-source-file="IdentityDocumentsTable.tsx" />;
};