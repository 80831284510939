import React from 'react';
import { useACHTransferGet, useOperatorGet } from 'src/hooks/reactQuery';
import { PageLayout } from '../deprecated/PageLayout';
import { SideBySide } from '@increase/shared/components/SideBySide';
import { TransferPropertyList } from '../property-lists/TransferPropertyList';
import { ROUTES } from 'src/lib/routes';
import { useTypedParams } from 'react-router-typesafe-routes/dom';
import { AssociatedManualTasks } from '../associated-manual-tasks';
import { ACHTransferTimeline } from '../timelines/ACHTransferTimeline';
import { TransferReviewPropertyList } from '../property-lists/TransferReviewPropertyList';
import { ACHTransferDetailsPropertyList } from '../property-lists/ACHTransferDetailsPropertyList';
export const ACHTransferDetailPage = () => {
  const {
    achTransferID
  } = useTypedParams(ROUTES.ACH_TRANSFER_DETAIL);
  const {
    data: transfer
  } = useACHTransferGet(achTransferID);
  const {
    data: operator
  } = useOperatorGet({});
  if (!transfer || !operator) {
    return <>Loading...</>;
  }
  return <PageLayout id="application.transferDetails" headline={achTransferID} data-sentry-element="PageLayout" data-sentry-component="ACHTransferDetailPage" data-sentry-source-file="ACHTransferDetailPage.tsx">
      <SideBySide right={<>
            <TransferPropertyList title="Summary" id={achTransferID} transfer={transfer} />
            <AssociatedManualTasks objectId={achTransferID} />
          </>} left={<>
            <ACHTransferTimeline achTransfer={transfer} />
            <ACHTransferDetailsPropertyList title="Transfer Details" achTransfer={transfer} />
            {transfer.review && <TransferReviewPropertyList review={transfer.review} manual_approval={transfer.transfer_reviewing_manual_approval} manual_denial={transfer.transfer_reviewing_manual_denial} />}
          </>} mode="rightDrawer" data-sentry-element="SideBySide" data-sentry-source-file="ACHTransferDetailPage.tsx" />
    </PageLayout>;
};