import React from 'react';
import { PageLayout } from './deprecated/PageLayout';
import { useValidPhysicalCardImageFileList } from '../hooks/reactQuery';
import { TableStateWrapper } from '@increase/shared/components/TableStateWrapper';
import { PrestyledTableProps, Table, TableColumns } from '@increase/shared/components/Table';
import { ValidPhysicalCardImageFileListResponseDataItem } from 'src/build/operations';
import { formatISO8601Timestamp, humanize } from '@increase/shared/lib/formatting';
const PhysicalCardImageTable = (props: PrestyledTableProps<ValidPhysicalCardImageFileListResponseDataItem>) => {
  const columns: TableColumns<ValidPhysicalCardImageFileListResponseDataItem> = [{
    header: 'Image',
    CellComponent: ({
      datum
    }) => <img alt={datum.id} className="border-strong w-96 border shadow-md" src={`data:image/png;base64,${datum.image_content}`} />
  }, {
    header: 'Group name',
    contents: record => ({
      text: record.group_name || '-'
    })
  }, {
    header: 'Created at',
    contents: record => ({
      text: formatISO8601Timestamp(record.created_at, 'month-day-year-hour-minute-second')
    })
  }, {
    header: 'Image type',
    contents: record => ({
      text: humanize(record.image_type)
    })
  }, {
    header: 'Image type',
    contents: record => ({
      text: record.id
    })
  }];
  return <Table {...props} columns={columns} data-sentry-element="Table" data-sentry-component="PhysicalCardImageTable" data-sentry-source-file="physical-card-images.tsx" />;
};
export const PhysicalCardImages = () => {
  const listResponse = useValidPhysicalCardImageFileList({});
  return <PageLayout headline="Physical card images" data-sentry-element="PageLayout" data-sentry-component="PhysicalCardImages" data-sentry-source-file="physical-card-images.tsx">
      <TableStateWrapper table={PhysicalCardImageTable} {...listResponse} style="detail" title="Images" emptyTitle="No images" emptySubtitle="There are no images to display" viewMoreHref="" data-sentry-element="TableStateWrapper" data-sentry-source-file="physical-card-images.tsx" />
    </PageLayout>;
};