import React, { useMemo } from 'react';
import { PageLayout } from '../deprecated/PageLayout';
import { useInboundFedNowMessageListInfinite } from 'src/hooks/reactQuery';
import { TableStateWrapper } from '@increase/shared/components/TableStateWrapper';
import { InboundFedNowMessageListResponseDataItem } from 'src/build/operations';
import { PrestyledTableProps, Table, TableColumns } from 'shared/components/Table';
import { buildPath, ROUTES } from 'src/lib/routes';
import { InternalOperatorOnly } from '../internal-operator-only';
import { formatISO8601Timestamp } from 'shared/lib/formatting';
type Props = PrestyledTableProps<InboundFedNowMessageListResponseDataItem>;
const InboundFedNowMessagesTable = (props: Props) => {
  const {
    getRowProps,
    ...rest
  } = props;
  const columns = useMemo<TableColumns<InboundFedNowMessageListResponseDataItem>>(() => {
    return [{
      header: 'ID',
      expand: 1,
      contents: message => ({
        text: message.id,
        textWeight: 'medium',
        textColor: 'emphasis'
      })
    } as const, {
      header: 'Created at',
      expand: 1,
      contents: message => ({
        text: formatISO8601Timestamp(message.created_at, 'month-day-year-hour-minute-second')
      })
    }, {
      header: 'Queue',
      expand: 1,
      contents: message => ({
        text: message.queue_name
      })
    }, {
      header: 'Status',
      expand: 1,
      contents: message => ({
        text: message.status
      })
    }, {
      header: 'Message',
      expand: 1,
      contents: message => ({
        text: message.message_id ?? '',
        href: message.message_id ? buildPath(ROUTES.OBJECT_VIEWER, {
          objectID: message.message_id
        }) : undefined
      })
    }];
  }, []);
  const defaultRowProps = (datum: InboundFedNowMessageListResponseDataItem) => ({
    href: buildPath(ROUTES.INBOUND_FED_NOW_MESSAGE_DETAIL, {
      messageId: datum.id
    }),
    className: 'hover:bg-main-hover cursor-pointer transition-all group'
  });
  return <Table<InboundFedNowMessageListResponseDataItem> columns={columns} getRowProps={getRowProps ?? defaultRowProps} {...rest} data-sentry-element="Table" data-sentry-component="InboundFedNowMessagesTable" data-sentry-source-file="InboundFedNowMessageListPage.tsx" />;
};
export const InboundFedNowMessageListPage = () => {
  const messageList = useInboundFedNowMessageListInfinite({});
  return <InternalOperatorOnly data-sentry-element="InternalOperatorOnly" data-sentry-component="InboundFedNowMessageListPage" data-sentry-source-file="InboundFedNowMessageListPage.tsx">
      <PageLayout headline={'Inbound FedNow messages'} data-sentry-element="PageLayout" data-sentry-source-file="InboundFedNowMessageListPage.tsx">
        <TableStateWrapper table={InboundFedNowMessagesTable} style="primary" {...messageList} emptyTitle="No bank programs" emptySubtitle="There are no bank programs to display" filters={[]} data-sentry-element="TableStateWrapper" data-sentry-source-file="InboundFedNowMessageListPage.tsx" />
      </PageLayout>
    </InternalOperatorOnly>;
};