import React from 'react';
import { PageLayout } from '../deprecated/PageLayout';
import { useSanctionsScreeningTriggerTokenGet } from 'src/hooks/reactQuery';
import { Loading } from '@increase/shared/components/Loading';
import { PropertyList } from '@increase/shared/components/PropertyList';
import { useTypedParams } from 'react-router-typesafe-routes/dom';
import { ROUTES } from 'src/lib/routes';
import { OperatorNotes } from '../operator-notes';
import { humanize } from 'shared/lib/formatting';
export const SanctionsScreeningTriggerTokensDetailPage = () => {
  const {
    tokenId
  } = useTypedParams(ROUTES.SANCTIONS_SCREENING_TRIGGER_TOKENS_DETAIL);
  const {
    data
  } = useSanctionsScreeningTriggerTokenGet(tokenId);
  if (!data) {
    return <Loading />;
  }
  return <PageLayout headline={`Trigger: ${data.token}`} data-sentry-element="PageLayout" data-sentry-component="SanctionsScreeningTriggerTokensDetailPage" data-sentry-source-file="SanctionsScreeningTriggerTokensDetailPage.tsx">
      <PropertyList title="Details" items={[{
      label: 'Token',
      value: data.token,
      weight: 'bold'
    }, {
      label: 'Type',
      value: 'Trigger phrase. Memos and entities matching this phrase will always stop for OFAC reviews.'
    }, {
      label: 'Created By',
      value: data.created_by_email
    }, {
      label: 'Reviewer',
      value: humanize(data.reviewer)
    }, {
      label: 'Created at',
      value: data.created_at,
      format: 'month-day-year-hour-minute-second'
    }]} data-sentry-element="PropertyList" data-sentry-source-file="SanctionsScreeningTriggerTokensDetailPage.tsx" />
      <OperatorNotes modelId={data.id} data-sentry-element="OperatorNotes" data-sentry-source-file="SanctionsScreeningTriggerTokensDetailPage.tsx" />
    </PageLayout>;
};