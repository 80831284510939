import { compact } from 'lodash';
import { ListItem, PropertyList, PropertyListProps } from '@increase/shared/components/PropertyList';
import { humanize } from '@increase/shared/lib/formatting';
import { UserGetResponse } from 'src/build/operations';
type UserPropertyListProps = {
  user: UserGetResponse;
} & Omit<PropertyListProps, 'items'>;
export const UserPropertyList = (props: UserPropertyListProps) => {
  const {
    user,
    ...rest
  } = props;
  return <PropertyList {...rest} items={compact<ListItem>([{
    label: 'ID',
    value: user.id,
    copyable: user.id
  }, {
    label: 'Email',
    value: user.email
  }, {
    label: 'Email verification status',
    value: humanize(user.email_verification_status)
  }, {
    label: 'Created at',
    value: user.created_at,
    format: 'date'
  }])} data-sentry-element="PropertyList" data-sentry-component="UserPropertyList" data-sentry-source-file="UserPropertyList.tsx" />;
};