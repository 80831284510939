import React from 'react';
import { useCompletedTransactionListInfinite, useProgramACHReturnRateGet } from 'src/hooks/reactQuery';
import { PageLayout } from './deprecated/PageLayout';
import { SideBySide } from '@increase/shared/components/SideBySide';
import { formatISO8601Timestamp, formatInteger, formatPercentage } from '@increase/shared/lib/formatting';
import { PropertyList } from '@increase/shared/components/PropertyList';
import { THRESHOLDS } from './ach-return-rates';
import { useTypedParams } from 'react-router-typesafe-routes/dom';
import { ROUTES, buildPath } from 'src/lib/routes';
import { compact } from 'lodash';
import { CompletedTransactionsTable } from './tables/CompletedTransactionTable';
import { TableStateWrapper } from '@increase/shared/components/TableStateWrapper';
import { useGroupPropertyRow, useProgramPropertyRow } from 'src/lib/propertyListHelpers';
export const ACHReturnRate = () => {
  const {
    achReturnRateID
  } = useTypedParams(ROUTES.ACH_RETURN_RATES_DETAIL);
  const {
    data
  } = useProgramACHReturnRateGet(achReturnRateID);
  const groupRow = useGroupPropertyRow(data?.group_id);
  const programRow = useProgramPropertyRow(data?.program_id);
  const completedTransactionList = useCompletedTransactionListInfinite({
    programs: compact([data?.program_id]),
    source_types: ['API::ACHTransferReturn'],
    amount_or_less: 0
  });
  if (!data) {
    return <h3>Loading</h3>;
  }
  return <PageLayout headline={`${data.group_name} - ${data.program_name} - ${formatISO8601Timestamp(data.period_end, 'date')}`} data-sentry-element="PageLayout" data-sentry-component="ACHReturnRate" data-sentry-source-file="ach-return-rate.tsx">
      <SideBySide right={<PropertyList title="Context" items={[groupRow, programRow, {
      label: 'See more',
      value: 'Historical rates',
      href: buildPath(ROUTES.ACH_RETURN_RATES_LIST, {}, {
        program: [data.program_id]
      })
    }]} />} left={<>
            <PropertyList title="Details" items={[{
        label: 'Period start',
        value: data.period_start,
        format: 'month-day-year-hour-minute-second'
      }, {
        label: 'Period end',
        value: data.period_end,
        format: 'month-day-year-hour-minute-second'
      }, {
        label: 'ACH debits',
        value: formatInteger(data.count_ach_debits)
      }, {
        label: 'ACH debit returns',
        value: formatInteger(data.count_ach_returns)
      }, {
        label: 'Overall',
        value: formatPercentage(parseFloat(data.overall_return_rate)),
        tooltip: `Nacha rule is ${formatPercentage(THRESHOLDS.overall)}`
      }, {
        label: 'Administrative',
        value: formatPercentage(parseFloat(data.administrative_return_rate)),
        tooltip: `Nacha rule is ${formatPercentage(THRESHOLDS.administrative)}`
      }, {
        label: 'Unauthorized',
        value: formatPercentage(parseFloat(data.unauthorized_return_rate)),
        tooltip: `Nacha rule is ${formatPercentage(THRESHOLDS.unauthorized)}`
      }]} />
            <TableStateWrapper {...completedTransactionList} table={CompletedTransactionsTable} style="detail" title="ACH Debit Returns" emptyTitle="No ACH Debit Returns" emptySubtitle="There are no ACH Debit Returns." showAccount showGroup showRoute={false} viewMoreHref={buildPath(ROUTES.TRANSACTIONS_LIST, {}, {
        program: [data.program_id],
        source_type: ['ACH']
      })} />
          </>} mode="rightDrawer" data-sentry-element="SideBySide" data-sentry-source-file="ach-return-rate.tsx" />
    </PageLayout>;
};