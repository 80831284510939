import { TableColumns, Table, PrestyledTableProps } from '@increase/shared/components/Table';
import { useMemo } from 'react';
import { LockboxListResponseDataItem, LockboxListResponseDataItemStatus } from 'src/build/operations';
import { buildPath, ROUTES } from 'src/lib/routes';
type Props = PrestyledTableProps<LockboxListResponseDataItem> & {
  showGroup: boolean;
  showAccount: boolean;
};
const readableStatus = (status: LockboxListResponseDataItemStatus): string => ({
  active: 'Active',
  inactive: 'Inactive'
})[status];
export const LockboxesTable = (props: Props) => {
  const {
    getRowProps,
    showAccount,
    showGroup,
    ...rest
  } = props;
  const columns = useMemo<TableColumns<LockboxListResponseDataItem>>(() => {
    return [showGroup && {
      header: 'Group',
      contents: lockbox => ({
        text: lockbox.group_id,
        href: buildPath(ROUTES.GROUPS_DETAIL, {
          groupId: lockbox.group_id
        })
      })
    }, showAccount && {
      header: 'Account',
      contents: lockbox => ({
        text: lockbox.account_id,
        href: buildPath(ROUTES.ACCOUNTS_DETAIL, {
          accountId: lockbox.account_id
        })
      })
    }, {
      header: 'Description',
      iconType: 'lockbox',
      expand: 1,
      contents: lockbox => ({
        text: lockbox.description ?? 'Unnamed lockbox',
        textWeight: 'medium',
        textColor: 'emphasis'
      })
    }, {
      header: 'Status',
      contents: lockbox => ({
        text: readableStatus(lockbox.status),
        badgeColor: lockbox.status === 'active' ? 'green' : 'red'
      })
    }];
  }, [showAccount, showGroup]);
  const defaultRowProps = (datum: LockboxListResponseDataItem) => ({
    href: buildPath(ROUTES.LOCKBOX_DETAIL, {
      lockboxId: datum.id
    }),
    className: 'hover:bg-main-hover cursor-pointer transition-all group'
  });
  return <Table<LockboxListResponseDataItem> columns={columns} getRowProps={getRowProps ?? defaultRowProps} {...rest} data-sentry-element="Table" data-sentry-component="LockboxesTable" data-sentry-source-file="LockboxesTable.tsx" />;
};