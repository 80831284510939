import React from 'react';
import { TooltipProps } from 'recharts';
import { formatAmount } from '@increase/shared/lib/formatting';
export const CustomTooltip = React.memo(({
  active,
  payload
}: TooltipProps<number, string>) => {
  if (active && payload && payload.length) {
    const periodData = payload[0].payload;
    const formattedValue = formatAmount(periodData.value, 'USD', true);
    const programName = periodData.program;
    const metricType = periodData.type;
    return <div className="bg-inverse text-inverse rounded p-3 text-sm shadow-md">
          <p>{`${programName} - ${metricType}: ${formattedValue}`}</p>
        </div>;
  }
  return null;
}, (prevProps, nextProps) => {
  return prevProps.active === nextProps.active && prevProps.payload === nextProps.payload;
});
CustomTooltip.displayName = 'CustomTooltip';