import classNames from 'classnames';
import { Icon, IconType } from './Icon';
import { Body, Heading } from './Text';
import { Box } from './Box';
import { Link } from 'react-router-dom';
import { DateFormat, formatISO8601Timestamp } from '../lib/formatting';
import { Tooltip } from './Tooltip';
import { DateTime } from 'luxon';
export type TimelineEntryBulletColor = 'complete' | 'modified' | 'incomplete' | 'pending' | 'failed' | 'selected' | 'unselected';
export type TimelineEntry = {
  date: string | null;
  bullet?: {
    hidden?: boolean;
    color?: TimelineEntryBulletColor;
    icon?: IconType;
    outline?: boolean;
  };
  object?: {
    name: string;
    href: string;
    icon: IconType;
    style: 'object-selected' | 'object-unselected' | 'document';
  } | {
    name: string;
    onClick: () => void;
    icon: IconType;
    style: 'object-selected' | 'object-unselected' | 'document';
  };
  text: string;
  textColor?: 'gray' | 'modified';
  tooltipText?: string;
};
export type TimelineProps = {
  title: string;
  titleStyle?: 'small' | 'large';
  entries: TimelineEntry[];
  timezone?: 'UTC' | 'America/New_York';
};
export const Timeline = ({
  title,
  titleStyle = 'large',
  entries,
  timezone
}: TimelineProps) => {
  return <Box data-sentry-element="Box" data-sentry-component="Timeline" data-sentry-source-file="Timeline.tsx">
      {titleStyle === 'small' ? <Heading size="small" contents={title} /> : <Heading contents={title} />}
      <div className="flow-root">
        <ul role="list">
          {entries.map((entry, entryIdx) => {
          const isLastRow = entryIdx === entries.length - 1;
          const bulletHidden = entry.bullet?.hidden ?? false;
          const bulletColor = entry.bullet?.color ?? 'complete';
          const bulletIcon = entry.bullet?.icon;
          const bulletOutline = entry.bullet?.outline ?? false;
          const previousRowDate = entryIdx > 0 ? entries[entryIdx - 1].date : null;
          const dateMatchesPrior = entryIdx > 0 && entry.date && previousRowDate && DateTime.fromISO(previousRowDate).hasSame(DateTime.fromISO(entry.date), 'day') && DateTime.fromISO(previousRowDate) <= DateTime.fromISO(entry.date);
          const dateFormat: DateFormat = dateMatchesPrior ? 'time' : 'month-day-year-hour-minute-second';
          return <li key={JSON.stringify(entry)}>
                <div className={classNames('relative')}>
                  {!isLastRow && <span className={classNames('border-strong absolute left-3 w-[1px] border-l', bulletHidden ? 'top-[calc(0.5rem+24px+8px)] h-[calc(100%-24px-8px)]' : 'top-8 h-[calc(100%-1.5rem)]')} aria-hidden="true" />}
                  <div className={classNames('relative flex items-start space-x-1')}>
                    {!bulletHidden && <div>
                        <span className={classNames('mt-3.5 flex h-6 w-6 items-center justify-center py-2', entry.object ? 'lg:mt-2.5' : 'lg:mt-2', {
                    'text-main': bulletColor === 'selected',
                    'text-subtle': bulletColor === 'unselected',
                    'text-disabled': bulletColor === 'incomplete',
                    'text-info': bulletColor === 'modified',
                    'text-danger': bulletColor === 'failed',
                    'text-success': bulletColor === 'complete'
                  })}>
                          {bulletIcon ? <Icon name={bulletIcon} /> : <div className={classNames('relative h-2 w-2 rounded-full', bulletOutline ? {
                      'border-stronger': bulletColor === 'incomplete',
                      'border-info-strong': bulletColor === 'modified',
                      'border-danger-strong': bulletColor === 'failed',
                      'border-success-strong': bulletColor === 'complete',
                      'border-warning-strong': bulletColor === 'pending',
                      'border-2': true,
                      'bg-none': true
                    } : {
                      'bg-disabled': bulletColor === 'incomplete',
                      'bg-info-strong before:bg-info-strong': bulletColor === 'modified',
                      'bg-danger-strong before:bg-danger-strong': bulletColor === 'failed',
                      'bg-success-strong before:bg-success-strong': bulletColor === 'complete',
                      'bg-warning-strong before:bg-warning-strong': bulletColor === 'pending'
                    }, isLastRow ? 'before:absolute before:inline-flex before:h-full before:w-full before:animate-ping before:rounded-full before:opacity-75' : 'before:hidden')} />}
                        </span>
                      </div>}
                    <div className={classNames('border-main flex max-w-full min-w-0 flex-1 flex-col items-start justify-center space-y-0 border-t py-3 lg:flex-row lg:items-center lg:justify-between lg:space-x-2 lg:py-1.5')}>
                      <div className={classNames('flex-auto', bulletHidden && 'pl-6 -indent-6')}>
                        {entry.object && function () {
                      const inner = <div className={classNames('mr-2 inline-flex cursor-pointer items-baseline space-x-1 py-0.5 font-mono transition-colors', 'group-focus:ring-main group-focus:ring-[3px] group-focus:outline-hidden', {
                        'border-strong bg-strong text-strong hover:bg-strong-hover rounded-full border pr-2 pl-1.5': entry.object.style === 'object-unselected'
                      }, {
                        'border-brand bg-brand text-inverse rounded-full border pr-2 pl-1.5': entry.object.style === 'object-selected'
                      }, {
                        'border-form border-strong bg-strong text-strong hover:bg-strong-hover rounded-full border pr-2 pl-1.5': entry.object.style === 'document'
                      })}>
                                <Icon name={entry.object.icon} className="self-center" />
                                <Body color="inherit" weight="mono" contents={entry.object.name} className={classNames({
                          'pl-6': bulletHidden
                        })} />
                              </div>;
                      if ('href' in entry.object) {
                        return <Link to={entry.object.href} className={classNames('group focus:outline-hidden')}>
                                  {inner}
                                </Link>;
                      } else {
                        return <span onClick={entry.object.onClick}>
                                  {inner}
                                </span>;
                      }
                    }()}
                        <Body color="primary" className={classNames('inline break-words')}>
                          {entry.text}
                        </Body>
                        {entry.tooltipText && <Tooltip direction="top" size="large" content={entry.tooltipText} className="inline-flex align-baseline">
                            <Icon name="info" className="stroke-subtle relative top-0.5 ml-1" />
                          </Tooltip>}
                      </div>
                      <div className={classNames('text-subtle text-right text-sm whitespace-nowrap', bulletHidden && 'ml-6')}>
                        <Body color="secondary" contents={entry.date ? formatISO8601Timestamp(entry.date, dateFormat, timezone) : ''} />
                      </div>
                    </div>
                  </div>
                </div>
              </li>;
        })}
        </ul>
      </div>
    </Box>;
};