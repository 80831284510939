import { compact } from 'lodash';
import { Badge, BadgeColor } from '@increase/shared/components/Badge';
import { ListItem, PropertyList, PropertyListProps } from '@increase/shared/components/PropertyList';
import { humanize } from '@increase/shared/lib/formatting';
import { EntityGetResponse, EntityGetResponseAutomatedCustomerIdentificationProgramEvaluation, EntityGetResponseStatus } from 'src/build/operations';
import { useCommercialOnboardingSurveyListInfinite, useConsumerOnboardingSurveyListInfinite, useCustomerIdentificationProgramTestingEntryListInfinite, useOperatorGet } from 'src/hooks/reactQuery';
import { useGroupPropertyRow } from 'src/lib/propertyListHelpers';
import { buildPath, ROUTES } from 'src/lib/routes';
import { Body } from '@increase/shared/components/Text';
type EntityPropertyListProps = {
  entity: EntityGetResponse;
} & Omit<PropertyListProps, 'items'>;
const statusBadgeColor: Record<EntityGetResponseStatus, BadgeColor> = {
  active: 'green',
  disabled: 'yellow',
  archived: 'gray'
};
const evaluationBadgeColor: Record<EntityGetResponseAutomatedCustomerIdentificationProgramEvaluation, BadgeColor> = {
  valid: 'green',
  indeterminate: 'yellow',
  invalid: 'gray'
};
export const EntityPropertyList = (props: EntityPropertyListProps) => {
  const {
    entity: entity,
    ...rest
  } = props;
  const {
    data: operator
  } = useOperatorGet({});
  const groupRow = useGroupPropertyRow(entity.group_id);
  const {
    data: consumerSurveys
  } = useConsumerOnboardingSurveyListInfinite({
    entities: [entity.id],
    limit: 1
  });
  const {
    data: commercialSurveys
  } = useCommercialOnboardingSurveyListInfinite({
    entities: [entity.id],
    limit: 1
  });
  const {
    data: testingEntries
  } = useCustomerIdentificationProgramTestingEntryListInfinite({
    entity_ids: [entity.id],
    limit: 1
  });
  if (!operator) {
    return null;
  }
  const testingEvidence = testingEntries?.pages?.[0]?.data?.[0];
  const consumerSurvey = consumerSurveys?.pages[0]?.data?.[0];
  const commercialSurvey = commercialSurveys?.pages[0]?.data?.[0];
  return <PropertyList {...rest} items={compact<ListItem>([{
    label: 'ID',
    value: entity.id,
    copyable: entity.id
  }, {
    label: 'Status',
    value: humanize(entity.status),
    badgeColor: statusBadgeColor[entity.status]
  }, operator.entitlements.includes('internal_read_write') && {
    label: 'Internal status',
    value: humanize(entity.automated_customer_identification_program_evaluation),
    badgeColor: evaluationBadgeColor[entity.automated_customer_identification_program_evaluation]
  }, operator.entitlements.includes('internal_read_write') && {
    label: 'Directly supervised?',
    value: entity.directly_supervised ? 'Yes' : 'No',
    tooltip: 'Entities are either first-line supervised by Increase or their Group.'
  }, operator.entitlements.includes('internal_read_write') && {
    label: 'Entity Classification',
    valueNode: <div className="mt-1 flex items-center gap-1">
              {entity.has_ownership_accounts && <Badge label="Account owner" size="medium" color="yellow" />}
              {entity.has_ownership_accounts && entity.has_informational_accounts && <Body> & </Body>}
              {entity.has_informational_accounts && <Badge label="Informational" size="medium" color="blue" />}
              {!entity.has_ownership_accounts && !entity.has_informational_accounts && <Body>No accounts.</Body>}
            </div>
  }, {
    label: 'Structure',
    value: humanize(entity.structure)
  }, groupRow, consumerSurvey && {
    label: 'Onboarding survey',
    value: 'View',
    href: buildPath(ROUTES.CONSUMER_ONBOARDING_SURVEYS_LIST, {}, {
      entity: [entity.id]
    })
  }, commercialSurvey && {
    label: 'Onboarding survey',
    value: 'View',
    href: buildPath(ROUTES.COMMERCIAL_ONBOARDING_SURVEYS_LIST, {}, {
      entity: [entity.id]
    })
  }, {
    label: 'Controls',
    value: 'View',
    href: buildPath(ROUTES.CONTROL_RECORDS_LIST, {}, {
      record_ids: [entity.id]
    })
  }, testingEntries?.pages?.[0]?.data && {
    label: 'Customer Identification Program Tests',
    value: testingEvidence ? 'View' : 'Never sampled',
    href: testingEvidence ? buildPath(ROUTES.CUSTOMER_IDENTIFICATION_PROGRAM_TESTING_ENTRIES_LIST, {}, {
      entity: [entity.id]
    }) : undefined
  }, {
    label: 'Created',
    value: entity.created_at,
    format: 'date'
  }])} data-sentry-element="PropertyList" data-sentry-component="EntityPropertyList" data-sentry-source-file="EntityPropertyList.tsx" />;
};