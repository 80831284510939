/**
 * Generated by orval v7.3.0 🍺
 * Do not edit manually.
 * Operations API
 * OpenAPI spec version: 0.0.1
 */
export type ValidPhysicalCardImageFileListParams = {
  cursor?: string;
  limit?: number;
};

export type UserListParams = {
  cursor?: string;
  limit?: number;
  keyword?: string;
};

export type UserSessionListParams = {
  cursor?: string;
  limit?: number;
};

export type UnusualActivityReportListStatusItem =
  (typeof UnusualActivityReportListStatusItem)[keyof typeof UnusualActivityReportListStatusItem];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const UnusualActivityReportListStatusItem = {
  pending: "pending",
  reviewing: "reviewing",
  skipped: "skipped",
  reviewed: "reviewed",
  requires_attention: "requires_attention",
} as const;

export type UnusualActivityReportListParams = {
  cursor?: string;
  limit?: number;
  created_at_or_after?: string;
  created_at_or_before?: string;
  status?: UnusualActivityReportListStatusItem[];
};

export type TransfersByCheckNumberListParams = {
  cursor?: string;
  limit?: number;
  check_number: string;
};

export type TransferReviewListBanksItem =
  (typeof TransferReviewListBanksItem)[keyof typeof TransferReviewListBanksItem];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const TransferReviewListBanksItem = {
  blue_ridge_bank: "blue_ridge_bank",
  core_bank: "core_bank",
  first_internet_bank: "first_internet_bank",
  global_innovations_bank: "global_innovations_bank",
  grasshopper_bank: "grasshopper_bank",
} as const;

export type TransferReviewListReviewersItem =
  (typeof TransferReviewListReviewersItem)[keyof typeof TransferReviewListReviewersItem];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const TransferReviewListReviewersItem = {
  increase: "increase",
  bank: "bank",
} as const;

export type TransferReviewListReasonsItem =
  (typeof TransferReviewListReasonsItem)[keyof typeof TransferReviewListReasonsItem];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const TransferReviewListReasonsItem = {
  transfer_amount_absolute_value: "transfer_amount_absolute_value",
  combined_rolling_24_hour_transfer_volume:
    "combined_rolling_24_hour_transfer_volume",
  rolling_24_hour_volume_for_transfer_type:
    "rolling_24_hour_volume_for_transfer_type",
  credit_exposure: "credit_exposure",
} as const;

export type TransferReviewListTransferTypesItem =
  (typeof TransferReviewListTransferTypesItem)[keyof typeof TransferReviewListTransferTypesItem];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const TransferReviewListTransferTypesItem = {
  check_transfer: "check_transfer",
  real_time_payments_transfer: "real_time_payments_transfer",
  ach_transfer: "ach_transfer",
  wire_transfer: "wire_transfer",
  check_deposit: "check_deposit",
  inbound_ach_entry: "inbound_ach_entry",
  inbound_ctx_entry: "inbound_ctx_entry",
  inbound_international_ach_entry: "inbound_international_ach_entry",
  inbound_wire: "inbound_wire",
} as const;

export type TransferReviewListParams = {
  cursor?: string;
  limit?: number;
  groups?: string[];
  transfer_types?: TransferReviewListTransferTypesItem[];
  reasons?: TransferReviewListReasonsItem[];
  reviewers?: TransferReviewListReviewersItem[];
  banks?: TransferReviewListBanksItem[];
};

export type TransactionsByACHTraceNumberListParams = {
  trace_number: string;
};

export type TransactionsByAccountAndRoutingNumberListParams = {
  cursor?: string;
  limit?: number;
  routing_number: string;
  account_number: string;
};

export type TransactionAccountingEntryForTraceListParams = {
  cursor?: string;
  limit?: number;
  trace_id: string;
};

export type TaskStatusListStatusesItem =
  (typeof TaskStatusListStatusesItem)[keyof typeof TaskStatusListStatusesItem];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const TaskStatusListStatusesItem = {
  active: "active",
  disabled: "disabled",
} as const;

export type TaskStatusListParams = {
  cursor?: string;
  limit?: number;
  statuses?: TaskStatusListStatusesItem[];
};

export type SessionSingleSignOnAuthenticationURLGetParams = {
  email: string;
  state?: string;
};

export type SanctionsScreeningTriggerTokenListReviewersItem =
  (typeof SanctionsScreeningTriggerTokenListReviewersItem)[keyof typeof SanctionsScreeningTriggerTokenListReviewersItem];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SanctionsScreeningTriggerTokenListReviewersItem = {
  increase: "increase",
  grasshopper: "grasshopper",
  core_bank: "core_bank",
} as const;

export type SanctionsScreeningTriggerTokenListParams = {
  cursor?: string;
  limit?: number;
  reviewers?: SanctionsScreeningTriggerTokenListReviewersItem[];
};

export type SanctionsScreeningReviewListRecordTypesItem =
  (typeof SanctionsScreeningReviewListRecordTypesItem)[keyof typeof SanctionsScreeningReviewListRecordTypesItem];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SanctionsScreeningReviewListRecordTypesItem = {
  entity: "entity",
  transfer: "transfer",
} as const;

export type SanctionsScreeningReviewListStatusesItem =
  (typeof SanctionsScreeningReviewListStatusesItem)[keyof typeof SanctionsScreeningReviewListStatusesItem];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SanctionsScreeningReviewListStatusesItem = {
  pending_reviewing: "pending_reviewing",
  backfill: "backfill",
  false_positive: "false_positive",
  true_positive: "true_positive",
  requires_attention: "requires_attention",
} as const;

export type SanctionsScreeningReviewListReviewersItem =
  (typeof SanctionsScreeningReviewListReviewersItem)[keyof typeof SanctionsScreeningReviewListReviewersItem];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SanctionsScreeningReviewListReviewersItem = {
  increase: "increase",
  grasshopper: "grasshopper",
  core_bank: "core_bank",
} as const;

export type SanctionsScreeningReviewListParams = {
  cursor?: string;
  limit?: number;
  record_ids?: string[];
  reviewers?: SanctionsScreeningReviewListReviewersItem[];
  statuses?: SanctionsScreeningReviewListStatusesItem[];
  record_types?: SanctionsScreeningReviewListRecordTypesItem[];
};

export type SanctionsScreeningReviewRecordGetParams = {
  review_id: string;
};

export type SanctionsScreeningHitListStatusItem =
  (typeof SanctionsScreeningHitListStatusItem)[keyof typeof SanctionsScreeningHitListStatusItem];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SanctionsScreeningHitListStatusItem = {
  pending_reviewing: "pending_reviewing",
  backfill: "backfill",
  false_positive: "false_positive",
  true_positive: "true_positive",
  requires_attention: "requires_attention",
} as const;

export type SanctionsScreeningHitListParams = {
  cursor?: string;
  limit?: number;
  review_id?: string;
  status?: SanctionsScreeningHitListStatusItem[];
  record_id?: string;
};

export type SanctionsScreeningAllowlistTokenListReviewersItem =
  (typeof SanctionsScreeningAllowlistTokenListReviewersItem)[keyof typeof SanctionsScreeningAllowlistTokenListReviewersItem];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SanctionsScreeningAllowlistTokenListReviewersItem = {
  increase: "increase",
  grasshopper: "grasshopper",
  core_bank: "core_bank",
} as const;

export type SanctionsScreeningAllowlistTokenListParams = {
  cursor?: string;
  limit?: number;
  reviewers?: SanctionsScreeningAllowlistTokenListReviewersItem[];
};

export type RoutingNumberListParams = {
  cursor?: string;
  limit?: number;
  routing_number?: string;
  name?: string;
  needs_website: boolean;
  needs_icon: boolean;
};

export type RoleListStatusesItem =
  (typeof RoleListStatusesItem)[keyof typeof RoleListStatusesItem];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const RoleListStatusesItem = {
  active: "active",
  deleted: "deleted",
} as const;

export type RoleListTitlesItem =
  (typeof RoleListTitlesItem)[keyof typeof RoleListTitlesItem];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const RoleListTitlesItem = {
  administrator: "administrator",
  owner: "owner",
  developer: "developer",
  controller: "controller",
  clerk: "clerk",
  compliance_v2: "compliance_v2",
  accountant: "accountant",
  viewer: "viewer",
} as const;

export type RoleListParams = {
  cursor?: string;
  limit?: number;
  group_ids?: string[];
  user_ids?: string[];
  titles?: RoleListTitlesItem[];
  statuses?: RoleListStatusesItem[];
};

export type ResultDeleteService =
  (typeof ResultDeleteService)[keyof typeof ResultDeleteService];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ResultDeleteService = {
  accounting: "accounting",
  administration: "administration",
  api: "api",
  context: "context",
  dashboard: "dashboard",
  databasemanager: "databasemanager",
  dummyservice: "dummyservice",
  federalreservegateway: "federalreservegateway",
  ibmmqproxy: "ibmmqproxy",
  imageprocessing: "imageprocessing",
  operations: "operations",
  sandbox: "sandbox",
  site: "site",
  tooling: "tooling",
  webhookproxy: "webhookproxy",
  vault_acquiring: "vault_acquiring",
  vault_brand: "vault_brand",
  vault_threedsecure: "vault_threedsecure",
} as const;

export type ResultDeleteActivity =
  (typeof ResultDeleteActivity)[keyof typeof ResultDeleteActivity];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ResultDeleteActivity = {
  check: "check",
  obligation: "obligation",
  task: "task",
} as const;

export type ResultDeleteParams = {
  activity: ResultDeleteActivity;
  name: string;
  service: ResultDeleteService;
};

export type ResultStatusListActivitiesItem =
  (typeof ResultStatusListActivitiesItem)[keyof typeof ResultStatusListActivitiesItem];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ResultStatusListActivitiesItem = {
  check: "check",
  obligation: "obligation",
  task: "task",
} as const;

export type ResultStatusListStatusesItem =
  (typeof ResultStatusListStatusesItem)[keyof typeof ResultStatusListStatusesItem];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ResultStatusListStatusesItem = {
  succeeding: "succeeding",
  failing: "failing",
  retired: "retired",
} as const;

export type ResultStatusListParams = {
  cursor?: string;
  limit?: number;
  statuses?: ResultStatusListStatusesItem[];
  activities?: ResultStatusListActivitiesItem[];
  suppressed?: boolean;
  description_filter?: string;
};

export type ResultEvaluationListParams = {
  cursor?: string;
  limit?: number;
  result_status_id: string;
};

export type RealTimePaymentsPrefundedPositionOverviewGetBank =
  (typeof RealTimePaymentsPrefundedPositionOverviewGetBank)[keyof typeof RealTimePaymentsPrefundedPositionOverviewGetBank];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const RealTimePaymentsPrefundedPositionOverviewGetBank = {
  blue_ridge_bank: "blue_ridge_bank",
  core_bank: "core_bank",
  first_internet_bank: "first_internet_bank",
  global_innovations_bank: "global_innovations_bank",
  grasshopper_bank: "grasshopper_bank",
} as const;

export type RealTimePaymentsPrefundedPositionOverviewGetParams = {
  bank: RealTimePaymentsPrefundedPositionOverviewGetBank;
};

export type RampBusinessAccountOnboardingSurveyListParams = {
  cursor?: string;
  limit?: number;
  accounts?: string[];
  created_at_or_after?: string;
  created_at_or_before?: string;
};

export type ProgramListComplianceRolesItem =
  (typeof ProgramListComplianceRolesItem)[keyof typeof ProgramListComplianceRolesItem];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ProgramListComplianceRolesItem = {
  first_line: "first_line",
  oversight: "oversight",
  technology_only: "technology_only",
} as const;

export type ProgramListBanksItem =
  (typeof ProgramListBanksItem)[keyof typeof ProgramListBanksItem];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ProgramListBanksItem = {
  blue_ridge_bank: "blue_ridge_bank",
  core_bank: "core_bank",
  first_internet_bank: "first_internet_bank",
  global_innovations_bank: "global_innovations_bank",
  grasshopper_bank: "grasshopper_bank",
} as const;

export type ProgramListParams = {
  cursor?: string;
  limit?: number;
  groups?: string[];
  bank_programs?: string[];
  keyword?: string;
  banks?: ProgramListBanksItem[];
  created_at_or_after?: string;
  created_at_or_before?: string;
  compliance_roles?: ProgramListComplianceRolesItem[];
};

export type ProgramBalanceGetParams = {
  program_id: string;
  date: string;
};

export type ProgramACHReturnRateListParams = {
  cursor?: string;
  limit?: number;
  period_start?: string;
  period_end?: string;
  on_or_over_threshold?: boolean;
  groups?: string[];
  program_ids?: string[];
};

export type PlatformVendorListParams = {
  cursor?: string;
  limit?: number;
  groups?: string[];
  keyword?: string;
};

export type PlatformVendorListSubmissionListParams = {
  cursor?: string;
  limit?: number;
  program_ids?: string[];
};

export type PlatformUnusualActivityReportListParams = {
  cursor?: string;
  limit?: number;
  groups?: string[];
  created_at_or_after?: string;
  created_at_or_before?: string;
};

export type PlatformComplianceSubmissionProgramListParams = {
  cursor?: string;
  limit?: number;
};

export type PlatformComplianceMetricsSubmissionListParams = {
  cursor?: string;
  limit?: number;
  program_ids?: string[];
};

export type PlatformComplaintListParams = {
  cursor?: string;
  limit?: number;
  groups?: string[];
  keyword?: string;
  created_at_or_after?: string;
  created_at_or_before?: string;
};

export type PlatformComplaintListSubmissionListParams = {
  cursor?: string;
  limit?: number;
  program_ids?: string[];
};

export type PhysicalCardImageFileListParams = {
  offset?: number;
  limit?: number;
};

export type PhysicalCardListStatusesItem =
  (typeof PhysicalCardListStatusesItem)[keyof typeof PhysicalCardListStatusesItem];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PhysicalCardListStatusesItem = {
  active: "active",
  disabled: "disabled",
  canceled: "canceled",
} as const;

export type PhysicalCardListShipmentStatusesItem =
  (typeof PhysicalCardListShipmentStatusesItem)[keyof typeof PhysicalCardListShipmentStatusesItem];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PhysicalCardListShipmentStatusesItem = {
  pending: "pending",
  canceled: "canceled",
  submitted: "submitted",
  acknowledged: "acknowledged",
  rejected: "rejected",
  shipped: "shipped",
  returned: "returned",
} as const;

export type PhysicalCardListShipmentMethodsItem =
  (typeof PhysicalCardListShipmentMethodsItem)[keyof typeof PhysicalCardListShipmentMethodsItem];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PhysicalCardListShipmentMethodsItem = {
  usps: "usps",
  fedex_priority_overnight: "fedex_priority_overnight",
  fedex_2_day: "fedex_2_day",
} as const;

export type PhysicalCardListParams = {
  cursor?: string;
  limit?: number;
  groups?: string[];
  card_ids?: string[];
  account_ids?: string[];
  entity_ids?: string[];
  shipment_methods?: PhysicalCardListShipmentMethodsItem[];
  shipment_statuses?: PhysicalCardListShipmentStatusesItem[];
  statuses?: PhysicalCardListStatusesItem[];
  created_at_or_after?: string;
  created_at_or_before?: string;
};

export type PendingTransactionListParams = {
  cursor?: string;
  limit?: number;
  group_ids?: string[];
  account_ids?: string[];
  route_ids?: string[];
  date_at_or_after?: string;
  date_at_or_before?: string;
  amount_or_greater?: number;
  amount_or_less?: number;
  exact_amount?: number;
};

export type PartnerInvitationListRoleItem =
  (typeof PartnerInvitationListRoleItem)[keyof typeof PartnerInvitationListRoleItem];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PartnerInvitationListRoleItem = {
  internal: "internal",
  check_reviewer: "check_reviewer",
  transaction_monitor: "transaction_monitor",
  card_printer: "card_printer",
  partner_read_write: "partner_read_write",
  partner_read_only: "partner_read_only",
  partner_administrator: "partner_administrator",
  limit_approver: "limit_approver",
} as const;

export type PartnerInvitationListBankItem =
  (typeof PartnerInvitationListBankItem)[keyof typeof PartnerInvitationListBankItem];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PartnerInvitationListBankItem = {
  blue_ridge_bank: "blue_ridge_bank",
  core_bank: "core_bank",
  first_internet_bank: "first_internet_bank",
  global_innovations_bank: "global_innovations_bank",
  grasshopper_bank: "grasshopper_bank",
} as const;

export type PartnerInvitationListParams = {
  cursor?: string;
  limit?: number;
  bank?: PartnerInvitationListBankItem[];
  role?: PartnerInvitationListRoleItem[];
  email?: string;
};

export type OperatorListRoleItem =
  (typeof OperatorListRoleItem)[keyof typeof OperatorListRoleItem];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const OperatorListRoleItem = {
  internal: "internal",
  check_reviewer: "check_reviewer",
  transaction_monitor: "transaction_monitor",
  card_printer: "card_printer",
  partner_read_write: "partner_read_write",
  partner_read_only: "partner_read_only",
  partner_administrator: "partner_administrator",
  limit_approver: "limit_approver",
} as const;

export type OperatorListBankItem =
  (typeof OperatorListBankItem)[keyof typeof OperatorListBankItem];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const OperatorListBankItem = {
  blue_ridge_bank: "blue_ridge_bank",
  core_bank: "core_bank",
  first_internet_bank: "first_internet_bank",
  global_innovations_bank: "global_innovations_bank",
  grasshopper_bank: "grasshopper_bank",
} as const;

export type OperatorListParams = {
  cursor?: string;
  limit?: number;
  bank?: OperatorListBankItem[];
  role?: OperatorListRoleItem[];
  email?: string;
};

export type OperatorManualQueueItemListStatusItem =
  (typeof OperatorManualQueueItemListStatusItem)[keyof typeof OperatorManualQueueItemListStatusItem];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const OperatorManualQueueItemListStatusItem = {
  pending_actioning: "pending_actioning",
  actioned: "actioned",
  requires_attention: "requires_attention",
} as const;

export type OperatorManualQueueItemListQueueItem =
  (typeof OperatorManualQueueItemListQueueItem)[keyof typeof OperatorManualQueueItemListQueueItem];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const OperatorManualQueueItemListQueueItem = {
  transaction_review: "transaction_review",
  group_review: "group_review",
  inbound_check_item_allocation: "inbound_check_item_allocation",
  check_transfer_payee_name: "check_transfer_payee_name",
} as const;

export type OperatorManualQueueItemListParams = {
  cursor?: string;
  limit?: number;
  queue?: OperatorManualQueueItemListQueueItem[];
  status?: OperatorManualQueueItemListStatusItem[];
};

export type OperatorTagListParams = {
  cursor?: string;
  limit?: number;
  model_id: string;
};

export type OperatorNoteListParams = {
  cursor?: string;
  limit?: number;
  model_id: string;
};

export type OperatorFileListParams = {
  cursor?: string;
  limit?: number;
  model_id: string;
};

export type OAuthConnectionListParams = {
  cursor?: string;
  limit?: number;
  groups?: string[];
  connected_groups?: string[];
  oauth_applications?: string[];
};

export type OAuthApplicationListParams = {
  cursor?: string;
  limit?: number;
  groups?: string[];
  keyword?: string;
};

export type ManualTaskRunListParams = {
  cursor?: string;
  limit?: number;
  task_name: string;
};

export type ManualTaskPreviewGetParams = {
  manual_task_class: string;
  arguments: unknown;
};

export type ManagementInformationSystemVolumesListPeriod =
  (typeof ManagementInformationSystemVolumesListPeriod)[keyof typeof ManagementInformationSystemVolumesListPeriod];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ManagementInformationSystemVolumesListPeriod = {
  day: "day",
  week: "week",
  month: "month",
  quarter: "quarter",
  year: "year",
} as const;

export type ManagementInformationSystemVolumesListParams = {
  at_time: string;
  period?: ManagementInformationSystemVolumesListPeriod;
};

export type ManagementInformationSystemRiskMetricsListPeriod =
  (typeof ManagementInformationSystemRiskMetricsListPeriod)[keyof typeof ManagementInformationSystemRiskMetricsListPeriod];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ManagementInformationSystemRiskMetricsListPeriod = {
  day: "day",
  week: "week",
  month: "month",
  quarter: "quarter",
  year: "year",
} as const;

export type ManagementInformationSystemRiskMetricsListParams = {
  at_time: string;
  period?: ManagementInformationSystemRiskMetricsListPeriod;
};

export type ManagementInformationSystemRevenuesListPeriod =
  (typeof ManagementInformationSystemRevenuesListPeriod)[keyof typeof ManagementInformationSystemRevenuesListPeriod];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ManagementInformationSystemRevenuesListPeriod = {
  day: "day",
  week: "week",
  month: "month",
  quarter: "quarter",
  year: "year",
} as const;

export type ManagementInformationSystemRevenuesListParams = {
  at_time: string;
  period?: ManagementInformationSystemRevenuesListPeriod;
  date_period?: string;
};

export type ManagementInformationSystemProgramCountsListPeriod =
  (typeof ManagementInformationSystemProgramCountsListPeriod)[keyof typeof ManagementInformationSystemProgramCountsListPeriod];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ManagementInformationSystemProgramCountsListPeriod = {
  day: "day",
  week: "week",
  month: "month",
  quarter: "quarter",
  year: "year",
} as const;

export type ManagementInformationSystemProgramCountsListParams = {
  at_time: string;
  period?: ManagementInformationSystemProgramCountsListPeriod;
};

export type ManagementInformationSystemBalancesListPeriod =
  (typeof ManagementInformationSystemBalancesListPeriod)[keyof typeof ManagementInformationSystemBalancesListPeriod];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ManagementInformationSystemBalancesListPeriod = {
  day: "day",
  week: "week",
  month: "month",
  quarter: "quarter",
  year: "year",
} as const;

export type ManagementInformationSystemBalancesListParams = {
  group_id?: string;
  program_id?: string;
  at_time: string;
  period?: ManagementInformationSystemBalancesListPeriod;
};

export type ManagementInformationSystemAccountsListParams = {
  at_time: string;
};

export type LockboxListStatusesItem =
  (typeof LockboxListStatusesItem)[keyof typeof LockboxListStatusesItem];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const LockboxListStatusesItem = {
  active: "active",
  inactive: "inactive",
} as const;

export type LockboxListParams = {
  cursor?: string;
  limit?: number;
  groups?: string[];
  account_ids?: string[];
  statuses?: LockboxListStatusesItem[];
  keyword?: string;
  line2_suffix?: string;
  created_at_or_after?: string;
  created_at_or_before?: string;
};

export type LateReturnRequestListParams = {
  cursor?: string;
  limit?: number;
  group_ids?: string[];
  keyword?: string;
};

export type InboundMailItemListParams = {
  cursor?: string;
  limit?: number;
};

export type InboundFedNowMessageListParams = {
  cursor?: string;
  limit?: number;
};

export type IdentityDocumentListIdentityVerificationStatus =
  (typeof IdentityDocumentListIdentityVerificationStatus)[keyof typeof IdentityDocumentListIdentityVerificationStatus];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const IdentityDocumentListIdentityVerificationStatus = {
  pending_verification: "pending_verification",
  update_pending_verification: "update_pending_verification",
  verified: "verified",
  falsified: "falsified",
  no_match: "no_match",
  verifying: "verifying",
  requires_attention: "requires_attention",
  not_performed: "not_performed",
  skipped: "skipped",
} as const;

export type IdentityDocumentListParams = {
  cursor?: string;
  limit?: number;
  identity_verification_status?: IdentityDocumentListIdentityVerificationStatus;
};

export type HTMLPreviewGetParams = {
  template_class: string;
};

export type GroupListStatusItem =
  (typeof GroupListStatusItem)[keyof typeof GroupListStatusItem];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GroupListStatusItem = {
  pending_activating: "pending_activating",
  active: "active",
  rejected: "rejected",
  offboarded: "offboarded",
} as const;

export type GroupListParams = {
  cursor?: string;
  limit?: number;
  status?: GroupListStatusItem[];
  keyword?: string;
  created_at_or_after?: string;
  created_at_or_before?: string;
};

export type GroupByNicknameGetParams = {
  nickname: string;
};

export type FraudKnownBadSubmissionsSearchParams = {
  cursor?: string;
  limit?: number;
  groups?: string[];
  entity_ids?: string[];
};

export type FinancialCrimesEnforcementNetworkThreeFourteenAPersonListStatusItem =
  (typeof FinancialCrimesEnforcementNetworkThreeFourteenAPersonListStatusItem)[keyof typeof FinancialCrimesEnforcementNetworkThreeFourteenAPersonListStatusItem];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const FinancialCrimesEnforcementNetworkThreeFourteenAPersonListStatusItem =
  {
    pending_allocating: "pending_allocating",
    pending_reviewing: "pending_reviewing",
    allocated_no_matches: "allocated_no_matches",
    allocated_with_matches: "allocated_with_matches",
    requires_attention: "requires_attention",
  } as const;

export type FinancialCrimesEnforcementNetworkThreeFourteenAPersonListParams = {
  cursor?: string;
  limit?: number;
  file_id: string;
  status?: FinancialCrimesEnforcementNetworkThreeFourteenAPersonListStatusItem[];
};

export type FinancialCrimesEnforcementNetworkThreeFourteenAFileListParams = {
  cursor?: string;
  limit?: number;
};

export type FinancialCrimesEnforcementNetworkThreeFourteenAEntryEntityMatchListParams =
  {
    cursor?: string;
    limit?: number;
    entry_id: string;
  };

export type FinancialCrimesEnforcementNetworkThreeFourteenABusinessListStatusItem =
  (typeof FinancialCrimesEnforcementNetworkThreeFourteenABusinessListStatusItem)[keyof typeof FinancialCrimesEnforcementNetworkThreeFourteenABusinessListStatusItem];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const FinancialCrimesEnforcementNetworkThreeFourteenABusinessListStatusItem =
  {
    pending_allocating: "pending_allocating",
    pending_reviewing: "pending_reviewing",
    allocated_no_matches: "allocated_no_matches",
    allocated_with_matches: "allocated_with_matches",
    requires_attention: "requires_attention",
  } as const;

export type FinancialCrimesEnforcementNetworkThreeFourteenABusinessListParams =
  {
    cursor?: string;
    limit?: number;
    file_id: string;
    status?: FinancialCrimesEnforcementNetworkThreeFourteenABusinessListStatusItem[];
  };

export type FeeStatementListParams = {
  cursor?: string;
  limit?: number;
  group_id: string;
};

export type FeePlanListParams = {
  offset?: number;
  group_id: string;
  program_id?: string;
};

export type FedwireInboundServiceMessageListStatusesItem =
  (typeof FedwireInboundServiceMessageListStatusesItem)[keyof typeof FedwireInboundServiceMessageListStatusesItem];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const FedwireInboundServiceMessageListStatusesItem = {
  actioned: "actioned",
  flagged_by_operator: "flagged_by_operator",
  ignored: "ignored",
  pending_review: "pending_review",
  requires_attention: "requires_attention",
} as const;

export type FedwireInboundServiceMessageListParams = {
  cursor?: string;
  limit?: number;
  statuses?: FedwireInboundServiceMessageListStatusesItem[];
};

export type FedwireInboundRequestForReversalListStatusesItem =
  (typeof FedwireInboundRequestForReversalListStatusesItem)[keyof typeof FedwireInboundRequestForReversalListStatusesItem];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const FedwireInboundRequestForReversalListStatusesItem = {
  abandoned: "abandoned",
  allocated: "allocated",
  flagged_by_operator: "flagged_by_operator",
  pending_allocating: "pending_allocating",
  requires_attention: "requires_attention",
} as const;

export type FedwireInboundRequestForReversalListParams = {
  cursor?: string;
  limit?: number;
  statuses?: FedwireInboundRequestForReversalListStatusesItem[];
};

export type ExternalAccountListParams = {
  cursor?: string;
  limit?: number;
  entity_count_or_greater?: number;
  transaction_count_or_greater?: number;
  routing_number?: string;
  account_number?: string;
};

export type EntityClusterListCategoriesItem =
  (typeof EntityClusterListCategoriesItem)[keyof typeof EntityClusterListCategoriesItem];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const EntityClusterListCategoriesItem = {
  tax_id_match: "tax_id_match",
  address_match: "address_match",
  transaction_match: "transaction_match",
  transaction_with_known_bad: "transaction_with_known_bad",
} as const;

export type EntityClusterListStatusesItem =
  (typeof EntityClusterListStatusesItem)[keyof typeof EntityClusterListStatusesItem];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const EntityClusterListStatusesItem = {
  reviewed: "reviewed",
  pending_reviewing: "pending_reviewing",
} as const;

export type EntityClusterListParams = {
  cursor?: string;
  limit?: number;
  entity_ids?: string[];
  statuses?: EntityClusterListStatusesItem[];
  categories?: EntityClusterListCategoriesItem[];
  count_or_greater?: number;
};

export type EntityAddressVerificationResultListParams = {
  cursor?: string;
  limit?: number;
  addressable_id: string;
};

export type EntityReferenceListStructuresItem =
  (typeof EntityReferenceListStructuresItem)[keyof typeof EntityReferenceListStructuresItem];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const EntityReferenceListStructuresItem = {
  corporation: "corporation",
  natural_person: "natural_person",
  joint: "joint",
  trust: "trust",
  government_authority: "government_authority",
} as const;

export type EntityReferenceListReviewStatusesItem =
  (typeof EntityReferenceListReviewStatusesItem)[keyof typeof EntityReferenceListReviewStatusesItem];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const EntityReferenceListReviewStatusesItem = {
  pending_reviewing: "pending_reviewing",
  review_not_required: "review_not_required",
  approved: "approved",
  approved_by_platform: "approved_by_platform",
  returned_to_user: "returned_to_user",
  requires_attention: "requires_attention",
} as const;

export type EntityReferenceListStatusesItem =
  (typeof EntityReferenceListStatusesItem)[keyof typeof EntityReferenceListStatusesItem];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const EntityReferenceListStatusesItem = {
  active: "active",
  archived: "archived",
  disabled: "disabled",
} as const;

export type EntityReferenceListParams = {
  cursor?: string;
  limit?: number;
  groups?: string[];
  external_account_id?: string;
  statuses?: EntityReferenceListStatusesItem[];
  review_statuses?: EntityReferenceListReviewStatusesItem[];
  structures?: EntityReferenceListStructuresItem[];
  keyword?: string;
  created_at_or_after?: string;
  created_at_or_before?: string;
};

export type EntityByNameListParams = {
  cursor?: string;
  limit?: number;
  name: string;
};

export type DocumentRequestListPlatformPeriodicTemplatedSpreadsheetReportRequestCategoriesItem =
  (typeof DocumentRequestListPlatformPeriodicTemplatedSpreadsheetReportRequestCategoriesItem)[keyof typeof DocumentRequestListPlatformPeriodicTemplatedSpreadsheetReportRequestCategoriesItem];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const DocumentRequestListPlatformPeriodicTemplatedSpreadsheetReportRequestCategoriesItem =
  {
    consumer_data: "consumer_data",
    credit_or_lending: "credit_or_lending",
    funds_availability: "funds_availability",
    marketing: "marketing",
    risk_and_underwriting: "risk_and_underwriting",
    terms: "terms",
    transaction_monitoring: "transaction_monitoring",
    compliance_materials: "compliance_materials",
    technology_information_security: "technology_information_security",
  } as const;

export type DocumentRequestListSourceCategoriesItem =
  (typeof DocumentRequestListSourceCategoriesItem)[keyof typeof DocumentRequestListSourceCategoriesItem];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const DocumentRequestListSourceCategoriesItem = {
  platform_complaint_list_submission_request:
    "platform_complaint_list_submission_request",
  platform_vendor_list_submission_request:
    "platform_vendor_list_submission_request",
  company_information_request: "company_information_request",
  direct_user_diligence_request: "direct_user_diligence_request",
  platform_compliance_metrics_submission_request:
    "platform_compliance_metrics_submission_request",
  platform_financial_report_submission_request:
    "platform_financial_report_submission_request",
  proof_of_authorization_request: "proof_of_authorization_request",
  platform_periodic_templated_spreadsheet_report_request:
    "platform_periodic_templated_spreadsheet_report_request",
} as const;

export type DocumentRequestListStatusesItem =
  (typeof DocumentRequestListStatusesItem)[keyof typeof DocumentRequestListStatusesItem];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const DocumentRequestListStatusesItem = {
  awaiting_submission: "awaiting_submission",
  awaiting_review: "awaiting_review",
  provisionally_accepted: "provisionally_accepted",
  accepted: "accepted",
  canceled: "canceled",
} as const;

export type DocumentRequestListParams = {
  cursor?: string;
  limit?: number;
  group_ids?: string[];
  program_ids?: string[];
  statuses?: DocumentRequestListStatusesItem[];
  source_categories?: DocumentRequestListSourceCategoriesItem[];
  platform_periodic_templated_spreadsheet_report_request_categories?: DocumentRequestListPlatformPeriodicTemplatedSpreadsheetReportRequestCategoriesItem[];
  created_at_or_after?: string;
  created_at_or_before?: string;
};

export type DocumentRequestScheduleListParams = {
  cursor?: string;
  limit?: number;
  program_id: string;
};

export type DigitalWalletImageFileListParams = {
  offset?: number;
  limit?: number;
};

export type CustomerIdentificationProgramTestingEntryListStatusesItem =
  (typeof CustomerIdentificationProgramTestingEntryListStatusesItem)[keyof typeof CustomerIdentificationProgramTestingEntryListStatusesItem];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CustomerIdentificationProgramTestingEntryListStatusesItem = {
  pending_reviewing: "pending_reviewing",
  reviewed_without_remediations: "reviewed_without_remediations",
  reviewed_with_remediation: "reviewed_with_remediation",
  pending_remediation: "pending_remediation",
} as const;

export type CustomerIdentificationProgramTestingEntryListParams = {
  cursor?: string;
  limit?: number;
  batch_ids?: string[];
  bank_program_ids?: string[];
  entity_ids?: string[];
  statuses?: CustomerIdentificationProgramTestingEntryListStatusesItem[];
};

export type CustomerIdentificationProgramTestingBatchListParams = {
  cursor?: string;
  limit?: number;
};

export type ControlRecordListCategoriesItem =
  (typeof ControlRecordListCategoriesItem)[keyof typeof ControlRecordListCategoriesItem];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ControlRecordListCategoriesItem = {
  commingled_account_transactions_are_bookkept_within_forty_eight_hours:
    "commingled_account_transactions_are_bookkept_within_forty_eight_hours",
  commingled_account_bookkeeping_balances_match_within_forty_eight_hours:
    "commingled_account_bookkeeping_balances_match_within_forty_eight_hours",
  entities_in_valid_jurisdictions: "entities_in_valid_jurisdictions",
  entities_have_industry_codes: "entities_have_industry_codes",
  entities_have_confirmed_details: "entities_have_confirmed_details",
  accounts_have_onboarding_surveys: "accounts_have_onboarding_surveys",
  accounts_have_non_negative_balances: "accounts_have_non_negative_balances",
  transactions_are_bookkept: "transactions_are_bookkept",
  entities_have_physical_addresses: "entities_have_physical_addresses",
  entities_have_supplemental_documents: "entities_have_supplemental_documents",
  entities_have_validated_supplemental_documents:
    "entities_have_validated_supplemental_documents",
  corporate_entities_have_controllers: "corporate_entities_have_controllers",
  bookkeeping_accounts_have_valid_balances:
    "bookkeeping_accounts_have_valid_balances",
  entities_have_tax_identifiers: "entities_have_tax_identifiers",
  entities_have_valid_addresses: "entities_have_valid_addresses",
} as const;

export type ControlRecordListResultsItem =
  (typeof ControlRecordListResultsItem)[keyof typeof ControlRecordListResultsItem];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ControlRecordListResultsItem = {
  success: "success",
  failure: "failure",
} as const;

export type ControlRecordListParams = {
  cursor?: string;
  limit?: number;
  control_configuration_ids?: string[];
  results?: ControlRecordListResultsItem[];
  categories?: ControlRecordListCategoriesItem[];
  record_ids?: string[];
};

export type ControlEvaluationListResultsItem =
  (typeof ControlEvaluationListResultsItem)[keyof typeof ControlEvaluationListResultsItem];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ControlEvaluationListResultsItem = {
  success: "success",
  failure: "failure",
} as const;

export type ControlEvaluationListParams = {
  cursor?: string;
  limit?: number;
  control_record_id: string;
  results?: ControlEvaluationListResultsItem[];
};

export type ControlConfigurationListCategoriesItem =
  (typeof ControlConfigurationListCategoriesItem)[keyof typeof ControlConfigurationListCategoriesItem];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ControlConfigurationListCategoriesItem = {
  commingled_account_transactions_are_bookkept_within_forty_eight_hours:
    "commingled_account_transactions_are_bookkept_within_forty_eight_hours",
  commingled_account_bookkeeping_balances_match_within_forty_eight_hours:
    "commingled_account_bookkeeping_balances_match_within_forty_eight_hours",
  entities_in_valid_jurisdictions: "entities_in_valid_jurisdictions",
  entities_have_industry_codes: "entities_have_industry_codes",
  entities_have_confirmed_details: "entities_have_confirmed_details",
  accounts_have_onboarding_surveys: "accounts_have_onboarding_surveys",
  accounts_have_non_negative_balances: "accounts_have_non_negative_balances",
  transactions_are_bookkept: "transactions_are_bookkept",
  entities_have_physical_addresses: "entities_have_physical_addresses",
  entities_have_supplemental_documents: "entities_have_supplemental_documents",
  entities_have_validated_supplemental_documents:
    "entities_have_validated_supplemental_documents",
  corporate_entities_have_controllers: "corporate_entities_have_controllers",
  bookkeeping_accounts_have_valid_balances:
    "bookkeeping_accounts_have_valid_balances",
  entities_have_tax_identifiers: "entities_have_tax_identifiers",
  entities_have_valid_addresses: "entities_have_valid_addresses",
} as const;

export type ControlConfigurationListResultsItem =
  (typeof ControlConfigurationListResultsItem)[keyof typeof ControlConfigurationListResultsItem];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ControlConfigurationListResultsItem = {
  success: "success",
  failure: "failure",
} as const;

export type ControlConfigurationListParams = {
  cursor?: string;
  limit?: number;
  groups?: string[];
  program_ids?: string[];
  results?: ControlConfigurationListResultsItem[];
  categories?: ControlConfigurationListCategoriesItem[];
};

export type ConsumerOnboardingSurveyListParams = {
  cursor?: string;
  limit?: number;
  programs?: string[];
  entities?: string[];
  created_at_or_after?: string;
  created_at_or_before?: string;
};

export type ComplianceDocumentListParams = {
  cursor?: string;
  limit?: number;
};

export type CompletedTransactionListSourceTypesItem =
  (typeof CompletedTransactionListSourceTypesItem)[keyof typeof CompletedTransactionListSourceTypesItem];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CompletedTransactionListSourceTypesItem = {
  "API::ACHCheckConversion": "API::ACHCheckConversion",
  "API::ACHCheckConversionReturn": "API::ACHCheckConversionReturn",
  "API::ACHTransferIntention": "API::ACHTransferIntention",
  "API::ACHTransferRejection": "API::ACHTransferRejection",
  "API::ACHTransferReturn": "API::ACHTransferReturn",
  "API::AccountTransferIntention": "API::AccountTransferIntention",
  "API::CardDisputeAcceptance": "API::CardDisputeAcceptance",
  "API::CardDisputeLoss": "API::CardDisputeLoss",
  "API::CardRefund": "API::CardRefund",
  "API::CardRevenuePayment": "API::CardRevenuePayment",
  "API::CardSettlement": "API::CardSettlement",
  "API::CashbackPayment": "API::CashbackPayment",
  "API::CheckDepositAcceptance": "API::CheckDepositAcceptance",
  "API::CheckDepositReturn": "API::CheckDepositReturn",
  "API::CheckTransferIntention": "API::CheckTransferIntention",
  "API::CheckTransferRejection": "API::CheckTransferRejection",
  "API::CheckTransferReturn": "API::CheckTransferReturn",
  "API::CheckTransferStopPaymentRequest":
    "API::CheckTransferStopPaymentRequest",
  "API::DeprecatedTransactionSource": "API::DeprecatedTransactionSource",
  "API::DisputeResolution": "API::DisputeResolution",
  "API::EmpyrealCashDeposit": "API::EmpyrealCashDeposit",
  "API::FeePayment": "API::FeePayment",
  "API::InboundACHTransferIntention": "API::InboundACHTransferIntention",
  "API::InboundACHTransferReturnIntention":
    "API::InboundACHTransferReturnIntention",
  "API::InboundCheck": "API::InboundCheck",
  "API::InboundCheckDepositReturnIntention":
    "API::InboundCheckDepositReturnIntention",
  "API::InboundCheckAdjustment": "API::InboundCheckAdjustment",
  "API::InboundRealTimePaymentsTransferConfirmation":
    "API::InboundRealTimePaymentsTransferConfirmation",
  "API::InboundWireReversal": "API::InboundWireReversal",
  "API::InboundWireTransferIntention": "API::InboundWireTransferIntention",
  "API::InboundWireTransferReversalIntention":
    "API::InboundWireTransferReversalIntention",
  "API::InterestPayment": "API::InterestPayment",
  "API::InternalGeneralLedgerTransaction":
    "API::InternalGeneralLedgerTransaction",
  "API::InternalSource": "API::InternalSource",
  "API::PrivacyCardRefund": "API::PrivacyCardRefund",
  "API::PrivacyCardSettlement": "API::PrivacyCardSettlement",
  "API::RealTimePaymentsTransferAcknowledgement":
    "API::RealTimePaymentsTransferAcknowledgement",
  "API::SampleFunds": "API::SampleFunds",
  "API::WireDrawdownPaymentIntention": "API::WireDrawdownPaymentIntention",
  "API::WireTransferIntention": "API::WireTransferIntention",
  "API::WireTransferRejection": "API::WireTransferRejection",
  "API::ACHDecline": "API::ACHDecline",
  "API::CardDecline": "API::CardDecline",
  "API::CheckDecline": "API::CheckDecline",
  "API::CheckDepositRejection": "API::CheckDepositRejection",
  "API::InboundRealTimePaymentsTransferDecline":
    "API::InboundRealTimePaymentsTransferDecline",
  "API::PrivacyCardDecline": "API::PrivacyCardDecline",
  "API::WireDecline": "API::WireDecline",
} as const;

export type CompletedTransactionListParams = {
  cursor?: string;
  limit?: number;
  accounts?: string[];
  account_numbers?: string[];
  cards?: string[];
  lockboxes?: string[];
  groups?: string[];
  programs?: string[];
  source_types?: CompletedTransactionListSourceTypesItem[];
  date_at_or_after?: string;
  date_at_or_before?: string;
  amount_or_greater?: number;
  amount_or_less?: number;
  exact_amount?: number;
};

export type ComplaintEntryListParams = {
  cursor?: string;
  limit?: number;
};

export type CommercialOnboardingSurveyListParams = {
  cursor?: string;
  limit?: number;
  programs?: string[];
  entities?: string[];
  created_at_or_after?: string;
  created_at_or_before?: string;
};

export type CheckTransferLookupGetParams = {
  check_number: string;
};

export type CheckDepositListStatus =
  (typeof CheckDepositListStatus)[keyof typeof CheckDepositListStatus];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CheckDepositListStatus = {
  pending_reviewing: "pending_reviewing",
  pending_transfer_reviewing: "pending_transfer_reviewing",
  pending_manual_reviewing: "pending_manual_reviewing",
  pending_submission: "pending_submission",
  submitted: "submitted",
  rejected: "rejected",
  returned: "returned",
  requires_attention: "requires_attention",
} as const;

export type CheckDepositListParams = {
  status?: CheckDepositListStatus;
};

export type CheckDepositContextGetParams = {
  check_deposit_id: string;
  auxiliary_on_us?: string;
  serial_number?: string;
  routing_number: string;
  account_number: string;
};

export type CheckItemAllocationSearchDirectiveGetParams = {
  account_number: string;
  routing_number: string;
  check_number: string;
};

export type CashReconciliationUnreconciledExpectedCashTransactionTupleListAccount =
  (typeof CashReconciliationUnreconciledExpectedCashTransactionTupleListAccount)[keyof typeof CashReconciliationUnreconciledExpectedCashTransactionTupleListAccount];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CashReconciliationUnreconciledExpectedCashTransactionTupleListAccount =
  {
    blue_ridge_bank_federal_reserve: "blue_ridge_bank_federal_reserve",
    first_internet_bank_federal_reserve: "first_internet_bank_federal_reserve",
    global_innovations_bank_federal_reserve:
      "global_innovations_bank_federal_reserve",
    grasshopper_bank_federal_reserve: "grasshopper_bank_federal_reserve",
    core_bank_federal_reserve: "core_bank_federal_reserve",
  } as const;

export type CashReconciliationUnreconciledExpectedCashTransactionTupleListParams =
  {
    account: CashReconciliationUnreconciledExpectedCashTransactionTupleListAccount;
    start_date: string;
    end_date: string;
  };

export type CashReconciliationUnreconciledExpectedCashTransactionTupleDetailsGetAccount =
  (typeof CashReconciliationUnreconciledExpectedCashTransactionTupleDetailsGetAccount)[keyof typeof CashReconciliationUnreconciledExpectedCashTransactionTupleDetailsGetAccount];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CashReconciliationUnreconciledExpectedCashTransactionTupleDetailsGetAccount =
  {
    blue_ridge_bank_federal_reserve: "blue_ridge_bank_federal_reserve",
    first_internet_bank_federal_reserve: "first_internet_bank_federal_reserve",
    global_innovations_bank_federal_reserve:
      "global_innovations_bank_federal_reserve",
    grasshopper_bank_federal_reserve: "grasshopper_bank_federal_reserve",
    core_bank_federal_reserve: "core_bank_federal_reserve",
  } as const;

export type CashReconciliationUnreconciledExpectedCashTransactionTupleDetailsGetParams =
  {
    account: CashReconciliationUnreconciledExpectedCashTransactionTupleDetailsGetAccount;
    financial_institution_reconcilement_data_settlement_date: string;
    financial_institution_reconcilement_data_transaction_code: string;
  };

export type CashReconciliationOverviewGetAccount =
  (typeof CashReconciliationOverviewGetAccount)[keyof typeof CashReconciliationOverviewGetAccount];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CashReconciliationOverviewGetAccount = {
  blue_ridge_bank_federal_reserve: "blue_ridge_bank_federal_reserve",
  first_internet_bank_federal_reserve: "first_internet_bank_federal_reserve",
  global_innovations_bank_federal_reserve:
    "global_innovations_bank_federal_reserve",
  grasshopper_bank_federal_reserve: "grasshopper_bank_federal_reserve",
  core_bank_federal_reserve: "core_bank_federal_reserve",
} as const;

export type CashReconciliationOverviewGetParams = {
  account: CashReconciliationOverviewGetAccount;
};

export type CardListStatusesItem =
  (typeof CardListStatusesItem)[keyof typeof CardListStatusesItem];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CardListStatusesItem = {
  active: "active",
  disabled: "disabled",
  canceled: "canceled",
} as const;

export type CardListParams = {
  cursor?: string;
  limit?: number;
  groups?: string[];
  account_ids?: string[];
  entity_ids?: string[];
  statuses?: CardListStatusesItem[];
  keyword?: string;
  created_at_or_after?: string;
  created_at_or_before?: string;
};

export type CardDisputeListStatusesItem =
  (typeof CardDisputeListStatusesItem)[keyof typeof CardDisputeListStatusesItem];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CardDisputeListStatusesItem = {
  pending_reviewing: "pending_reviewing",
  pending_user_information: "pending_user_information",
  accepted: "accepted",
  rejected: "rejected",
  lost: "lost",
  won: "won",
} as const;

export type CardDisputeListParams = {
  cursor?: string;
  limit?: number;
  card_ids?: string[];
  statuses?: CardDisputeListStatusesItem[];
};

export type BookkeepingEntryListParams = {
  cursor?: string;
  limit?: number;
  bookkeeping_account_ids?: string[];
};

export type BookkeepingAccountListComplianceCategoriesItem =
  (typeof BookkeepingAccountListComplianceCategoriesItem)[keyof typeof BookkeepingAccountListComplianceCategoriesItem];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const BookkeepingAccountListComplianceCategoriesItem = {
  commingled_cash: "commingled_cash",
  customer_balance: "customer_balance",
} as const;

export type BookkeepingAccountListParams = {
  cursor?: string;
  limit?: number;
  groups?: string[];
  compliance_categories?: BookkeepingAccountListComplianceCategoriesItem[];
};

export type BeneficialOwnerRelationshipListParams = {
  cursor?: string;
  limit?: number;
  beneficial_owner_id: string;
};

export type BankProgramListBankItem =
  (typeof BankProgramListBankItem)[keyof typeof BankProgramListBankItem];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const BankProgramListBankItem = {
  blue_ridge_bank: "blue_ridge_bank",
  core_bank: "core_bank",
  first_internet_bank: "first_internet_bank",
  global_innovations_bank: "global_innovations_bank",
  grasshopper_bank: "grasshopper_bank",
} as const;

export type BankProgramListParams = {
  cursor?: string;
  limit?: number;
  bank?: BankProgramListBankItem[];
  keyword?: string;
};

export type BankFeeListFeeTypeItem =
  (typeof BankFeeListFeeTypeItem)[keyof typeof BankFeeListFeeTypeItem];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const BankFeeListFeeTypeItem = {
  implementation: "implementation",
  correction: "correction",
  check_origination: "check_origination",
  ach_same_day_origination: "ach_same_day_origination",
  ach_next_day_origination: "ach_next_day_origination",
  ach_return: "ach_return",
  ach_unauthorized_return: "ach_unauthorized_return",
  wire_transfer_origination: "wire_transfer_origination",
  real_time_payments_transfer_origination:
    "real_time_payments_transfer_origination",
  card_volume: "card_volume",
  monthly_base_fee: "monthly_base_fee",
  consumer_entity: "consumer_entity",
  non_card_transaction_minimum: "non_card_transaction_minimum",
  card_transaction_minimum: "card_transaction_minimum",
} as const;

export type BankFeeListParams = {
  cursor?: string;
  limit?: number;
  bank_fee_statement_id: string;
  fee_type?: BankFeeListFeeTypeItem[];
};

export type BankFeeStatementListParams = {
  cursor?: string;
  limit?: number;
  bank_program_id: string;
};

export type AttestationListParams = {
  cursor?: string;
  limit?: number;
};

export type ACHTransferLookupGetParams = {
  trace_number: string;
  amount: number;
};

export type ACHCompanyIDLookupGetParams = {
  ach_company_id: string;
};

export type ACHOriginationVolumeMonitoringGetBank =
  (typeof ACHOriginationVolumeMonitoringGetBank)[keyof typeof ACHOriginationVolumeMonitoringGetBank];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ACHOriginationVolumeMonitoringGetBank = {
  blue_ridge_bank: "blue_ridge_bank",
  core_bank: "core_bank",
  first_internet_bank: "first_internet_bank",
  global_innovations_bank: "global_innovations_bank",
  grasshopper_bank: "grasshopper_bank",
} as const;

export type ACHOriginationVolumeMonitoringGetParams = {
  bank: ACHOriginationVolumeMonitoringGetBank;
  processing_date: string;
};

export type ACHHistoricalOriginationVolumeForRoutingNumberGetRoutingNumber =
  (typeof ACHHistoricalOriginationVolumeForRoutingNumberGetRoutingNumber)[keyof typeof ACHHistoricalOriginationVolumeForRoutingNumberGetRoutingNumber];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ACHHistoricalOriginationVolumeForRoutingNumberGetRoutingNumber = {
  NUMBER_051402372: "051402372",
  NUMBER_053112929: "053112929",
  NUMBER_074014187: "074014187",
  NUMBER_074920909: "074920909",
  NUMBER_091218461: "091218461",
  NUMBER_091218490: "091218490",
  NUMBER_026015024: "026015024",
  NUMBER_026015244: "026015244",
  NUMBER_104001808: "104001808",
  NUMBER_104902114: "104902114",
} as const;

export type ACHHistoricalOriginationVolumeForRoutingNumberGetParams = {
  routing_number: ACHHistoricalOriginationVolumeForRoutingNumberGetRoutingNumber;
};

export type AccountListBanksItem =
  (typeof AccountListBanksItem)[keyof typeof AccountListBanksItem];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const AccountListBanksItem = {
  blue_ridge_bank: "blue_ridge_bank",
  core_bank: "core_bank",
  first_internet_bank: "first_internet_bank",
  global_innovations_bank: "global_innovations_bank",
  grasshopper_bank: "grasshopper_bank",
} as const;

export type AccountListStatusesItem =
  (typeof AccountListStatusesItem)[keyof typeof AccountListStatusesItem];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const AccountListStatusesItem = {
  closed: "closed",
  open: "open",
  pending_opening: "pending_opening",
} as const;

export type AccountListParams = {
  cursor?: string;
  limit?: number;
  groups?: string[];
  entity_ids?: string[];
  program_ids?: string[];
  statuses?: AccountListStatusesItem[];
  banks?: AccountListBanksItem[];
  keyword?: string;
  created_at_or_after?: string;
  created_at_or_before?: string;
};

export type AccountStatementListParams = {
  cursor?: string;
  limit?: number;
  accounts?: string[];
  statement_period_start_or_after?: string;
  statement_period_start_or_before?: string;
};

export type AccountByAccountNumberGetParams = {
  account_number: string;
};

export type AccountBalanceGetParams = {
  account_id: string;
  date: string;
};

export type AccountNumberListStatusesItem =
  (typeof AccountNumberListStatusesItem)[keyof typeof AccountNumberListStatusesItem];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const AccountNumberListStatusesItem = {
  active: "active",
  disabled: "disabled",
  canceled: "canceled",
} as const;

export type AccountNumberListParams = {
  cursor?: string;
  limit?: number;
  groups?: string[];
  statuses?: AccountNumberListStatusesItem[];
  account_ids?: string[];
  keyword?: string;
  created_at_or_after?: string;
  created_at_or_before?: string;
};

export interface Error {
  message: string;
  status: number;
}

export type ValidPhysicalCardImageFileListResponseNextCursor = string | null;

export type ValidPhysicalCardImageFileListResponseDataItemImageType =
  (typeof ValidPhysicalCardImageFileListResponseDataItemImageType)[keyof typeof ValidPhysicalCardImageFileListResponseDataItemImageType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ValidPhysicalCardImageFileListResponseDataItemImageType = {
  front: "front",
  back: "back",
  carrier: "carrier",
} as const;

export type ValidPhysicalCardImageFileListResponseDataItemGroupName =
  | string
  | null;

export type ValidPhysicalCardImageFileListResponseDataItem = {
  created_at: string;
  group_id: string;
  group_name: ValidPhysicalCardImageFileListResponseDataItemGroupName;
  id: string;
  image_content: string;
  image_type: ValidPhysicalCardImageFileListResponseDataItemImageType;
  updated_at: string;
};

export interface ValidPhysicalCardImageFileListResponse {
  data: ValidPhysicalCardImageFileListResponseDataItem[];
  next_cursor: ValidPhysicalCardImageFileListResponseNextCursor;
}

export type UserSessionListResponseNextCursor = string | null;

export type UserSessionListResponseDataItem = {
  created_at: string;
  group_id: string;
  id: string;
  user_email: string;
};

export interface UserSessionListResponse {
  data: UserSessionListResponseDataItem[];
  next_cursor: UserSessionListResponseNextCursor;
}

export type UserListResponseNextCursor = string | null;

export interface UserListResponse {
  data: UserListResponseDataItem[];
  next_cursor: UserListResponseNextCursor;
}

export type UserListResponseDataItemEnrichmentAnyOf = {
  created_at: string;
  results: unknown;
};

export type UserListResponseDataItemEnrichment =
  UserListResponseDataItemEnrichmentAnyOf | null;

export type UserListResponseDataItemEmailVerificationStatus =
  (typeof UserListResponseDataItemEmailVerificationStatus)[keyof typeof UserListResponseDataItemEmailVerificationStatus];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const UserListResponseDataItemEmailVerificationStatus = {
  pending_verification: "pending_verification",
  ignored: "ignored",
  verified: "verified",
  requires_attention: "requires_attention",
} as const;

export type UserListResponseDataItem = {
  created_at: string;
  email: string;
  email_verification_status: UserListResponseDataItemEmailVerificationStatus;
  enrichment: UserListResponseDataItemEnrichment;
  id: string;
};

export interface UserImpersonatePostResponse {
  [key: string]: unknown;
}

export type UserGetResponseEnrichmentAnyOf = {
  created_at: string;
  results: unknown;
};

export type UserGetResponseEnrichment = UserGetResponseEnrichmentAnyOf | null;

export type UserGetResponseEmailVerificationStatus =
  (typeof UserGetResponseEmailVerificationStatus)[keyof typeof UserGetResponseEmailVerificationStatus];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const UserGetResponseEmailVerificationStatus = {
  pending_verification: "pending_verification",
  ignored: "ignored",
  verified: "verified",
  requires_attention: "requires_attention",
} as const;

export interface UserGetResponse {
  created_at: string;
  email: string;
  email_verification_status: UserGetResponseEmailVerificationStatus;
  enrichment: UserGetResponseEnrichment;
  id: string;
}

export type UnusualActivityReportPostResponseRelatedTransactionsItem = {
  id: string;
};

export type UnusualActivityReportPostResponseRelatedGroupsItem = {
  id: string;
};

export type UnusualActivityReportPostResponseRelatedEntitiesItem = {
  id: string;
};

export type UnusualActivityReportPostResponseRelatedDeclinedTransactionsItem = {
  id: string;
};

export type UnusualActivityReportPostResponseRelatedCardsItem = {
  id: string;
};

export type UnusualActivityReportPostResponseRelatedAccountsItem = {
  id: string;
};

export type UnusualActivityReportPostResponseRelatedAccountNumbersItem = {
  id: string;
};

export type UnusualActivityReportPostResponseReferralChannel =
  (typeof UnusualActivityReportPostResponseReferralChannel)[keyof typeof UnusualActivityReportPostResponseReferralChannel];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const UnusualActivityReportPostResponseReferralChannel = {
  external_bank_reported: "external_bank_reported",
  external_platform_reported: "external_platform_reported",
  business: "business",
  internal_monitoring: "internal_monitoring",
} as const;

export type UnusualActivityReportPostResponseReferenceNumber = string | null;

export type UnusualActivityReportPostResponseIncreaseReviewStatus =
  (typeof UnusualActivityReportPostResponseIncreaseReviewStatus)[keyof typeof UnusualActivityReportPostResponseIncreaseReviewStatus];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const UnusualActivityReportPostResponseIncreaseReviewStatus = {
  pending: "pending",
  reviewing: "reviewing",
  skipped: "skipped",
  reviewed: "reviewed",
  requires_attention: "requires_attention",
} as const;

export type UnusualActivityReportPostResponseDeterminedOn = string | null;

export type UnusualActivityReportPostResponseBank =
  (typeof UnusualActivityReportPostResponseBank)[keyof typeof UnusualActivityReportPostResponseBank];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const UnusualActivityReportPostResponseBank = {
  blue_ridge_bank: "blue_ridge_bank",
  core_bank: "core_bank",
  first_internet_bank: "first_internet_bank",
  global_innovations_bank: "global_innovations_bank",
  grasshopper_bank: "grasshopper_bank",
} as const;

export interface UnusualActivityReportPostResponse {
  activity_began_on: string;
  activity_ended_on: string;
  bank: UnusualActivityReportPostResponseBank;
  created_at: string;
  determined_on: UnusualActivityReportPostResponseDeterminedOn;
  id: string;
  increase_review_status: UnusualActivityReportPostResponseIncreaseReviewStatus;
  narrative: string;
  operator_email: string;
  reference_number: UnusualActivityReportPostResponseReferenceNumber;
  referral_channel: UnusualActivityReportPostResponseReferralChannel;
  related_account_numbers: UnusualActivityReportPostResponseRelatedAccountNumbersItem[];
  related_accounts: UnusualActivityReportPostResponseRelatedAccountsItem[];
  related_cards: UnusualActivityReportPostResponseRelatedCardsItem[];
  related_declined_transactions: UnusualActivityReportPostResponseRelatedDeclinedTransactionsItem[];
  related_entities: UnusualActivityReportPostResponseRelatedEntitiesItem[];
  related_groups: UnusualActivityReportPostResponseRelatedGroupsItem[];
  related_transactions: UnusualActivityReportPostResponseRelatedTransactionsItem[];
  updated_at: string;
}

export type UnusualActivityReportPostParametersReferralChannel =
  (typeof UnusualActivityReportPostParametersReferralChannel)[keyof typeof UnusualActivityReportPostParametersReferralChannel];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const UnusualActivityReportPostParametersReferralChannel = {
  external_bank_reported: "external_bank_reported",
  external_platform_reported: "external_platform_reported",
  business: "business",
  internal_monitoring: "internal_monitoring",
} as const;

export type UnusualActivityReportPostParametersBank =
  (typeof UnusualActivityReportPostParametersBank)[keyof typeof UnusualActivityReportPostParametersBank];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const UnusualActivityReportPostParametersBank = {
  blue_ridge_bank: "blue_ridge_bank",
  core_bank: "core_bank",
  first_internet_bank: "first_internet_bank",
  global_innovations_bank: "global_innovations_bank",
  grasshopper_bank: "grasshopper_bank",
} as const;

export interface UnusualActivityReportPostParameters {
  activity_began_on: string;
  activity_ended_on: string;
  bank: UnusualActivityReportPostParametersBank;
  determined_on?: string;
  narrative: string;
  reference_number?: string;
  referral_channel: UnusualActivityReportPostParametersReferralChannel;
  related_account_ids: string[];
  related_account_number_ids: string[];
  related_card_ids: string[];
  related_declined_transaction_ids: string[];
  related_entity_ids: string[];
  related_group_ids: string[];
  related_transaction_ids: string[];
}

export type UnusualActivityReportOperatorFilePostResponsePurpose =
  | "audit"
  | "know_your_customer"
  | "export"
  | "other"
  | "unusual_activity_report_attachment"
  | null;

export type UnusualActivityReportOperatorFilePostResponseDescription =
  | string
  | null;

export interface UnusualActivityReportOperatorFilePostResponse {
  created_at: string;
  description: UnusualActivityReportOperatorFilePostResponseDescription;
  file_hash: string;
  id: string;
  model_id: string;
  model_type: string;
  operator_email: string;
  operator_id: string;
  purpose: UnusualActivityReportOperatorFilePostResponsePurpose;
  transmission_name: string;
}

export interface UnusualActivityReportOperatorFilePostParameters {
  description?: string;
  file: Blob;
  model_id: string;
}

export type UnusualActivityReportListResponseNextCursor = string | null;

export type UnusualActivityReportListResponseDataItemReferralChannel =
  (typeof UnusualActivityReportListResponseDataItemReferralChannel)[keyof typeof UnusualActivityReportListResponseDataItemReferralChannel];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const UnusualActivityReportListResponseDataItemReferralChannel = {
  external_bank_reported: "external_bank_reported",
  external_platform_reported: "external_platform_reported",
  business: "business",
  internal_monitoring: "internal_monitoring",
} as const;

export type UnusualActivityReportListResponseDataItemReferenceNumber =
  | string
  | null;

export type UnusualActivityReportListResponseDataItemIncreaseReviewStatus =
  (typeof UnusualActivityReportListResponseDataItemIncreaseReviewStatus)[keyof typeof UnusualActivityReportListResponseDataItemIncreaseReviewStatus];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const UnusualActivityReportListResponseDataItemIncreaseReviewStatus = {
  pending: "pending",
  reviewing: "reviewing",
  skipped: "skipped",
  reviewed: "reviewed",
  requires_attention: "requires_attention",
} as const;

export type UnusualActivityReportListResponseDataItem = {
  created_at: string;
  id: string;
  increase_review_status: UnusualActivityReportListResponseDataItemIncreaseReviewStatus;
  narrative: string;
  operator_email: string;
  reference_number: UnusualActivityReportListResponseDataItemReferenceNumber;
  referral_channel: UnusualActivityReportListResponseDataItemReferralChannel;
  updated_at: string;
};

export interface UnusualActivityReportListResponse {
  data: UnusualActivityReportListResponseDataItem[];
  next_cursor: UnusualActivityReportListResponseNextCursor;
}

export type UnusualActivityReportGetResponseRelatedTransactionsItem = {
  id: string;
};

export type UnusualActivityReportGetResponseRelatedGroupsItem = {
  id: string;
};

export type UnusualActivityReportGetResponseRelatedEntitiesItem = {
  id: string;
};

export type UnusualActivityReportGetResponseRelatedDeclinedTransactionsItem = {
  id: string;
};

export type UnusualActivityReportGetResponseRelatedCardsItem = {
  id: string;
};

export type UnusualActivityReportGetResponseRelatedAccountsItem = {
  id: string;
};

export type UnusualActivityReportGetResponseRelatedAccountNumbersItem = {
  id: string;
};

export type UnusualActivityReportGetResponseReferralChannel =
  (typeof UnusualActivityReportGetResponseReferralChannel)[keyof typeof UnusualActivityReportGetResponseReferralChannel];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const UnusualActivityReportGetResponseReferralChannel = {
  external_bank_reported: "external_bank_reported",
  external_platform_reported: "external_platform_reported",
  business: "business",
  internal_monitoring: "internal_monitoring",
} as const;

export type UnusualActivityReportGetResponseReferenceNumber = string | null;

export type UnusualActivityReportGetResponseIncreaseReviewStatus =
  (typeof UnusualActivityReportGetResponseIncreaseReviewStatus)[keyof typeof UnusualActivityReportGetResponseIncreaseReviewStatus];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const UnusualActivityReportGetResponseIncreaseReviewStatus = {
  pending: "pending",
  reviewing: "reviewing",
  skipped: "skipped",
  reviewed: "reviewed",
  requires_attention: "requires_attention",
} as const;

export type UnusualActivityReportGetResponseDeterminedOn = string | null;

export type UnusualActivityReportGetResponseBank =
  (typeof UnusualActivityReportGetResponseBank)[keyof typeof UnusualActivityReportGetResponseBank];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const UnusualActivityReportGetResponseBank = {
  blue_ridge_bank: "blue_ridge_bank",
  core_bank: "core_bank",
  first_internet_bank: "first_internet_bank",
  global_innovations_bank: "global_innovations_bank",
  grasshopper_bank: "grasshopper_bank",
} as const;

export interface UnusualActivityReportGetResponse {
  activity_began_on: string;
  activity_ended_on: string;
  bank: UnusualActivityReportGetResponseBank;
  created_at: string;
  determined_on: UnusualActivityReportGetResponseDeterminedOn;
  id: string;
  increase_review_status: UnusualActivityReportGetResponseIncreaseReviewStatus;
  narrative: string;
  operator_email: string;
  reference_number: UnusualActivityReportGetResponseReferenceNumber;
  referral_channel: UnusualActivityReportGetResponseReferralChannel;
  related_account_numbers: UnusualActivityReportGetResponseRelatedAccountNumbersItem[];
  related_accounts: UnusualActivityReportGetResponseRelatedAccountsItem[];
  related_cards: UnusualActivityReportGetResponseRelatedCardsItem[];
  related_declined_transactions: UnusualActivityReportGetResponseRelatedDeclinedTransactionsItem[];
  related_entities: UnusualActivityReportGetResponseRelatedEntitiesItem[];
  related_groups: UnusualActivityReportGetResponseRelatedGroupsItem[];
  related_transactions: UnusualActivityReportGetResponseRelatedTransactionsItem[];
  updated_at: string;
}

export type UnitedStatesPostalServiceWebhookPostResponse = string;

export type TransfersByCheckNumberListResponseNextCursor = string | null;

export interface TransfersByCheckNumberListResponse {
  data: TransfersByCheckNumberListResponseDataItem[];
  next_cursor: TransfersByCheckNumberListResponseNextCursor;
}

export type TransfersByCheckNumberListResponseDataItemWireTransferReturnRequestsAnyOfItemReason =
  (typeof TransfersByCheckNumberListResponseDataItemWireTransferReturnRequestsAnyOfItemReason)[keyof typeof TransfersByCheckNumberListResponseDataItemWireTransferReturnRequestsAnyOfItemReason];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const TransfersByCheckNumberListResponseDataItemWireTransferReturnRequestsAnyOfItemReason =
  {
    fraudulent: "fraudulent",
    requested_by_customer: "requested_by_customer",
  } as const;

export type TransfersByCheckNumberListResponseDataItemWireTransferReturnRequestsAnyOfItem =
  {
    created_at: string;
    id: string;
    reason: TransfersByCheckNumberListResponseDataItemWireTransferReturnRequestsAnyOfItemReason;
  };

export type TransfersByCheckNumberListResponseDataItemWireTransferReturnRequests =

    | TransfersByCheckNumberListResponseDataItemWireTransferReturnRequestsAnyOfItem[]
    | null;

export type TransfersByCheckNumberListResponseDataItemTransferType =
  (typeof TransfersByCheckNumberListResponseDataItemTransferType)[keyof typeof TransfersByCheckNumberListResponseDataItemTransferType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const TransfersByCheckNumberListResponseDataItemTransferType = {
  ach_transfer: "ach_transfer",
  account_transfer: "account_transfer",
  check_deposit: "check_deposit",
  check_transfer: "check_transfer",
  inbound_ach_transfer: "inbound_ach_transfer",
  inbound_real_time_payments_transfer: "inbound_real_time_payments_transfer",
  inbound_wire_transfer: "inbound_wire_transfer",
  real_time_payments_transfer: "real_time_payments_transfer",
  wire_transfer: "wire_transfer",
} as const;

export type TransfersByCheckNumberListResponseDataItemTransferReviewingManualDenialAnyOf =
  {
    created_at: string;
    operator_email: string;
  };

export type TransfersByCheckNumberListResponseDataItemTransferReviewingManualDenial =
  TransfersByCheckNumberListResponseDataItemTransferReviewingManualDenialAnyOf | null;

export type TransfersByCheckNumberListResponseDataItemTransferReviewingManualApprovalAnyOf =
  {
    created_at: string;
    operator_email: string;
  };

export type TransfersByCheckNumberListResponseDataItemTransferReviewingManualApproval =
  TransfersByCheckNumberListResponseDataItemTransferReviewingManualApprovalAnyOf | null;

export type TransfersByCheckNumberListResponseDataItem = {
  account_id: string;
  amount: number;
  bank: TransfersByCheckNumberListResponseDataItemBank;
  check_deposit_rejection: TransfersByCheckNumberListResponseDataItemCheckDepositRejection;
  completed_transactions: TransfersByCheckNumberListResponseDataItemCompletedTransactionsItem[];
  created_at: string;
  created_by: TransfersByCheckNumberListResponseDataItemCreatedBy;
  group_id: string;
  id: string;
  inbound_funds_hold: TransfersByCheckNumberListResponseDataItemInboundFundsHold;
  status: string;
  transfer_approval: TransfersByCheckNumberListResponseDataItemTransferApproval;
  transfer_cancellation: TransfersByCheckNumberListResponseDataItemTransferCancellation;
  transfer_instruction: unknown;
  transfer_review: TransfersByCheckNumberListResponseDataItemTransferReview;
  transfer_reviewing_manual_approval: TransfersByCheckNumberListResponseDataItemTransferReviewingManualApproval;
  transfer_reviewing_manual_denial: TransfersByCheckNumberListResponseDataItemTransferReviewingManualDenial;
  transfer_type: TransfersByCheckNumberListResponseDataItemTransferType;
  wire_transfer_return_requests: TransfersByCheckNumberListResponseDataItemWireTransferReturnRequests;
};

export type TransfersByCheckNumberListResponseDataItemTransferReviewAnyOfReviewer =
  (typeof TransfersByCheckNumberListResponseDataItemTransferReviewAnyOfReviewer)[keyof typeof TransfersByCheckNumberListResponseDataItemTransferReviewAnyOfReviewer];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const TransfersByCheckNumberListResponseDataItemTransferReviewAnyOfReviewer =
  {
    increase: "increase",
    bank: "bank",
  } as const;

export type TransfersByCheckNumberListResponseDataItemTransferReviewAnyOfReason =
  (typeof TransfersByCheckNumberListResponseDataItemTransferReviewAnyOfReason)[keyof typeof TransfersByCheckNumberListResponseDataItemTransferReviewAnyOfReason];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const TransfersByCheckNumberListResponseDataItemTransferReviewAnyOfReason =
  {
    transfer_amount_absolute_value: "transfer_amount_absolute_value",
    combined_rolling_24_hour_transfer_volume:
      "combined_rolling_24_hour_transfer_volume",
    rolling_24_hour_volume_for_transfer_type:
      "rolling_24_hour_volume_for_transfer_type",
    credit_exposure: "credit_exposure",
  } as const;

export type TransfersByCheckNumberListResponseDataItemTransferReviewAnyOf = {
  created_at: string;
  reason: TransfersByCheckNumberListResponseDataItemTransferReviewAnyOfReason;
  reviewer: TransfersByCheckNumberListResponseDataItemTransferReviewAnyOfReviewer;
};

export type TransfersByCheckNumberListResponseDataItemTransferReview =
  TransfersByCheckNumberListResponseDataItemTransferReviewAnyOf | null;

export type TransfersByCheckNumberListResponseDataItemTransferCancellation =
  TransfersByCheckNumberListResponseDataItemTransferCancellationAnyOf | null;

export type TransfersByCheckNumberListResponseDataItemTransferCancellationAnyOfCanceledById =
  string | null;

export type TransfersByCheckNumberListResponseDataItemTransferCancellationAnyOfCanceledByEmail =
  string | null;

export type TransfersByCheckNumberListResponseDataItemTransferCancellationAnyOf =
  {
    canceled_by_email: TransfersByCheckNumberListResponseDataItemTransferCancellationAnyOfCanceledByEmail;
    canceled_by_id: TransfersByCheckNumberListResponseDataItemTransferCancellationAnyOfCanceledById;
    created_at: string;
  };

export type TransfersByCheckNumberListResponseDataItemTransferApprovalAnyOfApprovedByEmail =
  string | null;

export type TransfersByCheckNumberListResponseDataItemTransferApprovalAnyOf = {
  approved_by_email: TransfersByCheckNumberListResponseDataItemTransferApprovalAnyOfApprovedByEmail;
  approved_by_id: string;
  created_at: string;
};

export type TransfersByCheckNumberListResponseDataItemTransferApproval =
  TransfersByCheckNumberListResponseDataItemTransferApprovalAnyOf | null;

export type TransfersByCheckNumberListResponseDataItemInboundFundsHold =
  TransfersByCheckNumberListResponseDataItemInboundFundsHoldAnyOf | null;

export type TransfersByCheckNumberListResponseDataItemInboundFundsHoldAnyOfStatus =
  (typeof TransfersByCheckNumberListResponseDataItemInboundFundsHoldAnyOfStatus)[keyof typeof TransfersByCheckNumberListResponseDataItemInboundFundsHoldAnyOfStatus];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const TransfersByCheckNumberListResponseDataItemInboundFundsHoldAnyOfStatus =
  {
    held: "held",
    complete: "complete",
  } as const;

export type TransfersByCheckNumberListResponseDataItemInboundFundsHoldAnyOfReleasedAt =
  string | null;

export type TransfersByCheckNumberListResponseDataItemInboundFundsHoldAnyOfPendingTransaction =
  TransfersByCheckNumberListResponseDataItemInboundFundsHoldAnyOfPendingTransactionAnyOf | null;

export type TransfersByCheckNumberListResponseDataItemInboundFundsHoldAnyOf = {
  account_id: string;
  amount: number;
  automatically_releases_at: string;
  group_id: string;
  id: string;
  pending_transaction: TransfersByCheckNumberListResponseDataItemInboundFundsHoldAnyOfPendingTransaction;
  released_at: TransfersByCheckNumberListResponseDataItemInboundFundsHoldAnyOfReleasedAt;
  source_id: string;
  source_type: string;
  status: TransfersByCheckNumberListResponseDataItemInboundFundsHoldAnyOfStatus;
};

export type TransfersByCheckNumberListResponseDataItemInboundFundsHoldAnyOfPendingTransactionAnyOfStatus =
  (typeof TransfersByCheckNumberListResponseDataItemInboundFundsHoldAnyOfPendingTransactionAnyOfStatus)[keyof typeof TransfersByCheckNumberListResponseDataItemInboundFundsHoldAnyOfPendingTransactionAnyOfStatus];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const TransfersByCheckNumberListResponseDataItemInboundFundsHoldAnyOfPendingTransactionAnyOfStatus =
  {
    pending: "pending",
    complete: "complete",
  } as const;

export type TransfersByCheckNumberListResponseDataItemInboundFundsHoldAnyOfPendingTransactionAnyOfSourceDocumentation =
  string | null;

export type TransfersByCheckNumberListResponseDataItemInboundFundsHoldAnyOfPendingTransactionAnyOfRoute =
  TransfersByCheckNumberListResponseDataItemInboundFundsHoldAnyOfPendingTransactionAnyOfRouteAnyOf | null;

export type TransfersByCheckNumberListResponseDataItemInboundFundsHoldAnyOfPendingTransactionAnyOf =
  {
    account_id: string;
    amount: number;
    completed_at: TransfersByCheckNumberListResponseDataItemInboundFundsHoldAnyOfPendingTransactionAnyOfCompletedAt;
    created_at: string;
    currency: TransfersByCheckNumberListResponseDataItemInboundFundsHoldAnyOfPendingTransactionAnyOfCurrency;
    date: string;
    description: string;
    group_id: string;
    id: string;
    route: TransfersByCheckNumberListResponseDataItemInboundFundsHoldAnyOfPendingTransactionAnyOfRoute;
    source: unknown;
    source_documentation: TransfersByCheckNumberListResponseDataItemInboundFundsHoldAnyOfPendingTransactionAnyOfSourceDocumentation;
    source_id: string;
    source_type: string;
    status: TransfersByCheckNumberListResponseDataItemInboundFundsHoldAnyOfPendingTransactionAnyOfStatus;
    type: string;
  };

export type TransfersByCheckNumberListResponseDataItemInboundFundsHoldAnyOfPendingTransactionAnyOfRouteAnyOfType =
  (typeof TransfersByCheckNumberListResponseDataItemInboundFundsHoldAnyOfPendingTransactionAnyOfRouteAnyOfType)[keyof typeof TransfersByCheckNumberListResponseDataItemInboundFundsHoldAnyOfPendingTransactionAnyOfRouteAnyOfType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const TransfersByCheckNumberListResponseDataItemInboundFundsHoldAnyOfPendingTransactionAnyOfRouteAnyOfType =
  {
    account_number: "account_number",
    card: "card",
    lockbox: "lockbox",
  } as const;

export type TransfersByCheckNumberListResponseDataItemInboundFundsHoldAnyOfPendingTransactionAnyOfRouteAnyOfDescription =
  string | null;

export type TransfersByCheckNumberListResponseDataItemInboundFundsHoldAnyOfPendingTransactionAnyOfRouteAnyOf =
  {
    description: TransfersByCheckNumberListResponseDataItemInboundFundsHoldAnyOfPendingTransactionAnyOfRouteAnyOfDescription;
    id: string;
    type: TransfersByCheckNumberListResponseDataItemInboundFundsHoldAnyOfPendingTransactionAnyOfRouteAnyOfType;
  };

export type TransfersByCheckNumberListResponseDataItemInboundFundsHoldAnyOfPendingTransactionAnyOfCurrency =
  (typeof TransfersByCheckNumberListResponseDataItemInboundFundsHoldAnyOfPendingTransactionAnyOfCurrency)[keyof typeof TransfersByCheckNumberListResponseDataItemInboundFundsHoldAnyOfPendingTransactionAnyOfCurrency];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const TransfersByCheckNumberListResponseDataItemInboundFundsHoldAnyOfPendingTransactionAnyOfCurrency =
  {
    CAD: "CAD",
    CHF: "CHF",
    EUR: "EUR",
    GBP: "GBP",
    JPY: "JPY",
    USD: "USD",
  } as const;

export type TransfersByCheckNumberListResponseDataItemInboundFundsHoldAnyOfPendingTransactionAnyOfCompletedAt =
  string | null;

export type TransfersByCheckNumberListResponseDataItemCreatedByAnyOfEmail =
  | string
  | null;

export type TransfersByCheckNumberListResponseDataItemCreatedByAnyOf = {
  email: TransfersByCheckNumberListResponseDataItemCreatedByAnyOfEmail;
  id: string;
};

export type TransfersByCheckNumberListResponseDataItemCreatedBy =
  TransfersByCheckNumberListResponseDataItemCreatedByAnyOf | null;

export type TransfersByCheckNumberListResponseDataItemCompletedTransactionsItemSourceType =
  (typeof TransfersByCheckNumberListResponseDataItemCompletedTransactionsItemSourceType)[keyof typeof TransfersByCheckNumberListResponseDataItemCompletedTransactionsItemSourceType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const TransfersByCheckNumberListResponseDataItemCompletedTransactionsItemSourceType =
  {
    "API::ACHCheckConversion": "API::ACHCheckConversion",
    "API::ACHCheckConversionReturn": "API::ACHCheckConversionReturn",
    "API::ACHTransferIntention": "API::ACHTransferIntention",
    "API::ACHTransferRejection": "API::ACHTransferRejection",
    "API::ACHTransferReturn": "API::ACHTransferReturn",
    "API::AccountTransferIntention": "API::AccountTransferIntention",
    "API::CardDisputeAcceptance": "API::CardDisputeAcceptance",
    "API::CardDisputeLoss": "API::CardDisputeLoss",
    "API::CardRefund": "API::CardRefund",
    "API::CardRevenuePayment": "API::CardRevenuePayment",
    "API::CardSettlement": "API::CardSettlement",
    "API::CashbackPayment": "API::CashbackPayment",
    "API::CheckDepositAcceptance": "API::CheckDepositAcceptance",
    "API::CheckDepositReturn": "API::CheckDepositReturn",
    "API::CheckTransferIntention": "API::CheckTransferIntention",
    "API::CheckTransferRejection": "API::CheckTransferRejection",
    "API::CheckTransferReturn": "API::CheckTransferReturn",
    "API::CheckTransferStopPaymentRequest":
      "API::CheckTransferStopPaymentRequest",
    "API::DeprecatedTransactionSource": "API::DeprecatedTransactionSource",
    "API::DisputeResolution": "API::DisputeResolution",
    "API::EmpyrealCashDeposit": "API::EmpyrealCashDeposit",
    "API::FeePayment": "API::FeePayment",
    "API::InboundACHTransferIntention": "API::InboundACHTransferIntention",
    "API::InboundACHTransferReturnIntention":
      "API::InboundACHTransferReturnIntention",
    "API::InboundCheck": "API::InboundCheck",
    "API::InboundCheckDepositReturnIntention":
      "API::InboundCheckDepositReturnIntention",
    "API::InboundCheckAdjustment": "API::InboundCheckAdjustment",
    "API::InboundRealTimePaymentsTransferConfirmation":
      "API::InboundRealTimePaymentsTransferConfirmation",
    "API::InboundWireReversal": "API::InboundWireReversal",
    "API::InboundWireTransferIntention": "API::InboundWireTransferIntention",
    "API::InboundWireTransferReversalIntention":
      "API::InboundWireTransferReversalIntention",
    "API::InterestPayment": "API::InterestPayment",
    "API::InternalGeneralLedgerTransaction":
      "API::InternalGeneralLedgerTransaction",
    "API::InternalSource": "API::InternalSource",
    "API::PrivacyCardRefund": "API::PrivacyCardRefund",
    "API::PrivacyCardSettlement": "API::PrivacyCardSettlement",
    "API::RealTimePaymentsTransferAcknowledgement":
      "API::RealTimePaymentsTransferAcknowledgement",
    "API::SampleFunds": "API::SampleFunds",
    "API::WireDrawdownPaymentIntention": "API::WireDrawdownPaymentIntention",
    "API::WireTransferIntention": "API::WireTransferIntention",
    "API::WireTransferRejection": "API::WireTransferRejection",
    "API::ACHDecline": "API::ACHDecline",
    "API::CardDecline": "API::CardDecline",
    "API::CheckDecline": "API::CheckDecline",
    "API::CheckDepositRejection": "API::CheckDepositRejection",
    "API::InboundRealTimePaymentsTransferDecline":
      "API::InboundRealTimePaymentsTransferDecline",
    "API::PrivacyCardDecline": "API::PrivacyCardDecline",
    "API::WireDecline": "API::WireDecline",
  } as const;

export type TransfersByCheckNumberListResponseDataItemCompletedTransactionsItemRoute =
  TransfersByCheckNumberListResponseDataItemCompletedTransactionsItemRouteAnyOf | null;

export type TransfersByCheckNumberListResponseDataItemCompletedTransactionsItem =
  {
    account_id: string;
    amount: number;
    currency: TransfersByCheckNumberListResponseDataItemCompletedTransactionsItemCurrency;
    date: string;
    description: string;
    group_id: string;
    group_name: TransfersByCheckNumberListResponseDataItemCompletedTransactionsItemGroupName;
    id: string;
    is_declined_transaction: boolean;
    route: TransfersByCheckNumberListResponseDataItemCompletedTransactionsItemRoute;
    source_id: string;
    source_type: TransfersByCheckNumberListResponseDataItemCompletedTransactionsItemSourceType;
    type: string;
  };

export type TransfersByCheckNumberListResponseDataItemCompletedTransactionsItemRouteAnyOfType =
  (typeof TransfersByCheckNumberListResponseDataItemCompletedTransactionsItemRouteAnyOfType)[keyof typeof TransfersByCheckNumberListResponseDataItemCompletedTransactionsItemRouteAnyOfType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const TransfersByCheckNumberListResponseDataItemCompletedTransactionsItemRouteAnyOfType =
  {
    account_number: "account_number",
    card: "card",
    lockbox: "lockbox",
  } as const;

export type TransfersByCheckNumberListResponseDataItemCompletedTransactionsItemRouteAnyOfDescription =
  string | null;

export type TransfersByCheckNumberListResponseDataItemCompletedTransactionsItemRouteAnyOf =
  {
    description: TransfersByCheckNumberListResponseDataItemCompletedTransactionsItemRouteAnyOfDescription;
    id: string;
    type: TransfersByCheckNumberListResponseDataItemCompletedTransactionsItemRouteAnyOfType;
  };

export type TransfersByCheckNumberListResponseDataItemCompletedTransactionsItemGroupName =
  string | null;

export type TransfersByCheckNumberListResponseDataItemCompletedTransactionsItemCurrency =
  (typeof TransfersByCheckNumberListResponseDataItemCompletedTransactionsItemCurrency)[keyof typeof TransfersByCheckNumberListResponseDataItemCompletedTransactionsItemCurrency];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const TransfersByCheckNumberListResponseDataItemCompletedTransactionsItemCurrency =
  {
    CAD: "CAD",
    CHF: "CHF",
    EUR: "EUR",
    GBP: "GBP",
    JPY: "JPY",
    USD: "USD",
  } as const;

export type TransfersByCheckNumberListResponseDataItemCheckDepositRejectionAnyOfReason =
  (typeof TransfersByCheckNumberListResponseDataItemCheckDepositRejectionAnyOfReason)[keyof typeof TransfersByCheckNumberListResponseDataItemCheckDepositRejectionAnyOfReason];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const TransfersByCheckNumberListResponseDataItemCheckDepositRejectionAnyOfReason =
  {
    incomplete_image: "incomplete_image",
    duplicate: "duplicate",
    poor_image_quality: "poor_image_quality",
    incorrect_amount: "incorrect_amount",
    incorrect_recipient: "incorrect_recipient",
    not_eligible_for_mobile_deposit: "not_eligible_for_mobile_deposit",
    missing_required_data_elements: "missing_required_data_elements",
    suspected_fraud: "suspected_fraud",
    deposit_window_expired: "deposit_window_expired",
    requested_by_user: "requested_by_user",
    unknown: "unknown",
  } as const;

export type TransfersByCheckNumberListResponseDataItemCheckDepositRejectionAnyOf =
  {
    created_at: string;
    reason: TransfersByCheckNumberListResponseDataItemCheckDepositRejectionAnyOfReason;
  };

export type TransfersByCheckNumberListResponseDataItemCheckDepositRejection =
  TransfersByCheckNumberListResponseDataItemCheckDepositRejectionAnyOf | null;

export type TransfersByCheckNumberListResponseDataItemBank =
  (typeof TransfersByCheckNumberListResponseDataItemBank)[keyof typeof TransfersByCheckNumberListResponseDataItemBank];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const TransfersByCheckNumberListResponseDataItemBank = {
  blue_ridge_bank: "blue_ridge_bank",
  core_bank: "core_bank",
  first_internet_bank: "first_internet_bank",
  global_innovations_bank: "global_innovations_bank",
  grasshopper_bank: "grasshopper_bank",
} as const;

export type TransferReviewingReleaseDirectivePostResponseTransferType =
  (typeof TransferReviewingReleaseDirectivePostResponseTransferType)[keyof typeof TransferReviewingReleaseDirectivePostResponseTransferType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const TransferReviewingReleaseDirectivePostResponseTransferType = {
  check_transfer: "check_transfer",
  real_time_payments_transfer: "real_time_payments_transfer",
  ach_transfer: "ach_transfer",
  wire_transfer: "wire_transfer",
  check_deposit: "check_deposit",
  inbound_ach_entry: "inbound_ach_entry",
  inbound_ctx_entry: "inbound_ctx_entry",
  inbound_international_ach_entry: "inbound_international_ach_entry",
  inbound_wire: "inbound_wire",
} as const;

export type TransferReviewingReleaseDirectivePostResponseReviewer =
  (typeof TransferReviewingReleaseDirectivePostResponseReviewer)[keyof typeof TransferReviewingReleaseDirectivePostResponseReviewer];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const TransferReviewingReleaseDirectivePostResponseReviewer = {
  increase: "increase",
  bank: "bank",
} as const;

export type TransferReviewingReleaseDirectivePostResponseReason =
  (typeof TransferReviewingReleaseDirectivePostResponseReason)[keyof typeof TransferReviewingReleaseDirectivePostResponseReason];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const TransferReviewingReleaseDirectivePostResponseReason = {
  transfer_amount_absolute_value: "transfer_amount_absolute_value",
  combined_rolling_24_hour_transfer_volume:
    "combined_rolling_24_hour_transfer_volume",
  rolling_24_hour_volume_for_transfer_type:
    "rolling_24_hour_volume_for_transfer_type",
  credit_exposure: "credit_exposure",
} as const;

export type TransferReviewingReleaseDirectivePostResponseGroupName =
  | string
  | null;

export type TransferReviewingReleaseDirectivePostResponseCurrency =
  (typeof TransferReviewingReleaseDirectivePostResponseCurrency)[keyof typeof TransferReviewingReleaseDirectivePostResponseCurrency];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const TransferReviewingReleaseDirectivePostResponseCurrency = {
  CAD: "CAD",
  CHF: "CHF",
  EUR: "EUR",
  GBP: "GBP",
  JPY: "JPY",
  USD: "USD",
} as const;

export type TransferReviewingReleaseDirectivePostResponseBank =
  (typeof TransferReviewingReleaseDirectivePostResponseBank)[keyof typeof TransferReviewingReleaseDirectivePostResponseBank];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const TransferReviewingReleaseDirectivePostResponseBank = {
  blue_ridge_bank: "blue_ridge_bank",
  core_bank: "core_bank",
  first_internet_bank: "first_internet_bank",
  global_innovations_bank: "global_innovations_bank",
  grasshopper_bank: "grasshopper_bank",
} as const;

export interface TransferReviewingReleaseDirectivePostResponse {
  amount: number;
  bank: TransferReviewingReleaseDirectivePostResponseBank;
  created_at: string;
  currency: TransferReviewingReleaseDirectivePostResponseCurrency;
  group_friendly_nickname: string;
  group_id: string;
  group_name: TransferReviewingReleaseDirectivePostResponseGroupName;
  id: string;
  program_id: string;
  program_name: string;
  reason: TransferReviewingReleaseDirectivePostResponseReason;
  reviewer: TransferReviewingReleaseDirectivePostResponseReviewer;
  transfer_id: string;
  transfer_type: TransferReviewingReleaseDirectivePostResponseTransferType;
}

export interface TransferReviewingReleaseDirectivePostParameters {
  note?: string;
}

export type TransferReviewingRejectDirectivePostResponseTransferType =
  (typeof TransferReviewingRejectDirectivePostResponseTransferType)[keyof typeof TransferReviewingRejectDirectivePostResponseTransferType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const TransferReviewingRejectDirectivePostResponseTransferType = {
  check_transfer: "check_transfer",
  real_time_payments_transfer: "real_time_payments_transfer",
  ach_transfer: "ach_transfer",
  wire_transfer: "wire_transfer",
  check_deposit: "check_deposit",
  inbound_ach_entry: "inbound_ach_entry",
  inbound_ctx_entry: "inbound_ctx_entry",
  inbound_international_ach_entry: "inbound_international_ach_entry",
  inbound_wire: "inbound_wire",
} as const;

export type TransferReviewingRejectDirectivePostResponseReviewer =
  (typeof TransferReviewingRejectDirectivePostResponseReviewer)[keyof typeof TransferReviewingRejectDirectivePostResponseReviewer];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const TransferReviewingRejectDirectivePostResponseReviewer = {
  increase: "increase",
  bank: "bank",
} as const;

export type TransferReviewingRejectDirectivePostResponseReason =
  (typeof TransferReviewingRejectDirectivePostResponseReason)[keyof typeof TransferReviewingRejectDirectivePostResponseReason];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const TransferReviewingRejectDirectivePostResponseReason = {
  transfer_amount_absolute_value: "transfer_amount_absolute_value",
  combined_rolling_24_hour_transfer_volume:
    "combined_rolling_24_hour_transfer_volume",
  rolling_24_hour_volume_for_transfer_type:
    "rolling_24_hour_volume_for_transfer_type",
  credit_exposure: "credit_exposure",
} as const;

export type TransferReviewingRejectDirectivePostResponseGroupName =
  | string
  | null;

export type TransferReviewingRejectDirectivePostResponseCurrency =
  (typeof TransferReviewingRejectDirectivePostResponseCurrency)[keyof typeof TransferReviewingRejectDirectivePostResponseCurrency];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const TransferReviewingRejectDirectivePostResponseCurrency = {
  CAD: "CAD",
  CHF: "CHF",
  EUR: "EUR",
  GBP: "GBP",
  JPY: "JPY",
  USD: "USD",
} as const;

export type TransferReviewingRejectDirectivePostResponseBank =
  (typeof TransferReviewingRejectDirectivePostResponseBank)[keyof typeof TransferReviewingRejectDirectivePostResponseBank];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const TransferReviewingRejectDirectivePostResponseBank = {
  blue_ridge_bank: "blue_ridge_bank",
  core_bank: "core_bank",
  first_internet_bank: "first_internet_bank",
  global_innovations_bank: "global_innovations_bank",
  grasshopper_bank: "grasshopper_bank",
} as const;

export interface TransferReviewingRejectDirectivePostResponse {
  amount: number;
  bank: TransferReviewingRejectDirectivePostResponseBank;
  created_at: string;
  currency: TransferReviewingRejectDirectivePostResponseCurrency;
  group_friendly_nickname: string;
  group_id: string;
  group_name: TransferReviewingRejectDirectivePostResponseGroupName;
  id: string;
  program_id: string;
  program_name: string;
  reason: TransferReviewingRejectDirectivePostResponseReason;
  reviewer: TransferReviewingRejectDirectivePostResponseReviewer;
  transfer_id: string;
  transfer_type: TransferReviewingRejectDirectivePostResponseTransferType;
}

export interface TransferReviewingRejectDirectivePostParameters {
  note?: string;
}

export type TransferReviewListResponseNextCursor = string | null;

export interface TransferReviewListResponse {
  data: TransferReviewListResponseDataItem[];
  next_cursor: TransferReviewListResponseNextCursor;
}

export type TransferReviewListResponseDataItemTransferType =
  (typeof TransferReviewListResponseDataItemTransferType)[keyof typeof TransferReviewListResponseDataItemTransferType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const TransferReviewListResponseDataItemTransferType = {
  check_transfer: "check_transfer",
  real_time_payments_transfer: "real_time_payments_transfer",
  ach_transfer: "ach_transfer",
  wire_transfer: "wire_transfer",
  check_deposit: "check_deposit",
  inbound_ach_entry: "inbound_ach_entry",
  inbound_ctx_entry: "inbound_ctx_entry",
  inbound_international_ach_entry: "inbound_international_ach_entry",
  inbound_wire: "inbound_wire",
} as const;

export type TransferReviewListResponseDataItemReviewer =
  (typeof TransferReviewListResponseDataItemReviewer)[keyof typeof TransferReviewListResponseDataItemReviewer];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const TransferReviewListResponseDataItemReviewer = {
  increase: "increase",
  bank: "bank",
} as const;

export type TransferReviewListResponseDataItemReason =
  (typeof TransferReviewListResponseDataItemReason)[keyof typeof TransferReviewListResponseDataItemReason];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const TransferReviewListResponseDataItemReason = {
  transfer_amount_absolute_value: "transfer_amount_absolute_value",
  combined_rolling_24_hour_transfer_volume:
    "combined_rolling_24_hour_transfer_volume",
  rolling_24_hour_volume_for_transfer_type:
    "rolling_24_hour_volume_for_transfer_type",
  credit_exposure: "credit_exposure",
} as const;

export type TransferReviewListResponseDataItemGroupName = string | null;

export type TransferReviewListResponseDataItemCurrency =
  (typeof TransferReviewListResponseDataItemCurrency)[keyof typeof TransferReviewListResponseDataItemCurrency];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const TransferReviewListResponseDataItemCurrency = {
  CAD: "CAD",
  CHF: "CHF",
  EUR: "EUR",
  GBP: "GBP",
  JPY: "JPY",
  USD: "USD",
} as const;

export type TransferReviewListResponseDataItemBank =
  (typeof TransferReviewListResponseDataItemBank)[keyof typeof TransferReviewListResponseDataItemBank];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const TransferReviewListResponseDataItemBank = {
  blue_ridge_bank: "blue_ridge_bank",
  core_bank: "core_bank",
  first_internet_bank: "first_internet_bank",
  global_innovations_bank: "global_innovations_bank",
  grasshopper_bank: "grasshopper_bank",
} as const;

export type TransferReviewListResponseDataItem = {
  amount: number;
  bank: TransferReviewListResponseDataItemBank;
  created_at: string;
  currency: TransferReviewListResponseDataItemCurrency;
  group_friendly_nickname: string;
  group_id: string;
  group_name: TransferReviewListResponseDataItemGroupName;
  id: string;
  program_id: string;
  program_name: string;
  reason: TransferReviewListResponseDataItemReason;
  reviewer: TransferReviewListResponseDataItemReviewer;
  transfer_id: string;
  transfer_type: TransferReviewListResponseDataItemTransferType;
};

export type TransferGetResponseWireTransferReturnRequestsAnyOfItemReason =
  (typeof TransferGetResponseWireTransferReturnRequestsAnyOfItemReason)[keyof typeof TransferGetResponseWireTransferReturnRequestsAnyOfItemReason];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const TransferGetResponseWireTransferReturnRequestsAnyOfItemReason = {
  fraudulent: "fraudulent",
  requested_by_customer: "requested_by_customer",
} as const;

export type TransferGetResponseWireTransferReturnRequestsAnyOfItem = {
  created_at: string;
  id: string;
  reason: TransferGetResponseWireTransferReturnRequestsAnyOfItemReason;
};

export type TransferGetResponseWireTransferReturnRequests =
  | TransferGetResponseWireTransferReturnRequestsAnyOfItem[]
  | null;

export type TransferGetResponseTransferType =
  (typeof TransferGetResponseTransferType)[keyof typeof TransferGetResponseTransferType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const TransferGetResponseTransferType = {
  ach_transfer: "ach_transfer",
  account_transfer: "account_transfer",
  check_deposit: "check_deposit",
  check_transfer: "check_transfer",
  inbound_ach_transfer: "inbound_ach_transfer",
  inbound_real_time_payments_transfer: "inbound_real_time_payments_transfer",
  inbound_wire_transfer: "inbound_wire_transfer",
  real_time_payments_transfer: "real_time_payments_transfer",
  wire_transfer: "wire_transfer",
} as const;

export type TransferGetResponseTransferReviewingManualDenialAnyOf = {
  created_at: string;
  operator_email: string;
};

export type TransferGetResponseTransferReviewingManualDenial =
  TransferGetResponseTransferReviewingManualDenialAnyOf | null;

export type TransferGetResponseTransferReviewingManualApprovalAnyOf = {
  created_at: string;
  operator_email: string;
};

export type TransferGetResponseTransferReviewingManualApproval =
  TransferGetResponseTransferReviewingManualApprovalAnyOf | null;

export type TransferGetResponseTransferReview =
  TransferGetResponseTransferReviewAnyOf | null;

export type TransferGetResponseTransferReviewAnyOfReviewer =
  (typeof TransferGetResponseTransferReviewAnyOfReviewer)[keyof typeof TransferGetResponseTransferReviewAnyOfReviewer];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const TransferGetResponseTransferReviewAnyOfReviewer = {
  increase: "increase",
  bank: "bank",
} as const;

export type TransferGetResponseTransferReviewAnyOfReason =
  (typeof TransferGetResponseTransferReviewAnyOfReason)[keyof typeof TransferGetResponseTransferReviewAnyOfReason];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const TransferGetResponseTransferReviewAnyOfReason = {
  transfer_amount_absolute_value: "transfer_amount_absolute_value",
  combined_rolling_24_hour_transfer_volume:
    "combined_rolling_24_hour_transfer_volume",
  rolling_24_hour_volume_for_transfer_type:
    "rolling_24_hour_volume_for_transfer_type",
  credit_exposure: "credit_exposure",
} as const;

export type TransferGetResponseTransferReviewAnyOf = {
  created_at: string;
  reason: TransferGetResponseTransferReviewAnyOfReason;
  reviewer: TransferGetResponseTransferReviewAnyOfReviewer;
};

export type TransferGetResponseTransferCancellation =
  TransferGetResponseTransferCancellationAnyOf | null;

export interface TransferGetResponse {
  account_id: string;
  amount: number;
  bank: TransferGetResponseBank;
  check_deposit_rejection: TransferGetResponseCheckDepositRejection;
  completed_transactions: TransferGetResponseCompletedTransactionsItem[];
  created_at: string;
  created_by: TransferGetResponseCreatedBy;
  group_id: string;
  id: string;
  inbound_funds_hold: TransferGetResponseInboundFundsHold;
  status: string;
  transfer_approval: TransferGetResponseTransferApproval;
  transfer_cancellation: TransferGetResponseTransferCancellation;
  transfer_instruction: unknown;
  transfer_review: TransferGetResponseTransferReview;
  transfer_reviewing_manual_approval: TransferGetResponseTransferReviewingManualApproval;
  transfer_reviewing_manual_denial: TransferGetResponseTransferReviewingManualDenial;
  transfer_type: TransferGetResponseTransferType;
  wire_transfer_return_requests: TransferGetResponseWireTransferReturnRequests;
}

export type TransferGetResponseTransferCancellationAnyOfCanceledById =
  | string
  | null;

export type TransferGetResponseTransferCancellationAnyOfCanceledByEmail =
  | string
  | null;

export type TransferGetResponseTransferCancellationAnyOf = {
  canceled_by_email: TransferGetResponseTransferCancellationAnyOfCanceledByEmail;
  canceled_by_id: TransferGetResponseTransferCancellationAnyOfCanceledById;
  created_at: string;
};

export type TransferGetResponseTransferApprovalAnyOfApprovedByEmail =
  | string
  | null;

export type TransferGetResponseTransferApprovalAnyOf = {
  approved_by_email: TransferGetResponseTransferApprovalAnyOfApprovedByEmail;
  approved_by_id: string;
  created_at: string;
};

export type TransferGetResponseTransferApproval =
  TransferGetResponseTransferApprovalAnyOf | null;

export type TransferGetResponseInboundFundsHold =
  TransferGetResponseInboundFundsHoldAnyOf | null;

export type TransferGetResponseInboundFundsHoldAnyOfStatus =
  (typeof TransferGetResponseInboundFundsHoldAnyOfStatus)[keyof typeof TransferGetResponseInboundFundsHoldAnyOfStatus];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const TransferGetResponseInboundFundsHoldAnyOfStatus = {
  held: "held",
  complete: "complete",
} as const;

export type TransferGetResponseInboundFundsHoldAnyOfReleasedAt = string | null;

export type TransferGetResponseInboundFundsHoldAnyOfPendingTransactionAnyOfStatus =
  (typeof TransferGetResponseInboundFundsHoldAnyOfPendingTransactionAnyOfStatus)[keyof typeof TransferGetResponseInboundFundsHoldAnyOfPendingTransactionAnyOfStatus];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const TransferGetResponseInboundFundsHoldAnyOfPendingTransactionAnyOfStatus =
  {
    pending: "pending",
    complete: "complete",
  } as const;

export type TransferGetResponseInboundFundsHoldAnyOfPendingTransactionAnyOfSourceDocumentation =
  string | null;

export type TransferGetResponseInboundFundsHoldAnyOfPendingTransactionAnyOf = {
  account_id: string;
  amount: number;
  completed_at: TransferGetResponseInboundFundsHoldAnyOfPendingTransactionAnyOfCompletedAt;
  created_at: string;
  currency: TransferGetResponseInboundFundsHoldAnyOfPendingTransactionAnyOfCurrency;
  date: string;
  description: string;
  group_id: string;
  id: string;
  route: TransferGetResponseInboundFundsHoldAnyOfPendingTransactionAnyOfRoute;
  source: unknown;
  source_documentation: TransferGetResponseInboundFundsHoldAnyOfPendingTransactionAnyOfSourceDocumentation;
  source_id: string;
  source_type: string;
  status: TransferGetResponseInboundFundsHoldAnyOfPendingTransactionAnyOfStatus;
  type: string;
};

export type TransferGetResponseInboundFundsHoldAnyOfPendingTransaction =
  TransferGetResponseInboundFundsHoldAnyOfPendingTransactionAnyOf | null;

export type TransferGetResponseInboundFundsHoldAnyOf = {
  account_id: string;
  amount: number;
  automatically_releases_at: string;
  group_id: string;
  id: string;
  pending_transaction: TransferGetResponseInboundFundsHoldAnyOfPendingTransaction;
  released_at: TransferGetResponseInboundFundsHoldAnyOfReleasedAt;
  source_id: string;
  source_type: string;
  status: TransferGetResponseInboundFundsHoldAnyOfStatus;
};

export type TransferGetResponseInboundFundsHoldAnyOfPendingTransactionAnyOfRouteAnyOfType =
  (typeof TransferGetResponseInboundFundsHoldAnyOfPendingTransactionAnyOfRouteAnyOfType)[keyof typeof TransferGetResponseInboundFundsHoldAnyOfPendingTransactionAnyOfRouteAnyOfType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const TransferGetResponseInboundFundsHoldAnyOfPendingTransactionAnyOfRouteAnyOfType =
  {
    account_number: "account_number",
    card: "card",
    lockbox: "lockbox",
  } as const;

export type TransferGetResponseInboundFundsHoldAnyOfPendingTransactionAnyOfRouteAnyOfDescription =
  string | null;

export type TransferGetResponseInboundFundsHoldAnyOfPendingTransactionAnyOfRouteAnyOf =
  {
    description: TransferGetResponseInboundFundsHoldAnyOfPendingTransactionAnyOfRouteAnyOfDescription;
    id: string;
    type: TransferGetResponseInboundFundsHoldAnyOfPendingTransactionAnyOfRouteAnyOfType;
  };

export type TransferGetResponseInboundFundsHoldAnyOfPendingTransactionAnyOfRoute =
  TransferGetResponseInboundFundsHoldAnyOfPendingTransactionAnyOfRouteAnyOf | null;

export type TransferGetResponseInboundFundsHoldAnyOfPendingTransactionAnyOfCurrency =
  (typeof TransferGetResponseInboundFundsHoldAnyOfPendingTransactionAnyOfCurrency)[keyof typeof TransferGetResponseInboundFundsHoldAnyOfPendingTransactionAnyOfCurrency];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const TransferGetResponseInboundFundsHoldAnyOfPendingTransactionAnyOfCurrency =
  {
    CAD: "CAD",
    CHF: "CHF",
    EUR: "EUR",
    GBP: "GBP",
    JPY: "JPY",
    USD: "USD",
  } as const;

export type TransferGetResponseInboundFundsHoldAnyOfPendingTransactionAnyOfCompletedAt =
  string | null;

export type TransferGetResponseCreatedByAnyOfEmail = string | null;

export type TransferGetResponseCreatedByAnyOf = {
  email: TransferGetResponseCreatedByAnyOfEmail;
  id: string;
};

export type TransferGetResponseCreatedBy =
  TransferGetResponseCreatedByAnyOf | null;

export type TransferGetResponseCompletedTransactionsItemSourceType =
  (typeof TransferGetResponseCompletedTransactionsItemSourceType)[keyof typeof TransferGetResponseCompletedTransactionsItemSourceType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const TransferGetResponseCompletedTransactionsItemSourceType = {
  "API::ACHCheckConversion": "API::ACHCheckConversion",
  "API::ACHCheckConversionReturn": "API::ACHCheckConversionReturn",
  "API::ACHTransferIntention": "API::ACHTransferIntention",
  "API::ACHTransferRejection": "API::ACHTransferRejection",
  "API::ACHTransferReturn": "API::ACHTransferReturn",
  "API::AccountTransferIntention": "API::AccountTransferIntention",
  "API::CardDisputeAcceptance": "API::CardDisputeAcceptance",
  "API::CardDisputeLoss": "API::CardDisputeLoss",
  "API::CardRefund": "API::CardRefund",
  "API::CardRevenuePayment": "API::CardRevenuePayment",
  "API::CardSettlement": "API::CardSettlement",
  "API::CashbackPayment": "API::CashbackPayment",
  "API::CheckDepositAcceptance": "API::CheckDepositAcceptance",
  "API::CheckDepositReturn": "API::CheckDepositReturn",
  "API::CheckTransferIntention": "API::CheckTransferIntention",
  "API::CheckTransferRejection": "API::CheckTransferRejection",
  "API::CheckTransferReturn": "API::CheckTransferReturn",
  "API::CheckTransferStopPaymentRequest":
    "API::CheckTransferStopPaymentRequest",
  "API::DeprecatedTransactionSource": "API::DeprecatedTransactionSource",
  "API::DisputeResolution": "API::DisputeResolution",
  "API::EmpyrealCashDeposit": "API::EmpyrealCashDeposit",
  "API::FeePayment": "API::FeePayment",
  "API::InboundACHTransferIntention": "API::InboundACHTransferIntention",
  "API::InboundACHTransferReturnIntention":
    "API::InboundACHTransferReturnIntention",
  "API::InboundCheck": "API::InboundCheck",
  "API::InboundCheckDepositReturnIntention":
    "API::InboundCheckDepositReturnIntention",
  "API::InboundCheckAdjustment": "API::InboundCheckAdjustment",
  "API::InboundRealTimePaymentsTransferConfirmation":
    "API::InboundRealTimePaymentsTransferConfirmation",
  "API::InboundWireReversal": "API::InboundWireReversal",
  "API::InboundWireTransferIntention": "API::InboundWireTransferIntention",
  "API::InboundWireTransferReversalIntention":
    "API::InboundWireTransferReversalIntention",
  "API::InterestPayment": "API::InterestPayment",
  "API::InternalGeneralLedgerTransaction":
    "API::InternalGeneralLedgerTransaction",
  "API::InternalSource": "API::InternalSource",
  "API::PrivacyCardRefund": "API::PrivacyCardRefund",
  "API::PrivacyCardSettlement": "API::PrivacyCardSettlement",
  "API::RealTimePaymentsTransferAcknowledgement":
    "API::RealTimePaymentsTransferAcknowledgement",
  "API::SampleFunds": "API::SampleFunds",
  "API::WireDrawdownPaymentIntention": "API::WireDrawdownPaymentIntention",
  "API::WireTransferIntention": "API::WireTransferIntention",
  "API::WireTransferRejection": "API::WireTransferRejection",
  "API::ACHDecline": "API::ACHDecline",
  "API::CardDecline": "API::CardDecline",
  "API::CheckDecline": "API::CheckDecline",
  "API::CheckDepositRejection": "API::CheckDepositRejection",
  "API::InboundRealTimePaymentsTransferDecline":
    "API::InboundRealTimePaymentsTransferDecline",
  "API::PrivacyCardDecline": "API::PrivacyCardDecline",
  "API::WireDecline": "API::WireDecline",
} as const;

export type TransferGetResponseCompletedTransactionsItemRoute =
  TransferGetResponseCompletedTransactionsItemRouteAnyOf | null;

export type TransferGetResponseCompletedTransactionsItemRouteAnyOfType =
  (typeof TransferGetResponseCompletedTransactionsItemRouteAnyOfType)[keyof typeof TransferGetResponseCompletedTransactionsItemRouteAnyOfType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const TransferGetResponseCompletedTransactionsItemRouteAnyOfType = {
  account_number: "account_number",
  card: "card",
  lockbox: "lockbox",
} as const;

export type TransferGetResponseCompletedTransactionsItemRouteAnyOfDescription =
  | string
  | null;

export type TransferGetResponseCompletedTransactionsItemRouteAnyOf = {
  description: TransferGetResponseCompletedTransactionsItemRouteAnyOfDescription;
  id: string;
  type: TransferGetResponseCompletedTransactionsItemRouteAnyOfType;
};

export type TransferGetResponseCompletedTransactionsItemGroupName =
  | string
  | null;

export type TransferGetResponseCompletedTransactionsItemCurrency =
  (typeof TransferGetResponseCompletedTransactionsItemCurrency)[keyof typeof TransferGetResponseCompletedTransactionsItemCurrency];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const TransferGetResponseCompletedTransactionsItemCurrency = {
  CAD: "CAD",
  CHF: "CHF",
  EUR: "EUR",
  GBP: "GBP",
  JPY: "JPY",
  USD: "USD",
} as const;

export type TransferGetResponseCompletedTransactionsItem = {
  account_id: string;
  amount: number;
  currency: TransferGetResponseCompletedTransactionsItemCurrency;
  date: string;
  description: string;
  group_id: string;
  group_name: TransferGetResponseCompletedTransactionsItemGroupName;
  id: string;
  is_declined_transaction: boolean;
  route: TransferGetResponseCompletedTransactionsItemRoute;
  source_id: string;
  source_type: TransferGetResponseCompletedTransactionsItemSourceType;
  type: string;
};

export type TransferGetResponseCheckDepositRejectionAnyOfReason =
  (typeof TransferGetResponseCheckDepositRejectionAnyOfReason)[keyof typeof TransferGetResponseCheckDepositRejectionAnyOfReason];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const TransferGetResponseCheckDepositRejectionAnyOfReason = {
  incomplete_image: "incomplete_image",
  duplicate: "duplicate",
  poor_image_quality: "poor_image_quality",
  incorrect_amount: "incorrect_amount",
  incorrect_recipient: "incorrect_recipient",
  not_eligible_for_mobile_deposit: "not_eligible_for_mobile_deposit",
  missing_required_data_elements: "missing_required_data_elements",
  suspected_fraud: "suspected_fraud",
  deposit_window_expired: "deposit_window_expired",
  requested_by_user: "requested_by_user",
  unknown: "unknown",
} as const;

export type TransferGetResponseCheckDepositRejectionAnyOf = {
  created_at: string;
  reason: TransferGetResponseCheckDepositRejectionAnyOfReason;
};

export type TransferGetResponseCheckDepositRejection =
  TransferGetResponseCheckDepositRejectionAnyOf | null;

export type TransferGetResponseBank =
  (typeof TransferGetResponseBank)[keyof typeof TransferGetResponseBank];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const TransferGetResponseBank = {
  blue_ridge_bank: "blue_ridge_bank",
  core_bank: "core_bank",
  first_internet_bank: "first_internet_bank",
  global_innovations_bank: "global_innovations_bank",
  grasshopper_bank: "grasshopper_bank",
} as const;

export type TransactionsByAccountAndRoutingNumberListResponseNextCursor =
  | string
  | null;

export interface TransactionsByAccountAndRoutingNumberListResponse {
  data: TransactionsByAccountAndRoutingNumberListResponseDataItem[];
  next_cursor: TransactionsByAccountAndRoutingNumberListResponseNextCursor;
}

export type TransactionsByAccountAndRoutingNumberListResponseDataItemTransferId =
  string | null;

export type TransactionsByAccountAndRoutingNumberListResponseDataItemSourceType =
  (typeof TransactionsByAccountAndRoutingNumberListResponseDataItemSourceType)[keyof typeof TransactionsByAccountAndRoutingNumberListResponseDataItemSourceType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const TransactionsByAccountAndRoutingNumberListResponseDataItemSourceType =
  {
    "API::ACHCheckConversion": "API::ACHCheckConversion",
    "API::ACHCheckConversionReturn": "API::ACHCheckConversionReturn",
    "API::ACHTransferIntention": "API::ACHTransferIntention",
    "API::ACHTransferRejection": "API::ACHTransferRejection",
    "API::ACHTransferReturn": "API::ACHTransferReturn",
    "API::AccountTransferIntention": "API::AccountTransferIntention",
    "API::CardDisputeAcceptance": "API::CardDisputeAcceptance",
    "API::CardDisputeLoss": "API::CardDisputeLoss",
    "API::CardRefund": "API::CardRefund",
    "API::CardRevenuePayment": "API::CardRevenuePayment",
    "API::CardSettlement": "API::CardSettlement",
    "API::CashbackPayment": "API::CashbackPayment",
    "API::CheckDepositAcceptance": "API::CheckDepositAcceptance",
    "API::CheckDepositReturn": "API::CheckDepositReturn",
    "API::CheckTransferIntention": "API::CheckTransferIntention",
    "API::CheckTransferRejection": "API::CheckTransferRejection",
    "API::CheckTransferReturn": "API::CheckTransferReturn",
    "API::CheckTransferStopPaymentRequest":
      "API::CheckTransferStopPaymentRequest",
    "API::DeprecatedTransactionSource": "API::DeprecatedTransactionSource",
    "API::DisputeResolution": "API::DisputeResolution",
    "API::EmpyrealCashDeposit": "API::EmpyrealCashDeposit",
    "API::FeePayment": "API::FeePayment",
    "API::InboundACHTransferIntention": "API::InboundACHTransferIntention",
    "API::InboundACHTransferReturnIntention":
      "API::InboundACHTransferReturnIntention",
    "API::InboundCheck": "API::InboundCheck",
    "API::InboundCheckDepositReturnIntention":
      "API::InboundCheckDepositReturnIntention",
    "API::InboundCheckAdjustment": "API::InboundCheckAdjustment",
    "API::InboundRealTimePaymentsTransferConfirmation":
      "API::InboundRealTimePaymentsTransferConfirmation",
    "API::InboundWireReversal": "API::InboundWireReversal",
    "API::InboundWireTransferIntention": "API::InboundWireTransferIntention",
    "API::InboundWireTransferReversalIntention":
      "API::InboundWireTransferReversalIntention",
    "API::InterestPayment": "API::InterestPayment",
    "API::InternalGeneralLedgerTransaction":
      "API::InternalGeneralLedgerTransaction",
    "API::InternalSource": "API::InternalSource",
    "API::PrivacyCardRefund": "API::PrivacyCardRefund",
    "API::PrivacyCardSettlement": "API::PrivacyCardSettlement",
    "API::RealTimePaymentsTransferAcknowledgement":
      "API::RealTimePaymentsTransferAcknowledgement",
    "API::SampleFunds": "API::SampleFunds",
    "API::WireDrawdownPaymentIntention": "API::WireDrawdownPaymentIntention",
    "API::WireTransferIntention": "API::WireTransferIntention",
    "API::WireTransferRejection": "API::WireTransferRejection",
  } as const;

export type TransactionsByAccountAndRoutingNumberListResponseDataItemSourceDocumentation =
  string | null;

export type TransactionsByAccountAndRoutingNumberListResponseDataItemRouteAnyOfType =
  (typeof TransactionsByAccountAndRoutingNumberListResponseDataItemRouteAnyOfType)[keyof typeof TransactionsByAccountAndRoutingNumberListResponseDataItemRouteAnyOfType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const TransactionsByAccountAndRoutingNumberListResponseDataItemRouteAnyOfType =
  {
    account_number: "account_number",
    card: "card",
    lockbox: "lockbox",
  } as const;

export type TransactionsByAccountAndRoutingNumberListResponseDataItemRouteAnyOfDescription =
  string | null;

export type TransactionsByAccountAndRoutingNumberListResponseDataItemRouteAnyOf =
  {
    description: TransactionsByAccountAndRoutingNumberListResponseDataItemRouteAnyOfDescription;
    id: string;
    type: TransactionsByAccountAndRoutingNumberListResponseDataItemRouteAnyOfType;
  };

export type TransactionsByAccountAndRoutingNumberListResponseDataItemRoute =
  TransactionsByAccountAndRoutingNumberListResponseDataItemRouteAnyOf | null;

export type TransactionsByAccountAndRoutingNumberListResponseDataItemGroupName =
  string | null;

export type TransactionsByAccountAndRoutingNumberListResponseDataItemCurrency =
  (typeof TransactionsByAccountAndRoutingNumberListResponseDataItemCurrency)[keyof typeof TransactionsByAccountAndRoutingNumberListResponseDataItemCurrency];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const TransactionsByAccountAndRoutingNumberListResponseDataItemCurrency =
  {
    CAD: "CAD",
    CHF: "CHF",
    EUR: "EUR",
    GBP: "GBP",
    JPY: "JPY",
    USD: "USD",
  } as const;

export type TransactionsByAccountAndRoutingNumberListResponseDataItem = {
  account_id: string;
  amount: number;
  created_at: string;
  currency: TransactionsByAccountAndRoutingNumberListResponseDataItemCurrency;
  date: string;
  description: string;
  group_id: string;
  group_name: TransactionsByAccountAndRoutingNumberListResponseDataItemGroupName;
  id: string;
  is_declined_transaction: boolean;
  route: TransactionsByAccountAndRoutingNumberListResponseDataItemRoute;
  source: unknown;
  source_documentation: TransactionsByAccountAndRoutingNumberListResponseDataItemSourceDocumentation;
  source_id: string;
  source_type: TransactionsByAccountAndRoutingNumberListResponseDataItemSourceType;
  transfer_id: TransactionsByAccountAndRoutingNumberListResponseDataItemTransferId;
  type: string;
};

export type TransactionsByACHTraceNumberListResponseNextCursor = string | null;

export interface TransactionsByACHTraceNumberListResponse {
  data: TransactionsByACHTraceNumberListResponseDataItem[];
  next_cursor: TransactionsByACHTraceNumberListResponseNextCursor;
}

export type TransactionsByACHTraceNumberListResponseDataItemTransferId =
  | string
  | null;

export type TransactionsByACHTraceNumberListResponseDataItemSourceType =
  (typeof TransactionsByACHTraceNumberListResponseDataItemSourceType)[keyof typeof TransactionsByACHTraceNumberListResponseDataItemSourceType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const TransactionsByACHTraceNumberListResponseDataItemSourceType = {
  "API::ACHCheckConversion": "API::ACHCheckConversion",
  "API::ACHCheckConversionReturn": "API::ACHCheckConversionReturn",
  "API::ACHTransferIntention": "API::ACHTransferIntention",
  "API::ACHTransferRejection": "API::ACHTransferRejection",
  "API::ACHTransferReturn": "API::ACHTransferReturn",
  "API::AccountTransferIntention": "API::AccountTransferIntention",
  "API::CardDisputeAcceptance": "API::CardDisputeAcceptance",
  "API::CardDisputeLoss": "API::CardDisputeLoss",
  "API::CardRefund": "API::CardRefund",
  "API::CardRevenuePayment": "API::CardRevenuePayment",
  "API::CardSettlement": "API::CardSettlement",
  "API::CashbackPayment": "API::CashbackPayment",
  "API::CheckDepositAcceptance": "API::CheckDepositAcceptance",
  "API::CheckDepositReturn": "API::CheckDepositReturn",
  "API::CheckTransferIntention": "API::CheckTransferIntention",
  "API::CheckTransferRejection": "API::CheckTransferRejection",
  "API::CheckTransferReturn": "API::CheckTransferReturn",
  "API::CheckTransferStopPaymentRequest":
    "API::CheckTransferStopPaymentRequest",
  "API::DeprecatedTransactionSource": "API::DeprecatedTransactionSource",
  "API::DisputeResolution": "API::DisputeResolution",
  "API::EmpyrealCashDeposit": "API::EmpyrealCashDeposit",
  "API::FeePayment": "API::FeePayment",
  "API::InboundACHTransferIntention": "API::InboundACHTransferIntention",
  "API::InboundACHTransferReturnIntention":
    "API::InboundACHTransferReturnIntention",
  "API::InboundCheck": "API::InboundCheck",
  "API::InboundCheckDepositReturnIntention":
    "API::InboundCheckDepositReturnIntention",
  "API::InboundCheckAdjustment": "API::InboundCheckAdjustment",
  "API::InboundRealTimePaymentsTransferConfirmation":
    "API::InboundRealTimePaymentsTransferConfirmation",
  "API::InboundWireReversal": "API::InboundWireReversal",
  "API::InboundWireTransferIntention": "API::InboundWireTransferIntention",
  "API::InboundWireTransferReversalIntention":
    "API::InboundWireTransferReversalIntention",
  "API::InterestPayment": "API::InterestPayment",
  "API::InternalGeneralLedgerTransaction":
    "API::InternalGeneralLedgerTransaction",
  "API::InternalSource": "API::InternalSource",
  "API::PrivacyCardRefund": "API::PrivacyCardRefund",
  "API::PrivacyCardSettlement": "API::PrivacyCardSettlement",
  "API::RealTimePaymentsTransferAcknowledgement":
    "API::RealTimePaymentsTransferAcknowledgement",
  "API::SampleFunds": "API::SampleFunds",
  "API::WireDrawdownPaymentIntention": "API::WireDrawdownPaymentIntention",
  "API::WireTransferIntention": "API::WireTransferIntention",
  "API::WireTransferRejection": "API::WireTransferRejection",
} as const;

export type TransactionsByACHTraceNumberListResponseDataItemSourceDocumentation =
  string | null;

export type TransactionsByACHTraceNumberListResponseDataItem = {
  account_id: string;
  amount: number;
  created_at: string;
  currency: TransactionsByACHTraceNumberListResponseDataItemCurrency;
  date: string;
  description: string;
  group_id: string;
  group_name: TransactionsByACHTraceNumberListResponseDataItemGroupName;
  id: string;
  is_declined_transaction: boolean;
  route: TransactionsByACHTraceNumberListResponseDataItemRoute;
  source: unknown;
  source_documentation: TransactionsByACHTraceNumberListResponseDataItemSourceDocumentation;
  source_id: string;
  source_type: TransactionsByACHTraceNumberListResponseDataItemSourceType;
  transfer_id: TransactionsByACHTraceNumberListResponseDataItemTransferId;
  type: string;
};

export type TransactionsByACHTraceNumberListResponseDataItemRouteAnyOfType =
  (typeof TransactionsByACHTraceNumberListResponseDataItemRouteAnyOfType)[keyof typeof TransactionsByACHTraceNumberListResponseDataItemRouteAnyOfType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const TransactionsByACHTraceNumberListResponseDataItemRouteAnyOfType = {
  account_number: "account_number",
  card: "card",
  lockbox: "lockbox",
} as const;

export type TransactionsByACHTraceNumberListResponseDataItemRouteAnyOfDescription =
  string | null;

export type TransactionsByACHTraceNumberListResponseDataItemRouteAnyOf = {
  description: TransactionsByACHTraceNumberListResponseDataItemRouteAnyOfDescription;
  id: string;
  type: TransactionsByACHTraceNumberListResponseDataItemRouteAnyOfType;
};

export type TransactionsByACHTraceNumberListResponseDataItemRoute =
  TransactionsByACHTraceNumberListResponseDataItemRouteAnyOf | null;

export type TransactionsByACHTraceNumberListResponseDataItemGroupName =
  | string
  | null;

export type TransactionsByACHTraceNumberListResponseDataItemCurrency =
  (typeof TransactionsByACHTraceNumberListResponseDataItemCurrency)[keyof typeof TransactionsByACHTraceNumberListResponseDataItemCurrency];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const TransactionsByACHTraceNumberListResponseDataItemCurrency = {
  CAD: "CAD",
  CHF: "CHF",
  EUR: "EUR",
  GBP: "GBP",
  JPY: "JPY",
  USD: "USD",
} as const;

export type TransactionGetResponseTransferId = string | null;

export type TransactionGetResponseSourceType =
  (typeof TransactionGetResponseSourceType)[keyof typeof TransactionGetResponseSourceType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const TransactionGetResponseSourceType = {
  "API::ACHCheckConversion": "API::ACHCheckConversion",
  "API::ACHCheckConversionReturn": "API::ACHCheckConversionReturn",
  "API::ACHTransferIntention": "API::ACHTransferIntention",
  "API::ACHTransferRejection": "API::ACHTransferRejection",
  "API::ACHTransferReturn": "API::ACHTransferReturn",
  "API::AccountTransferIntention": "API::AccountTransferIntention",
  "API::CardDisputeAcceptance": "API::CardDisputeAcceptance",
  "API::CardDisputeLoss": "API::CardDisputeLoss",
  "API::CardRefund": "API::CardRefund",
  "API::CardRevenuePayment": "API::CardRevenuePayment",
  "API::CardSettlement": "API::CardSettlement",
  "API::CashbackPayment": "API::CashbackPayment",
  "API::CheckDepositAcceptance": "API::CheckDepositAcceptance",
  "API::CheckDepositReturn": "API::CheckDepositReturn",
  "API::CheckTransferIntention": "API::CheckTransferIntention",
  "API::CheckTransferRejection": "API::CheckTransferRejection",
  "API::CheckTransferReturn": "API::CheckTransferReturn",
  "API::CheckTransferStopPaymentRequest":
    "API::CheckTransferStopPaymentRequest",
  "API::DeprecatedTransactionSource": "API::DeprecatedTransactionSource",
  "API::DisputeResolution": "API::DisputeResolution",
  "API::EmpyrealCashDeposit": "API::EmpyrealCashDeposit",
  "API::FeePayment": "API::FeePayment",
  "API::InboundACHTransferIntention": "API::InboundACHTransferIntention",
  "API::InboundACHTransferReturnIntention":
    "API::InboundACHTransferReturnIntention",
  "API::InboundCheck": "API::InboundCheck",
  "API::InboundCheckDepositReturnIntention":
    "API::InboundCheckDepositReturnIntention",
  "API::InboundCheckAdjustment": "API::InboundCheckAdjustment",
  "API::InboundRealTimePaymentsTransferConfirmation":
    "API::InboundRealTimePaymentsTransferConfirmation",
  "API::InboundWireReversal": "API::InboundWireReversal",
  "API::InboundWireTransferIntention": "API::InboundWireTransferIntention",
  "API::InboundWireTransferReversalIntention":
    "API::InboundWireTransferReversalIntention",
  "API::InterestPayment": "API::InterestPayment",
  "API::InternalGeneralLedgerTransaction":
    "API::InternalGeneralLedgerTransaction",
  "API::InternalSource": "API::InternalSource",
  "API::PrivacyCardRefund": "API::PrivacyCardRefund",
  "API::PrivacyCardSettlement": "API::PrivacyCardSettlement",
  "API::RealTimePaymentsTransferAcknowledgement":
    "API::RealTimePaymentsTransferAcknowledgement",
  "API::SampleFunds": "API::SampleFunds",
  "API::WireDrawdownPaymentIntention": "API::WireDrawdownPaymentIntention",
  "API::WireTransferIntention": "API::WireTransferIntention",
  "API::WireTransferRejection": "API::WireTransferRejection",
} as const;

export type TransactionGetResponseSourceDocumentation = string | null;

export interface TransactionGetResponse {
  account_id: string;
  amount: number;
  created_at: string;
  currency: TransactionGetResponseCurrency;
  date: string;
  description: string;
  group_id: string;
  group_name: TransactionGetResponseGroupName;
  id: string;
  is_declined_transaction: boolean;
  route: TransactionGetResponseRoute;
  source: unknown;
  source_documentation: TransactionGetResponseSourceDocumentation;
  source_id: string;
  source_type: TransactionGetResponseSourceType;
  transfer_id: TransactionGetResponseTransferId;
  type: string;
}

export type TransactionGetResponseRouteAnyOfType =
  (typeof TransactionGetResponseRouteAnyOfType)[keyof typeof TransactionGetResponseRouteAnyOfType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const TransactionGetResponseRouteAnyOfType = {
  account_number: "account_number",
  card: "card",
  lockbox: "lockbox",
} as const;

export type TransactionGetResponseRouteAnyOfDescription = string | null;

export type TransactionGetResponseRouteAnyOf = {
  description: TransactionGetResponseRouteAnyOfDescription;
  id: string;
  type: TransactionGetResponseRouteAnyOfType;
};

export type TransactionGetResponseRoute =
  TransactionGetResponseRouteAnyOf | null;

export type TransactionGetResponseGroupName = string | null;

export type TransactionGetResponseCurrency =
  (typeof TransactionGetResponseCurrency)[keyof typeof TransactionGetResponseCurrency];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const TransactionGetResponseCurrency = {
  CAD: "CAD",
  CHF: "CHF",
  EUR: "EUR",
  GBP: "GBP",
  JPY: "JPY",
  USD: "USD",
} as const;

export type TransactionAccountingEntryForTraceListResponseNextCursor =
  | string
  | null;

export interface TransactionAccountingEntryForTraceListResponse {
  data: TransactionAccountingEntryForTraceListResponseDataItem[];
  next_cursor: TransactionAccountingEntryForTraceListResponseNextCursor;
}

export type TransactionAccountingEntryForTraceListResponseDataItemTransactionSourceType =
  string | null;

export type TransactionAccountingEntryForTraceListResponseDataItemFlow =
  (typeof TransactionAccountingEntryForTraceListResponseDataItemFlow)[keyof typeof TransactionAccountingEntryForTraceListResponseDataItemFlow];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const TransactionAccountingEntryForTraceListResponseDataItemFlow = {
  inbound_wires: "inbound_wires",
  outbound_wires: "outbound_wires",
  inbound_wire_reversals: "inbound_wire_reversals",
  inbound_bank_to_bank_wires: "inbound_bank_to_bank_wires",
  inbound_ach: "inbound_ach",
  outbound_ach: "outbound_ach",
  inbound_ach_return: "inbound_ach_return",
  check_outclearing: "check_outclearing",
  check_inclearing: "check_inclearing",
  check_return_inclearing: "check_return_inclearing",
  received_check_adjustment: "received_check_adjustment",
  empyreal: "empyreal",
  internal_source: "internal_source",
  internal_general_ledger: "internal_general_ledger",
  interest: "interest",
  account_transfers: "account_transfers",
  deprecated: "deprecated",
} as const;

export type TransactionAccountingEntryForTraceListResponseDataItemDebitAccount =
  (typeof TransactionAccountingEntryForTraceListResponseDataItemDebitAccount)[keyof typeof TransactionAccountingEntryForTraceListResponseDataItemDebitAccount];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const TransactionAccountingEntryForTraceListResponseDataItemDebitAccount =
  {
    user_transactions: "user_transactions",
    wire_transfer_pending_allocating: "wire_transfer_pending_allocating",
    wire_transfer_pending_submitting: "wire_transfer_pending_submitting",
    wire_reversal_pending_allocating: "wire_reversal_pending_allocating",
    wire_reversal_pending_submitting: "wire_reversal_pending_submitting",
    bank_to_bank_wire_pending_allocating:
      "bank_to_bank_wire_pending_allocating",
    fedwire_inbound_settlement: "fedwire_inbound_settlement",
    fedwire_outbound_settlement: "fedwire_outbound_settlement",
    ach_transfer_pending_submitting: "ach_transfer_pending_submitting",
    ach_transfer_pending_allocating: "ach_transfer_pending_allocating",
    ach_transfer_return_pending_submitting:
      "ach_transfer_return_pending_submitting",
    ach_transfer_return_pending_allocating:
      "ach_transfer_return_pending_allocating",
    ach_dishonored_return_pending_submitting:
      "ach_dishonored_return_pending_submitting",
    fedach_outbound_forwards_settlement: "fedach_outbound_forwards_settlement",
    fedach_inbound_forwards_settlement: "fedach_inbound_forwards_settlement",
    fedach_outbound_returns_settlement: "fedach_outbound_returns_settlement",
    fedach_inbound_returns_settlement: "fedach_inbound_returns_settlement",
    check_item_pending_submitting: "check_item_pending_submitting",
    check_item_pending_allocating: "check_item_pending_allocating",
    check_return_pending_submitting: "check_return_pending_submitting",
    check_return_pending_allocating: "check_return_pending_allocating",
    check_adjustment_pending_allocating: "check_adjustment_pending_allocating",
    check21_outclearing_settlement: "check21_outclearing_settlement",
    check21_inclearing_settlement: "check21_inclearing_settlement",
    check21_adjustment_settlement: "check21_adjustment_settlement",
    empyreal_settlement: "empyreal_settlement",
    empyreal_pending_allocating: "empyreal_pending_allocating",
    internal_source_settlement: "internal_source_settlement",
    internal_general_ledger_pending: "internal_general_ledger_pending",
    interest_expense: "interest_expense",
    account_transfer_pending: "account_transfer_pending",
    external_activity_settlement: "external_activity_settlement",
    deprecated_transaction_settlement: "deprecated_transaction_settlement",
  } as const;

export type TransactionAccountingEntryForTraceListResponseDataItemCreditAccount =
  (typeof TransactionAccountingEntryForTraceListResponseDataItemCreditAccount)[keyof typeof TransactionAccountingEntryForTraceListResponseDataItemCreditAccount];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const TransactionAccountingEntryForTraceListResponseDataItemCreditAccount =
  {
    user_transactions: "user_transactions",
    wire_transfer_pending_allocating: "wire_transfer_pending_allocating",
    wire_transfer_pending_submitting: "wire_transfer_pending_submitting",
    wire_reversal_pending_allocating: "wire_reversal_pending_allocating",
    wire_reversal_pending_submitting: "wire_reversal_pending_submitting",
    bank_to_bank_wire_pending_allocating:
      "bank_to_bank_wire_pending_allocating",
    fedwire_inbound_settlement: "fedwire_inbound_settlement",
    fedwire_outbound_settlement: "fedwire_outbound_settlement",
    ach_transfer_pending_submitting: "ach_transfer_pending_submitting",
    ach_transfer_pending_allocating: "ach_transfer_pending_allocating",
    ach_transfer_return_pending_submitting:
      "ach_transfer_return_pending_submitting",
    ach_transfer_return_pending_allocating:
      "ach_transfer_return_pending_allocating",
    ach_dishonored_return_pending_submitting:
      "ach_dishonored_return_pending_submitting",
    fedach_outbound_forwards_settlement: "fedach_outbound_forwards_settlement",
    fedach_inbound_forwards_settlement: "fedach_inbound_forwards_settlement",
    fedach_outbound_returns_settlement: "fedach_outbound_returns_settlement",
    fedach_inbound_returns_settlement: "fedach_inbound_returns_settlement",
    check_item_pending_submitting: "check_item_pending_submitting",
    check_item_pending_allocating: "check_item_pending_allocating",
    check_return_pending_submitting: "check_return_pending_submitting",
    check_return_pending_allocating: "check_return_pending_allocating",
    check_adjustment_pending_allocating: "check_adjustment_pending_allocating",
    check21_outclearing_settlement: "check21_outclearing_settlement",
    check21_inclearing_settlement: "check21_inclearing_settlement",
    check21_adjustment_settlement: "check21_adjustment_settlement",
    empyreal_settlement: "empyreal_settlement",
    empyreal_pending_allocating: "empyreal_pending_allocating",
    internal_source_settlement: "internal_source_settlement",
    internal_general_ledger_pending: "internal_general_ledger_pending",
    interest_expense: "interest_expense",
    account_transfer_pending: "account_transfer_pending",
    external_activity_settlement: "external_activity_settlement",
    deprecated_transaction_settlement: "deprecated_transaction_settlement",
  } as const;

export type TransactionAccountingEntryForTraceListResponseDataItemBank =
  (typeof TransactionAccountingEntryForTraceListResponseDataItemBank)[keyof typeof TransactionAccountingEntryForTraceListResponseDataItemBank];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const TransactionAccountingEntryForTraceListResponseDataItemBank = {
  blue_ridge_bank: "blue_ridge_bank",
  core_bank: "core_bank",
  first_internet_bank: "first_internet_bank",
  global_innovations_bank: "global_innovations_bank",
  grasshopper_bank: "grasshopper_bank",
} as const;

export type TransactionAccountingEntryForTraceListResponseDataItem = {
  amount: number;
  bank: TransactionAccountingEntryForTraceListResponseDataItemBank;
  credit_account: TransactionAccountingEntryForTraceListResponseDataItemCreditAccount;
  date: string;
  debit_account: TransactionAccountingEntryForTraceListResponseDataItemDebitAccount;
  flow: TransactionAccountingEntryForTraceListResponseDataItemFlow;
  id: string;
  source_id: string;
  source_type: string;
  transaction_source_type: TransactionAccountingEntryForTraceListResponseDataItemTransactionSourceType;
};

export type TokenizationMessagePostResponse = string;

export interface TokenizationMessagePostParameters {
  references: unknown;
  request_payload: unknown;
  tokenization_operation: unknown;
}

export type TaskStatusPatchResponseStatus =
  (typeof TaskStatusPatchResponseStatus)[keyof typeof TaskStatusPatchResponseStatus];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const TaskStatusPatchResponseStatus = {
  active: "active",
  disabled: "disabled",
} as const;

export type TaskStatusPatchResponseResultStatusId = string | null;

export type TaskStatusPatchResponseCron = string | null;

export interface TaskStatusPatchResponse {
  created_at: string;
  cron: TaskStatusPatchResponseCron;
  id: string;
  name: string;
  result_status_id: TaskStatusPatchResponseResultStatusId;
  status: TaskStatusPatchResponseStatus;
}

export type TaskStatusPatchParametersStatus =
  (typeof TaskStatusPatchParametersStatus)[keyof typeof TaskStatusPatchParametersStatus];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const TaskStatusPatchParametersStatus = {
  active: "active",
  disabled: "disabled",
} as const;

export interface TaskStatusPatchParameters {
  status: TaskStatusPatchParametersStatus;
  task_status_id: string;
}

export type TaskStatusListResponseNextCursor = string | null;

export interface TaskStatusListResponse {
  data: TaskStatusListResponseDataItem[];
  next_cursor: TaskStatusListResponseNextCursor;
}

export type TaskStatusListResponseDataItemStatus =
  (typeof TaskStatusListResponseDataItemStatus)[keyof typeof TaskStatusListResponseDataItemStatus];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const TaskStatusListResponseDataItemStatus = {
  active: "active",
  disabled: "disabled",
} as const;

export type TaskStatusListResponseDataItemResultStatusId = string | null;

export type TaskStatusListResponseDataItemCron = string | null;

export type TaskStatusListResponseDataItem = {
  created_at: string;
  cron: TaskStatusListResponseDataItemCron;
  id: string;
  name: string;
  result_status_id: TaskStatusListResponseDataItemResultStatusId;
  status: TaskStatusListResponseDataItemStatus;
};

export interface SovosResponseGetResponse {
  id: string;
  raw_response_body: string;
}

export interface SessionSingleSignOnPostResponse {
  message: string;
}

export interface SessionSingleSignOnPostParameters {
  code: string;
  state: string;
}

export type SessionSingleSignOnAuthenticationURLGetResponseRedirectUrl =
  | string
  | null;

export interface SessionSingleSignOnAuthenticationURLGetResponse {
  redirect_url: SessionSingleSignOnAuthenticationURLGetResponseRedirectUrl;
}

export type SessionPostResponseOneTimePasswordStatus =
  (typeof SessionPostResponseOneTimePasswordStatus)[keyof typeof SessionPostResponseOneTimePasswordStatus];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SessionPostResponseOneTimePasswordStatus = {
  confirmed: "confirmed",
  pending_confirming: "pending_confirming",
} as const;

export interface SessionPostResponse {
  message: string;
  one_time_password_status: SessionPostResponseOneTimePasswordStatus;
}

export interface SessionPostParameters {
  email: string;
  one_time_password?: string;
  password: string;
}

export type SessionGetResponseOneTimePasswordStatus =
  (typeof SessionGetResponseOneTimePasswordStatus)[keyof typeof SessionGetResponseOneTimePasswordStatus];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SessionGetResponseOneTimePasswordStatus = {
  confirmed: "confirmed",
  pending_confirming: "pending_confirming",
} as const;

export interface SessionGetResponse {
  message: string;
  one_time_password_status: SessionGetResponseOneTimePasswordStatus;
}

export interface SessionDeleteResponse {
  [key: string]: unknown;
}

export type SecretaryOfStateSearchIntentGetResponseSubmittedState =
  | string
  | null;

export type SecretaryOfStateSearchIntentGetResponseStatus =
  (typeof SecretaryOfStateSearchIntentGetResponseStatus)[keyof typeof SecretaryOfStateSearchIntentGetResponseStatus];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SecretaryOfStateSearchIntentGetResponseStatus = {
  pending: "pending",
  searching: "searching",
  retrying: "retrying",
  complete: "complete",
  failed: "failed",
  requires_attention: "requires_attention",
  skipped: "skipped",
} as const;

export type SecretaryOfStateSearchIntentGetResponseSearchStrategy =
  (typeof SecretaryOfStateSearchIntentGetResponseSearchStrategy)[keyof typeof SecretaryOfStateSearchIntentGetResponseSearchStrategy];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SecretaryOfStateSearchIntentGetResponseSearchStrategy = {
  incorporation_state: "incorporation_state",
  address_state: "address_state",
} as const;

export type SecretaryOfStateSearchIntentGetResponseSearchResponseAnyOf = {
  id: string;
  response_body: string;
};

export type SecretaryOfStateSearchIntentGetResponseSearchResponse =
  SecretaryOfStateSearchIntentGetResponseSearchResponseAnyOf | null;

export type SecretaryOfStateSearchIntentGetResponseOutcome =
  | "found"
  | "not_found"
  | null;

export interface SecretaryOfStateSearchIntentGetResponse {
  created_at: string;
  entity_id: string;
  id: string;
  outcome: SecretaryOfStateSearchIntentGetResponseOutcome;
  search_response: SecretaryOfStateSearchIntentGetResponseSearchResponse;
  search_strategy: SecretaryOfStateSearchIntentGetResponseSearchStrategy;
  searchable_submission_class: string;
  searchable_submission_id: string;
  status: SecretaryOfStateSearchIntentGetResponseStatus;
  submitted_address_state: string;
  submitted_name: string;
  submitted_state: SecretaryOfStateSearchIntentGetResponseSubmittedState;
}

export type SanctionsScreeningTriggerTokenPostResponseReviewer =
  (typeof SanctionsScreeningTriggerTokenPostResponseReviewer)[keyof typeof SanctionsScreeningTriggerTokenPostResponseReviewer];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SanctionsScreeningTriggerTokenPostResponseReviewer = {
  increase: "increase",
  grasshopper: "grasshopper",
  core_bank: "core_bank",
} as const;

export interface SanctionsScreeningTriggerTokenPostResponse {
  created_at: string;
  created_by_email: string;
  id: string;
  reviewer: SanctionsScreeningTriggerTokenPostResponseReviewer;
  token: string;
}

export interface SanctionsScreeningTriggerTokenPostParameters {
  note: string;
  token: string;
}

export type SanctionsScreeningTriggerTokenListResponseNextCursor =
  | string
  | null;

export type SanctionsScreeningTriggerTokenListResponseDataItemReviewer =
  (typeof SanctionsScreeningTriggerTokenListResponseDataItemReviewer)[keyof typeof SanctionsScreeningTriggerTokenListResponseDataItemReviewer];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SanctionsScreeningTriggerTokenListResponseDataItemReviewer = {
  increase: "increase",
  grasshopper: "grasshopper",
  core_bank: "core_bank",
} as const;

export type SanctionsScreeningTriggerTokenListResponseDataItem = {
  created_at: string;
  created_by_email: string;
  id: string;
  reviewer: SanctionsScreeningTriggerTokenListResponseDataItemReviewer;
  token: string;
};

export interface SanctionsScreeningTriggerTokenListResponse {
  data: SanctionsScreeningTriggerTokenListResponseDataItem[];
  next_cursor: SanctionsScreeningTriggerTokenListResponseNextCursor;
}

export type SanctionsScreeningTriggerTokenGetResponseReviewer =
  (typeof SanctionsScreeningTriggerTokenGetResponseReviewer)[keyof typeof SanctionsScreeningTriggerTokenGetResponseReviewer];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SanctionsScreeningTriggerTokenGetResponseReviewer = {
  increase: "increase",
  grasshopper: "grasshopper",
  core_bank: "core_bank",
} as const;

export interface SanctionsScreeningTriggerTokenGetResponse {
  created_at: string;
  created_by_email: string;
  id: string;
  reviewer: SanctionsScreeningTriggerTokenGetResponseReviewer;
  token: string;
}

export type SanctionsScreeningTriggerTokenArchiveDirectivePostResponseReviewer =
  (typeof SanctionsScreeningTriggerTokenArchiveDirectivePostResponseReviewer)[keyof typeof SanctionsScreeningTriggerTokenArchiveDirectivePostResponseReviewer];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SanctionsScreeningTriggerTokenArchiveDirectivePostResponseReviewer =
  {
    increase: "increase",
    grasshopper: "grasshopper",
    core_bank: "core_bank",
  } as const;

export interface SanctionsScreeningTriggerTokenArchiveDirectivePostResponse {
  created_at: string;
  created_by_email: string;
  id: string;
  reviewer: SanctionsScreeningTriggerTokenArchiveDirectivePostResponseReviewer;
  token: string;
}

export type SanctionsScreeningReviewRecordGetResponseWireTransferInstruction =
  SanctionsScreeningReviewRecordGetResponseWireTransferInstructionAnyOf | null;

export interface SanctionsScreeningReviewRecordGetResponse {
  entity_id: SanctionsScreeningReviewRecordGetResponseEntityId;
  entity_setup_beneficial_owner_submission?: SanctionsScreeningReviewRecordGetResponseEntitySetupBeneficialOwnerSubmission;
  entity_setup_corporation_submission?: SanctionsScreeningReviewRecordGetResponseEntitySetupCorporationSubmission;
  entity_setup_natural_person_submission?: SanctionsScreeningReviewRecordGetResponseEntitySetupNaturalPersonSubmission;
  inbound_ach_international_addenda11?: SanctionsScreeningReviewRecordGetResponseInboundAchInternationalAddenda11;
  inbound_wire?: SanctionsScreeningReviewRecordGetResponseInboundWire;
  record_id: string;
  record_type: SanctionsScreeningReviewRecordGetResponseRecordType;
  supplemental_documentation?: unknown;
  wire_transfer_instruction?: SanctionsScreeningReviewRecordGetResponseWireTransferInstruction;
}

export type SanctionsScreeningReviewRecordGetResponseWireTransferInstructionAnyOfOriginatorName =
  string | null;

export type SanctionsScreeningReviewRecordGetResponseWireTransferInstructionAnyOfOriginatorAddressLine3 =
  string | null;

export type SanctionsScreeningReviewRecordGetResponseWireTransferInstructionAnyOfOriginatorAddressLine2 =
  string | null;

export type SanctionsScreeningReviewRecordGetResponseWireTransferInstructionAnyOfOriginatorAddressLine1 =
  string | null;

export type SanctionsScreeningReviewRecordGetResponseWireTransferInstructionAnyOfDestinationBankName =
  string | null;

export type SanctionsScreeningReviewRecordGetResponseWireTransferInstructionAnyOfBeneficiaryName =
  string | null;

export type SanctionsScreeningReviewRecordGetResponseWireTransferInstructionAnyOfBeneficiaryFinancialInstitutionName =
  string | null;

export type SanctionsScreeningReviewRecordGetResponseWireTransferInstructionAnyOfBeneficiaryFinancialInstitutionAddressLine3 =
  string | null;

export type SanctionsScreeningReviewRecordGetResponseWireTransferInstructionAnyOfBeneficiaryFinancialInstitutionAddressLine2 =
  string | null;

export type SanctionsScreeningReviewRecordGetResponseWireTransferInstructionAnyOfBeneficiaryFinancialInstitutionAddressLine1 =
  string | null;

export type SanctionsScreeningReviewRecordGetResponseWireTransferInstructionAnyOfBeneficiaryAddressLine3 =
  string | null;

export type SanctionsScreeningReviewRecordGetResponseWireTransferInstructionAnyOfBeneficiaryAddressLine2 =
  string | null;

export type SanctionsScreeningReviewRecordGetResponseWireTransferInstructionAnyOfBeneficiaryAddressLine1 =
  string | null;

export type SanctionsScreeningReviewRecordGetResponseWireTransferInstructionAnyOf =
  {
    amount_usd: number;
    beneficiary_address_line1: SanctionsScreeningReviewRecordGetResponseWireTransferInstructionAnyOfBeneficiaryAddressLine1;
    beneficiary_address_line2: SanctionsScreeningReviewRecordGetResponseWireTransferInstructionAnyOfBeneficiaryAddressLine2;
    beneficiary_address_line3: SanctionsScreeningReviewRecordGetResponseWireTransferInstructionAnyOfBeneficiaryAddressLine3;
    beneficiary_financial_institution_address_line1: SanctionsScreeningReviewRecordGetResponseWireTransferInstructionAnyOfBeneficiaryFinancialInstitutionAddressLine1;
    beneficiary_financial_institution_address_line2: SanctionsScreeningReviewRecordGetResponseWireTransferInstructionAnyOfBeneficiaryFinancialInstitutionAddressLine2;
    beneficiary_financial_institution_address_line3: SanctionsScreeningReviewRecordGetResponseWireTransferInstructionAnyOfBeneficiaryFinancialInstitutionAddressLine3;
    beneficiary_financial_institution_name: SanctionsScreeningReviewRecordGetResponseWireTransferInstructionAnyOfBeneficiaryFinancialInstitutionName;
    beneficiary_name: SanctionsScreeningReviewRecordGetResponseWireTransferInstructionAnyOfBeneficiaryName;
    created_at: string;
    destination_bank_name: SanctionsScreeningReviewRecordGetResponseWireTransferInstructionAnyOfDestinationBankName;
    destination_routing_number: string;
    id: string;
    message_to_recipient: string;
    originator_address_line1: SanctionsScreeningReviewRecordGetResponseWireTransferInstructionAnyOfOriginatorAddressLine1;
    originator_address_line2: SanctionsScreeningReviewRecordGetResponseWireTransferInstructionAnyOfOriginatorAddressLine2;
    originator_address_line3: SanctionsScreeningReviewRecordGetResponseWireTransferInstructionAnyOfOriginatorAddressLine3;
    originator_name: SanctionsScreeningReviewRecordGetResponseWireTransferInstructionAnyOfOriginatorName;
  };

export type SanctionsScreeningReviewRecordGetResponseRecordType =
  (typeof SanctionsScreeningReviewRecordGetResponseRecordType)[keyof typeof SanctionsScreeningReviewRecordGetResponseRecordType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SanctionsScreeningReviewRecordGetResponseRecordType = {
  inbound_wire: "inbound_wire",
  outbound_wire: "outbound_wire",
  international_ach_entry: "international_ach_entry",
  entity_setup_beneficial_owner: "entity_setup_beneficial_owner",
  entity_setup_corporation: "entity_setup_corporation",
  entity_setup_natural_person: "entity_setup_natural_person",
  entity_setup_government_authority_person:
    "entity_setup_government_authority_person",
  entity_setup_government_authority: "entity_setup_government_authority",
} as const;

export type SanctionsScreeningReviewRecordGetResponseInboundWire =
  SanctionsScreeningReviewRecordGetResponseInboundWireAnyOf | null;

export type SanctionsScreeningReviewRecordGetResponseInboundWireAnyOfOriginatorToBeneficiaryInformation =
  string | null;

export type SanctionsScreeningReviewRecordGetResponseInboundWireAnyOfOriginatorName =
  string | null;

export type SanctionsScreeningReviewRecordGetResponseInboundWireAnyOfOriginatorAddress =
  string | null;

export type SanctionsScreeningReviewRecordGetResponseInboundWireAnyOfOriginatingBankName =
  string | null;

export type SanctionsScreeningReviewRecordGetResponseInboundWireAnyOfBeneficiaryName =
  string | null;

export type SanctionsScreeningReviewRecordGetResponseInboundWireAnyOfBeneficiaryIdentifier =
  string | null;

export type SanctionsScreeningReviewRecordGetResponseInboundWireAnyOfBeneficiaryAddress =
  string | null;

export type SanctionsScreeningReviewRecordGetResponseInboundWireAnyOf = {
  amount: number;
  beneficiary_address: SanctionsScreeningReviewRecordGetResponseInboundWireAnyOfBeneficiaryAddress;
  beneficiary_identifier: SanctionsScreeningReviewRecordGetResponseInboundWireAnyOfBeneficiaryIdentifier;
  beneficiary_name: SanctionsScreeningReviewRecordGetResponseInboundWireAnyOfBeneficiaryName;
  created_at: string;
  id: string;
  originating_bank_name: SanctionsScreeningReviewRecordGetResponseInboundWireAnyOfOriginatingBankName;
  originating_routing_number: string;
  originator_address: SanctionsScreeningReviewRecordGetResponseInboundWireAnyOfOriginatorAddress;
  originator_name: SanctionsScreeningReviewRecordGetResponseInboundWireAnyOfOriginatorName;
  originator_to_beneficiary_information: SanctionsScreeningReviewRecordGetResponseInboundWireAnyOfOriginatorToBeneficiaryInformation;
};

export type SanctionsScreeningReviewRecordGetResponseInboundAchInternationalAddenda11 =
  SanctionsScreeningReviewRecordGetResponseInboundAchInternationalAddenda11AnyOf | null;

export type SanctionsScreeningReviewRecordGetResponseInboundAchInternationalAddenda11AnyOfOriginatingBankName =
  string | null;

export type SanctionsScreeningReviewRecordGetResponseInboundAchInternationalAddenda11AnyOf =
  {
    addenda_type_code: string;
    created_at: string;
    id: string;
    originating_bank_name: SanctionsScreeningReviewRecordGetResponseInboundAchInternationalAddenda11AnyOfOriginatingBankName;
    originating_routing_number: string;
    originator_name: string;
    originator_street_address: string;
  };

export type SanctionsScreeningReviewRecordGetResponseEntitySetupNaturalPersonSubmission =
  SanctionsScreeningReviewRecordGetResponseEntitySetupNaturalPersonSubmissionAnyOf | null;

export type SanctionsScreeningReviewRecordGetResponseEntitySetupNaturalPersonSubmissionAnyOfTaxIdValidationStatus =
  (typeof SanctionsScreeningReviewRecordGetResponseEntitySetupNaturalPersonSubmissionAnyOfTaxIdValidationStatus)[keyof typeof SanctionsScreeningReviewRecordGetResponseEntitySetupNaturalPersonSubmissionAnyOfTaxIdValidationStatus];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SanctionsScreeningReviewRecordGetResponseEntitySetupNaturalPersonSubmissionAnyOfTaxIdValidationStatus =
  {
    pending_validating: "pending_validating",
    update_pending_validating: "update_pending_validating",
    requires_attention: "requires_attention",
    failed: "failed",
    validated: "validated",
    not_provided: "not_provided",
    skipped: "skipped",
  } as const;

export type SanctionsScreeningReviewRecordGetResponseEntitySetupNaturalPersonSubmissionAnyOfSubmittedIdentificationMethod =
  (typeof SanctionsScreeningReviewRecordGetResponseEntitySetupNaturalPersonSubmissionAnyOfSubmittedIdentificationMethod)[keyof typeof SanctionsScreeningReviewRecordGetResponseEntitySetupNaturalPersonSubmissionAnyOfSubmittedIdentificationMethod];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SanctionsScreeningReviewRecordGetResponseEntitySetupNaturalPersonSubmissionAnyOfSubmittedIdentificationMethod =
  {
    social_security_number: "social_security_number",
    individual_taxpayer_identification_number:
      "individual_taxpayer_identification_number",
    passport: "passport",
    drivers_license: "drivers_license",
    other: "other",
  } as const;

export type SanctionsScreeningReviewRecordGetResponseEntitySetupNaturalPersonSubmissionAnyOfSubmittedAddressLine2 =
  string | null;

export type SanctionsScreeningReviewRecordGetResponseEntitySetupNaturalPersonSubmissionAnyOfSubmittedAddress =
  {
    city: string;
    line1: string;
    line2: SanctionsScreeningReviewRecordGetResponseEntitySetupNaturalPersonSubmissionAnyOfSubmittedAddressLine2;
    state: string;
    zip: string;
  };

export type SanctionsScreeningReviewRecordGetResponseEntitySetupNaturalPersonSubmissionAnyOfSubmissionSovosResponseId =
  string | null;

export type SanctionsScreeningReviewRecordGetResponseEntitySetupNaturalPersonSubmissionAnyOfMicrobiltItemTaxIdType =
  "SSN" | null;

export type SanctionsScreeningReviewRecordGetResponseEntitySetupNaturalPersonSubmissionAnyOfMicrobiltItemTaxIdLast4 =
  string | null;

export type SanctionsScreeningReviewRecordGetResponseEntitySetupNaturalPersonSubmissionAnyOfMicrobiltItemReturnCode =
  (typeof SanctionsScreeningReviewRecordGetResponseEntitySetupNaturalPersonSubmissionAnyOfMicrobiltItemReturnCode)[keyof typeof SanctionsScreeningReviewRecordGetResponseEntitySetupNaturalPersonSubmissionAnyOfMicrobiltItemReturnCode];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SanctionsScreeningReviewRecordGetResponseEntitySetupNaturalPersonSubmissionAnyOfMicrobiltItemReturnCode =
  {
    OK: "OK",
  } as const;

export type SanctionsScreeningReviewRecordGetResponseEntitySetupNaturalPersonSubmissionAnyOfMicrobiltItemMiddleName =
  string | null;

export type SanctionsScreeningReviewRecordGetResponseEntitySetupNaturalPersonSubmissionAnyOfMicrobiltItemDeathDate =
  string | null;

export type SanctionsScreeningReviewRecordGetResponseEntitySetupNaturalPersonSubmissionAnyOfMicrobiltItemBirthDate =
  string | null;

export type SanctionsScreeningReviewRecordGetResponseEntitySetupNaturalPersonSubmissionAnyOfMicrobiltItem =
  {
    birth_date: SanctionsScreeningReviewRecordGetResponseEntitySetupNaturalPersonSubmissionAnyOfMicrobiltItemBirthDate;
    created_at: string;
    death_date: SanctionsScreeningReviewRecordGetResponseEntitySetupNaturalPersonSubmissionAnyOfMicrobiltItemDeathDate;
    first_name: string;
    id: string;
    last_name: string;
    middle_name: SanctionsScreeningReviewRecordGetResponseEntitySetupNaturalPersonSubmissionAnyOfMicrobiltItemMiddleName;
    request_id: string;
    response: string;
    return_code: SanctionsScreeningReviewRecordGetResponseEntitySetupNaturalPersonSubmissionAnyOfMicrobiltItemReturnCode;
    tax_id_last_4: SanctionsScreeningReviewRecordGetResponseEntitySetupNaturalPersonSubmissionAnyOfMicrobiltItemTaxIdLast4;
    tax_id_type: SanctionsScreeningReviewRecordGetResponseEntitySetupNaturalPersonSubmissionAnyOfMicrobiltItemTaxIdType;
  };

export type SanctionsScreeningReviewRecordGetResponseEntitySetupNaturalPersonSubmissionAnyOfIdentityVerificationStatus =
  (typeof SanctionsScreeningReviewRecordGetResponseEntitySetupNaturalPersonSubmissionAnyOfIdentityVerificationStatus)[keyof typeof SanctionsScreeningReviewRecordGetResponseEntitySetupNaturalPersonSubmissionAnyOfIdentityVerificationStatus];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SanctionsScreeningReviewRecordGetResponseEntitySetupNaturalPersonSubmissionAnyOfIdentityVerificationStatus =
  {
    pending_verification: "pending_verification",
    update_pending_verification: "update_pending_verification",
    verified: "verified",
    falsified: "falsified",
    no_match: "no_match",
    verifying: "verifying",
    requires_attention: "requires_attention",
    not_performed: "not_performed",
    skipped: "skipped",
  } as const;

export type SanctionsScreeningReviewRecordGetResponseEntitySetupNaturalPersonSubmissionAnyOf =
  {
    created_at: string;
    id: string;
    identity_document_submissions: SanctionsScreeningReviewRecordGetResponseEntitySetupNaturalPersonSubmissionAnyOfIdentityDocumentSubmissionsItem[];
    identity_submitted_at: string;
    identity_verification_status: SanctionsScreeningReviewRecordGetResponseEntitySetupNaturalPersonSubmissionAnyOfIdentityVerificationStatus;
    microbilt: SanctionsScreeningReviewRecordGetResponseEntitySetupNaturalPersonSubmissionAnyOfMicrobiltItem[];
    submission_sovos_response_id: SanctionsScreeningReviewRecordGetResponseEntitySetupNaturalPersonSubmissionAnyOfSubmissionSovosResponseId;
    submitted_address: SanctionsScreeningReviewRecordGetResponseEntitySetupNaturalPersonSubmissionAnyOfSubmittedAddress;
    submitted_date_of_birth: string;
    submitted_identification_method: SanctionsScreeningReviewRecordGetResponseEntitySetupNaturalPersonSubmissionAnyOfSubmittedIdentificationMethod;
    submitted_identification_number_last4: string;
    submitted_name: string;
    tax_id_validation_status: SanctionsScreeningReviewRecordGetResponseEntitySetupNaturalPersonSubmissionAnyOfTaxIdValidationStatus;
  };

export type SanctionsScreeningReviewRecordGetResponseEntitySetupNaturalPersonSubmissionAnyOfIdentityDocumentSubmissionsItemSubmittedState =
  string | null;

export type SanctionsScreeningReviewRecordGetResponseEntitySetupNaturalPersonSubmissionAnyOfIdentityDocumentSubmissionsItemSubmittedExpirationDate =
  string | null;

export type SanctionsScreeningReviewRecordGetResponseEntitySetupNaturalPersonSubmissionAnyOfIdentityDocumentSubmissionsItemSubmittedDocumentType =
  (typeof SanctionsScreeningReviewRecordGetResponseEntitySetupNaturalPersonSubmissionAnyOfIdentityDocumentSubmissionsItemSubmittedDocumentType)[keyof typeof SanctionsScreeningReviewRecordGetResponseEntitySetupNaturalPersonSubmissionAnyOfIdentityDocumentSubmissionsItemSubmittedDocumentType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SanctionsScreeningReviewRecordGetResponseEntitySetupNaturalPersonSubmissionAnyOfIdentityDocumentSubmissionsItemSubmittedDocumentType =
  {
    passport: "passport",
    us_drivers_license: "us_drivers_license",
    other: "other",
  } as const;

export type SanctionsScreeningReviewRecordGetResponseEntitySetupNaturalPersonSubmissionAnyOfIdentityDocumentSubmissionsItemSubmittedDocumentNumber =
  string | null;

export type SanctionsScreeningReviewRecordGetResponseEntitySetupNaturalPersonSubmissionAnyOfIdentityDocumentSubmissionsItemSubmittedAddressLine2 =
  string | null;

export type SanctionsScreeningReviewRecordGetResponseEntitySetupNaturalPersonSubmissionAnyOfIdentityDocumentSubmissionsItemSubmittedAddress =
  {
    city: string;
    line1: string;
    line2: SanctionsScreeningReviewRecordGetResponseEntitySetupNaturalPersonSubmissionAnyOfIdentityDocumentSubmissionsItemSubmittedAddressLine2;
    state: string;
    zip: string;
  };

export type SanctionsScreeningReviewRecordGetResponseEntitySetupNaturalPersonSubmissionAnyOfIdentityDocumentSubmissionsItemIdentityVerificationStatus =
  (typeof SanctionsScreeningReviewRecordGetResponseEntitySetupNaturalPersonSubmissionAnyOfIdentityDocumentSubmissionsItemIdentityVerificationStatus)[keyof typeof SanctionsScreeningReviewRecordGetResponseEntitySetupNaturalPersonSubmissionAnyOfIdentityDocumentSubmissionsItemIdentityVerificationStatus];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SanctionsScreeningReviewRecordGetResponseEntitySetupNaturalPersonSubmissionAnyOfIdentityDocumentSubmissionsItemIdentityVerificationStatus =
  {
    pending_verification: "pending_verification",
    update_pending_verification: "update_pending_verification",
    verified: "verified",
    falsified: "falsified",
    no_match: "no_match",
    verifying: "verifying",
    requires_attention: "requires_attention",
    not_performed: "not_performed",
    skipped: "skipped",
  } as const;

export type SanctionsScreeningReviewRecordGetResponseEntitySetupNaturalPersonSubmissionAnyOfIdentityDocumentSubmissionsItemApiBackFileId =
  string | null;

export type SanctionsScreeningReviewRecordGetResponseEntitySetupNaturalPersonSubmissionAnyOfIdentityDocumentSubmissionsItem =
  {
    api_back_file_id: SanctionsScreeningReviewRecordGetResponseEntitySetupNaturalPersonSubmissionAnyOfIdentityDocumentSubmissionsItemApiBackFileId;
    api_file_id: string;
    created_at: string;
    group_id: string;
    id: string;
    identity_verification_status: SanctionsScreeningReviewRecordGetResponseEntitySetupNaturalPersonSubmissionAnyOfIdentityDocumentSubmissionsItemIdentityVerificationStatus;
    submitted_address: SanctionsScreeningReviewRecordGetResponseEntitySetupNaturalPersonSubmissionAnyOfIdentityDocumentSubmissionsItemSubmittedAddress;
    submitted_country: string;
    submitted_date_of_birth: string;
    submitted_document_number: SanctionsScreeningReviewRecordGetResponseEntitySetupNaturalPersonSubmissionAnyOfIdentityDocumentSubmissionsItemSubmittedDocumentNumber;
    submitted_document_type: SanctionsScreeningReviewRecordGetResponseEntitySetupNaturalPersonSubmissionAnyOfIdentityDocumentSubmissionsItemSubmittedDocumentType;
    submitted_expiration_date: SanctionsScreeningReviewRecordGetResponseEntitySetupNaturalPersonSubmissionAnyOfIdentityDocumentSubmissionsItemSubmittedExpirationDate;
    submitted_name: string;
    submitted_state: SanctionsScreeningReviewRecordGetResponseEntitySetupNaturalPersonSubmissionAnyOfIdentityDocumentSubmissionsItemSubmittedState;
  };

export type SanctionsScreeningReviewRecordGetResponseEntitySetupCorporationSubmission =
  SanctionsScreeningReviewRecordGetResponseEntitySetupCorporationSubmissionAnyOf | null;

export type SanctionsScreeningReviewRecordGetResponseEntitySetupCorporationSubmissionAnyOfTaxIdValidationStatus =
  (typeof SanctionsScreeningReviewRecordGetResponseEntitySetupCorporationSubmissionAnyOfTaxIdValidationStatus)[keyof typeof SanctionsScreeningReviewRecordGetResponseEntitySetupCorporationSubmissionAnyOfTaxIdValidationStatus];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SanctionsScreeningReviewRecordGetResponseEntitySetupCorporationSubmissionAnyOfTaxIdValidationStatus =
  {
    pending_validating: "pending_validating",
    update_pending_validating: "update_pending_validating",
    requires_attention: "requires_attention",
    failed: "failed",
    validated: "validated",
    not_provided: "not_provided",
    skipped: "skipped",
  } as const;

export type SanctionsScreeningReviewRecordGetResponseEntitySetupCorporationSubmissionAnyOfSubmittedWebsite =
  string | null;

export type SanctionsScreeningReviewRecordGetResponseEntitySetupCorporationSubmissionAnyOfSubmittedTaxId =
  string | null;

export type SanctionsScreeningReviewRecordGetResponseEntitySetupCorporationSubmissionAnyOfSubmittedState =
  string | null;

export type SanctionsScreeningReviewRecordGetResponseEntitySetupCorporationSubmissionAnyOfSubmittedAddressLine2 =
  string | null;

export type SanctionsScreeningReviewRecordGetResponseEntitySetupCorporationSubmissionAnyOfSubmittedAddress =
  {
    city: string;
    line1: string;
    line2: SanctionsScreeningReviewRecordGetResponseEntitySetupCorporationSubmissionAnyOfSubmittedAddressLine2;
    state: string;
    zip: string;
  };

export type SanctionsScreeningReviewRecordGetResponseEntitySetupCorporationSubmissionAnyOfSubmissionSovosResponseId =
  string | null;

export type SanctionsScreeningReviewRecordGetResponseEntitySetupCorporationSubmissionAnyOfIndustryCode =

    | "11"
    | "111"
    | "1111"
    | "11111"
    | "111110"
    | "11112"
    | "111120"
    | "11113"
    | "111130"
    | "11114"
    | "111140"
    | "11115"
    | "111150"
    | "11116"
    | "111160"
    | "11119"
    | "111191"
    | "111199"
    | "1112"
    | "11121"
    | "111211"
    | "111219"
    | "1113"
    | "11131"
    | "111310"
    | "11132"
    | "111320"
    | "11133"
    | "111331"
    | "111332"
    | "111333"
    | "111334"
    | "111335"
    | "111336"
    | "111339"
    | "1114"
    | "11141"
    | "111411"
    | "111419"
    | "11142"
    | "111421"
    | "111422"
    | "1119"
    | "11191"
    | "111910"
    | "11192"
    | "111920"
    | "11193"
    | "111930"
    | "11194"
    | "111940"
    | "11199"
    | "111991"
    | "111992"
    | "111998"
    | "112"
    | "1121"
    | "11211"
    | "112111"
    | "112112"
    | "11212"
    | "112120"
    | "11213"
    | "112130"
    | "1122"
    | "11221"
    | "112210"
    | "1123"
    | "11231"
    | "112310"
    | "11232"
    | "112320"
    | "11233"
    | "112330"
    | "11234"
    | "112340"
    | "11239"
    | "112390"
    | "1124"
    | "11241"
    | "112410"
    | "11242"
    | "112420"
    | "1125"
    | "11251"
    | "112511"
    | "112512"
    | "112519"
    | "1129"
    | "11291"
    | "112910"
    | "11292"
    | "112920"
    | "11293"
    | "112930"
    | "11299"
    | "112990"
    | "113"
    | "1131"
    | "11311"
    | "113110"
    | "1132"
    | "11321"
    | "113210"
    | "1133"
    | "11331"
    | "113310"
    | "114"
    | "1141"
    | "11411"
    | "114111"
    | "114112"
    | "114119"
    | "1142"
    | "11421"
    | "114210"
    | "115"
    | "1151"
    | "11511"
    | "115111"
    | "115112"
    | "115113"
    | "115114"
    | "115115"
    | "115116"
    | "1152"
    | "11521"
    | "115210"
    | "1153"
    | "11531"
    | "115310"
    | "21"
    | "211"
    | "2111"
    | "21112"
    | "211120"
    | "21113"
    | "211130"
    | "212"
    | "2121"
    | "21211"
    | "212114"
    | "212115"
    | "2122"
    | "21221"
    | "212210"
    | "21222"
    | "212220"
    | "21223"
    | "212230"
    | "21229"
    | "212290"
    | "2123"
    | "21231"
    | "212311"
    | "212312"
    | "212313"
    | "212319"
    | "21232"
    | "212321"
    | "212322"
    | "212323"
    | "21239"
    | "212390"
    | "213"
    | "2131"
    | "21311"
    | "213111"
    | "213112"
    | "213113"
    | "213114"
    | "213115"
    | "22"
    | "221"
    | "2211"
    | "22111"
    | "221111"
    | "221112"
    | "221113"
    | "221114"
    | "221115"
    | "221116"
    | "221117"
    | "221118"
    | "22112"
    | "221121"
    | "221122"
    | "2212"
    | "22121"
    | "221210"
    | "2213"
    | "22131"
    | "221310"
    | "22132"
    | "221320"
    | "22133"
    | "221330"
    | "23"
    | "236"
    | "2361"
    | "23611"
    | "236115"
    | "236116"
    | "236117"
    | "236118"
    | "2362"
    | "23621"
    | "236210"
    | "23622"
    | "236220"
    | "237"
    | "2371"
    | "23711"
    | "237110"
    | "23712"
    | "237120"
    | "23713"
    | "237130"
    | "2372"
    | "23721"
    | "237210"
    | "2373"
    | "23731"
    | "237310"
    | "2379"
    | "23799"
    | "237990"
    | "238"
    | "2381"
    | "23811"
    | "238110"
    | "23812"
    | "238120"
    | "23813"
    | "238130"
    | "23814"
    | "238140"
    | "23815"
    | "238150"
    | "23816"
    | "238160"
    | "23817"
    | "238170"
    | "23819"
    | "238190"
    | "2382"
    | "23821"
    | "238210"
    | "23822"
    | "238220"
    | "23829"
    | "238290"
    | "2383"
    | "23831"
    | "238310"
    | "23832"
    | "238320"
    | "23833"
    | "238330"
    | "23834"
    | "238340"
    | "23835"
    | "238350"
    | "23839"
    | "238390"
    | "2389"
    | "23891"
    | "238910"
    | "23899"
    | "238990"
    | "31-33"
    | "311"
    | "3111"
    | "31111"
    | "311111"
    | "311119"
    | "3112"
    | "31121"
    | "311211"
    | "311212"
    | "311213"
    | "31122"
    | "311221"
    | "311224"
    | "311225"
    | "31123"
    | "311230"
    | "3113"
    | "31131"
    | "311313"
    | "311314"
    | "31134"
    | "311340"
    | "31135"
    | "311351"
    | "311352"
    | "3114"
    | "31141"
    | "311411"
    | "311412"
    | "31142"
    | "311421"
    | "311422"
    | "311423"
    | "3115"
    | "31151"
    | "311511"
    | "311512"
    | "311513"
    | "311514"
    | "31152"
    | "311520"
    | "3116"
    | "31161"
    | "311611"
    | "311612"
    | "311613"
    | "311615"
    | "3117"
    | "31171"
    | "311710"
    | "3118"
    | "31181"
    | "311811"
    | "311812"
    | "311813"
    | "31182"
    | "311821"
    | "311824"
    | "31183"
    | "311830"
    | "3119"
    | "31191"
    | "311911"
    | "311919"
    | "31192"
    | "311920"
    | "31193"
    | "311930"
    | "31194"
    | "311941"
    | "311942"
    | "31199"
    | "311991"
    | "311999"
    | "312"
    | "3121"
    | "31211"
    | "312111"
    | "312112"
    | "312113"
    | "31212"
    | "312120"
    | "31213"
    | "312130"
    | "31214"
    | "312140"
    | "3122"
    | "31223"
    | "312230"
    | "313"
    | "3131"
    | "31311"
    | "313110"
    | "3132"
    | "31321"
    | "313210"
    | "31322"
    | "313220"
    | "31323"
    | "313230"
    | "31324"
    | "313240"
    | "3133"
    | "31331"
    | "313310"
    | "31332"
    | "313320"
    | "314"
    | "3141"
    | "31411"
    | "314110"
    | "31412"
    | "314120"
    | "3149"
    | "31491"
    | "314910"
    | "31499"
    | "314994"
    | "314999"
    | "315"
    | "3151"
    | "31512"
    | "315120"
    | "3152"
    | "31521"
    | "315210"
    | "31525"
    | "315250"
    | "3159"
    | "31599"
    | "315990"
    | "316"
    | "3161"
    | "31611"
    | "316110"
    | "3162"
    | "31621"
    | "316210"
    | "3169"
    | "31699"
    | "316990"
    | "321"
    | "3211"
    | "32111"
    | "321113"
    | "321114"
    | "3212"
    | "32121"
    | "321211"
    | "321212"
    | "321215"
    | "321219"
    | "3219"
    | "32191"
    | "321911"
    | "321912"
    | "321918"
    | "32192"
    | "321920"
    | "32199"
    | "321991"
    | "321992"
    | "321999"
    | "322"
    | "3221"
    | "32211"
    | "322110"
    | "32212"
    | "322120"
    | "32213"
    | "322130"
    | "3222"
    | "32221"
    | "322211"
    | "322212"
    | "322219"
    | "32222"
    | "322220"
    | "32223"
    | "322230"
    | "32229"
    | "322291"
    | "322299"
    | "323"
    | "3231"
    | "32311"
    | "323111"
    | "323113"
    | "323117"
    | "32312"
    | "323120"
    | "324"
    | "3241"
    | "32411"
    | "324110"
    | "32412"
    | "324121"
    | "324122"
    | "32419"
    | "324191"
    | "324199"
    | "325"
    | "3251"
    | "32511"
    | "325110"
    | "32512"
    | "325120"
    | "32513"
    | "325130"
    | "32518"
    | "325180"
    | "32519"
    | "325193"
    | "325194"
    | "325199"
    | "3252"
    | "32521"
    | "325211"
    | "325212"
    | "32522"
    | "325220"
    | "3253"
    | "32531"
    | "325311"
    | "325312"
    | "325314"
    | "325315"
    | "32532"
    | "325320"
    | "3254"
    | "32541"
    | "325411"
    | "325412"
    | "325413"
    | "325414"
    | "3255"
    | "32551"
    | "325510"
    | "32552"
    | "325520"
    | "3256"
    | "32561"
    | "325611"
    | "325612"
    | "325613"
    | "32562"
    | "325620"
    | "3259"
    | "32591"
    | "325910"
    | "32592"
    | "325920"
    | "32599"
    | "325991"
    | "325992"
    | "325998"
    | "326"
    | "3261"
    | "32611"
    | "326111"
    | "326112"
    | "326113"
    | "32612"
    | "326121"
    | "326122"
    | "32613"
    | "326130"
    | "32614"
    | "326140"
    | "32615"
    | "326150"
    | "32616"
    | "326160"
    | "32619"
    | "326191"
    | "326199"
    | "3262"
    | "32621"
    | "326211"
    | "326212"
    | "32622"
    | "326220"
    | "32629"
    | "326291"
    | "326299"
    | "327"
    | "3271"
    | "32711"
    | "327110"
    | "32712"
    | "327120"
    | "3272"
    | "32721"
    | "327211"
    | "327212"
    | "327213"
    | "327215"
    | "3273"
    | "32731"
    | "327310"
    | "32732"
    | "327320"
    | "32733"
    | "327331"
    | "327332"
    | "32739"
    | "327390"
    | "3274"
    | "32741"
    | "327410"
    | "32742"
    | "327420"
    | "3279"
    | "32791"
    | "327910"
    | "32799"
    | "327991"
    | "327992"
    | "327993"
    | "327999"
    | "331"
    | "3311"
    | "33111"
    | "331110"
    | "3312"
    | "33121"
    | "331210"
    | "33122"
    | "331221"
    | "331222"
    | "3313"
    | "33131"
    | "331313"
    | "331314"
    | "331315"
    | "331318"
    | "3314"
    | "33141"
    | "331410"
    | "33142"
    | "331420"
    | "33149"
    | "331491"
    | "331492"
    | "3315"
    | "33151"
    | "331511"
    | "331512"
    | "331513"
    | "33152"
    | "331523"
    | "331524"
    | "331529"
    | "332"
    | "3321"
    | "33211"
    | "332111"
    | "332112"
    | "332114"
    | "332117"
    | "332119"
    | "3322"
    | "33221"
    | "332215"
    | "332216"
    | "3323"
    | "33231"
    | "332311"
    | "332312"
    | "332313"
    | "33232"
    | "332321"
    | "332322"
    | "332323"
    | "3324"
    | "33241"
    | "332410"
    | "33242"
    | "332420"
    | "33243"
    | "332431"
    | "332439"
    | "3325"
    | "33251"
    | "332510"
    | "3326"
    | "33261"
    | "332613"
    | "332618"
    | "3327"
    | "33271"
    | "332710"
    | "33272"
    | "332721"
    | "332722"
    | "3328"
    | "33281"
    | "332811"
    | "332812"
    | "332813"
    | "3329"
    | "33291"
    | "332911"
    | "332912"
    | "332913"
    | "332919"
    | "33299"
    | "332991"
    | "332992"
    | "332993"
    | "332994"
    | "332996"
    | "332999"
    | "333"
    | "3331"
    | "33311"
    | "333111"
    | "333112"
    | "33312"
    | "333120"
    | "33313"
    | "333131"
    | "333132"
    | "3332"
    | "33324"
    | "333241"
    | "333242"
    | "333243"
    | "333248"
    | "3333"
    | "33331"
    | "333310"
    | "3334"
    | "33341"
    | "333413"
    | "333414"
    | "333415"
    | "3335"
    | "33351"
    | "333511"
    | "333514"
    | "333515"
    | "333517"
    | "333519"
    | "3336"
    | "33361"
    | "333611"
    | "333612"
    | "333613"
    | "333618"
    | "3339"
    | "33391"
    | "333912"
    | "333914"
    | "33392"
    | "333921"
    | "333922"
    | "333923"
    | "333924"
    | "33399"
    | "333991"
    | "333992"
    | "333993"
    | "333994"
    | "333995"
    | "333996"
    | "333998"
    | "334"
    | "3341"
    | "33411"
    | "334111"
    | "334112"
    | "334118"
    | "3342"
    | "33421"
    | "334210"
    | "33422"
    | "334220"
    | "33429"
    | "334290"
    | "3343"
    | "33431"
    | "334310"
    | "3344"
    | "33441"
    | "334412"
    | "334413"
    | "334416"
    | "334417"
    | "334418"
    | "334419"
    | "3345"
    | "33451"
    | "334510"
    | "334511"
    | "334512"
    | "334513"
    | "334514"
    | "334515"
    | "334516"
    | "334517"
    | "334519"
    | "3346"
    | "33461"
    | "334610"
    | "335"
    | "3351"
    | "33513"
    | "335131"
    | "335132"
    | "335139"
    | "3352"
    | "33521"
    | "335210"
    | "33522"
    | "335220"
    | "3353"
    | "33531"
    | "335311"
    | "335312"
    | "335313"
    | "335314"
    | "3359"
    | "33591"
    | "335910"
    | "33592"
    | "335921"
    | "335929"
    | "33593"
    | "335931"
    | "335932"
    | "33599"
    | "335991"
    | "335999"
    | "336"
    | "3361"
    | "33611"
    | "336110"
    | "33612"
    | "336120"
    | "3362"
    | "33621"
    | "336211"
    | "336212"
    | "336213"
    | "336214"
    | "3363"
    | "33631"
    | "336310"
    | "33632"
    | "336320"
    | "33633"
    | "336330"
    | "33634"
    | "336340"
    | "33635"
    | "336350"
    | "33636"
    | "336360"
    | "33637"
    | "336370"
    | "33639"
    | "336390"
    | "3364"
    | "33641"
    | "336411"
    | "336412"
    | "336413"
    | "336414"
    | "336415"
    | "336419"
    | "3365"
    | "33651"
    | "336510"
    | "3366"
    | "33661"
    | "336611"
    | "336612"
    | "3369"
    | "33699"
    | "336991"
    | "336992"
    | "336999"
    | "337"
    | "3371"
    | "33711"
    | "337110"
    | "33712"
    | "337121"
    | "337122"
    | "337126"
    | "337127"
    | "3372"
    | "33721"
    | "337211"
    | "337212"
    | "337214"
    | "337215"
    | "3379"
    | "33791"
    | "337910"
    | "33792"
    | "337920"
    | "339"
    | "3391"
    | "33911"
    | "339112"
    | "339113"
    | "339114"
    | "339115"
    | "339116"
    | "3399"
    | "33991"
    | "339910"
    | "33992"
    | "339920"
    | "33993"
    | "339930"
    | "33994"
    | "339940"
    | "33995"
    | "339950"
    | "33999"
    | "339991"
    | "339992"
    | "339993"
    | "339994"
    | "339995"
    | "339999"
    | "42"
    | "423"
    | "4231"
    | "42311"
    | "423110"
    | "42312"
    | "423120"
    | "42313"
    | "423130"
    | "42314"
    | "423140"
    | "4232"
    | "42321"
    | "423210"
    | "42322"
    | "423220"
    | "4233"
    | "42331"
    | "423310"
    | "42332"
    | "423320"
    | "42333"
    | "423330"
    | "42339"
    | "423390"
    | "4234"
    | "42341"
    | "423410"
    | "42342"
    | "423420"
    | "42343"
    | "423430"
    | "42344"
    | "423440"
    | "42345"
    | "423450"
    | "42346"
    | "423460"
    | "42349"
    | "423490"
    | "4235"
    | "42351"
    | "423510"
    | "42352"
    | "423520"
    | "4236"
    | "42361"
    | "423610"
    | "42362"
    | "423620"
    | "42369"
    | "423690"
    | "4237"
    | "42371"
    | "423710"
    | "42372"
    | "423720"
    | "42373"
    | "423730"
    | "42374"
    | "423740"
    | "4238"
    | "42381"
    | "423810"
    | "42382"
    | "423820"
    | "42383"
    | "423830"
    | "42384"
    | "423840"
    | "42385"
    | "423850"
    | "42386"
    | "423860"
    | "4239"
    | "42391"
    | "423910"
    | "42392"
    | "423920"
    | "42393"
    | "423930"
    | "42394"
    | "423940"
    | "42399"
    | "423990"
    | "424"
    | "4241"
    | "42411"
    | "424110"
    | "42412"
    | "424120"
    | "42413"
    | "424130"
    | "4242"
    | "42421"
    | "424210"
    | "4243"
    | "42431"
    | "424310"
    | "42434"
    | "424340"
    | "42435"
    | "424350"
    | "4244"
    | "42441"
    | "424410"
    | "42442"
    | "424420"
    | "42443"
    | "424430"
    | "42444"
    | "424440"
    | "42445"
    | "424450"
    | "42446"
    | "424460"
    | "42447"
    | "424470"
    | "42448"
    | "424480"
    | "42449"
    | "424490"
    | "4245"
    | "42451"
    | "424510"
    | "42452"
    | "424520"
    | "42459"
    | "424590"
    | "4246"
    | "42461"
    | "424610"
    | "42469"
    | "424690"
    | "4247"
    | "42471"
    | "424710"
    | "42472"
    | "424720"
    | "4248"
    | "42481"
    | "424810"
    | "42482"
    | "424820"
    | "4249"
    | "42491"
    | "424910"
    | "42492"
    | "424920"
    | "42493"
    | "424930"
    | "42494"
    | "424940"
    | "42495"
    | "424950"
    | "42499"
    | "424990"
    | "425"
    | "4251"
    | "42512"
    | "425120"
    | "44-45"
    | "441"
    | "4411"
    | "44111"
    | "441110"
    | "44112"
    | "441120"
    | "4412"
    | "44121"
    | "441210"
    | "44122"
    | "441222"
    | "441227"
    | "4413"
    | "44133"
    | "441330"
    | "44134"
    | "441340"
    | "444"
    | "4441"
    | "44411"
    | "444110"
    | "44412"
    | "444120"
    | "44414"
    | "444140"
    | "44418"
    | "444180"
    | "4442"
    | "44423"
    | "444230"
    | "44424"
    | "444240"
    | "445"
    | "4451"
    | "44511"
    | "445110"
    | "44513"
    | "445131"
    | "445132"
    | "4452"
    | "44523"
    | "445230"
    | "44524"
    | "445240"
    | "44525"
    | "445250"
    | "44529"
    | "445291"
    | "445292"
    | "445298"
    | "4453"
    | "44532"
    | "445320"
    | "449"
    | "4491"
    | "44911"
    | "449110"
    | "44912"
    | "449121"
    | "449122"
    | "449129"
    | "4492"
    | "44921"
    | "449210"
    | "455"
    | "4551"
    | "45511"
    | "455110"
    | "4552"
    | "45521"
    | "455211"
    | "455219"
    | "456"
    | "4561"
    | "45611"
    | "456110"
    | "45612"
    | "456120"
    | "45613"
    | "456130"
    | "45619"
    | "456191"
    | "456199"
    | "457"
    | "4571"
    | "45711"
    | "457110"
    | "45712"
    | "457120"
    | "4572"
    | "45721"
    | "457210"
    | "458"
    | "4581"
    | "45811"
    | "458110"
    | "4582"
    | "45821"
    | "458210"
    | "4583"
    | "45831"
    | "458310"
    | "45832"
    | "458320"
    | "459"
    | "4591"
    | "45911"
    | "459110"
    | "45912"
    | "459120"
    | "45913"
    | "459130"
    | "45914"
    | "459140"
    | "4592"
    | "45921"
    | "459210"
    | "4593"
    | "45931"
    | "459310"
    | "4594"
    | "45941"
    | "459410"
    | "45942"
    | "459420"
    | "4595"
    | "45951"
    | "459510"
    | "4599"
    | "45991"
    | "459910"
    | "45992"
    | "459920"
    | "45993"
    | "459930"
    | "45999"
    | "459991"
    | "459999"
    | "48-49"
    | "481"
    | "4811"
    | "48111"
    | "481111"
    | "481112"
    | "4812"
    | "48121"
    | "481211"
    | "481212"
    | "481219"
    | "482"
    | "4821"
    | "48211"
    | "482111"
    | "482112"
    | "483"
    | "4831"
    | "48311"
    | "483111"
    | "483112"
    | "483113"
    | "483114"
    | "4832"
    | "48321"
    | "483211"
    | "483212"
    | "484"
    | "4841"
    | "48411"
    | "484110"
    | "48412"
    | "484121"
    | "484122"
    | "4842"
    | "48421"
    | "484210"
    | "48422"
    | "484220"
    | "48423"
    | "484230"
    | "485"
    | "4851"
    | "48511"
    | "485111"
    | "485112"
    | "485113"
    | "485119"
    | "4852"
    | "48521"
    | "485210"
    | "4853"
    | "48531"
    | "485310"
    | "48532"
    | "485320"
    | "4854"
    | "48541"
    | "485410"
    | "4855"
    | "48551"
    | "485510"
    | "4859"
    | "48599"
    | "485991"
    | "485999"
    | "486"
    | "4861"
    | "48611"
    | "486110"
    | "4862"
    | "48621"
    | "486210"
    | "4869"
    | "48691"
    | "486910"
    | "48699"
    | "486990"
    | "487"
    | "4871"
    | "48711"
    | "487110"
    | "4872"
    | "48721"
    | "487210"
    | "4879"
    | "48799"
    | "487990"
    | "488"
    | "4881"
    | "48811"
    | "488111"
    | "488119"
    | "48819"
    | "488190"
    | "4882"
    | "48821"
    | "488210"
    | "4883"
    | "48831"
    | "488310"
    | "48832"
    | "488320"
    | "48833"
    | "488330"
    | "48839"
    | "488390"
    | "4884"
    | "48841"
    | "488410"
    | "48849"
    | "488490"
    | "4885"
    | "48851"
    | "488510"
    | "4889"
    | "48899"
    | "488991"
    | "488999"
    | "491"
    | "4911"
    | "49111"
    | "491110"
    | "492"
    | "4921"
    | "49211"
    | "492110"
    | "4922"
    | "49221"
    | "492210"
    | "493"
    | "4931"
    | "49311"
    | "493110"
    | "49312"
    | "493120"
    | "49313"
    | "493130"
    | "49319"
    | "493190"
    | "51"
    | "512"
    | "5121"
    | "51211"
    | "512110"
    | "51212"
    | "512120"
    | "51213"
    | "512131"
    | "512132"
    | "51219"
    | "512191"
    | "512199"
    | "5122"
    | "51223"
    | "512230"
    | "51224"
    | "512240"
    | "51225"
    | "512250"
    | "51229"
    | "512290"
    | "513"
    | "5131"
    | "51311"
    | "513110"
    | "51312"
    | "513120"
    | "51313"
    | "513130"
    | "51314"
    | "513140"
    | "51319"
    | "513191"
    | "513199"
    | "5132"
    | "5112"
    | "51321"
    | "513210"
    | "516"
    | "5161"
    | "51611"
    | "516110"
    | "51612"
    | "516120"
    | "5162"
    | "51621"
    | "516210"
    | "517"
    | "5171"
    | "51711"
    | "517111"
    | "517112"
    | "51712"
    | "517121"
    | "517122"
    | "5174"
    | "51741"
    | "517410"
    | "5178"
    | "51781"
    | "517810"
    | "518"
    | "5182"
    | "51821"
    | "518210"
    | "519"
    | "5192"
    | "51921"
    | "519210"
    | "51929"
    | "519290"
    | "52"
    | "521"
    | "5211"
    | "52111"
    | "521110"
    | "522"
    | "5221"
    | "52211"
    | "522110"
    | "52213"
    | "522130"
    | "52218"
    | "522180"
    | "5222"
    | "52221"
    | "522210"
    | "52222"
    | "522220"
    | "52229"
    | "522291"
    | "522292"
    | "522299"
    | "5223"
    | "52231"
    | "522310"
    | "52232"
    | "522320"
    | "52239"
    | "522390"
    | "523"
    | "5231"
    | "52315"
    | "523150"
    | "52316"
    | "523160"
    | "5232"
    | "52321"
    | "523210"
    | "5239"
    | "52391"
    | "523910"
    | "52394"
    | "523940"
    | "52399"
    | "523991"
    | "523999"
    | "524"
    | "5241"
    | "52411"
    | "524113"
    | "524114"
    | "52412"
    | "524126"
    | "524127"
    | "524128"
    | "52413"
    | "524130"
    | "5242"
    | "52421"
    | "524210"
    | "52429"
    | "524291"
    | "524292"
    | "524298"
    | "525"
    | "5251"
    | "52511"
    | "525110"
    | "52512"
    | "525120"
    | "52519"
    | "525190"
    | "5259"
    | "52591"
    | "525910"
    | "52592"
    | "525920"
    | "52599"
    | "525990"
    | "53"
    | "531"
    | "5311"
    | "53111"
    | "531110"
    | "53112"
    | "531120"
    | "53113"
    | "531130"
    | "53119"
    | "531190"
    | "5312"
    | "53121"
    | "531210"
    | "5313"
    | "53131"
    | "531311"
    | "531312"
    | "53132"
    | "531320"
    | "53139"
    | "531390"
    | "532"
    | "5321"
    | "53211"
    | "532111"
    | "532112"
    | "53212"
    | "532120"
    | "5322"
    | "53221"
    | "532210"
    | "53228"
    | "532281"
    | "532282"
    | "532283"
    | "532284"
    | "532289"
    | "5323"
    | "53231"
    | "532310"
    | "5324"
    | "53241"
    | "532411"
    | "532412"
    | "53242"
    | "532420"
    | "53249"
    | "532490"
    | "533"
    | "5331"
    | "53311"
    | "533110"
    | "54"
    | "541"
    | "5411"
    | "54111"
    | "541110"
    | "54112"
    | "541120"
    | "54119"
    | "541191"
    | "541199"
    | "5412"
    | "54121"
    | "541211"
    | "541213"
    | "541214"
    | "541219"
    | "5413"
    | "54131"
    | "541310"
    | "54132"
    | "541320"
    | "54133"
    | "541330"
    | "54134"
    | "541340"
    | "54135"
    | "541350"
    | "54136"
    | "541360"
    | "54137"
    | "541370"
    | "54138"
    | "541380"
    | "5414"
    | "54141"
    | "541410"
    | "54142"
    | "541420"
    | "54143"
    | "541430"
    | "54149"
    | "541490"
    | "5415"
    | "54151"
    | "541511"
    | "541512"
    | "541513"
    | "541519"
    | "5416"
    | "54161"
    | "541611"
    | "541612"
    | "541613"
    | "541614"
    | "541618"
    | "54162"
    | "541620"
    | "54169"
    | "541690"
    | "5417"
    | "54171"
    | "541713"
    | "541714"
    | "541715"
    | "54172"
    | "541720"
    | "5418"
    | "54181"
    | "541810"
    | "54182"
    | "541820"
    | "54183"
    | "541830"
    | "54184"
    | "541840"
    | "54185"
    | "541850"
    | "54186"
    | "541860"
    | "54187"
    | "541870"
    | "54189"
    | "541890"
    | "5419"
    | "54191"
    | "541910"
    | "54192"
    | "541921"
    | "541922"
    | "54193"
    | "541930"
    | "54194"
    | "541940"
    | "54199"
    | "541990"
    | "55"
    | "551"
    | "5511"
    | "55111"
    | "551111"
    | "551112"
    | "551114"
    | "56"
    | "561"
    | "5611"
    | "56111"
    | "561110"
    | "5612"
    | "56121"
    | "561210"
    | "5613"
    | "56131"
    | "561311"
    | "561312"
    | "56132"
    | "561320"
    | "56133"
    | "561330"
    | "5614"
    | "56141"
    | "561410"
    | "56142"
    | "561421"
    | "561422"
    | "56143"
    | "561431"
    | "561439"
    | "56144"
    | "561440"
    | "56145"
    | "561450"
    | "56149"
    | "561491"
    | "561492"
    | "561499"
    | "5615"
    | "56151"
    | "561510"
    | "56152"
    | "561520"
    | "56159"
    | "561591"
    | "561599"
    | "5616"
    | "56161"
    | "561611"
    | "561612"
    | "561613"
    | "56162"
    | "561621"
    | "561622"
    | "5617"
    | "56171"
    | "561710"
    | "56172"
    | "561720"
    | "56173"
    | "561730"
    | "56174"
    | "561740"
    | "56179"
    | "561790"
    | "5619"
    | "56191"
    | "561910"
    | "56192"
    | "561920"
    | "56199"
    | "561990"
    | "562"
    | "5621"
    | "56211"
    | "562111"
    | "562112"
    | "562119"
    | "5622"
    | "56221"
    | "562211"
    | "562212"
    | "562213"
    | "562219"
    | "5629"
    | "56291"
    | "562910"
    | "56292"
    | "562920"
    | "56299"
    | "562991"
    | "562998"
    | "61"
    | "611"
    | "6111"
    | "61111"
    | "611110"
    | "6112"
    | "61121"
    | "611210"
    | "6113"
    | "61131"
    | "611310"
    | "6114"
    | "61141"
    | "611410"
    | "61142"
    | "611420"
    | "61143"
    | "611430"
    | "6115"
    | "61151"
    | "611511"
    | "611512"
    | "611513"
    | "611519"
    | "6116"
    | "61161"
    | "611610"
    | "61162"
    | "611620"
    | "61163"
    | "611630"
    | "61169"
    | "611691"
    | "611692"
    | "611699"
    | "6117"
    | "61171"
    | "611710"
    | "62"
    | "621"
    | "6211"
    | "62111"
    | "621111"
    | "621112"
    | "6212"
    | "62121"
    | "621210"
    | "6213"
    | "62131"
    | "621310"
    | "62132"
    | "621320"
    | "62133"
    | "621330"
    | "62134"
    | "621340"
    | "62139"
    | "621391"
    | "621399"
    | "6214"
    | "62141"
    | "621410"
    | "62142"
    | "621420"
    | "62149"
    | "621491"
    | "621492"
    | "621493"
    | "621498"
    | "6215"
    | "62151"
    | "621511"
    | "621512"
    | "6216"
    | "62161"
    | "621610"
    | "6219"
    | "62191"
    | "621910"
    | "62199"
    | "621991"
    | "621999"
    | "622"
    | "6221"
    | "62211"
    | "622110"
    | "6222"
    | "62221"
    | "622210"
    | "6223"
    | "62231"
    | "622310"
    | "623"
    | "6231"
    | "62311"
    | "623110"
    | "6232"
    | "62321"
    | "623210"
    | "62322"
    | "623220"
    | "6233"
    | "62331"
    | "623311"
    | "623312"
    | "6239"
    | "62399"
    | "623990"
    | "624"
    | "6241"
    | "62411"
    | "624110"
    | "62412"
    | "624120"
    | "62419"
    | "624190"
    | "6242"
    | "62421"
    | "624210"
    | "62422"
    | "624221"
    | "624229"
    | "62423"
    | "624230"
    | "6243"
    | "62431"
    | "624310"
    | "6244"
    | "62441"
    | "624410"
    | "71"
    | "711"
    | "7111"
    | "71111"
    | "711110"
    | "71112"
    | "711120"
    | "71113"
    | "711130"
    | "71119"
    | "711190"
    | "7112"
    | "71121"
    | "711211"
    | "711212"
    | "711219"
    | "7113"
    | "71131"
    | "711310"
    | "71132"
    | "711320"
    | "7114"
    | "71141"
    | "711410"
    | "7115"
    | "71151"
    | "711510"
    | "712"
    | "7121"
    | "71211"
    | "712110"
    | "71212"
    | "712120"
    | "71213"
    | "712130"
    | "71219"
    | "712190"
    | "713"
    | "7131"
    | "71311"
    | "713110"
    | "71312"
    | "713120"
    | "7132"
    | "71321"
    | "713210"
    | "71329"
    | "713290"
    | "7139"
    | "71391"
    | "713910"
    | "71392"
    | "713920"
    | "71393"
    | "713930"
    | "71394"
    | "713940"
    | "71395"
    | "713950"
    | "71399"
    | "713990"
    | "72"
    | "721"
    | "7211"
    | "72111"
    | "721110"
    | "72112"
    | "721120"
    | "72119"
    | "721191"
    | "721199"
    | "7212"
    | "72121"
    | "721211"
    | "721214"
    | "7213"
    | "72131"
    | "721310"
    | "722"
    | "7223"
    | "72231"
    | "722310"
    | "72232"
    | "722320"
    | "72233"
    | "722330"
    | "7224"
    | "72241"
    | "722410"
    | "7225"
    | "72251"
    | "722511"
    | "722513"
    | "722514"
    | "722515"
    | "81"
    | "811"
    | "8111"
    | "81111"
    | "811111"
    | "811114"
    | "81112"
    | "811121"
    | "811122"
    | "81119"
    | "811191"
    | "811192"
    | "811198"
    | "8112"
    | "81121"
    | "811210"
    | "8113"
    | "81131"
    | "811310"
    | "8114"
    | "81141"
    | "811411"
    | "811412"
    | "81142"
    | "811420"
    | "81143"
    | "811430"
    | "81149"
    | "811490"
    | "812"
    | "8121"
    | "81211"
    | "812111"
    | "812112"
    | "812113"
    | "81219"
    | "812191"
    | "812199"
    | "8122"
    | "81221"
    | "812210"
    | "81222"
    | "812220"
    | "8123"
    | "81231"
    | "812310"
    | "81232"
    | "812320"
    | "81233"
    | "812331"
    | "812332"
    | "8129"
    | "81291"
    | "812910"
    | "81292"
    | "812921"
    | "812922"
    | "81293"
    | "812930"
    | "81299"
    | "812990"
    | "813"
    | "8131"
    | "81311"
    | "813110"
    | "8132"
    | "81321"
    | "813211"
    | "813212"
    | "813219"
    | "8133"
    | "81331"
    | "813311"
    | "813312"
    | "813319"
    | "8134"
    | "81341"
    | "813410"
    | "8139"
    | "81391"
    | "813910"
    | "81392"
    | "813920"
    | "81393"
    | "813930"
    | "81394"
    | "813940"
    | "81399"
    | "813990"
    | "814"
    | "8141"
    | "81411"
    | "814110"
    | "92"
    | "921"
    | "9211"
    | "92111"
    | "921110"
    | "92112"
    | "921120"
    | "92113"
    | "921130"
    | "92114"
    | "921140"
    | "92115"
    | "921150"
    | "92119"
    | "921190"
    | "922"
    | "9221"
    | "92211"
    | "922110"
    | "92212"
    | "922120"
    | "92213"
    | "922130"
    | "92214"
    | "922140"
    | "92215"
    | "922150"
    | "92216"
    | "922160"
    | "92219"
    | "922190"
    | "923"
    | "9231"
    | "92311"
    | "923110"
    | "92312"
    | "923120"
    | "92313"
    | "923130"
    | "92314"
    | "923140"
    | "924"
    | "9241"
    | "92411"
    | "924110"
    | "92412"
    | "924120"
    | "925"
    | "9251"
    | "92511"
    | "925110"
    | "92512"
    | "925120"
    | "926"
    | "9261"
    | "92611"
    | "926110"
    | "92612"
    | "926120"
    | "92613"
    | "926130"
    | "92614"
    | "926140"
    | "92615"
    | "926150"
    | "927"
    | "9271"
    | "92711"
    | "927110"
    | "928"
    | "9281"
    | "92811"
    | "928110"
    | "92812"
    | "928120"
    | "999300"
    | null;

export type SanctionsScreeningReviewRecordGetResponseEntitySetupCorporationSubmissionAnyOfEntitySetupCorporationBeneficialOwnerRelationshipsItemSubmittedCompanyTitle =
  string | null;

export type SanctionsScreeningReviewRecordGetResponseEntitySetupCorporationSubmissionAnyOfEntitySetupCorporationBeneficialOwnerRelationshipsItemProng =
  (typeof SanctionsScreeningReviewRecordGetResponseEntitySetupCorporationSubmissionAnyOfEntitySetupCorporationBeneficialOwnerRelationshipsItemProng)[keyof typeof SanctionsScreeningReviewRecordGetResponseEntitySetupCorporationSubmissionAnyOfEntitySetupCorporationBeneficialOwnerRelationshipsItemProng];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SanctionsScreeningReviewRecordGetResponseEntitySetupCorporationSubmissionAnyOfEntitySetupCorporationBeneficialOwnerRelationshipsItemProng =
  {
    ownership: "ownership",
    control: "control",
  } as const;

export type SanctionsScreeningReviewRecordGetResponseEntitySetupCorporationSubmissionAnyOfEntitySetupCorporationBeneficialOwnerRelationshipsItem =
  {
    archived: boolean;
    entity_setup_beneficial_owner_submission: SanctionsScreeningReviewRecordGetResponseEntitySetupCorporationSubmissionAnyOfEntitySetupCorporationBeneficialOwnerRelationshipsItemEntitySetupBeneficialOwnerSubmission;
    entity_setup_corporation_submission_id: string;
    id: string;
    prong: SanctionsScreeningReviewRecordGetResponseEntitySetupCorporationSubmissionAnyOfEntitySetupCorporationBeneficialOwnerRelationshipsItemProng;
    submitted_company_title: SanctionsScreeningReviewRecordGetResponseEntitySetupCorporationSubmissionAnyOfEntitySetupCorporationBeneficialOwnerRelationshipsItemSubmittedCompanyTitle;
  };

export type SanctionsScreeningReviewRecordGetResponseEntitySetupCorporationSubmissionAnyOf =
  {
    beneficial_ownership_exemption: SanctionsScreeningReviewRecordGetResponseEntitySetupCorporationSubmissionAnyOfBeneficialOwnershipExemption;
    cobalt_intelligence_secretary_of_state_search_intents: SanctionsScreeningReviewRecordGetResponseEntitySetupCorporationSubmissionAnyOfCobaltIntelligenceSecretaryOfStateSearchIntentsItem[];
    created_at: string;
    entity_setup_corporation_beneficial_owner_relationships: SanctionsScreeningReviewRecordGetResponseEntitySetupCorporationSubmissionAnyOfEntitySetupCorporationBeneficialOwnerRelationshipsItem[];
    id: string;
    industry_code: SanctionsScreeningReviewRecordGetResponseEntitySetupCorporationSubmissionAnyOfIndustryCode;
    submission_sovos_response_id: SanctionsScreeningReviewRecordGetResponseEntitySetupCorporationSubmissionAnyOfSubmissionSovosResponseId;
    submitted_address: SanctionsScreeningReviewRecordGetResponseEntitySetupCorporationSubmissionAnyOfSubmittedAddress;
    submitted_name: string;
    submitted_state: SanctionsScreeningReviewRecordGetResponseEntitySetupCorporationSubmissionAnyOfSubmittedState;
    submitted_tax_id: SanctionsScreeningReviewRecordGetResponseEntitySetupCorporationSubmissionAnyOfSubmittedTaxId;
    submitted_website: SanctionsScreeningReviewRecordGetResponseEntitySetupCorporationSubmissionAnyOfSubmittedWebsite;
    tax_id_submitted_at: string;
    tax_id_validation_status: SanctionsScreeningReviewRecordGetResponseEntitySetupCorporationSubmissionAnyOfTaxIdValidationStatus;
  };

export type SanctionsScreeningReviewRecordGetResponseEntitySetupCorporationSubmissionAnyOfEntitySetupCorporationBeneficialOwnerRelationshipsItemEntitySetupBeneficialOwnerSubmissionTaxIdValidationStatus =
  (typeof SanctionsScreeningReviewRecordGetResponseEntitySetupCorporationSubmissionAnyOfEntitySetupCorporationBeneficialOwnerRelationshipsItemEntitySetupBeneficialOwnerSubmissionTaxIdValidationStatus)[keyof typeof SanctionsScreeningReviewRecordGetResponseEntitySetupCorporationSubmissionAnyOfEntitySetupCorporationBeneficialOwnerRelationshipsItemEntitySetupBeneficialOwnerSubmissionTaxIdValidationStatus];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SanctionsScreeningReviewRecordGetResponseEntitySetupCorporationSubmissionAnyOfEntitySetupCorporationBeneficialOwnerRelationshipsItemEntitySetupBeneficialOwnerSubmissionTaxIdValidationStatus =
  {
    pending_validating: "pending_validating",
    update_pending_validating: "update_pending_validating",
    requires_attention: "requires_attention",
    failed: "failed",
    validated: "validated",
    not_provided: "not_provided",
    skipped: "skipped",
  } as const;

export type SanctionsScreeningReviewRecordGetResponseEntitySetupCorporationSubmissionAnyOfEntitySetupCorporationBeneficialOwnerRelationshipsItemEntitySetupBeneficialOwnerSubmissionSubmittedIdentificationNumberLast4 =
  string | null;

export type SanctionsScreeningReviewRecordGetResponseEntitySetupCorporationSubmissionAnyOfEntitySetupCorporationBeneficialOwnerRelationshipsItemEntitySetupBeneficialOwnerSubmissionSubmittedIdentificationMethod =
  (typeof SanctionsScreeningReviewRecordGetResponseEntitySetupCorporationSubmissionAnyOfEntitySetupCorporationBeneficialOwnerRelationshipsItemEntitySetupBeneficialOwnerSubmissionSubmittedIdentificationMethod)[keyof typeof SanctionsScreeningReviewRecordGetResponseEntitySetupCorporationSubmissionAnyOfEntitySetupCorporationBeneficialOwnerRelationshipsItemEntitySetupBeneficialOwnerSubmissionSubmittedIdentificationMethod];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SanctionsScreeningReviewRecordGetResponseEntitySetupCorporationSubmissionAnyOfEntitySetupCorporationBeneficialOwnerRelationshipsItemEntitySetupBeneficialOwnerSubmissionSubmittedIdentificationMethod =
  {
    social_security_number: "social_security_number",
    individual_taxpayer_identification_number:
      "individual_taxpayer_identification_number",
    passport: "passport",
    drivers_license: "drivers_license",
    other: "other",
  } as const;

export type SanctionsScreeningReviewRecordGetResponseEntitySetupCorporationSubmissionAnyOfEntitySetupCorporationBeneficialOwnerRelationshipsItemEntitySetupBeneficialOwnerSubmissionSubmittedAddressLine2 =
  string | null;

export type SanctionsScreeningReviewRecordGetResponseEntitySetupCorporationSubmissionAnyOfEntitySetupCorporationBeneficialOwnerRelationshipsItemEntitySetupBeneficialOwnerSubmissionSubmittedAddress =
  {
    city: string;
    line1: string;
    line2: SanctionsScreeningReviewRecordGetResponseEntitySetupCorporationSubmissionAnyOfEntitySetupCorporationBeneficialOwnerRelationshipsItemEntitySetupBeneficialOwnerSubmissionSubmittedAddressLine2;
    state: string;
    zip: string;
  };

export type SanctionsScreeningReviewRecordGetResponseEntitySetupCorporationSubmissionAnyOfEntitySetupCorporationBeneficialOwnerRelationshipsItemEntitySetupBeneficialOwnerSubmissionSubmissionSovosResponseId =
  string | null;

export type SanctionsScreeningReviewRecordGetResponseEntitySetupCorporationSubmissionAnyOfEntitySetupCorporationBeneficialOwnerRelationshipsItemEntitySetupBeneficialOwnerSubmission =
  {
    created_at: string;
    id: string;
    identity_document_submissions: SanctionsScreeningReviewRecordGetResponseEntitySetupCorporationSubmissionAnyOfEntitySetupCorporationBeneficialOwnerRelationshipsItemEntitySetupBeneficialOwnerSubmissionIdentityDocumentSubmissionsItem[];
    identity_verification_status: SanctionsScreeningReviewRecordGetResponseEntitySetupCorporationSubmissionAnyOfEntitySetupCorporationBeneficialOwnerRelationshipsItemEntitySetupBeneficialOwnerSubmissionIdentityVerificationStatus;
    microbilt: SanctionsScreeningReviewRecordGetResponseEntitySetupCorporationSubmissionAnyOfEntitySetupCorporationBeneficialOwnerRelationshipsItemEntitySetupBeneficialOwnerSubmissionMicrobiltItem[];
    submission_sovos_response_id: SanctionsScreeningReviewRecordGetResponseEntitySetupCorporationSubmissionAnyOfEntitySetupCorporationBeneficialOwnerRelationshipsItemEntitySetupBeneficialOwnerSubmissionSubmissionSovosResponseId;
    submitted_address: SanctionsScreeningReviewRecordGetResponseEntitySetupCorporationSubmissionAnyOfEntitySetupCorporationBeneficialOwnerRelationshipsItemEntitySetupBeneficialOwnerSubmissionSubmittedAddress;
    submitted_date_of_birth: string;
    submitted_identification_method: SanctionsScreeningReviewRecordGetResponseEntitySetupCorporationSubmissionAnyOfEntitySetupCorporationBeneficialOwnerRelationshipsItemEntitySetupBeneficialOwnerSubmissionSubmittedIdentificationMethod;
    submitted_identification_number_last4: SanctionsScreeningReviewRecordGetResponseEntitySetupCorporationSubmissionAnyOfEntitySetupCorporationBeneficialOwnerRelationshipsItemEntitySetupBeneficialOwnerSubmissionSubmittedIdentificationNumberLast4;
    submitted_name: string;
    tax_id_validation_status: SanctionsScreeningReviewRecordGetResponseEntitySetupCorporationSubmissionAnyOfEntitySetupCorporationBeneficialOwnerRelationshipsItemEntitySetupBeneficialOwnerSubmissionTaxIdValidationStatus;
  };

export type SanctionsScreeningReviewRecordGetResponseEntitySetupCorporationSubmissionAnyOfEntitySetupCorporationBeneficialOwnerRelationshipsItemEntitySetupBeneficialOwnerSubmissionMicrobiltItemTaxIdType =
  "SSN" | null;

export type SanctionsScreeningReviewRecordGetResponseEntitySetupCorporationSubmissionAnyOfEntitySetupCorporationBeneficialOwnerRelationshipsItemEntitySetupBeneficialOwnerSubmissionMicrobiltItemTaxIdLast4 =
  string | null;

export type SanctionsScreeningReviewRecordGetResponseEntitySetupCorporationSubmissionAnyOfEntitySetupCorporationBeneficialOwnerRelationshipsItemEntitySetupBeneficialOwnerSubmissionMicrobiltItemReturnCode =
  (typeof SanctionsScreeningReviewRecordGetResponseEntitySetupCorporationSubmissionAnyOfEntitySetupCorporationBeneficialOwnerRelationshipsItemEntitySetupBeneficialOwnerSubmissionMicrobiltItemReturnCode)[keyof typeof SanctionsScreeningReviewRecordGetResponseEntitySetupCorporationSubmissionAnyOfEntitySetupCorporationBeneficialOwnerRelationshipsItemEntitySetupBeneficialOwnerSubmissionMicrobiltItemReturnCode];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SanctionsScreeningReviewRecordGetResponseEntitySetupCorporationSubmissionAnyOfEntitySetupCorporationBeneficialOwnerRelationshipsItemEntitySetupBeneficialOwnerSubmissionMicrobiltItemReturnCode =
  {
    OK: "OK",
  } as const;

export type SanctionsScreeningReviewRecordGetResponseEntitySetupCorporationSubmissionAnyOfEntitySetupCorporationBeneficialOwnerRelationshipsItemEntitySetupBeneficialOwnerSubmissionMicrobiltItemMiddleName =
  string | null;

export type SanctionsScreeningReviewRecordGetResponseEntitySetupCorporationSubmissionAnyOfEntitySetupCorporationBeneficialOwnerRelationshipsItemEntitySetupBeneficialOwnerSubmissionMicrobiltItemDeathDate =
  string | null;

export type SanctionsScreeningReviewRecordGetResponseEntitySetupCorporationSubmissionAnyOfEntitySetupCorporationBeneficialOwnerRelationshipsItemEntitySetupBeneficialOwnerSubmissionMicrobiltItemBirthDate =
  string | null;

export type SanctionsScreeningReviewRecordGetResponseEntitySetupCorporationSubmissionAnyOfEntitySetupCorporationBeneficialOwnerRelationshipsItemEntitySetupBeneficialOwnerSubmissionMicrobiltItem =
  {
    birth_date: SanctionsScreeningReviewRecordGetResponseEntitySetupCorporationSubmissionAnyOfEntitySetupCorporationBeneficialOwnerRelationshipsItemEntitySetupBeneficialOwnerSubmissionMicrobiltItemBirthDate;
    created_at: string;
    death_date: SanctionsScreeningReviewRecordGetResponseEntitySetupCorporationSubmissionAnyOfEntitySetupCorporationBeneficialOwnerRelationshipsItemEntitySetupBeneficialOwnerSubmissionMicrobiltItemDeathDate;
    first_name: string;
    id: string;
    last_name: string;
    middle_name: SanctionsScreeningReviewRecordGetResponseEntitySetupCorporationSubmissionAnyOfEntitySetupCorporationBeneficialOwnerRelationshipsItemEntitySetupBeneficialOwnerSubmissionMicrobiltItemMiddleName;
    request_id: string;
    response: string;
    return_code: SanctionsScreeningReviewRecordGetResponseEntitySetupCorporationSubmissionAnyOfEntitySetupCorporationBeneficialOwnerRelationshipsItemEntitySetupBeneficialOwnerSubmissionMicrobiltItemReturnCode;
    tax_id_last_4: SanctionsScreeningReviewRecordGetResponseEntitySetupCorporationSubmissionAnyOfEntitySetupCorporationBeneficialOwnerRelationshipsItemEntitySetupBeneficialOwnerSubmissionMicrobiltItemTaxIdLast4;
    tax_id_type: SanctionsScreeningReviewRecordGetResponseEntitySetupCorporationSubmissionAnyOfEntitySetupCorporationBeneficialOwnerRelationshipsItemEntitySetupBeneficialOwnerSubmissionMicrobiltItemTaxIdType;
  };

export type SanctionsScreeningReviewRecordGetResponseEntitySetupCorporationSubmissionAnyOfEntitySetupCorporationBeneficialOwnerRelationshipsItemEntitySetupBeneficialOwnerSubmissionIdentityVerificationStatus =
  (typeof SanctionsScreeningReviewRecordGetResponseEntitySetupCorporationSubmissionAnyOfEntitySetupCorporationBeneficialOwnerRelationshipsItemEntitySetupBeneficialOwnerSubmissionIdentityVerificationStatus)[keyof typeof SanctionsScreeningReviewRecordGetResponseEntitySetupCorporationSubmissionAnyOfEntitySetupCorporationBeneficialOwnerRelationshipsItemEntitySetupBeneficialOwnerSubmissionIdentityVerificationStatus];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SanctionsScreeningReviewRecordGetResponseEntitySetupCorporationSubmissionAnyOfEntitySetupCorporationBeneficialOwnerRelationshipsItemEntitySetupBeneficialOwnerSubmissionIdentityVerificationStatus =
  {
    pending_verification: "pending_verification",
    update_pending_verification: "update_pending_verification",
    verified: "verified",
    falsified: "falsified",
    no_match: "no_match",
    verifying: "verifying",
    requires_attention: "requires_attention",
    not_performed: "not_performed",
    skipped: "skipped",
  } as const;

export type SanctionsScreeningReviewRecordGetResponseEntitySetupCorporationSubmissionAnyOfEntitySetupCorporationBeneficialOwnerRelationshipsItemEntitySetupBeneficialOwnerSubmissionIdentityDocumentSubmissionsItemSubmittedState =
  string | null;

export type SanctionsScreeningReviewRecordGetResponseEntitySetupCorporationSubmissionAnyOfEntitySetupCorporationBeneficialOwnerRelationshipsItemEntitySetupBeneficialOwnerSubmissionIdentityDocumentSubmissionsItemSubmittedExpirationDate =
  string | null;

export type SanctionsScreeningReviewRecordGetResponseEntitySetupCorporationSubmissionAnyOfEntitySetupCorporationBeneficialOwnerRelationshipsItemEntitySetupBeneficialOwnerSubmissionIdentityDocumentSubmissionsItemSubmittedDocumentType =
  (typeof SanctionsScreeningReviewRecordGetResponseEntitySetupCorporationSubmissionAnyOfEntitySetupCorporationBeneficialOwnerRelationshipsItemEntitySetupBeneficialOwnerSubmissionIdentityDocumentSubmissionsItemSubmittedDocumentType)[keyof typeof SanctionsScreeningReviewRecordGetResponseEntitySetupCorporationSubmissionAnyOfEntitySetupCorporationBeneficialOwnerRelationshipsItemEntitySetupBeneficialOwnerSubmissionIdentityDocumentSubmissionsItemSubmittedDocumentType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SanctionsScreeningReviewRecordGetResponseEntitySetupCorporationSubmissionAnyOfEntitySetupCorporationBeneficialOwnerRelationshipsItemEntitySetupBeneficialOwnerSubmissionIdentityDocumentSubmissionsItemSubmittedDocumentType =
  {
    passport: "passport",
    us_drivers_license: "us_drivers_license",
    other: "other",
  } as const;

export type SanctionsScreeningReviewRecordGetResponseEntitySetupCorporationSubmissionAnyOfEntitySetupCorporationBeneficialOwnerRelationshipsItemEntitySetupBeneficialOwnerSubmissionIdentityDocumentSubmissionsItemSubmittedDocumentNumber =
  string | null;

export type SanctionsScreeningReviewRecordGetResponseEntitySetupCorporationSubmissionAnyOfEntitySetupCorporationBeneficialOwnerRelationshipsItemEntitySetupBeneficialOwnerSubmissionIdentityDocumentSubmissionsItemSubmittedAddressLine2 =
  string | null;

export type SanctionsScreeningReviewRecordGetResponseEntitySetupCorporationSubmissionAnyOfEntitySetupCorporationBeneficialOwnerRelationshipsItemEntitySetupBeneficialOwnerSubmissionIdentityDocumentSubmissionsItemSubmittedAddress =
  {
    city: string;
    line1: string;
    line2: SanctionsScreeningReviewRecordGetResponseEntitySetupCorporationSubmissionAnyOfEntitySetupCorporationBeneficialOwnerRelationshipsItemEntitySetupBeneficialOwnerSubmissionIdentityDocumentSubmissionsItemSubmittedAddressLine2;
    state: string;
    zip: string;
  };

export type SanctionsScreeningReviewRecordGetResponseEntitySetupCorporationSubmissionAnyOfEntitySetupCorporationBeneficialOwnerRelationshipsItemEntitySetupBeneficialOwnerSubmissionIdentityDocumentSubmissionsItemIdentityVerificationStatus =
  (typeof SanctionsScreeningReviewRecordGetResponseEntitySetupCorporationSubmissionAnyOfEntitySetupCorporationBeneficialOwnerRelationshipsItemEntitySetupBeneficialOwnerSubmissionIdentityDocumentSubmissionsItemIdentityVerificationStatus)[keyof typeof SanctionsScreeningReviewRecordGetResponseEntitySetupCorporationSubmissionAnyOfEntitySetupCorporationBeneficialOwnerRelationshipsItemEntitySetupBeneficialOwnerSubmissionIdentityDocumentSubmissionsItemIdentityVerificationStatus];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SanctionsScreeningReviewRecordGetResponseEntitySetupCorporationSubmissionAnyOfEntitySetupCorporationBeneficialOwnerRelationshipsItemEntitySetupBeneficialOwnerSubmissionIdentityDocumentSubmissionsItemIdentityVerificationStatus =
  {
    pending_verification: "pending_verification",
    update_pending_verification: "update_pending_verification",
    verified: "verified",
    falsified: "falsified",
    no_match: "no_match",
    verifying: "verifying",
    requires_attention: "requires_attention",
    not_performed: "not_performed",
    skipped: "skipped",
  } as const;

export type SanctionsScreeningReviewRecordGetResponseEntitySetupCorporationSubmissionAnyOfEntitySetupCorporationBeneficialOwnerRelationshipsItemEntitySetupBeneficialOwnerSubmissionIdentityDocumentSubmissionsItemApiBackFileId =
  string | null;

export type SanctionsScreeningReviewRecordGetResponseEntitySetupCorporationSubmissionAnyOfEntitySetupCorporationBeneficialOwnerRelationshipsItemEntitySetupBeneficialOwnerSubmissionIdentityDocumentSubmissionsItem =
  {
    api_back_file_id: SanctionsScreeningReviewRecordGetResponseEntitySetupCorporationSubmissionAnyOfEntitySetupCorporationBeneficialOwnerRelationshipsItemEntitySetupBeneficialOwnerSubmissionIdentityDocumentSubmissionsItemApiBackFileId;
    api_file_id: string;
    created_at: string;
    group_id: string;
    id: string;
    identity_verification_status: SanctionsScreeningReviewRecordGetResponseEntitySetupCorporationSubmissionAnyOfEntitySetupCorporationBeneficialOwnerRelationshipsItemEntitySetupBeneficialOwnerSubmissionIdentityDocumentSubmissionsItemIdentityVerificationStatus;
    submitted_address: SanctionsScreeningReviewRecordGetResponseEntitySetupCorporationSubmissionAnyOfEntitySetupCorporationBeneficialOwnerRelationshipsItemEntitySetupBeneficialOwnerSubmissionIdentityDocumentSubmissionsItemSubmittedAddress;
    submitted_country: string;
    submitted_date_of_birth: string;
    submitted_document_number: SanctionsScreeningReviewRecordGetResponseEntitySetupCorporationSubmissionAnyOfEntitySetupCorporationBeneficialOwnerRelationshipsItemEntitySetupBeneficialOwnerSubmissionIdentityDocumentSubmissionsItemSubmittedDocumentNumber;
    submitted_document_type: SanctionsScreeningReviewRecordGetResponseEntitySetupCorporationSubmissionAnyOfEntitySetupCorporationBeneficialOwnerRelationshipsItemEntitySetupBeneficialOwnerSubmissionIdentityDocumentSubmissionsItemSubmittedDocumentType;
    submitted_expiration_date: SanctionsScreeningReviewRecordGetResponseEntitySetupCorporationSubmissionAnyOfEntitySetupCorporationBeneficialOwnerRelationshipsItemEntitySetupBeneficialOwnerSubmissionIdentityDocumentSubmissionsItemSubmittedExpirationDate;
    submitted_name: string;
    submitted_state: SanctionsScreeningReviewRecordGetResponseEntitySetupCorporationSubmissionAnyOfEntitySetupCorporationBeneficialOwnerRelationshipsItemEntitySetupBeneficialOwnerSubmissionIdentityDocumentSubmissionsItemSubmittedState;
  };

export type SanctionsScreeningReviewRecordGetResponseEntitySetupCorporationSubmissionAnyOfCobaltIntelligenceSecretaryOfStateSearchIntentsItemSubmittedState =
  string | null;

export type SanctionsScreeningReviewRecordGetResponseEntitySetupCorporationSubmissionAnyOfCobaltIntelligenceSecretaryOfStateSearchIntentsItemStatus =
  (typeof SanctionsScreeningReviewRecordGetResponseEntitySetupCorporationSubmissionAnyOfCobaltIntelligenceSecretaryOfStateSearchIntentsItemStatus)[keyof typeof SanctionsScreeningReviewRecordGetResponseEntitySetupCorporationSubmissionAnyOfCobaltIntelligenceSecretaryOfStateSearchIntentsItemStatus];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SanctionsScreeningReviewRecordGetResponseEntitySetupCorporationSubmissionAnyOfCobaltIntelligenceSecretaryOfStateSearchIntentsItemStatus =
  {
    pending: "pending",
    searching: "searching",
    retrying: "retrying",
    complete: "complete",
    failed: "failed",
    requires_attention: "requires_attention",
    skipped: "skipped",
  } as const;

export type SanctionsScreeningReviewRecordGetResponseEntitySetupCorporationSubmissionAnyOfCobaltIntelligenceSecretaryOfStateSearchIntentsItemSearchStrategy =
  (typeof SanctionsScreeningReviewRecordGetResponseEntitySetupCorporationSubmissionAnyOfCobaltIntelligenceSecretaryOfStateSearchIntentsItemSearchStrategy)[keyof typeof SanctionsScreeningReviewRecordGetResponseEntitySetupCorporationSubmissionAnyOfCobaltIntelligenceSecretaryOfStateSearchIntentsItemSearchStrategy];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SanctionsScreeningReviewRecordGetResponseEntitySetupCorporationSubmissionAnyOfCobaltIntelligenceSecretaryOfStateSearchIntentsItemSearchStrategy =
  {
    incorporation_state: "incorporation_state",
    address_state: "address_state",
  } as const;

export type SanctionsScreeningReviewRecordGetResponseEntitySetupCorporationSubmissionAnyOfCobaltIntelligenceSecretaryOfStateSearchIntentsItemSearchResponseAnyOf =
  {
    id: string;
    response_body: string;
  };

export type SanctionsScreeningReviewRecordGetResponseEntitySetupCorporationSubmissionAnyOfCobaltIntelligenceSecretaryOfStateSearchIntentsItemSearchResponse =
  SanctionsScreeningReviewRecordGetResponseEntitySetupCorporationSubmissionAnyOfCobaltIntelligenceSecretaryOfStateSearchIntentsItemSearchResponseAnyOf | null;

export type SanctionsScreeningReviewRecordGetResponseEntitySetupCorporationSubmissionAnyOfCobaltIntelligenceSecretaryOfStateSearchIntentsItemOutcome =
  "found" | "not_found" | null;

export type SanctionsScreeningReviewRecordGetResponseEntitySetupCorporationSubmissionAnyOfCobaltIntelligenceSecretaryOfStateSearchIntentsItem =
  {
    created_at: string;
    entity_id: string;
    id: string;
    outcome: SanctionsScreeningReviewRecordGetResponseEntitySetupCorporationSubmissionAnyOfCobaltIntelligenceSecretaryOfStateSearchIntentsItemOutcome;
    search_response: SanctionsScreeningReviewRecordGetResponseEntitySetupCorporationSubmissionAnyOfCobaltIntelligenceSecretaryOfStateSearchIntentsItemSearchResponse;
    search_strategy: SanctionsScreeningReviewRecordGetResponseEntitySetupCorporationSubmissionAnyOfCobaltIntelligenceSecretaryOfStateSearchIntentsItemSearchStrategy;
    searchable_submission_class: string;
    searchable_submission_id: string;
    status: SanctionsScreeningReviewRecordGetResponseEntitySetupCorporationSubmissionAnyOfCobaltIntelligenceSecretaryOfStateSearchIntentsItemStatus;
    submitted_address_state: string;
    submitted_name: string;
    submitted_state: SanctionsScreeningReviewRecordGetResponseEntitySetupCorporationSubmissionAnyOfCobaltIntelligenceSecretaryOfStateSearchIntentsItemSubmittedState;
  };

export type SanctionsScreeningReviewRecordGetResponseEntitySetupCorporationSubmissionAnyOfBeneficialOwnershipExemption =
  "regulated_financial_institution" | "publicly_traded_company" | null;

export type SanctionsScreeningReviewRecordGetResponseEntitySetupBeneficialOwnerSubmissionAnyOfTaxIdValidationStatus =
  (typeof SanctionsScreeningReviewRecordGetResponseEntitySetupBeneficialOwnerSubmissionAnyOfTaxIdValidationStatus)[keyof typeof SanctionsScreeningReviewRecordGetResponseEntitySetupBeneficialOwnerSubmissionAnyOfTaxIdValidationStatus];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SanctionsScreeningReviewRecordGetResponseEntitySetupBeneficialOwnerSubmissionAnyOfTaxIdValidationStatus =
  {
    pending_validating: "pending_validating",
    update_pending_validating: "update_pending_validating",
    requires_attention: "requires_attention",
    failed: "failed",
    validated: "validated",
    not_provided: "not_provided",
    skipped: "skipped",
  } as const;

export type SanctionsScreeningReviewRecordGetResponseEntitySetupBeneficialOwnerSubmissionAnyOfSubmittedIdentificationNumberLast4 =
  string | null;

export type SanctionsScreeningReviewRecordGetResponseEntitySetupBeneficialOwnerSubmissionAnyOfSubmittedIdentificationMethod =
  (typeof SanctionsScreeningReviewRecordGetResponseEntitySetupBeneficialOwnerSubmissionAnyOfSubmittedIdentificationMethod)[keyof typeof SanctionsScreeningReviewRecordGetResponseEntitySetupBeneficialOwnerSubmissionAnyOfSubmittedIdentificationMethod];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SanctionsScreeningReviewRecordGetResponseEntitySetupBeneficialOwnerSubmissionAnyOfSubmittedIdentificationMethod =
  {
    social_security_number: "social_security_number",
    individual_taxpayer_identification_number:
      "individual_taxpayer_identification_number",
    passport: "passport",
    drivers_license: "drivers_license",
    other: "other",
  } as const;

export type SanctionsScreeningReviewRecordGetResponseEntitySetupBeneficialOwnerSubmissionAnyOfSubmittedAddressLine2 =
  string | null;

export type SanctionsScreeningReviewRecordGetResponseEntitySetupBeneficialOwnerSubmissionAnyOfSubmittedAddress =
  {
    city: string;
    line1: string;
    line2: SanctionsScreeningReviewRecordGetResponseEntitySetupBeneficialOwnerSubmissionAnyOfSubmittedAddressLine2;
    state: string;
    zip: string;
  };

export type SanctionsScreeningReviewRecordGetResponseEntitySetupBeneficialOwnerSubmissionAnyOfSubmissionSovosResponseId =
  string | null;

export type SanctionsScreeningReviewRecordGetResponseEntitySetupBeneficialOwnerSubmissionAnyOf =
  {
    created_at: string;
    id: string;
    identity_document_submissions: SanctionsScreeningReviewRecordGetResponseEntitySetupBeneficialOwnerSubmissionAnyOfIdentityDocumentSubmissionsItem[];
    identity_verification_status: SanctionsScreeningReviewRecordGetResponseEntitySetupBeneficialOwnerSubmissionAnyOfIdentityVerificationStatus;
    microbilt: SanctionsScreeningReviewRecordGetResponseEntitySetupBeneficialOwnerSubmissionAnyOfMicrobiltItem[];
    submission_sovos_response_id: SanctionsScreeningReviewRecordGetResponseEntitySetupBeneficialOwnerSubmissionAnyOfSubmissionSovosResponseId;
    submitted_address: SanctionsScreeningReviewRecordGetResponseEntitySetupBeneficialOwnerSubmissionAnyOfSubmittedAddress;
    submitted_date_of_birth: string;
    submitted_identification_method: SanctionsScreeningReviewRecordGetResponseEntitySetupBeneficialOwnerSubmissionAnyOfSubmittedIdentificationMethod;
    submitted_identification_number_last4: SanctionsScreeningReviewRecordGetResponseEntitySetupBeneficialOwnerSubmissionAnyOfSubmittedIdentificationNumberLast4;
    submitted_name: string;
    tax_id_validation_status: SanctionsScreeningReviewRecordGetResponseEntitySetupBeneficialOwnerSubmissionAnyOfTaxIdValidationStatus;
  };

export type SanctionsScreeningReviewRecordGetResponseEntitySetupBeneficialOwnerSubmission =
  SanctionsScreeningReviewRecordGetResponseEntitySetupBeneficialOwnerSubmissionAnyOf | null;

export type SanctionsScreeningReviewRecordGetResponseEntitySetupBeneficialOwnerSubmissionAnyOfMicrobiltItemTaxIdType =
  "SSN" | null;

export type SanctionsScreeningReviewRecordGetResponseEntitySetupBeneficialOwnerSubmissionAnyOfMicrobiltItemTaxIdLast4 =
  string | null;

export type SanctionsScreeningReviewRecordGetResponseEntitySetupBeneficialOwnerSubmissionAnyOfMicrobiltItemReturnCode =
  (typeof SanctionsScreeningReviewRecordGetResponseEntitySetupBeneficialOwnerSubmissionAnyOfMicrobiltItemReturnCode)[keyof typeof SanctionsScreeningReviewRecordGetResponseEntitySetupBeneficialOwnerSubmissionAnyOfMicrobiltItemReturnCode];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SanctionsScreeningReviewRecordGetResponseEntitySetupBeneficialOwnerSubmissionAnyOfMicrobiltItemReturnCode =
  {
    OK: "OK",
  } as const;

export type SanctionsScreeningReviewRecordGetResponseEntitySetupBeneficialOwnerSubmissionAnyOfMicrobiltItemMiddleName =
  string | null;

export type SanctionsScreeningReviewRecordGetResponseEntitySetupBeneficialOwnerSubmissionAnyOfMicrobiltItemDeathDate =
  string | null;

export type SanctionsScreeningReviewRecordGetResponseEntitySetupBeneficialOwnerSubmissionAnyOfMicrobiltItemBirthDate =
  string | null;

export type SanctionsScreeningReviewRecordGetResponseEntitySetupBeneficialOwnerSubmissionAnyOfMicrobiltItem =
  {
    birth_date: SanctionsScreeningReviewRecordGetResponseEntitySetupBeneficialOwnerSubmissionAnyOfMicrobiltItemBirthDate;
    created_at: string;
    death_date: SanctionsScreeningReviewRecordGetResponseEntitySetupBeneficialOwnerSubmissionAnyOfMicrobiltItemDeathDate;
    first_name: string;
    id: string;
    last_name: string;
    middle_name: SanctionsScreeningReviewRecordGetResponseEntitySetupBeneficialOwnerSubmissionAnyOfMicrobiltItemMiddleName;
    request_id: string;
    response: string;
    return_code: SanctionsScreeningReviewRecordGetResponseEntitySetupBeneficialOwnerSubmissionAnyOfMicrobiltItemReturnCode;
    tax_id_last_4: SanctionsScreeningReviewRecordGetResponseEntitySetupBeneficialOwnerSubmissionAnyOfMicrobiltItemTaxIdLast4;
    tax_id_type: SanctionsScreeningReviewRecordGetResponseEntitySetupBeneficialOwnerSubmissionAnyOfMicrobiltItemTaxIdType;
  };

export type SanctionsScreeningReviewRecordGetResponseEntitySetupBeneficialOwnerSubmissionAnyOfIdentityVerificationStatus =
  (typeof SanctionsScreeningReviewRecordGetResponseEntitySetupBeneficialOwnerSubmissionAnyOfIdentityVerificationStatus)[keyof typeof SanctionsScreeningReviewRecordGetResponseEntitySetupBeneficialOwnerSubmissionAnyOfIdentityVerificationStatus];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SanctionsScreeningReviewRecordGetResponseEntitySetupBeneficialOwnerSubmissionAnyOfIdentityVerificationStatus =
  {
    pending_verification: "pending_verification",
    update_pending_verification: "update_pending_verification",
    verified: "verified",
    falsified: "falsified",
    no_match: "no_match",
    verifying: "verifying",
    requires_attention: "requires_attention",
    not_performed: "not_performed",
    skipped: "skipped",
  } as const;

export type SanctionsScreeningReviewRecordGetResponseEntitySetupBeneficialOwnerSubmissionAnyOfIdentityDocumentSubmissionsItemSubmittedState =
  string | null;

export type SanctionsScreeningReviewRecordGetResponseEntitySetupBeneficialOwnerSubmissionAnyOfIdentityDocumentSubmissionsItemSubmittedExpirationDate =
  string | null;

export type SanctionsScreeningReviewRecordGetResponseEntitySetupBeneficialOwnerSubmissionAnyOfIdentityDocumentSubmissionsItemSubmittedDocumentType =
  (typeof SanctionsScreeningReviewRecordGetResponseEntitySetupBeneficialOwnerSubmissionAnyOfIdentityDocumentSubmissionsItemSubmittedDocumentType)[keyof typeof SanctionsScreeningReviewRecordGetResponseEntitySetupBeneficialOwnerSubmissionAnyOfIdentityDocumentSubmissionsItemSubmittedDocumentType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SanctionsScreeningReviewRecordGetResponseEntitySetupBeneficialOwnerSubmissionAnyOfIdentityDocumentSubmissionsItemSubmittedDocumentType =
  {
    passport: "passport",
    us_drivers_license: "us_drivers_license",
    other: "other",
  } as const;

export type SanctionsScreeningReviewRecordGetResponseEntitySetupBeneficialOwnerSubmissionAnyOfIdentityDocumentSubmissionsItemSubmittedDocumentNumber =
  string | null;

export type SanctionsScreeningReviewRecordGetResponseEntitySetupBeneficialOwnerSubmissionAnyOfIdentityDocumentSubmissionsItem =
  {
    api_back_file_id: SanctionsScreeningReviewRecordGetResponseEntitySetupBeneficialOwnerSubmissionAnyOfIdentityDocumentSubmissionsItemApiBackFileId;
    api_file_id: string;
    created_at: string;
    group_id: string;
    id: string;
    identity_verification_status: SanctionsScreeningReviewRecordGetResponseEntitySetupBeneficialOwnerSubmissionAnyOfIdentityDocumentSubmissionsItemIdentityVerificationStatus;
    submitted_address: SanctionsScreeningReviewRecordGetResponseEntitySetupBeneficialOwnerSubmissionAnyOfIdentityDocumentSubmissionsItemSubmittedAddress;
    submitted_country: string;
    submitted_date_of_birth: string;
    submitted_document_number: SanctionsScreeningReviewRecordGetResponseEntitySetupBeneficialOwnerSubmissionAnyOfIdentityDocumentSubmissionsItemSubmittedDocumentNumber;
    submitted_document_type: SanctionsScreeningReviewRecordGetResponseEntitySetupBeneficialOwnerSubmissionAnyOfIdentityDocumentSubmissionsItemSubmittedDocumentType;
    submitted_expiration_date: SanctionsScreeningReviewRecordGetResponseEntitySetupBeneficialOwnerSubmissionAnyOfIdentityDocumentSubmissionsItemSubmittedExpirationDate;
    submitted_name: string;
    submitted_state: SanctionsScreeningReviewRecordGetResponseEntitySetupBeneficialOwnerSubmissionAnyOfIdentityDocumentSubmissionsItemSubmittedState;
  };

export type SanctionsScreeningReviewRecordGetResponseEntitySetupBeneficialOwnerSubmissionAnyOfIdentityDocumentSubmissionsItemSubmittedAddressLine2 =
  string | null;

export type SanctionsScreeningReviewRecordGetResponseEntitySetupBeneficialOwnerSubmissionAnyOfIdentityDocumentSubmissionsItemSubmittedAddress =
  {
    city: string;
    line1: string;
    line2: SanctionsScreeningReviewRecordGetResponseEntitySetupBeneficialOwnerSubmissionAnyOfIdentityDocumentSubmissionsItemSubmittedAddressLine2;
    state: string;
    zip: string;
  };

export type SanctionsScreeningReviewRecordGetResponseEntitySetupBeneficialOwnerSubmissionAnyOfIdentityDocumentSubmissionsItemIdentityVerificationStatus =
  (typeof SanctionsScreeningReviewRecordGetResponseEntitySetupBeneficialOwnerSubmissionAnyOfIdentityDocumentSubmissionsItemIdentityVerificationStatus)[keyof typeof SanctionsScreeningReviewRecordGetResponseEntitySetupBeneficialOwnerSubmissionAnyOfIdentityDocumentSubmissionsItemIdentityVerificationStatus];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SanctionsScreeningReviewRecordGetResponseEntitySetupBeneficialOwnerSubmissionAnyOfIdentityDocumentSubmissionsItemIdentityVerificationStatus =
  {
    pending_verification: "pending_verification",
    update_pending_verification: "update_pending_verification",
    verified: "verified",
    falsified: "falsified",
    no_match: "no_match",
    verifying: "verifying",
    requires_attention: "requires_attention",
    not_performed: "not_performed",
    skipped: "skipped",
  } as const;

export type SanctionsScreeningReviewRecordGetResponseEntitySetupBeneficialOwnerSubmissionAnyOfIdentityDocumentSubmissionsItemApiBackFileId =
  string | null;

export type SanctionsScreeningReviewRecordGetResponseEntityId = string | null;

export type SanctionsScreeningReviewPatchResponseStatus =
  (typeof SanctionsScreeningReviewPatchResponseStatus)[keyof typeof SanctionsScreeningReviewPatchResponseStatus];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SanctionsScreeningReviewPatchResponseStatus = {
  pending_reviewing: "pending_reviewing",
  backfill: "backfill",
  false_positive: "false_positive",
  true_positive: "true_positive",
  requires_attention: "requires_attention",
} as const;

export type SanctionsScreeningReviewPatchResponseScreeningHistory =
  (typeof SanctionsScreeningReviewPatchResponseScreeningHistory)[keyof typeof SanctionsScreeningReviewPatchResponseScreeningHistory];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SanctionsScreeningReviewPatchResponseScreeningHistory = {
  first_screen: "first_screen",
  rescreen: "rescreen",
} as const;

export type SanctionsScreeningReviewPatchResponseReviewer =
  (typeof SanctionsScreeningReviewPatchResponseReviewer)[keyof typeof SanctionsScreeningReviewPatchResponseReviewer];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SanctionsScreeningReviewPatchResponseReviewer = {
  increase: "increase",
  grasshopper: "grasshopper",
  core_bank: "core_bank",
} as const;

export type SanctionsScreeningReviewPatchResponseRecordType =
  (typeof SanctionsScreeningReviewPatchResponseRecordType)[keyof typeof SanctionsScreeningReviewPatchResponseRecordType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SanctionsScreeningReviewPatchResponseRecordType = {
  inbound_wire: "inbound_wire",
  outbound_wire: "outbound_wire",
  international_ach_entry: "international_ach_entry",
  entity_setup_beneficial_owner: "entity_setup_beneficial_owner",
  entity_setup_corporation: "entity_setup_corporation",
  entity_setup_natural_person: "entity_setup_natural_person",
  entity_setup_government_authority_person:
    "entity_setup_government_authority_person",
  entity_setup_government_authority: "entity_setup_government_authority",
} as const;

export interface SanctionsScreeningReviewPatchResponse {
  created_at: string;
  hits_count: number;
  id: string;
  record_id: string;
  record_type: SanctionsScreeningReviewPatchResponseRecordType;
  reviewer: SanctionsScreeningReviewPatchResponseReviewer;
  screening_history: SanctionsScreeningReviewPatchResponseScreeningHistory;
  status: SanctionsScreeningReviewPatchResponseStatus;
}

export type SanctionsScreeningReviewPatchParametersStatus =
  (typeof SanctionsScreeningReviewPatchParametersStatus)[keyof typeof SanctionsScreeningReviewPatchParametersStatus];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SanctionsScreeningReviewPatchParametersStatus = {
  pending_reviewing: "pending_reviewing",
  backfill: "backfill",
  false_positive: "false_positive",
  true_positive: "true_positive",
  requires_attention: "requires_attention",
} as const;

export interface SanctionsScreeningReviewPatchParameters {
  explanation: string;
  status: SanctionsScreeningReviewPatchParametersStatus;
}

export type SanctionsScreeningReviewListResponseNextCursor = string | null;

export interface SanctionsScreeningReviewListResponse {
  data: SanctionsScreeningReviewListResponseDataItem[];
  next_cursor: SanctionsScreeningReviewListResponseNextCursor;
}

export type SanctionsScreeningReviewListResponseDataItemStatus =
  (typeof SanctionsScreeningReviewListResponseDataItemStatus)[keyof typeof SanctionsScreeningReviewListResponseDataItemStatus];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SanctionsScreeningReviewListResponseDataItemStatus = {
  pending_reviewing: "pending_reviewing",
  backfill: "backfill",
  false_positive: "false_positive",
  true_positive: "true_positive",
  requires_attention: "requires_attention",
} as const;

export type SanctionsScreeningReviewListResponseDataItemScreeningHistory =
  (typeof SanctionsScreeningReviewListResponseDataItemScreeningHistory)[keyof typeof SanctionsScreeningReviewListResponseDataItemScreeningHistory];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SanctionsScreeningReviewListResponseDataItemScreeningHistory = {
  first_screen: "first_screen",
  rescreen: "rescreen",
} as const;

export type SanctionsScreeningReviewListResponseDataItemReviewer =
  (typeof SanctionsScreeningReviewListResponseDataItemReviewer)[keyof typeof SanctionsScreeningReviewListResponseDataItemReviewer];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SanctionsScreeningReviewListResponseDataItemReviewer = {
  increase: "increase",
  grasshopper: "grasshopper",
  core_bank: "core_bank",
} as const;

export type SanctionsScreeningReviewListResponseDataItemRecordType =
  (typeof SanctionsScreeningReviewListResponseDataItemRecordType)[keyof typeof SanctionsScreeningReviewListResponseDataItemRecordType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SanctionsScreeningReviewListResponseDataItemRecordType = {
  inbound_wire: "inbound_wire",
  outbound_wire: "outbound_wire",
  international_ach_entry: "international_ach_entry",
  entity_setup_beneficial_owner: "entity_setup_beneficial_owner",
  entity_setup_corporation: "entity_setup_corporation",
  entity_setup_natural_person: "entity_setup_natural_person",
  entity_setup_government_authority_person:
    "entity_setup_government_authority_person",
  entity_setup_government_authority: "entity_setup_government_authority",
} as const;

export type SanctionsScreeningReviewListResponseDataItem = {
  created_at: string;
  hits_count: number;
  id: string;
  record_id: string;
  record_type: SanctionsScreeningReviewListResponseDataItemRecordType;
  reviewer: SanctionsScreeningReviewListResponseDataItemReviewer;
  screening_history: SanctionsScreeningReviewListResponseDataItemScreeningHistory;
  status: SanctionsScreeningReviewListResponseDataItemStatus;
};

export type SanctionsScreeningReviewGetResponseStatus =
  (typeof SanctionsScreeningReviewGetResponseStatus)[keyof typeof SanctionsScreeningReviewGetResponseStatus];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SanctionsScreeningReviewGetResponseStatus = {
  pending_reviewing: "pending_reviewing",
  backfill: "backfill",
  false_positive: "false_positive",
  true_positive: "true_positive",
  requires_attention: "requires_attention",
} as const;

export type SanctionsScreeningReviewGetResponseScreeningHistory =
  (typeof SanctionsScreeningReviewGetResponseScreeningHistory)[keyof typeof SanctionsScreeningReviewGetResponseScreeningHistory];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SanctionsScreeningReviewGetResponseScreeningHistory = {
  first_screen: "first_screen",
  rescreen: "rescreen",
} as const;

export type SanctionsScreeningReviewGetResponseReviewer =
  (typeof SanctionsScreeningReviewGetResponseReviewer)[keyof typeof SanctionsScreeningReviewGetResponseReviewer];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SanctionsScreeningReviewGetResponseReviewer = {
  increase: "increase",
  grasshopper: "grasshopper",
  core_bank: "core_bank",
} as const;

export type SanctionsScreeningReviewGetResponseRecordType =
  (typeof SanctionsScreeningReviewGetResponseRecordType)[keyof typeof SanctionsScreeningReviewGetResponseRecordType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SanctionsScreeningReviewGetResponseRecordType = {
  inbound_wire: "inbound_wire",
  outbound_wire: "outbound_wire",
  international_ach_entry: "international_ach_entry",
  entity_setup_beneficial_owner: "entity_setup_beneficial_owner",
  entity_setup_corporation: "entity_setup_corporation",
  entity_setup_natural_person: "entity_setup_natural_person",
  entity_setup_government_authority_person:
    "entity_setup_government_authority_person",
  entity_setup_government_authority: "entity_setup_government_authority",
} as const;

export interface SanctionsScreeningReviewGetResponse {
  created_at: string;
  hits_count: number;
  id: string;
  record_id: string;
  record_type: SanctionsScreeningReviewGetResponseRecordType;
  reviewer: SanctionsScreeningReviewGetResponseReviewer;
  screening_history: SanctionsScreeningReviewGetResponseScreeningHistory;
  status: SanctionsScreeningReviewGetResponseStatus;
}

export type SanctionsScreeningHitPatchResponseTriggerTokenHitAnyOfField =
  (typeof SanctionsScreeningHitPatchResponseTriggerTokenHitAnyOfField)[keyof typeof SanctionsScreeningHitPatchResponseTriggerTokenHitAnyOfField];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SanctionsScreeningHitPatchResponseTriggerTokenHitAnyOfField = {
  originator_to_beneficiary_message: "originator_to_beneficiary_message",
  beneficiary_name: "beneficiary_name",
  beneficiary_address: "beneficiary_address",
  originator_name: "originator_name",
  originator_address: "originator_address",
  name: "name",
} as const;

export type SanctionsScreeningHitPatchResponseTriggerTokenHitAnyOf = {
  field: SanctionsScreeningHitPatchResponseTriggerTokenHitAnyOfField;
  token: string;
  token_id: string;
};

export type SanctionsScreeningHitPatchResponseTriggerTokenHit =
  SanctionsScreeningHitPatchResponseTriggerTokenHitAnyOf | null;

export type SanctionsScreeningHitPatchResponseStatus =
  (typeof SanctionsScreeningHitPatchResponseStatus)[keyof typeof SanctionsScreeningHitPatchResponseStatus];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SanctionsScreeningHitPatchResponseStatus = {
  pending_reviewing: "pending_reviewing",
  backfill: "backfill",
  false_positive: "false_positive",
  true_positive: "true_positive",
  requires_attention: "requires_attention",
} as const;

export type SanctionsScreeningHitPatchResponseInternationalTradeAdministrationHitAnyOf =
  {
    hit: unknown;
  };

export type SanctionsScreeningHitPatchResponseInternationalTradeAdministrationHit =
  SanctionsScreeningHitPatchResponseInternationalTradeAdministrationHitAnyOf | null;

export type SanctionsScreeningHitPatchResponseFuzzySearchHit =
  SanctionsScreeningHitPatchResponseFuzzySearchHitAnyOf | null;

export interface SanctionsScreeningHitPatchResponse {
  created_at: string;
  fuzzy_search_hit: SanctionsScreeningHitPatchResponseFuzzySearchHit;
  id: string;
  international_trade_administration_hit: SanctionsScreeningHitPatchResponseInternationalTradeAdministrationHit;
  review_id: string;
  status: SanctionsScreeningHitPatchResponseStatus;
  trigger_token_hit: SanctionsScreeningHitPatchResponseTriggerTokenHit;
}

export type SanctionsScreeningHitPatchResponseFuzzySearchHitAnyOfField =
  (typeof SanctionsScreeningHitPatchResponseFuzzySearchHitAnyOfField)[keyof typeof SanctionsScreeningHitPatchResponseFuzzySearchHitAnyOfField];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SanctionsScreeningHitPatchResponseFuzzySearchHitAnyOfField = {
  originator_to_beneficiary_message: "originator_to_beneficiary_message",
  beneficiary_name: "beneficiary_name",
  beneficiary_address: "beneficiary_address",
  originator_name: "originator_name",
  originator_address: "originator_address",
  name: "name",
} as const;

export type SanctionsScreeningHitPatchResponseFuzzySearchHitAnyOfEntryType =
  | "Entity"
  | "Individual"
  | "Vessel"
  | "Aircraft"
  | null;

export type SanctionsScreeningHitPatchResponseFuzzySearchHitAnyOfEntrySource =
  (typeof SanctionsScreeningHitPatchResponseFuzzySearchHitAnyOfEntrySource)[keyof typeof SanctionsScreeningHitPatchResponseFuzzySearchHitAnyOfEntrySource];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SanctionsScreeningHitPatchResponseFuzzySearchHitAnyOfEntrySource =
  {
    "Specially_Designated_Nationals_(SDN)_-_Treasury_Department":
      "Specially Designated Nationals (SDN) - Treasury Department",
    "Entity_List_(EL)_-_Bureau_of_Industry_and_Security":
      "Entity List (EL) - Bureau of Industry and Security",
    "ITAR_Debarred_(DTC)_-_State_Department":
      "ITAR Debarred (DTC) - State Department",
    "Denied_Persons_List_(DPL)_-_Bureau_of_Industry_and_Security":
      "Denied Persons List (DPL) - Bureau of Industry and Security",
    "Sectoral_Sanctions_Identifications_List_(SSI)_-_Treasury_Department":
      "Sectoral Sanctions Identifications List (SSI) - Treasury Department",
    "Unverified_List_(UVL)_-_Bureau_of_Industry_and_Security":
      "Unverified List (UVL) - Bureau of Industry and Security",
    "Nonproliferation_Sanctions_(ISN)_-_State_Department":
      "Nonproliferation Sanctions (ISN) - State Department",
    "Palestinian_Legislative_Council_List_(PLC)_-_Treasury_Department":
      "Palestinian Legislative Council List (PLC) - Treasury Department",
    "Military_End_User_(MEU)_List_-_Bureau_of_Industry_and_Security":
      "Military End User (MEU) List - Bureau of Industry and Security",
    "Non-SDN_Chinese_Military-Industrial_Complex_Companies_List_(CMIC)_-_Treasury_Department":
      "Non-SDN Chinese Military-Industrial Complex Companies List (CMIC) - Treasury Department",
    "Non-SDN_Menu-Based_Sanctions_List_(NS-MBS_List)_-_Treasury_Department":
      "Non-SDN Menu-Based Sanctions List (NS-MBS List) - Treasury Department",
    "Foreign_Sanctions_Evaders_(FSE)_-_Treasury_Department":
      "Foreign Sanctions Evaders (FSE) - Treasury Department",
    "Capta_List_(CAP)_-_Treasury_Department":
      "Capta List (CAP) - Treasury Department",
  } as const;

export type SanctionsScreeningHitPatchResponseFuzzySearchHitAnyOfAlgorithm =
  (typeof SanctionsScreeningHitPatchResponseFuzzySearchHitAnyOfAlgorithm)[keyof typeof SanctionsScreeningHitPatchResponseFuzzySearchHitAnyOfAlgorithm];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SanctionsScreeningHitPatchResponseFuzzySearchHitAnyOfAlgorithm = {
  jaro_winkler: "jaro_winkler",
  word_aligned_jaro_winkler: "word_aligned_jaro_winkler",
  word_aligned_soundex: "word_aligned_soundex",
  ending_anchored_jaro_winkler: "ending_anchored_jaro_winkler",
} as const;

export type SanctionsScreeningHitPatchResponseFuzzySearchHitAnyOf = {
  addresses: string[];
  algorithm: SanctionsScreeningHitPatchResponseFuzzySearchHitAnyOfAlgorithm;
  date_of_births: string[];
  entry_external_id: string;
  entry_source: SanctionsScreeningHitPatchResponseFuzzySearchHitAnyOfEntrySource;
  entry_type: SanctionsScreeningHitPatchResponseFuzzySearchHitAnyOfEntryType;
  field: SanctionsScreeningHitPatchResponseFuzzySearchHitAnyOfField;
  name: string;
  other_names: string[];
  place_of_births: string[];
  /** @pattern ^-?\d+(\.\d+)?$ */
  score: string;
};

export type SanctionsScreeningHitPatchParametersStatus =
  (typeof SanctionsScreeningHitPatchParametersStatus)[keyof typeof SanctionsScreeningHitPatchParametersStatus];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SanctionsScreeningHitPatchParametersStatus = {
  pending_reviewing: "pending_reviewing",
  backfill: "backfill",
  false_positive: "false_positive",
  true_positive: "true_positive",
  requires_attention: "requires_attention",
} as const;

export interface SanctionsScreeningHitPatchParameters {
  explanation: string;
  status: SanctionsScreeningHitPatchParametersStatus;
}

export type SanctionsScreeningHitListResponseNextCursor = string | null;

export interface SanctionsScreeningHitListResponse {
  data: SanctionsScreeningHitListResponseDataItem[];
  next_cursor: SanctionsScreeningHitListResponseNextCursor;
}

export type SanctionsScreeningHitListResponseDataItemTriggerTokenHitAnyOfField =
  (typeof SanctionsScreeningHitListResponseDataItemTriggerTokenHitAnyOfField)[keyof typeof SanctionsScreeningHitListResponseDataItemTriggerTokenHitAnyOfField];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SanctionsScreeningHitListResponseDataItemTriggerTokenHitAnyOfField =
  {
    originator_to_beneficiary_message: "originator_to_beneficiary_message",
    beneficiary_name: "beneficiary_name",
    beneficiary_address: "beneficiary_address",
    originator_name: "originator_name",
    originator_address: "originator_address",
    name: "name",
  } as const;

export type SanctionsScreeningHitListResponseDataItemTriggerTokenHitAnyOf = {
  field: SanctionsScreeningHitListResponseDataItemTriggerTokenHitAnyOfField;
  token: string;
  token_id: string;
};

export type SanctionsScreeningHitListResponseDataItemTriggerTokenHit =
  SanctionsScreeningHitListResponseDataItemTriggerTokenHitAnyOf | null;

export type SanctionsScreeningHitListResponseDataItemStatus =
  (typeof SanctionsScreeningHitListResponseDataItemStatus)[keyof typeof SanctionsScreeningHitListResponseDataItemStatus];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SanctionsScreeningHitListResponseDataItemStatus = {
  pending_reviewing: "pending_reviewing",
  backfill: "backfill",
  false_positive: "false_positive",
  true_positive: "true_positive",
  requires_attention: "requires_attention",
} as const;

export type SanctionsScreeningHitListResponseDataItemInternationalTradeAdministrationHitAnyOf =
  {
    hit: unknown;
  };

export type SanctionsScreeningHitListResponseDataItemInternationalTradeAdministrationHit =
  SanctionsScreeningHitListResponseDataItemInternationalTradeAdministrationHitAnyOf | null;

export type SanctionsScreeningHitListResponseDataItemFuzzySearchHit =
  SanctionsScreeningHitListResponseDataItemFuzzySearchHitAnyOf | null;

export type SanctionsScreeningHitListResponseDataItem = {
  created_at: string;
  fuzzy_search_hit: SanctionsScreeningHitListResponseDataItemFuzzySearchHit;
  id: string;
  international_trade_administration_hit: SanctionsScreeningHitListResponseDataItemInternationalTradeAdministrationHit;
  review_id: string;
  status: SanctionsScreeningHitListResponseDataItemStatus;
  trigger_token_hit: SanctionsScreeningHitListResponseDataItemTriggerTokenHit;
};

export type SanctionsScreeningHitListResponseDataItemFuzzySearchHitAnyOfField =
  (typeof SanctionsScreeningHitListResponseDataItemFuzzySearchHitAnyOfField)[keyof typeof SanctionsScreeningHitListResponseDataItemFuzzySearchHitAnyOfField];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SanctionsScreeningHitListResponseDataItemFuzzySearchHitAnyOfField =
  {
    originator_to_beneficiary_message: "originator_to_beneficiary_message",
    beneficiary_name: "beneficiary_name",
    beneficiary_address: "beneficiary_address",
    originator_name: "originator_name",
    originator_address: "originator_address",
    name: "name",
  } as const;

export type SanctionsScreeningHitListResponseDataItemFuzzySearchHitAnyOfEntryType =
  "Entity" | "Individual" | "Vessel" | "Aircraft" | null;

export type SanctionsScreeningHitListResponseDataItemFuzzySearchHitAnyOfEntrySource =
  (typeof SanctionsScreeningHitListResponseDataItemFuzzySearchHitAnyOfEntrySource)[keyof typeof SanctionsScreeningHitListResponseDataItemFuzzySearchHitAnyOfEntrySource];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SanctionsScreeningHitListResponseDataItemFuzzySearchHitAnyOfEntrySource =
  {
    "Specially_Designated_Nationals_(SDN)_-_Treasury_Department":
      "Specially Designated Nationals (SDN) - Treasury Department",
    "Entity_List_(EL)_-_Bureau_of_Industry_and_Security":
      "Entity List (EL) - Bureau of Industry and Security",
    "ITAR_Debarred_(DTC)_-_State_Department":
      "ITAR Debarred (DTC) - State Department",
    "Denied_Persons_List_(DPL)_-_Bureau_of_Industry_and_Security":
      "Denied Persons List (DPL) - Bureau of Industry and Security",
    "Sectoral_Sanctions_Identifications_List_(SSI)_-_Treasury_Department":
      "Sectoral Sanctions Identifications List (SSI) - Treasury Department",
    "Unverified_List_(UVL)_-_Bureau_of_Industry_and_Security":
      "Unverified List (UVL) - Bureau of Industry and Security",
    "Nonproliferation_Sanctions_(ISN)_-_State_Department":
      "Nonproliferation Sanctions (ISN) - State Department",
    "Palestinian_Legislative_Council_List_(PLC)_-_Treasury_Department":
      "Palestinian Legislative Council List (PLC) - Treasury Department",
    "Military_End_User_(MEU)_List_-_Bureau_of_Industry_and_Security":
      "Military End User (MEU) List - Bureau of Industry and Security",
    "Non-SDN_Chinese_Military-Industrial_Complex_Companies_List_(CMIC)_-_Treasury_Department":
      "Non-SDN Chinese Military-Industrial Complex Companies List (CMIC) - Treasury Department",
    "Non-SDN_Menu-Based_Sanctions_List_(NS-MBS_List)_-_Treasury_Department":
      "Non-SDN Menu-Based Sanctions List (NS-MBS List) - Treasury Department",
    "Foreign_Sanctions_Evaders_(FSE)_-_Treasury_Department":
      "Foreign Sanctions Evaders (FSE) - Treasury Department",
    "Capta_List_(CAP)_-_Treasury_Department":
      "Capta List (CAP) - Treasury Department",
  } as const;

export type SanctionsScreeningHitListResponseDataItemFuzzySearchHitAnyOfAlgorithm =
  (typeof SanctionsScreeningHitListResponseDataItemFuzzySearchHitAnyOfAlgorithm)[keyof typeof SanctionsScreeningHitListResponseDataItemFuzzySearchHitAnyOfAlgorithm];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SanctionsScreeningHitListResponseDataItemFuzzySearchHitAnyOfAlgorithm =
  {
    jaro_winkler: "jaro_winkler",
    word_aligned_jaro_winkler: "word_aligned_jaro_winkler",
    word_aligned_soundex: "word_aligned_soundex",
    ending_anchored_jaro_winkler: "ending_anchored_jaro_winkler",
  } as const;

export type SanctionsScreeningHitListResponseDataItemFuzzySearchHitAnyOf = {
  addresses: string[];
  algorithm: SanctionsScreeningHitListResponseDataItemFuzzySearchHitAnyOfAlgorithm;
  date_of_births: string[];
  entry_external_id: string;
  entry_source: SanctionsScreeningHitListResponseDataItemFuzzySearchHitAnyOfEntrySource;
  entry_type: SanctionsScreeningHitListResponseDataItemFuzzySearchHitAnyOfEntryType;
  field: SanctionsScreeningHitListResponseDataItemFuzzySearchHitAnyOfField;
  name: string;
  other_names: string[];
  place_of_births: string[];
  /** @pattern ^-?\d+(\.\d+)?$ */
  score: string;
};

export type SanctionsScreeningAllowlistTokenPostResponseReviewer =
  (typeof SanctionsScreeningAllowlistTokenPostResponseReviewer)[keyof typeof SanctionsScreeningAllowlistTokenPostResponseReviewer];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SanctionsScreeningAllowlistTokenPostResponseReviewer = {
  increase: "increase",
  grasshopper: "grasshopper",
  core_bank: "core_bank",
} as const;

export interface SanctionsScreeningAllowlistTokenPostResponse {
  created_at: string;
  created_by_email: string;
  id: string;
  reviewer: SanctionsScreeningAllowlistTokenPostResponseReviewer;
  token: string;
}

export interface SanctionsScreeningAllowlistTokenPostParameters {
  note: string;
  token: string;
}

export type SanctionsScreeningAllowlistTokenListResponseNextCursor =
  | string
  | null;

export type SanctionsScreeningAllowlistTokenListResponseDataItemReviewer =
  (typeof SanctionsScreeningAllowlistTokenListResponseDataItemReviewer)[keyof typeof SanctionsScreeningAllowlistTokenListResponseDataItemReviewer];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SanctionsScreeningAllowlistTokenListResponseDataItemReviewer = {
  increase: "increase",
  grasshopper: "grasshopper",
  core_bank: "core_bank",
} as const;

export type SanctionsScreeningAllowlistTokenListResponseDataItem = {
  created_at: string;
  created_by_email: string;
  id: string;
  reviewer: SanctionsScreeningAllowlistTokenListResponseDataItemReviewer;
  token: string;
};

export interface SanctionsScreeningAllowlistTokenListResponse {
  data: SanctionsScreeningAllowlistTokenListResponseDataItem[];
  next_cursor: SanctionsScreeningAllowlistTokenListResponseNextCursor;
}

export type SanctionsScreeningAllowlistTokenGetResponseReviewer =
  (typeof SanctionsScreeningAllowlistTokenGetResponseReviewer)[keyof typeof SanctionsScreeningAllowlistTokenGetResponseReviewer];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SanctionsScreeningAllowlistTokenGetResponseReviewer = {
  increase: "increase",
  grasshopper: "grasshopper",
  core_bank: "core_bank",
} as const;

export interface SanctionsScreeningAllowlistTokenGetResponse {
  created_at: string;
  created_by_email: string;
  id: string;
  reviewer: SanctionsScreeningAllowlistTokenGetResponseReviewer;
  token: string;
}

export type SanctionsScreeningAllowlistTokenArchiveDirectivePostResponseReviewer =
  (typeof SanctionsScreeningAllowlistTokenArchiveDirectivePostResponseReviewer)[keyof typeof SanctionsScreeningAllowlistTokenArchiveDirectivePostResponseReviewer];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SanctionsScreeningAllowlistTokenArchiveDirectivePostResponseReviewer =
  {
    increase: "increase",
    grasshopper: "grasshopper",
    core_bank: "core_bank",
  } as const;

export interface SanctionsScreeningAllowlistTokenArchiveDirectivePostResponse {
  created_at: string;
  created_by_email: string;
  id: string;
  reviewer: SanctionsScreeningAllowlistTokenArchiveDirectivePostResponseReviewer;
  token: string;
}

export type RoutingNumberPatchResponseFinancialInstitutionWebsite =
  | string
  | null;

export type RoutingNumberPatchResponseFinancialInstitutionIconUrl =
  | string
  | null;

export interface RoutingNumberPatchResponse {
  created_at: string;
  fedach_enabled: boolean;
  fedwire_enabled: boolean;
  financial_institution_icon_url: RoutingNumberPatchResponseFinancialInstitutionIconUrl;
  financial_institution_website: RoutingNumberPatchResponseFinancialInstitutionWebsite;
  id: string;
  name: string;
  pretty_name: string;
  real_time_payments_enabled: boolean;
  routing_number: string;
  updated_at: string;
}

export interface RoutingNumberPatchParameters {
  financial_institution_icon_url?: string;
  financial_institution_website?: string;
}

export type RoutingNumberListResponseNextCursor = string | null;

export type RoutingNumberListResponseDataItemFinancialInstitutionWebsite =
  | string
  | null;

export type RoutingNumberListResponseDataItemFinancialInstitutionIconUrl =
  | string
  | null;

export type RoutingNumberListResponseDataItem = {
  created_at: string;
  fedach_enabled: boolean;
  fedwire_enabled: boolean;
  financial_institution_icon_url: RoutingNumberListResponseDataItemFinancialInstitutionIconUrl;
  financial_institution_website: RoutingNumberListResponseDataItemFinancialInstitutionWebsite;
  id: string;
  name: string;
  pretty_name: string;
  real_time_payments_enabled: boolean;
  routing_number: string;
  updated_at: string;
};

export interface RoutingNumberListResponse {
  data: RoutingNumberListResponseDataItem[];
  next_cursor: RoutingNumberListResponseNextCursor;
}

export type RoleListResponseNextCursor = string | null;

export interface RoleListResponse {
  data: RoleListResponseDataItem[];
  next_cursor: RoleListResponseNextCursor;
}

export type RoleListResponseDataItemTitle =
  (typeof RoleListResponseDataItemTitle)[keyof typeof RoleListResponseDataItemTitle];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const RoleListResponseDataItemTitle = {
  administrator: "administrator",
  owner: "owner",
  developer: "developer",
  controller: "controller",
  clerk: "clerk",
  compliance_v2: "compliance_v2",
  accountant: "accountant",
  viewer: "viewer",
} as const;

export type RoleListResponseDataItem = {
  created_at: string;
  deleted_at: RoleListResponseDataItemDeletedAt;
  email: string;
  email_verification_status: RoleListResponseDataItemEmailVerificationStatus;
  enrichment: RoleListResponseDataItemEnrichment;
  group_id: string;
  group_name: RoleListResponseDataItemGroupName;
  id: string;
  latest_session: RoleListResponseDataItemLatestSession;
  require_one_time_password: boolean;
  title: RoleListResponseDataItemTitle;
  user_id: string;
};

export type RoleListResponseDataItemLatestSessionAnyOfEnvironment =
  (typeof RoleListResponseDataItemLatestSessionAnyOfEnvironment)[keyof typeof RoleListResponseDataItemLatestSessionAnyOfEnvironment];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const RoleListResponseDataItemLatestSessionAnyOfEnvironment = {
  production: "production",
  sandbox: "sandbox",
} as const;

export type RoleListResponseDataItemLatestSessionAnyOf = {
  created_at: string;
  environment: RoleListResponseDataItemLatestSessionAnyOfEnvironment;
  id: string;
  role_id: string;
};

export type RoleListResponseDataItemLatestSession =
  RoleListResponseDataItemLatestSessionAnyOf | null;

export type RoleListResponseDataItemGroupName = string | null;

export type RoleListResponseDataItemEnrichmentAnyOf = {
  created_at: string;
  results: unknown;
};

export type RoleListResponseDataItemEnrichment =
  RoleListResponseDataItemEnrichmentAnyOf | null;

export type RoleListResponseDataItemEmailVerificationStatus =
  (typeof RoleListResponseDataItemEmailVerificationStatus)[keyof typeof RoleListResponseDataItemEmailVerificationStatus];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const RoleListResponseDataItemEmailVerificationStatus = {
  pending_verification: "pending_verification",
  ignored: "ignored",
  verified: "verified",
  requires_attention: "requires_attention",
} as const;

export type RoleListResponseDataItemDeletedAt = string | null;

export interface ResultSuppressDirectivePostResponse {
  message: string;
}

export type ResultSuppressDirectivePostParametersService =
  (typeof ResultSuppressDirectivePostParametersService)[keyof typeof ResultSuppressDirectivePostParametersService];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ResultSuppressDirectivePostParametersService = {
  accounting: "accounting",
  administration: "administration",
  api: "api",
  context: "context",
  dashboard: "dashboard",
  databasemanager: "databasemanager",
  dummyservice: "dummyservice",
  federalreservegateway: "federalreservegateway",
  ibmmqproxy: "ibmmqproxy",
  imageprocessing: "imageprocessing",
  operations: "operations",
  sandbox: "sandbox",
  site: "site",
  tooling: "tooling",
  webhookproxy: "webhookproxy",
  vault_acquiring: "vault_acquiring",
  vault_brand: "vault_brand",
  vault_threedsecure: "vault_threedsecure",
} as const;

export type ResultSuppressDirectivePostParametersActivity =
  (typeof ResultSuppressDirectivePostParametersActivity)[keyof typeof ResultSuppressDirectivePostParametersActivity];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ResultSuppressDirectivePostParametersActivity = {
  check: "check",
  obligation: "obligation",
  task: "task",
} as const;

export interface ResultSuppressDirectivePostParameters {
  activity: ResultSuppressDirectivePostParametersActivity;
  expires_at: string;
  name: string;
  reraise_on_failure_reason_change: boolean;
  service: ResultSuppressDirectivePostParametersService;
}

export interface ResultSummaryGetResponse {
  failing: number;
  succeeding: number;
  suppressed_failing: number;
  suppressed_succeeding: number;
}

export type ResultStatusListResponseNextCursor = string | null;

export interface ResultStatusListResponse {
  data: ResultStatusListResponseDataItem[];
  next_cursor: ResultStatusListResponseNextCursor;
}

export type ResultStatusListResponseDataItemSuppressionExpiresAt =
  | string
  | null;

export type ResultStatusListResponseDataItemStatus =
  (typeof ResultStatusListResponseDataItemStatus)[keyof typeof ResultStatusListResponseDataItemStatus];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ResultStatusListResponseDataItemStatus = {
  succeeding: "succeeding",
  failing: "failing",
  retired: "retired",
} as const;

export type ResultStatusListResponseDataItemService =
  (typeof ResultStatusListResponseDataItemService)[keyof typeof ResultStatusListResponseDataItemService];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ResultStatusListResponseDataItemService = {
  accounting: "accounting",
  administration: "administration",
  api: "api",
  context: "context",
  dashboard: "dashboard",
  databasemanager: "databasemanager",
  dummyservice: "dummyservice",
  federalreservegateway: "federalreservegateway",
  ibmmqproxy: "ibmmqproxy",
  imageprocessing: "imageprocessing",
  operations: "operations",
  sandbox: "sandbox",
  site: "site",
  tooling: "tooling",
  webhookproxy: "webhookproxy",
  vault_acquiring: "vault_acquiring",
  vault_brand: "vault_brand",
  vault_threedsecure: "vault_threedsecure",
} as const;

export type ResultStatusListResponseDataItemLatestFailureReason = string | null;

export type ResultStatusListResponseDataItemLatestCreatedAt = string | null;

export type ResultStatusListResponseDataItemFailingSince = string | null;

export type ResultStatusListResponseDataItemActivity =
  (typeof ResultStatusListResponseDataItemActivity)[keyof typeof ResultStatusListResponseDataItemActivity];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ResultStatusListResponseDataItemActivity = {
  check: "check",
  obligation: "obligation",
  task: "task",
} as const;

export type ResultStatusListResponseDataItem = {
  activity: ResultStatusListResponseDataItemActivity;
  failing_since: ResultStatusListResponseDataItemFailingSince;
  id: string;
  latest_created_at: ResultStatusListResponseDataItemLatestCreatedAt;
  latest_failure_reason: ResultStatusListResponseDataItemLatestFailureReason;
  name: string;
  service: ResultStatusListResponseDataItemService;
  status: ResultStatusListResponseDataItemStatus;
  suppression_expires_at: ResultStatusListResponseDataItemSuppressionExpiresAt;
};

export type ResultStatusGetResponseSuppressionExpiresAt = string | null;

export type ResultStatusGetResponseStatus =
  (typeof ResultStatusGetResponseStatus)[keyof typeof ResultStatusGetResponseStatus];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ResultStatusGetResponseStatus = {
  succeeding: "succeeding",
  failing: "failing",
  retired: "retired",
} as const;

export type ResultStatusGetResponseService =
  (typeof ResultStatusGetResponseService)[keyof typeof ResultStatusGetResponseService];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ResultStatusGetResponseService = {
  accounting: "accounting",
  administration: "administration",
  api: "api",
  context: "context",
  dashboard: "dashboard",
  databasemanager: "databasemanager",
  dummyservice: "dummyservice",
  federalreservegateway: "federalreservegateway",
  ibmmqproxy: "ibmmqproxy",
  imageprocessing: "imageprocessing",
  operations: "operations",
  sandbox: "sandbox",
  site: "site",
  tooling: "tooling",
  webhookproxy: "webhookproxy",
  vault_acquiring: "vault_acquiring",
  vault_brand: "vault_brand",
  vault_threedsecure: "vault_threedsecure",
} as const;

export type ResultStatusGetResponseLatestFailureReason = string | null;

export type ResultStatusGetResponseLatestCreatedAt = string | null;

export type ResultStatusGetResponseFailingSince = string | null;

export type ResultStatusGetResponseActivity =
  (typeof ResultStatusGetResponseActivity)[keyof typeof ResultStatusGetResponseActivity];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ResultStatusGetResponseActivity = {
  check: "check",
  obligation: "obligation",
  task: "task",
} as const;

export interface ResultStatusGetResponse {
  activity: ResultStatusGetResponseActivity;
  failing_since: ResultStatusGetResponseFailingSince;
  id: string;
  latest_created_at: ResultStatusGetResponseLatestCreatedAt;
  latest_failure_reason: ResultStatusGetResponseLatestFailureReason;
  name: string;
  service: ResultStatusGetResponseService;
  status: ResultStatusGetResponseStatus;
  suppression_expires_at: ResultStatusGetResponseSuppressionExpiresAt;
}

export interface ResultRetryDirectivePostResponse {
  message: string;
}

export interface ResultRetryDirectivePostParameters {
  result_id: string;
}

export type ResultPostResponseService =
  (typeof ResultPostResponseService)[keyof typeof ResultPostResponseService];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ResultPostResponseService = {
  accounting: "accounting",
  administration: "administration",
  api: "api",
  context: "context",
  dashboard: "dashboard",
  databasemanager: "databasemanager",
  dummyservice: "dummyservice",
  federalreservegateway: "federalreservegateway",
  ibmmqproxy: "ibmmqproxy",
  imageprocessing: "imageprocessing",
  operations: "operations",
  sandbox: "sandbox",
  site: "site",
  tooling: "tooling",
  webhookproxy: "webhookproxy",
  vault_acquiring: "vault_acquiring",
  vault_brand: "vault_brand",
  vault_threedsecure: "vault_threedsecure",
} as const;

export type ResultPostResponseFailureReason = string | null;

export type ResultPostResponseActivity =
  (typeof ResultPostResponseActivity)[keyof typeof ResultPostResponseActivity];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ResultPostResponseActivity = {
  check: "check",
  obligation: "obligation",
  task: "task",
} as const;

export interface ResultPostResponse {
  activity: ResultPostResponseActivity;
  created_at: string;
  failure_reason: ResultPostResponseFailureReason;
  id: string;
  name: string;
  service: ResultPostResponseService;
  success: boolean;
}

export type ResultPostParametersService =
  (typeof ResultPostParametersService)[keyof typeof ResultPostParametersService];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ResultPostParametersService = {
  accounting: "accounting",
  administration: "administration",
  api: "api",
  context: "context",
  dashboard: "dashboard",
  databasemanager: "databasemanager",
  dummyservice: "dummyservice",
  federalreservegateway: "federalreservegateway",
  ibmmqproxy: "ibmmqproxy",
  imageprocessing: "imageprocessing",
  operations: "operations",
  sandbox: "sandbox",
  site: "site",
  tooling: "tooling",
  webhookproxy: "webhookproxy",
  vault_acquiring: "vault_acquiring",
  vault_brand: "vault_brand",
  vault_threedsecure: "vault_threedsecure",
} as const;

export type ResultPostParametersActivity =
  (typeof ResultPostParametersActivity)[keyof typeof ResultPostParametersActivity];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ResultPostParametersActivity = {
  check: "check",
  obligation: "obligation",
  task: "task",
} as const;

export interface ResultPostParameters {
  activity: ResultPostParametersActivity;
  duration_milliseconds?: number;
  failure_reason?: string;
  name: string;
  notify_changed_failures: boolean;
  notify_email: boolean;
  notify_slack: boolean;
  service: ResultPostParametersService;
  success: boolean;
}

export type ResultListResponseNextCursor = string | null;

export interface ResultListResponse {
  data: ResultListResponseDataItem[];
  next_cursor: ResultListResponseNextCursor;
}

export type ResultListResponseDataItemSuppressionExpiresAt = string | null;

export type ResultListResponseDataItemService =
  (typeof ResultListResponseDataItemService)[keyof typeof ResultListResponseDataItemService];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ResultListResponseDataItemService = {
  accounting: "accounting",
  administration: "administration",
  api: "api",
  context: "context",
  dashboard: "dashboard",
  databasemanager: "databasemanager",
  dummyservice: "dummyservice",
  federalreservegateway: "federalreservegateway",
  ibmmqproxy: "ibmmqproxy",
  imageprocessing: "imageprocessing",
  operations: "operations",
  sandbox: "sandbox",
  site: "site",
  tooling: "tooling",
  webhookproxy: "webhookproxy",
  vault_acquiring: "vault_acquiring",
  vault_brand: "vault_brand",
  vault_threedsecure: "vault_threedsecure",
} as const;

export type ResultListResponseDataItemLatestFailureReason = string | null;

export type ResultListResponseDataItemFailingSince = string | null;

export type ResultListResponseDataItemActivity =
  (typeof ResultListResponseDataItemActivity)[keyof typeof ResultListResponseDataItemActivity];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ResultListResponseDataItemActivity = {
  check: "check",
  obligation: "obligation",
  task: "task",
} as const;

export type ResultListResponseDataItem = {
  activity: ResultListResponseDataItemActivity;
  failing_since: ResultListResponseDataItemFailingSince;
  id: string;
  latest_created_at: string;
  latest_failure_reason: ResultListResponseDataItemLatestFailureReason;
  latest_success: boolean;
  name: string;
  result_status_id: string;
  service: ResultListResponseDataItemService;
  suppression_expires_at: ResultListResponseDataItemSuppressionExpiresAt;
};

export type ResultEvaluationListResponseNextCursor = string | null;

export interface ResultEvaluationListResponse {
  data: ResultEvaluationListResponseDataItem[];
  next_cursor: ResultEvaluationListResponseNextCursor;
}

export type ResultEvaluationListResponseDataItemFailureReason = string | null;

export type ResultEvaluationListResponseDataItemDurationMilliseconds =
  | number
  | null;

export type ResultEvaluationListResponseDataItem = {
  created_at: string;
  duration_milliseconds: ResultEvaluationListResponseDataItemDurationMilliseconds;
  failure_reason: ResultEvaluationListResponseDataItemFailureReason;
  id: string;
  success: boolean;
};

export interface ResultDeleteResponse {
  message: string;
}

export type ReserveAccountsOverviewGetResponseReserveAccountsItemRestrictions =
  | "no_deposits"
  | "no_withdrawals"
  | null;

export type ReserveAccountsOverviewGetResponseReserveAccountsItem = {
  bank: ReserveAccountsOverviewGetResponseReserveAccountsItemBank;
  current_balance: number;
  group_friendly_nickname: string;
  group_id: string;
  group_name: ReserveAccountsOverviewGetResponseReserveAccountsItemGroupName;
  id: string;
  programs: ReserveAccountsOverviewGetResponseReserveAccountsItemProgramsItem[];
  restrictions: ReserveAccountsOverviewGetResponseReserveAccountsItemRestrictions;
  total_expected_minimum_balance: number;
};

export interface ReserveAccountsOverviewGetResponse {
  first_internet_bank_servicer_required_reserve: ReserveAccountsOverviewGetResponseFirstInternetBankServicerRequiredReserve;
  reserve_accounts: ReserveAccountsOverviewGetResponseReserveAccountsItem[];
}

export type ReserveAccountsOverviewGetResponseReserveAccountsItemProgramsItemMinimumExpectedReserve =
  number | null;

export type ReserveAccountsOverviewGetResponseReserveAccountsItemProgramsItem =
  {
    first_internet_bank_required_reserve: ReserveAccountsOverviewGetResponseReserveAccountsItemProgramsItemFirstInternetBankRequiredReserve;
    id: string;
    minimum_expected_reserve: ReserveAccountsOverviewGetResponseReserveAccountsItemProgramsItemMinimumExpectedReserve;
    name: string;
  };

export type ReserveAccountsOverviewGetResponseReserveAccountsItemProgramsItemFirstInternetBankRequiredReserveAnyOf =
  {
    id: string;
    month_starting: string;
    required_minimum_balance: number;
  };

export type ReserveAccountsOverviewGetResponseReserveAccountsItemProgramsItemFirstInternetBankRequiredReserve =
  ReserveAccountsOverviewGetResponseReserveAccountsItemProgramsItemFirstInternetBankRequiredReserveAnyOf | null;

export type ReserveAccountsOverviewGetResponseReserveAccountsItemGroupName =
  | string
  | null;

export type ReserveAccountsOverviewGetResponseReserveAccountsItemBank =
  (typeof ReserveAccountsOverviewGetResponseReserveAccountsItemBank)[keyof typeof ReserveAccountsOverviewGetResponseReserveAccountsItemBank];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ReserveAccountsOverviewGetResponseReserveAccountsItemBank = {
  blue_ridge_bank: "blue_ridge_bank",
  core_bank: "core_bank",
  first_internet_bank: "first_internet_bank",
  global_innovations_bank: "global_innovations_bank",
  grasshopper_bank: "grasshopper_bank",
} as const;

export type ReserveAccountsOverviewGetResponseFirstInternetBankServicerRequiredReserveAnyOf =
  {
    computed_reserve_target: number;
    effective_date: string;
    required_reserve: number;
    total_program_reserve_account_balance: number;
  };

export type ReserveAccountsOverviewGetResponseFirstInternetBankServicerRequiredReserve =
  ReserveAccountsOverviewGetResponseFirstInternetBankServicerRequiredReserveAnyOf | null;

export type RegisterPostResponseOneTimePasswordStatus =
  (typeof RegisterPostResponseOneTimePasswordStatus)[keyof typeof RegisterPostResponseOneTimePasswordStatus];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const RegisterPostResponseOneTimePasswordStatus = {
  confirmed: "confirmed",
  pending_confirming: "pending_confirming",
} as const;

export interface RegisterPostResponse {
  message: string;
  one_time_password_status: RegisterPostResponseOneTimePasswordStatus;
}

export interface RegisterPostParameters {
  code: string;
  email: string;
  password: string;
}

export type RealTimePaymentsPrefundedPositionOverviewGetResponseSupplementalFundingsItem =
  {
    amount: number;
    created_at: string;
    id: string;
    status: string;
    wire_transfer_id: string;
  };

export type RealTimePaymentsPrefundedPositionOverviewGetResponseReconciliationStatusNotificationDataItem =
  {
    id: string;
    new_opening_balance: number;
    new_reconciliation_window_date: string;
  };

export interface RealTimePaymentsPrefundedPositionOverviewGetResponse {
  bank: RealTimePaymentsPrefundedPositionOverviewGetResponseBank;
  participant_id: string;
  prefunded_position: RealTimePaymentsPrefundedPositionOverviewGetResponsePrefundedPosition;
  reconciliation_status_notification_data: RealTimePaymentsPrefundedPositionOverviewGetResponseReconciliationStatusNotificationDataItem[];
  supplemental_fundings: RealTimePaymentsPrefundedPositionOverviewGetResponseSupplementalFundingsItem[];
}

export type RealTimePaymentsPrefundedPositionOverviewGetResponsePrefundedPositionLatestBaselineAnyOfSummarySinceThen =
  {
    amount_received: number;
    amount_sent: number;
    count_received: number;
    count_sent: number;
  };

export type RealTimePaymentsPrefundedPositionOverviewGetResponsePrefundedPositionLatestBaselineAnyOf =
  {
    amount: number;
    effective_at: string;
    source_id: string;
    summary_since_then: RealTimePaymentsPrefundedPositionOverviewGetResponsePrefundedPositionLatestBaselineAnyOfSummarySinceThen;
  };

export type RealTimePaymentsPrefundedPositionOverviewGetResponsePrefundedPositionLatestBaseline =
  RealTimePaymentsPrefundedPositionOverviewGetResponsePrefundedPositionLatestBaselineAnyOf | null;

export type RealTimePaymentsPrefundedPositionOverviewGetResponsePrefundedPosition =
  {
    current: number;
    latest_baseline: RealTimePaymentsPrefundedPositionOverviewGetResponsePrefundedPositionLatestBaseline;
    minimum: number;
    minimum_topup: number;
    target: number;
  };

export type RealTimePaymentsPrefundedPositionOverviewGetResponseBank =
  | "blue_ridge_bank"
  | "core_bank"
  | "first_internet_bank"
  | "global_innovations_bank"
  | "grasshopper_bank"
  | null;

export type RampBusinessAccountOnboardingSurveyListResponseNextCursor =
  | string
  | null;

export interface RampBusinessAccountOnboardingSurveyListResponse {
  data: RampBusinessAccountOnboardingSurveyListResponseDataItem[];
  next_cursor: RampBusinessAccountOnboardingSurveyListResponseNextCursor;
}

export type RampBusinessAccountOnboardingSurveyListResponseDataItemMonthlyInternationalWireAmount =
  (typeof RampBusinessAccountOnboardingSurveyListResponseDataItemMonthlyInternationalWireAmount)[keyof typeof RampBusinessAccountOnboardingSurveyListResponseDataItemMonthlyInternationalWireAmount];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const RampBusinessAccountOnboardingSurveyListResponseDataItemMonthlyInternationalWireAmount =
  {
    zero: "zero",
    zero_to_ten_thousand_dollars: "zero_to_ten_thousand_dollars",
    ten_thousand_to_fifty_thousand_dollars:
      "ten_thousand_to_fifty_thousand_dollars",
    fifty_thousand_to_one_hundred_thousand_dollars:
      "fifty_thousand_to_one_hundred_thousand_dollars",
    more_than_one_hundred_thousand_dollars:
      "more_than_one_hundred_thousand_dollars",
  } as const;

export type RampBusinessAccountOnboardingSurveyListResponseDataItemMonthlyDomesticWireAmount =
  (typeof RampBusinessAccountOnboardingSurveyListResponseDataItemMonthlyDomesticWireAmount)[keyof typeof RampBusinessAccountOnboardingSurveyListResponseDataItemMonthlyDomesticWireAmount];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const RampBusinessAccountOnboardingSurveyListResponseDataItemMonthlyDomesticWireAmount =
  {
    zero: "zero",
    zero_to_ten_thousand_dollars: "zero_to_ten_thousand_dollars",
    ten_thousand_to_fifty_thousand_dollars:
      "ten_thousand_to_fifty_thousand_dollars",
    fifty_thousand_to_one_hundred_thousand_dollars:
      "fifty_thousand_to_one_hundred_thousand_dollars",
    more_than_one_hundred_thousand_dollars:
      "more_than_one_hundred_thousand_dollars",
  } as const;

export type RampBusinessAccountOnboardingSurveyListResponseDataItemMonthlyDepositAmount =
  (typeof RampBusinessAccountOnboardingSurveyListResponseDataItemMonthlyDepositAmount)[keyof typeof RampBusinessAccountOnboardingSurveyListResponseDataItemMonthlyDepositAmount];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const RampBusinessAccountOnboardingSurveyListResponseDataItemMonthlyDepositAmount =
  {
    zero: "zero",
    zero_to_ten_thousand_dollars: "zero_to_ten_thousand_dollars",
    ten_thousand_to_fifty_thousand_dollars:
      "ten_thousand_to_fifty_thousand_dollars",
    fifty_thousand_to_one_hundred_thousand_dollars:
      "fifty_thousand_to_one_hundred_thousand_dollars",
    more_than_one_hundred_thousand_dollars:
      "more_than_one_hundred_thousand_dollars",
  } as const;

export type RampBusinessAccountOnboardingSurveyListResponseDataItemMonthlyAchOriginationAmount =
  (typeof RampBusinessAccountOnboardingSurveyListResponseDataItemMonthlyAchOriginationAmount)[keyof typeof RampBusinessAccountOnboardingSurveyListResponseDataItemMonthlyAchOriginationAmount];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const RampBusinessAccountOnboardingSurveyListResponseDataItemMonthlyAchOriginationAmount =
  {
    zero: "zero",
    zero_to_ten_thousand_dollars: "zero_to_ten_thousand_dollars",
    ten_thousand_to_fifty_thousand_dollars:
      "ten_thousand_to_fifty_thousand_dollars",
    fifty_thousand_to_one_hundred_thousand_dollars:
      "fifty_thousand_to_one_hundred_thousand_dollars",
    more_than_one_hundred_thousand_dollars:
      "more_than_one_hundred_thousand_dollars",
  } as const;

export type RampBusinessAccountOnboardingSurveyListResponseDataItemEntityName =
  | string
  | null;

export type RampBusinessAccountOnboardingSurveyListResponseDataItemEntityId =
  | string
  | null;

export type RampBusinessAccountOnboardingSurveyListResponseDataItemArchivedAt =
  | string
  | null;

export type RampBusinessAccountOnboardingSurveyListResponseDataItem = {
  account_id: string;
  archived_at: RampBusinessAccountOnboardingSurveyListResponseDataItemArchivedAt;
  created_at: string;
  engages_in_loans_secured_by_cash_collateral_and_marketable_securities: boolean;
  entity_id: RampBusinessAccountOnboardingSurveyListResponseDataItemEntityId;
  entity_name: RampBusinessAccountOnboardingSurveyListResponseDataItemEntityName;
  group_id: string;
  id: string;
  is_auctioneer: boolean;
  is_broker_or_dealer_in_securities: boolean;
  is_cash_intensive_business: boolean;
  is_casino: boolean;
  is_chartering_or_operation_of_ships_buses_or_aircraft: boolean;
  is_check_casher: boolean;
  is_dealer_in_antiquities: boolean;
  is_dealer_in_precious_metals_stones_and_jewels: boolean;
  is_dealer_or_broker_in_virtual_currency: boolean;
  is_deposit_broker: boolean;
  is_financial_services_provider: boolean;
  is_gaming: boolean;
  is_internet_gambling_related: boolean;
  is_marijuana_related: boolean;
  is_non_bank_financial_institution: boolean;
  is_non_profit_organization_or_charity: boolean;
  is_pawn_broker: boolean;
  is_professional_service_provider: boolean;
  is_purchaser_or_seller_of_vehicles: boolean;
  is_senior_foreign_political_figure_or_associate: boolean;
  is_title_insurance_operations_and_real_estate_closing: boolean;
  is_trade_union: boolean;
  issues_negotiable_instruments: boolean;
  issues_stored_value_cards: boolean;
  monthly_ach_origination_amount: RampBusinessAccountOnboardingSurveyListResponseDataItemMonthlyAchOriginationAmount;
  monthly_deposit_amount: RampBusinessAccountOnboardingSurveyListResponseDataItemMonthlyDepositAmount;
  monthly_domestic_wire_amount: RampBusinessAccountOnboardingSurveyListResponseDataItemMonthlyDomesticWireAmount;
  monthly_international_wire_amount: RampBusinessAccountOnboardingSurveyListResponseDataItemMonthlyInternationalWireAmount;
  receives_credit_debit_or_stored_value_card_payments: boolean;
};

export type RampBusinessAccountOnboardingSurveyGetResponseMonthlyInternationalWireAmount =
  (typeof RampBusinessAccountOnboardingSurveyGetResponseMonthlyInternationalWireAmount)[keyof typeof RampBusinessAccountOnboardingSurveyGetResponseMonthlyInternationalWireAmount];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const RampBusinessAccountOnboardingSurveyGetResponseMonthlyInternationalWireAmount =
  {
    zero: "zero",
    zero_to_ten_thousand_dollars: "zero_to_ten_thousand_dollars",
    ten_thousand_to_fifty_thousand_dollars:
      "ten_thousand_to_fifty_thousand_dollars",
    fifty_thousand_to_one_hundred_thousand_dollars:
      "fifty_thousand_to_one_hundred_thousand_dollars",
    more_than_one_hundred_thousand_dollars:
      "more_than_one_hundred_thousand_dollars",
  } as const;

export type RampBusinessAccountOnboardingSurveyGetResponseMonthlyDomesticWireAmount =
  (typeof RampBusinessAccountOnboardingSurveyGetResponseMonthlyDomesticWireAmount)[keyof typeof RampBusinessAccountOnboardingSurveyGetResponseMonthlyDomesticWireAmount];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const RampBusinessAccountOnboardingSurveyGetResponseMonthlyDomesticWireAmount =
  {
    zero: "zero",
    zero_to_ten_thousand_dollars: "zero_to_ten_thousand_dollars",
    ten_thousand_to_fifty_thousand_dollars:
      "ten_thousand_to_fifty_thousand_dollars",
    fifty_thousand_to_one_hundred_thousand_dollars:
      "fifty_thousand_to_one_hundred_thousand_dollars",
    more_than_one_hundred_thousand_dollars:
      "more_than_one_hundred_thousand_dollars",
  } as const;

export type RampBusinessAccountOnboardingSurveyGetResponseMonthlyDepositAmount =
  (typeof RampBusinessAccountOnboardingSurveyGetResponseMonthlyDepositAmount)[keyof typeof RampBusinessAccountOnboardingSurveyGetResponseMonthlyDepositAmount];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const RampBusinessAccountOnboardingSurveyGetResponseMonthlyDepositAmount =
  {
    zero: "zero",
    zero_to_ten_thousand_dollars: "zero_to_ten_thousand_dollars",
    ten_thousand_to_fifty_thousand_dollars:
      "ten_thousand_to_fifty_thousand_dollars",
    fifty_thousand_to_one_hundred_thousand_dollars:
      "fifty_thousand_to_one_hundred_thousand_dollars",
    more_than_one_hundred_thousand_dollars:
      "more_than_one_hundred_thousand_dollars",
  } as const;

export type RampBusinessAccountOnboardingSurveyGetResponseMonthlyAchOriginationAmount =
  (typeof RampBusinessAccountOnboardingSurveyGetResponseMonthlyAchOriginationAmount)[keyof typeof RampBusinessAccountOnboardingSurveyGetResponseMonthlyAchOriginationAmount];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const RampBusinessAccountOnboardingSurveyGetResponseMonthlyAchOriginationAmount =
  {
    zero: "zero",
    zero_to_ten_thousand_dollars: "zero_to_ten_thousand_dollars",
    ten_thousand_to_fifty_thousand_dollars:
      "ten_thousand_to_fifty_thousand_dollars",
    fifty_thousand_to_one_hundred_thousand_dollars:
      "fifty_thousand_to_one_hundred_thousand_dollars",
    more_than_one_hundred_thousand_dollars:
      "more_than_one_hundred_thousand_dollars",
  } as const;

export type RampBusinessAccountOnboardingSurveyGetResponseEntityName =
  | string
  | null;

export type RampBusinessAccountOnboardingSurveyGetResponseEntityId =
  | string
  | null;

export type RampBusinessAccountOnboardingSurveyGetResponseArchivedAt =
  | string
  | null;

export interface RampBusinessAccountOnboardingSurveyGetResponse {
  account_id: string;
  archived_at: RampBusinessAccountOnboardingSurveyGetResponseArchivedAt;
  created_at: string;
  engages_in_loans_secured_by_cash_collateral_and_marketable_securities: boolean;
  entity_id: RampBusinessAccountOnboardingSurveyGetResponseEntityId;
  entity_name: RampBusinessAccountOnboardingSurveyGetResponseEntityName;
  group_id: string;
  id: string;
  is_auctioneer: boolean;
  is_broker_or_dealer_in_securities: boolean;
  is_cash_intensive_business: boolean;
  is_casino: boolean;
  is_chartering_or_operation_of_ships_buses_or_aircraft: boolean;
  is_check_casher: boolean;
  is_dealer_in_antiquities: boolean;
  is_dealer_in_precious_metals_stones_and_jewels: boolean;
  is_dealer_or_broker_in_virtual_currency: boolean;
  is_deposit_broker: boolean;
  is_financial_services_provider: boolean;
  is_gaming: boolean;
  is_internet_gambling_related: boolean;
  is_marijuana_related: boolean;
  is_non_bank_financial_institution: boolean;
  is_non_profit_organization_or_charity: boolean;
  is_pawn_broker: boolean;
  is_professional_service_provider: boolean;
  is_purchaser_or_seller_of_vehicles: boolean;
  is_senior_foreign_political_figure_or_associate: boolean;
  is_title_insurance_operations_and_real_estate_closing: boolean;
  is_trade_union: boolean;
  issues_negotiable_instruments: boolean;
  issues_stored_value_cards: boolean;
  monthly_ach_origination_amount: RampBusinessAccountOnboardingSurveyGetResponseMonthlyAchOriginationAmount;
  monthly_deposit_amount: RampBusinessAccountOnboardingSurveyGetResponseMonthlyDepositAmount;
  monthly_domestic_wire_amount: RampBusinessAccountOnboardingSurveyGetResponseMonthlyDomesticWireAmount;
  monthly_international_wire_amount: RampBusinessAccountOnboardingSurveyGetResponseMonthlyInternationalWireAmount;
  receives_credit_debit_or_stored_value_card_payments: boolean;
}

export interface ProofOfAuthorizationRequestSubmissionPatchResponse {
  ach_transfers: ProofOfAuthorizationRequestSubmissionPatchResponseAchTransfersItem[];
  claim_number: ProofOfAuthorizationRequestSubmissionPatchResponseClaimNumber;
  contact_email: ProofOfAuthorizationRequestSubmissionPatchResponseContactEmail;
  contact_fax: ProofOfAuthorizationRequestSubmissionPatchResponseContactFax;
  contact_name: ProofOfAuthorizationRequestSubmissionPatchResponseContactName;
  created_at: string;
  document_request: ProofOfAuthorizationRequestSubmissionPatchResponseDocumentRequest;
  due_on: string;
  group_id: string;
  id: string;
  submissions: ProofOfAuthorizationRequestSubmissionPatchResponseSubmissionsItem[];
}

export type ProofOfAuthorizationRequestSubmissionPatchResponseSubmissionsItemStatus =
  (typeof ProofOfAuthorizationRequestSubmissionPatchResponseSubmissionsItemStatus)[keyof typeof ProofOfAuthorizationRequestSubmissionPatchResponseSubmissionsItemStatus];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ProofOfAuthorizationRequestSubmissionPatchResponseSubmissionsItemStatus =
  {
    pending_review: "pending_review",
    rejected: "rejected",
    canceled: "canceled",
    pending_sending: "pending_sending",
    sent: "sent",
  } as const;

export type ProofOfAuthorizationRequestSubmissionPatchResponseSubmissionsItemAuthorizerName =
  string | null;

export type ProofOfAuthorizationRequestSubmissionPatchResponseSubmissionsItemAuthorizerIpAddress =
  string | null;

export type ProofOfAuthorizationRequestSubmissionPatchResponseSubmissionsItemAuthorizerEmail =
  string | null;

export type ProofOfAuthorizationRequestSubmissionPatchResponseSubmissionsItemAuthorizerCompany =
  string | null;

export type ProofOfAuthorizationRequestSubmissionPatchResponseSubmissionsItemAdditionalEvidenceFileId =
  string | null;

export type ProofOfAuthorizationRequestSubmissionPatchResponseSubmissionsItem =
  {
    additional_evidence_file_id: ProofOfAuthorizationRequestSubmissionPatchResponseSubmissionsItemAdditionalEvidenceFileId;
    authorization_terms: string;
    authorized_at: string;
    authorizer_company: ProofOfAuthorizationRequestSubmissionPatchResponseSubmissionsItemAuthorizerCompany;
    authorizer_email: ProofOfAuthorizationRequestSubmissionPatchResponseSubmissionsItemAuthorizerEmail;
    authorizer_ip_address: ProofOfAuthorizationRequestSubmissionPatchResponseSubmissionsItemAuthorizerIpAddress;
    authorizer_name: ProofOfAuthorizationRequestSubmissionPatchResponseSubmissionsItemAuthorizerName;
    created_at: string;
    id: string;
    status: ProofOfAuthorizationRequestSubmissionPatchResponseSubmissionsItemStatus;
    updated_at: string;
  };

export type ProofOfAuthorizationRequestSubmissionPatchResponseDocumentRequestStatus =
  (typeof ProofOfAuthorizationRequestSubmissionPatchResponseDocumentRequestStatus)[keyof typeof ProofOfAuthorizationRequestSubmissionPatchResponseDocumentRequestStatus];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ProofOfAuthorizationRequestSubmissionPatchResponseDocumentRequestStatus =
  {
    awaiting_submission: "awaiting_submission",
    awaiting_review: "awaiting_review",
    provisionally_accepted: "provisionally_accepted",
    accepted: "accepted",
    canceled: "canceled",
  } as const;

export type ProofOfAuthorizationRequestSubmissionPatchResponseDocumentRequest =
  {
    created_at: string;
    description: string;
    document_submissions: ProofOfAuthorizationRequestSubmissionPatchResponseDocumentRequestDocumentSubmissionsItem[];
    group_id: string;
    group_name: ProofOfAuthorizationRequestSubmissionPatchResponseDocumentRequestGroupName;
    id: string;
    nondocument_submissions: ProofOfAuthorizationRequestSubmissionPatchResponseDocumentRequestNondocumentSubmissions;
    program_id: string;
    program_name: string;
    reviews: ProofOfAuthorizationRequestSubmissionPatchResponseDocumentRequestReviewsItem[];
    source: unknown;
    status: ProofOfAuthorizationRequestSubmissionPatchResponseDocumentRequestStatus;
  };

export type ProofOfAuthorizationRequestSubmissionPatchResponseDocumentRequestReviewsItemReviewerEmail =
  string | null;

export type ProofOfAuthorizationRequestSubmissionPatchResponseDocumentRequestReviewsItemResult =
  (typeof ProofOfAuthorizationRequestSubmissionPatchResponseDocumentRequestReviewsItemResult)[keyof typeof ProofOfAuthorizationRequestSubmissionPatchResponseDocumentRequestReviewsItemResult];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ProofOfAuthorizationRequestSubmissionPatchResponseDocumentRequestReviewsItemResult =
  {
    accepted: "accepted",
    rejected: "rejected",
    provisionally_accepted: "provisionally_accepted",
  } as const;

export type ProofOfAuthorizationRequestSubmissionPatchResponseDocumentRequestReviewsItemComment =
  string | null;

export type ProofOfAuthorizationRequestSubmissionPatchResponseDocumentRequestReviewsItem =
  {
    comment: ProofOfAuthorizationRequestSubmissionPatchResponseDocumentRequestReviewsItemComment;
    created_at: string;
    id: string;
    result: ProofOfAuthorizationRequestSubmissionPatchResponseDocumentRequestReviewsItemResult;
    reviewer_email: ProofOfAuthorizationRequestSubmissionPatchResponseDocumentRequestReviewsItemReviewerEmail;
  };

export type ProofOfAuthorizationRequestSubmissionPatchResponseDocumentRequestNondocumentSubmissionsProofOfAuthorizationRequestIds =
  string[] | null;

export type ProofOfAuthorizationRequestSubmissionPatchResponseDocumentRequestNondocumentSubmissionsPlatformVendorListSubmissionId =
  string | null;

export type ProofOfAuthorizationRequestSubmissionPatchResponseDocumentRequestNondocumentSubmissionsPlatformFinancialReportSubmissionId =
  string | null;

export type ProofOfAuthorizationRequestSubmissionPatchResponseDocumentRequestNondocumentSubmissionsPlatformComplianceMetricsSubmissionId =
  string | null;

export type ProofOfAuthorizationRequestSubmissionPatchResponseDocumentRequestNondocumentSubmissionsPlatformComplaintListSubmissionId =
  string | null;

export type ProofOfAuthorizationRequestSubmissionPatchResponseDocumentRequestNondocumentSubmissionsDirectUserDiligenceSubmissionId =
  string | null;

export type ProofOfAuthorizationRequestSubmissionPatchResponseDocumentRequestNondocumentSubmissions =
  {
    direct_user_diligence_submission_id: ProofOfAuthorizationRequestSubmissionPatchResponseDocumentRequestNondocumentSubmissionsDirectUserDiligenceSubmissionId;
    platform_complaint_list_submission_id: ProofOfAuthorizationRequestSubmissionPatchResponseDocumentRequestNondocumentSubmissionsPlatformComplaintListSubmissionId;
    platform_compliance_metrics_submission_id: ProofOfAuthorizationRequestSubmissionPatchResponseDocumentRequestNondocumentSubmissionsPlatformComplianceMetricsSubmissionId;
    platform_financial_report_submission_id: ProofOfAuthorizationRequestSubmissionPatchResponseDocumentRequestNondocumentSubmissionsPlatformFinancialReportSubmissionId;
    platform_vendor_list_submission_id: ProofOfAuthorizationRequestSubmissionPatchResponseDocumentRequestNondocumentSubmissionsPlatformVendorListSubmissionId;
    proof_of_authorization_request_ids: ProofOfAuthorizationRequestSubmissionPatchResponseDocumentRequestNondocumentSubmissionsProofOfAuthorizationRequestIds;
  };

export type ProofOfAuthorizationRequestSubmissionPatchResponseDocumentRequestGroupName =
  string | null;

export type ProofOfAuthorizationRequestSubmissionPatchResponseDocumentRequestDocumentSubmissionsItemCreatedByEmail =
  string | null;

export type ProofOfAuthorizationRequestSubmissionPatchResponseDocumentRequestDocumentSubmissionsItem =
  {
    created_at: string;
    created_by_email: ProofOfAuthorizationRequestSubmissionPatchResponseDocumentRequestDocumentSubmissionsItemCreatedByEmail;
    file_id: string;
    file_name: string;
    id: string;
  };

export type ProofOfAuthorizationRequestSubmissionPatchResponseContactName =
  | string
  | null;

export type ProofOfAuthorizationRequestSubmissionPatchResponseContactFax =
  | string
  | null;

export type ProofOfAuthorizationRequestSubmissionPatchResponseContactEmail =
  | string
  | null;

export type ProofOfAuthorizationRequestSubmissionPatchResponseClaimNumber =
  | string
  | null;

export type ProofOfAuthorizationRequestSubmissionPatchResponseAchTransfersItem =
  {
    amount: number;
    currency: string;
    description: string;
    id: string;
  };

export interface ProofOfAuthorizationRequestSubmissionPatchParameters {
  approved: boolean;
}

export type ProgramListResponseNextCursor = string | null;

export interface ProgramListResponse {
  data: ProgramListResponseDataItem[];
  next_cursor: ProgramListResponseNextCursor;
}

export type ProgramListResponseDataItemUsdWireTransfersAutomatedRolling24HourVolumeLimit =
  number | null;

export type ProgramListResponseDataItemUsdRealTimePaymentsAutomatedRolling24HourVolumeLimit =
  number | null;

export type ProgramListResponseDataItemUsdCombinedInboundAutomatedRolling24HourVolumeLimit =
  number | null;

export type ProgramListResponseDataItemUsdCheckTransfersAutomatedRolling24HourVolumeLimit =
  number | null;

export type ProgramListResponseDataItemUsdCheckDepositsAutomatedRolling24HourVolumeLimit =
  number | null;

export type ProgramListResponseDataItemUsdAchDebitTransfersAutomatedRolling24HourVolumeLimit =
  number | null;

export type ProgramListResponseDataItemUsdAchCreditTransfersAutomatedRolling24HourVolumeLimit =
  number | null;

export type ProgramListResponseDataItemReserveAccountId = string | null;

export type ProgramListResponseDataItemReserveAccountExpectedMinimumBalance =
  | number
  | null;

export type ProgramListResponseDataItemMaximumPhysicalCardCount = number | null;

export type ProgramListResponseDataItemMaximumCardCount = number | null;

export type ProgramListResponseDataItemMaximumAccountNumberCount =
  | number
  | null;

export type ProgramListResponseDataItemInterestRateAnyOf = {
  /** @pattern ^-?\d+(\.\d+)?$ */
  current_value: string;
  summary: string;
};

export type ProgramListResponseDataItemInterestRate =
  ProgramListResponseDataItemInterestRateAnyOf | null;

export type ProgramListResponseDataItemInterestAccountId = string | null;

export type ProgramListResponseDataItemIncreaseComplianceRole =
  (typeof ProgramListResponseDataItemIncreaseComplianceRole)[keyof typeof ProgramListResponseDataItemIncreaseComplianceRole];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ProgramListResponseDataItemIncreaseComplianceRole = {
  first_line: "first_line",
  oversight: "oversight",
  technology_only: "technology_only",
} as const;

export type ProgramListResponseDataItemInboundFundsHoldPolicy =
  (typeof ProgramListResponseDataItemInboundFundsHoldPolicy)[keyof typeof ProgramListResponseDataItemInboundFundsHoldPolicy];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ProgramListResponseDataItemInboundFundsHoldPolicy = {
  release_immediately: "release_immediately",
  standard: "standard",
  five_business_days_for_ach: "five_business_days_for_ach",
  seven_business_days_for_ach: "seven_business_days_for_ach",
} as const;

export type ProgramListResponseDataItemGroupName = string | null;

export type ProgramListResponseDataItemForBenefitOfEntityId = string | null;

export type ProgramListResponseDataItemFirstInternetBankRequiredMinimumReserve =
  number | null;

export type ProgramListResponseDataItemBillingAccountId = string | null;

export type ProgramListResponseDataItemBank =
  (typeof ProgramListResponseDataItemBank)[keyof typeof ProgramListResponseDataItemBank];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ProgramListResponseDataItemBank = {
  blue_ridge_bank: "blue_ridge_bank",
  core_bank: "core_bank",
  first_internet_bank: "first_internet_bank",
  global_innovations_bank: "global_innovations_bank",
  grasshopper_bank: "grasshopper_bank",
} as const;

export type ProgramListResponseDataItem = {
  allow_account_creation: boolean;
  allows_submitting_security_incident_reports: boolean;
  allows_submitting_unusual_activity_reports: boolean;
  allows_submitting_vendors: boolean;
  api_transfers_require_approval: boolean;
  bank: ProgramListResponseDataItemBank;
  bank_program_id: string;
  billing_account_id: ProgramListResponseDataItemBillingAccountId;
  count_accounts: number;
  count_entities: number;
  created_at: string;
  credit_exposure_limit: number;
  dashboard_transfers_require_approval: boolean;
  first_internet_bank_required_minimum_reserve: ProgramListResponseDataItemFirstInternetBankRequiredMinimumReserve;
  for_benefit_of_entity_id: ProgramListResponseDataItemForBenefitOfEntityId;
  group_id: string;
  group_name: ProgramListResponseDataItemGroupName;
  id: string;
  inbound_funds_hold_policy: ProgramListResponseDataItemInboundFundsHoldPolicy;
  increase_compliance_role: ProgramListResponseDataItemIncreaseComplianceRole;
  interest_account_id: ProgramListResponseDataItemInterestAccountId;
  interest_rate: ProgramListResponseDataItemInterestRate;
  limit_rolling_24_hour_card_authorization_volume: boolean;
  maximum_account_number_count: ProgramListResponseDataItemMaximumAccountNumberCount;
  maximum_card_count: ProgramListResponseDataItemMaximumCardCount;
  maximum_physical_card_count: ProgramListResponseDataItemMaximumPhysicalCardCount;
  name: string;
  request_complaint_lists_automatically: boolean;
  reserve_account_expected_minimum_balance: ProgramListResponseDataItemReserveAccountExpectedMinimumBalance;
  reserve_account_id: ProgramListResponseDataItemReserveAccountId;
  sum_balances: number;
  transfers_approvable_by_creator: boolean;
  updated_at: string;
  usd_ach_credit_transfers_automated_rolling_24_hour_volume_limit: ProgramListResponseDataItemUsdAchCreditTransfersAutomatedRolling24HourVolumeLimit;
  usd_ach_debit_transfers_automated_rolling_24_hour_volume_limit: ProgramListResponseDataItemUsdAchDebitTransfersAutomatedRolling24HourVolumeLimit;
  usd_authentication_attempted_card_limit_rolling_24_hour_volume_limit: number;
  usd_check_deposits_automated_rolling_24_hour_volume_limit: ProgramListResponseDataItemUsdCheckDepositsAutomatedRolling24HourVolumeLimit;
  usd_check_transfers_automated_rolling_24_hour_volume_limit: ProgramListResponseDataItemUsdCheckTransfersAutomatedRolling24HourVolumeLimit;
  usd_combined_inbound_automated_rolling_24_hour_volume_limit: ProgramListResponseDataItemUsdCombinedInboundAutomatedRolling24HourVolumeLimit;
  usd_combined_rolling_24_hour_transfer_volume_limit: number;
  usd_per_ach_credit_transfer_limit: number;
  usd_per_ach_debit_transfer_limit: number;
  usd_per_check_deposit_limit: number;
  usd_per_check_transfer_limit: number;
  usd_per_real_time_payments_transfer_limit: number;
  usd_per_wire_transfer_limit: number;
  usd_real_time_payments_automated_rolling_24_hour_volume_limit: ProgramListResponseDataItemUsdRealTimePaymentsAutomatedRolling24HourVolumeLimit;
  usd_wire_transfers_automated_rolling_24_hour_volume_limit: ProgramListResponseDataItemUsdWireTransfersAutomatedRolling24HourVolumeLimit;
};

export type ProgramLimitsPatchResponseUsdWireTransfersAutomatedRolling24HourVolumeLimit =
  number | null;

export type ProgramLimitsPatchResponseUsdRealTimePaymentsAutomatedRolling24HourVolumeLimit =
  number | null;

export type ProgramLimitsPatchResponseUsdCombinedInboundAutomatedRolling24HourVolumeLimit =
  number | null;

export type ProgramLimitsPatchResponseUsdCheckTransfersAutomatedRolling24HourVolumeLimit =
  number | null;

export type ProgramLimitsPatchResponseUsdCheckDepositsAutomatedRolling24HourVolumeLimit =
  number | null;

export type ProgramLimitsPatchResponseUsdAchDebitTransfersAutomatedRolling24HourVolumeLimit =
  number | null;

export type ProgramLimitsPatchResponseUsdAchCreditTransfersAutomatedRolling24HourVolumeLimit =
  number | null;

export type ProgramLimitsPatchResponseReserveAccountId = string | null;

export type ProgramLimitsPatchResponseReserveAccountExpectedMinimumBalance =
  | number
  | null;

export type ProgramLimitsPatchResponseMaximumPhysicalCardCount = number | null;

export type ProgramLimitsPatchResponseMaximumCardCount = number | null;

export type ProgramLimitsPatchResponseMaximumAccountNumberCount = number | null;

export interface ProgramLimitsPatchResponse {
  allow_account_creation: boolean;
  allows_submitting_security_incident_reports: boolean;
  allows_submitting_unusual_activity_reports: boolean;
  allows_submitting_vendors: boolean;
  api_transfers_require_approval: boolean;
  bank: ProgramLimitsPatchResponseBank;
  bank_program_id: string;
  billing_account_id: ProgramLimitsPatchResponseBillingAccountId;
  count_accounts: number;
  count_entities: number;
  created_at: string;
  credit_exposure_limit: number;
  dashboard_transfers_require_approval: boolean;
  first_internet_bank_required_minimum_reserve: ProgramLimitsPatchResponseFirstInternetBankRequiredMinimumReserve;
  for_benefit_of_entity_id: ProgramLimitsPatchResponseForBenefitOfEntityId;
  group_id: string;
  group_name: ProgramLimitsPatchResponseGroupName;
  id: string;
  inbound_funds_hold_policy: ProgramLimitsPatchResponseInboundFundsHoldPolicy;
  increase_compliance_role: ProgramLimitsPatchResponseIncreaseComplianceRole;
  interest_account_id: ProgramLimitsPatchResponseInterestAccountId;
  interest_rate: ProgramLimitsPatchResponseInterestRate;
  limit_rolling_24_hour_card_authorization_volume: boolean;
  maximum_account_number_count: ProgramLimitsPatchResponseMaximumAccountNumberCount;
  maximum_card_count: ProgramLimitsPatchResponseMaximumCardCount;
  maximum_physical_card_count: ProgramLimitsPatchResponseMaximumPhysicalCardCount;
  name: string;
  request_complaint_lists_automatically: boolean;
  reserve_account_expected_minimum_balance: ProgramLimitsPatchResponseReserveAccountExpectedMinimumBalance;
  reserve_account_id: ProgramLimitsPatchResponseReserveAccountId;
  sum_balances: number;
  transfers_approvable_by_creator: boolean;
  updated_at: string;
  usd_ach_credit_transfers_automated_rolling_24_hour_volume_limit: ProgramLimitsPatchResponseUsdAchCreditTransfersAutomatedRolling24HourVolumeLimit;
  usd_ach_debit_transfers_automated_rolling_24_hour_volume_limit: ProgramLimitsPatchResponseUsdAchDebitTransfersAutomatedRolling24HourVolumeLimit;
  usd_authentication_attempted_card_limit_rolling_24_hour_volume_limit: number;
  usd_check_deposits_automated_rolling_24_hour_volume_limit: ProgramLimitsPatchResponseUsdCheckDepositsAutomatedRolling24HourVolumeLimit;
  usd_check_transfers_automated_rolling_24_hour_volume_limit: ProgramLimitsPatchResponseUsdCheckTransfersAutomatedRolling24HourVolumeLimit;
  usd_combined_inbound_automated_rolling_24_hour_volume_limit: ProgramLimitsPatchResponseUsdCombinedInboundAutomatedRolling24HourVolumeLimit;
  usd_combined_rolling_24_hour_transfer_volume_limit: number;
  usd_per_ach_credit_transfer_limit: number;
  usd_per_ach_debit_transfer_limit: number;
  usd_per_check_deposit_limit: number;
  usd_per_check_transfer_limit: number;
  usd_per_real_time_payments_transfer_limit: number;
  usd_per_wire_transfer_limit: number;
  usd_real_time_payments_automated_rolling_24_hour_volume_limit: ProgramLimitsPatchResponseUsdRealTimePaymentsAutomatedRolling24HourVolumeLimit;
  usd_wire_transfers_automated_rolling_24_hour_volume_limit: ProgramLimitsPatchResponseUsdWireTransfersAutomatedRolling24HourVolumeLimit;
}

export type ProgramLimitsPatchResponseInterestRateAnyOf = {
  /** @pattern ^-?\d+(\.\d+)?$ */
  current_value: string;
  summary: string;
};

export type ProgramLimitsPatchResponseInterestRate =
  ProgramLimitsPatchResponseInterestRateAnyOf | null;

export type ProgramLimitsPatchResponseInterestAccountId = string | null;

export type ProgramLimitsPatchResponseIncreaseComplianceRole =
  (typeof ProgramLimitsPatchResponseIncreaseComplianceRole)[keyof typeof ProgramLimitsPatchResponseIncreaseComplianceRole];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ProgramLimitsPatchResponseIncreaseComplianceRole = {
  first_line: "first_line",
  oversight: "oversight",
  technology_only: "technology_only",
} as const;

export type ProgramLimitsPatchResponseInboundFundsHoldPolicy =
  (typeof ProgramLimitsPatchResponseInboundFundsHoldPolicy)[keyof typeof ProgramLimitsPatchResponseInboundFundsHoldPolicy];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ProgramLimitsPatchResponseInboundFundsHoldPolicy = {
  release_immediately: "release_immediately",
  standard: "standard",
  five_business_days_for_ach: "five_business_days_for_ach",
  seven_business_days_for_ach: "seven_business_days_for_ach",
} as const;

export type ProgramLimitsPatchResponseGroupName = string | null;

export type ProgramLimitsPatchResponseForBenefitOfEntityId = string | null;

export type ProgramLimitsPatchResponseFirstInternetBankRequiredMinimumReserve =
  | number
  | null;

export type ProgramLimitsPatchResponseBillingAccountId = string | null;

export type ProgramLimitsPatchResponseBank =
  (typeof ProgramLimitsPatchResponseBank)[keyof typeof ProgramLimitsPatchResponseBank];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ProgramLimitsPatchResponseBank = {
  blue_ridge_bank: "blue_ridge_bank",
  core_bank: "core_bank",
  first_internet_bank: "first_internet_bank",
  global_innovations_bank: "global_innovations_bank",
  grasshopper_bank: "grasshopper_bank",
} as const;

export interface ProgramLimitsPatchParameters {
  ach_credit_transfers_automated_limit_per_transfer?: number;
  ach_credit_transfers_automated_rolling_24_hour_volume_limit?: number;
  ach_debit_transfers_automated_limit_per_transfer?: number;
  ach_debit_transfers_automated_rolling_24_hour_volume_limit?: number;
  authentication_attempted_card_limit_rolling_24_hour_volume?: number;
  check_deposits_automated_limit_per_transfer?: number;
  check_deposits_automated_rolling_24_hour_volume_limit?: number;
  check_transfers_automated_limit_per_transfer?: number;
  check_transfers_automated_rolling_24_hour_volume_limit?: number;
  combined_inbound_automated_limit_rolling_24_hour_volume?: number;
  combined_transfers_automated_rolling_24_hour_volume_limit?: number;
  limit_rolling_24_hour_card_authorization_volume?: boolean;
  real_time_payments_automated_rolling_24_hour_volume_limit?: number;
  real_time_payments_transfers_automated_limit_per_transfer?: number;
  unset_ach_credit_transfers_automated_rolling_24_hour_volume_limit?: boolean;
  unset_ach_debit_transfers_automated_rolling_24_hour_volume_limit?: boolean;
  unset_authentication_attempted_card_limit_rolling_24_hour_volume?: boolean;
  unset_check_deposits_automated_rolling_24_hour_volume_limit?: boolean;
  unset_check_transfers_automated_rolling_24_hour_volume_limit?: boolean;
  unset_combined_inbound_automated_limit_rolling_24_hour_volume?: boolean;
  unset_real_time_payments_automated_rolling_24_hour_volume_limit?: boolean;
  unset_wire_transfers_automated_rolling_24_hour_volume_limit?: boolean;
  wire_transfers_automated_limit_per_transfer?: number;
  wire_transfers_automated_rolling_24_hour_volume_limit?: number;
}

export type ProgramInterestRatePostResponseUsdWireTransfersAutomatedRolling24HourVolumeLimit =
  number | null;

export type ProgramInterestRatePostResponseUsdRealTimePaymentsAutomatedRolling24HourVolumeLimit =
  number | null;

export type ProgramInterestRatePostResponseUsdCombinedInboundAutomatedRolling24HourVolumeLimit =
  number | null;

export type ProgramInterestRatePostResponseUsdCheckTransfersAutomatedRolling24HourVolumeLimit =
  number | null;

export type ProgramInterestRatePostResponseUsdCheckDepositsAutomatedRolling24HourVolumeLimit =
  number | null;

export type ProgramInterestRatePostResponseUsdAchDebitTransfersAutomatedRolling24HourVolumeLimit =
  number | null;

export type ProgramInterestRatePostResponseUsdAchCreditTransfersAutomatedRolling24HourVolumeLimit =
  number | null;

export type ProgramInterestRatePostResponseReserveAccountId = string | null;

export type ProgramInterestRatePostResponseReserveAccountExpectedMinimumBalance =
  number | null;

export type ProgramInterestRatePostResponseMaximumPhysicalCardCount =
  | number
  | null;

export type ProgramInterestRatePostResponseMaximumCardCount = number | null;

export type ProgramInterestRatePostResponseMaximumAccountNumberCount =
  | number
  | null;

export type ProgramInterestRatePostResponseInterestRateAnyOf = {
  /** @pattern ^-?\d+(\.\d+)?$ */
  current_value: string;
  summary: string;
};

export type ProgramInterestRatePostResponseInterestRate =
  ProgramInterestRatePostResponseInterestRateAnyOf | null;

export type ProgramInterestRatePostResponseInterestAccountId = string | null;

export type ProgramInterestRatePostResponseIncreaseComplianceRole =
  (typeof ProgramInterestRatePostResponseIncreaseComplianceRole)[keyof typeof ProgramInterestRatePostResponseIncreaseComplianceRole];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ProgramInterestRatePostResponseIncreaseComplianceRole = {
  first_line: "first_line",
  oversight: "oversight",
  technology_only: "technology_only",
} as const;

export type ProgramInterestRatePostResponseInboundFundsHoldPolicy =
  (typeof ProgramInterestRatePostResponseInboundFundsHoldPolicy)[keyof typeof ProgramInterestRatePostResponseInboundFundsHoldPolicy];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ProgramInterestRatePostResponseInboundFundsHoldPolicy = {
  release_immediately: "release_immediately",
  standard: "standard",
  five_business_days_for_ach: "five_business_days_for_ach",
  seven_business_days_for_ach: "seven_business_days_for_ach",
} as const;

export type ProgramInterestRatePostResponseGroupName = string | null;

export type ProgramInterestRatePostResponseForBenefitOfEntityId = string | null;

export type ProgramInterestRatePostResponseFirstInternetBankRequiredMinimumReserve =
  number | null;

export type ProgramInterestRatePostResponseBillingAccountId = string | null;

export type ProgramInterestRatePostResponseBank =
  (typeof ProgramInterestRatePostResponseBank)[keyof typeof ProgramInterestRatePostResponseBank];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ProgramInterestRatePostResponseBank = {
  blue_ridge_bank: "blue_ridge_bank",
  core_bank: "core_bank",
  first_internet_bank: "first_internet_bank",
  global_innovations_bank: "global_innovations_bank",
  grasshopper_bank: "grasshopper_bank",
} as const;

export interface ProgramInterestRatePostResponse {
  allow_account_creation: boolean;
  allows_submitting_security_incident_reports: boolean;
  allows_submitting_unusual_activity_reports: boolean;
  allows_submitting_vendors: boolean;
  api_transfers_require_approval: boolean;
  bank: ProgramInterestRatePostResponseBank;
  bank_program_id: string;
  billing_account_id: ProgramInterestRatePostResponseBillingAccountId;
  count_accounts: number;
  count_entities: number;
  created_at: string;
  credit_exposure_limit: number;
  dashboard_transfers_require_approval: boolean;
  first_internet_bank_required_minimum_reserve: ProgramInterestRatePostResponseFirstInternetBankRequiredMinimumReserve;
  for_benefit_of_entity_id: ProgramInterestRatePostResponseForBenefitOfEntityId;
  group_id: string;
  group_name: ProgramInterestRatePostResponseGroupName;
  id: string;
  inbound_funds_hold_policy: ProgramInterestRatePostResponseInboundFundsHoldPolicy;
  increase_compliance_role: ProgramInterestRatePostResponseIncreaseComplianceRole;
  interest_account_id: ProgramInterestRatePostResponseInterestAccountId;
  interest_rate: ProgramInterestRatePostResponseInterestRate;
  limit_rolling_24_hour_card_authorization_volume: boolean;
  maximum_account_number_count: ProgramInterestRatePostResponseMaximumAccountNumberCount;
  maximum_card_count: ProgramInterestRatePostResponseMaximumCardCount;
  maximum_physical_card_count: ProgramInterestRatePostResponseMaximumPhysicalCardCount;
  name: string;
  request_complaint_lists_automatically: boolean;
  reserve_account_expected_minimum_balance: ProgramInterestRatePostResponseReserveAccountExpectedMinimumBalance;
  reserve_account_id: ProgramInterestRatePostResponseReserveAccountId;
  sum_balances: number;
  transfers_approvable_by_creator: boolean;
  updated_at: string;
  usd_ach_credit_transfers_automated_rolling_24_hour_volume_limit: ProgramInterestRatePostResponseUsdAchCreditTransfersAutomatedRolling24HourVolumeLimit;
  usd_ach_debit_transfers_automated_rolling_24_hour_volume_limit: ProgramInterestRatePostResponseUsdAchDebitTransfersAutomatedRolling24HourVolumeLimit;
  usd_authentication_attempted_card_limit_rolling_24_hour_volume_limit: number;
  usd_check_deposits_automated_rolling_24_hour_volume_limit: ProgramInterestRatePostResponseUsdCheckDepositsAutomatedRolling24HourVolumeLimit;
  usd_check_transfers_automated_rolling_24_hour_volume_limit: ProgramInterestRatePostResponseUsdCheckTransfersAutomatedRolling24HourVolumeLimit;
  usd_combined_inbound_automated_rolling_24_hour_volume_limit: ProgramInterestRatePostResponseUsdCombinedInboundAutomatedRolling24HourVolumeLimit;
  usd_combined_rolling_24_hour_transfer_volume_limit: number;
  usd_per_ach_credit_transfer_limit: number;
  usd_per_ach_debit_transfer_limit: number;
  usd_per_check_deposit_limit: number;
  usd_per_check_transfer_limit: number;
  usd_per_real_time_payments_transfer_limit: number;
  usd_per_wire_transfer_limit: number;
  usd_real_time_payments_automated_rolling_24_hour_volume_limit: ProgramInterestRatePostResponseUsdRealTimePaymentsAutomatedRolling24HourVolumeLimit;
  usd_wire_transfers_automated_rolling_24_hour_volume_limit: ProgramInterestRatePostResponseUsdWireTransfersAutomatedRolling24HourVolumeLimit;
}

export type ProgramInterestRatePostParametersInterestRatePlan =
  (typeof ProgramInterestRatePostParametersInterestRatePlan)[keyof typeof ProgramInterestRatePostParametersInterestRatePlan];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ProgramInterestRatePostParametersInterestRatePlan = {
  top_of_federal_funds_target_range: "top_of_federal_funds_target_range",
  prior_month_federal_funds_effective_rate:
    "prior_month_federal_funds_effective_rate",
  prior_business_day_effective_federal_funds_rate:
    "prior_business_day_effective_federal_funds_rate",
  last_business_day_of_prior_month_effective_federal_funds_rate:
    "last_business_day_of_prior_month_effective_federal_funds_rate",
  fixed: "fixed",
  no_interest: "no_interest",
} as const;

export interface ProgramInterestRatePostParameters {
  delta: string;
  interest_rate_plan: ProgramInterestRatePostParametersInterestRatePlan;
}

export type ProgramGetResponseUsdWireTransfersAutomatedRolling24HourVolumeLimit =
  number | null;

export type ProgramGetResponseUsdRealTimePaymentsAutomatedRolling24HourVolumeLimit =
  number | null;

export type ProgramGetResponseUsdCombinedInboundAutomatedRolling24HourVolumeLimit =
  number | null;

export type ProgramGetResponseUsdCheckTransfersAutomatedRolling24HourVolumeLimit =
  number | null;

export type ProgramGetResponseUsdCheckDepositsAutomatedRolling24HourVolumeLimit =
  number | null;

export type ProgramGetResponseUsdAchDebitTransfersAutomatedRolling24HourVolumeLimit =
  number | null;

export type ProgramGetResponseUsdAchCreditTransfersAutomatedRolling24HourVolumeLimit =
  number | null;

export type ProgramGetResponseReserveAccountId = string | null;

export type ProgramGetResponseReserveAccountExpectedMinimumBalance =
  | number
  | null;

export type ProgramGetResponseMaximumPhysicalCardCount = number | null;

export type ProgramGetResponseMaximumCardCount = number | null;

export type ProgramGetResponseMaximumAccountNumberCount = number | null;

export type ProgramGetResponseInterestRateAnyOf = {
  /** @pattern ^-?\d+(\.\d+)?$ */
  current_value: string;
  summary: string;
};

export type ProgramGetResponseInterestRate =
  ProgramGetResponseInterestRateAnyOf | null;

export type ProgramGetResponseInterestAccountId = string | null;

export type ProgramGetResponseIncreaseComplianceRole =
  (typeof ProgramGetResponseIncreaseComplianceRole)[keyof typeof ProgramGetResponseIncreaseComplianceRole];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ProgramGetResponseIncreaseComplianceRole = {
  first_line: "first_line",
  oversight: "oversight",
  technology_only: "technology_only",
} as const;

export type ProgramGetResponseInboundFundsHoldPolicy =
  (typeof ProgramGetResponseInboundFundsHoldPolicy)[keyof typeof ProgramGetResponseInboundFundsHoldPolicy];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ProgramGetResponseInboundFundsHoldPolicy = {
  release_immediately: "release_immediately",
  standard: "standard",
  five_business_days_for_ach: "five_business_days_for_ach",
  seven_business_days_for_ach: "seven_business_days_for_ach",
} as const;

export type ProgramGetResponseGroupName = string | null;

export type ProgramGetResponseForBenefitOfEntityId = string | null;

export type ProgramGetResponseFirstInternetBankRequiredMinimumReserve =
  | number
  | null;

export type ProgramGetResponseBillingAccountId = string | null;

export type ProgramGetResponseBank =
  (typeof ProgramGetResponseBank)[keyof typeof ProgramGetResponseBank];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ProgramGetResponseBank = {
  blue_ridge_bank: "blue_ridge_bank",
  core_bank: "core_bank",
  first_internet_bank: "first_internet_bank",
  global_innovations_bank: "global_innovations_bank",
  grasshopper_bank: "grasshopper_bank",
} as const;

export interface ProgramGetResponse {
  allow_account_creation: boolean;
  allows_submitting_security_incident_reports: boolean;
  allows_submitting_unusual_activity_reports: boolean;
  allows_submitting_vendors: boolean;
  api_transfers_require_approval: boolean;
  bank: ProgramGetResponseBank;
  bank_program_id: string;
  billing_account_id: ProgramGetResponseBillingAccountId;
  count_accounts: number;
  count_entities: number;
  created_at: string;
  credit_exposure_limit: number;
  dashboard_transfers_require_approval: boolean;
  first_internet_bank_required_minimum_reserve: ProgramGetResponseFirstInternetBankRequiredMinimumReserve;
  for_benefit_of_entity_id: ProgramGetResponseForBenefitOfEntityId;
  group_id: string;
  group_name: ProgramGetResponseGroupName;
  id: string;
  inbound_funds_hold_policy: ProgramGetResponseInboundFundsHoldPolicy;
  increase_compliance_role: ProgramGetResponseIncreaseComplianceRole;
  interest_account_id: ProgramGetResponseInterestAccountId;
  interest_rate: ProgramGetResponseInterestRate;
  limit_rolling_24_hour_card_authorization_volume: boolean;
  maximum_account_number_count: ProgramGetResponseMaximumAccountNumberCount;
  maximum_card_count: ProgramGetResponseMaximumCardCount;
  maximum_physical_card_count: ProgramGetResponseMaximumPhysicalCardCount;
  name: string;
  request_complaint_lists_automatically: boolean;
  reserve_account_expected_minimum_balance: ProgramGetResponseReserveAccountExpectedMinimumBalance;
  reserve_account_id: ProgramGetResponseReserveAccountId;
  sum_balances: number;
  transfers_approvable_by_creator: boolean;
  updated_at: string;
  usd_ach_credit_transfers_automated_rolling_24_hour_volume_limit: ProgramGetResponseUsdAchCreditTransfersAutomatedRolling24HourVolumeLimit;
  usd_ach_debit_transfers_automated_rolling_24_hour_volume_limit: ProgramGetResponseUsdAchDebitTransfersAutomatedRolling24HourVolumeLimit;
  usd_authentication_attempted_card_limit_rolling_24_hour_volume_limit: number;
  usd_check_deposits_automated_rolling_24_hour_volume_limit: ProgramGetResponseUsdCheckDepositsAutomatedRolling24HourVolumeLimit;
  usd_check_transfers_automated_rolling_24_hour_volume_limit: ProgramGetResponseUsdCheckTransfersAutomatedRolling24HourVolumeLimit;
  usd_combined_inbound_automated_rolling_24_hour_volume_limit: ProgramGetResponseUsdCombinedInboundAutomatedRolling24HourVolumeLimit;
  usd_combined_rolling_24_hour_transfer_volume_limit: number;
  usd_per_ach_credit_transfer_limit: number;
  usd_per_ach_debit_transfer_limit: number;
  usd_per_check_deposit_limit: number;
  usd_per_check_transfer_limit: number;
  usd_per_real_time_payments_transfer_limit: number;
  usd_per_wire_transfer_limit: number;
  usd_real_time_payments_automated_rolling_24_hour_volume_limit: ProgramGetResponseUsdRealTimePaymentsAutomatedRolling24HourVolumeLimit;
  usd_wire_transfers_automated_rolling_24_hour_volume_limit: ProgramGetResponseUsdWireTransfersAutomatedRolling24HourVolumeLimit;
}

export type ProgramCreditExposureGetResponseUnreleasedSummariesItemCurrency =
  (typeof ProgramCreditExposureGetResponseUnreleasedSummariesItemCurrency)[keyof typeof ProgramCreditExposureGetResponseUnreleasedSummariesItemCurrency];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ProgramCreditExposureGetResponseUnreleasedSummariesItemCurrency = {
  CAD: "CAD",
  CHF: "CHF",
  EUR: "EUR",
  GBP: "GBP",
  JPY: "JPY",
  USD: "USD",
} as const;

export type ProgramCreditExposureGetResponseUnreleasedSummariesItem = {
  amount: number;
  count: number;
  currency: ProgramCreditExposureGetResponseUnreleasedSummariesItemCurrency;
  releases_at: string;
  source_type: string;
};

export interface ProgramCreditExposureGetResponse {
  unreleased_summaries: ProgramCreditExposureGetResponseUnreleasedSummariesItem[];
  usd_credit_exposure_balance: number;
  usd_credit_exposure_limit: number;
  usd_current_balance: number;
  usd_current_credit_exposure: number;
  usd_pending_balance: number;
}

export type ProgramBalanceGetResponseCurrency =
  (typeof ProgramBalanceGetResponseCurrency)[keyof typeof ProgramBalanceGetResponseCurrency];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ProgramBalanceGetResponseCurrency = {
  CAD: "CAD",
  CHF: "CHF",
  EUR: "EUR",
  GBP: "GBP",
  JPY: "JPY",
  USD: "USD",
} as const;

export interface ProgramBalanceGetResponse {
  currency: ProgramBalanceGetResponseCurrency;
  current_balance: number;
}

export type ProgramACHReturnRateListResponseNextCursor = string | null;

export type ProgramACHReturnRateListResponseDataItemGroupName = string | null;

export type ProgramACHReturnRateListResponseDataItem = {
  /** @pattern ^-?\d+(\.\d+)?$ */
  administrative_return_rate: string;
  count_ach_debits: number;
  count_ach_returns: number;
  created_at: string;
  group_id: string;
  group_name: ProgramACHReturnRateListResponseDataItemGroupName;
  id: string;
  /** @pattern ^-?\d+(\.\d+)?$ */
  overall_return_rate: string;
  period_end: string;
  period_start: string;
  program_id: string;
  program_name: string;
  /** @pattern ^-?\d+(\.\d+)?$ */
  unauthorized_return_rate: string;
  updated_at: string;
};

export interface ProgramACHReturnRateListResponse {
  data: ProgramACHReturnRateListResponseDataItem[];
  next_cursor: ProgramACHReturnRateListResponseNextCursor;
}

export type ProgramACHReturnRateGetResponseGroupName = string | null;

export interface ProgramACHReturnRateGetResponse {
  /** @pattern ^-?\d+(\.\d+)?$ */
  administrative_return_rate: string;
  count_ach_debits: number;
  count_ach_returns: number;
  created_at: string;
  group_id: string;
  group_name: ProgramACHReturnRateGetResponseGroupName;
  id: string;
  /** @pattern ^-?\d+(\.\d+)?$ */
  overall_return_rate: string;
  period_end: string;
  period_start: string;
  program_id: string;
  program_name: string;
  /** @pattern ^-?\d+(\.\d+)?$ */
  unauthorized_return_rate: string;
  updated_at: string;
}

export type PlatformVendorListSubmissionListResponseNextCursor = string | null;

export type PlatformVendorListSubmissionListResponseDataItemVendorsItemRiskLevel =
  (typeof PlatformVendorListSubmissionListResponseDataItemVendorsItemRiskLevel)[keyof typeof PlatformVendorListSubmissionListResponseDataItemVendorsItemRiskLevel];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PlatformVendorListSubmissionListResponseDataItemVendorsItemRiskLevel =
  {
    low: "low",
    medium: "medium",
    high: "high",
    critical: "critical",
  } as const;

export type PlatformVendorListSubmissionListResponseDataItemVendorsItemRemovedOn =
  string | null;

export type PlatformVendorListSubmissionListResponseDataItemVendorsItemDiligenceReviewResult =
  (typeof PlatformVendorListSubmissionListResponseDataItemVendorsItemDiligenceReviewResult)[keyof typeof PlatformVendorListSubmissionListResponseDataItemVendorsItemDiligenceReviewResult];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PlatformVendorListSubmissionListResponseDataItemVendorsItemDiligenceReviewResult =
  {
    current: "current",
    past_due: "past_due",
  } as const;

export type PlatformVendorListSubmissionListResponseDataItemVendorsItem = {
  added_on: string;
  description: string;
  diligence_review_result: PlatformVendorListSubmissionListResponseDataItemVendorsItemDiligenceReviewResult;
  id: string;
  last_reviewed_on: string;
  name: string;
  removed_on: PlatformVendorListSubmissionListResponseDataItemVendorsItemRemovedOn;
  risk_level: PlatformVendorListSubmissionListResponseDataItemVendorsItemRiskLevel;
  website: string;
};

export type PlatformVendorListSubmissionListResponseDataItemPeriodStart =
  | string
  | null;

export type PlatformVendorListSubmissionListResponseDataItemPeriodEnd =
  | string
  | null;

export type PlatformVendorListSubmissionListResponseDataItem = {
  created_at: string;
  document_request_id: string;
  group_id: string;
  id: string;
  period_end: PlatformVendorListSubmissionListResponseDataItemPeriodEnd;
  period_start: PlatformVendorListSubmissionListResponseDataItemPeriodStart;
  vendors: PlatformVendorListSubmissionListResponseDataItemVendorsItem[];
};

export interface PlatformVendorListSubmissionListResponse {
  data: PlatformVendorListSubmissionListResponseDataItem[];
  next_cursor: PlatformVendorListSubmissionListResponseNextCursor;
}

export type PlatformVendorListSubmissionGetResponseVendorsItemRiskLevel =
  (typeof PlatformVendorListSubmissionGetResponseVendorsItemRiskLevel)[keyof typeof PlatformVendorListSubmissionGetResponseVendorsItemRiskLevel];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PlatformVendorListSubmissionGetResponseVendorsItemRiskLevel = {
  low: "low",
  medium: "medium",
  high: "high",
  critical: "critical",
} as const;

export type PlatformVendorListSubmissionGetResponseVendorsItemRemovedOn =
  | string
  | null;

export type PlatformVendorListSubmissionGetResponseVendorsItemDiligenceReviewResult =
  (typeof PlatformVendorListSubmissionGetResponseVendorsItemDiligenceReviewResult)[keyof typeof PlatformVendorListSubmissionGetResponseVendorsItemDiligenceReviewResult];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PlatformVendorListSubmissionGetResponseVendorsItemDiligenceReviewResult =
  {
    current: "current",
    past_due: "past_due",
  } as const;

export type PlatformVendorListSubmissionGetResponseVendorsItem = {
  added_on: string;
  description: string;
  diligence_review_result: PlatformVendorListSubmissionGetResponseVendorsItemDiligenceReviewResult;
  id: string;
  last_reviewed_on: string;
  name: string;
  removed_on: PlatformVendorListSubmissionGetResponseVendorsItemRemovedOn;
  risk_level: PlatformVendorListSubmissionGetResponseVendorsItemRiskLevel;
  website: string;
};

export type PlatformVendorListSubmissionGetResponsePeriodStart = string | null;

export type PlatformVendorListSubmissionGetResponsePeriodEnd = string | null;

export interface PlatformVendorListSubmissionGetResponse {
  created_at: string;
  document_request_id: string;
  group_id: string;
  id: string;
  period_end: PlatformVendorListSubmissionGetResponsePeriodEnd;
  period_start: PlatformVendorListSubmissionGetResponsePeriodStart;
  vendors: PlatformVendorListSubmissionGetResponseVendorsItem[];
}

export type PlatformVendorListResponseNextCursor = string | null;

export interface PlatformVendorListResponse {
  data: PlatformVendorListResponseDataItem[];
  next_cursor: PlatformVendorListResponseNextCursor;
}

export type PlatformVendorListResponseDataItemRiskLevel =
  (typeof PlatformVendorListResponseDataItemRiskLevel)[keyof typeof PlatformVendorListResponseDataItemRiskLevel];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PlatformVendorListResponseDataItemRiskLevel = {
  low: "low",
  medium: "medium",
  high: "high",
  critical: "critical",
} as const;

export type PlatformVendorListResponseDataItemRemovedOn = string | null;

export type PlatformVendorListResponseDataItemDiligenceReviewResult =
  (typeof PlatformVendorListResponseDataItemDiligenceReviewResult)[keyof typeof PlatformVendorListResponseDataItemDiligenceReviewResult];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PlatformVendorListResponseDataItemDiligenceReviewResult = {
  current: "current",
  past_due: "past_due",
} as const;

export type PlatformVendorListResponseDataItem = {
  added_on: string;
  created_at: string;
  description: string;
  diligence_review_result: PlatformVendorListResponseDataItemDiligenceReviewResult;
  group_id: string;
  id: string;
  last_reviewed_on: string;
  name: string;
  removed_on: PlatformVendorListResponseDataItemRemovedOn;
  risk_level: PlatformVendorListResponseDataItemRiskLevel;
  updated_at: string;
  website: string;
};

export type PlatformVendorGetResponseRiskLevel =
  (typeof PlatformVendorGetResponseRiskLevel)[keyof typeof PlatformVendorGetResponseRiskLevel];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PlatformVendorGetResponseRiskLevel = {
  low: "low",
  medium: "medium",
  high: "high",
  critical: "critical",
} as const;

export type PlatformVendorGetResponseRemovedOn = string | null;

export type PlatformVendorGetResponseDiligenceReviewResult =
  (typeof PlatformVendorGetResponseDiligenceReviewResult)[keyof typeof PlatformVendorGetResponseDiligenceReviewResult];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PlatformVendorGetResponseDiligenceReviewResult = {
  current: "current",
  past_due: "past_due",
} as const;

export interface PlatformVendorGetResponse {
  added_on: string;
  created_at: string;
  description: string;
  diligence_review_result: PlatformVendorGetResponseDiligenceReviewResult;
  group_id: string;
  id: string;
  last_reviewed_on: string;
  name: string;
  removed_on: PlatformVendorGetResponseRemovedOn;
  risk_level: PlatformVendorGetResponseRiskLevel;
  updated_at: string;
  website: string;
}

export type PlatformUnusualActivityReportListResponseNextCursor = string | null;

export interface PlatformUnusualActivityReportListResponse {
  data: PlatformUnusualActivityReportListResponseDataItem[];
  next_cursor: PlatformUnusualActivityReportListResponseNextCursor;
}

export type PlatformUnusualActivityReportListResponseDataItemRelatedTransactionsItem =
  {
    description: string;
    id: string;
  };

export type PlatformUnusualActivityReportListResponseDataItemRelatedEntitiesItem =
  {
    description: string;
    id: string;
  };

export type PlatformUnusualActivityReportListResponseDataItemRelatedDeclinedTransactionsItem =
  {
    description: string;
    id: string;
  };

export type PlatformUnusualActivityReportListResponseDataItem = {
  activity_began_on: PlatformUnusualActivityReportListResponseDataItemActivityBeganOn;
  activity_ended_on: PlatformUnusualActivityReportListResponseDataItemActivityEndedOn;
  created_at: string;
  created_by_email: PlatformUnusualActivityReportListResponseDataItemCreatedByEmail;
  file_attachments: PlatformUnusualActivityReportListResponseDataItemFileAttachmentsItem[];
  group_id: string;
  id: string;
  increase_review_status: PlatformUnusualActivityReportListResponseDataItemIncreaseReviewStatus;
  narrative: string;
  program_id: string;
  related_account_numbers: PlatformUnusualActivityReportListResponseDataItemRelatedAccountNumbersItem[];
  related_accounts: PlatformUnusualActivityReportListResponseDataItemRelatedAccountsItem[];
  related_cards: PlatformUnusualActivityReportListResponseDataItemRelatedCardsItem[];
  related_declined_transactions: PlatformUnusualActivityReportListResponseDataItemRelatedDeclinedTransactionsItem[];
  related_entities: PlatformUnusualActivityReportListResponseDataItemRelatedEntitiesItem[];
  related_transactions: PlatformUnusualActivityReportListResponseDataItemRelatedTransactionsItem[];
  updated_at: string;
};

export type PlatformUnusualActivityReportListResponseDataItemRelatedCardsItemDescription =
  string | null;

export type PlatformUnusualActivityReportListResponseDataItemRelatedCardsItem =
  {
    description: PlatformUnusualActivityReportListResponseDataItemRelatedCardsItemDescription;
    id: string;
  };

export type PlatformUnusualActivityReportListResponseDataItemRelatedAccountsItem =
  {
    description: string;
    id: string;
  };

export type PlatformUnusualActivityReportListResponseDataItemRelatedAccountNumbersItem =
  {
    description: string;
    id: string;
  };

export type PlatformUnusualActivityReportListResponseDataItemIncreaseReviewStatus =
  (typeof PlatformUnusualActivityReportListResponseDataItemIncreaseReviewStatus)[keyof typeof PlatformUnusualActivityReportListResponseDataItemIncreaseReviewStatus];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PlatformUnusualActivityReportListResponseDataItemIncreaseReviewStatus =
  {
    reviewing: "reviewing",
    skipped: "skipped",
    reviewed: "reviewed",
    requires_attention: "requires_attention",
  } as const;

export type PlatformUnusualActivityReportListResponseDataItemFileAttachmentsItem =
  {
    created_at: string;
    filename: string;
    id: string;
  };

export type PlatformUnusualActivityReportListResponseDataItemCreatedByEmail =
  | string
  | null;

export type PlatformUnusualActivityReportListResponseDataItemActivityEndedOn =
  | string
  | null;

export type PlatformUnusualActivityReportListResponseDataItemActivityBeganOn =
  | string
  | null;

export type PlatformUnusualActivityReportGetResponseRelatedTransactionsItem = {
  description: string;
  id: string;
};

export type PlatformUnusualActivityReportGetResponseRelatedEntitiesItem = {
  description: string;
  id: string;
};

export type PlatformUnusualActivityReportGetResponseRelatedDeclinedTransactionsItem =
  {
    description: string;
    id: string;
  };

export type PlatformUnusualActivityReportGetResponseRelatedCardsItemDescription =
  string | null;

export type PlatformUnusualActivityReportGetResponseRelatedCardsItem = {
  description: PlatformUnusualActivityReportGetResponseRelatedCardsItemDescription;
  id: string;
};

export type PlatformUnusualActivityReportGetResponseRelatedAccountsItem = {
  description: string;
  id: string;
};

export type PlatformUnusualActivityReportGetResponseRelatedAccountNumbersItem =
  {
    description: string;
    id: string;
  };

export type PlatformUnusualActivityReportGetResponseIncreaseReviewStatus =
  (typeof PlatformUnusualActivityReportGetResponseIncreaseReviewStatus)[keyof typeof PlatformUnusualActivityReportGetResponseIncreaseReviewStatus];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PlatformUnusualActivityReportGetResponseIncreaseReviewStatus = {
  reviewing: "reviewing",
  skipped: "skipped",
  reviewed: "reviewed",
  requires_attention: "requires_attention",
} as const;

export type PlatformUnusualActivityReportGetResponseFileAttachmentsItem = {
  created_at: string;
  filename: string;
  id: string;
};

export type PlatformUnusualActivityReportGetResponseCreatedByEmail =
  | string
  | null;

export type PlatformUnusualActivityReportGetResponseActivityEndedOn =
  | string
  | null;

export type PlatformUnusualActivityReportGetResponseActivityBeganOn =
  | string
  | null;

export interface PlatformUnusualActivityReportGetResponse {
  activity_began_on: PlatformUnusualActivityReportGetResponseActivityBeganOn;
  activity_ended_on: PlatformUnusualActivityReportGetResponseActivityEndedOn;
  created_at: string;
  created_by_email: PlatformUnusualActivityReportGetResponseCreatedByEmail;
  file_attachments: PlatformUnusualActivityReportGetResponseFileAttachmentsItem[];
  group_id: string;
  id: string;
  increase_review_status: PlatformUnusualActivityReportGetResponseIncreaseReviewStatus;
  narrative: string;
  program_id: string;
  related_account_numbers: PlatformUnusualActivityReportGetResponseRelatedAccountNumbersItem[];
  related_accounts: PlatformUnusualActivityReportGetResponseRelatedAccountsItem[];
  related_cards: PlatformUnusualActivityReportGetResponseRelatedCardsItem[];
  related_declined_transactions: PlatformUnusualActivityReportGetResponseRelatedDeclinedTransactionsItem[];
  related_entities: PlatformUnusualActivityReportGetResponseRelatedEntitiesItem[];
  related_transactions: PlatformUnusualActivityReportGetResponseRelatedTransactionsItem[];
  updated_at: string;
}

export type PlatformFinancialReportSubmissionGetResponseCreatedByEmail =
  | string
  | null;

export interface PlatformFinancialReportSubmissionGetResponse {
  cash_on_hand_last_day_of_period: number;
  created_at: string;
  created_by_email: PlatformFinancialReportSubmissionGetResponseCreatedByEmail;
  document_request_id: string;
  fewer_than_four_quarters_of_runway: boolean;
  group_id: string;
  id: string;
  period_balance_sheet_file_id: string;
  period_end: string;
  period_income_statement_file_id: string;
  period_start: string;
  total_net_income_for_period: number;
}

export type PlatformComplianceSubmissionProgramListResponseNextCursor =
  | string
  | null;

export interface PlatformComplianceSubmissionProgramListResponse {
  data: PlatformComplianceSubmissionProgramListResponseDataItem[];
  next_cursor: PlatformComplianceSubmissionProgramListResponseNextCursor;
}

export type PlatformComplianceSubmissionProgramListResponseDataItemUsdWireTransfersAutomatedRolling24HourVolumeLimit =
  number | null;

export type PlatformComplianceSubmissionProgramListResponseDataItemUsdRealTimePaymentsAutomatedRolling24HourVolumeLimit =
  number | null;

export type PlatformComplianceSubmissionProgramListResponseDataItemUsdCombinedInboundAutomatedRolling24HourVolumeLimit =
  number | null;

export type PlatformComplianceSubmissionProgramListResponseDataItemUsdCheckTransfersAutomatedRolling24HourVolumeLimit =
  number | null;

export type PlatformComplianceSubmissionProgramListResponseDataItemUsdCheckDepositsAutomatedRolling24HourVolumeLimit =
  number | null;

export type PlatformComplianceSubmissionProgramListResponseDataItemUsdAchDebitTransfersAutomatedRolling24HourVolumeLimit =
  number | null;

export type PlatformComplianceSubmissionProgramListResponseDataItemUsdAchCreditTransfersAutomatedRolling24HourVolumeLimit =
  number | null;

export type PlatformComplianceSubmissionProgramListResponseDataItemReserveAccountId =
  string | null;

export type PlatformComplianceSubmissionProgramListResponseDataItemReserveAccountExpectedMinimumBalance =
  number | null;

export type PlatformComplianceSubmissionProgramListResponseDataItemMaximumPhysicalCardCount =
  number | null;

export type PlatformComplianceSubmissionProgramListResponseDataItemMaximumCardCount =
  number | null;

export type PlatformComplianceSubmissionProgramListResponseDataItemMaximumAccountNumberCount =
  number | null;

export type PlatformComplianceSubmissionProgramListResponseDataItemInterestRateAnyOf =
  {
    /** @pattern ^-?\d+(\.\d+)?$ */
    current_value: string;
    summary: string;
  };

export type PlatformComplianceSubmissionProgramListResponseDataItemInterestRate =
  PlatformComplianceSubmissionProgramListResponseDataItemInterestRateAnyOf | null;

export type PlatformComplianceSubmissionProgramListResponseDataItemInterestAccountId =
  string | null;

export type PlatformComplianceSubmissionProgramListResponseDataItemIncreaseComplianceRole =
  (typeof PlatformComplianceSubmissionProgramListResponseDataItemIncreaseComplianceRole)[keyof typeof PlatformComplianceSubmissionProgramListResponseDataItemIncreaseComplianceRole];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PlatformComplianceSubmissionProgramListResponseDataItemIncreaseComplianceRole =
  {
    first_line: "first_line",
    oversight: "oversight",
    technology_only: "technology_only",
  } as const;

export type PlatformComplianceSubmissionProgramListResponseDataItemInboundFundsHoldPolicy =
  (typeof PlatformComplianceSubmissionProgramListResponseDataItemInboundFundsHoldPolicy)[keyof typeof PlatformComplianceSubmissionProgramListResponseDataItemInboundFundsHoldPolicy];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PlatformComplianceSubmissionProgramListResponseDataItemInboundFundsHoldPolicy =
  {
    release_immediately: "release_immediately",
    standard: "standard",
    five_business_days_for_ach: "five_business_days_for_ach",
    seven_business_days_for_ach: "seven_business_days_for_ach",
  } as const;

export type PlatformComplianceSubmissionProgramListResponseDataItemGroupName =
  | string
  | null;

export type PlatformComplianceSubmissionProgramListResponseDataItemForBenefitOfEntityId =
  string | null;

export type PlatformComplianceSubmissionProgramListResponseDataItemFirstInternetBankRequiredMinimumReserve =
  number | null;

export type PlatformComplianceSubmissionProgramListResponseDataItemBillingAccountId =
  string | null;

export type PlatformComplianceSubmissionProgramListResponseDataItemBank =
  (typeof PlatformComplianceSubmissionProgramListResponseDataItemBank)[keyof typeof PlatformComplianceSubmissionProgramListResponseDataItemBank];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PlatformComplianceSubmissionProgramListResponseDataItemBank = {
  blue_ridge_bank: "blue_ridge_bank",
  core_bank: "core_bank",
  first_internet_bank: "first_internet_bank",
  global_innovations_bank: "global_innovations_bank",
  grasshopper_bank: "grasshopper_bank",
} as const;

export type PlatformComplianceSubmissionProgramListResponseDataItem = {
  allow_account_creation: boolean;
  allows_submitting_security_incident_reports: boolean;
  allows_submitting_unusual_activity_reports: boolean;
  allows_submitting_vendors: boolean;
  api_transfers_require_approval: boolean;
  bank: PlatformComplianceSubmissionProgramListResponseDataItemBank;
  bank_program_id: string;
  billing_account_id: PlatformComplianceSubmissionProgramListResponseDataItemBillingAccountId;
  count_accounts: number;
  count_entities: number;
  created_at: string;
  credit_exposure_limit: number;
  dashboard_transfers_require_approval: boolean;
  first_internet_bank_required_minimum_reserve: PlatformComplianceSubmissionProgramListResponseDataItemFirstInternetBankRequiredMinimumReserve;
  for_benefit_of_entity_id: PlatformComplianceSubmissionProgramListResponseDataItemForBenefitOfEntityId;
  group_id: string;
  group_name: PlatformComplianceSubmissionProgramListResponseDataItemGroupName;
  id: string;
  inbound_funds_hold_policy: PlatformComplianceSubmissionProgramListResponseDataItemInboundFundsHoldPolicy;
  increase_compliance_role: PlatformComplianceSubmissionProgramListResponseDataItemIncreaseComplianceRole;
  interest_account_id: PlatformComplianceSubmissionProgramListResponseDataItemInterestAccountId;
  interest_rate: PlatformComplianceSubmissionProgramListResponseDataItemInterestRate;
  limit_rolling_24_hour_card_authorization_volume: boolean;
  maximum_account_number_count: PlatformComplianceSubmissionProgramListResponseDataItemMaximumAccountNumberCount;
  maximum_card_count: PlatformComplianceSubmissionProgramListResponseDataItemMaximumCardCount;
  maximum_physical_card_count: PlatformComplianceSubmissionProgramListResponseDataItemMaximumPhysicalCardCount;
  name: string;
  request_complaint_lists_automatically: boolean;
  reserve_account_expected_minimum_balance: PlatformComplianceSubmissionProgramListResponseDataItemReserveAccountExpectedMinimumBalance;
  reserve_account_id: PlatformComplianceSubmissionProgramListResponseDataItemReserveAccountId;
  sum_balances: number;
  transfers_approvable_by_creator: boolean;
  updated_at: string;
  usd_ach_credit_transfers_automated_rolling_24_hour_volume_limit: PlatformComplianceSubmissionProgramListResponseDataItemUsdAchCreditTransfersAutomatedRolling24HourVolumeLimit;
  usd_ach_debit_transfers_automated_rolling_24_hour_volume_limit: PlatformComplianceSubmissionProgramListResponseDataItemUsdAchDebitTransfersAutomatedRolling24HourVolumeLimit;
  usd_authentication_attempted_card_limit_rolling_24_hour_volume_limit: number;
  usd_check_deposits_automated_rolling_24_hour_volume_limit: PlatformComplianceSubmissionProgramListResponseDataItemUsdCheckDepositsAutomatedRolling24HourVolumeLimit;
  usd_check_transfers_automated_rolling_24_hour_volume_limit: PlatformComplianceSubmissionProgramListResponseDataItemUsdCheckTransfersAutomatedRolling24HourVolumeLimit;
  usd_combined_inbound_automated_rolling_24_hour_volume_limit: PlatformComplianceSubmissionProgramListResponseDataItemUsdCombinedInboundAutomatedRolling24HourVolumeLimit;
  usd_combined_rolling_24_hour_transfer_volume_limit: number;
  usd_per_ach_credit_transfer_limit: number;
  usd_per_ach_debit_transfer_limit: number;
  usd_per_check_deposit_limit: number;
  usd_per_check_transfer_limit: number;
  usd_per_real_time_payments_transfer_limit: number;
  usd_per_wire_transfer_limit: number;
  usd_real_time_payments_automated_rolling_24_hour_volume_limit: PlatformComplianceSubmissionProgramListResponseDataItemUsdRealTimePaymentsAutomatedRolling24HourVolumeLimit;
  usd_wire_transfers_automated_rolling_24_hour_volume_limit: PlatformComplianceSubmissionProgramListResponseDataItemUsdWireTransfersAutomatedRolling24HourVolumeLimit;
};

export type PlatformComplianceMetricsSubmissionListResponseNextCursor =
  | string
  | null;

export interface PlatformComplianceMetricsSubmissionListResponse {
  data: PlatformComplianceMetricsSubmissionListResponseDataItem[];
  next_cursor: PlatformComplianceMetricsSubmissionListResponseNextCursor;
}

export type PlatformComplianceMetricsSubmissionListResponseDataItemPeriodStart =
  string | null;

export type PlatformComplianceMetricsSubmissionListResponseDataItemPeriodEnd =
  | string
  | null;

export type PlatformComplianceMetricsSubmissionListResponseDataItemNumberOfUsersOnboarded =
  number | null;

export type PlatformComplianceMetricsSubmissionListResponseDataItemNumberOfUsersOffboarded =
  number | null;

export type PlatformComplianceMetricsSubmissionListResponseDataItemNumberOfRegulatorLawEnforcementLegalComplaintsReceived =
  number | null;

export type PlatformComplianceMetricsSubmissionListResponseDataItemNumberOfFraudCasesAssociatedWithIdentityTheft =
  number | null;

export type PlatformComplianceMetricsSubmissionListResponseDataItemNumberOfComplaintsReceived =
  number | null;

export type PlatformComplianceMetricsSubmissionListResponseDataItemNumberOfCardRelatedRegulationEDisputesReceived =
  number | null;

export type PlatformComplianceMetricsSubmissionListResponseDataItemNumberOfApplicationsReceived =
  number | null;

export type PlatformComplianceMetricsSubmissionListResponseDataItemNumberOfApplicationsDenied =
  number | null;

export type PlatformComplianceMetricsSubmissionListResponseDataItemNumberOfAchRelatedRegulationEDisputesReceived =
  number | null;

export type PlatformComplianceMetricsSubmissionListResponseDataItemEarliestUnresolvedAlert =
  string | null;

export type PlatformComplianceMetricsSubmissionListResponseDataItemCreatedByEmail =
  string | null;

export type PlatformComplianceMetricsSubmissionListResponseDataItem = {
  attested_complaints_are_up_to_date: boolean;
  attested_security_incident_reports_are_up_to_date: boolean;
  attested_vendors_are_up_to_date: boolean;
  created_at: string;
  created_by_email: PlatformComplianceMetricsSubmissionListResponseDataItemCreatedByEmail;
  document_request_id: string;
  earliest_unresolved_alert: PlatformComplianceMetricsSubmissionListResponseDataItemEarliestUnresolvedAlert;
  fraud_loss_amount: number;
  group_id: string;
  id: string;
  number_of_ach_related_regulation_e_disputes_received: PlatformComplianceMetricsSubmissionListResponseDataItemNumberOfAchRelatedRegulationEDisputesReceived;
  number_of_aml_related_transaction_monitoring_alerts_generated: number;
  number_of_applications_denied: PlatformComplianceMetricsSubmissionListResponseDataItemNumberOfApplicationsDenied;
  number_of_applications_received: PlatformComplianceMetricsSubmissionListResponseDataItemNumberOfApplicationsReceived;
  number_of_card_related_regulation_e_disputes_received: PlatformComplianceMetricsSubmissionListResponseDataItemNumberOfCardRelatedRegulationEDisputesReceived;
  number_of_cases_escalated_to_increase: number;
  number_of_complaints_received: PlatformComplianceMetricsSubmissionListResponseDataItemNumberOfComplaintsReceived;
  number_of_fraud_cases: number;
  number_of_fraud_cases_associated_with_identity_theft: PlatformComplianceMetricsSubmissionListResponseDataItemNumberOfFraudCasesAssociatedWithIdentityTheft;
  number_of_fraud_related_transaction_monitoring_alerts_generated: number;
  number_of_regulator_law_enforcement_legal_complaints_received: PlatformComplianceMetricsSubmissionListResponseDataItemNumberOfRegulatorLawEnforcementLegalComplaintsReceived;
  number_of_true_office_of_foreign_asset_control_hits: number;
  number_of_unusual_activity_report_cases_created: number;
  number_of_users_offboarded: PlatformComplianceMetricsSubmissionListResponseDataItemNumberOfUsersOffboarded;
  number_of_users_onboarded: PlatformComplianceMetricsSubmissionListResponseDataItemNumberOfUsersOnboarded;
  period_end: PlatformComplianceMetricsSubmissionListResponseDataItemPeriodEnd;
  period_start: PlatformComplianceMetricsSubmissionListResponseDataItemPeriodStart;
  program_id: string;
};

export type PlatformComplianceMetricsSubmissionGetResponsePeriodStart =
  | string
  | null;

export type PlatformComplianceMetricsSubmissionGetResponsePeriodEnd =
  | string
  | null;

export type PlatformComplianceMetricsSubmissionGetResponseNumberOfUsersOnboarded =
  number | null;

export type PlatformComplianceMetricsSubmissionGetResponseNumberOfUsersOffboarded =
  number | null;

export type PlatformComplianceMetricsSubmissionGetResponseNumberOfRegulatorLawEnforcementLegalComplaintsReceived =
  number | null;

export type PlatformComplianceMetricsSubmissionGetResponseNumberOfFraudCasesAssociatedWithIdentityTheft =
  number | null;

export type PlatformComplianceMetricsSubmissionGetResponseNumberOfComplaintsReceived =
  number | null;

export type PlatformComplianceMetricsSubmissionGetResponseNumberOfCardRelatedRegulationEDisputesReceived =
  number | null;

export type PlatformComplianceMetricsSubmissionGetResponseNumberOfApplicationsReceived =
  number | null;

export type PlatformComplianceMetricsSubmissionGetResponseNumberOfApplicationsDenied =
  number | null;

export type PlatformComplianceMetricsSubmissionGetResponseNumberOfAchRelatedRegulationEDisputesReceived =
  number | null;

export type PlatformComplianceMetricsSubmissionGetResponseEarliestUnresolvedAlert =
  string | null;

export type PlatformComplianceMetricsSubmissionGetResponseCreatedByEmail =
  | string
  | null;

export interface PlatformComplianceMetricsSubmissionGetResponse {
  attested_complaints_are_up_to_date: boolean;
  attested_security_incident_reports_are_up_to_date: boolean;
  attested_vendors_are_up_to_date: boolean;
  created_at: string;
  created_by_email: PlatformComplianceMetricsSubmissionGetResponseCreatedByEmail;
  document_request_id: string;
  earliest_unresolved_alert: PlatformComplianceMetricsSubmissionGetResponseEarliestUnresolvedAlert;
  fraud_loss_amount: number;
  group_id: string;
  id: string;
  number_of_ach_related_regulation_e_disputes_received: PlatformComplianceMetricsSubmissionGetResponseNumberOfAchRelatedRegulationEDisputesReceived;
  number_of_aml_related_transaction_monitoring_alerts_generated: number;
  number_of_applications_denied: PlatformComplianceMetricsSubmissionGetResponseNumberOfApplicationsDenied;
  number_of_applications_received: PlatformComplianceMetricsSubmissionGetResponseNumberOfApplicationsReceived;
  number_of_card_related_regulation_e_disputes_received: PlatformComplianceMetricsSubmissionGetResponseNumberOfCardRelatedRegulationEDisputesReceived;
  number_of_cases_escalated_to_increase: number;
  number_of_complaints_received: PlatformComplianceMetricsSubmissionGetResponseNumberOfComplaintsReceived;
  number_of_fraud_cases: number;
  number_of_fraud_cases_associated_with_identity_theft: PlatformComplianceMetricsSubmissionGetResponseNumberOfFraudCasesAssociatedWithIdentityTheft;
  number_of_fraud_related_transaction_monitoring_alerts_generated: number;
  number_of_regulator_law_enforcement_legal_complaints_received: PlatformComplianceMetricsSubmissionGetResponseNumberOfRegulatorLawEnforcementLegalComplaintsReceived;
  number_of_true_office_of_foreign_asset_control_hits: number;
  number_of_unusual_activity_report_cases_created: number;
  number_of_users_offboarded: PlatformComplianceMetricsSubmissionGetResponseNumberOfUsersOffboarded;
  number_of_users_onboarded: PlatformComplianceMetricsSubmissionGetResponseNumberOfUsersOnboarded;
  period_end: PlatformComplianceMetricsSubmissionGetResponsePeriodEnd;
  period_start: PlatformComplianceMetricsSubmissionGetResponsePeriodStart;
  program_id: string;
}

export type PlatformComplaintListSubmissionListResponseNextCursor =
  | string
  | null;

export type PlatformComplaintListSubmissionListResponseDataItemPeriodStart =
  | string
  | null;

export type PlatformComplaintListSubmissionListResponseDataItemPeriodEnd =
  | string
  | null;

export type PlatformComplaintListSubmissionListResponseDataItem = {
  complaints: PlatformComplaintListSubmissionListResponseDataItemComplaintsItem[];
  created_at: string;
  document_request_id: string;
  group_id: string;
  id: string;
  period_end: PlatformComplaintListSubmissionListResponseDataItemPeriodEnd;
  period_start: PlatformComplaintListSubmissionListResponseDataItemPeriodStart;
};

export interface PlatformComplaintListSubmissionListResponse {
  data: PlatformComplaintListSubmissionListResponseDataItem[];
  next_cursor: PlatformComplaintListSubmissionListResponseNextCursor;
}

export type PlatformComplaintListSubmissionListResponseDataItemComplaintsItemSource =
  (typeof PlatformComplaintListSubmissionListResponseDataItemComplaintsItemSource)[keyof typeof PlatformComplaintListSubmissionListResponseDataItemComplaintsItemSource];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PlatformComplaintListSubmissionListResponseDataItemComplaintsItemSource =
  {
    customer: "customer",
    regulator: "regulator",
    law_enforcement: "law_enforcement",
    law_firm: "law_firm",
  } as const;

export type PlatformComplaintListSubmissionListResponseDataItemComplaintsItemResolvedOn =
  string | null;

export type PlatformComplaintListSubmissionListResponseDataItemComplaintsItemComplaintType =
  (typeof PlatformComplaintListSubmissionListResponseDataItemComplaintsItemComplaintType)[keyof typeof PlatformComplaintListSubmissionListResponseDataItemComplaintsItemComplaintType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PlatformComplaintListSubmissionListResponseDataItemComplaintsItemComplaintType =
  {
    complaint: "complaint",
    dispute: "dispute",
  } as const;

export type PlatformComplaintListSubmissionListResponseDataItemComplaintsItem =
  {
    complaint_type: PlatformComplaintListSubmissionListResponseDataItemComplaintsItemComplaintType;
    description: string;
    id: string;
    name: string;
    received_on: string;
    resolved_on: PlatformComplaintListSubmissionListResponseDataItemComplaintsItemResolvedOn;
    source: PlatformComplaintListSubmissionListResponseDataItemComplaintsItemSource;
  };

export type PlatformComplaintListSubmissionGetResponsePeriodStart =
  | string
  | null;

export type PlatformComplaintListSubmissionGetResponsePeriodEnd = string | null;

export interface PlatformComplaintListSubmissionGetResponse {
  complaints: PlatformComplaintListSubmissionGetResponseComplaintsItem[];
  created_at: string;
  document_request_id: string;
  group_id: string;
  id: string;
  period_end: PlatformComplaintListSubmissionGetResponsePeriodEnd;
  period_start: PlatformComplaintListSubmissionGetResponsePeriodStart;
}

export type PlatformComplaintListSubmissionGetResponseComplaintsItemSource =
  (typeof PlatformComplaintListSubmissionGetResponseComplaintsItemSource)[keyof typeof PlatformComplaintListSubmissionGetResponseComplaintsItemSource];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PlatformComplaintListSubmissionGetResponseComplaintsItemSource = {
  customer: "customer",
  regulator: "regulator",
  law_enforcement: "law_enforcement",
  law_firm: "law_firm",
} as const;

export type PlatformComplaintListSubmissionGetResponseComplaintsItemResolvedOn =
  string | null;

export type PlatformComplaintListSubmissionGetResponseComplaintsItemComplaintType =
  (typeof PlatformComplaintListSubmissionGetResponseComplaintsItemComplaintType)[keyof typeof PlatformComplaintListSubmissionGetResponseComplaintsItemComplaintType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PlatformComplaintListSubmissionGetResponseComplaintsItemComplaintType =
  {
    complaint: "complaint",
    dispute: "dispute",
  } as const;

export type PlatformComplaintListSubmissionGetResponseComplaintsItem = {
  complaint_type: PlatformComplaintListSubmissionGetResponseComplaintsItemComplaintType;
  description: string;
  id: string;
  name: string;
  received_on: string;
  resolved_on: PlatformComplaintListSubmissionGetResponseComplaintsItemResolvedOn;
  source: PlatformComplaintListSubmissionGetResponseComplaintsItemSource;
};

export type PlatformComplaintListResponseNextCursor = string | null;

export interface PlatformComplaintListResponse {
  data: PlatformComplaintListResponseDataItem[];
  next_cursor: PlatformComplaintListResponseNextCursor;
}

export type PlatformComplaintListResponseDataItemSource =
  (typeof PlatformComplaintListResponseDataItemSource)[keyof typeof PlatformComplaintListResponseDataItemSource];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PlatformComplaintListResponseDataItemSource = {
  customer: "customer",
  regulator: "regulator",
  law_enforcement: "law_enforcement",
  law_firm: "law_firm",
} as const;

export type PlatformComplaintListResponseDataItemResolvedOn = string | null;

export type PlatformComplaintListResponseDataItemComplaintType =
  (typeof PlatformComplaintListResponseDataItemComplaintType)[keyof typeof PlatformComplaintListResponseDataItemComplaintType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PlatformComplaintListResponseDataItemComplaintType = {
  complaint: "complaint",
  ach_dispute: "ach_dispute",
  card_dispute: "card_dispute",
} as const;

export type PlatformComplaintListResponseDataItem = {
  complaint_type: PlatformComplaintListResponseDataItemComplaintType;
  created_at: string;
  description: string;
  group_id: string;
  id: string;
  name: string;
  received_on: string;
  resolved_on: PlatformComplaintListResponseDataItemResolvedOn;
  source: PlatformComplaintListResponseDataItemSource;
  updated_at: string;
};

export type PlatformComplaintGetResponseSource =
  (typeof PlatformComplaintGetResponseSource)[keyof typeof PlatformComplaintGetResponseSource];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PlatformComplaintGetResponseSource = {
  customer: "customer",
  regulator: "regulator",
  law_enforcement: "law_enforcement",
  law_firm: "law_firm",
} as const;

export type PlatformComplaintGetResponseResolvedOn = string | null;

export type PlatformComplaintGetResponseComplaintType =
  (typeof PlatformComplaintGetResponseComplaintType)[keyof typeof PlatformComplaintGetResponseComplaintType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PlatformComplaintGetResponseComplaintType = {
  complaint: "complaint",
  ach_dispute: "ach_dispute",
  card_dispute: "card_dispute",
} as const;

export interface PlatformComplaintGetResponse {
  complaint_type: PlatformComplaintGetResponseComplaintType;
  created_at: string;
  description: string;
  group_id: string;
  id: string;
  name: string;
  received_on: string;
  resolved_on: PlatformComplaintGetResponseResolvedOn;
  source: PlatformComplaintGetResponseSource;
  updated_at: string;
}

export type PhysicalCardListResponseNextCursor = string | null;

export type PhysicalCardListResponseDataItemStatus =
  (typeof PhysicalCardListResponseDataItemStatus)[keyof typeof PhysicalCardListResponseDataItemStatus];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PhysicalCardListResponseDataItemStatus = {
  active: "active",
  disabled: "disabled",
  canceled: "canceled",
} as const;

export type PhysicalCardListResponseDataItem = {
  account_id: string;
  canceled_at: PhysicalCardListResponseDataItemCanceledAt;
  card_id: string;
  cardholder: PhysicalCardListResponseDataItemCardholder;
  created_at: string;
  group_id: string;
  id: string;
  physical_card_profile_id: PhysicalCardListResponseDataItemPhysicalCardProfileId;
  shipment: PhysicalCardListResponseDataItemShipment;
  status: PhysicalCardListResponseDataItemStatus;
};

export interface PhysicalCardListResponse {
  data: PhysicalCardListResponseDataItem[];
  next_cursor: PhysicalCardListResponseNextCursor;
}

export type PhysicalCardListResponseDataItemShipmentTracking =
  PhysicalCardListResponseDataItemShipmentTrackingAnyOf | null;

export type PhysicalCardListResponseDataItemShipmentTrackingAnyOfReturnReason =
  | string
  | null;

export type PhysicalCardListResponseDataItemShipmentTrackingAnyOfReturnNumber =
  | string
  | null;

export type PhysicalCardListResponseDataItemShipmentTrackingAnyOf = {
  number: string;
  return_number: PhysicalCardListResponseDataItemShipmentTrackingAnyOfReturnNumber;
  return_reason: PhysicalCardListResponseDataItemShipmentTrackingAnyOfReturnReason;
  shipped_at: string;
};

export type PhysicalCardListResponseDataItemShipmentStatus =
  (typeof PhysicalCardListResponseDataItemShipmentStatus)[keyof typeof PhysicalCardListResponseDataItemShipmentStatus];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PhysicalCardListResponseDataItemShipmentStatus = {
  pending: "pending",
  canceled: "canceled",
  submitted: "submitted",
  acknowledged: "acknowledged",
  rejected: "rejected",
  shipped: "shipped",
  returned: "returned",
} as const;

export type PhysicalCardListResponseDataItemShipmentMethod =
  (typeof PhysicalCardListResponseDataItemShipmentMethod)[keyof typeof PhysicalCardListResponseDataItemShipmentMethod];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PhysicalCardListResponseDataItemShipmentMethod = {
  usps: "usps",
  fedex_priority_overnight: "fedex_priority_overnight",
  fedex_2_day: "fedex_2_day",
} as const;

export type PhysicalCardListResponseDataItemShipmentAddressLine3 =
  | string
  | null;

export type PhysicalCardListResponseDataItemShipmentAddressLine2 =
  | string
  | null;

export type PhysicalCardListResponseDataItemShipmentAddress = {
  city: string;
  line1: string;
  line2: PhysicalCardListResponseDataItemShipmentAddressLine2;
  line3: PhysicalCardListResponseDataItemShipmentAddressLine3;
  name: string;
  postal_code: string;
  state: string;
};

export type PhysicalCardListResponseDataItemShipment = {
  address: PhysicalCardListResponseDataItemShipmentAddress;
  method: PhysicalCardListResponseDataItemShipmentMethod;
  status: PhysicalCardListResponseDataItemShipmentStatus;
  tracking: PhysicalCardListResponseDataItemShipmentTracking;
};

export type PhysicalCardListResponseDataItemPhysicalCardProfileId =
  | string
  | null;

export type PhysicalCardListResponseDataItemCardholder = {
  first_name: string;
  last_name: string;
};

export type PhysicalCardListResponseDataItemCanceledAt = string | null;

export type PhysicalCardImageFileRejectPostResponseStatus =
  (typeof PhysicalCardImageFileRejectPostResponseStatus)[keyof typeof PhysicalCardImageFileRejectPostResponseStatus];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PhysicalCardImageFileRejectPostResponseStatus = {
  pending_reviewing: "pending_reviewing",
  rejected: "rejected",
  pending_submitting: "pending_submitting",
  submitted: "submitted",
  requires_attention: "requires_attention",
} as const;

export type PhysicalCardImageFileRejectPostResponseImageType =
  (typeof PhysicalCardImageFileRejectPostResponseImageType)[keyof typeof PhysicalCardImageFileRejectPostResponseImageType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PhysicalCardImageFileRejectPostResponseImageType = {
  front: "front",
  back: "back",
  carrier: "carrier",
} as const;

export interface PhysicalCardImageFileRejectPostResponse {
  card_profiles: PhysicalCardImageFileRejectPostResponseCardProfilesItem[];
  created_at: string;
  group_id: string;
  id: string;
  image_type: PhysicalCardImageFileRejectPostResponseImageType;
  status: PhysicalCardImageFileRejectPostResponseStatus;
  transformed_api_file_id: string;
  updated_at: string;
}

export type PhysicalCardImageFileRejectPostResponseCardProfilesItemStatus =
  (typeof PhysicalCardImageFileRejectPostResponseCardProfilesItemStatus)[keyof typeof PhysicalCardImageFileRejectPostResponseCardProfilesItemStatus];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PhysicalCardImageFileRejectPostResponseCardProfilesItemStatus = {
  pending_creating: "pending_creating",
  pending_reviewing: "pending_reviewing",
  rejected: "rejected",
  pending_submitting: "pending_submitting",
  active: "active",
  archived: "archived",
} as const;

export type PhysicalCardImageFileRejectPostResponseCardProfilesItem = {
  id: string;
  status: PhysicalCardImageFileRejectPostResponseCardProfilesItemStatus;
};

export type PhysicalCardImageFileListResponseNextCursor = string | null;

export interface PhysicalCardImageFileListResponse {
  data: PhysicalCardImageFileListResponseDataItem[];
  next_cursor: PhysicalCardImageFileListResponseNextCursor;
}

export type PhysicalCardImageFileListResponseDataItemStatus =
  (typeof PhysicalCardImageFileListResponseDataItemStatus)[keyof typeof PhysicalCardImageFileListResponseDataItemStatus];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PhysicalCardImageFileListResponseDataItemStatus = {
  pending_reviewing: "pending_reviewing",
  rejected: "rejected",
  pending_submitting: "pending_submitting",
  submitted: "submitted",
  requires_attention: "requires_attention",
} as const;

export type PhysicalCardImageFileListResponseDataItemImageType =
  (typeof PhysicalCardImageFileListResponseDataItemImageType)[keyof typeof PhysicalCardImageFileListResponseDataItemImageType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PhysicalCardImageFileListResponseDataItemImageType = {
  front: "front",
  back: "back",
  carrier: "carrier",
} as const;

export type PhysicalCardImageFileListResponseDataItemCardProfilesItemStatus =
  (typeof PhysicalCardImageFileListResponseDataItemCardProfilesItemStatus)[keyof typeof PhysicalCardImageFileListResponseDataItemCardProfilesItemStatus];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PhysicalCardImageFileListResponseDataItemCardProfilesItemStatus = {
  pending_creating: "pending_creating",
  pending_reviewing: "pending_reviewing",
  rejected: "rejected",
  pending_submitting: "pending_submitting",
  active: "active",
  archived: "archived",
} as const;

export type PhysicalCardImageFileListResponseDataItemCardProfilesItem = {
  id: string;
  status: PhysicalCardImageFileListResponseDataItemCardProfilesItemStatus;
};

export type PhysicalCardImageFileListResponseDataItem = {
  card_profiles: PhysicalCardImageFileListResponseDataItemCardProfilesItem[];
  created_at: string;
  group_id: string;
  id: string;
  image_type: PhysicalCardImageFileListResponseDataItemImageType;
  status: PhysicalCardImageFileListResponseDataItemStatus;
  transformed_api_file_id: string;
  updated_at: string;
};

export type PhysicalCardImageFileApprovePostResponseStatus =
  (typeof PhysicalCardImageFileApprovePostResponseStatus)[keyof typeof PhysicalCardImageFileApprovePostResponseStatus];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PhysicalCardImageFileApprovePostResponseStatus = {
  pending_reviewing: "pending_reviewing",
  rejected: "rejected",
  pending_submitting: "pending_submitting",
  submitted: "submitted",
  requires_attention: "requires_attention",
} as const;

export type PhysicalCardImageFileApprovePostResponseImageType =
  (typeof PhysicalCardImageFileApprovePostResponseImageType)[keyof typeof PhysicalCardImageFileApprovePostResponseImageType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PhysicalCardImageFileApprovePostResponseImageType = {
  front: "front",
  back: "back",
  carrier: "carrier",
} as const;

export type PhysicalCardImageFileApprovePostResponseCardProfilesItemStatus =
  (typeof PhysicalCardImageFileApprovePostResponseCardProfilesItemStatus)[keyof typeof PhysicalCardImageFileApprovePostResponseCardProfilesItemStatus];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PhysicalCardImageFileApprovePostResponseCardProfilesItemStatus = {
  pending_creating: "pending_creating",
  pending_reviewing: "pending_reviewing",
  rejected: "rejected",
  pending_submitting: "pending_submitting",
  active: "active",
  archived: "archived",
} as const;

export type PhysicalCardImageFileApprovePostResponseCardProfilesItem = {
  id: string;
  status: PhysicalCardImageFileApprovePostResponseCardProfilesItemStatus;
};

export interface PhysicalCardImageFileApprovePostResponse {
  card_profiles: PhysicalCardImageFileApprovePostResponseCardProfilesItem[];
  created_at: string;
  group_id: string;
  id: string;
  image_type: PhysicalCardImageFileApprovePostResponseImageType;
  status: PhysicalCardImageFileApprovePostResponseStatus;
  transformed_api_file_id: string;
  updated_at: string;
}

export type PhysicalCardGetResponseStatus =
  (typeof PhysicalCardGetResponseStatus)[keyof typeof PhysicalCardGetResponseStatus];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PhysicalCardGetResponseStatus = {
  active: "active",
  disabled: "disabled",
  canceled: "canceled",
} as const;

export type PhysicalCardGetResponseShipment = {
  address: PhysicalCardGetResponseShipmentAddress;
  method: PhysicalCardGetResponseShipmentMethod;
  status: PhysicalCardGetResponseShipmentStatus;
  tracking: PhysicalCardGetResponseShipmentTracking;
};

export interface PhysicalCardGetResponse {
  account_id: string;
  canceled_at: PhysicalCardGetResponseCanceledAt;
  card_id: string;
  cardholder: PhysicalCardGetResponseCardholder;
  created_at: string;
  group_id: string;
  id: string;
  physical_card_profile_id: PhysicalCardGetResponsePhysicalCardProfileId;
  shipment: PhysicalCardGetResponseShipment;
  status: PhysicalCardGetResponseStatus;
}

export type PhysicalCardGetResponseShipmentTrackingAnyOfReturnReason =
  | string
  | null;

export type PhysicalCardGetResponseShipmentTrackingAnyOfReturnNumber =
  | string
  | null;

export type PhysicalCardGetResponseShipmentTrackingAnyOf = {
  number: string;
  return_number: PhysicalCardGetResponseShipmentTrackingAnyOfReturnNumber;
  return_reason: PhysicalCardGetResponseShipmentTrackingAnyOfReturnReason;
  shipped_at: string;
};

export type PhysicalCardGetResponseShipmentTracking =
  PhysicalCardGetResponseShipmentTrackingAnyOf | null;

export type PhysicalCardGetResponseShipmentStatus =
  (typeof PhysicalCardGetResponseShipmentStatus)[keyof typeof PhysicalCardGetResponseShipmentStatus];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PhysicalCardGetResponseShipmentStatus = {
  pending: "pending",
  canceled: "canceled",
  submitted: "submitted",
  acknowledged: "acknowledged",
  rejected: "rejected",
  shipped: "shipped",
  returned: "returned",
} as const;

export type PhysicalCardGetResponseShipmentMethod =
  (typeof PhysicalCardGetResponseShipmentMethod)[keyof typeof PhysicalCardGetResponseShipmentMethod];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PhysicalCardGetResponseShipmentMethod = {
  usps: "usps",
  fedex_priority_overnight: "fedex_priority_overnight",
  fedex_2_day: "fedex_2_day",
} as const;

export type PhysicalCardGetResponseShipmentAddressLine3 = string | null;

export type PhysicalCardGetResponseShipmentAddressLine2 = string | null;

export type PhysicalCardGetResponseShipmentAddress = {
  city: string;
  line1: string;
  line2: PhysicalCardGetResponseShipmentAddressLine2;
  line3: PhysicalCardGetResponseShipmentAddressLine3;
  name: string;
  postal_code: string;
  state: string;
};

export type PhysicalCardGetResponsePhysicalCardProfileId = string | null;

export type PhysicalCardGetResponseCardholder = {
  first_name: string;
  last_name: string;
};

export type PhysicalCardGetResponseCanceledAt = string | null;

export type PendingTransactionListResponseNextCursor = string | null;

export type PendingTransactionListResponseDataItemStatus =
  (typeof PendingTransactionListResponseDataItemStatus)[keyof typeof PendingTransactionListResponseDataItemStatus];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PendingTransactionListResponseDataItemStatus = {
  pending: "pending",
  complete: "complete",
} as const;

export type PendingTransactionListResponseDataItemSourceDocumentation =
  | string
  | null;

export type PendingTransactionListResponseDataItemRoute =
  PendingTransactionListResponseDataItemRouteAnyOf | null;

export type PendingTransactionListResponseDataItem = {
  account_id: string;
  amount: number;
  completed_at: PendingTransactionListResponseDataItemCompletedAt;
  created_at: string;
  currency: PendingTransactionListResponseDataItemCurrency;
  date: string;
  description: string;
  group_id: string;
  id: string;
  route: PendingTransactionListResponseDataItemRoute;
  source: unknown;
  source_documentation: PendingTransactionListResponseDataItemSourceDocumentation;
  source_id: string;
  source_type: string;
  status: PendingTransactionListResponseDataItemStatus;
  type: string;
};

export interface PendingTransactionListResponse {
  data: PendingTransactionListResponseDataItem[];
  next_cursor: PendingTransactionListResponseNextCursor;
}

export type PendingTransactionListResponseDataItemRouteAnyOfType =
  (typeof PendingTransactionListResponseDataItemRouteAnyOfType)[keyof typeof PendingTransactionListResponseDataItemRouteAnyOfType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PendingTransactionListResponseDataItemRouteAnyOfType = {
  account_number: "account_number",
  card: "card",
  lockbox: "lockbox",
} as const;

export type PendingTransactionListResponseDataItemRouteAnyOfDescription =
  | string
  | null;

export type PendingTransactionListResponseDataItemRouteAnyOf = {
  description: PendingTransactionListResponseDataItemRouteAnyOfDescription;
  id: string;
  type: PendingTransactionListResponseDataItemRouteAnyOfType;
};

export type PendingTransactionListResponseDataItemCurrency =
  (typeof PendingTransactionListResponseDataItemCurrency)[keyof typeof PendingTransactionListResponseDataItemCurrency];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PendingTransactionListResponseDataItemCurrency = {
  CAD: "CAD",
  CHF: "CHF",
  EUR: "EUR",
  GBP: "GBP",
  JPY: "JPY",
  USD: "USD",
} as const;

export type PendingTransactionListResponseDataItemCompletedAt = string | null;

export type PendingTransactionGetResponseStatus =
  (typeof PendingTransactionGetResponseStatus)[keyof typeof PendingTransactionGetResponseStatus];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PendingTransactionGetResponseStatus = {
  pending: "pending",
  complete: "complete",
} as const;

export type PendingTransactionGetResponseSourceDocumentation = string | null;

export type PendingTransactionGetResponseRouteAnyOfType =
  (typeof PendingTransactionGetResponseRouteAnyOfType)[keyof typeof PendingTransactionGetResponseRouteAnyOfType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PendingTransactionGetResponseRouteAnyOfType = {
  account_number: "account_number",
  card: "card",
  lockbox: "lockbox",
} as const;

export type PendingTransactionGetResponseRouteAnyOfDescription = string | null;

export type PendingTransactionGetResponseRouteAnyOf = {
  description: PendingTransactionGetResponseRouteAnyOfDescription;
  id: string;
  type: PendingTransactionGetResponseRouteAnyOfType;
};

export type PendingTransactionGetResponseRoute =
  PendingTransactionGetResponseRouteAnyOf | null;

export type PendingTransactionGetResponseCurrency =
  (typeof PendingTransactionGetResponseCurrency)[keyof typeof PendingTransactionGetResponseCurrency];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PendingTransactionGetResponseCurrency = {
  CAD: "CAD",
  CHF: "CHF",
  EUR: "EUR",
  GBP: "GBP",
  JPY: "JPY",
  USD: "USD",
} as const;

export type PendingTransactionGetResponseCompletedAt = string | null;

export interface PendingTransactionGetResponse {
  account_id: string;
  amount: number;
  completed_at: PendingTransactionGetResponseCompletedAt;
  created_at: string;
  currency: PendingTransactionGetResponseCurrency;
  date: string;
  description: string;
  group_id: string;
  id: string;
  route: PendingTransactionGetResponseRoute;
  source: unknown;
  source_documentation: PendingTransactionGetResponseSourceDocumentation;
  source_id: string;
  source_type: string;
  status: PendingTransactionGetResponseStatus;
  type: string;
}

export type PassportMachineReadableZoneExtractionPostResponseParsedPassportDataAnyOf =
  {
    date_of_birth: string;
    expiration_date: string;
    given_names: string[];
    issuing_country: string;
    passport_number: string;
    surname: string;
  };

export type PassportMachineReadableZoneExtractionPostResponseParsedPassportData =
  PassportMachineReadableZoneExtractionPostResponseParsedPassportDataAnyOf | null;

export interface PassportMachineReadableZoneExtractionPostResponse {
  parsed_passport_data: PassportMachineReadableZoneExtractionPostResponseParsedPassportData;
}

export interface PassportMachineReadableZoneExtractionPostParameters {
  entity_setup_identity_document_submission_id: string;
}

export type PartnerInvitationPostResponseRole =
  (typeof PartnerInvitationPostResponseRole)[keyof typeof PartnerInvitationPostResponseRole];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PartnerInvitationPostResponseRole = {
  internal: "internal",
  check_reviewer: "check_reviewer",
  transaction_monitor: "transaction_monitor",
  card_printer: "card_printer",
  partner_read_write: "partner_read_write",
  partner_read_only: "partner_read_only",
  partner_administrator: "partner_administrator",
  limit_approver: "limit_approver",
} as const;

export type PartnerInvitationPostResponseRedeemedAt = string | null;

export type PartnerInvitationPostResponseBank =
  | "blue_ridge_bank"
  | "core_bank"
  | "first_internet_bank"
  | "global_innovations_bank"
  | "grasshopper_bank"
  | null;

export interface PartnerInvitationPostResponse {
  bank: PartnerInvitationPostResponseBank;
  created_at: string;
  email: string;
  id: string;
  inviter_id: string;
  redeemed_at: PartnerInvitationPostResponseRedeemedAt;
  role: PartnerInvitationPostResponseRole;
  updated_at: string;
}

export type PartnerInvitationPostParametersRole =
  (typeof PartnerInvitationPostParametersRole)[keyof typeof PartnerInvitationPostParametersRole];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PartnerInvitationPostParametersRole = {
  internal: "internal",
  check_reviewer: "check_reviewer",
  transaction_monitor: "transaction_monitor",
  card_printer: "card_printer",
  partner_read_write: "partner_read_write",
  partner_read_only: "partner_read_only",
  partner_administrator: "partner_administrator",
  limit_approver: "limit_approver",
} as const;

export type PartnerInvitationPostParametersBank =
  (typeof PartnerInvitationPostParametersBank)[keyof typeof PartnerInvitationPostParametersBank];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PartnerInvitationPostParametersBank = {
  blue_ridge_bank: "blue_ridge_bank",
  core_bank: "core_bank",
  first_internet_bank: "first_internet_bank",
  global_innovations_bank: "global_innovations_bank",
  grasshopper_bank: "grasshopper_bank",
} as const;

export interface PartnerInvitationPostParameters {
  bank: PartnerInvitationPostParametersBank;
  email: string;
  role: PartnerInvitationPostParametersRole;
}

export type PartnerInvitationListResponseNextCursor = string | null;

export interface PartnerInvitationListResponse {
  data: PartnerInvitationListResponseDataItem[];
  next_cursor: PartnerInvitationListResponseNextCursor;
}

export type PartnerInvitationListResponseDataItemRole =
  (typeof PartnerInvitationListResponseDataItemRole)[keyof typeof PartnerInvitationListResponseDataItemRole];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PartnerInvitationListResponseDataItemRole = {
  internal: "internal",
  check_reviewer: "check_reviewer",
  transaction_monitor: "transaction_monitor",
  card_printer: "card_printer",
  partner_read_write: "partner_read_write",
  partner_read_only: "partner_read_only",
  partner_administrator: "partner_administrator",
  limit_approver: "limit_approver",
} as const;

export type PartnerInvitationListResponseDataItemRedeemedAt = string | null;

export type PartnerInvitationListResponseDataItemBank =
  | "blue_ridge_bank"
  | "core_bank"
  | "first_internet_bank"
  | "global_innovations_bank"
  | "grasshopper_bank"
  | null;

export type PartnerInvitationListResponseDataItem = {
  bank: PartnerInvitationListResponseDataItemBank;
  created_at: string;
  email: string;
  id: string;
  inviter_id: string;
  redeemed_at: PartnerInvitationListResponseDataItemRedeemedAt;
  role: PartnerInvitationListResponseDataItemRole;
  updated_at: string;
};

export type PartnerInvitationDeleteResponseRole =
  (typeof PartnerInvitationDeleteResponseRole)[keyof typeof PartnerInvitationDeleteResponseRole];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PartnerInvitationDeleteResponseRole = {
  internal: "internal",
  check_reviewer: "check_reviewer",
  transaction_monitor: "transaction_monitor",
  card_printer: "card_printer",
  partner_read_write: "partner_read_write",
  partner_read_only: "partner_read_only",
  partner_administrator: "partner_administrator",
  limit_approver: "limit_approver",
} as const;

export type PartnerInvitationDeleteResponseRedeemedAt = string | null;

export type PartnerInvitationDeleteResponseBank =
  | "blue_ridge_bank"
  | "core_bank"
  | "first_internet_bank"
  | "global_innovations_bank"
  | "grasshopper_bank"
  | null;

export interface PartnerInvitationDeleteResponse {
  bank: PartnerInvitationDeleteResponseBank;
  created_at: string;
  email: string;
  id: string;
  inviter_id: string;
  redeemed_at: PartnerInvitationDeleteResponseRedeemedAt;
  role: PartnerInvitationDeleteResponseRole;
  updated_at: string;
}

export type OutboundFileViewDirectiveGetResponse = Blob;

export type OutboundACHFilesOverviewGetResponsePendingCanaryTransfersItemAchTransferSubmittedFileId =
  string | null;

export type OutboundACHFilesOverviewGetResponsePendingCanaryTransfersItemAchTransferSubmittedAt =
  string | null;

export type OutboundACHFilesOverviewGetResponsePendingCanaryTransfersItemAchTransfer =
  {
    id: string;
    status: string;
    submitted_at: OutboundACHFilesOverviewGetResponsePendingCanaryTransfersItemAchTransferSubmittedAt;
    submitted_file_id: OutboundACHFilesOverviewGetResponsePendingCanaryTransfersItemAchTransferSubmittedFileId;
  };

export type OutboundACHFilesOverviewGetResponsePendingCanaryTransfersItem = {
  ach_transfer: OutboundACHFilesOverviewGetResponsePendingCanaryTransfersItemAchTransfer;
  alert_status: string;
  bank: string;
  created_at: string;
  expected_complete_by: string;
  id: string;
};

export type OutboundACHFilesOverviewGetResponseOutboundAchFilesItemTotalDebitEntryAmount =
  number | null;

export type OutboundACHFilesOverviewGetResponseOutboundAchFilesItemTotalCreditEntryAmount =
  number | null;

export type OutboundACHFilesOverviewGetResponseOutboundAchFilesItemOutboundFileStatus =
  (typeof OutboundACHFilesOverviewGetResponseOutboundAchFilesItemOutboundFileStatus)[keyof typeof OutboundACHFilesOverviewGetResponseOutboundAchFilesItemOutboundFileStatus];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const OutboundACHFilesOverviewGetResponseOutboundAchFilesItemOutboundFileStatus =
  {
    abandoned: "abandoned",
    complete: "complete",
    pending_sending: "pending_sending",
    pending_storing: "pending_storing",
    sending: "sending",
    storing: "storing",
    requires_attention: "requires_attention",
  } as const;

export type OutboundACHFilesOverviewGetResponseOutboundAchFilesItemFileType =
  (typeof OutboundACHFilesOverviewGetResponseOutboundAchFilesItemFileType)[keyof typeof OutboundACHFilesOverviewGetResponseOutboundAchFilesItemFileType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const OutboundACHFilesOverviewGetResponseOutboundAchFilesItemFileType = {
  notification_of_change: "notification_of_change",
  origination: "origination",
  return: "return",
  prenotification: "prenotification",
} as const;

export type OutboundACHFilesOverviewGetResponseOutboundAchFilesItemBatchCount =
  | number
  | null;

export type OutboundACHFilesOverviewGetResponseOutboundAchFilesItemBank =
  | "blue_ridge_bank"
  | "core_bank"
  | "first_internet_bank"
  | "global_innovations_bank"
  | "grasshopper_bank"
  | null;

export type OutboundACHFilesOverviewGetResponseOutboundAchFilesItem = {
  acknowledgements: OutboundACHFilesOverviewGetResponseOutboundAchFilesItemAcknowledgementsItem[];
  bank: OutboundACHFilesOverviewGetResponseOutboundAchFilesItemBank;
  batch_count: OutboundACHFilesOverviewGetResponseOutboundAchFilesItemBatchCount;
  created_at: string;
  file_type: OutboundACHFilesOverviewGetResponseOutboundAchFilesItemFileType;
  id: string;
  outbound_file_id: string;
  outbound_file_status: OutboundACHFilesOverviewGetResponseOutboundAchFilesItemOutboundFileStatus;
  status: string;
  total_credit_entry_amount: OutboundACHFilesOverviewGetResponseOutboundAchFilesItemTotalCreditEntryAmount;
  total_debit_entry_amount: OutboundACHFilesOverviewGetResponseOutboundAchFilesItemTotalDebitEntryAmount;
  transmission_name: string;
};

export interface OutboundACHFilesOverviewGetResponse {
  outbound_ach_files: OutboundACHFilesOverviewGetResponseOutboundAchFilesItem[];
  pending_canary_transfers: OutboundACHFilesOverviewGetResponsePendingCanaryTransfersItem[];
}

export type OutboundACHFilesOverviewGetResponseOutboundAchFilesItemAcknowledgementsItemFileStatus =
  string | null;

export type OutboundACHFilesOverviewGetResponseOutboundAchFilesItemAcknowledgementsItemErrorMessage =
  string | null;

export type OutboundACHFilesOverviewGetResponseOutboundAchFilesItemAcknowledgementsItem =
  {
    created_at: string;
    error_message: OutboundACHFilesOverviewGetResponseOutboundAchFilesItemAcknowledgementsItemErrorMessage;
    file_process_datetime: string;
    file_status: OutboundACHFilesOverviewGetResponseOutboundAchFilesItemAcknowledgementsItemFileStatus;
    id: string;
  };

export type OperatorTagPostResponseValue = string | null;

export interface OperatorTagPostResponse {
  id: string;
  key: string;
  value: OperatorTagPostResponseValue;
}

export interface OperatorTagPostParameters {
  key: string;
  model_id: string;
  value?: string;
}

export type OperatorTagListResponseNextCursor = string | null;

export type OperatorTagListResponseDataItemValue = string | null;

export type OperatorTagListResponseDataItem = {
  id: string;
  key: string;
  value: OperatorTagListResponseDataItemValue;
};

export interface OperatorTagListResponse {
  data: OperatorTagListResponseDataItem[];
  next_cursor: OperatorTagListResponseNextCursor;
}

export interface OperatorOneTimePasswordProvisioningURIGetResponse {
  one_time_password_provisioning_uri: string;
}

export type OperatorNotePostResponseOperatorBank =
  | "blue_ridge_bank"
  | "core_bank"
  | "first_internet_bank"
  | "global_innovations_bank"
  | "grasshopper_bank"
  | null;

export interface OperatorNotePostResponse {
  date: string;
  id: string;
  operator_bank: OperatorNotePostResponseOperatorBank;
  operator_email: string;
  text: string;
}

export interface OperatorNotePostParameters {
  model_id: string;
  text: string;
}

export type OperatorNoteListResponseNextCursor = string | null;

export interface OperatorNoteListResponse {
  data: OperatorNoteListResponseDataItem[];
  next_cursor: OperatorNoteListResponseNextCursor;
}

export type OperatorNoteListResponseDataItemOperatorBank =
  | "blue_ridge_bank"
  | "core_bank"
  | "first_internet_bank"
  | "global_innovations_bank"
  | "grasshopper_bank"
  | null;

export type OperatorNoteListResponseDataItem = {
  date: string;
  id: string;
  operator_bank: OperatorNoteListResponseDataItemOperatorBank;
  operator_email: string;
  text: string;
};

export type OperatorManualQueueItemPatchResponseStatus =
  (typeof OperatorManualQueueItemPatchResponseStatus)[keyof typeof OperatorManualQueueItemPatchResponseStatus];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const OperatorManualQueueItemPatchResponseStatus = {
  pending_actioning: "pending_actioning",
  actioned: "actioned",
  requires_attention: "requires_attention",
} as const;

export type OperatorManualQueueItemPatchResponseQueue =
  (typeof OperatorManualQueueItemPatchResponseQueue)[keyof typeof OperatorManualQueueItemPatchResponseQueue];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const OperatorManualQueueItemPatchResponseQueue = {
  transaction_review: "transaction_review",
  group_review: "group_review",
  inbound_check_item_allocation: "inbound_check_item_allocation",
  check_transfer_payee_name: "check_transfer_payee_name",
} as const;

export type OperatorManualQueueItemPatchResponseLink = string | null;

export type OperatorManualQueueItemPatchResponseCause =
  | "flagged_by_operator"
  | "ach_return_always_monitor"
  | "wire_office_of_foreign_asset_control_hit"
  | "too_many_irs_transactions"
  | "too_many_paypal_verification_transactions"
  | "potential_platform_activity"
  | "known_bad_list_match"
  | null;

export interface OperatorManualQueueItemPatchResponse {
  cause: OperatorManualQueueItemPatchResponseCause;
  created_at: string;
  description: string;
  id: string;
  link: OperatorManualQueueItemPatchResponseLink;
  queue: OperatorManualQueueItemPatchResponseQueue;
  record_class: string;
  record_id: string;
  status: OperatorManualQueueItemPatchResponseStatus;
}

export type OperatorManualQueueItemPatchParametersStatus =
  (typeof OperatorManualQueueItemPatchParametersStatus)[keyof typeof OperatorManualQueueItemPatchParametersStatus];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const OperatorManualQueueItemPatchParametersStatus = {
  pending_actioning: "pending_actioning",
  actioned: "actioned",
  requires_attention: "requires_attention",
} as const;

export interface OperatorManualQueueItemPatchParameters {
  status: OperatorManualQueueItemPatchParametersStatus;
}

export type OperatorManualQueueItemListResponseNextCursor = string | null;

export interface OperatorManualQueueItemListResponse {
  data: OperatorManualQueueItemListResponseDataItem[];
  next_cursor: OperatorManualQueueItemListResponseNextCursor;
}

export type OperatorManualQueueItemListResponseDataItemStatus =
  (typeof OperatorManualQueueItemListResponseDataItemStatus)[keyof typeof OperatorManualQueueItemListResponseDataItemStatus];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const OperatorManualQueueItemListResponseDataItemStatus = {
  pending_actioning: "pending_actioning",
  actioned: "actioned",
  requires_attention: "requires_attention",
} as const;

export type OperatorManualQueueItemListResponseDataItemQueue =
  (typeof OperatorManualQueueItemListResponseDataItemQueue)[keyof typeof OperatorManualQueueItemListResponseDataItemQueue];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const OperatorManualQueueItemListResponseDataItemQueue = {
  transaction_review: "transaction_review",
  group_review: "group_review",
  inbound_check_item_allocation: "inbound_check_item_allocation",
  check_transfer_payee_name: "check_transfer_payee_name",
} as const;

export type OperatorManualQueueItemListResponseDataItemLink = string | null;

export type OperatorManualQueueItemListResponseDataItemCause =
  | "flagged_by_operator"
  | "ach_return_always_monitor"
  | "wire_office_of_foreign_asset_control_hit"
  | "too_many_irs_transactions"
  | "too_many_paypal_verification_transactions"
  | "potential_platform_activity"
  | "known_bad_list_match"
  | null;

export type OperatorManualQueueItemListResponseDataItem = {
  cause: OperatorManualQueueItemListResponseDataItemCause;
  created_at: string;
  description: string;
  id: string;
  link: OperatorManualQueueItemListResponseDataItemLink;
  queue: OperatorManualQueueItemListResponseDataItemQueue;
  record_class: string;
  record_id: string;
  status: OperatorManualQueueItemListResponseDataItemStatus;
};

export type OperatorListResponseNextCursor = string | null;

export interface OperatorListResponse {
  data: OperatorListResponseDataItem[];
  next_cursor: OperatorListResponseNextCursor;
}

export type OperatorListResponseDataItemRole =
  (typeof OperatorListResponseDataItemRole)[keyof typeof OperatorListResponseDataItemRole];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const OperatorListResponseDataItemRole = {
  internal: "internal",
  check_reviewer: "check_reviewer",
  transaction_monitor: "transaction_monitor",
  card_printer: "card_printer",
  partner_read_write: "partner_read_write",
  partner_read_only: "partner_read_only",
  partner_administrator: "partner_administrator",
  limit_approver: "limit_approver",
} as const;

export type OperatorListResponseDataItemOneTimePasswordStatus =
  (typeof OperatorListResponseDataItemOneTimePasswordStatus)[keyof typeof OperatorListResponseDataItemOneTimePasswordStatus];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const OperatorListResponseDataItemOneTimePasswordStatus = {
  confirmed: "confirmed",
  pending_confirming: "pending_confirming",
} as const;

export type OperatorListResponseDataItemBank =
  | "blue_ridge_bank"
  | "core_bank"
  | "first_internet_bank"
  | "global_innovations_bank"
  | "grasshopper_bank"
  | null;

export type OperatorListResponseDataItem = {
  bank: OperatorListResponseDataItemBank;
  created_at: string;
  email: string;
  id: string;
  one_time_password_status: OperatorListResponseDataItemOneTimePasswordStatus;
  role: OperatorListResponseDataItemRole;
};

export type OperatorGetResponseEntitlementsItem =
  (typeof OperatorGetResponseEntitlementsItem)[keyof typeof OperatorGetResponseEntitlementsItem];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const OperatorGetResponseEntitlementsItem = {
  none_any_operator: "none_any_operator",
  self_read: "self_read",
  self_update: "self_update",
  unauthenticated: "unauthenticated",
  internal_service_call: "internal_service_call",
  api_file_read: "api_file_read",
  bookkeeping_read: "bookkeeping_read",
  card_printer_read: "card_printer_read",
  card_read: "card_read",
  check_deposit_read: "check_deposit_read",
  check_item_allocating: "check_item_allocating",
  check_review: "check_review",
  customer_identification_program_testing_entry_write:
    "customer_identification_program_testing_entry_write",
  document_request_read: "document_request_read",
  entity_cluster_manage: "entity_cluster_manage",
  historical_ach_volume_read: "historical_ach_volume_read",
  increase_compliance_documents_read: "increase_compliance_documents_read",
  identity_document_read: "identity_document_read",
  identity_document_queue_management: "identity_document_queue_management",
  internal_read_write: "internal_read_write",
  invitation_read: "invitation_read",
  late_return_request_read: "late_return_request_read",
  late_return_request_write: "late_return_request_write",
  office_of_foreign_asset_control_hit_read:
    "office_of_foreign_asset_control_hit_read",
  office_of_foreign_asset_control_queue_management:
    "office_of_foreign_asset_control_queue_management",
  operator_read: "operator_read",
  operator_note_write: "operator_note_write",
  operator_tag_read: "operator_tag_read",
  partner_group_read: "partner_group_read",
  partner_program_write: "partner_program_write",
  partner_program_limits_write: "partner_program_limits_write",
  partner_manage_members: "partner_manage_members",
  partner_overview_read: "partner_overview_read",
  role_list: "role_list",
  suspicious_activity_report_read: "suspicious_activity_report_read",
  suspicious_activity_report_write: "suspicious_activity_report_write",
  transaction_read: "transaction_read",
  unusual_activity_report_read: "unusual_activity_report_read",
  unusual_activity_report_write: "unusual_activity_report_write",
  user_impersonate: "user_impersonate",
  run_manual_task: "run_manual_task",
  list_transfers_by_trace_number_and_amount:
    "list_transfers_by_trace_number_and_amount",
  three_fourteen_a_manage: "three_fourteen_a_manage",
} as const;

export type OperatorGetResponseBankRoutingNumber =
  | "051402372"
  | "053112929"
  | "074014187"
  | "074920909"
  | "091218461"
  | "091218490"
  | "026015024"
  | "026015244"
  | "104001808"
  | "104902114"
  | null;

export type OperatorGetResponseBank =
  | "blue_ridge_bank"
  | "core_bank"
  | "first_internet_bank"
  | "global_innovations_bank"
  | "grasshopper_bank"
  | null;

export interface OperatorGetResponse {
  bank: OperatorGetResponseBank;
  bank_routing_number: OperatorGetResponseBankRoutingNumber;
  email: string;
  entitlements: OperatorGetResponseEntitlementsItem[];
  limit_approver: boolean;
  multibank: boolean;
  role_name: string;
}

export type OperatorFileViewDirectiveGetResponse = Blob;

export type OperatorFilePostResponsePurpose =
  | "audit"
  | "know_your_customer"
  | "export"
  | "other"
  | "unusual_activity_report_attachment"
  | null;

export type OperatorFilePostResponseDescription = string | null;

export interface OperatorFilePostResponse {
  created_at: string;
  description: OperatorFilePostResponseDescription;
  file_hash: string;
  id: string;
  model_id: string;
  model_type: string;
  operator_email: string;
  operator_id: string;
  purpose: OperatorFilePostResponsePurpose;
  transmission_name: string;
}

export type OperatorFilePostParametersPurpose =
  (typeof OperatorFilePostParametersPurpose)[keyof typeof OperatorFilePostParametersPurpose];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const OperatorFilePostParametersPurpose = {
  audit: "audit",
  know_your_customer: "know_your_customer",
  export: "export",
  other: "other",
  unusual_activity_report_attachment: "unusual_activity_report_attachment",
} as const;

export interface OperatorFilePostParameters {
  description?: string;
  file: Blob;
  model_id: string;
  purpose: OperatorFilePostParametersPurpose;
}

export type OperatorFileListResponseNextCursor = string | null;

export type OperatorFileListResponseDataItemPurpose =
  | "audit"
  | "know_your_customer"
  | "export"
  | "other"
  | "unusual_activity_report_attachment"
  | null;

export type OperatorFileListResponseDataItemDescription = string | null;

export type OperatorFileListResponseDataItem = {
  created_at: string;
  description: OperatorFileListResponseDataItemDescription;
  file_hash: string;
  id: string;
  model_id: string;
  model_type: string;
  operator_email: string;
  operator_id: string;
  purpose: OperatorFileListResponseDataItemPurpose;
  transmission_name: string;
};

export interface OperatorFileListResponse {
  data: OperatorFileListResponseDataItem[];
  next_cursor: OperatorFileListResponseNextCursor;
}

export type OperatorExportPostResponsePurpose =
  | "audit"
  | "know_your_customer"
  | "export"
  | "other"
  | "unusual_activity_report_attachment"
  | null;

export type OperatorExportPostResponseDescription = string | null;

export interface OperatorExportPostResponse {
  created_at: string;
  description: OperatorExportPostResponseDescription;
  file_hash: string;
  id: string;
  model_id: string;
  model_type: string;
  operator_email: string;
  operator_id: string;
  purpose: OperatorExportPostResponsePurpose;
  transmission_name: string;
}

export type OperatorExportPostParametersCategory =
  (typeof OperatorExportPostParametersCategory)[keyof typeof OperatorExportPostParametersCategory];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const OperatorExportPostParametersCategory = {
  platform_compliance_metrics_submissions:
    "platform_compliance_metrics_submissions",
  platform_complaint_list_submissions: "platform_complaint_list_submissions",
  platform_vendor_list_submissions: "platform_vendor_list_submissions",
} as const;

export interface OperatorExportPostParameters {
  category: OperatorExportPostParametersCategory;
  group_id: string;
}

export type OperatorDeleteResponseRole =
  (typeof OperatorDeleteResponseRole)[keyof typeof OperatorDeleteResponseRole];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const OperatorDeleteResponseRole = {
  internal: "internal",
  check_reviewer: "check_reviewer",
  transaction_monitor: "transaction_monitor",
  card_printer: "card_printer",
  partner_read_write: "partner_read_write",
  partner_read_only: "partner_read_only",
  partner_administrator: "partner_administrator",
  limit_approver: "limit_approver",
} as const;

export type OperatorDeleteResponseOneTimePasswordStatus =
  (typeof OperatorDeleteResponseOneTimePasswordStatus)[keyof typeof OperatorDeleteResponseOneTimePasswordStatus];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const OperatorDeleteResponseOneTimePasswordStatus = {
  confirmed: "confirmed",
  pending_confirming: "pending_confirming",
} as const;

export type OperatorDeleteResponseBank =
  | "blue_ridge_bank"
  | "core_bank"
  | "first_internet_bank"
  | "global_innovations_bank"
  | "grasshopper_bank"
  | null;

export interface OperatorDeleteResponse {
  bank: OperatorDeleteResponseBank;
  created_at: string;
  email: string;
  id: string;
  one_time_password_status: OperatorDeleteResponseOneTimePasswordStatus;
  role: OperatorDeleteResponseRole;
}

export type OperatorConfirmOneTimePasswordPostResponseOneTimePasswordStatus =
  (typeof OperatorConfirmOneTimePasswordPostResponseOneTimePasswordStatus)[keyof typeof OperatorConfirmOneTimePasswordPostResponseOneTimePasswordStatus];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const OperatorConfirmOneTimePasswordPostResponseOneTimePasswordStatus = {
  confirmed: "confirmed",
  pending_confirming: "pending_confirming",
} as const;

export interface OperatorConfirmOneTimePasswordPostResponse {
  one_time_password_status: OperatorConfirmOneTimePasswordPostResponseOneTimePasswordStatus;
}

export interface OperatorConfirmOneTimePasswordPostParameters {
  one_time_password: string;
}

export type ObligationDocumentListResponseDataDocumentsItem = {
  document_link: string;
  document_name: string;
  document_partner: string;
};

export type ObligationDocumentListResponseData = {
  documents: ObligationDocumentListResponseDataDocumentsItem[];
};

export interface ObligationDocumentListResponse {
  data: ObligationDocumentListResponseData;
}

export type ObligationDocumentGetResponseDataObligationsItem = {
  fulfillment_method_description: string;
  latest_result: boolean;
  obligation_description: string;
};

export type ObligationDocumentGetResponseDataFrequency =
  (typeof ObligationDocumentGetResponseDataFrequency)[keyof typeof ObligationDocumentGetResponseDataFrequency];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ObligationDocumentGetResponseDataFrequency = {
  annual: "annual",
  monthly: "monthly",
  biweekly: "biweekly",
} as const;

export type ObligationDocumentGetResponseDataDocumentPartner =
  (typeof ObligationDocumentGetResponseDataDocumentPartner)[keyof typeof ObligationDocumentGetResponseDataDocumentPartner];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ObligationDocumentGetResponseDataDocumentPartner = {
  american_institute_of_certified_public_accountants:
    "american_institute_of_certified_public_accountants",
  consumer_financial_protection_bureau: "consumer_financial_protection_bureau",
  federal_deposit_insurance_corporation:
    "federal_deposit_insurance_corporation",
  payment_card_industry: "payment_card_industry",
  united_states_house_of_representatives:
    "united_states_house_of_representatives",
} as const;

export type ObligationDocumentGetResponseDataAuditsItem = {
  audit_name: string;
  audit_report: string;
  date_completed: string;
};

export type ObligationDocumentGetResponseData = {
  audits: ObligationDocumentGetResponseDataAuditsItem[];
  document_link: string;
  document_name: string;
  document_partner: ObligationDocumentGetResponseDataDocumentPartner;
  frequency: ObligationDocumentGetResponseDataFrequency;
  obligations: ObligationDocumentGetResponseDataObligationsItem[];
};

export interface ObligationDocumentGetResponse {
  data: ObligationDocumentGetResponseData;
}

export type ObjectGetResponseMetaTransactionAccountingTraceId = string | null;

export type ObjectGetResponseMeta = {
  additional: unknown;
  class_name: string;
  has_many: unknown;
  operator_notable: boolean;
  transaction_accounting_trace_id: ObjectGetResponseMetaTransactionAccountingTraceId;
};

export interface ObjectGetResponse {
  data: unknown;
  meta: ObjectGetResponseMeta;
}

export type OAuthConnectionListResponseNextCursor = string | null;

export interface OAuthConnectionListResponse {
  data: OAuthConnectionListResponseDataItem[];
  next_cursor: OAuthConnectionListResponseNextCursor;
}

export type OAuthConnectionListResponseDataItemName = string | null;

export type OAuthConnectionListResponseDataItemDeletedAt = string | null;

export type OAuthConnectionListResponseDataItem = {
  application_owning_group_id: string;
  connected_group_id: string;
  created_at: string;
  deleted_at: OAuthConnectionListResponseDataItemDeletedAt;
  id: string;
  name: OAuthConnectionListResponseDataItemName;
  oauth_application_id: string;
};

export type OAuthApplicationListResponseNextCursor = string | null;

export type OAuthApplicationListResponseDataItemDeletedAt = string | null;

export type OAuthApplicationListResponseDataItem = {
  created_at: string;
  deleted_at: OAuthApplicationListResponseDataItemDeletedAt;
  group_id: string;
  id: string;
  name: string;
};

export interface OAuthApplicationListResponse {
  data: OAuthApplicationListResponseDataItem[];
  next_cursor: OAuthApplicationListResponseNextCursor;
}

export type OAuthApplicationGetResponseDeletedAt = string | null;

export interface OAuthApplicationGetResponse {
  created_at: string;
  deleted_at: OAuthApplicationGetResponseDeletedAt;
  group_id: string;
  id: string;
  name: string;
}

export type MicroBiltAdvancedPeopleSearchResultGetResponseTaxIdType =
  | "SSN"
  | null;

export type MicroBiltAdvancedPeopleSearchResultGetResponseTaxIdLast4 =
  | string
  | null;

export type MicroBiltAdvancedPeopleSearchResultGetResponseReturnCode =
  (typeof MicroBiltAdvancedPeopleSearchResultGetResponseReturnCode)[keyof typeof MicroBiltAdvancedPeopleSearchResultGetResponseReturnCode];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const MicroBiltAdvancedPeopleSearchResultGetResponseReturnCode = {
  OK: "OK",
} as const;

export type MicroBiltAdvancedPeopleSearchResultGetResponseMiddleName =
  | string
  | null;

export type MicroBiltAdvancedPeopleSearchResultGetResponseDeathDate =
  | string
  | null;

export type MicroBiltAdvancedPeopleSearchResultGetResponseBirthDate =
  | string
  | null;

export interface MicroBiltAdvancedPeopleSearchResultGetResponse {
  birth_date: MicroBiltAdvancedPeopleSearchResultGetResponseBirthDate;
  created_at: string;
  death_date: MicroBiltAdvancedPeopleSearchResultGetResponseDeathDate;
  first_name: string;
  id: string;
  last_name: string;
  middle_name: MicroBiltAdvancedPeopleSearchResultGetResponseMiddleName;
  request_id: string;
  response: string;
  return_code: MicroBiltAdvancedPeopleSearchResultGetResponseReturnCode;
  tax_id_last_4: MicroBiltAdvancedPeopleSearchResultGetResponseTaxIdLast4;
  tax_id_type: MicroBiltAdvancedPeopleSearchResultGetResponseTaxIdType;
}

export type ManualTaskRunPostResponseSucceededAt = string | null;

export type ManualTaskRunPostResponseStatus =
  (typeof ManualTaskRunPostResponseStatus)[keyof typeof ManualTaskRunPostResponseStatus];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ManualTaskRunPostResponseStatus = {
  pending: "pending",
  running: "running",
  succeeded: "succeeded",
  failed: "failed",
  abandoned: "abandoned",
  requires_attention: "requires_attention",
} as const;

export type ManualTaskRunPostResponseStartedAt = string | null;

export type ManualTaskRunPostResponseRedirectUrl = string | null;

export type ManualTaskRunPostResponseOutput = string | null;

export type ManualTaskRunPostResponseFailureMessage = string | null;

export type ManualTaskRunPostResponseFailedAt = string | null;

export interface ManualTaskRunPostResponse {
  arguments: unknown;
  failed_at: ManualTaskRunPostResponseFailedAt;
  failure_message: ManualTaskRunPostResponseFailureMessage;
  id: string;
  operator_email: string;
  output: ManualTaskRunPostResponseOutput;
  redirect_url: ManualTaskRunPostResponseRedirectUrl;
  started_at: ManualTaskRunPostResponseStartedAt;
  status: ManualTaskRunPostResponseStatus;
  succeeded_at: ManualTaskRunPostResponseSucceededAt;
  task_name: string;
}

export interface ManualTaskRunPostParameters {
  arguments: unknown;
  manual_task_class: string;
}

export type ManualTaskRunListResponseNextCursor = string | null;

export interface ManualTaskRunListResponse {
  data: ManualTaskRunListResponseDataItem[];
  next_cursor: ManualTaskRunListResponseNextCursor;
}

export type ManualTaskRunListResponseDataItemSucceededAt = string | null;

export type ManualTaskRunListResponseDataItemStatus =
  (typeof ManualTaskRunListResponseDataItemStatus)[keyof typeof ManualTaskRunListResponseDataItemStatus];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ManualTaskRunListResponseDataItemStatus = {
  pending: "pending",
  running: "running",
  succeeded: "succeeded",
  failed: "failed",
  abandoned: "abandoned",
  requires_attention: "requires_attention",
} as const;

export type ManualTaskRunListResponseDataItemStartedAt = string | null;

export type ManualTaskRunListResponseDataItemRedirectUrl = string | null;

export type ManualTaskRunListResponseDataItemOutput = string | null;

export type ManualTaskRunListResponseDataItemFailureMessage = string | null;

export type ManualTaskRunListResponseDataItemFailedAt = string | null;

export type ManualTaskRunListResponseDataItem = {
  arguments: unknown;
  failed_at: ManualTaskRunListResponseDataItemFailedAt;
  failure_message: ManualTaskRunListResponseDataItemFailureMessage;
  id: string;
  operator_email: string;
  output: ManualTaskRunListResponseDataItemOutput;
  redirect_url: ManualTaskRunListResponseDataItemRedirectUrl;
  started_at: ManualTaskRunListResponseDataItemStartedAt;
  status: ManualTaskRunListResponseDataItemStatus;
  succeeded_at: ManualTaskRunListResponseDataItemSucceededAt;
  task_name: string;
};

export type ManualTaskRunGetResponseSucceededAt = string | null;

export type ManualTaskRunGetResponseStatus =
  (typeof ManualTaskRunGetResponseStatus)[keyof typeof ManualTaskRunGetResponseStatus];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ManualTaskRunGetResponseStatus = {
  pending: "pending",
  running: "running",
  succeeded: "succeeded",
  failed: "failed",
  abandoned: "abandoned",
  requires_attention: "requires_attention",
} as const;

export type ManualTaskRunGetResponseStartedAt = string | null;

export type ManualTaskRunGetResponseRedirectUrl = string | null;

export type ManualTaskRunGetResponseOutput = string | null;

export type ManualTaskRunGetResponseFailureMessage = string | null;

export type ManualTaskRunGetResponseFailedAt = string | null;

export interface ManualTaskRunGetResponse {
  arguments: unknown;
  failed_at: ManualTaskRunGetResponseFailedAt;
  failure_message: ManualTaskRunGetResponseFailureMessage;
  id: string;
  operator_email: string;
  output: ManualTaskRunGetResponseOutput;
  redirect_url: ManualTaskRunGetResponseRedirectUrl;
  started_at: ManualTaskRunGetResponseStartedAt;
  status: ManualTaskRunGetResponseStatus;
  succeeded_at: ManualTaskRunGetResponseSucceededAt;
  task_name: string;
}

export type ManualTaskPreviewGetResponseSectionsAnyOfItemElements =
  | ManualTaskPreviewGetResponseSectionsAnyOfItemElementsAnyOfItem[]
  | null;

export type ManualTaskPreviewGetResponseSectionsAnyOfItem = {
  elements: ManualTaskPreviewGetResponseSectionsAnyOfItemElements;
  title: string;
};

export type ManualTaskPreviewGetResponseSections =
  | ManualTaskPreviewGetResponseSectionsAnyOfItem[]
  | null;

export interface ManualTaskPreviewGetResponse {
  sections: ManualTaskPreviewGetResponseSections;
}

export type ManualTaskPreviewGetResponseSectionsAnyOfItemElementsAnyOfItemLabel =
  string | null;

export type ManualTaskPreviewGetResponseSectionsAnyOfItemElementsAnyOfItemImageSrc =
  string | null;

export type ManualTaskPreviewGetResponseSectionsAnyOfItemElementsAnyOfItemHref =
  string | null;

export type ManualTaskPreviewGetResponseSectionsAnyOfItemElementsAnyOfItemContent =
  string | null;

export type ManualTaskPreviewGetResponseSectionsAnyOfItemElementsAnyOfItem = {
  content: ManualTaskPreviewGetResponseSectionsAnyOfItemElementsAnyOfItemContent;
  href: ManualTaskPreviewGetResponseSectionsAnyOfItemElementsAnyOfItemHref;
  image_src: ManualTaskPreviewGetResponseSectionsAnyOfItemElementsAnyOfItemImageSrc;
  label: ManualTaskPreviewGetResponseSectionsAnyOfItemElementsAnyOfItemLabel;
};

export type ManualTaskListResponseNextCursor = string | null;

export type ManualTaskListResponseDataItemDescription = string | null;

export type ManualTaskListResponseDataItem = {
  arguments: ManualTaskListResponseDataItemArgumentsItem[];
  description: ManualTaskListResponseDataItemDescription;
  github_link: string;
  name: string;
};

export interface ManualTaskListResponse {
  data: ManualTaskListResponseDataItem[];
  next_cursor: ManualTaskListResponseNextCursor;
}

export type ManualTaskListResponseDataItemArgumentsItemType =
  (typeof ManualTaskListResponseDataItemArgumentsItemType)[keyof typeof ManualTaskListResponseDataItemArgumentsItemType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ManualTaskListResponseDataItemArgumentsItemType = {
  "Operations::APISchema::UntypedParameter":
    "Operations::APISchema::UntypedParameter",
  "Operations::APISchema::TimestampParameter":
    "Operations::APISchema::TimestampParameter",
  "Operations::APISchema::StringParameter":
    "Operations::APISchema::StringParameter",
  "Operations::APISchema::ModelIDParameter":
    "Operations::APISchema::ModelIDParameter",
  "Operations::APISchema::ModelIDArrayParameter":
    "Operations::APISchema::ModelIDArrayParameter",
  "Operations::APISchema::LegacyCategoricalParameter":
    "Operations::APISchema::LegacyCategoricalParameter",
  "Operations::APISchema::IntegerParameter":
    "Operations::APISchema::IntegerParameter",
  "Operations::APISchema::FileUploadParameter":
    "Operations::APISchema::FileUploadParameter",
  "Operations::APISchema::EnumParameter":
    "Operations::APISchema::EnumParameter",
  "Operations::APISchema::EnumArrayParameter":
    "Operations::APISchema::EnumArrayParameter",
  "Operations::APISchema::DecimalParameter":
    "Operations::APISchema::DecimalParameter",
  "Operations::APISchema::DateParameter":
    "Operations::APISchema::DateParameter",
  "Operations::APISchema::CursorParameter":
    "Operations::APISchema::CursorParameter",
  "Operations::APISchema::ClassParameter":
    "Operations::APISchema::ClassParameter",
  "Operations::APISchema::BooleanParameter":
    "Operations::APISchema::BooleanParameter",
  "Operations::APISchema::Base64FileUploadParameter":
    "Operations::APISchema::Base64FileUploadParameter",
  "Operations::APISchema::ArrayParameter":
    "Operations::APISchema::ArrayParameter",
  "Operations::ManualTask::LargeStringParameter":
    "Operations::ManualTask::LargeStringParameter",
} as const;

export type ManualTaskListResponseDataItemArgumentsItemPlaceholder =
  | string
  | null;

export type ManualTaskListResponseDataItemArgumentsItemOptions =
  | string[]
  | null;

export type ManualTaskListResponseDataItemArgumentsItemModelClasses =
  | string[]
  | null;

export type ManualTaskListResponseDataItemArgumentsItemDocumentation =
  | string
  | null;

export type ManualTaskListResponseDataItemArgumentsItemDefault = string | null;

export type ManualTaskListResponseDataItemArgumentsItem = {
  default: ManualTaskListResponseDataItemArgumentsItemDefault;
  documentation: ManualTaskListResponseDataItemArgumentsItemDocumentation;
  model_classes: ManualTaskListResponseDataItemArgumentsItemModelClasses;
  name: string;
  optional: boolean;
  options: ManualTaskListResponseDataItemArgumentsItemOptions;
  placeholder: ManualTaskListResponseDataItemArgumentsItemPlaceholder;
  type: ManualTaskListResponseDataItemArgumentsItemType;
};

export interface ManualAssuranceGetResponse {
  created_at: string;
  document_name: string;
  id: string;
  obligation_description: string;
  operator_id: string;
  success: boolean;
}

export interface ManagementInformationSystemVolumesListResponse {
  data: ManagementInformationSystemVolumesListResponseData;
}

export type ManagementInformationSystemVolumesListResponseDataVolumesItem = {
  card_settlement_count: number;
  card_volume: number;
  lookback_total_volume: number;
  originated_ach_transaction_count: number;
  originated_ach_volume: number;
  originated_check_transaction_count: number;
  originated_check_volume: number;
  originated_rtp_transaction_count: number;
  originated_rtp_volume: number;
  originated_wire_transaction_count: number;
  originated_wire_volume: number;
  partner_name: string;
  program_id: string;
  total_volume: number;
};

export type ManagementInformationSystemVolumesListResponseDataTotals = {
  card_settlement_count: number;
  card_volume: number;
  lookback_volume: number;
  originated_ach_transaction_count: number;
  originated_ach_volume: number;
  originated_check_transaction_count: number;
  originated_check_volume: number;
  originated_rtp_transaction_count: number;
  originated_rtp_volume: number;
  originated_wire_transaction_count: number;
  originated_wire_volume: number;
  volume: number;
};

export type ManagementInformationSystemVolumesListResponseData = {
  totals: ManagementInformationSystemVolumesListResponseDataTotals;
  volumes: ManagementInformationSystemVolumesListResponseDataVolumesItem[];
};

export interface ManagementInformationSystemRiskMetricsListResponse {
  data: ManagementInformationSystemRiskMetricsListResponseData;
}

export type ManagementInformationSystemRiskMetricsListResponseDataTotals = {
  total_count_complaints: number;
  total_count_transaction_monitoring_alerts: number;
  total_previous_count_complaints: number;
  total_previous_count_transaction_monitoring_alerts: number;
};

export type ManagementInformationSystemRiskMetricsListResponseDataMetricsItem =
  {
    count_complaints: number;
    count_transaction_monitoring_alerts: number;
    partner_name: string;
    previous_count_complaints: number;
    previous_count_transaction_monitoring_alerts: number;
    program_id: string;
  };

export type ManagementInformationSystemRiskMetricsListResponseData = {
  metrics: ManagementInformationSystemRiskMetricsListResponseDataMetricsItem[];
  totals: ManagementInformationSystemRiskMetricsListResponseDataTotals;
};

export type ManagementInformationSystemRevenuesListResponseDataTotals = {
  previous_revenue: number;
  revenue: number;
};

export type ManagementInformationSystemRevenuesListResponseDataRevenuesItem = {
  partner_name: string;
  previous_revenue: number;
  program_id: string;
  revenue: number;
};

export type ManagementInformationSystemRevenuesListResponseData = {
  revenues: ManagementInformationSystemRevenuesListResponseDataRevenuesItem[];
  totals: ManagementInformationSystemRevenuesListResponseDataTotals;
};

export interface ManagementInformationSystemRevenuesListResponse {
  data: ManagementInformationSystemRevenuesListResponseData;
}

export type ManagementInformationSystemProgramCountsListResponseData = {
  lookback_number_of_programs: number;
  number_programs: number;
};

export interface ManagementInformationSystemProgramCountsListResponse {
  data: ManagementInformationSystemProgramCountsListResponseData;
}

export type ManagementInformationSystemBalancesListResponseDataTotals = {
  average_usd_balance: number;
  lookback_usd_balance: number;
  usd_balance: number;
};

export type ManagementInformationSystemBalancesListResponseDataBalancesItem = {
  average_usd_balance: number;
  lookback_usd_balance: number;
  partner_name: string;
  program_id: string;
  usd_balance: number;
};

export type ManagementInformationSystemBalancesListResponseData = {
  balances: ManagementInformationSystemBalancesListResponseDataBalancesItem[];
  totals: ManagementInformationSystemBalancesListResponseDataTotals;
};

export interface ManagementInformationSystemBalancesListResponse {
  data: ManagementInformationSystemBalancesListResponseData;
}

export interface ManagementInformationSystemAccountsListResponse {
  data: ManagementInformationSystemAccountsListResponseData;
}

export type ManagementInformationSystemAccountsListResponseDataTotals = {
  count_accounts: number;
  count_open_accounts: number;
};

export type ManagementInformationSystemAccountsListResponseDataAccountSummariesItem =
  {
    count_accounts: number;
    count_open_accounts: number;
    partner_name: string;
    program_id: string;
  };

export type ManagementInformationSystemAccountsListResponseData = {
  account_summaries: ManagementInformationSystemAccountsListResponseDataAccountSummariesItem[];
  totals: ManagementInformationSystemAccountsListResponseDataTotals;
};

export type MagneticInkCharacterExtractionPostResponseRawAnnotationsItemBoundingPolygonItem =
  {
    x: number;
    y: number;
  };

export type MagneticInkCharacterExtractionPostResponseRawAnnotationsItem = {
  bounding_polygon: MagneticInkCharacterExtractionPostResponseRawAnnotationsItemBoundingPolygonItem[];
  text: string;
};

export type MagneticInkCharacterExtractionPostResponseMagneticInkCharacterLine =
  MagneticInkCharacterExtractionPostResponseMagneticInkCharacterLineAnyOf | null;

export interface MagneticInkCharacterExtractionPostResponse {
  error: MagneticInkCharacterExtractionPostResponseError;
  magnetic_ink_character_line: MagneticInkCharacterExtractionPostResponseMagneticInkCharacterLine;
  raw_annotations: MagneticInkCharacterExtractionPostResponseRawAnnotationsItem[];
}

export type MagneticInkCharacterExtractionPostResponseMagneticInkCharacterLineAnyOfSerialNumberAnyOfBoundingPolygonItem =
  {
    x: number;
    y: number;
  };

export type MagneticInkCharacterExtractionPostResponseMagneticInkCharacterLineAnyOfSerialNumberAnyOf =
  {
    bounding_polygon: MagneticInkCharacterExtractionPostResponseMagneticInkCharacterLineAnyOfSerialNumberAnyOfBoundingPolygonItem[];
    text: string;
  };

export type MagneticInkCharacterExtractionPostResponseMagneticInkCharacterLineAnyOfSerialNumber =
  MagneticInkCharacterExtractionPostResponseMagneticInkCharacterLineAnyOfSerialNumberAnyOf | null;

export type MagneticInkCharacterExtractionPostResponseMagneticInkCharacterLineAnyOfRoutingNumber =
  {
    bounding_polygon: MagneticInkCharacterExtractionPostResponseMagneticInkCharacterLineAnyOfRoutingNumberBoundingPolygonItem[];
    text: string;
  };

export type MagneticInkCharacterExtractionPostResponseMagneticInkCharacterLineAnyOf =
  {
    account_number: MagneticInkCharacterExtractionPostResponseMagneticInkCharacterLineAnyOfAccountNumber;
    auxiliary_on_us: MagneticInkCharacterExtractionPostResponseMagneticInkCharacterLineAnyOfAuxiliaryOnUs;
    routing_number: MagneticInkCharacterExtractionPostResponseMagneticInkCharacterLineAnyOfRoutingNumber;
    serial_number: MagneticInkCharacterExtractionPostResponseMagneticInkCharacterLineAnyOfSerialNumber;
  };

export type MagneticInkCharacterExtractionPostResponseMagneticInkCharacterLineAnyOfRoutingNumberBoundingPolygonItem =
  {
    x: number;
    y: number;
  };

export type MagneticInkCharacterExtractionPostResponseMagneticInkCharacterLineAnyOfAuxiliaryOnUsAnyOfBoundingPolygonItem =
  {
    x: number;
    y: number;
  };

export type MagneticInkCharacterExtractionPostResponseMagneticInkCharacterLineAnyOfAuxiliaryOnUsAnyOf =
  {
    bounding_polygon: MagneticInkCharacterExtractionPostResponseMagneticInkCharacterLineAnyOfAuxiliaryOnUsAnyOfBoundingPolygonItem[];
    text: string;
  };

export type MagneticInkCharacterExtractionPostResponseMagneticInkCharacterLineAnyOfAuxiliaryOnUs =
  MagneticInkCharacterExtractionPostResponseMagneticInkCharacterLineAnyOfAuxiliaryOnUsAnyOf | null;

export type MagneticInkCharacterExtractionPostResponseMagneticInkCharacterLineAnyOfAccountNumberBoundingPolygonItem =
  {
    x: number;
    y: number;
  };

export type MagneticInkCharacterExtractionPostResponseMagneticInkCharacterLineAnyOfAccountNumber =
  {
    bounding_polygon: MagneticInkCharacterExtractionPostResponseMagneticInkCharacterLineAnyOfAccountNumberBoundingPolygonItem[];
    text: string;
  };

export type MagneticInkCharacterExtractionPostResponseError = string | null;

export interface MagneticInkCharacterExtractionPostParameters {
  file: Blob;
  record_id: string;
}

export type LockboxListResponseNextCursor = string | null;

export interface LockboxListResponse {
  data: LockboxListResponseDataItem[];
  next_cursor: LockboxListResponseNextCursor;
}

export type LockboxListResponseDataItemStatus =
  (typeof LockboxListResponseDataItemStatus)[keyof typeof LockboxListResponseDataItemStatus];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const LockboxListResponseDataItemStatus = {
  active: "active",
  inactive: "inactive",
} as const;

export type LockboxListResponseDataItemDescription = string | null;

export type LockboxListResponseDataItem = {
  account_id: string;
  created_at: string;
  description: LockboxListResponseDataItemDescription;
  group_id: string;
  id: string;
  status: LockboxListResponseDataItemStatus;
};

export type LockboxGetResponseStatus =
  (typeof LockboxGetResponseStatus)[keyof typeof LockboxGetResponseStatus];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const LockboxGetResponseStatus = {
  active: "active",
  inactive: "inactive",
} as const;

export type LockboxGetResponseDescription = string | null;

export interface LockboxGetResponse {
  account_id: string;
  created_at: string;
  description: LockboxGetResponseDescription;
  group_id: string;
  id: string;
  status: LockboxGetResponseStatus;
}

export type LobWebhookPostResponse = string;

export interface LateReturnRequestPostResponse {
  ach_transfers: LateReturnRequestPostResponseAchTransfersItem[];
  claim_number: LateReturnRequestPostResponseClaimNumber;
  contact_email: LateReturnRequestPostResponseContactEmail;
  contact_fax: LateReturnRequestPostResponseContactFax;
  contact_name: LateReturnRequestPostResponseContactName;
  created_at: string;
  document_request: LateReturnRequestPostResponseDocumentRequest;
  due_on: string;
  group_id: string;
  id: string;
  submissions: LateReturnRequestPostResponseSubmissionsItem[];
}

export type LateReturnRequestPostResponseSubmissionsItemStatus =
  (typeof LateReturnRequestPostResponseSubmissionsItemStatus)[keyof typeof LateReturnRequestPostResponseSubmissionsItemStatus];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const LateReturnRequestPostResponseSubmissionsItemStatus = {
  pending_review: "pending_review",
  rejected: "rejected",
  canceled: "canceled",
  pending_sending: "pending_sending",
  sent: "sent",
} as const;

export type LateReturnRequestPostResponseSubmissionsItemAuthorizerName =
  | string
  | null;

export type LateReturnRequestPostResponseSubmissionsItemAuthorizerIpAddress =
  | string
  | null;

export type LateReturnRequestPostResponseSubmissionsItemAuthorizerEmail =
  | string
  | null;

export type LateReturnRequestPostResponseSubmissionsItemAuthorizerCompany =
  | string
  | null;

export type LateReturnRequestPostResponseSubmissionsItemAdditionalEvidenceFileId =
  string | null;

export type LateReturnRequestPostResponseSubmissionsItem = {
  additional_evidence_file_id: LateReturnRequestPostResponseSubmissionsItemAdditionalEvidenceFileId;
  authorization_terms: string;
  authorized_at: string;
  authorizer_company: LateReturnRequestPostResponseSubmissionsItemAuthorizerCompany;
  authorizer_email: LateReturnRequestPostResponseSubmissionsItemAuthorizerEmail;
  authorizer_ip_address: LateReturnRequestPostResponseSubmissionsItemAuthorizerIpAddress;
  authorizer_name: LateReturnRequestPostResponseSubmissionsItemAuthorizerName;
  created_at: string;
  id: string;
  status: LateReturnRequestPostResponseSubmissionsItemStatus;
  updated_at: string;
};

export type LateReturnRequestPostResponseDocumentRequestStatus =
  (typeof LateReturnRequestPostResponseDocumentRequestStatus)[keyof typeof LateReturnRequestPostResponseDocumentRequestStatus];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const LateReturnRequestPostResponseDocumentRequestStatus = {
  awaiting_submission: "awaiting_submission",
  awaiting_review: "awaiting_review",
  provisionally_accepted: "provisionally_accepted",
  accepted: "accepted",
  canceled: "canceled",
} as const;

export type LateReturnRequestPostResponseDocumentRequestReviewsItemReviewerEmail =
  string | null;

export type LateReturnRequestPostResponseDocumentRequestReviewsItemResult =
  (typeof LateReturnRequestPostResponseDocumentRequestReviewsItemResult)[keyof typeof LateReturnRequestPostResponseDocumentRequestReviewsItemResult];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const LateReturnRequestPostResponseDocumentRequestReviewsItemResult = {
  accepted: "accepted",
  rejected: "rejected",
  provisionally_accepted: "provisionally_accepted",
} as const;

export type LateReturnRequestPostResponseDocumentRequestReviewsItemComment =
  | string
  | null;

export type LateReturnRequestPostResponseDocumentRequestReviewsItem = {
  comment: LateReturnRequestPostResponseDocumentRequestReviewsItemComment;
  created_at: string;
  id: string;
  result: LateReturnRequestPostResponseDocumentRequestReviewsItemResult;
  reviewer_email: LateReturnRequestPostResponseDocumentRequestReviewsItemReviewerEmail;
};

export type LateReturnRequestPostResponseDocumentRequestNondocumentSubmissionsProofOfAuthorizationRequestIds =
  string[] | null;

export type LateReturnRequestPostResponseDocumentRequestNondocumentSubmissionsPlatformVendorListSubmissionId =
  string | null;

export type LateReturnRequestPostResponseDocumentRequestNondocumentSubmissionsPlatformFinancialReportSubmissionId =
  string | null;

export type LateReturnRequestPostResponseDocumentRequestNondocumentSubmissionsPlatformComplianceMetricsSubmissionId =
  string | null;

export type LateReturnRequestPostResponseDocumentRequestNondocumentSubmissionsPlatformComplaintListSubmissionId =
  string | null;

export type LateReturnRequestPostResponseDocumentRequestNondocumentSubmissionsDirectUserDiligenceSubmissionId =
  string | null;

export type LateReturnRequestPostResponseDocumentRequestNondocumentSubmissions =
  {
    direct_user_diligence_submission_id: LateReturnRequestPostResponseDocumentRequestNondocumentSubmissionsDirectUserDiligenceSubmissionId;
    platform_complaint_list_submission_id: LateReturnRequestPostResponseDocumentRequestNondocumentSubmissionsPlatformComplaintListSubmissionId;
    platform_compliance_metrics_submission_id: LateReturnRequestPostResponseDocumentRequestNondocumentSubmissionsPlatformComplianceMetricsSubmissionId;
    platform_financial_report_submission_id: LateReturnRequestPostResponseDocumentRequestNondocumentSubmissionsPlatformFinancialReportSubmissionId;
    platform_vendor_list_submission_id: LateReturnRequestPostResponseDocumentRequestNondocumentSubmissionsPlatformVendorListSubmissionId;
    proof_of_authorization_request_ids: LateReturnRequestPostResponseDocumentRequestNondocumentSubmissionsProofOfAuthorizationRequestIds;
  };

export type LateReturnRequestPostResponseDocumentRequestGroupName =
  | string
  | null;

export type LateReturnRequestPostResponseDocumentRequestDocumentSubmissionsItemCreatedByEmail =
  string | null;

export type LateReturnRequestPostResponseDocumentRequestDocumentSubmissionsItem =
  {
    created_at: string;
    created_by_email: LateReturnRequestPostResponseDocumentRequestDocumentSubmissionsItemCreatedByEmail;
    file_id: string;
    file_name: string;
    id: string;
  };

export type LateReturnRequestPostResponseDocumentRequest = {
  created_at: string;
  description: string;
  document_submissions: LateReturnRequestPostResponseDocumentRequestDocumentSubmissionsItem[];
  group_id: string;
  group_name: LateReturnRequestPostResponseDocumentRequestGroupName;
  id: string;
  nondocument_submissions: LateReturnRequestPostResponseDocumentRequestNondocumentSubmissions;
  program_id: string;
  program_name: string;
  reviews: LateReturnRequestPostResponseDocumentRequestReviewsItem[];
  source: unknown;
  status: LateReturnRequestPostResponseDocumentRequestStatus;
};

export type LateReturnRequestPostResponseContactName = string | null;

export type LateReturnRequestPostResponseContactFax = string | null;

export type LateReturnRequestPostResponseContactEmail = string | null;

export type LateReturnRequestPostResponseClaimNumber = string | null;

export type LateReturnRequestPostResponseAchTransfersItem = {
  amount: number;
  currency: string;
  description: string;
  id: string;
};

export interface LateReturnRequestPostParameters {
  ach_transfer_ids: string[];
  claim_number?: string;
  contact_email?: string;
  contact_fax?: string;
  contact_name?: string;
  due_on: string;
  file: Blob;
  file_1?: Blob;
  file_2?: Blob;
}

export type LateReturnRequestListResponseNextCursor = string | null;

export interface LateReturnRequestListResponse {
  data: LateReturnRequestListResponseDataItem[];
  next_cursor: LateReturnRequestListResponseNextCursor;
}

export type LateReturnRequestListResponseDataItemSubmissionsItemStatus =
  (typeof LateReturnRequestListResponseDataItemSubmissionsItemStatus)[keyof typeof LateReturnRequestListResponseDataItemSubmissionsItemStatus];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const LateReturnRequestListResponseDataItemSubmissionsItemStatus = {
  pending_review: "pending_review",
  rejected: "rejected",
  canceled: "canceled",
  pending_sending: "pending_sending",
  sent: "sent",
} as const;

export type LateReturnRequestListResponseDataItemSubmissionsItemAuthorizerName =
  string | null;

export type LateReturnRequestListResponseDataItemSubmissionsItemAuthorizerIpAddress =
  string | null;

export type LateReturnRequestListResponseDataItemSubmissionsItemAuthorizerEmail =
  string | null;

export type LateReturnRequestListResponseDataItemSubmissionsItemAuthorizerCompany =
  string | null;

export type LateReturnRequestListResponseDataItemSubmissionsItemAdditionalEvidenceFileId =
  string | null;

export type LateReturnRequestListResponseDataItemSubmissionsItem = {
  additional_evidence_file_id: LateReturnRequestListResponseDataItemSubmissionsItemAdditionalEvidenceFileId;
  authorization_terms: string;
  authorized_at: string;
  authorizer_company: LateReturnRequestListResponseDataItemSubmissionsItemAuthorizerCompany;
  authorizer_email: LateReturnRequestListResponseDataItemSubmissionsItemAuthorizerEmail;
  authorizer_ip_address: LateReturnRequestListResponseDataItemSubmissionsItemAuthorizerIpAddress;
  authorizer_name: LateReturnRequestListResponseDataItemSubmissionsItemAuthorizerName;
  created_at: string;
  id: string;
  status: LateReturnRequestListResponseDataItemSubmissionsItemStatus;
  updated_at: string;
};

export type LateReturnRequestListResponseDataItemDocumentRequestStatus =
  (typeof LateReturnRequestListResponseDataItemDocumentRequestStatus)[keyof typeof LateReturnRequestListResponseDataItemDocumentRequestStatus];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const LateReturnRequestListResponseDataItemDocumentRequestStatus = {
  awaiting_submission: "awaiting_submission",
  awaiting_review: "awaiting_review",
  provisionally_accepted: "provisionally_accepted",
  accepted: "accepted",
  canceled: "canceled",
} as const;

export type LateReturnRequestListResponseDataItemDocumentRequestReviewsItemReviewerEmail =
  string | null;

export type LateReturnRequestListResponseDataItemDocumentRequestReviewsItemResult =
  (typeof LateReturnRequestListResponseDataItemDocumentRequestReviewsItemResult)[keyof typeof LateReturnRequestListResponseDataItemDocumentRequestReviewsItemResult];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const LateReturnRequestListResponseDataItemDocumentRequestReviewsItemResult =
  {
    accepted: "accepted",
    rejected: "rejected",
    provisionally_accepted: "provisionally_accepted",
  } as const;

export type LateReturnRequestListResponseDataItemDocumentRequestReviewsItemComment =
  string | null;

export type LateReturnRequestListResponseDataItemDocumentRequestReviewsItem = {
  comment: LateReturnRequestListResponseDataItemDocumentRequestReviewsItemComment;
  created_at: string;
  id: string;
  result: LateReturnRequestListResponseDataItemDocumentRequestReviewsItemResult;
  reviewer_email: LateReturnRequestListResponseDataItemDocumentRequestReviewsItemReviewerEmail;
};

export type LateReturnRequestListResponseDataItemDocumentRequest = {
  created_at: string;
  description: string;
  document_submissions: LateReturnRequestListResponseDataItemDocumentRequestDocumentSubmissionsItem[];
  group_id: string;
  group_name: LateReturnRequestListResponseDataItemDocumentRequestGroupName;
  id: string;
  nondocument_submissions: LateReturnRequestListResponseDataItemDocumentRequestNondocumentSubmissions;
  program_id: string;
  program_name: string;
  reviews: LateReturnRequestListResponseDataItemDocumentRequestReviewsItem[];
  source: unknown;
  status: LateReturnRequestListResponseDataItemDocumentRequestStatus;
};

export type LateReturnRequestListResponseDataItemDocumentRequestNondocumentSubmissionsProofOfAuthorizationRequestIds =
  string[] | null;

export type LateReturnRequestListResponseDataItemDocumentRequestNondocumentSubmissionsPlatformVendorListSubmissionId =
  string | null;

export type LateReturnRequestListResponseDataItemDocumentRequestNondocumentSubmissionsPlatformFinancialReportSubmissionId =
  string | null;

export type LateReturnRequestListResponseDataItemDocumentRequestNondocumentSubmissionsPlatformComplianceMetricsSubmissionId =
  string | null;

export type LateReturnRequestListResponseDataItemDocumentRequestNondocumentSubmissionsPlatformComplaintListSubmissionId =
  string | null;

export type LateReturnRequestListResponseDataItemDocumentRequestNondocumentSubmissionsDirectUserDiligenceSubmissionId =
  string | null;

export type LateReturnRequestListResponseDataItemDocumentRequestNondocumentSubmissions =
  {
    direct_user_diligence_submission_id: LateReturnRequestListResponseDataItemDocumentRequestNondocumentSubmissionsDirectUserDiligenceSubmissionId;
    platform_complaint_list_submission_id: LateReturnRequestListResponseDataItemDocumentRequestNondocumentSubmissionsPlatformComplaintListSubmissionId;
    platform_compliance_metrics_submission_id: LateReturnRequestListResponseDataItemDocumentRequestNondocumentSubmissionsPlatformComplianceMetricsSubmissionId;
    platform_financial_report_submission_id: LateReturnRequestListResponseDataItemDocumentRequestNondocumentSubmissionsPlatformFinancialReportSubmissionId;
    platform_vendor_list_submission_id: LateReturnRequestListResponseDataItemDocumentRequestNondocumentSubmissionsPlatformVendorListSubmissionId;
    proof_of_authorization_request_ids: LateReturnRequestListResponseDataItemDocumentRequestNondocumentSubmissionsProofOfAuthorizationRequestIds;
  };

export type LateReturnRequestListResponseDataItemDocumentRequestGroupName =
  | string
  | null;

export type LateReturnRequestListResponseDataItemDocumentRequestDocumentSubmissionsItemCreatedByEmail =
  string | null;

export type LateReturnRequestListResponseDataItemDocumentRequestDocumentSubmissionsItem =
  {
    created_at: string;
    created_by_email: LateReturnRequestListResponseDataItemDocumentRequestDocumentSubmissionsItemCreatedByEmail;
    file_id: string;
    file_name: string;
    id: string;
  };

export type LateReturnRequestListResponseDataItemContactName = string | null;

export type LateReturnRequestListResponseDataItemContactFax = string | null;

export type LateReturnRequestListResponseDataItemContactEmail = string | null;

export type LateReturnRequestListResponseDataItemClaimNumber = string | null;

export type LateReturnRequestListResponseDataItemAchTransfersItem = {
  amount: number;
  currency: string;
  description: string;
  id: string;
};

export type LateReturnRequestListResponseDataItem = {
  ach_transfers: LateReturnRequestListResponseDataItemAchTransfersItem[];
  claim_number: LateReturnRequestListResponseDataItemClaimNumber;
  contact_email: LateReturnRequestListResponseDataItemContactEmail;
  contact_fax: LateReturnRequestListResponseDataItemContactFax;
  contact_name: LateReturnRequestListResponseDataItemContactName;
  created_at: string;
  document_request: LateReturnRequestListResponseDataItemDocumentRequest;
  due_on: string;
  group_id: string;
  id: string;
  submissions: LateReturnRequestListResponseDataItemSubmissionsItem[];
};

export interface LateReturnRequestGetResponse {
  ach_transfers: LateReturnRequestGetResponseAchTransfersItem[];
  claim_number: LateReturnRequestGetResponseClaimNumber;
  contact_email: LateReturnRequestGetResponseContactEmail;
  contact_fax: LateReturnRequestGetResponseContactFax;
  contact_name: LateReturnRequestGetResponseContactName;
  created_at: string;
  document_request: LateReturnRequestGetResponseDocumentRequest;
  due_on: string;
  group_id: string;
  id: string;
  submissions: LateReturnRequestGetResponseSubmissionsItem[];
}

export type LateReturnRequestGetResponseSubmissionsItemStatus =
  (typeof LateReturnRequestGetResponseSubmissionsItemStatus)[keyof typeof LateReturnRequestGetResponseSubmissionsItemStatus];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const LateReturnRequestGetResponseSubmissionsItemStatus = {
  pending_review: "pending_review",
  rejected: "rejected",
  canceled: "canceled",
  pending_sending: "pending_sending",
  sent: "sent",
} as const;

export type LateReturnRequestGetResponseSubmissionsItemAuthorizerName =
  | string
  | null;

export type LateReturnRequestGetResponseSubmissionsItemAuthorizerIpAddress =
  | string
  | null;

export type LateReturnRequestGetResponseSubmissionsItemAuthorizerEmail =
  | string
  | null;

export type LateReturnRequestGetResponseSubmissionsItemAuthorizerCompany =
  | string
  | null;

export type LateReturnRequestGetResponseSubmissionsItemAdditionalEvidenceFileId =
  string | null;

export type LateReturnRequestGetResponseSubmissionsItem = {
  additional_evidence_file_id: LateReturnRequestGetResponseSubmissionsItemAdditionalEvidenceFileId;
  authorization_terms: string;
  authorized_at: string;
  authorizer_company: LateReturnRequestGetResponseSubmissionsItemAuthorizerCompany;
  authorizer_email: LateReturnRequestGetResponseSubmissionsItemAuthorizerEmail;
  authorizer_ip_address: LateReturnRequestGetResponseSubmissionsItemAuthorizerIpAddress;
  authorizer_name: LateReturnRequestGetResponseSubmissionsItemAuthorizerName;
  created_at: string;
  id: string;
  status: LateReturnRequestGetResponseSubmissionsItemStatus;
  updated_at: string;
};

export type LateReturnRequestGetResponseDocumentRequestStatus =
  (typeof LateReturnRequestGetResponseDocumentRequestStatus)[keyof typeof LateReturnRequestGetResponseDocumentRequestStatus];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const LateReturnRequestGetResponseDocumentRequestStatus = {
  awaiting_submission: "awaiting_submission",
  awaiting_review: "awaiting_review",
  provisionally_accepted: "provisionally_accepted",
  accepted: "accepted",
  canceled: "canceled",
} as const;

export type LateReturnRequestGetResponseDocumentRequestReviewsItemReviewerEmail =
  string | null;

export type LateReturnRequestGetResponseDocumentRequestReviewsItemResult =
  (typeof LateReturnRequestGetResponseDocumentRequestReviewsItemResult)[keyof typeof LateReturnRequestGetResponseDocumentRequestReviewsItemResult];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const LateReturnRequestGetResponseDocumentRequestReviewsItemResult = {
  accepted: "accepted",
  rejected: "rejected",
  provisionally_accepted: "provisionally_accepted",
} as const;

export type LateReturnRequestGetResponseDocumentRequestReviewsItemComment =
  | string
  | null;

export type LateReturnRequestGetResponseDocumentRequestReviewsItem = {
  comment: LateReturnRequestGetResponseDocumentRequestReviewsItemComment;
  created_at: string;
  id: string;
  result: LateReturnRequestGetResponseDocumentRequestReviewsItemResult;
  reviewer_email: LateReturnRequestGetResponseDocumentRequestReviewsItemReviewerEmail;
};

export type LateReturnRequestGetResponseDocumentRequest = {
  created_at: string;
  description: string;
  document_submissions: LateReturnRequestGetResponseDocumentRequestDocumentSubmissionsItem[];
  group_id: string;
  group_name: LateReturnRequestGetResponseDocumentRequestGroupName;
  id: string;
  nondocument_submissions: LateReturnRequestGetResponseDocumentRequestNondocumentSubmissions;
  program_id: string;
  program_name: string;
  reviews: LateReturnRequestGetResponseDocumentRequestReviewsItem[];
  source: unknown;
  status: LateReturnRequestGetResponseDocumentRequestStatus;
};

export type LateReturnRequestGetResponseDocumentRequestNondocumentSubmissionsProofOfAuthorizationRequestIds =
  string[] | null;

export type LateReturnRequestGetResponseDocumentRequestNondocumentSubmissionsPlatformVendorListSubmissionId =
  string | null;

export type LateReturnRequestGetResponseDocumentRequestNondocumentSubmissionsPlatformFinancialReportSubmissionId =
  string | null;

export type LateReturnRequestGetResponseDocumentRequestNondocumentSubmissionsPlatformComplianceMetricsSubmissionId =
  string | null;

export type LateReturnRequestGetResponseDocumentRequestNondocumentSubmissionsPlatformComplaintListSubmissionId =
  string | null;

export type LateReturnRequestGetResponseDocumentRequestNondocumentSubmissionsDirectUserDiligenceSubmissionId =
  string | null;

export type LateReturnRequestGetResponseDocumentRequestNondocumentSubmissions =
  {
    direct_user_diligence_submission_id: LateReturnRequestGetResponseDocumentRequestNondocumentSubmissionsDirectUserDiligenceSubmissionId;
    platform_complaint_list_submission_id: LateReturnRequestGetResponseDocumentRequestNondocumentSubmissionsPlatformComplaintListSubmissionId;
    platform_compliance_metrics_submission_id: LateReturnRequestGetResponseDocumentRequestNondocumentSubmissionsPlatformComplianceMetricsSubmissionId;
    platform_financial_report_submission_id: LateReturnRequestGetResponseDocumentRequestNondocumentSubmissionsPlatformFinancialReportSubmissionId;
    platform_vendor_list_submission_id: LateReturnRequestGetResponseDocumentRequestNondocumentSubmissionsPlatformVendorListSubmissionId;
    proof_of_authorization_request_ids: LateReturnRequestGetResponseDocumentRequestNondocumentSubmissionsProofOfAuthorizationRequestIds;
  };

export type LateReturnRequestGetResponseDocumentRequestGroupName =
  | string
  | null;

export type LateReturnRequestGetResponseDocumentRequestDocumentSubmissionsItemCreatedByEmail =
  string | null;

export type LateReturnRequestGetResponseDocumentRequestDocumentSubmissionsItem =
  {
    created_at: string;
    created_by_email: LateReturnRequestGetResponseDocumentRequestDocumentSubmissionsItemCreatedByEmail;
    file_id: string;
    file_name: string;
    id: string;
  };

export type LateReturnRequestGetResponseContactName = string | null;

export type LateReturnRequestGetResponseContactFax = string | null;

export type LateReturnRequestGetResponseContactEmail = string | null;

export type LateReturnRequestGetResponseClaimNumber = string | null;

export type LateReturnRequestGetResponseAchTransfersItem = {
  amount: number;
  currency: string;
  description: string;
  id: string;
};

export type InternalFileViewDirectiveGetResponse = Blob;

export type InitialStateGetResponseMappingsCurrencyMinorUnitCountLookup = {
  CAD: number;
  CHF: number;
  EUR: number;
  GBP: number;
  JPY: number;
  USD: number;
};

export type InitialStateGetResponseMappings = {
  currencyMinorUnitCountLookup: InitialStateGetResponseMappingsCurrencyMinorUnitCountLookup;
};

export type InitialStateGetResponseConstants = {
  /** @pattern ^-?\d+(\.\d+)?$ */
  ach_origination_volume_warn_threshold: string;
  default_ach_company_id: string;
};

export interface InitialStateGetResponse {
  categoricals: InitialStateGetResponseCategoricals;
  constants: InitialStateGetResponseConstants;
  mappings: InitialStateGetResponseMappings;
}

export type InitialStateGetResponseCategoricalsPeerGroupItem = {
  name: string;
  value: string;
};

export type InitialStateGetResponseCategoricalsPartnerBankItem = {
  name: string;
  value: string;
};

export type InitialStateGetResponseCategoricalsIndustryCodeItem = {
  name: string;
  value: string;
};

export type InitialStateGetResponseCategoricals = {
  check_rejection_reason: InitialStateGetResponseCategoricalsCheckRejectionReasonItem[];
  currency: InitialStateGetResponseCategoricalsCurrencyItem[];
  gate: InitialStateGetResponseCategoricalsGateItem[];
  industry_code: InitialStateGetResponseCategoricalsIndustryCodeItem[];
  partner_bank: InitialStateGetResponseCategoricalsPartnerBankItem[];
  peer_group: InitialStateGetResponseCategoricalsPeerGroupItem[];
};

export type InitialStateGetResponseCategoricalsGateItemValue =
  (typeof InitialStateGetResponseCategoricalsGateItemValue)[keyof typeof InitialStateGetResponseCategoricalsGateItemValue];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const InitialStateGetResponseCategoricalsGateItemValue = {
  allow_any_visa_tokenization_token_requestor:
    "allow_any_visa_tokenization_token_requestor",
  account_list_balance: "account_list_balance",
  ach_debits: "ach_debits",
  bookkeeping: "bookkeeping",
  check_transfer_balance_check_parameter:
    "check_transfer_balance_check_parameter",
  credit_reserve_available_balance: "credit_reserve_available_balance",
  hide_dashboard_transaction_description_filter:
    "hide_dashboard_transaction_description_filter",
  error_endpoint: "error_endpoint",
  government_authority_entity_create: "government_authority_entity_create",
  high_priority_webhooks: "high_priority_webhooks",
  inbound_checks: "inbound_checks",
  international_checks: "international_checks",
  legacy_entity_api: "legacy_entity_api",
  limits: "limits",
  max_limit_disabled: "max_limit_disabled",
  natural_person_entity_create: "natural_person_entity_create",
  natural_person_entity_accept_non_tax_id:
    "natural_person_entity_accept_non_tax_id",
  notification_of_change_creating: "notification_of_change_creating",
  post_office_box_entity_address: "post_office_box_entity_address",
  rate_limiting_disabled: "rate_limiting_disabled",
  rate_limit_tier_2x: "rate_limit_tier_2x",
  real_time_payments_request_for_payments:
    "real_time_payments_request_for_payments",
  structured_x12_addenda: "structured_x12_addenda",
  custom_physical_cards: "custom_physical_cards",
  faster_physical_cards_shipping: "faster_physical_cards_shipping",
  physical_cards_text: "physical_cards_text",
  dashboard_graphs_disabled: "dashboard_graphs_disabled",
  dashboard_rollups: "dashboard_rollups",
  wire_drawdown_requests: "wire_drawdown_requests",
  prevent_new_account_creation: "prevent_new_account_creation",
  ramp_business_account_onboarding_survey:
    "ramp_business_account_onboarding_survey",
  entity_onboarding_survey_backfill: "entity_onboarding_survey_backfill",
  effective_date_in_ach_transfer_instruction_post:
    "effective_date_in_ach_transfer_instruction_post",
  wire_transfer_synchronous_intention: "wire_transfer_synchronous_intention",
  transfer_sessions: "transfer_sessions",
  checkeeper_v2: "checkeeper_v2",
  checkeeper_v3: "checkeeper_v3",
  lob: "lob",
  allow_synchronous_transaction_timing: "allow_synchronous_transaction_timing",
  allow_dedicated_card_account_range: "allow_dedicated_card_account_range",
  card_details_iframes: "card_details_iframes",
  business_communication_solutions: "business_communication_solutions",
} as const;

export type InitialStateGetResponseCategoricalsGateItem = {
  name: string;
  value: InitialStateGetResponseCategoricalsGateItemValue;
};

export type InitialStateGetResponseCategoricalsCurrencyItem = {
  name: string;
  value: string;
};

export type InitialStateGetResponseCategoricalsCheckRejectionReasonItem = {
  name: string;
  value: string;
};

export type InboundMailItemRejectPostResponseStatus =
  (typeof InboundMailItemRejectPostResponseStatus)[keyof typeof InboundMailItemRejectPostResponseStatus];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const InboundMailItemRejectPostResponseStatus = {
  pending_scanning: "pending_scanning",
  pending_allocating: "pending_allocating",
  pending_automatic_allocating: "pending_automatic_allocating",
  pending_return_allocating: "pending_return_allocating",
  complete: "complete",
  skipped: "skipped",
  requires_attention: "requires_attention",
} as const;

export type InboundMailItemRejectPostResponsePayee = string | null;

export type InboundMailItemRejectPostResponseInternalFileId = string | null;

export type InboundMailItemRejectPostResponseEnvelopeInternalFileId =
  | string
  | null;

export interface InboundMailItemRejectPostResponse {
  amounts: string[];
  checks: InboundMailItemRejectPostResponseChecksItem[];
  created_at: string;
  envelope_internal_file_id: InboundMailItemRejectPostResponseEnvelopeInternalFileId;
  id: string;
  internal_file_id: InboundMailItemRejectPostResponseInternalFileId;
  payee: InboundMailItemRejectPostResponsePayee;
  stable_identifier: string;
  status: InboundMailItemRejectPostResponseStatus;
}

export type InboundMailItemRejectPostResponseChecksItemPayer = string | null;

export type InboundMailItemRejectPostResponseChecksItemPayee = string | null;

export type InboundMailItemRejectPostResponseChecksItemCheckNumber =
  | string
  | null;

export type InboundMailItemRejectPostResponseChecksItem = {
  amount: number;
  check_number: InboundMailItemRejectPostResponseChecksItemCheckNumber;
  created_at: string;
  currency: string;
  id: string;
  payee: InboundMailItemRejectPostResponseChecksItemPayee;
  payer: InboundMailItemRejectPostResponseChecksItemPayer;
};

export type InboundMailItemRejectPostParametersRejectionReason =
  (typeof InboundMailItemRejectPostParametersRejectionReason)[keyof typeof InboundMailItemRejectPostParametersRejectionReason];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const InboundMailItemRejectPostParametersRejectionReason = {
  no_matching_lockbox_and_suite_86107: "no_matching_lockbox_and_suite_86107",
  no_matching_lockbox: "no_matching_lockbox",
  no_check: "no_check",
  lockbox_not_active: "lockbox_not_active",
} as const;

export interface InboundMailItemRejectPostParameters {
  assign_to_ascend: boolean;
  rejection_reason: InboundMailItemRejectPostParametersRejectionReason;
}

export type InboundMailItemListResponseNextCursor = string | null;

export interface InboundMailItemListResponse {
  data: InboundMailItemListResponseDataItem[];
  next_cursor: InboundMailItemListResponseNextCursor;
}

export type InboundMailItemListResponseDataItemStatus =
  (typeof InboundMailItemListResponseDataItemStatus)[keyof typeof InboundMailItemListResponseDataItemStatus];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const InboundMailItemListResponseDataItemStatus = {
  pending_scanning: "pending_scanning",
  pending_allocating: "pending_allocating",
  pending_automatic_allocating: "pending_automatic_allocating",
  pending_return_allocating: "pending_return_allocating",
  complete: "complete",
  skipped: "skipped",
  requires_attention: "requires_attention",
} as const;

export type InboundMailItemListResponseDataItemPayee = string | null;

export type InboundMailItemListResponseDataItemInternalFileId = string | null;

export type InboundMailItemListResponseDataItemEnvelopeInternalFileId =
  | string
  | null;

export type InboundMailItemListResponseDataItem = {
  amounts: string[];
  checks: InboundMailItemListResponseDataItemChecksItem[];
  created_at: string;
  envelope_internal_file_id: InboundMailItemListResponseDataItemEnvelopeInternalFileId;
  id: string;
  internal_file_id: InboundMailItemListResponseDataItemInternalFileId;
  payee: InboundMailItemListResponseDataItemPayee;
  stable_identifier: string;
  status: InboundMailItemListResponseDataItemStatus;
};

export type InboundMailItemListResponseDataItemChecksItemPayer = string | null;

export type InboundMailItemListResponseDataItemChecksItemPayee = string | null;

export type InboundMailItemListResponseDataItemChecksItemCheckNumber =
  | string
  | null;

export type InboundMailItemListResponseDataItemChecksItem = {
  amount: number;
  check_number: InboundMailItemListResponseDataItemChecksItemCheckNumber;
  created_at: string;
  currency: string;
  id: string;
  payee: InboundMailItemListResponseDataItemChecksItemPayee;
  payer: InboundMailItemListResponseDataItemChecksItemPayer;
};

export type InboundMailItemGetResponseStatus =
  (typeof InboundMailItemGetResponseStatus)[keyof typeof InboundMailItemGetResponseStatus];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const InboundMailItemGetResponseStatus = {
  pending_scanning: "pending_scanning",
  pending_allocating: "pending_allocating",
  pending_automatic_allocating: "pending_automatic_allocating",
  pending_return_allocating: "pending_return_allocating",
  complete: "complete",
  skipped: "skipped",
  requires_attention: "requires_attention",
} as const;

export type InboundMailItemGetResponsePayee = string | null;

export type InboundMailItemGetResponseInternalFileId = string | null;

export type InboundMailItemGetResponseEnvelopeInternalFileId = string | null;

export interface InboundMailItemGetResponse {
  amounts: string[];
  checks: InboundMailItemGetResponseChecksItem[];
  created_at: string;
  envelope_internal_file_id: InboundMailItemGetResponseEnvelopeInternalFileId;
  id: string;
  internal_file_id: InboundMailItemGetResponseInternalFileId;
  payee: InboundMailItemGetResponsePayee;
  stable_identifier: string;
  status: InboundMailItemGetResponseStatus;
}

export type InboundMailItemGetResponseChecksItemPayer = string | null;

export type InboundMailItemGetResponseChecksItemPayee = string | null;

export type InboundMailItemGetResponseChecksItemCheckNumber = string | null;

export type InboundMailItemGetResponseChecksItem = {
  amount: number;
  check_number: InboundMailItemGetResponseChecksItemCheckNumber;
  created_at: string;
  currency: string;
  id: string;
  payee: InboundMailItemGetResponseChecksItemPayee;
  payer: InboundMailItemGetResponseChecksItemPayer;
};

export type InboundMailItemAllocatePostResponseStatus =
  (typeof InboundMailItemAllocatePostResponseStatus)[keyof typeof InboundMailItemAllocatePostResponseStatus];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const InboundMailItemAllocatePostResponseStatus = {
  pending_scanning: "pending_scanning",
  pending_allocating: "pending_allocating",
  pending_automatic_allocating: "pending_automatic_allocating",
  pending_return_allocating: "pending_return_allocating",
  complete: "complete",
  skipped: "skipped",
  requires_attention: "requires_attention",
} as const;

export type InboundMailItemAllocatePostResponsePayee = string | null;

export type InboundMailItemAllocatePostResponseInternalFileId = string | null;

export type InboundMailItemAllocatePostResponseEnvelopeInternalFileId =
  | string
  | null;

export interface InboundMailItemAllocatePostResponse {
  amounts: string[];
  checks: InboundMailItemAllocatePostResponseChecksItem[];
  created_at: string;
  envelope_internal_file_id: InboundMailItemAllocatePostResponseEnvelopeInternalFileId;
  id: string;
  internal_file_id: InboundMailItemAllocatePostResponseInternalFileId;
  payee: InboundMailItemAllocatePostResponsePayee;
  stable_identifier: string;
  status: InboundMailItemAllocatePostResponseStatus;
}

export type InboundMailItemAllocatePostResponseChecksItemPayer = string | null;

export type InboundMailItemAllocatePostResponseChecksItemPayee = string | null;

export type InboundMailItemAllocatePostResponseChecksItemCheckNumber =
  | string
  | null;

export type InboundMailItemAllocatePostResponseChecksItem = {
  amount: number;
  check_number: InboundMailItemAllocatePostResponseChecksItemCheckNumber;
  created_at: string;
  currency: string;
  id: string;
  payee: InboundMailItemAllocatePostResponseChecksItemPayee;
  payer: InboundMailItemAllocatePostResponseChecksItemPayer;
};

export type InboundMailItemAllocatePostParametersChecksItem = {
  amount: number;
  back_page_number?: number;
  front_page_number?: number;
};

export interface InboundMailItemAllocatePostParameters {
  checks: InboundMailItemAllocatePostParametersChecksItem[];
  line2_suffix: string;
}

export type InboundFedNowMessageListResponseNextCursor = string | null;

export interface InboundFedNowMessageListResponse {
  data: InboundFedNowMessageListResponseDataItem[];
  next_cursor: InboundFedNowMessageListResponseNextCursor;
}

export type InboundFedNowMessageListResponseDataItemStatus =
  (typeof InboundFedNowMessageListResponseDataItemStatus)[keyof typeof InboundFedNowMessageListResponseDataItemStatus];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const InboundFedNowMessageListResponseDataItemStatus = {
  pending_parsing: "pending_parsing",
  complete: "complete",
  requires_attention: "requires_attention",
} as const;

export type InboundFedNowMessageListResponseDataItemMessageType = string | null;

export type InboundFedNowMessageListResponseDataItemMessageId = string | null;

export type InboundFedNowMessageListResponseDataItem = {
  body: string;
  created_at: string;
  id: string;
  message_id: InboundFedNowMessageListResponseDataItemMessageId;
  message_type: InboundFedNowMessageListResponseDataItemMessageType;
  mq_message_descriptor: unknown;
  mq_properties: unknown;
  queue_name: string;
  status: InboundFedNowMessageListResponseDataItemStatus;
};

export type InboundFedNowMessageGetResponseStatus =
  (typeof InboundFedNowMessageGetResponseStatus)[keyof typeof InboundFedNowMessageGetResponseStatus];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const InboundFedNowMessageGetResponseStatus = {
  pending_parsing: "pending_parsing",
  complete: "complete",
  requires_attention: "requires_attention",
} as const;

export type InboundFedNowMessageGetResponseMessageType = string | null;

export type InboundFedNowMessageGetResponseMessageId = string | null;

export interface InboundFedNowMessageGetResponse {
  body: string;
  created_at: string;
  id: string;
  message_id: InboundFedNowMessageGetResponseMessageId;
  message_type: InboundFedNowMessageGetResponseMessageType;
  mq_message_descriptor: unknown;
  mq_properties: unknown;
  queue_name: string;
  status: InboundFedNowMessageGetResponseStatus;
}

export type InboundCheckGetResponseImagesItem = {
  api_file_id: string;
  view_side: string;
};

export type InboundCheckGetResponseCheckNumber = string | null;

export interface InboundCheckGetResponse {
  check_number: InboundCheckGetResponseCheckNumber;
  images: InboundCheckGetResponseImagesItem[];
}

export type IdentityDocumentPatchResponseSubmittedState = string | null;

export type IdentityDocumentPatchResponseSubmittedExpirationDate =
  | string
  | null;

export type IdentityDocumentPatchResponseSubmittedDocumentType =
  (typeof IdentityDocumentPatchResponseSubmittedDocumentType)[keyof typeof IdentityDocumentPatchResponseSubmittedDocumentType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const IdentityDocumentPatchResponseSubmittedDocumentType = {
  passport: "passport",
  us_drivers_license: "us_drivers_license",
  other: "other",
} as const;

export type IdentityDocumentPatchResponseSubmittedDocumentNumber =
  | string
  | null;

export interface IdentityDocumentPatchResponse {
  api_back_file_id: IdentityDocumentPatchResponseApiBackFileId;
  api_file_id: string;
  created_at: string;
  group_id: string;
  id: string;
  identity_verification_status: IdentityDocumentPatchResponseIdentityVerificationStatus;
  submitted_address: IdentityDocumentPatchResponseSubmittedAddress;
  submitted_country: string;
  submitted_date_of_birth: string;
  submitted_document_number: IdentityDocumentPatchResponseSubmittedDocumentNumber;
  submitted_document_type: IdentityDocumentPatchResponseSubmittedDocumentType;
  submitted_expiration_date: IdentityDocumentPatchResponseSubmittedExpirationDate;
  submitted_name: string;
  submitted_state: IdentityDocumentPatchResponseSubmittedState;
}

export type IdentityDocumentPatchResponseSubmittedAddressLine2 = string | null;

export type IdentityDocumentPatchResponseSubmittedAddress = {
  city: string;
  line1: string;
  line2: IdentityDocumentPatchResponseSubmittedAddressLine2;
  state: string;
  zip: string;
};

export type IdentityDocumentPatchResponseIdentityVerificationStatus =
  (typeof IdentityDocumentPatchResponseIdentityVerificationStatus)[keyof typeof IdentityDocumentPatchResponseIdentityVerificationStatus];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const IdentityDocumentPatchResponseIdentityVerificationStatus = {
  pending_verification: "pending_verification",
  update_pending_verification: "update_pending_verification",
  verified: "verified",
  falsified: "falsified",
  no_match: "no_match",
  verifying: "verifying",
  requires_attention: "requires_attention",
  not_performed: "not_performed",
  skipped: "skipped",
} as const;

export type IdentityDocumentPatchResponseApiBackFileId = string | null;

export type IdentityDocumentPatchParametersIdentityVerificationStatus =
  (typeof IdentityDocumentPatchParametersIdentityVerificationStatus)[keyof typeof IdentityDocumentPatchParametersIdentityVerificationStatus];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const IdentityDocumentPatchParametersIdentityVerificationStatus = {
  pending_verification: "pending_verification",
  update_pending_verification: "update_pending_verification",
  verified: "verified",
  falsified: "falsified",
  no_match: "no_match",
  verifying: "verifying",
  requires_attention: "requires_attention",
  not_performed: "not_performed",
  skipped: "skipped",
} as const;

export interface IdentityDocumentPatchParameters {
  identity_verification_status: IdentityDocumentPatchParametersIdentityVerificationStatus;
  note?: string;
}

export type IdentityDocumentListResponseNextCursor = string | null;

export interface IdentityDocumentListResponse {
  data: IdentityDocumentListResponseDataItem[];
  next_cursor: IdentityDocumentListResponseNextCursor;
}

export type IdentityDocumentListResponseDataItemSubmittedState = string | null;

export type IdentityDocumentListResponseDataItemSubmittedExpirationDate =
  | string
  | null;

export type IdentityDocumentListResponseDataItemSubmittedDocumentType =
  (typeof IdentityDocumentListResponseDataItemSubmittedDocumentType)[keyof typeof IdentityDocumentListResponseDataItemSubmittedDocumentType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const IdentityDocumentListResponseDataItemSubmittedDocumentType = {
  passport: "passport",
  us_drivers_license: "us_drivers_license",
  other: "other",
} as const;

export type IdentityDocumentListResponseDataItemSubmittedDocumentNumber =
  | string
  | null;

export type IdentityDocumentListResponseDataItemSubmittedAddressLine2 =
  | string
  | null;

export type IdentityDocumentListResponseDataItemSubmittedAddress = {
  city: string;
  line1: string;
  line2: IdentityDocumentListResponseDataItemSubmittedAddressLine2;
  state: string;
  zip: string;
};

export type IdentityDocumentListResponseDataItemIdentityVerificationStatus =
  (typeof IdentityDocumentListResponseDataItemIdentityVerificationStatus)[keyof typeof IdentityDocumentListResponseDataItemIdentityVerificationStatus];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const IdentityDocumentListResponseDataItemIdentityVerificationStatus = {
  pending_verification: "pending_verification",
  update_pending_verification: "update_pending_verification",
  verified: "verified",
  falsified: "falsified",
  no_match: "no_match",
  verifying: "verifying",
  requires_attention: "requires_attention",
  not_performed: "not_performed",
  skipped: "skipped",
} as const;

export type IdentityDocumentListResponseDataItemApiBackFileId = string | null;

export type IdentityDocumentListResponseDataItem = {
  api_back_file_id: IdentityDocumentListResponseDataItemApiBackFileId;
  api_file_id: string;
  created_at: string;
  group_id: string;
  id: string;
  identity_verification_status: IdentityDocumentListResponseDataItemIdentityVerificationStatus;
  submitted_address: IdentityDocumentListResponseDataItemSubmittedAddress;
  submitted_country: string;
  submitted_date_of_birth: string;
  submitted_document_number: IdentityDocumentListResponseDataItemSubmittedDocumentNumber;
  submitted_document_type: IdentityDocumentListResponseDataItemSubmittedDocumentType;
  submitted_expiration_date: IdentityDocumentListResponseDataItemSubmittedExpirationDate;
  submitted_name: string;
  submitted_state: IdentityDocumentListResponseDataItemSubmittedState;
};

export type IdentityDocumentGetResponseSubmittedState = string | null;

export type IdentityDocumentGetResponseSubmittedExpirationDate = string | null;

export type IdentityDocumentGetResponseSubmittedDocumentType =
  (typeof IdentityDocumentGetResponseSubmittedDocumentType)[keyof typeof IdentityDocumentGetResponseSubmittedDocumentType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const IdentityDocumentGetResponseSubmittedDocumentType = {
  passport: "passport",
  us_drivers_license: "us_drivers_license",
  other: "other",
} as const;

export type IdentityDocumentGetResponseSubmittedDocumentNumber = string | null;

export type IdentityDocumentGetResponseSubmittedAddressLine2 = string | null;

export type IdentityDocumentGetResponseSubmittedAddress = {
  city: string;
  line1: string;
  line2: IdentityDocumentGetResponseSubmittedAddressLine2;
  state: string;
  zip: string;
};

export type IdentityDocumentGetResponseIdentityVerificationStatus =
  (typeof IdentityDocumentGetResponseIdentityVerificationStatus)[keyof typeof IdentityDocumentGetResponseIdentityVerificationStatus];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const IdentityDocumentGetResponseIdentityVerificationStatus = {
  pending_verification: "pending_verification",
  update_pending_verification: "update_pending_verification",
  verified: "verified",
  falsified: "falsified",
  no_match: "no_match",
  verifying: "verifying",
  requires_attention: "requires_attention",
  not_performed: "not_performed",
  skipped: "skipped",
} as const;

export type IdentityDocumentGetResponseApiBackFileId = string | null;

export interface IdentityDocumentGetResponse {
  api_back_file_id: IdentityDocumentGetResponseApiBackFileId;
  api_file_id: string;
  created_at: string;
  group_id: string;
  id: string;
  identity_verification_status: IdentityDocumentGetResponseIdentityVerificationStatus;
  submitted_address: IdentityDocumentGetResponseSubmittedAddress;
  submitted_country: string;
  submitted_date_of_birth: string;
  submitted_document_number: IdentityDocumentGetResponseSubmittedDocumentNumber;
  submitted_document_type: IdentityDocumentGetResponseSubmittedDocumentType;
  submitted_expiration_date: IdentityDocumentGetResponseSubmittedExpirationDate;
  submitted_name: string;
  submitted_state: IdentityDocumentGetResponseSubmittedState;
}

export type HTMLPreviewGetResponse = string;

export type GroupPatchResponseStatus =
  (typeof GroupPatchResponseStatus)[keyof typeof GroupPatchResponseStatus];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GroupPatchResponseStatus = {
  pending_activating: "pending_activating",
  active: "active",
  rejected: "rejected",
  offboarded: "offboarded",
} as const;

export type GroupPatchResponseName = string | null;

export interface GroupPatchResponse {
  created_at: string;
  friendly_nickname: string;
  id: string;
  name: GroupPatchResponseName;
  status: GroupPatchResponseStatus;
}

export interface GroupPatchParameters {
  facilitates_investment?: boolean;
  facilitates_money_services_business?: boolean;
  is_favorite?: boolean;
  needs_diligence_periodically_refreshed?: boolean;
}

export type GroupListResponseNextCursor = string | null;

export type GroupListResponseDataItemStatus =
  (typeof GroupListResponseDataItemStatus)[keyof typeof GroupListResponseDataItemStatus];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GroupListResponseDataItemStatus = {
  pending_activating: "pending_activating",
  active: "active",
  rejected: "rejected",
  offboarded: "offboarded",
} as const;

export type GroupListResponseDataItemName = string | null;

export type GroupListResponseDataItem = {
  created_at: string;
  friendly_nickname: string;
  id: string;
  name: GroupListResponseDataItemName;
  status: GroupListResponseDataItemStatus;
};

export interface GroupListResponse {
  data: GroupListResponseDataItem[];
  next_cursor: GroupListResponseNextCursor;
}

export type GroupGetResponseSubmittedCompanyDetails = string | null;

export type GroupGetResponseStatus =
  (typeof GroupGetResponseStatus)[keyof typeof GroupGetResponseStatus];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GroupGetResponseStatus = {
  pending_activating: "pending_activating",
  active: "active",
  rejected: "rejected",
  offboarded: "offboarded",
} as const;

export type GroupGetResponseSlackChannelIdentifier = string | null;

export type GroupGetResponseRiskRating =
  (typeof GroupGetResponseRiskRating)[keyof typeof GroupGetResponseRiskRating];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GroupGetResponseRiskRating = {
  high: "high",
  low: "low",
  medium: "medium",
} as const;

export type GroupGetResponsePeerGroup =
  (typeof GroupGetResponsePeerGroup)[keyof typeof GroupGetResponsePeerGroup];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GroupGetResponsePeerGroup = {
  business: "business",
  family: "family",
  insider: "insider",
} as const;

export type GroupGetResponseName = string | null;

export type GroupGetResponseMaximumEventSubscriptionCount = number | null;

export type GroupGetResponseFirstInternetBankMigrationStatus =
  (typeof GroupGetResponseFirstInternetBankMigrationStatus)[keyof typeof GroupGetResponseFirstInternetBankMigrationStatus];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GroupGetResponseFirstInternetBankMigrationStatus = {
  migrated: "migrated",
  already_on_first_internet_bank: "already_on_first_internet_bank",
  account_closed: "account_closed",
} as const;

export type GroupGetResponseFirstInternetBankMigrationAcceptedTermsAt =
  | string
  | null;

export type GroupGetResponseBanksItem =
  (typeof GroupGetResponseBanksItem)[keyof typeof GroupGetResponseBanksItem];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GroupGetResponseBanksItem = {
  blue_ridge_bank: "blue_ridge_bank",
  core_bank: "core_bank",
  first_internet_bank: "first_internet_bank",
  global_innovations_bank: "global_innovations_bank",
  grasshopper_bank: "grasshopper_bank",
} as const;

export interface GroupGetResponse {
  ach_company_id: string;
  banks: GroupGetResponseBanksItem[];
  created_at: string;
  facilitates_investment: boolean;
  facilitates_money_services_business: boolean;
  first_internet_bank_migration_accepted_terms_at: GroupGetResponseFirstInternetBankMigrationAcceptedTermsAt;
  first_internet_bank_migration_status: GroupGetResponseFirstInternetBankMigrationStatus;
  friendly_nickname: string;
  id: string;
  locked: boolean;
  maximum_event_subscription_count: GroupGetResponseMaximumEventSubscriptionCount;
  name: GroupGetResponseName;
  needs_diligence_periodically_refreshed: boolean;
  peer_group: GroupGetResponsePeerGroup;
  risk_rating: GroupGetResponseRiskRating;
  slack_channel_identifier: GroupGetResponseSlackChannelIdentifier;
  status: GroupGetResponseStatus;
  submitted_company_details: GroupGetResponseSubmittedCompanyDetails;
}

export type GroupGatePostResponseGatesItem =
  (typeof GroupGatePostResponseGatesItem)[keyof typeof GroupGatePostResponseGatesItem];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GroupGatePostResponseGatesItem = {
  allow_any_visa_tokenization_token_requestor:
    "allow_any_visa_tokenization_token_requestor",
  account_list_balance: "account_list_balance",
  ach_debits: "ach_debits",
  bookkeeping: "bookkeeping",
  check_transfer_balance_check_parameter:
    "check_transfer_balance_check_parameter",
  credit_reserve_available_balance: "credit_reserve_available_balance",
  hide_dashboard_transaction_description_filter:
    "hide_dashboard_transaction_description_filter",
  error_endpoint: "error_endpoint",
  government_authority_entity_create: "government_authority_entity_create",
  high_priority_webhooks: "high_priority_webhooks",
  inbound_checks: "inbound_checks",
  international_checks: "international_checks",
  legacy_entity_api: "legacy_entity_api",
  limits: "limits",
  max_limit_disabled: "max_limit_disabled",
  natural_person_entity_create: "natural_person_entity_create",
  natural_person_entity_accept_non_tax_id:
    "natural_person_entity_accept_non_tax_id",
  notification_of_change_creating: "notification_of_change_creating",
  post_office_box_entity_address: "post_office_box_entity_address",
  rate_limiting_disabled: "rate_limiting_disabled",
  rate_limit_tier_2x: "rate_limit_tier_2x",
  real_time_payments_request_for_payments:
    "real_time_payments_request_for_payments",
  structured_x12_addenda: "structured_x12_addenda",
  custom_physical_cards: "custom_physical_cards",
  faster_physical_cards_shipping: "faster_physical_cards_shipping",
  physical_cards_text: "physical_cards_text",
  dashboard_graphs_disabled: "dashboard_graphs_disabled",
  dashboard_rollups: "dashboard_rollups",
  wire_drawdown_requests: "wire_drawdown_requests",
  prevent_new_account_creation: "prevent_new_account_creation",
  ramp_business_account_onboarding_survey:
    "ramp_business_account_onboarding_survey",
  entity_onboarding_survey_backfill: "entity_onboarding_survey_backfill",
  effective_date_in_ach_transfer_instruction_post:
    "effective_date_in_ach_transfer_instruction_post",
  wire_transfer_synchronous_intention: "wire_transfer_synchronous_intention",
  transfer_sessions: "transfer_sessions",
  checkeeper_v2: "checkeeper_v2",
  checkeeper_v3: "checkeeper_v3",
  lob: "lob",
  allow_synchronous_transaction_timing: "allow_synchronous_transaction_timing",
  allow_dedicated_card_account_range: "allow_dedicated_card_account_range",
  card_details_iframes: "card_details_iframes",
  business_communication_solutions: "business_communication_solutions",
} as const;

export interface GroupGatePostResponse {
  gates: GroupGatePostResponseGatesItem[];
}

export type GroupGatePostParametersFeature =
  (typeof GroupGatePostParametersFeature)[keyof typeof GroupGatePostParametersFeature];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GroupGatePostParametersFeature = {
  allow_any_visa_tokenization_token_requestor:
    "allow_any_visa_tokenization_token_requestor",
  account_list_balance: "account_list_balance",
  ach_debits: "ach_debits",
  bookkeeping: "bookkeeping",
  check_transfer_balance_check_parameter:
    "check_transfer_balance_check_parameter",
  credit_reserve_available_balance: "credit_reserve_available_balance",
  hide_dashboard_transaction_description_filter:
    "hide_dashboard_transaction_description_filter",
  error_endpoint: "error_endpoint",
  government_authority_entity_create: "government_authority_entity_create",
  high_priority_webhooks: "high_priority_webhooks",
  inbound_checks: "inbound_checks",
  international_checks: "international_checks",
  legacy_entity_api: "legacy_entity_api",
  limits: "limits",
  max_limit_disabled: "max_limit_disabled",
  natural_person_entity_create: "natural_person_entity_create",
  natural_person_entity_accept_non_tax_id:
    "natural_person_entity_accept_non_tax_id",
  notification_of_change_creating: "notification_of_change_creating",
  post_office_box_entity_address: "post_office_box_entity_address",
  rate_limiting_disabled: "rate_limiting_disabled",
  rate_limit_tier_2x: "rate_limit_tier_2x",
  real_time_payments_request_for_payments:
    "real_time_payments_request_for_payments",
  structured_x12_addenda: "structured_x12_addenda",
  custom_physical_cards: "custom_physical_cards",
  faster_physical_cards_shipping: "faster_physical_cards_shipping",
  physical_cards_text: "physical_cards_text",
  dashboard_graphs_disabled: "dashboard_graphs_disabled",
  dashboard_rollups: "dashboard_rollups",
  wire_drawdown_requests: "wire_drawdown_requests",
  prevent_new_account_creation: "prevent_new_account_creation",
  ramp_business_account_onboarding_survey:
    "ramp_business_account_onboarding_survey",
  entity_onboarding_survey_backfill: "entity_onboarding_survey_backfill",
  effective_date_in_ach_transfer_instruction_post:
    "effective_date_in_ach_transfer_instruction_post",
  wire_transfer_synchronous_intention: "wire_transfer_synchronous_intention",
  transfer_sessions: "transfer_sessions",
  checkeeper_v2: "checkeeper_v2",
  checkeeper_v3: "checkeeper_v3",
  lob: "lob",
  allow_synchronous_transaction_timing: "allow_synchronous_transaction_timing",
  allow_dedicated_card_account_range: "allow_dedicated_card_account_range",
  card_details_iframes: "card_details_iframes",
  business_communication_solutions: "business_communication_solutions",
} as const;

export interface GroupGatePostParameters {
  feature: GroupGatePostParametersFeature;
}

export type GroupGateListResponseGatesItem =
  (typeof GroupGateListResponseGatesItem)[keyof typeof GroupGateListResponseGatesItem];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GroupGateListResponseGatesItem = {
  allow_any_visa_tokenization_token_requestor:
    "allow_any_visa_tokenization_token_requestor",
  account_list_balance: "account_list_balance",
  ach_debits: "ach_debits",
  bookkeeping: "bookkeeping",
  check_transfer_balance_check_parameter:
    "check_transfer_balance_check_parameter",
  credit_reserve_available_balance: "credit_reserve_available_balance",
  hide_dashboard_transaction_description_filter:
    "hide_dashboard_transaction_description_filter",
  error_endpoint: "error_endpoint",
  government_authority_entity_create: "government_authority_entity_create",
  high_priority_webhooks: "high_priority_webhooks",
  inbound_checks: "inbound_checks",
  international_checks: "international_checks",
  legacy_entity_api: "legacy_entity_api",
  limits: "limits",
  max_limit_disabled: "max_limit_disabled",
  natural_person_entity_create: "natural_person_entity_create",
  natural_person_entity_accept_non_tax_id:
    "natural_person_entity_accept_non_tax_id",
  notification_of_change_creating: "notification_of_change_creating",
  post_office_box_entity_address: "post_office_box_entity_address",
  rate_limiting_disabled: "rate_limiting_disabled",
  rate_limit_tier_2x: "rate_limit_tier_2x",
  real_time_payments_request_for_payments:
    "real_time_payments_request_for_payments",
  structured_x12_addenda: "structured_x12_addenda",
  custom_physical_cards: "custom_physical_cards",
  faster_physical_cards_shipping: "faster_physical_cards_shipping",
  physical_cards_text: "physical_cards_text",
  dashboard_graphs_disabled: "dashboard_graphs_disabled",
  dashboard_rollups: "dashboard_rollups",
  wire_drawdown_requests: "wire_drawdown_requests",
  prevent_new_account_creation: "prevent_new_account_creation",
  ramp_business_account_onboarding_survey:
    "ramp_business_account_onboarding_survey",
  entity_onboarding_survey_backfill: "entity_onboarding_survey_backfill",
  effective_date_in_ach_transfer_instruction_post:
    "effective_date_in_ach_transfer_instruction_post",
  wire_transfer_synchronous_intention: "wire_transfer_synchronous_intention",
  transfer_sessions: "transfer_sessions",
  checkeeper_v2: "checkeeper_v2",
  checkeeper_v3: "checkeeper_v3",
  lob: "lob",
  allow_synchronous_transaction_timing: "allow_synchronous_transaction_timing",
  allow_dedicated_card_account_range: "allow_dedicated_card_account_range",
  card_details_iframes: "card_details_iframes",
  business_communication_solutions: "business_communication_solutions",
} as const;

export interface GroupGateListResponse {
  gates: GroupGateListResponseGatesItem[];
}

export type GroupGateDeleteResponseGatesItem =
  (typeof GroupGateDeleteResponseGatesItem)[keyof typeof GroupGateDeleteResponseGatesItem];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GroupGateDeleteResponseGatesItem = {
  allow_any_visa_tokenization_token_requestor:
    "allow_any_visa_tokenization_token_requestor",
  account_list_balance: "account_list_balance",
  ach_debits: "ach_debits",
  bookkeeping: "bookkeeping",
  check_transfer_balance_check_parameter:
    "check_transfer_balance_check_parameter",
  credit_reserve_available_balance: "credit_reserve_available_balance",
  hide_dashboard_transaction_description_filter:
    "hide_dashboard_transaction_description_filter",
  error_endpoint: "error_endpoint",
  government_authority_entity_create: "government_authority_entity_create",
  high_priority_webhooks: "high_priority_webhooks",
  inbound_checks: "inbound_checks",
  international_checks: "international_checks",
  legacy_entity_api: "legacy_entity_api",
  limits: "limits",
  max_limit_disabled: "max_limit_disabled",
  natural_person_entity_create: "natural_person_entity_create",
  natural_person_entity_accept_non_tax_id:
    "natural_person_entity_accept_non_tax_id",
  notification_of_change_creating: "notification_of_change_creating",
  post_office_box_entity_address: "post_office_box_entity_address",
  rate_limiting_disabled: "rate_limiting_disabled",
  rate_limit_tier_2x: "rate_limit_tier_2x",
  real_time_payments_request_for_payments:
    "real_time_payments_request_for_payments",
  structured_x12_addenda: "structured_x12_addenda",
  custom_physical_cards: "custom_physical_cards",
  faster_physical_cards_shipping: "faster_physical_cards_shipping",
  physical_cards_text: "physical_cards_text",
  dashboard_graphs_disabled: "dashboard_graphs_disabled",
  dashboard_rollups: "dashboard_rollups",
  wire_drawdown_requests: "wire_drawdown_requests",
  prevent_new_account_creation: "prevent_new_account_creation",
  ramp_business_account_onboarding_survey:
    "ramp_business_account_onboarding_survey",
  entity_onboarding_survey_backfill: "entity_onboarding_survey_backfill",
  effective_date_in_ach_transfer_instruction_post:
    "effective_date_in_ach_transfer_instruction_post",
  wire_transfer_synchronous_intention: "wire_transfer_synchronous_intention",
  transfer_sessions: "transfer_sessions",
  checkeeper_v2: "checkeeper_v2",
  checkeeper_v3: "checkeeper_v3",
  lob: "lob",
  allow_synchronous_transaction_timing: "allow_synchronous_transaction_timing",
  allow_dedicated_card_account_range: "allow_dedicated_card_account_range",
  card_details_iframes: "card_details_iframes",
  business_communication_solutions: "business_communication_solutions",
} as const;

export interface GroupGateDeleteResponse {
  gates: GroupGateDeleteResponseGatesItem[];
}

export interface GroupByNicknameGetResponse {
  id: string;
}

export type FraudKnownBadSubmissionsSearchResponseNextCursor = string | null;

export interface FraudKnownBadSubmissionsSearchResponse {
  data: FraudKnownBadSubmissionsSearchResponseDataItem[];
  next_cursor: FraudKnownBadSubmissionsSearchResponseNextCursor;
}

export type FraudKnownBadSubmissionsSearchResponseDataItemGroup =
  FraudKnownBadSubmissionsSearchResponseDataItemGroupAnyOf | null;

export type FraudKnownBadSubmissionsSearchResponseDataItem = {
  created_at: string;
  entity: FraudKnownBadSubmissionsSearchResponseDataItemEntity;
  group: FraudKnownBadSubmissionsSearchResponseDataItemGroup;
  id: string;
};

export type FraudKnownBadSubmissionsSearchResponseDataItemGroupAnyOfSubmittedCompanyDetails =
  string | null;

export type FraudKnownBadSubmissionsSearchResponseDataItemGroupAnyOfStatus =
  (typeof FraudKnownBadSubmissionsSearchResponseDataItemGroupAnyOfStatus)[keyof typeof FraudKnownBadSubmissionsSearchResponseDataItemGroupAnyOfStatus];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const FraudKnownBadSubmissionsSearchResponseDataItemGroupAnyOfStatus = {
  pending_activating: "pending_activating",
  active: "active",
  rejected: "rejected",
  offboarded: "offboarded",
} as const;

export type FraudKnownBadSubmissionsSearchResponseDataItemGroupAnyOfSlackChannelIdentifier =
  string | null;

export type FraudKnownBadSubmissionsSearchResponseDataItemGroupAnyOfRiskRating =
  (typeof FraudKnownBadSubmissionsSearchResponseDataItemGroupAnyOfRiskRating)[keyof typeof FraudKnownBadSubmissionsSearchResponseDataItemGroupAnyOfRiskRating];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const FraudKnownBadSubmissionsSearchResponseDataItemGroupAnyOfRiskRating =
  {
    high: "high",
    low: "low",
    medium: "medium",
  } as const;

export type FraudKnownBadSubmissionsSearchResponseDataItemGroupAnyOfPeerGroup =
  (typeof FraudKnownBadSubmissionsSearchResponseDataItemGroupAnyOfPeerGroup)[keyof typeof FraudKnownBadSubmissionsSearchResponseDataItemGroupAnyOfPeerGroup];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const FraudKnownBadSubmissionsSearchResponseDataItemGroupAnyOfPeerGroup =
  {
    business: "business",
    family: "family",
    insider: "insider",
  } as const;

export type FraudKnownBadSubmissionsSearchResponseDataItemGroupAnyOfName =
  | string
  | null;

export type FraudKnownBadSubmissionsSearchResponseDataItemGroupAnyOfMaximumEventSubscriptionCount =
  number | null;

export type FraudKnownBadSubmissionsSearchResponseDataItemGroupAnyOfFirstInternetBankMigrationStatus =
  (typeof FraudKnownBadSubmissionsSearchResponseDataItemGroupAnyOfFirstInternetBankMigrationStatus)[keyof typeof FraudKnownBadSubmissionsSearchResponseDataItemGroupAnyOfFirstInternetBankMigrationStatus];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const FraudKnownBadSubmissionsSearchResponseDataItemGroupAnyOfFirstInternetBankMigrationStatus =
  {
    migrated: "migrated",
    already_on_first_internet_bank: "already_on_first_internet_bank",
    account_closed: "account_closed",
  } as const;

export type FraudKnownBadSubmissionsSearchResponseDataItemGroupAnyOfFirstInternetBankMigrationAcceptedTermsAt =
  string | null;

export type FraudKnownBadSubmissionsSearchResponseDataItemGroupAnyOfBanksItem =
  (typeof FraudKnownBadSubmissionsSearchResponseDataItemGroupAnyOfBanksItem)[keyof typeof FraudKnownBadSubmissionsSearchResponseDataItemGroupAnyOfBanksItem];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const FraudKnownBadSubmissionsSearchResponseDataItemGroupAnyOfBanksItem =
  {
    blue_ridge_bank: "blue_ridge_bank",
    core_bank: "core_bank",
    first_internet_bank: "first_internet_bank",
    global_innovations_bank: "global_innovations_bank",
    grasshopper_bank: "grasshopper_bank",
  } as const;

export type FraudKnownBadSubmissionsSearchResponseDataItemGroupAnyOf = {
  ach_company_id: string;
  banks: FraudKnownBadSubmissionsSearchResponseDataItemGroupAnyOfBanksItem[];
  created_at: string;
  facilitates_investment: boolean;
  facilitates_money_services_business: boolean;
  first_internet_bank_migration_accepted_terms_at: FraudKnownBadSubmissionsSearchResponseDataItemGroupAnyOfFirstInternetBankMigrationAcceptedTermsAt;
  first_internet_bank_migration_status: FraudKnownBadSubmissionsSearchResponseDataItemGroupAnyOfFirstInternetBankMigrationStatus;
  friendly_nickname: string;
  id: string;
  locked: boolean;
  maximum_event_subscription_count: FraudKnownBadSubmissionsSearchResponseDataItemGroupAnyOfMaximumEventSubscriptionCount;
  name: FraudKnownBadSubmissionsSearchResponseDataItemGroupAnyOfName;
  needs_diligence_periodically_refreshed: boolean;
  peer_group: FraudKnownBadSubmissionsSearchResponseDataItemGroupAnyOfPeerGroup;
  risk_rating: FraudKnownBadSubmissionsSearchResponseDataItemGroupAnyOfRiskRating;
  slack_channel_identifier: FraudKnownBadSubmissionsSearchResponseDataItemGroupAnyOfSlackChannelIdentifier;
  status: FraudKnownBadSubmissionsSearchResponseDataItemGroupAnyOfStatus;
  submitted_company_details: FraudKnownBadSubmissionsSearchResponseDataItemGroupAnyOfSubmittedCompanyDetails;
};

export type FraudKnownBadSubmissionsSearchResponseDataItemEntity =
  FraudKnownBadSubmissionsSearchResponseDataItemEntityAnyOf | null;

export type FraudKnownBadSubmissionsSearchResponseDataItemEntityAnyOfTaxId =
  | string
  | null;

export type FraudKnownBadSubmissionsSearchResponseDataItemEntityAnyOfSupplementalDocumentsItemReviewStatus =
  (typeof FraudKnownBadSubmissionsSearchResponseDataItemEntityAnyOfSupplementalDocumentsItemReviewStatus)[keyof typeof FraudKnownBadSubmissionsSearchResponseDataItemEntityAnyOfSupplementalDocumentsItemReviewStatus];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const FraudKnownBadSubmissionsSearchResponseDataItemEntityAnyOfSupplementalDocumentsItemReviewStatus =
  {
    pending_reviewing: "pending_reviewing",
    skipped: "skipped",
    accepted: "accepted",
    accepted_manually_reviewed: "accepted_manually_reviewed",
    rejected: "rejected",
    requires_attention: "requires_attention",
  } as const;

export type FraudKnownBadSubmissionsSearchResponseDataItemEntityAnyOfSupplementalDocumentsItemRejectReason =

    | "parse_failed"
    | "wrong_structure"
    | "data_mismatch"
    | "missing_datasource"
    | "evaluation_failed"
    | null;

export type FraudKnownBadSubmissionsSearchResponseDataItemEntityAnyOfSupplementalDocumentsItem =
  {
    created_at: string;
    file: FraudKnownBadSubmissionsSearchResponseDataItemEntityAnyOfSupplementalDocumentsItemFile;
    id: string;
    reject_reason: FraudKnownBadSubmissionsSearchResponseDataItemEntityAnyOfSupplementalDocumentsItemRejectReason;
    review_status: FraudKnownBadSubmissionsSearchResponseDataItemEntityAnyOfSupplementalDocumentsItemReviewStatus;
  };

export type FraudKnownBadSubmissionsSearchResponseDataItemEntityAnyOfSupplementalDocumentsItemFilePurpose =
  (typeof FraudKnownBadSubmissionsSearchResponseDataItemEntityAnyOfSupplementalDocumentsItemFilePurpose)[keyof typeof FraudKnownBadSubmissionsSearchResponseDataItemEntityAnyOfSupplementalDocumentsItemFilePurpose];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const FraudKnownBadSubmissionsSearchResponseDataItemEntityAnyOfSupplementalDocumentsItemFilePurpose =
  {
    check_image_front: "check_image_front",
    check_image_back: "check_image_back",
    processed_check_image_front: "processed_check_image_front",
    processed_check_image_back: "processed_check_image_back",
    mailed_check_image: "mailed_check_image",
    check_attachment: "check_attachment",
    inbound_mail_item: "inbound_mail_item",
    form_1099_int: "form_1099_int",
    form_1099_misc: "form_1099_misc",
    form_ss_4: "form_ss_4",
    identity_document: "identity_document",
    increase_statement: "increase_statement",
    other: "other",
    trust_formation_document: "trust_formation_document",
    digital_wallet_artwork: "digital_wallet_artwork",
    digital_wallet_app_icon: "digital_wallet_app_icon",
    physical_card_front: "physical_card_front",
    physical_card_back: "physical_card_back",
    physical_card_carrier: "physical_card_carrier",
    document_request: "document_request",
    entity_supplemental_document: "entity_supplemental_document",
    export: "export",
    unusual_activity_report_attachment: "unusual_activity_report_attachment",
    deposit_account_control_agreement: "deposit_account_control_agreement",
    proof_of_authorization_request_submission:
      "proof_of_authorization_request_submission",
  } as const;

export type FraudKnownBadSubmissionsSearchResponseDataItemEntityAnyOfSupplementalDocumentsItemFileDirection =
  (typeof FraudKnownBadSubmissionsSearchResponseDataItemEntityAnyOfSupplementalDocumentsItemFileDirection)[keyof typeof FraudKnownBadSubmissionsSearchResponseDataItemEntityAnyOfSupplementalDocumentsItemFileDirection];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const FraudKnownBadSubmissionsSearchResponseDataItemEntityAnyOfSupplementalDocumentsItemFileDirection =
  {
    to_increase: "to_increase",
    from_increase: "from_increase",
  } as const;

export type FraudKnownBadSubmissionsSearchResponseDataItemEntityAnyOfSupplementalDocumentsItemFileDescription =
  string | null;

export type FraudKnownBadSubmissionsSearchResponseDataItemEntityAnyOfSupplementalDocumentsItemFile =
  {
    created_at: string;
    description: FraudKnownBadSubmissionsSearchResponseDataItemEntityAnyOfSupplementalDocumentsItemFileDescription;
    direction: FraudKnownBadSubmissionsSearchResponseDataItemEntityAnyOfSupplementalDocumentsItemFileDirection;
    filename: string;
    id: string;
    mime: string;
    purpose: FraudKnownBadSubmissionsSearchResponseDataItemEntityAnyOfSupplementalDocumentsItemFilePurpose;
  };

export type FraudKnownBadSubmissionsSearchResponseDataItemEntityAnyOfStructure =
  (typeof FraudKnownBadSubmissionsSearchResponseDataItemEntityAnyOfStructure)[keyof typeof FraudKnownBadSubmissionsSearchResponseDataItemEntityAnyOfStructure];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const FraudKnownBadSubmissionsSearchResponseDataItemEntityAnyOfStructure =
  {
    corporation: "corporation",
    natural_person: "natural_person",
    joint: "joint",
    trust: "trust",
    government_authority: "government_authority",
  } as const;

export type FraudKnownBadSubmissionsSearchResponseDataItemEntityAnyOfStatus =
  (typeof FraudKnownBadSubmissionsSearchResponseDataItemEntityAnyOfStatus)[keyof typeof FraudKnownBadSubmissionsSearchResponseDataItemEntityAnyOfStatus];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const FraudKnownBadSubmissionsSearchResponseDataItemEntityAnyOfStatus = {
  active: "active",
  archived: "archived",
  disabled: "disabled",
} as const;

export type FraudKnownBadSubmissionsSearchResponseDataItemEntityAnyOfSetupSubmissionStructureTrust =
  FraudKnownBadSubmissionsSearchResponseDataItemEntityAnyOfSetupSubmissionStructureTrustAnyOf | null;

export type FraudKnownBadSubmissionsSearchResponseDataItemEntityAnyOfSetupSubmissionStructure =
  {
    corporation: FraudKnownBadSubmissionsSearchResponseDataItemEntityAnyOfSetupSubmissionStructureCorporation;
    government_authority: FraudKnownBadSubmissionsSearchResponseDataItemEntityAnyOfSetupSubmissionStructureGovernmentAuthority;
    joint: FraudKnownBadSubmissionsSearchResponseDataItemEntityAnyOfSetupSubmissionStructureJoint;
    natural_person: FraudKnownBadSubmissionsSearchResponseDataItemEntityAnyOfSetupSubmissionStructureNaturalPerson;
    trust: FraudKnownBadSubmissionsSearchResponseDataItemEntityAnyOfSetupSubmissionStructureTrust;
  };

export type FraudKnownBadSubmissionsSearchResponseDataItemEntityAnyOf = {
  approved_at: FraudKnownBadSubmissionsSearchResponseDataItemEntityAnyOfApprovedAt;
  approved_by: FraudKnownBadSubmissionsSearchResponseDataItemEntityAnyOfApprovedBy;
  automated_customer_identification_program_evaluation: FraudKnownBadSubmissionsSearchResponseDataItemEntityAnyOfAutomatedCustomerIdentificationProgramEvaluation;
  created_at: string;
  customer_information_policy_problems: FraudKnownBadSubmissionsSearchResponseDataItemEntityAnyOfCustomerInformationPolicyProblemsItem[];
  directly_supervised: boolean;
  group_id: string;
  has_informational_accounts: boolean;
  has_ownership_accounts: boolean;
  id: string;
  name: string;
  review_status: FraudKnownBadSubmissionsSearchResponseDataItemEntityAnyOfReviewStatus;
  setup_submission_structure: FraudKnownBadSubmissionsSearchResponseDataItemEntityAnyOfSetupSubmissionStructure;
  status: FraudKnownBadSubmissionsSearchResponseDataItemEntityAnyOfStatus;
  structure: FraudKnownBadSubmissionsSearchResponseDataItemEntityAnyOfStructure;
  supplemental_documents: FraudKnownBadSubmissionsSearchResponseDataItemEntityAnyOfSupplementalDocumentsItem[];
  tax_id: FraudKnownBadSubmissionsSearchResponseDataItemEntityAnyOfTaxId;
};

export type FraudKnownBadSubmissionsSearchResponseDataItemEntityAnyOfSetupSubmissionStructureTrustAnyOfTrusteesItemTaxIdValidationStatus =
  (typeof FraudKnownBadSubmissionsSearchResponseDataItemEntityAnyOfSetupSubmissionStructureTrustAnyOfTrusteesItemTaxIdValidationStatus)[keyof typeof FraudKnownBadSubmissionsSearchResponseDataItemEntityAnyOfSetupSubmissionStructureTrustAnyOfTrusteesItemTaxIdValidationStatus];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const FraudKnownBadSubmissionsSearchResponseDataItemEntityAnyOfSetupSubmissionStructureTrustAnyOfTrusteesItemTaxIdValidationStatus =
  {
    pending_validating: "pending_validating",
    update_pending_validating: "update_pending_validating",
    requires_attention: "requires_attention",
    failed: "failed",
    validated: "validated",
    not_provided: "not_provided",
    skipped: "skipped",
  } as const;

export type FraudKnownBadSubmissionsSearchResponseDataItemEntityAnyOfSetupSubmissionStructureTrustAnyOfTrusteesItemSubmittedIdentificationMethod =
  (typeof FraudKnownBadSubmissionsSearchResponseDataItemEntityAnyOfSetupSubmissionStructureTrustAnyOfTrusteesItemSubmittedIdentificationMethod)[keyof typeof FraudKnownBadSubmissionsSearchResponseDataItemEntityAnyOfSetupSubmissionStructureTrustAnyOfTrusteesItemSubmittedIdentificationMethod];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const FraudKnownBadSubmissionsSearchResponseDataItemEntityAnyOfSetupSubmissionStructureTrustAnyOfTrusteesItemSubmittedIdentificationMethod =
  {
    social_security_number: "social_security_number",
    individual_taxpayer_identification_number:
      "individual_taxpayer_identification_number",
    passport: "passport",
    drivers_license: "drivers_license",
    other: "other",
  } as const;

export type FraudKnownBadSubmissionsSearchResponseDataItemEntityAnyOfSetupSubmissionStructureTrustAnyOfTrusteesItem =
  {
    created_at: string;
    id: string;
    identity_document_submissions: FraudKnownBadSubmissionsSearchResponseDataItemEntityAnyOfSetupSubmissionStructureTrustAnyOfTrusteesItemIdentityDocumentSubmissionsItem[];
    identity_submitted_at: string;
    identity_verification_status: FraudKnownBadSubmissionsSearchResponseDataItemEntityAnyOfSetupSubmissionStructureTrustAnyOfTrusteesItemIdentityVerificationStatus;
    microbilt: FraudKnownBadSubmissionsSearchResponseDataItemEntityAnyOfSetupSubmissionStructureTrustAnyOfTrusteesItemMicrobiltItem[];
    submission_sovos_response_id: FraudKnownBadSubmissionsSearchResponseDataItemEntityAnyOfSetupSubmissionStructureTrustAnyOfTrusteesItemSubmissionSovosResponseId;
    submitted_address: FraudKnownBadSubmissionsSearchResponseDataItemEntityAnyOfSetupSubmissionStructureTrustAnyOfTrusteesItemSubmittedAddress;
    submitted_date_of_birth: string;
    submitted_identification_method: FraudKnownBadSubmissionsSearchResponseDataItemEntityAnyOfSetupSubmissionStructureTrustAnyOfTrusteesItemSubmittedIdentificationMethod;
    submitted_identification_number_last4: string;
    submitted_name: string;
    tax_id_validation_status: FraudKnownBadSubmissionsSearchResponseDataItemEntityAnyOfSetupSubmissionStructureTrustAnyOfTrusteesItemTaxIdValidationStatus;
  };

export type FraudKnownBadSubmissionsSearchResponseDataItemEntityAnyOfSetupSubmissionStructureTrustAnyOf =
  {
    grantor: FraudKnownBadSubmissionsSearchResponseDataItemEntityAnyOfSetupSubmissionStructureTrustAnyOfGrantor;
    id: string;
    submitted_address: FraudKnownBadSubmissionsSearchResponseDataItemEntityAnyOfSetupSubmissionStructureTrustAnyOfSubmittedAddress;
    submitted_category: FraudKnownBadSubmissionsSearchResponseDataItemEntityAnyOfSetupSubmissionStructureTrustAnyOfSubmittedCategory;
    submitted_name: string;
    submitted_tax_id: FraudKnownBadSubmissionsSearchResponseDataItemEntityAnyOfSetupSubmissionStructureTrustAnyOfSubmittedTaxId;
    trustees: FraudKnownBadSubmissionsSearchResponseDataItemEntityAnyOfSetupSubmissionStructureTrustAnyOfTrusteesItem[];
  };

export type FraudKnownBadSubmissionsSearchResponseDataItemEntityAnyOfSetupSubmissionStructureTrustAnyOfTrusteesItemSubmittedAddressLine2 =
  string | null;

export type FraudKnownBadSubmissionsSearchResponseDataItemEntityAnyOfSetupSubmissionStructureTrustAnyOfTrusteesItemSubmittedAddress =
  {
    city: string;
    line1: string;
    line2: FraudKnownBadSubmissionsSearchResponseDataItemEntityAnyOfSetupSubmissionStructureTrustAnyOfTrusteesItemSubmittedAddressLine2;
    state: string;
    zip: string;
  };

export type FraudKnownBadSubmissionsSearchResponseDataItemEntityAnyOfSetupSubmissionStructureTrustAnyOfTrusteesItemSubmissionSovosResponseId =
  string | null;

export type FraudKnownBadSubmissionsSearchResponseDataItemEntityAnyOfSetupSubmissionStructureTrustAnyOfTrusteesItemMicrobiltItemTaxIdType =
  "SSN" | null;

export type FraudKnownBadSubmissionsSearchResponseDataItemEntityAnyOfSetupSubmissionStructureTrustAnyOfTrusteesItemMicrobiltItemTaxIdLast4 =
  string | null;

export type FraudKnownBadSubmissionsSearchResponseDataItemEntityAnyOfSetupSubmissionStructureTrustAnyOfTrusteesItemMicrobiltItemReturnCode =
  (typeof FraudKnownBadSubmissionsSearchResponseDataItemEntityAnyOfSetupSubmissionStructureTrustAnyOfTrusteesItemMicrobiltItemReturnCode)[keyof typeof FraudKnownBadSubmissionsSearchResponseDataItemEntityAnyOfSetupSubmissionStructureTrustAnyOfTrusteesItemMicrobiltItemReturnCode];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const FraudKnownBadSubmissionsSearchResponseDataItemEntityAnyOfSetupSubmissionStructureTrustAnyOfTrusteesItemMicrobiltItemReturnCode =
  {
    OK: "OK",
  } as const;

export type FraudKnownBadSubmissionsSearchResponseDataItemEntityAnyOfSetupSubmissionStructureTrustAnyOfTrusteesItemMicrobiltItemMiddleName =
  string | null;

export type FraudKnownBadSubmissionsSearchResponseDataItemEntityAnyOfSetupSubmissionStructureTrustAnyOfTrusteesItemMicrobiltItemDeathDate =
  string | null;

export type FraudKnownBadSubmissionsSearchResponseDataItemEntityAnyOfSetupSubmissionStructureTrustAnyOfTrusteesItemMicrobiltItemBirthDate =
  string | null;

export type FraudKnownBadSubmissionsSearchResponseDataItemEntityAnyOfSetupSubmissionStructureTrustAnyOfTrusteesItemMicrobiltItem =
  {
    birth_date: FraudKnownBadSubmissionsSearchResponseDataItemEntityAnyOfSetupSubmissionStructureTrustAnyOfTrusteesItemMicrobiltItemBirthDate;
    created_at: string;
    death_date: FraudKnownBadSubmissionsSearchResponseDataItemEntityAnyOfSetupSubmissionStructureTrustAnyOfTrusteesItemMicrobiltItemDeathDate;
    first_name: string;
    id: string;
    last_name: string;
    middle_name: FraudKnownBadSubmissionsSearchResponseDataItemEntityAnyOfSetupSubmissionStructureTrustAnyOfTrusteesItemMicrobiltItemMiddleName;
    request_id: string;
    response: string;
    return_code: FraudKnownBadSubmissionsSearchResponseDataItemEntityAnyOfSetupSubmissionStructureTrustAnyOfTrusteesItemMicrobiltItemReturnCode;
    tax_id_last_4: FraudKnownBadSubmissionsSearchResponseDataItemEntityAnyOfSetupSubmissionStructureTrustAnyOfTrusteesItemMicrobiltItemTaxIdLast4;
    tax_id_type: FraudKnownBadSubmissionsSearchResponseDataItemEntityAnyOfSetupSubmissionStructureTrustAnyOfTrusteesItemMicrobiltItemTaxIdType;
  };

export type FraudKnownBadSubmissionsSearchResponseDataItemEntityAnyOfSetupSubmissionStructureTrustAnyOfTrusteesItemIdentityVerificationStatus =
  (typeof FraudKnownBadSubmissionsSearchResponseDataItemEntityAnyOfSetupSubmissionStructureTrustAnyOfTrusteesItemIdentityVerificationStatus)[keyof typeof FraudKnownBadSubmissionsSearchResponseDataItemEntityAnyOfSetupSubmissionStructureTrustAnyOfTrusteesItemIdentityVerificationStatus];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const FraudKnownBadSubmissionsSearchResponseDataItemEntityAnyOfSetupSubmissionStructureTrustAnyOfTrusteesItemIdentityVerificationStatus =
  {
    pending_verification: "pending_verification",
    update_pending_verification: "update_pending_verification",
    verified: "verified",
    falsified: "falsified",
    no_match: "no_match",
    verifying: "verifying",
    requires_attention: "requires_attention",
    not_performed: "not_performed",
    skipped: "skipped",
  } as const;

export type FraudKnownBadSubmissionsSearchResponseDataItemEntityAnyOfSetupSubmissionStructureTrustAnyOfTrusteesItemIdentityDocumentSubmissionsItemSubmittedState =
  string | null;

export type FraudKnownBadSubmissionsSearchResponseDataItemEntityAnyOfSetupSubmissionStructureTrustAnyOfTrusteesItemIdentityDocumentSubmissionsItemSubmittedExpirationDate =
  string | null;

export type FraudKnownBadSubmissionsSearchResponseDataItemEntityAnyOfSetupSubmissionStructureTrustAnyOfTrusteesItemIdentityDocumentSubmissionsItemSubmittedDocumentType =
  (typeof FraudKnownBadSubmissionsSearchResponseDataItemEntityAnyOfSetupSubmissionStructureTrustAnyOfTrusteesItemIdentityDocumentSubmissionsItemSubmittedDocumentType)[keyof typeof FraudKnownBadSubmissionsSearchResponseDataItemEntityAnyOfSetupSubmissionStructureTrustAnyOfTrusteesItemIdentityDocumentSubmissionsItemSubmittedDocumentType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const FraudKnownBadSubmissionsSearchResponseDataItemEntityAnyOfSetupSubmissionStructureTrustAnyOfTrusteesItemIdentityDocumentSubmissionsItemSubmittedDocumentType =
  {
    passport: "passport",
    us_drivers_license: "us_drivers_license",
    other: "other",
  } as const;

export type FraudKnownBadSubmissionsSearchResponseDataItemEntityAnyOfSetupSubmissionStructureTrustAnyOfTrusteesItemIdentityDocumentSubmissionsItemSubmittedDocumentNumber =
  string | null;

export type FraudKnownBadSubmissionsSearchResponseDataItemEntityAnyOfSetupSubmissionStructureTrustAnyOfTrusteesItemIdentityDocumentSubmissionsItemSubmittedAddressLine2 =
  string | null;

export type FraudKnownBadSubmissionsSearchResponseDataItemEntityAnyOfSetupSubmissionStructureTrustAnyOfTrusteesItemIdentityDocumentSubmissionsItemSubmittedAddress =
  {
    city: string;
    line1: string;
    line2: FraudKnownBadSubmissionsSearchResponseDataItemEntityAnyOfSetupSubmissionStructureTrustAnyOfTrusteesItemIdentityDocumentSubmissionsItemSubmittedAddressLine2;
    state: string;
    zip: string;
  };

export type FraudKnownBadSubmissionsSearchResponseDataItemEntityAnyOfSetupSubmissionStructureTrustAnyOfTrusteesItemIdentityDocumentSubmissionsItemIdentityVerificationStatus =
  (typeof FraudKnownBadSubmissionsSearchResponseDataItemEntityAnyOfSetupSubmissionStructureTrustAnyOfTrusteesItemIdentityDocumentSubmissionsItemIdentityVerificationStatus)[keyof typeof FraudKnownBadSubmissionsSearchResponseDataItemEntityAnyOfSetupSubmissionStructureTrustAnyOfTrusteesItemIdentityDocumentSubmissionsItemIdentityVerificationStatus];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const FraudKnownBadSubmissionsSearchResponseDataItemEntityAnyOfSetupSubmissionStructureTrustAnyOfTrusteesItemIdentityDocumentSubmissionsItemIdentityVerificationStatus =
  {
    pending_verification: "pending_verification",
    update_pending_verification: "update_pending_verification",
    verified: "verified",
    falsified: "falsified",
    no_match: "no_match",
    verifying: "verifying",
    requires_attention: "requires_attention",
    not_performed: "not_performed",
    skipped: "skipped",
  } as const;

export type FraudKnownBadSubmissionsSearchResponseDataItemEntityAnyOfSetupSubmissionStructureTrustAnyOfTrusteesItemIdentityDocumentSubmissionsItemApiBackFileId =
  string | null;

export type FraudKnownBadSubmissionsSearchResponseDataItemEntityAnyOfSetupSubmissionStructureTrustAnyOfTrusteesItemIdentityDocumentSubmissionsItem =
  {
    api_back_file_id: FraudKnownBadSubmissionsSearchResponseDataItemEntityAnyOfSetupSubmissionStructureTrustAnyOfTrusteesItemIdentityDocumentSubmissionsItemApiBackFileId;
    api_file_id: string;
    created_at: string;
    group_id: string;
    id: string;
    identity_verification_status: FraudKnownBadSubmissionsSearchResponseDataItemEntityAnyOfSetupSubmissionStructureTrustAnyOfTrusteesItemIdentityDocumentSubmissionsItemIdentityVerificationStatus;
    submitted_address: FraudKnownBadSubmissionsSearchResponseDataItemEntityAnyOfSetupSubmissionStructureTrustAnyOfTrusteesItemIdentityDocumentSubmissionsItemSubmittedAddress;
    submitted_country: string;
    submitted_date_of_birth: string;
    submitted_document_number: FraudKnownBadSubmissionsSearchResponseDataItemEntityAnyOfSetupSubmissionStructureTrustAnyOfTrusteesItemIdentityDocumentSubmissionsItemSubmittedDocumentNumber;
    submitted_document_type: FraudKnownBadSubmissionsSearchResponseDataItemEntityAnyOfSetupSubmissionStructureTrustAnyOfTrusteesItemIdentityDocumentSubmissionsItemSubmittedDocumentType;
    submitted_expiration_date: FraudKnownBadSubmissionsSearchResponseDataItemEntityAnyOfSetupSubmissionStructureTrustAnyOfTrusteesItemIdentityDocumentSubmissionsItemSubmittedExpirationDate;
    submitted_name: string;
    submitted_state: FraudKnownBadSubmissionsSearchResponseDataItemEntityAnyOfSetupSubmissionStructureTrustAnyOfTrusteesItemIdentityDocumentSubmissionsItemSubmittedState;
  };

export type FraudKnownBadSubmissionsSearchResponseDataItemEntityAnyOfSetupSubmissionStructureTrustAnyOfSubmittedTaxId =
  string | null;

export type FraudKnownBadSubmissionsSearchResponseDataItemEntityAnyOfSetupSubmissionStructureTrustAnyOfSubmittedCategory =
  (typeof FraudKnownBadSubmissionsSearchResponseDataItemEntityAnyOfSetupSubmissionStructureTrustAnyOfSubmittedCategory)[keyof typeof FraudKnownBadSubmissionsSearchResponseDataItemEntityAnyOfSetupSubmissionStructureTrustAnyOfSubmittedCategory];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const FraudKnownBadSubmissionsSearchResponseDataItemEntityAnyOfSetupSubmissionStructureTrustAnyOfSubmittedCategory =
  {
    revocable: "revocable",
    irrevocable: "irrevocable",
  } as const;

export type FraudKnownBadSubmissionsSearchResponseDataItemEntityAnyOfSetupSubmissionStructureTrustAnyOfSubmittedAddressLine2 =
  string | null;

export type FraudKnownBadSubmissionsSearchResponseDataItemEntityAnyOfSetupSubmissionStructureTrustAnyOfSubmittedAddress =
  {
    city: string;
    line1: string;
    line2: FraudKnownBadSubmissionsSearchResponseDataItemEntityAnyOfSetupSubmissionStructureTrustAnyOfSubmittedAddressLine2;
    state: string;
    zip: string;
  };

export type FraudKnownBadSubmissionsSearchResponseDataItemEntityAnyOfSetupSubmissionStructureTrustAnyOfGrantorAnyOfTaxIdValidationStatus =
  (typeof FraudKnownBadSubmissionsSearchResponseDataItemEntityAnyOfSetupSubmissionStructureTrustAnyOfGrantorAnyOfTaxIdValidationStatus)[keyof typeof FraudKnownBadSubmissionsSearchResponseDataItemEntityAnyOfSetupSubmissionStructureTrustAnyOfGrantorAnyOfTaxIdValidationStatus];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const FraudKnownBadSubmissionsSearchResponseDataItemEntityAnyOfSetupSubmissionStructureTrustAnyOfGrantorAnyOfTaxIdValidationStatus =
  {
    pending_validating: "pending_validating",
    update_pending_validating: "update_pending_validating",
    requires_attention: "requires_attention",
    failed: "failed",
    validated: "validated",
    not_provided: "not_provided",
    skipped: "skipped",
  } as const;

export type FraudKnownBadSubmissionsSearchResponseDataItemEntityAnyOfSetupSubmissionStructureTrustAnyOfGrantorAnyOfSubmittedIdentificationMethod =
  (typeof FraudKnownBadSubmissionsSearchResponseDataItemEntityAnyOfSetupSubmissionStructureTrustAnyOfGrantorAnyOfSubmittedIdentificationMethod)[keyof typeof FraudKnownBadSubmissionsSearchResponseDataItemEntityAnyOfSetupSubmissionStructureTrustAnyOfGrantorAnyOfSubmittedIdentificationMethod];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const FraudKnownBadSubmissionsSearchResponseDataItemEntityAnyOfSetupSubmissionStructureTrustAnyOfGrantorAnyOfSubmittedIdentificationMethod =
  {
    social_security_number: "social_security_number",
    individual_taxpayer_identification_number:
      "individual_taxpayer_identification_number",
    passport: "passport",
    drivers_license: "drivers_license",
    other: "other",
  } as const;

export type FraudKnownBadSubmissionsSearchResponseDataItemEntityAnyOfSetupSubmissionStructureTrustAnyOfGrantorAnyOf =
  {
    created_at: string;
    id: string;
    identity_document_submissions: FraudKnownBadSubmissionsSearchResponseDataItemEntityAnyOfSetupSubmissionStructureTrustAnyOfGrantorAnyOfIdentityDocumentSubmissionsItem[];
    identity_submitted_at: string;
    identity_verification_status: FraudKnownBadSubmissionsSearchResponseDataItemEntityAnyOfSetupSubmissionStructureTrustAnyOfGrantorAnyOfIdentityVerificationStatus;
    microbilt: FraudKnownBadSubmissionsSearchResponseDataItemEntityAnyOfSetupSubmissionStructureTrustAnyOfGrantorAnyOfMicrobiltItem[];
    submission_sovos_response_id: FraudKnownBadSubmissionsSearchResponseDataItemEntityAnyOfSetupSubmissionStructureTrustAnyOfGrantorAnyOfSubmissionSovosResponseId;
    submitted_address: FraudKnownBadSubmissionsSearchResponseDataItemEntityAnyOfSetupSubmissionStructureTrustAnyOfGrantorAnyOfSubmittedAddress;
    submitted_date_of_birth: string;
    submitted_identification_method: FraudKnownBadSubmissionsSearchResponseDataItemEntityAnyOfSetupSubmissionStructureTrustAnyOfGrantorAnyOfSubmittedIdentificationMethod;
    submitted_identification_number_last4: string;
    submitted_name: string;
    tax_id_validation_status: FraudKnownBadSubmissionsSearchResponseDataItemEntityAnyOfSetupSubmissionStructureTrustAnyOfGrantorAnyOfTaxIdValidationStatus;
  };

export type FraudKnownBadSubmissionsSearchResponseDataItemEntityAnyOfSetupSubmissionStructureTrustAnyOfGrantor =
  FraudKnownBadSubmissionsSearchResponseDataItemEntityAnyOfSetupSubmissionStructureTrustAnyOfGrantorAnyOf | null;

export type FraudKnownBadSubmissionsSearchResponseDataItemEntityAnyOfSetupSubmissionStructureTrustAnyOfGrantorAnyOfSubmittedAddressLine2 =
  string | null;

export type FraudKnownBadSubmissionsSearchResponseDataItemEntityAnyOfSetupSubmissionStructureTrustAnyOfGrantorAnyOfSubmittedAddress =
  {
    city: string;
    line1: string;
    line2: FraudKnownBadSubmissionsSearchResponseDataItemEntityAnyOfSetupSubmissionStructureTrustAnyOfGrantorAnyOfSubmittedAddressLine2;
    state: string;
    zip: string;
  };

export type FraudKnownBadSubmissionsSearchResponseDataItemEntityAnyOfSetupSubmissionStructureTrustAnyOfGrantorAnyOfSubmissionSovosResponseId =
  string | null;

export type FraudKnownBadSubmissionsSearchResponseDataItemEntityAnyOfSetupSubmissionStructureTrustAnyOfGrantorAnyOfMicrobiltItemTaxIdType =
  "SSN" | null;

export type FraudKnownBadSubmissionsSearchResponseDataItemEntityAnyOfSetupSubmissionStructureTrustAnyOfGrantorAnyOfMicrobiltItemTaxIdLast4 =
  string | null;

export type FraudKnownBadSubmissionsSearchResponseDataItemEntityAnyOfSetupSubmissionStructureTrustAnyOfGrantorAnyOfMicrobiltItemReturnCode =
  (typeof FraudKnownBadSubmissionsSearchResponseDataItemEntityAnyOfSetupSubmissionStructureTrustAnyOfGrantorAnyOfMicrobiltItemReturnCode)[keyof typeof FraudKnownBadSubmissionsSearchResponseDataItemEntityAnyOfSetupSubmissionStructureTrustAnyOfGrantorAnyOfMicrobiltItemReturnCode];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const FraudKnownBadSubmissionsSearchResponseDataItemEntityAnyOfSetupSubmissionStructureTrustAnyOfGrantorAnyOfMicrobiltItemReturnCode =
  {
    OK: "OK",
  } as const;

export type FraudKnownBadSubmissionsSearchResponseDataItemEntityAnyOfSetupSubmissionStructureTrustAnyOfGrantorAnyOfMicrobiltItemMiddleName =
  string | null;

export type FraudKnownBadSubmissionsSearchResponseDataItemEntityAnyOfSetupSubmissionStructureTrustAnyOfGrantorAnyOfMicrobiltItemDeathDate =
  string | null;

export type FraudKnownBadSubmissionsSearchResponseDataItemEntityAnyOfSetupSubmissionStructureTrustAnyOfGrantorAnyOfMicrobiltItemBirthDate =
  string | null;

export type FraudKnownBadSubmissionsSearchResponseDataItemEntityAnyOfSetupSubmissionStructureTrustAnyOfGrantorAnyOfMicrobiltItem =
  {
    birth_date: FraudKnownBadSubmissionsSearchResponseDataItemEntityAnyOfSetupSubmissionStructureTrustAnyOfGrantorAnyOfMicrobiltItemBirthDate;
    created_at: string;
    death_date: FraudKnownBadSubmissionsSearchResponseDataItemEntityAnyOfSetupSubmissionStructureTrustAnyOfGrantorAnyOfMicrobiltItemDeathDate;
    first_name: string;
    id: string;
    last_name: string;
    middle_name: FraudKnownBadSubmissionsSearchResponseDataItemEntityAnyOfSetupSubmissionStructureTrustAnyOfGrantorAnyOfMicrobiltItemMiddleName;
    request_id: string;
    response: string;
    return_code: FraudKnownBadSubmissionsSearchResponseDataItemEntityAnyOfSetupSubmissionStructureTrustAnyOfGrantorAnyOfMicrobiltItemReturnCode;
    tax_id_last_4: FraudKnownBadSubmissionsSearchResponseDataItemEntityAnyOfSetupSubmissionStructureTrustAnyOfGrantorAnyOfMicrobiltItemTaxIdLast4;
    tax_id_type: FraudKnownBadSubmissionsSearchResponseDataItemEntityAnyOfSetupSubmissionStructureTrustAnyOfGrantorAnyOfMicrobiltItemTaxIdType;
  };

export type FraudKnownBadSubmissionsSearchResponseDataItemEntityAnyOfSetupSubmissionStructureTrustAnyOfGrantorAnyOfIdentityVerificationStatus =
  (typeof FraudKnownBadSubmissionsSearchResponseDataItemEntityAnyOfSetupSubmissionStructureTrustAnyOfGrantorAnyOfIdentityVerificationStatus)[keyof typeof FraudKnownBadSubmissionsSearchResponseDataItemEntityAnyOfSetupSubmissionStructureTrustAnyOfGrantorAnyOfIdentityVerificationStatus];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const FraudKnownBadSubmissionsSearchResponseDataItemEntityAnyOfSetupSubmissionStructureTrustAnyOfGrantorAnyOfIdentityVerificationStatus =
  {
    pending_verification: "pending_verification",
    update_pending_verification: "update_pending_verification",
    verified: "verified",
    falsified: "falsified",
    no_match: "no_match",
    verifying: "verifying",
    requires_attention: "requires_attention",
    not_performed: "not_performed",
    skipped: "skipped",
  } as const;

export type FraudKnownBadSubmissionsSearchResponseDataItemEntityAnyOfSetupSubmissionStructureTrustAnyOfGrantorAnyOfIdentityDocumentSubmissionsItemSubmittedState =
  string | null;

export type FraudKnownBadSubmissionsSearchResponseDataItemEntityAnyOfSetupSubmissionStructureTrustAnyOfGrantorAnyOfIdentityDocumentSubmissionsItemSubmittedExpirationDate =
  string | null;

export type FraudKnownBadSubmissionsSearchResponseDataItemEntityAnyOfSetupSubmissionStructureTrustAnyOfGrantorAnyOfIdentityDocumentSubmissionsItemSubmittedDocumentType =
  (typeof FraudKnownBadSubmissionsSearchResponseDataItemEntityAnyOfSetupSubmissionStructureTrustAnyOfGrantorAnyOfIdentityDocumentSubmissionsItemSubmittedDocumentType)[keyof typeof FraudKnownBadSubmissionsSearchResponseDataItemEntityAnyOfSetupSubmissionStructureTrustAnyOfGrantorAnyOfIdentityDocumentSubmissionsItemSubmittedDocumentType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const FraudKnownBadSubmissionsSearchResponseDataItemEntityAnyOfSetupSubmissionStructureTrustAnyOfGrantorAnyOfIdentityDocumentSubmissionsItemSubmittedDocumentType =
  {
    passport: "passport",
    us_drivers_license: "us_drivers_license",
    other: "other",
  } as const;

export type FraudKnownBadSubmissionsSearchResponseDataItemEntityAnyOfSetupSubmissionStructureTrustAnyOfGrantorAnyOfIdentityDocumentSubmissionsItemSubmittedDocumentNumber =
  string | null;

export type FraudKnownBadSubmissionsSearchResponseDataItemEntityAnyOfSetupSubmissionStructureTrustAnyOfGrantorAnyOfIdentityDocumentSubmissionsItemSubmittedAddressLine2 =
  string | null;

export type FraudKnownBadSubmissionsSearchResponseDataItemEntityAnyOfSetupSubmissionStructureTrustAnyOfGrantorAnyOfIdentityDocumentSubmissionsItemSubmittedAddress =
  {
    city: string;
    line1: string;
    line2: FraudKnownBadSubmissionsSearchResponseDataItemEntityAnyOfSetupSubmissionStructureTrustAnyOfGrantorAnyOfIdentityDocumentSubmissionsItemSubmittedAddressLine2;
    state: string;
    zip: string;
  };

export type FraudKnownBadSubmissionsSearchResponseDataItemEntityAnyOfSetupSubmissionStructureTrustAnyOfGrantorAnyOfIdentityDocumentSubmissionsItemIdentityVerificationStatus =
  (typeof FraudKnownBadSubmissionsSearchResponseDataItemEntityAnyOfSetupSubmissionStructureTrustAnyOfGrantorAnyOfIdentityDocumentSubmissionsItemIdentityVerificationStatus)[keyof typeof FraudKnownBadSubmissionsSearchResponseDataItemEntityAnyOfSetupSubmissionStructureTrustAnyOfGrantorAnyOfIdentityDocumentSubmissionsItemIdentityVerificationStatus];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const FraudKnownBadSubmissionsSearchResponseDataItemEntityAnyOfSetupSubmissionStructureTrustAnyOfGrantorAnyOfIdentityDocumentSubmissionsItemIdentityVerificationStatus =
  {
    pending_verification: "pending_verification",
    update_pending_verification: "update_pending_verification",
    verified: "verified",
    falsified: "falsified",
    no_match: "no_match",
    verifying: "verifying",
    requires_attention: "requires_attention",
    not_performed: "not_performed",
    skipped: "skipped",
  } as const;

export type FraudKnownBadSubmissionsSearchResponseDataItemEntityAnyOfSetupSubmissionStructureTrustAnyOfGrantorAnyOfIdentityDocumentSubmissionsItemApiBackFileId =
  string | null;

export type FraudKnownBadSubmissionsSearchResponseDataItemEntityAnyOfSetupSubmissionStructureTrustAnyOfGrantorAnyOfIdentityDocumentSubmissionsItem =
  {
    api_back_file_id: FraudKnownBadSubmissionsSearchResponseDataItemEntityAnyOfSetupSubmissionStructureTrustAnyOfGrantorAnyOfIdentityDocumentSubmissionsItemApiBackFileId;
    api_file_id: string;
    created_at: string;
    group_id: string;
    id: string;
    identity_verification_status: FraudKnownBadSubmissionsSearchResponseDataItemEntityAnyOfSetupSubmissionStructureTrustAnyOfGrantorAnyOfIdentityDocumentSubmissionsItemIdentityVerificationStatus;
    submitted_address: FraudKnownBadSubmissionsSearchResponseDataItemEntityAnyOfSetupSubmissionStructureTrustAnyOfGrantorAnyOfIdentityDocumentSubmissionsItemSubmittedAddress;
    submitted_country: string;
    submitted_date_of_birth: string;
    submitted_document_number: FraudKnownBadSubmissionsSearchResponseDataItemEntityAnyOfSetupSubmissionStructureTrustAnyOfGrantorAnyOfIdentityDocumentSubmissionsItemSubmittedDocumentNumber;
    submitted_document_type: FraudKnownBadSubmissionsSearchResponseDataItemEntityAnyOfSetupSubmissionStructureTrustAnyOfGrantorAnyOfIdentityDocumentSubmissionsItemSubmittedDocumentType;
    submitted_expiration_date: FraudKnownBadSubmissionsSearchResponseDataItemEntityAnyOfSetupSubmissionStructureTrustAnyOfGrantorAnyOfIdentityDocumentSubmissionsItemSubmittedExpirationDate;
    submitted_name: string;
    submitted_state: FraudKnownBadSubmissionsSearchResponseDataItemEntityAnyOfSetupSubmissionStructureTrustAnyOfGrantorAnyOfIdentityDocumentSubmissionsItemSubmittedState;
  };

export type FraudKnownBadSubmissionsSearchResponseDataItemEntityAnyOfSetupSubmissionStructureNaturalPerson =
  FraudKnownBadSubmissionsSearchResponseDataItemEntityAnyOfSetupSubmissionStructureNaturalPersonAnyOf | null;

export type FraudKnownBadSubmissionsSearchResponseDataItemEntityAnyOfSetupSubmissionStructureNaturalPersonAnyOfTaxIdValidationStatus =
  (typeof FraudKnownBadSubmissionsSearchResponseDataItemEntityAnyOfSetupSubmissionStructureNaturalPersonAnyOfTaxIdValidationStatus)[keyof typeof FraudKnownBadSubmissionsSearchResponseDataItemEntityAnyOfSetupSubmissionStructureNaturalPersonAnyOfTaxIdValidationStatus];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const FraudKnownBadSubmissionsSearchResponseDataItemEntityAnyOfSetupSubmissionStructureNaturalPersonAnyOfTaxIdValidationStatus =
  {
    pending_validating: "pending_validating",
    update_pending_validating: "update_pending_validating",
    requires_attention: "requires_attention",
    failed: "failed",
    validated: "validated",
    not_provided: "not_provided",
    skipped: "skipped",
  } as const;

export type FraudKnownBadSubmissionsSearchResponseDataItemEntityAnyOfSetupSubmissionStructureNaturalPersonAnyOfSubmittedIdentificationMethod =
  (typeof FraudKnownBadSubmissionsSearchResponseDataItemEntityAnyOfSetupSubmissionStructureNaturalPersonAnyOfSubmittedIdentificationMethod)[keyof typeof FraudKnownBadSubmissionsSearchResponseDataItemEntityAnyOfSetupSubmissionStructureNaturalPersonAnyOfSubmittedIdentificationMethod];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const FraudKnownBadSubmissionsSearchResponseDataItemEntityAnyOfSetupSubmissionStructureNaturalPersonAnyOfSubmittedIdentificationMethod =
  {
    social_security_number: "social_security_number",
    individual_taxpayer_identification_number:
      "individual_taxpayer_identification_number",
    passport: "passport",
    drivers_license: "drivers_license",
    other: "other",
  } as const;

export type FraudKnownBadSubmissionsSearchResponseDataItemEntityAnyOfSetupSubmissionStructureNaturalPersonAnyOfSubmittedAddressLine2 =
  string | null;

export type FraudKnownBadSubmissionsSearchResponseDataItemEntityAnyOfSetupSubmissionStructureNaturalPersonAnyOfSubmittedAddress =
  {
    city: string;
    line1: string;
    line2: FraudKnownBadSubmissionsSearchResponseDataItemEntityAnyOfSetupSubmissionStructureNaturalPersonAnyOfSubmittedAddressLine2;
    state: string;
    zip: string;
  };

export type FraudKnownBadSubmissionsSearchResponseDataItemEntityAnyOfSetupSubmissionStructureNaturalPersonAnyOfSubmissionSovosResponseId =
  string | null;

export type FraudKnownBadSubmissionsSearchResponseDataItemEntityAnyOfSetupSubmissionStructureNaturalPersonAnyOf =
  {
    created_at: string;
    id: string;
    identity_document_submissions: FraudKnownBadSubmissionsSearchResponseDataItemEntityAnyOfSetupSubmissionStructureNaturalPersonAnyOfIdentityDocumentSubmissionsItem[];
    identity_submitted_at: string;
    identity_verification_status: FraudKnownBadSubmissionsSearchResponseDataItemEntityAnyOfSetupSubmissionStructureNaturalPersonAnyOfIdentityVerificationStatus;
    microbilt: FraudKnownBadSubmissionsSearchResponseDataItemEntityAnyOfSetupSubmissionStructureNaturalPersonAnyOfMicrobiltItem[];
    submission_sovos_response_id: FraudKnownBadSubmissionsSearchResponseDataItemEntityAnyOfSetupSubmissionStructureNaturalPersonAnyOfSubmissionSovosResponseId;
    submitted_address: FraudKnownBadSubmissionsSearchResponseDataItemEntityAnyOfSetupSubmissionStructureNaturalPersonAnyOfSubmittedAddress;
    submitted_date_of_birth: string;
    submitted_identification_method: FraudKnownBadSubmissionsSearchResponseDataItemEntityAnyOfSetupSubmissionStructureNaturalPersonAnyOfSubmittedIdentificationMethod;
    submitted_identification_number_last4: string;
    submitted_name: string;
    tax_id_validation_status: FraudKnownBadSubmissionsSearchResponseDataItemEntityAnyOfSetupSubmissionStructureNaturalPersonAnyOfTaxIdValidationStatus;
  };

export type FraudKnownBadSubmissionsSearchResponseDataItemEntityAnyOfSetupSubmissionStructureNaturalPersonAnyOfMicrobiltItemTaxIdType =
  "SSN" | null;

export type FraudKnownBadSubmissionsSearchResponseDataItemEntityAnyOfSetupSubmissionStructureNaturalPersonAnyOfMicrobiltItemTaxIdLast4 =
  string | null;

export type FraudKnownBadSubmissionsSearchResponseDataItemEntityAnyOfSetupSubmissionStructureNaturalPersonAnyOfMicrobiltItemReturnCode =
  (typeof FraudKnownBadSubmissionsSearchResponseDataItemEntityAnyOfSetupSubmissionStructureNaturalPersonAnyOfMicrobiltItemReturnCode)[keyof typeof FraudKnownBadSubmissionsSearchResponseDataItemEntityAnyOfSetupSubmissionStructureNaturalPersonAnyOfMicrobiltItemReturnCode];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const FraudKnownBadSubmissionsSearchResponseDataItemEntityAnyOfSetupSubmissionStructureNaturalPersonAnyOfMicrobiltItemReturnCode =
  {
    OK: "OK",
  } as const;

export type FraudKnownBadSubmissionsSearchResponseDataItemEntityAnyOfSetupSubmissionStructureNaturalPersonAnyOfMicrobiltItemMiddleName =
  string | null;

export type FraudKnownBadSubmissionsSearchResponseDataItemEntityAnyOfSetupSubmissionStructureNaturalPersonAnyOfMicrobiltItemDeathDate =
  string | null;

export type FraudKnownBadSubmissionsSearchResponseDataItemEntityAnyOfSetupSubmissionStructureNaturalPersonAnyOfMicrobiltItemBirthDate =
  string | null;

export type FraudKnownBadSubmissionsSearchResponseDataItemEntityAnyOfSetupSubmissionStructureNaturalPersonAnyOfMicrobiltItem =
  {
    birth_date: FraudKnownBadSubmissionsSearchResponseDataItemEntityAnyOfSetupSubmissionStructureNaturalPersonAnyOfMicrobiltItemBirthDate;
    created_at: string;
    death_date: FraudKnownBadSubmissionsSearchResponseDataItemEntityAnyOfSetupSubmissionStructureNaturalPersonAnyOfMicrobiltItemDeathDate;
    first_name: string;
    id: string;
    last_name: string;
    middle_name: FraudKnownBadSubmissionsSearchResponseDataItemEntityAnyOfSetupSubmissionStructureNaturalPersonAnyOfMicrobiltItemMiddleName;
    request_id: string;
    response: string;
    return_code: FraudKnownBadSubmissionsSearchResponseDataItemEntityAnyOfSetupSubmissionStructureNaturalPersonAnyOfMicrobiltItemReturnCode;
    tax_id_last_4: FraudKnownBadSubmissionsSearchResponseDataItemEntityAnyOfSetupSubmissionStructureNaturalPersonAnyOfMicrobiltItemTaxIdLast4;
    tax_id_type: FraudKnownBadSubmissionsSearchResponseDataItemEntityAnyOfSetupSubmissionStructureNaturalPersonAnyOfMicrobiltItemTaxIdType;
  };

export type FraudKnownBadSubmissionsSearchResponseDataItemEntityAnyOfSetupSubmissionStructureNaturalPersonAnyOfIdentityVerificationStatus =
  (typeof FraudKnownBadSubmissionsSearchResponseDataItemEntityAnyOfSetupSubmissionStructureNaturalPersonAnyOfIdentityVerificationStatus)[keyof typeof FraudKnownBadSubmissionsSearchResponseDataItemEntityAnyOfSetupSubmissionStructureNaturalPersonAnyOfIdentityVerificationStatus];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const FraudKnownBadSubmissionsSearchResponseDataItemEntityAnyOfSetupSubmissionStructureNaturalPersonAnyOfIdentityVerificationStatus =
  {
    pending_verification: "pending_verification",
    update_pending_verification: "update_pending_verification",
    verified: "verified",
    falsified: "falsified",
    no_match: "no_match",
    verifying: "verifying",
    requires_attention: "requires_attention",
    not_performed: "not_performed",
    skipped: "skipped",
  } as const;

export type FraudKnownBadSubmissionsSearchResponseDataItemEntityAnyOfSetupSubmissionStructureNaturalPersonAnyOfIdentityDocumentSubmissionsItemSubmittedState =
  string | null;

export type FraudKnownBadSubmissionsSearchResponseDataItemEntityAnyOfSetupSubmissionStructureNaturalPersonAnyOfIdentityDocumentSubmissionsItemSubmittedExpirationDate =
  string | null;

export type FraudKnownBadSubmissionsSearchResponseDataItemEntityAnyOfSetupSubmissionStructureNaturalPersonAnyOfIdentityDocumentSubmissionsItemSubmittedDocumentType =
  (typeof FraudKnownBadSubmissionsSearchResponseDataItemEntityAnyOfSetupSubmissionStructureNaturalPersonAnyOfIdentityDocumentSubmissionsItemSubmittedDocumentType)[keyof typeof FraudKnownBadSubmissionsSearchResponseDataItemEntityAnyOfSetupSubmissionStructureNaturalPersonAnyOfIdentityDocumentSubmissionsItemSubmittedDocumentType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const FraudKnownBadSubmissionsSearchResponseDataItemEntityAnyOfSetupSubmissionStructureNaturalPersonAnyOfIdentityDocumentSubmissionsItemSubmittedDocumentType =
  {
    passport: "passport",
    us_drivers_license: "us_drivers_license",
    other: "other",
  } as const;

export type FraudKnownBadSubmissionsSearchResponseDataItemEntityAnyOfSetupSubmissionStructureNaturalPersonAnyOfIdentityDocumentSubmissionsItemSubmittedDocumentNumber =
  string | null;

export type FraudKnownBadSubmissionsSearchResponseDataItemEntityAnyOfSetupSubmissionStructureNaturalPersonAnyOfIdentityDocumentSubmissionsItem =
  {
    api_back_file_id: FraudKnownBadSubmissionsSearchResponseDataItemEntityAnyOfSetupSubmissionStructureNaturalPersonAnyOfIdentityDocumentSubmissionsItemApiBackFileId;
    api_file_id: string;
    created_at: string;
    group_id: string;
    id: string;
    identity_verification_status: FraudKnownBadSubmissionsSearchResponseDataItemEntityAnyOfSetupSubmissionStructureNaturalPersonAnyOfIdentityDocumentSubmissionsItemIdentityVerificationStatus;
    submitted_address: FraudKnownBadSubmissionsSearchResponseDataItemEntityAnyOfSetupSubmissionStructureNaturalPersonAnyOfIdentityDocumentSubmissionsItemSubmittedAddress;
    submitted_country: string;
    submitted_date_of_birth: string;
    submitted_document_number: FraudKnownBadSubmissionsSearchResponseDataItemEntityAnyOfSetupSubmissionStructureNaturalPersonAnyOfIdentityDocumentSubmissionsItemSubmittedDocumentNumber;
    submitted_document_type: FraudKnownBadSubmissionsSearchResponseDataItemEntityAnyOfSetupSubmissionStructureNaturalPersonAnyOfIdentityDocumentSubmissionsItemSubmittedDocumentType;
    submitted_expiration_date: FraudKnownBadSubmissionsSearchResponseDataItemEntityAnyOfSetupSubmissionStructureNaturalPersonAnyOfIdentityDocumentSubmissionsItemSubmittedExpirationDate;
    submitted_name: string;
    submitted_state: FraudKnownBadSubmissionsSearchResponseDataItemEntityAnyOfSetupSubmissionStructureNaturalPersonAnyOfIdentityDocumentSubmissionsItemSubmittedState;
  };

export type FraudKnownBadSubmissionsSearchResponseDataItemEntityAnyOfSetupSubmissionStructureNaturalPersonAnyOfIdentityDocumentSubmissionsItemSubmittedAddressLine2 =
  string | null;

export type FraudKnownBadSubmissionsSearchResponseDataItemEntityAnyOfSetupSubmissionStructureNaturalPersonAnyOfIdentityDocumentSubmissionsItemSubmittedAddress =
  {
    city: string;
    line1: string;
    line2: FraudKnownBadSubmissionsSearchResponseDataItemEntityAnyOfSetupSubmissionStructureNaturalPersonAnyOfIdentityDocumentSubmissionsItemSubmittedAddressLine2;
    state: string;
    zip: string;
  };

export type FraudKnownBadSubmissionsSearchResponseDataItemEntityAnyOfSetupSubmissionStructureNaturalPersonAnyOfIdentityDocumentSubmissionsItemIdentityVerificationStatus =
  (typeof FraudKnownBadSubmissionsSearchResponseDataItemEntityAnyOfSetupSubmissionStructureNaturalPersonAnyOfIdentityDocumentSubmissionsItemIdentityVerificationStatus)[keyof typeof FraudKnownBadSubmissionsSearchResponseDataItemEntityAnyOfSetupSubmissionStructureNaturalPersonAnyOfIdentityDocumentSubmissionsItemIdentityVerificationStatus];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const FraudKnownBadSubmissionsSearchResponseDataItemEntityAnyOfSetupSubmissionStructureNaturalPersonAnyOfIdentityDocumentSubmissionsItemIdentityVerificationStatus =
  {
    pending_verification: "pending_verification",
    update_pending_verification: "update_pending_verification",
    verified: "verified",
    falsified: "falsified",
    no_match: "no_match",
    verifying: "verifying",
    requires_attention: "requires_attention",
    not_performed: "not_performed",
    skipped: "skipped",
  } as const;

export type FraudKnownBadSubmissionsSearchResponseDataItemEntityAnyOfSetupSubmissionStructureNaturalPersonAnyOfIdentityDocumentSubmissionsItemApiBackFileId =
  string | null;

export type FraudKnownBadSubmissionsSearchResponseDataItemEntityAnyOfSetupSubmissionStructureJointAnyOfItemTaxIdValidationStatus =
  (typeof FraudKnownBadSubmissionsSearchResponseDataItemEntityAnyOfSetupSubmissionStructureJointAnyOfItemTaxIdValidationStatus)[keyof typeof FraudKnownBadSubmissionsSearchResponseDataItemEntityAnyOfSetupSubmissionStructureJointAnyOfItemTaxIdValidationStatus];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const FraudKnownBadSubmissionsSearchResponseDataItemEntityAnyOfSetupSubmissionStructureJointAnyOfItemTaxIdValidationStatus =
  {
    pending_validating: "pending_validating",
    update_pending_validating: "update_pending_validating",
    requires_attention: "requires_attention",
    failed: "failed",
    validated: "validated",
    not_provided: "not_provided",
    skipped: "skipped",
  } as const;

export type FraudKnownBadSubmissionsSearchResponseDataItemEntityAnyOfSetupSubmissionStructureJointAnyOfItemSubmittedIdentificationMethod =
  (typeof FraudKnownBadSubmissionsSearchResponseDataItemEntityAnyOfSetupSubmissionStructureJointAnyOfItemSubmittedIdentificationMethod)[keyof typeof FraudKnownBadSubmissionsSearchResponseDataItemEntityAnyOfSetupSubmissionStructureJointAnyOfItemSubmittedIdentificationMethod];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const FraudKnownBadSubmissionsSearchResponseDataItemEntityAnyOfSetupSubmissionStructureJointAnyOfItemSubmittedIdentificationMethod =
  {
    social_security_number: "social_security_number",
    individual_taxpayer_identification_number:
      "individual_taxpayer_identification_number",
    passport: "passport",
    drivers_license: "drivers_license",
    other: "other",
  } as const;

export type FraudKnownBadSubmissionsSearchResponseDataItemEntityAnyOfSetupSubmissionStructureJointAnyOfItem =
  {
    created_at: string;
    id: string;
    identity_document_submissions: FraudKnownBadSubmissionsSearchResponseDataItemEntityAnyOfSetupSubmissionStructureJointAnyOfItemIdentityDocumentSubmissionsItem[];
    identity_submitted_at: string;
    identity_verification_status: FraudKnownBadSubmissionsSearchResponseDataItemEntityAnyOfSetupSubmissionStructureJointAnyOfItemIdentityVerificationStatus;
    microbilt: FraudKnownBadSubmissionsSearchResponseDataItemEntityAnyOfSetupSubmissionStructureJointAnyOfItemMicrobiltItem[];
    submission_sovos_response_id: FraudKnownBadSubmissionsSearchResponseDataItemEntityAnyOfSetupSubmissionStructureJointAnyOfItemSubmissionSovosResponseId;
    submitted_address: FraudKnownBadSubmissionsSearchResponseDataItemEntityAnyOfSetupSubmissionStructureJointAnyOfItemSubmittedAddress;
    submitted_date_of_birth: string;
    submitted_identification_method: FraudKnownBadSubmissionsSearchResponseDataItemEntityAnyOfSetupSubmissionStructureJointAnyOfItemSubmittedIdentificationMethod;
    submitted_identification_number_last4: string;
    submitted_name: string;
    tax_id_validation_status: FraudKnownBadSubmissionsSearchResponseDataItemEntityAnyOfSetupSubmissionStructureJointAnyOfItemTaxIdValidationStatus;
  };

export type FraudKnownBadSubmissionsSearchResponseDataItemEntityAnyOfSetupSubmissionStructureJoint =

    | FraudKnownBadSubmissionsSearchResponseDataItemEntityAnyOfSetupSubmissionStructureJointAnyOfItem[]
    | null;

export type FraudKnownBadSubmissionsSearchResponseDataItemEntityAnyOfSetupSubmissionStructureJointAnyOfItemSubmittedAddressLine2 =
  string | null;

export type FraudKnownBadSubmissionsSearchResponseDataItemEntityAnyOfSetupSubmissionStructureJointAnyOfItemSubmittedAddress =
  {
    city: string;
    line1: string;
    line2: FraudKnownBadSubmissionsSearchResponseDataItemEntityAnyOfSetupSubmissionStructureJointAnyOfItemSubmittedAddressLine2;
    state: string;
    zip: string;
  };

export type FraudKnownBadSubmissionsSearchResponseDataItemEntityAnyOfSetupSubmissionStructureJointAnyOfItemSubmissionSovosResponseId =
  string | null;

export type FraudKnownBadSubmissionsSearchResponseDataItemEntityAnyOfSetupSubmissionStructureJointAnyOfItemMicrobiltItemTaxIdType =
  "SSN" | null;

export type FraudKnownBadSubmissionsSearchResponseDataItemEntityAnyOfSetupSubmissionStructureJointAnyOfItemMicrobiltItemTaxIdLast4 =
  string | null;

export type FraudKnownBadSubmissionsSearchResponseDataItemEntityAnyOfSetupSubmissionStructureJointAnyOfItemMicrobiltItemReturnCode =
  (typeof FraudKnownBadSubmissionsSearchResponseDataItemEntityAnyOfSetupSubmissionStructureJointAnyOfItemMicrobiltItemReturnCode)[keyof typeof FraudKnownBadSubmissionsSearchResponseDataItemEntityAnyOfSetupSubmissionStructureJointAnyOfItemMicrobiltItemReturnCode];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const FraudKnownBadSubmissionsSearchResponseDataItemEntityAnyOfSetupSubmissionStructureJointAnyOfItemMicrobiltItemReturnCode =
  {
    OK: "OK",
  } as const;

export type FraudKnownBadSubmissionsSearchResponseDataItemEntityAnyOfSetupSubmissionStructureJointAnyOfItemMicrobiltItemMiddleName =
  string | null;

export type FraudKnownBadSubmissionsSearchResponseDataItemEntityAnyOfSetupSubmissionStructureJointAnyOfItemMicrobiltItemDeathDate =
  string | null;

export type FraudKnownBadSubmissionsSearchResponseDataItemEntityAnyOfSetupSubmissionStructureJointAnyOfItemMicrobiltItemBirthDate =
  string | null;

export type FraudKnownBadSubmissionsSearchResponseDataItemEntityAnyOfSetupSubmissionStructureJointAnyOfItemMicrobiltItem =
  {
    birth_date: FraudKnownBadSubmissionsSearchResponseDataItemEntityAnyOfSetupSubmissionStructureJointAnyOfItemMicrobiltItemBirthDate;
    created_at: string;
    death_date: FraudKnownBadSubmissionsSearchResponseDataItemEntityAnyOfSetupSubmissionStructureJointAnyOfItemMicrobiltItemDeathDate;
    first_name: string;
    id: string;
    last_name: string;
    middle_name: FraudKnownBadSubmissionsSearchResponseDataItemEntityAnyOfSetupSubmissionStructureJointAnyOfItemMicrobiltItemMiddleName;
    request_id: string;
    response: string;
    return_code: FraudKnownBadSubmissionsSearchResponseDataItemEntityAnyOfSetupSubmissionStructureJointAnyOfItemMicrobiltItemReturnCode;
    tax_id_last_4: FraudKnownBadSubmissionsSearchResponseDataItemEntityAnyOfSetupSubmissionStructureJointAnyOfItemMicrobiltItemTaxIdLast4;
    tax_id_type: FraudKnownBadSubmissionsSearchResponseDataItemEntityAnyOfSetupSubmissionStructureJointAnyOfItemMicrobiltItemTaxIdType;
  };

export type FraudKnownBadSubmissionsSearchResponseDataItemEntityAnyOfSetupSubmissionStructureJointAnyOfItemIdentityVerificationStatus =
  (typeof FraudKnownBadSubmissionsSearchResponseDataItemEntityAnyOfSetupSubmissionStructureJointAnyOfItemIdentityVerificationStatus)[keyof typeof FraudKnownBadSubmissionsSearchResponseDataItemEntityAnyOfSetupSubmissionStructureJointAnyOfItemIdentityVerificationStatus];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const FraudKnownBadSubmissionsSearchResponseDataItemEntityAnyOfSetupSubmissionStructureJointAnyOfItemIdentityVerificationStatus =
  {
    pending_verification: "pending_verification",
    update_pending_verification: "update_pending_verification",
    verified: "verified",
    falsified: "falsified",
    no_match: "no_match",
    verifying: "verifying",
    requires_attention: "requires_attention",
    not_performed: "not_performed",
    skipped: "skipped",
  } as const;

export type FraudKnownBadSubmissionsSearchResponseDataItemEntityAnyOfSetupSubmissionStructureJointAnyOfItemIdentityDocumentSubmissionsItemSubmittedState =
  string | null;

export type FraudKnownBadSubmissionsSearchResponseDataItemEntityAnyOfSetupSubmissionStructureJointAnyOfItemIdentityDocumentSubmissionsItemSubmittedExpirationDate =
  string | null;

export type FraudKnownBadSubmissionsSearchResponseDataItemEntityAnyOfSetupSubmissionStructureJointAnyOfItemIdentityDocumentSubmissionsItemSubmittedDocumentType =
  (typeof FraudKnownBadSubmissionsSearchResponseDataItemEntityAnyOfSetupSubmissionStructureJointAnyOfItemIdentityDocumentSubmissionsItemSubmittedDocumentType)[keyof typeof FraudKnownBadSubmissionsSearchResponseDataItemEntityAnyOfSetupSubmissionStructureJointAnyOfItemIdentityDocumentSubmissionsItemSubmittedDocumentType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const FraudKnownBadSubmissionsSearchResponseDataItemEntityAnyOfSetupSubmissionStructureJointAnyOfItemIdentityDocumentSubmissionsItemSubmittedDocumentType =
  {
    passport: "passport",
    us_drivers_license: "us_drivers_license",
    other: "other",
  } as const;

export type FraudKnownBadSubmissionsSearchResponseDataItemEntityAnyOfSetupSubmissionStructureJointAnyOfItemIdentityDocumentSubmissionsItemSubmittedDocumentNumber =
  string | null;

export type FraudKnownBadSubmissionsSearchResponseDataItemEntityAnyOfSetupSubmissionStructureJointAnyOfItemIdentityDocumentSubmissionsItemSubmittedAddressLine2 =
  string | null;

export type FraudKnownBadSubmissionsSearchResponseDataItemEntityAnyOfSetupSubmissionStructureJointAnyOfItemIdentityDocumentSubmissionsItemSubmittedAddress =
  {
    city: string;
    line1: string;
    line2: FraudKnownBadSubmissionsSearchResponseDataItemEntityAnyOfSetupSubmissionStructureJointAnyOfItemIdentityDocumentSubmissionsItemSubmittedAddressLine2;
    state: string;
    zip: string;
  };

export type FraudKnownBadSubmissionsSearchResponseDataItemEntityAnyOfSetupSubmissionStructureJointAnyOfItemIdentityDocumentSubmissionsItemIdentityVerificationStatus =
  (typeof FraudKnownBadSubmissionsSearchResponseDataItemEntityAnyOfSetupSubmissionStructureJointAnyOfItemIdentityDocumentSubmissionsItemIdentityVerificationStatus)[keyof typeof FraudKnownBadSubmissionsSearchResponseDataItemEntityAnyOfSetupSubmissionStructureJointAnyOfItemIdentityDocumentSubmissionsItemIdentityVerificationStatus];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const FraudKnownBadSubmissionsSearchResponseDataItemEntityAnyOfSetupSubmissionStructureJointAnyOfItemIdentityDocumentSubmissionsItemIdentityVerificationStatus =
  {
    pending_verification: "pending_verification",
    update_pending_verification: "update_pending_verification",
    verified: "verified",
    falsified: "falsified",
    no_match: "no_match",
    verifying: "verifying",
    requires_attention: "requires_attention",
    not_performed: "not_performed",
    skipped: "skipped",
  } as const;

export type FraudKnownBadSubmissionsSearchResponseDataItemEntityAnyOfSetupSubmissionStructureJointAnyOfItemIdentityDocumentSubmissionsItemApiBackFileId =
  string | null;

export type FraudKnownBadSubmissionsSearchResponseDataItemEntityAnyOfSetupSubmissionStructureJointAnyOfItemIdentityDocumentSubmissionsItem =
  {
    api_back_file_id: FraudKnownBadSubmissionsSearchResponseDataItemEntityAnyOfSetupSubmissionStructureJointAnyOfItemIdentityDocumentSubmissionsItemApiBackFileId;
    api_file_id: string;
    created_at: string;
    group_id: string;
    id: string;
    identity_verification_status: FraudKnownBadSubmissionsSearchResponseDataItemEntityAnyOfSetupSubmissionStructureJointAnyOfItemIdentityDocumentSubmissionsItemIdentityVerificationStatus;
    submitted_address: FraudKnownBadSubmissionsSearchResponseDataItemEntityAnyOfSetupSubmissionStructureJointAnyOfItemIdentityDocumentSubmissionsItemSubmittedAddress;
    submitted_country: string;
    submitted_date_of_birth: string;
    submitted_document_number: FraudKnownBadSubmissionsSearchResponseDataItemEntityAnyOfSetupSubmissionStructureJointAnyOfItemIdentityDocumentSubmissionsItemSubmittedDocumentNumber;
    submitted_document_type: FraudKnownBadSubmissionsSearchResponseDataItemEntityAnyOfSetupSubmissionStructureJointAnyOfItemIdentityDocumentSubmissionsItemSubmittedDocumentType;
    submitted_expiration_date: FraudKnownBadSubmissionsSearchResponseDataItemEntityAnyOfSetupSubmissionStructureJointAnyOfItemIdentityDocumentSubmissionsItemSubmittedExpirationDate;
    submitted_name: string;
    submitted_state: FraudKnownBadSubmissionsSearchResponseDataItemEntityAnyOfSetupSubmissionStructureJointAnyOfItemIdentityDocumentSubmissionsItemSubmittedState;
  };

export type FraudKnownBadSubmissionsSearchResponseDataItemEntityAnyOfSetupSubmissionStructureGovernmentAuthority =
  FraudKnownBadSubmissionsSearchResponseDataItemEntityAnyOfSetupSubmissionStructureGovernmentAuthorityAnyOf | null;

export type FraudKnownBadSubmissionsSearchResponseDataItemEntityAnyOfSetupSubmissionStructureGovernmentAuthorityAnyOfTaxIdValidationStatus =
  (typeof FraudKnownBadSubmissionsSearchResponseDataItemEntityAnyOfSetupSubmissionStructureGovernmentAuthorityAnyOfTaxIdValidationStatus)[keyof typeof FraudKnownBadSubmissionsSearchResponseDataItemEntityAnyOfSetupSubmissionStructureGovernmentAuthorityAnyOfTaxIdValidationStatus];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const FraudKnownBadSubmissionsSearchResponseDataItemEntityAnyOfSetupSubmissionStructureGovernmentAuthorityAnyOfTaxIdValidationStatus =
  {
    pending_validating: "pending_validating",
    update_pending_validating: "update_pending_validating",
    requires_attention: "requires_attention",
    failed: "failed",
    validated: "validated",
    not_provided: "not_provided",
    skipped: "skipped",
  } as const;

export type FraudKnownBadSubmissionsSearchResponseDataItemEntityAnyOfSetupSubmissionStructureGovernmentAuthorityAnyOfSubmittedWebsite =
  string | null;

export type FraudKnownBadSubmissionsSearchResponseDataItemEntityAnyOfSetupSubmissionStructureGovernmentAuthorityAnyOfSubmittedTaxId =
  string | null;

export type FraudKnownBadSubmissionsSearchResponseDataItemEntityAnyOfSetupSubmissionStructureGovernmentAuthorityAnyOfSubmittedAddressLine2 =
  string | null;

export type FraudKnownBadSubmissionsSearchResponseDataItemEntityAnyOfSetupSubmissionStructureGovernmentAuthorityAnyOfSubmittedAddress =
  {
    city: string;
    line1: string;
    line2: FraudKnownBadSubmissionsSearchResponseDataItemEntityAnyOfSetupSubmissionStructureGovernmentAuthorityAnyOfSubmittedAddressLine2;
    state: string;
    zip: string;
  };

export type FraudKnownBadSubmissionsSearchResponseDataItemEntityAnyOfSetupSubmissionStructureGovernmentAuthorityAnyOfEntitySetupGovernmentAuthorizedPersonRelationshipsItemEntitySetupGovernmentAuthorizedPersonSubmission =
  {
    id: string;
    submitted_name: string;
  };

export type FraudKnownBadSubmissionsSearchResponseDataItemEntityAnyOfSetupSubmissionStructureGovernmentAuthorityAnyOfEntitySetupGovernmentAuthorizedPersonRelationshipsItem =
  {
    archived: boolean;
    entity_setup_government_authority_submission_id: string;
    entity_setup_government_authorized_person_submission: FraudKnownBadSubmissionsSearchResponseDataItemEntityAnyOfSetupSubmissionStructureGovernmentAuthorityAnyOfEntitySetupGovernmentAuthorizedPersonRelationshipsItemEntitySetupGovernmentAuthorizedPersonSubmission;
    id: string;
  };

export type FraudKnownBadSubmissionsSearchResponseDataItemEntityAnyOfSetupSubmissionStructureGovernmentAuthorityAnyOf =
  {
    entity_setup_government_authorized_person_relationships: FraudKnownBadSubmissionsSearchResponseDataItemEntityAnyOfSetupSubmissionStructureGovernmentAuthorityAnyOfEntitySetupGovernmentAuthorizedPersonRelationshipsItem[];
    id: string;
    submitted_address: FraudKnownBadSubmissionsSearchResponseDataItemEntityAnyOfSetupSubmissionStructureGovernmentAuthorityAnyOfSubmittedAddress;
    submitted_category: string;
    submitted_name: string;
    submitted_tax_id: FraudKnownBadSubmissionsSearchResponseDataItemEntityAnyOfSetupSubmissionStructureGovernmentAuthorityAnyOfSubmittedTaxId;
    submitted_website: FraudKnownBadSubmissionsSearchResponseDataItemEntityAnyOfSetupSubmissionStructureGovernmentAuthorityAnyOfSubmittedWebsite;
    tax_id_validation_status: FraudKnownBadSubmissionsSearchResponseDataItemEntityAnyOfSetupSubmissionStructureGovernmentAuthorityAnyOfTaxIdValidationStatus;
  };

export type FraudKnownBadSubmissionsSearchResponseDataItemEntityAnyOfSetupSubmissionStructureCorporationAnyOfTaxIdValidationStatus =
  (typeof FraudKnownBadSubmissionsSearchResponseDataItemEntityAnyOfSetupSubmissionStructureCorporationAnyOfTaxIdValidationStatus)[keyof typeof FraudKnownBadSubmissionsSearchResponseDataItemEntityAnyOfSetupSubmissionStructureCorporationAnyOfTaxIdValidationStatus];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const FraudKnownBadSubmissionsSearchResponseDataItemEntityAnyOfSetupSubmissionStructureCorporationAnyOfTaxIdValidationStatus =
  {
    pending_validating: "pending_validating",
    update_pending_validating: "update_pending_validating",
    requires_attention: "requires_attention",
    failed: "failed",
    validated: "validated",
    not_provided: "not_provided",
    skipped: "skipped",
  } as const;

export type FraudKnownBadSubmissionsSearchResponseDataItemEntityAnyOfSetupSubmissionStructureCorporationAnyOfSubmittedWebsite =
  string | null;

export type FraudKnownBadSubmissionsSearchResponseDataItemEntityAnyOfSetupSubmissionStructureCorporationAnyOfSubmittedTaxId =
  string | null;

export type FraudKnownBadSubmissionsSearchResponseDataItemEntityAnyOfSetupSubmissionStructureCorporationAnyOfSubmittedState =
  string | null;

export type FraudKnownBadSubmissionsSearchResponseDataItemEntityAnyOfSetupSubmissionStructureCorporationAnyOf =
  {
    beneficial_ownership_exemption: FraudKnownBadSubmissionsSearchResponseDataItemEntityAnyOfSetupSubmissionStructureCorporationAnyOfBeneficialOwnershipExemption;
    cobalt_intelligence_secretary_of_state_search_intents: FraudKnownBadSubmissionsSearchResponseDataItemEntityAnyOfSetupSubmissionStructureCorporationAnyOfCobaltIntelligenceSecretaryOfStateSearchIntentsItem[];
    created_at: string;
    entity_setup_corporation_beneficial_owner_relationships: FraudKnownBadSubmissionsSearchResponseDataItemEntityAnyOfSetupSubmissionStructureCorporationAnyOfEntitySetupCorporationBeneficialOwnerRelationshipsItem[];
    id: string;
    industry_code: FraudKnownBadSubmissionsSearchResponseDataItemEntityAnyOfSetupSubmissionStructureCorporationAnyOfIndustryCode;
    submission_sovos_response_id: FraudKnownBadSubmissionsSearchResponseDataItemEntityAnyOfSetupSubmissionStructureCorporationAnyOfSubmissionSovosResponseId;
    submitted_address: FraudKnownBadSubmissionsSearchResponseDataItemEntityAnyOfSetupSubmissionStructureCorporationAnyOfSubmittedAddress;
    submitted_name: string;
    submitted_state: FraudKnownBadSubmissionsSearchResponseDataItemEntityAnyOfSetupSubmissionStructureCorporationAnyOfSubmittedState;
    submitted_tax_id: FraudKnownBadSubmissionsSearchResponseDataItemEntityAnyOfSetupSubmissionStructureCorporationAnyOfSubmittedTaxId;
    submitted_website: FraudKnownBadSubmissionsSearchResponseDataItemEntityAnyOfSetupSubmissionStructureCorporationAnyOfSubmittedWebsite;
    tax_id_submitted_at: string;
    tax_id_validation_status: FraudKnownBadSubmissionsSearchResponseDataItemEntityAnyOfSetupSubmissionStructureCorporationAnyOfTaxIdValidationStatus;
  };

export type FraudKnownBadSubmissionsSearchResponseDataItemEntityAnyOfSetupSubmissionStructureCorporation =
  FraudKnownBadSubmissionsSearchResponseDataItemEntityAnyOfSetupSubmissionStructureCorporationAnyOf | null;

export type FraudKnownBadSubmissionsSearchResponseDataItemEntityAnyOfSetupSubmissionStructureCorporationAnyOfSubmittedAddressLine2 =
  string | null;

export type FraudKnownBadSubmissionsSearchResponseDataItemEntityAnyOfSetupSubmissionStructureCorporationAnyOfSubmittedAddress =
  {
    city: string;
    line1: string;
    line2: FraudKnownBadSubmissionsSearchResponseDataItemEntityAnyOfSetupSubmissionStructureCorporationAnyOfSubmittedAddressLine2;
    state: string;
    zip: string;
  };

export type FraudKnownBadSubmissionsSearchResponseDataItemEntityAnyOfSetupSubmissionStructureCorporationAnyOfSubmissionSovosResponseId =
  string | null;

export type FraudKnownBadSubmissionsSearchResponseDataItemEntityAnyOfSetupSubmissionStructureCorporationAnyOfIndustryCode =

    | "11"
    | "111"
    | "1111"
    | "11111"
    | "111110"
    | "11112"
    | "111120"
    | "11113"
    | "111130"
    | "11114"
    | "111140"
    | "11115"
    | "111150"
    | "11116"
    | "111160"
    | "11119"
    | "111191"
    | "111199"
    | "1112"
    | "11121"
    | "111211"
    | "111219"
    | "1113"
    | "11131"
    | "111310"
    | "11132"
    | "111320"
    | "11133"
    | "111331"
    | "111332"
    | "111333"
    | "111334"
    | "111335"
    | "111336"
    | "111339"
    | "1114"
    | "11141"
    | "111411"
    | "111419"
    | "11142"
    | "111421"
    | "111422"
    | "1119"
    | "11191"
    | "111910"
    | "11192"
    | "111920"
    | "11193"
    | "111930"
    | "11194"
    | "111940"
    | "11199"
    | "111991"
    | "111992"
    | "111998"
    | "112"
    | "1121"
    | "11211"
    | "112111"
    | "112112"
    | "11212"
    | "112120"
    | "11213"
    | "112130"
    | "1122"
    | "11221"
    | "112210"
    | "1123"
    | "11231"
    | "112310"
    | "11232"
    | "112320"
    | "11233"
    | "112330"
    | "11234"
    | "112340"
    | "11239"
    | "112390"
    | "1124"
    | "11241"
    | "112410"
    | "11242"
    | "112420"
    | "1125"
    | "11251"
    | "112511"
    | "112512"
    | "112519"
    | "1129"
    | "11291"
    | "112910"
    | "11292"
    | "112920"
    | "11293"
    | "112930"
    | "11299"
    | "112990"
    | "113"
    | "1131"
    | "11311"
    | "113110"
    | "1132"
    | "11321"
    | "113210"
    | "1133"
    | "11331"
    | "113310"
    | "114"
    | "1141"
    | "11411"
    | "114111"
    | "114112"
    | "114119"
    | "1142"
    | "11421"
    | "114210"
    | "115"
    | "1151"
    | "11511"
    | "115111"
    | "115112"
    | "115113"
    | "115114"
    | "115115"
    | "115116"
    | "1152"
    | "11521"
    | "115210"
    | "1153"
    | "11531"
    | "115310"
    | "21"
    | "211"
    | "2111"
    | "21112"
    | "211120"
    | "21113"
    | "211130"
    | "212"
    | "2121"
    | "21211"
    | "212114"
    | "212115"
    | "2122"
    | "21221"
    | "212210"
    | "21222"
    | "212220"
    | "21223"
    | "212230"
    | "21229"
    | "212290"
    | "2123"
    | "21231"
    | "212311"
    | "212312"
    | "212313"
    | "212319"
    | "21232"
    | "212321"
    | "212322"
    | "212323"
    | "21239"
    | "212390"
    | "213"
    | "2131"
    | "21311"
    | "213111"
    | "213112"
    | "213113"
    | "213114"
    | "213115"
    | "22"
    | "221"
    | "2211"
    | "22111"
    | "221111"
    | "221112"
    | "221113"
    | "221114"
    | "221115"
    | "221116"
    | "221117"
    | "221118"
    | "22112"
    | "221121"
    | "221122"
    | "2212"
    | "22121"
    | "221210"
    | "2213"
    | "22131"
    | "221310"
    | "22132"
    | "221320"
    | "22133"
    | "221330"
    | "23"
    | "236"
    | "2361"
    | "23611"
    | "236115"
    | "236116"
    | "236117"
    | "236118"
    | "2362"
    | "23621"
    | "236210"
    | "23622"
    | "236220"
    | "237"
    | "2371"
    | "23711"
    | "237110"
    | "23712"
    | "237120"
    | "23713"
    | "237130"
    | "2372"
    | "23721"
    | "237210"
    | "2373"
    | "23731"
    | "237310"
    | "2379"
    | "23799"
    | "237990"
    | "238"
    | "2381"
    | "23811"
    | "238110"
    | "23812"
    | "238120"
    | "23813"
    | "238130"
    | "23814"
    | "238140"
    | "23815"
    | "238150"
    | "23816"
    | "238160"
    | "23817"
    | "238170"
    | "23819"
    | "238190"
    | "2382"
    | "23821"
    | "238210"
    | "23822"
    | "238220"
    | "23829"
    | "238290"
    | "2383"
    | "23831"
    | "238310"
    | "23832"
    | "238320"
    | "23833"
    | "238330"
    | "23834"
    | "238340"
    | "23835"
    | "238350"
    | "23839"
    | "238390"
    | "2389"
    | "23891"
    | "238910"
    | "23899"
    | "238990"
    | "31-33"
    | "311"
    | "3111"
    | "31111"
    | "311111"
    | "311119"
    | "3112"
    | "31121"
    | "311211"
    | "311212"
    | "311213"
    | "31122"
    | "311221"
    | "311224"
    | "311225"
    | "31123"
    | "311230"
    | "3113"
    | "31131"
    | "311313"
    | "311314"
    | "31134"
    | "311340"
    | "31135"
    | "311351"
    | "311352"
    | "3114"
    | "31141"
    | "311411"
    | "311412"
    | "31142"
    | "311421"
    | "311422"
    | "311423"
    | "3115"
    | "31151"
    | "311511"
    | "311512"
    | "311513"
    | "311514"
    | "31152"
    | "311520"
    | "3116"
    | "31161"
    | "311611"
    | "311612"
    | "311613"
    | "311615"
    | "3117"
    | "31171"
    | "311710"
    | "3118"
    | "31181"
    | "311811"
    | "311812"
    | "311813"
    | "31182"
    | "311821"
    | "311824"
    | "31183"
    | "311830"
    | "3119"
    | "31191"
    | "311911"
    | "311919"
    | "31192"
    | "311920"
    | "31193"
    | "311930"
    | "31194"
    | "311941"
    | "311942"
    | "31199"
    | "311991"
    | "311999"
    | "312"
    | "3121"
    | "31211"
    | "312111"
    | "312112"
    | "312113"
    | "31212"
    | "312120"
    | "31213"
    | "312130"
    | "31214"
    | "312140"
    | "3122"
    | "31223"
    | "312230"
    | "313"
    | "3131"
    | "31311"
    | "313110"
    | "3132"
    | "31321"
    | "313210"
    | "31322"
    | "313220"
    | "31323"
    | "313230"
    | "31324"
    | "313240"
    | "3133"
    | "31331"
    | "313310"
    | "31332"
    | "313320"
    | "314"
    | "3141"
    | "31411"
    | "314110"
    | "31412"
    | "314120"
    | "3149"
    | "31491"
    | "314910"
    | "31499"
    | "314994"
    | "314999"
    | "315"
    | "3151"
    | "31512"
    | "315120"
    | "3152"
    | "31521"
    | "315210"
    | "31525"
    | "315250"
    | "3159"
    | "31599"
    | "315990"
    | "316"
    | "3161"
    | "31611"
    | "316110"
    | "3162"
    | "31621"
    | "316210"
    | "3169"
    | "31699"
    | "316990"
    | "321"
    | "3211"
    | "32111"
    | "321113"
    | "321114"
    | "3212"
    | "32121"
    | "321211"
    | "321212"
    | "321215"
    | "321219"
    | "3219"
    | "32191"
    | "321911"
    | "321912"
    | "321918"
    | "32192"
    | "321920"
    | "32199"
    | "321991"
    | "321992"
    | "321999"
    | "322"
    | "3221"
    | "32211"
    | "322110"
    | "32212"
    | "322120"
    | "32213"
    | "322130"
    | "3222"
    | "32221"
    | "322211"
    | "322212"
    | "322219"
    | "32222"
    | "322220"
    | "32223"
    | "322230"
    | "32229"
    | "322291"
    | "322299"
    | "323"
    | "3231"
    | "32311"
    | "323111"
    | "323113"
    | "323117"
    | "32312"
    | "323120"
    | "324"
    | "3241"
    | "32411"
    | "324110"
    | "32412"
    | "324121"
    | "324122"
    | "32419"
    | "324191"
    | "324199"
    | "325"
    | "3251"
    | "32511"
    | "325110"
    | "32512"
    | "325120"
    | "32513"
    | "325130"
    | "32518"
    | "325180"
    | "32519"
    | "325193"
    | "325194"
    | "325199"
    | "3252"
    | "32521"
    | "325211"
    | "325212"
    | "32522"
    | "325220"
    | "3253"
    | "32531"
    | "325311"
    | "325312"
    | "325314"
    | "325315"
    | "32532"
    | "325320"
    | "3254"
    | "32541"
    | "325411"
    | "325412"
    | "325413"
    | "325414"
    | "3255"
    | "32551"
    | "325510"
    | "32552"
    | "325520"
    | "3256"
    | "32561"
    | "325611"
    | "325612"
    | "325613"
    | "32562"
    | "325620"
    | "3259"
    | "32591"
    | "325910"
    | "32592"
    | "325920"
    | "32599"
    | "325991"
    | "325992"
    | "325998"
    | "326"
    | "3261"
    | "32611"
    | "326111"
    | "326112"
    | "326113"
    | "32612"
    | "326121"
    | "326122"
    | "32613"
    | "326130"
    | "32614"
    | "326140"
    | "32615"
    | "326150"
    | "32616"
    | "326160"
    | "32619"
    | "326191"
    | "326199"
    | "3262"
    | "32621"
    | "326211"
    | "326212"
    | "32622"
    | "326220"
    | "32629"
    | "326291"
    | "326299"
    | "327"
    | "3271"
    | "32711"
    | "327110"
    | "32712"
    | "327120"
    | "3272"
    | "32721"
    | "327211"
    | "327212"
    | "327213"
    | "327215"
    | "3273"
    | "32731"
    | "327310"
    | "32732"
    | "327320"
    | "32733"
    | "327331"
    | "327332"
    | "32739"
    | "327390"
    | "3274"
    | "32741"
    | "327410"
    | "32742"
    | "327420"
    | "3279"
    | "32791"
    | "327910"
    | "32799"
    | "327991"
    | "327992"
    | "327993"
    | "327999"
    | "331"
    | "3311"
    | "33111"
    | "331110"
    | "3312"
    | "33121"
    | "331210"
    | "33122"
    | "331221"
    | "331222"
    | "3313"
    | "33131"
    | "331313"
    | "331314"
    | "331315"
    | "331318"
    | "3314"
    | "33141"
    | "331410"
    | "33142"
    | "331420"
    | "33149"
    | "331491"
    | "331492"
    | "3315"
    | "33151"
    | "331511"
    | "331512"
    | "331513"
    | "33152"
    | "331523"
    | "331524"
    | "331529"
    | "332"
    | "3321"
    | "33211"
    | "332111"
    | "332112"
    | "332114"
    | "332117"
    | "332119"
    | "3322"
    | "33221"
    | "332215"
    | "332216"
    | "3323"
    | "33231"
    | "332311"
    | "332312"
    | "332313"
    | "33232"
    | "332321"
    | "332322"
    | "332323"
    | "3324"
    | "33241"
    | "332410"
    | "33242"
    | "332420"
    | "33243"
    | "332431"
    | "332439"
    | "3325"
    | "33251"
    | "332510"
    | "3326"
    | "33261"
    | "332613"
    | "332618"
    | "3327"
    | "33271"
    | "332710"
    | "33272"
    | "332721"
    | "332722"
    | "3328"
    | "33281"
    | "332811"
    | "332812"
    | "332813"
    | "3329"
    | "33291"
    | "332911"
    | "332912"
    | "332913"
    | "332919"
    | "33299"
    | "332991"
    | "332992"
    | "332993"
    | "332994"
    | "332996"
    | "332999"
    | "333"
    | "3331"
    | "33311"
    | "333111"
    | "333112"
    | "33312"
    | "333120"
    | "33313"
    | "333131"
    | "333132"
    | "3332"
    | "33324"
    | "333241"
    | "333242"
    | "333243"
    | "333248"
    | "3333"
    | "33331"
    | "333310"
    | "3334"
    | "33341"
    | "333413"
    | "333414"
    | "333415"
    | "3335"
    | "33351"
    | "333511"
    | "333514"
    | "333515"
    | "333517"
    | "333519"
    | "3336"
    | "33361"
    | "333611"
    | "333612"
    | "333613"
    | "333618"
    | "3339"
    | "33391"
    | "333912"
    | "333914"
    | "33392"
    | "333921"
    | "333922"
    | "333923"
    | "333924"
    | "33399"
    | "333991"
    | "333992"
    | "333993"
    | "333994"
    | "333995"
    | "333996"
    | "333998"
    | "334"
    | "3341"
    | "33411"
    | "334111"
    | "334112"
    | "334118"
    | "3342"
    | "33421"
    | "334210"
    | "33422"
    | "334220"
    | "33429"
    | "334290"
    | "3343"
    | "33431"
    | "334310"
    | "3344"
    | "33441"
    | "334412"
    | "334413"
    | "334416"
    | "334417"
    | "334418"
    | "334419"
    | "3345"
    | "33451"
    | "334510"
    | "334511"
    | "334512"
    | "334513"
    | "334514"
    | "334515"
    | "334516"
    | "334517"
    | "334519"
    | "3346"
    | "33461"
    | "334610"
    | "335"
    | "3351"
    | "33513"
    | "335131"
    | "335132"
    | "335139"
    | "3352"
    | "33521"
    | "335210"
    | "33522"
    | "335220"
    | "3353"
    | "33531"
    | "335311"
    | "335312"
    | "335313"
    | "335314"
    | "3359"
    | "33591"
    | "335910"
    | "33592"
    | "335921"
    | "335929"
    | "33593"
    | "335931"
    | "335932"
    | "33599"
    | "335991"
    | "335999"
    | "336"
    | "3361"
    | "33611"
    | "336110"
    | "33612"
    | "336120"
    | "3362"
    | "33621"
    | "336211"
    | "336212"
    | "336213"
    | "336214"
    | "3363"
    | "33631"
    | "336310"
    | "33632"
    | "336320"
    | "33633"
    | "336330"
    | "33634"
    | "336340"
    | "33635"
    | "336350"
    | "33636"
    | "336360"
    | "33637"
    | "336370"
    | "33639"
    | "336390"
    | "3364"
    | "33641"
    | "336411"
    | "336412"
    | "336413"
    | "336414"
    | "336415"
    | "336419"
    | "3365"
    | "33651"
    | "336510"
    | "3366"
    | "33661"
    | "336611"
    | "336612"
    | "3369"
    | "33699"
    | "336991"
    | "336992"
    | "336999"
    | "337"
    | "3371"
    | "33711"
    | "337110"
    | "33712"
    | "337121"
    | "337122"
    | "337126"
    | "337127"
    | "3372"
    | "33721"
    | "337211"
    | "337212"
    | "337214"
    | "337215"
    | "3379"
    | "33791"
    | "337910"
    | "33792"
    | "337920"
    | "339"
    | "3391"
    | "33911"
    | "339112"
    | "339113"
    | "339114"
    | "339115"
    | "339116"
    | "3399"
    | "33991"
    | "339910"
    | "33992"
    | "339920"
    | "33993"
    | "339930"
    | "33994"
    | "339940"
    | "33995"
    | "339950"
    | "33999"
    | "339991"
    | "339992"
    | "339993"
    | "339994"
    | "339995"
    | "339999"
    | "42"
    | "423"
    | "4231"
    | "42311"
    | "423110"
    | "42312"
    | "423120"
    | "42313"
    | "423130"
    | "42314"
    | "423140"
    | "4232"
    | "42321"
    | "423210"
    | "42322"
    | "423220"
    | "4233"
    | "42331"
    | "423310"
    | "42332"
    | "423320"
    | "42333"
    | "423330"
    | "42339"
    | "423390"
    | "4234"
    | "42341"
    | "423410"
    | "42342"
    | "423420"
    | "42343"
    | "423430"
    | "42344"
    | "423440"
    | "42345"
    | "423450"
    | "42346"
    | "423460"
    | "42349"
    | "423490"
    | "4235"
    | "42351"
    | "423510"
    | "42352"
    | "423520"
    | "4236"
    | "42361"
    | "423610"
    | "42362"
    | "423620"
    | "42369"
    | "423690"
    | "4237"
    | "42371"
    | "423710"
    | "42372"
    | "423720"
    | "42373"
    | "423730"
    | "42374"
    | "423740"
    | "4238"
    | "42381"
    | "423810"
    | "42382"
    | "423820"
    | "42383"
    | "423830"
    | "42384"
    | "423840"
    | "42385"
    | "423850"
    | "42386"
    | "423860"
    | "4239"
    | "42391"
    | "423910"
    | "42392"
    | "423920"
    | "42393"
    | "423930"
    | "42394"
    | "423940"
    | "42399"
    | "423990"
    | "424"
    | "4241"
    | "42411"
    | "424110"
    | "42412"
    | "424120"
    | "42413"
    | "424130"
    | "4242"
    | "42421"
    | "424210"
    | "4243"
    | "42431"
    | "424310"
    | "42434"
    | "424340"
    | "42435"
    | "424350"
    | "4244"
    | "42441"
    | "424410"
    | "42442"
    | "424420"
    | "42443"
    | "424430"
    | "42444"
    | "424440"
    | "42445"
    | "424450"
    | "42446"
    | "424460"
    | "42447"
    | "424470"
    | "42448"
    | "424480"
    | "42449"
    | "424490"
    | "4245"
    | "42451"
    | "424510"
    | "42452"
    | "424520"
    | "42459"
    | "424590"
    | "4246"
    | "42461"
    | "424610"
    | "42469"
    | "424690"
    | "4247"
    | "42471"
    | "424710"
    | "42472"
    | "424720"
    | "4248"
    | "42481"
    | "424810"
    | "42482"
    | "424820"
    | "4249"
    | "42491"
    | "424910"
    | "42492"
    | "424920"
    | "42493"
    | "424930"
    | "42494"
    | "424940"
    | "42495"
    | "424950"
    | "42499"
    | "424990"
    | "425"
    | "4251"
    | "42512"
    | "425120"
    | "44-45"
    | "441"
    | "4411"
    | "44111"
    | "441110"
    | "44112"
    | "441120"
    | "4412"
    | "44121"
    | "441210"
    | "44122"
    | "441222"
    | "441227"
    | "4413"
    | "44133"
    | "441330"
    | "44134"
    | "441340"
    | "444"
    | "4441"
    | "44411"
    | "444110"
    | "44412"
    | "444120"
    | "44414"
    | "444140"
    | "44418"
    | "444180"
    | "4442"
    | "44423"
    | "444230"
    | "44424"
    | "444240"
    | "445"
    | "4451"
    | "44511"
    | "445110"
    | "44513"
    | "445131"
    | "445132"
    | "4452"
    | "44523"
    | "445230"
    | "44524"
    | "445240"
    | "44525"
    | "445250"
    | "44529"
    | "445291"
    | "445292"
    | "445298"
    | "4453"
    | "44532"
    | "445320"
    | "449"
    | "4491"
    | "44911"
    | "449110"
    | "44912"
    | "449121"
    | "449122"
    | "449129"
    | "4492"
    | "44921"
    | "449210"
    | "455"
    | "4551"
    | "45511"
    | "455110"
    | "4552"
    | "45521"
    | "455211"
    | "455219"
    | "456"
    | "4561"
    | "45611"
    | "456110"
    | "45612"
    | "456120"
    | "45613"
    | "456130"
    | "45619"
    | "456191"
    | "456199"
    | "457"
    | "4571"
    | "45711"
    | "457110"
    | "45712"
    | "457120"
    | "4572"
    | "45721"
    | "457210"
    | "458"
    | "4581"
    | "45811"
    | "458110"
    | "4582"
    | "45821"
    | "458210"
    | "4583"
    | "45831"
    | "458310"
    | "45832"
    | "458320"
    | "459"
    | "4591"
    | "45911"
    | "459110"
    | "45912"
    | "459120"
    | "45913"
    | "459130"
    | "45914"
    | "459140"
    | "4592"
    | "45921"
    | "459210"
    | "4593"
    | "45931"
    | "459310"
    | "4594"
    | "45941"
    | "459410"
    | "45942"
    | "459420"
    | "4595"
    | "45951"
    | "459510"
    | "4599"
    | "45991"
    | "459910"
    | "45992"
    | "459920"
    | "45993"
    | "459930"
    | "45999"
    | "459991"
    | "459999"
    | "48-49"
    | "481"
    | "4811"
    | "48111"
    | "481111"
    | "481112"
    | "4812"
    | "48121"
    | "481211"
    | "481212"
    | "481219"
    | "482"
    | "4821"
    | "48211"
    | "482111"
    | "482112"
    | "483"
    | "4831"
    | "48311"
    | "483111"
    | "483112"
    | "483113"
    | "483114"
    | "4832"
    | "48321"
    | "483211"
    | "483212"
    | "484"
    | "4841"
    | "48411"
    | "484110"
    | "48412"
    | "484121"
    | "484122"
    | "4842"
    | "48421"
    | "484210"
    | "48422"
    | "484220"
    | "48423"
    | "484230"
    | "485"
    | "4851"
    | "48511"
    | "485111"
    | "485112"
    | "485113"
    | "485119"
    | "4852"
    | "48521"
    | "485210"
    | "4853"
    | "48531"
    | "485310"
    | "48532"
    | "485320"
    | "4854"
    | "48541"
    | "485410"
    | "4855"
    | "48551"
    | "485510"
    | "4859"
    | "48599"
    | "485991"
    | "485999"
    | "486"
    | "4861"
    | "48611"
    | "486110"
    | "4862"
    | "48621"
    | "486210"
    | "4869"
    | "48691"
    | "486910"
    | "48699"
    | "486990"
    | "487"
    | "4871"
    | "48711"
    | "487110"
    | "4872"
    | "48721"
    | "487210"
    | "4879"
    | "48799"
    | "487990"
    | "488"
    | "4881"
    | "48811"
    | "488111"
    | "488119"
    | "48819"
    | "488190"
    | "4882"
    | "48821"
    | "488210"
    | "4883"
    | "48831"
    | "488310"
    | "48832"
    | "488320"
    | "48833"
    | "488330"
    | "48839"
    | "488390"
    | "4884"
    | "48841"
    | "488410"
    | "48849"
    | "488490"
    | "4885"
    | "48851"
    | "488510"
    | "4889"
    | "48899"
    | "488991"
    | "488999"
    | "491"
    | "4911"
    | "49111"
    | "491110"
    | "492"
    | "4921"
    | "49211"
    | "492110"
    | "4922"
    | "49221"
    | "492210"
    | "493"
    | "4931"
    | "49311"
    | "493110"
    | "49312"
    | "493120"
    | "49313"
    | "493130"
    | "49319"
    | "493190"
    | "51"
    | "512"
    | "5121"
    | "51211"
    | "512110"
    | "51212"
    | "512120"
    | "51213"
    | "512131"
    | "512132"
    | "51219"
    | "512191"
    | "512199"
    | "5122"
    | "51223"
    | "512230"
    | "51224"
    | "512240"
    | "51225"
    | "512250"
    | "51229"
    | "512290"
    | "513"
    | "5131"
    | "51311"
    | "513110"
    | "51312"
    | "513120"
    | "51313"
    | "513130"
    | "51314"
    | "513140"
    | "51319"
    | "513191"
    | "513199"
    | "5132"
    | "5112"
    | "51321"
    | "513210"
    | "516"
    | "5161"
    | "51611"
    | "516110"
    | "51612"
    | "516120"
    | "5162"
    | "51621"
    | "516210"
    | "517"
    | "5171"
    | "51711"
    | "517111"
    | "517112"
    | "51712"
    | "517121"
    | "517122"
    | "5174"
    | "51741"
    | "517410"
    | "5178"
    | "51781"
    | "517810"
    | "518"
    | "5182"
    | "51821"
    | "518210"
    | "519"
    | "5192"
    | "51921"
    | "519210"
    | "51929"
    | "519290"
    | "52"
    | "521"
    | "5211"
    | "52111"
    | "521110"
    | "522"
    | "5221"
    | "52211"
    | "522110"
    | "52213"
    | "522130"
    | "52218"
    | "522180"
    | "5222"
    | "52221"
    | "522210"
    | "52222"
    | "522220"
    | "52229"
    | "522291"
    | "522292"
    | "522299"
    | "5223"
    | "52231"
    | "522310"
    | "52232"
    | "522320"
    | "52239"
    | "522390"
    | "523"
    | "5231"
    | "52315"
    | "523150"
    | "52316"
    | "523160"
    | "5232"
    | "52321"
    | "523210"
    | "5239"
    | "52391"
    | "523910"
    | "52394"
    | "523940"
    | "52399"
    | "523991"
    | "523999"
    | "524"
    | "5241"
    | "52411"
    | "524113"
    | "524114"
    | "52412"
    | "524126"
    | "524127"
    | "524128"
    | "52413"
    | "524130"
    | "5242"
    | "52421"
    | "524210"
    | "52429"
    | "524291"
    | "524292"
    | "524298"
    | "525"
    | "5251"
    | "52511"
    | "525110"
    | "52512"
    | "525120"
    | "52519"
    | "525190"
    | "5259"
    | "52591"
    | "525910"
    | "52592"
    | "525920"
    | "52599"
    | "525990"
    | "53"
    | "531"
    | "5311"
    | "53111"
    | "531110"
    | "53112"
    | "531120"
    | "53113"
    | "531130"
    | "53119"
    | "531190"
    | "5312"
    | "53121"
    | "531210"
    | "5313"
    | "53131"
    | "531311"
    | "531312"
    | "53132"
    | "531320"
    | "53139"
    | "531390"
    | "532"
    | "5321"
    | "53211"
    | "532111"
    | "532112"
    | "53212"
    | "532120"
    | "5322"
    | "53221"
    | "532210"
    | "53228"
    | "532281"
    | "532282"
    | "532283"
    | "532284"
    | "532289"
    | "5323"
    | "53231"
    | "532310"
    | "5324"
    | "53241"
    | "532411"
    | "532412"
    | "53242"
    | "532420"
    | "53249"
    | "532490"
    | "533"
    | "5331"
    | "53311"
    | "533110"
    | "54"
    | "541"
    | "5411"
    | "54111"
    | "541110"
    | "54112"
    | "541120"
    | "54119"
    | "541191"
    | "541199"
    | "5412"
    | "54121"
    | "541211"
    | "541213"
    | "541214"
    | "541219"
    | "5413"
    | "54131"
    | "541310"
    | "54132"
    | "541320"
    | "54133"
    | "541330"
    | "54134"
    | "541340"
    | "54135"
    | "541350"
    | "54136"
    | "541360"
    | "54137"
    | "541370"
    | "54138"
    | "541380"
    | "5414"
    | "54141"
    | "541410"
    | "54142"
    | "541420"
    | "54143"
    | "541430"
    | "54149"
    | "541490"
    | "5415"
    | "54151"
    | "541511"
    | "541512"
    | "541513"
    | "541519"
    | "5416"
    | "54161"
    | "541611"
    | "541612"
    | "541613"
    | "541614"
    | "541618"
    | "54162"
    | "541620"
    | "54169"
    | "541690"
    | "5417"
    | "54171"
    | "541713"
    | "541714"
    | "541715"
    | "54172"
    | "541720"
    | "5418"
    | "54181"
    | "541810"
    | "54182"
    | "541820"
    | "54183"
    | "541830"
    | "54184"
    | "541840"
    | "54185"
    | "541850"
    | "54186"
    | "541860"
    | "54187"
    | "541870"
    | "54189"
    | "541890"
    | "5419"
    | "54191"
    | "541910"
    | "54192"
    | "541921"
    | "541922"
    | "54193"
    | "541930"
    | "54194"
    | "541940"
    | "54199"
    | "541990"
    | "55"
    | "551"
    | "5511"
    | "55111"
    | "551111"
    | "551112"
    | "551114"
    | "56"
    | "561"
    | "5611"
    | "56111"
    | "561110"
    | "5612"
    | "56121"
    | "561210"
    | "5613"
    | "56131"
    | "561311"
    | "561312"
    | "56132"
    | "561320"
    | "56133"
    | "561330"
    | "5614"
    | "56141"
    | "561410"
    | "56142"
    | "561421"
    | "561422"
    | "56143"
    | "561431"
    | "561439"
    | "56144"
    | "561440"
    | "56145"
    | "561450"
    | "56149"
    | "561491"
    | "561492"
    | "561499"
    | "5615"
    | "56151"
    | "561510"
    | "56152"
    | "561520"
    | "56159"
    | "561591"
    | "561599"
    | "5616"
    | "56161"
    | "561611"
    | "561612"
    | "561613"
    | "56162"
    | "561621"
    | "561622"
    | "5617"
    | "56171"
    | "561710"
    | "56172"
    | "561720"
    | "56173"
    | "561730"
    | "56174"
    | "561740"
    | "56179"
    | "561790"
    | "5619"
    | "56191"
    | "561910"
    | "56192"
    | "561920"
    | "56199"
    | "561990"
    | "562"
    | "5621"
    | "56211"
    | "562111"
    | "562112"
    | "562119"
    | "5622"
    | "56221"
    | "562211"
    | "562212"
    | "562213"
    | "562219"
    | "5629"
    | "56291"
    | "562910"
    | "56292"
    | "562920"
    | "56299"
    | "562991"
    | "562998"
    | "61"
    | "611"
    | "6111"
    | "61111"
    | "611110"
    | "6112"
    | "61121"
    | "611210"
    | "6113"
    | "61131"
    | "611310"
    | "6114"
    | "61141"
    | "611410"
    | "61142"
    | "611420"
    | "61143"
    | "611430"
    | "6115"
    | "61151"
    | "611511"
    | "611512"
    | "611513"
    | "611519"
    | "6116"
    | "61161"
    | "611610"
    | "61162"
    | "611620"
    | "61163"
    | "611630"
    | "61169"
    | "611691"
    | "611692"
    | "611699"
    | "6117"
    | "61171"
    | "611710"
    | "62"
    | "621"
    | "6211"
    | "62111"
    | "621111"
    | "621112"
    | "6212"
    | "62121"
    | "621210"
    | "6213"
    | "62131"
    | "621310"
    | "62132"
    | "621320"
    | "62133"
    | "621330"
    | "62134"
    | "621340"
    | "62139"
    | "621391"
    | "621399"
    | "6214"
    | "62141"
    | "621410"
    | "62142"
    | "621420"
    | "62149"
    | "621491"
    | "621492"
    | "621493"
    | "621498"
    | "6215"
    | "62151"
    | "621511"
    | "621512"
    | "6216"
    | "62161"
    | "621610"
    | "6219"
    | "62191"
    | "621910"
    | "62199"
    | "621991"
    | "621999"
    | "622"
    | "6221"
    | "62211"
    | "622110"
    | "6222"
    | "62221"
    | "622210"
    | "6223"
    | "62231"
    | "622310"
    | "623"
    | "6231"
    | "62311"
    | "623110"
    | "6232"
    | "62321"
    | "623210"
    | "62322"
    | "623220"
    | "6233"
    | "62331"
    | "623311"
    | "623312"
    | "6239"
    | "62399"
    | "623990"
    | "624"
    | "6241"
    | "62411"
    | "624110"
    | "62412"
    | "624120"
    | "62419"
    | "624190"
    | "6242"
    | "62421"
    | "624210"
    | "62422"
    | "624221"
    | "624229"
    | "62423"
    | "624230"
    | "6243"
    | "62431"
    | "624310"
    | "6244"
    | "62441"
    | "624410"
    | "71"
    | "711"
    | "7111"
    | "71111"
    | "711110"
    | "71112"
    | "711120"
    | "71113"
    | "711130"
    | "71119"
    | "711190"
    | "7112"
    | "71121"
    | "711211"
    | "711212"
    | "711219"
    | "7113"
    | "71131"
    | "711310"
    | "71132"
    | "711320"
    | "7114"
    | "71141"
    | "711410"
    | "7115"
    | "71151"
    | "711510"
    | "712"
    | "7121"
    | "71211"
    | "712110"
    | "71212"
    | "712120"
    | "71213"
    | "712130"
    | "71219"
    | "712190"
    | "713"
    | "7131"
    | "71311"
    | "713110"
    | "71312"
    | "713120"
    | "7132"
    | "71321"
    | "713210"
    | "71329"
    | "713290"
    | "7139"
    | "71391"
    | "713910"
    | "71392"
    | "713920"
    | "71393"
    | "713930"
    | "71394"
    | "713940"
    | "71395"
    | "713950"
    | "71399"
    | "713990"
    | "72"
    | "721"
    | "7211"
    | "72111"
    | "721110"
    | "72112"
    | "721120"
    | "72119"
    | "721191"
    | "721199"
    | "7212"
    | "72121"
    | "721211"
    | "721214"
    | "7213"
    | "72131"
    | "721310"
    | "722"
    | "7223"
    | "72231"
    | "722310"
    | "72232"
    | "722320"
    | "72233"
    | "722330"
    | "7224"
    | "72241"
    | "722410"
    | "7225"
    | "72251"
    | "722511"
    | "722513"
    | "722514"
    | "722515"
    | "81"
    | "811"
    | "8111"
    | "81111"
    | "811111"
    | "811114"
    | "81112"
    | "811121"
    | "811122"
    | "81119"
    | "811191"
    | "811192"
    | "811198"
    | "8112"
    | "81121"
    | "811210"
    | "8113"
    | "81131"
    | "811310"
    | "8114"
    | "81141"
    | "811411"
    | "811412"
    | "81142"
    | "811420"
    | "81143"
    | "811430"
    | "81149"
    | "811490"
    | "812"
    | "8121"
    | "81211"
    | "812111"
    | "812112"
    | "812113"
    | "81219"
    | "812191"
    | "812199"
    | "8122"
    | "81221"
    | "812210"
    | "81222"
    | "812220"
    | "8123"
    | "81231"
    | "812310"
    | "81232"
    | "812320"
    | "81233"
    | "812331"
    | "812332"
    | "8129"
    | "81291"
    | "812910"
    | "81292"
    | "812921"
    | "812922"
    | "81293"
    | "812930"
    | "81299"
    | "812990"
    | "813"
    | "8131"
    | "81311"
    | "813110"
    | "8132"
    | "81321"
    | "813211"
    | "813212"
    | "813219"
    | "8133"
    | "81331"
    | "813311"
    | "813312"
    | "813319"
    | "8134"
    | "81341"
    | "813410"
    | "8139"
    | "81391"
    | "813910"
    | "81392"
    | "813920"
    | "81393"
    | "813930"
    | "81394"
    | "813940"
    | "81399"
    | "813990"
    | "814"
    | "8141"
    | "81411"
    | "814110"
    | "92"
    | "921"
    | "9211"
    | "92111"
    | "921110"
    | "92112"
    | "921120"
    | "92113"
    | "921130"
    | "92114"
    | "921140"
    | "92115"
    | "921150"
    | "92119"
    | "921190"
    | "922"
    | "9221"
    | "92211"
    | "922110"
    | "92212"
    | "922120"
    | "92213"
    | "922130"
    | "92214"
    | "922140"
    | "92215"
    | "922150"
    | "92216"
    | "922160"
    | "92219"
    | "922190"
    | "923"
    | "9231"
    | "92311"
    | "923110"
    | "92312"
    | "923120"
    | "92313"
    | "923130"
    | "92314"
    | "923140"
    | "924"
    | "9241"
    | "92411"
    | "924110"
    | "92412"
    | "924120"
    | "925"
    | "9251"
    | "92511"
    | "925110"
    | "92512"
    | "925120"
    | "926"
    | "9261"
    | "92611"
    | "926110"
    | "92612"
    | "926120"
    | "92613"
    | "926130"
    | "92614"
    | "926140"
    | "92615"
    | "926150"
    | "927"
    | "9271"
    | "92711"
    | "927110"
    | "928"
    | "9281"
    | "92811"
    | "928110"
    | "92812"
    | "928120"
    | "999300"
    | null;

export type FraudKnownBadSubmissionsSearchResponseDataItemEntityAnyOfSetupSubmissionStructureCorporationAnyOfEntitySetupCorporationBeneficialOwnerRelationshipsItemSubmittedCompanyTitle =
  string | null;

export type FraudKnownBadSubmissionsSearchResponseDataItemEntityAnyOfSetupSubmissionStructureCorporationAnyOfEntitySetupCorporationBeneficialOwnerRelationshipsItemProng =
  (typeof FraudKnownBadSubmissionsSearchResponseDataItemEntityAnyOfSetupSubmissionStructureCorporationAnyOfEntitySetupCorporationBeneficialOwnerRelationshipsItemProng)[keyof typeof FraudKnownBadSubmissionsSearchResponseDataItemEntityAnyOfSetupSubmissionStructureCorporationAnyOfEntitySetupCorporationBeneficialOwnerRelationshipsItemProng];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const FraudKnownBadSubmissionsSearchResponseDataItemEntityAnyOfSetupSubmissionStructureCorporationAnyOfEntitySetupCorporationBeneficialOwnerRelationshipsItemProng =
  {
    ownership: "ownership",
    control: "control",
  } as const;

export type FraudKnownBadSubmissionsSearchResponseDataItemEntityAnyOfSetupSubmissionStructureCorporationAnyOfEntitySetupCorporationBeneficialOwnerRelationshipsItem =
  {
    archived: boolean;
    entity_setup_beneficial_owner_submission: FraudKnownBadSubmissionsSearchResponseDataItemEntityAnyOfSetupSubmissionStructureCorporationAnyOfEntitySetupCorporationBeneficialOwnerRelationshipsItemEntitySetupBeneficialOwnerSubmission;
    entity_setup_corporation_submission_id: string;
    id: string;
    prong: FraudKnownBadSubmissionsSearchResponseDataItemEntityAnyOfSetupSubmissionStructureCorporationAnyOfEntitySetupCorporationBeneficialOwnerRelationshipsItemProng;
    submitted_company_title: FraudKnownBadSubmissionsSearchResponseDataItemEntityAnyOfSetupSubmissionStructureCorporationAnyOfEntitySetupCorporationBeneficialOwnerRelationshipsItemSubmittedCompanyTitle;
  };

export type FraudKnownBadSubmissionsSearchResponseDataItemEntityAnyOfSetupSubmissionStructureCorporationAnyOfEntitySetupCorporationBeneficialOwnerRelationshipsItemEntitySetupBeneficialOwnerSubmissionTaxIdValidationStatus =
  (typeof FraudKnownBadSubmissionsSearchResponseDataItemEntityAnyOfSetupSubmissionStructureCorporationAnyOfEntitySetupCorporationBeneficialOwnerRelationshipsItemEntitySetupBeneficialOwnerSubmissionTaxIdValidationStatus)[keyof typeof FraudKnownBadSubmissionsSearchResponseDataItemEntityAnyOfSetupSubmissionStructureCorporationAnyOfEntitySetupCorporationBeneficialOwnerRelationshipsItemEntitySetupBeneficialOwnerSubmissionTaxIdValidationStatus];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const FraudKnownBadSubmissionsSearchResponseDataItemEntityAnyOfSetupSubmissionStructureCorporationAnyOfEntitySetupCorporationBeneficialOwnerRelationshipsItemEntitySetupBeneficialOwnerSubmissionTaxIdValidationStatus =
  {
    pending_validating: "pending_validating",
    update_pending_validating: "update_pending_validating",
    requires_attention: "requires_attention",
    failed: "failed",
    validated: "validated",
    not_provided: "not_provided",
    skipped: "skipped",
  } as const;

export type FraudKnownBadSubmissionsSearchResponseDataItemEntityAnyOfSetupSubmissionStructureCorporationAnyOfEntitySetupCorporationBeneficialOwnerRelationshipsItemEntitySetupBeneficialOwnerSubmissionSubmittedIdentificationNumberLast4 =
  string | null;

export type FraudKnownBadSubmissionsSearchResponseDataItemEntityAnyOfSetupSubmissionStructureCorporationAnyOfEntitySetupCorporationBeneficialOwnerRelationshipsItemEntitySetupBeneficialOwnerSubmissionSubmittedIdentificationMethod =
  (typeof FraudKnownBadSubmissionsSearchResponseDataItemEntityAnyOfSetupSubmissionStructureCorporationAnyOfEntitySetupCorporationBeneficialOwnerRelationshipsItemEntitySetupBeneficialOwnerSubmissionSubmittedIdentificationMethod)[keyof typeof FraudKnownBadSubmissionsSearchResponseDataItemEntityAnyOfSetupSubmissionStructureCorporationAnyOfEntitySetupCorporationBeneficialOwnerRelationshipsItemEntitySetupBeneficialOwnerSubmissionSubmittedIdentificationMethod];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const FraudKnownBadSubmissionsSearchResponseDataItemEntityAnyOfSetupSubmissionStructureCorporationAnyOfEntitySetupCorporationBeneficialOwnerRelationshipsItemEntitySetupBeneficialOwnerSubmissionSubmittedIdentificationMethod =
  {
    social_security_number: "social_security_number",
    individual_taxpayer_identification_number:
      "individual_taxpayer_identification_number",
    passport: "passport",
    drivers_license: "drivers_license",
    other: "other",
  } as const;

export type FraudKnownBadSubmissionsSearchResponseDataItemEntityAnyOfSetupSubmissionStructureCorporationAnyOfEntitySetupCorporationBeneficialOwnerRelationshipsItemEntitySetupBeneficialOwnerSubmissionSubmittedAddressLine2 =
  string | null;

export type FraudKnownBadSubmissionsSearchResponseDataItemEntityAnyOfSetupSubmissionStructureCorporationAnyOfEntitySetupCorporationBeneficialOwnerRelationshipsItemEntitySetupBeneficialOwnerSubmissionSubmittedAddress =
  {
    city: string;
    line1: string;
    line2: FraudKnownBadSubmissionsSearchResponseDataItemEntityAnyOfSetupSubmissionStructureCorporationAnyOfEntitySetupCorporationBeneficialOwnerRelationshipsItemEntitySetupBeneficialOwnerSubmissionSubmittedAddressLine2;
    state: string;
    zip: string;
  };

export type FraudKnownBadSubmissionsSearchResponseDataItemEntityAnyOfSetupSubmissionStructureCorporationAnyOfEntitySetupCorporationBeneficialOwnerRelationshipsItemEntitySetupBeneficialOwnerSubmissionSubmissionSovosResponseId =
  string | null;

export type FraudKnownBadSubmissionsSearchResponseDataItemEntityAnyOfSetupSubmissionStructureCorporationAnyOfEntitySetupCorporationBeneficialOwnerRelationshipsItemEntitySetupBeneficialOwnerSubmissionMicrobiltItemTaxIdType =
  "SSN" | null;

export type FraudKnownBadSubmissionsSearchResponseDataItemEntityAnyOfSetupSubmissionStructureCorporationAnyOfEntitySetupCorporationBeneficialOwnerRelationshipsItemEntitySetupBeneficialOwnerSubmissionMicrobiltItemTaxIdLast4 =
  string | null;

export type FraudKnownBadSubmissionsSearchResponseDataItemEntityAnyOfSetupSubmissionStructureCorporationAnyOfEntitySetupCorporationBeneficialOwnerRelationshipsItemEntitySetupBeneficialOwnerSubmissionMicrobiltItemReturnCode =
  (typeof FraudKnownBadSubmissionsSearchResponseDataItemEntityAnyOfSetupSubmissionStructureCorporationAnyOfEntitySetupCorporationBeneficialOwnerRelationshipsItemEntitySetupBeneficialOwnerSubmissionMicrobiltItemReturnCode)[keyof typeof FraudKnownBadSubmissionsSearchResponseDataItemEntityAnyOfSetupSubmissionStructureCorporationAnyOfEntitySetupCorporationBeneficialOwnerRelationshipsItemEntitySetupBeneficialOwnerSubmissionMicrobiltItemReturnCode];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const FraudKnownBadSubmissionsSearchResponseDataItemEntityAnyOfSetupSubmissionStructureCorporationAnyOfEntitySetupCorporationBeneficialOwnerRelationshipsItemEntitySetupBeneficialOwnerSubmissionMicrobiltItemReturnCode =
  {
    OK: "OK",
  } as const;

export type FraudKnownBadSubmissionsSearchResponseDataItemEntityAnyOfSetupSubmissionStructureCorporationAnyOfEntitySetupCorporationBeneficialOwnerRelationshipsItemEntitySetupBeneficialOwnerSubmissionMicrobiltItemMiddleName =
  string | null;

export type FraudKnownBadSubmissionsSearchResponseDataItemEntityAnyOfSetupSubmissionStructureCorporationAnyOfEntitySetupCorporationBeneficialOwnerRelationshipsItemEntitySetupBeneficialOwnerSubmissionMicrobiltItemDeathDate =
  string | null;

export type FraudKnownBadSubmissionsSearchResponseDataItemEntityAnyOfSetupSubmissionStructureCorporationAnyOfEntitySetupCorporationBeneficialOwnerRelationshipsItemEntitySetupBeneficialOwnerSubmissionMicrobiltItemBirthDate =
  string | null;

export type FraudKnownBadSubmissionsSearchResponseDataItemEntityAnyOfSetupSubmissionStructureCorporationAnyOfEntitySetupCorporationBeneficialOwnerRelationshipsItemEntitySetupBeneficialOwnerSubmissionMicrobiltItem =
  {
    birth_date: FraudKnownBadSubmissionsSearchResponseDataItemEntityAnyOfSetupSubmissionStructureCorporationAnyOfEntitySetupCorporationBeneficialOwnerRelationshipsItemEntitySetupBeneficialOwnerSubmissionMicrobiltItemBirthDate;
    created_at: string;
    death_date: FraudKnownBadSubmissionsSearchResponseDataItemEntityAnyOfSetupSubmissionStructureCorporationAnyOfEntitySetupCorporationBeneficialOwnerRelationshipsItemEntitySetupBeneficialOwnerSubmissionMicrobiltItemDeathDate;
    first_name: string;
    id: string;
    last_name: string;
    middle_name: FraudKnownBadSubmissionsSearchResponseDataItemEntityAnyOfSetupSubmissionStructureCorporationAnyOfEntitySetupCorporationBeneficialOwnerRelationshipsItemEntitySetupBeneficialOwnerSubmissionMicrobiltItemMiddleName;
    request_id: string;
    response: string;
    return_code: FraudKnownBadSubmissionsSearchResponseDataItemEntityAnyOfSetupSubmissionStructureCorporationAnyOfEntitySetupCorporationBeneficialOwnerRelationshipsItemEntitySetupBeneficialOwnerSubmissionMicrobiltItemReturnCode;
    tax_id_last_4: FraudKnownBadSubmissionsSearchResponseDataItemEntityAnyOfSetupSubmissionStructureCorporationAnyOfEntitySetupCorporationBeneficialOwnerRelationshipsItemEntitySetupBeneficialOwnerSubmissionMicrobiltItemTaxIdLast4;
    tax_id_type: FraudKnownBadSubmissionsSearchResponseDataItemEntityAnyOfSetupSubmissionStructureCorporationAnyOfEntitySetupCorporationBeneficialOwnerRelationshipsItemEntitySetupBeneficialOwnerSubmissionMicrobiltItemTaxIdType;
  };

export type FraudKnownBadSubmissionsSearchResponseDataItemEntityAnyOfSetupSubmissionStructureCorporationAnyOfEntitySetupCorporationBeneficialOwnerRelationshipsItemEntitySetupBeneficialOwnerSubmissionIdentityVerificationStatus =
  (typeof FraudKnownBadSubmissionsSearchResponseDataItemEntityAnyOfSetupSubmissionStructureCorporationAnyOfEntitySetupCorporationBeneficialOwnerRelationshipsItemEntitySetupBeneficialOwnerSubmissionIdentityVerificationStatus)[keyof typeof FraudKnownBadSubmissionsSearchResponseDataItemEntityAnyOfSetupSubmissionStructureCorporationAnyOfEntitySetupCorporationBeneficialOwnerRelationshipsItemEntitySetupBeneficialOwnerSubmissionIdentityVerificationStatus];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const FraudKnownBadSubmissionsSearchResponseDataItemEntityAnyOfSetupSubmissionStructureCorporationAnyOfEntitySetupCorporationBeneficialOwnerRelationshipsItemEntitySetupBeneficialOwnerSubmissionIdentityVerificationStatus =
  {
    pending_verification: "pending_verification",
    update_pending_verification: "update_pending_verification",
    verified: "verified",
    falsified: "falsified",
    no_match: "no_match",
    verifying: "verifying",
    requires_attention: "requires_attention",
    not_performed: "not_performed",
    skipped: "skipped",
  } as const;

export type FraudKnownBadSubmissionsSearchResponseDataItemEntityAnyOfSetupSubmissionStructureCorporationAnyOfEntitySetupCorporationBeneficialOwnerRelationshipsItemEntitySetupBeneficialOwnerSubmissionIdentityDocumentSubmissionsItemSubmittedState =
  string | null;

export type FraudKnownBadSubmissionsSearchResponseDataItemEntityAnyOfSetupSubmissionStructureCorporationAnyOfEntitySetupCorporationBeneficialOwnerRelationshipsItemEntitySetupBeneficialOwnerSubmissionIdentityDocumentSubmissionsItemSubmittedExpirationDate =
  string | null;

export type FraudKnownBadSubmissionsSearchResponseDataItemEntityAnyOfSetupSubmissionStructureCorporationAnyOfEntitySetupCorporationBeneficialOwnerRelationshipsItemEntitySetupBeneficialOwnerSubmissionIdentityDocumentSubmissionsItemSubmittedDocumentType =
  (typeof FraudKnownBadSubmissionsSearchResponseDataItemEntityAnyOfSetupSubmissionStructureCorporationAnyOfEntitySetupCorporationBeneficialOwnerRelationshipsItemEntitySetupBeneficialOwnerSubmissionIdentityDocumentSubmissionsItemSubmittedDocumentType)[keyof typeof FraudKnownBadSubmissionsSearchResponseDataItemEntityAnyOfSetupSubmissionStructureCorporationAnyOfEntitySetupCorporationBeneficialOwnerRelationshipsItemEntitySetupBeneficialOwnerSubmissionIdentityDocumentSubmissionsItemSubmittedDocumentType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const FraudKnownBadSubmissionsSearchResponseDataItemEntityAnyOfSetupSubmissionStructureCorporationAnyOfEntitySetupCorporationBeneficialOwnerRelationshipsItemEntitySetupBeneficialOwnerSubmissionIdentityDocumentSubmissionsItemSubmittedDocumentType =
  {
    passport: "passport",
    us_drivers_license: "us_drivers_license",
    other: "other",
  } as const;

export type FraudKnownBadSubmissionsSearchResponseDataItemEntityAnyOfSetupSubmissionStructureCorporationAnyOfEntitySetupCorporationBeneficialOwnerRelationshipsItemEntitySetupBeneficialOwnerSubmissionIdentityDocumentSubmissionsItemSubmittedDocumentNumber =
  string | null;

export type FraudKnownBadSubmissionsSearchResponseDataItemEntityAnyOfSetupSubmissionStructureCorporationAnyOfEntitySetupCorporationBeneficialOwnerRelationshipsItemEntitySetupBeneficialOwnerSubmissionIdentityDocumentSubmissionsItemSubmittedAddressLine2 =
  string | null;

export type FraudKnownBadSubmissionsSearchResponseDataItemEntityAnyOfSetupSubmissionStructureCorporationAnyOfEntitySetupCorporationBeneficialOwnerRelationshipsItemEntitySetupBeneficialOwnerSubmissionIdentityDocumentSubmissionsItemSubmittedAddress =
  {
    city: string;
    line1: string;
    line2: FraudKnownBadSubmissionsSearchResponseDataItemEntityAnyOfSetupSubmissionStructureCorporationAnyOfEntitySetupCorporationBeneficialOwnerRelationshipsItemEntitySetupBeneficialOwnerSubmissionIdentityDocumentSubmissionsItemSubmittedAddressLine2;
    state: string;
    zip: string;
  };

export type FraudKnownBadSubmissionsSearchResponseDataItemEntityAnyOfSetupSubmissionStructureCorporationAnyOfEntitySetupCorporationBeneficialOwnerRelationshipsItemEntitySetupBeneficialOwnerSubmissionIdentityDocumentSubmissionsItemIdentityVerificationStatus =
  (typeof FraudKnownBadSubmissionsSearchResponseDataItemEntityAnyOfSetupSubmissionStructureCorporationAnyOfEntitySetupCorporationBeneficialOwnerRelationshipsItemEntitySetupBeneficialOwnerSubmissionIdentityDocumentSubmissionsItemIdentityVerificationStatus)[keyof typeof FraudKnownBadSubmissionsSearchResponseDataItemEntityAnyOfSetupSubmissionStructureCorporationAnyOfEntitySetupCorporationBeneficialOwnerRelationshipsItemEntitySetupBeneficialOwnerSubmissionIdentityDocumentSubmissionsItemIdentityVerificationStatus];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const FraudKnownBadSubmissionsSearchResponseDataItemEntityAnyOfSetupSubmissionStructureCorporationAnyOfEntitySetupCorporationBeneficialOwnerRelationshipsItemEntitySetupBeneficialOwnerSubmissionIdentityDocumentSubmissionsItemIdentityVerificationStatus =
  {
    pending_verification: "pending_verification",
    update_pending_verification: "update_pending_verification",
    verified: "verified",
    falsified: "falsified",
    no_match: "no_match",
    verifying: "verifying",
    requires_attention: "requires_attention",
    not_performed: "not_performed",
    skipped: "skipped",
  } as const;

export type FraudKnownBadSubmissionsSearchResponseDataItemEntityAnyOfSetupSubmissionStructureCorporationAnyOfEntitySetupCorporationBeneficialOwnerRelationshipsItemEntitySetupBeneficialOwnerSubmissionIdentityDocumentSubmissionsItemApiBackFileId =
  string | null;

export type FraudKnownBadSubmissionsSearchResponseDataItemEntityAnyOfSetupSubmissionStructureCorporationAnyOfEntitySetupCorporationBeneficialOwnerRelationshipsItemEntitySetupBeneficialOwnerSubmissionIdentityDocumentSubmissionsItem =
  {
    api_back_file_id: FraudKnownBadSubmissionsSearchResponseDataItemEntityAnyOfSetupSubmissionStructureCorporationAnyOfEntitySetupCorporationBeneficialOwnerRelationshipsItemEntitySetupBeneficialOwnerSubmissionIdentityDocumentSubmissionsItemApiBackFileId;
    api_file_id: string;
    created_at: string;
    group_id: string;
    id: string;
    identity_verification_status: FraudKnownBadSubmissionsSearchResponseDataItemEntityAnyOfSetupSubmissionStructureCorporationAnyOfEntitySetupCorporationBeneficialOwnerRelationshipsItemEntitySetupBeneficialOwnerSubmissionIdentityDocumentSubmissionsItemIdentityVerificationStatus;
    submitted_address: FraudKnownBadSubmissionsSearchResponseDataItemEntityAnyOfSetupSubmissionStructureCorporationAnyOfEntitySetupCorporationBeneficialOwnerRelationshipsItemEntitySetupBeneficialOwnerSubmissionIdentityDocumentSubmissionsItemSubmittedAddress;
    submitted_country: string;
    submitted_date_of_birth: string;
    submitted_document_number: FraudKnownBadSubmissionsSearchResponseDataItemEntityAnyOfSetupSubmissionStructureCorporationAnyOfEntitySetupCorporationBeneficialOwnerRelationshipsItemEntitySetupBeneficialOwnerSubmissionIdentityDocumentSubmissionsItemSubmittedDocumentNumber;
    submitted_document_type: FraudKnownBadSubmissionsSearchResponseDataItemEntityAnyOfSetupSubmissionStructureCorporationAnyOfEntitySetupCorporationBeneficialOwnerRelationshipsItemEntitySetupBeneficialOwnerSubmissionIdentityDocumentSubmissionsItemSubmittedDocumentType;
    submitted_expiration_date: FraudKnownBadSubmissionsSearchResponseDataItemEntityAnyOfSetupSubmissionStructureCorporationAnyOfEntitySetupCorporationBeneficialOwnerRelationshipsItemEntitySetupBeneficialOwnerSubmissionIdentityDocumentSubmissionsItemSubmittedExpirationDate;
    submitted_name: string;
    submitted_state: FraudKnownBadSubmissionsSearchResponseDataItemEntityAnyOfSetupSubmissionStructureCorporationAnyOfEntitySetupCorporationBeneficialOwnerRelationshipsItemEntitySetupBeneficialOwnerSubmissionIdentityDocumentSubmissionsItemSubmittedState;
  };

export type FraudKnownBadSubmissionsSearchResponseDataItemEntityAnyOfSetupSubmissionStructureCorporationAnyOfEntitySetupCorporationBeneficialOwnerRelationshipsItemEntitySetupBeneficialOwnerSubmission =
  {
    created_at: string;
    id: string;
    identity_document_submissions: FraudKnownBadSubmissionsSearchResponseDataItemEntityAnyOfSetupSubmissionStructureCorporationAnyOfEntitySetupCorporationBeneficialOwnerRelationshipsItemEntitySetupBeneficialOwnerSubmissionIdentityDocumentSubmissionsItem[];
    identity_verification_status: FraudKnownBadSubmissionsSearchResponseDataItemEntityAnyOfSetupSubmissionStructureCorporationAnyOfEntitySetupCorporationBeneficialOwnerRelationshipsItemEntitySetupBeneficialOwnerSubmissionIdentityVerificationStatus;
    microbilt: FraudKnownBadSubmissionsSearchResponseDataItemEntityAnyOfSetupSubmissionStructureCorporationAnyOfEntitySetupCorporationBeneficialOwnerRelationshipsItemEntitySetupBeneficialOwnerSubmissionMicrobiltItem[];
    submission_sovos_response_id: FraudKnownBadSubmissionsSearchResponseDataItemEntityAnyOfSetupSubmissionStructureCorporationAnyOfEntitySetupCorporationBeneficialOwnerRelationshipsItemEntitySetupBeneficialOwnerSubmissionSubmissionSovosResponseId;
    submitted_address: FraudKnownBadSubmissionsSearchResponseDataItemEntityAnyOfSetupSubmissionStructureCorporationAnyOfEntitySetupCorporationBeneficialOwnerRelationshipsItemEntitySetupBeneficialOwnerSubmissionSubmittedAddress;
    submitted_date_of_birth: string;
    submitted_identification_method: FraudKnownBadSubmissionsSearchResponseDataItemEntityAnyOfSetupSubmissionStructureCorporationAnyOfEntitySetupCorporationBeneficialOwnerRelationshipsItemEntitySetupBeneficialOwnerSubmissionSubmittedIdentificationMethod;
    submitted_identification_number_last4: FraudKnownBadSubmissionsSearchResponseDataItemEntityAnyOfSetupSubmissionStructureCorporationAnyOfEntitySetupCorporationBeneficialOwnerRelationshipsItemEntitySetupBeneficialOwnerSubmissionSubmittedIdentificationNumberLast4;
    submitted_name: string;
    tax_id_validation_status: FraudKnownBadSubmissionsSearchResponseDataItemEntityAnyOfSetupSubmissionStructureCorporationAnyOfEntitySetupCorporationBeneficialOwnerRelationshipsItemEntitySetupBeneficialOwnerSubmissionTaxIdValidationStatus;
  };

export type FraudKnownBadSubmissionsSearchResponseDataItemEntityAnyOfSetupSubmissionStructureCorporationAnyOfCobaltIntelligenceSecretaryOfStateSearchIntentsItemSubmittedState =
  string | null;

export type FraudKnownBadSubmissionsSearchResponseDataItemEntityAnyOfSetupSubmissionStructureCorporationAnyOfCobaltIntelligenceSecretaryOfStateSearchIntentsItemStatus =
  (typeof FraudKnownBadSubmissionsSearchResponseDataItemEntityAnyOfSetupSubmissionStructureCorporationAnyOfCobaltIntelligenceSecretaryOfStateSearchIntentsItemStatus)[keyof typeof FraudKnownBadSubmissionsSearchResponseDataItemEntityAnyOfSetupSubmissionStructureCorporationAnyOfCobaltIntelligenceSecretaryOfStateSearchIntentsItemStatus];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const FraudKnownBadSubmissionsSearchResponseDataItemEntityAnyOfSetupSubmissionStructureCorporationAnyOfCobaltIntelligenceSecretaryOfStateSearchIntentsItemStatus =
  {
    pending: "pending",
    searching: "searching",
    retrying: "retrying",
    complete: "complete",
    failed: "failed",
    requires_attention: "requires_attention",
    skipped: "skipped",
  } as const;

export type FraudKnownBadSubmissionsSearchResponseDataItemEntityAnyOfSetupSubmissionStructureCorporationAnyOfCobaltIntelligenceSecretaryOfStateSearchIntentsItemSearchStrategy =
  (typeof FraudKnownBadSubmissionsSearchResponseDataItemEntityAnyOfSetupSubmissionStructureCorporationAnyOfCobaltIntelligenceSecretaryOfStateSearchIntentsItemSearchStrategy)[keyof typeof FraudKnownBadSubmissionsSearchResponseDataItemEntityAnyOfSetupSubmissionStructureCorporationAnyOfCobaltIntelligenceSecretaryOfStateSearchIntentsItemSearchStrategy];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const FraudKnownBadSubmissionsSearchResponseDataItemEntityAnyOfSetupSubmissionStructureCorporationAnyOfCobaltIntelligenceSecretaryOfStateSearchIntentsItemSearchStrategy =
  {
    incorporation_state: "incorporation_state",
    address_state: "address_state",
  } as const;

export type FraudKnownBadSubmissionsSearchResponseDataItemEntityAnyOfSetupSubmissionStructureCorporationAnyOfCobaltIntelligenceSecretaryOfStateSearchIntentsItemSearchResponseAnyOf =
  {
    id: string;
    response_body: string;
  };

export type FraudKnownBadSubmissionsSearchResponseDataItemEntityAnyOfSetupSubmissionStructureCorporationAnyOfCobaltIntelligenceSecretaryOfStateSearchIntentsItemSearchResponse =
  FraudKnownBadSubmissionsSearchResponseDataItemEntityAnyOfSetupSubmissionStructureCorporationAnyOfCobaltIntelligenceSecretaryOfStateSearchIntentsItemSearchResponseAnyOf | null;

export type FraudKnownBadSubmissionsSearchResponseDataItemEntityAnyOfSetupSubmissionStructureCorporationAnyOfCobaltIntelligenceSecretaryOfStateSearchIntentsItemOutcome =
  "found" | "not_found" | null;

export type FraudKnownBadSubmissionsSearchResponseDataItemEntityAnyOfSetupSubmissionStructureCorporationAnyOfCobaltIntelligenceSecretaryOfStateSearchIntentsItem =
  {
    created_at: string;
    entity_id: string;
    id: string;
    outcome: FraudKnownBadSubmissionsSearchResponseDataItemEntityAnyOfSetupSubmissionStructureCorporationAnyOfCobaltIntelligenceSecretaryOfStateSearchIntentsItemOutcome;
    search_response: FraudKnownBadSubmissionsSearchResponseDataItemEntityAnyOfSetupSubmissionStructureCorporationAnyOfCobaltIntelligenceSecretaryOfStateSearchIntentsItemSearchResponse;
    search_strategy: FraudKnownBadSubmissionsSearchResponseDataItemEntityAnyOfSetupSubmissionStructureCorporationAnyOfCobaltIntelligenceSecretaryOfStateSearchIntentsItemSearchStrategy;
    searchable_submission_class: string;
    searchable_submission_id: string;
    status: FraudKnownBadSubmissionsSearchResponseDataItemEntityAnyOfSetupSubmissionStructureCorporationAnyOfCobaltIntelligenceSecretaryOfStateSearchIntentsItemStatus;
    submitted_address_state: string;
    submitted_name: string;
    submitted_state: FraudKnownBadSubmissionsSearchResponseDataItemEntityAnyOfSetupSubmissionStructureCorporationAnyOfCobaltIntelligenceSecretaryOfStateSearchIntentsItemSubmittedState;
  };

export type FraudKnownBadSubmissionsSearchResponseDataItemEntityAnyOfSetupSubmissionStructureCorporationAnyOfBeneficialOwnershipExemption =
  "regulated_financial_institution" | "publicly_traded_company" | null;

export type FraudKnownBadSubmissionsSearchResponseDataItemEntityAnyOfReviewStatus =
  (typeof FraudKnownBadSubmissionsSearchResponseDataItemEntityAnyOfReviewStatus)[keyof typeof FraudKnownBadSubmissionsSearchResponseDataItemEntityAnyOfReviewStatus];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const FraudKnownBadSubmissionsSearchResponseDataItemEntityAnyOfReviewStatus =
  {
    pending_reviewing: "pending_reviewing",
    review_not_required: "review_not_required",
    approved: "approved",
    approved_by_platform: "approved_by_platform",
    returned_to_user: "returned_to_user",
    requires_attention: "requires_attention",
  } as const;

export type FraudKnownBadSubmissionsSearchResponseDataItemEntityAnyOfCustomerInformationPolicyProblemsItem =
  (typeof FraudKnownBadSubmissionsSearchResponseDataItemEntityAnyOfCustomerInformationPolicyProblemsItem)[keyof typeof FraudKnownBadSubmissionsSearchResponseDataItemEntityAnyOfCustomerInformationPolicyProblemsItem];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const FraudKnownBadSubmissionsSearchResponseDataItemEntityAnyOfCustomerInformationPolicyProblemsItem =
  {
    details_need_initial_confirmation: "details_need_initial_confirmation",
    details_need_reconfirmation: "details_need_reconfirmation",
    missing_controller: "missing_controller",
    tax_id_not_validated: "tax_id_not_validated",
    identity_not_verified: "identity_not_verified",
    full_tax_id_required_unless_non_us_citizen:
      "full_tax_id_required_unless_non_us_citizen",
    physical_address_not_valid: "physical_address_not_valid",
    missing_grantor: "missing_grantor",
  } as const;

export type FraudKnownBadSubmissionsSearchResponseDataItemEntityAnyOfAutomatedCustomerIdentificationProgramEvaluation =
  (typeof FraudKnownBadSubmissionsSearchResponseDataItemEntityAnyOfAutomatedCustomerIdentificationProgramEvaluation)[keyof typeof FraudKnownBadSubmissionsSearchResponseDataItemEntityAnyOfAutomatedCustomerIdentificationProgramEvaluation];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const FraudKnownBadSubmissionsSearchResponseDataItemEntityAnyOfAutomatedCustomerIdentificationProgramEvaluation =
  {
    valid: "valid",
    indeterminate: "indeterminate",
    invalid: "invalid",
  } as const;

export type FraudKnownBadSubmissionsSearchResponseDataItemEntityAnyOfApprovedBy =
  string | null;

export type FraudKnownBadSubmissionsSearchResponseDataItemEntityAnyOfApprovedAt =
  string | null;

export type FlagsGetResponseFlagsItem = {
  name: string;
  production: boolean;
  sandbox: boolean;
};

export interface FlagsGetResponse {
  flags: FlagsGetResponseFlagsItem[];
}

export type FinancialCrimesEnforcementNetworkThreeFourteenAPersonListResponseNextCursor =
  string | null;

export interface FinancialCrimesEnforcementNetworkThreeFourteenAPersonListResponse {
  data: FinancialCrimesEnforcementNetworkThreeFourteenAPersonListResponseDataItem[];
  next_cursor: FinancialCrimesEnforcementNetworkThreeFourteenAPersonListResponseNextCursor;
}

export type FinancialCrimesEnforcementNetworkThreeFourteenAPersonListResponseDataItemZip =
  string | null;

export type FinancialCrimesEnforcementNetworkThreeFourteenAPersonListResponseDataItemSuffix =
  string | null;

export type FinancialCrimesEnforcementNetworkThreeFourteenAPersonListResponseDataItemStreet =
  string | null;

export type FinancialCrimesEnforcementNetworkThreeFourteenAPersonListResponseDataItemStatus =
  (typeof FinancialCrimesEnforcementNetworkThreeFourteenAPersonListResponseDataItemStatus)[keyof typeof FinancialCrimesEnforcementNetworkThreeFourteenAPersonListResponseDataItemStatus];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const FinancialCrimesEnforcementNetworkThreeFourteenAPersonListResponseDataItemStatus =
  {
    pending_allocating: "pending_allocating",
    pending_reviewing: "pending_reviewing",
    allocated_no_matches: "allocated_no_matches",
    allocated_with_matches: "allocated_with_matches",
    requires_attention: "requires_attention",
  } as const;

export type FinancialCrimesEnforcementNetworkThreeFourteenAPersonListResponseDataItemState =
  string | null;

export type FinancialCrimesEnforcementNetworkThreeFourteenAPersonListResponseDataItemPhone =
  string | null;

export type FinancialCrimesEnforcementNetworkThreeFourteenAPersonListResponseDataItemNumberType =

    | "social_security_number"
    | "drivers_license"
    | "alien_registration"
    | "passport"
    | "other"
    | null;

export type FinancialCrimesEnforcementNetworkThreeFourteenAPersonListResponseDataItemNumber =
  string | null;

export type FinancialCrimesEnforcementNetworkThreeFourteenAPersonListResponseDataItemMiddleName =
  string | null;

export type FinancialCrimesEnforcementNetworkThreeFourteenAPersonListResponseDataItemLastName =
  string | null;

export type FinancialCrimesEnforcementNetworkThreeFourteenAPersonListResponseDataItemFirstName =
  string | null;

export type FinancialCrimesEnforcementNetworkThreeFourteenAPersonListResponseDataItemElectronicAddressType =
  "tax_identification_number" | "employer_identification_number" | null;

export type FinancialCrimesEnforcementNetworkThreeFourteenAPersonListResponseDataItemElectronicAddress =
  string | null;

export type FinancialCrimesEnforcementNetworkThreeFourteenAPersonListResponseDataItemDateOfBirth =
  string | null;

export type FinancialCrimesEnforcementNetworkThreeFourteenAPersonListResponseDataItemCountry =
  string | null;

export type FinancialCrimesEnforcementNetworkThreeFourteenAPersonListResponseDataItemCity =
  string | null;

export type FinancialCrimesEnforcementNetworkThreeFourteenAPersonListResponseDataItemAliasSuffix =
  string | null;

export type FinancialCrimesEnforcementNetworkThreeFourteenAPersonListResponseDataItemAliasMiddleName =
  string | null;

export type FinancialCrimesEnforcementNetworkThreeFourteenAPersonListResponseDataItemAliasLastName =
  string | null;

export type FinancialCrimesEnforcementNetworkThreeFourteenAPersonListResponseDataItemAliasFirstName =
  string | null;

export type FinancialCrimesEnforcementNetworkThreeFourteenAPersonListResponseDataItem =
  {
    alias_first_name: FinancialCrimesEnforcementNetworkThreeFourteenAPersonListResponseDataItemAliasFirstName;
    alias_last_name: FinancialCrimesEnforcementNetworkThreeFourteenAPersonListResponseDataItemAliasLastName;
    alias_middle_name: FinancialCrimesEnforcementNetworkThreeFourteenAPersonListResponseDataItemAliasMiddleName;
    alias_suffix: FinancialCrimesEnforcementNetworkThreeFourteenAPersonListResponseDataItemAliasSuffix;
    city: FinancialCrimesEnforcementNetworkThreeFourteenAPersonListResponseDataItemCity;
    country: FinancialCrimesEnforcementNetworkThreeFourteenAPersonListResponseDataItemCountry;
    created_at: string;
    date_of_birth: FinancialCrimesEnforcementNetworkThreeFourteenAPersonListResponseDataItemDateOfBirth;
    electronic_address: FinancialCrimesEnforcementNetworkThreeFourteenAPersonListResponseDataItemElectronicAddress;
    electronic_address_type: FinancialCrimesEnforcementNetworkThreeFourteenAPersonListResponseDataItemElectronicAddressType;
    file_id: string;
    first_name: FinancialCrimesEnforcementNetworkThreeFourteenAPersonListResponseDataItemFirstName;
    id: string;
    last_name: FinancialCrimesEnforcementNetworkThreeFourteenAPersonListResponseDataItemLastName;
    middle_name: FinancialCrimesEnforcementNetworkThreeFourteenAPersonListResponseDataItemMiddleName;
    number: FinancialCrimesEnforcementNetworkThreeFourteenAPersonListResponseDataItemNumber;
    number_type: FinancialCrimesEnforcementNetworkThreeFourteenAPersonListResponseDataItemNumberType;
    phone: FinancialCrimesEnforcementNetworkThreeFourteenAPersonListResponseDataItemPhone;
    state: FinancialCrimesEnforcementNetworkThreeFourteenAPersonListResponseDataItemState;
    status: FinancialCrimesEnforcementNetworkThreeFourteenAPersonListResponseDataItemStatus;
    street: FinancialCrimesEnforcementNetworkThreeFourteenAPersonListResponseDataItemStreet;
    suffix: FinancialCrimesEnforcementNetworkThreeFourteenAPersonListResponseDataItemSuffix;
    tracking_number: string;
    zip: FinancialCrimesEnforcementNetworkThreeFourteenAPersonListResponseDataItemZip;
  };

export type FinancialCrimesEnforcementNetworkThreeFourteenAPersonGetResponseZip =
  string | null;

export type FinancialCrimesEnforcementNetworkThreeFourteenAPersonGetResponseSuffix =
  string | null;

export type FinancialCrimesEnforcementNetworkThreeFourteenAPersonGetResponseStreet =
  string | null;

export type FinancialCrimesEnforcementNetworkThreeFourteenAPersonGetResponseStatus =
  (typeof FinancialCrimesEnforcementNetworkThreeFourteenAPersonGetResponseStatus)[keyof typeof FinancialCrimesEnforcementNetworkThreeFourteenAPersonGetResponseStatus];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const FinancialCrimesEnforcementNetworkThreeFourteenAPersonGetResponseStatus =
  {
    pending_allocating: "pending_allocating",
    pending_reviewing: "pending_reviewing",
    allocated_no_matches: "allocated_no_matches",
    allocated_with_matches: "allocated_with_matches",
    requires_attention: "requires_attention",
  } as const;

export type FinancialCrimesEnforcementNetworkThreeFourteenAPersonGetResponseState =
  string | null;

export type FinancialCrimesEnforcementNetworkThreeFourteenAPersonGetResponsePhone =
  string | null;

export type FinancialCrimesEnforcementNetworkThreeFourteenAPersonGetResponseNumberType =

    | "social_security_number"
    | "drivers_license"
    | "alien_registration"
    | "passport"
    | "other"
    | null;

export type FinancialCrimesEnforcementNetworkThreeFourteenAPersonGetResponseNumber =
  string | null;

export type FinancialCrimesEnforcementNetworkThreeFourteenAPersonGetResponseMiddleName =
  string | null;

export type FinancialCrimesEnforcementNetworkThreeFourteenAPersonGetResponseLastName =
  string | null;

export type FinancialCrimesEnforcementNetworkThreeFourteenAPersonGetResponseFirstName =
  string | null;

export type FinancialCrimesEnforcementNetworkThreeFourteenAPersonGetResponseElectronicAddressType =
  "tax_identification_number" | "employer_identification_number" | null;

export type FinancialCrimesEnforcementNetworkThreeFourteenAPersonGetResponseElectronicAddress =
  string | null;

export type FinancialCrimesEnforcementNetworkThreeFourteenAPersonGetResponseDateOfBirth =
  string | null;

export type FinancialCrimesEnforcementNetworkThreeFourteenAPersonGetResponseCountry =
  string | null;

export type FinancialCrimesEnforcementNetworkThreeFourteenAPersonGetResponseCity =
  string | null;

export type FinancialCrimesEnforcementNetworkThreeFourteenAPersonGetResponseAliasSuffix =
  string | null;

export type FinancialCrimesEnforcementNetworkThreeFourteenAPersonGetResponseAliasMiddleName =
  string | null;

export type FinancialCrimesEnforcementNetworkThreeFourteenAPersonGetResponseAliasLastName =
  string | null;

export type FinancialCrimesEnforcementNetworkThreeFourteenAPersonGetResponseAliasFirstName =
  string | null;

export interface FinancialCrimesEnforcementNetworkThreeFourteenAPersonGetResponse {
  alias_first_name: FinancialCrimesEnforcementNetworkThreeFourteenAPersonGetResponseAliasFirstName;
  alias_last_name: FinancialCrimesEnforcementNetworkThreeFourteenAPersonGetResponseAliasLastName;
  alias_middle_name: FinancialCrimesEnforcementNetworkThreeFourteenAPersonGetResponseAliasMiddleName;
  alias_suffix: FinancialCrimesEnforcementNetworkThreeFourteenAPersonGetResponseAliasSuffix;
  city: FinancialCrimesEnforcementNetworkThreeFourteenAPersonGetResponseCity;
  country: FinancialCrimesEnforcementNetworkThreeFourteenAPersonGetResponseCountry;
  created_at: string;
  date_of_birth: FinancialCrimesEnforcementNetworkThreeFourteenAPersonGetResponseDateOfBirth;
  electronic_address: FinancialCrimesEnforcementNetworkThreeFourteenAPersonGetResponseElectronicAddress;
  electronic_address_type: FinancialCrimesEnforcementNetworkThreeFourteenAPersonGetResponseElectronicAddressType;
  file_id: string;
  first_name: FinancialCrimesEnforcementNetworkThreeFourteenAPersonGetResponseFirstName;
  id: string;
  last_name: FinancialCrimesEnforcementNetworkThreeFourteenAPersonGetResponseLastName;
  middle_name: FinancialCrimesEnforcementNetworkThreeFourteenAPersonGetResponseMiddleName;
  number: FinancialCrimesEnforcementNetworkThreeFourteenAPersonGetResponseNumber;
  number_type: FinancialCrimesEnforcementNetworkThreeFourteenAPersonGetResponseNumberType;
  phone: FinancialCrimesEnforcementNetworkThreeFourteenAPersonGetResponsePhone;
  state: FinancialCrimesEnforcementNetworkThreeFourteenAPersonGetResponseState;
  status: FinancialCrimesEnforcementNetworkThreeFourteenAPersonGetResponseStatus;
  street: FinancialCrimesEnforcementNetworkThreeFourteenAPersonGetResponseStreet;
  suffix: FinancialCrimesEnforcementNetworkThreeFourteenAPersonGetResponseSuffix;
  tracking_number: string;
  zip: FinancialCrimesEnforcementNetworkThreeFourteenAPersonGetResponseZip;
}

export interface FinancialCrimesEnforcementNetworkThreeFourteenAFilePostResponse {
  [key: string]: unknown;
}

export interface FinancialCrimesEnforcementNetworkThreeFourteenAFilePostParameters {
  file: Blob;
}

export type FinancialCrimesEnforcementNetworkThreeFourteenAFileListResponseNextCursor =
  string | null;

export interface FinancialCrimesEnforcementNetworkThreeFourteenAFileListResponse {
  data: FinancialCrimesEnforcementNetworkThreeFourteenAFileListResponseDataItem[];
  next_cursor: FinancialCrimesEnforcementNetworkThreeFourteenAFileListResponseNextCursor;
}

export type FinancialCrimesEnforcementNetworkThreeFourteenAFileListResponseDataItemType =
  (typeof FinancialCrimesEnforcementNetworkThreeFourteenAFileListResponseDataItemType)[keyof typeof FinancialCrimesEnforcementNetworkThreeFourteenAFileListResponseDataItemType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const FinancialCrimesEnforcementNetworkThreeFourteenAFileListResponseDataItemType =
  {
    business: "business",
    person: "person",
  } as const;

export type FinancialCrimesEnforcementNetworkThreeFourteenAFileListResponseDataItemStatus =
  (typeof FinancialCrimesEnforcementNetworkThreeFourteenAFileListResponseDataItemStatus)[keyof typeof FinancialCrimesEnforcementNetworkThreeFourteenAFileListResponseDataItemStatus];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const FinancialCrimesEnforcementNetworkThreeFourteenAFileListResponseDataItemStatus =
  {
    completed: "completed",
    pending: "pending",
  } as const;

export type FinancialCrimesEnforcementNetworkThreeFourteenAFileListResponseDataItemBank =
  (typeof FinancialCrimesEnforcementNetworkThreeFourteenAFileListResponseDataItemBank)[keyof typeof FinancialCrimesEnforcementNetworkThreeFourteenAFileListResponseDataItemBank];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const FinancialCrimesEnforcementNetworkThreeFourteenAFileListResponseDataItemBank =
  {
    blue_ridge_bank: "blue_ridge_bank",
    core_bank: "core_bank",
    first_internet_bank: "first_internet_bank",
    global_innovations_bank: "global_innovations_bank",
    grasshopper_bank: "grasshopper_bank",
  } as const;

export type FinancialCrimesEnforcementNetworkThreeFourteenAFileListResponseDataItem =
  {
    bank: FinancialCrimesEnforcementNetworkThreeFourteenAFileListResponseDataItemBank;
    created_at: string;
    entries_count: number;
    id: string;
    inbound_file_transmission_name: string;
    status: FinancialCrimesEnforcementNetworkThreeFourteenAFileListResponseDataItemStatus;
    type: FinancialCrimesEnforcementNetworkThreeFourteenAFileListResponseDataItemType;
  };

export type FinancialCrimesEnforcementNetworkThreeFourteenAFileGetResponseType =
  (typeof FinancialCrimesEnforcementNetworkThreeFourteenAFileGetResponseType)[keyof typeof FinancialCrimesEnforcementNetworkThreeFourteenAFileGetResponseType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const FinancialCrimesEnforcementNetworkThreeFourteenAFileGetResponseType =
  {
    business: "business",
    person: "person",
  } as const;

export type FinancialCrimesEnforcementNetworkThreeFourteenAFileGetResponseStatus =
  (typeof FinancialCrimesEnforcementNetworkThreeFourteenAFileGetResponseStatus)[keyof typeof FinancialCrimesEnforcementNetworkThreeFourteenAFileGetResponseStatus];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const FinancialCrimesEnforcementNetworkThreeFourteenAFileGetResponseStatus =
  {
    completed: "completed",
    pending: "pending",
  } as const;

export type FinancialCrimesEnforcementNetworkThreeFourteenAFileGetResponseBank =
  (typeof FinancialCrimesEnforcementNetworkThreeFourteenAFileGetResponseBank)[keyof typeof FinancialCrimesEnforcementNetworkThreeFourteenAFileGetResponseBank];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const FinancialCrimesEnforcementNetworkThreeFourteenAFileGetResponseBank =
  {
    blue_ridge_bank: "blue_ridge_bank",
    core_bank: "core_bank",
    first_internet_bank: "first_internet_bank",
    global_innovations_bank: "global_innovations_bank",
    grasshopper_bank: "grasshopper_bank",
  } as const;

export interface FinancialCrimesEnforcementNetworkThreeFourteenAFileGetResponse {
  bank: FinancialCrimesEnforcementNetworkThreeFourteenAFileGetResponseBank;
  created_at: string;
  entries_count: number;
  id: string;
  inbound_file_transmission_name: string;
  status: FinancialCrimesEnforcementNetworkThreeFourteenAFileGetResponseStatus;
  type: FinancialCrimesEnforcementNetworkThreeFourteenAFileGetResponseType;
}

export type FinancialCrimesEnforcementNetworkThreeFourteenAEntryEntityMatchPatchResponseStatus =
  (typeof FinancialCrimesEnforcementNetworkThreeFourteenAEntryEntityMatchPatchResponseStatus)[keyof typeof FinancialCrimesEnforcementNetworkThreeFourteenAEntryEntityMatchPatchResponseStatus];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const FinancialCrimesEnforcementNetworkThreeFourteenAEntryEntityMatchPatchResponseStatus =
  {
    pending_reviewing: "pending_reviewing",
    false_positive: "false_positive",
    true_match: "true_match",
    requires_attention: "requires_attention",
  } as const;

export type FinancialCrimesEnforcementNetworkThreeFourteenAEntryEntityMatchPatchResponseEntityStructure =
  (typeof FinancialCrimesEnforcementNetworkThreeFourteenAEntryEntityMatchPatchResponseEntityStructure)[keyof typeof FinancialCrimesEnforcementNetworkThreeFourteenAEntryEntityMatchPatchResponseEntityStructure];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const FinancialCrimesEnforcementNetworkThreeFourteenAEntryEntityMatchPatchResponseEntityStructure =
  {
    corporation: "corporation",
    natural_person: "natural_person",
    joint: "joint",
    trust: "trust",
    government_authority: "government_authority",
  } as const;

export interface FinancialCrimesEnforcementNetworkThreeFourteenAEntryEntityMatchPatchResponse {
  created_at: string;
  entity_id: string;
  entity_name: string;
  entity_structure: FinancialCrimesEnforcementNetworkThreeFourteenAEntryEntityMatchPatchResponseEntityStructure;
  entry_id: string;
  id: string;
  status: FinancialCrimesEnforcementNetworkThreeFourteenAEntryEntityMatchPatchResponseStatus;
}

export type FinancialCrimesEnforcementNetworkThreeFourteenAEntryEntityMatchPatchParametersStatus =
  (typeof FinancialCrimesEnforcementNetworkThreeFourteenAEntryEntityMatchPatchParametersStatus)[keyof typeof FinancialCrimesEnforcementNetworkThreeFourteenAEntryEntityMatchPatchParametersStatus];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const FinancialCrimesEnforcementNetworkThreeFourteenAEntryEntityMatchPatchParametersStatus =
  {
    pending_reviewing: "pending_reviewing",
    false_positive: "false_positive",
    true_match: "true_match",
    requires_attention: "requires_attention",
  } as const;

export interface FinancialCrimesEnforcementNetworkThreeFourteenAEntryEntityMatchPatchParameters {
  status: FinancialCrimesEnforcementNetworkThreeFourteenAEntryEntityMatchPatchParametersStatus;
}

export type FinancialCrimesEnforcementNetworkThreeFourteenAEntryEntityMatchListResponseNextCursor =
  string | null;

export interface FinancialCrimesEnforcementNetworkThreeFourteenAEntryEntityMatchListResponse {
  data: FinancialCrimesEnforcementNetworkThreeFourteenAEntryEntityMatchListResponseDataItem[];
  next_cursor: FinancialCrimesEnforcementNetworkThreeFourteenAEntryEntityMatchListResponseNextCursor;
}

export type FinancialCrimesEnforcementNetworkThreeFourteenAEntryEntityMatchListResponseDataItemStatus =
  (typeof FinancialCrimesEnforcementNetworkThreeFourteenAEntryEntityMatchListResponseDataItemStatus)[keyof typeof FinancialCrimesEnforcementNetworkThreeFourteenAEntryEntityMatchListResponseDataItemStatus];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const FinancialCrimesEnforcementNetworkThreeFourteenAEntryEntityMatchListResponseDataItemStatus =
  {
    pending_reviewing: "pending_reviewing",
    false_positive: "false_positive",
    true_match: "true_match",
    requires_attention: "requires_attention",
  } as const;

export type FinancialCrimesEnforcementNetworkThreeFourteenAEntryEntityMatchListResponseDataItemEntityStructure =
  (typeof FinancialCrimesEnforcementNetworkThreeFourteenAEntryEntityMatchListResponseDataItemEntityStructure)[keyof typeof FinancialCrimesEnforcementNetworkThreeFourteenAEntryEntityMatchListResponseDataItemEntityStructure];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const FinancialCrimesEnforcementNetworkThreeFourteenAEntryEntityMatchListResponseDataItemEntityStructure =
  {
    corporation: "corporation",
    natural_person: "natural_person",
    joint: "joint",
    trust: "trust",
    government_authority: "government_authority",
  } as const;

export type FinancialCrimesEnforcementNetworkThreeFourteenAEntryEntityMatchListResponseDataItem =
  {
    created_at: string;
    entity_id: string;
    entity_name: string;
    entity_structure: FinancialCrimesEnforcementNetworkThreeFourteenAEntryEntityMatchListResponseDataItemEntityStructure;
    entry_id: string;
    id: string;
    status: FinancialCrimesEnforcementNetworkThreeFourteenAEntryEntityMatchListResponseDataItemStatus;
  };

export type FinancialCrimesEnforcementNetworkThreeFourteenABusinessListResponseNextCursor =
  string | null;

export interface FinancialCrimesEnforcementNetworkThreeFourteenABusinessListResponse {
  data: FinancialCrimesEnforcementNetworkThreeFourteenABusinessListResponseDataItem[];
  next_cursor: FinancialCrimesEnforcementNetworkThreeFourteenABusinessListResponseNextCursor;
}

export type FinancialCrimesEnforcementNetworkThreeFourteenABusinessListResponseDataItemZip =
  string | null;

export type FinancialCrimesEnforcementNetworkThreeFourteenABusinessListResponseDataItemStreet =
  string | null;

export type FinancialCrimesEnforcementNetworkThreeFourteenABusinessListResponseDataItemStatus =
  (typeof FinancialCrimesEnforcementNetworkThreeFourteenABusinessListResponseDataItemStatus)[keyof typeof FinancialCrimesEnforcementNetworkThreeFourteenABusinessListResponseDataItemStatus];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const FinancialCrimesEnforcementNetworkThreeFourteenABusinessListResponseDataItemStatus =
  {
    pending_allocating: "pending_allocating",
    pending_reviewing: "pending_reviewing",
    allocated_no_matches: "allocated_no_matches",
    allocated_with_matches: "allocated_with_matches",
    requires_attention: "requires_attention",
  } as const;

export type FinancialCrimesEnforcementNetworkThreeFourteenABusinessListResponseDataItemState =
  string | null;

export type FinancialCrimesEnforcementNetworkThreeFourteenABusinessListResponseDataItemPhone =
  string | null;

export type FinancialCrimesEnforcementNetworkThreeFourteenABusinessListResponseDataItemNumberType =
  "tax_identification_number" | "employer_identification_number" | null;

export type FinancialCrimesEnforcementNetworkThreeFourteenABusinessListResponseDataItemNumber =
  string | null;

export type FinancialCrimesEnforcementNetworkThreeFourteenABusinessListResponseDataItemIncorporated =
  string | null;

export type FinancialCrimesEnforcementNetworkThreeFourteenABusinessListResponseDataItemElectronicAddressType =
  "tax_identification_number" | "employer_identification_number" | null;

export type FinancialCrimesEnforcementNetworkThreeFourteenABusinessListResponseDataItemElectronicAddress =
  string | null;

export type FinancialCrimesEnforcementNetworkThreeFourteenABusinessListResponseDataItemDoingBusinessAsName =
  string | null;

export type FinancialCrimesEnforcementNetworkThreeFourteenABusinessListResponseDataItemCountry =
  string | null;

export type FinancialCrimesEnforcementNetworkThreeFourteenABusinessListResponseDataItemCity =
  string | null;

export type FinancialCrimesEnforcementNetworkThreeFourteenABusinessListResponseDataItemBusinessName =
  string | null;

export type FinancialCrimesEnforcementNetworkThreeFourteenABusinessListResponseDataItem =
  {
    business_name: FinancialCrimesEnforcementNetworkThreeFourteenABusinessListResponseDataItemBusinessName;
    city: FinancialCrimesEnforcementNetworkThreeFourteenABusinessListResponseDataItemCity;
    country: FinancialCrimesEnforcementNetworkThreeFourteenABusinessListResponseDataItemCountry;
    created_at: string;
    doing_business_as_name: FinancialCrimesEnforcementNetworkThreeFourteenABusinessListResponseDataItemDoingBusinessAsName;
    electronic_address: FinancialCrimesEnforcementNetworkThreeFourteenABusinessListResponseDataItemElectronicAddress;
    electronic_address_type: FinancialCrimesEnforcementNetworkThreeFourteenABusinessListResponseDataItemElectronicAddressType;
    file_id: string;
    id: string;
    incorporated: FinancialCrimesEnforcementNetworkThreeFourteenABusinessListResponseDataItemIncorporated;
    number: FinancialCrimesEnforcementNetworkThreeFourteenABusinessListResponseDataItemNumber;
    number_type: FinancialCrimesEnforcementNetworkThreeFourteenABusinessListResponseDataItemNumberType;
    phone: FinancialCrimesEnforcementNetworkThreeFourteenABusinessListResponseDataItemPhone;
    state: FinancialCrimesEnforcementNetworkThreeFourteenABusinessListResponseDataItemState;
    status: FinancialCrimesEnforcementNetworkThreeFourteenABusinessListResponseDataItemStatus;
    street: FinancialCrimesEnforcementNetworkThreeFourteenABusinessListResponseDataItemStreet;
    tracking_number: string;
    zip: FinancialCrimesEnforcementNetworkThreeFourteenABusinessListResponseDataItemZip;
  };

export type FinancialCrimesEnforcementNetworkThreeFourteenABusinessGetResponseZip =
  string | null;

export type FinancialCrimesEnforcementNetworkThreeFourteenABusinessGetResponseStreet =
  string | null;

export type FinancialCrimesEnforcementNetworkThreeFourteenABusinessGetResponseStatus =
  (typeof FinancialCrimesEnforcementNetworkThreeFourteenABusinessGetResponseStatus)[keyof typeof FinancialCrimesEnforcementNetworkThreeFourteenABusinessGetResponseStatus];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const FinancialCrimesEnforcementNetworkThreeFourteenABusinessGetResponseStatus =
  {
    pending_allocating: "pending_allocating",
    pending_reviewing: "pending_reviewing",
    allocated_no_matches: "allocated_no_matches",
    allocated_with_matches: "allocated_with_matches",
    requires_attention: "requires_attention",
  } as const;

export type FinancialCrimesEnforcementNetworkThreeFourteenABusinessGetResponseState =
  string | null;

export type FinancialCrimesEnforcementNetworkThreeFourteenABusinessGetResponsePhone =
  string | null;

export type FinancialCrimesEnforcementNetworkThreeFourteenABusinessGetResponseNumberType =
  "tax_identification_number" | "employer_identification_number" | null;

export type FinancialCrimesEnforcementNetworkThreeFourteenABusinessGetResponseNumber =
  string | null;

export type FinancialCrimesEnforcementNetworkThreeFourteenABusinessGetResponseIncorporated =
  string | null;

export type FinancialCrimesEnforcementNetworkThreeFourteenABusinessGetResponseElectronicAddressType =
  "tax_identification_number" | "employer_identification_number" | null;

export type FinancialCrimesEnforcementNetworkThreeFourteenABusinessGetResponseElectronicAddress =
  string | null;

export type FinancialCrimesEnforcementNetworkThreeFourteenABusinessGetResponseDoingBusinessAsName =
  string | null;

export type FinancialCrimesEnforcementNetworkThreeFourteenABusinessGetResponseCountry =
  string | null;

export type FinancialCrimesEnforcementNetworkThreeFourteenABusinessGetResponseCity =
  string | null;

export type FinancialCrimesEnforcementNetworkThreeFourteenABusinessGetResponseBusinessName =
  string | null;

export interface FinancialCrimesEnforcementNetworkThreeFourteenABusinessGetResponse {
  business_name: FinancialCrimesEnforcementNetworkThreeFourteenABusinessGetResponseBusinessName;
  city: FinancialCrimesEnforcementNetworkThreeFourteenABusinessGetResponseCity;
  country: FinancialCrimesEnforcementNetworkThreeFourteenABusinessGetResponseCountry;
  created_at: string;
  doing_business_as_name: FinancialCrimesEnforcementNetworkThreeFourteenABusinessGetResponseDoingBusinessAsName;
  electronic_address: FinancialCrimesEnforcementNetworkThreeFourteenABusinessGetResponseElectronicAddress;
  electronic_address_type: FinancialCrimesEnforcementNetworkThreeFourteenABusinessGetResponseElectronicAddressType;
  file_id: string;
  id: string;
  incorporated: FinancialCrimesEnforcementNetworkThreeFourteenABusinessGetResponseIncorporated;
  number: FinancialCrimesEnforcementNetworkThreeFourteenABusinessGetResponseNumber;
  number_type: FinancialCrimesEnforcementNetworkThreeFourteenABusinessGetResponseNumberType;
  phone: FinancialCrimesEnforcementNetworkThreeFourteenABusinessGetResponsePhone;
  state: FinancialCrimesEnforcementNetworkThreeFourteenABusinessGetResponseState;
  status: FinancialCrimesEnforcementNetworkThreeFourteenABusinessGetResponseStatus;
  street: FinancialCrimesEnforcementNetworkThreeFourteenABusinessGetResponseStreet;
  tracking_number: string;
  zip: FinancialCrimesEnforcementNetworkThreeFourteenABusinessGetResponseZip;
}

export type FeeStatementListResponseNextCursor = string | null;

export interface FeeStatementListResponse {
  data: FeeStatementListResponseDataItem[];
  next_cursor: FeeStatementListResponseNextCursor;
}

export type FeeStatementListResponseDataItemStatus =
  (typeof FeeStatementListResponseDataItemStatus)[keyof typeof FeeStatementListResponseDataItemStatus];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const FeeStatementListResponseDataItemStatus = {
  open: "open",
  closed: "closed",
} as const;

export type FeeStatementListResponseDataItemPaymentsAmount = number | null;

export type FeeStatementListResponseDataItemFeesAmount = number | null;

export type FeeStatementListResponseDataItemEarnedCreditsAmount = number | null;

export type FeeStatementListResponseDataItem = {
  created_at: string;
  earned_credits_amount: FeeStatementListResponseDataItemEarnedCreditsAmount;
  fees_amount: FeeStatementListResponseDataItemFeesAmount;
  group_id: string;
  id: string;
  payments_amount: FeeStatementListResponseDataItemPaymentsAmount;
  period_end: string;
  period_start: string;
  status: FeeStatementListResponseDataItemStatus;
};

export type FeePlanListResponseNextCursor = string | null;

export type FeePlanListResponseDataItemTerms =
  (typeof FeePlanListResponseDataItemTerms)[keyof typeof FeePlanListResponseDataItemTerms];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const FeePlanListResponseDataItemTerms = {
  sticker: "sticker",
  negotiated: "negotiated",
} as const;

export type FeePlanListResponseDataItemProgramName = string | null;

export type FeePlanListResponseDataItemCurrency =
  (typeof FeePlanListResponseDataItemCurrency)[keyof typeof FeePlanListResponseDataItemCurrency];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const FeePlanListResponseDataItemCurrency = {
  CAD: "CAD",
  CHF: "CHF",
  EUR: "EUR",
  GBP: "GBP",
  JPY: "JPY",
  USD: "USD",
} as const;

export type FeePlanListResponseDataItemBank =
  (typeof FeePlanListResponseDataItemBank)[keyof typeof FeePlanListResponseDataItemBank];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const FeePlanListResponseDataItemBank = {
  blue_ridge_bank: "blue_ridge_bank",
  core_bank: "core_bank",
  first_internet_bank: "first_internet_bank",
  global_innovations_bank: "global_innovations_bank",
  grasshopper_bank: "grasshopper_bank",
} as const;

export type FeePlanListResponseDataItem = {
  /** @pattern ^-?\d+(\.\d+)?$ */
  ach_administrative_return_fee: string;
  /** @pattern ^-?\d+(\.\d+)?$ */
  ach_origination_fee: string;
  /** @pattern ^-?\d+(\.\d+)?$ */
  ach_return_fee: string;
  /** @pattern ^-?\d+(\.\d+)?$ */
  ach_same_day_origination_fee: string;
  /** @pattern ^-?\d+(\.\d+)?$ */
  ach_unauthorized_return_fee: string;
  active: boolean;
  /** @pattern ^-?\d+(\.\d+)?$ */
  balance_fee: string;
  bank: FeePlanListResponseDataItemBank;
  /** @pattern ^-?\d+(\.\d+)?$ */
  card_fee: string;
  /** @pattern ^-?\d+(\.\d+)?$ */
  card_processing_fee_rate: string;
  /** @pattern ^-?\d+(\.\d+)?$ */
  check_deposit_mail_item_fee: string;
  /** @pattern ^-?\d+(\.\d+)?$ */
  check_deposit_return_fee: string;
  /** @pattern ^-?\d+(\.\d+)?$ */
  check_transfer_attachment_page_fee: string;
  /** @pattern ^-?\d+(\.\d+)?$ */
  check_transfer_origination_fee: string;
  /** @pattern ^-?\d+(\.\d+)?$ */
  check_transfer_origination_with_fedex_standard_overnight_fee: string;
  /** @pattern ^-?\d+(\.\d+)?$ */
  check_transfer_without_fulfillment_origination_fee: string;
  created_at: string;
  currency: FeePlanListResponseDataItemCurrency;
  effective_date: string;
  group_id: string;
  id: string;
  /** @pattern ^-?\d+(\.\d+)?$ */
  intrafi_balance_annual_fee_rate: string;
  /** @pattern ^-?\d+(\.\d+)?$ */
  late_return_request_fee: string;
  /** @pattern ^-?\d+(\.\d+)?$ */
  monthly_account_fee: string;
  /** @pattern ^-?\d+(\.\d+)?$ */
  monthly_account_number_fee: string;
  /** @pattern ^-?\d+(\.\d+)?$ */
  monthly_base_fee: string;
  /** @pattern ^-?\d+(\.\d+)?$ */
  monthly_minimum_fee: string;
  /** @pattern ^-?\d+(\.\d+)?$ */
  monthly_single_sign_on_fee: string;
  pass_through_physical_card_fees: boolean;
  /** @pattern ^-?\d+(\.\d+)?$ */
  physical_card_fee: string;
  program_id: string;
  program_name: FeePlanListResponseDataItemProgramName;
  /** @pattern ^-?\d+(\.\d+)?$ */
  real_time_payments_transfer_origination_fee: string;
  terms: FeePlanListResponseDataItemTerms;
  /** @pattern ^-?\d+(\.\d+)?$ */
  uncommon_minimum_same_day_transfer_fraction: string;
  updated_at: string;
  /** @pattern ^-?\d+(\.\d+)?$ */
  wire_drawdown_origination_fee: string;
  /** @pattern ^-?\d+(\.\d+)?$ */
  wire_origination_fee: string;
};

export interface FeePlanListResponse {
  data: FeePlanListResponseDataItem[];
  next_cursor: FeePlanListResponseNextCursor;
}

export type FedwireInboundServiceMessageListResponseNextCursor = string | null;

export type FedwireInboundServiceMessageListResponseDataItemReviewStatus =
  (typeof FedwireInboundServiceMessageListResponseDataItemReviewStatus)[keyof typeof FedwireInboundServiceMessageListResponseDataItemReviewStatus];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const FedwireInboundServiceMessageListResponseDataItemReviewStatus = {
  actioned: "actioned",
  flagged_by_operator: "flagged_by_operator",
  ignored: "ignored",
  pending_review: "pending_review",
  requires_attention: "requires_attention",
} as const;

export type FedwireInboundServiceMessageListResponseDataItem = {
  created_at: string;
  id: string;
  message_body: string;
  received_on: string;
  receiver_institution: string;
  review_status: FedwireInboundServiceMessageListResponseDataItemReviewStatus;
  sender_institution: string;
  updated_at: string;
};

export interface FedwireInboundServiceMessageListResponse {
  data: FedwireInboundServiceMessageListResponseDataItem[];
  next_cursor: FedwireInboundServiceMessageListResponseNextCursor;
}

export type FedwireInboundServiceMessageGetResponseReviewStatus =
  (typeof FedwireInboundServiceMessageGetResponseReviewStatus)[keyof typeof FedwireInboundServiceMessageGetResponseReviewStatus];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const FedwireInboundServiceMessageGetResponseReviewStatus = {
  actioned: "actioned",
  flagged_by_operator: "flagged_by_operator",
  ignored: "ignored",
  pending_review: "pending_review",
  requires_attention: "requires_attention",
} as const;

export interface FedwireInboundServiceMessageGetResponse {
  created_at: string;
  id: string;
  message_body: string;
  received_on: string;
  receiver_institution: string;
  review_status: FedwireInboundServiceMessageGetResponseReviewStatus;
  sender_institution: string;
  updated_at: string;
}

export type FedwireInboundRequestForReversalListResponseNextCursor =
  | string
  | null;

export interface FedwireInboundRequestForReversalListResponse {
  data: FedwireInboundRequestForReversalListResponseDataItem[];
  next_cursor: FedwireInboundRequestForReversalListResponseNextCursor;
}

export type FedwireInboundRequestForReversalListResponseDataItemAllocationStatus =
  (typeof FedwireInboundRequestForReversalListResponseDataItemAllocationStatus)[keyof typeof FedwireInboundRequestForReversalListResponseDataItemAllocationStatus];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const FedwireInboundRequestForReversalListResponseDataItemAllocationStatus =
  {
    abandoned: "abandoned",
    allocated: "allocated",
    flagged_by_operator: "flagged_by_operator",
    pending_allocating: "pending_allocating",
    requires_attention: "requires_attention",
  } as const;

export type FedwireInboundRequestForReversalListResponseDataItem = {
  allocation_status: FedwireInboundRequestForReversalListResponseDataItemAllocationStatus;
  created_at: string;
  id: string;
  message_body: string;
  received_on: string;
  receiver_institution: string;
  sender_institution: string;
  updated_at: string;
};

export type FedwireInboundRequestForReversalGetResponseAllocationStatus =
  (typeof FedwireInboundRequestForReversalGetResponseAllocationStatus)[keyof typeof FedwireInboundRequestForReversalGetResponseAllocationStatus];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const FedwireInboundRequestForReversalGetResponseAllocationStatus = {
  abandoned: "abandoned",
  allocated: "allocated",
  flagged_by_operator: "flagged_by_operator",
  pending_allocating: "pending_allocating",
  requires_attention: "requires_attention",
} as const;

export interface FedwireInboundRequestForReversalGetResponse {
  allocation_status: FedwireInboundRequestForReversalGetResponseAllocationStatus;
  created_at: string;
  id: string;
  message_body: string;
  received_on: string;
  receiver_institution: string;
  sender_institution: string;
  updated_at: string;
}

export type FedexWebhookPostResponse = string;

export type ExternalAccountListResponseNextCursor = string | null;

export interface ExternalAccountListResponse {
  data: ExternalAccountListResponseDataItem[];
  next_cursor: ExternalAccountListResponseNextCursor;
}

export type ExternalAccountListResponseDataItemRoutingNumberDetailsAnyOfFinancialInstitutionWebsite =
  string | null;

export type ExternalAccountListResponseDataItemRoutingNumberDetailsAnyOfFinancialInstitutionIconUrl =
  string | null;

export type ExternalAccountListResponseDataItemRoutingNumberDetailsAnyOf = {
  created_at: string;
  fedach_enabled: boolean;
  fedwire_enabled: boolean;
  financial_institution_icon_url: ExternalAccountListResponseDataItemRoutingNumberDetailsAnyOfFinancialInstitutionIconUrl;
  financial_institution_website: ExternalAccountListResponseDataItemRoutingNumberDetailsAnyOfFinancialInstitutionWebsite;
  id: string;
  name: string;
  pretty_name: string;
  real_time_payments_enabled: boolean;
  routing_number: string;
  updated_at: string;
};

export type ExternalAccountListResponseDataItemRoutingNumberDetails =
  ExternalAccountListResponseDataItemRoutingNumberDetailsAnyOf | null;

export type ExternalAccountListResponseDataItemExternalAccountTransactionsSampleItemEntityId =
  string | null;

export type ExternalAccountListResponseDataItemExternalAccountTransactionsSampleItem =
  {
    account_transaction_id: string;
    entity_id: ExternalAccountListResponseDataItemExternalAccountTransactionsSampleItemEntityId;
  };

export type ExternalAccountListResponseDataItem = {
  account_number: string;
  created_at: string;
  external_account_transactions_sample: ExternalAccountListResponseDataItemExternalAccountTransactionsSampleItem[];
  id: string;
  routing_number: string;
  routing_number_details: ExternalAccountListResponseDataItemRoutingNumberDetails;
  updated_at: string;
};

export type ExternalAccountGetResponseRoutingNumberDetails =
  ExternalAccountGetResponseRoutingNumberDetailsAnyOf | null;

export interface ExternalAccountGetResponse {
  account_number: string;
  created_at: string;
  external_account_transactions_sample: ExternalAccountGetResponseExternalAccountTransactionsSampleItem[];
  id: string;
  routing_number: string;
  routing_number_details: ExternalAccountGetResponseRoutingNumberDetails;
  updated_at: string;
}

export type ExternalAccountGetResponseRoutingNumberDetailsAnyOfFinancialInstitutionWebsite =
  string | null;

export type ExternalAccountGetResponseRoutingNumberDetailsAnyOfFinancialInstitutionIconUrl =
  string | null;

export type ExternalAccountGetResponseRoutingNumberDetailsAnyOf = {
  created_at: string;
  fedach_enabled: boolean;
  fedwire_enabled: boolean;
  financial_institution_icon_url: ExternalAccountGetResponseRoutingNumberDetailsAnyOfFinancialInstitutionIconUrl;
  financial_institution_website: ExternalAccountGetResponseRoutingNumberDetailsAnyOfFinancialInstitutionWebsite;
  id: string;
  name: string;
  pretty_name: string;
  real_time_payments_enabled: boolean;
  routing_number: string;
  updated_at: string;
};

export type ExternalAccountGetResponseExternalAccountTransactionsSampleItemEntityId =
  string | null;

export type ExternalAccountGetResponseExternalAccountTransactionsSampleItem = {
  account_transaction_id: string;
  entity_id: ExternalAccountGetResponseExternalAccountTransactionsSampleItemEntityId;
};

export type EntitySupplementalDocumentSubmissionEvaluationGetResponseSuccessfulChecksItem =
  (typeof EntitySupplementalDocumentSubmissionEvaluationGetResponseSuccessfulChecksItem)[keyof typeof EntitySupplementalDocumentSubmissionEvaluationGetResponseSuccessfulChecksItem];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const EntitySupplementalDocumentSubmissionEvaluationGetResponseSuccessfulChecksItem =
  {
    correct_format: "correct_format",
    expected_entity_structure: "expected_entity_structure",
    entity_lookup_performed: "entity_lookup_performed",
    beneficial_owner_lookup_performed: "beneficial_owner_lookup_performed",
    entity_data_matches: "entity_data_matches",
    beneficial_owner_data_matches: "beneficial_owner_data_matches",
    evaluation_successful: "evaluation_successful",
  } as const;

export type EntitySupplementalDocumentSubmissionEvaluationGetResponseRequiredChecksItem =
  (typeof EntitySupplementalDocumentSubmissionEvaluationGetResponseRequiredChecksItem)[keyof typeof EntitySupplementalDocumentSubmissionEvaluationGetResponseRequiredChecksItem];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const EntitySupplementalDocumentSubmissionEvaluationGetResponseRequiredChecksItem =
  {
    correct_format: "correct_format",
    expected_entity_structure: "expected_entity_structure",
    entity_lookup_performed: "entity_lookup_performed",
    beneficial_owner_lookup_performed: "beneficial_owner_lookup_performed",
    entity_data_matches: "entity_data_matches",
    beneficial_owner_data_matches: "beneficial_owner_data_matches",
    evaluation_successful: "evaluation_successful",
  } as const;

export interface EntitySupplementalDocumentSubmissionEvaluationGetResponse {
  required_checks: EntitySupplementalDocumentSubmissionEvaluationGetResponseRequiredChecksItem[];
  successful_checks: EntitySupplementalDocumentSubmissionEvaluationGetResponseSuccessfulChecksItem[];
}

export type EntityReferenceListResponseNextCursor = string | null;

export interface EntityReferenceListResponse {
  data: EntityReferenceListResponseDataItem[];
  next_cursor: EntityReferenceListResponseNextCursor;
}

export type EntityReferenceListResponseDataItemStructure =
  (typeof EntityReferenceListResponseDataItemStructure)[keyof typeof EntityReferenceListResponseDataItemStructure];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const EntityReferenceListResponseDataItemStructure = {
  corporation: "corporation",
  natural_person: "natural_person",
  joint: "joint",
  trust: "trust",
  government_authority: "government_authority",
} as const;

export type EntityReferenceListResponseDataItemStatus =
  (typeof EntityReferenceListResponseDataItemStatus)[keyof typeof EntityReferenceListResponseDataItemStatus];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const EntityReferenceListResponseDataItemStatus = {
  active: "active",
  archived: "archived",
  disabled: "disabled",
} as const;

export type EntityReferenceListResponseDataItemReviewStatus =
  (typeof EntityReferenceListResponseDataItemReviewStatus)[keyof typeof EntityReferenceListResponseDataItemReviewStatus];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const EntityReferenceListResponseDataItemReviewStatus = {
  pending_reviewing: "pending_reviewing",
  review_not_required: "review_not_required",
  approved: "approved",
  approved_by_platform: "approved_by_platform",
  returned_to_user: "returned_to_user",
  requires_attention: "requires_attention",
} as const;

export type EntityReferenceListResponseDataItem = {
  created_at: string;
  group_id: string;
  id: string;
  name: string;
  review_status: EntityReferenceListResponseDataItemReviewStatus;
  status: EntityReferenceListResponseDataItemStatus;
  structure: EntityReferenceListResponseDataItemStructure;
};

export type EntityPatchResponseTaxId = string | null;

export interface EntityPatchResponse {
  approved_at: EntityPatchResponseApprovedAt;
  approved_by: EntityPatchResponseApprovedBy;
  automated_customer_identification_program_evaluation: EntityPatchResponseAutomatedCustomerIdentificationProgramEvaluation;
  created_at: string;
  customer_information_policy_problems: EntityPatchResponseCustomerInformationPolicyProblemsItem[];
  directly_supervised: boolean;
  group_id: string;
  has_informational_accounts: boolean;
  has_ownership_accounts: boolean;
  id: string;
  name: string;
  review_status: EntityPatchResponseReviewStatus;
  setup_submission_structure: EntityPatchResponseSetupSubmissionStructure;
  status: EntityPatchResponseStatus;
  structure: EntityPatchResponseStructure;
  supplemental_documents: EntityPatchResponseSupplementalDocumentsItem[];
  tax_id: EntityPatchResponseTaxId;
}

export type EntityPatchResponseSupplementalDocumentsItemReviewStatus =
  (typeof EntityPatchResponseSupplementalDocumentsItemReviewStatus)[keyof typeof EntityPatchResponseSupplementalDocumentsItemReviewStatus];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const EntityPatchResponseSupplementalDocumentsItemReviewStatus = {
  pending_reviewing: "pending_reviewing",
  skipped: "skipped",
  accepted: "accepted",
  accepted_manually_reviewed: "accepted_manually_reviewed",
  rejected: "rejected",
  requires_attention: "requires_attention",
} as const;

export type EntityPatchResponseSupplementalDocumentsItemRejectReason =
  | "parse_failed"
  | "wrong_structure"
  | "data_mismatch"
  | "missing_datasource"
  | "evaluation_failed"
  | null;

export type EntityPatchResponseSupplementalDocumentsItemFilePurpose =
  (typeof EntityPatchResponseSupplementalDocumentsItemFilePurpose)[keyof typeof EntityPatchResponseSupplementalDocumentsItemFilePurpose];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const EntityPatchResponseSupplementalDocumentsItemFilePurpose = {
  check_image_front: "check_image_front",
  check_image_back: "check_image_back",
  processed_check_image_front: "processed_check_image_front",
  processed_check_image_back: "processed_check_image_back",
  mailed_check_image: "mailed_check_image",
  check_attachment: "check_attachment",
  inbound_mail_item: "inbound_mail_item",
  form_1099_int: "form_1099_int",
  form_1099_misc: "form_1099_misc",
  form_ss_4: "form_ss_4",
  identity_document: "identity_document",
  increase_statement: "increase_statement",
  other: "other",
  trust_formation_document: "trust_formation_document",
  digital_wallet_artwork: "digital_wallet_artwork",
  digital_wallet_app_icon: "digital_wallet_app_icon",
  physical_card_front: "physical_card_front",
  physical_card_back: "physical_card_back",
  physical_card_carrier: "physical_card_carrier",
  document_request: "document_request",
  entity_supplemental_document: "entity_supplemental_document",
  export: "export",
  unusual_activity_report_attachment: "unusual_activity_report_attachment",
  deposit_account_control_agreement: "deposit_account_control_agreement",
  proof_of_authorization_request_submission:
    "proof_of_authorization_request_submission",
} as const;

export type EntityPatchResponseSupplementalDocumentsItemFileDirection =
  (typeof EntityPatchResponseSupplementalDocumentsItemFileDirection)[keyof typeof EntityPatchResponseSupplementalDocumentsItemFileDirection];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const EntityPatchResponseSupplementalDocumentsItemFileDirection = {
  to_increase: "to_increase",
  from_increase: "from_increase",
} as const;

export type EntityPatchResponseSupplementalDocumentsItemFileDescription =
  | string
  | null;

export type EntityPatchResponseSupplementalDocumentsItemFile = {
  created_at: string;
  description: EntityPatchResponseSupplementalDocumentsItemFileDescription;
  direction: EntityPatchResponseSupplementalDocumentsItemFileDirection;
  filename: string;
  id: string;
  mime: string;
  purpose: EntityPatchResponseSupplementalDocumentsItemFilePurpose;
};

export type EntityPatchResponseSupplementalDocumentsItem = {
  created_at: string;
  file: EntityPatchResponseSupplementalDocumentsItemFile;
  id: string;
  reject_reason: EntityPatchResponseSupplementalDocumentsItemRejectReason;
  review_status: EntityPatchResponseSupplementalDocumentsItemReviewStatus;
};

export type EntityPatchResponseStructure =
  (typeof EntityPatchResponseStructure)[keyof typeof EntityPatchResponseStructure];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const EntityPatchResponseStructure = {
  corporation: "corporation",
  natural_person: "natural_person",
  joint: "joint",
  trust: "trust",
  government_authority: "government_authority",
} as const;

export type EntityPatchResponseStatus =
  (typeof EntityPatchResponseStatus)[keyof typeof EntityPatchResponseStatus];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const EntityPatchResponseStatus = {
  active: "active",
  archived: "archived",
  disabled: "disabled",
} as const;

export type EntityPatchResponseSetupSubmissionStructureTrustAnyOf = {
  grantor: EntityPatchResponseSetupSubmissionStructureTrustAnyOfGrantor;
  id: string;
  submitted_address: EntityPatchResponseSetupSubmissionStructureTrustAnyOfSubmittedAddress;
  submitted_category: EntityPatchResponseSetupSubmissionStructureTrustAnyOfSubmittedCategory;
  submitted_name: string;
  submitted_tax_id: EntityPatchResponseSetupSubmissionStructureTrustAnyOfSubmittedTaxId;
  trustees: EntityPatchResponseSetupSubmissionStructureTrustAnyOfTrusteesItem[];
};

export type EntityPatchResponseSetupSubmissionStructureTrust =
  EntityPatchResponseSetupSubmissionStructureTrustAnyOf | null;

export type EntityPatchResponseSetupSubmissionStructure = {
  corporation: EntityPatchResponseSetupSubmissionStructureCorporation;
  government_authority: EntityPatchResponseSetupSubmissionStructureGovernmentAuthority;
  joint: EntityPatchResponseSetupSubmissionStructureJoint;
  natural_person: EntityPatchResponseSetupSubmissionStructureNaturalPerson;
  trust: EntityPatchResponseSetupSubmissionStructureTrust;
};

export type EntityPatchResponseSetupSubmissionStructureTrustAnyOfTrusteesItemTaxIdValidationStatus =
  (typeof EntityPatchResponseSetupSubmissionStructureTrustAnyOfTrusteesItemTaxIdValidationStatus)[keyof typeof EntityPatchResponseSetupSubmissionStructureTrustAnyOfTrusteesItemTaxIdValidationStatus];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const EntityPatchResponseSetupSubmissionStructureTrustAnyOfTrusteesItemTaxIdValidationStatus =
  {
    pending_validating: "pending_validating",
    update_pending_validating: "update_pending_validating",
    requires_attention: "requires_attention",
    failed: "failed",
    validated: "validated",
    not_provided: "not_provided",
    skipped: "skipped",
  } as const;

export type EntityPatchResponseSetupSubmissionStructureTrustAnyOfTrusteesItemSubmittedIdentificationMethod =
  (typeof EntityPatchResponseSetupSubmissionStructureTrustAnyOfTrusteesItemSubmittedIdentificationMethod)[keyof typeof EntityPatchResponseSetupSubmissionStructureTrustAnyOfTrusteesItemSubmittedIdentificationMethod];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const EntityPatchResponseSetupSubmissionStructureTrustAnyOfTrusteesItemSubmittedIdentificationMethod =
  {
    social_security_number: "social_security_number",
    individual_taxpayer_identification_number:
      "individual_taxpayer_identification_number",
    passport: "passport",
    drivers_license: "drivers_license",
    other: "other",
  } as const;

export type EntityPatchResponseSetupSubmissionStructureTrustAnyOfTrusteesItemSubmittedAddressLine2 =
  string | null;

export type EntityPatchResponseSetupSubmissionStructureTrustAnyOfTrusteesItemSubmittedAddress =
  {
    city: string;
    line1: string;
    line2: EntityPatchResponseSetupSubmissionStructureTrustAnyOfTrusteesItemSubmittedAddressLine2;
    state: string;
    zip: string;
  };

export type EntityPatchResponseSetupSubmissionStructureTrustAnyOfTrusteesItemSubmissionSovosResponseId =
  string | null;

export type EntityPatchResponseSetupSubmissionStructureTrustAnyOfTrusteesItem =
  {
    created_at: string;
    id: string;
    identity_document_submissions: EntityPatchResponseSetupSubmissionStructureTrustAnyOfTrusteesItemIdentityDocumentSubmissionsItem[];
    identity_submitted_at: string;
    identity_verification_status: EntityPatchResponseSetupSubmissionStructureTrustAnyOfTrusteesItemIdentityVerificationStatus;
    microbilt: EntityPatchResponseSetupSubmissionStructureTrustAnyOfTrusteesItemMicrobiltItem[];
    submission_sovos_response_id: EntityPatchResponseSetupSubmissionStructureTrustAnyOfTrusteesItemSubmissionSovosResponseId;
    submitted_address: EntityPatchResponseSetupSubmissionStructureTrustAnyOfTrusteesItemSubmittedAddress;
    submitted_date_of_birth: string;
    submitted_identification_method: EntityPatchResponseSetupSubmissionStructureTrustAnyOfTrusteesItemSubmittedIdentificationMethod;
    submitted_identification_number_last4: string;
    submitted_name: string;
    tax_id_validation_status: EntityPatchResponseSetupSubmissionStructureTrustAnyOfTrusteesItemTaxIdValidationStatus;
  };

export type EntityPatchResponseSetupSubmissionStructureTrustAnyOfTrusteesItemMicrobiltItemTaxIdType =
  "SSN" | null;

export type EntityPatchResponseSetupSubmissionStructureTrustAnyOfTrusteesItemMicrobiltItemTaxIdLast4 =
  string | null;

export type EntityPatchResponseSetupSubmissionStructureTrustAnyOfTrusteesItemMicrobiltItemReturnCode =
  (typeof EntityPatchResponseSetupSubmissionStructureTrustAnyOfTrusteesItemMicrobiltItemReturnCode)[keyof typeof EntityPatchResponseSetupSubmissionStructureTrustAnyOfTrusteesItemMicrobiltItemReturnCode];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const EntityPatchResponseSetupSubmissionStructureTrustAnyOfTrusteesItemMicrobiltItemReturnCode =
  {
    OK: "OK",
  } as const;

export type EntityPatchResponseSetupSubmissionStructureTrustAnyOfTrusteesItemMicrobiltItemMiddleName =
  string | null;

export type EntityPatchResponseSetupSubmissionStructureTrustAnyOfTrusteesItemMicrobiltItemDeathDate =
  string | null;

export type EntityPatchResponseSetupSubmissionStructureTrustAnyOfTrusteesItemMicrobiltItemBirthDate =
  string | null;

export type EntityPatchResponseSetupSubmissionStructureTrustAnyOfTrusteesItemMicrobiltItem =
  {
    birth_date: EntityPatchResponseSetupSubmissionStructureTrustAnyOfTrusteesItemMicrobiltItemBirthDate;
    created_at: string;
    death_date: EntityPatchResponseSetupSubmissionStructureTrustAnyOfTrusteesItemMicrobiltItemDeathDate;
    first_name: string;
    id: string;
    last_name: string;
    middle_name: EntityPatchResponseSetupSubmissionStructureTrustAnyOfTrusteesItemMicrobiltItemMiddleName;
    request_id: string;
    response: string;
    return_code: EntityPatchResponseSetupSubmissionStructureTrustAnyOfTrusteesItemMicrobiltItemReturnCode;
    tax_id_last_4: EntityPatchResponseSetupSubmissionStructureTrustAnyOfTrusteesItemMicrobiltItemTaxIdLast4;
    tax_id_type: EntityPatchResponseSetupSubmissionStructureTrustAnyOfTrusteesItemMicrobiltItemTaxIdType;
  };

export type EntityPatchResponseSetupSubmissionStructureTrustAnyOfTrusteesItemIdentityVerificationStatus =
  (typeof EntityPatchResponseSetupSubmissionStructureTrustAnyOfTrusteesItemIdentityVerificationStatus)[keyof typeof EntityPatchResponseSetupSubmissionStructureTrustAnyOfTrusteesItemIdentityVerificationStatus];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const EntityPatchResponseSetupSubmissionStructureTrustAnyOfTrusteesItemIdentityVerificationStatus =
  {
    pending_verification: "pending_verification",
    update_pending_verification: "update_pending_verification",
    verified: "verified",
    falsified: "falsified",
    no_match: "no_match",
    verifying: "verifying",
    requires_attention: "requires_attention",
    not_performed: "not_performed",
    skipped: "skipped",
  } as const;

export type EntityPatchResponseSetupSubmissionStructureTrustAnyOfTrusteesItemIdentityDocumentSubmissionsItemSubmittedState =
  string | null;

export type EntityPatchResponseSetupSubmissionStructureTrustAnyOfTrusteesItemIdentityDocumentSubmissionsItemSubmittedExpirationDate =
  string | null;

export type EntityPatchResponseSetupSubmissionStructureTrustAnyOfTrusteesItemIdentityDocumentSubmissionsItemSubmittedDocumentType =
  (typeof EntityPatchResponseSetupSubmissionStructureTrustAnyOfTrusteesItemIdentityDocumentSubmissionsItemSubmittedDocumentType)[keyof typeof EntityPatchResponseSetupSubmissionStructureTrustAnyOfTrusteesItemIdentityDocumentSubmissionsItemSubmittedDocumentType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const EntityPatchResponseSetupSubmissionStructureTrustAnyOfTrusteesItemIdentityDocumentSubmissionsItemSubmittedDocumentType =
  {
    passport: "passport",
    us_drivers_license: "us_drivers_license",
    other: "other",
  } as const;

export type EntityPatchResponseSetupSubmissionStructureTrustAnyOfTrusteesItemIdentityDocumentSubmissionsItemSubmittedDocumentNumber =
  string | null;

export type EntityPatchResponseSetupSubmissionStructureTrustAnyOfTrusteesItemIdentityDocumentSubmissionsItem =
  {
    api_back_file_id: EntityPatchResponseSetupSubmissionStructureTrustAnyOfTrusteesItemIdentityDocumentSubmissionsItemApiBackFileId;
    api_file_id: string;
    created_at: string;
    group_id: string;
    id: string;
    identity_verification_status: EntityPatchResponseSetupSubmissionStructureTrustAnyOfTrusteesItemIdentityDocumentSubmissionsItemIdentityVerificationStatus;
    submitted_address: EntityPatchResponseSetupSubmissionStructureTrustAnyOfTrusteesItemIdentityDocumentSubmissionsItemSubmittedAddress;
    submitted_country: string;
    submitted_date_of_birth: string;
    submitted_document_number: EntityPatchResponseSetupSubmissionStructureTrustAnyOfTrusteesItemIdentityDocumentSubmissionsItemSubmittedDocumentNumber;
    submitted_document_type: EntityPatchResponseSetupSubmissionStructureTrustAnyOfTrusteesItemIdentityDocumentSubmissionsItemSubmittedDocumentType;
    submitted_expiration_date: EntityPatchResponseSetupSubmissionStructureTrustAnyOfTrusteesItemIdentityDocumentSubmissionsItemSubmittedExpirationDate;
    submitted_name: string;
    submitted_state: EntityPatchResponseSetupSubmissionStructureTrustAnyOfTrusteesItemIdentityDocumentSubmissionsItemSubmittedState;
  };

export type EntityPatchResponseSetupSubmissionStructureTrustAnyOfTrusteesItemIdentityDocumentSubmissionsItemSubmittedAddressLine2 =
  string | null;

export type EntityPatchResponseSetupSubmissionStructureTrustAnyOfTrusteesItemIdentityDocumentSubmissionsItemSubmittedAddress =
  {
    city: string;
    line1: string;
    line2: EntityPatchResponseSetupSubmissionStructureTrustAnyOfTrusteesItemIdentityDocumentSubmissionsItemSubmittedAddressLine2;
    state: string;
    zip: string;
  };

export type EntityPatchResponseSetupSubmissionStructureTrustAnyOfTrusteesItemIdentityDocumentSubmissionsItemIdentityVerificationStatus =
  (typeof EntityPatchResponseSetupSubmissionStructureTrustAnyOfTrusteesItemIdentityDocumentSubmissionsItemIdentityVerificationStatus)[keyof typeof EntityPatchResponseSetupSubmissionStructureTrustAnyOfTrusteesItemIdentityDocumentSubmissionsItemIdentityVerificationStatus];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const EntityPatchResponseSetupSubmissionStructureTrustAnyOfTrusteesItemIdentityDocumentSubmissionsItemIdentityVerificationStatus =
  {
    pending_verification: "pending_verification",
    update_pending_verification: "update_pending_verification",
    verified: "verified",
    falsified: "falsified",
    no_match: "no_match",
    verifying: "verifying",
    requires_attention: "requires_attention",
    not_performed: "not_performed",
    skipped: "skipped",
  } as const;

export type EntityPatchResponseSetupSubmissionStructureTrustAnyOfTrusteesItemIdentityDocumentSubmissionsItemApiBackFileId =
  string | null;

export type EntityPatchResponseSetupSubmissionStructureTrustAnyOfSubmittedTaxId =
  string | null;

export type EntityPatchResponseSetupSubmissionStructureTrustAnyOfSubmittedCategory =
  (typeof EntityPatchResponseSetupSubmissionStructureTrustAnyOfSubmittedCategory)[keyof typeof EntityPatchResponseSetupSubmissionStructureTrustAnyOfSubmittedCategory];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const EntityPatchResponseSetupSubmissionStructureTrustAnyOfSubmittedCategory =
  {
    revocable: "revocable",
    irrevocable: "irrevocable",
  } as const;

export type EntityPatchResponseSetupSubmissionStructureTrustAnyOfSubmittedAddressLine2 =
  string | null;

export type EntityPatchResponseSetupSubmissionStructureTrustAnyOfSubmittedAddress =
  {
    city: string;
    line1: string;
    line2: EntityPatchResponseSetupSubmissionStructureTrustAnyOfSubmittedAddressLine2;
    state: string;
    zip: string;
  };

export type EntityPatchResponseSetupSubmissionStructureTrustAnyOfGrantor =
  EntityPatchResponseSetupSubmissionStructureTrustAnyOfGrantorAnyOf | null;

export type EntityPatchResponseSetupSubmissionStructureTrustAnyOfGrantorAnyOfTaxIdValidationStatus =
  (typeof EntityPatchResponseSetupSubmissionStructureTrustAnyOfGrantorAnyOfTaxIdValidationStatus)[keyof typeof EntityPatchResponseSetupSubmissionStructureTrustAnyOfGrantorAnyOfTaxIdValidationStatus];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const EntityPatchResponseSetupSubmissionStructureTrustAnyOfGrantorAnyOfTaxIdValidationStatus =
  {
    pending_validating: "pending_validating",
    update_pending_validating: "update_pending_validating",
    requires_attention: "requires_attention",
    failed: "failed",
    validated: "validated",
    not_provided: "not_provided",
    skipped: "skipped",
  } as const;

export type EntityPatchResponseSetupSubmissionStructureTrustAnyOfGrantorAnyOfSubmittedIdentificationMethod =
  (typeof EntityPatchResponseSetupSubmissionStructureTrustAnyOfGrantorAnyOfSubmittedIdentificationMethod)[keyof typeof EntityPatchResponseSetupSubmissionStructureTrustAnyOfGrantorAnyOfSubmittedIdentificationMethod];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const EntityPatchResponseSetupSubmissionStructureTrustAnyOfGrantorAnyOfSubmittedIdentificationMethod =
  {
    social_security_number: "social_security_number",
    individual_taxpayer_identification_number:
      "individual_taxpayer_identification_number",
    passport: "passport",
    drivers_license: "drivers_license",
    other: "other",
  } as const;

export type EntityPatchResponseSetupSubmissionStructureTrustAnyOfGrantorAnyOf =
  {
    created_at: string;
    id: string;
    identity_document_submissions: EntityPatchResponseSetupSubmissionStructureTrustAnyOfGrantorAnyOfIdentityDocumentSubmissionsItem[];
    identity_submitted_at: string;
    identity_verification_status: EntityPatchResponseSetupSubmissionStructureTrustAnyOfGrantorAnyOfIdentityVerificationStatus;
    microbilt: EntityPatchResponseSetupSubmissionStructureTrustAnyOfGrantorAnyOfMicrobiltItem[];
    submission_sovos_response_id: EntityPatchResponseSetupSubmissionStructureTrustAnyOfGrantorAnyOfSubmissionSovosResponseId;
    submitted_address: EntityPatchResponseSetupSubmissionStructureTrustAnyOfGrantorAnyOfSubmittedAddress;
    submitted_date_of_birth: string;
    submitted_identification_method: EntityPatchResponseSetupSubmissionStructureTrustAnyOfGrantorAnyOfSubmittedIdentificationMethod;
    submitted_identification_number_last4: string;
    submitted_name: string;
    tax_id_validation_status: EntityPatchResponseSetupSubmissionStructureTrustAnyOfGrantorAnyOfTaxIdValidationStatus;
  };

export type EntityPatchResponseSetupSubmissionStructureTrustAnyOfGrantorAnyOfSubmittedAddressLine2 =
  string | null;

export type EntityPatchResponseSetupSubmissionStructureTrustAnyOfGrantorAnyOfSubmittedAddress =
  {
    city: string;
    line1: string;
    line2: EntityPatchResponseSetupSubmissionStructureTrustAnyOfGrantorAnyOfSubmittedAddressLine2;
    state: string;
    zip: string;
  };

export type EntityPatchResponseSetupSubmissionStructureTrustAnyOfGrantorAnyOfSubmissionSovosResponseId =
  string | null;

export type EntityPatchResponseSetupSubmissionStructureTrustAnyOfGrantorAnyOfMicrobiltItemTaxIdType =
  "SSN" | null;

export type EntityPatchResponseSetupSubmissionStructureTrustAnyOfGrantorAnyOfMicrobiltItemTaxIdLast4 =
  string | null;

export type EntityPatchResponseSetupSubmissionStructureTrustAnyOfGrantorAnyOfMicrobiltItemReturnCode =
  (typeof EntityPatchResponseSetupSubmissionStructureTrustAnyOfGrantorAnyOfMicrobiltItemReturnCode)[keyof typeof EntityPatchResponseSetupSubmissionStructureTrustAnyOfGrantorAnyOfMicrobiltItemReturnCode];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const EntityPatchResponseSetupSubmissionStructureTrustAnyOfGrantorAnyOfMicrobiltItemReturnCode =
  {
    OK: "OK",
  } as const;

export type EntityPatchResponseSetupSubmissionStructureTrustAnyOfGrantorAnyOfMicrobiltItemMiddleName =
  string | null;

export type EntityPatchResponseSetupSubmissionStructureTrustAnyOfGrantorAnyOfMicrobiltItemDeathDate =
  string | null;

export type EntityPatchResponseSetupSubmissionStructureTrustAnyOfGrantorAnyOfMicrobiltItemBirthDate =
  string | null;

export type EntityPatchResponseSetupSubmissionStructureTrustAnyOfGrantorAnyOfMicrobiltItem =
  {
    birth_date: EntityPatchResponseSetupSubmissionStructureTrustAnyOfGrantorAnyOfMicrobiltItemBirthDate;
    created_at: string;
    death_date: EntityPatchResponseSetupSubmissionStructureTrustAnyOfGrantorAnyOfMicrobiltItemDeathDate;
    first_name: string;
    id: string;
    last_name: string;
    middle_name: EntityPatchResponseSetupSubmissionStructureTrustAnyOfGrantorAnyOfMicrobiltItemMiddleName;
    request_id: string;
    response: string;
    return_code: EntityPatchResponseSetupSubmissionStructureTrustAnyOfGrantorAnyOfMicrobiltItemReturnCode;
    tax_id_last_4: EntityPatchResponseSetupSubmissionStructureTrustAnyOfGrantorAnyOfMicrobiltItemTaxIdLast4;
    tax_id_type: EntityPatchResponseSetupSubmissionStructureTrustAnyOfGrantorAnyOfMicrobiltItemTaxIdType;
  };

export type EntityPatchResponseSetupSubmissionStructureTrustAnyOfGrantorAnyOfIdentityVerificationStatus =
  (typeof EntityPatchResponseSetupSubmissionStructureTrustAnyOfGrantorAnyOfIdentityVerificationStatus)[keyof typeof EntityPatchResponseSetupSubmissionStructureTrustAnyOfGrantorAnyOfIdentityVerificationStatus];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const EntityPatchResponseSetupSubmissionStructureTrustAnyOfGrantorAnyOfIdentityVerificationStatus =
  {
    pending_verification: "pending_verification",
    update_pending_verification: "update_pending_verification",
    verified: "verified",
    falsified: "falsified",
    no_match: "no_match",
    verifying: "verifying",
    requires_attention: "requires_attention",
    not_performed: "not_performed",
    skipped: "skipped",
  } as const;

export type EntityPatchResponseSetupSubmissionStructureTrustAnyOfGrantorAnyOfIdentityDocumentSubmissionsItemSubmittedState =
  string | null;

export type EntityPatchResponseSetupSubmissionStructureTrustAnyOfGrantorAnyOfIdentityDocumentSubmissionsItemSubmittedExpirationDate =
  string | null;

export type EntityPatchResponseSetupSubmissionStructureTrustAnyOfGrantorAnyOfIdentityDocumentSubmissionsItemSubmittedDocumentType =
  (typeof EntityPatchResponseSetupSubmissionStructureTrustAnyOfGrantorAnyOfIdentityDocumentSubmissionsItemSubmittedDocumentType)[keyof typeof EntityPatchResponseSetupSubmissionStructureTrustAnyOfGrantorAnyOfIdentityDocumentSubmissionsItemSubmittedDocumentType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const EntityPatchResponseSetupSubmissionStructureTrustAnyOfGrantorAnyOfIdentityDocumentSubmissionsItemSubmittedDocumentType =
  {
    passport: "passport",
    us_drivers_license: "us_drivers_license",
    other: "other",
  } as const;

export type EntityPatchResponseSetupSubmissionStructureTrustAnyOfGrantorAnyOfIdentityDocumentSubmissionsItemSubmittedDocumentNumber =
  string | null;

export type EntityPatchResponseSetupSubmissionStructureTrustAnyOfGrantorAnyOfIdentityDocumentSubmissionsItem =
  {
    api_back_file_id: EntityPatchResponseSetupSubmissionStructureTrustAnyOfGrantorAnyOfIdentityDocumentSubmissionsItemApiBackFileId;
    api_file_id: string;
    created_at: string;
    group_id: string;
    id: string;
    identity_verification_status: EntityPatchResponseSetupSubmissionStructureTrustAnyOfGrantorAnyOfIdentityDocumentSubmissionsItemIdentityVerificationStatus;
    submitted_address: EntityPatchResponseSetupSubmissionStructureTrustAnyOfGrantorAnyOfIdentityDocumentSubmissionsItemSubmittedAddress;
    submitted_country: string;
    submitted_date_of_birth: string;
    submitted_document_number: EntityPatchResponseSetupSubmissionStructureTrustAnyOfGrantorAnyOfIdentityDocumentSubmissionsItemSubmittedDocumentNumber;
    submitted_document_type: EntityPatchResponseSetupSubmissionStructureTrustAnyOfGrantorAnyOfIdentityDocumentSubmissionsItemSubmittedDocumentType;
    submitted_expiration_date: EntityPatchResponseSetupSubmissionStructureTrustAnyOfGrantorAnyOfIdentityDocumentSubmissionsItemSubmittedExpirationDate;
    submitted_name: string;
    submitted_state: EntityPatchResponseSetupSubmissionStructureTrustAnyOfGrantorAnyOfIdentityDocumentSubmissionsItemSubmittedState;
  };

export type EntityPatchResponseSetupSubmissionStructureTrustAnyOfGrantorAnyOfIdentityDocumentSubmissionsItemSubmittedAddressLine2 =
  string | null;

export type EntityPatchResponseSetupSubmissionStructureTrustAnyOfGrantorAnyOfIdentityDocumentSubmissionsItemSubmittedAddress =
  {
    city: string;
    line1: string;
    line2: EntityPatchResponseSetupSubmissionStructureTrustAnyOfGrantorAnyOfIdentityDocumentSubmissionsItemSubmittedAddressLine2;
    state: string;
    zip: string;
  };

export type EntityPatchResponseSetupSubmissionStructureTrustAnyOfGrantorAnyOfIdentityDocumentSubmissionsItemIdentityVerificationStatus =
  (typeof EntityPatchResponseSetupSubmissionStructureTrustAnyOfGrantorAnyOfIdentityDocumentSubmissionsItemIdentityVerificationStatus)[keyof typeof EntityPatchResponseSetupSubmissionStructureTrustAnyOfGrantorAnyOfIdentityDocumentSubmissionsItemIdentityVerificationStatus];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const EntityPatchResponseSetupSubmissionStructureTrustAnyOfGrantorAnyOfIdentityDocumentSubmissionsItemIdentityVerificationStatus =
  {
    pending_verification: "pending_verification",
    update_pending_verification: "update_pending_verification",
    verified: "verified",
    falsified: "falsified",
    no_match: "no_match",
    verifying: "verifying",
    requires_attention: "requires_attention",
    not_performed: "not_performed",
    skipped: "skipped",
  } as const;

export type EntityPatchResponseSetupSubmissionStructureTrustAnyOfGrantorAnyOfIdentityDocumentSubmissionsItemApiBackFileId =
  string | null;

export type EntityPatchResponseSetupSubmissionStructureNaturalPerson =
  EntityPatchResponseSetupSubmissionStructureNaturalPersonAnyOf | null;

export type EntityPatchResponseSetupSubmissionStructureNaturalPersonAnyOfTaxIdValidationStatus =
  (typeof EntityPatchResponseSetupSubmissionStructureNaturalPersonAnyOfTaxIdValidationStatus)[keyof typeof EntityPatchResponseSetupSubmissionStructureNaturalPersonAnyOfTaxIdValidationStatus];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const EntityPatchResponseSetupSubmissionStructureNaturalPersonAnyOfTaxIdValidationStatus =
  {
    pending_validating: "pending_validating",
    update_pending_validating: "update_pending_validating",
    requires_attention: "requires_attention",
    failed: "failed",
    validated: "validated",
    not_provided: "not_provided",
    skipped: "skipped",
  } as const;

export type EntityPatchResponseSetupSubmissionStructureNaturalPersonAnyOfSubmittedIdentificationMethod =
  (typeof EntityPatchResponseSetupSubmissionStructureNaturalPersonAnyOfSubmittedIdentificationMethod)[keyof typeof EntityPatchResponseSetupSubmissionStructureNaturalPersonAnyOfSubmittedIdentificationMethod];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const EntityPatchResponseSetupSubmissionStructureNaturalPersonAnyOfSubmittedIdentificationMethod =
  {
    social_security_number: "social_security_number",
    individual_taxpayer_identification_number:
      "individual_taxpayer_identification_number",
    passport: "passport",
    drivers_license: "drivers_license",
    other: "other",
  } as const;

export type EntityPatchResponseSetupSubmissionStructureNaturalPersonAnyOfSubmittedAddressLine2 =
  string | null;

export type EntityPatchResponseSetupSubmissionStructureNaturalPersonAnyOfSubmittedAddress =
  {
    city: string;
    line1: string;
    line2: EntityPatchResponseSetupSubmissionStructureNaturalPersonAnyOfSubmittedAddressLine2;
    state: string;
    zip: string;
  };

export type EntityPatchResponseSetupSubmissionStructureNaturalPersonAnyOfSubmissionSovosResponseId =
  string | null;

export type EntityPatchResponseSetupSubmissionStructureNaturalPersonAnyOf = {
  created_at: string;
  id: string;
  identity_document_submissions: EntityPatchResponseSetupSubmissionStructureNaturalPersonAnyOfIdentityDocumentSubmissionsItem[];
  identity_submitted_at: string;
  identity_verification_status: EntityPatchResponseSetupSubmissionStructureNaturalPersonAnyOfIdentityVerificationStatus;
  microbilt: EntityPatchResponseSetupSubmissionStructureNaturalPersonAnyOfMicrobiltItem[];
  submission_sovos_response_id: EntityPatchResponseSetupSubmissionStructureNaturalPersonAnyOfSubmissionSovosResponseId;
  submitted_address: EntityPatchResponseSetupSubmissionStructureNaturalPersonAnyOfSubmittedAddress;
  submitted_date_of_birth: string;
  submitted_identification_method: EntityPatchResponseSetupSubmissionStructureNaturalPersonAnyOfSubmittedIdentificationMethod;
  submitted_identification_number_last4: string;
  submitted_name: string;
  tax_id_validation_status: EntityPatchResponseSetupSubmissionStructureNaturalPersonAnyOfTaxIdValidationStatus;
};

export type EntityPatchResponseSetupSubmissionStructureNaturalPersonAnyOfMicrobiltItemTaxIdType =
  "SSN" | null;

export type EntityPatchResponseSetupSubmissionStructureNaturalPersonAnyOfMicrobiltItemTaxIdLast4 =
  string | null;

export type EntityPatchResponseSetupSubmissionStructureNaturalPersonAnyOfMicrobiltItemReturnCode =
  (typeof EntityPatchResponseSetupSubmissionStructureNaturalPersonAnyOfMicrobiltItemReturnCode)[keyof typeof EntityPatchResponseSetupSubmissionStructureNaturalPersonAnyOfMicrobiltItemReturnCode];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const EntityPatchResponseSetupSubmissionStructureNaturalPersonAnyOfMicrobiltItemReturnCode =
  {
    OK: "OK",
  } as const;

export type EntityPatchResponseSetupSubmissionStructureNaturalPersonAnyOfMicrobiltItemMiddleName =
  string | null;

export type EntityPatchResponseSetupSubmissionStructureNaturalPersonAnyOfMicrobiltItemDeathDate =
  string | null;

export type EntityPatchResponseSetupSubmissionStructureNaturalPersonAnyOfMicrobiltItemBirthDate =
  string | null;

export type EntityPatchResponseSetupSubmissionStructureNaturalPersonAnyOfMicrobiltItem =
  {
    birth_date: EntityPatchResponseSetupSubmissionStructureNaturalPersonAnyOfMicrobiltItemBirthDate;
    created_at: string;
    death_date: EntityPatchResponseSetupSubmissionStructureNaturalPersonAnyOfMicrobiltItemDeathDate;
    first_name: string;
    id: string;
    last_name: string;
    middle_name: EntityPatchResponseSetupSubmissionStructureNaturalPersonAnyOfMicrobiltItemMiddleName;
    request_id: string;
    response: string;
    return_code: EntityPatchResponseSetupSubmissionStructureNaturalPersonAnyOfMicrobiltItemReturnCode;
    tax_id_last_4: EntityPatchResponseSetupSubmissionStructureNaturalPersonAnyOfMicrobiltItemTaxIdLast4;
    tax_id_type: EntityPatchResponseSetupSubmissionStructureNaturalPersonAnyOfMicrobiltItemTaxIdType;
  };

export type EntityPatchResponseSetupSubmissionStructureNaturalPersonAnyOfIdentityVerificationStatus =
  (typeof EntityPatchResponseSetupSubmissionStructureNaturalPersonAnyOfIdentityVerificationStatus)[keyof typeof EntityPatchResponseSetupSubmissionStructureNaturalPersonAnyOfIdentityVerificationStatus];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const EntityPatchResponseSetupSubmissionStructureNaturalPersonAnyOfIdentityVerificationStatus =
  {
    pending_verification: "pending_verification",
    update_pending_verification: "update_pending_verification",
    verified: "verified",
    falsified: "falsified",
    no_match: "no_match",
    verifying: "verifying",
    requires_attention: "requires_attention",
    not_performed: "not_performed",
    skipped: "skipped",
  } as const;

export type EntityPatchResponseSetupSubmissionStructureNaturalPersonAnyOfIdentityDocumentSubmissionsItemSubmittedState =
  string | null;

export type EntityPatchResponseSetupSubmissionStructureNaturalPersonAnyOfIdentityDocumentSubmissionsItemSubmittedExpirationDate =
  string | null;

export type EntityPatchResponseSetupSubmissionStructureNaturalPersonAnyOfIdentityDocumentSubmissionsItemSubmittedDocumentType =
  (typeof EntityPatchResponseSetupSubmissionStructureNaturalPersonAnyOfIdentityDocumentSubmissionsItemSubmittedDocumentType)[keyof typeof EntityPatchResponseSetupSubmissionStructureNaturalPersonAnyOfIdentityDocumentSubmissionsItemSubmittedDocumentType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const EntityPatchResponseSetupSubmissionStructureNaturalPersonAnyOfIdentityDocumentSubmissionsItemSubmittedDocumentType =
  {
    passport: "passport",
    us_drivers_license: "us_drivers_license",
    other: "other",
  } as const;

export type EntityPatchResponseSetupSubmissionStructureNaturalPersonAnyOfIdentityDocumentSubmissionsItemSubmittedDocumentNumber =
  string | null;

export type EntityPatchResponseSetupSubmissionStructureNaturalPersonAnyOfIdentityDocumentSubmissionsItemSubmittedAddressLine2 =
  string | null;

export type EntityPatchResponseSetupSubmissionStructureNaturalPersonAnyOfIdentityDocumentSubmissionsItemSubmittedAddress =
  {
    city: string;
    line1: string;
    line2: EntityPatchResponseSetupSubmissionStructureNaturalPersonAnyOfIdentityDocumentSubmissionsItemSubmittedAddressLine2;
    state: string;
    zip: string;
  };

export type EntityPatchResponseSetupSubmissionStructureNaturalPersonAnyOfIdentityDocumentSubmissionsItemIdentityVerificationStatus =
  (typeof EntityPatchResponseSetupSubmissionStructureNaturalPersonAnyOfIdentityDocumentSubmissionsItemIdentityVerificationStatus)[keyof typeof EntityPatchResponseSetupSubmissionStructureNaturalPersonAnyOfIdentityDocumentSubmissionsItemIdentityVerificationStatus];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const EntityPatchResponseSetupSubmissionStructureNaturalPersonAnyOfIdentityDocumentSubmissionsItemIdentityVerificationStatus =
  {
    pending_verification: "pending_verification",
    update_pending_verification: "update_pending_verification",
    verified: "verified",
    falsified: "falsified",
    no_match: "no_match",
    verifying: "verifying",
    requires_attention: "requires_attention",
    not_performed: "not_performed",
    skipped: "skipped",
  } as const;

export type EntityPatchResponseSetupSubmissionStructureNaturalPersonAnyOfIdentityDocumentSubmissionsItemApiBackFileId =
  string | null;

export type EntityPatchResponseSetupSubmissionStructureNaturalPersonAnyOfIdentityDocumentSubmissionsItem =
  {
    api_back_file_id: EntityPatchResponseSetupSubmissionStructureNaturalPersonAnyOfIdentityDocumentSubmissionsItemApiBackFileId;
    api_file_id: string;
    created_at: string;
    group_id: string;
    id: string;
    identity_verification_status: EntityPatchResponseSetupSubmissionStructureNaturalPersonAnyOfIdentityDocumentSubmissionsItemIdentityVerificationStatus;
    submitted_address: EntityPatchResponseSetupSubmissionStructureNaturalPersonAnyOfIdentityDocumentSubmissionsItemSubmittedAddress;
    submitted_country: string;
    submitted_date_of_birth: string;
    submitted_document_number: EntityPatchResponseSetupSubmissionStructureNaturalPersonAnyOfIdentityDocumentSubmissionsItemSubmittedDocumentNumber;
    submitted_document_type: EntityPatchResponseSetupSubmissionStructureNaturalPersonAnyOfIdentityDocumentSubmissionsItemSubmittedDocumentType;
    submitted_expiration_date: EntityPatchResponseSetupSubmissionStructureNaturalPersonAnyOfIdentityDocumentSubmissionsItemSubmittedExpirationDate;
    submitted_name: string;
    submitted_state: EntityPatchResponseSetupSubmissionStructureNaturalPersonAnyOfIdentityDocumentSubmissionsItemSubmittedState;
  };

export type EntityPatchResponseSetupSubmissionStructureJoint =
  | EntityPatchResponseSetupSubmissionStructureJointAnyOfItem[]
  | null;

export type EntityPatchResponseSetupSubmissionStructureJointAnyOfItemTaxIdValidationStatus =
  (typeof EntityPatchResponseSetupSubmissionStructureJointAnyOfItemTaxIdValidationStatus)[keyof typeof EntityPatchResponseSetupSubmissionStructureJointAnyOfItemTaxIdValidationStatus];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const EntityPatchResponseSetupSubmissionStructureJointAnyOfItemTaxIdValidationStatus =
  {
    pending_validating: "pending_validating",
    update_pending_validating: "update_pending_validating",
    requires_attention: "requires_attention",
    failed: "failed",
    validated: "validated",
    not_provided: "not_provided",
    skipped: "skipped",
  } as const;

export type EntityPatchResponseSetupSubmissionStructureJointAnyOfItemSubmittedIdentificationMethod =
  (typeof EntityPatchResponseSetupSubmissionStructureJointAnyOfItemSubmittedIdentificationMethod)[keyof typeof EntityPatchResponseSetupSubmissionStructureJointAnyOfItemSubmittedIdentificationMethod];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const EntityPatchResponseSetupSubmissionStructureJointAnyOfItemSubmittedIdentificationMethod =
  {
    social_security_number: "social_security_number",
    individual_taxpayer_identification_number:
      "individual_taxpayer_identification_number",
    passport: "passport",
    drivers_license: "drivers_license",
    other: "other",
  } as const;

export type EntityPatchResponseSetupSubmissionStructureJointAnyOfItem = {
  created_at: string;
  id: string;
  identity_document_submissions: EntityPatchResponseSetupSubmissionStructureJointAnyOfItemIdentityDocumentSubmissionsItem[];
  identity_submitted_at: string;
  identity_verification_status: EntityPatchResponseSetupSubmissionStructureJointAnyOfItemIdentityVerificationStatus;
  microbilt: EntityPatchResponseSetupSubmissionStructureJointAnyOfItemMicrobiltItem[];
  submission_sovos_response_id: EntityPatchResponseSetupSubmissionStructureJointAnyOfItemSubmissionSovosResponseId;
  submitted_address: EntityPatchResponseSetupSubmissionStructureJointAnyOfItemSubmittedAddress;
  submitted_date_of_birth: string;
  submitted_identification_method: EntityPatchResponseSetupSubmissionStructureJointAnyOfItemSubmittedIdentificationMethod;
  submitted_identification_number_last4: string;
  submitted_name: string;
  tax_id_validation_status: EntityPatchResponseSetupSubmissionStructureJointAnyOfItemTaxIdValidationStatus;
};

export type EntityPatchResponseSetupSubmissionStructureJointAnyOfItemSubmittedAddressLine2 =
  string | null;

export type EntityPatchResponseSetupSubmissionStructureJointAnyOfItemSubmittedAddress =
  {
    city: string;
    line1: string;
    line2: EntityPatchResponseSetupSubmissionStructureJointAnyOfItemSubmittedAddressLine2;
    state: string;
    zip: string;
  };

export type EntityPatchResponseSetupSubmissionStructureJointAnyOfItemSubmissionSovosResponseId =
  string | null;

export type EntityPatchResponseSetupSubmissionStructureJointAnyOfItemMicrobiltItemTaxIdType =
  "SSN" | null;

export type EntityPatchResponseSetupSubmissionStructureJointAnyOfItemMicrobiltItemTaxIdLast4 =
  string | null;

export type EntityPatchResponseSetupSubmissionStructureJointAnyOfItemMicrobiltItemReturnCode =
  (typeof EntityPatchResponseSetupSubmissionStructureJointAnyOfItemMicrobiltItemReturnCode)[keyof typeof EntityPatchResponseSetupSubmissionStructureJointAnyOfItemMicrobiltItemReturnCode];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const EntityPatchResponseSetupSubmissionStructureJointAnyOfItemMicrobiltItemReturnCode =
  {
    OK: "OK",
  } as const;

export type EntityPatchResponseSetupSubmissionStructureJointAnyOfItemMicrobiltItemMiddleName =
  string | null;

export type EntityPatchResponseSetupSubmissionStructureJointAnyOfItemMicrobiltItemDeathDate =
  string | null;

export type EntityPatchResponseSetupSubmissionStructureJointAnyOfItemMicrobiltItemBirthDate =
  string | null;

export type EntityPatchResponseSetupSubmissionStructureJointAnyOfItemMicrobiltItem =
  {
    birth_date: EntityPatchResponseSetupSubmissionStructureJointAnyOfItemMicrobiltItemBirthDate;
    created_at: string;
    death_date: EntityPatchResponseSetupSubmissionStructureJointAnyOfItemMicrobiltItemDeathDate;
    first_name: string;
    id: string;
    last_name: string;
    middle_name: EntityPatchResponseSetupSubmissionStructureJointAnyOfItemMicrobiltItemMiddleName;
    request_id: string;
    response: string;
    return_code: EntityPatchResponseSetupSubmissionStructureJointAnyOfItemMicrobiltItemReturnCode;
    tax_id_last_4: EntityPatchResponseSetupSubmissionStructureJointAnyOfItemMicrobiltItemTaxIdLast4;
    tax_id_type: EntityPatchResponseSetupSubmissionStructureJointAnyOfItemMicrobiltItemTaxIdType;
  };

export type EntityPatchResponseSetupSubmissionStructureJointAnyOfItemIdentityVerificationStatus =
  (typeof EntityPatchResponseSetupSubmissionStructureJointAnyOfItemIdentityVerificationStatus)[keyof typeof EntityPatchResponseSetupSubmissionStructureJointAnyOfItemIdentityVerificationStatus];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const EntityPatchResponseSetupSubmissionStructureJointAnyOfItemIdentityVerificationStatus =
  {
    pending_verification: "pending_verification",
    update_pending_verification: "update_pending_verification",
    verified: "verified",
    falsified: "falsified",
    no_match: "no_match",
    verifying: "verifying",
    requires_attention: "requires_attention",
    not_performed: "not_performed",
    skipped: "skipped",
  } as const;

export type EntityPatchResponseSetupSubmissionStructureJointAnyOfItemIdentityDocumentSubmissionsItemSubmittedState =
  string | null;

export type EntityPatchResponseSetupSubmissionStructureJointAnyOfItemIdentityDocumentSubmissionsItemSubmittedExpirationDate =
  string | null;

export type EntityPatchResponseSetupSubmissionStructureJointAnyOfItemIdentityDocumentSubmissionsItemSubmittedDocumentType =
  (typeof EntityPatchResponseSetupSubmissionStructureJointAnyOfItemIdentityDocumentSubmissionsItemSubmittedDocumentType)[keyof typeof EntityPatchResponseSetupSubmissionStructureJointAnyOfItemIdentityDocumentSubmissionsItemSubmittedDocumentType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const EntityPatchResponseSetupSubmissionStructureJointAnyOfItemIdentityDocumentSubmissionsItemSubmittedDocumentType =
  {
    passport: "passport",
    us_drivers_license: "us_drivers_license",
    other: "other",
  } as const;

export type EntityPatchResponseSetupSubmissionStructureJointAnyOfItemIdentityDocumentSubmissionsItemSubmittedDocumentNumber =
  string | null;

export type EntityPatchResponseSetupSubmissionStructureJointAnyOfItemIdentityDocumentSubmissionsItem =
  {
    api_back_file_id: EntityPatchResponseSetupSubmissionStructureJointAnyOfItemIdentityDocumentSubmissionsItemApiBackFileId;
    api_file_id: string;
    created_at: string;
    group_id: string;
    id: string;
    identity_verification_status: EntityPatchResponseSetupSubmissionStructureJointAnyOfItemIdentityDocumentSubmissionsItemIdentityVerificationStatus;
    submitted_address: EntityPatchResponseSetupSubmissionStructureJointAnyOfItemIdentityDocumentSubmissionsItemSubmittedAddress;
    submitted_country: string;
    submitted_date_of_birth: string;
    submitted_document_number: EntityPatchResponseSetupSubmissionStructureJointAnyOfItemIdentityDocumentSubmissionsItemSubmittedDocumentNumber;
    submitted_document_type: EntityPatchResponseSetupSubmissionStructureJointAnyOfItemIdentityDocumentSubmissionsItemSubmittedDocumentType;
    submitted_expiration_date: EntityPatchResponseSetupSubmissionStructureJointAnyOfItemIdentityDocumentSubmissionsItemSubmittedExpirationDate;
    submitted_name: string;
    submitted_state: EntityPatchResponseSetupSubmissionStructureJointAnyOfItemIdentityDocumentSubmissionsItemSubmittedState;
  };

export type EntityPatchResponseSetupSubmissionStructureJointAnyOfItemIdentityDocumentSubmissionsItemSubmittedAddressLine2 =
  string | null;

export type EntityPatchResponseSetupSubmissionStructureJointAnyOfItemIdentityDocumentSubmissionsItemSubmittedAddress =
  {
    city: string;
    line1: string;
    line2: EntityPatchResponseSetupSubmissionStructureJointAnyOfItemIdentityDocumentSubmissionsItemSubmittedAddressLine2;
    state: string;
    zip: string;
  };

export type EntityPatchResponseSetupSubmissionStructureJointAnyOfItemIdentityDocumentSubmissionsItemIdentityVerificationStatus =
  (typeof EntityPatchResponseSetupSubmissionStructureJointAnyOfItemIdentityDocumentSubmissionsItemIdentityVerificationStatus)[keyof typeof EntityPatchResponseSetupSubmissionStructureJointAnyOfItemIdentityDocumentSubmissionsItemIdentityVerificationStatus];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const EntityPatchResponseSetupSubmissionStructureJointAnyOfItemIdentityDocumentSubmissionsItemIdentityVerificationStatus =
  {
    pending_verification: "pending_verification",
    update_pending_verification: "update_pending_verification",
    verified: "verified",
    falsified: "falsified",
    no_match: "no_match",
    verifying: "verifying",
    requires_attention: "requires_attention",
    not_performed: "not_performed",
    skipped: "skipped",
  } as const;

export type EntityPatchResponseSetupSubmissionStructureJointAnyOfItemIdentityDocumentSubmissionsItemApiBackFileId =
  string | null;

export type EntityPatchResponseSetupSubmissionStructureGovernmentAuthorityAnyOfTaxIdValidationStatus =
  (typeof EntityPatchResponseSetupSubmissionStructureGovernmentAuthorityAnyOfTaxIdValidationStatus)[keyof typeof EntityPatchResponseSetupSubmissionStructureGovernmentAuthorityAnyOfTaxIdValidationStatus];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const EntityPatchResponseSetupSubmissionStructureGovernmentAuthorityAnyOfTaxIdValidationStatus =
  {
    pending_validating: "pending_validating",
    update_pending_validating: "update_pending_validating",
    requires_attention: "requires_attention",
    failed: "failed",
    validated: "validated",
    not_provided: "not_provided",
    skipped: "skipped",
  } as const;

export type EntityPatchResponseSetupSubmissionStructureGovernmentAuthorityAnyOfSubmittedWebsite =
  string | null;

export type EntityPatchResponseSetupSubmissionStructureGovernmentAuthorityAnyOfSubmittedTaxId =
  string | null;

export type EntityPatchResponseSetupSubmissionStructureGovernmentAuthorityAnyOf =
  {
    entity_setup_government_authorized_person_relationships: EntityPatchResponseSetupSubmissionStructureGovernmentAuthorityAnyOfEntitySetupGovernmentAuthorizedPersonRelationshipsItem[];
    id: string;
    submitted_address: EntityPatchResponseSetupSubmissionStructureGovernmentAuthorityAnyOfSubmittedAddress;
    submitted_category: string;
    submitted_name: string;
    submitted_tax_id: EntityPatchResponseSetupSubmissionStructureGovernmentAuthorityAnyOfSubmittedTaxId;
    submitted_website: EntityPatchResponseSetupSubmissionStructureGovernmentAuthorityAnyOfSubmittedWebsite;
    tax_id_validation_status: EntityPatchResponseSetupSubmissionStructureGovernmentAuthorityAnyOfTaxIdValidationStatus;
  };

export type EntityPatchResponseSetupSubmissionStructureGovernmentAuthority =
  EntityPatchResponseSetupSubmissionStructureGovernmentAuthorityAnyOf | null;

export type EntityPatchResponseSetupSubmissionStructureGovernmentAuthorityAnyOfSubmittedAddressLine2 =
  string | null;

export type EntityPatchResponseSetupSubmissionStructureGovernmentAuthorityAnyOfSubmittedAddress =
  {
    city: string;
    line1: string;
    line2: EntityPatchResponseSetupSubmissionStructureGovernmentAuthorityAnyOfSubmittedAddressLine2;
    state: string;
    zip: string;
  };

export type EntityPatchResponseSetupSubmissionStructureGovernmentAuthorityAnyOfEntitySetupGovernmentAuthorizedPersonRelationshipsItemEntitySetupGovernmentAuthorizedPersonSubmission =
  {
    id: string;
    submitted_name: string;
  };

export type EntityPatchResponseSetupSubmissionStructureGovernmentAuthorityAnyOfEntitySetupGovernmentAuthorizedPersonRelationshipsItem =
  {
    archived: boolean;
    entity_setup_government_authority_submission_id: string;
    entity_setup_government_authorized_person_submission: EntityPatchResponseSetupSubmissionStructureGovernmentAuthorityAnyOfEntitySetupGovernmentAuthorizedPersonRelationshipsItemEntitySetupGovernmentAuthorizedPersonSubmission;
    id: string;
  };

export type EntityPatchResponseSetupSubmissionStructureCorporation =
  EntityPatchResponseSetupSubmissionStructureCorporationAnyOf | null;

export type EntityPatchResponseSetupSubmissionStructureCorporationAnyOfTaxIdValidationStatus =
  (typeof EntityPatchResponseSetupSubmissionStructureCorporationAnyOfTaxIdValidationStatus)[keyof typeof EntityPatchResponseSetupSubmissionStructureCorporationAnyOfTaxIdValidationStatus];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const EntityPatchResponseSetupSubmissionStructureCorporationAnyOfTaxIdValidationStatus =
  {
    pending_validating: "pending_validating",
    update_pending_validating: "update_pending_validating",
    requires_attention: "requires_attention",
    failed: "failed",
    validated: "validated",
    not_provided: "not_provided",
    skipped: "skipped",
  } as const;

export type EntityPatchResponseSetupSubmissionStructureCorporationAnyOfSubmittedWebsite =
  string | null;

export type EntityPatchResponseSetupSubmissionStructureCorporationAnyOfSubmittedTaxId =
  string | null;

export type EntityPatchResponseSetupSubmissionStructureCorporationAnyOfSubmittedState =
  string | null;

export type EntityPatchResponseSetupSubmissionStructureCorporationAnyOfSubmittedAddressLine2 =
  string | null;

export type EntityPatchResponseSetupSubmissionStructureCorporationAnyOfSubmittedAddress =
  {
    city: string;
    line1: string;
    line2: EntityPatchResponseSetupSubmissionStructureCorporationAnyOfSubmittedAddressLine2;
    state: string;
    zip: string;
  };

export type EntityPatchResponseSetupSubmissionStructureCorporationAnyOfSubmissionSovosResponseId =
  string | null;

export type EntityPatchResponseSetupSubmissionStructureCorporationAnyOfIndustryCode =

    | "11"
    | "111"
    | "1111"
    | "11111"
    | "111110"
    | "11112"
    | "111120"
    | "11113"
    | "111130"
    | "11114"
    | "111140"
    | "11115"
    | "111150"
    | "11116"
    | "111160"
    | "11119"
    | "111191"
    | "111199"
    | "1112"
    | "11121"
    | "111211"
    | "111219"
    | "1113"
    | "11131"
    | "111310"
    | "11132"
    | "111320"
    | "11133"
    | "111331"
    | "111332"
    | "111333"
    | "111334"
    | "111335"
    | "111336"
    | "111339"
    | "1114"
    | "11141"
    | "111411"
    | "111419"
    | "11142"
    | "111421"
    | "111422"
    | "1119"
    | "11191"
    | "111910"
    | "11192"
    | "111920"
    | "11193"
    | "111930"
    | "11194"
    | "111940"
    | "11199"
    | "111991"
    | "111992"
    | "111998"
    | "112"
    | "1121"
    | "11211"
    | "112111"
    | "112112"
    | "11212"
    | "112120"
    | "11213"
    | "112130"
    | "1122"
    | "11221"
    | "112210"
    | "1123"
    | "11231"
    | "112310"
    | "11232"
    | "112320"
    | "11233"
    | "112330"
    | "11234"
    | "112340"
    | "11239"
    | "112390"
    | "1124"
    | "11241"
    | "112410"
    | "11242"
    | "112420"
    | "1125"
    | "11251"
    | "112511"
    | "112512"
    | "112519"
    | "1129"
    | "11291"
    | "112910"
    | "11292"
    | "112920"
    | "11293"
    | "112930"
    | "11299"
    | "112990"
    | "113"
    | "1131"
    | "11311"
    | "113110"
    | "1132"
    | "11321"
    | "113210"
    | "1133"
    | "11331"
    | "113310"
    | "114"
    | "1141"
    | "11411"
    | "114111"
    | "114112"
    | "114119"
    | "1142"
    | "11421"
    | "114210"
    | "115"
    | "1151"
    | "11511"
    | "115111"
    | "115112"
    | "115113"
    | "115114"
    | "115115"
    | "115116"
    | "1152"
    | "11521"
    | "115210"
    | "1153"
    | "11531"
    | "115310"
    | "21"
    | "211"
    | "2111"
    | "21112"
    | "211120"
    | "21113"
    | "211130"
    | "212"
    | "2121"
    | "21211"
    | "212114"
    | "212115"
    | "2122"
    | "21221"
    | "212210"
    | "21222"
    | "212220"
    | "21223"
    | "212230"
    | "21229"
    | "212290"
    | "2123"
    | "21231"
    | "212311"
    | "212312"
    | "212313"
    | "212319"
    | "21232"
    | "212321"
    | "212322"
    | "212323"
    | "21239"
    | "212390"
    | "213"
    | "2131"
    | "21311"
    | "213111"
    | "213112"
    | "213113"
    | "213114"
    | "213115"
    | "22"
    | "221"
    | "2211"
    | "22111"
    | "221111"
    | "221112"
    | "221113"
    | "221114"
    | "221115"
    | "221116"
    | "221117"
    | "221118"
    | "22112"
    | "221121"
    | "221122"
    | "2212"
    | "22121"
    | "221210"
    | "2213"
    | "22131"
    | "221310"
    | "22132"
    | "221320"
    | "22133"
    | "221330"
    | "23"
    | "236"
    | "2361"
    | "23611"
    | "236115"
    | "236116"
    | "236117"
    | "236118"
    | "2362"
    | "23621"
    | "236210"
    | "23622"
    | "236220"
    | "237"
    | "2371"
    | "23711"
    | "237110"
    | "23712"
    | "237120"
    | "23713"
    | "237130"
    | "2372"
    | "23721"
    | "237210"
    | "2373"
    | "23731"
    | "237310"
    | "2379"
    | "23799"
    | "237990"
    | "238"
    | "2381"
    | "23811"
    | "238110"
    | "23812"
    | "238120"
    | "23813"
    | "238130"
    | "23814"
    | "238140"
    | "23815"
    | "238150"
    | "23816"
    | "238160"
    | "23817"
    | "238170"
    | "23819"
    | "238190"
    | "2382"
    | "23821"
    | "238210"
    | "23822"
    | "238220"
    | "23829"
    | "238290"
    | "2383"
    | "23831"
    | "238310"
    | "23832"
    | "238320"
    | "23833"
    | "238330"
    | "23834"
    | "238340"
    | "23835"
    | "238350"
    | "23839"
    | "238390"
    | "2389"
    | "23891"
    | "238910"
    | "23899"
    | "238990"
    | "31-33"
    | "311"
    | "3111"
    | "31111"
    | "311111"
    | "311119"
    | "3112"
    | "31121"
    | "311211"
    | "311212"
    | "311213"
    | "31122"
    | "311221"
    | "311224"
    | "311225"
    | "31123"
    | "311230"
    | "3113"
    | "31131"
    | "311313"
    | "311314"
    | "31134"
    | "311340"
    | "31135"
    | "311351"
    | "311352"
    | "3114"
    | "31141"
    | "311411"
    | "311412"
    | "31142"
    | "311421"
    | "311422"
    | "311423"
    | "3115"
    | "31151"
    | "311511"
    | "311512"
    | "311513"
    | "311514"
    | "31152"
    | "311520"
    | "3116"
    | "31161"
    | "311611"
    | "311612"
    | "311613"
    | "311615"
    | "3117"
    | "31171"
    | "311710"
    | "3118"
    | "31181"
    | "311811"
    | "311812"
    | "311813"
    | "31182"
    | "311821"
    | "311824"
    | "31183"
    | "311830"
    | "3119"
    | "31191"
    | "311911"
    | "311919"
    | "31192"
    | "311920"
    | "31193"
    | "311930"
    | "31194"
    | "311941"
    | "311942"
    | "31199"
    | "311991"
    | "311999"
    | "312"
    | "3121"
    | "31211"
    | "312111"
    | "312112"
    | "312113"
    | "31212"
    | "312120"
    | "31213"
    | "312130"
    | "31214"
    | "312140"
    | "3122"
    | "31223"
    | "312230"
    | "313"
    | "3131"
    | "31311"
    | "313110"
    | "3132"
    | "31321"
    | "313210"
    | "31322"
    | "313220"
    | "31323"
    | "313230"
    | "31324"
    | "313240"
    | "3133"
    | "31331"
    | "313310"
    | "31332"
    | "313320"
    | "314"
    | "3141"
    | "31411"
    | "314110"
    | "31412"
    | "314120"
    | "3149"
    | "31491"
    | "314910"
    | "31499"
    | "314994"
    | "314999"
    | "315"
    | "3151"
    | "31512"
    | "315120"
    | "3152"
    | "31521"
    | "315210"
    | "31525"
    | "315250"
    | "3159"
    | "31599"
    | "315990"
    | "316"
    | "3161"
    | "31611"
    | "316110"
    | "3162"
    | "31621"
    | "316210"
    | "3169"
    | "31699"
    | "316990"
    | "321"
    | "3211"
    | "32111"
    | "321113"
    | "321114"
    | "3212"
    | "32121"
    | "321211"
    | "321212"
    | "321215"
    | "321219"
    | "3219"
    | "32191"
    | "321911"
    | "321912"
    | "321918"
    | "32192"
    | "321920"
    | "32199"
    | "321991"
    | "321992"
    | "321999"
    | "322"
    | "3221"
    | "32211"
    | "322110"
    | "32212"
    | "322120"
    | "32213"
    | "322130"
    | "3222"
    | "32221"
    | "322211"
    | "322212"
    | "322219"
    | "32222"
    | "322220"
    | "32223"
    | "322230"
    | "32229"
    | "322291"
    | "322299"
    | "323"
    | "3231"
    | "32311"
    | "323111"
    | "323113"
    | "323117"
    | "32312"
    | "323120"
    | "324"
    | "3241"
    | "32411"
    | "324110"
    | "32412"
    | "324121"
    | "324122"
    | "32419"
    | "324191"
    | "324199"
    | "325"
    | "3251"
    | "32511"
    | "325110"
    | "32512"
    | "325120"
    | "32513"
    | "325130"
    | "32518"
    | "325180"
    | "32519"
    | "325193"
    | "325194"
    | "325199"
    | "3252"
    | "32521"
    | "325211"
    | "325212"
    | "32522"
    | "325220"
    | "3253"
    | "32531"
    | "325311"
    | "325312"
    | "325314"
    | "325315"
    | "32532"
    | "325320"
    | "3254"
    | "32541"
    | "325411"
    | "325412"
    | "325413"
    | "325414"
    | "3255"
    | "32551"
    | "325510"
    | "32552"
    | "325520"
    | "3256"
    | "32561"
    | "325611"
    | "325612"
    | "325613"
    | "32562"
    | "325620"
    | "3259"
    | "32591"
    | "325910"
    | "32592"
    | "325920"
    | "32599"
    | "325991"
    | "325992"
    | "325998"
    | "326"
    | "3261"
    | "32611"
    | "326111"
    | "326112"
    | "326113"
    | "32612"
    | "326121"
    | "326122"
    | "32613"
    | "326130"
    | "32614"
    | "326140"
    | "32615"
    | "326150"
    | "32616"
    | "326160"
    | "32619"
    | "326191"
    | "326199"
    | "3262"
    | "32621"
    | "326211"
    | "326212"
    | "32622"
    | "326220"
    | "32629"
    | "326291"
    | "326299"
    | "327"
    | "3271"
    | "32711"
    | "327110"
    | "32712"
    | "327120"
    | "3272"
    | "32721"
    | "327211"
    | "327212"
    | "327213"
    | "327215"
    | "3273"
    | "32731"
    | "327310"
    | "32732"
    | "327320"
    | "32733"
    | "327331"
    | "327332"
    | "32739"
    | "327390"
    | "3274"
    | "32741"
    | "327410"
    | "32742"
    | "327420"
    | "3279"
    | "32791"
    | "327910"
    | "32799"
    | "327991"
    | "327992"
    | "327993"
    | "327999"
    | "331"
    | "3311"
    | "33111"
    | "331110"
    | "3312"
    | "33121"
    | "331210"
    | "33122"
    | "331221"
    | "331222"
    | "3313"
    | "33131"
    | "331313"
    | "331314"
    | "331315"
    | "331318"
    | "3314"
    | "33141"
    | "331410"
    | "33142"
    | "331420"
    | "33149"
    | "331491"
    | "331492"
    | "3315"
    | "33151"
    | "331511"
    | "331512"
    | "331513"
    | "33152"
    | "331523"
    | "331524"
    | "331529"
    | "332"
    | "3321"
    | "33211"
    | "332111"
    | "332112"
    | "332114"
    | "332117"
    | "332119"
    | "3322"
    | "33221"
    | "332215"
    | "332216"
    | "3323"
    | "33231"
    | "332311"
    | "332312"
    | "332313"
    | "33232"
    | "332321"
    | "332322"
    | "332323"
    | "3324"
    | "33241"
    | "332410"
    | "33242"
    | "332420"
    | "33243"
    | "332431"
    | "332439"
    | "3325"
    | "33251"
    | "332510"
    | "3326"
    | "33261"
    | "332613"
    | "332618"
    | "3327"
    | "33271"
    | "332710"
    | "33272"
    | "332721"
    | "332722"
    | "3328"
    | "33281"
    | "332811"
    | "332812"
    | "332813"
    | "3329"
    | "33291"
    | "332911"
    | "332912"
    | "332913"
    | "332919"
    | "33299"
    | "332991"
    | "332992"
    | "332993"
    | "332994"
    | "332996"
    | "332999"
    | "333"
    | "3331"
    | "33311"
    | "333111"
    | "333112"
    | "33312"
    | "333120"
    | "33313"
    | "333131"
    | "333132"
    | "3332"
    | "33324"
    | "333241"
    | "333242"
    | "333243"
    | "333248"
    | "3333"
    | "33331"
    | "333310"
    | "3334"
    | "33341"
    | "333413"
    | "333414"
    | "333415"
    | "3335"
    | "33351"
    | "333511"
    | "333514"
    | "333515"
    | "333517"
    | "333519"
    | "3336"
    | "33361"
    | "333611"
    | "333612"
    | "333613"
    | "333618"
    | "3339"
    | "33391"
    | "333912"
    | "333914"
    | "33392"
    | "333921"
    | "333922"
    | "333923"
    | "333924"
    | "33399"
    | "333991"
    | "333992"
    | "333993"
    | "333994"
    | "333995"
    | "333996"
    | "333998"
    | "334"
    | "3341"
    | "33411"
    | "334111"
    | "334112"
    | "334118"
    | "3342"
    | "33421"
    | "334210"
    | "33422"
    | "334220"
    | "33429"
    | "334290"
    | "3343"
    | "33431"
    | "334310"
    | "3344"
    | "33441"
    | "334412"
    | "334413"
    | "334416"
    | "334417"
    | "334418"
    | "334419"
    | "3345"
    | "33451"
    | "334510"
    | "334511"
    | "334512"
    | "334513"
    | "334514"
    | "334515"
    | "334516"
    | "334517"
    | "334519"
    | "3346"
    | "33461"
    | "334610"
    | "335"
    | "3351"
    | "33513"
    | "335131"
    | "335132"
    | "335139"
    | "3352"
    | "33521"
    | "335210"
    | "33522"
    | "335220"
    | "3353"
    | "33531"
    | "335311"
    | "335312"
    | "335313"
    | "335314"
    | "3359"
    | "33591"
    | "335910"
    | "33592"
    | "335921"
    | "335929"
    | "33593"
    | "335931"
    | "335932"
    | "33599"
    | "335991"
    | "335999"
    | "336"
    | "3361"
    | "33611"
    | "336110"
    | "33612"
    | "336120"
    | "3362"
    | "33621"
    | "336211"
    | "336212"
    | "336213"
    | "336214"
    | "3363"
    | "33631"
    | "336310"
    | "33632"
    | "336320"
    | "33633"
    | "336330"
    | "33634"
    | "336340"
    | "33635"
    | "336350"
    | "33636"
    | "336360"
    | "33637"
    | "336370"
    | "33639"
    | "336390"
    | "3364"
    | "33641"
    | "336411"
    | "336412"
    | "336413"
    | "336414"
    | "336415"
    | "336419"
    | "3365"
    | "33651"
    | "336510"
    | "3366"
    | "33661"
    | "336611"
    | "336612"
    | "3369"
    | "33699"
    | "336991"
    | "336992"
    | "336999"
    | "337"
    | "3371"
    | "33711"
    | "337110"
    | "33712"
    | "337121"
    | "337122"
    | "337126"
    | "337127"
    | "3372"
    | "33721"
    | "337211"
    | "337212"
    | "337214"
    | "337215"
    | "3379"
    | "33791"
    | "337910"
    | "33792"
    | "337920"
    | "339"
    | "3391"
    | "33911"
    | "339112"
    | "339113"
    | "339114"
    | "339115"
    | "339116"
    | "3399"
    | "33991"
    | "339910"
    | "33992"
    | "339920"
    | "33993"
    | "339930"
    | "33994"
    | "339940"
    | "33995"
    | "339950"
    | "33999"
    | "339991"
    | "339992"
    | "339993"
    | "339994"
    | "339995"
    | "339999"
    | "42"
    | "423"
    | "4231"
    | "42311"
    | "423110"
    | "42312"
    | "423120"
    | "42313"
    | "423130"
    | "42314"
    | "423140"
    | "4232"
    | "42321"
    | "423210"
    | "42322"
    | "423220"
    | "4233"
    | "42331"
    | "423310"
    | "42332"
    | "423320"
    | "42333"
    | "423330"
    | "42339"
    | "423390"
    | "4234"
    | "42341"
    | "423410"
    | "42342"
    | "423420"
    | "42343"
    | "423430"
    | "42344"
    | "423440"
    | "42345"
    | "423450"
    | "42346"
    | "423460"
    | "42349"
    | "423490"
    | "4235"
    | "42351"
    | "423510"
    | "42352"
    | "423520"
    | "4236"
    | "42361"
    | "423610"
    | "42362"
    | "423620"
    | "42369"
    | "423690"
    | "4237"
    | "42371"
    | "423710"
    | "42372"
    | "423720"
    | "42373"
    | "423730"
    | "42374"
    | "423740"
    | "4238"
    | "42381"
    | "423810"
    | "42382"
    | "423820"
    | "42383"
    | "423830"
    | "42384"
    | "423840"
    | "42385"
    | "423850"
    | "42386"
    | "423860"
    | "4239"
    | "42391"
    | "423910"
    | "42392"
    | "423920"
    | "42393"
    | "423930"
    | "42394"
    | "423940"
    | "42399"
    | "423990"
    | "424"
    | "4241"
    | "42411"
    | "424110"
    | "42412"
    | "424120"
    | "42413"
    | "424130"
    | "4242"
    | "42421"
    | "424210"
    | "4243"
    | "42431"
    | "424310"
    | "42434"
    | "424340"
    | "42435"
    | "424350"
    | "4244"
    | "42441"
    | "424410"
    | "42442"
    | "424420"
    | "42443"
    | "424430"
    | "42444"
    | "424440"
    | "42445"
    | "424450"
    | "42446"
    | "424460"
    | "42447"
    | "424470"
    | "42448"
    | "424480"
    | "42449"
    | "424490"
    | "4245"
    | "42451"
    | "424510"
    | "42452"
    | "424520"
    | "42459"
    | "424590"
    | "4246"
    | "42461"
    | "424610"
    | "42469"
    | "424690"
    | "4247"
    | "42471"
    | "424710"
    | "42472"
    | "424720"
    | "4248"
    | "42481"
    | "424810"
    | "42482"
    | "424820"
    | "4249"
    | "42491"
    | "424910"
    | "42492"
    | "424920"
    | "42493"
    | "424930"
    | "42494"
    | "424940"
    | "42495"
    | "424950"
    | "42499"
    | "424990"
    | "425"
    | "4251"
    | "42512"
    | "425120"
    | "44-45"
    | "441"
    | "4411"
    | "44111"
    | "441110"
    | "44112"
    | "441120"
    | "4412"
    | "44121"
    | "441210"
    | "44122"
    | "441222"
    | "441227"
    | "4413"
    | "44133"
    | "441330"
    | "44134"
    | "441340"
    | "444"
    | "4441"
    | "44411"
    | "444110"
    | "44412"
    | "444120"
    | "44414"
    | "444140"
    | "44418"
    | "444180"
    | "4442"
    | "44423"
    | "444230"
    | "44424"
    | "444240"
    | "445"
    | "4451"
    | "44511"
    | "445110"
    | "44513"
    | "445131"
    | "445132"
    | "4452"
    | "44523"
    | "445230"
    | "44524"
    | "445240"
    | "44525"
    | "445250"
    | "44529"
    | "445291"
    | "445292"
    | "445298"
    | "4453"
    | "44532"
    | "445320"
    | "449"
    | "4491"
    | "44911"
    | "449110"
    | "44912"
    | "449121"
    | "449122"
    | "449129"
    | "4492"
    | "44921"
    | "449210"
    | "455"
    | "4551"
    | "45511"
    | "455110"
    | "4552"
    | "45521"
    | "455211"
    | "455219"
    | "456"
    | "4561"
    | "45611"
    | "456110"
    | "45612"
    | "456120"
    | "45613"
    | "456130"
    | "45619"
    | "456191"
    | "456199"
    | "457"
    | "4571"
    | "45711"
    | "457110"
    | "45712"
    | "457120"
    | "4572"
    | "45721"
    | "457210"
    | "458"
    | "4581"
    | "45811"
    | "458110"
    | "4582"
    | "45821"
    | "458210"
    | "4583"
    | "45831"
    | "458310"
    | "45832"
    | "458320"
    | "459"
    | "4591"
    | "45911"
    | "459110"
    | "45912"
    | "459120"
    | "45913"
    | "459130"
    | "45914"
    | "459140"
    | "4592"
    | "45921"
    | "459210"
    | "4593"
    | "45931"
    | "459310"
    | "4594"
    | "45941"
    | "459410"
    | "45942"
    | "459420"
    | "4595"
    | "45951"
    | "459510"
    | "4599"
    | "45991"
    | "459910"
    | "45992"
    | "459920"
    | "45993"
    | "459930"
    | "45999"
    | "459991"
    | "459999"
    | "48-49"
    | "481"
    | "4811"
    | "48111"
    | "481111"
    | "481112"
    | "4812"
    | "48121"
    | "481211"
    | "481212"
    | "481219"
    | "482"
    | "4821"
    | "48211"
    | "482111"
    | "482112"
    | "483"
    | "4831"
    | "48311"
    | "483111"
    | "483112"
    | "483113"
    | "483114"
    | "4832"
    | "48321"
    | "483211"
    | "483212"
    | "484"
    | "4841"
    | "48411"
    | "484110"
    | "48412"
    | "484121"
    | "484122"
    | "4842"
    | "48421"
    | "484210"
    | "48422"
    | "484220"
    | "48423"
    | "484230"
    | "485"
    | "4851"
    | "48511"
    | "485111"
    | "485112"
    | "485113"
    | "485119"
    | "4852"
    | "48521"
    | "485210"
    | "4853"
    | "48531"
    | "485310"
    | "48532"
    | "485320"
    | "4854"
    | "48541"
    | "485410"
    | "4855"
    | "48551"
    | "485510"
    | "4859"
    | "48599"
    | "485991"
    | "485999"
    | "486"
    | "4861"
    | "48611"
    | "486110"
    | "4862"
    | "48621"
    | "486210"
    | "4869"
    | "48691"
    | "486910"
    | "48699"
    | "486990"
    | "487"
    | "4871"
    | "48711"
    | "487110"
    | "4872"
    | "48721"
    | "487210"
    | "4879"
    | "48799"
    | "487990"
    | "488"
    | "4881"
    | "48811"
    | "488111"
    | "488119"
    | "48819"
    | "488190"
    | "4882"
    | "48821"
    | "488210"
    | "4883"
    | "48831"
    | "488310"
    | "48832"
    | "488320"
    | "48833"
    | "488330"
    | "48839"
    | "488390"
    | "4884"
    | "48841"
    | "488410"
    | "48849"
    | "488490"
    | "4885"
    | "48851"
    | "488510"
    | "4889"
    | "48899"
    | "488991"
    | "488999"
    | "491"
    | "4911"
    | "49111"
    | "491110"
    | "492"
    | "4921"
    | "49211"
    | "492110"
    | "4922"
    | "49221"
    | "492210"
    | "493"
    | "4931"
    | "49311"
    | "493110"
    | "49312"
    | "493120"
    | "49313"
    | "493130"
    | "49319"
    | "493190"
    | "51"
    | "512"
    | "5121"
    | "51211"
    | "512110"
    | "51212"
    | "512120"
    | "51213"
    | "512131"
    | "512132"
    | "51219"
    | "512191"
    | "512199"
    | "5122"
    | "51223"
    | "512230"
    | "51224"
    | "512240"
    | "51225"
    | "512250"
    | "51229"
    | "512290"
    | "513"
    | "5131"
    | "51311"
    | "513110"
    | "51312"
    | "513120"
    | "51313"
    | "513130"
    | "51314"
    | "513140"
    | "51319"
    | "513191"
    | "513199"
    | "5132"
    | "5112"
    | "51321"
    | "513210"
    | "516"
    | "5161"
    | "51611"
    | "516110"
    | "51612"
    | "516120"
    | "5162"
    | "51621"
    | "516210"
    | "517"
    | "5171"
    | "51711"
    | "517111"
    | "517112"
    | "51712"
    | "517121"
    | "517122"
    | "5174"
    | "51741"
    | "517410"
    | "5178"
    | "51781"
    | "517810"
    | "518"
    | "5182"
    | "51821"
    | "518210"
    | "519"
    | "5192"
    | "51921"
    | "519210"
    | "51929"
    | "519290"
    | "52"
    | "521"
    | "5211"
    | "52111"
    | "521110"
    | "522"
    | "5221"
    | "52211"
    | "522110"
    | "52213"
    | "522130"
    | "52218"
    | "522180"
    | "5222"
    | "52221"
    | "522210"
    | "52222"
    | "522220"
    | "52229"
    | "522291"
    | "522292"
    | "522299"
    | "5223"
    | "52231"
    | "522310"
    | "52232"
    | "522320"
    | "52239"
    | "522390"
    | "523"
    | "5231"
    | "52315"
    | "523150"
    | "52316"
    | "523160"
    | "5232"
    | "52321"
    | "523210"
    | "5239"
    | "52391"
    | "523910"
    | "52394"
    | "523940"
    | "52399"
    | "523991"
    | "523999"
    | "524"
    | "5241"
    | "52411"
    | "524113"
    | "524114"
    | "52412"
    | "524126"
    | "524127"
    | "524128"
    | "52413"
    | "524130"
    | "5242"
    | "52421"
    | "524210"
    | "52429"
    | "524291"
    | "524292"
    | "524298"
    | "525"
    | "5251"
    | "52511"
    | "525110"
    | "52512"
    | "525120"
    | "52519"
    | "525190"
    | "5259"
    | "52591"
    | "525910"
    | "52592"
    | "525920"
    | "52599"
    | "525990"
    | "53"
    | "531"
    | "5311"
    | "53111"
    | "531110"
    | "53112"
    | "531120"
    | "53113"
    | "531130"
    | "53119"
    | "531190"
    | "5312"
    | "53121"
    | "531210"
    | "5313"
    | "53131"
    | "531311"
    | "531312"
    | "53132"
    | "531320"
    | "53139"
    | "531390"
    | "532"
    | "5321"
    | "53211"
    | "532111"
    | "532112"
    | "53212"
    | "532120"
    | "5322"
    | "53221"
    | "532210"
    | "53228"
    | "532281"
    | "532282"
    | "532283"
    | "532284"
    | "532289"
    | "5323"
    | "53231"
    | "532310"
    | "5324"
    | "53241"
    | "532411"
    | "532412"
    | "53242"
    | "532420"
    | "53249"
    | "532490"
    | "533"
    | "5331"
    | "53311"
    | "533110"
    | "54"
    | "541"
    | "5411"
    | "54111"
    | "541110"
    | "54112"
    | "541120"
    | "54119"
    | "541191"
    | "541199"
    | "5412"
    | "54121"
    | "541211"
    | "541213"
    | "541214"
    | "541219"
    | "5413"
    | "54131"
    | "541310"
    | "54132"
    | "541320"
    | "54133"
    | "541330"
    | "54134"
    | "541340"
    | "54135"
    | "541350"
    | "54136"
    | "541360"
    | "54137"
    | "541370"
    | "54138"
    | "541380"
    | "5414"
    | "54141"
    | "541410"
    | "54142"
    | "541420"
    | "54143"
    | "541430"
    | "54149"
    | "541490"
    | "5415"
    | "54151"
    | "541511"
    | "541512"
    | "541513"
    | "541519"
    | "5416"
    | "54161"
    | "541611"
    | "541612"
    | "541613"
    | "541614"
    | "541618"
    | "54162"
    | "541620"
    | "54169"
    | "541690"
    | "5417"
    | "54171"
    | "541713"
    | "541714"
    | "541715"
    | "54172"
    | "541720"
    | "5418"
    | "54181"
    | "541810"
    | "54182"
    | "541820"
    | "54183"
    | "541830"
    | "54184"
    | "541840"
    | "54185"
    | "541850"
    | "54186"
    | "541860"
    | "54187"
    | "541870"
    | "54189"
    | "541890"
    | "5419"
    | "54191"
    | "541910"
    | "54192"
    | "541921"
    | "541922"
    | "54193"
    | "541930"
    | "54194"
    | "541940"
    | "54199"
    | "541990"
    | "55"
    | "551"
    | "5511"
    | "55111"
    | "551111"
    | "551112"
    | "551114"
    | "56"
    | "561"
    | "5611"
    | "56111"
    | "561110"
    | "5612"
    | "56121"
    | "561210"
    | "5613"
    | "56131"
    | "561311"
    | "561312"
    | "56132"
    | "561320"
    | "56133"
    | "561330"
    | "5614"
    | "56141"
    | "561410"
    | "56142"
    | "561421"
    | "561422"
    | "56143"
    | "561431"
    | "561439"
    | "56144"
    | "561440"
    | "56145"
    | "561450"
    | "56149"
    | "561491"
    | "561492"
    | "561499"
    | "5615"
    | "56151"
    | "561510"
    | "56152"
    | "561520"
    | "56159"
    | "561591"
    | "561599"
    | "5616"
    | "56161"
    | "561611"
    | "561612"
    | "561613"
    | "56162"
    | "561621"
    | "561622"
    | "5617"
    | "56171"
    | "561710"
    | "56172"
    | "561720"
    | "56173"
    | "561730"
    | "56174"
    | "561740"
    | "56179"
    | "561790"
    | "5619"
    | "56191"
    | "561910"
    | "56192"
    | "561920"
    | "56199"
    | "561990"
    | "562"
    | "5621"
    | "56211"
    | "562111"
    | "562112"
    | "562119"
    | "5622"
    | "56221"
    | "562211"
    | "562212"
    | "562213"
    | "562219"
    | "5629"
    | "56291"
    | "562910"
    | "56292"
    | "562920"
    | "56299"
    | "562991"
    | "562998"
    | "61"
    | "611"
    | "6111"
    | "61111"
    | "611110"
    | "6112"
    | "61121"
    | "611210"
    | "6113"
    | "61131"
    | "611310"
    | "6114"
    | "61141"
    | "611410"
    | "61142"
    | "611420"
    | "61143"
    | "611430"
    | "6115"
    | "61151"
    | "611511"
    | "611512"
    | "611513"
    | "611519"
    | "6116"
    | "61161"
    | "611610"
    | "61162"
    | "611620"
    | "61163"
    | "611630"
    | "61169"
    | "611691"
    | "611692"
    | "611699"
    | "6117"
    | "61171"
    | "611710"
    | "62"
    | "621"
    | "6211"
    | "62111"
    | "621111"
    | "621112"
    | "6212"
    | "62121"
    | "621210"
    | "6213"
    | "62131"
    | "621310"
    | "62132"
    | "621320"
    | "62133"
    | "621330"
    | "62134"
    | "621340"
    | "62139"
    | "621391"
    | "621399"
    | "6214"
    | "62141"
    | "621410"
    | "62142"
    | "621420"
    | "62149"
    | "621491"
    | "621492"
    | "621493"
    | "621498"
    | "6215"
    | "62151"
    | "621511"
    | "621512"
    | "6216"
    | "62161"
    | "621610"
    | "6219"
    | "62191"
    | "621910"
    | "62199"
    | "621991"
    | "621999"
    | "622"
    | "6221"
    | "62211"
    | "622110"
    | "6222"
    | "62221"
    | "622210"
    | "6223"
    | "62231"
    | "622310"
    | "623"
    | "6231"
    | "62311"
    | "623110"
    | "6232"
    | "62321"
    | "623210"
    | "62322"
    | "623220"
    | "6233"
    | "62331"
    | "623311"
    | "623312"
    | "6239"
    | "62399"
    | "623990"
    | "624"
    | "6241"
    | "62411"
    | "624110"
    | "62412"
    | "624120"
    | "62419"
    | "624190"
    | "6242"
    | "62421"
    | "624210"
    | "62422"
    | "624221"
    | "624229"
    | "62423"
    | "624230"
    | "6243"
    | "62431"
    | "624310"
    | "6244"
    | "62441"
    | "624410"
    | "71"
    | "711"
    | "7111"
    | "71111"
    | "711110"
    | "71112"
    | "711120"
    | "71113"
    | "711130"
    | "71119"
    | "711190"
    | "7112"
    | "71121"
    | "711211"
    | "711212"
    | "711219"
    | "7113"
    | "71131"
    | "711310"
    | "71132"
    | "711320"
    | "7114"
    | "71141"
    | "711410"
    | "7115"
    | "71151"
    | "711510"
    | "712"
    | "7121"
    | "71211"
    | "712110"
    | "71212"
    | "712120"
    | "71213"
    | "712130"
    | "71219"
    | "712190"
    | "713"
    | "7131"
    | "71311"
    | "713110"
    | "71312"
    | "713120"
    | "7132"
    | "71321"
    | "713210"
    | "71329"
    | "713290"
    | "7139"
    | "71391"
    | "713910"
    | "71392"
    | "713920"
    | "71393"
    | "713930"
    | "71394"
    | "713940"
    | "71395"
    | "713950"
    | "71399"
    | "713990"
    | "72"
    | "721"
    | "7211"
    | "72111"
    | "721110"
    | "72112"
    | "721120"
    | "72119"
    | "721191"
    | "721199"
    | "7212"
    | "72121"
    | "721211"
    | "721214"
    | "7213"
    | "72131"
    | "721310"
    | "722"
    | "7223"
    | "72231"
    | "722310"
    | "72232"
    | "722320"
    | "72233"
    | "722330"
    | "7224"
    | "72241"
    | "722410"
    | "7225"
    | "72251"
    | "722511"
    | "722513"
    | "722514"
    | "722515"
    | "81"
    | "811"
    | "8111"
    | "81111"
    | "811111"
    | "811114"
    | "81112"
    | "811121"
    | "811122"
    | "81119"
    | "811191"
    | "811192"
    | "811198"
    | "8112"
    | "81121"
    | "811210"
    | "8113"
    | "81131"
    | "811310"
    | "8114"
    | "81141"
    | "811411"
    | "811412"
    | "81142"
    | "811420"
    | "81143"
    | "811430"
    | "81149"
    | "811490"
    | "812"
    | "8121"
    | "81211"
    | "812111"
    | "812112"
    | "812113"
    | "81219"
    | "812191"
    | "812199"
    | "8122"
    | "81221"
    | "812210"
    | "81222"
    | "812220"
    | "8123"
    | "81231"
    | "812310"
    | "81232"
    | "812320"
    | "81233"
    | "812331"
    | "812332"
    | "8129"
    | "81291"
    | "812910"
    | "81292"
    | "812921"
    | "812922"
    | "81293"
    | "812930"
    | "81299"
    | "812990"
    | "813"
    | "8131"
    | "81311"
    | "813110"
    | "8132"
    | "81321"
    | "813211"
    | "813212"
    | "813219"
    | "8133"
    | "81331"
    | "813311"
    | "813312"
    | "813319"
    | "8134"
    | "81341"
    | "813410"
    | "8139"
    | "81391"
    | "813910"
    | "81392"
    | "813920"
    | "81393"
    | "813930"
    | "81394"
    | "813940"
    | "81399"
    | "813990"
    | "814"
    | "8141"
    | "81411"
    | "814110"
    | "92"
    | "921"
    | "9211"
    | "92111"
    | "921110"
    | "92112"
    | "921120"
    | "92113"
    | "921130"
    | "92114"
    | "921140"
    | "92115"
    | "921150"
    | "92119"
    | "921190"
    | "922"
    | "9221"
    | "92211"
    | "922110"
    | "92212"
    | "922120"
    | "92213"
    | "922130"
    | "92214"
    | "922140"
    | "92215"
    | "922150"
    | "92216"
    | "922160"
    | "92219"
    | "922190"
    | "923"
    | "9231"
    | "92311"
    | "923110"
    | "92312"
    | "923120"
    | "92313"
    | "923130"
    | "92314"
    | "923140"
    | "924"
    | "9241"
    | "92411"
    | "924110"
    | "92412"
    | "924120"
    | "925"
    | "9251"
    | "92511"
    | "925110"
    | "92512"
    | "925120"
    | "926"
    | "9261"
    | "92611"
    | "926110"
    | "92612"
    | "926120"
    | "92613"
    | "926130"
    | "92614"
    | "926140"
    | "92615"
    | "926150"
    | "927"
    | "9271"
    | "92711"
    | "927110"
    | "928"
    | "9281"
    | "92811"
    | "928110"
    | "92812"
    | "928120"
    | "999300"
    | null;

export type EntityPatchResponseSetupSubmissionStructureCorporationAnyOfEntitySetupCorporationBeneficialOwnerRelationshipsItemSubmittedCompanyTitle =
  string | null;

export type EntityPatchResponseSetupSubmissionStructureCorporationAnyOfEntitySetupCorporationBeneficialOwnerRelationshipsItemProng =
  (typeof EntityPatchResponseSetupSubmissionStructureCorporationAnyOfEntitySetupCorporationBeneficialOwnerRelationshipsItemProng)[keyof typeof EntityPatchResponseSetupSubmissionStructureCorporationAnyOfEntitySetupCorporationBeneficialOwnerRelationshipsItemProng];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const EntityPatchResponseSetupSubmissionStructureCorporationAnyOfEntitySetupCorporationBeneficialOwnerRelationshipsItemProng =
  {
    ownership: "ownership",
    control: "control",
  } as const;

export type EntityPatchResponseSetupSubmissionStructureCorporationAnyOfEntitySetupCorporationBeneficialOwnerRelationshipsItem =
  {
    archived: boolean;
    entity_setup_beneficial_owner_submission: EntityPatchResponseSetupSubmissionStructureCorporationAnyOfEntitySetupCorporationBeneficialOwnerRelationshipsItemEntitySetupBeneficialOwnerSubmission;
    entity_setup_corporation_submission_id: string;
    id: string;
    prong: EntityPatchResponseSetupSubmissionStructureCorporationAnyOfEntitySetupCorporationBeneficialOwnerRelationshipsItemProng;
    submitted_company_title: EntityPatchResponseSetupSubmissionStructureCorporationAnyOfEntitySetupCorporationBeneficialOwnerRelationshipsItemSubmittedCompanyTitle;
  };

export type EntityPatchResponseSetupSubmissionStructureCorporationAnyOf = {
  beneficial_ownership_exemption: EntityPatchResponseSetupSubmissionStructureCorporationAnyOfBeneficialOwnershipExemption;
  cobalt_intelligence_secretary_of_state_search_intents: EntityPatchResponseSetupSubmissionStructureCorporationAnyOfCobaltIntelligenceSecretaryOfStateSearchIntentsItem[];
  created_at: string;
  entity_setup_corporation_beneficial_owner_relationships: EntityPatchResponseSetupSubmissionStructureCorporationAnyOfEntitySetupCorporationBeneficialOwnerRelationshipsItem[];
  id: string;
  industry_code: EntityPatchResponseSetupSubmissionStructureCorporationAnyOfIndustryCode;
  submission_sovos_response_id: EntityPatchResponseSetupSubmissionStructureCorporationAnyOfSubmissionSovosResponseId;
  submitted_address: EntityPatchResponseSetupSubmissionStructureCorporationAnyOfSubmittedAddress;
  submitted_name: string;
  submitted_state: EntityPatchResponseSetupSubmissionStructureCorporationAnyOfSubmittedState;
  submitted_tax_id: EntityPatchResponseSetupSubmissionStructureCorporationAnyOfSubmittedTaxId;
  submitted_website: EntityPatchResponseSetupSubmissionStructureCorporationAnyOfSubmittedWebsite;
  tax_id_submitted_at: string;
  tax_id_validation_status: EntityPatchResponseSetupSubmissionStructureCorporationAnyOfTaxIdValidationStatus;
};

export type EntityPatchResponseSetupSubmissionStructureCorporationAnyOfEntitySetupCorporationBeneficialOwnerRelationshipsItemEntitySetupBeneficialOwnerSubmissionTaxIdValidationStatus =
  (typeof EntityPatchResponseSetupSubmissionStructureCorporationAnyOfEntitySetupCorporationBeneficialOwnerRelationshipsItemEntitySetupBeneficialOwnerSubmissionTaxIdValidationStatus)[keyof typeof EntityPatchResponseSetupSubmissionStructureCorporationAnyOfEntitySetupCorporationBeneficialOwnerRelationshipsItemEntitySetupBeneficialOwnerSubmissionTaxIdValidationStatus];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const EntityPatchResponseSetupSubmissionStructureCorporationAnyOfEntitySetupCorporationBeneficialOwnerRelationshipsItemEntitySetupBeneficialOwnerSubmissionTaxIdValidationStatus =
  {
    pending_validating: "pending_validating",
    update_pending_validating: "update_pending_validating",
    requires_attention: "requires_attention",
    failed: "failed",
    validated: "validated",
    not_provided: "not_provided",
    skipped: "skipped",
  } as const;

export type EntityPatchResponseSetupSubmissionStructureCorporationAnyOfEntitySetupCorporationBeneficialOwnerRelationshipsItemEntitySetupBeneficialOwnerSubmissionSubmittedIdentificationNumberLast4 =
  string | null;

export type EntityPatchResponseSetupSubmissionStructureCorporationAnyOfEntitySetupCorporationBeneficialOwnerRelationshipsItemEntitySetupBeneficialOwnerSubmissionSubmittedIdentificationMethod =
  (typeof EntityPatchResponseSetupSubmissionStructureCorporationAnyOfEntitySetupCorporationBeneficialOwnerRelationshipsItemEntitySetupBeneficialOwnerSubmissionSubmittedIdentificationMethod)[keyof typeof EntityPatchResponseSetupSubmissionStructureCorporationAnyOfEntitySetupCorporationBeneficialOwnerRelationshipsItemEntitySetupBeneficialOwnerSubmissionSubmittedIdentificationMethod];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const EntityPatchResponseSetupSubmissionStructureCorporationAnyOfEntitySetupCorporationBeneficialOwnerRelationshipsItemEntitySetupBeneficialOwnerSubmissionSubmittedIdentificationMethod =
  {
    social_security_number: "social_security_number",
    individual_taxpayer_identification_number:
      "individual_taxpayer_identification_number",
    passport: "passport",
    drivers_license: "drivers_license",
    other: "other",
  } as const;

export type EntityPatchResponseSetupSubmissionStructureCorporationAnyOfEntitySetupCorporationBeneficialOwnerRelationshipsItemEntitySetupBeneficialOwnerSubmissionSubmittedAddressLine2 =
  string | null;

export type EntityPatchResponseSetupSubmissionStructureCorporationAnyOfEntitySetupCorporationBeneficialOwnerRelationshipsItemEntitySetupBeneficialOwnerSubmissionSubmittedAddress =
  {
    city: string;
    line1: string;
    line2: EntityPatchResponseSetupSubmissionStructureCorporationAnyOfEntitySetupCorporationBeneficialOwnerRelationshipsItemEntitySetupBeneficialOwnerSubmissionSubmittedAddressLine2;
    state: string;
    zip: string;
  };

export type EntityPatchResponseSetupSubmissionStructureCorporationAnyOfEntitySetupCorporationBeneficialOwnerRelationshipsItemEntitySetupBeneficialOwnerSubmissionSubmissionSovosResponseId =
  string | null;

export type EntityPatchResponseSetupSubmissionStructureCorporationAnyOfEntitySetupCorporationBeneficialOwnerRelationshipsItemEntitySetupBeneficialOwnerSubmission =
  {
    created_at: string;
    id: string;
    identity_document_submissions: EntityPatchResponseSetupSubmissionStructureCorporationAnyOfEntitySetupCorporationBeneficialOwnerRelationshipsItemEntitySetupBeneficialOwnerSubmissionIdentityDocumentSubmissionsItem[];
    identity_verification_status: EntityPatchResponseSetupSubmissionStructureCorporationAnyOfEntitySetupCorporationBeneficialOwnerRelationshipsItemEntitySetupBeneficialOwnerSubmissionIdentityVerificationStatus;
    microbilt: EntityPatchResponseSetupSubmissionStructureCorporationAnyOfEntitySetupCorporationBeneficialOwnerRelationshipsItemEntitySetupBeneficialOwnerSubmissionMicrobiltItem[];
    submission_sovos_response_id: EntityPatchResponseSetupSubmissionStructureCorporationAnyOfEntitySetupCorporationBeneficialOwnerRelationshipsItemEntitySetupBeneficialOwnerSubmissionSubmissionSovosResponseId;
    submitted_address: EntityPatchResponseSetupSubmissionStructureCorporationAnyOfEntitySetupCorporationBeneficialOwnerRelationshipsItemEntitySetupBeneficialOwnerSubmissionSubmittedAddress;
    submitted_date_of_birth: string;
    submitted_identification_method: EntityPatchResponseSetupSubmissionStructureCorporationAnyOfEntitySetupCorporationBeneficialOwnerRelationshipsItemEntitySetupBeneficialOwnerSubmissionSubmittedIdentificationMethod;
    submitted_identification_number_last4: EntityPatchResponseSetupSubmissionStructureCorporationAnyOfEntitySetupCorporationBeneficialOwnerRelationshipsItemEntitySetupBeneficialOwnerSubmissionSubmittedIdentificationNumberLast4;
    submitted_name: string;
    tax_id_validation_status: EntityPatchResponseSetupSubmissionStructureCorporationAnyOfEntitySetupCorporationBeneficialOwnerRelationshipsItemEntitySetupBeneficialOwnerSubmissionTaxIdValidationStatus;
  };

export type EntityPatchResponseSetupSubmissionStructureCorporationAnyOfEntitySetupCorporationBeneficialOwnerRelationshipsItemEntitySetupBeneficialOwnerSubmissionMicrobiltItemTaxIdType =
  "SSN" | null;

export type EntityPatchResponseSetupSubmissionStructureCorporationAnyOfEntitySetupCorporationBeneficialOwnerRelationshipsItemEntitySetupBeneficialOwnerSubmissionMicrobiltItemTaxIdLast4 =
  string | null;

export type EntityPatchResponseSetupSubmissionStructureCorporationAnyOfEntitySetupCorporationBeneficialOwnerRelationshipsItemEntitySetupBeneficialOwnerSubmissionMicrobiltItemReturnCode =
  (typeof EntityPatchResponseSetupSubmissionStructureCorporationAnyOfEntitySetupCorporationBeneficialOwnerRelationshipsItemEntitySetupBeneficialOwnerSubmissionMicrobiltItemReturnCode)[keyof typeof EntityPatchResponseSetupSubmissionStructureCorporationAnyOfEntitySetupCorporationBeneficialOwnerRelationshipsItemEntitySetupBeneficialOwnerSubmissionMicrobiltItemReturnCode];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const EntityPatchResponseSetupSubmissionStructureCorporationAnyOfEntitySetupCorporationBeneficialOwnerRelationshipsItemEntitySetupBeneficialOwnerSubmissionMicrobiltItemReturnCode =
  {
    OK: "OK",
  } as const;

export type EntityPatchResponseSetupSubmissionStructureCorporationAnyOfEntitySetupCorporationBeneficialOwnerRelationshipsItemEntitySetupBeneficialOwnerSubmissionMicrobiltItemMiddleName =
  string | null;

export type EntityPatchResponseSetupSubmissionStructureCorporationAnyOfEntitySetupCorporationBeneficialOwnerRelationshipsItemEntitySetupBeneficialOwnerSubmissionMicrobiltItemDeathDate =
  string | null;

export type EntityPatchResponseSetupSubmissionStructureCorporationAnyOfEntitySetupCorporationBeneficialOwnerRelationshipsItemEntitySetupBeneficialOwnerSubmissionMicrobiltItemBirthDate =
  string | null;

export type EntityPatchResponseSetupSubmissionStructureCorporationAnyOfEntitySetupCorporationBeneficialOwnerRelationshipsItemEntitySetupBeneficialOwnerSubmissionMicrobiltItem =
  {
    birth_date: EntityPatchResponseSetupSubmissionStructureCorporationAnyOfEntitySetupCorporationBeneficialOwnerRelationshipsItemEntitySetupBeneficialOwnerSubmissionMicrobiltItemBirthDate;
    created_at: string;
    death_date: EntityPatchResponseSetupSubmissionStructureCorporationAnyOfEntitySetupCorporationBeneficialOwnerRelationshipsItemEntitySetupBeneficialOwnerSubmissionMicrobiltItemDeathDate;
    first_name: string;
    id: string;
    last_name: string;
    middle_name: EntityPatchResponseSetupSubmissionStructureCorporationAnyOfEntitySetupCorporationBeneficialOwnerRelationshipsItemEntitySetupBeneficialOwnerSubmissionMicrobiltItemMiddleName;
    request_id: string;
    response: string;
    return_code: EntityPatchResponseSetupSubmissionStructureCorporationAnyOfEntitySetupCorporationBeneficialOwnerRelationshipsItemEntitySetupBeneficialOwnerSubmissionMicrobiltItemReturnCode;
    tax_id_last_4: EntityPatchResponseSetupSubmissionStructureCorporationAnyOfEntitySetupCorporationBeneficialOwnerRelationshipsItemEntitySetupBeneficialOwnerSubmissionMicrobiltItemTaxIdLast4;
    tax_id_type: EntityPatchResponseSetupSubmissionStructureCorporationAnyOfEntitySetupCorporationBeneficialOwnerRelationshipsItemEntitySetupBeneficialOwnerSubmissionMicrobiltItemTaxIdType;
  };

export type EntityPatchResponseSetupSubmissionStructureCorporationAnyOfEntitySetupCorporationBeneficialOwnerRelationshipsItemEntitySetupBeneficialOwnerSubmissionIdentityVerificationStatus =
  (typeof EntityPatchResponseSetupSubmissionStructureCorporationAnyOfEntitySetupCorporationBeneficialOwnerRelationshipsItemEntitySetupBeneficialOwnerSubmissionIdentityVerificationStatus)[keyof typeof EntityPatchResponseSetupSubmissionStructureCorporationAnyOfEntitySetupCorporationBeneficialOwnerRelationshipsItemEntitySetupBeneficialOwnerSubmissionIdentityVerificationStatus];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const EntityPatchResponseSetupSubmissionStructureCorporationAnyOfEntitySetupCorporationBeneficialOwnerRelationshipsItemEntitySetupBeneficialOwnerSubmissionIdentityVerificationStatus =
  {
    pending_verification: "pending_verification",
    update_pending_verification: "update_pending_verification",
    verified: "verified",
    falsified: "falsified",
    no_match: "no_match",
    verifying: "verifying",
    requires_attention: "requires_attention",
    not_performed: "not_performed",
    skipped: "skipped",
  } as const;

export type EntityPatchResponseSetupSubmissionStructureCorporationAnyOfEntitySetupCorporationBeneficialOwnerRelationshipsItemEntitySetupBeneficialOwnerSubmissionIdentityDocumentSubmissionsItemSubmittedState =
  string | null;

export type EntityPatchResponseSetupSubmissionStructureCorporationAnyOfEntitySetupCorporationBeneficialOwnerRelationshipsItemEntitySetupBeneficialOwnerSubmissionIdentityDocumentSubmissionsItemSubmittedExpirationDate =
  string | null;

export type EntityPatchResponseSetupSubmissionStructureCorporationAnyOfEntitySetupCorporationBeneficialOwnerRelationshipsItemEntitySetupBeneficialOwnerSubmissionIdentityDocumentSubmissionsItemSubmittedDocumentType =
  (typeof EntityPatchResponseSetupSubmissionStructureCorporationAnyOfEntitySetupCorporationBeneficialOwnerRelationshipsItemEntitySetupBeneficialOwnerSubmissionIdentityDocumentSubmissionsItemSubmittedDocumentType)[keyof typeof EntityPatchResponseSetupSubmissionStructureCorporationAnyOfEntitySetupCorporationBeneficialOwnerRelationshipsItemEntitySetupBeneficialOwnerSubmissionIdentityDocumentSubmissionsItemSubmittedDocumentType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const EntityPatchResponseSetupSubmissionStructureCorporationAnyOfEntitySetupCorporationBeneficialOwnerRelationshipsItemEntitySetupBeneficialOwnerSubmissionIdentityDocumentSubmissionsItemSubmittedDocumentType =
  {
    passport: "passport",
    us_drivers_license: "us_drivers_license",
    other: "other",
  } as const;

export type EntityPatchResponseSetupSubmissionStructureCorporationAnyOfEntitySetupCorporationBeneficialOwnerRelationshipsItemEntitySetupBeneficialOwnerSubmissionIdentityDocumentSubmissionsItemSubmittedDocumentNumber =
  string | null;

export type EntityPatchResponseSetupSubmissionStructureCorporationAnyOfEntitySetupCorporationBeneficialOwnerRelationshipsItemEntitySetupBeneficialOwnerSubmissionIdentityDocumentSubmissionsItemSubmittedAddressLine2 =
  string | null;

export type EntityPatchResponseSetupSubmissionStructureCorporationAnyOfEntitySetupCorporationBeneficialOwnerRelationshipsItemEntitySetupBeneficialOwnerSubmissionIdentityDocumentSubmissionsItemSubmittedAddress =
  {
    city: string;
    line1: string;
    line2: EntityPatchResponseSetupSubmissionStructureCorporationAnyOfEntitySetupCorporationBeneficialOwnerRelationshipsItemEntitySetupBeneficialOwnerSubmissionIdentityDocumentSubmissionsItemSubmittedAddressLine2;
    state: string;
    zip: string;
  };

export type EntityPatchResponseSetupSubmissionStructureCorporationAnyOfEntitySetupCorporationBeneficialOwnerRelationshipsItemEntitySetupBeneficialOwnerSubmissionIdentityDocumentSubmissionsItemIdentityVerificationStatus =
  (typeof EntityPatchResponseSetupSubmissionStructureCorporationAnyOfEntitySetupCorporationBeneficialOwnerRelationshipsItemEntitySetupBeneficialOwnerSubmissionIdentityDocumentSubmissionsItemIdentityVerificationStatus)[keyof typeof EntityPatchResponseSetupSubmissionStructureCorporationAnyOfEntitySetupCorporationBeneficialOwnerRelationshipsItemEntitySetupBeneficialOwnerSubmissionIdentityDocumentSubmissionsItemIdentityVerificationStatus];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const EntityPatchResponseSetupSubmissionStructureCorporationAnyOfEntitySetupCorporationBeneficialOwnerRelationshipsItemEntitySetupBeneficialOwnerSubmissionIdentityDocumentSubmissionsItemIdentityVerificationStatus =
  {
    pending_verification: "pending_verification",
    update_pending_verification: "update_pending_verification",
    verified: "verified",
    falsified: "falsified",
    no_match: "no_match",
    verifying: "verifying",
    requires_attention: "requires_attention",
    not_performed: "not_performed",
    skipped: "skipped",
  } as const;

export type EntityPatchResponseSetupSubmissionStructureCorporationAnyOfEntitySetupCorporationBeneficialOwnerRelationshipsItemEntitySetupBeneficialOwnerSubmissionIdentityDocumentSubmissionsItemApiBackFileId =
  string | null;

export type EntityPatchResponseSetupSubmissionStructureCorporationAnyOfEntitySetupCorporationBeneficialOwnerRelationshipsItemEntitySetupBeneficialOwnerSubmissionIdentityDocumentSubmissionsItem =
  {
    api_back_file_id: EntityPatchResponseSetupSubmissionStructureCorporationAnyOfEntitySetupCorporationBeneficialOwnerRelationshipsItemEntitySetupBeneficialOwnerSubmissionIdentityDocumentSubmissionsItemApiBackFileId;
    api_file_id: string;
    created_at: string;
    group_id: string;
    id: string;
    identity_verification_status: EntityPatchResponseSetupSubmissionStructureCorporationAnyOfEntitySetupCorporationBeneficialOwnerRelationshipsItemEntitySetupBeneficialOwnerSubmissionIdentityDocumentSubmissionsItemIdentityVerificationStatus;
    submitted_address: EntityPatchResponseSetupSubmissionStructureCorporationAnyOfEntitySetupCorporationBeneficialOwnerRelationshipsItemEntitySetupBeneficialOwnerSubmissionIdentityDocumentSubmissionsItemSubmittedAddress;
    submitted_country: string;
    submitted_date_of_birth: string;
    submitted_document_number: EntityPatchResponseSetupSubmissionStructureCorporationAnyOfEntitySetupCorporationBeneficialOwnerRelationshipsItemEntitySetupBeneficialOwnerSubmissionIdentityDocumentSubmissionsItemSubmittedDocumentNumber;
    submitted_document_type: EntityPatchResponseSetupSubmissionStructureCorporationAnyOfEntitySetupCorporationBeneficialOwnerRelationshipsItemEntitySetupBeneficialOwnerSubmissionIdentityDocumentSubmissionsItemSubmittedDocumentType;
    submitted_expiration_date: EntityPatchResponseSetupSubmissionStructureCorporationAnyOfEntitySetupCorporationBeneficialOwnerRelationshipsItemEntitySetupBeneficialOwnerSubmissionIdentityDocumentSubmissionsItemSubmittedExpirationDate;
    submitted_name: string;
    submitted_state: EntityPatchResponseSetupSubmissionStructureCorporationAnyOfEntitySetupCorporationBeneficialOwnerRelationshipsItemEntitySetupBeneficialOwnerSubmissionIdentityDocumentSubmissionsItemSubmittedState;
  };

export type EntityPatchResponseSetupSubmissionStructureCorporationAnyOfCobaltIntelligenceSecretaryOfStateSearchIntentsItemSubmittedState =
  string | null;

export type EntityPatchResponseSetupSubmissionStructureCorporationAnyOfCobaltIntelligenceSecretaryOfStateSearchIntentsItemStatus =
  (typeof EntityPatchResponseSetupSubmissionStructureCorporationAnyOfCobaltIntelligenceSecretaryOfStateSearchIntentsItemStatus)[keyof typeof EntityPatchResponseSetupSubmissionStructureCorporationAnyOfCobaltIntelligenceSecretaryOfStateSearchIntentsItemStatus];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const EntityPatchResponseSetupSubmissionStructureCorporationAnyOfCobaltIntelligenceSecretaryOfStateSearchIntentsItemStatus =
  {
    pending: "pending",
    searching: "searching",
    retrying: "retrying",
    complete: "complete",
    failed: "failed",
    requires_attention: "requires_attention",
    skipped: "skipped",
  } as const;

export type EntityPatchResponseSetupSubmissionStructureCorporationAnyOfCobaltIntelligenceSecretaryOfStateSearchIntentsItemSearchStrategy =
  (typeof EntityPatchResponseSetupSubmissionStructureCorporationAnyOfCobaltIntelligenceSecretaryOfStateSearchIntentsItemSearchStrategy)[keyof typeof EntityPatchResponseSetupSubmissionStructureCorporationAnyOfCobaltIntelligenceSecretaryOfStateSearchIntentsItemSearchStrategy];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const EntityPatchResponseSetupSubmissionStructureCorporationAnyOfCobaltIntelligenceSecretaryOfStateSearchIntentsItemSearchStrategy =
  {
    incorporation_state: "incorporation_state",
    address_state: "address_state",
  } as const;

export type EntityPatchResponseSetupSubmissionStructureCorporationAnyOfCobaltIntelligenceSecretaryOfStateSearchIntentsItemSearchResponseAnyOf =
  {
    id: string;
    response_body: string;
  };

export type EntityPatchResponseSetupSubmissionStructureCorporationAnyOfCobaltIntelligenceSecretaryOfStateSearchIntentsItemSearchResponse =
  EntityPatchResponseSetupSubmissionStructureCorporationAnyOfCobaltIntelligenceSecretaryOfStateSearchIntentsItemSearchResponseAnyOf | null;

export type EntityPatchResponseSetupSubmissionStructureCorporationAnyOfCobaltIntelligenceSecretaryOfStateSearchIntentsItemOutcome =
  "found" | "not_found" | null;

export type EntityPatchResponseSetupSubmissionStructureCorporationAnyOfCobaltIntelligenceSecretaryOfStateSearchIntentsItem =
  {
    created_at: string;
    entity_id: string;
    id: string;
    outcome: EntityPatchResponseSetupSubmissionStructureCorporationAnyOfCobaltIntelligenceSecretaryOfStateSearchIntentsItemOutcome;
    search_response: EntityPatchResponseSetupSubmissionStructureCorporationAnyOfCobaltIntelligenceSecretaryOfStateSearchIntentsItemSearchResponse;
    search_strategy: EntityPatchResponseSetupSubmissionStructureCorporationAnyOfCobaltIntelligenceSecretaryOfStateSearchIntentsItemSearchStrategy;
    searchable_submission_class: string;
    searchable_submission_id: string;
    status: EntityPatchResponseSetupSubmissionStructureCorporationAnyOfCobaltIntelligenceSecretaryOfStateSearchIntentsItemStatus;
    submitted_address_state: string;
    submitted_name: string;
    submitted_state: EntityPatchResponseSetupSubmissionStructureCorporationAnyOfCobaltIntelligenceSecretaryOfStateSearchIntentsItemSubmittedState;
  };

export type EntityPatchResponseSetupSubmissionStructureCorporationAnyOfBeneficialOwnershipExemption =
  "regulated_financial_institution" | "publicly_traded_company" | null;

export type EntityPatchResponseReviewStatus =
  (typeof EntityPatchResponseReviewStatus)[keyof typeof EntityPatchResponseReviewStatus];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const EntityPatchResponseReviewStatus = {
  pending_reviewing: "pending_reviewing",
  review_not_required: "review_not_required",
  approved: "approved",
  approved_by_platform: "approved_by_platform",
  returned_to_user: "returned_to_user",
  requires_attention: "requires_attention",
} as const;

export type EntityPatchResponseCustomerInformationPolicyProblemsItem =
  (typeof EntityPatchResponseCustomerInformationPolicyProblemsItem)[keyof typeof EntityPatchResponseCustomerInformationPolicyProblemsItem];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const EntityPatchResponseCustomerInformationPolicyProblemsItem = {
  details_need_initial_confirmation: "details_need_initial_confirmation",
  details_need_reconfirmation: "details_need_reconfirmation",
  missing_controller: "missing_controller",
  tax_id_not_validated: "tax_id_not_validated",
  identity_not_verified: "identity_not_verified",
  full_tax_id_required_unless_non_us_citizen:
    "full_tax_id_required_unless_non_us_citizen",
  physical_address_not_valid: "physical_address_not_valid",
  missing_grantor: "missing_grantor",
} as const;

export type EntityPatchResponseAutomatedCustomerIdentificationProgramEvaluation =
  (typeof EntityPatchResponseAutomatedCustomerIdentificationProgramEvaluation)[keyof typeof EntityPatchResponseAutomatedCustomerIdentificationProgramEvaluation];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const EntityPatchResponseAutomatedCustomerIdentificationProgramEvaluation =
  {
    valid: "valid",
    indeterminate: "indeterminate",
    invalid: "invalid",
  } as const;

export type EntityPatchResponseApprovedBy = string | null;

export type EntityPatchResponseApprovedAt = string | null;

export type EntityPatchParametersOutcome =
  (typeof EntityPatchParametersOutcome)[keyof typeof EntityPatchParametersOutcome];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const EntityPatchParametersOutcome = {
  approved: "approved",
  returned_to_user: "returned_to_user",
} as const;

export interface EntityPatchParameters {
  outcome: EntityPatchParametersOutcome;
}

export type EntityGetResponseTaxId = string | null;

export type EntityGetResponseSupplementalDocumentsItemReviewStatus =
  (typeof EntityGetResponseSupplementalDocumentsItemReviewStatus)[keyof typeof EntityGetResponseSupplementalDocumentsItemReviewStatus];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const EntityGetResponseSupplementalDocumentsItemReviewStatus = {
  pending_reviewing: "pending_reviewing",
  skipped: "skipped",
  accepted: "accepted",
  accepted_manually_reviewed: "accepted_manually_reviewed",
  rejected: "rejected",
  requires_attention: "requires_attention",
} as const;

export type EntityGetResponseSupplementalDocumentsItemRejectReason =
  | "parse_failed"
  | "wrong_structure"
  | "data_mismatch"
  | "missing_datasource"
  | "evaluation_failed"
  | null;

export type EntityGetResponseSupplementalDocumentsItem = {
  created_at: string;
  file: EntityGetResponseSupplementalDocumentsItemFile;
  id: string;
  reject_reason: EntityGetResponseSupplementalDocumentsItemRejectReason;
  review_status: EntityGetResponseSupplementalDocumentsItemReviewStatus;
};

export type EntityGetResponseSupplementalDocumentsItemFilePurpose =
  (typeof EntityGetResponseSupplementalDocumentsItemFilePurpose)[keyof typeof EntityGetResponseSupplementalDocumentsItemFilePurpose];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const EntityGetResponseSupplementalDocumentsItemFilePurpose = {
  check_image_front: "check_image_front",
  check_image_back: "check_image_back",
  processed_check_image_front: "processed_check_image_front",
  processed_check_image_back: "processed_check_image_back",
  mailed_check_image: "mailed_check_image",
  check_attachment: "check_attachment",
  inbound_mail_item: "inbound_mail_item",
  form_1099_int: "form_1099_int",
  form_1099_misc: "form_1099_misc",
  form_ss_4: "form_ss_4",
  identity_document: "identity_document",
  increase_statement: "increase_statement",
  other: "other",
  trust_formation_document: "trust_formation_document",
  digital_wallet_artwork: "digital_wallet_artwork",
  digital_wallet_app_icon: "digital_wallet_app_icon",
  physical_card_front: "physical_card_front",
  physical_card_back: "physical_card_back",
  physical_card_carrier: "physical_card_carrier",
  document_request: "document_request",
  entity_supplemental_document: "entity_supplemental_document",
  export: "export",
  unusual_activity_report_attachment: "unusual_activity_report_attachment",
  deposit_account_control_agreement: "deposit_account_control_agreement",
  proof_of_authorization_request_submission:
    "proof_of_authorization_request_submission",
} as const;

export type EntityGetResponseSupplementalDocumentsItemFileDirection =
  (typeof EntityGetResponseSupplementalDocumentsItemFileDirection)[keyof typeof EntityGetResponseSupplementalDocumentsItemFileDirection];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const EntityGetResponseSupplementalDocumentsItemFileDirection = {
  to_increase: "to_increase",
  from_increase: "from_increase",
} as const;

export type EntityGetResponseSupplementalDocumentsItemFileDescription =
  | string
  | null;

export type EntityGetResponseSupplementalDocumentsItemFile = {
  created_at: string;
  description: EntityGetResponseSupplementalDocumentsItemFileDescription;
  direction: EntityGetResponseSupplementalDocumentsItemFileDirection;
  filename: string;
  id: string;
  mime: string;
  purpose: EntityGetResponseSupplementalDocumentsItemFilePurpose;
};

export type EntityGetResponseStructure =
  (typeof EntityGetResponseStructure)[keyof typeof EntityGetResponseStructure];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const EntityGetResponseStructure = {
  corporation: "corporation",
  natural_person: "natural_person",
  joint: "joint",
  trust: "trust",
  government_authority: "government_authority",
} as const;

export type EntityGetResponseStatus =
  (typeof EntityGetResponseStatus)[keyof typeof EntityGetResponseStatus];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const EntityGetResponseStatus = {
  active: "active",
  archived: "archived",
  disabled: "disabled",
} as const;

export interface EntityGetResponse {
  approved_at: EntityGetResponseApprovedAt;
  approved_by: EntityGetResponseApprovedBy;
  automated_customer_identification_program_evaluation: EntityGetResponseAutomatedCustomerIdentificationProgramEvaluation;
  created_at: string;
  customer_information_policy_problems: EntityGetResponseCustomerInformationPolicyProblemsItem[];
  directly_supervised: boolean;
  group_id: string;
  has_informational_accounts: boolean;
  has_ownership_accounts: boolean;
  id: string;
  name: string;
  review_status: EntityGetResponseReviewStatus;
  setup_submission_structure: EntityGetResponseSetupSubmissionStructure;
  status: EntityGetResponseStatus;
  structure: EntityGetResponseStructure;
  supplemental_documents: EntityGetResponseSupplementalDocumentsItem[];
  tax_id: EntityGetResponseTaxId;
}

export type EntityGetResponseSetupSubmissionStructureTrustAnyOf = {
  grantor: EntityGetResponseSetupSubmissionStructureTrustAnyOfGrantor;
  id: string;
  submitted_address: EntityGetResponseSetupSubmissionStructureTrustAnyOfSubmittedAddress;
  submitted_category: EntityGetResponseSetupSubmissionStructureTrustAnyOfSubmittedCategory;
  submitted_name: string;
  submitted_tax_id: EntityGetResponseSetupSubmissionStructureTrustAnyOfSubmittedTaxId;
  trustees: EntityGetResponseSetupSubmissionStructureTrustAnyOfTrusteesItem[];
};

export type EntityGetResponseSetupSubmissionStructureTrust =
  EntityGetResponseSetupSubmissionStructureTrustAnyOf | null;

export type EntityGetResponseSetupSubmissionStructure = {
  corporation: EntityGetResponseSetupSubmissionStructureCorporation;
  government_authority: EntityGetResponseSetupSubmissionStructureGovernmentAuthority;
  joint: EntityGetResponseSetupSubmissionStructureJoint;
  natural_person: EntityGetResponseSetupSubmissionStructureNaturalPerson;
  trust: EntityGetResponseSetupSubmissionStructureTrust;
};

export type EntityGetResponseSetupSubmissionStructureTrustAnyOfTrusteesItemTaxIdValidationStatus =
  (typeof EntityGetResponseSetupSubmissionStructureTrustAnyOfTrusteesItemTaxIdValidationStatus)[keyof typeof EntityGetResponseSetupSubmissionStructureTrustAnyOfTrusteesItemTaxIdValidationStatus];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const EntityGetResponseSetupSubmissionStructureTrustAnyOfTrusteesItemTaxIdValidationStatus =
  {
    pending_validating: "pending_validating",
    update_pending_validating: "update_pending_validating",
    requires_attention: "requires_attention",
    failed: "failed",
    validated: "validated",
    not_provided: "not_provided",
    skipped: "skipped",
  } as const;

export type EntityGetResponseSetupSubmissionStructureTrustAnyOfTrusteesItemSubmittedIdentificationMethod =
  (typeof EntityGetResponseSetupSubmissionStructureTrustAnyOfTrusteesItemSubmittedIdentificationMethod)[keyof typeof EntityGetResponseSetupSubmissionStructureTrustAnyOfTrusteesItemSubmittedIdentificationMethod];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const EntityGetResponseSetupSubmissionStructureTrustAnyOfTrusteesItemSubmittedIdentificationMethod =
  {
    social_security_number: "social_security_number",
    individual_taxpayer_identification_number:
      "individual_taxpayer_identification_number",
    passport: "passport",
    drivers_license: "drivers_license",
    other: "other",
  } as const;

export type EntityGetResponseSetupSubmissionStructureTrustAnyOfTrusteesItemSubmittedAddress =
  {
    city: string;
    line1: string;
    line2: EntityGetResponseSetupSubmissionStructureTrustAnyOfTrusteesItemSubmittedAddressLine2;
    state: string;
    zip: string;
  };

export type EntityGetResponseSetupSubmissionStructureTrustAnyOfTrusteesItem = {
  created_at: string;
  id: string;
  identity_document_submissions: EntityGetResponseSetupSubmissionStructureTrustAnyOfTrusteesItemIdentityDocumentSubmissionsItem[];
  identity_submitted_at: string;
  identity_verification_status: EntityGetResponseSetupSubmissionStructureTrustAnyOfTrusteesItemIdentityVerificationStatus;
  microbilt: EntityGetResponseSetupSubmissionStructureTrustAnyOfTrusteesItemMicrobiltItem[];
  submission_sovos_response_id: EntityGetResponseSetupSubmissionStructureTrustAnyOfTrusteesItemSubmissionSovosResponseId;
  submitted_address: EntityGetResponseSetupSubmissionStructureTrustAnyOfTrusteesItemSubmittedAddress;
  submitted_date_of_birth: string;
  submitted_identification_method: EntityGetResponseSetupSubmissionStructureTrustAnyOfTrusteesItemSubmittedIdentificationMethod;
  submitted_identification_number_last4: string;
  submitted_name: string;
  tax_id_validation_status: EntityGetResponseSetupSubmissionStructureTrustAnyOfTrusteesItemTaxIdValidationStatus;
};

export type EntityGetResponseSetupSubmissionStructureTrustAnyOfTrusteesItemSubmittedAddressLine2 =
  string | null;

export type EntityGetResponseSetupSubmissionStructureTrustAnyOfTrusteesItemSubmissionSovosResponseId =
  string | null;

export type EntityGetResponseSetupSubmissionStructureTrustAnyOfTrusteesItemMicrobiltItemTaxIdType =
  "SSN" | null;

export type EntityGetResponseSetupSubmissionStructureTrustAnyOfTrusteesItemMicrobiltItemTaxIdLast4 =
  string | null;

export type EntityGetResponseSetupSubmissionStructureTrustAnyOfTrusteesItemMicrobiltItemReturnCode =
  (typeof EntityGetResponseSetupSubmissionStructureTrustAnyOfTrusteesItemMicrobiltItemReturnCode)[keyof typeof EntityGetResponseSetupSubmissionStructureTrustAnyOfTrusteesItemMicrobiltItemReturnCode];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const EntityGetResponseSetupSubmissionStructureTrustAnyOfTrusteesItemMicrobiltItemReturnCode =
  {
    OK: "OK",
  } as const;

export type EntityGetResponseSetupSubmissionStructureTrustAnyOfTrusteesItemMicrobiltItemMiddleName =
  string | null;

export type EntityGetResponseSetupSubmissionStructureTrustAnyOfTrusteesItemMicrobiltItemDeathDate =
  string | null;

export type EntityGetResponseSetupSubmissionStructureTrustAnyOfTrusteesItemMicrobiltItemBirthDate =
  string | null;

export type EntityGetResponseSetupSubmissionStructureTrustAnyOfTrusteesItemMicrobiltItem =
  {
    birth_date: EntityGetResponseSetupSubmissionStructureTrustAnyOfTrusteesItemMicrobiltItemBirthDate;
    created_at: string;
    death_date: EntityGetResponseSetupSubmissionStructureTrustAnyOfTrusteesItemMicrobiltItemDeathDate;
    first_name: string;
    id: string;
    last_name: string;
    middle_name: EntityGetResponseSetupSubmissionStructureTrustAnyOfTrusteesItemMicrobiltItemMiddleName;
    request_id: string;
    response: string;
    return_code: EntityGetResponseSetupSubmissionStructureTrustAnyOfTrusteesItemMicrobiltItemReturnCode;
    tax_id_last_4: EntityGetResponseSetupSubmissionStructureTrustAnyOfTrusteesItemMicrobiltItemTaxIdLast4;
    tax_id_type: EntityGetResponseSetupSubmissionStructureTrustAnyOfTrusteesItemMicrobiltItemTaxIdType;
  };

export type EntityGetResponseSetupSubmissionStructureTrustAnyOfTrusteesItemIdentityVerificationStatus =
  (typeof EntityGetResponseSetupSubmissionStructureTrustAnyOfTrusteesItemIdentityVerificationStatus)[keyof typeof EntityGetResponseSetupSubmissionStructureTrustAnyOfTrusteesItemIdentityVerificationStatus];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const EntityGetResponseSetupSubmissionStructureTrustAnyOfTrusteesItemIdentityVerificationStatus =
  {
    pending_verification: "pending_verification",
    update_pending_verification: "update_pending_verification",
    verified: "verified",
    falsified: "falsified",
    no_match: "no_match",
    verifying: "verifying",
    requires_attention: "requires_attention",
    not_performed: "not_performed",
    skipped: "skipped",
  } as const;

export type EntityGetResponseSetupSubmissionStructureTrustAnyOfTrusteesItemIdentityDocumentSubmissionsItemSubmittedState =
  string | null;

export type EntityGetResponseSetupSubmissionStructureTrustAnyOfTrusteesItemIdentityDocumentSubmissionsItemSubmittedExpirationDate =
  string | null;

export type EntityGetResponseSetupSubmissionStructureTrustAnyOfTrusteesItemIdentityDocumentSubmissionsItemSubmittedDocumentType =
  (typeof EntityGetResponseSetupSubmissionStructureTrustAnyOfTrusteesItemIdentityDocumentSubmissionsItemSubmittedDocumentType)[keyof typeof EntityGetResponseSetupSubmissionStructureTrustAnyOfTrusteesItemIdentityDocumentSubmissionsItemSubmittedDocumentType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const EntityGetResponseSetupSubmissionStructureTrustAnyOfTrusteesItemIdentityDocumentSubmissionsItemSubmittedDocumentType =
  {
    passport: "passport",
    us_drivers_license: "us_drivers_license",
    other: "other",
  } as const;

export type EntityGetResponseSetupSubmissionStructureTrustAnyOfTrusteesItemIdentityDocumentSubmissionsItemSubmittedDocumentNumber =
  string | null;

export type EntityGetResponseSetupSubmissionStructureTrustAnyOfTrusteesItemIdentityDocumentSubmissionsItem =
  {
    api_back_file_id: EntityGetResponseSetupSubmissionStructureTrustAnyOfTrusteesItemIdentityDocumentSubmissionsItemApiBackFileId;
    api_file_id: string;
    created_at: string;
    group_id: string;
    id: string;
    identity_verification_status: EntityGetResponseSetupSubmissionStructureTrustAnyOfTrusteesItemIdentityDocumentSubmissionsItemIdentityVerificationStatus;
    submitted_address: EntityGetResponseSetupSubmissionStructureTrustAnyOfTrusteesItemIdentityDocumentSubmissionsItemSubmittedAddress;
    submitted_country: string;
    submitted_date_of_birth: string;
    submitted_document_number: EntityGetResponseSetupSubmissionStructureTrustAnyOfTrusteesItemIdentityDocumentSubmissionsItemSubmittedDocumentNumber;
    submitted_document_type: EntityGetResponseSetupSubmissionStructureTrustAnyOfTrusteesItemIdentityDocumentSubmissionsItemSubmittedDocumentType;
    submitted_expiration_date: EntityGetResponseSetupSubmissionStructureTrustAnyOfTrusteesItemIdentityDocumentSubmissionsItemSubmittedExpirationDate;
    submitted_name: string;
    submitted_state: EntityGetResponseSetupSubmissionStructureTrustAnyOfTrusteesItemIdentityDocumentSubmissionsItemSubmittedState;
  };

export type EntityGetResponseSetupSubmissionStructureTrustAnyOfTrusteesItemIdentityDocumentSubmissionsItemSubmittedAddressLine2 =
  string | null;

export type EntityGetResponseSetupSubmissionStructureTrustAnyOfTrusteesItemIdentityDocumentSubmissionsItemSubmittedAddress =
  {
    city: string;
    line1: string;
    line2: EntityGetResponseSetupSubmissionStructureTrustAnyOfTrusteesItemIdentityDocumentSubmissionsItemSubmittedAddressLine2;
    state: string;
    zip: string;
  };

export type EntityGetResponseSetupSubmissionStructureTrustAnyOfTrusteesItemIdentityDocumentSubmissionsItemIdentityVerificationStatus =
  (typeof EntityGetResponseSetupSubmissionStructureTrustAnyOfTrusteesItemIdentityDocumentSubmissionsItemIdentityVerificationStatus)[keyof typeof EntityGetResponseSetupSubmissionStructureTrustAnyOfTrusteesItemIdentityDocumentSubmissionsItemIdentityVerificationStatus];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const EntityGetResponseSetupSubmissionStructureTrustAnyOfTrusteesItemIdentityDocumentSubmissionsItemIdentityVerificationStatus =
  {
    pending_verification: "pending_verification",
    update_pending_verification: "update_pending_verification",
    verified: "verified",
    falsified: "falsified",
    no_match: "no_match",
    verifying: "verifying",
    requires_attention: "requires_attention",
    not_performed: "not_performed",
    skipped: "skipped",
  } as const;

export type EntityGetResponseSetupSubmissionStructureTrustAnyOfTrusteesItemIdentityDocumentSubmissionsItemApiBackFileId =
  string | null;

export type EntityGetResponseSetupSubmissionStructureTrustAnyOfSubmittedTaxId =
  | string
  | null;

export type EntityGetResponseSetupSubmissionStructureTrustAnyOfSubmittedCategory =
  (typeof EntityGetResponseSetupSubmissionStructureTrustAnyOfSubmittedCategory)[keyof typeof EntityGetResponseSetupSubmissionStructureTrustAnyOfSubmittedCategory];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const EntityGetResponseSetupSubmissionStructureTrustAnyOfSubmittedCategory =
  {
    revocable: "revocable",
    irrevocable: "irrevocable",
  } as const;

export type EntityGetResponseSetupSubmissionStructureTrustAnyOfSubmittedAddressLine2 =
  string | null;

export type EntityGetResponseSetupSubmissionStructureTrustAnyOfSubmittedAddress =
  {
    city: string;
    line1: string;
    line2: EntityGetResponseSetupSubmissionStructureTrustAnyOfSubmittedAddressLine2;
    state: string;
    zip: string;
  };

export type EntityGetResponseSetupSubmissionStructureTrustAnyOfGrantor =
  EntityGetResponseSetupSubmissionStructureTrustAnyOfGrantorAnyOf | null;

export type EntityGetResponseSetupSubmissionStructureTrustAnyOfGrantorAnyOfTaxIdValidationStatus =
  (typeof EntityGetResponseSetupSubmissionStructureTrustAnyOfGrantorAnyOfTaxIdValidationStatus)[keyof typeof EntityGetResponseSetupSubmissionStructureTrustAnyOfGrantorAnyOfTaxIdValidationStatus];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const EntityGetResponseSetupSubmissionStructureTrustAnyOfGrantorAnyOfTaxIdValidationStatus =
  {
    pending_validating: "pending_validating",
    update_pending_validating: "update_pending_validating",
    requires_attention: "requires_attention",
    failed: "failed",
    validated: "validated",
    not_provided: "not_provided",
    skipped: "skipped",
  } as const;

export type EntityGetResponseSetupSubmissionStructureTrustAnyOfGrantorAnyOfSubmittedIdentificationMethod =
  (typeof EntityGetResponseSetupSubmissionStructureTrustAnyOfGrantorAnyOfSubmittedIdentificationMethod)[keyof typeof EntityGetResponseSetupSubmissionStructureTrustAnyOfGrantorAnyOfSubmittedIdentificationMethod];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const EntityGetResponseSetupSubmissionStructureTrustAnyOfGrantorAnyOfSubmittedIdentificationMethod =
  {
    social_security_number: "social_security_number",
    individual_taxpayer_identification_number:
      "individual_taxpayer_identification_number",
    passport: "passport",
    drivers_license: "drivers_license",
    other: "other",
  } as const;

export type EntityGetResponseSetupSubmissionStructureTrustAnyOfGrantorAnyOfSubmittedAddressLine2 =
  string | null;

export type EntityGetResponseSetupSubmissionStructureTrustAnyOfGrantorAnyOfSubmittedAddress =
  {
    city: string;
    line1: string;
    line2: EntityGetResponseSetupSubmissionStructureTrustAnyOfGrantorAnyOfSubmittedAddressLine2;
    state: string;
    zip: string;
  };

export type EntityGetResponseSetupSubmissionStructureTrustAnyOfGrantorAnyOfSubmissionSovosResponseId =
  string | null;

export type EntityGetResponseSetupSubmissionStructureTrustAnyOfGrantorAnyOfMicrobiltItemTaxIdType =
  "SSN" | null;

export type EntityGetResponseSetupSubmissionStructureTrustAnyOfGrantorAnyOfMicrobiltItemTaxIdLast4 =
  string | null;

export type EntityGetResponseSetupSubmissionStructureTrustAnyOfGrantorAnyOfMicrobiltItemReturnCode =
  (typeof EntityGetResponseSetupSubmissionStructureTrustAnyOfGrantorAnyOfMicrobiltItemReturnCode)[keyof typeof EntityGetResponseSetupSubmissionStructureTrustAnyOfGrantorAnyOfMicrobiltItemReturnCode];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const EntityGetResponseSetupSubmissionStructureTrustAnyOfGrantorAnyOfMicrobiltItemReturnCode =
  {
    OK: "OK",
  } as const;

export type EntityGetResponseSetupSubmissionStructureTrustAnyOfGrantorAnyOfMicrobiltItemMiddleName =
  string | null;

export type EntityGetResponseSetupSubmissionStructureTrustAnyOfGrantorAnyOfMicrobiltItemDeathDate =
  string | null;

export type EntityGetResponseSetupSubmissionStructureTrustAnyOfGrantorAnyOfMicrobiltItemBirthDate =
  string | null;

export type EntityGetResponseSetupSubmissionStructureTrustAnyOfGrantorAnyOfMicrobiltItem =
  {
    birth_date: EntityGetResponseSetupSubmissionStructureTrustAnyOfGrantorAnyOfMicrobiltItemBirthDate;
    created_at: string;
    death_date: EntityGetResponseSetupSubmissionStructureTrustAnyOfGrantorAnyOfMicrobiltItemDeathDate;
    first_name: string;
    id: string;
    last_name: string;
    middle_name: EntityGetResponseSetupSubmissionStructureTrustAnyOfGrantorAnyOfMicrobiltItemMiddleName;
    request_id: string;
    response: string;
    return_code: EntityGetResponseSetupSubmissionStructureTrustAnyOfGrantorAnyOfMicrobiltItemReturnCode;
    tax_id_last_4: EntityGetResponseSetupSubmissionStructureTrustAnyOfGrantorAnyOfMicrobiltItemTaxIdLast4;
    tax_id_type: EntityGetResponseSetupSubmissionStructureTrustAnyOfGrantorAnyOfMicrobiltItemTaxIdType;
  };

export type EntityGetResponseSetupSubmissionStructureTrustAnyOfGrantorAnyOfIdentityVerificationStatus =
  (typeof EntityGetResponseSetupSubmissionStructureTrustAnyOfGrantorAnyOfIdentityVerificationStatus)[keyof typeof EntityGetResponseSetupSubmissionStructureTrustAnyOfGrantorAnyOfIdentityVerificationStatus];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const EntityGetResponseSetupSubmissionStructureTrustAnyOfGrantorAnyOfIdentityVerificationStatus =
  {
    pending_verification: "pending_verification",
    update_pending_verification: "update_pending_verification",
    verified: "verified",
    falsified: "falsified",
    no_match: "no_match",
    verifying: "verifying",
    requires_attention: "requires_attention",
    not_performed: "not_performed",
    skipped: "skipped",
  } as const;

export type EntityGetResponseSetupSubmissionStructureTrustAnyOfGrantorAnyOfIdentityDocumentSubmissionsItemSubmittedState =
  string | null;

export type EntityGetResponseSetupSubmissionStructureTrustAnyOfGrantorAnyOfIdentityDocumentSubmissionsItemSubmittedExpirationDate =
  string | null;

export type EntityGetResponseSetupSubmissionStructureTrustAnyOfGrantorAnyOfIdentityDocumentSubmissionsItemSubmittedDocumentType =
  (typeof EntityGetResponseSetupSubmissionStructureTrustAnyOfGrantorAnyOfIdentityDocumentSubmissionsItemSubmittedDocumentType)[keyof typeof EntityGetResponseSetupSubmissionStructureTrustAnyOfGrantorAnyOfIdentityDocumentSubmissionsItemSubmittedDocumentType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const EntityGetResponseSetupSubmissionStructureTrustAnyOfGrantorAnyOfIdentityDocumentSubmissionsItemSubmittedDocumentType =
  {
    passport: "passport",
    us_drivers_license: "us_drivers_license",
    other: "other",
  } as const;

export type EntityGetResponseSetupSubmissionStructureTrustAnyOfGrantorAnyOfIdentityDocumentSubmissionsItemSubmittedDocumentNumber =
  string | null;

export type EntityGetResponseSetupSubmissionStructureTrustAnyOfGrantorAnyOfIdentityDocumentSubmissionsItem =
  {
    api_back_file_id: EntityGetResponseSetupSubmissionStructureTrustAnyOfGrantorAnyOfIdentityDocumentSubmissionsItemApiBackFileId;
    api_file_id: string;
    created_at: string;
    group_id: string;
    id: string;
    identity_verification_status: EntityGetResponseSetupSubmissionStructureTrustAnyOfGrantorAnyOfIdentityDocumentSubmissionsItemIdentityVerificationStatus;
    submitted_address: EntityGetResponseSetupSubmissionStructureTrustAnyOfGrantorAnyOfIdentityDocumentSubmissionsItemSubmittedAddress;
    submitted_country: string;
    submitted_date_of_birth: string;
    submitted_document_number: EntityGetResponseSetupSubmissionStructureTrustAnyOfGrantorAnyOfIdentityDocumentSubmissionsItemSubmittedDocumentNumber;
    submitted_document_type: EntityGetResponseSetupSubmissionStructureTrustAnyOfGrantorAnyOfIdentityDocumentSubmissionsItemSubmittedDocumentType;
    submitted_expiration_date: EntityGetResponseSetupSubmissionStructureTrustAnyOfGrantorAnyOfIdentityDocumentSubmissionsItemSubmittedExpirationDate;
    submitted_name: string;
    submitted_state: EntityGetResponseSetupSubmissionStructureTrustAnyOfGrantorAnyOfIdentityDocumentSubmissionsItemSubmittedState;
  };

export type EntityGetResponseSetupSubmissionStructureTrustAnyOfGrantorAnyOf = {
  created_at: string;
  id: string;
  identity_document_submissions: EntityGetResponseSetupSubmissionStructureTrustAnyOfGrantorAnyOfIdentityDocumentSubmissionsItem[];
  identity_submitted_at: string;
  identity_verification_status: EntityGetResponseSetupSubmissionStructureTrustAnyOfGrantorAnyOfIdentityVerificationStatus;
  microbilt: EntityGetResponseSetupSubmissionStructureTrustAnyOfGrantorAnyOfMicrobiltItem[];
  submission_sovos_response_id: EntityGetResponseSetupSubmissionStructureTrustAnyOfGrantorAnyOfSubmissionSovosResponseId;
  submitted_address: EntityGetResponseSetupSubmissionStructureTrustAnyOfGrantorAnyOfSubmittedAddress;
  submitted_date_of_birth: string;
  submitted_identification_method: EntityGetResponseSetupSubmissionStructureTrustAnyOfGrantorAnyOfSubmittedIdentificationMethod;
  submitted_identification_number_last4: string;
  submitted_name: string;
  tax_id_validation_status: EntityGetResponseSetupSubmissionStructureTrustAnyOfGrantorAnyOfTaxIdValidationStatus;
};

export type EntityGetResponseSetupSubmissionStructureTrustAnyOfGrantorAnyOfIdentityDocumentSubmissionsItemSubmittedAddressLine2 =
  string | null;

export type EntityGetResponseSetupSubmissionStructureTrustAnyOfGrantorAnyOfIdentityDocumentSubmissionsItemSubmittedAddress =
  {
    city: string;
    line1: string;
    line2: EntityGetResponseSetupSubmissionStructureTrustAnyOfGrantorAnyOfIdentityDocumentSubmissionsItemSubmittedAddressLine2;
    state: string;
    zip: string;
  };

export type EntityGetResponseSetupSubmissionStructureTrustAnyOfGrantorAnyOfIdentityDocumentSubmissionsItemIdentityVerificationStatus =
  (typeof EntityGetResponseSetupSubmissionStructureTrustAnyOfGrantorAnyOfIdentityDocumentSubmissionsItemIdentityVerificationStatus)[keyof typeof EntityGetResponseSetupSubmissionStructureTrustAnyOfGrantorAnyOfIdentityDocumentSubmissionsItemIdentityVerificationStatus];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const EntityGetResponseSetupSubmissionStructureTrustAnyOfGrantorAnyOfIdentityDocumentSubmissionsItemIdentityVerificationStatus =
  {
    pending_verification: "pending_verification",
    update_pending_verification: "update_pending_verification",
    verified: "verified",
    falsified: "falsified",
    no_match: "no_match",
    verifying: "verifying",
    requires_attention: "requires_attention",
    not_performed: "not_performed",
    skipped: "skipped",
  } as const;

export type EntityGetResponseSetupSubmissionStructureTrustAnyOfGrantorAnyOfIdentityDocumentSubmissionsItemApiBackFileId =
  string | null;

export type EntityGetResponseSetupSubmissionStructureNaturalPerson =
  EntityGetResponseSetupSubmissionStructureNaturalPersonAnyOf | null;

export type EntityGetResponseSetupSubmissionStructureNaturalPersonAnyOfTaxIdValidationStatus =
  (typeof EntityGetResponseSetupSubmissionStructureNaturalPersonAnyOfTaxIdValidationStatus)[keyof typeof EntityGetResponseSetupSubmissionStructureNaturalPersonAnyOfTaxIdValidationStatus];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const EntityGetResponseSetupSubmissionStructureNaturalPersonAnyOfTaxIdValidationStatus =
  {
    pending_validating: "pending_validating",
    update_pending_validating: "update_pending_validating",
    requires_attention: "requires_attention",
    failed: "failed",
    validated: "validated",
    not_provided: "not_provided",
    skipped: "skipped",
  } as const;

export type EntityGetResponseSetupSubmissionStructureNaturalPersonAnyOfSubmittedIdentificationMethod =
  (typeof EntityGetResponseSetupSubmissionStructureNaturalPersonAnyOfSubmittedIdentificationMethod)[keyof typeof EntityGetResponseSetupSubmissionStructureNaturalPersonAnyOfSubmittedIdentificationMethod];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const EntityGetResponseSetupSubmissionStructureNaturalPersonAnyOfSubmittedIdentificationMethod =
  {
    social_security_number: "social_security_number",
    individual_taxpayer_identification_number:
      "individual_taxpayer_identification_number",
    passport: "passport",
    drivers_license: "drivers_license",
    other: "other",
  } as const;

export type EntityGetResponseSetupSubmissionStructureNaturalPersonAnyOfSubmittedAddressLine2 =
  string | null;

export type EntityGetResponseSetupSubmissionStructureNaturalPersonAnyOfSubmittedAddress =
  {
    city: string;
    line1: string;
    line2: EntityGetResponseSetupSubmissionStructureNaturalPersonAnyOfSubmittedAddressLine2;
    state: string;
    zip: string;
  };

export type EntityGetResponseSetupSubmissionStructureNaturalPersonAnyOfSubmissionSovosResponseId =
  string | null;

export type EntityGetResponseSetupSubmissionStructureNaturalPersonAnyOfMicrobiltItemTaxIdType =
  "SSN" | null;

export type EntityGetResponseSetupSubmissionStructureNaturalPersonAnyOfMicrobiltItemTaxIdLast4 =
  string | null;

export type EntityGetResponseSetupSubmissionStructureNaturalPersonAnyOfMicrobiltItemReturnCode =
  (typeof EntityGetResponseSetupSubmissionStructureNaturalPersonAnyOfMicrobiltItemReturnCode)[keyof typeof EntityGetResponseSetupSubmissionStructureNaturalPersonAnyOfMicrobiltItemReturnCode];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const EntityGetResponseSetupSubmissionStructureNaturalPersonAnyOfMicrobiltItemReturnCode =
  {
    OK: "OK",
  } as const;

export type EntityGetResponseSetupSubmissionStructureNaturalPersonAnyOfMicrobiltItemMiddleName =
  string | null;

export type EntityGetResponseSetupSubmissionStructureNaturalPersonAnyOfMicrobiltItemDeathDate =
  string | null;

export type EntityGetResponseSetupSubmissionStructureNaturalPersonAnyOfMicrobiltItemBirthDate =
  string | null;

export type EntityGetResponseSetupSubmissionStructureNaturalPersonAnyOfMicrobiltItem =
  {
    birth_date: EntityGetResponseSetupSubmissionStructureNaturalPersonAnyOfMicrobiltItemBirthDate;
    created_at: string;
    death_date: EntityGetResponseSetupSubmissionStructureNaturalPersonAnyOfMicrobiltItemDeathDate;
    first_name: string;
    id: string;
    last_name: string;
    middle_name: EntityGetResponseSetupSubmissionStructureNaturalPersonAnyOfMicrobiltItemMiddleName;
    request_id: string;
    response: string;
    return_code: EntityGetResponseSetupSubmissionStructureNaturalPersonAnyOfMicrobiltItemReturnCode;
    tax_id_last_4: EntityGetResponseSetupSubmissionStructureNaturalPersonAnyOfMicrobiltItemTaxIdLast4;
    tax_id_type: EntityGetResponseSetupSubmissionStructureNaturalPersonAnyOfMicrobiltItemTaxIdType;
  };

export type EntityGetResponseSetupSubmissionStructureNaturalPersonAnyOfIdentityVerificationStatus =
  (typeof EntityGetResponseSetupSubmissionStructureNaturalPersonAnyOfIdentityVerificationStatus)[keyof typeof EntityGetResponseSetupSubmissionStructureNaturalPersonAnyOfIdentityVerificationStatus];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const EntityGetResponseSetupSubmissionStructureNaturalPersonAnyOfIdentityVerificationStatus =
  {
    pending_verification: "pending_verification",
    update_pending_verification: "update_pending_verification",
    verified: "verified",
    falsified: "falsified",
    no_match: "no_match",
    verifying: "verifying",
    requires_attention: "requires_attention",
    not_performed: "not_performed",
    skipped: "skipped",
  } as const;

export type EntityGetResponseSetupSubmissionStructureNaturalPersonAnyOfIdentityDocumentSubmissionsItemSubmittedState =
  string | null;

export type EntityGetResponseSetupSubmissionStructureNaturalPersonAnyOfIdentityDocumentSubmissionsItemSubmittedExpirationDate =
  string | null;

export type EntityGetResponseSetupSubmissionStructureNaturalPersonAnyOfIdentityDocumentSubmissionsItemSubmittedDocumentType =
  (typeof EntityGetResponseSetupSubmissionStructureNaturalPersonAnyOfIdentityDocumentSubmissionsItemSubmittedDocumentType)[keyof typeof EntityGetResponseSetupSubmissionStructureNaturalPersonAnyOfIdentityDocumentSubmissionsItemSubmittedDocumentType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const EntityGetResponseSetupSubmissionStructureNaturalPersonAnyOfIdentityDocumentSubmissionsItemSubmittedDocumentType =
  {
    passport: "passport",
    us_drivers_license: "us_drivers_license",
    other: "other",
  } as const;

export type EntityGetResponseSetupSubmissionStructureNaturalPersonAnyOfIdentityDocumentSubmissionsItemSubmittedDocumentNumber =
  string | null;

export type EntityGetResponseSetupSubmissionStructureNaturalPersonAnyOfIdentityDocumentSubmissionsItem =
  {
    api_back_file_id: EntityGetResponseSetupSubmissionStructureNaturalPersonAnyOfIdentityDocumentSubmissionsItemApiBackFileId;
    api_file_id: string;
    created_at: string;
    group_id: string;
    id: string;
    identity_verification_status: EntityGetResponseSetupSubmissionStructureNaturalPersonAnyOfIdentityDocumentSubmissionsItemIdentityVerificationStatus;
    submitted_address: EntityGetResponseSetupSubmissionStructureNaturalPersonAnyOfIdentityDocumentSubmissionsItemSubmittedAddress;
    submitted_country: string;
    submitted_date_of_birth: string;
    submitted_document_number: EntityGetResponseSetupSubmissionStructureNaturalPersonAnyOfIdentityDocumentSubmissionsItemSubmittedDocumentNumber;
    submitted_document_type: EntityGetResponseSetupSubmissionStructureNaturalPersonAnyOfIdentityDocumentSubmissionsItemSubmittedDocumentType;
    submitted_expiration_date: EntityGetResponseSetupSubmissionStructureNaturalPersonAnyOfIdentityDocumentSubmissionsItemSubmittedExpirationDate;
    submitted_name: string;
    submitted_state: EntityGetResponseSetupSubmissionStructureNaturalPersonAnyOfIdentityDocumentSubmissionsItemSubmittedState;
  };

export type EntityGetResponseSetupSubmissionStructureNaturalPersonAnyOf = {
  created_at: string;
  id: string;
  identity_document_submissions: EntityGetResponseSetupSubmissionStructureNaturalPersonAnyOfIdentityDocumentSubmissionsItem[];
  identity_submitted_at: string;
  identity_verification_status: EntityGetResponseSetupSubmissionStructureNaturalPersonAnyOfIdentityVerificationStatus;
  microbilt: EntityGetResponseSetupSubmissionStructureNaturalPersonAnyOfMicrobiltItem[];
  submission_sovos_response_id: EntityGetResponseSetupSubmissionStructureNaturalPersonAnyOfSubmissionSovosResponseId;
  submitted_address: EntityGetResponseSetupSubmissionStructureNaturalPersonAnyOfSubmittedAddress;
  submitted_date_of_birth: string;
  submitted_identification_method: EntityGetResponseSetupSubmissionStructureNaturalPersonAnyOfSubmittedIdentificationMethod;
  submitted_identification_number_last4: string;
  submitted_name: string;
  tax_id_validation_status: EntityGetResponseSetupSubmissionStructureNaturalPersonAnyOfTaxIdValidationStatus;
};

export type EntityGetResponseSetupSubmissionStructureNaturalPersonAnyOfIdentityDocumentSubmissionsItemSubmittedAddressLine2 =
  string | null;

export type EntityGetResponseSetupSubmissionStructureNaturalPersonAnyOfIdentityDocumentSubmissionsItemSubmittedAddress =
  {
    city: string;
    line1: string;
    line2: EntityGetResponseSetupSubmissionStructureNaturalPersonAnyOfIdentityDocumentSubmissionsItemSubmittedAddressLine2;
    state: string;
    zip: string;
  };

export type EntityGetResponseSetupSubmissionStructureNaturalPersonAnyOfIdentityDocumentSubmissionsItemIdentityVerificationStatus =
  (typeof EntityGetResponseSetupSubmissionStructureNaturalPersonAnyOfIdentityDocumentSubmissionsItemIdentityVerificationStatus)[keyof typeof EntityGetResponseSetupSubmissionStructureNaturalPersonAnyOfIdentityDocumentSubmissionsItemIdentityVerificationStatus];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const EntityGetResponseSetupSubmissionStructureNaturalPersonAnyOfIdentityDocumentSubmissionsItemIdentityVerificationStatus =
  {
    pending_verification: "pending_verification",
    update_pending_verification: "update_pending_verification",
    verified: "verified",
    falsified: "falsified",
    no_match: "no_match",
    verifying: "verifying",
    requires_attention: "requires_attention",
    not_performed: "not_performed",
    skipped: "skipped",
  } as const;

export type EntityGetResponseSetupSubmissionStructureNaturalPersonAnyOfIdentityDocumentSubmissionsItemApiBackFileId =
  string | null;

export type EntityGetResponseSetupSubmissionStructureJoint =
  | EntityGetResponseSetupSubmissionStructureJointAnyOfItem[]
  | null;

export type EntityGetResponseSetupSubmissionStructureJointAnyOfItemTaxIdValidationStatus =
  (typeof EntityGetResponseSetupSubmissionStructureJointAnyOfItemTaxIdValidationStatus)[keyof typeof EntityGetResponseSetupSubmissionStructureJointAnyOfItemTaxIdValidationStatus];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const EntityGetResponseSetupSubmissionStructureJointAnyOfItemTaxIdValidationStatus =
  {
    pending_validating: "pending_validating",
    update_pending_validating: "update_pending_validating",
    requires_attention: "requires_attention",
    failed: "failed",
    validated: "validated",
    not_provided: "not_provided",
    skipped: "skipped",
  } as const;

export type EntityGetResponseSetupSubmissionStructureJointAnyOfItemSubmittedIdentificationMethod =
  (typeof EntityGetResponseSetupSubmissionStructureJointAnyOfItemSubmittedIdentificationMethod)[keyof typeof EntityGetResponseSetupSubmissionStructureJointAnyOfItemSubmittedIdentificationMethod];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const EntityGetResponseSetupSubmissionStructureJointAnyOfItemSubmittedIdentificationMethod =
  {
    social_security_number: "social_security_number",
    individual_taxpayer_identification_number:
      "individual_taxpayer_identification_number",
    passport: "passport",
    drivers_license: "drivers_license",
    other: "other",
  } as const;

export type EntityGetResponseSetupSubmissionStructureJointAnyOfItemSubmittedAddressLine2 =
  string | null;

export type EntityGetResponseSetupSubmissionStructureJointAnyOfItemSubmittedAddress =
  {
    city: string;
    line1: string;
    line2: EntityGetResponseSetupSubmissionStructureJointAnyOfItemSubmittedAddressLine2;
    state: string;
    zip: string;
  };

export type EntityGetResponseSetupSubmissionStructureJointAnyOfItemSubmissionSovosResponseId =
  string | null;

export type EntityGetResponseSetupSubmissionStructureJointAnyOfItemMicrobiltItemTaxIdType =
  "SSN" | null;

export type EntityGetResponseSetupSubmissionStructureJointAnyOfItemMicrobiltItemTaxIdLast4 =
  string | null;

export type EntityGetResponseSetupSubmissionStructureJointAnyOfItemMicrobiltItemReturnCode =
  (typeof EntityGetResponseSetupSubmissionStructureJointAnyOfItemMicrobiltItemReturnCode)[keyof typeof EntityGetResponseSetupSubmissionStructureJointAnyOfItemMicrobiltItemReturnCode];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const EntityGetResponseSetupSubmissionStructureJointAnyOfItemMicrobiltItemReturnCode =
  {
    OK: "OK",
  } as const;

export type EntityGetResponseSetupSubmissionStructureJointAnyOfItemMicrobiltItemMiddleName =
  string | null;

export type EntityGetResponseSetupSubmissionStructureJointAnyOfItemMicrobiltItemDeathDate =
  string | null;

export type EntityGetResponseSetupSubmissionStructureJointAnyOfItemMicrobiltItemBirthDate =
  string | null;

export type EntityGetResponseSetupSubmissionStructureJointAnyOfItemMicrobiltItem =
  {
    birth_date: EntityGetResponseSetupSubmissionStructureJointAnyOfItemMicrobiltItemBirthDate;
    created_at: string;
    death_date: EntityGetResponseSetupSubmissionStructureJointAnyOfItemMicrobiltItemDeathDate;
    first_name: string;
    id: string;
    last_name: string;
    middle_name: EntityGetResponseSetupSubmissionStructureJointAnyOfItemMicrobiltItemMiddleName;
    request_id: string;
    response: string;
    return_code: EntityGetResponseSetupSubmissionStructureJointAnyOfItemMicrobiltItemReturnCode;
    tax_id_last_4: EntityGetResponseSetupSubmissionStructureJointAnyOfItemMicrobiltItemTaxIdLast4;
    tax_id_type: EntityGetResponseSetupSubmissionStructureJointAnyOfItemMicrobiltItemTaxIdType;
  };

export type EntityGetResponseSetupSubmissionStructureJointAnyOfItemIdentityVerificationStatus =
  (typeof EntityGetResponseSetupSubmissionStructureJointAnyOfItemIdentityVerificationStatus)[keyof typeof EntityGetResponseSetupSubmissionStructureJointAnyOfItemIdentityVerificationStatus];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const EntityGetResponseSetupSubmissionStructureJointAnyOfItemIdentityVerificationStatus =
  {
    pending_verification: "pending_verification",
    update_pending_verification: "update_pending_verification",
    verified: "verified",
    falsified: "falsified",
    no_match: "no_match",
    verifying: "verifying",
    requires_attention: "requires_attention",
    not_performed: "not_performed",
    skipped: "skipped",
  } as const;

export type EntityGetResponseSetupSubmissionStructureJointAnyOfItemIdentityDocumentSubmissionsItemSubmittedState =
  string | null;

export type EntityGetResponseSetupSubmissionStructureJointAnyOfItemIdentityDocumentSubmissionsItemSubmittedExpirationDate =
  string | null;

export type EntityGetResponseSetupSubmissionStructureJointAnyOfItemIdentityDocumentSubmissionsItemSubmittedDocumentType =
  (typeof EntityGetResponseSetupSubmissionStructureJointAnyOfItemIdentityDocumentSubmissionsItemSubmittedDocumentType)[keyof typeof EntityGetResponseSetupSubmissionStructureJointAnyOfItemIdentityDocumentSubmissionsItemSubmittedDocumentType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const EntityGetResponseSetupSubmissionStructureJointAnyOfItemIdentityDocumentSubmissionsItemSubmittedDocumentType =
  {
    passport: "passport",
    us_drivers_license: "us_drivers_license",
    other: "other",
  } as const;

export type EntityGetResponseSetupSubmissionStructureJointAnyOfItemIdentityDocumentSubmissionsItemSubmittedDocumentNumber =
  string | null;

export type EntityGetResponseSetupSubmissionStructureJointAnyOfItemIdentityDocumentSubmissionsItem =
  {
    api_back_file_id: EntityGetResponseSetupSubmissionStructureJointAnyOfItemIdentityDocumentSubmissionsItemApiBackFileId;
    api_file_id: string;
    created_at: string;
    group_id: string;
    id: string;
    identity_verification_status: EntityGetResponseSetupSubmissionStructureJointAnyOfItemIdentityDocumentSubmissionsItemIdentityVerificationStatus;
    submitted_address: EntityGetResponseSetupSubmissionStructureJointAnyOfItemIdentityDocumentSubmissionsItemSubmittedAddress;
    submitted_country: string;
    submitted_date_of_birth: string;
    submitted_document_number: EntityGetResponseSetupSubmissionStructureJointAnyOfItemIdentityDocumentSubmissionsItemSubmittedDocumentNumber;
    submitted_document_type: EntityGetResponseSetupSubmissionStructureJointAnyOfItemIdentityDocumentSubmissionsItemSubmittedDocumentType;
    submitted_expiration_date: EntityGetResponseSetupSubmissionStructureJointAnyOfItemIdentityDocumentSubmissionsItemSubmittedExpirationDate;
    submitted_name: string;
    submitted_state: EntityGetResponseSetupSubmissionStructureJointAnyOfItemIdentityDocumentSubmissionsItemSubmittedState;
  };

export type EntityGetResponseSetupSubmissionStructureJointAnyOfItem = {
  created_at: string;
  id: string;
  identity_document_submissions: EntityGetResponseSetupSubmissionStructureJointAnyOfItemIdentityDocumentSubmissionsItem[];
  identity_submitted_at: string;
  identity_verification_status: EntityGetResponseSetupSubmissionStructureJointAnyOfItemIdentityVerificationStatus;
  microbilt: EntityGetResponseSetupSubmissionStructureJointAnyOfItemMicrobiltItem[];
  submission_sovos_response_id: EntityGetResponseSetupSubmissionStructureJointAnyOfItemSubmissionSovosResponseId;
  submitted_address: EntityGetResponseSetupSubmissionStructureJointAnyOfItemSubmittedAddress;
  submitted_date_of_birth: string;
  submitted_identification_method: EntityGetResponseSetupSubmissionStructureJointAnyOfItemSubmittedIdentificationMethod;
  submitted_identification_number_last4: string;
  submitted_name: string;
  tax_id_validation_status: EntityGetResponseSetupSubmissionStructureJointAnyOfItemTaxIdValidationStatus;
};

export type EntityGetResponseSetupSubmissionStructureJointAnyOfItemIdentityDocumentSubmissionsItemSubmittedAddressLine2 =
  string | null;

export type EntityGetResponseSetupSubmissionStructureJointAnyOfItemIdentityDocumentSubmissionsItemSubmittedAddress =
  {
    city: string;
    line1: string;
    line2: EntityGetResponseSetupSubmissionStructureJointAnyOfItemIdentityDocumentSubmissionsItemSubmittedAddressLine2;
    state: string;
    zip: string;
  };

export type EntityGetResponseSetupSubmissionStructureJointAnyOfItemIdentityDocumentSubmissionsItemIdentityVerificationStatus =
  (typeof EntityGetResponseSetupSubmissionStructureJointAnyOfItemIdentityDocumentSubmissionsItemIdentityVerificationStatus)[keyof typeof EntityGetResponseSetupSubmissionStructureJointAnyOfItemIdentityDocumentSubmissionsItemIdentityVerificationStatus];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const EntityGetResponseSetupSubmissionStructureJointAnyOfItemIdentityDocumentSubmissionsItemIdentityVerificationStatus =
  {
    pending_verification: "pending_verification",
    update_pending_verification: "update_pending_verification",
    verified: "verified",
    falsified: "falsified",
    no_match: "no_match",
    verifying: "verifying",
    requires_attention: "requires_attention",
    not_performed: "not_performed",
    skipped: "skipped",
  } as const;

export type EntityGetResponseSetupSubmissionStructureJointAnyOfItemIdentityDocumentSubmissionsItemApiBackFileId =
  string | null;

export type EntityGetResponseSetupSubmissionStructureGovernmentAuthority =
  EntityGetResponseSetupSubmissionStructureGovernmentAuthorityAnyOf | null;

export type EntityGetResponseSetupSubmissionStructureGovernmentAuthorityAnyOfTaxIdValidationStatus =
  (typeof EntityGetResponseSetupSubmissionStructureGovernmentAuthorityAnyOfTaxIdValidationStatus)[keyof typeof EntityGetResponseSetupSubmissionStructureGovernmentAuthorityAnyOfTaxIdValidationStatus];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const EntityGetResponseSetupSubmissionStructureGovernmentAuthorityAnyOfTaxIdValidationStatus =
  {
    pending_validating: "pending_validating",
    update_pending_validating: "update_pending_validating",
    requires_attention: "requires_attention",
    failed: "failed",
    validated: "validated",
    not_provided: "not_provided",
    skipped: "skipped",
  } as const;

export type EntityGetResponseSetupSubmissionStructureGovernmentAuthorityAnyOfSubmittedWebsite =
  string | null;

export type EntityGetResponseSetupSubmissionStructureGovernmentAuthorityAnyOfSubmittedTaxId =
  string | null;

export type EntityGetResponseSetupSubmissionStructureGovernmentAuthorityAnyOfSubmittedAddressLine2 =
  string | null;

export type EntityGetResponseSetupSubmissionStructureGovernmentAuthorityAnyOfSubmittedAddress =
  {
    city: string;
    line1: string;
    line2: EntityGetResponseSetupSubmissionStructureGovernmentAuthorityAnyOfSubmittedAddressLine2;
    state: string;
    zip: string;
  };

export type EntityGetResponseSetupSubmissionStructureGovernmentAuthorityAnyOf =
  {
    entity_setup_government_authorized_person_relationships: EntityGetResponseSetupSubmissionStructureGovernmentAuthorityAnyOfEntitySetupGovernmentAuthorizedPersonRelationshipsItem[];
    id: string;
    submitted_address: EntityGetResponseSetupSubmissionStructureGovernmentAuthorityAnyOfSubmittedAddress;
    submitted_category: string;
    submitted_name: string;
    submitted_tax_id: EntityGetResponseSetupSubmissionStructureGovernmentAuthorityAnyOfSubmittedTaxId;
    submitted_website: EntityGetResponseSetupSubmissionStructureGovernmentAuthorityAnyOfSubmittedWebsite;
    tax_id_validation_status: EntityGetResponseSetupSubmissionStructureGovernmentAuthorityAnyOfTaxIdValidationStatus;
  };

export type EntityGetResponseSetupSubmissionStructureGovernmentAuthorityAnyOfEntitySetupGovernmentAuthorizedPersonRelationshipsItemEntitySetupGovernmentAuthorizedPersonSubmission =
  {
    id: string;
    submitted_name: string;
  };

export type EntityGetResponseSetupSubmissionStructureGovernmentAuthorityAnyOfEntitySetupGovernmentAuthorizedPersonRelationshipsItem =
  {
    archived: boolean;
    entity_setup_government_authority_submission_id: string;
    entity_setup_government_authorized_person_submission: EntityGetResponseSetupSubmissionStructureGovernmentAuthorityAnyOfEntitySetupGovernmentAuthorizedPersonRelationshipsItemEntitySetupGovernmentAuthorizedPersonSubmission;
    id: string;
  };

export type EntityGetResponseSetupSubmissionStructureCorporationAnyOfTaxIdValidationStatus =
  (typeof EntityGetResponseSetupSubmissionStructureCorporationAnyOfTaxIdValidationStatus)[keyof typeof EntityGetResponseSetupSubmissionStructureCorporationAnyOfTaxIdValidationStatus];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const EntityGetResponseSetupSubmissionStructureCorporationAnyOfTaxIdValidationStatus =
  {
    pending_validating: "pending_validating",
    update_pending_validating: "update_pending_validating",
    requires_attention: "requires_attention",
    failed: "failed",
    validated: "validated",
    not_provided: "not_provided",
    skipped: "skipped",
  } as const;

export type EntityGetResponseSetupSubmissionStructureCorporationAnyOfSubmittedWebsite =
  string | null;

export type EntityGetResponseSetupSubmissionStructureCorporationAnyOfSubmittedTaxId =
  string | null;

export type EntityGetResponseSetupSubmissionStructureCorporationAnyOfSubmittedState =
  string | null;

export type EntityGetResponseSetupSubmissionStructureCorporationAnyOf = {
  beneficial_ownership_exemption: EntityGetResponseSetupSubmissionStructureCorporationAnyOfBeneficialOwnershipExemption;
  cobalt_intelligence_secretary_of_state_search_intents: EntityGetResponseSetupSubmissionStructureCorporationAnyOfCobaltIntelligenceSecretaryOfStateSearchIntentsItem[];
  created_at: string;
  entity_setup_corporation_beneficial_owner_relationships: EntityGetResponseSetupSubmissionStructureCorporationAnyOfEntitySetupCorporationBeneficialOwnerRelationshipsItem[];
  id: string;
  industry_code: EntityGetResponseSetupSubmissionStructureCorporationAnyOfIndustryCode;
  submission_sovos_response_id: EntityGetResponseSetupSubmissionStructureCorporationAnyOfSubmissionSovosResponseId;
  submitted_address: EntityGetResponseSetupSubmissionStructureCorporationAnyOfSubmittedAddress;
  submitted_name: string;
  submitted_state: EntityGetResponseSetupSubmissionStructureCorporationAnyOfSubmittedState;
  submitted_tax_id: EntityGetResponseSetupSubmissionStructureCorporationAnyOfSubmittedTaxId;
  submitted_website: EntityGetResponseSetupSubmissionStructureCorporationAnyOfSubmittedWebsite;
  tax_id_submitted_at: string;
  tax_id_validation_status: EntityGetResponseSetupSubmissionStructureCorporationAnyOfTaxIdValidationStatus;
};

export type EntityGetResponseSetupSubmissionStructureCorporation =
  EntityGetResponseSetupSubmissionStructureCorporationAnyOf | null;

export type EntityGetResponseSetupSubmissionStructureCorporationAnyOfSubmittedAddressLine2 =
  string | null;

export type EntityGetResponseSetupSubmissionStructureCorporationAnyOfSubmittedAddress =
  {
    city: string;
    line1: string;
    line2: EntityGetResponseSetupSubmissionStructureCorporationAnyOfSubmittedAddressLine2;
    state: string;
    zip: string;
  };

export type EntityGetResponseSetupSubmissionStructureCorporationAnyOfSubmissionSovosResponseId =
  string | null;

export type EntityGetResponseSetupSubmissionStructureCorporationAnyOfIndustryCode =

    | "11"
    | "111"
    | "1111"
    | "11111"
    | "111110"
    | "11112"
    | "111120"
    | "11113"
    | "111130"
    | "11114"
    | "111140"
    | "11115"
    | "111150"
    | "11116"
    | "111160"
    | "11119"
    | "111191"
    | "111199"
    | "1112"
    | "11121"
    | "111211"
    | "111219"
    | "1113"
    | "11131"
    | "111310"
    | "11132"
    | "111320"
    | "11133"
    | "111331"
    | "111332"
    | "111333"
    | "111334"
    | "111335"
    | "111336"
    | "111339"
    | "1114"
    | "11141"
    | "111411"
    | "111419"
    | "11142"
    | "111421"
    | "111422"
    | "1119"
    | "11191"
    | "111910"
    | "11192"
    | "111920"
    | "11193"
    | "111930"
    | "11194"
    | "111940"
    | "11199"
    | "111991"
    | "111992"
    | "111998"
    | "112"
    | "1121"
    | "11211"
    | "112111"
    | "112112"
    | "11212"
    | "112120"
    | "11213"
    | "112130"
    | "1122"
    | "11221"
    | "112210"
    | "1123"
    | "11231"
    | "112310"
    | "11232"
    | "112320"
    | "11233"
    | "112330"
    | "11234"
    | "112340"
    | "11239"
    | "112390"
    | "1124"
    | "11241"
    | "112410"
    | "11242"
    | "112420"
    | "1125"
    | "11251"
    | "112511"
    | "112512"
    | "112519"
    | "1129"
    | "11291"
    | "112910"
    | "11292"
    | "112920"
    | "11293"
    | "112930"
    | "11299"
    | "112990"
    | "113"
    | "1131"
    | "11311"
    | "113110"
    | "1132"
    | "11321"
    | "113210"
    | "1133"
    | "11331"
    | "113310"
    | "114"
    | "1141"
    | "11411"
    | "114111"
    | "114112"
    | "114119"
    | "1142"
    | "11421"
    | "114210"
    | "115"
    | "1151"
    | "11511"
    | "115111"
    | "115112"
    | "115113"
    | "115114"
    | "115115"
    | "115116"
    | "1152"
    | "11521"
    | "115210"
    | "1153"
    | "11531"
    | "115310"
    | "21"
    | "211"
    | "2111"
    | "21112"
    | "211120"
    | "21113"
    | "211130"
    | "212"
    | "2121"
    | "21211"
    | "212114"
    | "212115"
    | "2122"
    | "21221"
    | "212210"
    | "21222"
    | "212220"
    | "21223"
    | "212230"
    | "21229"
    | "212290"
    | "2123"
    | "21231"
    | "212311"
    | "212312"
    | "212313"
    | "212319"
    | "21232"
    | "212321"
    | "212322"
    | "212323"
    | "21239"
    | "212390"
    | "213"
    | "2131"
    | "21311"
    | "213111"
    | "213112"
    | "213113"
    | "213114"
    | "213115"
    | "22"
    | "221"
    | "2211"
    | "22111"
    | "221111"
    | "221112"
    | "221113"
    | "221114"
    | "221115"
    | "221116"
    | "221117"
    | "221118"
    | "22112"
    | "221121"
    | "221122"
    | "2212"
    | "22121"
    | "221210"
    | "2213"
    | "22131"
    | "221310"
    | "22132"
    | "221320"
    | "22133"
    | "221330"
    | "23"
    | "236"
    | "2361"
    | "23611"
    | "236115"
    | "236116"
    | "236117"
    | "236118"
    | "2362"
    | "23621"
    | "236210"
    | "23622"
    | "236220"
    | "237"
    | "2371"
    | "23711"
    | "237110"
    | "23712"
    | "237120"
    | "23713"
    | "237130"
    | "2372"
    | "23721"
    | "237210"
    | "2373"
    | "23731"
    | "237310"
    | "2379"
    | "23799"
    | "237990"
    | "238"
    | "2381"
    | "23811"
    | "238110"
    | "23812"
    | "238120"
    | "23813"
    | "238130"
    | "23814"
    | "238140"
    | "23815"
    | "238150"
    | "23816"
    | "238160"
    | "23817"
    | "238170"
    | "23819"
    | "238190"
    | "2382"
    | "23821"
    | "238210"
    | "23822"
    | "238220"
    | "23829"
    | "238290"
    | "2383"
    | "23831"
    | "238310"
    | "23832"
    | "238320"
    | "23833"
    | "238330"
    | "23834"
    | "238340"
    | "23835"
    | "238350"
    | "23839"
    | "238390"
    | "2389"
    | "23891"
    | "238910"
    | "23899"
    | "238990"
    | "31-33"
    | "311"
    | "3111"
    | "31111"
    | "311111"
    | "311119"
    | "3112"
    | "31121"
    | "311211"
    | "311212"
    | "311213"
    | "31122"
    | "311221"
    | "311224"
    | "311225"
    | "31123"
    | "311230"
    | "3113"
    | "31131"
    | "311313"
    | "311314"
    | "31134"
    | "311340"
    | "31135"
    | "311351"
    | "311352"
    | "3114"
    | "31141"
    | "311411"
    | "311412"
    | "31142"
    | "311421"
    | "311422"
    | "311423"
    | "3115"
    | "31151"
    | "311511"
    | "311512"
    | "311513"
    | "311514"
    | "31152"
    | "311520"
    | "3116"
    | "31161"
    | "311611"
    | "311612"
    | "311613"
    | "311615"
    | "3117"
    | "31171"
    | "311710"
    | "3118"
    | "31181"
    | "311811"
    | "311812"
    | "311813"
    | "31182"
    | "311821"
    | "311824"
    | "31183"
    | "311830"
    | "3119"
    | "31191"
    | "311911"
    | "311919"
    | "31192"
    | "311920"
    | "31193"
    | "311930"
    | "31194"
    | "311941"
    | "311942"
    | "31199"
    | "311991"
    | "311999"
    | "312"
    | "3121"
    | "31211"
    | "312111"
    | "312112"
    | "312113"
    | "31212"
    | "312120"
    | "31213"
    | "312130"
    | "31214"
    | "312140"
    | "3122"
    | "31223"
    | "312230"
    | "313"
    | "3131"
    | "31311"
    | "313110"
    | "3132"
    | "31321"
    | "313210"
    | "31322"
    | "313220"
    | "31323"
    | "313230"
    | "31324"
    | "313240"
    | "3133"
    | "31331"
    | "313310"
    | "31332"
    | "313320"
    | "314"
    | "3141"
    | "31411"
    | "314110"
    | "31412"
    | "314120"
    | "3149"
    | "31491"
    | "314910"
    | "31499"
    | "314994"
    | "314999"
    | "315"
    | "3151"
    | "31512"
    | "315120"
    | "3152"
    | "31521"
    | "315210"
    | "31525"
    | "315250"
    | "3159"
    | "31599"
    | "315990"
    | "316"
    | "3161"
    | "31611"
    | "316110"
    | "3162"
    | "31621"
    | "316210"
    | "3169"
    | "31699"
    | "316990"
    | "321"
    | "3211"
    | "32111"
    | "321113"
    | "321114"
    | "3212"
    | "32121"
    | "321211"
    | "321212"
    | "321215"
    | "321219"
    | "3219"
    | "32191"
    | "321911"
    | "321912"
    | "321918"
    | "32192"
    | "321920"
    | "32199"
    | "321991"
    | "321992"
    | "321999"
    | "322"
    | "3221"
    | "32211"
    | "322110"
    | "32212"
    | "322120"
    | "32213"
    | "322130"
    | "3222"
    | "32221"
    | "322211"
    | "322212"
    | "322219"
    | "32222"
    | "322220"
    | "32223"
    | "322230"
    | "32229"
    | "322291"
    | "322299"
    | "323"
    | "3231"
    | "32311"
    | "323111"
    | "323113"
    | "323117"
    | "32312"
    | "323120"
    | "324"
    | "3241"
    | "32411"
    | "324110"
    | "32412"
    | "324121"
    | "324122"
    | "32419"
    | "324191"
    | "324199"
    | "325"
    | "3251"
    | "32511"
    | "325110"
    | "32512"
    | "325120"
    | "32513"
    | "325130"
    | "32518"
    | "325180"
    | "32519"
    | "325193"
    | "325194"
    | "325199"
    | "3252"
    | "32521"
    | "325211"
    | "325212"
    | "32522"
    | "325220"
    | "3253"
    | "32531"
    | "325311"
    | "325312"
    | "325314"
    | "325315"
    | "32532"
    | "325320"
    | "3254"
    | "32541"
    | "325411"
    | "325412"
    | "325413"
    | "325414"
    | "3255"
    | "32551"
    | "325510"
    | "32552"
    | "325520"
    | "3256"
    | "32561"
    | "325611"
    | "325612"
    | "325613"
    | "32562"
    | "325620"
    | "3259"
    | "32591"
    | "325910"
    | "32592"
    | "325920"
    | "32599"
    | "325991"
    | "325992"
    | "325998"
    | "326"
    | "3261"
    | "32611"
    | "326111"
    | "326112"
    | "326113"
    | "32612"
    | "326121"
    | "326122"
    | "32613"
    | "326130"
    | "32614"
    | "326140"
    | "32615"
    | "326150"
    | "32616"
    | "326160"
    | "32619"
    | "326191"
    | "326199"
    | "3262"
    | "32621"
    | "326211"
    | "326212"
    | "32622"
    | "326220"
    | "32629"
    | "326291"
    | "326299"
    | "327"
    | "3271"
    | "32711"
    | "327110"
    | "32712"
    | "327120"
    | "3272"
    | "32721"
    | "327211"
    | "327212"
    | "327213"
    | "327215"
    | "3273"
    | "32731"
    | "327310"
    | "32732"
    | "327320"
    | "32733"
    | "327331"
    | "327332"
    | "32739"
    | "327390"
    | "3274"
    | "32741"
    | "327410"
    | "32742"
    | "327420"
    | "3279"
    | "32791"
    | "327910"
    | "32799"
    | "327991"
    | "327992"
    | "327993"
    | "327999"
    | "331"
    | "3311"
    | "33111"
    | "331110"
    | "3312"
    | "33121"
    | "331210"
    | "33122"
    | "331221"
    | "331222"
    | "3313"
    | "33131"
    | "331313"
    | "331314"
    | "331315"
    | "331318"
    | "3314"
    | "33141"
    | "331410"
    | "33142"
    | "331420"
    | "33149"
    | "331491"
    | "331492"
    | "3315"
    | "33151"
    | "331511"
    | "331512"
    | "331513"
    | "33152"
    | "331523"
    | "331524"
    | "331529"
    | "332"
    | "3321"
    | "33211"
    | "332111"
    | "332112"
    | "332114"
    | "332117"
    | "332119"
    | "3322"
    | "33221"
    | "332215"
    | "332216"
    | "3323"
    | "33231"
    | "332311"
    | "332312"
    | "332313"
    | "33232"
    | "332321"
    | "332322"
    | "332323"
    | "3324"
    | "33241"
    | "332410"
    | "33242"
    | "332420"
    | "33243"
    | "332431"
    | "332439"
    | "3325"
    | "33251"
    | "332510"
    | "3326"
    | "33261"
    | "332613"
    | "332618"
    | "3327"
    | "33271"
    | "332710"
    | "33272"
    | "332721"
    | "332722"
    | "3328"
    | "33281"
    | "332811"
    | "332812"
    | "332813"
    | "3329"
    | "33291"
    | "332911"
    | "332912"
    | "332913"
    | "332919"
    | "33299"
    | "332991"
    | "332992"
    | "332993"
    | "332994"
    | "332996"
    | "332999"
    | "333"
    | "3331"
    | "33311"
    | "333111"
    | "333112"
    | "33312"
    | "333120"
    | "33313"
    | "333131"
    | "333132"
    | "3332"
    | "33324"
    | "333241"
    | "333242"
    | "333243"
    | "333248"
    | "3333"
    | "33331"
    | "333310"
    | "3334"
    | "33341"
    | "333413"
    | "333414"
    | "333415"
    | "3335"
    | "33351"
    | "333511"
    | "333514"
    | "333515"
    | "333517"
    | "333519"
    | "3336"
    | "33361"
    | "333611"
    | "333612"
    | "333613"
    | "333618"
    | "3339"
    | "33391"
    | "333912"
    | "333914"
    | "33392"
    | "333921"
    | "333922"
    | "333923"
    | "333924"
    | "33399"
    | "333991"
    | "333992"
    | "333993"
    | "333994"
    | "333995"
    | "333996"
    | "333998"
    | "334"
    | "3341"
    | "33411"
    | "334111"
    | "334112"
    | "334118"
    | "3342"
    | "33421"
    | "334210"
    | "33422"
    | "334220"
    | "33429"
    | "334290"
    | "3343"
    | "33431"
    | "334310"
    | "3344"
    | "33441"
    | "334412"
    | "334413"
    | "334416"
    | "334417"
    | "334418"
    | "334419"
    | "3345"
    | "33451"
    | "334510"
    | "334511"
    | "334512"
    | "334513"
    | "334514"
    | "334515"
    | "334516"
    | "334517"
    | "334519"
    | "3346"
    | "33461"
    | "334610"
    | "335"
    | "3351"
    | "33513"
    | "335131"
    | "335132"
    | "335139"
    | "3352"
    | "33521"
    | "335210"
    | "33522"
    | "335220"
    | "3353"
    | "33531"
    | "335311"
    | "335312"
    | "335313"
    | "335314"
    | "3359"
    | "33591"
    | "335910"
    | "33592"
    | "335921"
    | "335929"
    | "33593"
    | "335931"
    | "335932"
    | "33599"
    | "335991"
    | "335999"
    | "336"
    | "3361"
    | "33611"
    | "336110"
    | "33612"
    | "336120"
    | "3362"
    | "33621"
    | "336211"
    | "336212"
    | "336213"
    | "336214"
    | "3363"
    | "33631"
    | "336310"
    | "33632"
    | "336320"
    | "33633"
    | "336330"
    | "33634"
    | "336340"
    | "33635"
    | "336350"
    | "33636"
    | "336360"
    | "33637"
    | "336370"
    | "33639"
    | "336390"
    | "3364"
    | "33641"
    | "336411"
    | "336412"
    | "336413"
    | "336414"
    | "336415"
    | "336419"
    | "3365"
    | "33651"
    | "336510"
    | "3366"
    | "33661"
    | "336611"
    | "336612"
    | "3369"
    | "33699"
    | "336991"
    | "336992"
    | "336999"
    | "337"
    | "3371"
    | "33711"
    | "337110"
    | "33712"
    | "337121"
    | "337122"
    | "337126"
    | "337127"
    | "3372"
    | "33721"
    | "337211"
    | "337212"
    | "337214"
    | "337215"
    | "3379"
    | "33791"
    | "337910"
    | "33792"
    | "337920"
    | "339"
    | "3391"
    | "33911"
    | "339112"
    | "339113"
    | "339114"
    | "339115"
    | "339116"
    | "3399"
    | "33991"
    | "339910"
    | "33992"
    | "339920"
    | "33993"
    | "339930"
    | "33994"
    | "339940"
    | "33995"
    | "339950"
    | "33999"
    | "339991"
    | "339992"
    | "339993"
    | "339994"
    | "339995"
    | "339999"
    | "42"
    | "423"
    | "4231"
    | "42311"
    | "423110"
    | "42312"
    | "423120"
    | "42313"
    | "423130"
    | "42314"
    | "423140"
    | "4232"
    | "42321"
    | "423210"
    | "42322"
    | "423220"
    | "4233"
    | "42331"
    | "423310"
    | "42332"
    | "423320"
    | "42333"
    | "423330"
    | "42339"
    | "423390"
    | "4234"
    | "42341"
    | "423410"
    | "42342"
    | "423420"
    | "42343"
    | "423430"
    | "42344"
    | "423440"
    | "42345"
    | "423450"
    | "42346"
    | "423460"
    | "42349"
    | "423490"
    | "4235"
    | "42351"
    | "423510"
    | "42352"
    | "423520"
    | "4236"
    | "42361"
    | "423610"
    | "42362"
    | "423620"
    | "42369"
    | "423690"
    | "4237"
    | "42371"
    | "423710"
    | "42372"
    | "423720"
    | "42373"
    | "423730"
    | "42374"
    | "423740"
    | "4238"
    | "42381"
    | "423810"
    | "42382"
    | "423820"
    | "42383"
    | "423830"
    | "42384"
    | "423840"
    | "42385"
    | "423850"
    | "42386"
    | "423860"
    | "4239"
    | "42391"
    | "423910"
    | "42392"
    | "423920"
    | "42393"
    | "423930"
    | "42394"
    | "423940"
    | "42399"
    | "423990"
    | "424"
    | "4241"
    | "42411"
    | "424110"
    | "42412"
    | "424120"
    | "42413"
    | "424130"
    | "4242"
    | "42421"
    | "424210"
    | "4243"
    | "42431"
    | "424310"
    | "42434"
    | "424340"
    | "42435"
    | "424350"
    | "4244"
    | "42441"
    | "424410"
    | "42442"
    | "424420"
    | "42443"
    | "424430"
    | "42444"
    | "424440"
    | "42445"
    | "424450"
    | "42446"
    | "424460"
    | "42447"
    | "424470"
    | "42448"
    | "424480"
    | "42449"
    | "424490"
    | "4245"
    | "42451"
    | "424510"
    | "42452"
    | "424520"
    | "42459"
    | "424590"
    | "4246"
    | "42461"
    | "424610"
    | "42469"
    | "424690"
    | "4247"
    | "42471"
    | "424710"
    | "42472"
    | "424720"
    | "4248"
    | "42481"
    | "424810"
    | "42482"
    | "424820"
    | "4249"
    | "42491"
    | "424910"
    | "42492"
    | "424920"
    | "42493"
    | "424930"
    | "42494"
    | "424940"
    | "42495"
    | "424950"
    | "42499"
    | "424990"
    | "425"
    | "4251"
    | "42512"
    | "425120"
    | "44-45"
    | "441"
    | "4411"
    | "44111"
    | "441110"
    | "44112"
    | "441120"
    | "4412"
    | "44121"
    | "441210"
    | "44122"
    | "441222"
    | "441227"
    | "4413"
    | "44133"
    | "441330"
    | "44134"
    | "441340"
    | "444"
    | "4441"
    | "44411"
    | "444110"
    | "44412"
    | "444120"
    | "44414"
    | "444140"
    | "44418"
    | "444180"
    | "4442"
    | "44423"
    | "444230"
    | "44424"
    | "444240"
    | "445"
    | "4451"
    | "44511"
    | "445110"
    | "44513"
    | "445131"
    | "445132"
    | "4452"
    | "44523"
    | "445230"
    | "44524"
    | "445240"
    | "44525"
    | "445250"
    | "44529"
    | "445291"
    | "445292"
    | "445298"
    | "4453"
    | "44532"
    | "445320"
    | "449"
    | "4491"
    | "44911"
    | "449110"
    | "44912"
    | "449121"
    | "449122"
    | "449129"
    | "4492"
    | "44921"
    | "449210"
    | "455"
    | "4551"
    | "45511"
    | "455110"
    | "4552"
    | "45521"
    | "455211"
    | "455219"
    | "456"
    | "4561"
    | "45611"
    | "456110"
    | "45612"
    | "456120"
    | "45613"
    | "456130"
    | "45619"
    | "456191"
    | "456199"
    | "457"
    | "4571"
    | "45711"
    | "457110"
    | "45712"
    | "457120"
    | "4572"
    | "45721"
    | "457210"
    | "458"
    | "4581"
    | "45811"
    | "458110"
    | "4582"
    | "45821"
    | "458210"
    | "4583"
    | "45831"
    | "458310"
    | "45832"
    | "458320"
    | "459"
    | "4591"
    | "45911"
    | "459110"
    | "45912"
    | "459120"
    | "45913"
    | "459130"
    | "45914"
    | "459140"
    | "4592"
    | "45921"
    | "459210"
    | "4593"
    | "45931"
    | "459310"
    | "4594"
    | "45941"
    | "459410"
    | "45942"
    | "459420"
    | "4595"
    | "45951"
    | "459510"
    | "4599"
    | "45991"
    | "459910"
    | "45992"
    | "459920"
    | "45993"
    | "459930"
    | "45999"
    | "459991"
    | "459999"
    | "48-49"
    | "481"
    | "4811"
    | "48111"
    | "481111"
    | "481112"
    | "4812"
    | "48121"
    | "481211"
    | "481212"
    | "481219"
    | "482"
    | "4821"
    | "48211"
    | "482111"
    | "482112"
    | "483"
    | "4831"
    | "48311"
    | "483111"
    | "483112"
    | "483113"
    | "483114"
    | "4832"
    | "48321"
    | "483211"
    | "483212"
    | "484"
    | "4841"
    | "48411"
    | "484110"
    | "48412"
    | "484121"
    | "484122"
    | "4842"
    | "48421"
    | "484210"
    | "48422"
    | "484220"
    | "48423"
    | "484230"
    | "485"
    | "4851"
    | "48511"
    | "485111"
    | "485112"
    | "485113"
    | "485119"
    | "4852"
    | "48521"
    | "485210"
    | "4853"
    | "48531"
    | "485310"
    | "48532"
    | "485320"
    | "4854"
    | "48541"
    | "485410"
    | "4855"
    | "48551"
    | "485510"
    | "4859"
    | "48599"
    | "485991"
    | "485999"
    | "486"
    | "4861"
    | "48611"
    | "486110"
    | "4862"
    | "48621"
    | "486210"
    | "4869"
    | "48691"
    | "486910"
    | "48699"
    | "486990"
    | "487"
    | "4871"
    | "48711"
    | "487110"
    | "4872"
    | "48721"
    | "487210"
    | "4879"
    | "48799"
    | "487990"
    | "488"
    | "4881"
    | "48811"
    | "488111"
    | "488119"
    | "48819"
    | "488190"
    | "4882"
    | "48821"
    | "488210"
    | "4883"
    | "48831"
    | "488310"
    | "48832"
    | "488320"
    | "48833"
    | "488330"
    | "48839"
    | "488390"
    | "4884"
    | "48841"
    | "488410"
    | "48849"
    | "488490"
    | "4885"
    | "48851"
    | "488510"
    | "4889"
    | "48899"
    | "488991"
    | "488999"
    | "491"
    | "4911"
    | "49111"
    | "491110"
    | "492"
    | "4921"
    | "49211"
    | "492110"
    | "4922"
    | "49221"
    | "492210"
    | "493"
    | "4931"
    | "49311"
    | "493110"
    | "49312"
    | "493120"
    | "49313"
    | "493130"
    | "49319"
    | "493190"
    | "51"
    | "512"
    | "5121"
    | "51211"
    | "512110"
    | "51212"
    | "512120"
    | "51213"
    | "512131"
    | "512132"
    | "51219"
    | "512191"
    | "512199"
    | "5122"
    | "51223"
    | "512230"
    | "51224"
    | "512240"
    | "51225"
    | "512250"
    | "51229"
    | "512290"
    | "513"
    | "5131"
    | "51311"
    | "513110"
    | "51312"
    | "513120"
    | "51313"
    | "513130"
    | "51314"
    | "513140"
    | "51319"
    | "513191"
    | "513199"
    | "5132"
    | "5112"
    | "51321"
    | "513210"
    | "516"
    | "5161"
    | "51611"
    | "516110"
    | "51612"
    | "516120"
    | "5162"
    | "51621"
    | "516210"
    | "517"
    | "5171"
    | "51711"
    | "517111"
    | "517112"
    | "51712"
    | "517121"
    | "517122"
    | "5174"
    | "51741"
    | "517410"
    | "5178"
    | "51781"
    | "517810"
    | "518"
    | "5182"
    | "51821"
    | "518210"
    | "519"
    | "5192"
    | "51921"
    | "519210"
    | "51929"
    | "519290"
    | "52"
    | "521"
    | "5211"
    | "52111"
    | "521110"
    | "522"
    | "5221"
    | "52211"
    | "522110"
    | "52213"
    | "522130"
    | "52218"
    | "522180"
    | "5222"
    | "52221"
    | "522210"
    | "52222"
    | "522220"
    | "52229"
    | "522291"
    | "522292"
    | "522299"
    | "5223"
    | "52231"
    | "522310"
    | "52232"
    | "522320"
    | "52239"
    | "522390"
    | "523"
    | "5231"
    | "52315"
    | "523150"
    | "52316"
    | "523160"
    | "5232"
    | "52321"
    | "523210"
    | "5239"
    | "52391"
    | "523910"
    | "52394"
    | "523940"
    | "52399"
    | "523991"
    | "523999"
    | "524"
    | "5241"
    | "52411"
    | "524113"
    | "524114"
    | "52412"
    | "524126"
    | "524127"
    | "524128"
    | "52413"
    | "524130"
    | "5242"
    | "52421"
    | "524210"
    | "52429"
    | "524291"
    | "524292"
    | "524298"
    | "525"
    | "5251"
    | "52511"
    | "525110"
    | "52512"
    | "525120"
    | "52519"
    | "525190"
    | "5259"
    | "52591"
    | "525910"
    | "52592"
    | "525920"
    | "52599"
    | "525990"
    | "53"
    | "531"
    | "5311"
    | "53111"
    | "531110"
    | "53112"
    | "531120"
    | "53113"
    | "531130"
    | "53119"
    | "531190"
    | "5312"
    | "53121"
    | "531210"
    | "5313"
    | "53131"
    | "531311"
    | "531312"
    | "53132"
    | "531320"
    | "53139"
    | "531390"
    | "532"
    | "5321"
    | "53211"
    | "532111"
    | "532112"
    | "53212"
    | "532120"
    | "5322"
    | "53221"
    | "532210"
    | "53228"
    | "532281"
    | "532282"
    | "532283"
    | "532284"
    | "532289"
    | "5323"
    | "53231"
    | "532310"
    | "5324"
    | "53241"
    | "532411"
    | "532412"
    | "53242"
    | "532420"
    | "53249"
    | "532490"
    | "533"
    | "5331"
    | "53311"
    | "533110"
    | "54"
    | "541"
    | "5411"
    | "54111"
    | "541110"
    | "54112"
    | "541120"
    | "54119"
    | "541191"
    | "541199"
    | "5412"
    | "54121"
    | "541211"
    | "541213"
    | "541214"
    | "541219"
    | "5413"
    | "54131"
    | "541310"
    | "54132"
    | "541320"
    | "54133"
    | "541330"
    | "54134"
    | "541340"
    | "54135"
    | "541350"
    | "54136"
    | "541360"
    | "54137"
    | "541370"
    | "54138"
    | "541380"
    | "5414"
    | "54141"
    | "541410"
    | "54142"
    | "541420"
    | "54143"
    | "541430"
    | "54149"
    | "541490"
    | "5415"
    | "54151"
    | "541511"
    | "541512"
    | "541513"
    | "541519"
    | "5416"
    | "54161"
    | "541611"
    | "541612"
    | "541613"
    | "541614"
    | "541618"
    | "54162"
    | "541620"
    | "54169"
    | "541690"
    | "5417"
    | "54171"
    | "541713"
    | "541714"
    | "541715"
    | "54172"
    | "541720"
    | "5418"
    | "54181"
    | "541810"
    | "54182"
    | "541820"
    | "54183"
    | "541830"
    | "54184"
    | "541840"
    | "54185"
    | "541850"
    | "54186"
    | "541860"
    | "54187"
    | "541870"
    | "54189"
    | "541890"
    | "5419"
    | "54191"
    | "541910"
    | "54192"
    | "541921"
    | "541922"
    | "54193"
    | "541930"
    | "54194"
    | "541940"
    | "54199"
    | "541990"
    | "55"
    | "551"
    | "5511"
    | "55111"
    | "551111"
    | "551112"
    | "551114"
    | "56"
    | "561"
    | "5611"
    | "56111"
    | "561110"
    | "5612"
    | "56121"
    | "561210"
    | "5613"
    | "56131"
    | "561311"
    | "561312"
    | "56132"
    | "561320"
    | "56133"
    | "561330"
    | "5614"
    | "56141"
    | "561410"
    | "56142"
    | "561421"
    | "561422"
    | "56143"
    | "561431"
    | "561439"
    | "56144"
    | "561440"
    | "56145"
    | "561450"
    | "56149"
    | "561491"
    | "561492"
    | "561499"
    | "5615"
    | "56151"
    | "561510"
    | "56152"
    | "561520"
    | "56159"
    | "561591"
    | "561599"
    | "5616"
    | "56161"
    | "561611"
    | "561612"
    | "561613"
    | "56162"
    | "561621"
    | "561622"
    | "5617"
    | "56171"
    | "561710"
    | "56172"
    | "561720"
    | "56173"
    | "561730"
    | "56174"
    | "561740"
    | "56179"
    | "561790"
    | "5619"
    | "56191"
    | "561910"
    | "56192"
    | "561920"
    | "56199"
    | "561990"
    | "562"
    | "5621"
    | "56211"
    | "562111"
    | "562112"
    | "562119"
    | "5622"
    | "56221"
    | "562211"
    | "562212"
    | "562213"
    | "562219"
    | "5629"
    | "56291"
    | "562910"
    | "56292"
    | "562920"
    | "56299"
    | "562991"
    | "562998"
    | "61"
    | "611"
    | "6111"
    | "61111"
    | "611110"
    | "6112"
    | "61121"
    | "611210"
    | "6113"
    | "61131"
    | "611310"
    | "6114"
    | "61141"
    | "611410"
    | "61142"
    | "611420"
    | "61143"
    | "611430"
    | "6115"
    | "61151"
    | "611511"
    | "611512"
    | "611513"
    | "611519"
    | "6116"
    | "61161"
    | "611610"
    | "61162"
    | "611620"
    | "61163"
    | "611630"
    | "61169"
    | "611691"
    | "611692"
    | "611699"
    | "6117"
    | "61171"
    | "611710"
    | "62"
    | "621"
    | "6211"
    | "62111"
    | "621111"
    | "621112"
    | "6212"
    | "62121"
    | "621210"
    | "6213"
    | "62131"
    | "621310"
    | "62132"
    | "621320"
    | "62133"
    | "621330"
    | "62134"
    | "621340"
    | "62139"
    | "621391"
    | "621399"
    | "6214"
    | "62141"
    | "621410"
    | "62142"
    | "621420"
    | "62149"
    | "621491"
    | "621492"
    | "621493"
    | "621498"
    | "6215"
    | "62151"
    | "621511"
    | "621512"
    | "6216"
    | "62161"
    | "621610"
    | "6219"
    | "62191"
    | "621910"
    | "62199"
    | "621991"
    | "621999"
    | "622"
    | "6221"
    | "62211"
    | "622110"
    | "6222"
    | "62221"
    | "622210"
    | "6223"
    | "62231"
    | "622310"
    | "623"
    | "6231"
    | "62311"
    | "623110"
    | "6232"
    | "62321"
    | "623210"
    | "62322"
    | "623220"
    | "6233"
    | "62331"
    | "623311"
    | "623312"
    | "6239"
    | "62399"
    | "623990"
    | "624"
    | "6241"
    | "62411"
    | "624110"
    | "62412"
    | "624120"
    | "62419"
    | "624190"
    | "6242"
    | "62421"
    | "624210"
    | "62422"
    | "624221"
    | "624229"
    | "62423"
    | "624230"
    | "6243"
    | "62431"
    | "624310"
    | "6244"
    | "62441"
    | "624410"
    | "71"
    | "711"
    | "7111"
    | "71111"
    | "711110"
    | "71112"
    | "711120"
    | "71113"
    | "711130"
    | "71119"
    | "711190"
    | "7112"
    | "71121"
    | "711211"
    | "711212"
    | "711219"
    | "7113"
    | "71131"
    | "711310"
    | "71132"
    | "711320"
    | "7114"
    | "71141"
    | "711410"
    | "7115"
    | "71151"
    | "711510"
    | "712"
    | "7121"
    | "71211"
    | "712110"
    | "71212"
    | "712120"
    | "71213"
    | "712130"
    | "71219"
    | "712190"
    | "713"
    | "7131"
    | "71311"
    | "713110"
    | "71312"
    | "713120"
    | "7132"
    | "71321"
    | "713210"
    | "71329"
    | "713290"
    | "7139"
    | "71391"
    | "713910"
    | "71392"
    | "713920"
    | "71393"
    | "713930"
    | "71394"
    | "713940"
    | "71395"
    | "713950"
    | "71399"
    | "713990"
    | "72"
    | "721"
    | "7211"
    | "72111"
    | "721110"
    | "72112"
    | "721120"
    | "72119"
    | "721191"
    | "721199"
    | "7212"
    | "72121"
    | "721211"
    | "721214"
    | "7213"
    | "72131"
    | "721310"
    | "722"
    | "7223"
    | "72231"
    | "722310"
    | "72232"
    | "722320"
    | "72233"
    | "722330"
    | "7224"
    | "72241"
    | "722410"
    | "7225"
    | "72251"
    | "722511"
    | "722513"
    | "722514"
    | "722515"
    | "81"
    | "811"
    | "8111"
    | "81111"
    | "811111"
    | "811114"
    | "81112"
    | "811121"
    | "811122"
    | "81119"
    | "811191"
    | "811192"
    | "811198"
    | "8112"
    | "81121"
    | "811210"
    | "8113"
    | "81131"
    | "811310"
    | "8114"
    | "81141"
    | "811411"
    | "811412"
    | "81142"
    | "811420"
    | "81143"
    | "811430"
    | "81149"
    | "811490"
    | "812"
    | "8121"
    | "81211"
    | "812111"
    | "812112"
    | "812113"
    | "81219"
    | "812191"
    | "812199"
    | "8122"
    | "81221"
    | "812210"
    | "81222"
    | "812220"
    | "8123"
    | "81231"
    | "812310"
    | "81232"
    | "812320"
    | "81233"
    | "812331"
    | "812332"
    | "8129"
    | "81291"
    | "812910"
    | "81292"
    | "812921"
    | "812922"
    | "81293"
    | "812930"
    | "81299"
    | "812990"
    | "813"
    | "8131"
    | "81311"
    | "813110"
    | "8132"
    | "81321"
    | "813211"
    | "813212"
    | "813219"
    | "8133"
    | "81331"
    | "813311"
    | "813312"
    | "813319"
    | "8134"
    | "81341"
    | "813410"
    | "8139"
    | "81391"
    | "813910"
    | "81392"
    | "813920"
    | "81393"
    | "813930"
    | "81394"
    | "813940"
    | "81399"
    | "813990"
    | "814"
    | "8141"
    | "81411"
    | "814110"
    | "92"
    | "921"
    | "9211"
    | "92111"
    | "921110"
    | "92112"
    | "921120"
    | "92113"
    | "921130"
    | "92114"
    | "921140"
    | "92115"
    | "921150"
    | "92119"
    | "921190"
    | "922"
    | "9221"
    | "92211"
    | "922110"
    | "92212"
    | "922120"
    | "92213"
    | "922130"
    | "92214"
    | "922140"
    | "92215"
    | "922150"
    | "92216"
    | "922160"
    | "92219"
    | "922190"
    | "923"
    | "9231"
    | "92311"
    | "923110"
    | "92312"
    | "923120"
    | "92313"
    | "923130"
    | "92314"
    | "923140"
    | "924"
    | "9241"
    | "92411"
    | "924110"
    | "92412"
    | "924120"
    | "925"
    | "9251"
    | "92511"
    | "925110"
    | "92512"
    | "925120"
    | "926"
    | "9261"
    | "92611"
    | "926110"
    | "92612"
    | "926120"
    | "92613"
    | "926130"
    | "92614"
    | "926140"
    | "92615"
    | "926150"
    | "927"
    | "9271"
    | "92711"
    | "927110"
    | "928"
    | "9281"
    | "92811"
    | "928110"
    | "92812"
    | "928120"
    | "999300"
    | null;

export type EntityGetResponseSetupSubmissionStructureCorporationAnyOfEntitySetupCorporationBeneficialOwnerRelationshipsItemSubmittedCompanyTitle =
  string | null;

export type EntityGetResponseSetupSubmissionStructureCorporationAnyOfEntitySetupCorporationBeneficialOwnerRelationshipsItemProng =
  (typeof EntityGetResponseSetupSubmissionStructureCorporationAnyOfEntitySetupCorporationBeneficialOwnerRelationshipsItemProng)[keyof typeof EntityGetResponseSetupSubmissionStructureCorporationAnyOfEntitySetupCorporationBeneficialOwnerRelationshipsItemProng];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const EntityGetResponseSetupSubmissionStructureCorporationAnyOfEntitySetupCorporationBeneficialOwnerRelationshipsItemProng =
  {
    ownership: "ownership",
    control: "control",
  } as const;

export type EntityGetResponseSetupSubmissionStructureCorporationAnyOfEntitySetupCorporationBeneficialOwnerRelationshipsItem =
  {
    archived: boolean;
    entity_setup_beneficial_owner_submission: EntityGetResponseSetupSubmissionStructureCorporationAnyOfEntitySetupCorporationBeneficialOwnerRelationshipsItemEntitySetupBeneficialOwnerSubmission;
    entity_setup_corporation_submission_id: string;
    id: string;
    prong: EntityGetResponseSetupSubmissionStructureCorporationAnyOfEntitySetupCorporationBeneficialOwnerRelationshipsItemProng;
    submitted_company_title: EntityGetResponseSetupSubmissionStructureCorporationAnyOfEntitySetupCorporationBeneficialOwnerRelationshipsItemSubmittedCompanyTitle;
  };

export type EntityGetResponseSetupSubmissionStructureCorporationAnyOfEntitySetupCorporationBeneficialOwnerRelationshipsItemEntitySetupBeneficialOwnerSubmissionTaxIdValidationStatus =
  (typeof EntityGetResponseSetupSubmissionStructureCorporationAnyOfEntitySetupCorporationBeneficialOwnerRelationshipsItemEntitySetupBeneficialOwnerSubmissionTaxIdValidationStatus)[keyof typeof EntityGetResponseSetupSubmissionStructureCorporationAnyOfEntitySetupCorporationBeneficialOwnerRelationshipsItemEntitySetupBeneficialOwnerSubmissionTaxIdValidationStatus];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const EntityGetResponseSetupSubmissionStructureCorporationAnyOfEntitySetupCorporationBeneficialOwnerRelationshipsItemEntitySetupBeneficialOwnerSubmissionTaxIdValidationStatus =
  {
    pending_validating: "pending_validating",
    update_pending_validating: "update_pending_validating",
    requires_attention: "requires_attention",
    failed: "failed",
    validated: "validated",
    not_provided: "not_provided",
    skipped: "skipped",
  } as const;

export type EntityGetResponseSetupSubmissionStructureCorporationAnyOfEntitySetupCorporationBeneficialOwnerRelationshipsItemEntitySetupBeneficialOwnerSubmissionSubmittedIdentificationNumberLast4 =
  string | null;

export type EntityGetResponseSetupSubmissionStructureCorporationAnyOfEntitySetupCorporationBeneficialOwnerRelationshipsItemEntitySetupBeneficialOwnerSubmissionSubmittedIdentificationMethod =
  (typeof EntityGetResponseSetupSubmissionStructureCorporationAnyOfEntitySetupCorporationBeneficialOwnerRelationshipsItemEntitySetupBeneficialOwnerSubmissionSubmittedIdentificationMethod)[keyof typeof EntityGetResponseSetupSubmissionStructureCorporationAnyOfEntitySetupCorporationBeneficialOwnerRelationshipsItemEntitySetupBeneficialOwnerSubmissionSubmittedIdentificationMethod];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const EntityGetResponseSetupSubmissionStructureCorporationAnyOfEntitySetupCorporationBeneficialOwnerRelationshipsItemEntitySetupBeneficialOwnerSubmissionSubmittedIdentificationMethod =
  {
    social_security_number: "social_security_number",
    individual_taxpayer_identification_number:
      "individual_taxpayer_identification_number",
    passport: "passport",
    drivers_license: "drivers_license",
    other: "other",
  } as const;

export type EntityGetResponseSetupSubmissionStructureCorporationAnyOfEntitySetupCorporationBeneficialOwnerRelationshipsItemEntitySetupBeneficialOwnerSubmissionSubmittedAddressLine2 =
  string | null;

export type EntityGetResponseSetupSubmissionStructureCorporationAnyOfEntitySetupCorporationBeneficialOwnerRelationshipsItemEntitySetupBeneficialOwnerSubmissionSubmittedAddress =
  {
    city: string;
    line1: string;
    line2: EntityGetResponseSetupSubmissionStructureCorporationAnyOfEntitySetupCorporationBeneficialOwnerRelationshipsItemEntitySetupBeneficialOwnerSubmissionSubmittedAddressLine2;
    state: string;
    zip: string;
  };

export type EntityGetResponseSetupSubmissionStructureCorporationAnyOfEntitySetupCorporationBeneficialOwnerRelationshipsItemEntitySetupBeneficialOwnerSubmissionSubmissionSovosResponseId =
  string | null;

export type EntityGetResponseSetupSubmissionStructureCorporationAnyOfEntitySetupCorporationBeneficialOwnerRelationshipsItemEntitySetupBeneficialOwnerSubmissionMicrobiltItemTaxIdType =
  "SSN" | null;

export type EntityGetResponseSetupSubmissionStructureCorporationAnyOfEntitySetupCorporationBeneficialOwnerRelationshipsItemEntitySetupBeneficialOwnerSubmissionMicrobiltItemTaxIdLast4 =
  string | null;

export type EntityGetResponseSetupSubmissionStructureCorporationAnyOfEntitySetupCorporationBeneficialOwnerRelationshipsItemEntitySetupBeneficialOwnerSubmissionMicrobiltItemReturnCode =
  (typeof EntityGetResponseSetupSubmissionStructureCorporationAnyOfEntitySetupCorporationBeneficialOwnerRelationshipsItemEntitySetupBeneficialOwnerSubmissionMicrobiltItemReturnCode)[keyof typeof EntityGetResponseSetupSubmissionStructureCorporationAnyOfEntitySetupCorporationBeneficialOwnerRelationshipsItemEntitySetupBeneficialOwnerSubmissionMicrobiltItemReturnCode];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const EntityGetResponseSetupSubmissionStructureCorporationAnyOfEntitySetupCorporationBeneficialOwnerRelationshipsItemEntitySetupBeneficialOwnerSubmissionMicrobiltItemReturnCode =
  {
    OK: "OK",
  } as const;

export type EntityGetResponseSetupSubmissionStructureCorporationAnyOfEntitySetupCorporationBeneficialOwnerRelationshipsItemEntitySetupBeneficialOwnerSubmissionMicrobiltItemMiddleName =
  string | null;

export type EntityGetResponseSetupSubmissionStructureCorporationAnyOfEntitySetupCorporationBeneficialOwnerRelationshipsItemEntitySetupBeneficialOwnerSubmissionMicrobiltItemDeathDate =
  string | null;

export type EntityGetResponseSetupSubmissionStructureCorporationAnyOfEntitySetupCorporationBeneficialOwnerRelationshipsItemEntitySetupBeneficialOwnerSubmissionMicrobiltItemBirthDate =
  string | null;

export type EntityGetResponseSetupSubmissionStructureCorporationAnyOfEntitySetupCorporationBeneficialOwnerRelationshipsItemEntitySetupBeneficialOwnerSubmissionMicrobiltItem =
  {
    birth_date: EntityGetResponseSetupSubmissionStructureCorporationAnyOfEntitySetupCorporationBeneficialOwnerRelationshipsItemEntitySetupBeneficialOwnerSubmissionMicrobiltItemBirthDate;
    created_at: string;
    death_date: EntityGetResponseSetupSubmissionStructureCorporationAnyOfEntitySetupCorporationBeneficialOwnerRelationshipsItemEntitySetupBeneficialOwnerSubmissionMicrobiltItemDeathDate;
    first_name: string;
    id: string;
    last_name: string;
    middle_name: EntityGetResponseSetupSubmissionStructureCorporationAnyOfEntitySetupCorporationBeneficialOwnerRelationshipsItemEntitySetupBeneficialOwnerSubmissionMicrobiltItemMiddleName;
    request_id: string;
    response: string;
    return_code: EntityGetResponseSetupSubmissionStructureCorporationAnyOfEntitySetupCorporationBeneficialOwnerRelationshipsItemEntitySetupBeneficialOwnerSubmissionMicrobiltItemReturnCode;
    tax_id_last_4: EntityGetResponseSetupSubmissionStructureCorporationAnyOfEntitySetupCorporationBeneficialOwnerRelationshipsItemEntitySetupBeneficialOwnerSubmissionMicrobiltItemTaxIdLast4;
    tax_id_type: EntityGetResponseSetupSubmissionStructureCorporationAnyOfEntitySetupCorporationBeneficialOwnerRelationshipsItemEntitySetupBeneficialOwnerSubmissionMicrobiltItemTaxIdType;
  };

export type EntityGetResponseSetupSubmissionStructureCorporationAnyOfEntitySetupCorporationBeneficialOwnerRelationshipsItemEntitySetupBeneficialOwnerSubmissionIdentityVerificationStatus =
  (typeof EntityGetResponseSetupSubmissionStructureCorporationAnyOfEntitySetupCorporationBeneficialOwnerRelationshipsItemEntitySetupBeneficialOwnerSubmissionIdentityVerificationStatus)[keyof typeof EntityGetResponseSetupSubmissionStructureCorporationAnyOfEntitySetupCorporationBeneficialOwnerRelationshipsItemEntitySetupBeneficialOwnerSubmissionIdentityVerificationStatus];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const EntityGetResponseSetupSubmissionStructureCorporationAnyOfEntitySetupCorporationBeneficialOwnerRelationshipsItemEntitySetupBeneficialOwnerSubmissionIdentityVerificationStatus =
  {
    pending_verification: "pending_verification",
    update_pending_verification: "update_pending_verification",
    verified: "verified",
    falsified: "falsified",
    no_match: "no_match",
    verifying: "verifying",
    requires_attention: "requires_attention",
    not_performed: "not_performed",
    skipped: "skipped",
  } as const;

export type EntityGetResponseSetupSubmissionStructureCorporationAnyOfEntitySetupCorporationBeneficialOwnerRelationshipsItemEntitySetupBeneficialOwnerSubmission =
  {
    created_at: string;
    id: string;
    identity_document_submissions: EntityGetResponseSetupSubmissionStructureCorporationAnyOfEntitySetupCorporationBeneficialOwnerRelationshipsItemEntitySetupBeneficialOwnerSubmissionIdentityDocumentSubmissionsItem[];
    identity_verification_status: EntityGetResponseSetupSubmissionStructureCorporationAnyOfEntitySetupCorporationBeneficialOwnerRelationshipsItemEntitySetupBeneficialOwnerSubmissionIdentityVerificationStatus;
    microbilt: EntityGetResponseSetupSubmissionStructureCorporationAnyOfEntitySetupCorporationBeneficialOwnerRelationshipsItemEntitySetupBeneficialOwnerSubmissionMicrobiltItem[];
    submission_sovos_response_id: EntityGetResponseSetupSubmissionStructureCorporationAnyOfEntitySetupCorporationBeneficialOwnerRelationshipsItemEntitySetupBeneficialOwnerSubmissionSubmissionSovosResponseId;
    submitted_address: EntityGetResponseSetupSubmissionStructureCorporationAnyOfEntitySetupCorporationBeneficialOwnerRelationshipsItemEntitySetupBeneficialOwnerSubmissionSubmittedAddress;
    submitted_date_of_birth: string;
    submitted_identification_method: EntityGetResponseSetupSubmissionStructureCorporationAnyOfEntitySetupCorporationBeneficialOwnerRelationshipsItemEntitySetupBeneficialOwnerSubmissionSubmittedIdentificationMethod;
    submitted_identification_number_last4: EntityGetResponseSetupSubmissionStructureCorporationAnyOfEntitySetupCorporationBeneficialOwnerRelationshipsItemEntitySetupBeneficialOwnerSubmissionSubmittedIdentificationNumberLast4;
    submitted_name: string;
    tax_id_validation_status: EntityGetResponseSetupSubmissionStructureCorporationAnyOfEntitySetupCorporationBeneficialOwnerRelationshipsItemEntitySetupBeneficialOwnerSubmissionTaxIdValidationStatus;
  };

export type EntityGetResponseSetupSubmissionStructureCorporationAnyOfEntitySetupCorporationBeneficialOwnerRelationshipsItemEntitySetupBeneficialOwnerSubmissionIdentityDocumentSubmissionsItemSubmittedState =
  string | null;

export type EntityGetResponseSetupSubmissionStructureCorporationAnyOfEntitySetupCorporationBeneficialOwnerRelationshipsItemEntitySetupBeneficialOwnerSubmissionIdentityDocumentSubmissionsItemSubmittedExpirationDate =
  string | null;

export type EntityGetResponseSetupSubmissionStructureCorporationAnyOfEntitySetupCorporationBeneficialOwnerRelationshipsItemEntitySetupBeneficialOwnerSubmissionIdentityDocumentSubmissionsItemSubmittedDocumentType =
  (typeof EntityGetResponseSetupSubmissionStructureCorporationAnyOfEntitySetupCorporationBeneficialOwnerRelationshipsItemEntitySetupBeneficialOwnerSubmissionIdentityDocumentSubmissionsItemSubmittedDocumentType)[keyof typeof EntityGetResponseSetupSubmissionStructureCorporationAnyOfEntitySetupCorporationBeneficialOwnerRelationshipsItemEntitySetupBeneficialOwnerSubmissionIdentityDocumentSubmissionsItemSubmittedDocumentType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const EntityGetResponseSetupSubmissionStructureCorporationAnyOfEntitySetupCorporationBeneficialOwnerRelationshipsItemEntitySetupBeneficialOwnerSubmissionIdentityDocumentSubmissionsItemSubmittedDocumentType =
  {
    passport: "passport",
    us_drivers_license: "us_drivers_license",
    other: "other",
  } as const;

export type EntityGetResponseSetupSubmissionStructureCorporationAnyOfEntitySetupCorporationBeneficialOwnerRelationshipsItemEntitySetupBeneficialOwnerSubmissionIdentityDocumentSubmissionsItemSubmittedDocumentNumber =
  string | null;

export type EntityGetResponseSetupSubmissionStructureCorporationAnyOfEntitySetupCorporationBeneficialOwnerRelationshipsItemEntitySetupBeneficialOwnerSubmissionIdentityDocumentSubmissionsItemSubmittedAddressLine2 =
  string | null;

export type EntityGetResponseSetupSubmissionStructureCorporationAnyOfEntitySetupCorporationBeneficialOwnerRelationshipsItemEntitySetupBeneficialOwnerSubmissionIdentityDocumentSubmissionsItemSubmittedAddress =
  {
    city: string;
    line1: string;
    line2: EntityGetResponseSetupSubmissionStructureCorporationAnyOfEntitySetupCorporationBeneficialOwnerRelationshipsItemEntitySetupBeneficialOwnerSubmissionIdentityDocumentSubmissionsItemSubmittedAddressLine2;
    state: string;
    zip: string;
  };

export type EntityGetResponseSetupSubmissionStructureCorporationAnyOfEntitySetupCorporationBeneficialOwnerRelationshipsItemEntitySetupBeneficialOwnerSubmissionIdentityDocumentSubmissionsItemIdentityVerificationStatus =
  (typeof EntityGetResponseSetupSubmissionStructureCorporationAnyOfEntitySetupCorporationBeneficialOwnerRelationshipsItemEntitySetupBeneficialOwnerSubmissionIdentityDocumentSubmissionsItemIdentityVerificationStatus)[keyof typeof EntityGetResponseSetupSubmissionStructureCorporationAnyOfEntitySetupCorporationBeneficialOwnerRelationshipsItemEntitySetupBeneficialOwnerSubmissionIdentityDocumentSubmissionsItemIdentityVerificationStatus];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const EntityGetResponseSetupSubmissionStructureCorporationAnyOfEntitySetupCorporationBeneficialOwnerRelationshipsItemEntitySetupBeneficialOwnerSubmissionIdentityDocumentSubmissionsItemIdentityVerificationStatus =
  {
    pending_verification: "pending_verification",
    update_pending_verification: "update_pending_verification",
    verified: "verified",
    falsified: "falsified",
    no_match: "no_match",
    verifying: "verifying",
    requires_attention: "requires_attention",
    not_performed: "not_performed",
    skipped: "skipped",
  } as const;

export type EntityGetResponseSetupSubmissionStructureCorporationAnyOfEntitySetupCorporationBeneficialOwnerRelationshipsItemEntitySetupBeneficialOwnerSubmissionIdentityDocumentSubmissionsItemApiBackFileId =
  string | null;

export type EntityGetResponseSetupSubmissionStructureCorporationAnyOfEntitySetupCorporationBeneficialOwnerRelationshipsItemEntitySetupBeneficialOwnerSubmissionIdentityDocumentSubmissionsItem =
  {
    api_back_file_id: EntityGetResponseSetupSubmissionStructureCorporationAnyOfEntitySetupCorporationBeneficialOwnerRelationshipsItemEntitySetupBeneficialOwnerSubmissionIdentityDocumentSubmissionsItemApiBackFileId;
    api_file_id: string;
    created_at: string;
    group_id: string;
    id: string;
    identity_verification_status: EntityGetResponseSetupSubmissionStructureCorporationAnyOfEntitySetupCorporationBeneficialOwnerRelationshipsItemEntitySetupBeneficialOwnerSubmissionIdentityDocumentSubmissionsItemIdentityVerificationStatus;
    submitted_address: EntityGetResponseSetupSubmissionStructureCorporationAnyOfEntitySetupCorporationBeneficialOwnerRelationshipsItemEntitySetupBeneficialOwnerSubmissionIdentityDocumentSubmissionsItemSubmittedAddress;
    submitted_country: string;
    submitted_date_of_birth: string;
    submitted_document_number: EntityGetResponseSetupSubmissionStructureCorporationAnyOfEntitySetupCorporationBeneficialOwnerRelationshipsItemEntitySetupBeneficialOwnerSubmissionIdentityDocumentSubmissionsItemSubmittedDocumentNumber;
    submitted_document_type: EntityGetResponseSetupSubmissionStructureCorporationAnyOfEntitySetupCorporationBeneficialOwnerRelationshipsItemEntitySetupBeneficialOwnerSubmissionIdentityDocumentSubmissionsItemSubmittedDocumentType;
    submitted_expiration_date: EntityGetResponseSetupSubmissionStructureCorporationAnyOfEntitySetupCorporationBeneficialOwnerRelationshipsItemEntitySetupBeneficialOwnerSubmissionIdentityDocumentSubmissionsItemSubmittedExpirationDate;
    submitted_name: string;
    submitted_state: EntityGetResponseSetupSubmissionStructureCorporationAnyOfEntitySetupCorporationBeneficialOwnerRelationshipsItemEntitySetupBeneficialOwnerSubmissionIdentityDocumentSubmissionsItemSubmittedState;
  };

export type EntityGetResponseSetupSubmissionStructureCorporationAnyOfCobaltIntelligenceSecretaryOfStateSearchIntentsItemSubmittedState =
  string | null;

export type EntityGetResponseSetupSubmissionStructureCorporationAnyOfCobaltIntelligenceSecretaryOfStateSearchIntentsItemStatus =
  (typeof EntityGetResponseSetupSubmissionStructureCorporationAnyOfCobaltIntelligenceSecretaryOfStateSearchIntentsItemStatus)[keyof typeof EntityGetResponseSetupSubmissionStructureCorporationAnyOfCobaltIntelligenceSecretaryOfStateSearchIntentsItemStatus];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const EntityGetResponseSetupSubmissionStructureCorporationAnyOfCobaltIntelligenceSecretaryOfStateSearchIntentsItemStatus =
  {
    pending: "pending",
    searching: "searching",
    retrying: "retrying",
    complete: "complete",
    failed: "failed",
    requires_attention: "requires_attention",
    skipped: "skipped",
  } as const;

export type EntityGetResponseSetupSubmissionStructureCorporationAnyOfCobaltIntelligenceSecretaryOfStateSearchIntentsItemSearchStrategy =
  (typeof EntityGetResponseSetupSubmissionStructureCorporationAnyOfCobaltIntelligenceSecretaryOfStateSearchIntentsItemSearchStrategy)[keyof typeof EntityGetResponseSetupSubmissionStructureCorporationAnyOfCobaltIntelligenceSecretaryOfStateSearchIntentsItemSearchStrategy];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const EntityGetResponseSetupSubmissionStructureCorporationAnyOfCobaltIntelligenceSecretaryOfStateSearchIntentsItemSearchStrategy =
  {
    incorporation_state: "incorporation_state",
    address_state: "address_state",
  } as const;

export type EntityGetResponseSetupSubmissionStructureCorporationAnyOfCobaltIntelligenceSecretaryOfStateSearchIntentsItem =
  {
    created_at: string;
    entity_id: string;
    id: string;
    outcome: EntityGetResponseSetupSubmissionStructureCorporationAnyOfCobaltIntelligenceSecretaryOfStateSearchIntentsItemOutcome;
    search_response: EntityGetResponseSetupSubmissionStructureCorporationAnyOfCobaltIntelligenceSecretaryOfStateSearchIntentsItemSearchResponse;
    search_strategy: EntityGetResponseSetupSubmissionStructureCorporationAnyOfCobaltIntelligenceSecretaryOfStateSearchIntentsItemSearchStrategy;
    searchable_submission_class: string;
    searchable_submission_id: string;
    status: EntityGetResponseSetupSubmissionStructureCorporationAnyOfCobaltIntelligenceSecretaryOfStateSearchIntentsItemStatus;
    submitted_address_state: string;
    submitted_name: string;
    submitted_state: EntityGetResponseSetupSubmissionStructureCorporationAnyOfCobaltIntelligenceSecretaryOfStateSearchIntentsItemSubmittedState;
  };

export type EntityGetResponseSetupSubmissionStructureCorporationAnyOfCobaltIntelligenceSecretaryOfStateSearchIntentsItemSearchResponseAnyOf =
  {
    id: string;
    response_body: string;
  };

export type EntityGetResponseSetupSubmissionStructureCorporationAnyOfCobaltIntelligenceSecretaryOfStateSearchIntentsItemSearchResponse =
  EntityGetResponseSetupSubmissionStructureCorporationAnyOfCobaltIntelligenceSecretaryOfStateSearchIntentsItemSearchResponseAnyOf | null;

export type EntityGetResponseSetupSubmissionStructureCorporationAnyOfCobaltIntelligenceSecretaryOfStateSearchIntentsItemOutcome =
  "found" | "not_found" | null;

export type EntityGetResponseSetupSubmissionStructureCorporationAnyOfBeneficialOwnershipExemption =
  "regulated_financial_institution" | "publicly_traded_company" | null;

export type EntityGetResponseReviewStatus =
  (typeof EntityGetResponseReviewStatus)[keyof typeof EntityGetResponseReviewStatus];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const EntityGetResponseReviewStatus = {
  pending_reviewing: "pending_reviewing",
  review_not_required: "review_not_required",
  approved: "approved",
  approved_by_platform: "approved_by_platform",
  returned_to_user: "returned_to_user",
  requires_attention: "requires_attention",
} as const;

export type EntityGetResponseCustomerInformationPolicyProblemsItem =
  (typeof EntityGetResponseCustomerInformationPolicyProblemsItem)[keyof typeof EntityGetResponseCustomerInformationPolicyProblemsItem];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const EntityGetResponseCustomerInformationPolicyProblemsItem = {
  details_need_initial_confirmation: "details_need_initial_confirmation",
  details_need_reconfirmation: "details_need_reconfirmation",
  missing_controller: "missing_controller",
  tax_id_not_validated: "tax_id_not_validated",
  identity_not_verified: "identity_not_verified",
  full_tax_id_required_unless_non_us_citizen:
    "full_tax_id_required_unless_non_us_citizen",
  physical_address_not_valid: "physical_address_not_valid",
  missing_grantor: "missing_grantor",
} as const;

export type EntityGetResponseAutomatedCustomerIdentificationProgramEvaluation =
  (typeof EntityGetResponseAutomatedCustomerIdentificationProgramEvaluation)[keyof typeof EntityGetResponseAutomatedCustomerIdentificationProgramEvaluation];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const EntityGetResponseAutomatedCustomerIdentificationProgramEvaluation =
  {
    valid: "valid",
    indeterminate: "indeterminate",
    invalid: "invalid",
  } as const;

export type EntityGetResponseApprovedBy = string | null;

export type EntityGetResponseApprovedAt = string | null;

export type EntityClusterReviewPostResponseStatus =
  (typeof EntityClusterReviewPostResponseStatus)[keyof typeof EntityClusterReviewPostResponseStatus];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const EntityClusterReviewPostResponseStatus = {
  reviewed: "reviewed",
  pending_reviewing: "pending_reviewing",
} as const;

export type EntityClusterReviewPostResponseReviewedByEmail = string | null;

export type EntityClusterReviewPostResponseEntitiesItemStatus =
  (typeof EntityClusterReviewPostResponseEntitiesItemStatus)[keyof typeof EntityClusterReviewPostResponseEntitiesItemStatus];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const EntityClusterReviewPostResponseEntitiesItemStatus = {
  active: "active",
  archived: "archived",
  disabled: "disabled",
} as const;

export type EntityClusterReviewPostResponseEntitiesItemGroupStatus =
  (typeof EntityClusterReviewPostResponseEntitiesItemGroupStatus)[keyof typeof EntityClusterReviewPostResponseEntitiesItemGroupStatus];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const EntityClusterReviewPostResponseEntitiesItemGroupStatus = {
  pending_activating: "pending_activating",
  active: "active",
  rejected: "rejected",
  offboarded: "offboarded",
} as const;

export type EntityClusterReviewPostResponseEntitiesItem = {
  created_at: string;
  group_id: string;
  group_status: EntityClusterReviewPostResponseEntitiesItemGroupStatus;
  id: string;
  name: string;
  status: EntityClusterReviewPostResponseEntitiesItemStatus;
};

export type EntityClusterReviewPostResponseCategory =
  (typeof EntityClusterReviewPostResponseCategory)[keyof typeof EntityClusterReviewPostResponseCategory];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const EntityClusterReviewPostResponseCategory = {
  tax_id_match: "tax_id_match",
  address_match: "address_match",
  transaction_match: "transaction_match",
  transaction_with_known_bad: "transaction_with_known_bad",
} as const;

export interface EntityClusterReviewPostResponse {
  category: EntityClusterReviewPostResponseCategory;
  created_at: string;
  entities: EntityClusterReviewPostResponseEntitiesItem[];
  id: string;
  matching_data: string;
  reviewed_by_email: EntityClusterReviewPostResponseReviewedByEmail;
  status: EntityClusterReviewPostResponseStatus;
}

export interface EntityClusterReviewPostParameters {
  message?: string;
}

export type EntityClusterListResponseNextCursor = string | null;

export type EntityClusterListResponseDataItemStatus =
  (typeof EntityClusterListResponseDataItemStatus)[keyof typeof EntityClusterListResponseDataItemStatus];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const EntityClusterListResponseDataItemStatus = {
  reviewed: "reviewed",
  pending_reviewing: "pending_reviewing",
} as const;

export type EntityClusterListResponseDataItemReviewedByEmail = string | null;

export type EntityClusterListResponseDataItem = {
  category: EntityClusterListResponseDataItemCategory;
  created_at: string;
  entities: EntityClusterListResponseDataItemEntitiesItem[];
  id: string;
  matching_data: string;
  reviewed_by_email: EntityClusterListResponseDataItemReviewedByEmail;
  status: EntityClusterListResponseDataItemStatus;
};

export interface EntityClusterListResponse {
  data: EntityClusterListResponseDataItem[];
  next_cursor: EntityClusterListResponseNextCursor;
}

export type EntityClusterListResponseDataItemEntitiesItemStatus =
  (typeof EntityClusterListResponseDataItemEntitiesItemStatus)[keyof typeof EntityClusterListResponseDataItemEntitiesItemStatus];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const EntityClusterListResponseDataItemEntitiesItemStatus = {
  active: "active",
  archived: "archived",
  disabled: "disabled",
} as const;

export type EntityClusterListResponseDataItemEntitiesItemGroupStatus =
  (typeof EntityClusterListResponseDataItemEntitiesItemGroupStatus)[keyof typeof EntityClusterListResponseDataItemEntitiesItemGroupStatus];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const EntityClusterListResponseDataItemEntitiesItemGroupStatus = {
  pending_activating: "pending_activating",
  active: "active",
  rejected: "rejected",
  offboarded: "offboarded",
} as const;

export type EntityClusterListResponseDataItemEntitiesItem = {
  created_at: string;
  group_id: string;
  group_status: EntityClusterListResponseDataItemEntitiesItemGroupStatus;
  id: string;
  name: string;
  status: EntityClusterListResponseDataItemEntitiesItemStatus;
};

export type EntityClusterListResponseDataItemCategory =
  (typeof EntityClusterListResponseDataItemCategory)[keyof typeof EntityClusterListResponseDataItemCategory];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const EntityClusterListResponseDataItemCategory = {
  tax_id_match: "tax_id_match",
  address_match: "address_match",
  transaction_match: "transaction_match",
  transaction_with_known_bad: "transaction_with_known_bad",
} as const;

export type EntityClusterGetResponseStatus =
  (typeof EntityClusterGetResponseStatus)[keyof typeof EntityClusterGetResponseStatus];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const EntityClusterGetResponseStatus = {
  reviewed: "reviewed",
  pending_reviewing: "pending_reviewing",
} as const;

export type EntityClusterGetResponseReviewedByEmail = string | null;

export type EntityClusterGetResponseEntitiesItemStatus =
  (typeof EntityClusterGetResponseEntitiesItemStatus)[keyof typeof EntityClusterGetResponseEntitiesItemStatus];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const EntityClusterGetResponseEntitiesItemStatus = {
  active: "active",
  archived: "archived",
  disabled: "disabled",
} as const;

export type EntityClusterGetResponseEntitiesItemGroupStatus =
  (typeof EntityClusterGetResponseEntitiesItemGroupStatus)[keyof typeof EntityClusterGetResponseEntitiesItemGroupStatus];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const EntityClusterGetResponseEntitiesItemGroupStatus = {
  pending_activating: "pending_activating",
  active: "active",
  rejected: "rejected",
  offboarded: "offboarded",
} as const;

export type EntityClusterGetResponseEntitiesItem = {
  created_at: string;
  group_id: string;
  group_status: EntityClusterGetResponseEntitiesItemGroupStatus;
  id: string;
  name: string;
  status: EntityClusterGetResponseEntitiesItemStatus;
};

export type EntityClusterGetResponseCategory =
  (typeof EntityClusterGetResponseCategory)[keyof typeof EntityClusterGetResponseCategory];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const EntityClusterGetResponseCategory = {
  tax_id_match: "tax_id_match",
  address_match: "address_match",
  transaction_match: "transaction_match",
  transaction_with_known_bad: "transaction_with_known_bad",
} as const;

export interface EntityClusterGetResponse {
  category: EntityClusterGetResponseCategory;
  created_at: string;
  entities: EntityClusterGetResponseEntitiesItem[];
  id: string;
  matching_data: string;
  reviewed_by_email: EntityClusterGetResponseReviewedByEmail;
  status: EntityClusterGetResponseStatus;
}

export type EntityByNameListResponseNextCursor = string | null;

export interface EntityByNameListResponse {
  data: EntityByNameListResponseDataItem[];
  next_cursor: EntityByNameListResponseNextCursor;
}

export type EntityByNameListResponseDataItemStructure =
  (typeof EntityByNameListResponseDataItemStructure)[keyof typeof EntityByNameListResponseDataItemStructure];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const EntityByNameListResponseDataItemStructure = {
  corporation: "corporation",
  natural_person: "natural_person",
  joint: "joint",
  trust: "trust",
  government_authority: "government_authority",
} as const;

export type EntityByNameListResponseDataItemStatus =
  (typeof EntityByNameListResponseDataItemStatus)[keyof typeof EntityByNameListResponseDataItemStatus];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const EntityByNameListResponseDataItemStatus = {
  active: "active",
  archived: "archived",
  disabled: "disabled",
} as const;

export type EntityByNameListResponseDataItemReviewStatus =
  (typeof EntityByNameListResponseDataItemReviewStatus)[keyof typeof EntityByNameListResponseDataItemReviewStatus];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const EntityByNameListResponseDataItemReviewStatus = {
  pending_reviewing: "pending_reviewing",
  review_not_required: "review_not_required",
  approved: "approved",
  approved_by_platform: "approved_by_platform",
  returned_to_user: "returned_to_user",
  requires_attention: "requires_attention",
} as const;

export type EntityByNameListResponseDataItem = {
  created_at: string;
  group_id: string;
  id: string;
  name: string;
  review_status: EntityByNameListResponseDataItemReviewStatus;
  status: EntityByNameListResponseDataItemStatus;
  structure: EntityByNameListResponseDataItemStructure;
};

export type EntityAddressVerificationResultListResponseNextCursor =
  | string
  | null;

export interface EntityAddressVerificationResultListResponse {
  data: EntityAddressVerificationResultListResponseDataItem[];
  next_cursor: EntityAddressVerificationResultListResponseNextCursor;
}

export type EntityAddressVerificationResultListResponseDataItemRecordType =
  | "street"
  | "highrise"
  | "firm"
  | "po_box"
  | "rural_route"
  | "general_delivery"
  | null;

export type EntityAddressVerificationResultListResponseDataItemPoBoxOnlyFlag =
  | boolean
  | null;

export type EntityAddressVerificationResultListResponseDataItemOutcome =
  (typeof EntityAddressVerificationResultListResponseDataItemOutcome)[keyof typeof EntityAddressVerificationResultListResponseDataItemOutcome];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const EntityAddressVerificationResultListResponseDataItemOutcome = {
  valid_address: "valid_address",
  valid_address_manual_override: "valid_address_manual_override",
  invalid_address: "invalid_address",
  mailbox_address: "mailbox_address",
  registered_agent_address: "registered_agent_address",
} as const;

export type EntityAddressVerificationResultListResponseDataItemOriginalZip =
  | string
  | null;

export type EntityAddressVerificationResultListResponseDataItemOriginalState =
  | string
  | null;

export type EntityAddressVerificationResultListResponseDataItemOriginalLine2 =
  | string
  | null;

export type EntityAddressVerificationResultListResponseDataItemOriginalLine1 =
  | string
  | null;

export type EntityAddressVerificationResultListResponseDataItemOriginalCity =
  | string
  | null;

export type EntityAddressVerificationResultListResponseDataItemLongitude =
  | string
  | null;

export type EntityAddressVerificationResultListResponseDataItemLatitude =
  | string
  | null;

export type EntityAddressVerificationResultListResponseDataItemFootnotesAnyOfItemCode =
  (typeof EntityAddressVerificationResultListResponseDataItemFootnotesAnyOfItemCode)[keyof typeof EntityAddressVerificationResultListResponseDataItemFootnotesAnyOfItemCode];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const EntityAddressVerificationResultListResponseDataItemFootnotesAnyOfItemCode =
  {
    AA: "AA",
    A1: "A1",
    BB: "BB",
    CC: "CC",
    TA: "TA",
    IA: "IA",
    N1: "N1",
    F1: "F1",
    G1: "G1",
    U1: "U1",
    C1: "C1",
    M1: "M1",
    M3: "M3",
    P1: "P1",
    P3: "P3",
    PB: "PB",
    R1: "R1",
    R7: "R7",
    RR: "RR",
  } as const;

export type EntityAddressVerificationResultListResponseDataItemFootnotesAnyOfItem =
  {
    code: EntityAddressVerificationResultListResponseDataItemFootnotesAnyOfItemCode;
    description: string;
    name: string;
    positive_sentiment: boolean;
  };

export type EntityAddressVerificationResultListResponseDataItemFootnotes =
  | EntityAddressVerificationResultListResponseDataItemFootnotesAnyOfItem[]
  | null;

export type EntityAddressVerificationResultListResponseDataItemDefaultBuildingAddress =
  boolean | null;

export type EntityAddressVerificationResultListResponseDataItemCountyFips =
  | string
  | null;

export type EntityAddressVerificationResultListResponseDataItemCounty =
  | string
  | null;

export type EntityAddressVerificationResultListResponseDataItemCleanZip =
  | string
  | null;

export type EntityAddressVerificationResultListResponseDataItemCleanState =
  | string
  | null;

export type EntityAddressVerificationResultListResponseDataItemCleanLine2 =
  | string
  | null;

export type EntityAddressVerificationResultListResponseDataItemCleanLine1 =
  | string
  | null;

export type EntityAddressVerificationResultListResponseDataItemCleanCity =
  | string
  | null;

export type EntityAddressVerificationResultListResponseDataItemCarrierRouteType =

    | "city_delivery"
    | "rural_route"
    | "highway_contract"
    | "po_box"
    | "general_delivery"
    | null;

export type EntityAddressVerificationResultListResponseDataItemCarrierRoute =
  | string
  | null;

export type EntityAddressVerificationResultListResponseDataItemAddressType =
  | "residential"
  | "commercial"
  | "unknown"
  | null;

export type EntityAddressVerificationResultListResponseDataItem = {
  address_type: EntityAddressVerificationResultListResponseDataItemAddressType;
  carrier_route: EntityAddressVerificationResultListResponseDataItemCarrierRoute;
  carrier_route_type: EntityAddressVerificationResultListResponseDataItemCarrierRouteType;
  clean_city: EntityAddressVerificationResultListResponseDataItemCleanCity;
  clean_line1: EntityAddressVerificationResultListResponseDataItemCleanLine1;
  clean_line2: EntityAddressVerificationResultListResponseDataItemCleanLine2;
  clean_state: EntityAddressVerificationResultListResponseDataItemCleanState;
  clean_zip: EntityAddressVerificationResultListResponseDataItemCleanZip;
  county: EntityAddressVerificationResultListResponseDataItemCounty;
  county_fips: EntityAddressVerificationResultListResponseDataItemCountyFips;
  created_at: string;
  default_building_address: EntityAddressVerificationResultListResponseDataItemDefaultBuildingAddress;
  footnotes: EntityAddressVerificationResultListResponseDataItemFootnotes;
  id: string;
  latitude: EntityAddressVerificationResultListResponseDataItemLatitude;
  longitude: EntityAddressVerificationResultListResponseDataItemLongitude;
  original_city: EntityAddressVerificationResultListResponseDataItemOriginalCity;
  original_line1: EntityAddressVerificationResultListResponseDataItemOriginalLine1;
  original_line2: EntityAddressVerificationResultListResponseDataItemOriginalLine2;
  original_state: EntityAddressVerificationResultListResponseDataItemOriginalState;
  original_zip: EntityAddressVerificationResultListResponseDataItemOriginalZip;
  outcome: EntityAddressVerificationResultListResponseDataItemOutcome;
  po_box_only_flag: EntityAddressVerificationResultListResponseDataItemPoBoxOnlyFlag;
  record_id: string;
  record_type: EntityAddressVerificationResultListResponseDataItemRecordType;
};

export type EntityAddressVerificationResultGetResponseRecordType =
  | "street"
  | "highrise"
  | "firm"
  | "po_box"
  | "rural_route"
  | "general_delivery"
  | null;

export type EntityAddressVerificationResultGetResponsePoBoxOnlyFlag =
  | boolean
  | null;

export type EntityAddressVerificationResultGetResponseOutcome =
  (typeof EntityAddressVerificationResultGetResponseOutcome)[keyof typeof EntityAddressVerificationResultGetResponseOutcome];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const EntityAddressVerificationResultGetResponseOutcome = {
  valid_address: "valid_address",
  valid_address_manual_override: "valid_address_manual_override",
  invalid_address: "invalid_address",
  mailbox_address: "mailbox_address",
  registered_agent_address: "registered_agent_address",
} as const;

export type EntityAddressVerificationResultGetResponseOriginalZip =
  | string
  | null;

export type EntityAddressVerificationResultGetResponseOriginalState =
  | string
  | null;

export type EntityAddressVerificationResultGetResponseOriginalLine2 =
  | string
  | null;

export type EntityAddressVerificationResultGetResponseOriginalLine1 =
  | string
  | null;

export type EntityAddressVerificationResultGetResponseOriginalCity =
  | string
  | null;

export type EntityAddressVerificationResultGetResponseLongitude = string | null;

export type EntityAddressVerificationResultGetResponseLatitude = string | null;

export type EntityAddressVerificationResultGetResponseFootnotesAnyOfItemCode =
  (typeof EntityAddressVerificationResultGetResponseFootnotesAnyOfItemCode)[keyof typeof EntityAddressVerificationResultGetResponseFootnotesAnyOfItemCode];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const EntityAddressVerificationResultGetResponseFootnotesAnyOfItemCode =
  {
    AA: "AA",
    A1: "A1",
    BB: "BB",
    CC: "CC",
    TA: "TA",
    IA: "IA",
    N1: "N1",
    F1: "F1",
    G1: "G1",
    U1: "U1",
    C1: "C1",
    M1: "M1",
    M3: "M3",
    P1: "P1",
    P3: "P3",
    PB: "PB",
    R1: "R1",
    R7: "R7",
    RR: "RR",
  } as const;

export type EntityAddressVerificationResultGetResponseFootnotesAnyOfItem = {
  code: EntityAddressVerificationResultGetResponseFootnotesAnyOfItemCode;
  description: string;
  name: string;
  positive_sentiment: boolean;
};

export type EntityAddressVerificationResultGetResponseFootnotes =
  | EntityAddressVerificationResultGetResponseFootnotesAnyOfItem[]
  | null;

export type EntityAddressVerificationResultGetResponseDefaultBuildingAddress =
  | boolean
  | null;

export type EntityAddressVerificationResultGetResponseCountyFips =
  | string
  | null;

export type EntityAddressVerificationResultGetResponseCounty = string | null;

export type EntityAddressVerificationResultGetResponseCleanZip = string | null;

export type EntityAddressVerificationResultGetResponseCleanState =
  | string
  | null;

export type EntityAddressVerificationResultGetResponseCleanLine2 =
  | string
  | null;

export type EntityAddressVerificationResultGetResponseCleanLine1 =
  | string
  | null;

export type EntityAddressVerificationResultGetResponseCleanCity = string | null;

export type EntityAddressVerificationResultGetResponseCarrierRouteType =
  | "city_delivery"
  | "rural_route"
  | "highway_contract"
  | "po_box"
  | "general_delivery"
  | null;

export type EntityAddressVerificationResultGetResponseCarrierRoute =
  | string
  | null;

export type EntityAddressVerificationResultGetResponseAddressType =
  | "residential"
  | "commercial"
  | "unknown"
  | null;

export interface EntityAddressVerificationResultGetResponse {
  address_type: EntityAddressVerificationResultGetResponseAddressType;
  carrier_route: EntityAddressVerificationResultGetResponseCarrierRoute;
  carrier_route_type: EntityAddressVerificationResultGetResponseCarrierRouteType;
  clean_city: EntityAddressVerificationResultGetResponseCleanCity;
  clean_line1: EntityAddressVerificationResultGetResponseCleanLine1;
  clean_line2: EntityAddressVerificationResultGetResponseCleanLine2;
  clean_state: EntityAddressVerificationResultGetResponseCleanState;
  clean_zip: EntityAddressVerificationResultGetResponseCleanZip;
  county: EntityAddressVerificationResultGetResponseCounty;
  county_fips: EntityAddressVerificationResultGetResponseCountyFips;
  created_at: string;
  default_building_address: EntityAddressVerificationResultGetResponseDefaultBuildingAddress;
  footnotes: EntityAddressVerificationResultGetResponseFootnotes;
  id: string;
  latitude: EntityAddressVerificationResultGetResponseLatitude;
  longitude: EntityAddressVerificationResultGetResponseLongitude;
  original_city: EntityAddressVerificationResultGetResponseOriginalCity;
  original_line1: EntityAddressVerificationResultGetResponseOriginalLine1;
  original_line2: EntityAddressVerificationResultGetResponseOriginalLine2;
  original_state: EntityAddressVerificationResultGetResponseOriginalState;
  original_zip: EntityAddressVerificationResultGetResponseOriginalZip;
  outcome: EntityAddressVerificationResultGetResponseOutcome;
  po_box_only_flag: EntityAddressVerificationResultGetResponsePoBoxOnlyFlag;
  record_id: string;
  record_type: EntityAddressVerificationResultGetResponseRecordType;
}

export type DocumentRequestSchedulePostResponseFirstRunOn = string | null;

export type DocumentRequestSchedulePostResponseCategory =
  (typeof DocumentRequestSchedulePostResponseCategory)[keyof typeof DocumentRequestSchedulePostResponseCategory];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const DocumentRequestSchedulePostResponseCategory = {
  consumer_data: "consumer_data",
  credit_or_lending: "credit_or_lending",
  financial_report: "financial_report",
  funds_availability: "funds_availability",
  marketing: "marketing",
  platform_compliance_metrics: "platform_compliance_metrics",
  risk_and_underwriting: "risk_and_underwriting",
  terms: "terms",
  transaction_monitoring: "transaction_monitoring",
  compliance_materials: "compliance_materials",
  technology_information_security: "technology_information_security",
} as const;

export type DocumentRequestSchedulePostResponseCadence =
  (typeof DocumentRequestSchedulePostResponseCadence)[keyof typeof DocumentRequestSchedulePostResponseCadence];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const DocumentRequestSchedulePostResponseCadence = {
  monthly: "monthly",
  quarterly: "quarterly",
  never: "never",
} as const;

export interface DocumentRequestSchedulePostResponse {
  cadence: DocumentRequestSchedulePostResponseCadence;
  category: DocumentRequestSchedulePostResponseCategory;
  first_run_on: DocumentRequestSchedulePostResponseFirstRunOn;
  id: string;
  program_id: string;
}

export type DocumentRequestSchedulePostParametersCategory =
  (typeof DocumentRequestSchedulePostParametersCategory)[keyof typeof DocumentRequestSchedulePostParametersCategory];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const DocumentRequestSchedulePostParametersCategory = {
  consumer_data: "consumer_data",
  credit_or_lending: "credit_or_lending",
  financial_report: "financial_report",
  funds_availability: "funds_availability",
  marketing: "marketing",
  platform_compliance_metrics: "platform_compliance_metrics",
  risk_and_underwriting: "risk_and_underwriting",
  terms: "terms",
  transaction_monitoring: "transaction_monitoring",
  compliance_materials: "compliance_materials",
  technology_information_security: "technology_information_security",
} as const;

export type DocumentRequestSchedulePostParametersCadence =
  (typeof DocumentRequestSchedulePostParametersCadence)[keyof typeof DocumentRequestSchedulePostParametersCadence];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const DocumentRequestSchedulePostParametersCadence = {
  monthly: "monthly",
  quarterly: "quarterly",
  never: "never",
} as const;

export interface DocumentRequestSchedulePostParameters {
  cadence: DocumentRequestSchedulePostParametersCadence;
  category: DocumentRequestSchedulePostParametersCategory;
  first_run_on?: string;
  program_id: string;
}

export type DocumentRequestScheduleListResponseNextCursor = string | null;

export interface DocumentRequestScheduleListResponse {
  data: DocumentRequestScheduleListResponseDataItem[];
  next_cursor: DocumentRequestScheduleListResponseNextCursor;
}

export type DocumentRequestScheduleListResponseDataItemFirstRunOn =
  | string
  | null;

export type DocumentRequestScheduleListResponseDataItemCategory =
  (typeof DocumentRequestScheduleListResponseDataItemCategory)[keyof typeof DocumentRequestScheduleListResponseDataItemCategory];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const DocumentRequestScheduleListResponseDataItemCategory = {
  consumer_data: "consumer_data",
  credit_or_lending: "credit_or_lending",
  financial_report: "financial_report",
  funds_availability: "funds_availability",
  marketing: "marketing",
  platform_compliance_metrics: "platform_compliance_metrics",
  risk_and_underwriting: "risk_and_underwriting",
  terms: "terms",
  transaction_monitoring: "transaction_monitoring",
  compliance_materials: "compliance_materials",
  technology_information_security: "technology_information_security",
} as const;

export type DocumentRequestScheduleListResponseDataItemCadence =
  (typeof DocumentRequestScheduleListResponseDataItemCadence)[keyof typeof DocumentRequestScheduleListResponseDataItemCadence];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const DocumentRequestScheduleListResponseDataItemCadence = {
  monthly: "monthly",
  quarterly: "quarterly",
  never: "never",
} as const;

export type DocumentRequestScheduleListResponseDataItem = {
  cadence: DocumentRequestScheduleListResponseDataItemCadence;
  category: DocumentRequestScheduleListResponseDataItemCategory;
  first_run_on: DocumentRequestScheduleListResponseDataItemFirstRunOn;
  id: string;
  program_id: string;
};

export type DocumentRequestListResponseNextCursor = string | null;

export interface DocumentRequestListResponse {
  data: DocumentRequestListResponseDataItem[];
  next_cursor: DocumentRequestListResponseNextCursor;
}

export type DocumentRequestListResponseDataItemStatus =
  (typeof DocumentRequestListResponseDataItemStatus)[keyof typeof DocumentRequestListResponseDataItemStatus];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const DocumentRequestListResponseDataItemStatus = {
  awaiting_submission: "awaiting_submission",
  awaiting_review: "awaiting_review",
  provisionally_accepted: "provisionally_accepted",
  accepted: "accepted",
  canceled: "canceled",
} as const;

export type DocumentRequestListResponseDataItemReviewsItemReviewerEmail =
  | string
  | null;

export type DocumentRequestListResponseDataItemReviewsItemResult =
  (typeof DocumentRequestListResponseDataItemReviewsItemResult)[keyof typeof DocumentRequestListResponseDataItemReviewsItemResult];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const DocumentRequestListResponseDataItemReviewsItemResult = {
  accepted: "accepted",
  rejected: "rejected",
  provisionally_accepted: "provisionally_accepted",
} as const;

export type DocumentRequestListResponseDataItemReviewsItemComment =
  | string
  | null;

export type DocumentRequestListResponseDataItemReviewsItem = {
  comment: DocumentRequestListResponseDataItemReviewsItemComment;
  created_at: string;
  id: string;
  result: DocumentRequestListResponseDataItemReviewsItemResult;
  reviewer_email: DocumentRequestListResponseDataItemReviewsItemReviewerEmail;
};

export type DocumentRequestListResponseDataItem = {
  created_at: string;
  description: string;
  document_submissions: DocumentRequestListResponseDataItemDocumentSubmissionsItem[];
  group_id: string;
  group_name: DocumentRequestListResponseDataItemGroupName;
  id: string;
  nondocument_submissions: DocumentRequestListResponseDataItemNondocumentSubmissions;
  program_id: string;
  program_name: string;
  reviews: DocumentRequestListResponseDataItemReviewsItem[];
  source: unknown;
  status: DocumentRequestListResponseDataItemStatus;
};

export type DocumentRequestListResponseDataItemNondocumentSubmissionsProofOfAuthorizationRequestIds =
  string[] | null;

export type DocumentRequestListResponseDataItemNondocumentSubmissionsPlatformVendorListSubmissionId =
  string | null;

export type DocumentRequestListResponseDataItemNondocumentSubmissionsPlatformFinancialReportSubmissionId =
  string | null;

export type DocumentRequestListResponseDataItemNondocumentSubmissionsPlatformComplianceMetricsSubmissionId =
  string | null;

export type DocumentRequestListResponseDataItemNondocumentSubmissionsPlatformComplaintListSubmissionId =
  string | null;

export type DocumentRequestListResponseDataItemNondocumentSubmissionsDirectUserDiligenceSubmissionId =
  string | null;

export type DocumentRequestListResponseDataItemNondocumentSubmissions = {
  direct_user_diligence_submission_id: DocumentRequestListResponseDataItemNondocumentSubmissionsDirectUserDiligenceSubmissionId;
  platform_complaint_list_submission_id: DocumentRequestListResponseDataItemNondocumentSubmissionsPlatformComplaintListSubmissionId;
  platform_compliance_metrics_submission_id: DocumentRequestListResponseDataItemNondocumentSubmissionsPlatformComplianceMetricsSubmissionId;
  platform_financial_report_submission_id: DocumentRequestListResponseDataItemNondocumentSubmissionsPlatformFinancialReportSubmissionId;
  platform_vendor_list_submission_id: DocumentRequestListResponseDataItemNondocumentSubmissionsPlatformVendorListSubmissionId;
  proof_of_authorization_request_ids: DocumentRequestListResponseDataItemNondocumentSubmissionsProofOfAuthorizationRequestIds;
};

export type DocumentRequestListResponseDataItemGroupName = string | null;

export type DocumentRequestListResponseDataItemDocumentSubmissionsItemCreatedByEmail =
  string | null;

export type DocumentRequestListResponseDataItemDocumentSubmissionsItem = {
  created_at: string;
  created_by_email: DocumentRequestListResponseDataItemDocumentSubmissionsItemCreatedByEmail;
  file_id: string;
  file_name: string;
  id: string;
};

export type DocumentRequestGetResponseStatus =
  (typeof DocumentRequestGetResponseStatus)[keyof typeof DocumentRequestGetResponseStatus];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const DocumentRequestGetResponseStatus = {
  awaiting_submission: "awaiting_submission",
  awaiting_review: "awaiting_review",
  provisionally_accepted: "provisionally_accepted",
  accepted: "accepted",
  canceled: "canceled",
} as const;

export interface DocumentRequestGetResponse {
  created_at: string;
  description: string;
  document_submissions: DocumentRequestGetResponseDocumentSubmissionsItem[];
  group_id: string;
  group_name: DocumentRequestGetResponseGroupName;
  id: string;
  nondocument_submissions: DocumentRequestGetResponseNondocumentSubmissions;
  program_id: string;
  program_name: string;
  reviews: DocumentRequestGetResponseReviewsItem[];
  source: unknown;
  status: DocumentRequestGetResponseStatus;
}

export type DocumentRequestGetResponseReviewsItemReviewerEmail = string | null;

export type DocumentRequestGetResponseReviewsItemResult =
  (typeof DocumentRequestGetResponseReviewsItemResult)[keyof typeof DocumentRequestGetResponseReviewsItemResult];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const DocumentRequestGetResponseReviewsItemResult = {
  accepted: "accepted",
  rejected: "rejected",
  provisionally_accepted: "provisionally_accepted",
} as const;

export type DocumentRequestGetResponseReviewsItemComment = string | null;

export type DocumentRequestGetResponseReviewsItem = {
  comment: DocumentRequestGetResponseReviewsItemComment;
  created_at: string;
  id: string;
  result: DocumentRequestGetResponseReviewsItemResult;
  reviewer_email: DocumentRequestGetResponseReviewsItemReviewerEmail;
};

export type DocumentRequestGetResponseNondocumentSubmissionsProofOfAuthorizationRequestIds =
  string[] | null;

export type DocumentRequestGetResponseNondocumentSubmissionsPlatformVendorListSubmissionId =
  string | null;

export type DocumentRequestGetResponseNondocumentSubmissionsPlatformFinancialReportSubmissionId =
  string | null;

export type DocumentRequestGetResponseNondocumentSubmissionsPlatformComplianceMetricsSubmissionId =
  string | null;

export type DocumentRequestGetResponseNondocumentSubmissionsPlatformComplaintListSubmissionId =
  string | null;

export type DocumentRequestGetResponseNondocumentSubmissionsDirectUserDiligenceSubmissionId =
  string | null;

export type DocumentRequestGetResponseNondocumentSubmissions = {
  direct_user_diligence_submission_id: DocumentRequestGetResponseNondocumentSubmissionsDirectUserDiligenceSubmissionId;
  platform_complaint_list_submission_id: DocumentRequestGetResponseNondocumentSubmissionsPlatformComplaintListSubmissionId;
  platform_compliance_metrics_submission_id: DocumentRequestGetResponseNondocumentSubmissionsPlatformComplianceMetricsSubmissionId;
  platform_financial_report_submission_id: DocumentRequestGetResponseNondocumentSubmissionsPlatformFinancialReportSubmissionId;
  platform_vendor_list_submission_id: DocumentRequestGetResponseNondocumentSubmissionsPlatformVendorListSubmissionId;
  proof_of_authorization_request_ids: DocumentRequestGetResponseNondocumentSubmissionsProofOfAuthorizationRequestIds;
};

export type DocumentRequestGetResponseGroupName = string | null;

export type DocumentRequestGetResponseDocumentSubmissionsItemCreatedByEmail =
  | string
  | null;

export type DocumentRequestGetResponseDocumentSubmissionsItem = {
  created_at: string;
  created_by_email: DocumentRequestGetResponseDocumentSubmissionsItemCreatedByEmail;
  file_id: string;
  file_name: string;
  id: string;
};

export type DocumentRequestCancelResponseStatus =
  (typeof DocumentRequestCancelResponseStatus)[keyof typeof DocumentRequestCancelResponseStatus];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const DocumentRequestCancelResponseStatus = {
  awaiting_submission: "awaiting_submission",
  awaiting_review: "awaiting_review",
  provisionally_accepted: "provisionally_accepted",
  accepted: "accepted",
  canceled: "canceled",
} as const;

export interface DocumentRequestCancelResponse {
  created_at: string;
  description: string;
  document_submissions: DocumentRequestCancelResponseDocumentSubmissionsItem[];
  group_id: string;
  group_name: DocumentRequestCancelResponseGroupName;
  id: string;
  nondocument_submissions: DocumentRequestCancelResponseNondocumentSubmissions;
  program_id: string;
  program_name: string;
  reviews: DocumentRequestCancelResponseReviewsItem[];
  source: unknown;
  status: DocumentRequestCancelResponseStatus;
}

export type DocumentRequestCancelResponseReviewsItemReviewerEmail =
  | string
  | null;

export type DocumentRequestCancelResponseReviewsItemResult =
  (typeof DocumentRequestCancelResponseReviewsItemResult)[keyof typeof DocumentRequestCancelResponseReviewsItemResult];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const DocumentRequestCancelResponseReviewsItemResult = {
  accepted: "accepted",
  rejected: "rejected",
  provisionally_accepted: "provisionally_accepted",
} as const;

export type DocumentRequestCancelResponseReviewsItemComment = string | null;

export type DocumentRequestCancelResponseReviewsItem = {
  comment: DocumentRequestCancelResponseReviewsItemComment;
  created_at: string;
  id: string;
  result: DocumentRequestCancelResponseReviewsItemResult;
  reviewer_email: DocumentRequestCancelResponseReviewsItemReviewerEmail;
};

export type DocumentRequestCancelResponseNondocumentSubmissionsProofOfAuthorizationRequestIds =
  string[] | null;

export type DocumentRequestCancelResponseNondocumentSubmissionsPlatformVendorListSubmissionId =
  string | null;

export type DocumentRequestCancelResponseNondocumentSubmissionsPlatformFinancialReportSubmissionId =
  string | null;

export type DocumentRequestCancelResponseNondocumentSubmissionsPlatformComplianceMetricsSubmissionId =
  string | null;

export type DocumentRequestCancelResponseNondocumentSubmissionsPlatformComplaintListSubmissionId =
  string | null;

export type DocumentRequestCancelResponseNondocumentSubmissionsDirectUserDiligenceSubmissionId =
  string | null;

export type DocumentRequestCancelResponseNondocumentSubmissions = {
  direct_user_diligence_submission_id: DocumentRequestCancelResponseNondocumentSubmissionsDirectUserDiligenceSubmissionId;
  platform_complaint_list_submission_id: DocumentRequestCancelResponseNondocumentSubmissionsPlatformComplaintListSubmissionId;
  platform_compliance_metrics_submission_id: DocumentRequestCancelResponseNondocumentSubmissionsPlatformComplianceMetricsSubmissionId;
  platform_financial_report_submission_id: DocumentRequestCancelResponseNondocumentSubmissionsPlatformFinancialReportSubmissionId;
  platform_vendor_list_submission_id: DocumentRequestCancelResponseNondocumentSubmissionsPlatformVendorListSubmissionId;
  proof_of_authorization_request_ids: DocumentRequestCancelResponseNondocumentSubmissionsProofOfAuthorizationRequestIds;
};

export type DocumentRequestCancelResponseGroupName = string | null;

export type DocumentRequestCancelResponseDocumentSubmissionsItemCreatedByEmail =
  string | null;

export type DocumentRequestCancelResponseDocumentSubmissionsItem = {
  created_at: string;
  created_by_email: DocumentRequestCancelResponseDocumentSubmissionsItemCreatedByEmail;
  file_id: string;
  file_name: string;
  id: string;
};

export type DirectUserDiligenceSubmissionGetResponseVolumeProjectionsDescription =
  string | null;

export type DirectUserDiligenceSubmissionGetResponseUnderwritingProcessDescription =
  string | null;

export type DirectUserDiligenceSubmissionGetResponseOtherLicensesDetails =
  | string
  | null;

export type DirectUserDiligenceSubmissionGetResponseOperatingExemptionExplaination =
  string | null;

export type DirectUserDiligenceSubmissionGetResponseNotRegisteredWithFinancialCrimesEnforcementNetwork =
  string | null;

export type DirectUserDiligenceSubmissionGetResponseNoMoneyTransmissionLicensesExplaination =
  string | null;

export type DirectUserDiligenceSubmissionGetResponseNoComplianceProgramExplaination =
  string | null;

export type DirectUserDiligenceSubmissionGetResponseMoneyTransmissionLicensesDetails =
  string | null;

export type DirectUserDiligenceSubmissionGetResponseMoneyServicesBusinessRegistrationNumber =
  string | null;

export type DirectUserDiligenceSubmissionGetResponseLocationsAndMarketsDescription =
  string | null;

export type DirectUserDiligenceSubmissionGetResponseIsUsingThirdParties =
  | string
  | null;

export type DirectUserDiligenceSubmissionGetResponseIsRegeristeredWithFinancialCrimesEnforcementNetwork =
  string | null;

export type DirectUserDiligenceSubmissionGetResponseIsOfferingCredit =
  | string
  | null;

export type DirectUserDiligenceSubmissionGetResponseHasOtherLicenses =
  | string
  | null;

export type DirectUserDiligenceSubmissionGetResponseHasMoneyTransmissionLicenses =
  string | null;

export type DirectUserDiligenceSubmissionGetResponseHasComplianceProgram =
  | string
  | null;

export type DirectUserDiligenceSubmissionGetResponseHasAgencyStatus =
  | string
  | null;

export type DirectUserDiligenceSubmissionGetResponseFundsFlowsDescription =
  | string
  | null;

export type DirectUserDiligenceSubmissionGetResponseFeeStructureDescription =
  | string
  | null;

export type DirectUserDiligenceSubmissionGetResponseEngagedInCryptoOrCannabis =
  | string
  | null;

export type DirectUserDiligenceSubmissionGetResponseDebtCollectionDescription =
  | string
  | null;

export type DirectUserDiligenceSubmissionGetResponseCustomersDescription =
  | string
  | null;

export type DirectUserDiligenceSubmissionGetResponseCustomerAgreementsDescription =
  string | null;

export type DirectUserDiligenceSubmissionGetResponseComplianceProgramExplaination =
  string | null;

export type DirectUserDiligenceSubmissionGetResponseCanManageClientMoney =
  | string
  | null;

export type DirectUserDiligenceSubmissionGetResponseBusinessDescription =
  | string
  | null;

export type DirectUserDiligenceSubmissionGetResponseAttachmentsItem = {
  created_at: string;
  field_name: string;
  file_id: string;
  file_name: string;
};

export type DirectUserDiligenceSubmissionGetResponseAgencyAgreementsDescription =
  string | null;

export type DirectUserDiligenceSubmissionGetResponseAccountTypesDescription =
  | string
  | null;

export interface DirectUserDiligenceSubmissionGetResponse {
  account_types_description: DirectUserDiligenceSubmissionGetResponseAccountTypesDescription;
  agency_agreements_description: DirectUserDiligenceSubmissionGetResponseAgencyAgreementsDescription;
  attachments: DirectUserDiligenceSubmissionGetResponseAttachmentsItem[];
  business_description: DirectUserDiligenceSubmissionGetResponseBusinessDescription;
  can_manage_client_money: DirectUserDiligenceSubmissionGetResponseCanManageClientMoney;
  compliance_program_explaination: DirectUserDiligenceSubmissionGetResponseComplianceProgramExplaination;
  created_at: string;
  customer_agreements_description: DirectUserDiligenceSubmissionGetResponseCustomerAgreementsDescription;
  customers_description: DirectUserDiligenceSubmissionGetResponseCustomersDescription;
  debt_collection_description: DirectUserDiligenceSubmissionGetResponseDebtCollectionDescription;
  document_request_id: string;
  engaged_in_crypto_or_cannabis: DirectUserDiligenceSubmissionGetResponseEngagedInCryptoOrCannabis;
  fee_structure_description: DirectUserDiligenceSubmissionGetResponseFeeStructureDescription;
  funds_flows_description: DirectUserDiligenceSubmissionGetResponseFundsFlowsDescription;
  group_id: string;
  has_agency_status: DirectUserDiligenceSubmissionGetResponseHasAgencyStatus;
  has_compliance_program: DirectUserDiligenceSubmissionGetResponseHasComplianceProgram;
  has_money_transmission_licenses: DirectUserDiligenceSubmissionGetResponseHasMoneyTransmissionLicenses;
  has_other_licenses: DirectUserDiligenceSubmissionGetResponseHasOtherLicenses;
  id: string;
  is_offering_credit: DirectUserDiligenceSubmissionGetResponseIsOfferingCredit;
  is_regeristered_with_financial_crimes_enforcement_network: DirectUserDiligenceSubmissionGetResponseIsRegeristeredWithFinancialCrimesEnforcementNetwork;
  is_using_third_parties: DirectUserDiligenceSubmissionGetResponseIsUsingThirdParties;
  locations_and_markets_description: DirectUserDiligenceSubmissionGetResponseLocationsAndMarketsDescription;
  money_services_business_registration_number: DirectUserDiligenceSubmissionGetResponseMoneyServicesBusinessRegistrationNumber;
  money_transmission_licenses_details: DirectUserDiligenceSubmissionGetResponseMoneyTransmissionLicensesDetails;
  no_compliance_program_explaination: DirectUserDiligenceSubmissionGetResponseNoComplianceProgramExplaination;
  no_money_transmission_licenses_explaination: DirectUserDiligenceSubmissionGetResponseNoMoneyTransmissionLicensesExplaination;
  not_registered_with_financial_crimes_enforcement_network: DirectUserDiligenceSubmissionGetResponseNotRegisteredWithFinancialCrimesEnforcementNetwork;
  operating_exemption_explaination: DirectUserDiligenceSubmissionGetResponseOperatingExemptionExplaination;
  other_licenses_details: DirectUserDiligenceSubmissionGetResponseOtherLicensesDetails;
  program_id: string;
  underwriting_process_description: DirectUserDiligenceSubmissionGetResponseUnderwritingProcessDescription;
  volume_projections_description: DirectUserDiligenceSubmissionGetResponseVolumeProjectionsDescription;
}

export type DigitalWalletImageFileRejectPostResponseVisaIdentifier =
  | string
  | null;

export type DigitalWalletImageFileRejectPostResponseStatus =
  (typeof DigitalWalletImageFileRejectPostResponseStatus)[keyof typeof DigitalWalletImageFileRejectPostResponseStatus];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const DigitalWalletImageFileRejectPostResponseStatus = {
  pending: "pending",
  active: "active",
  rejected: "rejected",
  requires_attention: "requires_attention",
} as const;

export type DigitalWalletImageFileRejectPostResponseImageType =
  (typeof DigitalWalletImageFileRejectPostResponseImageType)[keyof typeof DigitalWalletImageFileRejectPostResponseImageType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const DigitalWalletImageFileRejectPostResponseImageType = {
  background: "background",
  icon: "icon",
} as const;

export interface DigitalWalletImageFileRejectPostResponse {
  api_file_id: string;
  card_profiles: DigitalWalletImageFileRejectPostResponseCardProfilesItem[];
  created_at: string;
  group_id: string;
  id: string;
  image_type: DigitalWalletImageFileRejectPostResponseImageType;
  status: DigitalWalletImageFileRejectPostResponseStatus;
  updated_at: string;
  visa_identifier: DigitalWalletImageFileRejectPostResponseVisaIdentifier;
}

export type DigitalWalletImageFileRejectPostResponseCardProfilesItemStatus =
  (typeof DigitalWalletImageFileRejectPostResponseCardProfilesItemStatus)[keyof typeof DigitalWalletImageFileRejectPostResponseCardProfilesItemStatus];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const DigitalWalletImageFileRejectPostResponseCardProfilesItemStatus = {
  pending: "pending",
  rejected: "rejected",
  active: "active",
  archived: "archived",
} as const;

export type DigitalWalletImageFileRejectPostResponseCardProfilesItem = {
  id: string;
  status: DigitalWalletImageFileRejectPostResponseCardProfilesItemStatus;
};

export type DigitalWalletImageFileListResponseNextCursor = string | null;

export interface DigitalWalletImageFileListResponse {
  data: DigitalWalletImageFileListResponseDataItem[];
  next_cursor: DigitalWalletImageFileListResponseNextCursor;
}

export type DigitalWalletImageFileListResponseDataItemVisaIdentifier =
  | string
  | null;

export type DigitalWalletImageFileListResponseDataItemStatus =
  (typeof DigitalWalletImageFileListResponseDataItemStatus)[keyof typeof DigitalWalletImageFileListResponseDataItemStatus];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const DigitalWalletImageFileListResponseDataItemStatus = {
  pending: "pending",
  active: "active",
  rejected: "rejected",
  requires_attention: "requires_attention",
} as const;

export type DigitalWalletImageFileListResponseDataItemImageType =
  (typeof DigitalWalletImageFileListResponseDataItemImageType)[keyof typeof DigitalWalletImageFileListResponseDataItemImageType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const DigitalWalletImageFileListResponseDataItemImageType = {
  background: "background",
  icon: "icon",
} as const;

export type DigitalWalletImageFileListResponseDataItemCardProfilesItemStatus =
  (typeof DigitalWalletImageFileListResponseDataItemCardProfilesItemStatus)[keyof typeof DigitalWalletImageFileListResponseDataItemCardProfilesItemStatus];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const DigitalWalletImageFileListResponseDataItemCardProfilesItemStatus =
  {
    pending: "pending",
    rejected: "rejected",
    active: "active",
    archived: "archived",
  } as const;

export type DigitalWalletImageFileListResponseDataItemCardProfilesItem = {
  id: string;
  status: DigitalWalletImageFileListResponseDataItemCardProfilesItemStatus;
};

export type DigitalWalletImageFileListResponseDataItem = {
  api_file_id: string;
  card_profiles: DigitalWalletImageFileListResponseDataItemCardProfilesItem[];
  created_at: string;
  group_id: string;
  id: string;
  image_type: DigitalWalletImageFileListResponseDataItemImageType;
  status: DigitalWalletImageFileListResponseDataItemStatus;
  updated_at: string;
  visa_identifier: DigitalWalletImageFileListResponseDataItemVisaIdentifier;
};

export type DigitalWalletImageFileApprovePostResponseVisaIdentifier =
  | string
  | null;

export type DigitalWalletImageFileApprovePostResponseStatus =
  (typeof DigitalWalletImageFileApprovePostResponseStatus)[keyof typeof DigitalWalletImageFileApprovePostResponseStatus];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const DigitalWalletImageFileApprovePostResponseStatus = {
  pending: "pending",
  active: "active",
  rejected: "rejected",
  requires_attention: "requires_attention",
} as const;

export type DigitalWalletImageFileApprovePostResponseImageType =
  (typeof DigitalWalletImageFileApprovePostResponseImageType)[keyof typeof DigitalWalletImageFileApprovePostResponseImageType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const DigitalWalletImageFileApprovePostResponseImageType = {
  background: "background",
  icon: "icon",
} as const;

export type DigitalWalletImageFileApprovePostResponseCardProfilesItemStatus =
  (typeof DigitalWalletImageFileApprovePostResponseCardProfilesItemStatus)[keyof typeof DigitalWalletImageFileApprovePostResponseCardProfilesItemStatus];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const DigitalWalletImageFileApprovePostResponseCardProfilesItemStatus = {
  pending: "pending",
  rejected: "rejected",
  active: "active",
  archived: "archived",
} as const;

export type DigitalWalletImageFileApprovePostResponseCardProfilesItem = {
  id: string;
  status: DigitalWalletImageFileApprovePostResponseCardProfilesItemStatus;
};

export interface DigitalWalletImageFileApprovePostResponse {
  api_file_id: string;
  card_profiles: DigitalWalletImageFileApprovePostResponseCardProfilesItem[];
  created_at: string;
  group_id: string;
  id: string;
  image_type: DigitalWalletImageFileApprovePostResponseImageType;
  status: DigitalWalletImageFileApprovePostResponseStatus;
  updated_at: string;
  visa_identifier: DigitalWalletImageFileApprovePostResponseVisaIdentifier;
}

export interface DigitalWalletImageFileApprovePostParameters {
  visa_identifier: string;
}

export type DeclinedTransactionGetResponseSourceType =
  (typeof DeclinedTransactionGetResponseSourceType)[keyof typeof DeclinedTransactionGetResponseSourceType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const DeclinedTransactionGetResponseSourceType = {
  "API::ACHDecline": "API::ACHDecline",
  "API::CardDecline": "API::CardDecline",
  "API::CheckDecline": "API::CheckDecline",
  "API::CheckDepositRejection": "API::CheckDepositRejection",
  "API::InboundRealTimePaymentsTransferDecline":
    "API::InboundRealTimePaymentsTransferDecline",
  "API::PrivacyCardDecline": "API::PrivacyCardDecline",
  "API::WireDecline": "API::WireDecline",
} as const;

export type DeclinedTransactionGetResponseSourceDocumentation = string | null;

export type DeclinedTransactionGetResponseRouteId = string | null;

export type DeclinedTransactionGetResponseCurrency =
  (typeof DeclinedTransactionGetResponseCurrency)[keyof typeof DeclinedTransactionGetResponseCurrency];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const DeclinedTransactionGetResponseCurrency = {
  CAD: "CAD",
  CHF: "CHF",
  EUR: "EUR",
  GBP: "GBP",
  JPY: "JPY",
  USD: "USD",
} as const;

export interface DeclinedTransactionGetResponse {
  account_id: string;
  amount: number;
  created_at: string;
  currency: DeclinedTransactionGetResponseCurrency;
  date: string;
  description: string;
  group_id: string;
  id: string;
  is_declined_transaction: boolean;
  route_id: DeclinedTransactionGetResponseRouteId;
  source: unknown;
  source_documentation: DeclinedTransactionGetResponseSourceDocumentation;
  source_id: string;
  source_type: DeclinedTransactionGetResponseSourceType;
  type: string;
}

export type CustomerIdentificationProgramTestingEntryPatchResponseTestingStatus =
  (typeof CustomerIdentificationProgramTestingEntryPatchResponseTestingStatus)[keyof typeof CustomerIdentificationProgramTestingEntryPatchResponseTestingStatus];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CustomerIdentificationProgramTestingEntryPatchResponseTestingStatus =
  {
    pending_reviewing: "pending_reviewing",
    reviewed_without_remediations: "reviewed_without_remediations",
    reviewed_with_remediation: "reviewed_with_remediation",
    pending_remediation: "pending_remediation",
  } as const;

export type CustomerIdentificationProgramTestingEntryPatchResponseReviewerId =
  | string
  | null;

export type CustomerIdentificationProgramTestingEntryPatchResponseReviewerEmail =
  string | null;

export type CustomerIdentificationProgramTestingEntryPatchResponseOriginalAutomatedEvaluation =
  (typeof CustomerIdentificationProgramTestingEntryPatchResponseOriginalAutomatedEvaluation)[keyof typeof CustomerIdentificationProgramTestingEntryPatchResponseOriginalAutomatedEvaluation];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CustomerIdentificationProgramTestingEntryPatchResponseOriginalAutomatedEvaluation =
  {
    valid: "valid",
    indeterminate: "indeterminate",
    invalid: "invalid",
  } as const;

export type CustomerIdentificationProgramTestingEntryPatchResponseNote =
  | string
  | null;

export type CustomerIdentificationProgramTestingEntryPatchResponseFinalAutomatedEvaluation =
  "valid" | "indeterminate" | "invalid" | null;

export interface CustomerIdentificationProgramTestingEntryPatchResponse {
  bank_program_id: string;
  bank_program_name: string;
  batch: CustomerIdentificationProgramTestingEntryPatchResponseBatch;
  entity_group_id: string;
  entity_id: string;
  entity_name: string;
  final_automated_evaluation: CustomerIdentificationProgramTestingEntryPatchResponseFinalAutomatedEvaluation;
  id: string;
  note: CustomerIdentificationProgramTestingEntryPatchResponseNote;
  original_automated_evaluation: CustomerIdentificationProgramTestingEntryPatchResponseOriginalAutomatedEvaluation;
  reviewer_email: CustomerIdentificationProgramTestingEntryPatchResponseReviewerEmail;
  reviewer_id: CustomerIdentificationProgramTestingEntryPatchResponseReviewerId;
  testing_status: CustomerIdentificationProgramTestingEntryPatchResponseTestingStatus;
}

export type CustomerIdentificationProgramTestingEntryPatchResponseBatchStatus =
  (typeof CustomerIdentificationProgramTestingEntryPatchResponseBatchStatus)[keyof typeof CustomerIdentificationProgramTestingEntryPatchResponseBatchStatus];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CustomerIdentificationProgramTestingEntryPatchResponseBatchStatus =
  {
    in_progress: "in_progress",
    completed: "completed",
  } as const;

export type CustomerIdentificationProgramTestingEntryPatchResponseBatchBank =
  (typeof CustomerIdentificationProgramTestingEntryPatchResponseBatchBank)[keyof typeof CustomerIdentificationProgramTestingEntryPatchResponseBatchBank];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CustomerIdentificationProgramTestingEntryPatchResponseBatchBank = {
  blue_ridge_bank: "blue_ridge_bank",
  core_bank: "core_bank",
  first_internet_bank: "first_internet_bank",
  global_innovations_bank: "global_innovations_bank",
  grasshopper_bank: "grasshopper_bank",
} as const;

export type CustomerIdentificationProgramTestingEntryPatchResponseBatch = {
  bank: CustomerIdentificationProgramTestingEntryPatchResponseBatchBank;
  id: string;
  period_end: string;
  period_start: string;
  status: CustomerIdentificationProgramTestingEntryPatchResponseBatchStatus;
};

export type CustomerIdentificationProgramTestingEntryPatchParametersTestingStatus =
  (typeof CustomerIdentificationProgramTestingEntryPatchParametersTestingStatus)[keyof typeof CustomerIdentificationProgramTestingEntryPatchParametersTestingStatus];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CustomerIdentificationProgramTestingEntryPatchParametersTestingStatus =
  {
    pending_reviewing: "pending_reviewing",
    reviewed_without_remediations: "reviewed_without_remediations",
    reviewed_with_remediation: "reviewed_with_remediation",
    pending_remediation: "pending_remediation",
  } as const;

export interface CustomerIdentificationProgramTestingEntryPatchParameters {
  note?: string;
  testing_status?: CustomerIdentificationProgramTestingEntryPatchParametersTestingStatus;
}

export type CustomerIdentificationProgramTestingEntryListResponseNextCursor =
  | string
  | null;

export interface CustomerIdentificationProgramTestingEntryListResponse {
  data: CustomerIdentificationProgramTestingEntryListResponseDataItem[];
  next_cursor: CustomerIdentificationProgramTestingEntryListResponseNextCursor;
}

export type CustomerIdentificationProgramTestingEntryListResponseDataItemTestingStatus =
  (typeof CustomerIdentificationProgramTestingEntryListResponseDataItemTestingStatus)[keyof typeof CustomerIdentificationProgramTestingEntryListResponseDataItemTestingStatus];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CustomerIdentificationProgramTestingEntryListResponseDataItemTestingStatus =
  {
    pending_reviewing: "pending_reviewing",
    reviewed_without_remediations: "reviewed_without_remediations",
    reviewed_with_remediation: "reviewed_with_remediation",
    pending_remediation: "pending_remediation",
  } as const;

export type CustomerIdentificationProgramTestingEntryListResponseDataItemReviewerId =
  string | null;

export type CustomerIdentificationProgramTestingEntryListResponseDataItemReviewerEmail =
  string | null;

export type CustomerIdentificationProgramTestingEntryListResponseDataItemOriginalAutomatedEvaluation =
  (typeof CustomerIdentificationProgramTestingEntryListResponseDataItemOriginalAutomatedEvaluation)[keyof typeof CustomerIdentificationProgramTestingEntryListResponseDataItemOriginalAutomatedEvaluation];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CustomerIdentificationProgramTestingEntryListResponseDataItemOriginalAutomatedEvaluation =
  {
    valid: "valid",
    indeterminate: "indeterminate",
    invalid: "invalid",
  } as const;

export type CustomerIdentificationProgramTestingEntryListResponseDataItemNote =
  | string
  | null;

export type CustomerIdentificationProgramTestingEntryListResponseDataItemFinalAutomatedEvaluation =
  "valid" | "indeterminate" | "invalid" | null;

export type CustomerIdentificationProgramTestingEntryListResponseDataItem = {
  bank_program_id: string;
  bank_program_name: string;
  batch: CustomerIdentificationProgramTestingEntryListResponseDataItemBatch;
  entity_group_id: string;
  entity_id: string;
  entity_name: string;
  final_automated_evaluation: CustomerIdentificationProgramTestingEntryListResponseDataItemFinalAutomatedEvaluation;
  id: string;
  note: CustomerIdentificationProgramTestingEntryListResponseDataItemNote;
  original_automated_evaluation: CustomerIdentificationProgramTestingEntryListResponseDataItemOriginalAutomatedEvaluation;
  reviewer_email: CustomerIdentificationProgramTestingEntryListResponseDataItemReviewerEmail;
  reviewer_id: CustomerIdentificationProgramTestingEntryListResponseDataItemReviewerId;
  testing_status: CustomerIdentificationProgramTestingEntryListResponseDataItemTestingStatus;
};

export type CustomerIdentificationProgramTestingEntryListResponseDataItemBatchStatus =
  (typeof CustomerIdentificationProgramTestingEntryListResponseDataItemBatchStatus)[keyof typeof CustomerIdentificationProgramTestingEntryListResponseDataItemBatchStatus];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CustomerIdentificationProgramTestingEntryListResponseDataItemBatchStatus =
  {
    in_progress: "in_progress",
    completed: "completed",
  } as const;

export type CustomerIdentificationProgramTestingEntryListResponseDataItemBatchBank =
  (typeof CustomerIdentificationProgramTestingEntryListResponseDataItemBatchBank)[keyof typeof CustomerIdentificationProgramTestingEntryListResponseDataItemBatchBank];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CustomerIdentificationProgramTestingEntryListResponseDataItemBatchBank =
  {
    blue_ridge_bank: "blue_ridge_bank",
    core_bank: "core_bank",
    first_internet_bank: "first_internet_bank",
    global_innovations_bank: "global_innovations_bank",
    grasshopper_bank: "grasshopper_bank",
  } as const;

export type CustomerIdentificationProgramTestingEntryListResponseDataItemBatch =
  {
    bank: CustomerIdentificationProgramTestingEntryListResponseDataItemBatchBank;
    id: string;
    period_end: string;
    period_start: string;
    status: CustomerIdentificationProgramTestingEntryListResponseDataItemBatchStatus;
  };

export type CustomerIdentificationProgramTestingEntryGetResponseTestingStatus =
  (typeof CustomerIdentificationProgramTestingEntryGetResponseTestingStatus)[keyof typeof CustomerIdentificationProgramTestingEntryGetResponseTestingStatus];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CustomerIdentificationProgramTestingEntryGetResponseTestingStatus =
  {
    pending_reviewing: "pending_reviewing",
    reviewed_without_remediations: "reviewed_without_remediations",
    reviewed_with_remediation: "reviewed_with_remediation",
    pending_remediation: "pending_remediation",
  } as const;

export type CustomerIdentificationProgramTestingEntryGetResponseReviewerId =
  | string
  | null;

export type CustomerIdentificationProgramTestingEntryGetResponseReviewerEmail =
  | string
  | null;

export type CustomerIdentificationProgramTestingEntryGetResponseOriginalAutomatedEvaluation =
  (typeof CustomerIdentificationProgramTestingEntryGetResponseOriginalAutomatedEvaluation)[keyof typeof CustomerIdentificationProgramTestingEntryGetResponseOriginalAutomatedEvaluation];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CustomerIdentificationProgramTestingEntryGetResponseOriginalAutomatedEvaluation =
  {
    valid: "valid",
    indeterminate: "indeterminate",
    invalid: "invalid",
  } as const;

export type CustomerIdentificationProgramTestingEntryGetResponseNote =
  | string
  | null;

export type CustomerIdentificationProgramTestingEntryGetResponseFinalAutomatedEvaluation =
  "valid" | "indeterminate" | "invalid" | null;

export interface CustomerIdentificationProgramTestingEntryGetResponse {
  bank_program_id: string;
  bank_program_name: string;
  batch: CustomerIdentificationProgramTestingEntryGetResponseBatch;
  entity_group_id: string;
  entity_id: string;
  entity_name: string;
  final_automated_evaluation: CustomerIdentificationProgramTestingEntryGetResponseFinalAutomatedEvaluation;
  id: string;
  note: CustomerIdentificationProgramTestingEntryGetResponseNote;
  original_automated_evaluation: CustomerIdentificationProgramTestingEntryGetResponseOriginalAutomatedEvaluation;
  reviewer_email: CustomerIdentificationProgramTestingEntryGetResponseReviewerEmail;
  reviewer_id: CustomerIdentificationProgramTestingEntryGetResponseReviewerId;
  testing_status: CustomerIdentificationProgramTestingEntryGetResponseTestingStatus;
}

export type CustomerIdentificationProgramTestingEntryGetResponseBatchStatus =
  (typeof CustomerIdentificationProgramTestingEntryGetResponseBatchStatus)[keyof typeof CustomerIdentificationProgramTestingEntryGetResponseBatchStatus];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CustomerIdentificationProgramTestingEntryGetResponseBatchStatus = {
  in_progress: "in_progress",
  completed: "completed",
} as const;

export type CustomerIdentificationProgramTestingEntryGetResponseBatchBank =
  (typeof CustomerIdentificationProgramTestingEntryGetResponseBatchBank)[keyof typeof CustomerIdentificationProgramTestingEntryGetResponseBatchBank];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CustomerIdentificationProgramTestingEntryGetResponseBatchBank = {
  blue_ridge_bank: "blue_ridge_bank",
  core_bank: "core_bank",
  first_internet_bank: "first_internet_bank",
  global_innovations_bank: "global_innovations_bank",
  grasshopper_bank: "grasshopper_bank",
} as const;

export type CustomerIdentificationProgramTestingEntryGetResponseBatch = {
  bank: CustomerIdentificationProgramTestingEntryGetResponseBatchBank;
  id: string;
  period_end: string;
  period_start: string;
  status: CustomerIdentificationProgramTestingEntryGetResponseBatchStatus;
};

export type CustomerIdentificationProgramTestingBatchSummaryGetResponseBankProgramsItemInitialCounts =
  {
    indeterminate: number;
    invalid: number;
    valid: number;
  };

export type CustomerIdentificationProgramTestingBatchSummaryGetResponseBankProgramsItemFinalCounts =
  {
    indeterminate: number;
    invalid: number;
    valid: number;
  };

export type CustomerIdentificationProgramTestingBatchSummaryGetResponseBankProgramsItem =
  {
    count_pending: number;
    final_counts: CustomerIdentificationProgramTestingBatchSummaryGetResponseBankProgramsItemFinalCounts;
    id: string;
    initial_counts: CustomerIdentificationProgramTestingBatchSummaryGetResponseBankProgramsItemInitialCounts;
    name: string;
  };

export interface CustomerIdentificationProgramTestingBatchSummaryGetResponse {
  bank_programs: CustomerIdentificationProgramTestingBatchSummaryGetResponseBankProgramsItem[];
}

export type CustomerIdentificationProgramTestingBatchListResponseNextCursor =
  | string
  | null;

export interface CustomerIdentificationProgramTestingBatchListResponse {
  data: CustomerIdentificationProgramTestingBatchListResponseDataItem[];
  next_cursor: CustomerIdentificationProgramTestingBatchListResponseNextCursor;
}

export type CustomerIdentificationProgramTestingBatchListResponseDataItemStatus =
  (typeof CustomerIdentificationProgramTestingBatchListResponseDataItemStatus)[keyof typeof CustomerIdentificationProgramTestingBatchListResponseDataItemStatus];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CustomerIdentificationProgramTestingBatchListResponseDataItemStatus =
  {
    in_progress: "in_progress",
    completed: "completed",
  } as const;

export type CustomerIdentificationProgramTestingBatchListResponseDataItemBank =
  (typeof CustomerIdentificationProgramTestingBatchListResponseDataItemBank)[keyof typeof CustomerIdentificationProgramTestingBatchListResponseDataItemBank];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CustomerIdentificationProgramTestingBatchListResponseDataItemBank =
  {
    blue_ridge_bank: "blue_ridge_bank",
    core_bank: "core_bank",
    first_internet_bank: "first_internet_bank",
    global_innovations_bank: "global_innovations_bank",
    grasshopper_bank: "grasshopper_bank",
  } as const;

export type CustomerIdentificationProgramTestingBatchListResponseDataItem = {
  bank: CustomerIdentificationProgramTestingBatchListResponseDataItemBank;
  id: string;
  period_end: string;
  period_start: string;
  status: CustomerIdentificationProgramTestingBatchListResponseDataItemStatus;
};

export type CustomerIdentificationProgramTestingBatchGetResponseStatus =
  (typeof CustomerIdentificationProgramTestingBatchGetResponseStatus)[keyof typeof CustomerIdentificationProgramTestingBatchGetResponseStatus];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CustomerIdentificationProgramTestingBatchGetResponseStatus = {
  in_progress: "in_progress",
  completed: "completed",
} as const;

export type CustomerIdentificationProgramTestingBatchGetResponseBank =
  (typeof CustomerIdentificationProgramTestingBatchGetResponseBank)[keyof typeof CustomerIdentificationProgramTestingBatchGetResponseBank];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CustomerIdentificationProgramTestingBatchGetResponseBank = {
  blue_ridge_bank: "blue_ridge_bank",
  core_bank: "core_bank",
  first_internet_bank: "first_internet_bank",
  global_innovations_bank: "global_innovations_bank",
  grasshopper_bank: "grasshopper_bank",
} as const;

export interface CustomerIdentificationProgramTestingBatchGetResponse {
  bank: CustomerIdentificationProgramTestingBatchGetResponseBank;
  id: string;
  period_end: string;
  period_start: string;
  status: CustomerIdentificationProgramTestingBatchGetResponseStatus;
}

export type ControlRecordListResponseNextCursor = string | null;

export interface ControlRecordListResponse {
  data: ControlRecordListResponseDataItem[];
  next_cursor: ControlRecordListResponseNextCursor;
}

export type ControlRecordListResponseDataItemResult =
  (typeof ControlRecordListResponseDataItemResult)[keyof typeof ControlRecordListResponseDataItemResult];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ControlRecordListResponseDataItemResult = {
  success: "success",
  failure: "failure",
} as const;

export type ControlRecordListResponseDataItemRecordType =
  (typeof ControlRecordListResponseDataItemRecordType)[keyof typeof ControlRecordListResponseDataItemRecordType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ControlRecordListResponseDataItemRecordType = {
  account: "account",
  bookkeeping_account: "bookkeeping_account",
  entity: "entity",
  transaction: "transaction",
} as const;

export type ControlRecordListResponseDataItemFailureMessage = string | null;

export type ControlRecordListResponseDataItemFailingSince = string | null;

export type ControlRecordListResponseDataItemEvaluatedAt = string | null;

export type ControlRecordListResponseDataItemControlExceptionAnyOfExceptionResult =
  (typeof ControlRecordListResponseDataItemControlExceptionAnyOfExceptionResult)[keyof typeof ControlRecordListResponseDataItemControlExceptionAnyOfExceptionResult];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ControlRecordListResponseDataItemControlExceptionAnyOfExceptionResult =
  {
    success: "success",
    failure: "failure",
  } as const;

export type ControlRecordListResponseDataItemControlExceptionAnyOf = {
  created_by_email: string;
  exception_result: ControlRecordListResponseDataItemControlExceptionAnyOfExceptionResult;
};

export type ControlRecordListResponseDataItemControlException =
  ControlRecordListResponseDataItemControlExceptionAnyOf | null;

export type ControlRecordListResponseDataItemCategory =
  (typeof ControlRecordListResponseDataItemCategory)[keyof typeof ControlRecordListResponseDataItemCategory];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ControlRecordListResponseDataItemCategory = {
  commingled_account_transactions_are_bookkept_within_forty_eight_hours:
    "commingled_account_transactions_are_bookkept_within_forty_eight_hours",
  commingled_account_bookkeeping_balances_match_within_forty_eight_hours:
    "commingled_account_bookkeeping_balances_match_within_forty_eight_hours",
  entities_in_valid_jurisdictions: "entities_in_valid_jurisdictions",
  entities_have_industry_codes: "entities_have_industry_codes",
  entities_have_confirmed_details: "entities_have_confirmed_details",
  accounts_have_onboarding_surveys: "accounts_have_onboarding_surveys",
  accounts_have_non_negative_balances: "accounts_have_non_negative_balances",
  transactions_are_bookkept: "transactions_are_bookkept",
  entities_have_physical_addresses: "entities_have_physical_addresses",
  entities_have_supplemental_documents: "entities_have_supplemental_documents",
  entities_have_validated_supplemental_documents:
    "entities_have_validated_supplemental_documents",
  corporate_entities_have_controllers: "corporate_entities_have_controllers",
  bookkeeping_accounts_have_valid_balances:
    "bookkeeping_accounts_have_valid_balances",
  entities_have_tax_identifiers: "entities_have_tax_identifiers",
  entities_have_valid_addresses: "entities_have_valid_addresses",
} as const;

export type ControlRecordListResponseDataItem = {
  category: ControlRecordListResponseDataItemCategory;
  control_configuration_id: string;
  control_exception: ControlRecordListResponseDataItemControlException;
  created_at: string;
  evaluated_at: ControlRecordListResponseDataItemEvaluatedAt;
  failing_since: ControlRecordListResponseDataItemFailingSince;
  failure_message: ControlRecordListResponseDataItemFailureMessage;
  group_id: string;
  id: string;
  program_id: string;
  record_id: string;
  record_name: string;
  record_type: ControlRecordListResponseDataItemRecordType;
  result: ControlRecordListResponseDataItemResult;
};

export type ControlRecordGetResponseResult =
  (typeof ControlRecordGetResponseResult)[keyof typeof ControlRecordGetResponseResult];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ControlRecordGetResponseResult = {
  success: "success",
  failure: "failure",
} as const;

export type ControlRecordGetResponseRecordType =
  (typeof ControlRecordGetResponseRecordType)[keyof typeof ControlRecordGetResponseRecordType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ControlRecordGetResponseRecordType = {
  account: "account",
  bookkeeping_account: "bookkeeping_account",
  entity: "entity",
  transaction: "transaction",
} as const;

export type ControlRecordGetResponseFailureMessage = string | null;

export type ControlRecordGetResponseFailingSince = string | null;

export type ControlRecordGetResponseEvaluatedAt = string | null;

export type ControlRecordGetResponseControlExceptionAnyOfExceptionResult =
  (typeof ControlRecordGetResponseControlExceptionAnyOfExceptionResult)[keyof typeof ControlRecordGetResponseControlExceptionAnyOfExceptionResult];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ControlRecordGetResponseControlExceptionAnyOfExceptionResult = {
  success: "success",
  failure: "failure",
} as const;

export type ControlRecordGetResponseControlExceptionAnyOf = {
  created_by_email: string;
  exception_result: ControlRecordGetResponseControlExceptionAnyOfExceptionResult;
};

export type ControlRecordGetResponseControlException =
  ControlRecordGetResponseControlExceptionAnyOf | null;

export type ControlRecordGetResponseCategory =
  (typeof ControlRecordGetResponseCategory)[keyof typeof ControlRecordGetResponseCategory];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ControlRecordGetResponseCategory = {
  commingled_account_transactions_are_bookkept_within_forty_eight_hours:
    "commingled_account_transactions_are_bookkept_within_forty_eight_hours",
  commingled_account_bookkeeping_balances_match_within_forty_eight_hours:
    "commingled_account_bookkeeping_balances_match_within_forty_eight_hours",
  entities_in_valid_jurisdictions: "entities_in_valid_jurisdictions",
  entities_have_industry_codes: "entities_have_industry_codes",
  entities_have_confirmed_details: "entities_have_confirmed_details",
  accounts_have_onboarding_surveys: "accounts_have_onboarding_surveys",
  accounts_have_non_negative_balances: "accounts_have_non_negative_balances",
  transactions_are_bookkept: "transactions_are_bookkept",
  entities_have_physical_addresses: "entities_have_physical_addresses",
  entities_have_supplemental_documents: "entities_have_supplemental_documents",
  entities_have_validated_supplemental_documents:
    "entities_have_validated_supplemental_documents",
  corporate_entities_have_controllers: "corporate_entities_have_controllers",
  bookkeeping_accounts_have_valid_balances:
    "bookkeeping_accounts_have_valid_balances",
  entities_have_tax_identifiers: "entities_have_tax_identifiers",
  entities_have_valid_addresses: "entities_have_valid_addresses",
} as const;

export interface ControlRecordGetResponse {
  category: ControlRecordGetResponseCategory;
  control_configuration_id: string;
  control_exception: ControlRecordGetResponseControlException;
  created_at: string;
  evaluated_at: ControlRecordGetResponseEvaluatedAt;
  failing_since: ControlRecordGetResponseFailingSince;
  failure_message: ControlRecordGetResponseFailureMessage;
  group_id: string;
  id: string;
  program_id: string;
  record_id: string;
  record_name: string;
  record_type: ControlRecordGetResponseRecordType;
  result: ControlRecordGetResponseResult;
}

export type ControlEvaluationListResponseNextCursor = string | null;

export interface ControlEvaluationListResponse {
  data: ControlEvaluationListResponseDataItem[];
  next_cursor: ControlEvaluationListResponseNextCursor;
}

export type ControlEvaluationListResponseDataItemResult =
  (typeof ControlEvaluationListResponseDataItemResult)[keyof typeof ControlEvaluationListResponseDataItemResult];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ControlEvaluationListResponseDataItemResult = {
  failure: "failure",
  success: "success",
  pending: "pending",
} as const;

export type ControlEvaluationListResponseDataItemFailureMessage = string | null;

export type ControlEvaluationListResponseDataItem = {
  created_at: string;
  failure_message: ControlEvaluationListResponseDataItemFailureMessage;
  id: string;
  result: ControlEvaluationListResponseDataItemResult;
  updated_at: string;
};

export type ControlConfigurationListResponseNextCursor = string | null;

export interface ControlConfigurationListResponse {
  data: ControlConfigurationListResponseDataItem[];
  next_cursor: ControlConfigurationListResponseNextCursor;
}

export type ControlConfigurationListResponseDataItemResult =
  (typeof ControlConfigurationListResponseDataItemResult)[keyof typeof ControlConfigurationListResponseDataItemResult];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ControlConfigurationListResponseDataItemResult = {
  success: "success",
  failure: "failure",
} as const;

export type ControlConfigurationListResponseDataItemGroupName = string | null;

export type ControlConfigurationListResponseDataItemDeletedAt = string | null;

export type ControlConfigurationListResponseDataItemCategory =
  (typeof ControlConfigurationListResponseDataItemCategory)[keyof typeof ControlConfigurationListResponseDataItemCategory];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ControlConfigurationListResponseDataItemCategory = {
  commingled_account_transactions_are_bookkept_within_forty_eight_hours:
    "commingled_account_transactions_are_bookkept_within_forty_eight_hours",
  commingled_account_bookkeeping_balances_match_within_forty_eight_hours:
    "commingled_account_bookkeeping_balances_match_within_forty_eight_hours",
  entities_in_valid_jurisdictions: "entities_in_valid_jurisdictions",
  entities_have_industry_codes: "entities_have_industry_codes",
  entities_have_confirmed_details: "entities_have_confirmed_details",
  accounts_have_onboarding_surveys: "accounts_have_onboarding_surveys",
  accounts_have_non_negative_balances: "accounts_have_non_negative_balances",
  transactions_are_bookkept: "transactions_are_bookkept",
  entities_have_physical_addresses: "entities_have_physical_addresses",
  entities_have_supplemental_documents: "entities_have_supplemental_documents",
  entities_have_validated_supplemental_documents:
    "entities_have_validated_supplemental_documents",
  corporate_entities_have_controllers: "corporate_entities_have_controllers",
  bookkeeping_accounts_have_valid_balances:
    "bookkeeping_accounts_have_valid_balances",
  entities_have_tax_identifiers: "entities_have_tax_identifiers",
  entities_have_valid_addresses: "entities_have_valid_addresses",
} as const;

export type ControlConfigurationListResponseDataItem = {
  bank_visible: boolean;
  category: ControlConfigurationListResponseDataItemCategory;
  created_at: string;
  deleted_at: ControlConfigurationListResponseDataItemDeletedAt;
  details: string[];
  group_id: string;
  group_name: ControlConfigurationListResponseDataItemGroupName;
  id: string;
  number_of_failures: number;
  number_of_successes: number;
  program_id: string;
  program_name: string;
  result: ControlConfigurationListResponseDataItemResult;
  title: string;
  user_visible: boolean;
};

export type ControlConfigurationGetResponseResult =
  (typeof ControlConfigurationGetResponseResult)[keyof typeof ControlConfigurationGetResponseResult];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ControlConfigurationGetResponseResult = {
  success: "success",
  failure: "failure",
} as const;

export type ControlConfigurationGetResponseGroupName = string | null;

export type ControlConfigurationGetResponseDeletedAt = string | null;

export type ControlConfigurationGetResponseCategory =
  (typeof ControlConfigurationGetResponseCategory)[keyof typeof ControlConfigurationGetResponseCategory];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ControlConfigurationGetResponseCategory = {
  commingled_account_transactions_are_bookkept_within_forty_eight_hours:
    "commingled_account_transactions_are_bookkept_within_forty_eight_hours",
  commingled_account_bookkeeping_balances_match_within_forty_eight_hours:
    "commingled_account_bookkeeping_balances_match_within_forty_eight_hours",
  entities_in_valid_jurisdictions: "entities_in_valid_jurisdictions",
  entities_have_industry_codes: "entities_have_industry_codes",
  entities_have_confirmed_details: "entities_have_confirmed_details",
  accounts_have_onboarding_surveys: "accounts_have_onboarding_surveys",
  accounts_have_non_negative_balances: "accounts_have_non_negative_balances",
  transactions_are_bookkept: "transactions_are_bookkept",
  entities_have_physical_addresses: "entities_have_physical_addresses",
  entities_have_supplemental_documents: "entities_have_supplemental_documents",
  entities_have_validated_supplemental_documents:
    "entities_have_validated_supplemental_documents",
  corporate_entities_have_controllers: "corporate_entities_have_controllers",
  bookkeeping_accounts_have_valid_balances:
    "bookkeeping_accounts_have_valid_balances",
  entities_have_tax_identifiers: "entities_have_tax_identifiers",
  entities_have_valid_addresses: "entities_have_valid_addresses",
} as const;

export interface ControlConfigurationGetResponse {
  bank_visible: boolean;
  category: ControlConfigurationGetResponseCategory;
  created_at: string;
  deleted_at: ControlConfigurationGetResponseDeletedAt;
  details: string[];
  group_id: string;
  group_name: ControlConfigurationGetResponseGroupName;
  id: string;
  number_of_failures: number;
  number_of_successes: number;
  program_id: string;
  program_name: string;
  result: ControlConfigurationGetResponseResult;
  title: string;
  user_visible: boolean;
}

export type ConsumerOnboardingSurveyListResponseNextCursor = string | null;

export interface ConsumerOnboardingSurveyListResponse {
  data: ConsumerOnboardingSurveyListResponseDataItem[];
  next_cursor: ConsumerOnboardingSurveyListResponseNextCursor;
}

export type ConsumerOnboardingSurveyListResponseDataItemPoliticallyExposedPersonDetails =
  string | null;

export type ConsumerOnboardingSurveyListResponseDataItemMonthlyWireReceivedAmount =
  (typeof ConsumerOnboardingSurveyListResponseDataItemMonthlyWireReceivedAmount)[keyof typeof ConsumerOnboardingSurveyListResponseDataItemMonthlyWireReceivedAmount];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ConsumerOnboardingSurveyListResponseDataItemMonthlyWireReceivedAmount =
  {
    zero: "zero",
    zero_to_ten_thousand_dollars: "zero_to_ten_thousand_dollars",
    ten_thousand_to_fifty_thousand_dollars:
      "ten_thousand_to_fifty_thousand_dollars",
    fifty_thousand_to_one_hundred_thousand_dollars:
      "fifty_thousand_to_one_hundred_thousand_dollars",
    more_than_one_hundred_thousand_dollars:
      "more_than_one_hundred_thousand_dollars",
  } as const;

export type ConsumerOnboardingSurveyListResponseDataItemMonthlyWireOriginatedAmount =
  (typeof ConsumerOnboardingSurveyListResponseDataItemMonthlyWireOriginatedAmount)[keyof typeof ConsumerOnboardingSurveyListResponseDataItemMonthlyWireOriginatedAmount];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ConsumerOnboardingSurveyListResponseDataItemMonthlyWireOriginatedAmount =
  {
    zero: "zero",
    zero_to_ten_thousand_dollars: "zero_to_ten_thousand_dollars",
    ten_thousand_to_fifty_thousand_dollars:
      "ten_thousand_to_fifty_thousand_dollars",
    fifty_thousand_to_one_hundred_thousand_dollars:
      "fifty_thousand_to_one_hundred_thousand_dollars",
    more_than_one_hundred_thousand_dollars:
      "more_than_one_hundred_thousand_dollars",
  } as const;

export type ConsumerOnboardingSurveyListResponseDataItemMonthlyAchReceivedAmount =
  (typeof ConsumerOnboardingSurveyListResponseDataItemMonthlyAchReceivedAmount)[keyof typeof ConsumerOnboardingSurveyListResponseDataItemMonthlyAchReceivedAmount];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ConsumerOnboardingSurveyListResponseDataItemMonthlyAchReceivedAmount =
  {
    zero: "zero",
    zero_to_ten_thousand_dollars: "zero_to_ten_thousand_dollars",
    ten_thousand_to_fifty_thousand_dollars:
      "ten_thousand_to_fifty_thousand_dollars",
    fifty_thousand_to_one_hundred_thousand_dollars:
      "fifty_thousand_to_one_hundred_thousand_dollars",
    more_than_one_hundred_thousand_dollars:
      "more_than_one_hundred_thousand_dollars",
  } as const;

export type ConsumerOnboardingSurveyListResponseDataItemMonthlyAchOriginatedAmount =
  (typeof ConsumerOnboardingSurveyListResponseDataItemMonthlyAchOriginatedAmount)[keyof typeof ConsumerOnboardingSurveyListResponseDataItemMonthlyAchOriginatedAmount];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ConsumerOnboardingSurveyListResponseDataItemMonthlyAchOriginatedAmount =
  {
    zero: "zero",
    zero_to_ten_thousand_dollars: "zero_to_ten_thousand_dollars",
    ten_thousand_to_fifty_thousand_dollars:
      "ten_thousand_to_fifty_thousand_dollars",
    fifty_thousand_to_one_hundred_thousand_dollars:
      "fifty_thousand_to_one_hundred_thousand_dollars",
    more_than_one_hundred_thousand_dollars:
      "more_than_one_hundred_thousand_dollars",
  } as const;

export type ConsumerOnboardingSurveyListResponseDataItemArchivedAt =
  | string
  | null;

export type ConsumerOnboardingSurveyListResponseDataItem = {
  archived_at: ConsumerOnboardingSurveyListResponseDataItemArchivedAt;
  created_at: string;
  deposit_source_is_bank_drafts: boolean;
  deposit_source_is_employment_income: boolean;
  deposit_source_is_gifts: boolean;
  deposit_source_is_grants_or_scholarships: boolean;
  deposit_source_is_investment_income: boolean;
  deposit_source_is_loans: boolean;
  deposit_source_is_other: boolean;
  deposit_source_is_retirement_or_pension_income: boolean;
  deposit_source_is_trust_payments: boolean;
  entity_id: string;
  entity_name: string;
  group_id: string;
  id: string;
  is_affiliated_with_politically_exposed_person: boolean;
  is_politically_exposed_or_senior_political_figure: boolean;
  is_professional_service_provider: boolean;
  monthly_ach_originated_amount: ConsumerOnboardingSurveyListResponseDataItemMonthlyAchOriginatedAmount;
  monthly_ach_received_amount: ConsumerOnboardingSurveyListResponseDataItemMonthlyAchReceivedAmount;
  monthly_wire_originated_amount: ConsumerOnboardingSurveyListResponseDataItemMonthlyWireOriginatedAmount;
  monthly_wire_received_amount: ConsumerOnboardingSurveyListResponseDataItemMonthlyWireReceivedAmount;
  occupation: string;
  politically_exposed_person_details: ConsumerOnboardingSurveyListResponseDataItemPoliticallyExposedPersonDetails;
  program_id: string;
};

export type ConsumerOnboardingSurveyGetResponsePoliticallyExposedPersonDetails =
  string | null;

export type ConsumerOnboardingSurveyGetResponseMonthlyWireReceivedAmount =
  (typeof ConsumerOnboardingSurveyGetResponseMonthlyWireReceivedAmount)[keyof typeof ConsumerOnboardingSurveyGetResponseMonthlyWireReceivedAmount];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ConsumerOnboardingSurveyGetResponseMonthlyWireReceivedAmount = {
  zero: "zero",
  zero_to_ten_thousand_dollars: "zero_to_ten_thousand_dollars",
  ten_thousand_to_fifty_thousand_dollars:
    "ten_thousand_to_fifty_thousand_dollars",
  fifty_thousand_to_one_hundred_thousand_dollars:
    "fifty_thousand_to_one_hundred_thousand_dollars",
  more_than_one_hundred_thousand_dollars:
    "more_than_one_hundred_thousand_dollars",
} as const;

export type ConsumerOnboardingSurveyGetResponseMonthlyWireOriginatedAmount =
  (typeof ConsumerOnboardingSurveyGetResponseMonthlyWireOriginatedAmount)[keyof typeof ConsumerOnboardingSurveyGetResponseMonthlyWireOriginatedAmount];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ConsumerOnboardingSurveyGetResponseMonthlyWireOriginatedAmount = {
  zero: "zero",
  zero_to_ten_thousand_dollars: "zero_to_ten_thousand_dollars",
  ten_thousand_to_fifty_thousand_dollars:
    "ten_thousand_to_fifty_thousand_dollars",
  fifty_thousand_to_one_hundred_thousand_dollars:
    "fifty_thousand_to_one_hundred_thousand_dollars",
  more_than_one_hundred_thousand_dollars:
    "more_than_one_hundred_thousand_dollars",
} as const;

export type ConsumerOnboardingSurveyGetResponseMonthlyAchReceivedAmount =
  (typeof ConsumerOnboardingSurveyGetResponseMonthlyAchReceivedAmount)[keyof typeof ConsumerOnboardingSurveyGetResponseMonthlyAchReceivedAmount];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ConsumerOnboardingSurveyGetResponseMonthlyAchReceivedAmount = {
  zero: "zero",
  zero_to_ten_thousand_dollars: "zero_to_ten_thousand_dollars",
  ten_thousand_to_fifty_thousand_dollars:
    "ten_thousand_to_fifty_thousand_dollars",
  fifty_thousand_to_one_hundred_thousand_dollars:
    "fifty_thousand_to_one_hundred_thousand_dollars",
  more_than_one_hundred_thousand_dollars:
    "more_than_one_hundred_thousand_dollars",
} as const;

export type ConsumerOnboardingSurveyGetResponseMonthlyAchOriginatedAmount =
  (typeof ConsumerOnboardingSurveyGetResponseMonthlyAchOriginatedAmount)[keyof typeof ConsumerOnboardingSurveyGetResponseMonthlyAchOriginatedAmount];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ConsumerOnboardingSurveyGetResponseMonthlyAchOriginatedAmount = {
  zero: "zero",
  zero_to_ten_thousand_dollars: "zero_to_ten_thousand_dollars",
  ten_thousand_to_fifty_thousand_dollars:
    "ten_thousand_to_fifty_thousand_dollars",
  fifty_thousand_to_one_hundred_thousand_dollars:
    "fifty_thousand_to_one_hundred_thousand_dollars",
  more_than_one_hundred_thousand_dollars:
    "more_than_one_hundred_thousand_dollars",
} as const;

export type ConsumerOnboardingSurveyGetResponseArchivedAt = string | null;

export interface ConsumerOnboardingSurveyGetResponse {
  archived_at: ConsumerOnboardingSurveyGetResponseArchivedAt;
  created_at: string;
  deposit_source_is_bank_drafts: boolean;
  deposit_source_is_employment_income: boolean;
  deposit_source_is_gifts: boolean;
  deposit_source_is_grants_or_scholarships: boolean;
  deposit_source_is_investment_income: boolean;
  deposit_source_is_loans: boolean;
  deposit_source_is_other: boolean;
  deposit_source_is_retirement_or_pension_income: boolean;
  deposit_source_is_trust_payments: boolean;
  entity_id: string;
  entity_name: string;
  group_id: string;
  id: string;
  is_affiliated_with_politically_exposed_person: boolean;
  is_politically_exposed_or_senior_political_figure: boolean;
  is_professional_service_provider: boolean;
  monthly_ach_originated_amount: ConsumerOnboardingSurveyGetResponseMonthlyAchOriginatedAmount;
  monthly_ach_received_amount: ConsumerOnboardingSurveyGetResponseMonthlyAchReceivedAmount;
  monthly_wire_originated_amount: ConsumerOnboardingSurveyGetResponseMonthlyWireOriginatedAmount;
  monthly_wire_received_amount: ConsumerOnboardingSurveyGetResponseMonthlyWireReceivedAmount;
  occupation: string;
  politically_exposed_person_details: ConsumerOnboardingSurveyGetResponsePoliticallyExposedPersonDetails;
  program_id: string;
}

export type ConsolidatedScreeningListEntryGetResponseType =
  | "Entity"
  | "Individual"
  | "Vessel"
  | "Aircraft"
  | null;

export type ConsolidatedScreeningListEntryGetResponseSource =
  (typeof ConsolidatedScreeningListEntryGetResponseSource)[keyof typeof ConsolidatedScreeningListEntryGetResponseSource];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ConsolidatedScreeningListEntryGetResponseSource = {
  "Specially_Designated_Nationals_(SDN)_-_Treasury_Department":
    "Specially Designated Nationals (SDN) - Treasury Department",
  "Entity_List_(EL)_-_Bureau_of_Industry_and_Security":
    "Entity List (EL) - Bureau of Industry and Security",
  "ITAR_Debarred_(DTC)_-_State_Department":
    "ITAR Debarred (DTC) - State Department",
  "Denied_Persons_List_(DPL)_-_Bureau_of_Industry_and_Security":
    "Denied Persons List (DPL) - Bureau of Industry and Security",
  "Sectoral_Sanctions_Identifications_List_(SSI)_-_Treasury_Department":
    "Sectoral Sanctions Identifications List (SSI) - Treasury Department",
  "Unverified_List_(UVL)_-_Bureau_of_Industry_and_Security":
    "Unverified List (UVL) - Bureau of Industry and Security",
  "Nonproliferation_Sanctions_(ISN)_-_State_Department":
    "Nonproliferation Sanctions (ISN) - State Department",
  "Palestinian_Legislative_Council_List_(PLC)_-_Treasury_Department":
    "Palestinian Legislative Council List (PLC) - Treasury Department",
  "Military_End_User_(MEU)_List_-_Bureau_of_Industry_and_Security":
    "Military End User (MEU) List - Bureau of Industry and Security",
  "Non-SDN_Chinese_Military-Industrial_Complex_Companies_List_(CMIC)_-_Treasury_Department":
    "Non-SDN Chinese Military-Industrial Complex Companies List (CMIC) - Treasury Department",
  "Non-SDN_Menu-Based_Sanctions_List_(NS-MBS_List)_-_Treasury_Department":
    "Non-SDN Menu-Based Sanctions List (NS-MBS List) - Treasury Department",
  "Foreign_Sanctions_Evaders_(FSE)_-_Treasury_Department":
    "Foreign Sanctions Evaders (FSE) - Treasury Department",
  "Capta_List_(CAP)_-_Treasury_Department":
    "Capta List (CAP) - Treasury Department",
} as const;

export interface ConsolidatedScreeningListEntryGetResponse {
  alternative_names: string[];
  external_id: string;
  id: string;
  name: string;
  source: ConsolidatedScreeningListEntryGetResponseSource;
  type: ConsolidatedScreeningListEntryGetResponseType;
}

export type ComplianceDocumentListResponseNextCursor = string | null;

export interface ComplianceDocumentListResponse {
  data: ComplianceDocumentListResponseDataItem[];
  next_cursor: ComplianceDocumentListResponseNextCursor;
}

export type ComplianceDocumentListResponseDataItemCategory =
  (typeof ComplianceDocumentListResponseDataItemCategory)[keyof typeof ComplianceDocumentListResponseDataItemCategory];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ComplianceDocumentListResponseDataItemCategory = {
  banking_policies: "banking_policies",
  information_technology_and_security_policies:
    "information_technology_and_security_policies",
  procedures: "procedures",
  risk_assessments: "risk_assessments",
  other_program_control_documents_and_references:
    "other_program_control_documents_and_references",
} as const;

export type ComplianceDocumentListResponseDataItemBank =
  | "blue_ridge_bank"
  | "core_bank"
  | "first_internet_bank"
  | "global_innovations_bank"
  | "grasshopper_bank"
  | null;

export type ComplianceDocumentListResponseDataItem = {
  bank: ComplianceDocumentListResponseDataItemBank;
  category: ComplianceDocumentListResponseDataItemCategory;
  category_pretty_name: string;
  created_at: string;
  dropbox_paper_url: string;
  id: string;
  name: string;
  updated_at: string;
};

export type ComplianceDocumentDownloadResponse = Blob;

export type ComplianceDocumentDeleteDirectivePostResponseCategory =
  (typeof ComplianceDocumentDeleteDirectivePostResponseCategory)[keyof typeof ComplianceDocumentDeleteDirectivePostResponseCategory];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ComplianceDocumentDeleteDirectivePostResponseCategory = {
  banking_policies: "banking_policies",
  information_technology_and_security_policies:
    "information_technology_and_security_policies",
  procedures: "procedures",
  risk_assessments: "risk_assessments",
  other_program_control_documents_and_references:
    "other_program_control_documents_and_references",
} as const;

export type ComplianceDocumentDeleteDirectivePostResponseBank =
  | "blue_ridge_bank"
  | "core_bank"
  | "first_internet_bank"
  | "global_innovations_bank"
  | "grasshopper_bank"
  | null;

export interface ComplianceDocumentDeleteDirectivePostResponse {
  bank: ComplianceDocumentDeleteDirectivePostResponseBank;
  category: ComplianceDocumentDeleteDirectivePostResponseCategory;
  category_pretty_name: string;
  created_at: string;
  dropbox_paper_url: string;
  id: string;
  name: string;
  updated_at: string;
}

export type CompletedTransactionListResponseNextCursor = string | null;

export interface CompletedTransactionListResponse {
  data: CompletedTransactionListResponseDataItem[];
  next_cursor: CompletedTransactionListResponseNextCursor;
}

export type CompletedTransactionListResponseDataItemSourceType =
  (typeof CompletedTransactionListResponseDataItemSourceType)[keyof typeof CompletedTransactionListResponseDataItemSourceType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CompletedTransactionListResponseDataItemSourceType = {
  "API::ACHCheckConversion": "API::ACHCheckConversion",
  "API::ACHCheckConversionReturn": "API::ACHCheckConversionReturn",
  "API::ACHTransferIntention": "API::ACHTransferIntention",
  "API::ACHTransferRejection": "API::ACHTransferRejection",
  "API::ACHTransferReturn": "API::ACHTransferReturn",
  "API::AccountTransferIntention": "API::AccountTransferIntention",
  "API::CardDisputeAcceptance": "API::CardDisputeAcceptance",
  "API::CardDisputeLoss": "API::CardDisputeLoss",
  "API::CardRefund": "API::CardRefund",
  "API::CardRevenuePayment": "API::CardRevenuePayment",
  "API::CardSettlement": "API::CardSettlement",
  "API::CashbackPayment": "API::CashbackPayment",
  "API::CheckDepositAcceptance": "API::CheckDepositAcceptance",
  "API::CheckDepositReturn": "API::CheckDepositReturn",
  "API::CheckTransferIntention": "API::CheckTransferIntention",
  "API::CheckTransferRejection": "API::CheckTransferRejection",
  "API::CheckTransferReturn": "API::CheckTransferReturn",
  "API::CheckTransferStopPaymentRequest":
    "API::CheckTransferStopPaymentRequest",
  "API::DeprecatedTransactionSource": "API::DeprecatedTransactionSource",
  "API::DisputeResolution": "API::DisputeResolution",
  "API::EmpyrealCashDeposit": "API::EmpyrealCashDeposit",
  "API::FeePayment": "API::FeePayment",
  "API::InboundACHTransferIntention": "API::InboundACHTransferIntention",
  "API::InboundACHTransferReturnIntention":
    "API::InboundACHTransferReturnIntention",
  "API::InboundCheck": "API::InboundCheck",
  "API::InboundCheckDepositReturnIntention":
    "API::InboundCheckDepositReturnIntention",
  "API::InboundCheckAdjustment": "API::InboundCheckAdjustment",
  "API::InboundRealTimePaymentsTransferConfirmation":
    "API::InboundRealTimePaymentsTransferConfirmation",
  "API::InboundWireReversal": "API::InboundWireReversal",
  "API::InboundWireTransferIntention": "API::InboundWireTransferIntention",
  "API::InboundWireTransferReversalIntention":
    "API::InboundWireTransferReversalIntention",
  "API::InterestPayment": "API::InterestPayment",
  "API::InternalGeneralLedgerTransaction":
    "API::InternalGeneralLedgerTransaction",
  "API::InternalSource": "API::InternalSource",
  "API::PrivacyCardRefund": "API::PrivacyCardRefund",
  "API::PrivacyCardSettlement": "API::PrivacyCardSettlement",
  "API::RealTimePaymentsTransferAcknowledgement":
    "API::RealTimePaymentsTransferAcknowledgement",
  "API::SampleFunds": "API::SampleFunds",
  "API::WireDrawdownPaymentIntention": "API::WireDrawdownPaymentIntention",
  "API::WireTransferIntention": "API::WireTransferIntention",
  "API::WireTransferRejection": "API::WireTransferRejection",
  "API::ACHDecline": "API::ACHDecline",
  "API::CardDecline": "API::CardDecline",
  "API::CheckDecline": "API::CheckDecline",
  "API::CheckDepositRejection": "API::CheckDepositRejection",
  "API::InboundRealTimePaymentsTransferDecline":
    "API::InboundRealTimePaymentsTransferDecline",
  "API::PrivacyCardDecline": "API::PrivacyCardDecline",
  "API::WireDecline": "API::WireDecline",
} as const;

export type CompletedTransactionListResponseDataItemRoute =
  CompletedTransactionListResponseDataItemRouteAnyOf | null;

export type CompletedTransactionListResponseDataItem = {
  account_id: string;
  amount: number;
  currency: CompletedTransactionListResponseDataItemCurrency;
  date: string;
  description: string;
  group_id: string;
  group_name: CompletedTransactionListResponseDataItemGroupName;
  id: string;
  is_declined_transaction: boolean;
  route: CompletedTransactionListResponseDataItemRoute;
  source_id: string;
  source_type: CompletedTransactionListResponseDataItemSourceType;
  type: string;
};

export type CompletedTransactionListResponseDataItemRouteAnyOfType =
  (typeof CompletedTransactionListResponseDataItemRouteAnyOfType)[keyof typeof CompletedTransactionListResponseDataItemRouteAnyOfType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CompletedTransactionListResponseDataItemRouteAnyOfType = {
  account_number: "account_number",
  card: "card",
  lockbox: "lockbox",
} as const;

export type CompletedTransactionListResponseDataItemRouteAnyOfDescription =
  | string
  | null;

export type CompletedTransactionListResponseDataItemRouteAnyOf = {
  description: CompletedTransactionListResponseDataItemRouteAnyOfDescription;
  id: string;
  type: CompletedTransactionListResponseDataItemRouteAnyOfType;
};

export type CompletedTransactionListResponseDataItemGroupName = string | null;

export type CompletedTransactionListResponseDataItemCurrency =
  (typeof CompletedTransactionListResponseDataItemCurrency)[keyof typeof CompletedTransactionListResponseDataItemCurrency];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CompletedTransactionListResponseDataItemCurrency = {
  CAD: "CAD",
  CHF: "CHF",
  EUR: "EUR",
  GBP: "GBP",
  JPY: "JPY",
  USD: "USD",
} as const;

export type ComplaintEntryListResponseNextCursor = string | null;

export interface ComplaintEntryListResponse {
  data: ComplaintEntryListResponseDataItem[];
  next_cursor: ComplaintEntryListResponseNextCursor;
}

export type ComplaintEntryListResponseDataItemSource =
  (typeof ComplaintEntryListResponseDataItemSource)[keyof typeof ComplaintEntryListResponseDataItemSource];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ComplaintEntryListResponseDataItemSource = {
  customer: "customer",
  regulator: "regulator",
  law_enforcement: "law_enforcement",
  law_firm: "law_firm",
} as const;

export type ComplaintEntryListResponseDataItemResolvedOn = string | null;

export type ComplaintEntryListResponseDataItemReceivedOn = string | null;

export type ComplaintEntryListResponseDataItemComplaintType =
  (typeof ComplaintEntryListResponseDataItemComplaintType)[keyof typeof ComplaintEntryListResponseDataItemComplaintType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ComplaintEntryListResponseDataItemComplaintType = {
  Complaint: "Complaint",
  "Dispute_Reg_E_(ACH)": "Dispute Reg E (ACH)",
  "Dispute_Reg_E_(Cards)": "Dispute Reg E (Cards)",
} as const;

export type ComplaintEntryListResponseDataItemAccountId = string | null;

export type ComplaintEntryListResponseDataItem = {
  account_id: ComplaintEntryListResponseDataItemAccountId;
  complaint_type: ComplaintEntryListResponseDataItemComplaintType;
  created_at: string;
  description: string;
  group_id: string;
  id: string;
  received_on: ComplaintEntryListResponseDataItemReceivedOn;
  resolved_on: ComplaintEntryListResponseDataItemResolvedOn;
  source: ComplaintEntryListResponseDataItemSource;
  updated_at: string;
};

export type ComplaintEntryGetResponseSource =
  (typeof ComplaintEntryGetResponseSource)[keyof typeof ComplaintEntryGetResponseSource];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ComplaintEntryGetResponseSource = {
  customer: "customer",
  regulator: "regulator",
  law_enforcement: "law_enforcement",
  law_firm: "law_firm",
} as const;

export type ComplaintEntryGetResponseResolvedOn = string | null;

export type ComplaintEntryGetResponseReceivedOn = string | null;

export type ComplaintEntryGetResponseComplaintType =
  (typeof ComplaintEntryGetResponseComplaintType)[keyof typeof ComplaintEntryGetResponseComplaintType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ComplaintEntryGetResponseComplaintType = {
  Complaint: "Complaint",
  "Dispute_Reg_E_(ACH)": "Dispute Reg E (ACH)",
  "Dispute_Reg_E_(Cards)": "Dispute Reg E (Cards)",
} as const;

export type ComplaintEntryGetResponseAccountId = string | null;

export interface ComplaintEntryGetResponse {
  account_id: ComplaintEntryGetResponseAccountId;
  complaint_type: ComplaintEntryGetResponseComplaintType;
  created_at: string;
  description: string;
  group_id: string;
  id: string;
  received_on: ComplaintEntryGetResponseReceivedOn;
  resolved_on: ComplaintEntryGetResponseResolvedOn;
  source: ComplaintEntryGetResponseSource;
  updated_at: string;
}

export type CommercialOnboardingSurveyListResponseNextCursor = string | null;

export interface CommercialOnboardingSurveyListResponse {
  data: CommercialOnboardingSurveyListResponseDataItem[];
  next_cursor: CommercialOnboardingSurveyListResponseNextCursor;
}

export type CommercialOnboardingSurveyListResponseDataItemMonthlyDomesticWireAmount =
  (typeof CommercialOnboardingSurveyListResponseDataItemMonthlyDomesticWireAmount)[keyof typeof CommercialOnboardingSurveyListResponseDataItemMonthlyDomesticWireAmount];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CommercialOnboardingSurveyListResponseDataItemMonthlyDomesticWireAmount =
  {
    zero: "zero",
    zero_to_ten_thousand_dollars: "zero_to_ten_thousand_dollars",
    ten_thousand_to_fifty_thousand_dollars:
      "ten_thousand_to_fifty_thousand_dollars",
    fifty_thousand_to_one_hundred_thousand_dollars:
      "fifty_thousand_to_one_hundred_thousand_dollars",
    more_than_one_hundred_thousand_dollars:
      "more_than_one_hundred_thousand_dollars",
  } as const;

export type CommercialOnboardingSurveyListResponseDataItemMonthlyDepositAmount =
  (typeof CommercialOnboardingSurveyListResponseDataItemMonthlyDepositAmount)[keyof typeof CommercialOnboardingSurveyListResponseDataItemMonthlyDepositAmount];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CommercialOnboardingSurveyListResponseDataItemMonthlyDepositAmount =
  {
    zero: "zero",
    zero_to_ten_thousand_dollars: "zero_to_ten_thousand_dollars",
    ten_thousand_to_fifty_thousand_dollars:
      "ten_thousand_to_fifty_thousand_dollars",
    fifty_thousand_to_one_hundred_thousand_dollars:
      "fifty_thousand_to_one_hundred_thousand_dollars",
    more_than_one_hundred_thousand_dollars:
      "more_than_one_hundred_thousand_dollars",
  } as const;

export type CommercialOnboardingSurveyListResponseDataItemMonthlyAchOriginationAmount =
  (typeof CommercialOnboardingSurveyListResponseDataItemMonthlyAchOriginationAmount)[keyof typeof CommercialOnboardingSurveyListResponseDataItemMonthlyAchOriginationAmount];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CommercialOnboardingSurveyListResponseDataItemMonthlyAchOriginationAmount =
  {
    zero: "zero",
    zero_to_ten_thousand_dollars: "zero_to_ten_thousand_dollars",
    ten_thousand_to_fifty_thousand_dollars:
      "ten_thousand_to_fifty_thousand_dollars",
    fifty_thousand_to_one_hundred_thousand_dollars:
      "fifty_thousand_to_one_hundred_thousand_dollars",
    more_than_one_hundred_thousand_dollars:
      "more_than_one_hundred_thousand_dollars",
  } as const;

export type CommercialOnboardingSurveyListResponseDataItemArchivedAt =
  | string
  | null;

export type CommercialOnboardingSurveyListResponseDataItem = {
  archived_at: CommercialOnboardingSurveyListResponseDataItemArchivedAt;
  created_at: string;
  entity_id: string;
  entity_name: string;
  group_id: string;
  id: string;
  is_check_casher: boolean;
  is_financial_services_provider: boolean;
  is_internet_gambling_related: boolean;
  is_marijuana_related: boolean;
  issues_negotiable_instruments: boolean;
  issues_stored_value_cards: boolean;
  monthly_ach_origination_amount: CommercialOnboardingSurveyListResponseDataItemMonthlyAchOriginationAmount;
  monthly_deposit_amount: CommercialOnboardingSurveyListResponseDataItemMonthlyDepositAmount;
  monthly_domestic_wire_amount: CommercialOnboardingSurveyListResponseDataItemMonthlyDomesticWireAmount;
  program_id: string;
  receives_credit_debit_or_stored_value_card_payments: boolean;
};

export type CommercialOnboardingSurveyGetResponseMonthlyDomesticWireAmount =
  (typeof CommercialOnboardingSurveyGetResponseMonthlyDomesticWireAmount)[keyof typeof CommercialOnboardingSurveyGetResponseMonthlyDomesticWireAmount];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CommercialOnboardingSurveyGetResponseMonthlyDomesticWireAmount = {
  zero: "zero",
  zero_to_ten_thousand_dollars: "zero_to_ten_thousand_dollars",
  ten_thousand_to_fifty_thousand_dollars:
    "ten_thousand_to_fifty_thousand_dollars",
  fifty_thousand_to_one_hundred_thousand_dollars:
    "fifty_thousand_to_one_hundred_thousand_dollars",
  more_than_one_hundred_thousand_dollars:
    "more_than_one_hundred_thousand_dollars",
} as const;

export type CommercialOnboardingSurveyGetResponseMonthlyDepositAmount =
  (typeof CommercialOnboardingSurveyGetResponseMonthlyDepositAmount)[keyof typeof CommercialOnboardingSurveyGetResponseMonthlyDepositAmount];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CommercialOnboardingSurveyGetResponseMonthlyDepositAmount = {
  zero: "zero",
  zero_to_ten_thousand_dollars: "zero_to_ten_thousand_dollars",
  ten_thousand_to_fifty_thousand_dollars:
    "ten_thousand_to_fifty_thousand_dollars",
  fifty_thousand_to_one_hundred_thousand_dollars:
    "fifty_thousand_to_one_hundred_thousand_dollars",
  more_than_one_hundred_thousand_dollars:
    "more_than_one_hundred_thousand_dollars",
} as const;

export type CommercialOnboardingSurveyGetResponseMonthlyAchOriginationAmount =
  (typeof CommercialOnboardingSurveyGetResponseMonthlyAchOriginationAmount)[keyof typeof CommercialOnboardingSurveyGetResponseMonthlyAchOriginationAmount];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CommercialOnboardingSurveyGetResponseMonthlyAchOriginationAmount =
  {
    zero: "zero",
    zero_to_ten_thousand_dollars: "zero_to_ten_thousand_dollars",
    ten_thousand_to_fifty_thousand_dollars:
      "ten_thousand_to_fifty_thousand_dollars",
    fifty_thousand_to_one_hundred_thousand_dollars:
      "fifty_thousand_to_one_hundred_thousand_dollars",
    more_than_one_hundred_thousand_dollars:
      "more_than_one_hundred_thousand_dollars",
  } as const;

export type CommercialOnboardingSurveyGetResponseArchivedAt = string | null;

export interface CommercialOnboardingSurveyGetResponse {
  archived_at: CommercialOnboardingSurveyGetResponseArchivedAt;
  created_at: string;
  entity_id: string;
  entity_name: string;
  group_id: string;
  id: string;
  is_check_casher: boolean;
  is_financial_services_provider: boolean;
  is_internet_gambling_related: boolean;
  is_marijuana_related: boolean;
  issues_negotiable_instruments: boolean;
  issues_stored_value_cards: boolean;
  monthly_ach_origination_amount: CommercialOnboardingSurveyGetResponseMonthlyAchOriginationAmount;
  monthly_deposit_amount: CommercialOnboardingSurveyGetResponseMonthlyDepositAmount;
  monthly_domestic_wire_amount: CommercialOnboardingSurveyGetResponseMonthlyDomesticWireAmount;
  program_id: string;
  receives_credit_debit_or_stored_value_card_payments: boolean;
}

export type CheckeeperV3WebhookPostResponse = string;

export type CheckTransferPayeeNameAnalysisReviewPostResponseStatus =
  (typeof CheckTransferPayeeNameAnalysisReviewPostResponseStatus)[keyof typeof CheckTransferPayeeNameAnalysisReviewPostResponseStatus];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CheckTransferPayeeNameAnalysisReviewPostResponseStatus = {
  pending_operator_review: "pending_operator_review",
  operator_decisioned: "operator_decisioned",
  complete: "complete",
  requires_attention: "requires_attention",
} as const;

export type CheckTransferPayeeNameAnalysisReviewPostResponseOutcome =
  | "name_match"
  | "name_mismatch"
  | null;

export type CheckTransferPayeeNameAnalysisReviewPostResponseCheckTransferRecipientName =
  string | null;

export type CheckTransferPayeeNameAnalysisReviewPostResponseBestAlgorithm =
  (typeof CheckTransferPayeeNameAnalysisReviewPostResponseBestAlgorithm)[keyof typeof CheckTransferPayeeNameAnalysisReviewPostResponseBestAlgorithm];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CheckTransferPayeeNameAnalysisReviewPostResponseBestAlgorithm = {
  simple_similarity: "simple_similarity",
  cleaned_similarity: "cleaned_similarity",
  cleaned_substring_match: "cleaned_substring_match",
} as const;

export interface CheckTransferPayeeNameAnalysisReviewPostResponse {
  best_algorithm: CheckTransferPayeeNameAnalysisReviewPostResponseBestAlgorithm;
  best_guess: string;
  best_score: number;
  best_target: string;
  best_threshold: number;
  check_item_id: string;
  check_transfer_id: string;
  check_transfer_recipient_name: CheckTransferPayeeNameAnalysisReviewPostResponseCheckTransferRecipientName;
  id: string;
  outcome: CheckTransferPayeeNameAnalysisReviewPostResponseOutcome;
  status: CheckTransferPayeeNameAnalysisReviewPostResponseStatus;
}

export type CheckTransferPayeeNameAnalysisReviewPostParametersOutcome =
  (typeof CheckTransferPayeeNameAnalysisReviewPostParametersOutcome)[keyof typeof CheckTransferPayeeNameAnalysisReviewPostParametersOutcome];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CheckTransferPayeeNameAnalysisReviewPostParametersOutcome = {
  name_match: "name_match",
  name_mismatch: "name_mismatch",
} as const;

export interface CheckTransferPayeeNameAnalysisReviewPostParameters {
  outcome: CheckTransferPayeeNameAnalysisReviewPostParametersOutcome;
}

export type CheckTransferPayeeNameAnalysisGetResponseStatus =
  (typeof CheckTransferPayeeNameAnalysisGetResponseStatus)[keyof typeof CheckTransferPayeeNameAnalysisGetResponseStatus];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CheckTransferPayeeNameAnalysisGetResponseStatus = {
  pending_operator_review: "pending_operator_review",
  operator_decisioned: "operator_decisioned",
  complete: "complete",
  requires_attention: "requires_attention",
} as const;

export type CheckTransferPayeeNameAnalysisGetResponseOutcome =
  | "name_match"
  | "name_mismatch"
  | null;

export type CheckTransferPayeeNameAnalysisGetResponseCheckTransferRecipientName =
  string | null;

export type CheckTransferPayeeNameAnalysisGetResponseBestAlgorithm =
  (typeof CheckTransferPayeeNameAnalysisGetResponseBestAlgorithm)[keyof typeof CheckTransferPayeeNameAnalysisGetResponseBestAlgorithm];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CheckTransferPayeeNameAnalysisGetResponseBestAlgorithm = {
  simple_similarity: "simple_similarity",
  cleaned_similarity: "cleaned_similarity",
  cleaned_substring_match: "cleaned_substring_match",
} as const;

export interface CheckTransferPayeeNameAnalysisGetResponse {
  best_algorithm: CheckTransferPayeeNameAnalysisGetResponseBestAlgorithm;
  best_guess: string;
  best_score: number;
  best_target: string;
  best_threshold: number;
  check_item_id: string;
  check_transfer_id: string;
  check_transfer_recipient_name: CheckTransferPayeeNameAnalysisGetResponseCheckTransferRecipientName;
  id: string;
  outcome: CheckTransferPayeeNameAnalysisGetResponseOutcome;
  status: CheckTransferPayeeNameAnalysisGetResponseStatus;
}

export type CheckTransferLookupGetResponseZip = string | null;

export type CheckTransferLookupGetResponseStatus =
  (typeof CheckTransferLookupGetResponseStatus)[keyof typeof CheckTransferLookupGetResponseStatus];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CheckTransferLookupGetResponseStatus = {
  pending_approval: "pending_approval",
  canceled: "canceled",
  pending_reviewing: "pending_reviewing",
  pending_manual_reviewing: "pending_manual_reviewing",
  requires_attention: "requires_attention",
  flagged_by_operator: "flagged_by_operator",
  rejected: "rejected",
  pending_submission: "pending_submission",
  submitting: "submitting",
  pending_mailing: "pending_mailing",
  mailed: "mailed",
  deposited: "deposited",
  stopped: "stopped",
  returned: "returned",
} as const;

export type CheckTransferLookupGetResponseState = string | null;

export type CheckTransferLookupGetResponseSignerName = string | null;

export type CheckTransferLookupGetResponseRoutingNumber =
  (typeof CheckTransferLookupGetResponseRoutingNumber)[keyof typeof CheckTransferLookupGetResponseRoutingNumber];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CheckTransferLookupGetResponseRoutingNumber = {
  NUMBER_051402372: "051402372",
  NUMBER_053112929: "053112929",
  NUMBER_074014187: "074014187",
  NUMBER_074920909: "074920909",
  NUMBER_091218461: "091218461",
  NUMBER_091218490: "091218490",
  NUMBER_026015024: "026015024",
  NUMBER_026015244: "026015244",
  NUMBER_104001808: "104001808",
  NUMBER_104902114: "104902114",
} as const;

export type CheckTransferLookupGetResponseReturnAddressZip = string | null;

export type CheckTransferLookupGetResponseReturnAddressState = string | null;

export type CheckTransferLookupGetResponseReturnAddressLine2 = string | null;

export type CheckTransferLookupGetResponseReturnAddressLine1 = string | null;

export type CheckTransferLookupGetResponseReturnAddressCity = string | null;

export type CheckTransferLookupGetResponseRecipientName = string | null;

export type CheckTransferLookupGetResponseMessage = string | null;

export type CheckTransferLookupGetResponseInformationalEntityName =
  | string
  | null;

export type CheckTransferLookupGetResponseGroupName = string | null;

export type CheckTransferLookupGetResponseEntityName = string | null;

export type CheckTransferLookupGetResponseCity = string | null;

export type CheckTransferLookupGetResponseAddressLine2 = string | null;

export type CheckTransferLookupGetResponseAddressLine1 = string | null;

export interface CheckTransferLookupGetResponse {
  account_name: string;
  account_number: string;
  address_line1: CheckTransferLookupGetResponseAddressLine1;
  address_line2: CheckTransferLookupGetResponseAddressLine2;
  amount: number;
  check_number: string;
  check_transfer_id: string;
  city: CheckTransferLookupGetResponseCity;
  created_at: string;
  entity_name: CheckTransferLookupGetResponseEntityName;
  group_name: CheckTransferLookupGetResponseGroupName;
  informational_entity_name: CheckTransferLookupGetResponseInformationalEntityName;
  message: CheckTransferLookupGetResponseMessage;
  recipient_name: CheckTransferLookupGetResponseRecipientName;
  return_address_city: CheckTransferLookupGetResponseReturnAddressCity;
  return_address_line1: CheckTransferLookupGetResponseReturnAddressLine1;
  return_address_line2: CheckTransferLookupGetResponseReturnAddressLine2;
  return_address_state: CheckTransferLookupGetResponseReturnAddressState;
  return_address_zip: CheckTransferLookupGetResponseReturnAddressZip;
  routing_number: CheckTransferLookupGetResponseRoutingNumber;
  signer_name: CheckTransferLookupGetResponseSignerName;
  state: CheckTransferLookupGetResponseState;
  status: CheckTransferLookupGetResponseStatus;
  updated_at: string;
  zip: CheckTransferLookupGetResponseZip;
}

export type CheckItemImageViewDataViewDirectiveGetResponse = Blob;

export type CheckItemAllocationSearchDirectiveGetResponseCheckTransferAnyOf = {
  amount: number;
  id: string;
  status: string;
  user_specified_source_account_number: boolean;
};

export type CheckItemAllocationSearchDirectiveGetResponseCheckTransfer =
  CheckItemAllocationSearchDirectiveGetResponseCheckTransferAnyOf | null;

export type CheckItemAllocationSearchDirectiveGetResponseAccountNumberAnyOfGroupName =
  string | null;

export type CheckItemAllocationSearchDirectiveGetResponseAccountNumberAnyOf = {
  account_name: string;
  group_name: CheckItemAllocationSearchDirectiveGetResponseAccountNumberAnyOfGroupName;
  group_nickname: string;
  id: string;
  name: string;
};

export type CheckItemAllocationSearchDirectiveGetResponseAccountNumber =
  CheckItemAllocationSearchDirectiveGetResponseAccountNumberAnyOf | null;

export interface CheckItemAllocationSearchDirectiveGetResponse {
  account_number: CheckItemAllocationSearchDirectiveGetResponseAccountNumber;
  check_transfer: CheckItemAllocationSearchDirectiveGetResponseCheckTransfer;
  has_inbound_checks: boolean;
}

export type CheckItemAllocationReturnDirectivePostResponseAllocationStatus =
  (typeof CheckItemAllocationReturnDirectivePostResponseAllocationStatus)[keyof typeof CheckItemAllocationReturnDirectivePostResponseAllocationStatus];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CheckItemAllocationReturnDirectivePostResponseAllocationStatus = {
  pending_enriching: "pending_enriching",
  pending_allocating: "pending_allocating",
  pending_name_matching: "pending_name_matching",
  allocated: "allocated",
  returned: "returned",
  written_off: "written_off",
  pending_operator_allocating: "pending_operator_allocating",
  allocating: "allocating",
  requires_attention: "requires_attention",
} as const;

export interface CheckItemAllocationReturnDirectivePostResponse {
  allocation_status: CheckItemAllocationReturnDirectivePostResponseAllocationStatus;
}

export type CheckItemAllocationReturnDirectivePostParametersReason =
  (typeof CheckItemAllocationReturnDirectivePostParametersReason)[keyof typeof CheckItemAllocationReturnDirectivePostParametersReason];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CheckItemAllocationReturnDirectivePostParametersReason = {
  ach_route_disabled: "ach_route_disabled",
  ach_route_canceled: "ach_route_canceled",
  altered_or_fictitious: "altered_or_fictitious",
  breaches_limit: "breaches_limit",
  endorsement_irregular: "endorsement_irregular",
  entity_not_active: "entity_not_active",
  group_locked: "group_locked",
  insufficient_funds: "insufficient_funds",
  stop_payment_requested: "stop_payment_requested",
  duplicate_presentment: "duplicate_presentment",
  not_authorized: "not_authorized",
  amount_mismatch: "amount_mismatch",
  not_our_item: "not_our_item",
  no_account_number_found: "no_account_number_found",
  refer_to_image: "refer_to_image",
  unable_to_process: "unable_to_process",
  user_initiated: "user_initiated",
} as const;

export interface CheckItemAllocationReturnDirectivePostParameters {
  account_number_id?: string;
  reason: CheckItemAllocationReturnDirectivePostParametersReason;
}

export type CheckItemAllocationPostResponseAllocationStatus =
  (typeof CheckItemAllocationPostResponseAllocationStatus)[keyof typeof CheckItemAllocationPostResponseAllocationStatus];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CheckItemAllocationPostResponseAllocationStatus = {
  pending_enriching: "pending_enriching",
  pending_allocating: "pending_allocating",
  pending_name_matching: "pending_name_matching",
  allocated: "allocated",
  returned: "returned",
  written_off: "written_off",
  pending_operator_allocating: "pending_operator_allocating",
  allocating: "allocating",
  requires_attention: "requires_attention",
} as const;

export interface CheckItemAllocationPostResponse {
  allocation_status: CheckItemAllocationPostResponseAllocationStatus;
}

export interface CheckItemAllocationPostParameters {
  account_number_id: string;
  check_transfer_id?: string;
}

export type CheckItemAllocationInformationGetResponsePayorBankRoutingNumber =
  | string
  | null;

export type CheckItemAllocationInformationGetResponseOperatorManualQueueItemAnyOf =
  {
    description: string;
    id: string;
  };

export type CheckItemAllocationInformationGetResponseOperatorManualQueueItem =
  CheckItemAllocationInformationGetResponseOperatorManualQueueItemAnyOf | null;

export type CheckItemAllocationInformationGetResponseOnUs = string | null;

export type CheckItemAllocationInformationGetResponseImageViewsItem = {
  image_view_data_id: string;
  view_side_indicator: string;
};

export type CheckItemAllocationInformationGetResponseComputedAccountNumber =
  | string
  | null;

export type CheckItemAllocationInformationGetResponseAuxiliaryOnUs =
  | string
  | null;

export type CheckItemAllocationInformationGetResponseAllocationStatus =
  (typeof CheckItemAllocationInformationGetResponseAllocationStatus)[keyof typeof CheckItemAllocationInformationGetResponseAllocationStatus];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CheckItemAllocationInformationGetResponseAllocationStatus = {
  pending_enriching: "pending_enriching",
  pending_allocating: "pending_allocating",
  pending_name_matching: "pending_name_matching",
  allocated: "allocated",
  returned: "returned",
  written_off: "written_off",
  pending_operator_allocating: "pending_operator_allocating",
  allocating: "allocating",
  requires_attention: "requires_attention",
} as const;

export interface CheckItemAllocationInformationGetResponse {
  allocation_status: CheckItemAllocationInformationGetResponseAllocationStatus;
  amount: number;
  auxiliary_on_us: CheckItemAllocationInformationGetResponseAuxiliaryOnUs;
  computed_account_number: CheckItemAllocationInformationGetResponseComputedAccountNumber;
  id: string;
  image_views: CheckItemAllocationInformationGetResponseImageViewsItem[];
  on_us: CheckItemAllocationInformationGetResponseOnUs;
  operator_manual_queue_item: CheckItemAllocationInformationGetResponseOperatorManualQueueItem;
  payor_bank_routing_number: CheckItemAllocationInformationGetResponsePayorBankRoutingNumber;
}

export type CheckDepositRejectDirectivePostResponseStatus =
  (typeof CheckDepositRejectDirectivePostResponseStatus)[keyof typeof CheckDepositRejectDirectivePostResponseStatus];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CheckDepositRejectDirectivePostResponseStatus = {
  pending_reviewing: "pending_reviewing",
  pending_transfer_reviewing: "pending_transfer_reviewing",
  pending_manual_reviewing: "pending_manual_reviewing",
  pending_submission: "pending_submission",
  submitted: "submitted",
  rejected: "rejected",
  returned: "returned",
  requires_attention: "requires_attention",
} as const;

export type CheckDepositRejectDirectivePostResponseCurrency =
  (typeof CheckDepositRejectDirectivePostResponseCurrency)[keyof typeof CheckDepositRejectDirectivePostResponseCurrency];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CheckDepositRejectDirectivePostResponseCurrency = {
  CAD: "CAD",
  CHF: "CHF",
  EUR: "EUR",
  GBP: "GBP",
  JPY: "JPY",
  USD: "USD",
} as const;

export type CheckDepositRejectDirectivePostResponseCheckDepositReturn =
  CheckDepositRejectDirectivePostResponseCheckDepositReturnAnyOf | null;

export interface CheckDepositRejectDirectivePostResponse {
  account_id: string;
  amount: number;
  back_image_file_id: CheckDepositRejectDirectivePostResponseBackImageFileId;
  check_deposit_acceptance: CheckDepositRejectDirectivePostResponseCheckDepositAcceptance;
  check_deposit_return: CheckDepositRejectDirectivePostResponseCheckDepositReturn;
  created_at: string;
  currency: CheckDepositRejectDirectivePostResponseCurrency;
  front_image_file_id: string;
  group_id: string;
  id: string;
  status: CheckDepositRejectDirectivePostResponseStatus;
}

export type CheckDepositRejectDirectivePostResponseCheckDepositReturnAnyOfReturnReason =
  (typeof CheckDepositRejectDirectivePostResponseCheckDepositReturnAnyOfReturnReason)[keyof typeof CheckDepositRejectDirectivePostResponseCheckDepositReturnAnyOfReturnReason];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CheckDepositRejectDirectivePostResponseCheckDepositReturnAnyOfReturnReason =
  {
    ach_conversion_not_supported: "ach_conversion_not_supported",
    closed_account: "closed_account",
    duplicate_submission: "duplicate_submission",
    insufficient_funds: "insufficient_funds",
    no_account: "no_account",
    not_authorized: "not_authorized",
    stale_dated: "stale_dated",
    stop_payment: "stop_payment",
    unknown_reason: "unknown_reason",
    unmatched_details: "unmatched_details",
    unreadable_image: "unreadable_image",
    endorsement_irregular: "endorsement_irregular",
    altered_or_fictitious_item: "altered_or_fictitious_item",
    frozen_or_blocked_account: "frozen_or_blocked_account",
    post_dated: "post_dated",
    endorsement_missing: "endorsement_missing",
    signature_missing: "signature_missing",
    stop_payment_suspect: "stop_payment_suspect",
    unusable_image: "unusable_image",
    image_fails_security_check: "image_fails_security_check",
    cannot_determine_amount: "cannot_determine_amount",
    signature_irregular: "signature_irregular",
    non_cash_item: "non_cash_item",
    unable_to_process: "unable_to_process",
    item_exceeds_dollar_limit: "item_exceeds_dollar_limit",
    branch_or_account_sold: "branch_or_account_sold",
  } as const;

export type CheckDepositRejectDirectivePostResponseCheckDepositReturnAnyOfCurrency =
  (typeof CheckDepositRejectDirectivePostResponseCheckDepositReturnAnyOfCurrency)[keyof typeof CheckDepositRejectDirectivePostResponseCheckDepositReturnAnyOfCurrency];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CheckDepositRejectDirectivePostResponseCheckDepositReturnAnyOfCurrency =
  {
    CAD: "CAD",
    CHF: "CHF",
    EUR: "EUR",
    GBP: "GBP",
    JPY: "JPY",
    USD: "USD",
  } as const;

export type CheckDepositRejectDirectivePostResponseCheckDepositReturnAnyOf = {
  account_id: string;
  amount: number;
  currency: CheckDepositRejectDirectivePostResponseCheckDepositReturnAnyOfCurrency;
  return_reason: CheckDepositRejectDirectivePostResponseCheckDepositReturnAnyOfReturnReason;
  returned_at: string;
};

export type CheckDepositRejectDirectivePostResponseCheckDepositAcceptanceAnyOfSerialNumber =
  string | null;

export type CheckDepositRejectDirectivePostResponseCheckDepositAcceptanceAnyOfAuxiliaryOnUs =
  string | null;

export type CheckDepositRejectDirectivePostResponseCheckDepositAcceptanceAnyOf =
  {
    account_number: string;
    amount: number;
    auxiliary_on_us: CheckDepositRejectDirectivePostResponseCheckDepositAcceptanceAnyOfAuxiliaryOnUs;
    routing_number: string;
    serial_number: CheckDepositRejectDirectivePostResponseCheckDepositAcceptanceAnyOfSerialNumber;
  };

export type CheckDepositRejectDirectivePostResponseCheckDepositAcceptance =
  CheckDepositRejectDirectivePostResponseCheckDepositAcceptanceAnyOf | null;

export type CheckDepositRejectDirectivePostResponseBackImageFileId =
  | string
  | null;

export type CheckDepositRejectDirectivePostParametersReason =
  (typeof CheckDepositRejectDirectivePostParametersReason)[keyof typeof CheckDepositRejectDirectivePostParametersReason];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CheckDepositRejectDirectivePostParametersReason = {
  incomplete_image: "incomplete_image",
  duplicate: "duplicate",
  poor_image_quality: "poor_image_quality",
  incorrect_amount: "incorrect_amount",
  incorrect_recipient: "incorrect_recipient",
  not_eligible_for_mobile_deposit: "not_eligible_for_mobile_deposit",
  missing_required_data_elements: "missing_required_data_elements",
  suspected_fraud: "suspected_fraud",
  deposit_window_expired: "deposit_window_expired",
  requested_by_user: "requested_by_user",
  unknown: "unknown",
} as const;

export interface CheckDepositRejectDirectivePostParameters {
  reason: CheckDepositRejectDirectivePostParametersReason;
}

export type CheckDepositListResponseNextCursor = string | null;

export type CheckDepositListResponseDataItem = {
  account_id: string;
  amount: number;
  back_image_file_id: CheckDepositListResponseDataItemBackImageFileId;
  check_deposit_acceptance: CheckDepositListResponseDataItemCheckDepositAcceptance;
  check_deposit_return: CheckDepositListResponseDataItemCheckDepositReturn;
  created_at: string;
  currency: CheckDepositListResponseDataItemCurrency;
  front_image_file_id: string;
  group_id: string;
  id: string;
  status: CheckDepositListResponseDataItemStatus;
};

export interface CheckDepositListResponse {
  data: CheckDepositListResponseDataItem[];
  next_cursor: CheckDepositListResponseNextCursor;
}

export type CheckDepositListResponseDataItemStatus =
  (typeof CheckDepositListResponseDataItemStatus)[keyof typeof CheckDepositListResponseDataItemStatus];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CheckDepositListResponseDataItemStatus = {
  pending_reviewing: "pending_reviewing",
  pending_transfer_reviewing: "pending_transfer_reviewing",
  pending_manual_reviewing: "pending_manual_reviewing",
  pending_submission: "pending_submission",
  submitted: "submitted",
  rejected: "rejected",
  returned: "returned",
  requires_attention: "requires_attention",
} as const;

export type CheckDepositListResponseDataItemCurrency =
  (typeof CheckDepositListResponseDataItemCurrency)[keyof typeof CheckDepositListResponseDataItemCurrency];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CheckDepositListResponseDataItemCurrency = {
  CAD: "CAD",
  CHF: "CHF",
  EUR: "EUR",
  GBP: "GBP",
  JPY: "JPY",
  USD: "USD",
} as const;

export type CheckDepositListResponseDataItemCheckDepositReturnAnyOfReturnReason =
  (typeof CheckDepositListResponseDataItemCheckDepositReturnAnyOfReturnReason)[keyof typeof CheckDepositListResponseDataItemCheckDepositReturnAnyOfReturnReason];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CheckDepositListResponseDataItemCheckDepositReturnAnyOfReturnReason =
  {
    ach_conversion_not_supported: "ach_conversion_not_supported",
    closed_account: "closed_account",
    duplicate_submission: "duplicate_submission",
    insufficient_funds: "insufficient_funds",
    no_account: "no_account",
    not_authorized: "not_authorized",
    stale_dated: "stale_dated",
    stop_payment: "stop_payment",
    unknown_reason: "unknown_reason",
    unmatched_details: "unmatched_details",
    unreadable_image: "unreadable_image",
    endorsement_irregular: "endorsement_irregular",
    altered_or_fictitious_item: "altered_or_fictitious_item",
    frozen_or_blocked_account: "frozen_or_blocked_account",
    post_dated: "post_dated",
    endorsement_missing: "endorsement_missing",
    signature_missing: "signature_missing",
    stop_payment_suspect: "stop_payment_suspect",
    unusable_image: "unusable_image",
    image_fails_security_check: "image_fails_security_check",
    cannot_determine_amount: "cannot_determine_amount",
    signature_irregular: "signature_irregular",
    non_cash_item: "non_cash_item",
    unable_to_process: "unable_to_process",
    item_exceeds_dollar_limit: "item_exceeds_dollar_limit",
    branch_or_account_sold: "branch_or_account_sold",
  } as const;

export type CheckDepositListResponseDataItemCheckDepositReturnAnyOfCurrency =
  (typeof CheckDepositListResponseDataItemCheckDepositReturnAnyOfCurrency)[keyof typeof CheckDepositListResponseDataItemCheckDepositReturnAnyOfCurrency];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CheckDepositListResponseDataItemCheckDepositReturnAnyOfCurrency = {
  CAD: "CAD",
  CHF: "CHF",
  EUR: "EUR",
  GBP: "GBP",
  JPY: "JPY",
  USD: "USD",
} as const;

export type CheckDepositListResponseDataItemCheckDepositReturnAnyOf = {
  account_id: string;
  amount: number;
  currency: CheckDepositListResponseDataItemCheckDepositReturnAnyOfCurrency;
  return_reason: CheckDepositListResponseDataItemCheckDepositReturnAnyOfReturnReason;
  returned_at: string;
};

export type CheckDepositListResponseDataItemCheckDepositReturn =
  CheckDepositListResponseDataItemCheckDepositReturnAnyOf | null;

export type CheckDepositListResponseDataItemCheckDepositAcceptanceAnyOfSerialNumber =
  string | null;

export type CheckDepositListResponseDataItemCheckDepositAcceptanceAnyOfAuxiliaryOnUs =
  string | null;

export type CheckDepositListResponseDataItemCheckDepositAcceptanceAnyOf = {
  account_number: string;
  amount: number;
  auxiliary_on_us: CheckDepositListResponseDataItemCheckDepositAcceptanceAnyOfAuxiliaryOnUs;
  routing_number: string;
  serial_number: CheckDepositListResponseDataItemCheckDepositAcceptanceAnyOfSerialNumber;
};

export type CheckDepositListResponseDataItemCheckDepositAcceptance =
  CheckDepositListResponseDataItemCheckDepositAcceptanceAnyOf | null;

export type CheckDepositListResponseDataItemBackImageFileId = string | null;

export type CheckDepositInboundFileViewDirectiveGetResponse = Blob;

export type CheckDepositGetResponseStatus =
  (typeof CheckDepositGetResponseStatus)[keyof typeof CheckDepositGetResponseStatus];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CheckDepositGetResponseStatus = {
  pending_reviewing: "pending_reviewing",
  pending_transfer_reviewing: "pending_transfer_reviewing",
  pending_manual_reviewing: "pending_manual_reviewing",
  pending_submission: "pending_submission",
  submitted: "submitted",
  rejected: "rejected",
  returned: "returned",
  requires_attention: "requires_attention",
} as const;

export type CheckDepositGetResponseCurrency =
  (typeof CheckDepositGetResponseCurrency)[keyof typeof CheckDepositGetResponseCurrency];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CheckDepositGetResponseCurrency = {
  CAD: "CAD",
  CHF: "CHF",
  EUR: "EUR",
  GBP: "GBP",
  JPY: "JPY",
  USD: "USD",
} as const;

export interface CheckDepositGetResponse {
  account_id: string;
  amount: number;
  back_image_file_id: CheckDepositGetResponseBackImageFileId;
  check_deposit_acceptance: CheckDepositGetResponseCheckDepositAcceptance;
  check_deposit_return: CheckDepositGetResponseCheckDepositReturn;
  created_at: string;
  currency: CheckDepositGetResponseCurrency;
  front_image_file_id: string;
  group_id: string;
  id: string;
  status: CheckDepositGetResponseStatus;
}

export type CheckDepositGetResponseCheckDepositReturnAnyOfReturnReason =
  (typeof CheckDepositGetResponseCheckDepositReturnAnyOfReturnReason)[keyof typeof CheckDepositGetResponseCheckDepositReturnAnyOfReturnReason];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CheckDepositGetResponseCheckDepositReturnAnyOfReturnReason = {
  ach_conversion_not_supported: "ach_conversion_not_supported",
  closed_account: "closed_account",
  duplicate_submission: "duplicate_submission",
  insufficient_funds: "insufficient_funds",
  no_account: "no_account",
  not_authorized: "not_authorized",
  stale_dated: "stale_dated",
  stop_payment: "stop_payment",
  unknown_reason: "unknown_reason",
  unmatched_details: "unmatched_details",
  unreadable_image: "unreadable_image",
  endorsement_irregular: "endorsement_irregular",
  altered_or_fictitious_item: "altered_or_fictitious_item",
  frozen_or_blocked_account: "frozen_or_blocked_account",
  post_dated: "post_dated",
  endorsement_missing: "endorsement_missing",
  signature_missing: "signature_missing",
  stop_payment_suspect: "stop_payment_suspect",
  unusable_image: "unusable_image",
  image_fails_security_check: "image_fails_security_check",
  cannot_determine_amount: "cannot_determine_amount",
  signature_irregular: "signature_irregular",
  non_cash_item: "non_cash_item",
  unable_to_process: "unable_to_process",
  item_exceeds_dollar_limit: "item_exceeds_dollar_limit",
  branch_or_account_sold: "branch_or_account_sold",
} as const;

export type CheckDepositGetResponseCheckDepositReturnAnyOfCurrency =
  (typeof CheckDepositGetResponseCheckDepositReturnAnyOfCurrency)[keyof typeof CheckDepositGetResponseCheckDepositReturnAnyOfCurrency];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CheckDepositGetResponseCheckDepositReturnAnyOfCurrency = {
  CAD: "CAD",
  CHF: "CHF",
  EUR: "EUR",
  GBP: "GBP",
  JPY: "JPY",
  USD: "USD",
} as const;

export type CheckDepositGetResponseCheckDepositReturnAnyOf = {
  account_id: string;
  amount: number;
  currency: CheckDepositGetResponseCheckDepositReturnAnyOfCurrency;
  return_reason: CheckDepositGetResponseCheckDepositReturnAnyOfReturnReason;
  returned_at: string;
};

export type CheckDepositGetResponseCheckDepositReturn =
  CheckDepositGetResponseCheckDepositReturnAnyOf | null;

export type CheckDepositGetResponseCheckDepositAcceptance =
  CheckDepositGetResponseCheckDepositAcceptanceAnyOf | null;

export type CheckDepositGetResponseCheckDepositAcceptanceAnyOfSerialNumber =
  | string
  | null;

export type CheckDepositGetResponseCheckDepositAcceptanceAnyOfAuxiliaryOnUs =
  | string
  | null;

export type CheckDepositGetResponseCheckDepositAcceptanceAnyOf = {
  account_number: string;
  amount: number;
  auxiliary_on_us: CheckDepositGetResponseCheckDepositAcceptanceAnyOfAuxiliaryOnUs;
  routing_number: string;
  serial_number: CheckDepositGetResponseCheckDepositAcceptanceAnyOfSerialNumber;
};

export type CheckDepositGetResponseBackImageFileId = string | null;

export interface CheckDepositContextGetResponse {
  account_entity_names: string[];
  potential_duplicates: CheckDepositContextGetResponsePotentialDuplicatesItem[];
}

export type CheckDepositContextGetResponsePotentialDuplicatesItemStatus =
  (typeof CheckDepositContextGetResponsePotentialDuplicatesItemStatus)[keyof typeof CheckDepositContextGetResponsePotentialDuplicatesItemStatus];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CheckDepositContextGetResponsePotentialDuplicatesItemStatus = {
  pending_reviewing: "pending_reviewing",
  pending_transfer_reviewing: "pending_transfer_reviewing",
  pending_manual_reviewing: "pending_manual_reviewing",
  pending_submission: "pending_submission",
  submitted: "submitted",
  rejected: "rejected",
  returned: "returned",
  requires_attention: "requires_attention",
} as const;

export type CheckDepositContextGetResponsePotentialDuplicatesItemCurrency =
  (typeof CheckDepositContextGetResponsePotentialDuplicatesItemCurrency)[keyof typeof CheckDepositContextGetResponsePotentialDuplicatesItemCurrency];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CheckDepositContextGetResponsePotentialDuplicatesItemCurrency = {
  CAD: "CAD",
  CHF: "CHF",
  EUR: "EUR",
  GBP: "GBP",
  JPY: "JPY",
  USD: "USD",
} as const;

export type CheckDepositContextGetResponsePotentialDuplicatesItemCheckDepositReturnAnyOfReturnReason =
  (typeof CheckDepositContextGetResponsePotentialDuplicatesItemCheckDepositReturnAnyOfReturnReason)[keyof typeof CheckDepositContextGetResponsePotentialDuplicatesItemCheckDepositReturnAnyOfReturnReason];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CheckDepositContextGetResponsePotentialDuplicatesItemCheckDepositReturnAnyOfReturnReason =
  {
    ach_conversion_not_supported: "ach_conversion_not_supported",
    closed_account: "closed_account",
    duplicate_submission: "duplicate_submission",
    insufficient_funds: "insufficient_funds",
    no_account: "no_account",
    not_authorized: "not_authorized",
    stale_dated: "stale_dated",
    stop_payment: "stop_payment",
    unknown_reason: "unknown_reason",
    unmatched_details: "unmatched_details",
    unreadable_image: "unreadable_image",
    endorsement_irregular: "endorsement_irregular",
    altered_or_fictitious_item: "altered_or_fictitious_item",
    frozen_or_blocked_account: "frozen_or_blocked_account",
    post_dated: "post_dated",
    endorsement_missing: "endorsement_missing",
    signature_missing: "signature_missing",
    stop_payment_suspect: "stop_payment_suspect",
    unusable_image: "unusable_image",
    image_fails_security_check: "image_fails_security_check",
    cannot_determine_amount: "cannot_determine_amount",
    signature_irregular: "signature_irregular",
    non_cash_item: "non_cash_item",
    unable_to_process: "unable_to_process",
    item_exceeds_dollar_limit: "item_exceeds_dollar_limit",
    branch_or_account_sold: "branch_or_account_sold",
  } as const;

export type CheckDepositContextGetResponsePotentialDuplicatesItemCheckDepositReturnAnyOfCurrency =
  (typeof CheckDepositContextGetResponsePotentialDuplicatesItemCheckDepositReturnAnyOfCurrency)[keyof typeof CheckDepositContextGetResponsePotentialDuplicatesItemCheckDepositReturnAnyOfCurrency];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CheckDepositContextGetResponsePotentialDuplicatesItemCheckDepositReturnAnyOfCurrency =
  {
    CAD: "CAD",
    CHF: "CHF",
    EUR: "EUR",
    GBP: "GBP",
    JPY: "JPY",
    USD: "USD",
  } as const;

export type CheckDepositContextGetResponsePotentialDuplicatesItemCheckDepositReturnAnyOf =
  {
    account_id: string;
    amount: number;
    currency: CheckDepositContextGetResponsePotentialDuplicatesItemCheckDepositReturnAnyOfCurrency;
    return_reason: CheckDepositContextGetResponsePotentialDuplicatesItemCheckDepositReturnAnyOfReturnReason;
    returned_at: string;
  };

export type CheckDepositContextGetResponsePotentialDuplicatesItemCheckDepositReturn =
  CheckDepositContextGetResponsePotentialDuplicatesItemCheckDepositReturnAnyOf | null;

export type CheckDepositContextGetResponsePotentialDuplicatesItemCheckDepositAcceptance =
  CheckDepositContextGetResponsePotentialDuplicatesItemCheckDepositAcceptanceAnyOf | null;

export type CheckDepositContextGetResponsePotentialDuplicatesItem = {
  account_id: string;
  amount: number;
  back_image_file_id: CheckDepositContextGetResponsePotentialDuplicatesItemBackImageFileId;
  check_deposit_acceptance: CheckDepositContextGetResponsePotentialDuplicatesItemCheckDepositAcceptance;
  check_deposit_return: CheckDepositContextGetResponsePotentialDuplicatesItemCheckDepositReturn;
  created_at: string;
  currency: CheckDepositContextGetResponsePotentialDuplicatesItemCurrency;
  front_image_file_id: string;
  group_id: string;
  id: string;
  status: CheckDepositContextGetResponsePotentialDuplicatesItemStatus;
};

export type CheckDepositContextGetResponsePotentialDuplicatesItemCheckDepositAcceptanceAnyOfSerialNumber =
  string | null;

export type CheckDepositContextGetResponsePotentialDuplicatesItemCheckDepositAcceptanceAnyOfAuxiliaryOnUs =
  string | null;

export type CheckDepositContextGetResponsePotentialDuplicatesItemCheckDepositAcceptanceAnyOf =
  {
    account_number: string;
    amount: number;
    auxiliary_on_us: CheckDepositContextGetResponsePotentialDuplicatesItemCheckDepositAcceptanceAnyOfAuxiliaryOnUs;
    routing_number: string;
    serial_number: CheckDepositContextGetResponsePotentialDuplicatesItemCheckDepositAcceptanceAnyOfSerialNumber;
  };

export type CheckDepositContextGetResponsePotentialDuplicatesItemBackImageFileId =
  string | null;

export type CheckDepositApproveDirectivePostResponseSerialNumber =
  | string
  | null;

export type CheckDepositApproveDirectivePostResponseAuxiliaryOnUs =
  | string
  | null;

export interface CheckDepositApproveDirectivePostResponse {
  account_number: string;
  amount: number;
  auxiliary_on_us: CheckDepositApproveDirectivePostResponseAuxiliaryOnUs;
  routing_number: string;
  serial_number: CheckDepositApproveDirectivePostResponseSerialNumber;
}

export interface CheckDepositApproveDirectivePostParameters {
  account_number: string;
  auxiliary_on_us?: string;
  processed_front_image_data_url: string;
  processed_rear_image_data_url: string;
  routing_number: string;
  serial_number?: string;
}

export type ChallengePostResponse = string;

export interface ChallengePostParameters {
  challenge_request: unknown;
  one_time_code?: string;
  session_data?: string;
}

export type CashReconciliationUnreconciledExpectedCashTransactionTupleListResponseUnreconciledExpectedCashTransactionTuplesItem =
  {
    arbitrary_example_id: string;
    count: number;
    financial_institution_reconcilement_data_settlement_date: string;
    financial_institution_reconcilement_data_transaction_code: string;
    sum: number;
  };

export interface CashReconciliationUnreconciledExpectedCashTransactionTupleListResponse {
  timed_out: boolean;
  unreconciled_expected_cash_transaction_tuples: CashReconciliationUnreconciledExpectedCashTransactionTupleListResponseUnreconciledExpectedCashTransactionTuplesItem[];
}

export type CashReconciliationUnreconciledExpectedCashTransactionTupleDetailsGetResponseDetailsItemWireDetails =
  CashReconciliationUnreconciledExpectedCashTransactionTupleDetailsGetResponseDetailsItemWireDetailsAnyOf | null;

export type CashReconciliationUnreconciledExpectedCashTransactionTupleDetailsGetResponseDetailsItem =
  {
    ach_details: CashReconciliationUnreconciledExpectedCashTransactionTupleDetailsGetResponseDetailsItemAchDetails;
    arbitrary_example_id: string;
    check21_details: CashReconciliationUnreconciledExpectedCashTransactionTupleDetailsGetResponseDetailsItemCheck21Details;
    count: number;
    sum: number;
    wire_details: CashReconciliationUnreconciledExpectedCashTransactionTupleDetailsGetResponseDetailsItemWireDetails;
  };

export interface CashReconciliationUnreconciledExpectedCashTransactionTupleDetailsGetResponse {
  details: CashReconciliationUnreconciledExpectedCashTransactionTupleDetailsGetResponseDetailsItem[];
}

export type CashReconciliationUnreconciledExpectedCashTransactionTupleDetailsGetResponseDetailsItemWireDetailsAnyOf =
  {
    input_message_accountability_data: string;
  };

export type CashReconciliationUnreconciledExpectedCashTransactionTupleDetailsGetResponseDetailsItemCheck21Details =
  CashReconciliationUnreconciledExpectedCashTransactionTupleDetailsGetResponseDetailsItemCheck21DetailsAnyOf | null;

export type CashReconciliationUnreconciledExpectedCashTransactionTupleDetailsGetResponseDetailsItemCheck21DetailsAnyOfOriginalCashLetterAmount =
  number | null;

export type CashReconciliationUnreconciledExpectedCashTransactionTupleDetailsGetResponseDetailsItemCheck21DetailsAnyOfGroupingKey =
  string | null;

export type CashReconciliationUnreconciledExpectedCashTransactionTupleDetailsGetResponseDetailsItemCheck21DetailsAnyOfDaylightOverdraftPostingTime =
  string | null;

export type CashReconciliationUnreconciledExpectedCashTransactionTupleDetailsGetResponseDetailsItemCheck21DetailsAnyOf =
  {
    daylight_overdraft_posting_time: CashReconciliationUnreconciledExpectedCashTransactionTupleDetailsGetResponseDetailsItemCheck21DetailsAnyOfDaylightOverdraftPostingTime;
    grouping_key: CashReconciliationUnreconciledExpectedCashTransactionTupleDetailsGetResponseDetailsItemCheck21DetailsAnyOfGroupingKey;
    original_cash_letter_amount: CashReconciliationUnreconciledExpectedCashTransactionTupleDetailsGetResponseDetailsItemCheck21DetailsAnyOfOriginalCashLetterAmount;
  };

export type CashReconciliationUnreconciledExpectedCashTransactionTupleDetailsGetResponseDetailsItemAchDetailsAnyOfProcessingDate =
  string | null;

export type CashReconciliationUnreconciledExpectedCashTransactionTupleDetailsGetResponseDetailsItemAchDetailsAnyOf =
  {
    debit_credit_indicator: string;
    processing_date: CashReconciliationUnreconciledExpectedCashTransactionTupleDetailsGetResponseDetailsItemAchDetailsAnyOfProcessingDate;
  };

export type CashReconciliationUnreconciledExpectedCashTransactionTupleDetailsGetResponseDetailsItemAchDetails =
  CashReconciliationUnreconciledExpectedCashTransactionTupleDetailsGetResponseDetailsItemAchDetailsAnyOf | null;

export interface CashReconciliationOverviewGetResponse {
  configured_for_account: boolean;
  unreconciled_financial_institution_reconcilement_data_detail_entries: CashReconciliationOverviewGetResponseUnreconciledFinancialInstitutionReconcilementDataDetailEntriesItem[];
  unreconciled_statement_of_account_details: CashReconciliationOverviewGetResponseUnreconciledStatementOfAccountDetailsItem[];
}

export type CashReconciliationOverviewGetResponseUnreconciledStatementOfAccountDetailsItemAdditionalInformationExtra =
  string | null;

export type CashReconciliationOverviewGetResponseUnreconciledStatementOfAccountDetailsItemAdditionalInformationCheck21OriginalCashLetterAmount =
  number | null;

export type CashReconciliationOverviewGetResponseUnreconciledStatementOfAccountDetailsItemAdditionalInformation =
  {
    check21_original_cash_letter_amount: CashReconciliationOverviewGetResponseUnreconciledStatementOfAccountDetailsItemAdditionalInformationCheck21OriginalCashLetterAmount;
    extra: CashReconciliationOverviewGetResponseUnreconciledStatementOfAccountDetailsItemAdditionalInformationExtra;
    report_type: string;
  };

export type CashReconciliationOverviewGetResponseUnreconciledStatementOfAccountDetailsItem =
  {
    additional_information: CashReconciliationOverviewGetResponseUnreconciledStatementOfAccountDetailsItemAdditionalInformation;
    amount: number;
    id: string;
    parsed_transaction_code: string;
    settlement_date: string;
    transaction_code: string;
  };

export type CashReconciliationOverviewGetResponseUnreconciledFinancialInstitutionReconcilementDataDetailEntriesItemWireDetailsAnyOf =
  {
    input_message_accountability_data: string;
  };

export type CashReconciliationOverviewGetResponseUnreconciledFinancialInstitutionReconcilementDataDetailEntriesItemWireDetails =
  CashReconciliationOverviewGetResponseUnreconciledFinancialInstitutionReconcilementDataDetailEntriesItemWireDetailsAnyOf | null;

export type CashReconciliationOverviewGetResponseUnreconciledFinancialInstitutionReconcilementDataDetailEntriesItem =
  {
    ach_details: CashReconciliationOverviewGetResponseUnreconciledFinancialInstitutionReconcilementDataDetailEntriesItemAchDetails;
    action: CashReconciliationOverviewGetResponseUnreconciledFinancialInstitutionReconcilementDataDetailEntriesItemAction;
    amount: number;
    check21_details: CashReconciliationOverviewGetResponseUnreconciledFinancialInstitutionReconcilementDataDetailEntriesItemCheck21Details;
    id: string;
    parsed_transaction_code: string;
    settlement_date: string;
    transaction_code: string;
    wire_details: CashReconciliationOverviewGetResponseUnreconciledFinancialInstitutionReconcilementDataDetailEntriesItemWireDetails;
  };

export type CashReconciliationOverviewGetResponseUnreconciledFinancialInstitutionReconcilementDataDetailEntriesItemCheck21DetailsAnyOfOriginalCashLetterAmount =
  number | null;

export type CashReconciliationOverviewGetResponseUnreconciledFinancialInstitutionReconcilementDataDetailEntriesItemCheck21DetailsAnyOfGroupingKey =
  string | null;

export type CashReconciliationOverviewGetResponseUnreconciledFinancialInstitutionReconcilementDataDetailEntriesItemCheck21DetailsAnyOfDaylightOverdraftPostingTime =
  string | null;

export type CashReconciliationOverviewGetResponseUnreconciledFinancialInstitutionReconcilementDataDetailEntriesItemCheck21DetailsAnyOf =
  {
    daylight_overdraft_posting_time: CashReconciliationOverviewGetResponseUnreconciledFinancialInstitutionReconcilementDataDetailEntriesItemCheck21DetailsAnyOfDaylightOverdraftPostingTime;
    grouping_key: CashReconciliationOverviewGetResponseUnreconciledFinancialInstitutionReconcilementDataDetailEntriesItemCheck21DetailsAnyOfGroupingKey;
    original_cash_letter_amount: CashReconciliationOverviewGetResponseUnreconciledFinancialInstitutionReconcilementDataDetailEntriesItemCheck21DetailsAnyOfOriginalCashLetterAmount;
  };

export type CashReconciliationOverviewGetResponseUnreconciledFinancialInstitutionReconcilementDataDetailEntriesItemCheck21Details =
  CashReconciliationOverviewGetResponseUnreconciledFinancialInstitutionReconcilementDataDetailEntriesItemCheck21DetailsAnyOf | null;

export type CashReconciliationOverviewGetResponseUnreconciledFinancialInstitutionReconcilementDataDetailEntriesItemAction =

    | "create_inbound_check_deposit_adjustment_from_financial_institution_reconcilement_data_detail_entry"
    | null;

export type CashReconciliationOverviewGetResponseUnreconciledFinancialInstitutionReconcilementDataDetailEntriesItemAchDetailsAnyOfProcessingDate =
  string | null;

export type CashReconciliationOverviewGetResponseUnreconciledFinancialInstitutionReconcilementDataDetailEntriesItemAchDetailsAnyOf =
  {
    debit_credit_indicator: string;
    processing_date: CashReconciliationOverviewGetResponseUnreconciledFinancialInstitutionReconcilementDataDetailEntriesItemAchDetailsAnyOfProcessingDate;
  };

export type CashReconciliationOverviewGetResponseUnreconciledFinancialInstitutionReconcilementDataDetailEntriesItemAchDetails =
  CashReconciliationOverviewGetResponseUnreconciledFinancialInstitutionReconcilementDataDetailEntriesItemAchDetailsAnyOf | null;

export type CardTokenPostResponseVaultCardVerificationValue2Token =
  | string
  | null;

export type CardTokenPostResponseVaultCardVerificationValue2ExpiresAt =
  | string
  | null;

export interface CardTokenPostResponse {
  expiration: string;
  group_id: string;
  id: string;
  last4: string;
  prefix: string;
  primary_account_number_length: number;
  vault_card_token: string;
  vault_card_verification_value2_expires_at: CardTokenPostResponseVaultCardVerificationValue2ExpiresAt;
  vault_card_verification_value2_token: CardTokenPostResponseVaultCardVerificationValue2Token;
}

export interface CardTokenPostParameters {
  expiration: string;
  hashed_bearer_credential: string;
  last4: string;
  prefix: string;
  primary_account_number_length: number;
  vault_card_token: string;
  vault_card_verification_value2_expires_at?: string;
  vault_card_verification_value2_token?: string;
}

export type CardPaymentGetResponseState = {
  authorized_amount: number;
  fuel_confirmed_amount: number;
  incremented_amount: number;
  reversed_amount: number;
  settled_amount: number;
};

export interface CardPaymentGetResponse {
  account_id: string;
  card_id: string;
  created_at: string;
  id: string;
  state: CardPaymentGetResponseState;
}

export type CardListResponseNextCursor = string | null;

export interface CardListResponse {
  data: CardListResponseDataItem[];
  next_cursor: CardListResponseNextCursor;
}

export type CardListResponseDataItemStatus =
  (typeof CardListResponseDataItemStatus)[keyof typeof CardListResponseDataItemStatus];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CardListResponseDataItemStatus = {
  active: "active",
  disabled: "disabled",
  canceled: "canceled",
} as const;

export type CardListResponseDataItemEntityId = string | null;

export type CardListResponseDataItemDescription = string | null;

export type CardListResponseDataItem = {
  account_id: string;
  created_at: string;
  description: CardListResponseDataItemDescription;
  entity_id: CardListResponseDataItemEntityId;
  expiration_month: number;
  expiration_year: number;
  group_id: string;
  id: string;
  last4: string;
  status: CardListResponseDataItemStatus;
};

export type CardGetResponseStatus =
  (typeof CardGetResponseStatus)[keyof typeof CardGetResponseStatus];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CardGetResponseStatus = {
  active: "active",
  disabled: "disabled",
  canceled: "canceled",
} as const;

export type CardGetResponseEntityId = string | null;

export type CardGetResponseDescription = string | null;

export interface CardGetResponse {
  account_id: string;
  created_at: string;
  description: CardGetResponseDescription;
  entity_id: CardGetResponseEntityId;
  expiration_month: number;
  expiration_year: number;
  group_id: string;
  id: string;
  last4: string;
  status: CardGetResponseStatus;
}

export type CardDisputeListResponseNextCursor = string | null;

export interface CardDisputeListResponse {
  data: CardDisputeListResponseDataItem[];
  next_cursor: CardDisputeListResponseNextCursor;
}

export type CardDisputeListResponseDataItemStatus =
  (typeof CardDisputeListResponseDataItemStatus)[keyof typeof CardDisputeListResponseDataItemStatus];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CardDisputeListResponseDataItemStatus = {
  pending_reviewing: "pending_reviewing",
  pending_user_information: "pending_user_information",
  accepted: "accepted",
  rejected: "rejected",
  lost: "lost",
  won: "won",
} as const;

export type CardDisputeListResponseDataItemPartialAmount = number | null;

export type CardDisputeListResponseDataItemEntityName = string | null;

export type CardDisputeListResponseDataItem = {
  account_id: string;
  card_id: string;
  created_at: string;
  entity_name: CardDisputeListResponseDataItemEntityName;
  explanation: string;
  group_id: string;
  id: string;
  partial_amount: CardDisputeListResponseDataItemPartialAmount;
  status: CardDisputeListResponseDataItemStatus;
  transaction_amount: number;
};

export interface CardDeclineGetResponse {
  account_id: string;
  card_id: string;
  created_at: string;
  declined_transaction_id: string;
  id: string;
}

export type BookkeepingEntrySetGetResponseTransactionId = string | null;

export type BookkeepingEntrySetGetResponseEntriesItemBookkeepingAccountComplianceCategory =
  "commingled_cash" | "customer_balance" | null;

export type BookkeepingEntrySetGetResponseEntriesItem = {
  amount: number;
  bookkeeping_account_compliance_category: BookkeepingEntrySetGetResponseEntriesItemBookkeepingAccountComplianceCategory;
  bookkeeping_account_id: string;
  bookkeeping_account_name: string;
  id: string;
};

export interface BookkeepingEntrySetGetResponse {
  created_at: string;
  date: string;
  entries: BookkeepingEntrySetGetResponseEntriesItem[];
  group_id: string;
  id: string;
  transaction_id: BookkeepingEntrySetGetResponseTransactionId;
}

export type BookkeepingEntryListResponseNextCursor = string | null;

export interface BookkeepingEntryListResponse {
  data: BookkeepingEntryListResponseDataItem[];
  next_cursor: BookkeepingEntryListResponseNextCursor;
}

export type BookkeepingEntryListResponseDataItemEntrySetTransactionId =
  | string
  | null;

export type BookkeepingEntryListResponseDataItemEntrySet = {
  date: string;
  id: string;
  transaction_id: BookkeepingEntryListResponseDataItemEntrySetTransactionId;
};

export type BookkeepingEntryListResponseDataItem = {
  account: BookkeepingEntryListResponseDataItemAccount;
  amount: number;
  created_at: string;
  entry_set: BookkeepingEntryListResponseDataItemEntrySet;
  group_id: string;
  id: string;
};

export type BookkeepingEntryListResponseDataItemAccountEntityName =
  | string
  | null;

export type BookkeepingEntryListResponseDataItemAccountEntityId = string | null;

export type BookkeepingEntryListResponseDataItemAccountComplianceCategory =
  | "commingled_cash"
  | "customer_balance"
  | null;

export type BookkeepingEntryListResponseDataItemAccountCashAccountName =
  | string
  | null;

export type BookkeepingEntryListResponseDataItemAccountCashAccountId =
  | string
  | null;

export type BookkeepingEntryListResponseDataItemAccount = {
  cash_account_id: BookkeepingEntryListResponseDataItemAccountCashAccountId;
  cash_account_name: BookkeepingEntryListResponseDataItemAccountCashAccountName;
  compliance_category: BookkeepingEntryListResponseDataItemAccountComplianceCategory;
  entity_id: BookkeepingEntryListResponseDataItemAccountEntityId;
  entity_name: BookkeepingEntryListResponseDataItemAccountEntityName;
  id: string;
  name: string;
};

export type BookkeepingAccountListResponseNextCursor = string | null;

export interface BookkeepingAccountListResponse {
  data: BookkeepingAccountListResponseDataItem[];
  next_cursor: BookkeepingAccountListResponseNextCursor;
}

export type BookkeepingAccountListResponseDataItemEntityName = string | null;

export type BookkeepingAccountListResponseDataItemEntityId = string | null;

export type BookkeepingAccountListResponseDataItemComplianceCategory =
  | "commingled_cash"
  | "customer_balance"
  | null;

export type BookkeepingAccountListResponseDataItemAccountName = string | null;

export type BookkeepingAccountListResponseDataItemAccountId = string | null;

export type BookkeepingAccountListResponseDataItem = {
  account_id: BookkeepingAccountListResponseDataItemAccountId;
  account_name: BookkeepingAccountListResponseDataItemAccountName;
  balance: number;
  compliance_category: BookkeepingAccountListResponseDataItemComplianceCategory;
  created_at: string;
  entity_id: BookkeepingAccountListResponseDataItemEntityId;
  entity_name: BookkeepingAccountListResponseDataItemEntityName;
  group_id: string;
  id: string;
  name: string;
};

export type BookkeepingAccountGetResponseEntityName = string | null;

export type BookkeepingAccountGetResponseEntityId = string | null;

export type BookkeepingAccountGetResponseComplianceCategory =
  | "commingled_cash"
  | "customer_balance"
  | null;

export type BookkeepingAccountGetResponseAccountName = string | null;

export type BookkeepingAccountGetResponseAccountId = string | null;

export interface BookkeepingAccountGetResponse {
  account_id: BookkeepingAccountGetResponseAccountId;
  account_name: BookkeepingAccountGetResponseAccountName;
  balance: number;
  compliance_category: BookkeepingAccountGetResponseComplianceCategory;
  created_at: string;
  entity_id: BookkeepingAccountGetResponseEntityId;
  entity_name: BookkeepingAccountGetResponseEntityName;
  group_id: string;
  id: string;
  name: string;
}

export type BeneficialOwnerRelationshipListResponseNextCursor = string | null;

export interface BeneficialOwnerRelationshipListResponse {
  data: BeneficialOwnerRelationshipListResponseDataItem[];
  next_cursor: BeneficialOwnerRelationshipListResponseNextCursor;
}

export type BeneficialOwnerRelationshipListResponseDataItemSubmittedCompanyTitle =
  string | null;

export type BeneficialOwnerRelationshipListResponseDataItemProng =
  (typeof BeneficialOwnerRelationshipListResponseDataItemProng)[keyof typeof BeneficialOwnerRelationshipListResponseDataItemProng];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const BeneficialOwnerRelationshipListResponseDataItemProng = {
  ownership: "ownership",
  control: "control",
} as const;

export type BeneficialOwnerRelationshipListResponseDataItem = {
  archived: boolean;
  entity_id: string;
  entity_setup_beneficial_owner_submission_id: string;
  entity_setup_beneficial_owner_submission_name: string;
  entity_setup_corporation_submission_id: string;
  entity_setup_corporation_submission_name: string;
  id: string;
  prong: BeneficialOwnerRelationshipListResponseDataItemProng;
  submitted_company_title: BeneficialOwnerRelationshipListResponseDataItemSubmittedCompanyTitle;
};

export type BeneficialOwnerGetResponseTaxIdValidationStatus =
  (typeof BeneficialOwnerGetResponseTaxIdValidationStatus)[keyof typeof BeneficialOwnerGetResponseTaxIdValidationStatus];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const BeneficialOwnerGetResponseTaxIdValidationStatus = {
  pending_validating: "pending_validating",
  update_pending_validating: "update_pending_validating",
  requires_attention: "requires_attention",
  failed: "failed",
  validated: "validated",
  not_provided: "not_provided",
  skipped: "skipped",
} as const;

export type BeneficialOwnerGetResponseSubmittedIdentificationNumberLast4 =
  | string
  | null;

export type BeneficialOwnerGetResponseSubmittedIdentificationMethod =
  (typeof BeneficialOwnerGetResponseSubmittedIdentificationMethod)[keyof typeof BeneficialOwnerGetResponseSubmittedIdentificationMethod];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const BeneficialOwnerGetResponseSubmittedIdentificationMethod = {
  social_security_number: "social_security_number",
  individual_taxpayer_identification_number:
    "individual_taxpayer_identification_number",
  passport: "passport",
  drivers_license: "drivers_license",
  other: "other",
} as const;

export interface BeneficialOwnerGetResponse {
  created_at: string;
  id: string;
  identity_document_submissions: BeneficialOwnerGetResponseIdentityDocumentSubmissionsItem[];
  identity_verification_status: BeneficialOwnerGetResponseIdentityVerificationStatus;
  microbilt: BeneficialOwnerGetResponseMicrobiltItem[];
  submission_sovos_response_id: BeneficialOwnerGetResponseSubmissionSovosResponseId;
  submitted_address: BeneficialOwnerGetResponseSubmittedAddress;
  submitted_date_of_birth: string;
  submitted_identification_method: BeneficialOwnerGetResponseSubmittedIdentificationMethod;
  submitted_identification_number_last4: BeneficialOwnerGetResponseSubmittedIdentificationNumberLast4;
  submitted_name: string;
  tax_id_validation_status: BeneficialOwnerGetResponseTaxIdValidationStatus;
}

export type BeneficialOwnerGetResponseSubmittedAddressLine2 = string | null;

export type BeneficialOwnerGetResponseSubmittedAddress = {
  city: string;
  line1: string;
  line2: BeneficialOwnerGetResponseSubmittedAddressLine2;
  state: string;
  zip: string;
};

export type BeneficialOwnerGetResponseSubmissionSovosResponseId = string | null;

export type BeneficialOwnerGetResponseMicrobiltItemTaxIdType = "SSN" | null;

export type BeneficialOwnerGetResponseMicrobiltItemTaxIdLast4 = string | null;

export type BeneficialOwnerGetResponseMicrobiltItemReturnCode =
  (typeof BeneficialOwnerGetResponseMicrobiltItemReturnCode)[keyof typeof BeneficialOwnerGetResponseMicrobiltItemReturnCode];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const BeneficialOwnerGetResponseMicrobiltItemReturnCode = {
  OK: "OK",
} as const;

export type BeneficialOwnerGetResponseMicrobiltItemMiddleName = string | null;

export type BeneficialOwnerGetResponseMicrobiltItemDeathDate = string | null;

export type BeneficialOwnerGetResponseMicrobiltItemBirthDate = string | null;

export type BeneficialOwnerGetResponseMicrobiltItem = {
  birth_date: BeneficialOwnerGetResponseMicrobiltItemBirthDate;
  created_at: string;
  death_date: BeneficialOwnerGetResponseMicrobiltItemDeathDate;
  first_name: string;
  id: string;
  last_name: string;
  middle_name: BeneficialOwnerGetResponseMicrobiltItemMiddleName;
  request_id: string;
  response: string;
  return_code: BeneficialOwnerGetResponseMicrobiltItemReturnCode;
  tax_id_last_4: BeneficialOwnerGetResponseMicrobiltItemTaxIdLast4;
  tax_id_type: BeneficialOwnerGetResponseMicrobiltItemTaxIdType;
};

export type BeneficialOwnerGetResponseIdentityVerificationStatus =
  (typeof BeneficialOwnerGetResponseIdentityVerificationStatus)[keyof typeof BeneficialOwnerGetResponseIdentityVerificationStatus];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const BeneficialOwnerGetResponseIdentityVerificationStatus = {
  pending_verification: "pending_verification",
  update_pending_verification: "update_pending_verification",
  verified: "verified",
  falsified: "falsified",
  no_match: "no_match",
  verifying: "verifying",
  requires_attention: "requires_attention",
  not_performed: "not_performed",
  skipped: "skipped",
} as const;

export type BeneficialOwnerGetResponseIdentityDocumentSubmissionsItemSubmittedState =
  string | null;

export type BeneficialOwnerGetResponseIdentityDocumentSubmissionsItemSubmittedExpirationDate =
  string | null;

export type BeneficialOwnerGetResponseIdentityDocumentSubmissionsItemSubmittedDocumentType =
  (typeof BeneficialOwnerGetResponseIdentityDocumentSubmissionsItemSubmittedDocumentType)[keyof typeof BeneficialOwnerGetResponseIdentityDocumentSubmissionsItemSubmittedDocumentType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const BeneficialOwnerGetResponseIdentityDocumentSubmissionsItemSubmittedDocumentType =
  {
    passport: "passport",
    us_drivers_license: "us_drivers_license",
    other: "other",
  } as const;

export type BeneficialOwnerGetResponseIdentityDocumentSubmissionsItemSubmittedDocumentNumber =
  string | null;

export type BeneficialOwnerGetResponseIdentityDocumentSubmissionsItemSubmittedAddressLine2 =
  string | null;

export type BeneficialOwnerGetResponseIdentityDocumentSubmissionsItemSubmittedAddress =
  {
    city: string;
    line1: string;
    line2: BeneficialOwnerGetResponseIdentityDocumentSubmissionsItemSubmittedAddressLine2;
    state: string;
    zip: string;
  };

export type BeneficialOwnerGetResponseIdentityDocumentSubmissionsItemIdentityVerificationStatus =
  (typeof BeneficialOwnerGetResponseIdentityDocumentSubmissionsItemIdentityVerificationStatus)[keyof typeof BeneficialOwnerGetResponseIdentityDocumentSubmissionsItemIdentityVerificationStatus];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const BeneficialOwnerGetResponseIdentityDocumentSubmissionsItemIdentityVerificationStatus =
  {
    pending_verification: "pending_verification",
    update_pending_verification: "update_pending_verification",
    verified: "verified",
    falsified: "falsified",
    no_match: "no_match",
    verifying: "verifying",
    requires_attention: "requires_attention",
    not_performed: "not_performed",
    skipped: "skipped",
  } as const;

export type BeneficialOwnerGetResponseIdentityDocumentSubmissionsItemApiBackFileId =
  string | null;

export type BeneficialOwnerGetResponseIdentityDocumentSubmissionsItem = {
  api_back_file_id: BeneficialOwnerGetResponseIdentityDocumentSubmissionsItemApiBackFileId;
  api_file_id: string;
  created_at: string;
  group_id: string;
  id: string;
  identity_verification_status: BeneficialOwnerGetResponseIdentityDocumentSubmissionsItemIdentityVerificationStatus;
  submitted_address: BeneficialOwnerGetResponseIdentityDocumentSubmissionsItemSubmittedAddress;
  submitted_country: string;
  submitted_date_of_birth: string;
  submitted_document_number: BeneficialOwnerGetResponseIdentityDocumentSubmissionsItemSubmittedDocumentNumber;
  submitted_document_type: BeneficialOwnerGetResponseIdentityDocumentSubmissionsItemSubmittedDocumentType;
  submitted_expiration_date: BeneficialOwnerGetResponseIdentityDocumentSubmissionsItemSubmittedExpirationDate;
  submitted_name: string;
  submitted_state: BeneficialOwnerGetResponseIdentityDocumentSubmissionsItemSubmittedState;
};

export type BankProgramListResponseNextCursor = string | null;

export interface BankProgramListResponse {
  data: BankProgramListResponseDataItem[];
  next_cursor: BankProgramListResponseNextCursor;
}

export type BankProgramListResponseDataItemOpenedAt = string | null;

export type BankProgramListResponseDataItemMarketingRiskRating =
  | "high"
  | "moderate"
  | "low"
  | null;

export type BankProgramListResponseDataItemDnaMirrorAccountNumber =
  | string
  | null;

export type BankProgramListResponseDataItemClosedAt = string | null;

export type BankProgramListResponseDataItemClassification =
  | "direct_user"
  | "regulated_user"
  | "money_transmitter"
  | "unlicensed_lender"
  | null;

export type BankProgramListResponseDataItemBank =
  (typeof BankProgramListResponseDataItemBank)[keyof typeof BankProgramListResponseDataItemBank];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const BankProgramListResponseDataItemBank = {
  blue_ridge_bank: "blue_ridge_bank",
  core_bank: "core_bank",
  first_internet_bank: "first_internet_bank",
  global_innovations_bank: "global_innovations_bank",
  grasshopper_bank: "grasshopper_bank",
} as const;

export type BankProgramListResponseDataItem = {
  bank: BankProgramListResponseDataItemBank;
  classification: BankProgramListResponseDataItemClassification;
  closed_at: BankProgramListResponseDataItemClosedAt;
  created_at: string;
  dna_mirror_account_number: BankProgramListResponseDataItemDnaMirrorAccountNumber;
  id: string;
  marketing_risk_rating: BankProgramListResponseDataItemMarketingRiskRating;
  name: string;
  opened_at: BankProgramListResponseDataItemOpenedAt;
};

export type BankProgramGetResponseOpenedAt = string | null;

export type BankProgramGetResponseMarketingRiskRating =
  | "high"
  | "moderate"
  | "low"
  | null;

export type BankProgramGetResponseDnaMirrorAccountNumber = string | null;

export type BankProgramGetResponseClosedAt = string | null;

export type BankProgramGetResponseClassification =
  | "direct_user"
  | "regulated_user"
  | "money_transmitter"
  | "unlicensed_lender"
  | null;

export type BankProgramGetResponseBank =
  (typeof BankProgramGetResponseBank)[keyof typeof BankProgramGetResponseBank];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const BankProgramGetResponseBank = {
  blue_ridge_bank: "blue_ridge_bank",
  core_bank: "core_bank",
  first_internet_bank: "first_internet_bank",
  global_innovations_bank: "global_innovations_bank",
  grasshopper_bank: "grasshopper_bank",
} as const;

export interface BankProgramGetResponse {
  bank: BankProgramGetResponseBank;
  classification: BankProgramGetResponseClassification;
  closed_at: BankProgramGetResponseClosedAt;
  created_at: string;
  dna_mirror_account_number: BankProgramGetResponseDnaMirrorAccountNumber;
  id: string;
  marketing_risk_rating: BankProgramGetResponseMarketingRiskRating;
  name: string;
  opened_at: BankProgramGetResponseOpenedAt;
}

export interface BankProgramCurrentFeePlanGetResponse {
  /** @pattern ^-?\d+(\.\d+)?$ */
  ach_future_dated_origination_fee: string;
  /** @pattern ^-?\d+(\.\d+)?$ */
  ach_return_fee: string;
  /** @pattern ^-?\d+(\.\d+)?$ */
  ach_same_day_origination_fee: string;
  bank_program_id: string;
  /** @pattern ^-?\d+(\.\d+)?$ */
  card_payment_fee_rate: string;
  /** @pattern ^-?\d+(\.\d+)?$ */
  check_origination_fee: string;
  created_at: string;
  effective_date: string;
  id: string;
  /** @pattern ^-?\d+(\.\d+)?$ */
  minimum_card_transaction_fee: string;
  /** @pattern ^-?\d+(\.\d+)?$ */
  minimum_non_card_transaction_fee: string;
  monthly_base_fee: number;
  /** @pattern ^-?\d+(\.\d+)?$ */
  monthly_consumer_entity_fee: string;
  /** @pattern ^-?\d+(\.\d+)?$ */
  real_time_payments_transfer_origination_fee: string;
  /** @pattern ^-?\d+(\.\d+)?$ */
  service_fee_rate: string;
  updated_at: string;
  /** @pattern ^-?\d+(\.\d+)?$ */
  wire_origination_fee: string;
}

export type BankFeeStatementListResponseNextCursor = string | null;

export interface BankFeeStatementListResponse {
  data: BankFeeStatementListResponseDataItem[];
  next_cursor: BankFeeStatementListResponseNextCursor;
}

export type BankFeeStatementListResponseDataItemStatus =
  (typeof BankFeeStatementListResponseDataItemStatus)[keyof typeof BankFeeStatementListResponseDataItemStatus];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const BankFeeStatementListResponseDataItemStatus = {
  open: "open",
  closed: "closed",
} as const;

export type BankFeeStatementListResponseDataItemCachedServiceFeeTotal =
  | number
  | null;

export type BankFeeStatementListResponseDataItem = {
  bank_fee_plan_id: string;
  bank_program_id: string;
  cached_bank_fee_statement_summaries: BankFeeStatementListResponseDataItemCachedBankFeeStatementSummariesItem[];
  cached_service_fee_total: BankFeeStatementListResponseDataItemCachedServiceFeeTotal;
  created_at: string;
  id: string;
  period_start: string;
  status: BankFeeStatementListResponseDataItemStatus;
  updated_at: string;
};

export type BankFeeStatementListResponseDataItemCachedBankFeeStatementSummariesItemFeeType =
  (typeof BankFeeStatementListResponseDataItemCachedBankFeeStatementSummariesItemFeeType)[keyof typeof BankFeeStatementListResponseDataItemCachedBankFeeStatementSummariesItemFeeType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const BankFeeStatementListResponseDataItemCachedBankFeeStatementSummariesItemFeeType =
  {
    implementation: "implementation",
    correction: "correction",
    check_origination: "check_origination",
    ach_same_day_origination: "ach_same_day_origination",
    ach_next_day_origination: "ach_next_day_origination",
    ach_return: "ach_return",
    ach_unauthorized_return: "ach_unauthorized_return",
    wire_transfer_origination: "wire_transfer_origination",
    real_time_payments_transfer_origination:
      "real_time_payments_transfer_origination",
    card_volume: "card_volume",
    monthly_base_fee: "monthly_base_fee",
    consumer_entity: "consumer_entity",
    non_card_transaction_minimum: "non_card_transaction_minimum",
    card_transaction_minimum: "card_transaction_minimum",
  } as const;

export type BankFeeStatementListResponseDataItemCachedBankFeeStatementSummariesItem =
  {
    amount: number;
    count: number;
    fee_type: BankFeeStatementListResponseDataItemCachedBankFeeStatementSummariesItemFeeType;
    id: string;
  };

export type BankFeeStatementGetResponseStatus =
  (typeof BankFeeStatementGetResponseStatus)[keyof typeof BankFeeStatementGetResponseStatus];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const BankFeeStatementGetResponseStatus = {
  open: "open",
  closed: "closed",
} as const;

export type BankFeeStatementGetResponseCachedServiceFeeTotal = number | null;

export type BankFeeStatementGetResponseCachedBankFeeStatementSummariesItemFeeType =
  (typeof BankFeeStatementGetResponseCachedBankFeeStatementSummariesItemFeeType)[keyof typeof BankFeeStatementGetResponseCachedBankFeeStatementSummariesItemFeeType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const BankFeeStatementGetResponseCachedBankFeeStatementSummariesItemFeeType =
  {
    implementation: "implementation",
    correction: "correction",
    check_origination: "check_origination",
    ach_same_day_origination: "ach_same_day_origination",
    ach_next_day_origination: "ach_next_day_origination",
    ach_return: "ach_return",
    ach_unauthorized_return: "ach_unauthorized_return",
    wire_transfer_origination: "wire_transfer_origination",
    real_time_payments_transfer_origination:
      "real_time_payments_transfer_origination",
    card_volume: "card_volume",
    monthly_base_fee: "monthly_base_fee",
    consumer_entity: "consumer_entity",
    non_card_transaction_minimum: "non_card_transaction_minimum",
    card_transaction_minimum: "card_transaction_minimum",
  } as const;

export type BankFeeStatementGetResponseCachedBankFeeStatementSummariesItem = {
  amount: number;
  count: number;
  fee_type: BankFeeStatementGetResponseCachedBankFeeStatementSummariesItemFeeType;
  id: string;
};

export interface BankFeeStatementGetResponse {
  bank_fee_plan_id: string;
  bank_program_id: string;
  cached_bank_fee_statement_summaries: BankFeeStatementGetResponseCachedBankFeeStatementSummariesItem[];
  cached_service_fee_total: BankFeeStatementGetResponseCachedServiceFeeTotal;
  created_at: string;
  id: string;
  period_start: string;
  status: BankFeeStatementGetResponseStatus;
  updated_at: string;
}

export type BankFeeListResponseNextCursor = string | null;

export interface BankFeeListResponse {
  data: BankFeeListResponseDataItem[];
  next_cursor: BankFeeListResponseNextCursor;
}

export type BankFeeListResponseDataItemRecordType = string | null;

export type BankFeeListResponseDataItemRecordId = string | null;

export type BankFeeListResponseDataItemFeeType =
  (typeof BankFeeListResponseDataItemFeeType)[keyof typeof BankFeeListResponseDataItemFeeType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const BankFeeListResponseDataItemFeeType = {
  implementation: "implementation",
  correction: "correction",
  check_origination: "check_origination",
  ach_same_day_origination: "ach_same_day_origination",
  ach_next_day_origination: "ach_next_day_origination",
  ach_return: "ach_return",
  ach_unauthorized_return: "ach_unauthorized_return",
  wire_transfer_origination: "wire_transfer_origination",
  real_time_payments_transfer_origination:
    "real_time_payments_transfer_origination",
  card_volume: "card_volume",
  monthly_base_fee: "monthly_base_fee",
  consumer_entity: "consumer_entity",
  non_card_transaction_minimum: "non_card_transaction_minimum",
  card_transaction_minimum: "card_transaction_minimum",
} as const;

export type BankFeeListResponseDataItem = {
  /** @pattern ^-?\d+(\.\d+)?$ */
  amount: string;
  bank_fee_plan_id: string;
  bank_fee_statement_id: string;
  bank_program_id: string;
  date: string;
  fee_type: BankFeeListResponseDataItemFeeType;
  id: string;
  record_id: BankFeeListResponseDataItemRecordId;
  record_type: BankFeeListResponseDataItemRecordType;
};

export type AuthorizationMessagePostResponse = string;

export type AuthorizationMessagePostParametersMissingReferencesReason =
  (typeof AuthorizationMessagePostParametersMissingReferencesReason)[keyof typeof AuthorizationMessagePostParametersMissingReferencesReason];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const AuthorizationMessagePostParametersMissingReferencesReason = {
  card_not_found: "card_not_found",
  internal_error: "internal_error",
} as const;

export interface AuthorizationMessagePostParameters {
  missing_references_reason?: AuthorizationMessagePostParametersMissingReferencesReason;
  references?: unknown;
  request_message_8583: string;
}

export type AuthenticationPostResponse = string;

export interface AuthenticationPostParameters {
  authentication_request: unknown;
  card_id: string;
}

export type AttestationPatchResponseAttestedTo =
  (typeof AttestationPatchResponseAttestedTo)[keyof typeof AttestationPatchResponseAttestedTo];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const AttestationPatchResponseAttestedTo = {
  security_awareness_training_completed:
    "security_awareness_training_completed",
  full_disk_encryption_enabled: "full_disk_encryption_enabled",
  cardholder_data_security_policy_acknowledged:
    "cardholder_data_security_policy_acknowledged",
} as const;

export type AttestationPatchResponseAttestedAt = string | null;

export interface AttestationPatchResponse {
  attestation_body: string;
  attested_at: AttestationPatchResponseAttestedAt;
  attested_to: AttestationPatchResponseAttestedTo;
  created_at: string;
  id: string;
  operator_id: string;
  pretty_name: string;
}

export interface AttestationPatchParameters {
  attested: boolean;
}

export type AttestationListResponseNextCursor = string | null;

export interface AttestationListResponse {
  data: AttestationListResponseDataItem[];
  next_cursor: AttestationListResponseNextCursor;
}

export type AttestationListResponseDataItemAttestedTo =
  (typeof AttestationListResponseDataItemAttestedTo)[keyof typeof AttestationListResponseDataItemAttestedTo];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const AttestationListResponseDataItemAttestedTo = {
  security_awareness_training_completed:
    "security_awareness_training_completed",
  full_disk_encryption_enabled: "full_disk_encryption_enabled",
  cardholder_data_security_policy_acknowledged:
    "cardholder_data_security_policy_acknowledged",
} as const;

export type AttestationListResponseDataItemAttestedAt = string | null;

export type AttestationListResponseDataItem = {
  attestation_body: string;
  attested_at: AttestationListResponseDataItemAttestedAt;
  attested_to: AttestationListResponseDataItemAttestedTo;
  created_at: string;
  id: string;
  operator_id: string;
  pretty_name: string;
};

export type AttestationGetResponseAttestedTo =
  (typeof AttestationGetResponseAttestedTo)[keyof typeof AttestationGetResponseAttestedTo];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const AttestationGetResponseAttestedTo = {
  security_awareness_training_completed:
    "security_awareness_training_completed",
  full_disk_encryption_enabled: "full_disk_encryption_enabled",
  cardholder_data_security_policy_acknowledged:
    "cardholder_data_security_policy_acknowledged",
} as const;

export type AttestationGetResponseAttestedAt = string | null;

export interface AttestationGetResponse {
  attestation_body: string;
  attested_at: AttestationGetResponseAttestedAt;
  attested_to: AttestationGetResponseAttestedTo;
  created_at: string;
  id: string;
  operator_id: string;
  pretty_name: string;
}

export type AccountStatementListResponseNextCursor = string | null;

export type AccountStatementListResponseDataItemStatus =
  (typeof AccountStatementListResponseDataItemStatus)[keyof typeof AccountStatementListResponseDataItemStatus];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const AccountStatementListResponseDataItemStatus = {
  valid: "valid",
  archived: "archived",
} as const;

export type AccountStatementListResponseDataItem = {
  account_id: string;
  created_at: string;
  file_id: string;
  id: string;
  statement_period_end: string;
  statement_period_start: string;
  status: AccountStatementListResponseDataItemStatus;
};

export interface AccountStatementListResponse {
  data: AccountStatementListResponseDataItem[];
  next_cursor: AccountStatementListResponseNextCursor;
}

export type AccountStatementGetResponseStatus =
  (typeof AccountStatementGetResponseStatus)[keyof typeof AccountStatementGetResponseStatus];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const AccountStatementGetResponseStatus = {
  valid: "valid",
  archived: "archived",
} as const;

export interface AccountStatementGetResponse {
  account_id: string;
  created_at: string;
  file_id: string;
  id: string;
  statement_period_end: string;
  statement_period_start: string;
  status: AccountStatementGetResponseStatus;
}

export type AccountNumberListResponseNextCursor = string | null;

export interface AccountNumberListResponse {
  data: AccountNumberListResponseDataItem[];
  next_cursor: AccountNumberListResponseNextCursor;
}

export type AccountNumberListResponseDataItemStatus =
  (typeof AccountNumberListResponseDataItemStatus)[keyof typeof AccountNumberListResponseDataItemStatus];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const AccountNumberListResponseDataItemStatus = {
  active: "active",
  disabled: "disabled",
  canceled: "canceled",
} as const;

export type AccountNumberListResponseDataItemInboundCheckStatus =
  (typeof AccountNumberListResponseDataItemInboundCheckStatus)[keyof typeof AccountNumberListResponseDataItemInboundCheckStatus];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const AccountNumberListResponseDataItemInboundCheckStatus = {
  allowed: "allowed",
  check_transfers_only: "check_transfers_only",
} as const;

export type AccountNumberListResponseDataItemGroupName = string | null;

export type AccountNumberListResponseDataItemAchDebitStatus =
  (typeof AccountNumberListResponseDataItemAchDebitStatus)[keyof typeof AccountNumberListResponseDataItemAchDebitStatus];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const AccountNumberListResponseDataItemAchDebitStatus = {
  allowed: "allowed",
  blocked: "blocked",
} as const;

export type AccountNumberListResponseDataItem = {
  account_id: string;
  account_name: string;
  account_number: string;
  ach_debit_status: AccountNumberListResponseDataItemAchDebitStatus;
  created_at: string;
  group_id: string;
  group_name: AccountNumberListResponseDataItemGroupName;
  id: string;
  inbound_check_status: AccountNumberListResponseDataItemInboundCheckStatus;
  name: string;
  routing_number: string;
  status: AccountNumberListResponseDataItemStatus;
};

export type AccountNumberGetResponseStatus =
  (typeof AccountNumberGetResponseStatus)[keyof typeof AccountNumberGetResponseStatus];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const AccountNumberGetResponseStatus = {
  active: "active",
  disabled: "disabled",
  canceled: "canceled",
} as const;

export type AccountNumberGetResponseInboundCheckStatus =
  (typeof AccountNumberGetResponseInboundCheckStatus)[keyof typeof AccountNumberGetResponseInboundCheckStatus];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const AccountNumberGetResponseInboundCheckStatus = {
  allowed: "allowed",
  check_transfers_only: "check_transfers_only",
} as const;

export type AccountNumberGetResponseGroupName = string | null;

export type AccountNumberGetResponseAchDebitStatus =
  (typeof AccountNumberGetResponseAchDebitStatus)[keyof typeof AccountNumberGetResponseAchDebitStatus];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const AccountNumberGetResponseAchDebitStatus = {
  allowed: "allowed",
  blocked: "blocked",
} as const;

export interface AccountNumberGetResponse {
  account_id: string;
  account_name: string;
  account_number: string;
  ach_debit_status: AccountNumberGetResponseAchDebitStatus;
  created_at: string;
  group_id: string;
  group_name: AccountNumberGetResponseGroupName;
  id: string;
  inbound_check_status: AccountNumberGetResponseInboundCheckStatus;
  name: string;
  routing_number: string;
  status: AccountNumberGetResponseStatus;
}

export type AccountListResponseNextCursor = string | null;

export interface AccountListResponse {
  data: AccountListResponseDataItem[];
  next_cursor: AccountListResponseNextCursor;
}

export type AccountListResponseDataItemTitle = string | null;

export type AccountListResponseDataItemStatus =
  (typeof AccountListResponseDataItemStatus)[keyof typeof AccountListResponseDataItemStatus];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const AccountListResponseDataItemStatus = {
  closed: "closed",
  open: "open",
  pending_opening: "pending_opening",
} as const;

export type AccountListResponseDataItemRestrictions =
  | "no_deposits"
  | "no_withdrawals"
  | null;

export type AccountListResponseDataItemReplacementAccountId = string | null;

export type AccountListResponseDataItemOpenedAt = string | null;

export type AccountListResponseDataItemInterestRate = {
  /** @pattern ^-?\d+(\.\d+)?$ */
  current_value: string;
  summary: string;
};

export type AccountListResponseDataItemInformationalEntityName = string | null;

export type AccountListResponseDataItemInformationalEntityId = string | null;

export type AccountListResponseDataItemGroupName = string | null;

export type AccountListResponseDataItemEntityName = string | null;

export type AccountListResponseDataItemEntityId = string | null;

export type AccountListResponseDataItemDepositAccountControlAgreementAnyOf = {
  counterparty_bank_name: string;
  file_id: string;
  id: string;
};

export type AccountListResponseDataItemDepositAccountControlAgreement =
  AccountListResponseDataItemDepositAccountControlAgreementAnyOf | null;

export type AccountListResponseDataItemCurrency =
  (typeof AccountListResponseDataItemCurrency)[keyof typeof AccountListResponseDataItemCurrency];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const AccountListResponseDataItemCurrency = {
  CAD: "CAD",
  CHF: "CHF",
  EUR: "EUR",
  GBP: "GBP",
  JPY: "JPY",
  USD: "USD",
} as const;

export type AccountListResponseDataItemClosedAt = string | null;

export type AccountListResponseDataItemBookkeepingExceptionReason =
  | "transit_account"
  | "fraud_suspense_account"
  | "out_of_band_bookkeeping"
  | null;

export type AccountListResponseDataItemBank =
  (typeof AccountListResponseDataItemBank)[keyof typeof AccountListResponseDataItemBank];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const AccountListResponseDataItemBank = {
  blue_ridge_bank: "blue_ridge_bank",
  core_bank: "core_bank",
  first_internet_bank: "first_internet_bank",
  global_innovations_bank: "global_innovations_bank",
  grasshopper_bank: "grasshopper_bank",
} as const;

export type AccountListResponseDataItem = {
  available_balance: number;
  bank: AccountListResponseDataItemBank;
  bookkeeping_exception_reason: AccountListResponseDataItemBookkeepingExceptionReason;
  closed_at: AccountListResponseDataItemClosedAt;
  created_at: string;
  currency: AccountListResponseDataItemCurrency;
  current_balance: number;
  deposit_account_control_agreement: AccountListResponseDataItemDepositAccountControlAgreement;
  entity_id: AccountListResponseDataItemEntityId;
  entity_name: AccountListResponseDataItemEntityName;
  group_id: string;
  group_name: AccountListResponseDataItemGroupName;
  id: string;
  informational_entity_id: AccountListResponseDataItemInformationalEntityId;
  informational_entity_name: AccountListResponseDataItemInformationalEntityName;
  interest_rate: AccountListResponseDataItemInterestRate;
  name: string;
  opened_at: AccountListResponseDataItemOpenedAt;
  program_id: string;
  program_name: string;
  replacement_account_id: AccountListResponseDataItemReplacementAccountId;
  restrictions: AccountListResponseDataItemRestrictions;
  status: AccountListResponseDataItemStatus;
  title: AccountListResponseDataItemTitle;
};

export type AccountGetResponseTitle = string | null;

export type AccountGetResponseStatus =
  (typeof AccountGetResponseStatus)[keyof typeof AccountGetResponseStatus];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const AccountGetResponseStatus = {
  closed: "closed",
  open: "open",
  pending_opening: "pending_opening",
} as const;

export type AccountGetResponseRestrictions =
  | "no_deposits"
  | "no_withdrawals"
  | null;

export type AccountGetResponseReplacementAccountId = string | null;

export type AccountGetResponseOpenedAt = string | null;

export type AccountGetResponseInterestRate = {
  /** @pattern ^-?\d+(\.\d+)?$ */
  current_value: string;
  summary: string;
};

export type AccountGetResponseInformationalEntityName = string | null;

export type AccountGetResponseInformationalEntityId = string | null;

export type AccountGetResponseGroupName = string | null;

export type AccountGetResponseEntityName = string | null;

export type AccountGetResponseEntityId = string | null;

export type AccountGetResponseDepositAccountControlAgreementAnyOf = {
  counterparty_bank_name: string;
  file_id: string;
  id: string;
};

export type AccountGetResponseDepositAccountControlAgreement =
  AccountGetResponseDepositAccountControlAgreementAnyOf | null;

export type AccountGetResponseCurrency =
  (typeof AccountGetResponseCurrency)[keyof typeof AccountGetResponseCurrency];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const AccountGetResponseCurrency = {
  CAD: "CAD",
  CHF: "CHF",
  EUR: "EUR",
  GBP: "GBP",
  JPY: "JPY",
  USD: "USD",
} as const;

export type AccountGetResponseClosedAt = string | null;

export type AccountGetResponseBookkeepingExceptionReason =
  | "transit_account"
  | "fraud_suspense_account"
  | "out_of_band_bookkeeping"
  | null;

export type AccountGetResponseBank =
  (typeof AccountGetResponseBank)[keyof typeof AccountGetResponseBank];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const AccountGetResponseBank = {
  blue_ridge_bank: "blue_ridge_bank",
  core_bank: "core_bank",
  first_internet_bank: "first_internet_bank",
  global_innovations_bank: "global_innovations_bank",
  grasshopper_bank: "grasshopper_bank",
} as const;

export interface AccountGetResponse {
  available_balance: number;
  bank: AccountGetResponseBank;
  bookkeeping_exception_reason: AccountGetResponseBookkeepingExceptionReason;
  closed_at: AccountGetResponseClosedAt;
  created_at: string;
  currency: AccountGetResponseCurrency;
  current_balance: number;
  deposit_account_control_agreement: AccountGetResponseDepositAccountControlAgreement;
  entity_id: AccountGetResponseEntityId;
  entity_name: AccountGetResponseEntityName;
  group_id: string;
  group_name: AccountGetResponseGroupName;
  id: string;
  informational_entity_id: AccountGetResponseInformationalEntityId;
  informational_entity_name: AccountGetResponseInformationalEntityName;
  interest_rate: AccountGetResponseInterestRate;
  name: string;
  opened_at: AccountGetResponseOpenedAt;
  program_id: string;
  program_name: string;
  replacement_account_id: AccountGetResponseReplacementAccountId;
  restrictions: AccountGetResponseRestrictions;
  status: AccountGetResponseStatus;
  title: AccountGetResponseTitle;
}

export type AccountByAccountNumberGetResponseTitle = string | null;

export type AccountByAccountNumberGetResponseStatus =
  (typeof AccountByAccountNumberGetResponseStatus)[keyof typeof AccountByAccountNumberGetResponseStatus];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const AccountByAccountNumberGetResponseStatus = {
  closed: "closed",
  open: "open",
  pending_opening: "pending_opening",
} as const;

export type AccountByAccountNumberGetResponseRestrictions =
  | "no_deposits"
  | "no_withdrawals"
  | null;

export type AccountByAccountNumberGetResponseReplacementAccountId =
  | string
  | null;

export type AccountByAccountNumberGetResponseOpenedAt = string | null;

export type AccountByAccountNumberGetResponseInterestRate = {
  /** @pattern ^-?\d+(\.\d+)?$ */
  current_value: string;
  summary: string;
};

export type AccountByAccountNumberGetResponseInformationalEntityName =
  | string
  | null;

export type AccountByAccountNumberGetResponseInformationalEntityId =
  | string
  | null;

export type AccountByAccountNumberGetResponseGroupName = string | null;

export type AccountByAccountNumberGetResponseEntityName = string | null;

export type AccountByAccountNumberGetResponseEntityId = string | null;

export type AccountByAccountNumberGetResponseDepositAccountControlAgreementAnyOf =
  {
    counterparty_bank_name: string;
    file_id: string;
    id: string;
  };

export type AccountByAccountNumberGetResponseDepositAccountControlAgreement =
  AccountByAccountNumberGetResponseDepositAccountControlAgreementAnyOf | null;

export type AccountByAccountNumberGetResponseCurrency =
  (typeof AccountByAccountNumberGetResponseCurrency)[keyof typeof AccountByAccountNumberGetResponseCurrency];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const AccountByAccountNumberGetResponseCurrency = {
  CAD: "CAD",
  CHF: "CHF",
  EUR: "EUR",
  GBP: "GBP",
  JPY: "JPY",
  USD: "USD",
} as const;

export type AccountByAccountNumberGetResponseClosedAt = string | null;

export type AccountByAccountNumberGetResponseBookkeepingExceptionReason =
  | "transit_account"
  | "fraud_suspense_account"
  | "out_of_band_bookkeeping"
  | null;

export type AccountByAccountNumberGetResponseBank =
  (typeof AccountByAccountNumberGetResponseBank)[keyof typeof AccountByAccountNumberGetResponseBank];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const AccountByAccountNumberGetResponseBank = {
  blue_ridge_bank: "blue_ridge_bank",
  core_bank: "core_bank",
  first_internet_bank: "first_internet_bank",
  global_innovations_bank: "global_innovations_bank",
  grasshopper_bank: "grasshopper_bank",
} as const;

export interface AccountByAccountNumberGetResponse {
  available_balance: number;
  bank: AccountByAccountNumberGetResponseBank;
  bookkeeping_exception_reason: AccountByAccountNumberGetResponseBookkeepingExceptionReason;
  closed_at: AccountByAccountNumberGetResponseClosedAt;
  created_at: string;
  currency: AccountByAccountNumberGetResponseCurrency;
  current_balance: number;
  deposit_account_control_agreement: AccountByAccountNumberGetResponseDepositAccountControlAgreement;
  entity_id: AccountByAccountNumberGetResponseEntityId;
  entity_name: AccountByAccountNumberGetResponseEntityName;
  group_id: string;
  group_name: AccountByAccountNumberGetResponseGroupName;
  id: string;
  informational_entity_id: AccountByAccountNumberGetResponseInformationalEntityId;
  informational_entity_name: AccountByAccountNumberGetResponseInformationalEntityName;
  interest_rate: AccountByAccountNumberGetResponseInterestRate;
  name: string;
  opened_at: AccountByAccountNumberGetResponseOpenedAt;
  program_id: string;
  program_name: string;
  replacement_account_id: AccountByAccountNumberGetResponseReplacementAccountId;
  restrictions: AccountByAccountNumberGetResponseRestrictions;
  status: AccountByAccountNumberGetResponseStatus;
  title: AccountByAccountNumberGetResponseTitle;
}

export type AccountBalanceGetResponseCurrency =
  (typeof AccountBalanceGetResponseCurrency)[keyof typeof AccountBalanceGetResponseCurrency];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const AccountBalanceGetResponseCurrency = {
  CAD: "CAD",
  CHF: "CHF",
  EUR: "EUR",
  GBP: "GBP",
  JPY: "JPY",
  USD: "USD",
} as const;

export interface AccountBalanceGetResponse {
  account_id: string;
  available_balance: number;
  currency: AccountBalanceGetResponseCurrency;
  current_balance: number;
  date: string;
  /** @pattern ^-?\d+(\.\d+)?$ */
  interest_rate: string;
}

export type APIInboundFileViewDirectiveGetResponse = Blob;

export type ACHTransferLookupGetResponseStatus =
  (typeof ACHTransferLookupGetResponseStatus)[keyof typeof ACHTransferLookupGetResponseStatus];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ACHTransferLookupGetResponseStatus = {
  pending_approval: "pending_approval",
  pending_transfer_session_confirmation:
    "pending_transfer_session_confirmation",
  canceled: "canceled",
  pending_reviewing: "pending_reviewing",
  pending_manual_reviewing: "pending_manual_reviewing",
  flagged_by_operator: "flagged_by_operator",
  requires_attention: "requires_attention",
  rejected: "rejected",
  pending_submission: "pending_submission",
  pending_file_including: "pending_file_including",
  submitted: "submitted",
  returned: "returned",
} as const;

export type ACHTransferLookupGetResponseStandardEntryClassCode =
  (typeof ACHTransferLookupGetResponseStandardEntryClassCode)[keyof typeof ACHTransferLookupGetResponseStandardEntryClassCode];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ACHTransferLookupGetResponseStandardEntryClassCode = {
  corporate_credit_or_debit: "corporate_credit_or_debit",
  corporate_trade_exchange: "corporate_trade_exchange",
  prearranged_payments_and_deposit: "prearranged_payments_and_deposit",
  internet_initiated: "internet_initiated",
} as const;

export type ACHTransferLookupGetResponseInformationalEntityName = string | null;

export type ACHTransferLookupGetResponseIndividualName = string | null;

export type ACHTransferLookupGetResponseIndividualId = string | null;

export type ACHTransferLookupGetResponseGroupName = string | null;

export type ACHTransferLookupGetResponseEntityName = string | null;

export type ACHTransferLookupGetResponseCompanyName = string | null;

export type ACHTransferLookupGetResponseCompanyEntryDescription = string | null;

export type ACHTransferLookupGetResponseCompanyDiscretionaryData =
  | string
  | null;

export type ACHTransferLookupGetResponseCompanyDescriptiveDate = string | null;

export type ACHTransferLookupGetResponseAddendum = string | null;

export interface ACHTransferLookupGetResponse {
  account_name: string;
  account_number: string;
  ach_transfer_id: string;
  addendum: ACHTransferLookupGetResponseAddendum;
  amount: number;
  company_descriptive_date: ACHTransferLookupGetResponseCompanyDescriptiveDate;
  company_discretionary_data: ACHTransferLookupGetResponseCompanyDiscretionaryData;
  company_entry_description: ACHTransferLookupGetResponseCompanyEntryDescription;
  company_name: ACHTransferLookupGetResponseCompanyName;
  created_at: string;
  entity_name: ACHTransferLookupGetResponseEntityName;
  group_name: ACHTransferLookupGetResponseGroupName;
  individual_id: ACHTransferLookupGetResponseIndividualId;
  individual_name: ACHTransferLookupGetResponseIndividualName;
  informational_entity_name: ACHTransferLookupGetResponseInformationalEntityName;
  routing_number: string;
  standard_entry_class_code: ACHTransferLookupGetResponseStandardEntryClassCode;
  statement_descriptor: string;
  status: ACHTransferLookupGetResponseStatus;
  updated_at: string;
}

export type ACHTransferGetResponseTransferSessionId = string | null;

export type ACHTransferGetResponseTransferReviewingManualDenialAnyOf = {
  created_at: string;
  operator_email: string;
};

export type ACHTransferGetResponseTransferReviewingManualDenial =
  ACHTransferGetResponseTransferReviewingManualDenialAnyOf | null;

export type ACHTransferGetResponseTransferReviewingManualApprovalAnyOf = {
  created_at: string;
  operator_email: string;
};

export type ACHTransferGetResponseTransferReviewingManualApproval =
  ACHTransferGetResponseTransferReviewingManualApprovalAnyOf | null;

export type ACHTransferGetResponseTransactionId = string | null;

export type ACHTransferGetResponseSubmissionAnyOfExpectedSettlementSchedule =
  (typeof ACHTransferGetResponseSubmissionAnyOfExpectedSettlementSchedule)[keyof typeof ACHTransferGetResponseSubmissionAnyOfExpectedSettlementSchedule];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ACHTransferGetResponseSubmissionAnyOfExpectedSettlementSchedule = {
  same_day: "same_day",
  future_dated: "future_dated",
} as const;

export type ACHTransferGetResponseSubmissionAnyOf = {
  effective_date: string;
  expected_funds_settlement_at: string;
  expected_settlement_schedule: ACHTransferGetResponseSubmissionAnyOfExpectedSettlementSchedule;
  submitted_at: string;
  trace_number: string;
};

export type ACHTransferGetResponseSubmission =
  ACHTransferGetResponseSubmissionAnyOf | null;

export type ACHTransferGetResponseStatus =
  (typeof ACHTransferGetResponseStatus)[keyof typeof ACHTransferGetResponseStatus];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ACHTransferGetResponseStatus = {
  pending_approval: "pending_approval",
  pending_transfer_session_confirmation:
    "pending_transfer_session_confirmation",
  canceled: "canceled",
  pending_reviewing: "pending_reviewing",
  pending_manual_reviewing: "pending_manual_reviewing",
  flagged_by_operator: "flagged_by_operator",
  requires_attention: "requires_attention",
  rejected: "rejected",
  pending_submission: "pending_submission",
  pending_file_including: "pending_file_including",
  submitted: "submitted",
  returned: "returned",
} as const;

export type ACHTransferGetResponseStandardEntryClassCode =
  (typeof ACHTransferGetResponseStandardEntryClassCode)[keyof typeof ACHTransferGetResponseStandardEntryClassCode];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ACHTransferGetResponseStandardEntryClassCode = {
  corporate_credit_or_debit: "corporate_credit_or_debit",
  corporate_trade_exchange: "corporate_trade_exchange",
  prearranged_payments_and_deposit: "prearranged_payments_and_deposit",
  internet_initiated: "internet_initiated",
} as const;

export interface ACHTransferGetResponse {
  account_id: string;
  account_number: string;
  ach_notifications_of_change: ACHTransferGetResponseAchNotificationsOfChangeItem[];
  acknowledgement: ACHTransferGetResponseAcknowledgement;
  addendum: ACHTransferGetResponseAddendum;
  amount: number;
  approval: ACHTransferGetResponseApproval;
  bank: ACHTransferGetResponseBank;
  cancellation: ACHTransferGetResponseCancellation;
  company_descriptive_date: ACHTransferGetResponseCompanyDescriptiveDate;
  company_discretionary_data: ACHTransferGetResponseCompanyDiscretionaryData;
  company_entry_description: ACHTransferGetResponseCompanyEntryDescription;
  company_name: ACHTransferGetResponseCompanyName;
  created_at: string;
  created_by_email: ACHTransferGetResponseCreatedByEmail;
  created_by_id: ACHTransferGetResponseCreatedById;
  expected_funds_arrival_at: ACHTransferGetResponseExpectedFundsArrivalAt;
  external_account_id: ACHTransferGetResponseExternalAccountId;
  funding: ACHTransferGetResponseFunding;
  group_id: string;
  id: string;
  inbound_funds_hold: ACHTransferGetResponseInboundFundsHold;
  individual_id: ACHTransferGetResponseIndividualId;
  individual_name: ACHTransferGetResponseIndividualName;
  pending_transaction_id: ACHTransferGetResponsePendingTransactionId;
  preferred_effective_date: ACHTransferGetResponsePreferredEffectiveDate;
  rejection: ACHTransferGetResponseRejection;
  return: ACHTransferGetResponseReturn;
  reversal: ACHTransferGetResponseReversal;
  reversed: ACHTransferGetResponseReversed;
  review: ACHTransferGetResponseReview;
  routing_number: string;
  standard_entry_class_code: ACHTransferGetResponseStandardEntryClassCode;
  statement_descriptor: string;
  status: ACHTransferGetResponseStatus;
  submission: ACHTransferGetResponseSubmission;
  transaction_id: ACHTransferGetResponseTransactionId;
  transfer_reviewing_manual_approval: ACHTransferGetResponseTransferReviewingManualApproval;
  transfer_reviewing_manual_denial: ACHTransferGetResponseTransferReviewingManualDenial;
  transfer_session_id: ACHTransferGetResponseTransferSessionId;
}

export type ACHTransferGetResponseReviewAnyOfTransferType =
  (typeof ACHTransferGetResponseReviewAnyOfTransferType)[keyof typeof ACHTransferGetResponseReviewAnyOfTransferType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ACHTransferGetResponseReviewAnyOfTransferType = {
  check_transfer: "check_transfer",
  real_time_payments_transfer: "real_time_payments_transfer",
  ach_transfer: "ach_transfer",
  wire_transfer: "wire_transfer",
  check_deposit: "check_deposit",
  inbound_ach_entry: "inbound_ach_entry",
  inbound_ctx_entry: "inbound_ctx_entry",
  inbound_international_ach_entry: "inbound_international_ach_entry",
  inbound_wire: "inbound_wire",
} as const;

export type ACHTransferGetResponseReviewAnyOfReviewer =
  (typeof ACHTransferGetResponseReviewAnyOfReviewer)[keyof typeof ACHTransferGetResponseReviewAnyOfReviewer];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ACHTransferGetResponseReviewAnyOfReviewer = {
  increase: "increase",
  bank: "bank",
} as const;

export type ACHTransferGetResponseReviewAnyOfReason =
  (typeof ACHTransferGetResponseReviewAnyOfReason)[keyof typeof ACHTransferGetResponseReviewAnyOfReason];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ACHTransferGetResponseReviewAnyOfReason = {
  transfer_amount_absolute_value: "transfer_amount_absolute_value",
  combined_rolling_24_hour_transfer_volume:
    "combined_rolling_24_hour_transfer_volume",
  rolling_24_hour_volume_for_transfer_type:
    "rolling_24_hour_volume_for_transfer_type",
  credit_exposure: "credit_exposure",
} as const;

export type ACHTransferGetResponseReviewAnyOfGroupName = string | null;

export type ACHTransferGetResponseReviewAnyOfCurrency =
  (typeof ACHTransferGetResponseReviewAnyOfCurrency)[keyof typeof ACHTransferGetResponseReviewAnyOfCurrency];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ACHTransferGetResponseReviewAnyOfCurrency = {
  CAD: "CAD",
  CHF: "CHF",
  EUR: "EUR",
  GBP: "GBP",
  JPY: "JPY",
  USD: "USD",
} as const;

export type ACHTransferGetResponseReviewAnyOfBank =
  (typeof ACHTransferGetResponseReviewAnyOfBank)[keyof typeof ACHTransferGetResponseReviewAnyOfBank];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ACHTransferGetResponseReviewAnyOfBank = {
  blue_ridge_bank: "blue_ridge_bank",
  core_bank: "core_bank",
  first_internet_bank: "first_internet_bank",
  global_innovations_bank: "global_innovations_bank",
  grasshopper_bank: "grasshopper_bank",
} as const;

export type ACHTransferGetResponseReviewAnyOf = {
  amount: number;
  bank: ACHTransferGetResponseReviewAnyOfBank;
  created_at: string;
  currency: ACHTransferGetResponseReviewAnyOfCurrency;
  group_friendly_nickname: string;
  group_id: string;
  group_name: ACHTransferGetResponseReviewAnyOfGroupName;
  id: string;
  program_id: string;
  program_name: string;
  reason: ACHTransferGetResponseReviewAnyOfReason;
  reviewer: ACHTransferGetResponseReviewAnyOfReviewer;
  transfer_id: string;
  transfer_type: ACHTransferGetResponseReviewAnyOfTransferType;
};

export type ACHTransferGetResponseReview =
  ACHTransferGetResponseReviewAnyOf | null;

export type ACHTransferGetResponseReversedAnyOf = {
  ach_transfer_id: string;
  created_at: string;
};

export type ACHTransferGetResponseReversed =
  ACHTransferGetResponseReversedAnyOf | null;

export type ACHTransferGetResponseReversalAnyOf = {
  ach_transfer_id: string;
  created_at: string;
};

export type ACHTransferGetResponseReversal =
  ACHTransferGetResponseReversalAnyOf | null;

export type ACHTransferGetResponseReturnAnyOfReturnReasonCode =
  (typeof ACHTransferGetResponseReturnAnyOfReturnReasonCode)[keyof typeof ACHTransferGetResponseReturnAnyOfReturnReasonCode];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ACHTransferGetResponseReturnAnyOfReturnReasonCode = {
  insufficient_fund: "insufficient_fund",
  no_account: "no_account",
  account_closed: "account_closed",
  invalid_account_number_structure: "invalid_account_number_structure",
  account_frozen_entry_returned_per_ofac_instruction:
    "account_frozen_entry_returned_per_ofac_instruction",
  credit_entry_refused_by_receiver: "credit_entry_refused_by_receiver",
  unauthorized_debit_to_consumer_account_using_corporate_sec_code:
    "unauthorized_debit_to_consumer_account_using_corporate_sec_code",
  corporate_customer_advised_not_authorized:
    "corporate_customer_advised_not_authorized",
  payment_stopped: "payment_stopped",
  non_transaction_account: "non_transaction_account",
  uncollected_funds: "uncollected_funds",
  routing_number_check_digit_error: "routing_number_check_digit_error",
  customer_advised_unauthorized_improper_ineligible_or_incomplete:
    "customer_advised_unauthorized_improper_ineligible_or_incomplete",
  amount_field_error: "amount_field_error",
  authorization_revoked_by_customer: "authorization_revoked_by_customer",
  invalid_ach_routing_number: "invalid_ach_routing_number",
  file_record_edit_criteria: "file_record_edit_criteria",
  enr_invalid_individual_name: "enr_invalid_individual_name",
  returned_per_odfi_request: "returned_per_odfi_request",
  limited_participation_dfi: "limited_participation_dfi",
  incorrectly_coded_outbound_international_payment:
    "incorrectly_coded_outbound_international_payment",
  account_sold_to_another_dfi: "account_sold_to_another_dfi",
  addenda_error: "addenda_error",
  beneficiary_or_account_holder_deceased:
    "beneficiary_or_account_holder_deceased",
  customer_advised_not_within_authorization_terms:
    "customer_advised_not_within_authorization_terms",
  corrected_return: "corrected_return",
  duplicate_entry: "duplicate_entry",
  duplicate_return: "duplicate_return",
  enr_duplicate_enrollment: "enr_duplicate_enrollment",
  enr_invalid_dfi_account_number: "enr_invalid_dfi_account_number",
  enr_invalid_individual_id_number: "enr_invalid_individual_id_number",
  enr_invalid_representative_payee_indicator:
    "enr_invalid_representative_payee_indicator",
  enr_invalid_transaction_code: "enr_invalid_transaction_code",
  enr_return_of_enr_entry: "enr_return_of_enr_entry",
  enr_routing_number_check_digit_error: "enr_routing_number_check_digit_error",
  entry_not_processed_by_gateway: "entry_not_processed_by_gateway",
  field_error: "field_error",
  foreign_receiving_dfi_unable_to_settle:
    "foreign_receiving_dfi_unable_to_settle",
  iat_entry_coding_error: "iat_entry_coding_error",
  improper_effective_entry_date: "improper_effective_entry_date",
  improper_source_document_source_document_presented:
    "improper_source_document_source_document_presented",
  invalid_company_id: "invalid_company_id",
  invalid_foreign_receiving_dfi_identification:
    "invalid_foreign_receiving_dfi_identification",
  invalid_individual_id_number: "invalid_individual_id_number",
  item_and_rck_entry_presented_for_payment:
    "item_and_rck_entry_presented_for_payment",
  item_related_to_rck_entry_is_ineligible:
    "item_related_to_rck_entry_is_ineligible",
  mandatory_field_error: "mandatory_field_error",
  misrouted_dishonored_return: "misrouted_dishonored_return",
  misrouted_return: "misrouted_return",
  no_errors_found: "no_errors_found",
  non_acceptance_of_r62_dishonored_return:
    "non_acceptance_of_r62_dishonored_return",
  non_participant_in_iat_program: "non_participant_in_iat_program",
  permissible_return_entry: "permissible_return_entry",
  permissible_return_entry_not_accepted:
    "permissible_return_entry_not_accepted",
  rdfi_non_settlement: "rdfi_non_settlement",
  rdfi_participant_in_check_truncation_program:
    "rdfi_participant_in_check_truncation_program",
  representative_payee_deceased_or_unable_to_continue_in_that_capacity:
    "representative_payee_deceased_or_unable_to_continue_in_that_capacity",
  return_not_a_duplicate: "return_not_a_duplicate",
  return_of_erroneous_or_reversing_debit:
    "return_of_erroneous_or_reversing_debit",
  return_of_improper_credit_entry: "return_of_improper_credit_entry",
  return_of_improper_debit_entry: "return_of_improper_debit_entry",
  return_of_xck_entry: "return_of_xck_entry",
  source_document_presented_for_payment:
    "source_document_presented_for_payment",
  state_law_affecting_rck_acceptance: "state_law_affecting_rck_acceptance",
  stop_payment_on_item_related_to_rck_entry:
    "stop_payment_on_item_related_to_rck_entry",
  stop_payment_on_source_document: "stop_payment_on_source_document",
  timely_original_return: "timely_original_return",
  trace_number_error: "trace_number_error",
  untimely_dishonored_return: "untimely_dishonored_return",
  untimely_return: "untimely_return",
} as const;

export type ACHTransferGetResponseReturnAnyOf = {
  created_at: string;
  raw_return_reason_code: string;
  return_reason_code: ACHTransferGetResponseReturnAnyOfReturnReasonCode;
  trace_number: string;
  transaction_id: string;
  transfer_id: string;
};

export type ACHTransferGetResponseReturn =
  ACHTransferGetResponseReturnAnyOf | null;

export type ACHTransferGetResponseRejectionAnyOf = {
  created_at: string;
  transaction_id: string;
};

export type ACHTransferGetResponseRejection =
  ACHTransferGetResponseRejectionAnyOf | null;

export type ACHTransferGetResponsePreferredEffectiveDateAnyOfSettlementSchedule =
  "same_day" | "future_dated" | null;

export type ACHTransferGetResponsePreferredEffectiveDateAnyOfDate =
  | string
  | null;

export type ACHTransferGetResponsePreferredEffectiveDateAnyOf = {
  date: ACHTransferGetResponsePreferredEffectiveDateAnyOfDate;
  settlement_schedule: ACHTransferGetResponsePreferredEffectiveDateAnyOfSettlementSchedule;
};

export type ACHTransferGetResponsePreferredEffectiveDate =
  ACHTransferGetResponsePreferredEffectiveDateAnyOf | null;

export type ACHTransferGetResponsePendingTransactionId = string | null;

export type ACHTransferGetResponseIndividualName = string | null;

export type ACHTransferGetResponseIndividualId = string | null;

export type ACHTransferGetResponseInboundFundsHoldAnyOfStatus =
  (typeof ACHTransferGetResponseInboundFundsHoldAnyOfStatus)[keyof typeof ACHTransferGetResponseInboundFundsHoldAnyOfStatus];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ACHTransferGetResponseInboundFundsHoldAnyOfStatus = {
  held: "held",
  complete: "complete",
} as const;

export type ACHTransferGetResponseInboundFundsHoldAnyOfReleasedAt =
  | string
  | null;

export type ACHTransferGetResponseInboundFundsHoldAnyOfPendingTransaction =
  ACHTransferGetResponseInboundFundsHoldAnyOfPendingTransactionAnyOf | null;

export type ACHTransferGetResponseInboundFundsHoldAnyOf = {
  account_id: string;
  amount: number;
  automatically_releases_at: string;
  group_id: string;
  id: string;
  pending_transaction: ACHTransferGetResponseInboundFundsHoldAnyOfPendingTransaction;
  released_at: ACHTransferGetResponseInboundFundsHoldAnyOfReleasedAt;
  source_id: string;
  source_type: string;
  status: ACHTransferGetResponseInboundFundsHoldAnyOfStatus;
};

export type ACHTransferGetResponseInboundFundsHold =
  ACHTransferGetResponseInboundFundsHoldAnyOf | null;

export type ACHTransferGetResponseInboundFundsHoldAnyOfPendingTransactionAnyOfStatus =
  (typeof ACHTransferGetResponseInboundFundsHoldAnyOfPendingTransactionAnyOfStatus)[keyof typeof ACHTransferGetResponseInboundFundsHoldAnyOfPendingTransactionAnyOfStatus];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ACHTransferGetResponseInboundFundsHoldAnyOfPendingTransactionAnyOfStatus =
  {
    pending: "pending",
    complete: "complete",
  } as const;

export type ACHTransferGetResponseInboundFundsHoldAnyOfPendingTransactionAnyOfSourceDocumentation =
  string | null;

export type ACHTransferGetResponseInboundFundsHoldAnyOfPendingTransactionAnyOfRouteAnyOfType =
  (typeof ACHTransferGetResponseInboundFundsHoldAnyOfPendingTransactionAnyOfRouteAnyOfType)[keyof typeof ACHTransferGetResponseInboundFundsHoldAnyOfPendingTransactionAnyOfRouteAnyOfType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ACHTransferGetResponseInboundFundsHoldAnyOfPendingTransactionAnyOfRouteAnyOfType =
  {
    account_number: "account_number",
    card: "card",
    lockbox: "lockbox",
  } as const;

export type ACHTransferGetResponseInboundFundsHoldAnyOfPendingTransactionAnyOfRouteAnyOfDescription =
  string | null;

export type ACHTransferGetResponseInboundFundsHoldAnyOfPendingTransactionAnyOfRouteAnyOf =
  {
    description: ACHTransferGetResponseInboundFundsHoldAnyOfPendingTransactionAnyOfRouteAnyOfDescription;
    id: string;
    type: ACHTransferGetResponseInboundFundsHoldAnyOfPendingTransactionAnyOfRouteAnyOfType;
  };

export type ACHTransferGetResponseInboundFundsHoldAnyOfPendingTransactionAnyOfRoute =
  ACHTransferGetResponseInboundFundsHoldAnyOfPendingTransactionAnyOfRouteAnyOf | null;

export type ACHTransferGetResponseInboundFundsHoldAnyOfPendingTransactionAnyOfCurrency =
  (typeof ACHTransferGetResponseInboundFundsHoldAnyOfPendingTransactionAnyOfCurrency)[keyof typeof ACHTransferGetResponseInboundFundsHoldAnyOfPendingTransactionAnyOfCurrency];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ACHTransferGetResponseInboundFundsHoldAnyOfPendingTransactionAnyOfCurrency =
  {
    CAD: "CAD",
    CHF: "CHF",
    EUR: "EUR",
    GBP: "GBP",
    JPY: "JPY",
    USD: "USD",
  } as const;

export type ACHTransferGetResponseInboundFundsHoldAnyOfPendingTransactionAnyOfCompletedAt =
  string | null;

export type ACHTransferGetResponseInboundFundsHoldAnyOfPendingTransactionAnyOf =
  {
    account_id: string;
    amount: number;
    completed_at: ACHTransferGetResponseInboundFundsHoldAnyOfPendingTransactionAnyOfCompletedAt;
    created_at: string;
    currency: ACHTransferGetResponseInboundFundsHoldAnyOfPendingTransactionAnyOfCurrency;
    date: string;
    description: string;
    group_id: string;
    id: string;
    route: ACHTransferGetResponseInboundFundsHoldAnyOfPendingTransactionAnyOfRoute;
    source: unknown;
    source_documentation: ACHTransferGetResponseInboundFundsHoldAnyOfPendingTransactionAnyOfSourceDocumentation;
    source_id: string;
    source_type: string;
    status: ACHTransferGetResponseInboundFundsHoldAnyOfPendingTransactionAnyOfStatus;
    type: string;
  };

export type ACHTransferGetResponseFunding =
  (typeof ACHTransferGetResponseFunding)[keyof typeof ACHTransferGetResponseFunding];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ACHTransferGetResponseFunding = {
  checking: "checking",
  savings: "savings",
} as const;

export type ACHTransferGetResponseExternalAccountId = string | null;

export type ACHTransferGetResponseExpectedFundsArrivalAt = string | null;

export type ACHTransferGetResponseCreatedById = string | null;

export type ACHTransferGetResponseCreatedByEmail = string | null;

export type ACHTransferGetResponseCompanyName = string | null;

export type ACHTransferGetResponseCompanyEntryDescription = string | null;

export type ACHTransferGetResponseCompanyDiscretionaryData = string | null;

export type ACHTransferGetResponseCompanyDescriptiveDate = string | null;

export type ACHTransferGetResponseCancellationAnyOfCancelledBy = string | null;

export type ACHTransferGetResponseCancellationAnyOf = {
  cancelled_at: string;
  cancelled_by: ACHTransferGetResponseCancellationAnyOfCancelledBy;
};

export type ACHTransferGetResponseCancellation =
  ACHTransferGetResponseCancellationAnyOf | null;

export type ACHTransferGetResponseBank =
  (typeof ACHTransferGetResponseBank)[keyof typeof ACHTransferGetResponseBank];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ACHTransferGetResponseBank = {
  blue_ridge_bank: "blue_ridge_bank",
  core_bank: "core_bank",
  first_internet_bank: "first_internet_bank",
  global_innovations_bank: "global_innovations_bank",
  grasshopper_bank: "grasshopper_bank",
} as const;

export type ACHTransferGetResponseApprovalAnyOfApprovedBy = string | null;

export type ACHTransferGetResponseApprovalAnyOf = {
  approved_at: string;
  approved_by: ACHTransferGetResponseApprovalAnyOfApprovedBy;
};

export type ACHTransferGetResponseApproval =
  ACHTransferGetResponseApprovalAnyOf | null;

export type ACHTransferGetResponseAddendum = string | null;

export type ACHTransferGetResponseAcknowledgementAnyOf = {
  acknowledged_at: string;
};

export type ACHTransferGetResponseAcknowledgement =
  ACHTransferGetResponseAcknowledgementAnyOf | null;

export type ACHTransferGetResponseAchNotificationsOfChangeItemChangeCode =
  (typeof ACHTransferGetResponseAchNotificationsOfChangeItemChangeCode)[keyof typeof ACHTransferGetResponseAchNotificationsOfChangeItemChangeCode];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ACHTransferGetResponseAchNotificationsOfChangeItemChangeCode = {
  incorrect_account_number: "incorrect_account_number",
  incorrect_routing_number: "incorrect_routing_number",
  incorrect_routing_number_and_account_number:
    "incorrect_routing_number_and_account_number",
  incorrect_transaction_code: "incorrect_transaction_code",
  incorrect_account_number_and_transaction_code:
    "incorrect_account_number_and_transaction_code",
  incorrect_routing_number_account_number_and_transaction_code:
    "incorrect_routing_number_account_number_and_transaction_code",
  incorrect_receiving_depository_financial_institution_identification:
    "incorrect_receiving_depository_financial_institution_identification",
  incorrect_individual_identification_number:
    "incorrect_individual_identification_number",
  addenda_format_error: "addenda_format_error",
  incorrect_standard_entry_class_code_for_outbound_international_payment:
    "incorrect_standard_entry_class_code_for_outbound_international_payment",
  misrouted_notification_of_change: "misrouted_notification_of_change",
  incorrect_trace_number: "incorrect_trace_number",
  incorrect_company_identification_number:
    "incorrect_company_identification_number",
  incorrect_identification_number: "incorrect_identification_number",
  incorrectly_formatted_corrected_data: "incorrectly_formatted_corrected_data",
  incorrect_discretionary_data: "incorrect_discretionary_data",
  routing_number_not_from_original_entry_detail_record:
    "routing_number_not_from_original_entry_detail_record",
  depository_financial_institution_account_number_not_from_original_entry_detail_record:
    "depository_financial_institution_account_number_not_from_original_entry_detail_record",
  incorrect_transaction_code_by_originating_depository_financial_institution:
    "incorrect_transaction_code_by_originating_depository_financial_institution",
} as const;

export type ACHTransferGetResponseAchNotificationsOfChangeItem = {
  change_code: ACHTransferGetResponseAchNotificationsOfChangeItemChangeCode;
  corrected_data: string;
  created_at: string;
};

export type ACHOriginationVolumeMonitoringGetResponseVolumeByCompanyIdItemVolume =
  {
    total_credit_amount: number;
    total_debit_amount: number;
  };

export type ACHOriginationVolumeMonitoringGetResponseVolumeByCompanyIdItem = {
  company_id: string;
  group: ACHOriginationVolumeMonitoringGetResponseVolumeByCompanyIdItemGroup;
  limit: ACHOriginationVolumeMonitoringGetResponseVolumeByCompanyIdItemLimit;
  shared_company_id: boolean;
  volume: ACHOriginationVolumeMonitoringGetResponseVolumeByCompanyIdItemVolume;
};

export type ACHOriginationVolumeMonitoringGetResponseVolumeByCompanyIdItemLimitAnyOf =
  {
    total_credit_amount: number;
    total_debit_amount: number;
  };

export type ACHOriginationVolumeMonitoringGetResponseVolumeByCompanyIdItemLimit =
  ACHOriginationVolumeMonitoringGetResponseVolumeByCompanyIdItemLimitAnyOf | null;

export type ACHOriginationVolumeMonitoringGetResponseVolumeByCompanyIdItemGroupAnyOf =
  {
    friendly_nickname: string;
    id: string;
  };

export type ACHOriginationVolumeMonitoringGetResponseVolumeByCompanyIdItemGroup =
  ACHOriginationVolumeMonitoringGetResponseVolumeByCompanyIdItemGroupAnyOf | null;

export type ACHOriginationVolumeMonitoringGetResponseIncludedProcessingDatesItem =
  {
    date: string;
  };

export type ACHOriginationVolumeMonitoringGetResponseBank =
  (typeof ACHOriginationVolumeMonitoringGetResponseBank)[keyof typeof ACHOriginationVolumeMonitoringGetResponseBank];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ACHOriginationVolumeMonitoringGetResponseBank = {
  blue_ridge_bank: "blue_ridge_bank",
  core_bank: "core_bank",
  first_internet_bank: "first_internet_bank",
  global_innovations_bank: "global_innovations_bank",
  grasshopper_bank: "grasshopper_bank",
} as const;

export interface ACHOriginationVolumeMonitoringGetResponse {
  bank: ACHOriginationVolumeMonitoringGetResponseBank;
  included_processing_dates: ACHOriginationVolumeMonitoringGetResponseIncludedProcessingDatesItem[];
  is_effective_date: boolean;
  volume_by_company_id: ACHOriginationVolumeMonitoringGetResponseVolumeByCompanyIdItem[];
}

export type ACHHistoricalOriginationVolumeForRoutingNumberGetResponseDataItem =
  {
    date: string;
    total_credit_amount: number;
    total_debit_amount: number;
  };

export interface ACHHistoricalOriginationVolumeForRoutingNumberGetResponse {
  data: ACHHistoricalOriginationVolumeForRoutingNumberGetResponseDataItem[];
}

export type ACHCompanyIDLookupGetResponseGroupName = string | null;

export type ACHCompanyIDLookupGetResponseAchContactPhoneNumber = string | null;

export type ACHCompanyIDLookupGetResponseAchContactEmail = string | null;

export type ACHCompanyIDLookupGetResponseAchCompanyName = string | null;

export interface ACHCompanyIDLookupGetResponse {
  ach_company_name: ACHCompanyIDLookupGetResponseAchCompanyName;
  ach_contact_email: ACHCompanyIDLookupGetResponseAchContactEmail;
  ach_contact_phone_number: ACHCompanyIDLookupGetResponseAchContactPhoneNumber;
  group_name: ACHCompanyIDLookupGetResponseGroupName;
}
