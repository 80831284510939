import { TableColumns, Table, PrestyledTableProps } from '@increase/shared/components/Table';
import { formatISO8601Timestamp, humanize } from '@increase/shared/lib/formatting';
import { useMemo } from 'react';
import { BadgeColor } from 'shared/components/Badge';
import { ControlRecordGetResponse } from 'src/build/operations';
import { absurd } from 'src/lib/absurd';
import { buildPath, ROUTES } from 'src/lib/routes';
type Props = PrestyledTableProps<ControlRecordGetResponse> & {
  showCategory: boolean;
  showObject: boolean;
};
export const controlRecordLink = (controlRecord: ControlRecordGetResponse) => {
  switch (controlRecord.record_type) {
    case 'bookkeeping_account':
      return buildPath(ROUTES.BOOKKEEPING_ACCOUNTS_DETAIL, {
        bookkeepingAccountID: controlRecord.record_id
      });
    case 'entity':
      return buildPath(ROUTES.ENTITY, {
        entityId: controlRecord.record_id
      });
    case 'account':
      return buildPath(ROUTES.ACCOUNTS_DETAIL, {
        accountId: controlRecord.record_id
      });
    case 'transaction':
      return buildPath(ROUTES.TRANSACTION_DETAIL, {
        transactionID: controlRecord.record_id
      });
    default:
      absurd(controlRecord.record_type);
  }
};
const resultBadgeColors: Record<ControlRecordGetResponse['result'], BadgeColor> = {
  success: 'green',
  failure: 'red'
};
export const ControlRecordsTable = (props: Props) => {
  const {
    getRowProps,
    showCategory,
    showObject,
    ...rest
  } = props;
  const columns = useMemo<TableColumns<ControlRecordGetResponse>>(() => {
    return [{
      header: 'Status',
      contents: control => ({
        text: humanize(control.result),
        badgeColor: resultBadgeColors[control.result]
      })
    }, showCategory && {
      header: 'Category',
      contents: control => ({
        text: humanize(control.category)
      })
    }, showObject && {
      header: 'Associated object',
      contents: control => ({
        text: control.record_name,
        href: controlRecordLink(control)
      })
    }, {
      header: 'Evaluated at',
      contents: control => ({
        text: control.evaluated_at ? formatISO8601Timestamp(control.evaluated_at, 'month-day-year-hour-minute-second') : '-'
      })
    }, {
      header: 'Failing since',
      contents: control => ({
        text: control.failing_since ? formatISO8601Timestamp(control.failing_since, 'month-day-year-hour-minute-second') : '-'
      })
    }];
  }, [showCategory, showObject]);
  const defaultRowProps = (datum: ControlRecordGetResponse) => ({
    className: 'hover:bg-main-hover cursor-pointer transition-all group',
    href: buildPath(ROUTES.CONTROL_RECORDS_DETAILS, {
      id: datum.id
    })
  });
  return <Table<ControlRecordGetResponse> columns={columns} getRowProps={getRowProps ?? defaultRowProps} {...rest} data-sentry-element="Table" data-sentry-component="ControlRecordsTable" data-sentry-source-file="ControlRecordsTable.tsx" />;
};