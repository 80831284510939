import { compact } from 'lodash';
import { BadgeColor } from '@increase/shared/components/Badge';
import { ListItem, PropertyList, PropertyListProps } from '@increase/shared/components/PropertyList';
import { formatAddress, humanize } from '@increase/shared/lib/formatting';
import { EntityGetResponseSetupSubmissionStructureCorporation, EntityGetResponseSetupSubmissionStructureCorporationAnyOfTaxIdValidationStatus } from 'src/build/operations';
import { useCategoricals } from 'src/hooks/use-categoricals';
import { nameFromMapping } from 'src/lib/name-from-mapping';
import { buildPath, ROUTES } from 'src/lib/routes';
import { InternalOperatorOnly } from '../internal-operator-only';
import { StyledLink } from '@increase/shared/components/StyledLink';
import { Body } from '@increase/shared/components/Text';
import { formatEmployerIdentificationNumber } from 'src/lib/format-string';
type EntityPropertyListProps = {
  corporation: NonNullable<EntityGetResponseSetupSubmissionStructureCorporation>;
  entityId?: string | null;
} & Omit<PropertyListProps, 'items'>;
const taxIdValidationStatusBadgeColor: Record<EntityGetResponseSetupSubmissionStructureCorporationAnyOfTaxIdValidationStatus, BadgeColor> = {
  pending_validating: 'blue',
  update_pending_validating: 'blue',
  requires_attention: 'yellow',
  failed: 'yellow',
  validated: 'green',
  not_provided: 'gray',
  skipped: 'gray'
};
export const EntityCorporationPropertyList = (props: EntityPropertyListProps) => {
  const {
    corporation: corporation,
    ...rest
  } = props;
  const categoricals = useCategoricals();
  if (!categoricals) {
    return null;
  }
  const industryName = corporation.industry_code ? nameFromMapping(categoricals.industry_code, corporation.industry_code) : null;
  const industryDisplay = industryName ? `${industryName} (${corporation.industry_code})` : corporation.industry_code || '-';
  return <PropertyList {...rest} action={<InternalOperatorOnly>
          <StyledLink style="underline" href={buildPath(ROUTES.ENTITY_ADDRESS_VERIFICATION, {
      addressableId: corporation.id
    })}>
            <Body>Address Verification</Body>
          </StyledLink>
        </InternalOperatorOnly>} items={compact<ListItem>([{
    label: 'Name',
    value: corporation.submitted_name
  }, {
    label: 'Website',
    value: corporation.submitted_website ?? '-'
  }, {
    label: 'Tax ID',
    value: corporation.submitted_tax_id ? formatEmployerIdentificationNumber(corporation.submitted_tax_id) : '-'
  }, {
    label: 'Sovos Tax ID Validation Return Code',
    value: humanize(corporation.tax_id_validation_status),
    badgeColor: taxIdValidationStatusBadgeColor[corporation.tax_id_validation_status]
  }, {
    label: 'Sovos Requested At',
    value: corporation.tax_id_submitted_at,
    format: 'month-day-year-hour-minute-second'
  }, corporation.submission_sovos_response_id && {
    label: 'Sovos Raw Response',
    href: buildPath(ROUTES.ENTITY_SOVOS_RESPONSE, {
      sovosResponseId: corporation.submission_sovos_response_id
    }),
    value: 'View'
  }, {
    label: 'Incorporation State',
    value: corporation.submitted_state ?? '-'
  }, {
    label: 'Address',
    value: formatAddress(corporation.submitted_address)
  }, {
    label: 'Industry',
    value: industryDisplay
  }, props.entityId && {
    label: 'Entity ID',
    value: props.entityId,
    href: buildPath(ROUTES.ENTITY, {
      entityId: props.entityId
    })
  }])} data-sentry-element="PropertyList" data-sentry-component="EntityCorporationPropertyList" data-sentry-source-file="EntityCorporationPropertyList.tsx" />;
};