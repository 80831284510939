import { ReactElement, ReactNode, useContext } from 'react';
import { Caption, Heading } from './Text';
import { Button } from './Button';
import { DialogContext } from './Modal';
type Props = {
  title?: string | ReactElement;
  subtitle?: string;
  body: ReactNode;
  leftButton?: ReactNode | null;
  rightButton?: ReactNode;
  cancelText?: string;
};
export const DrawerLayout = ({
  title,
  subtitle,
  body,
  leftButton,
  rightButton,
  cancelText = 'Cancel'
}: Props) => {
  const {
    setModalState
  } = useContext(DialogContext);
  const titleElement = typeof title === 'string' ? <Heading size="medium" color="primary" contents={title} /> : title || <div />;
  leftButton = leftButton === null ? null : leftButton || <Button style="secondary" text={cancelText} onClick={() => {
    setModalState('none');
  }} />;
  return <div className="divide-color-main flex h-full flex-col divide-y" data-sentry-component="DrawerLayout" data-sentry-source-file="DrawerLayout.tsx">
      <div className="flex items-center justify-between px-5 py-3 sm:p-5">
        <div className="shrink-0 grow-0">{titleElement}</div>
        <Caption className="shrink-0 grow-0 font-[500]" color="secondary" data-sentry-element="Caption" data-sentry-source-file="DrawerLayout.tsx">
          {subtitle}
        </Caption>
      </div>
      <div className="before:from-main relative grow overflow-hidden before:pointer-events-none before:absolute before:inset-x-0 before:bottom-0 before:z-10 before:h-16 before:bg-gradient-to-t before:to-transparent">
        <div className="h-full w-full overflow-y-scroll px-5 pt-5 pb-16">
          {body}
        </div>
      </div>
      <div className="shrink-0 grow-0 p-5">
        <div className="flex flex-row gap-2">
          {leftButton}
          <div className="grow" />
          {rightButton}
        </div>
      </div>
    </div>;
};