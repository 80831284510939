import React, { useCallback, useEffect, useState } from 'react';
import { getMouseLocationInCanvas } from 'src/lib/image-utility';
import { must } from 'src/lib/must';
type Props = {
  pixels: ImageData;
  maxWidth?: number;
  maxHeight?: number;
  annotations?: Annotation[];
  selectedAnnotationID?: string | null;
  onHoveredAnnotationChange?: (annotation: string | null) => void;
};
type Point = {
  x: number;
  y: number;
};
export type Annotation = {
  id: string;
  text: string;
  boundingPolygon: Point[];
};
export const ImageDataView = ({
  pixels,
  maxWidth = 800,
  maxHeight,
  annotations,
  selectedAnnotationID,
  onHoveredAnnotationChange
}: Props) => {
  const [canvas, setCanvas] = useState<HTMLCanvasElement | null>(null);
  const [mouseLocation, setMouseLocation] = useState<Point | null>(null);
  const [hoveredAnnotation, setHoveredAnnotation] = useState<Annotation | null>(null);
  const canvasRef = useCallback((node: HTMLCanvasElement | null) => {
    if (node) {
      setCanvas(node);
    }
  }, []);
  useEffect(() => {
    onHoveredAnnotationChange?.(hoveredAnnotation ? hoveredAnnotation.id : null);
  }, [hoveredAnnotation, onHoveredAnnotationChange]);
  useEffect(() => {
    if (!canvas) {
      return;
    }
    canvas.width = pixels.width;
    canvas.height = pixels.height;
    let pixelsTransform = new DOMMatrixReadOnly();
    let scale = 1;
    if (pixels.width > maxWidth) {
      scale = maxWidth / pixels.width;
    }
    if (maxHeight && pixels.height * scale > maxHeight) {
      scale = maxHeight / (pixels.height * scale);
    }
    pixelsTransform = pixelsTransform.scale(scale);
    const ctx = must(canvas.getContext('2d'));
    ctx.clearRect(0, 0, canvas.width, canvas.height);
    ctx.putImageData(pixels, 0, 0);
    let currentlyHoveredAnnotation: Annotation | null = null;
    for (let annotation of annotations ?? []) {
      ctx.save();
      ctx.setTransform(pixelsTransform.inverse());
      ctx.strokeStyle = 'rgba(98, 255, 0, 0.8)';
      ctx.lineWidth = 1;
      ctx.beginPath();
      const [start, ...rest] = annotation.boundingPolygon.map(point => pixelsTransform.transformPoint(point));
      ctx.moveTo(start.x, start.y);
      rest.forEach(point => {
        ctx.lineTo(point.x, point.y);
      });
      ctx.lineTo(start.x, start.y);
      if (mouseLocation && ctx.isPointInPath(mouseLocation.x, mouseLocation.y)) {
        currentlyHoveredAnnotation = annotation;
      }
      if (selectedAnnotationID === annotation.id) {
        ctx.fillStyle = 'rgba(98, 255, 0, 0.3)';
        ctx.fill();
      }
      ctx.stroke();
      ctx.restore();
    }
    if (hoveredAnnotation?.id !== currentlyHoveredAnnotation?.id) {
      setHoveredAnnotation(currentlyHoveredAnnotation);
    }
  }, [pixels, canvas, annotations, mouseLocation, maxWidth, maxHeight, selectedAnnotationID, hoveredAnnotation, setHoveredAnnotation]);
  const onMouseMove = useCallback((e: React.MouseEvent<HTMLCanvasElement, MouseEvent>) => setMouseLocation(getMouseLocationInCanvas(e)), []);
  const onMouseExit = useCallback(() => {
    setMouseLocation(null);
  }, []);
  return <canvas onMouseMove={onMouseMove} onMouseOut={onMouseExit} onMouseLeave={onMouseExit} ref={canvasRef} style={{
    maxWidth: `${maxWidth}px`,
    maxHeight: `${maxHeight}px`
  }} data-sentry-component="ImageDataView" data-sentry-source-file="image-data-view.tsx"></canvas>;
};