import { TimelineEntry, Timeline } from '@increase/shared/components/Timeline';
import { usePendingTransactionGet, useTransactionGet } from 'src/hooks/reactQuery';
import { formatAmount } from '@increase/shared/lib/formatting';
import _ from 'lodash';
import moment from 'moment';
import { ROUTES, buildPath } from 'src/lib/routes';
import { ACHTransferGetResponse } from 'src/build/operations';
const byEmailOrAPI = (actor: string | null): string => actor ? `by ${actor}` : `via API`;
type Props = {
  achTransfer: ACHTransferGetResponse;
  highlightedTransactionId?: string;
};
export const ACHTransferTimeline = ({
  achTransfer,
  highlightedTransactionId
}: Props) => {
  const {
    data: pendingTransaction
  } = usePendingTransactionGet(achTransfer.pending_transaction_id ?? '', {
    enabled: !!achTransfer.pending_transaction_id
  });
  const {
    data: holdPendingTransaction
  } = usePendingTransactionGet(achTransfer.inbound_funds_hold?.pending_transaction?.id ?? '', {
    enabled: !!achTransfer.inbound_funds_hold?.pending_transaction?.id
  });
  const {
    data: transaction
  } = useTransactionGet(achTransfer.transaction_id ?? '', {
    enabled: !!achTransfer.transaction_id
  });
  const {
    data: returnTransaction
  } = useTransactionGet(achTransfer.return?.transaction_id ?? '', {
    enabled: !!achTransfer.return?.transaction_id
  });
  const {
    data: rejectionTransaction
  } = useTransactionGet(achTransfer.rejection?.transaction_id ?? '', {
    enabled: !!achTransfer.rejection?.transaction_id
  });
  const timelineEntries: (TimelineEntry & {
    sortPriority?: number;
  })[] = [{
    bullet: {
      hidden: true
    },
    object: {
      href: buildPath(ROUTES.ACH_TRANSFER_DETAIL, {
        achTransferID: achTransfer.id
      }),
      name: 'ach_transfer',
      icon: 'cube',
      style: highlightedTransactionId ? 'object-unselected' : 'object-selected'
    },
    text: `created ${byEmailOrAPI(achTransfer.created_by_email)}`,
    date: achTransfer.created_at
  }];
  if (pendingTransaction) {
    timelineEntries.push({
      bullet: {
        color: highlightedTransactionId === pendingTransaction.id ? 'selected' : 'unselected',
        icon: 'arrow_right'
      },
      object: {
        href: buildPath(ROUTES.PENDING_TRANSACTION_DETAIL, {
          pendingTransactionID: pendingTransaction.id
        }),
        name: 'pending_transaction',
        icon: 'file_add',
        style: highlightedTransactionId === pendingTransaction.id ? 'object-selected' : 'object-unselected'
      },
      text: `created for ${formatAmount(pendingTransaction.amount, 'USD')}`,
      date: pendingTransaction.created_at
    });
    if (pendingTransaction.completed_at) {
      timelineEntries.push({
        text: `Pending transaction completed`,
        date: pendingTransaction.completed_at,
        sortPriority: 1
      });
    }
  }
  if (achTransfer.approval) {
    timelineEntries.push({
      text: `Approved ${byEmailOrAPI(achTransfer.approval.approved_by)}`,
      date: achTransfer.approval.approved_at,
      sortPriority: 0
    });
  }
  if (achTransfer.cancellation) {
    timelineEntries.push({
      bullet: {
        color: 'modified'
      },
      text: `Canceled ${byEmailOrAPI(achTransfer.cancellation.cancelled_by)}`,
      date: achTransfer.cancellation.cancelled_at,
      sortPriority: 0
    });
  }
  if (achTransfer.status === 'pending_approval') {
    timelineEntries.push({
      bullet: {
        color: 'pending'
      },
      text: `Pending approval by your team`,
      date: null
    });
  }
  if (achTransfer.status === 'pending_reviewing') {
    timelineEntries.push({
      bullet: {
        color: 'pending'
      },
      text: `Pending review by Increase`,
      date: null
    });
  }
  if (achTransfer.status === 'pending_submission') {
    timelineEntries.push({
      bullet: {
        color: 'pending'
      },
      text: `Pending submission to the Federal Reserve`,
      date: null
    });
  }
  if (achTransfer.submission) {
    timelineEntries.push({
      text: `Submitted to the Federal Reserve`,
      date: achTransfer.submission.submitted_at
    });
  }
  if (achTransfer.acknowledgement) {
    timelineEntries.push({
      text: `File containing this transfer acknowledged by the Federal Reserve`,
      date: achTransfer.acknowledgement.acknowledged_at
    });
  }
  if (achTransfer.submission && !achTransfer.acknowledgement) {
    const expectedAt = moment(achTransfer.submission.submitted_at).add(15, 'minutes').toISOString();
    timelineEntries.push({
      text: `Expected file acknowledgement by the Federal Reserve, usually within 15 minutes of submission`,
      bullet: {
        color: 'pending'
      },
      date: expectedAt
    });
  }
  if (achTransfer.expected_funds_arrival_at) {
    const isFutureSettlement = moment(achTransfer.expected_funds_arrival_at).isAfter(moment());
    timelineEntries.push({
      bullet: {
        color: isFutureSettlement ? 'pending' : 'complete'
      },
      text: `Expected settlement with recipient bank`,
      date: achTransfer.expected_funds_arrival_at
    });
  }
  if (transaction) {
    timelineEntries.push({
      bullet: {
        color: highlightedTransactionId === transaction.id ? 'selected' : 'unselected',
        icon: 'arrow_right'
      },
      object: {
        href: buildPath(ROUTES.TRANSACTION_DETAIL, {
          transactionID: transaction.id
        }),
        name: 'transaction',
        icon: 'file_add',
        style: highlightedTransactionId === transaction.id ? 'object-selected' : 'object-unselected'
      },
      text: `created for ${formatAmount(transaction.amount, transaction.currency)}`,
      date: transaction.created_at,
      sortPriority: 2
    });
  }
  if (holdPendingTransaction) {
    timelineEntries.push({
      bullet: {
        color: highlightedTransactionId === holdPendingTransaction.id ? 'selected' : 'unselected',
        icon: 'arrow_right'
      },
      object: {
        href: buildPath(ROUTES.PENDING_TRANSACTION_DETAIL, {
          pendingTransactionID: holdPendingTransaction.id
        }),
        name: 'pending_transaction',
        icon: 'file_add',
        style: highlightedTransactionId === holdPendingTransaction.id ? 'object-selected' : 'object-unselected'
      },
      text: `created for a ${formatAmount(holdPendingTransaction.amount, holdPendingTransaction.currency)} hold`,
      date: holdPendingTransaction.created_at,
      sortPriority: 3
    });
    if (holdPendingTransaction.completed_at) {
      timelineEntries.push({
        text: `Hold for ${formatAmount(holdPendingTransaction.amount, holdPendingTransaction.currency)} released`,
        date: holdPendingTransaction.completed_at,
        sortPriority: 1
      });
    } else if (achTransfer.inbound_funds_hold) {
      timelineEntries.push({
        bullet: {
          color: 'pending'
        },
        text: `Hold for ${formatAmount(holdPendingTransaction.amount, holdPendingTransaction.currency)} will be released`,
        date: achTransfer.inbound_funds_hold.automatically_releases_at,
        sortPriority: 1
      });
    }
  }
  achTransfer.ach_notifications_of_change.forEach(notificationOfChange => timelineEntries.push({
    text: `Notification of Change received`,
    date: notificationOfChange.created_at
  }));
  if (achTransfer.rejection) {
    timelineEntries.push({
      text: 'Rejected by Increase',
      date: achTransfer.rejection.created_at,
      bullet: {
        color: 'failed'
      }
    });
    if (rejectionTransaction) {
      timelineEntries.push({
        bullet: {
          color: highlightedTransactionId === rejectionTransaction.id ? 'selected' : 'unselected',
          icon: 'arrow_right'
        },
        object: {
          href: buildPath(ROUTES.TRANSACTION_DETAIL, {
            transactionID: rejectionTransaction.id
          }),
          name: 'transaction',
          icon: 'file_add',
          style: highlightedTransactionId === rejectionTransaction.id ? 'object-selected' : 'object-unselected'
        },
        text: `created for ${formatAmount(rejectionTransaction.amount, rejectionTransaction.currency)}`,
        date: rejectionTransaction.created_at,
        sortPriority: 2
      });
    }
  }
  if (achTransfer.reversal) {
    timelineEntries.push({
      text: 'Reversal ACH initiated',
      date: achTransfer.reversal.created_at,
      bullet: {
        color: 'modified'
      },
      tooltipText: 'A reversal is a second transfer that undoes the original transfer. It may be returned.'
    });
  }
  if (achTransfer.return && returnTransaction) {
    timelineEntries.push({
      bullet: {
        color: 'modified'
      },
      text: `ACH return request received`,
      date: achTransfer.return.created_at
    });
    timelineEntries.push({
      bullet: {
        color: highlightedTransactionId === returnTransaction.id ? 'selected' : 'unselected',
        icon: 'arrow_right'
      },
      object: {
        href: buildPath(ROUTES.TRANSACTION_DETAIL, {
          transactionID: returnTransaction.id
        }),
        name: 'transaction',
        icon: 'file_add',
        style: highlightedTransactionId === returnTransaction.id ? 'object-selected' : 'object-unselected'
      },
      text: `created for ${formatAmount(returnTransaction.amount, returnTransaction.currency)}`,
      date: returnTransaction.created_at
    });
  }
  const orderedTimelineEntries = _.sortBy(timelineEntries, entry => [entry.date ? moment(entry.date).unix() : moment().add(10, 'years'), entry.sortPriority ?? 0]);
  return <Timeline title="Timeline" entries={orderedTimelineEntries} data-sentry-element="Timeline" data-sentry-component="ACHTransferTimeline" data-sentry-source-file="ACHTransferTimeline.tsx" />;
};