import { TableColumns, Table, PrestyledTableProps } from '@increase/shared/components/Table';
import { formatISO8601Timestamp } from '@increase/shared/lib/formatting';
import { useMemo } from 'react';
import { PlatformComplaintListSubmissionGetResponse, PlatformComplianceMetricsSubmissionGetResponse, PlatformVendorListSubmissionGetResponse } from 'src/build/operations';
export type Item = PlatformComplianceMetricsSubmissionGetResponse | PlatformVendorListSubmissionGetResponse | PlatformComplaintListSubmissionGetResponse;
export type ComplianceSubmissionTableProps = PrestyledTableProps<Item> & {
  hrefPrefix: string;
};
export const ComplianceSubmissionTable = (props: ComplianceSubmissionTableProps) => {
  const {
    hrefPrefix,
    ...rest
  } = props;
  const columns = useMemo<TableColumns<Item>>(() => {
    return [{
      header: 'ID',
      expand: 1,
      contents: submission => ({
        text: submission.id
      })
    }, {
      header: 'Period start',
      contents: submission => ({
        text: submission.period_start ? formatISO8601Timestamp(submission.period_start, 'date', 'UTC') : '-'
      })
    }, {
      header: 'Period end',
      contents: submission => ({
        text: submission.period_end ? formatISO8601Timestamp(submission.period_end, 'date', 'UTC') : '-'
      })
    }, {
      header: 'Submitted',
      align: 'right',
      contents: submission => ({
        text: formatISO8601Timestamp(submission.created_at, 'date')
      })
    }];
  }, []);
  const defaultRowProps = (datum: Item) => ({
    href: `/${hrefPrefix}${datum.id}`,
    className: 'hover:bg-main-hover cursor-pointer transition-all group'
  });
  return <Table<Item> columns={columns} getRowProps={defaultRowProps} {...rest} data-sentry-element="Table" data-sentry-component="ComplianceSubmissionTable" data-sentry-source-file="ComplianceSubmissionTable.tsx" />;
};