import { TableColumns, Table, PrestyledTableProps } from '@increase/shared/components/Table';
import { formatISO8601TimestampAsISODate } from '@increase/shared/lib/formatting';
import { useMemo } from 'react';
import { useServices } from 'src/hooks/use-services';
import { AccountStatementGetResponse } from 'src/build/operations';
import { buildPath, ROUTES } from 'src/lib/routes';
type Props = PrestyledTableProps<AccountStatementGetResponse>;
export const AccountStatementTable = (props: Props) => {
  const {
    getRowProps,
    ...rest
  } = props;
  const {
    operations
  } = useServices();
  const columns = useMemo<TableColumns<AccountStatementGetResponse>>(() => {
    return [{
      header: 'Account',
      expand: 1,
      contents: statement => ({
        text: statement.account_id,
        href: buildPath(ROUTES.ACCOUNTS_DETAIL, {
          accountId: statement.account_id
        })
      })
    }, {
      header: 'Statement Period',
      expand: 1,
      contents: statement => ({
        text: `${formatISO8601TimestampAsISODate(statement.statement_period_start)}`
      })
    }];
  }, []);
  const defaultRowProps = (datum: AccountStatementGetResponse) => ({
    href: `${operations}/api_files/${datum.file_id}/view`,
    className: 'hover:bg-main-hover cursor-pointer transition-all group'
  });
  return <Table<AccountStatementGetResponse> columns={columns} getRowProps={getRowProps ?? defaultRowProps} {...rest} data-sentry-element="Table" data-sentry-component="AccountStatementTable" data-sentry-source-file="AccountStatementTable.tsx" />;
};