import { SearchableObject, TableAmountFilter, TableDateFilter, TableEnumFilter, TableSearchableObjectFilter, TableStringFilter } from '@increase/shared/components/Table/filters';
import { IconType } from '@increase/shared/components/Icon';
import { DateTime } from 'luxon';
import { AccountGetResponseBank, AccountListParams, AccountListResponseDataItem, AccountNumberListParams, AccountNumberListResponseDataItem, BankFeeListResponseDataItemFeeType, BankProgramListResponseDataItem, CardListParams, CardListResponseDataItem, EntityGetResponse, EntityReferenceListParams, EntityReferenceListResponseDataItem, GroupGetResponse, GroupListParams, GroupListResponseDataItem, LockboxListParams, LockboxListResponseDataItem, OAuthApplicationListResponseDataItem, OperatorListResponseDataItemRole, ProgramListParams, ProgramListResponseDataItem, accountGet, accountList, accountNumberGet, accountNumberList, bankProgramGet, bankProgramList, cardGet, cardList, entityGet, entityReferenceList, groupGet, groupList, lockboxGet, lockboxList, oAuthApplicationGet, oAuthApplicationList, programGet, programList } from 'src/build/operations';
import { StatusIndicatorColor, accountNumberStatusIndicatorColor, accountStatusIndicatorColor, cardStatusIndicatorColor, entityStatusIndicatorColor, groupStatusIndicatorColor, lockboxStatusIndicatorColor, statusIndicatorColors } from './statusIndicatorColors';
import classNames from 'classnames';
import { TimeRangeSearchParams } from 'src/hooks/timeSearchParameters';

/**
 * Time range filters
 */

export const makeTimeRangeFilter = (timeRange: TimeRangeSearchParams, setTimeRange: (newState: {
  startAt: string | undefined;
  endAt: string | undefined;
}) => void, label: 'Created' | 'Date' | 'Reported To Increase'): TableDateFilter => ({
  type: 'date',
  label,
  id: label.toLowerCase(),
  icon: 'calendar',
  selection: {
    onOrAfter: timeRange.startAt ? DateTime.fromISO(timeRange.startAt) : undefined,
    onOrBefore: timeRange.endAt ? DateTime.fromISO(timeRange.endAt) : undefined
  },
  onChange: ({
    onOrAfter,
    onOrBefore
  }) => {
    // We can't just set these without checking if they've changed because the first change will trigger a re-render
    const afterIso = onOrAfter?.toISODate() ?? undefined;
    const beforeIso = onOrBefore?.toISODate() ?? undefined;
    setTimeRange({
      startAt: afterIso,
      endAt: beforeIso
    });
  }
});

/**
 * String filters
 */
const makeStringFilter = (stateVariable: string | null | undefined, setStateFn: (newState: string | null | undefined) => void, label: 'Name' | 'Description' | 'Keyword' | 'Email'): TableStringFilter => ({
  type: 'string',
  label,
  id: label.toLowerCase(),
  icon: 'file_search',
  value: stateVariable ?? '',
  onChange: value => setStateFn(value === '' ? null : value)
});
export const makeKeywordFilter = (stateVariable: string | null | undefined, setStateFn: (newState: string | null | undefined) => void): TableStringFilter => makeStringFilter(stateVariable, setStateFn, 'Keyword');
export const makeEmailFilter = (stateVariable: string | null | undefined, setStateFn: (newState: string | null | undefined) => void): TableStringFilter => makeStringFilter(stateVariable, setStateFn, 'Email');
export const makeDescriptionFilter = (stateVariable: string | null | undefined, setStateFn: (newState: string | null | undefined) => void): TableStringFilter => makeStringFilter(stateVariable, setStateFn, 'Description');

/**
 * Enum filters
 */
export const makeEnumFilter = <T extends string,>(stateVariable: T[], setStateFn: (newState: T[]) => void, label: string, labeledOptions: Record<T, string>, icon: IconType): TableEnumFilter<T> => ({
  type: 'enum',
  label,
  id: label.toLowerCase(),
  icon,
  labeledOptions,
  selections: stateVariable,
  onChange: setStateFn
});
export const makeStatusFilter = <T extends string,>(stateVariable: T[], setStateFn: (newState: T[]) => void, labeledOptions: Record<T, string>): TableEnumFilter<T> => makeEnumFilter(stateVariable, setStateFn, 'Status', labeledOptions, 'tick_circle');
export const makeBankFilter = (stateVariable: AccountGetResponseBank[], setStateFn: (newState: AccountGetResponseBank[]) => void): TableEnumFilter<AccountGetResponseBank> => makeEnumFilter(stateVariable, setStateFn, 'Bank', {
  blue_ridge_bank: 'Blue Ridge Bank',
  first_internet_bank: 'First Internet Bank',
  global_innovations_bank: 'Global Innovations Bank',
  grasshopper_bank: 'Grasshopper Bank',
  core_bank: 'Core Bank'
}, 'bank');
export const makeOperatorRoleFilter = (stateVariable: OperatorListResponseDataItemRole[], setStateFn: (newState: OperatorListResponseDataItemRole[]) => void): TableEnumFilter<OperatorListResponseDataItemRole> => makeEnumFilter(stateVariable, setStateFn, 'Role', {
  internal: 'Internal',
  check_reviewer: 'Check Reviewer',
  transaction_monitor: 'Transaction Monitor',
  card_printer: 'Card Printer',
  partner_read_write: 'Partner Read/Write',
  partner_read_only: 'Partner Read-Only',
  partner_administrator: 'Partner Administrator',
  limit_approver: 'Limit Approver'
}, 'user');
export const makeFeeTypeFilter = (stateVariable: BankFeeListResponseDataItemFeeType[], setStateFn: (newState: BankFeeListResponseDataItemFeeType[]) => void): TableEnumFilter<BankFeeListResponseDataItemFeeType> => makeEnumFilter(stateVariable, setStateFn, 'Fee Type', {
  implementation: 'Implementation',
  correction: 'Correction',
  check_origination: 'Check Origination',
  ach_same_day_origination: 'ACH Same Day Origination',
  ach_next_day_origination: 'ACH Next Day Origination',
  ach_return: 'ACH Return',
  ach_unauthorized_return: 'ACH Unauthorized Return',
  wire_transfer_origination: 'Wire Transfer Origination',
  real_time_payments_transfer_origination: 'Real Time Payments Transfer Origination',
  card_volume: 'Card Volume',
  monthly_base_fee: 'Monthly Base Fee',
  consumer_entity: 'Consumer Entity',
  non_card_transaction_minimum: 'Non Card Transaction Minimum',
  card_transaction_minimum: 'Card Transaction Minimum'
}, 'coins_swap');

/**
 * Amount filter
 */

export const makeAmountFilter = (amountOrGreater: number | null, amountOrLess: number | null, exactAmountFilter: number | null, setAmountFilter: (newState: {
  newAmountOrGreater: number | null;
  newAmountOrLess: number | null;
  newExactAmount: number | null;
}) => void): TableAmountFilter => {
  const exactAmountSelection = exactAmountFilter !== null ? {
    exactly: exactAmountFilter
  } : undefined;
  const rangeAmountSelection = {
    greaterThanOrEqualTo: amountOrGreater !== undefined && amountOrGreater !== null ? amountOrGreater : undefined,
    lessThanOrEqualTo: amountOrLess !== undefined && amountOrLess !== null ? amountOrLess : undefined
  };
  return {
    type: 'amount',
    label: 'Amount',
    id: 'amount',
    icon: 'coins',
    selection: exactAmountSelection ?? rangeAmountSelection,
    onChange: value => {
      if ('exactly' in value && value.exactly !== undefined) {
        setAmountFilter({
          newAmountOrGreater: null,
          newAmountOrLess: null,
          newExactAmount: value.exactly
        });
      } else if ('greaterThanOrEqualTo' in value && value.greaterThanOrEqualTo !== undefined || 'lessThanOrEqualTo' in value && value.lessThanOrEqualTo !== undefined) {
        setAmountFilter({
          newAmountOrGreater: value.greaterThanOrEqualTo ?? null,
          newAmountOrLess: value.lessThanOrEqualTo ?? null,
          newExactAmount: null
        });
      } else {
        setAmountFilter({
          newAmountOrGreater: null,
          newAmountOrLess: null,
          newExactAmount: null
        });
      }
    }
  };
};

/**
 * Search filters
 */

const SearchFilterStatusIndicator = (props: {
  color: StatusIndicatorColor;
}) => <div className={classNames('h-2 w-2 rounded-full', statusIndicatorColors[props.color])} data-sentry-component="SearchFilterStatusIndicator" data-sentry-source-file="tableFilterHelpers.tsx" />;
export const makeGroupFilter = (stateVariable: string[], setStateFn: (newState: string[]) => void, initialListParameters?: Omit<GroupListParams, 'keyword'>): TableSearchableObjectFilter<GroupListResponseDataItem | GroupGetResponse> => ({
  type: 'searchable-object',
  label: 'Group',
  id: 'group',
  icon: 'cube',
  selections: stateVariable,
  onChange: setStateFn,
  getLabel: group => group.name ?? group.friendly_nickname,
  getInitialElements: () => groupList(initialListParameters ?? {}).then(response => response.data.data),
  search: keyword => groupList({
    ...initialListParameters,
    keyword
  }).then(response => response.data.data),
  getElement: id => groupGet(id).then(response => response.data),
  getHref: group => `/groups/${group.id}`,
  getDetail: group => <SearchFilterStatusIndicator color={groupStatusIndicatorColor[group.status]} />
});
export const makeAccountFilter = (stateVariable: string[], setStateFn: (newState: string[]) => void, initialListParameters?: Omit<AccountListParams, 'keyword'>): TableSearchableObjectFilter<AccountListResponseDataItem> => ({
  type: 'searchable-object',
  label: 'Account',
  id: 'account',
  icon: 'account',
  selections: stateVariable,
  onChange: setStateFn,
  getLabel: account => account.name,
  getInitialElements: () => accountList(initialListParameters ?? {}).then(response => response.data.data),
  search: keyword => accountList({
    ...initialListParameters,
    keyword
  }).then(response => response.data.data),
  getElement: id => accountGet(id).then(response => response.data),
  getDetail: account => <SearchFilterStatusIndicator color={accountStatusIndicatorColor[account.status]} />
});
export const makeAccountNumberFilter = (stateVariable: string[], setStateFn: (newState: string[]) => void, initialListParameters?: Omit<AccountNumberListParams, 'keyword'>): TableSearchableObjectFilter<AccountNumberListResponseDataItem> => ({
  type: 'searchable-object',
  label: 'Account Number',
  id: 'account_anum',
  icon: 'route',
  selections: stateVariable,
  onChange: setStateFn,
  getLabel: accountNumber => accountNumber.name,
  getInitialElements: () => accountNumberList(initialListParameters ?? {}).then(response => response.data.data),
  search: keyword => accountNumberList({
    ...initialListParameters,
    keyword
  }).then(response => response.data.data),
  getElement: id => accountNumberGet(id).then(response => response.data),
  getDetail: accountNumber => <SearchFilterStatusIndicator color={accountNumberStatusIndicatorColor[accountNumber.status]} />
});
export const makeLockboxFilter = (stateVariable: string[], setStateFn: (newState: string[]) => void, initialListParameters?: Omit<LockboxListParams, 'keyword'>): TableSearchableObjectFilter<LockboxListResponseDataItem> => ({
  type: 'searchable-object',
  label: 'Lockbox',
  id: 'lockbox_filter',
  icon: 'lockbox',
  selections: stateVariable,
  onChange: setStateFn,
  getLabel: lockbox => lockbox.description || lockbox.id,
  getInitialElements: () => lockboxList(initialListParameters ?? {}).then(response => response.data.data),
  search: keyword => lockboxList({
    ...initialListParameters,
    keyword
  }).then(response => response.data.data),
  getElement: id => lockboxGet(id).then(response => response.data),
  getDetail: lockbox => <SearchFilterStatusIndicator color={lockboxStatusIndicatorColor[lockbox.status]} />
});
export const makeCardFilter = (stateVariable: string[], setStateFn: (newState: string[]) => void, initialListParameters?: Omit<CardListParams, 'keyword'>): TableSearchableObjectFilter<CardListResponseDataItem> => ({
  type: 'searchable-object',
  label: 'Card',
  id: 'card',
  icon: 'card',
  selections: stateVariable,
  onChange: setStateFn,
  getLabel: card => card.description ?? 'Unnamed Card',
  getInitialElements: () => cardList(initialListParameters ?? {}).then(response => response.data.data),
  search: keyword => cardList({
    ...initialListParameters,
    keyword
  }).then(response => response.data.data),
  getElement: id => cardGet(id).then(response => response.data),
  getDetail: card => <SearchFilterStatusIndicator color={cardStatusIndicatorColor[card.status]} />
});
export const makeEntityFilter = (stateVariable: string[], setStateFn: (newState: string[]) => void, initialListParameters?: Omit<EntityReferenceListParams, 'keyword'>): TableSearchableObjectFilter<EntityReferenceListResponseDataItem | EntityGetResponse> => ({
  type: 'searchable-object',
  label: 'Entity',
  id: 'entity',
  icon: 'users',
  selections: stateVariable,
  onChange: setStateFn,
  getLabel: account => account.name,
  getInitialElements: () => entityReferenceList(initialListParameters ?? {}).then(response => response.data.data),
  search: keyword => entityReferenceList({
    ...initialListParameters,
    keyword
  }).then(response => response.data.data),
  getElement: id => entityGet(id).then(response => response.data),
  getDetail: entity => <SearchFilterStatusIndicator color={entityStatusIndicatorColor[entity.status]} />
});
export const makeProgramFilter = (stateVariable: string[], setStateFn: (newState: string[]) => void, initialListParameters?: Omit<ProgramListParams, 'keyword'>): TableSearchableObjectFilter<ProgramListResponseDataItem> => ({
  type: 'searchable-object',
  label: 'Program',
  id: 'program',
  icon: 'folder',
  selections: stateVariable,
  onChange: setStateFn,
  getLabel: account => account.name,
  getInitialElements: () => programList(initialListParameters ?? {}).then(response => response.data.data),
  search: keyword => programList({
    ...initialListParameters,
    keyword
  }).then(response => response.data.data),
  getElement: id => programGet(id).then(response => response.data)
});
export const makeBankProgramFilter = (stateVariable: string[], setStateFn: (newState: string[]) => void): TableSearchableObjectFilter<BankProgramListResponseDataItem> => ({
  type: 'searchable-object',
  label: 'Bank Program',
  id: 'bank-program',
  icon: 'bank',
  selections: stateVariable,
  onChange: setStateFn,
  getLabel: bankProgram => bankProgram.name,
  getInitialElements: () => bankProgramList().then(response => response.data.data),
  getElement: id => bankProgramGet(id).then(response => response.data)
});
export const makeOAuthApplicationFilter = (stateVariable: string[], setStateFn: (newState: string[]) => void): TableSearchableObjectFilter<OAuthApplicationListResponseDataItem> => ({
  type: 'searchable-object',
  label: 'OAuth Application',
  id: 'oauth-application',
  icon: 'application',
  selections: stateVariable,
  onChange: setStateFn,
  getLabel: application => application.name,
  getInitialElements: () => oAuthApplicationList().then(response => response.data.data),
  getElement: id => oAuthApplicationGet(id).then(response => response.data)
});
export const makeModelIdFilter = (stateVariable: string[], setStateFn: (newState: string[]) => void, label: string, icon: IconType): TableSearchableObjectFilter<SearchableObject> => ({
  type: 'searchable-object',
  label,
  id: label,
  icon,
  selections: stateVariable,
  onChange: setStateFn,
  getLabel: thing => thing.id,
  getInitialElements: async () => [],
  search: async id => [{
    id
  }],
  getElement: async id => ({
    id
  })
});