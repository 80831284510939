import { useCallback, useState } from 'react';
import { useSessionPost } from '../../hooks/reactQuery';
import { TextInput } from '../deprecated/TextInput';
import { absurd } from '../../lib/absurd';
import { encodeRedirectState } from 'shared/lib/singleSignOn';
import { sessionSingleSignOnAuthenticationURLGet } from '../../build/operations';
import { useSearchParams } from 'react-router-dom';
import { AxiosError } from 'axios';
import { redirectTo } from '../../lib/redirect';
import { getPathAndArguments } from 'shared/lib/getPathAndArguments';
type FormState = {
  type: 'email';
} | {
  type: 'sign-in';
};
export const SignIn = () => {
  const sessionPost = useSessionPost();
  const [formToShow, setFormToShow] = useState<FormState>({
    type: 'email'
  });
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [oneTimePassword, setOneTimePassword] = useState('');
  const [signInErrors, setSignInErrors] = useState('');
  const [searchParams] = useSearchParams();
  const redirect = searchParams.get('redirect') || getPathAndArguments();
  const selectNextForm = async (email: string): Promise<void> => {
    const state = encodeRedirectState(email, redirect);
    try {
      const {
        data
      } = await sessionSingleSignOnAuthenticationURLGet({
        email: email,
        state: state
      });
      if (data?.redirect_url) {
        redirectTo(data.redirect_url);
      } else {
        setFormToShow({
          type: 'sign-in'
        });
      }
    } catch (error) {
      let errorMessage = 'An unknown error occured';
      if (error instanceof AxiosError) {
        errorMessage = error?.response?.data?.message || errorMessage;
      }
      setSignInErrors(errorMessage);
    }
  };
  const signIn = useCallback(() => {
    sessionPost.mutate([{
      email,
      password,
      one_time_password: oneTimePassword === '' ? undefined : oneTimePassword
    }]);
  }, [sessionPost, email, password, oneTimePassword]);
  const errorMessage = (): string | undefined | null => {
    if (signInErrors) {
      return signInErrors;
    }
    if (sessionPost.error) {
      return sessionPost.error.response?.data.message;
    }
    if (searchParams.get('single_sign_on_error_reason')) {
      return searchParams.get('single_sign_on_error_reason');
    }
    return undefined;
  };
  return <div className="flex h-screen w-full items-center justify-center p-3" data-sentry-component="SignIn" data-sentry-source-file="sign-in.tsx">
      <div className="w-full max-w-md space-y-4 text-center">
        <>
          <h1 className="text-xl">Administration Welcome!</h1>
          <p className="text-lg">Log in to your account to continue</p>
          {errorMessage() && <div className="text-danger text-sm">{errorMessage()}</div>}
          {(() => {
          switch (formToShow.type) {
            case 'email':
              return <form onSubmit={submitEvent => {
                submitEvent.preventDefault();
                selectNextForm(email);
              }} className="space-y-2">
                    <TextInput type="email" name="email" autoComplete="username" placeholder="Email address" value={email} onChange={e => setEmail(e)} />
                    <button disabled={!email} className="bg-info-strong text-inverse w-full rounded p-1 shadow-sm hover:opacity-80" type="submit">
                      Continue
                    </button>
                  </form>;
            case 'sign-in':
              return <form method="post" onSubmit={submitEvent => {
                submitEvent.preventDefault();
                signIn();
              }} className="space-y-2">
                    <TextInput type="email" name="email" className="input-disabled" placeholder="Email address" disabled={true} value={email} onChange={e => setEmail(e)} />
                    <TextInput type="password" name="password" placeholder="Password" value={password} onChange={e => setPassword(e)} />
                    <TextInput name="oneTimePassword" placeholder="Time-based one-time password" value={oneTimePassword} onChange={e => setOneTimePassword(e)} />
                    <button className="bg-info-strong text-inverse w-full rounded p-1 shadow-sm hover:opacity-80" type="submit">
                      Log in
                    </button>
                  </form>;
            default:
              return absurd(formToShow);
          }
        })()}
        </>
      </div>
    </div>;
};