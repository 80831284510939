import { compact } from 'lodash';
import { ListItem, PropertyList, PropertyListProps } from '@increase/shared/components/PropertyList';
import { formatBank } from 'src/lib/formatEnums';
import { humanize } from '@increase/shared/lib/formatting';
import { BankProgramGetResponse } from 'src/build/operations';
import { bankProgramClassificationIconTypes } from '../tables/BankProgramsTable';
type BankProgramPropertyListProps = {
  bankProgram: BankProgramGetResponse;
} & Omit<PropertyListProps, 'items'>;
export const BankProgramPropertyList = (props: BankProgramPropertyListProps) => {
  const {
    bankProgram,
    ...rest
  } = props;
  return <PropertyList {...rest} items={compact<ListItem>([{
    label: 'ID',
    value: bankProgram.id,
    copyable: bankProgram.id
  }, {
    label: 'Bank',
    value: formatBank(bankProgram.bank)
  }, {
    label: 'Created at',
    value: bankProgram.created_at,
    format: 'date'
  }, bankProgram.closed_at && {
    label: 'Closed at',
    value: bankProgram.closed_at,
    format: 'date'
  }, {
    label: 'Name',
    value: bankProgram.name
  }, bankProgram.classification && {
    label: 'Classification',
    value: humanize(bankProgram.classification),
    badgeColor: 'gray',
    badgeIcon: bankProgramClassificationIconTypes[bankProgram.classification]
  }, bankProgram.marketing_risk_rating && {
    label: 'Marketing Risk Rating',
    value: bankProgram.marketing_risk_rating ? humanize(bankProgram.marketing_risk_rating) : 'Not applicable'
  }, bankProgram.dna_mirror_account_number && {
    label: 'DNA Mirror Account Number',
    value: bankProgram.dna_mirror_account_number
  }])} data-sentry-element="PropertyList" data-sentry-component="BankProgramPropertyList" data-sentry-source-file="BankProgramPropertyList.tsx" />;
};