import classNames from 'classnames';
import { isMobileSafari } from 'react-device-detect';
export type TextAreaProps = JSX.IntrinsicElements['textarea'] & {
  className?: string;
  hasError?: boolean;
  onKeyDown?: (event: React.KeyboardEvent<HTMLTextAreaElement>) => void;
} & {
  value?: string;
};
const TextArea = ({
  className,
  hasError,
  rows = 4,
  value,
  onKeyDown,
  ...rest
}: TextAreaProps) => {
  return <textarea rows={rows} className={classNames('text-strong placeholder:text-disabled block w-full rounded px-[12px] py-[8px] text-[14px] leading-[20px] font-[440] tracking-[.014em] shadow-xs outline-none', 'focus:ring-[3px] focus:outline-0', 'disabled:bg-disabled disabled:opacity-50', 'border-strong bg-main border transition-all', className, hasError ? 'border-danger-strong focus:border-danger-strong focus:ring-danger' : 'focus:border-stronger focus:ring-main', isMobileSafari && 'focus:ring-1')} onKeyDown={onKeyDown} value={value ?? ''} {...rest} data-sentry-component="TextArea" data-sentry-source-file="TextArea.tsx" />;
};
export { TextArea };