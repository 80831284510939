import { PageLayout } from '../deprecated/PageLayout';
import { TableStateWrapper } from '@increase/shared/components/TableStateWrapper';
import { ROUTES, buildPath } from 'src/lib/routes';
import { SanctionsScreeningReviewListResponseDataItem } from 'src/build/operations';
import { useOperatorGet, useSanctionsScreeningReviewListInfinite } from 'src/hooks/reactQuery';
import { SanctionsScreeningReviewsTable } from '../tables/SanctionScreeningReviewsTable';
const getRowProps = (datum: SanctionsScreeningReviewListResponseDataItem) => ({
  href: buildPath(ROUTES.SANCTIONS_SCREENING_REVIEW_DETAIL, {
    reviewID: datum.id
  }),
  className: 'hover:bg-main-hover cursor-pointer transition-all group'
});
export const PendingSanctionsScreeningReviewsListPage = () => {
  const {
    data
  } = useOperatorGet({});
  const listResponse = useSanctionsScreeningReviewListInfinite({
    statuses: ['pending_reviewing'],
    reviewers: data?.multibank ? ['increase'] : undefined
  });
  return <PageLayout headline="Pending Sanctions Screening Reviews" data-sentry-element="PageLayout" data-sentry-component="PendingSanctionsScreeningReviewsListPage" data-sentry-source-file="PendingSanctionsScreeningReviewsListPage.tsx">
      <TableStateWrapper {...listResponse} table={SanctionsScreeningReviewsTable} style="primary" emptyTitle="No items pending review." emptySubtitle="" filters={[]} getRowProps={getRowProps} showRecordId={true} data-sentry-element="TableStateWrapper" data-sentry-source-file="PendingSanctionsScreeningReviewsListPage.tsx" />
    </PageLayout>;
};