import { compact } from 'lodash';
import { ListItem, PropertyList, PropertyListProps } from '@increase/shared/components/PropertyList';
import { formatBank } from 'src/lib/formatEnums';
import { formatAccuratePercentage, formatInteger } from '@increase/shared/lib/formatting';
import { ProgramGetResponse } from 'src/build/operations';
import { useGroupPropertyRow } from 'src/lib/propertyListHelpers';
import { buildPath, ROUTES } from 'src/lib/routes';
import { useBankProgramGet } from 'src/hooks/reactQuery';
type ProgramPropertyListProps = {
  program: ProgramGetResponse;
} & Omit<PropertyListProps, 'items'>;
export const ProgramPropertyList = (props: ProgramPropertyListProps) => {
  const {
    program,
    ...rest
  } = props;
  const groupRow = useGroupPropertyRow(program.group_id);
  const {
    data: bankProgram
  } = useBankProgramGet(props.program.bank_program_id);
  return <PropertyList {...rest} items={compact<ListItem>([{
    label: 'ID',
    value: program.id,
    copyable: program.id
  }, {
    label: 'Name',
    value: program.name
  }, {
    label: 'Bank',
    value: formatBank(program.bank)
  }, groupRow, {
    label: 'Opened at',
    value: program.created_at,
    format: 'date'
  }, program.interest_rate && {
    label: 'Interest rate',
    value: formatAccuratePercentage(program.interest_rate.current_value),
    tooltip: program.interest_rate.summary
  }, {
    label: 'Bank Program',
    value: bankProgram?.name || program.bank_program_id,
    href: buildPath(ROUTES.BANK_PROGRAMS_DETAIL, {
      bankProgramId: program.bank_program_id
    })
  }, {
    label: 'Number of open accounts',
    value: formatInteger(program.count_accounts)
  }, {
    label: 'Number of entities',
    value: formatInteger(program.count_entities)
  }, program.billing_account_id && {
    label: 'Billing account',
    value: program.billing_account_id,
    href: buildPath(ROUTES.ACCOUNTS_DETAIL, {
      accountId: program.billing_account_id
    })
  }, program.interest_account_id && {
    label: 'Interest account',
    value: program.interest_account_id,
    href: buildPath(ROUTES.ACCOUNTS_DETAIL, {
      accountId: program.interest_account_id
    })
  }, program.reserve_account_id && {
    label: 'Reserve account',
    value: program.reserve_account_id,
    href: buildPath(ROUTES.ACCOUNTS_DETAIL, {
      accountId: program.reserve_account_id
    })
  }, {
    label: 'Allows new accounts?',
    value: program.allow_account_creation ? 'Yes' : 'No',
    badgeColor: program.allow_account_creation ? undefined : 'red'
  }])} data-sentry-element="PropertyList" data-sentry-component="ProgramPropertyList" data-sentry-source-file="ProgramPropertyList.tsx" />;
};