// FIXME - this is a duplicate of the function in @increase/shared/lib/formatting.
export const formatInteger = (value: number): string => {
  return value.toLocaleString('en-US', {
    maximumFractionDigits: 0,
  });
};

export const formatEmployerIdentificationNumber = (value: string): string => {
  if (value.match(/^\d{9}$/)) {
    return `${value.slice(0, 2)}-${value.slice(2)}`;
  }
  return value;
};
