import classNames from 'classnames';
import { Box } from './Box';
import { Icon, IconType } from './Icon';
import { Body, BodyStylingProps, Caption } from './Text';
type Props = {
  items: MenuItem[];
};
export type MenuItem = {
  icon?: IconType;
  iconClassName?: string;
  title: string;
  subtitle?: string;
  key: string;
  textColor?: BodyStylingProps['color'];
  onClick?: () => void;
};
export const MenuLayout = ({
  items
}: Props) => {
  const hasSubtitle = items.some(item => item.subtitle);
  return <Box gap="0" className={classNames('p-1', hasSubtitle && 'divide-color-main divide-y')} data-sentry-element="Box" data-sentry-component="MenuLayout" data-sentry-source-file="MenuLayout.tsx">
      {items.map(item => <div key={item.key} className={classNames('flex flex-row items-center gap-2 rounded-sm', item.onClick && 'hover:bg-main-selected cursor-pointer', item.subtitle ? 'px-2 py-4 md:py-2.5 md:pr-3 md:pl-2' : 'px-2 py-3 md:px-3 md:py-1.5')} onClick={item.onClick}>
          {item.icon && <div className={classNames(item.iconClassName ? item.iconClassName : 'stroke-main', 'shrink-0 self-center rounded')}>
              <Icon name={item.icon} className="stroke-[inherit]" />
            </div>}
          <div className="flex flex-col">
            <Body color={item.textColor ? item.textColor : 'emphasis'} weight="medium">
              {item.title}
            </Body>
            {item.subtitle && <Caption color="secondary">{item.subtitle}</Caption>}
          </div>
        </div>)}
    </Box>;
};