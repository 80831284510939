import { useAccountGet, useAccountListInfinite, useEntityGet, useGroupGet, useOperatorGet, useProgramCreditExposureGet, useProgramGet } from 'src/hooks/reactQuery';
import { PageLayout } from '../deprecated/PageLayout';
import { SideBySide } from '@increase/shared/components/SideBySide';
import { Loading } from '@increase/shared/components/Loading';
import { TableStateWrapper } from '@increase/shared/components/TableStateWrapper';
import { AccountsTable } from '../tables/AccountsTable';
import { ProgramPropertyList } from '../property-lists/ProgramPropertyList';
import { AlertList } from '@increase/shared/components/AlertList';
import { ProgramTransferSettingsPropertyList } from '../property-lists/ProgramTransferSettingsPropertyList';
import { ProgramReportingConfigurationPropertyList } from '../property-lists/ProgramReportingConfigurationPropertyList';
import { formatAmount, formatInteger, humanize } from '@increase/shared/lib/formatting';
import { InternalOperatorOnly } from '../internal-operator-only';
import { StackOfLinks } from '@increase/shared/components/StackOfLinks';
import { AssociatedManualTasks } from '../associated-manual-tasks';
import { ListItem, PropertyList } from '@increase/shared/components/PropertyList';
import { useTypedParams } from 'react-router-typesafe-routes/dom';
import { ROUTES, buildPath } from 'src/lib/routes';
import { ProgramTransactionLimitsPropertyList } from '../property-lists/ProgramTransactionLimitsPropertyList';
import { ProgramGetResponse } from 'src/build/operations';
import { ProgramCardTransactionLimits } from '../property-lists/ProgramCardTransactionLimits';
import { FirstInternetBankProgramTransactionLimitsPropertyList } from '../property-lists/FirstInternetBankProgramTransactionLimitsPropertyList';
import { UnreleasedCreditExposureTransactionSummariesTable } from '../tables/UnreleasedCreditExposureTransactionSummariesTable';
import { compact } from 'lodash';
import { useDrawer } from 'shared/components/Modal';
import { SetProgramInterestRateDrawer } from '../drawers/SetProgramInterestRateDrawer';
import { Button } from 'shared/components/Button';
const ForBenefitOfEntityDetails = (props: {
  entityId: string;
}) => {
  const {
    data
  } = useEntityGet(props.entityId);
  if (!data) {
    return <Loading />;
  }
  return <AlertList tasks={[{
    icon: 'info',
    title: `${data.name} • For benefit of`,
    key: '0',
    body: `This is an FBO program. All of the funds associated with this program are owned by ${data.name} for the benefit of the platform's customers.`,
    style: 'informational',
    link: buildPath(ROUTES.ENTITY, {
      entityId: props.entityId
    })
  }]} data-sentry-element="AlertList" data-sentry-component="ForBenefitOfEntityDetails" data-sentry-source-file="ProgramDetailPage.tsx" />;
};
const CreditExposureDetails = (props: {
  program: ProgramGetResponse;
}) => {
  const {
    data: creditExposure
  } = useProgramCreditExposureGet(props.program.id);
  if (!creditExposure) {
    return <Loading />;
  }
  return <>
      <PropertyList title="Credit exposure" items={[{
      label: 'Inbound funds hold policy',
      value: humanize(props.program.inbound_funds_hold_policy)
    }, {
      label: '[A] Credit exposure limit',
      value: formatAmount(props.program.credit_exposure_limit, 'USD')
    }, {
      label: '[B] Current credit exposure',
      value: formatAmount(creditExposure.usd_current_credit_exposure, 'USD')
    }, {
      label: '[C] Current balance',
      value: formatAmount(creditExposure.usd_current_balance, 'USD')
    }, {
      label: '[D] Pending balance',
      value: formatAmount(creditExposure.usd_pending_balance, 'USD')
    }, {
      label: '[A - B] Credit exposure headroom',
      value: formatAmount(props.program.credit_exposure_limit - creditExposure.usd_current_credit_exposure, 'USD')
    }, {
      label: '[C + D] Available balance',
      value: formatAmount(creditExposure.usd_current_balance + creditExposure.usd_pending_balance, 'USD')
    }, {
      label: 'Equation',
      value: 'Withdrawable = min(A - B, C + D)'
    }, {
      label: 'Available amount user can withdraw',
      value: formatAmount(creditExposure.usd_credit_exposure_balance, 'USD')
    }]} data-sentry-element="PropertyList" data-sentry-source-file="ProgramDetailPage.tsx" />
      <TableStateWrapper table={UnreleasedCreditExposureTransactionSummariesTable} data={creditExposure.unreleased_summaries.map(x => ({
      ...x,
      id: `${x.source_type}-${x.releases_at}`
    }))} hasNextPage={false} error={null} style="detail" title="Unreleased credit exposure" emptyTitle="No unreleased credit exposure transactions" emptySubtitle="" viewMoreHref="" data-sentry-element="TableStateWrapper" data-sentry-source-file="ProgramDetailPage.tsx" />
    </>;
};
const ReserveDetails = (props: {
  program: ProgramGetResponse;
}) => {
  const {
    data: reserveAccount
  } = useAccountGet(props.program.reserve_account_id || '', {
    enabled: !!props.program.reserve_account_id
  });
  return <PropertyList title="Reserve" items={compact<ListItem>([{
    label: 'Account',
    value: props.program.reserve_account_id || 'none set',
    href: props.program.reserve_account_id ? buildPath(ROUTES.ACCOUNTS_DETAIL, {
      accountId: props.program.reserve_account_id
    }) : undefined
  }, props.program.reserve_account_id && {
    label: 'Increase-configured minimum',
    value: props.program.reserve_account_expected_minimum_balance === null ? 'none set' : formatAmount(props.program.reserve_account_expected_minimum_balance, 'USD')
  }, props.program.first_internet_bank_required_minimum_reserve !== null && {
    label: 'First Internet Bank required minimum',
    value: formatAmount(props.program.first_internet_bank_required_minimum_reserve, 'USD')
  }, props.program.reserve_account_id && {
    label: 'Current balance',
    value: reserveAccount ? formatAmount(reserveAccount.current_balance, 'USD') : ''
  }])} data-sentry-element="PropertyList" data-sentry-component="ReserveDetails" data-sentry-source-file="ProgramDetailPage.tsx" />;
};
export const ProgramDetailPage = () => {
  const {
    programId
  } = useTypedParams(ROUTES.PROGRAMS_DETAIL);
  const {
    data: program
  } = useProgramGet(programId);
  const {
    data: operator
  } = useOperatorGet({});
  const {
    data: group
  } = useGroupGet(program?.group_id || '', {
    enabled: !!program
  });
  const accountsList = useAccountListInfinite({
    program_ids: [programId],
    limit: 5
  });
  const {
    showDrawer,
    closeDrawer
  } = useDrawer();
  if (!program || !group || !operator) {
    return <Loading />;
  }
  const showSetInterestRateDrawer = () => {
    showDrawer(<SetProgramInterestRateDrawer program={program} onSuccess={closeDrawer} />);
  };
  const operatorIsInternalOrGrasshopperOrCore = operator.entitlements.includes('internal_read_write') || operator.bank === 'grasshopper_bank' || operator.bank === 'core_bank';
  const operatorIsFirstInternetBank = operator.bank === 'first_internet_bank';
  const operatorCanSetInterestRate = operator.entitlements.includes('partner_program_write') && operator.bank !== 'first_internet_bank';
  return <PageLayout headline={program.name} action={operatorCanSetInterestRate && <Button text="Set interest rate" style="secondary" onClick={showSetInterestRateDrawer} />} data-sentry-element="PageLayout" data-sentry-component="ProgramDetailPage" data-sentry-source-file="ProgramDetailPage.tsx">
      <SideBySide mode="rightDrawer" right={<>
            <ProgramPropertyList program={program} title="Summary" />
            <InternalOperatorOnly>
              <StackOfLinks links={[{
          href: buildPath(ROUTES.GROUP_PROGRAM_FEE_PLANS, {
            groupID: program.group_id,
            programID: program.id
          }),
          text: 'Fee plans'
        }, {
          href: buildPath(ROUTES.DOCUMENT_REQUESTS_LIST, {}, {
            program: [program.id]
          }),
          text: 'Document requests'
        }, {
          href: buildPath(ROUTES.CONTROLS_LIST, {}, {
            program: [program.id]
          }),
          text: 'Controls'
        }, {
          href: buildPath(ROUTES.DOCUMENT_REQUEST_SCHEDULE, {
            programId: program.id
          }),
          text: 'Document request schedule'
        }]} />
            </InternalOperatorOnly>
            <AssociatedManualTasks objectId={programId} />
          </>} left={<>
            {program.for_benefit_of_entity_id && <ForBenefitOfEntityDetails entityId={program.for_benefit_of_entity_id} />}
            <TableStateWrapper table={AccountsTable} {...accountsList} style="detail" title={`Accounts • ${formatAmount(program.sum_balances, 'USD')}`} emptyTitle="No accounts" emptySubtitle="There are no accounts to display" viewMoreHref={buildPath(ROUTES.ACCOUNTS_LIST, {}, {
        program: [programId]
      })} showBank={false} showProgram={false} showGroup={false} showEntity={false} />
            <ProgramReportingConfigurationPropertyList title="Compliance reporting" program={program} />
            <ProgramTransferSettingsPropertyList title="Transfer settings" program={program} />
            <InternalOperatorOnly>
              <PropertyList title="Route count limits" items={[{
          label: 'Maximum account numbers',
          value: program.maximum_account_number_count ? formatInteger(program.maximum_account_number_count) : 'Not set, using default value.'
        }, {
          label: 'Maximum cards',
          value: program.maximum_card_count ? formatInteger(program.maximum_card_count) : 'Not set, using default value.'
        }, {
          label: 'Maximum physical cards',
          value: program.maximum_physical_card_count ? formatInteger(program.maximum_physical_card_count) : 'Not set, using default value.'
        }]} />
            </InternalOperatorOnly>

            {operatorIsInternalOrGrasshopperOrCore && <ProgramTransactionLimitsPropertyList program={program} />}

            {operatorIsFirstInternetBank && <FirstInternetBankProgramTransactionLimitsPropertyList program={program} />}

            <InternalOperatorOnly>
              <ProgramCardTransactionLimits program={program} title="Card limits" />
            </InternalOperatorOnly>

            <InternalOperatorOnly>
              {(program.reserve_account_id || program.first_internet_bank_required_minimum_reserve !== null) && <ReserveDetails program={program} />}
            </InternalOperatorOnly>

            {operator.entitlements.includes('internal_read_write') ? <CreditExposureDetails program={program} /> : <PropertyList title="Credit exposure" items={[{
        label: 'Inbound funds hold policy',
        value: humanize(program.inbound_funds_hold_policy)
      }]} />}
          </>} data-sentry-element="SideBySide" data-sentry-source-file="ProgramDetailPage.tsx" />
    </PageLayout>;
};