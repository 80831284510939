import type { AxiosRequestConfig } from 'axios';
import { useFederalReserveGatewayLimitsList } from 'src/hooks/reactQuery';
import { useServices } from '../hooks/use-services';
import { PageLayout } from './deprecated/PageLayout';
import { StackedList } from '@increase/shared/components/lists/StackedList';
import { ListRow } from '@increase/shared/components/lists/ListRow';
import { ROUTES, buildPath } from 'src/lib/routes';
function useAxiosOptions(): AxiosRequestConfig {
  const {
    federalreservegateway: baseURL
  } = useServices();
  return {
    baseURL
  };
}
export const AllFederalReserveLimits = () => {
  const {
    data,
    error
  } = useFederalReserveGatewayLimitsList(useAxiosOptions());
  const loading = !error && !data;
  return <PageLayout id="application.service.all-federal-reserve-limits" headline="Federal Reserve Limits" data-sentry-element="PageLayout" data-sentry-component="AllFederalReserveLimits" data-sentry-source-file="all-federal-reserve-limits.tsx">
      {loading && <div>Loading...</div>}
      {error && <div id="application.service.application.service.all-federal-reserve-limits.error" className="border-danger bg-danger text-danger relative rounded border px-4 py-3">
          Error: {error.message}
        </div>}
      {data && <StackedList>
          {data.limits.map(item => <ListRow key={item.id} icon="building" title={`Limits for routing number: ${item.routing_number}`} href={buildPath(ROUTES.FEDERAL_RESERVE_LIMIT_DETAILS, {
        limitsId: item.id
      })} />)}
        </StackedList>}
    </PageLayout>;
};