import { useMemo } from 'react';
import { PrestyledTableProps, Table, TableColumns } from '@increase/shared/components/Table';
import { formatISO8601Timestamp, humanize } from '@increase/shared/lib/formatting';
import { UnusualActivityReportListResponseDataItem } from 'src/build/operations';
import { buildPath, ROUTES } from 'src/lib/routes';
import { increaseReviewStatusBadgeColor } from 'src/components/detail_pages/UnusualActivityReportDetailPage';
type Props = PrestyledTableProps<UnusualActivityReportListResponseDataItem>;
const defaultRowProps = (datum: UnusualActivityReportListResponseDataItem) => {
  return {
    href: buildPath(ROUTES.DIRECT_UNUSUAL_ACTIVITY_REPORTS_DETAIL, {
      unusualActivityReportId: datum.id
    }),
    className: 'hover:bg-main-hover cursor-pointer transition-all group'
  };
};
export const UnusualActivityReportsTable = (props: Props) => {
  const {
    getRowProps,
    ...rest
  } = props;
  const columns = useMemo<TableColumns<UnusualActivityReportListResponseDataItem>>(() => {
    return [{
      header: 'Identifier',
      expand: 1,
      contents: report => {
        return {
          text: report.id,
          textWeight: 'mono'
        };
      }
    }, {
      header: 'Referral Channel',
      contents: report => ({
        text: humanize(report.referral_channel)
      })
    }, {
      header: 'Increase Review Status',
      align: 'right',
      contents: report => ({
        text: humanize(report.increase_review_status),
        badgeColor: increaseReviewStatusBadgeColor[report.increase_review_status]
      })
    }, {
      header: 'Created',
      align: 'right',
      contents: report => ({
        text: formatISO8601Timestamp(report.created_at, 'date')
      })
    }];
  }, []);
  return <Table {...rest} getRowProps={getRowProps ?? defaultRowProps} columns={columns} data-sentry-element="Table" data-sentry-component="UnusualActivityReportsTable" data-sentry-source-file="UnusualActivityReportsTable.tsx" />;
};