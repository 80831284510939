import {
  AccountGetResponseBank,
  BankProgramGetResponseBank,
} from 'src/build/operations';

export const formatBank = (
  bank: BankProgramGetResponseBank | AccountGetResponseBank
): string => {
  return {
    first_internet_bank: 'First Internet Bank',
    blue_ridge_bank: 'Blue Ridge Bank',
    global_innovations_bank: 'Global Innovations Bank',
    grasshopper_bank: 'Grasshopper Bank',
    core_bank: 'Core Bank',
  }[bank];
};
