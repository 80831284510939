import React, { useMemo } from 'react';
import { PageLayout } from '../deprecated/PageLayout';
import { useBankProgramListInfinite, useOperatorGet } from 'src/hooks/reactQuery';
import { BankProgramsTable } from '../tables/BankProgramsTable';
import { makeBankFilter } from 'src/lib/tableFilterHelpers';
import { makeKeywordFilter } from 'src/lib/tableFilterHelpers';
import { TableStateWrapper } from '@increase/shared/components/TableStateWrapper';
import { ROUTES } from 'src/lib/routes';
import { useSearchParamsState } from 'shared/hooks/useTypedSearchParamsState';
import { TableFilter } from 'shared/components/Table/filters';
import { compact } from 'lodash';
export const BankProgramListPage = () => {
  const {
    data: operator
  } = useOperatorGet({});
  const [bankFilter, setBankFilter] = useSearchParamsState(ROUTES.BANK_PROGRAMS_LIST, 'bank');
  const [keywordFilter, setKeywordFilter] = useSearchParamsState(ROUTES.BANK_PROGRAMS_LIST, 'keyword');
  const filters: TableFilter[] = useMemo(() => compact([operator?.multibank && makeBankFilter(bankFilter, setBankFilter), makeKeywordFilter(keywordFilter, setKeywordFilter)]), [bankFilter, keywordFilter, operator?.multibank, setBankFilter, setKeywordFilter]);
  const bankprogramList = useBankProgramListInfinite({
    bank: bankFilter.length > 0 ? bankFilter : undefined,
    keyword: keywordFilter ? keywordFilter : undefined
  });
  return <PageLayout headline={'Bank programs'} data-sentry-element="PageLayout" data-sentry-component="BankProgramListPage" data-sentry-source-file="BankProgramListPage.tsx">
      <TableStateWrapper table={BankProgramsTable} style="primary" {...bankprogramList} emptyTitle="No bank programs" emptySubtitle="There are no bank programs to display" filters={filters} data-sentry-element="TableStateWrapper" data-sentry-source-file="BankProgramListPage.tsx" />
    </PageLayout>;
};