import React from 'react';
import { useEntityAddressVerificationList } from 'src/hooks/reactQuery';
import { PageLayout } from '../deprecated/PageLayout';
import { TableStateWrapper } from '@increase/shared/components/TableStateWrapper';
import { ROUTES } from 'src/lib/routes';
import { useTypedParams } from 'react-router-typesafe-routes/dom';
import { badgeColors, EntityAddressVerificationTable } from '../tables/EntityAddressVerificationTable';
import { useDrawer } from 'shared/components/Modal';
import { EntityAddressVerificationResultListResponseDataItem } from 'src/build/operations';
import { DrawerLayout } from 'shared/components/DrawerLayout';
import { Box } from 'shared/components/Box';
import { PropertyList } from 'shared/components/PropertyList';
import { StyledLink } from 'shared/components/StyledLink';
import { Body } from 'shared/components/Text';
import { compact } from 'lodash';
import { humanize } from 'shared/lib/formatting';
const boolean = (value: boolean | null) => value == null ? '?' : value === true ? 'Yes' : 'No';
const Drawer = ({
  data
}: {
  data: EntityAddressVerificationResultListResponseDataItem;
}) => {
  return <DrawerLayout title="Details" body={<Box>
          <StyledLink href={`https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(compact([data.clean_line1, data.clean_line2, data.clean_city, data.clean_state, data.clean_zip]).join(' '))}`} target="_blank" style="underline">
            <Body>View on Google Maps</Body>
          </StyledLink>

          <PropertyList title="Overview" items={[{
      label: 'Outcome',
      value: humanize(data.outcome),
      badgeColor: badgeColors[data.outcome]
    }, {
      label: 'Original',
      value: compact([data.original_line1, data.original_line2, data.original_city, data.original_state, data.original_zip]).join(' '),
      copyable: compact([data.original_line1, data.original_line2, data.original_city, data.original_state, data.original_zip]).join(' ')
    }, {
      label: 'Clean',
      value: compact([data.clean_line1, data.clean_line2, data.clean_city, data.clean_state, data.clean_zip]).join(' '),
      copyable: compact([data.clean_line1, data.clean_line2, data.clean_city, data.clean_state, data.clean_zip]).join(' ')
    }]} />

          <PropertyList title="USPS Details" items={[{
      label: 'Address Type',
      value: data.address_type || '?'
    }, {
      label: 'Record Type',
      value: data.record_type || '?'
    }, {
      label: 'Carrier Route Type',
      value: data.carrier_route_type || '?'
    }, {
      label: 'Is Default Building Address',
      value: boolean(data.default_building_address)
    }, {
      label: 'Is PO Box Only Address',
      value: boolean(data.po_box_only_flag)
    }, {
      label: 'County',
      value: data.county || '?'
    }, {
      label: 'County FIPS',
      value: data.county_fips || '?'
    }, {
      label: 'Carrier Route',
      value: data.carrier_route || '?'
    }, data.latitude && data.longitude ? {
      label: 'Latitude / Longitude',
      value: `${data.latitude}, ${data.longitude}`,
      href: `http://maps.google.com/maps?z=12&t=m&q=loc:${data.latitude}+${data.longitude}`
    } : {
      label: 'Latitude / Longitude',
      value: '?'
    }]} />

          <PropertyList title="USPS Footnotes" items={data.footnotes?.map(footnote => ({
      label: footnote.name,
      tooltip: footnote.description,
      tooltipDirection: 'right',
      value: footnote.code,
      badgeColor: footnote.positive_sentiment ? 'green' : 'red'
    })) || [{
      label: 'None',
      value: ''
    }]} />
        </Box>} data-sentry-element="DrawerLayout" data-sentry-component="Drawer" data-sentry-source-file="EntityAddressVerificationListPage.tsx" />;
};
export const EntityAddressVerificationListPage = () => {
  const {
    addressableId
  } = useTypedParams(ROUTES.ENTITY_ADDRESS_VERIFICATION);
  const listResult = useEntityAddressVerificationList({
    addressable_id: addressableId
  });
  const {
    showDrawer
  } = useDrawer();
  return <PageLayout headline={`Entity Address Verifications (${addressableId})`} data-sentry-element="PageLayout" data-sentry-component="EntityAddressVerificationListPage" data-sentry-source-file="EntityAddressVerificationListPage.tsx">
      <TableStateWrapper table={EntityAddressVerificationTable} {...listResult} style="primary" emptyTitle="No Address Verifications" emptySubtitle="There are no address details to display" filters={[]} getRowProps={row => ({
      className: 'hover:bg-main-hover cursor-pointer transition-all group',
      onClick: () => showDrawer(<Drawer data={row} />)
    })} data-sentry-element="TableStateWrapper" data-sentry-source-file="EntityAddressVerificationListPage.tsx" />
    </PageLayout>;
};