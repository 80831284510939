import React from 'react';
import { PageLayout } from '../deprecated/PageLayout';
import { useBookkeepingEntrySetGet } from 'src/hooks/reactQuery';
import { SideBySide } from '@increase/shared/components/SideBySide';
import { TableStateWrapper } from '@increase/shared/components/TableStateWrapper';
import { useTypedParams } from 'react-router-typesafe-routes/dom';
import { buildPath, ROUTES } from 'src/lib/routes';
import { ListItem, PropertyList } from '@increase/shared/components/PropertyList';
import { Loading } from '@increase/shared/components/Loading';
import { BookkeepingEntriesForOneEntrySetTable } from '../tables/BookkeepingEntriesForOneEntrySetTable';
import { useGroupPropertyRow } from 'src/lib/propertyListHelpers';
import { compact, sortBy } from 'lodash';
export const BookkeepingEntrySetDetailPage = () => {
  const {
    bookkeepingEntrySetID
  } = useTypedParams(ROUTES.BOOKKEEPING_ENTRY_SETS_DETAIL);
  const {
    data
  } = useBookkeepingEntrySetGet(bookkeepingEntrySetID);
  const groupRow = useGroupPropertyRow(data?.group_id);
  if (!data) {
    return <Loading />;
  }
  return <PageLayout headline={'Bookkeeping Entry Set'} data-sentry-element="PageLayout" data-sentry-component="BookkeepingEntrySetDetailPage" data-sentry-source-file="BookeepingEntrySetDetailPage.tsx">
      <SideBySide mode="rightDrawer" right={<PropertyList items={compact<ListItem>([{
      label: 'ID',
      value: data.id
    }, data.transaction_id && {
      label: 'Cash Transaction ID',
      value: data.transaction_id,
      href: buildPath(ROUTES.TRANSACTION_DETAIL, {
        transactionID: data.transaction_id
      })
    }, groupRow, {
      label: 'Created at',
      value: data.created_at,
      format: 'month-day-year-hour-minute-second'
    }, {
      label: 'Date',
      value: data.created_at,
      format: 'month-day-year-hour-minute-second'
    }])} title="Details" />} left={<TableStateWrapper table={BookkeepingEntriesForOneEntrySetTable} style="detail" data={sortBy(data.entries, e => e.amount)} error={null} title="Entries" emptyTitle="No entries" emptySubtitle="There are no entries to display" viewMoreHref="" />} data-sentry-element="SideBySide" data-sentry-source-file="BookeepingEntrySetDetailPage.tsx" />
    </PageLayout>;
};