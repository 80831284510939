import React, { useState } from 'react';
import { ImageToolInner, MultiImageTool } from './check-workflow/image-tool';
import { MICRResult, TranscribeMICR } from './check-workflow/transcribe-micr';
import { ImageThreshold } from './check-workflow/image-threshold';
import { SubmitReview } from './check-workflow/submit-review';
import { useNavigate } from 'react-router';
import { useCheckDepositGet, useCheckDepositReject } from 'src/hooks/reactQuery';
import { useServices } from 'src/hooks/use-services';
import { useCategoricals } from 'src/hooks/use-categoricals';
import { CheckDepositGetResponse, CheckDepositRejectDirectivePostParametersReason } from 'src/build/operations';
import { Button } from '@increase/shared/components/Button';
import { Button as DeprecatedButton } from './deprecated/Button';
import { PageLayout } from './deprecated/PageLayout';
import { StyledLink } from './deprecated/StyledLink';
import { OperatorTags } from './operator-tags';
import { useTypedParams } from 'react-router-typesafe-routes/dom';
import { ROUTES, buildPath } from 'src/lib/routes';
import { imageDataToCanvasImageSource } from 'src/lib/image-utility';
import { Box } from '@increase/shared/components/Box';
import { Body, Caption, Heading } from '@increase/shared/components/Text';
import { useKeyPressEvent } from 'react-use';
import { PropertyList } from '@increase/shared/components/PropertyList';
import { formatAmount } from '@increase/shared/lib/formatting';
import { Select, SelectSection } from '@increase/shared/components/Select';
import { computeThreshold } from 'src/lib/otsu';
import { StackOfLinks } from 'shared/components/StackOfLinks';
import { EndorsementStampingTool } from './check-workflow/endorsement-stamping-tool';
const TARGET_DPI = 240;
const SIZE_MARGIN = 1.15;
export const CHECK_IMAGE_MINIMUM_HEIGHT = Math.ceil(1.752 * TARGET_DPI * SIZE_MARGIN);
export const CHECK_IMAGE_MINIMUM_WIDTH = Math.ceil(3 * TARGET_DPI * SIZE_MARGIN);
const KeyListener: React.FC<{
  listenedKey: string;
  onKeyPress: (e: KeyboardEvent) => void;
}> = ({
  listenedKey,
  onKeyPress
}) => {
  useKeyPressEvent(listenedKey, onKeyPress);
  return <></>;
};
const RejectCheckForm = ({
  checkDeposit
}: {
  checkDeposit: CheckDepositGetResponse;
}) => {
  const checkDepositReject = useCheckDepositReject();
  const navigate = useNavigate();
  const [rejectionReason, setRejectionReason] = useState<CheckDepositRejectDirectivePostParametersReason | null>(null);
  const categoricals = useCategoricals();
  const reject = () => {
    if (rejectionReason) {
      checkDepositReject.mutateAsync([checkDeposit.id, {
        reason: rejectionReason
      }]).then(() => {
        navigate(ROUTES.CHECK_DEPOSIT_REVIEW_LIST.buildPath({}));
      }).catch(err => {
        alert(err);
      });
    }
  };
  if (!categoricals) {
    return <div />;
  }
  const reasons: SelectSection<CheckDepositRejectDirectivePostParametersReason | null>[] = [{
    options: Object.values(CheckDepositRejectDirectivePostParametersReason).map(r => ({
      value: r,
      label: r
    }))
  }];
  return <Box data-sentry-element="Box" data-sentry-component="RejectCheckForm" data-sentry-source-file="check-deposit-review.tsx">
      <Heading size="small" contents="Or, reject this check:" data-sentry-element="Heading" data-sentry-source-file="check-deposit-review.tsx" />
      <div>
        <Select<CheckDepositRejectDirectivePostParametersReason | null> data-testid="application.check-review.rejectionReasonInput" id={'application.check-review.rejectionReasonInput'} name="rejectionReason" value={rejectionReason ?? undefined} onUpdate={s => setRejectionReason(s)} placeholder="Select a reason..." sections={reasons} data-sentry-element="Select" data-sentry-source-file="check-deposit-review.tsx" />
      </div>
      <div>
        <DeprecatedButton data-testid="checkDepositInstruction.reject" id="checkDepositInstruction.reject" disabled={!rejectionReason || checkDepositReject.isLoading} onClick={reject} data-sentry-element="DeprecatedButton" data-sentry-source-file="check-deposit-review.tsx">
          Reject
        </DeprecatedButton>
      </div>
    </Box>;
};
const suggestedInitialMICRBounds = (frontPixels: ImageData) => {
  const down = Math.floor(frontPixels.height * 0.85);
  return {
    top: down,
    left: 0,
    width: frontPixels.width,
    height: frontPixels.height - down
  };
};
type stepDetails = {
  heading: string;
  subheading?: string;
  additionalHelpLink?: string;
  keyboardShortcuts?: {
    key: string[];
    action: string;
  }[];
  optionalStep?: boolean;
};
const imageToolKeyboardShortcuts = [{
  key: ['→', 'd'],
  action: 'Rotate right'
}, {
  key: ['←', 'a'],
  action: 'Rotate left'
}, {
  key: ['shift'],
  action: 'Fine-control'
}, {
  key: ['r'],
  action: 'Reset image'
}, {
  key: ['esc'],
  action: 'Clear selection'
}, {
  key: ['t'],
  action: 'Select top third'
}, {
  key: ['b'],
  action: 'Select bottom third'
}, {
  key: ['h'],
  action: 'Select top half'
}, {
  key: ['j'],
  action: 'Previous page'
}, {
  key: ['k'],
  action: 'Next page'
}, {
  key: ['enter'],
  action: 'Select pixels'
}, {
  key: ['w'],
  action: 'Move selection up'
}, {
  key: ['s'],
  action: 'Move selection down'
}];
const thresholdToolKeyboardShortcuts = [{
  key: ['→', 'd'],
  action: 'Increase threshold'
}, {
  key: ['←', 'a'],
  action: 'Decrease threshold'
}, {
  key: ['shift'],
  action: 'Fine-control'
}, {
  key: ['enter'],
  action: 'Choose threshold'
}, {
  key: ['r'],
  action: 'Reset threshold'
}];
const endorsementImageToolKeyboardShortcuts = [{
  key: ['enter'],
  action: 'Continue'
}, {
  key: ['→', 'd'],
  action: 'Rotate image right'
}, {
  key: ['←', 'a'],
  action: 'Rotate image left'
}, {
  key: ['e'],
  action: 'Stamp endorsement'
}, {
  key: ['z'],
  action: 'Reset image'
}, {
  key: ['j'],
  action: 'Move proposed endorsement left'
}, {
  key: ['k'],
  action: 'Move proposed endorsement right'
}, {
  key: ['shift'],
  action: 'Fine-control'
}];
const steps: stepDetails[] = [{
  heading: 'Select the front of the check',
  subheading: 'Rotate the image until the check is facing up. Click and drag or click twice to select. Press enter when done.',
  keyboardShortcuts: imageToolKeyboardShortcuts
}, {
  heading: 'Adjust front pixels until the text is readable',
  keyboardShortcuts: thresholdToolKeyboardShortcuts
}, {
  heading: 'Select the back of the check',
  subheading: 'Rotate the image until the check is horizontal with the endorsement line on the right. Click and drag or click twice to select. Press enter when done.',
  keyboardShortcuts: imageToolKeyboardShortcuts
}, {
  heading: 'Adjust back pixels until the text is readable',
  keyboardShortcuts: thresholdToolKeyboardShortcuts
}, {
  heading: 'Select MICR Line',
  keyboardShortcuts: imageToolKeyboardShortcuts
}, {
  heading: 'Transcribe MICR Line',
  additionalHelpLink: 'https://paper.dropbox.com/doc/MICR-line-transcription--ByF3h1hSkFTVpbOVNliucAAWAQ-sPco5C0E67RANo4N9f1gb',
  keyboardShortcuts: [{
    key: ['enter'],
    action: 'Continue'
  }]
}, {
  heading: 'Add endorsement if needed',
  subheading: 'If the check is not endorsed, click in the middle of the endorsement line to add one. Press Enter to continue.',
  keyboardShortcuts: endorsementImageToolKeyboardShortcuts,
  optionalStep: true
}, {
  heading: 'Review and submit',
  subheading: 'Perform necessary confirmations and submit the check for processing.'
}];
const lastStepNumber = steps.length;
const CheckDepositReviewSteps = ({
  checkDeposit,
  frontImageURL,
  backImageURL
}: {
  checkDeposit: CheckDepositGetResponse;
  frontImageURL: string;
  backImageURL: string;
}) => {
  const [step, setStep] = useState(0);
  const [maxStepReached, setMaxStepReached] = useState(0);
  const incrementStep = () => {
    setStep(step + 1);
    const nextStepDetails = steps[step + 1];
    setMaxStepReached(Math.max(maxStepReached, step + (nextStepDetails.optionalStep ? 2 : 1)));
  };
  const decrementStep = () => setStep(step - 1);
  const [frontPixels, setFrontPixels] = useState<ImageData | null>(null);
  const [frontThreshold, setFrontThreshold] = useState(128);
  const [rearPixels, setRearPixels] = useState<ImageData | null>(null);
  const [rearThreshold, setRearThreshold] = useState(128);
  const [frontMonotonePixels, setFrontMonotonePixels] = useState<ImageData | null>(null);
  const [rearMonotonePixels, setRearMonotonePixels] = useState<ImageData | null>(null);
  const [endorsedRearMonotonePixels, setEndorsedRearMonotonePixels] = useState<ImageData | null>(null);
  const [micrPixels, setMICRPixels] = useState<ImageData | null>(null);
  const [micrResult, setMICRResult] = useState<MICRResult | null>(null);
  const stepDetails = steps[step];
  return <PageLayout id="application.check-review" headline={<span>Review {checkDeposit.id}</span>} data-sentry-element="PageLayout" data-sentry-component="CheckDepositReviewSteps" data-sentry-source-file="check-deposit-review.tsx">
      <div className="flex flex-row flex-wrap-reverse space-x-8">
        <div className="w-[802px] select-none">
          {(() => {
          switch (step) {
            case 0:
              return <Box>
                    <MultiImageTool selectionMode="area" url={frontImageURL} minimumWidth={CHECK_IMAGE_MINIMUM_WIDTH} minimumHeight={CHECK_IMAGE_MINIMUM_HEIGHT} onPixelsExtracted={pixels => {
                  setFrontPixels(pixels);
                  setFrontThreshold(computeThreshold(pixels));
                  incrementStep();
                }} />
                  </Box>;
            case 1:
              return frontPixels ? <Box>
                    <ImageThreshold pixels={frontPixels} initialThreshold={frontThreshold} onLevelUpdated={(pixels, level) => {
                  setFrontMonotonePixels(pixels);
                  setFrontThreshold(level);
                  incrementStep();
                }} />
                  </Box> : <h2>Finish selecting front pixels</h2>;
            case 2:
              return <Box>
                    <MultiImageTool selectionMode="area" url={backImageURL} minimumWidth={CHECK_IMAGE_MINIMUM_WIDTH} minimumHeight={CHECK_IMAGE_MINIMUM_HEIGHT} onPixelsExtracted={pixels => {
                  setRearPixels(pixels);
                  setRearThreshold(computeThreshold(pixels));
                  incrementStep();
                }} />
                  </Box>;
            case 3:
              return rearPixels ? <Box>
                    <ImageThreshold pixels={rearPixels} initialThreshold={rearThreshold} onLevelUpdated={(pixels, level) => {
                  setRearMonotonePixels(pixels);
                  setRearThreshold(level);
                  setEndorsedRearMonotonePixels(pixels);
                  incrementStep();
                }} />
                  </Box> : <h2>Finish selecting rear pixels</h2>;
            case 4:
              return <Box>
                    {frontPixels && <ImageToolInner selectionMode="area" imageSource={imageDataToCanvasImageSource(frontPixels)} initialBoundsInImageSpace={suggestedInitialMICRBounds(frontPixels)} onPixelsExtracted={pixels => {
                  setMICRPixels(pixels);
                  incrementStep();
                }} />}
                  </Box>;
            case 5:
              return micrPixels ? <Box>
                    <TranscribeMICR recordID={checkDeposit.id} micrPixels={micrPixels} micrResult={micrResult} onUpdate={newMicrResult => {
                  setMICRResult(newMicrResult);
                  incrementStep();
                }} />
                  </Box> : <h2>Finish selecting MICR line</h2>;
            case 6:
              return rearMonotonePixels && endorsedRearMonotonePixels && <div>
                      <KeyListener listenedKey="Enter" onKeyPress={incrementStep} />
                      <EndorsementStampingTool undoToPixels={rearMonotonePixels} pixels={endorsedRearMonotonePixels} onPixelsChange={setEndorsedRearMonotonePixels} />
                    </div>;
            case 7:
              return micrResult && frontMonotonePixels && endorsedRearMonotonePixels ? <Box>
                    <SubmitReview checkDeposit={checkDeposit} micr={micrResult} front={frontMonotonePixels} rear={endorsedRearMonotonePixels} />
                  </Box> : <h2>
                    Finish selecting front pixels, rear pixels, and MICR line
                  </h2>;
          }
          return <></>;
        })()}
        </div>

        <div className="card-sm flex-column space-y-4 p-2">
          <div className="flex-column max-w-[300px] space-y-2">
            <div>
              <Caption contents={`Step ${step + 1} / ${lastStepNumber}`} data-sentry-element="Caption" data-sentry-source-file="check-deposit-review.tsx" />
            </div>

            <div>
              <Heading size="medium" contents={stepDetails.heading} data-sentry-element="Heading" data-sentry-source-file="check-deposit-review.tsx" />
            </div>

            {stepDetails.additionalHelpLink && <div>
                <Body>
                  <StyledLink target="_blank" to={stepDetails.additionalHelpLink}>
                    Additional instructions
                  </StyledLink>
                </Body>
              </div>}

            {stepDetails.subheading && <div>
                <Body>{stepDetails.subheading}</Body>
              </div>}

            <Box flex gap="2" data-sentry-element="Box" data-sentry-source-file="check-deposit-review.tsx">
              {step > 0 && <Button size="small" onClick={decrementStep} text="Previous step" />}
              {step + 1 < lastStepNumber && step < maxStepReached && <Button size="small" onClick={incrementStep} text="Next step" />}
            </Box>
          </div>

          <StackOfLinks title="" links={[{
          href: buildPath(ROUTES.TRANSFER_DETAIL, {
            transferID: checkDeposit.id
          }),
          text: checkDeposit.id
        }, {
          href: buildPath(ROUTES.ACCOUNTS_DETAIL, {
            accountId: checkDeposit.account_id
          }),
          text: checkDeposit.account_id
        }, {
          href: buildPath(ROUTES.GROUPS_DETAIL, {
            groupId: checkDeposit.group_id
          }),
          text: checkDeposit.group_id
        }]} data-sentry-element="StackOfLinks" data-sentry-source-file="check-deposit-review.tsx" />

          <OperatorTags modelId={checkDeposit.group_id} hideForm={true} data-sentry-element="OperatorTags" data-sentry-source-file="check-deposit-review.tsx" />

          <Body className="pb-2" data-sentry-element="Body" data-sentry-source-file="check-deposit-review.tsx">
            Amount:{' '}
            <span className="font-[520]">
              {formatAmount(checkDeposit.amount, 'USD')}
            </span>
          </Body>

          <div className="border-main h-px border-t" />

          {stepDetails?.keyboardShortcuts && <>
              <div className="flex-column space-y-1">
                {stepDetails?.keyboardShortcuts?.map(shortcut => <div key={shortcut.action} className="flex space-x-2">
                    <div className="flex space-x-1">
                      {shortcut.key.map(key => <div key={key} className="text-subtle border-strong bg-strong rounded-sm border px-1">
                          <Caption weight="medium" color="inherit">
                            {key}
                          </Caption>
                        </div>)}
                    </div>
                    <Body>{shortcut.action}</Body>
                  </div>)}
              </div>

              <div className="border-main h-px border-t" />
            </>}

          <RejectCheckForm checkDeposit={checkDeposit} data-sentry-element="RejectCheckForm" data-sentry-source-file="check-deposit-review.tsx" />
        </div>
      </div>
    </PageLayout>;
};
export const CheckDepositReview = () => {
  const {
    checkDepositID
  } = useTypedParams(ROUTES.CHECK_DEPOSIT_REVIEW);
  const {
    operations
  } = useServices();
  const {
    data: checkDeposit
  } = useCheckDepositGet(checkDepositID);
  if (!checkDeposit) {
    return <h3>Loading</h3>;
  }
  const [frontImageURL, backImageURL] = [checkDeposit.front_image_file_id, checkDeposit.back_image_file_id].map(id => `${operations}/check_deposit_api_files/${id}/view`);
  if (checkDeposit.status === 'pending_reviewing') {
    return <CheckDepositReviewSteps checkDeposit={checkDeposit} frontImageURL={frontImageURL} backImageURL={backImageURL} />;
  }
  const acceptance = checkDeposit.check_deposit_acceptance;
  const depositReturn = checkDeposit.check_deposit_return;
  return <PageLayout id="application.checkViewing" headline={<span>
          {checkDeposit.status} {checkDeposit.id}
        </span>} data-sentry-element="PageLayout" data-sentry-component="CheckDepositReview" data-sentry-source-file="check-deposit-review.tsx">
      <StyledLink to={buildPath(ROUTES.GROUPS_DETAIL, {
      groupId: checkDeposit.group_id
    })} data-sentry-element="StyledLink" data-sentry-source-file="check-deposit-review.tsx">
        {checkDeposit.group_id}
      </StyledLink>
      <br />
      <StyledLink to={buildPath(ROUTES.ACCOUNTS_DETAIL, {
      accountId: checkDeposit.account_id
    })} data-sentry-element="StyledLink" data-sentry-source-file="check-deposit-review.tsx">
        {checkDeposit.account_id}
      </StyledLink>
      <OperatorTags modelId={checkDeposit.group_id} data-sentry-element="OperatorTags" data-sentry-source-file="check-deposit-review.tsx" />
      {acceptance && <PropertyList items={[{
      label: 'Amount',
      value: formatAmount(acceptance.amount, 'USD')
    }, {
      label: 'Auxiliary on us',
      value: acceptance.auxiliary_on_us || ''
    }, {
      label: 'Routing number',
      value: acceptance.routing_number
    }, {
      label: 'Account number',
      value: acceptance.account_number
    }, {
      label: 'Serial number',
      value: acceptance.serial_number || ''
    }]} />}
      {depositReturn && <PropertyList title="Return" items={[{
      label: 'Returned at',
      value: depositReturn.returned_at,
      format: 'month-day-year-hour-minute-second'
    }, {
      label: 'Amount',
      value: formatAmount(depositReturn.amount, 'USD')
    }, {
      label: 'Return reason',
      value: depositReturn.return_reason
    }]} />}

      <MultiImageTool url={frontImageURL} selectionMode="none" data-sentry-element="MultiImageTool" data-sentry-source-file="check-deposit-review.tsx" />
      <MultiImageTool url={backImageURL} selectionMode="none" data-sentry-element="MultiImageTool" data-sentry-source-file="check-deposit-review.tsx" />
    </PageLayout>;
};