import React from 'react';
import { PageLayout } from './deprecated/PageLayout';
import { RealTimePaymentsPrefundedPositionOverviewGetBank } from 'src/build/operations';
import { useOperatorGet, useRealTimePaymentsPrefundedPositionOverviewGet } from 'src/hooks/reactQuery';
import { Table } from './deprecated/Table';
import { PropertyList, PropertyListItem } from './deprecated/PropertyList';
import { Section } from './deprecated/Section';
import { formatAmount } from '@increase/shared/lib/formatting';
import { StyledLink } from './deprecated/StyledLink';
import { buildPath, ROUTES } from 'src/lib/routes';
import { useSearchParamsState } from '@increase/shared/hooks/useTypedSearchParamsState';
import { Select, SelectSection } from '@increase/shared/components/Select';
import { InternalOperatorOnly } from './internal-operator-only';
const DEFAULT_BANK = 'first_internet_bank';
export const RealTimePaymentsPrefundedPosition = () => {
  const [bank, setBank] = useSearchParamsState(ROUTES.REAL_TIME_PAYMENTS_PREFUNDED_POSITION, 'bank');
  const {
    data: operator
  } = useOperatorGet({});
  const bankToSearch = bank || operator?.bank || DEFAULT_BANK;
  const {
    data
  } = useRealTimePaymentsPrefundedPositionOverviewGet({
    bank: bankToSearch
  });
  if (!data || !operator) {
    return <div>Loading</div>;
  }
  const showBankPicker = !operator.bank;
  const sections: SelectSection<RealTimePaymentsPrefundedPositionOverviewGetBank>[] = [{
    options: Object.values(RealTimePaymentsPrefundedPositionOverviewGetBank).map(b => ({
      label: b,
      value: b
    }))
  }];
  return <PageLayout headline="Real-Time Payments prefunded position" data-sentry-element="PageLayout" data-sentry-component="RealTimePaymentsPrefundedPosition" data-sentry-source-file="real-time-payments-prefunded-position.tsx">
      {showBankPicker ? <div>
          <b>Bank:</b>
          <Select value={bankToSearch} disabled={operator.bank !== null} onUpdate={value => {
        setBank(value);
      }} sections={sections} />
        </div> : <div>{bank}</div>}

      <PropertyList data-sentry-element="PropertyList" data-sentry-source-file="real-time-payments-prefunded-position.tsx">
        <PropertyListItem label="Participant ID" data-sentry-element="PropertyListItem" data-sentry-source-file="real-time-payments-prefunded-position.tsx">
          {data.participant_id}
        </PropertyListItem>
        <PropertyListItem label="Prefunded position" data-sentry-element="PropertyListItem" data-sentry-source-file="real-time-payments-prefunded-position.tsx">
          {formatAmount(data.prefunded_position.current, 'USD')}
        </PropertyListItem>
        <PropertyListItem label="Target" data-sentry-element="PropertyListItem" data-sentry-source-file="real-time-payments-prefunded-position.tsx">
          {formatAmount(data.prefunded_position.target, 'USD')}
        </PropertyListItem>
        <PropertyListItem label="The Clearing House Minimum" data-sentry-element="PropertyListItem" data-sentry-source-file="real-time-payments-prefunded-position.tsx">
          {formatAmount(data.prefunded_position.minimum, 'USD')}
        </PropertyListItem>
        <PropertyListItem label="Minimum supplemental funding" data-sentry-element="PropertyListItem" data-sentry-source-file="real-time-payments-prefunded-position.tsx">
          {formatAmount(data.prefunded_position.minimum_topup, 'USD')}
        </PropertyListItem>
        {data.prefunded_position.latest_baseline && <>
            <PropertyListItem label="Latest reported position">
              {formatAmount(data.prefunded_position.latest_baseline.amount, 'USD')}{' '}
              (<span> </span>
              {data.prefunded_position.latest_baseline.effective_at})
            </PropertyListItem>
            <InternalOperatorOnly>
              <PropertyListItem label="Latest baseline message">
                <StyledLink to={buildPath(ROUTES.OBJECT_VIEWER, {
              objectID: data.prefunded_position.latest_baseline.source_id
            })}>
                  {data.prefunded_position.latest_baseline.source_id}
                </StyledLink>
              </PropertyListItem>
            </InternalOperatorOnly>
            <PropertyListItem label="Transfers since then">
              <ul>
                <li>
                  Sent:{' '}
                  {data.prefunded_position.latest_baseline.summary_since_then.count_sent}{' '}
                  for{' '}
                  {formatAmount(data.prefunded_position.latest_baseline.summary_since_then.amount_sent, 'USD')}{' '}
                </li>
                <li>
                  Received:{' '}
                  {data.prefunded_position.latest_baseline.summary_since_then.count_received}{' '}
                  for{' '}
                  {formatAmount(data.prefunded_position.latest_baseline.summary_since_then.amount_received, 'USD')}{' '}
                </li>
              </ul>
            </PropertyListItem>
          </>}
      </PropertyList>

      {data.reconciliation_status_notification_data.length > 0 && <Section header="Recent reconciliation status notifications">
          <Table data={data.reconciliation_status_notification_data} columns={[{
        key: 'id',
        label: 'Id'
      }, {
        key: 'date',
        label: 'Date',
        render: notification => notification.new_reconciliation_window_date
      }, {
        key: 'balance',
        label: 'Opening position',
        render: notification => formatAmount(notification.new_opening_balance, 'USD')
      }]} />
        </Section>}

      {data.supplemental_fundings.length > 0 && <Section header="Recent supplemental fundings">
          <Table data={data.supplemental_fundings} columns={[{
        key: 'id',
        label: 'Id'
      }, {
        key: 'created_at',
        label: 'Created at',
        render: funding => funding.created_at
      }, {
        key: 'status',
        label: 'Status'
      }, {
        key: 'amount',
        label: 'Amount',
        render: funding => formatAmount(funding.amount, 'USD')
      }, {
        key: 'wire_transfer_id',
        label: 'Wire Transfer',
        render: funding => <StyledLink to={buildPath(ROUTES.TRANSFER_DETAIL, {
          transferID: funding.wire_transfer_id
        })}>
                    {funding.wire_transfer_id}
                  </StyledLink>
      }]} />
        </Section>}
    </PageLayout>;
};