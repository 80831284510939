import { TableColumns, Table, PrestyledTableProps } from '@increase/shared/components/Table';
import { useMemo } from 'react';
import { BookkeepingEntryListResponseDataItem } from 'src/build/operations';
import { formatAmount, formatISO8601Timestamp } from '@increase/shared/lib/formatting';
import { buildPath, ROUTES } from 'src/lib/routes';
type Props = PrestyledTableProps<BookkeepingEntryListResponseDataItem>;
export const BookkeepingEntriesTable = (props: Props) => {
  const {
    getRowProps,
    ...rest
  } = props;
  const columns = useMemo<TableColumns<BookkeepingEntryListResponseDataItem>>(() => {
    return [{
      header: 'Date',
      contents: record => ({
        text: formatISO8601Timestamp(record.entry_set.date, 'date')
      })
    }, {
      header: 'Transaction ID',
      contents: record => ({
        text: record.entry_set.transaction_id ?? '',
        href: record.entry_set.transaction_id ? buildPath(ROUTES.TRANSACTION_DETAIL, {
          transactionID: record.entry_set.transaction_id
        }) : undefined
      })
    }, {
      header: 'Entry Set',
      contents: record => ({
        text: record.entry_set.id,
        href: buildPath(ROUTES.BOOKKEEPING_ENTRY_SETS_DETAIL, {
          bookkeepingEntrySetID: record.entry_set.id
        })
      })
    }, {
      header: 'Amount',
      contents: record => ({
        text: formatAmount(record.amount, 'USD'),
        textColor: 'emphasis',
        textWeight: 'bold'
      })
    }];
  }, []);
  const defaultRowProps = () => ({
    className: 'hover:bg-main-hover cursor-pointer transition-all group'
  });
  return <Table<BookkeepingEntryListResponseDataItem> columns={columns} getRowProps={getRowProps ?? defaultRowProps} {...rest} data-sentry-element="Table" data-sentry-component="BookkeepingEntriesTable" data-sentry-source-file="BookkeepingEntriesTable.tsx" />;
};