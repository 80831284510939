import React from 'react';
import { usePendingTransactionGet } from 'src/hooks/reactQuery';
import { OperatorNotes } from './operator-notes';
import { PageLayout } from './deprecated/PageLayout';
import { SideBySide } from '@increase/shared/components/SideBySide';
import { PendingTransactionPropertyList } from './property-lists/PendingTransactionPropertyList';
import { Box } from '@increase/shared/components/Box';
import { Icon } from '@increase/shared/components/Icon';
import { Note } from '@increase/shared/components/Note';
import { Body } from '@increase/shared/components/Text';
import { formatAmount, humanize } from '@increase/shared/lib/formatting';
import { PropertyList } from '@increase/shared/components/PropertyList';
import { useTypedParams } from 'react-router-typesafe-routes/dom';
import { ROUTES } from 'src/lib/routes';
const SKIP_SOURCE_KEYS = ['amount', 'currency', 'class_name', 'transaction_id', 'type'];
function extractSourceFields(object: Record<string, string>, prefix = ''): {
  label: string;
  value: string;
}[] {
  return Object.entries(object).filter(([key]) => !SKIP_SOURCE_KEYS.includes(key)).map(([key, value]) => {
    if (value && typeof value == 'object') {
      return extractSourceFields(value, prefix + key + '.');
    } else {
      return [{
        label: humanize(`${prefix}${key}`),
        value
      }];
    }
  }).reduce((a, b) => a.concat(b), []);
}
export const PendingTransactionDetail = () => {
  const {
    pendingTransactionID
  } = useTypedParams(ROUTES.PENDING_TRANSACTION_DETAIL);
  const {
    data: pendingTransaction
  } = usePendingTransactionGet(pendingTransactionID);
  if (!pendingTransaction) {
    return <>Loading...</>;
  }
  const source = pendingTransaction.source as unknown as Record<string, string> | undefined;
  return <PageLayout id="application.transactionDetails" headline={`${pendingTransaction.source_type} ${formatAmount(pendingTransaction.amount, pendingTransaction.currency)}`} data-sentry-element="PageLayout" data-sentry-component="PendingTransactionDetail" data-sentry-source-file="pending-transaction-detail.tsx">
      <SideBySide right={<PendingTransactionPropertyList title="Basics" pendingTransaction={pendingTransaction} />} left={<>
            {pendingTransaction.source_documentation && <Note>
                <Box gap="2">
                  <Box flex center>
                    <Icon name="info" />
                    <Body weight="bold">{pendingTransaction.source_type}</Body>
                  </Box>
                  <Body>{pendingTransaction.source_documentation}</Body>
                </Box>
              </Note>}

            <PropertyList title="Details" items={[{
        label: 'Description',
        value: pendingTransaction.description
      }].concat(extractSourceFields(source || {}))} />
          </>} mode="rightDrawer" data-sentry-element="SideBySide" data-sentry-source-file="pending-transaction-detail.tsx" />

      <br />

      <OperatorNotes modelId={pendingTransactionID} data-sentry-element="OperatorNotes" data-sentry-source-file="pending-transaction-detail.tsx" />
    </PageLayout>;
};