import { compact } from 'lodash';
import { BadgeColor } from '@increase/shared/components/Badge';
import { ListItem, PropertyList, PropertyListProps } from '@increase/shared/components/PropertyList';
import { formatAccuratePercentage, formatAmount } from '@increase/shared/lib/formatting';
import { formatBank } from 'src/lib/formatEnums';
import { AccountGetResponse, AccountGetResponseStatus } from 'src/build/operations';
import { useEntityGet, useGroupGatesList } from 'src/hooks/reactQuery';
import { useGroupPropertyRow, useProgramPropertyRow } from 'src/lib/propertyListHelpers';
import { ROUTES, buildPath } from 'src/lib/routes';
type AccountPropertyListProps = {
  account: AccountGetResponse;
} & Omit<PropertyListProps, 'items'>;
const statusBadgeColor: Record<AccountGetResponseStatus, BadgeColor> = {
  closed: 'gray',
  open: 'green',
  pending_opening: 'gray'
};
const statusName: Record<AccountGetResponseStatus, string> = {
  closed: 'Closed',
  open: 'Open',
  pending_opening: 'Pending Opening'
};
export const AccountPropertyList = (props: AccountPropertyListProps) => {
  const {
    account: account,
    ...rest
  } = props;
  const {
    data: entity
  } = useEntityGet(account.entity_id || '', {
    enabled: !!account.entity_id
  });
  const {
    data: informationalEntity
  } = useEntityGet(account.informational_entity_id || '', {
    enabled: !!account.informational_entity_id
  });
  const groupRow = useGroupPropertyRow(account.group_id);
  const programRow = useProgramPropertyRow(account.program_id);
  const {
    data: gates
  } = useGroupGatesList(account.group_id);
  const hasSurveys = gates?.gates?.includes('ramp_business_account_onboarding_survey');
  return <PropertyList {...rest} items={compact<ListItem>([{
    label: 'Available Balance',
    value: formatAmount(account.available_balance, account.currency)
  }, {
    label: 'Current Balance',
    value: formatAmount(account.current_balance, account.currency)
  }, {
    label: 'Interest rate',
    value: formatAccuratePercentage(account.interest_rate.current_value),
    tooltip: account.interest_rate.summary
  }, {
    label: 'Bank',
    value: formatBank(account.bank)
  }, {
    label: 'ID',
    value: account.id,
    copyable: account.id
  }, groupRow, programRow, {
    label: 'Status',
    value: statusName[account.status],
    badgeColor: statusBadgeColor[account.status]
  }, hasSurveys && {
    label: 'Onboarding survey',
    value: 'View',
    href: buildPath(ROUTES.RAMP_BUSINESS_ACCOUNT_ONBOARDING_SURVEYS_LIST, {}, {
      accounts: [account.id]
    })
  }, account.closed_at && {
    label: 'Closed At',
    value: account.closed_at,
    format: 'date'
  }, {
    label: 'Name',
    value: account.name
  }, account.title && {
    label: 'Title',
    value: account.title
  }, {
    label: 'Created',
    value: account.created_at,
    format: 'date'
  }, entity && {
    label: 'Account owner',
    value: entity.name,
    href: buildPath(ROUTES.ENTITY, {
      entityId: entity.id
    })
  }, informationalEntity && {
    label: 'Account beneficiary',
    value: informationalEntity.name,
    href: buildPath(ROUTES.ENTITY, {
      entityId: informationalEntity.id
    })
  }, {
    label: 'Restrictions',
    value: account.restrictions ?? 'None'
  }, account.bookkeeping_exception_reason && {
    label: 'Bookkeeping Exception Reason',
    value: account.bookkeeping_exception_reason
  }, account.deposit_account_control_agreement && {
    label: 'Deposit Account Control Agreement',
    value: `DACA with ${account.deposit_account_control_agreement.counterparty_bank_name}`,
    href: `https://operations.increase.com/api_files/${account.deposit_account_control_agreement.file_id}/view`
  }])} data-sentry-element="PropertyList" data-sentry-component="AccountPropertyList" data-sentry-source-file="AccountPropertyList.tsx" />;
};