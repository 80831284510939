export function useServices(): {
  administration: string;
  dashboard: string;
  operations: string;
  federalreservegateway: string;
} {
  switch (process.env.NODE_ENV) {
    case 'development':
      if (
        process.env.DOMAIN === 'increase.local' ||
        process.env.NEXT_PUBLIC_DOMAIN === 'increase.local'
      ) {
        return {
          administration: 'https://administration.increase.local/',
          dashboard: 'https://dashboard.increase.local/',
          operations: 'https://operations.increase.local',
          federalreservegateway: 'https://federalreservegateway.increase.local',
        };
      }

      return {
        administration: 'http://administration.bank.localhost:3001/',
        dashboard: 'http://dashboard.bank.localhost:3010/',
        operations: 'http://operations.bank.localhost:30002',
        federalreservegateway:
          'http://federalreservegateway.bank.localhost:30003',
      };
    case 'test':
      return {
        administration: '',
        dashboard: '',
        operations: '',
        federalreservegateway: '',
      };
    case 'production':
      return {
        administration: `https://administration.increase.com`,
        dashboard: `https://dashboard.increase.com`,
        operations: `https://operations.increase.com`,
        federalreservegateway: `https://federalreservegateway.increase.com`,
      };
    default:
      throw new Error('Environment not known: ' + process.env.NODE_ENV);
  }
}
