import { useUnusualActivityReportGet } from 'src/hooks/reactQuery';
import { UnusualActivityReportGetResponse } from 'src/build/operations';
import { PageLayout } from '../deprecated/PageLayout';
import { SideBySide } from '@increase/shared/components/SideBySide';
import { UnusualActivityReportPropertyList } from 'src/components/property-lists/UnusualActivityReportPropertyList';
import { Loading } from '@increase/shared/components/Loading';
import { PropertyList } from '@increase/shared/components/PropertyList';
import { OperatorNotes } from '../operator-notes';
import { UnusualActivityReportOperatorFiles } from '../operator-files';
import { Box } from '@increase/shared/components/Box';
import { Body, Heading } from '@increase/shared/components/Text';
import { useTypedParams } from 'react-router-typesafe-routes/dom';
import { ROUTES, buildPath } from 'src/lib/routes';
import { StyledLink } from 'src/components/deprecated/StyledLink';
import { BadgeColor } from '@increase/shared/components/Badge';
import { RequireEntitlements } from 'src/components/require-entitlements';
export const increaseReviewStatusBadgeColor: Record<UnusualActivityReportGetResponse['increase_review_status'], BadgeColor> = {
  pending: 'yellow',
  reviewing: 'blue',
  reviewed: 'green',
  skipped: 'gray',
  requires_attention: 'yellow'
};
const updateLocation = (report: UnusualActivityReportGetResponse) => {
  const path = buildPath(ROUTES.MANUAL_TASK, {
    taskName: 'Operations::ManualTask::UpdateUnusualActivityReport'
  });
  const objectIds = (objects: {
    id: string;
  }[]): string => {
    return objects.map(({
      id
    }) => id).join('\n');
  };
  const searchParams = new URLSearchParams({
    unusual_activity_report_id: report.id,
    reference_number: report.reference_number ?? '',
    referral_channel: report.referral_channel ?? '',
    activity_began_on: report.activity_began_on,
    activity_ended_on: report.activity_ended_on,
    determined_on: report.determined_on ?? '',
    narrative: report.narrative,
    related_group_ids: objectIds(report.related_groups),
    related_entity_ids: objectIds(report.related_entities),
    related_account_ids: objectIds(report.related_accounts),
    related_card_ids: objectIds(report.related_cards),
    related_account_number_ids: objectIds(report.related_account_numbers),
    related_transaction_ids: objectIds(report.related_transactions),
    related_declined_transaction_ids: objectIds(report.related_declined_transactions)
  });
  return [path, searchParams].join('?');
};
const markReviewing = (report: UnusualActivityReportGetResponse) => {
  const path = buildPath(ROUTES.MANUAL_TASK, {
    taskName: 'Operations::ManualTask::MarkDirectUnusualActivityReportReviewing'
  });
  const searchParams = new URLSearchParams({
    unusual_activity_report_id: report.id
  });
  return [path, searchParams].join('?');
};
const markReviewed = (report: UnusualActivityReportGetResponse) => {
  const path = buildPath(ROUTES.MANUAL_TASK, {
    taskName: 'Operations::ManualTask::MarkDirectUnusualActivityReportReviewed'
  });
  const searchParams = new URLSearchParams({
    unusual_activity_report_id: report.id,
    determined_on: report.determined_on ?? ''
  });
  return [path, searchParams].join('?');
};
const markSkipped = (report: UnusualActivityReportGetResponse) => {
  const path = buildPath(ROUTES.MANUAL_TASK, {
    taskName: 'Operations::ManualTask::MarkDirectUnusualActivityReportSkipped'
  });
  const searchParams = new URLSearchParams({
    unusual_activity_report_id: report.id
  });
  return [path, searchParams].join('?');
};
export const UnusualActivityReportDetailPage = () => {
  const {
    unusualActivityReportId
  } = useTypedParams(ROUTES.UNUSUAL_ACTIVITY_REPORTS.DIRECT.DETAILS);
  const {
    data: report
  } = useUnusualActivityReportGet(unusualActivityReportId);
  if (!report) {
    return <Loading />;
  }
  return <PageLayout headline={'Unusual Activity Report'} action={<RequireEntitlements entitlements={['run_manual_task']}>
          <StyledLink to={updateLocation(report)}>Update Report</StyledLink>
          {report.increase_review_status === 'pending' && <StyledLink to={markReviewing(report)}>Mark Reviewing</StyledLink>}
          {report.increase_review_status === 'reviewing' && <RequireEntitlements entitlements={['internal_read_write']}>
              <StyledLink to={markReviewed(report)}>Mark Reviewed</StyledLink>
              <StyledLink to={markSkipped(report)}>Mark Skipped</StyledLink>
            </RequireEntitlements>}
        </RequireEntitlements>} data-sentry-element="PageLayout" data-sentry-component="UnusualActivityReportDetailPage" data-sentry-source-file="UnusualActivityReportDetailPage.tsx">
      <SideBySide mode="rightDrawer" right={<UnusualActivityReportPropertyList unusualActivityReport={report} layoutHorizontallyWhenPossible={false} title="Summary" />} left={<>
            <Box>
              <Heading>Narrative</Heading>
              <Body color="primary" className="whitespace-pre-line">
                {report.narrative}
              </Body>
            </Box>

            {report.related_groups.length > 0 && <PropertyList title="Related Groups" layoutHorizontallyWhenPossible={true} items={report.related_groups.map((group, idx) => ({
        label: `Group ${idx + 1}`,
        value: group.id,
        href: buildPath(ROUTES.GROUPS_DETAIL, {
          groupId: group.id
        })
      }))} />}
            {report.related_accounts.length > 0 && <PropertyList title="Related Accounts" layoutHorizontallyWhenPossible={true} items={report.related_accounts.map((account, idx) => ({
        label: `Account ${idx + 1}`,
        value: account.id,
        href: buildPath(ROUTES.ACCOUNTS_DETAIL, {
          accountId: account.id
        })
      }))} />}
            {report.related_account_numbers.length > 0 && <PropertyList title="Related Account Numbers" layoutHorizontallyWhenPossible={true} items={report.related_account_numbers.map((accountNumber, idx) => ({
        label: `Account Number ${idx + 1}`,
        value: accountNumber.id,
        href: buildPath(ROUTES.ACCOUNT_NUMBERS_DETAILS, {
          accountNumberId: accountNumber.id
        })
      }))} />}
            {report.related_cards.length > 0 && <PropertyList title="Related Cards" layoutHorizontallyWhenPossible={true} items={report.related_cards.map((card, idx) => ({
        label: `Card ${idx + 1}`,
        value: card.id,
        href: buildPath(ROUTES.CARD, {
          cardID: card.id
        })
      }))} />}
            {report.related_entities.length > 0 && <PropertyList title="Related Entities" layoutHorizontallyWhenPossible={true} items={report.related_entities.map((entity, idx) => ({
        label: `Entity ${idx + 1}`,
        value: entity.id,
        href: buildPath(ROUTES.ENTITY, {
          entityId: entity.id
        })
      }))} />}
            {report.related_transactions.length > 0 && <PropertyList title="Related Transactions" layoutHorizontallyWhenPossible={true} items={report.related_transactions.map((transaction, idx) => ({
        label: `Transaction ${idx + 1}`,
        value: transaction.id,
        href: buildPath(ROUTES.TRANSACTION_DETAIL, {
          transactionID: transaction.id
        })
      }))} />}
            {report.related_declined_transactions.length > 0 && <PropertyList title="Related Declined Transactions" layoutHorizontallyWhenPossible={true} items={report.related_declined_transactions.map((declinedTransaction, idx) => ({
        label: `Declined Transaction ${idx + 1}`,
        value: declinedTransaction.id,
        href: buildPath(ROUTES.DECLINED_TRANSACTION_DETAIL, {
          declinedTransactionID: declinedTransaction.id
        })
      }))} />}
            <UnusualActivityReportOperatorFiles modelId={report.id} />

            <OperatorNotes modelId={report.id} />
          </>} data-sentry-element="SideBySide" data-sentry-source-file="UnusualActivityReportDetailPage.tsx" />
    </PageLayout>;
};