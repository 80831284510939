import React, { useMemo, useState } from 'react';
import { PageLayout } from '../deprecated/PageLayout';
import { useOperatorGet, useSanctionsScreeningTriggerTokensListInfinite, useSanctionsScreeningTriggerTokensPost } from 'src/hooks/reactQuery';
import { TableStateWrapper } from '@increase/shared/components/TableStateWrapper';
import { SanctionsScreeningTriggerTokensTable } from '../tables/SanctionsScreeningTriggerTokensTable';
import { Button } from '@increase/shared/components/Button';
import { useDrawer } from '@increase/shared/components/Modal';
import { DrawerLayout } from '@increase/shared/components/DrawerLayout';
import { Box } from '@increase/shared/components/Box';
import { Body } from '@increase/shared/components/Text';
import { TextInput } from '@increase/shared/components/TextInput';
import { compact } from 'lodash';
import { TableFilter } from 'shared/components/Table/filters';
import { useSearchParamsState } from 'shared/hooks/useTypedSearchParamsState';
import { ROUTES } from 'src/lib/routes';
import { makeEnumFilter } from 'src/lib/tableFilterHelpers';
import { AlertList } from 'shared/components/AlertList';
const Drawer = (props: {
  onClose: () => void;
}) => {
  const mutate = useSanctionsScreeningTriggerTokensPost();
  const [token, setToken] = useState('');
  const [note, setNote] = useState('');
  return <DrawerLayout title="Create new word or phrase" body={<Box>
          <Body color="secondary">
            Adding a word or phrase to the OFAC triggering Tokens list will
            cause future screening to stop on any transaction that contains the
            word or phrase. Capitalization does not matter.
          </Body>

          <TextInput value={token} onChange={e => setToken(e.target.value)} />
          <TextInput value={note} onChange={e => setNote(e.target.value)} placeholder="Note" />
        </Box>} rightButton={<Button icon="create" text="Save" onClick={async () => {
    await mutate.mutateAsync([{
      token,
      note
    }]);
    props.onClose();
  }} style="warning" disabled={mutate.isLoading || token.length === 0 || note.length === 0} />} data-sentry-element="DrawerLayout" data-sentry-component="Drawer" data-sentry-source-file="SanctionsScreeningTriggerTokensListPage.tsx" />;
};
export const SanctionsScreeningTriggerTokensListPage = () => {
  const [reviewers, setReviewers] = useSearchParamsState(ROUTES.SANCTIONS_SCREENING_REVIEWS_LIST, 'reviewer');
  const {
    data: operator
  } = useOperatorGet({});
  const multibank = !operator?.bank;
  const filters: TableFilter[] = useMemo(() => {
    return compact([multibank && makeEnumFilter(reviewers, setReviewers, 'Reviewer', {
      grasshopper: 'Grasshopper Bank',
      core_bank: 'Core Bank',
      increase: 'Increase'
    }, 'users')]);
  }, [reviewers, setReviewers, multibank]);
  const listResult = useSanctionsScreeningTriggerTokensListInfinite({
    reviewers: reviewers.length > 0 ? reviewers : undefined
  });
  const {
    showDrawer,
    closeDrawer
  } = useDrawer();
  return <PageLayout headline={'OFAC Triggering Tokens'} data-sentry-element="PageLayout" data-sentry-component="SanctionsScreeningTriggerTokensListPage" data-sentry-source-file="SanctionsScreeningTriggerTokensListPage.tsx">
      <AlertList tasks={compact([{
      icon: 'info',
      title: 'About',
      body: ['Use this tool to flag phrases or words for manual review.', 'An alert will be generated if a screened field contains the configured token.'].join(' '),
      style: 'informational',
      key: '0'
    }])} data-sentry-element="AlertList" data-sentry-source-file="SanctionsScreeningTriggerTokensListPage.tsx" />
      <TableStateWrapper action={<Button style="primary" text="Create" icon="edit" onClick={() => showDrawer(<Drawer onClose={closeDrawer} />)} />} table={SanctionsScreeningTriggerTokensTable} style="primary" {...listResult} emptyTitle="No OFAC triggering Tokens" emptySubtitle="Phrases here will trigger OFAC alerts. Use this to configure OFAC screening." filters={filters} data-sentry-element="TableStateWrapper" data-sentry-source-file="SanctionsScreeningTriggerTokensListPage.tsx" />
    </PageLayout>;
};