import React from 'react';
import { useCardDeclineGet } from 'src/hooks/reactQuery';
import { PageLayout } from './deprecated/PageLayout';
import { Section } from './deprecated/Section';
import { useTypedParams } from 'react-router-typesafe-routes/dom';
import { ROUTES, buildPath } from 'src/lib/routes';
import { PropertyList } from '@increase/shared/components/PropertyList';
export const GroupCardDeclineDetail = () => {
  const {
    cardDeclineID
  } = useTypedParams(ROUTES.CARD_DECLINE);
  const {
    data: cardDecline
  } = useCardDeclineGet(cardDeclineID);
  if (!cardDecline) {
    return <div>Loading...</div>;
  }
  return <PageLayout headline={cardDecline.id} data-sentry-element="PageLayout" data-sentry-component="GroupCardDeclineDetail" data-sentry-source-file="group-card-decline-detail.tsx">
      <Section header="Details" data-sentry-element="Section" data-sentry-source-file="group-card-decline-detail.tsx">
        <PropertyList items={[{
        label: 'Account',
        value: cardDecline.account_id,
        href: buildPath(ROUTES.ACCOUNTS_DETAIL, {
          accountId: cardDecline.account_id
        })
      }, {
        label: 'Card',
        value: cardDecline.card_id,
        href: buildPath(ROUTES.CARD, {
          cardID: cardDecline.card_id
        })
      }, {
        label: 'Declined transaction details',
        value: cardDecline.declined_transaction_id,
        href: buildPath(ROUTES.DECLINED_TRANSACTION_DETAIL, {
          declinedTransactionID: cardDecline.declined_transaction_id
        })
      }, {
        label: 'Created at',
        value: cardDecline.created_at,
        format: 'month-day-year-hour-minute-second'
      }]} data-sentry-element="PropertyList" data-sentry-source-file="group-card-decline-detail.tsx" />
      </Section>
    </PageLayout>;
};