// Copied from https://github.com/cawfree/otsu/blob/master/src/index.js

import { grayHistogram } from './image-utility';

const width = (histogram: number[], start: number, end: number) => {
  let v = 0;
  for (let i = start; i < end; i += 1) {
    v += histogram[i];
  }
  return v;
};

const weight = (
  histogram: number[],
  start: number,
  end: number,
  total: number
) => {
  return width(histogram, start, end) / total;
};

const mean = (
  histogram: number[],
  start: number,
  end: number,
  width: number
) => {
  let v = 0;
  for (let i = start; i < end; i += 1) {
    v += histogram[i] * i;
  }
  return v * width;
};

const variance = (
  histogram: number[],
  start: number,
  end: number,
  mean: number,
  width: number
) => {
  let v = 0;
  for (let i = start; i < end; i += 1) {
    const d = i - mean;
    v += d * d * histogram[i];
  }
  return v * width;
};

const cross = (wb: number, vb: number, wf: number, vf: number) =>
  wb * vb + wf * vf;

export const computeThreshold = (data: ImageData): number => {
  const histogram = grayHistogram(data);
  const totalPixels = data.data.length / 4;

  const vars = [...Array(256)].map((_, i) => {
    const s0 = 0;
    const e0 = i;
    const s1 = i;
    const e1 = 256;

    const w0 = 1 / width(histogram, s0, e0);
    const w1 = 1 / width(histogram, s1, e1);

    const wb = weight(histogram, s0, e0, totalPixels);
    const vb = variance(histogram, s0, e0, mean(histogram, s0, e0, w0), w0);

    const wf = weight(histogram, s1, e1, totalPixels);
    const vf = variance(histogram, s1, e1, mean(histogram, s1, e1, w1), w1);

    const x = cross(wb, vb, wf, vf);

    return !isNaN(x) ? x : Number.POSITIVE_INFINITY;
  });

  return vars.indexOf(Math.min(...vars));
};
