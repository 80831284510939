import { useInboundFedNowMessageGet } from 'src/hooks/reactQuery';
import { PageLayout } from '../deprecated/PageLayout';
import { OperatorNotes } from '../operator-notes';
import { useTypedParams } from 'react-router-typesafe-routes/dom';
import { buildPath, ROUTES } from 'src/lib/routes';
import { Heading } from 'shared/components/Text';
import { InboundFedNowMessageGetResponse } from 'src/build/operations';
import { ListItem, PropertyList, PropertyListProps } from 'shared/components/PropertyList';
import { compact } from 'lodash';
import XMLViewer from 'react-xml-viewer';
import dynamic from 'next/dynamic';
import { CopyButton } from 'shared/components/CopyButton';
const JSONViewer = dynamic(() => import('react-json-view'), {
  ssr: false
});
type ThesePropertyListsProps = {
  message: InboundFedNowMessageGetResponse;
} & Omit<PropertyListProps, 'items'>;
const AboveBodyPropertyList = (props: ThesePropertyListsProps) => {
  const {
    message,
    ...rest
  } = props;
  return <PropertyList {...rest} items={compact<ListItem>([{
    label: 'ID',
    value: message.id,
    copyable: message.id
  }, {
    label: 'Created at',
    value: message.created_at,
    format: 'month-day-year-hour-minute-second'
  }, {
    label: 'Queue',
    value: message.queue_name
  }, {
    label: 'Status',
    value: message.status
  }, {
    label: 'Message',
    value: message.message_id ?? '',
    href: message.message_id ? buildPath(ROUTES.OBJECT_VIEWER, {
      objectID: message.message_id
    }) : undefined
  }])} data-sentry-element="PropertyList" data-sentry-component="AboveBodyPropertyList" data-sentry-source-file="InboundFedNowMessageDetailPage.tsx" />;
};
export const InboundFedNowMessageDetailPage = () => {
  const {
    messageId
  } = useTypedParams(ROUTES.INBOUND_FED_NOW_MESSAGE_DETAIL);
  const {
    data: message
  } = useInboundFedNowMessageGet(messageId);
  if (!message) {
    return <h3>Loading</h3>;
  }
  return <PageLayout headline={'Inbound FedNow message'} data-sentry-element="PageLayout" data-sentry-component="InboundFedNowMessageDetailPage" data-sentry-source-file="InboundFedNowMessageDetailPage.tsx">
      <AboveBodyPropertyList message={message} data-sentry-element="AboveBodyPropertyList" data-sentry-source-file="InboundFedNowMessageDetailPage.tsx" />

      <Heading size="small" data-sentry-element="Heading" data-sentry-source-file="InboundFedNowMessageDetailPage.tsx">Body</Heading>
      <div>
        <CopyButton valueToDisplay="Copy Raw" valueToCopy={message.body} data-sentry-element="CopyButton" data-sentry-source-file="InboundFedNowMessageDetailPage.tsx" />
      </div>
      <div className="bg-strong p-2">
        <XMLViewer xml={message.body} collapsible={true} data-sentry-element="XMLViewer" data-sentry-source-file="InboundFedNowMessageDetailPage.tsx" />
      </div>

      <Heading size="small" data-sentry-element="Heading" data-sentry-source-file="InboundFedNowMessageDetailPage.tsx">MQ Message Descriptor</Heading>
      <div className="bg-strong p-2">
        <JSONViewer name={null} displayDataTypes={false} indentWidth={2} displayObjectSize={false} quotesOnKeys={false} collapseStringsAfterLength={30} src={message.mq_message_descriptor as object} data-sentry-element="JSONViewer" data-sentry-source-file="InboundFedNowMessageDetailPage.tsx" />
      </div>

      <Heading size="small" data-sentry-element="Heading" data-sentry-source-file="InboundFedNowMessageDetailPage.tsx">MQ Properties</Heading>
      <div className="bg-strong p-2">
        <JSONViewer name={null} displayDataTypes={false} indentWidth={2} displayObjectSize={false} quotesOnKeys={false} collapseStringsAfterLength={30} src={message.mq_properties as object} data-sentry-element="JSONViewer" data-sentry-source-file="InboundFedNowMessageDetailPage.tsx" />
      </div>

      <OperatorNotes modelId={message.id} data-sentry-element="OperatorNotes" data-sentry-source-file="InboundFedNowMessageDetailPage.tsx" />
    </PageLayout>;
};