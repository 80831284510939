import { useManualTaskList, useManualTaskPreviewGet, useManualTaskRunList, useManualTaskRunPost } from 'src/hooks/reactQuery';
import { Navigate, useSearchParams } from 'react-router-dom';
import { debounce, isEqual, startCase } from 'lodash';
import { PageLayout } from './deprecated/PageLayout';
import { Section } from './deprecated/Section';
import { ItemList } from './deprecated/ItemList';
import moment from 'moment';
import { Button } from './deprecated/Button';
import { absurd } from 'src/lib/absurd';
import { useCallback, useEffect, useRef, useState } from 'react';
import { SideBySide } from '@increase/shared/components/SideBySide';
import { ManualTaskListResponseDataItem, ManualTaskPreviewGetResponse, ManualTaskRunPostResponse } from 'src/build/operations';
import { StyledLink } from '@increase/shared/components/StyledLink';
import { useTypedParams } from 'react-router-typesafe-routes/dom';
import { ROUTES, buildPath } from 'src/lib/routes';
import { AlertList } from '@increase/shared/components/AlertList';
import { Heading } from '@increase/shared/components/Text';
import { Box } from '@increase/shared/components/Box';
import { EmptyState } from 'shared/components/EmptyState';
const SuccessfulTaskRun = ({
  manualTaskRun
}: {
  manualTaskRun: ManualTaskRunPostResponse;
}) => {
  switch (manualTaskRun.status) {
    case 'pending':
    case 'running':
    case undefined:
      return <>Loading...</>;
    case 'succeeded':
      return <>
          <AlertList tasks={[{
          icon: 'info',
          title: 'Success',
          body: manualTaskRun.output ?? '',
          key: '0',
          style: 'success',
          link: buildPath(ROUTES.MANUAL_TASK_RUN, {
            manualTaskRunId: manualTaskRun.id
          })
        }]} />
          {manualTaskRun.redirect_url && (manualTaskRun.redirect_url.startsWith('https://') ? window.location.href = manualTaskRun.redirect_url : <Navigate to={manualTaskRun.redirect_url} />)}
        </>;
    case 'failed':
    case 'requires_attention':
      return <AlertList tasks={[{
        icon: 'info',
        title: 'Error',
        body: manualTaskRun.output ?? '',
        key: '0',
        style: 'error',
        link: buildPath(ROUTES.MANUAL_TASK_RUN, {
          manualTaskRunId: manualTaskRun.id
        })
      }]} />;
    case 'abandoned':
      return <AlertList tasks={[{
        icon: 'info',
        title: 'Error',
        body: 'Abandoned! ' + (manualTaskRun.output ?? ''),
        key: '0',
        style: 'error',
        link: buildPath(ROUTES.MANUAL_TASK_RUN, {
          manualTaskRunId: manualTaskRun.id
        })
      }]} />;
    default:
      absurd(manualTaskRun.status);
  }
};
const fileToBase64 = async (file: File): Promise<{
  content_type: string;
  base64_content: string;
}> => new Promise((resolve, _) => {
  const reader = new FileReader();
  reader.onloadend = () => {
    const result = reader.result as string;
    // The first part here is the content type, e.g.
    // "data:image/jpeg;base64,<base64-encoded-image>"
    const [contentType, base64Content] = result.split(',');
    const cleanContentType = contentType.replace(/^data:/, '').replace(/;base64$/, '');
    resolve({
      content_type: cleanContentType,
      base64_content: base64Content
    });
  };
  reader.readAsDataURL(file);
});
const ManualTaskDetailInner = ({
  task
}: {
  task: ManualTaskListResponseDataItem;
}) => {
  const [preview, setPreview] = useState<ManualTaskPreviewGetResponse | null>(null);
  const [queryParams] = useSearchParams();
  const {
    data: manualTaskRuns
  } = useManualTaskRunList({
    task_name: task.name
  });
  const formRef = useRef<HTMLFormElement>(null);
  const [previewArguments, setPreviewArguments] = useState<Record<string, unknown>>({});
  const {
    data: previewData
  } = useManualTaskPreviewGet({
    arguments: previewArguments,
    manual_task_class: task.name
  });
  useEffect(() => {
    if (previewData && !isEqual(previewData, preview)) {
      setPreview(previewData);
    }
  }, [preview, previewData]);
  const manualTaskRunPost = useManualTaskRunPost();
  const renderError = () => {
    const error = manualTaskRunPost.error;
    if (error) {
      const message = error.response?.data?.message ?? error.message;
      return <AlertList tasks={[{
        icon: 'info',
        title: 'Error',
        body: message,
        key: '0',
        style: 'error'
      }]} />;
    }
    return <></>;
  };
  const formDataToArguments = useCallback(async (formData: FormData): Promise<Record<string, unknown>> => {
    const form = Object.fromEntries(formData);
    const entries = Object.entries(form).filter(([_, v]) => v && v !== '');
    const transformed: Record<string, unknown> = {};
    for (const [name, value] of entries) {
      const argument = task.arguments.find(a => a.name === name);
      transformed[name] = value;
      switch (argument?.type) {
        case 'Operations::APISchema::ModelIDArrayParameter':
          if (typeof value === 'string') {
            transformed[name] = value.split('\n').map(modelId => modelId.trim());
          }
          break;
        case 'Operations::APISchema::ModelIDParameter':
          if (typeof value === 'string') {
            transformed[name] = value.trim();
          }
          break;
        case 'Operations::APISchema::Base64FileUploadParameter':
          if (value instanceof File) {
            transformed[name] = await fileToBase64(value);
          }
          break;
      }
    }
    return transformed;
  }, [task.arguments]);
  const handleFormSubmit = async (submitEvent: React.FormEvent<HTMLFormElement>) => {
    submitEvent.preventDefault();
    const formData = new FormData(submitEvent.currentTarget);
    const transformed = await formDataToArguments(formData);
    manualTaskRunPost.mutate([{
      manual_task_class: task.name,
      arguments: transformed
    }]);
  };
  const updatePreview = useCallback(async () => {
    if (!formRef.current) {
      return;
    }
    const formData = new FormData(formRef.current);
    const transformed = await formDataToArguments(formData);
    setPreviewArguments(transformed);
  }, [formDataToArguments]);
  useEffect(() => {
    updatePreview();
  }, [updatePreview]);
  const debouncedUpdatePreview = debounce(updatePreview, 500);
  const readableTaskName = task.name.replace('Operations::ManualTask::', '');
  return <PageLayout headline={<div className="flex items-center justify-between gap-2">
          <div>{readableTaskName}</div>
          <Button onClick={() => window.open(task.github_link)}>
            View Code
          </Button>
        </div>} data-sentry-element="PageLayout" data-sentry-component="ManualTaskDetailInner" data-sentry-source-file="manual-task-detail.tsx">
      <SideBySide mode="centered" left={<>
            <div className="text-subtle">{task.description}</div>
            <form ref={formRef} autoComplete="off" onSubmit={handleFormSubmit} className="max-w-lg space-y-2">
              {task.arguments.map(argument => {
          const prefilled = queryParams.get(argument.name) ?? undefined;
          return <div key={argument.name} className="space-y-1">
                    <div className="text-main text-sm">
                      {startCase(argument.name)}
                    </div>
                    {(() => {
              switch (argument.type) {
                case 'Operations::APISchema::EnumParameter':
                case 'Operations::APISchema::LegacyCategoricalParameter':
                  return <select onChange={debouncedUpdatePreview} className="border-strong block w-full rounded border p-1.5 text-sm shadow-sm" name={argument.name} defaultValue={argument.default ?? prefilled}>
                              {argument.optional && <option />}
                              {argument.options?.map(o => <option value={o} key={o}>
                                  {o}
                                </option>)}
                            </select>;
                case 'Operations::APISchema::BooleanParameter':
                  return argument.optional ? <select onChange={debouncedUpdatePreview} className="border-strong block w-full rounded border p-1.5 text-sm shadow-sm" name={argument.name}>
                              <option />
                              <option value="true" selected={prefilled === 'true'}>
                                True
                              </option>
                              <option value="false" selected={prefilled === 'false'}>
                                False
                              </option>
                            </select> : <>
                              {/* https://stackoverflow.com/questions/1809494/post-unchecked-html-checkboxes */}
                              <input onChange={debouncedUpdatePreview} type="hidden" value="false" name={argument.name} />
                              <input onChange={debouncedUpdatePreview} className="border-strong rounded border p-1.5 text-sm shadow-sm" type="checkbox" name={argument.name} value="true" defaultChecked={prefilled === 'true' || argument.placeholder === 'true'} />
                            </>;
                case 'Operations::APISchema::IntegerParameter':
                  return <input onChange={debouncedUpdatePreview} className="border-strong block w-full rounded border p-1.5 text-sm shadow-sm" type="number" autoComplete="off" name={argument.name} defaultValue={prefilled} placeholder={argument.placeholder ?? undefined} onWheel={e => e.currentTarget.blur()} />;
                case 'Operations::APISchema::DateParameter':
                  return <input onChange={debouncedUpdatePreview} className="border-strong block w-full rounded border p-1.5 text-sm shadow-sm" type="date" name={argument.name} autoComplete="off" defaultValue={prefilled ?? argument.placeholder ?? undefined} />;
                case 'Operations::APISchema::ModelIDArrayParameter':
                  return <>
                              <textarea onChange={debouncedUpdatePreview} className="border-strong block w-full rounded border p-1.5 text-sm shadow-sm" name={argument.name} defaultValue={prefilled} />
                              <div className="text-xs">
                                Enter IDs, one per line.
                              </div>
                            </>;
                case 'Operations::ManualTask::LargeStringParameter':
                  return <>
                              <textarea onChange={debouncedUpdatePreview} className="border-strong block w-full resize rounded border p-1.5 text-sm shadow-sm" name={argument.name} defaultValue={prefilled} style={{
                      whiteSpace: 'nowrap'
                    }} />
                            </>;
                case 'Operations::APISchema::Base64FileUploadParameter':
                  return <>
                              <input onChange={debouncedUpdatePreview} autoComplete="off" className="border-strong block w-full rounded border p-1.5 text-sm shadow-sm" type="file" name={argument.name} />
                            </>;
                default:
                  return <input onChange={debouncedUpdatePreview} autoComplete="off" className="border-strong placeholder:text-disabled block w-full rounded border p-1.5 text-sm shadow-sm" type="text" name={argument.name} defaultValue={prefilled} placeholder={argument.placeholder ?? undefined} />;
              }
            })()}
                    <div className="text-main text-xs">
                      {argument.type === 'Operations::APISchema::BooleanParameter' ? '' : argument.optional ? 'Optional.' : 'Required.'}
                      {argument.documentation && ` ${argument.documentation}`}
                    </div>
                  </div>;
        })}
              <Button type="submit" disabled={manualTaskRunPost.isLoading}>
                Submit
              </Button>
              <br />
              <br />
              <div>
                {manualTaskRunPost.data && <SuccessfulTaskRun manualTaskRun={manualTaskRunPost.data} />}
              </div>
              <div className="">{renderError()}</div>
            </form>
          </>} right={<div>
            {preview && preview.sections && preview.sections.length > 0 && <>
                <div className="text-main mb-2 text-xl">Preview</div>
                <div className="bg-strong rounded p-2">
                  {preview.sections.map(section => <div key={section.title}>
                      {section.title && <div className="text-main text-lg font-medium">
                          {section.title}
                        </div>}
                      {section.elements && section.elements.map(element => <div key={element.label}>
                            {element.label && <div className="text-main text-sm font-medium">
                                {element.label}
                              </div>}
                            {element.href ? <StyledLink href={element.href} style="underline">
                                {element.image_src && <iframe key={element.image_src} src={element.image_src} className="w-full rounded-lg" />}
                                {element.content && <div className="text-main text-sm">
                                    {element.content}
                                  </div>}
                              </StyledLink> : <>
                                {element.image_src && <iframe src={element.image_src} height={500} className="w-full rounded-lg" />}
                                {element.content && <div className="text-main text-sm">
                                    {element.content}
                                  </div>}
                              </>}
                          </div>)}
                    </div>)}
                </div>
              </>}
          </div>} data-sentry-element="SideBySide" data-sentry-source-file="manual-task-detail.tsx" />
      <Section header="Previous Runs" data-sentry-element="Section" data-sentry-source-file="manual-task-detail.tsx">
        {manualTaskRuns && manualTaskRuns.data.length > 0 ? <ItemList items={manualTaskRuns.data.map(mtr => ({
        id: mtr.id,
        text: `Started by ${mtr.operator_email} ${moment(mtr.started_at).fromNow()}`,
        detail: `${mtr.status} | ${JSON.stringify(mtr.arguments)}`,
        href: buildPath(ROUTES.MANUAL_TASK_RUN, {
          manualTaskRunId: mtr.id
        })
      }))} /> : <EmptyState illustration="metrics" style="minimized" title="No previous runs">
            When you run a manual task, it will appear here
          </EmptyState>}
      </Section>
    </PageLayout>;
};
export const ManualTaskDetail = () => {
  const {
    taskName
  } = useTypedParams(ROUTES.MANUAL_TASK);
  const {
    data: manualTasks
  } = useManualTaskList({});
  const task = manualTasks?.data?.find(t => t.name === taskName);
  if (!manualTasks) {
    return <>Loading...</>;
  }
  if (!task) {
    return <Box padding="6">
        <Heading>No task named {taskName}.</Heading>
      </Box>;
  }
  return <ManualTaskDetailInner task={task} data-sentry-element="ManualTaskDetailInner" data-sentry-component="ManualTaskDetail" data-sentry-source-file="manual-task-detail.tsx" />;
};