import { compact } from 'lodash';
import { ListItem, PropertyList, PropertyListProps } from '@increase/shared/components/PropertyList';
import { formatAmount, humanize } from '@increase/shared/lib/formatting';
import { TransferGetResponse, ACHTransferGetResponse } from 'src/build/operations';
import { useAccountGet } from 'src/hooks/reactQuery';
import { useAccountPropertyRow, useGroupPropertyRow, useProgramPropertyRow } from 'src/lib/propertyListHelpers';
type TransferPropertyListProps = {
  id: string;
  transfer: TransferGetResponse | ACHTransferGetResponse;
} & Omit<PropertyListProps, 'items'>;
export const TransferPropertyList = (props: TransferPropertyListProps) => {
  const {
    id,
    transfer,
    ...rest
  } = props;
  const groupRow = useGroupPropertyRow(transfer.group_id);
  const accountRow = useAccountPropertyRow(transfer.account_id);
  const {
    data: account
  } = useAccountGet(transfer.account_id);
  const programRow = useProgramPropertyRow(account?.program_id);
  const dashboardUrl = `https://dashboard.increase.com/transfers/${id}`;
  return <PropertyList {...rest} items={compact<ListItem>([{
    label: 'ID',
    value: id,
    copyable: id
  }, {
    label: 'Status',
    value: humanize(transfer.status),
    badgeColor: transfer.status === 'pending' ? 'yellow' : transfer.status === 'rejected' ? 'red' : 'gray'
  }, {
    label: 'Amount',
    value: formatAmount(transfer.amount, 'USD')
  }, {
    label: 'Created at',
    value: transfer.created_at,
    format: 'month-day-year-hour-minute-second'
  }, groupRow, programRow, accountRow, {
    label: 'Dashboard URL',
    value: 'Copy',
    copyable: dashboardUrl
  }])} data-sentry-element="PropertyList" data-sentry-component="TransferPropertyList" data-sentry-source-file="TransferPropertyList.tsx" />;
};