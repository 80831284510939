import { TableColumns, Table, PrestyledTableProps } from '@increase/shared/components/Table';
import { useMemo } from 'react';
import { BankFeeStatementListResponseDataItem } from 'src/build/operations';
import { formatISO8601Timestamp } from '@increase/shared/lib/formatting';
import { buildPath, ROUTES } from 'src/lib/routes';
type Props = PrestyledTableProps<BankFeeStatementListResponseDataItem>;
export const BankFeeStatementsTable = (props: Props) => {
  const {
    getRowProps,
    ...rest
  } = props;
  const columns = useMemo<TableColumns<BankFeeStatementListResponseDataItem>>(() => {
    return [{
      header: 'Period',
      expand: 1,
      contents: bankFeeStatement => ({
        text: formatISO8601Timestamp(bankFeeStatement.period_start, 'month-year', 'UTC'),
        textWeight: 'medium',
        textColor: 'primary'
      })
    }, {
      header: 'Status',
      contents: bankFeeStatement => ({
        text: bankFeeStatement.status,
        badgeColor: bankFeeStatement.status === 'open' ? 'blue' : 'gray'
      })
    }];
  }, []);
  const defaultRowProps = (datum: BankFeeStatementListResponseDataItem) => {
    return {
      href: buildPath(ROUTES.BANK_FEE_STATEMENTS_DETAIL, {
        bankFeeStatementId: datum.id
      }),
      className: 'hover:bg-main-hover cursor-pointer transition-all group'
    };
  };
  return <Table<BankFeeStatementListResponseDataItem> columns={columns} getRowProps={getRowProps ?? defaultRowProps} {...rest} data-sentry-element="Table" data-sentry-component="BankFeeStatementsTable" data-sentry-source-file="BankFeeStatementsTable.tsx" />;
};