import { useDirectUserDiligenceSubmissionGet } from 'src/hooks/reactQuery';
import { PageLayout } from './deprecated/PageLayout';
import { useTypedParams } from 'react-router-typesafe-routes/dom';
import { ROUTES } from 'src/lib/routes';
import { PropertyList, ListItem } from '@increase/shared/components/PropertyList';
import { useGroupPropertyRow, useProgramPropertyRow } from 'src/lib/propertyListHelpers';
import { pick } from 'lodash';
export const DirectUserDiligenceSubmission = () => {
  const {
    submissionID
  } = useTypedParams(ROUTES.PLATFORM_COMPLIANCE_METRICS_SUBMISSIONS_DETAIL);
  const {
    data: submission
  } = useDirectUserDiligenceSubmissionGet(submissionID);
  const groupRow = useGroupPropertyRow(submission?.group_id);
  const programRow = useProgramPropertyRow(submission?.program_id);
  if (!submission) {
    return <></>;
  }
  const responses = Object.entries(pick(submission, ['business_description', 'customers_description', 'locations_and_markets_description', 'engaged_in_crypto_or_cannabis', 'customer_agreements_description', 'account_types_description', 'funds_flows_description', 'fee_structure_description', 'is_regeristered_with_financial_crimes_enforcement_network', 'not_registered_with_financial_crimes_enforcement_network', 'money_services_business_registration_number', 'has_money_transmission_licenses', 'no_money_transmission_licenses_explaination', 'money_transmission_licenses_details', 'has_other_licenses', 'other_licenses_details', 'can_manage_client_money', 'operating_exemption_explaination', 'has_compliance_program', 'compliance_program_explaination', 'no_compliance_program_explaination', 'has_agency_status', 'agency_agreements_description', 'is_offering_credit', 'underwriting_process_description', 'debt_collection_description', 'is_using_third_parties', 'volume_projections_description'])).map(([label, value]) => ({
    label,
    value: value ?? 'Not applicable.'
  }) as ListItem);
  const attachments = submission.attachments.map(value => ({
    label: value.field_name,
    value: value.file_id,
    href: `https://operations.increase.com/api_files/${value.file_id}/view`
  }) as ListItem).sort((a, b) => a.label.localeCompare(b.label));
  return <PageLayout id="direct_user_diligence_submission" headline="Direct User Diligence Submission" data-sentry-element="PageLayout" data-sentry-component="DirectUserDiligenceSubmission" data-sentry-source-file="direct-user-diligence-submission.tsx">
      <PropertyList title="Submission" items={[{
      label: 'Submission ID',
      value: submissionID
    }, groupRow, programRow, {
      label: 'Submitted at',
      value: submission.created_at,
      format: 'month-day-year-hour-minute-second'
    }]} data-sentry-element="PropertyList" data-sentry-source-file="direct-user-diligence-submission.tsx" />
      <PropertyList title="Responses" items={responses} data-sentry-element="PropertyList" data-sentry-source-file="direct-user-diligence-submission.tsx" />
      <PropertyList title="Attachments" items={attachments} data-sentry-element="PropertyList" data-sentry-source-file="direct-user-diligence-submission.tsx" />
    </PageLayout>;
};