import { Subheading } from 'shared/components/Text';
import { Box } from 'shared/components/Box';
import { Check, Image } from './types';
import { Button } from 'shared/components/Button';
import { useInboundMailItemAllocatePost, useInboundMailItemRejectPost, useLockboxList } from 'src/hooks/reactQuery';
import { TextInput } from 'shared/components/TextInput';
import { Select } from 'shared/components/Select';
import { useEffect, useState } from 'react';
import { Lockbox, LockboxNotFound } from './lockbox';
import { AmountInput } from 'shared/components/AmountInput';
import { CheckboxInput } from 'shared/components/CheckboxInput';
import { PDF } from './pdf';
import { InboundMailItemAllocatePostResponse, InboundMailItemRejectPostParametersRejectionReason, InboundMailItemRejectPostResponse, LockboxGetResponse } from 'src/build/operations/operations.schemas';
const Sidebar = ({
  inboundMailItemId,
  checks,
  selectedImage,
  line2Suffix,
  onUpdate,
  onUpdateCheck,
  onAddCheck,
  onDeleteCheck,
  onAllocate,
  onReject
}: {
  inboundMailItemId: string;
  checks: Check[];
  selectedImage: Image | null;
  line2Suffix: string;
  onUpdate: (line2Suffix: string) => void;
  onUpdateCheck: (id: string, amount: number | undefined, front: Image | null, back: Image | null) => void;
  onAddCheck: () => void;
  onDeleteCheck: (id: string) => void;
  onAllocate: (inboundMailItem: InboundMailItemAllocatePostResponse) => void;
  onReject: (inboundMailItem: InboundMailItemRejectPostResponse) => void;
}) => {
  const [rejectionReason, setRejectionReason] = useState<string>('');
  const [assignToAscend, setAssignToAscend] = useState(false);
  const [lockbox, setLockbox] = useState<LockboxGetResponse | null>(null);
  const {
    mutateAsync: allocate,
    isLoading: isAllocating
  } = useInboundMailItemAllocatePost();
  const {
    mutateAsync: reject,
    isLoading: isRejecting
  } = useInboundMailItemRejectPost();
  const {
    refetch: fetchLockboxes
  } = useLockboxList({
    line2_suffix: line2Suffix
  }, {
    enabled: !!line2Suffix
  });
  useEffect(() => {
    const fetchLockbox = async () => {
      if (line2Suffix) {
        const result = await fetchLockboxes();
        const lockbox = result.data?.data[0];
        setLockbox(lockbox ?? null);
      } else {
        setLockbox(null);
      }
    };
    fetchLockbox();
  }, [line2Suffix, fetchLockboxes]);
  const handleSubmit = async () => {
    const result = await allocate([inboundMailItemId, {
      checks: checks.map(check => ({
        amount: check.amount ?? 0,
        front_page_number: check.front?.index,
        back_page_number: check.back?.index
      })),
      line2_suffix: line2Suffix
    }]);
    onAllocate(result);
  };
  const handleReject = async () => {
    const result = await reject([inboundMailItemId, {
      rejection_reason: rejectionReason as InboundMailItemRejectPostParametersRejectionReason,
      assign_to_ascend: assignToAscend
    }]);
    onReject(result);
  };
  const stopPropagation = (e: React.KeyboardEvent<HTMLInputElement>) => {
    e.stopPropagation();
  };
  return <Box gap="4" data-sentry-element="Box" data-sentry-component="Sidebar" data-sentry-source-file="sidebar.tsx">
      <Box className="rounded-lg border border-gray-200 p-4" data-sentry-element="Box" data-sentry-source-file="sidebar.tsx">
        <Subheading data-sentry-element="Subheading" data-sentry-source-file="sidebar.tsx">Reject</Subheading>
        <Select name="rejection_reason" placeholder="Reason" value={rejectionReason} onChange={e => {
        setRejectionReason(e.target.value);
      }} sections={[{
        options: [{
          value: '',
          label: 'Select a reason'
        }, {
          value: 'no_matching_lockbox_and_suite_86107',
          label: 'No Matching Lockbox and Suite 86107'
        }, {
          value: 'no_matching_lockbox',
          label: 'No Matching Lockbox'
        }, {
          value: 'no_check',
          label: 'No Check'
        }, {
          value: 'lockbox_not_active',
          label: 'Lockbox Not Active'
        }]
      }]} data-sentry-element="Select" data-sentry-source-file="sidebar.tsx" />
        <CheckboxInput id="assign_to_ascend" label="Assign to Ascend" checked={assignToAscend} onChange={e => setAssignToAscend(e.target.checked)} data-sentry-element="CheckboxInput" data-sentry-source-file="sidebar.tsx" />
        <div className="flex justify-end">
          <Button style="warning" onClick={handleReject} text="Reject" disabled={rejectionReason === '' || isRejecting} data-sentry-element="Button" data-sentry-source-file="sidebar.tsx" />
        </div>
      </Box>
      <Box className="rounded-lg border border-gray-200 p-4" data-sentry-element="Box" data-sentry-source-file="sidebar.tsx">
        <Subheading data-sentry-element="Subheading" data-sentry-source-file="sidebar.tsx">Allocate</Subheading>

        <TextInput id="line2suffix" placeholder="Line 2 suffix" value={line2Suffix} onChange={e => onUpdate(e.target.value)} autoComplete="off" onKeyDown={stopPropagation} autoFocus data-sentry-element="TextInput" data-sentry-source-file="sidebar.tsx" />

        {line2Suffix && (lockbox ? <Lockbox lockbox={lockbox} /> : <LockboxNotFound />)}

        <Box gap="6" data-sentry-element="Box" data-sentry-source-file="sidebar.tsx">
          {checks.map(check => {
          return <Box key={check.id} gap="2">
                <Box flex gap="1">
                  <AmountInput value={check.amount !== undefined ? check.amount / 100.0 : undefined} onKeyDown={stopPropagation} placeholder="$0.00" onValueChange={e => onUpdateCheck(check.id, e.floatValue !== undefined ? e.floatValue * 100 : undefined, check.front, check.back)} />
                  <Button icon="trash" style="warning" onClick={() => {
                onDeleteCheck(check.id);
              }} />
                </Box>
                <Box flex gap="2" className="w-full">
                  {check.front ? <PDF imageSource={check.front.image} className="w-full" overlay={<Box className="absolute top-2 right-2">
                          <Button size="small" style="warning" icon="trash" onClick={() => {
                  onUpdateCheck(check.id, check.amount, null, check.back);
                }} />
                        </Box>} /> : <Button text="Front" style="secondary" icon="image_check" size="small" className="w-full" disabled={selectedImage === null} onClick={() => {
                onUpdateCheck(check.id, check.amount, selectedImage, check.back);
              }} />}

                  {check.back ? <PDF imageSource={check.back.image} className="w-full" overlay={<Box className="absolute top-2 right-2">
                          <Button size="small" style="warning" icon="trash" onClick={() => {
                  onUpdateCheck(check.id, check.amount, check.front, null);
                }} />
                        </Box>} /> : <Button text="Back" style="secondary" icon="image_check" size="small" className="w-full" disabled={selectedImage === null} onClick={() => {
                onUpdateCheck(check.id, check.amount, check.front, selectedImage);
              }} />}
                </Box>
              </Box>;
        })}

          <Button text="Add check" style="secondary" icon="plus_circle" onClick={onAddCheck} data-sentry-element="Button" data-sentry-source-file="sidebar.tsx" />
        </Box>

        <div className="flex justify-end">
          <Button onClick={handleSubmit} text="Allocate" disabled={line2Suffix === '' || isAllocating || !lockbox || !checks.every(check => check.front && check.back)} data-sentry-element="Button" data-sentry-source-file="sidebar.tsx" />
        </div>
      </Box>
    </Box>;
};
export { Sidebar };