import React from 'react';
import { useControlConfigurationGet, useControlRecordListInfinite } from 'src/hooks/reactQuery';
import { PageLayout } from '../deprecated/PageLayout';
import { SideBySide } from '@increase/shared/components/SideBySide';
import { TableStateWrapper } from '@increase/shared/components/TableStateWrapper';
import { formatInteger, humanize } from '@increase/shared/lib/formatting';
import { ControlConfigurationPropertyList } from '../property-lists/ControlConfigurationPropertyList';
import { ControlRecordsTable } from '../tables/ControlRecordsTable';
import { Instructions } from '@increase/shared/components/Instructions';
import { useTypedParams } from 'react-router-typesafe-routes/dom';
import { ROUTES, buildPath } from 'src/lib/routes';
import { AssociatedManualTasks } from '../associated-manual-tasks';
export const ControlConfigurationDetailPage = () => {
  const {
    id: controlConfigurationId
  } = useTypedParams(ROUTES.CONTROLS_DETAIL);
  const {
    data: controlConfiguration
  } = useControlConfigurationGet(controlConfigurationId);
  const successResults = useControlRecordListInfinite({
    control_configuration_ids: [controlConfigurationId],
    results: ['success'],
    limit: 5
  });
  const failureResults = useControlRecordListInfinite({
    control_configuration_ids: [controlConfigurationId],
    results: ['failure'],
    limit: 5
  });
  if (!controlConfiguration) {
    return <>Loading...</>;
  }
  return <PageLayout headline={humanize(controlConfiguration.category)} data-sentry-element="PageLayout" data-sentry-component="ControlConfigurationDetailPage" data-sentry-source-file="ControlConfigurationDetailPage.tsx">
      <SideBySide right={<>
            <ControlConfigurationPropertyList title="Details" controlConfiguration={controlConfiguration} />
            <AssociatedManualTasks objectId={controlConfiguration.id} />
          </>} left={<>
            <Instructions illustration="protection" text={controlConfiguration.title} details={controlConfiguration.details} />
            <TableStateWrapper {...successResults} table={ControlRecordsTable} style="detail" showObject showCategory={false} title={`Success examples (${formatInteger(controlConfiguration.number_of_successes)})`} emptyTitle="No successes" emptySubtitle="There are no successful records in this control." viewMoreHref={buildPath(ROUTES.CONTROL_RECORDS_LIST, {}, {
        results: ['success'],
        control_configuration_ids: [controlConfigurationId]
      })} />
            <TableStateWrapper {...failureResults} table={ControlRecordsTable} style="detail" showObject showCategory={false} title={`Failure examples (${formatInteger(controlConfiguration.number_of_failures)})`} emptyTitle="No failures" emptySubtitle="There are no failing records in this control." viewMoreHref={buildPath(ROUTES.CONTROL_RECORDS_LIST, {}, {
        results: ['failure'],
        control_configuration_ids: [controlConfigurationId]
      })} />
          </>} mode="rightDrawer" data-sentry-element="SideBySide" data-sentry-source-file="ControlConfigurationDetailPage.tsx" />
    </PageLayout>;
};