import { compact } from 'lodash';
import { ListItem, PropertyList, PropertyListProps } from '@increase/shared/components/PropertyList';
import { formatISO8601Timestamp } from '@increase/shared/lib/formatting';
import { PlatformUnusualActivityReportGetResponse } from 'src/build/operations';
import { increaseReviewStatusBadgeColor } from '../tables/PlatformUnusualActivityReportsTable';
import { useGroupPropertyRow } from 'src/lib/propertyListHelpers';
type PlatformUnusualActivityReportPropertyListProps = {
  unusualActivityReport: PlatformUnusualActivityReportGetResponse;
} & Omit<PropertyListProps, 'items'>;
export const PlatformUnusualActivityReportPropertyList = (props: PlatformUnusualActivityReportPropertyListProps) => {
  const {
    unusualActivityReport,
    ...rest
  } = props;
  const groupRow = useGroupPropertyRow(unusualActivityReport.group_id);
  return <PropertyList {...rest} items={compact<ListItem>([{
    label: 'ID',
    value: unusualActivityReport.id,
    copyable: unusualActivityReport.id
  }, {
    label: 'Increase Review Status',
    value: unusualActivityReport.increase_review_status,
    badgeColor: increaseReviewStatusBadgeColor[unusualActivityReport.increase_review_status]
  }, groupRow, {
    label: 'Created By',
    value: unusualActivityReport.created_by_email ?? 'N/A'
  }, {
    label: 'Created',
    value: unusualActivityReport.created_at,
    format: 'month-day-year-hour-minute-second'
  }, {
    label: 'Activity Began At',
    value: unusualActivityReport.activity_began_on ? formatISO8601Timestamp(unusualActivityReport.activity_began_on, 'date', 'UTC') : 'N/A'
  }, {
    label: 'Activity Ended At',
    value: unusualActivityReport.activity_ended_on ? formatISO8601Timestamp(unusualActivityReport.activity_ended_on, 'date', 'UTC') : 'N/A'
  }])} data-sentry-element="PropertyList" data-sentry-component="PlatformUnusualActivityReportPropertyList" data-sentry-source-file="PlatformUnusualActivityReportPropertyList.tsx" />;
};