import { compact, uniq } from 'lodash';
import { useState } from 'react';
import { useTypedParams } from 'react-router-typesafe-routes/dom';
import { Box } from '@increase/shared/components/Box';
import { ListItem, PropertyList } from '@increase/shared/components/PropertyList';
import { SideBySide } from '@increase/shared/components/SideBySide';
import { Table } from '@increase/shared/components/Table';
import { Heading } from '@increase/shared/components/Text';
import { TextArea } from '@increase/shared/components/TextArea';
import { humanize, formatInteger, formatISO8601Timestamp } from '@increase/shared/lib/formatting';
import { CompletedTransactionListResponseDataItem, EntityClusterGetResponse, EntityClusterGetResponseCategory, EntityClusterGetResponseEntitiesItem, FraudKnownBadSubmissionsSearchResponseDataItem } from 'src/build/operations';
import { useEntityClusterReviewPost, useEntityClusterGet, useTransactionsByAccountAndRoutingNumberList, useFraudKnownBadSubmissionsSearch } from 'src/hooks/reactQuery';
import { ROUTES, buildPath } from 'src/lib/routes';
import { PageLayout } from '../deprecated/PageLayout';
import { OperatorNotes } from '../operator-notes';
import { Button } from '@increase/shared/components/Button';
import { TableStateWrapper } from '@increase/shared/components/TableStateWrapper';
import { CompletedTransactionsTable, CompletedTransactionsTableProps } from '../tables/CompletedTransactionTable';
import { FraudKnownBadSubmissionsTable } from '../tables/FraudKnownBadSubmissionsTable';
import { Loading } from '@increase/shared/components/Loading';
const ReviewCluster = (props: {
  entityCluster: EntityClusterGetResponse;
}) => {
  const submitReview = useEntityClusterReviewPost();
  const [message, setMessage] = useState('');
  return <Box data-sentry-element="Box" data-sentry-component="ReviewCluster" data-sentry-source-file="EntityClusterDetailPage.tsx">
      <TextArea placeholder="Review comment" value={message} onChange={e => setMessage(e.target.value)} data-sentry-element="TextArea" data-sentry-source-file="EntityClusterDetailPage.tsx" />
      <Button text="Mark reviewed" onClick={() => submitReview.mutateAsync([props.entityCluster.id, message.length > 0 ? {
      message
    } : {}])} data-sentry-element="Button" data-sentry-source-file="EntityClusterDetailPage.tsx" />
    </Box>;
};
const EntityClusterTransactions = (props: {
  routing_account_number: string;
}) => {
  const [routingNumber, accountNumber] = props.routing_account_number.split('-');
  const {
    data,
    error
  } = useTransactionsByAccountAndRoutingNumberList({
    routing_number: routingNumber,
    account_number: accountNumber
  });
  if (!data) {
    return <></>;
  }
  return <TableStateWrapper<CompletedTransactionListResponseDataItem, CompletedTransactionsTableProps> table={CompletedTransactionsTable} data={data.data} error={error} style="detail" title="Transactions" emptyTitle="No transactions" emptySubtitle="There are no transactions." showAccount={true} showGroup={true} showRoute={false} viewMoreHref="" data-sentry-element="TableStateWrapper" data-sentry-component="EntityClusterTransactions" data-sentry-source-file="EntityClusterDetailPage.tsx" />;
};
const EntityClusterKnownBadSubmissions = (props: {
  entities: EntityClusterGetResponseEntitiesItem[];
}) => {
  const {
    data,
    error
  } = useFraudKnownBadSubmissionsSearch({
    entity_ids: props.entities.map(entity => entity.id),
    groups: props.entities.map(entity => entity.group_id)
  });
  if (!data) {
    return <Loading />;
  }
  return <TableStateWrapper<FraudKnownBadSubmissionsSearchResponseDataItem> table={FraudKnownBadSubmissionsTable} data={data.data} error={error} style="detail" title="Known bad submissions" emptyTitle="No known bad submissions" emptySubtitle="There are no known bad submissions." viewMoreHref="" data-sentry-element="TableStateWrapper" data-sentry-component="EntityClusterKnownBadSubmissions" data-sentry-source-file="EntityClusterDetailPage.tsx" />;
};
export const EntityClusterDetailPage = () => {
  const {
    entityClusterID
  } = useTypedParams(ROUTES.ENTITY_CLUSTER_DETAIL);
  const {
    data
  } = useEntityClusterGet(entityClusterID);
  if (!data) {
    return <></>;
  }
  const isTransactionMatch = EntityClusterGetResponseCategory.transaction_match === data.category;
  const isKnownBadTransactionMatch = EntityClusterGetResponseCategory.transaction_with_known_bad === data.category;
  return <PageLayout id="application.service.entity" headline={`Entity Cluster ${entityClusterID}`} data-sentry-element="PageLayout" data-sentry-component="EntityClusterDetailPage" data-sentry-source-file="EntityClusterDetailPage.tsx">
      <SideBySide mode="rightDrawer" right={<Box>
            <Heading>Details</Heading>
            <PropertyList items={compact<ListItem>([{
        label: 'Status',
        value: humanize(data.status)
      }, data.reviewed_by_email && {
        label: 'Reviewed by',
        value: data.reviewed_by_email
      }, {
        label: 'Reason',
        value: humanize(data.category)
      }, {
        label: 'Matching data',
        value: data.matching_data,
        copyable: data.matching_data
      }, {
        label: 'Entity count',
        value: formatInteger(data.entities.length)
      }, {
        label: 'Unique groups',
        value: formatInteger(uniq(data.entities.map(e => e.group_id)).length)
      }])} />
          </Box>} left={<Box>
            {data.status === 'pending_reviewing' && <ReviewCluster entityCluster={data} />}
            <OperatorNotes modelId={entityClusterID} readOnly={data.status === 'pending_reviewing'} />

            <Heading>Matching Entities</Heading>
            <Table data={data.entities} columns={[{
        header: 'Name',
        contents: r => ({
          text: r.name,
          href: buildPath(ROUTES.ENTITY, {
            entityId: r.id
          })
        })
      }, {
        header: 'Entity status',
        contents: r => ({
          text: humanize(r.status),
          badgeColor: r.status === 'active' ? 'green' : 'gray'
        })
      }, {
        header: 'Entity created',
        contents: r => ({
          text: formatISO8601Timestamp(r.created_at, 'month-day-year-hour-minute-second')
        })
      }, {
        header: 'Group',
        contents: r => ({
          text: r.group_id,
          href: buildPath(ROUTES.GROUPS_DETAIL, {
            groupId: r.group_id
          })
        })
      }, {
        header: 'Group status',
        contents: r => ({
          text: humanize(r.group_status),
          badgeColor: r.group_status === 'active' ? 'green' : 'red'
        })
      }]} />
            {(isTransactionMatch || isKnownBadTransactionMatch) && <EntityClusterTransactions routing_account_number={data.matching_data} />}
            {isKnownBadTransactionMatch && <EntityClusterKnownBadSubmissions entities={data.entities} />}
          </Box>} data-sentry-element="SideBySide" data-sentry-source-file="EntityClusterDetailPage.tsx" />
    </PageLayout>;
};