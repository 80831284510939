import { useCallback, useMemo, useState } from 'react';
import { applyThreshold, grayHistogram } from '../../lib/image-utility';
import { ImageDataView } from './image-data-view';
import { useKeyPressEvent } from 'react-use';
import _ from 'lodash';
import { Body } from 'shared/components/Text';
type Props = {
  pixels: ImageData;
  initialThreshold: number;
  onLevelUpdated: (imageData: ImageData, level: number) => void;
};
const HistogramSparkline = ({
  data,
  threshold
}: {
  data: number[];
  threshold: number;
}) => {
  const max = Math.max(...data);
  const height = 18;
  return <div style={{
    borderWidth: '1px',
    borderColor: 'rgba(168, 164, 248, 0.5)',
    height: `${height}px`,
    width: '256px',
    boxSizing: 'content-box'
  }} data-sentry-component="HistogramSparkline" data-sentry-source-file="image-threshold.tsx">
      {data.map((value, index) => {
      const color = index === threshold ? 'red' : 'black';
      const columnHeight = index === threshold ? height : value / max * height;
      return <div key={index} style={{
        display: 'inline-block',
        height: `${columnHeight}px`,
        width: '1px',
        backgroundColor: color
      }}></div>;
    })}
    </div>;
};
const clampLevel = (value: number) => {
  return _.clamp(value, 0, 255);
};
export const ImageThreshold = ({
  pixels,
  onLevelUpdated,
  initialThreshold
}: Props) => {
  const [level, setLevel] = useState(initialThreshold);
  const thresholdedPixels = applyThreshold(pixels, level);
  const histogram = useMemo(() => {
    return grayHistogram(pixels);
  }, [pixels]);
  useKeyPressEvent('Enter', () => {
    onLevelUpdated(thresholdedPixels, level);
  });
  const incrementLevel = useCallback((e: KeyboardEvent) => {
    setLevel(clampLevel(level + (e.shiftKey ? 1 : 10)));
  }, [level, setLevel]);
  const decrementLevel = useCallback((e: KeyboardEvent) => {
    setLevel(clampLevel(level - (e.shiftKey ? 1 : 10)));
  }, [level, setLevel]);
  useKeyPressEvent('ArrowUp', incrementLevel);
  useKeyPressEvent('ArrowRight', incrementLevel);
  useKeyPressEvent('d', incrementLevel);
  useKeyPressEvent('D', incrementLevel);
  useKeyPressEvent('ArrowDown', decrementLevel);
  useKeyPressEvent('ArrowLeft', decrementLevel);
  useKeyPressEvent('a', decrementLevel);
  useKeyPressEvent('A', decrementLevel);
  useKeyPressEvent('r', () => setLevel(initialThreshold));
  return <div data-sentry-component="ImageThreshold" data-sentry-source-file="image-threshold.tsx">
      <div className="flex space-x-2">
        <Body data-sentry-element="Body" data-sentry-source-file="image-threshold.tsx">
          Level: <div className="inline-block min-w-[3em]">{level}</div>
        </Body>
        <HistogramSparkline data={histogram} threshold={level} data-sentry-element="HistogramSparkline" data-sentry-source-file="image-threshold.tsx" />
      </div>
      <ImageDataView pixels={thresholdedPixels} data-sentry-element="ImageDataView" data-sentry-source-file="image-threshold.tsx" />
    </div>;
};