import { useMemo } from 'react';
import { BadgeColor } from '@increase/shared/components/Badge';
import { PrestyledTableProps, Table, TableColumns } from '@increase/shared/components/Table';
import { formatISO8601Timestamp, humanize } from '@increase/shared/lib/formatting';
import { PlatformComplaintListResponseDataItem } from 'src/build/operations';
import { buildPath, ROUTES } from 'src/lib/routes';
type PlatformComplaintTableProps = PrestyledTableProps<PlatformComplaintListResponseDataItem>;
const platformComplaintSourceBadgeColors: Record<PlatformComplaintListResponseDataItem['source'], BadgeColor> = {
  customer: 'gray',
  law_enforcement: 'yellow',
  law_firm: 'yellow',
  regulator: 'yellow'
};
const defaultRowProps = (datum: PlatformComplaintListResponseDataItem) => ({
  href: buildPath(ROUTES.PLATFORM_COMPLAINTS_DETAIL, {
    platformComplaintId: datum.id
  }),
  className: 'hover:bg-main-hover cursor-pointer transition-all group'
});
export const PlatformComplaintsTable = (props: PlatformComplaintTableProps) => {
  const {
    getRowProps,
    ...rest
  } = props;
  const columns = useMemo<TableColumns<PlatformComplaintListResponseDataItem>>(() => {
    return [{
      header: 'Group',
      expand: 1,
      contents: complaint => {
        return {
          text: complaint.group_id,
          href: buildPath(ROUTES.GROUPS_DETAIL, {
            groupId: complaint.group_id
          })
        };
      }
    }, {
      header: 'Customer Name',
      expand: 1,
      contents: complaint => {
        return {
          text: complaint.name,
          textColor: 'primary',
          textWeight: 'medium'
        };
      }
    }, {
      header: 'Type',
      contents: complaint => ({
        text: humanize(complaint.complaint_type)
      })
    }, {
      header: 'Source',
      contents: complaint => ({
        text: humanize(complaint.source),
        badgeColor: platformComplaintSourceBadgeColors[complaint.source]
      })
    }, {
      header: 'Received on',
      contents: complaint => ({
        text: formatISO8601Timestamp(complaint.received_on, 'date')
      })
    }, {
      header: 'Resolved on',
      contents: complaint => ({
        text: complaint.resolved_on ? formatISO8601Timestamp(complaint.resolved_on, 'date') : 'Not resolved'
      })
    }, {
      header: 'Submitted to Increase',
      align: 'right',
      contents: log => ({
        text: formatISO8601Timestamp(log.created_at, 'date')
      })
    }];
  }, []);
  return <Table {...rest} getRowProps={getRowProps ?? defaultRowProps} columns={columns} data-sentry-element="Table" data-sentry-component="PlatformComplaintsTable" data-sentry-source-file="PlatformComplaintsTable.tsx" />;
};