import { compact } from 'lodash';
import { BadgeColor } from '@increase/shared/components/Badge';
import { ListItem, PropertyList, PropertyListProps } from '@increase/shared/components/PropertyList';
import { LateReturnRequestListResponseDataItemSubmissionsItem, LateReturnRequestListResponseDataItemSubmissionsItemStatus } from 'src/build/operations';
import { useServices } from 'src/hooks/use-services';
type LateReturnRequestSubmissionPropertyListProps = {
  lateReturnRequestSubmission: LateReturnRequestListResponseDataItemSubmissionsItem;
} & Omit<PropertyListProps, 'items'>;
const lateReturnRequestSubmissionBadgeColors: Record<LateReturnRequestListResponseDataItemSubmissionsItemStatus, BadgeColor> = {
  pending_review: 'yellow',
  pending_sending: 'blue',
  canceled: 'gray',
  rejected: 'red',
  sent: 'green'
};
export const LateReturnRequestSubmissionPropertyList = (props: LateReturnRequestSubmissionPropertyListProps) => {
  const {
    lateReturnRequestSubmission,
    ...rest
  } = props;
  const {
    operations
  } = useServices();
  return <PropertyList {...rest} items={compact<ListItem>([{
    label: 'ID',
    value: lateReturnRequestSubmission.id,
    copyable: lateReturnRequestSubmission.id
  }, {
    label: 'Status',
    value: lateReturnRequestSubmission.status,
    badgeColor: lateReturnRequestSubmissionBadgeColors[lateReturnRequestSubmission.status]
  }, {
    label: 'Created',
    value: lateReturnRequestSubmission.created_at,
    format: 'month-day-year-hour-minute-second'
  }, {
    label: 'Updated',
    value: lateReturnRequestSubmission.updated_at,
    format: 'month-day-year-hour-minute-second'
  }, {
    label: 'Authorized At',
    value: lateReturnRequestSubmission.authorized_at,
    format: 'month-day-year-hour-minute-second'
  }, {
    label: 'Authorizer Name',
    value: lateReturnRequestSubmission.authorizer_name ?? '-'
  }, {
    label: 'Authorizer Email',
    value: lateReturnRequestSubmission.authorizer_email ?? '-'
  }, {
    label: 'Authorizer Company',
    value: lateReturnRequestSubmission.authorizer_company ?? '-'
  }, {
    label: 'Authorizer IP Address',
    value: lateReturnRequestSubmission.authorizer_ip_address ?? '-'
  }, {
    label: 'Authorization Terms',
    value: lateReturnRequestSubmission.authorization_terms
  }, lateReturnRequestSubmission.additional_evidence_file_id && {
    label: 'Additional Evidence',
    value: lateReturnRequestSubmission.additional_evidence_file_id,
    href: lateReturnRequestSubmission.additional_evidence_file_id ? operations + '/api_files/' + lateReturnRequestSubmission.additional_evidence_file_id + '/view' : undefined
  }])} data-sentry-element="PropertyList" data-sentry-component="LateReturnRequestSubmissionPropertyList" data-sentry-source-file="LateReturnRequestSubmissionPropertyList.tsx" />;
};