import { compact } from 'lodash';
import { useTypedParams } from 'react-router-typesafe-routes/dom';
import { Box } from '@increase/shared/components/Box';
import { ListItem, PropertyList } from '@increase/shared/components/PropertyList';
import { SideBySide } from '@increase/shared/components/SideBySide';
import { Body, Heading } from '@increase/shared/components/Text';
import { useSecretaryOfSearchIntentGet } from 'src/hooks/reactQuery';
import { PageLayout } from '../deprecated/PageLayout';
import { BadgeColor } from '@increase/shared/components/Badge';
import { humanize } from '@increase/shared/lib/formatting';
import { EntityGetResponseSetupSubmissionStructureCorporationAnyOfCobaltIntelligenceSecretaryOfStateSearchIntentsItemOutcome as Outcome } from 'src/build/operations';
import { InternalOperatorOnly } from '../internal-operator-only';
import { ROUTES, buildPath } from 'src/lib/routes';
const badgeColor: Record<NonNullable<Outcome>, BadgeColor> = {
  found: 'green',
  not_found: 'gray'
};
export const EntitySecretaryOfStateSearchIntentDetailPage = () => {
  const {
    secretaryOfStateSearchIntentId
  } = useTypedParams(ROUTES.ENTITY_SECRETARY_OF_STATE_SEARCH_INTENT);
  const {
    data
  } = useSecretaryOfSearchIntentGet(secretaryOfStateSearchIntentId);
  if (!data) {
    return <></>;
  }
  return <InternalOperatorOnly data-sentry-element="InternalOperatorOnly" data-sentry-component="EntitySecretaryOfStateSearchIntentDetailPage" data-sentry-source-file="EntitySecretaryOfStateSearchIntentDetailPage.tsx">
      <PageLayout headline={`Secretary of State Search`} data-sentry-element="PageLayout" data-sentry-source-file="EntitySecretaryOfStateSearchIntentDetailPage.tsx">
        <SideBySide mode="rightDrawer" right={<Box>
              <Heading>Details</Heading>
              <PropertyList items={compact<ListItem>([{
          label: 'ID',
          value: data.id,
          copyable: data.id
        }, {
          label: 'Outcome',
          value: data.outcome ? humanize(data.outcome) : 'N/A',
          badgeColor: badgeColor[data.outcome ?? 'not_found']
        }, {
          label: 'Name',
          value: data.submitted_name
        }, {
          label: 'Incorporation State',
          value: data.submitted_state ?? '-'
        }, {
          label: 'Address State',
          value: data.submitted_address_state
        }, {
          label: 'Search Strategy',
          value: humanize(data.search_strategy)
        }, {
          label: 'Created',
          value: data.created_at,
          format: 'date'
        }, {
          label: 'Searchable Submission ID',
          value: data.searchable_submission_id,
          copyable: data.searchable_submission_id
        }, {
          label: 'Entity ID',
          value: data.entity_id,
          href: buildPath(ROUTES.ENTITY, {
            entityId: data.entity_id
          })
        }])} />
            </Box>} left={<Box>
              <Heading>Raw Response</Heading>
              <Box className="p-4">
                {data.search_response ? <Body weight="mono">
                    <pre>
                      {JSON.stringify(JSON.parse(data.search_response.response_body), null, 2)}
                    </pre>
                  </Body> : <Body weight="mono">{'Empty'}</Body>}
              </Box>
            </Box>} data-sentry-element="SideBySide" data-sentry-source-file="EntitySecretaryOfStateSearchIntentDetailPage.tsx" />
      </PageLayout>
    </InternalOperatorOnly>;
};