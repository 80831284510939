import React, { useEffect, useState } from 'react';
import { QueryClientProvider, QueryClient } from '@tanstack/react-query';
import { Authentication } from './auth/authentication';
import axios from 'axios';
import { useServices } from '../hooks/use-services';
import { BrowserRouter } from 'react-router-dom';
const networkMode = () => process.env.NODE_ENV === 'production' ? 'online' : 'always';
const defaultQueryClient = new QueryClient({
  defaultOptions: {
    queries: {
      networkMode: networkMode(),
      retry: false
    },
    mutations: {
      networkMode: networkMode()
    }
  }
});
export const Root = () => {
  const [loaded, setLoaded] = useState(false);
  const {
    operations
  } = useServices();
  useEffect(() => {
    const baseURLInterceptor = axios.interceptors.request.use(config => ({
      baseURL: operations,
      withCredentials: true,
      ...config
    }));
    setLoaded(true);
    return () => {
      axios.interceptors.request.eject(baseURLInterceptor);
    };
  }, [operations, setLoaded]);
  return <QueryClientProvider client={defaultQueryClient} data-sentry-element="QueryClientProvider" data-sentry-component="Root" data-sentry-source-file="root.tsx">
      {loaded && <BrowserRouter>
          <Authentication />
        </BrowserRouter>}
    </QueryClientProvider>;
};