import { compact } from 'lodash';
import { ListItem, PropertyList, PropertyListProps } from '@increase/shared/components/PropertyList';
import { FedwireInboundServiceMessageGetResponse } from 'src/build/operations';
type FedwireServiceMessagePropertyListProps = {
  fedwireServiceMessage: FedwireInboundServiceMessageGetResponse;
} & Omit<PropertyListProps, 'items'>;
export const FedwireServiceMessagePropertyList = (props: FedwireServiceMessagePropertyListProps) => {
  const {
    fedwireServiceMessage,
    ...rest
  } = props;
  return <PropertyList {...rest} items={compact<ListItem>([{
    label: 'ID',
    value: fedwireServiceMessage.id,
    copyable: fedwireServiceMessage.id
  }, {
    label: 'Status',
    value: fedwireServiceMessage.review_status
  }, {
    label: 'Received On',
    value: fedwireServiceMessage.received_on,
    format: 'date'
  }])} data-sentry-element="PropertyList" data-sentry-component="FedwireServiceMessagePropertyList" data-sentry-source-file="FedwireServiceMessagePropertyList.tsx" />;
};