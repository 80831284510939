import { useCallback, useState } from 'react';
import { addText, imageDataToCanvasImageSource } from '../../lib/image-utility';
import { useKeyPressEvent } from 'react-use';
import _ from 'lodash';
import { ImageToolInner, Point } from './image-tool';
type Props = {
  undoToPixels: ImageData;
  pixels: ImageData;
  onPixelsChange: (imageData: ImageData) => void;
};
const endorsement = {
  text: 'For Deposit Only',
  font: '42px Arial'
};
const initialProposedPoint = (pixels: ImageData): Point => {
  return {
    x: pixels.width * 0.85,
    y: pixels.height / 2
  };
};
const nudgePixels = (shiftKey: boolean) => shiftKey ? 1 : 10;
export const EndorsementStampingTool = ({
  undoToPixels,
  pixels,
  onPixelsChange
}: Props) => {
  const [proposedPoint, setProposedPoint] = useState<Point | null>(initialProposedPoint(pixels));
  const rendered = proposedPoint ? addText(pixels, endorsement.text, endorsement.font, 'stroke', 'red', proposedPoint) : pixels;
  const endorse = useCallback((point: Point) => {
    setProposedPoint(null);
    const withText = addText(pixels, endorsement.text, endorsement.font, 'fill', 'black', point);
    onPixelsChange(withText);
  }, [pixels, onPixelsChange]);
  const moveProposedPointUpOrDown = useCallback((dx: number) => {
    if (!proposedPoint) {
      return;
    }
    setProposedPoint({
      x: _.clamp(proposedPoint.x + dx, 0, pixels.width),
      y: proposedPoint.y
    });
  }, [pixels.width, proposedPoint]);
  useKeyPressEvent('z', () => onPixelsChange(undoToPixels));
  useKeyPressEvent('e', () => proposedPoint && endorse(proposedPoint));
  useKeyPressEvent('k', e => moveProposedPointUpOrDown(nudgePixels(e.shiftKey)));
  useKeyPressEvent('K', e => moveProposedPointUpOrDown(nudgePixels(e.shiftKey)));
  useKeyPressEvent('j', e => moveProposedPointUpOrDown(-nudgePixels(e.shiftKey)));
  useKeyPressEvent('J', e => moveProposedPointUpOrDown(-nudgePixels(e.shiftKey)));
  return <div data-sentry-component="EndorsementStampingTool" data-sentry-source-file="endorsement-stamping-tool.tsx">
      <ImageToolInner imageSource={imageDataToCanvasImageSource(rendered)} selectionMode="point" onPointSelected={endorse} onHoveredPointChanged={setProposedPoint} data-sentry-element="ImageToolInner" data-sentry-source-file="endorsement-stamping-tool.tsx" />
    </div>;
};