import { Popover, Transition } from '@headlessui/react';
import classNames from 'classnames';
import { Fragment, ReactNode, FC } from 'react';
const transitionStyles = {
  enter: 'transition ease-out duration-200 transform sm:origin-top-right',
  enterFrom: 'opacity-0 sm:scale-[.97] sm:translate-y-0 translate-y-full',
  enterTo: 'opacity-100 scale-100 sm:translate-y-0',
  leave: 'transition ease-out duration-200 transform sm:origin-top-right',
  leaveFrom: 'opacity-100 scale-100 sm:translate-y-0',
  leaveTo: 'opacity-0 sm:scale-[.97] sm:translate-y-0 translate-y-full'
};
const popoverStyles = 'fixed bottom-0 top-auto left-0 right-0 sm:absolute z-10 sm:top-full sm:bottom-auto mt-2 sm:w-max sm:min-w-[200px] rounded-lg sm:rounded border border-strong bg-subtle shadow-bottomsheet sm:shadow-2xl transition-all';
type MenuProps = {
  align?: 'left' | 'right';
  button: ReactNode;
  children: ReactNode;
};
export const Menu: FC<MenuProps> = ({
  button,
  children,
  align = 'right'
}) => {
  return <Popover as="div" className="relative" data-sentry-element="Popover" data-sentry-component="Menu" data-sentry-source-file="Menu.tsx">
      <Popover.Button as="div" data-sentry-element="unknown" data-sentry-source-file="Menu.tsx">{button}</Popover.Button>
      <Transition as={Fragment} {...transitionStyles} data-sentry-element="Transition" data-sentry-source-file="Menu.tsx">
        <Popover.Panel className={classNames(popoverStyles, align === 'left' ? 'sm:right-auto sm:left-0' : 'sm:right-0 sm:left-auto')} data-sentry-element="unknown" data-sentry-source-file="Menu.tsx">
          {children}
        </Popover.Panel>
      </Transition>
    </Popover>;
};