import { OperatorNoteListResponseDataItemOperatorBank } from 'src/build/operations';

const FALLBACK_ICON = '/assets/img/increase-icon.png';

export const getBankIconString = (
  bank: OperatorNoteListResponseDataItemOperatorBank
): string => {
  if (!bank) {
    return FALLBACK_ICON;
  }

  return {
    first_internet_bank: '/assets/img/fib-icon.png',
    blue_ridge_bank: '/assets/img/brb-icon.png',
    grasshopper_bank: '/assets/img/grasshopper-icon.png',
    global_innovations_bank: FALLBACK_ICON,
    core_bank: '/assets/img/core-bank-icon.png',
  }[bank];
};
