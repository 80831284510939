import React, { useMemo } from 'react';
import { PageLayout } from '../deprecated/PageLayout';
import { Loading } from '@increase/shared/components/Loading';
import { PropertyList } from '@increase/shared/components/PropertyList';
import { useTypedParams } from 'react-router-typesafe-routes/dom';
import { buildPath, ROUTES } from 'src/lib/routes';
import { useFinancialCrimesEnforcementNetworkThreeFourteenAEntryEntityMatchListInfinite, useFinancialCrimesEnforcementNetworkThreeFourteenAEntryEntityMatchPatch, useFinancialCrimesEnforcementNetworkThreeFourteenABusinessGet } from 'src/hooks/reactQuery';
import { compact } from 'lodash';
import { OperatorNotes } from '../operator-notes';
import { humanize } from 'shared/lib/formatting';
import { financialCrimesEnforcementNetworkThreeFourteenAEntryStatusBadgeColor } from './FinancialCrimesEnforcementNetworkThreeFourteenAFileDetailPage';
import { FinancialCrimesEnforcementNetworkThreeFourteenAEntryEntityMatchListResponseDataItem } from 'src/build/operations';
import { PrestyledTableProps, Table, TableColumns } from 'shared/components/Table';
import { TableStateWrapper } from 'shared/components/TableStateWrapper';
import { Button } from 'shared/components/Button';
import { Box } from 'shared/components/Box';
type Props = PrestyledTableProps<FinancialCrimesEnforcementNetworkThreeFourteenAEntryEntityMatchListResponseDataItem>;
const badgeColor = {
  pending_reviewing: 'yellow',
  false_positive: 'green',
  true_match: 'red',
  requires_attention: 'yellow'
};
const Buttons = (props: {
  item: FinancialCrimesEnforcementNetworkThreeFourteenAEntryEntityMatchListResponseDataItem;
}) => {
  const mutate = useFinancialCrimesEnforcementNetworkThreeFourteenAEntryEntityMatchPatch();
  return <Box flex data-sentry-element="Box" data-sentry-component="Buttons" data-sentry-source-file="FinancialCrimesEnforcementNetworkThreeFourteenABusinessDetailPage.tsx">
      <Button disabled={props.item.status !== 'pending_reviewing'} size="small" style="primary" text="False Positive" onClick={() => mutate.mutateAsync([props.item.id, {
      status: 'false_positive'
    }])} data-sentry-element="Button" data-sentry-source-file="FinancialCrimesEnforcementNetworkThreeFourteenABusinessDetailPage.tsx" />
      <Button disabled={props.item.status !== 'pending_reviewing'} size="small" style="warning" text="True Match" onClick={() => mutate.mutateAsync([props.item.id, {
      status: 'true_match'
    }])} data-sentry-element="Button" data-sentry-source-file="FinancialCrimesEnforcementNetworkThreeFourteenABusinessDetailPage.tsx" />
    </Box>;
};
const ThreeFourteenAMatchesTable = ({
  ...rest
}: Props) => {
  const columns = useMemo<TableColumns<FinancialCrimesEnforcementNetworkThreeFourteenAEntryEntityMatchListResponseDataItem>>(() => {
    return [{
      header: 'Entity',
      contents: match => ({
        text: match.entity_name,
        textWeight: 'medium',
        textColor: 'emphasis'
      })
    }, {
      header: 'Through',
      contents: () => ({
        text: 'Company'
      })
    }, {
      header: 'Name',
      contents: match => ({
        text: match.entity_id,
        href: buildPath(ROUTES.ENTITY, {
          entityId: match.entity_id
        })
      })
    }, {
      header: 'Status',
      contents: entry => ({
        text: humanize(entry.status),
        badgeColor: badgeColor[entry.status]
      }),
      expand: 1
    }, {
      header: 'Actions',
      CellComponent: ({
        datum
      }) => {
        return <Buttons item={datum} />;
      }
    }];
  }, []);
  const defaultRowProps = (_datum: FinancialCrimesEnforcementNetworkThreeFourteenAEntryEntityMatchListResponseDataItem) => ({
    className: 'hover:bg-main-hover cursor-pointer transition-all group'
  });
  return <Table<FinancialCrimesEnforcementNetworkThreeFourteenAEntryEntityMatchListResponseDataItem> columns={columns} getRowProps={defaultRowProps} {...rest} data-sentry-element="Table" data-sentry-component="ThreeFourteenAMatchesTable" data-sentry-source-file="FinancialCrimesEnforcementNetworkThreeFourteenABusinessDetailPage.tsx" />;
};
export const FinancialCrimesEnforcementNetworkThreeFourteenABusinessDetailPage = () => {
  const {
    entryId
  } = useTypedParams(ROUTES.FINANCIAL_CRIMES_ENFORCEMENT_NETWORK_THREE_FOURTEEN_A_BUSINESS_DETAIL);
  const {
    data
  } = useFinancialCrimesEnforcementNetworkThreeFourteenABusinessGet(entryId);
  const listResult = useFinancialCrimesEnforcementNetworkThreeFourteenAEntryEntityMatchListInfinite({
    entry_id: entryId
  });
  if (!data) {
    return <Loading />;
  }
  return <PageLayout headline={data.tracking_number} data-sentry-element="PageLayout" data-sentry-component="FinancialCrimesEnforcementNetworkThreeFourteenABusinessDetailPage" data-sentry-source-file="FinancialCrimesEnforcementNetworkThreeFourteenABusinessDetailPage.tsx">
        <PropertyList title="Details from FinCEN" items={[{
      label: 'Tracking number',
      value: data.tracking_number
    }, {
      label: 'Business name',
      value: data.business_name || ''
    }, {
      label: 'Doing Business As Name',
      value: data.doing_business_as_name || ''
    }, {
      label: 'Number',
      value: data.number || ''
    }, {
      label: 'Number type',
      value: data.number_type || ''
    }, {
      label: 'Incorporated',
      value: data.incorporated || ''
    }, {
      label: 'Address',
      value: compact([data.street, data.city, data.state, data.zip, data.country]).join(', ')
    }, {
      label: 'Phone',
      value: data.phone || ''
    }, {
      label: 'Electronic address',
      value: data.electronic_address || ''
    }, {
      label: 'Electronic address type',
      value: data.electronic_address_type || ''
    }]} data-sentry-element="PropertyList" data-sentry-source-file="FinancialCrimesEnforcementNetworkThreeFourteenABusinessDetailPage.tsx" />

        <PropertyList title="Matching information" items={[{
      label: 'Status',
      value: humanize(data.status),
      badgeColor: financialCrimesEnforcementNetworkThreeFourteenAEntryStatusBadgeColor(data.status)
    }]} data-sentry-element="PropertyList" data-sentry-source-file="FinancialCrimesEnforcementNetworkThreeFourteenABusinessDetailPage.tsx" />

        <TableStateWrapper table={ThreeFourteenAMatchesTable} {...listResult} style="primary" filters={[]} emptyTitle="No matches" emptySubtitle="There are no matches for this FinCEN entry." data-sentry-element="TableStateWrapper" data-sentry-source-file="FinancialCrimesEnforcementNetworkThreeFourteenABusinessDetailPage.tsx" />

        <OperatorNotes modelId={data.id} data-sentry-element="OperatorNotes" data-sentry-source-file="FinancialCrimesEnforcementNetworkThreeFourteenABusinessDetailPage.tsx" />
      </PageLayout>;
};