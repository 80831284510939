import { useSingleSignOnSessionPost } from 'src/hooks/reactQuery';
import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTypedSearchParams } from 'react-router-typesafe-routes/dom';
import { ROUTES } from 'src/lib/routes';
import { decodeRedirectState } from 'shared/lib/singleSignOn';
import { fromPairs, toPairs } from 'lodash';
const buildUrl = (path: string, params: Record<string, string | null>): string => {
  const cleaned = fromPairs(toPairs(params).filter((x): x is [string, string] => !!x[1]));
  const args = new URLSearchParams(cleaned).toString();
  return args ? `${path}?${args}` : path;
};
export const SingleSignOnCallback = () => {
  const singleSignOnSessionPost = useSingleSignOnSessionPost();
  const [{
    code,
    state,
    error_description: errorDescription
  }] = useTypedSearchParams(ROUTES.AUTHENTICATION_CALLBACK);
  const navigate = useNavigate();
  const isMountedRef = useRef(false);
  const [sessionPosting, setSessionPosting] = useState(false);
  useEffect(() => {
    if (isMountedRef.current) {
      return;
    }
    isMountedRef.current = true;
    const redirectToSignInWithError = (errorReason: string, redirectUrl: string | null = null) => {
      const url = buildUrl('/', {
        single_sign_on_error_reason: errorReason,
        redirect: redirectUrl
      });
      navigate(url, {
        replace: true
      });
    };
    if (code && !sessionPosting) {
      const decodedState = state ? decodeRedirectState(state) : undefined;
      setSessionPosting(true);
      singleSignOnSessionPost.mutateAsync([{
        code,
        state: state ? decodeURIComponent(state) : ''
      }]).then(() => {
        const url = decodedState?.nextUrl || '';
        navigate(`/${url}`, {
          replace: true
        });
      }).catch(err => {
        const data = err.response?.data;
        redirectToSignInWithError(data.message, decodedState?.nextUrl || null);
      });
    }
    if (!sessionPosting && errorDescription) {
      redirectToSignInWithError(errorDescription);
    }
  }, [code, sessionPosting, singleSignOnSessionPost, navigate, state, errorDescription]);
  return <div data-sentry-component="SingleSignOnCallback" data-sentry-source-file="SingleSignOnCallback.tsx">
      {code ? 'Redirecting..' : errorDescription || 'Invalid URL. Try again!'}
    </div>;
};