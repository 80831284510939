import classNames from 'classnames';
import { useIsLoaded } from '../../hooks/useIsLoaded';
import { ReactNode, useEffect, useRef } from 'react';
import { Body } from '../Text';
import { WarningNumber } from '../WarningNumber';
import styles from './index.module.css';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
type Props = {
  segments: readonly ({
    title: string;
    count?: number;
    selected: boolean;
  } & ({
    onSelect: () => void;
  } | {
    href: string;
  }))[];
  actionRight?: ReactNode;
  style?: 'default' | 'ghost';
};
export const SegmentedControl = ({
  segments,
  actionRight,
  style = 'default'
}: Props) => {
  const controlRef = useRef<HTMLDivElement>(null);
  const segmentRefs = useRef<(HTMLDivElement | null)[]>([]);
  useEffect(() => {
    segmentRefs.current = segmentRefs.current.slice(0, segments.length);
  }, [segments]);
  const loaded = useIsLoaded();
  const selectedIndex = segments.findIndex(x => x.selected);
  const navigate = useNavigate();
  useEffect(() => {
    const currentSegmentRef = segmentRefs.current[selectedIndex];
    const adjustOffsetWidth = () => {
      if (currentSegmentRef) {
        const {
          offsetWidth,
          offsetLeft
        } = currentSegmentRef;
        const selectedStyle = controlRef.current?.style;
        selectedStyle?.setProperty('--highlight-width', `${offsetWidth}px`);
        selectedStyle?.setProperty('--highlight-x-pos', `${offsetLeft}px`);
      }
    };
    adjustOffsetWidth();
    window.addEventListener('resize', adjustOffsetWidth);
    return () => window.removeEventListener('resize', adjustOffsetWidth);
  }, [segments, selectedIndex]);
  return <div className={classNames(styles['controls-container'], 'relative flex min-w-0 sm:-mx-6 md:mx-0 md:w-full md:py-0 md:before:hidden md:after:hidden', 'border-main -mx-4 w-screen border-y py-2', 'before:absolute before:inset-y-0 before:left-0 before:z-20 before:w-4 before:bg-gradient-to-r before:to-transparent', 'after:absolute after:inset-y-0 after:right-0 after:z-20 after:w-4 after:bg-gradient-to-l after:to-transparent', style === 'ghost' ? 'before:from-main after:from-main bg-transparent md:border-none' : 'bg-stronger before:from-stronger after:from-stronger dark:bg-subtle dark:before:from-subtle dark:after:from-subtle md:border-none md:bg-transparent dark:md:bg-transparent')} ref={controlRef} data-sentry-component="SegmentedControl" data-sentry-source-file="index.tsx">
      <div className={classNames(styles.controls, loaded && styles.ready, 'no-scrollbar relative m-auto inline-flex w-full items-center justify-between overflow-x-scroll px-4 whitespace-nowrap transition sm:px-6 md:overflow-visible md:px-0', 'before:absolute before:inset-0 before:rounded', style === 'ghost' ? 'before:bg-main-selected' : 'before:card-xs before:border-strong dark:before:bg-main-selected')}>
        <div className={classNames('inline-flex justify-start gap-1 md:rounded', style === 'default' && 'md:border-main md:bg-stronger dark:md:bg-main md:border')}>
          {segments.map((item, i) => {
          const body = <div key={item.title} className={classNames('relative z-[1] rounded px-2 text-center', 'focus-visible:ring-main cursor-pointer focus-visible:ring-[3px] focus-visible:outline-none', item.selected ? 'transition-colors md:hover:bg-transparent' : 'md:hover:bg-main-hover')} ref={el => segmentRefs.current[i] = el} tabIndex={0} onClick={'onSelect' in item ? item.onSelect : undefined} onKeyDown={e => {
            if (e.key === 'Enter') {
              if ('href' in item) {
                navigate(item.href);
              } else {
                item.onSelect();
              }
            }
          }}>
                <div className={classNames('pointer-events-none relative block h-[28px] cursor-pointer py-1 transition-colors', item.selected ? 'text-strong' : 'text-subtle')}>
                  <div className="flex flex-row gap-2 select-none">
                    <Body color="inherit" contents={item.title} weight="medium" />
                    {item.count != null && <WarningNumber count={item.count} />}
                  </div>
                </div>
              </div>;
          if ('href' in item) {
            return <Link to={item.href} key={item.title}>
                  {body}
                </Link>;
          } else {
            return body;
          }
        })}
        </div>
        {actionRight && <div className="px-3">{actionRight}</div>}
      </div>
    </div>;
};