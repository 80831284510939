import { useCustomerIdentificationProgramTestingBatchGet, useCustomerIdentificationProgramTestingBatchSummaryGet } from 'src/hooks/reactQuery';
import { PageLayout } from '../deprecated/PageLayout';
import { SideBySide } from '@increase/shared/components/SideBySide';
import { Loading } from '@increase/shared/components/Loading';
import { PropertyList } from '@increase/shared/components/PropertyList';
import { formatInteger } from '@increase/shared/lib/formatting';
import { Table } from '@increase/shared/components/Table';
import { Box } from '@increase/shared/components/Box';
import { OperatorNotes } from '../operator-notes';
import { useTypedParams } from 'react-router-typesafe-routes/dom';
import { ROUTES, buildPath } from 'src/lib/routes';
import { AssociatedManualTasks } from '../associated-manual-tasks';
import { formatBank } from 'src/lib/formatEnums';
export const CustomerIdentificationProgramTestingBatchDetailPage = () => {
  const {
    batchId
  } = useTypedParams(ROUTES.CUSTOMER_IDENTIFICATION_PROGRAM_TESTING_BATCHES_DETAILS);
  const {
    data: batch
  } = useCustomerIdentificationProgramTestingBatchGet(batchId);
  const {
    data: summary
  } = useCustomerIdentificationProgramTestingBatchSummaryGet(batchId);
  if (!batch || !summary) {
    return <Loading />;
  }
  return <PageLayout headline={batch.period_start + ' through ' + batch.period_end} data-sentry-element="PageLayout" data-sentry-component="CustomerIdentificationProgramTestingBatchDetailPage" data-sentry-source-file="CustomerIdentificationProgramTestingBatchDetailPage.tsx">
      <SideBySide mode="rightDrawer" right={<>
            <PropertyList title="Information" items={[{
        label: 'Bank',
        value: formatBank(batch.bank)
      }, {
        label: 'Period start',
        value: batch.period_start,
        format: 'date'
      }, {
        label: 'Period end',
        value: batch.period_end,
        format: 'date'
      }, {
        label: 'Status',
        value: batch.status
      }, {
        label: 'Entries',
        value: 'View all',
        href: buildPath(ROUTES.CUSTOMER_IDENTIFICATION_PROGRAM_TESTING_ENTRIES_LIST, {}, {
          batch_id: [batchId]
        })
      }]} />
            <AssociatedManualTasks objectId={batch.id} />
          </>} left={<Box>
            <OperatorNotes modelId={batch.id} />
            <Table data={summary.bank_programs} columns={[{
        header: 'Bank program',
        contents: d => ({
          text: d.name,
          href: buildPath(ROUTES.BANK_PROGRAMS_DETAIL, {
            bankProgramId: d.id
          })
        })
      }, {
        header: 'Total sampled',
        contents: d => ({
          text: formatInteger(d.initial_counts.invalid + d.initial_counts.indeterminate + d.initial_counts.valid)
        })
      }, {
        header: 'Pending user remediation',
        contents: d => ({
          text: formatInteger(d.count_pending)
        })
      }, {
        header: 'Details',
        contents: d => ({
          text: 'view',
          href: buildPath(ROUTES.CUSTOMER_IDENTIFICATION_PROGRAM_TESTING_ENTRIES_LIST, {}, {
            batch_id: [batchId],
            bank_program: [d.id]
          })
        })
      }]} />
          </Box>} data-sentry-element="SideBySide" data-sentry-source-file="CustomerIdentificationProgramTestingBatchDetailPage.tsx" />
    </PageLayout>;
};