import React, { useMemo } from 'react';
import { PageLayout } from '../deprecated/PageLayout';
import { useFinancialCrimesEnforcementNetworkThreeFourteenAFileListInfinite, useFinancialCrimesEnforcementNetworkThreeFourteenAFilePost } from 'src/hooks/reactQuery';
import { buildPath, ROUTES } from 'src/lib/routes';
import { PrestyledTableProps, TableColumns, Table } from 'shared/components/Table';
import { formatInteger, formatISO8601Timestamp, humanize } from 'shared/lib/formatting';
import { FinancialCrimesEnforcementNetworkThreeFourteenAFileListResponseDataItem } from 'src/build/operations';
import { TableStateWrapper } from 'shared/components/TableStateWrapper';
import { useDropzone } from 'react-dropzone';
import { RequireEntitlements } from '../require-entitlements';
import { Button } from 'shared/components/Button';
type Props = PrestyledTableProps<FinancialCrimesEnforcementNetworkThreeFourteenAFileListResponseDataItem>;
export const ThreeFourteenAFilesTable = ({
  ...rest
}: Props) => {
  const columns = useMemo<TableColumns<FinancialCrimesEnforcementNetworkThreeFourteenAFileListResponseDataItem>>(() => {
    return [{
      header: 'Name',
      contents: file => ({
        text: file.inbound_file_transmission_name,
        textWeight: 'medium',
        textColor: 'emphasis'
      })
    }, {
      header: 'ID',
      contents: file => ({
        text: file.id
      })
    }, {
      header: 'Status',
      contents: file => ({
        text: humanize(file.status),
        badgeColor: {
          pending: 'yellow',
          completed: 'green'
        }[file.status]
      })
    }, {
      header: 'Count',
      expand: 1,
      contents: file => ({
        text: formatInteger(file.entries_count)
      })
    }, {
      header: 'Created at',
      contents: file => ({
        text: formatISO8601Timestamp(file.created_at, 'month-day-year-hour-minute-second')
      })
    }];
  }, []);
  const defaultRowProps = (datum: FinancialCrimesEnforcementNetworkThreeFourteenAFileListResponseDataItem) => ({
    href: buildPath(ROUTES.FINANCIAL_CRIMES_ENFORCEMENT_NETWORK_THREE_FOURTEEN_A_FILE_DETAIL, {
      fileId: datum.id
    }),
    className: 'hover:bg-main-hover cursor-pointer transition-all group'
  });
  return <Table<FinancialCrimesEnforcementNetworkThreeFourteenAFileListResponseDataItem> columns={columns} getRowProps={defaultRowProps} {...rest} data-sentry-element="Table" data-sentry-component="ThreeFourteenAFilesTable" data-sentry-source-file="FinancialCrimesEnforcementNetworkThreeFourteenAFileListPage.tsx" />;
};
export const FinancialCrimesEnforcementNetworkThreeFourteenAFileListPage = () => {
  const listResults = useFinancialCrimesEnforcementNetworkThreeFourteenAFileListInfinite({});
  const filePost = useFinancialCrimesEnforcementNetworkThreeFourteenAFilePost();
  const createFile = (file: File) => {
    filePost.mutateAsync([{
      file
    }]);
  };
  const {
    getInputProps,
    getRootProps
  } = useDropzone({
    onDrop: acceptedFiles => createFile(acceptedFiles[0])
  });
  return <PageLayout headline={'All FinCEN 314(a) uploaded lists'} data-sentry-element="PageLayout" data-sentry-component="FinancialCrimesEnforcementNetworkThreeFourteenAFileListPage" data-sentry-source-file="FinancialCrimesEnforcementNetworkThreeFourteenAFileListPage.tsx">
        <input {...getInputProps()} />

        <TableStateWrapper table={ThreeFourteenAFilesTable} {...listResults} action={<RequireEntitlements entitlements={['three_fourteen_a_manage']}>
              <Button onClick={getRootProps().onClick} icon="arrow_up" text={filePost.data ? 'Working...' : 'Upload'} disabled={!!filePost.data} />
            </RequireEntitlements>} style="primary" filters={[]} emptyTitle="No FinCEN 314(a) uploaded lists" emptySubtitle="There are no FinCEN 314(a) uploaded lists to display" data-sentry-element="TableStateWrapper" data-sentry-source-file="FinancialCrimesEnforcementNetworkThreeFourteenAFileListPage.tsx" />
      </PageLayout>;
};