import classNames from 'classnames';
import React, { ReactNode } from 'react';
import { Link as ReactRouterLink } from 'react-router-dom';
type LinkProps = {
  href: string;
  children: ReactNode;
  className?: string;
  target?: '_blank';
  style?: 'underline' | 'bold';
  suppressFocus?: boolean;
  onClick?: () => void;
};
const isHrefExternal = (href: string): boolean => {
  return !href.startsWith('/');
};
export const StyledLink = ({
  href,
  target,
  className,
  children,
  style,
  suppressFocus,
  onClick
}: LinkProps) => {
  const classNameInner = classNames('focus:outline-hidden', !suppressFocus && 'focus-visible:ring-main focus-visible:ring-[3px] focus-visible:ring-offset-[1px]', style === 'underline' && 'underline underline-offset-2 decoration-dotted hover:decoration-solid hover:bg-strong-hover rounded-sm', style === 'bold' && 'font-[500] hover:underline', className);
  const rel = target ? 'noreferrer' : undefined;
  return isHrefExternal(href) ? <a href={href} className={classNameInner} target={target} rel={rel} tabIndex={suppressFocus ? -1 : 0} {...href.startsWith('data') && {
    download: 'data'
  }} data-sentry-component="StyledLink" data-sentry-source-file="StyledLink.tsx">
      {children}
    </a> : <ReactRouterLink to={href} className={classNameInner} target={target} rel={rel} tabIndex={suppressFocus ? -1 : 0} onClick={onClick} data-sentry-element="ReactRouterLink" data-sentry-component="StyledLink" data-sentry-source-file="StyledLink.tsx">
      {children}
    </ReactRouterLink>;
};