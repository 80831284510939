import { useFedwireInboundServiceMessagesGet } from 'src/hooks/reactQuery';
import { PageLayout } from '../deprecated/PageLayout';
import { OperatorNotes } from '../operator-notes';
import { useTypedParams } from 'react-router-typesafe-routes/dom';
import { ROUTES } from 'src/lib/routes';
import { FedwireServiceMessagePropertyList } from '../property-lists/FedwireServiceMessagePropertyList';
import { splitOnFedwireTags } from 'src/lib/fedwire-utility';
import { Heading } from 'shared/components/Text';
export const FedwireInboundServiceMessageDetailPage = () => {
  const {
    serviceMessageId
  } = useTypedParams(ROUTES.FEDWIRE_INBOUND_SERVICE_MESSAGES_DETAIL);
  const {
    data: serviceMessage
  } = useFedwireInboundServiceMessagesGet(serviceMessageId);
  if (!serviceMessage) {
    return <h3>Loading</h3>;
  }
  return <PageLayout headline={'Fedwire Service Message'} data-sentry-element="PageLayout" data-sentry-component="FedwireInboundServiceMessageDetailPage" data-sentry-source-file="FedwireInboundServiceMessageDetailPage.tsx">
      <FedwireServiceMessagePropertyList fedwireServiceMessage={serviceMessage} data-sentry-element="FedwireServiceMessagePropertyList" data-sentry-source-file="FedwireInboundServiceMessageDetailPage.tsx" />

      <Heading size="small" data-sentry-element="Heading" data-sentry-source-file="FedwireInboundServiceMessageDetailPage.tsx">Body</Heading>
      <div className="bg-strong p-2">
        <pre className="grow overflow-scroll font-mono text-sm">
          {splitOnFedwireTags(serviceMessage.message_body)}
        </pre>
      </div>

      <OperatorNotes modelId={serviceMessage.id} data-sentry-element="OperatorNotes" data-sentry-source-file="FedwireInboundServiceMessageDetailPage.tsx" />
    </PageLayout>;
};