import { ListItem } from '@increase/shared/components/PropertyList';
import { useAccountGet, useGroupGet, useProgramGet } from 'src/hooks/reactQuery';
import { buildPath, ROUTES } from './routes';
import { Body } from 'shared/components/Text';
import { StyledLink } from 'shared/components/StyledLink';
import { Badge } from 'shared/components/Badge';
import { Box } from 'shared/components/Box';
import { humanize } from 'shared/lib/formatting';
export const useGroupPropertyRow = (groupId: string | undefined): ListItem => {
  const {
    data
  } = useGroupGet(groupId || '', {
    enabled: !!groupId
  });
  if (!groupId || !data) {
    return {
      label: 'Group',
      value: ''
    };
  }
  const name = data.name || data.friendly_nickname;
  return data.locked ? {
    label: 'Group',
    valueNode: <Box flex gap="6">
            <StyledLink href={buildPath(ROUTES.GROUPS_DETAIL, {
        groupId
      })} style="underline">
              <Body color="primary">{name}</Body>
            </StyledLink>
            <Badge color="red" label={humanize(data.status)} icon="lock" />
          </Box>
  } : {
    label: 'Group',
    value: name,
    href: buildPath(ROUTES.GROUPS_DETAIL, {
      groupId
    })
  };
};
export const useProgramPropertyRow = (programId: string | undefined): ListItem => {
  const {
    data
  } = useProgramGet(programId || '', {
    enabled: !!programId
  });
  if (!data || !programId) {
    return {
      label: 'program',
      value: ''
    };
  }
  return {
    label: 'Program',
    value: data.name,
    href: buildPath(ROUTES.PROGRAMS_DETAIL, {
      programId
    })
  };
};
export const useAccountPropertyRow = (accountId: string | undefined): ListItem => {
  const {
    data
  } = useAccountGet(accountId || '', {
    enabled: !!accountId
  });
  if (!data || !accountId) {
    return {
      label: 'account',
      value: ''
    };
  }
  return {
    label: 'Account',
    value: data.name,
    href: buildPath(ROUTES.ACCOUNTS_DETAIL, {
      accountId
    })
  };
};