import React from 'react';
import { Body, Caption } from './Text';
import { DateFormat, formatISO8601Timestamp } from '../lib/formatting';
import { Tooltip } from './Tooltip';
function getLocalTimezoneAbbr(): string {
  const str = new Date().toString(); // e.g. "Tue Mar 31 2025 12:00:00 GMT-0400 (EDT)"
  const match = str.match(/\(([^)]+)\)/);
  if (match) {
    const words = match[1].split(' ');
    return words.length > 1 ? words.map(w => w[0]).join('') : words[0];
  }
  return '';
}
type FormattedTimestampProps = {
  timestamp: string;
  format: DateFormat;
};
export const FormattedTimestamp = ({
  timestamp,
  format
}: FormattedTimestampProps) => {
  // Local timezone code
  const localTimezone = getLocalTimezoneAbbr();
  const inner = <div className="p-2">
      <div className="flex items-center justify-between gap-6">
        <Caption color="offset" className="dark:text-strong" contents={`${localTimezone} • Your Computer`} />
        <Caption color="disabled" className="dark:text-subtle" contents={formatISO8601Timestamp(timestamp, 'month-day-year-hour-minute-second')} />
      </div>
      <div className="flex items-center justify-between gap-3">
        <Caption color="offset" className="dark:text-strong" contents="UTC" />
        <Caption color="disabled" className="dark:text-subtle" contents={formatISO8601Timestamp(timestamp, 'month-day-year-hour-minute-second', 'UTC')} />
      </div>
    </div>;
  return <Tooltip content={inner} direction="bottom" className="max-w-full whitespace-nowrap" data-sentry-element="Tooltip" data-sentry-component="FormattedTimestamp" data-sentry-source-file="FormattedTimestamp.tsx">
      <Body color="primary" className="cursor-default" data-sentry-element="Body" data-sentry-source-file="FormattedTimestamp.tsx">
        {formatISO8601Timestamp(timestamp, format)}
      </Body>
    </Tooltip>;
};