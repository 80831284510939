import React from 'react';
import { PageLayout } from './deprecated/PageLayout';
import { useReserveAccountOverviewGet } from 'src/hooks/reactQuery';
import { Loading } from '@increase/shared/components/Loading';
import { ReserveAccountsOverviewGetResponseReserveAccountsItem } from 'src/build/operations';
import { Table, TableColumns } from '@increase/shared/components/Table';
import { formatAmount } from '@increase/shared/lib/formatting';
import { buildPath, ROUTES } from 'src/lib/routes';
import { StyledLink } from '@increase/shared/components/StyledLink';
import { Body, Heading } from '@increase/shared/components/Text';
import { Badge, BadgeColor } from '@increase/shared/components/Badge';
import { PropertyList } from 'shared/components/PropertyList';
import { InternalOperatorOnly } from './internal-operator-only';
const ProgramDetailsCell = ({
  datum: row
}: {
  datum: ReserveAccountsOverviewGetResponseReserveAccountsItem;
}) => {
  return <div data-sentry-component="ProgramDetailsCell" data-sentry-source-file="reserve-accounts.tsx">
      {row.programs.map(program => <div className="border-main m-1 flex flex-col space-y-1 rounded border p-3 text-sm" key={program.id}>
          <Body weight="bold">
            <StyledLink style="underline" href={buildPath(ROUTES.PROGRAMS_DETAIL, {
          programId: program.id
        })} suppressFocus>
              {program.id}
            </StyledLink>
          </Body>
          <Body weight="bold">{program.name}</Body>
          <Body>
            Configured minimum reserve:{' '}
            <Body className="inline" weight="bold">
              {program.minimum_expected_reserve !== null ? formatAmount(program.minimum_expected_reserve, 'USD') : 'none'}
            </Body>
          </Body>
          {program.first_internet_bank_required_reserve && <>
              <Body>
                First Internet Bank required reserve:{' '}
                <Body className="inline" weight="bold">
                  {formatAmount(program.first_internet_bank_required_reserve.required_minimum_balance, 'USD')}
                </Body>
              </Body>

              <Body>
                ↳calculated for month starting{' '}
                <Body className="inline" weight="bold">
                  {program.first_internet_bank_required_reserve.month_starting}
                </Body>
              </Body>

              <div className="flex">
                {program.minimum_expected_reserve === null ? <Badge color="red" label={'no reserve configured'} /> : program.minimum_expected_reserve < program.first_internet_bank_required_reserve.required_minimum_balance ? <Badge color="red" label={'reserve smaller than required'} /> : null}
              </div>

              <InternalOperatorOnly>
                {program.first_internet_bank_required_reserve && program.minimum_expected_reserve !== program.first_internet_bank_required_reserve.required_minimum_balance && <Body>
                      <StyledLink style="underline" href={`/manual_tasks/Operations::ManualTask::UpdateProgram?program_id=${program.id}&reserve_account_expected_minimum_balance=${program.first_internet_bank_required_reserve.required_minimum_balance}`}>
                        Manual task to update configured reserve to match First
                        Internet Bank required reserve
                      </StyledLink>
                    </Body>}
              </InternalOperatorOnly>
            </>}
        </div>)}
    </div>;
};
const BalanceCell = ({
  datum: row
}: {
  datum: ReserveAccountsOverviewGetResponseReserveAccountsItem;
}) => {
  let badge: {
    color: BadgeColor;
    label: string;
  };
  if (row.current_balance >= row.total_expected_minimum_balance) {
    badge = {
      color: 'green',
      label: 'OK'
    };
  } else {
    badge = {
      color: 'red',
      label: 'Insufficient'
    };
  }
  return <div className="flex flex-col space-y-1" data-sentry-component="BalanceCell" data-sentry-source-file="reserve-accounts.tsx">
      <div className="flex space-x-1">
        <Body className="inline" color="secondary" data-sentry-element="Body" data-sentry-source-file="reserve-accounts.tsx">
          Current:
        </Body>
        <Body className="inline" color="secondary" weight="bold" data-sentry-element="Body" data-sentry-source-file="reserve-accounts.tsx">
          {formatAmount(row.current_balance, 'USD')}
        </Body>
        <Badge size="small" color={badge.color} label={badge.label} data-sentry-element="Badge" data-sentry-source-file="reserve-accounts.tsx" />
      </div>
      <div className="flex space-x-1">
        <Body className="inline" color="secondary" data-sentry-element="Body" data-sentry-source-file="reserve-accounts.tsx">
          Required miniumum:
        </Body>
        <Body className="inline" color="secondary" weight="bold" data-sentry-element="Body" data-sentry-source-file="reserve-accounts.tsx">
          {formatAmount(row.total_expected_minimum_balance, 'USD')}
        </Body>
      </div>
    </div>;
};
export const ReserveAccountOverview = () => {
  const {
    data: overview
  } = useReserveAccountOverviewGet({});
  if (!overview) {
    return <Loading />;
  }
  const columns: TableColumns<ReserveAccountsOverviewGetResponseReserveAccountsItem> = [{
    header: 'Account',
    contents: a => ({
      text: a.id,
      textWeight: 'bold',
      textColor: 'emphasis',
      href: buildPath(ROUTES.ACCOUNTS_DETAIL, {
        accountId: a.id
      })
    })
  }, {
    header: 'Restrictions',
    contents: a => ({
      text: a.restrictions || 'none',
      badgeColor: a.restrictions === 'no_withdrawals' ? 'blue' : 'yellow'
    })
  }, {
    header: 'Group',
    contents: a => ({
      text: a.group_name || a.group_friendly_nickname,
      href: buildPath(ROUTES.GROUPS_DETAIL, {
        groupId: a.group_id
      })
    })
  }, {
    header: 'Balance',
    CellComponent: BalanceCell
  }, {
    header: 'Programs',
    CellComponent: ProgramDetailsCell
  }];
  return <PageLayout headline="Reserve Accounts" data-sentry-element="PageLayout" data-sentry-component="ReserveAccountOverview" data-sentry-source-file="reserve-accounts.tsx">
      {overview.first_internet_bank_servicer_required_reserve && <>
          <PropertyList title="First Internet Bank Servicer Required Reserve" items={[{
        label: 'Effective Date',
        value: overview.first_internet_bank_servicer_required_reserve.effective_date
      }, {
        label: 'Computed Reserve Target',
        value: formatAmount(overview.first_internet_bank_servicer_required_reserve.computed_reserve_target, 'USD')
      }, {
        label: 'Total Program Reserve Account Balance',
        value: formatAmount(overview.first_internet_bank_servicer_required_reserve.total_program_reserve_account_balance, 'USD')
      }, {
        label: 'Gap: Max(Computed Reserve Target - Total Program Reserve Account Balance, 0)',
        value: formatAmount(Math.max(overview.first_internet_bank_servicer_required_reserve.computed_reserve_target - overview.first_internet_bank_servicer_required_reserve.total_program_reserve_account_balance, 0), 'USD')
      }, {
        label: 'Required Reserve',
        value: formatAmount(overview.first_internet_bank_servicer_required_reserve.required_reserve, 'USD'),
        weight: 'bold'
      }]} />
          <Heading>Program Reserve Accounts</Heading>
        </>}
      <Table columns={columns} data={overview.reserve_accounts} data-sentry-element="Table" data-sentry-source-file="reserve-accounts.tsx" />
    </PageLayout>;
};