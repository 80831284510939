import { compact } from 'lodash';
import { BadgeColor } from '@increase/shared/components/Badge';
import { ListItem, PropertyList, PropertyListProps } from '@increase/shared/components/PropertyList';
import { formatInteger, humanize } from '@increase/shared/lib/formatting';
import { ControlConfigurationGetResponse, ControlConfigurationGetResponseResult } from 'src/build/operations';
import { useGroupPropertyRow, useProgramPropertyRow } from 'src/lib/propertyListHelpers';
type ControlConfigurationPropertyListProps = {
  controlConfiguration: ControlConfigurationGetResponse;
} & Omit<PropertyListProps, 'items'>;
const resultBadgeColor: Record<ControlConfigurationGetResponseResult, BadgeColor> = {
  success: 'green',
  failure: 'red'
};
export const ControlConfigurationPropertyList = (props: ControlConfigurationPropertyListProps) => {
  const {
    controlConfiguration,
    ...rest
  } = props;
  const groupRow = useGroupPropertyRow(controlConfiguration.group_id);
  const programRow = useProgramPropertyRow(controlConfiguration.program_id);
  return <PropertyList {...rest} items={compact<ListItem>([{
    label: 'ID',
    value: controlConfiguration.id,
    copyable: controlConfiguration.id
  }, groupRow, programRow, {
    label: 'Visibility',
    value: controlConfiguration.user_visible ? 'Visible to user' : 'Increase-only',
    badgeColor: controlConfiguration.user_visible ? 'gray' : 'yellow'
  }, {
    label: 'Category',
    value: humanize(controlConfiguration.category)
  }, {
    label: 'Latest result',
    value: humanize(controlConfiguration.result),
    badgeColor: resultBadgeColor[controlConfiguration.result]
  }, {
    label: 'Number of successes',
    value: formatInteger(controlConfiguration.number_of_successes)
  }, {
    label: 'Number of failures',
    value: formatInteger(controlConfiguration.number_of_failures)
  }, {
    label: 'Created',
    value: controlConfiguration.created_at,
    format: 'date'
  }, controlConfiguration.deleted_at && {
    label: 'Deleted at',
    value: controlConfiguration.deleted_at,
    format: 'date'
  }])} data-sentry-element="PropertyList" data-sentry-component="ControlConfigurationPropertyList" data-sentry-source-file="ControlConfigurationPropertyList.tsx" />;
};