import { DateTime } from 'luxon';
import { useState } from 'react';
import { Body, Caption } from './Text';
import classNames from 'classnames';
import { Icon } from './Icon';
import { must } from '../lib/must';
export type DateRangePickerProps = {
  startDate: DateTime | undefined;
  endDate: DateTime | undefined;
  startDateLabel: string;
  endDateLabel: string;
  selectableRange?: 'any' | 'past' | 'future';
  onChange: (o: {
    startDate: DateTime | undefined;
    endDate: DateTime | undefined;
  }) => void;
};
export const DateRangePicker = ({
  startDate,
  endDate,
  startDateLabel,
  endDateLabel,
  selectableRange = 'any',
  onChange
}: DateRangePickerProps) => {
  const [currentDisplayMonth, setCurrentDisplayMonth] = useState<DateTime>(endDate ? endDate : DateTime.now());
  const isDisplayingCurrentMonth = DateTime.now().month === currentDisplayMonth.month && DateTime.now().year === currentDisplayMonth.year;
  const [mode, setMode] = useState<'startDate' | 'endDate'>('startDate');
  const [hoverDate, setHoverDate] = useState<DateTime | undefined>(undefined);
  const today = DateTime.now().startOf('day');
  const onlyPastDates = selectableRange === 'past';
  const onlyFutureDates = selectableRange === 'future';
  const anyDates = selectableRange === 'any';
  const handleDateClick = (date: DateTime) => {
    const isBeforeStartDate = startDate && date.toMillis() < startDate.toMillis();
    const isAfterEndDate = endDate && date.toMillis() > endDate.toMillis();
    const isCurrentMonth = date.month === currentDisplayMonth.month;
    if (onlyPastDates && date > today) {
      return; // Do nothing if the date is after today
    }
    if (onlyFutureDates && date < today) {
      return; // Do nothing if the date is after today
    }
    if (!isCurrentMonth) {
      return; // Do nothing if the date is after today
    }
    if (mode === 'startDate') {
      onChange({
        startDate: date,
        endDate: isAfterEndDate ? undefined : endDate
      });
      setMode(isAfterEndDate ? 'startDate' : 'endDate');
    } else {
      onChange({
        startDate: isBeforeStartDate ? date : startDate,
        endDate: isBeforeStartDate ? undefined : date
      });
      setMode(isBeforeStartDate ? 'endDate' : 'startDate');
    }
  };
  const firstMonthDay = currentDisplayMonth.startOf('month');
  const daysInMonth = must(currentDisplayMonth.daysInMonth);
  const firstSunday = firstMonthDay.weekday !== 7 ? firstMonthDay.minus({
    days: firstMonthDay.weekday
  }) : firstMonthDay;
  const lengthOfMonth = firstMonthDay.weekday !== 7 ? firstMonthDay.weekday + daysInMonth : daysInMonth;
  return <div className="space-y-4" data-sentry-component="DateRangePicker" data-sentry-source-file="DateRangerPicker.tsx">
      <div className="space-y-1">
        <div className="flex items-center">
          <Body className="grow" weight="medium" color="primary" data-sentry-element="Body" data-sentry-source-file="DateRangerPicker.tsx">
            {startDateLabel}
          </Body>
          <Body className="grow" weight="medium" color="primary" data-sentry-element="Body" data-sentry-source-file="DateRangerPicker.tsx">
            {endDateLabel}
          </Body>
        </div>
        <div className="flex flex-row gap-2">
          <div onClick={() => setMode('startDate')} className={classNames('flex items-center justify-between', 'border-strong h-[32px] w-full cursor-pointer rounded border pl-2 text-[14px] leading-[20px] font-[440] tracking-[.014em] transition outline-none', mode === 'startDate' ? 'bg-main z-10 shadow-sm' : 'bg-strong hover:bg-main opacity-50 hover:opacity-100')}>
            <div>
              <Body color={startDate ? 'primary' : 'secondary'} data-sentry-element="Body" data-sentry-source-file="DateRangerPicker.tsx">
                {startDate?.toFormat('MMM d, yyyy') ?? 'None'}
              </Body>
            </div>
            <div className={classNames('hover:bg-main-hover cursor-pointer rounded p-[6px] transition-opacity', startDate && mode === 'startDate' ? 'opacity-100' : 'opacity-0')} onClick={() => onChange({
            endDate,
            startDate: undefined
          })}>
              <Icon name="close" data-sentry-element="Icon" data-sentry-source-file="DateRangerPicker.tsx" />
            </div>
          </div>
          <div onClick={() => setMode('endDate')} className={classNames('flex items-center justify-between', 'border-strong h-[32px] w-full cursor-pointer rounded border pl-2 text-[14px] leading-[20px] font-[440] tracking-[.014em] transition outline-none', mode === 'endDate' ? 'bg-main z-10 shadow-sm' : 'bg-strong hover:bg-main opacity-50 hover:opacity-100', endDate ? 'text-strong' : 'text-disabled')}>
            <div>
              <Body color={endDate ? 'primary' : 'secondary'} data-sentry-element="Body" data-sentry-source-file="DateRangerPicker.tsx">
                {endDate?.toFormat('MMM d, yyyy') ?? 'None'}
              </Body>
            </div>
            <div className={classNames('hover:bg-main-hover cursor-pointer rounded p-[6px] transition-opacity', endDate && mode === 'endDate' ? 'opacity-100' : 'opacity-0')} onClick={() => endDate && onChange({
            startDate,
            endDate: undefined
          })}>
              <Icon name="close" data-sentry-element="Icon" data-sentry-source-file="DateRangerPicker.tsx" />
            </div>
          </div>
        </div>
      </div>

      <div className="select-none">
        <div className="flex items-center justify-between">
          <div className="hover:bg-main-hover cursor-pointer rounded p-2" onClick={() => {
          if (!isDisplayingCurrentMonth || isDisplayingCurrentMonth && !onlyFutureDates) {
            setCurrentDisplayMonth(currentDisplayMonth.minus({
              months: 1
            }));
          }
        }}>
            <Icon name="caret_left" data-sentry-element="Icon" data-sentry-source-file="DateRangerPicker.tsx" />
          </div>
          <div className="text-center">
            <Body color="primary" weight="bold" data-sentry-element="Body" data-sentry-source-file="DateRangerPicker.tsx">
              {currentDisplayMonth.toFormat('MMMM, yyyy')}
            </Body>
          </div>
          <div className="hover:bg-main-hover cursor-pointer rounded p-2" onClick={() => {
          if (!isDisplayingCurrentMonth || isDisplayingCurrentMonth && !onlyPastDates) {
            setCurrentDisplayMonth(currentDisplayMonth.plus({
              months: 1
            }));
          }
        }}>
            <Icon name="caret_right" data-sentry-element="Icon" data-sentry-source-file="DateRangerPicker.tsx" />
          </div>
        </div>
        <div className="grid grid-cols-7 py-2">
          <div className="text-center">
            <Caption color="secondary" weight="medium" data-sentry-element="Caption" data-sentry-source-file="DateRangerPicker.tsx">
              Su
            </Caption>
          </div>
          <div className="text-center">
            <Caption color="secondary" weight="medium" data-sentry-element="Caption" data-sentry-source-file="DateRangerPicker.tsx">
              Mo
            </Caption>
          </div>
          <div className="text-center">
            <Caption color="secondary" weight="medium" data-sentry-element="Caption" data-sentry-source-file="DateRangerPicker.tsx">
              Tu
            </Caption>
          </div>
          <div className="text-center">
            <Caption color="secondary" weight="medium" data-sentry-element="Caption" data-sentry-source-file="DateRangerPicker.tsx">
              We
            </Caption>
          </div>
          <div className="text-center">
            <Caption color="secondary" weight="medium" data-sentry-element="Caption" data-sentry-source-file="DateRangerPicker.tsx">
              Th
            </Caption>
          </div>
          <div className="text-center">
            <Caption color="secondary" weight="medium" data-sentry-element="Caption" data-sentry-source-file="DateRangerPicker.tsx">
              Fr
            </Caption>
          </div>
          <div className="text-center">
            <Caption color="secondary" weight="medium" data-sentry-element="Caption" data-sentry-source-file="DateRangerPicker.tsx">
              Sa
            </Caption>
          </div>
        </div>
        <div className="grid grid-cols-7 gap-y-1">
          {Array.from({
          length: lengthOfMonth > 35 ? 42 : 35
        }).map((_, idx) => {
          const date = firstSunday.plus({
            days: idx
          }).startOf('day');
          const isCurrentMonth = date.month === currentDisplayMonth.month;
          const isSelectable = onlyPastDates && date <= today && isCurrentMonth || onlyFutureDates && date >= today && isCurrentMonth || anyDates && isCurrentMonth;
          const isToday = today.hasSame(date, 'day');
          const isStartDate = startDate && startDate.hasSame(date, 'day');
          const isEndDate = endDate && endDate.hasSame(date, 'day');
          const isSelected = isStartDate || isEndDate;
          const isHoverDate = hoverDate && hoverDate.hasSame(date, 'day');
          const isAfterStartDate = startDate && startDate.diff(date, 'day').days <= 0 || !startDate;
          const isBeforeEndDate = endDate && endDate.diff(date, 'day').days >= 0 || !endDate;
          const isWithinRange = (startDate || endDate) && isAfterStartDate && isBeforeEndDate && isSelectable;
          return <div key={idx} className={classNames('aspect-square', isWithinRange && 'bg-main-selected', isStartDate && 'rounded', isEndDate && 'rounded')} onClick={() => handleDateClick(date)} onMouseEnter={() => setHoverDate(date)} onMouseLeave={() => hoverDate === date && setHoverDate(undefined)}>
                  <div className={classNames('relative flex h-full w-full items-center justify-center select-none', isSelected && isSelectable && 'bg-brand rounded', isHoverDate && isSelectable && 'border-brand cursor-pointer rounded border')}>
                    <Body color={isSelected ? 'offset' : !isSelectable ? 'disabled' : 'primary'} weight={'medium'}>
                      {isCurrentMonth && date.toFormat('d')}
                    </Body>
                    <div className={classNames(!isToday && 'hidden', isSelected ? 'bg-main' : 'bg-brand', 'absolute bottom-1/2 left-1/2 h-1 w-1 -translate-x-1/2 translate-y-4 rounded-full')} />
                  </div>
                </div>;
        })}
        </div>
      </div>
    </div>;
};