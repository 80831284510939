import React, { useMemo } from 'react';
import { PageLayout } from '../deprecated/PageLayout';
import { Loading } from '@increase/shared/components/Loading';
import { PropertyList } from '@increase/shared/components/PropertyList';
import { useTypedParams } from 'react-router-typesafe-routes/dom';
import { buildPath, ROUTES } from 'src/lib/routes';
import { useFinancialCrimesEnforcementNetworkThreeFourteenABusinessListInfinite, useFinancialCrimesEnforcementNetworkThreeFourteenAFileGet, useFinancialCrimesEnforcementNetworkThreeFourteenAPersonListInfinite } from 'src/hooks/reactQuery';
import { formatInteger, humanize } from 'shared/lib/formatting';
import { PrestyledTableProps, TableColumns, Table } from 'shared/components/Table';
import { FinancialCrimesEnforcementNetworkThreeFourteenABusinessListResponseDataItem, FinancialCrimesEnforcementNetworkThreeFourteenAPersonListResponseDataItem } from 'src/build/operations';
import { TableStateWrapper } from 'shared/components/TableStateWrapper';
import { compact } from 'lodash';
import { Heading } from 'shared/components/Text';
import { BadgeColor } from 'shared/components/Badge';
export const financialCrimesEnforcementNetworkThreeFourteenAEntryStatusBadgeColor = (status: FinancialCrimesEnforcementNetworkThreeFourteenAPersonListResponseDataItem['status']): BadgeColor => {
  return ({
    pending_reviewing: 'yellow',
    pending_allocating: 'yellow',
    allocated_no_matches: 'green',
    allocated_with_matches: 'red',
    requires_attention: 'red'
  } as const)[status];
};
export const ThreeFourteenAPersonsTable = ({
  ...rest
}: PrestyledTableProps<FinancialCrimesEnforcementNetworkThreeFourteenAPersonListResponseDataItem>) => {
  const columns = useMemo<TableColumns<FinancialCrimesEnforcementNetworkThreeFourteenAPersonListResponseDataItem>>(() => {
    return [{
      header: 'Tracking Number',
      contents: entry => ({
        text: entry.tracking_number,
        textWeight: 'medium',
        textColor: 'emphasis'
      })
    }, {
      header: 'Name',
      contents: entry => ({
        text: compact([entry.first_name, entry.middle_name, entry.last_name]).join(' ')
      })
    }, {
      header: 'Status',
      contents: entry => ({
        text: humanize(entry.status),
        badgeColor: financialCrimesEnforcementNetworkThreeFourteenAEntryStatusBadgeColor(entry.status)
      })
    }, {
      header: 'Alias',
      contents: entry => ({
        text: compact([entry.alias_first_name, entry.alias_middle_name, entry.alias_last_name]).join(' ')
      })
    }];
  }, []);
  const defaultRowProps = (datum: FinancialCrimesEnforcementNetworkThreeFourteenAPersonListResponseDataItem) => ({
    href: buildPath(ROUTES.FINANCIAL_CRIMES_ENFORCEMENT_NETWORK_THREE_FOURTEEN_A_PERSON_DETAIL, {
      entryId: datum.id
    }),
    className: 'hover:bg-main-hover cursor-pointer transition-all group'
  });
  return <Table<FinancialCrimesEnforcementNetworkThreeFourteenAPersonListResponseDataItem> columns={columns} getRowProps={defaultRowProps} {...rest} data-sentry-element="Table" data-sentry-component="ThreeFourteenAPersonsTable" data-sentry-source-file="FinancialCrimesEnforcementNetworkThreeFourteenAFileDetailPage.tsx" />;
};
export const ThreeFourteenABusinessTable = ({
  ...rest
}: PrestyledTableProps<FinancialCrimesEnforcementNetworkThreeFourteenABusinessListResponseDataItem>) => {
  const columns = useMemo<TableColumns<FinancialCrimesEnforcementNetworkThreeFourteenABusinessListResponseDataItem>>(() => {
    return [{
      header: 'Tracking Number',
      contents: entry => ({
        text: entry.tracking_number,
        textWeight: 'medium',
        textColor: 'emphasis'
      })
    }, {
      header: 'Name',
      contents: entry => ({
        text: compact([entry.business_name]).join(' ')
      })
    }, {
      header: 'Doing Business As',
      contents: entry => ({
        text: compact([entry.doing_business_as_name]).join(' ')
      })
    }, {
      header: 'Status',
      contents: entry => ({
        text: humanize(entry.status),
        badgeColor: financialCrimesEnforcementNetworkThreeFourteenAEntryStatusBadgeColor(entry.status)
      })
    }];
  }, []);
  const defaultRowProps = (datum: FinancialCrimesEnforcementNetworkThreeFourteenABusinessListResponseDataItem) => ({
    href: buildPath(ROUTES.FINANCIAL_CRIMES_ENFORCEMENT_NETWORK_THREE_FOURTEEN_A_BUSINESS_DETAIL, {
      entryId: datum.id
    }),
    className: 'hover:bg-main-hover cursor-pointer transition-all group'
  });
  return <Table<FinancialCrimesEnforcementNetworkThreeFourteenABusinessListResponseDataItem> columns={columns} getRowProps={defaultRowProps} {...rest} data-sentry-element="Table" data-sentry-component="ThreeFourteenABusinessTable" data-sentry-source-file="FinancialCrimesEnforcementNetworkThreeFourteenAFileDetailPage.tsx" />;
};
const PersonsLists = (props: {
  fileId: string;
}) => {
  const listResults = useFinancialCrimesEnforcementNetworkThreeFourteenAPersonListInfinite({
    file_id: props.fileId
  });
  const pendingListResults = useFinancialCrimesEnforcementNetworkThreeFourteenAPersonListInfinite({
    file_id: props.fileId,
    status: ['pending_allocating', 'pending_reviewing', 'allocated_with_matches']
  });
  return <>
      {pendingListResults.data && pendingListResults.data.pages[0]?.data?.length > 0 && <>
            <Heading>Entries needing attention</Heading>
            <TableStateWrapper table={ThreeFourteenAPersonsTable} {...pendingListResults} style="primary" filters={[]} emptyTitle="No entries" emptySubtitle="There are no entries to display" />
          </>}

      <Heading data-sentry-element="Heading" data-sentry-source-file="FinancialCrimesEnforcementNetworkThreeFourteenAFileDetailPage.tsx">All entries</Heading>
      <TableStateWrapper table={ThreeFourteenAPersonsTable} {...listResults} style="primary" filters={[]} emptyTitle="No entries" emptySubtitle="There are no entries to display" data-sentry-element="TableStateWrapper" data-sentry-source-file="FinancialCrimesEnforcementNetworkThreeFourteenAFileDetailPage.tsx" />
    </>;
};
const BusinessesLists = (props: {
  fileId: string;
}) => {
  const listResults = useFinancialCrimesEnforcementNetworkThreeFourteenABusinessListInfinite({
    file_id: props.fileId
  });
  const pendingListResults = useFinancialCrimesEnforcementNetworkThreeFourteenABusinessListInfinite({
    file_id: props.fileId,
    status: ['pending_allocating', 'pending_reviewing', 'allocated_with_matches']
  });
  return <>
      {pendingListResults.data && pendingListResults.data.pages[0]?.data?.length > 0 && <>
            <Heading>Entries needing attention</Heading>
            <TableStateWrapper table={ThreeFourteenABusinessTable} {...pendingListResults} style="primary" filters={[]} emptyTitle="No entries" emptySubtitle="There are no entries to display" />
          </>}

      <Heading data-sentry-element="Heading" data-sentry-source-file="FinancialCrimesEnforcementNetworkThreeFourteenAFileDetailPage.tsx">All entries</Heading>
      <TableStateWrapper table={ThreeFourteenABusinessTable} {...listResults} style="primary" filters={[]} emptyTitle="No entries" emptySubtitle="There are no entries to display" data-sentry-element="TableStateWrapper" data-sentry-source-file="FinancialCrimesEnforcementNetworkThreeFourteenAFileDetailPage.tsx" />
    </>;
};
export const FinancialCrimesEnforcementNetworkThreeFourteenAFileDetailPage = () => {
  const {
    fileId
  } = useTypedParams(ROUTES.FINANCIAL_CRIMES_ENFORCEMENT_NETWORK_THREE_FOURTEEN_A_FILE_DETAIL);
  const {
    data
  } = useFinancialCrimesEnforcementNetworkThreeFourteenAFileGet(fileId);
  if (!data) {
    return <Loading />;
  }
  return <PageLayout headline={`FinCEN 314(a) file • ${data.id}`} data-sentry-element="PageLayout" data-sentry-component="FinancialCrimesEnforcementNetworkThreeFourteenAFileDetailPage" data-sentry-source-file="FinancialCrimesEnforcementNetworkThreeFourteenAFileDetailPage.tsx">
        <PropertyList title="Details" items={[{
      label: 'Created At',
      value: data.created_at,
      format: 'month-day-year-hour-minute-second'
    }, {
      label: 'Name',
      value: data.inbound_file_transmission_name
    }, {
      label: 'File ID',
      value: data.id
    }, {
      label: 'File Type',
      value: data.type
    }, {
      label: 'Status',
      value: humanize(data.status),
      badgeColor: ({
        pending: 'yellow',
        completed: 'green'
      } as const)[data.status]
    }, {
      label: 'Entries',
      value: formatInteger(data.entries_count)
    }]} data-sentry-element="PropertyList" data-sentry-source-file="FinancialCrimesEnforcementNetworkThreeFourteenAFileDetailPage.tsx" />
        {data.type === 'person' ? <PersonsLists fileId={fileId} /> : <BusinessesLists fileId={fileId} />}
      </PageLayout>;
};