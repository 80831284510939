export const encodeRedirectState = (
  email: string,
  nextUrl: string | null
): string =>
  encodeURIComponent(
    btoa(
      JSON.stringify({
        nextUrl,
        email,
      })
    )
  );

export const decodeRedirectState = (state: string): { nextUrl?: string } =>
  JSON.parse(atob(decodeURIComponent(state)));
