import {
  accountList,
  entityPatch,
  groupGateDelete,
  groupGatePost,
  groupGet,
  groupList,
  groupPatch,
  initialStateGet,
  objectGet,
  operatorFileList,
  operatorFilePost,
  operatorGet,
  operatorNoteList,
  operatorNotePost,
  operatorTagList,
  operatorTagPost,
  transactionGet,
  roleList,
  userSessionList,
  entityGet,
  operatorManualQueueItemList,
  operatorManualQueueItemPatch,
  manualTaskList,
  manualTaskRunList,
  manualTaskRunGet,
  manualTaskRunPost,
  transferReviewingReleaseDirectivePost,
  outboundACHFilesOverviewGet,
  sessionGet,
  operatorConfirmOneTimePasswordPost,
  sessionPost,
  registerPost,
  sessionDelete,
  resultList,
  resultSuppressDirectivePost,
  resultRetryDirectivePost,
  resultDelete,
  operatorList,
  partnerInvitationList,
  partnerInvitationPost,
  accountGet,
  accountBalanceGet,
  identityDocumentList,
  identityDocumentGet,
  identityDocumentPatch,
  entityReferenceList,
  checkDepositContextGet,
  operatorOneTimePasswordProvisioningURIGet,
  checkItemAllocationInformationGet,
  checkItemAllocationSearchDirectiveGet,
  checkItemAllocationPost,
  programList,
  documentRequestList,
  beneficialOwnerGet,
  entityByNameList,
  checkDepositGet,
  accountByAccountNumberGet,
  platformComplianceMetricsSubmissionGet,
  platformVendorListSubmissionGet,
  platformComplaintListSubmissionGet,
  directUserDiligenceSubmissionGet,
  checkItemAllocationReturnDirectivePost,
  cardDisputeList,
  cardList,
  cardGet,
  accountNumberList,
  transactionsByACHTraceNumberList,
  bookkeepingAccountList,
  digitalWalletImageFileList,
  physicalCardImageFileList,
  validPhysicalCardImageFileList,
  digitalWalletImageFileApprovePost,
  physicalCardImageFileApprovePost,
  digitalWalletImageFileRejectPost,
  physicalCardImageFileRejectPost,
  inboundCheckGet,
  aCHOriginationVolumeMonitoringGet,
  lateReturnRequestList,
  lateReturnRequestPost,
  programGet,
  bookkeepingEntryList,
  declinedTransactionGet,
  realTimePaymentsPrefundedPositionOverviewGet,
  feePlanList,
  feeStatementList,
  routingNumberList,
  entitySupplementalDocumentSubmissionEvaluationGet,
  pendingTransactionGet,
  pendingTransactionList,
  entityClusterList,
  entityClusterGet,
  managementInformationSystemProgramCountsList,
  proofOfAuthorizationRequestSubmissionPatch,
  operatorDelete,
  managementInformationSystemBalancesList,
  managementInformationSystemRevenuesList,
  managementInformationSystemVolumesList,
  managementInformationSystemRiskMetricsList,
  obligationDocumentGet,
  transferReviewingRejectDirectivePost,
  transferGet,
  cardPaymentGet,
  cardDeclineGet,
  attestationGet,
  attestationPatch,
  flagsGet,
  unusualActivityReportList,
  unusualActivityReportGet,
  complaintEntryList,
  lateReturnRequestGet,
  managementInformationSystemAccountsList,
  platformComplaintList,
  PlatformComplaintListResponse,
  PlatformComplaintListParams,
  platformUnusualActivityReportList,
  PlatformUnusualActivityReportListParams,
  PlatformUnusualActivityReportListResponse,
  platformUnusualActivityReportGet,
  CardListParams,
  CardListResponse,
  CardDisputeListParams,
  CardDisputeListResponse,
  CompletedTransactionListParams,
  CompletedTransactionListResponse,
  completedTransactionList,
  AccountNumberListResponse,
  AccountNumberListParams,
  accountNumberGet,
  AccountListParams,
  AccountListResponse,
  bankProgramList,
  BankProgramListParams,
  BankProgramListResponse,
  bankProgramGet,
  EntityReferenceListParams,
  EntityReferenceListResponse,
  GroupListParams,
  GroupListResponse,
  CustomerIdentificationProgramTestingBatchListParams,
  CustomerIdentificationProgramTestingBatchListResponse,
  CustomerIdentificationProgramTestingEntryListParams,
  CustomerIdentificationProgramTestingEntryListResponse,
  customerIdentificationProgramTestingBatchList,
  customerIdentificationProgramTestingEntryList,
  customerIdentificationProgramTestingEntryGet,
  RoleListParams,
  RoleListResponse,
  ProgramListParams,
  ProgramListResponse,
  BookkeepingAccountListResponse,
  BookkeepingAccountListParams,
  BookkeepingEntryListParams,
  BookkeepingEntryListResponse,
  bookkeepingAccountGet,
  platformComplaintGet,
  resultStatusList,
  ResultStatusListParams,
  ResultStatusListResponse,
  resultStatusGet,
  DocumentRequestListParams,
  DocumentRequestListResponse,
  resultEvaluationList,
  ResultEvaluationListParams,
  ResultEvaluationListResponse,
  PendingTransactionListParams,
  PendingTransactionListResponse,
  LateReturnRequestListParams,
  LateReturnRequestListResponse,
  cashReconciliationOverviewGet,
  cashReconciliationUnreconciledExpectedCashTransactionTupleList,
  groupGateList,
  cashReconciliationUnreconciledExpectedCashTransactionTupleDetailsGet,
  ControlConfigurationListParams,
  controlConfigurationList,
  ControlConfigurationListResponse,
  controlConfigurationGet,
  ControlRecordListParams,
  ControlRecordListResponse,
  controlRecordList,
  controlRecordGet,
  RampBusinessAccountOnboardingSurveyListParams,
  RampBusinessAccountOnboardingSurveyListResponse,
  rampBusinessAccountOnboardingSurveyGet,
  rampBusinessAccountOnboardingSurveyList,
  UserListResponse,
  userList,
  UserListParams,
  userGet,
  beneficialOwnerRelationshipList,
  BeneficialOwnerRelationshipListParams,
  BeneficialOwnerRelationshipListResponse,
  ComplaintEntryListParams,
  ComplaintEntryListResponse,
  platformComplianceMetricsSubmissionList,
  PlatformComplianceMetricsSubmissionListParams,
  PlatformComplianceMetricsSubmissionListResponse,
  platformComplaintListSubmissionList,
  PlatformComplaintListSubmissionListParams,
  PlatformComplaintListSubmissionListResponse,
  PlatformVendorListSubmissionListParams,
  PlatformVendorListSubmissionListResponse,
  platformVendorListSubmissionList,
  platformComplianceSubmissionProgramList,
  PlatformComplianceSubmissionProgramListParams,
  PlatformComplianceSubmissionProgramListResponse,
  programACHReturnRateGet,
  programACHReturnRateList,
  ProgramACHReturnRateListParams,
  ProgramACHReturnRateListResponse,
  customerIdentificationProgramTestingBatchGet,
  customerIdentificationProgramTestingBatchSummaryGet,
  transferReviewList,
  TransferReviewListParams,
  TransferReviewListResponse,
  programLimitsPatch,
  inboundMailItemList,
  ManualTaskPreviewGetResponse,
  manualTaskPreviewGet,
  ManualTaskPreviewGetParams,
  bankProgramCurrentFeePlanGet,
  programCreditExposureGet,
  aCHCompanyIDLookupGet,
  documentRequestCancel,
  checkDepositList,
  CheckDepositContextGetParams,
  CheckDepositContextGetResponse,
  checkDepositRejectDirectivePost,
  checkDepositApproveDirectivePost,
  bankFeeStatementList,
  BankFeeStatementListParams,
  BankFeeStatementListResponse,
  bankFeeStatementGet,
  EntityClusterListParams,
  EntityClusterListResponse,
  entityClusterReviewPost,
  oAuthApplicationList,
  OAuthApplicationListParams,
  OAuthApplicationListResponse,
  oAuthConnectionList,
  OAuthConnectionListParams,
  OAuthConnectionListResponse,
  oAuthApplicationGet,
  platformVendorList,
  PlatformVendorListResponse,
  PlatformVendorListParams,
  OperatorListParams,
  OperatorListResponse,
  PartnerInvitationListParams,
  PartnerInvitationListResponse,
  partnerInvitationDelete,
  bankFeeList,
  BankFeeListParams,
  BankFeeListResponse,
  commercialOnboardingSurveyGet,
  commercialOnboardingSurveyList,
  CommercialOnboardingSurveyListParams,
  CommercialOnboardingSurveyListResponse,
  consumerOnboardingSurveyGet,
  consumerOnboardingSurveyList,
  ConsumerOnboardingSurveyListParams,
  ConsumerOnboardingSurveyListResponse,
  OperatorManualQueueItemListParams,
  OperatorManualQueueItemListResponse,
  sanctionsScreeningTriggerTokenList,
  SanctionsScreeningTriggerTokenListParams,
  SanctionsScreeningTriggerTokenListResponse,
  sanctionsScreeningTriggerTokenArchiveDirectivePost,
  sanctionsScreeningTriggerTokenPost,
  consolidatedScreeningListEntryGet,
  resultSummaryGet,
  transfersByCheckNumberList,
  documentRequestGet,
  sanctionsScreeningReviewList,
  SanctionsScreeningReviewListParams,
  SanctionsScreeningReviewListResponse,
  sanctionsScreeningReviewGet,
  sanctionsScreeningHitList,
  SanctionsScreeningHitListParams,
  SanctionsScreeningHitListResponse,
  sanctionsScreeningHitPatch,
  sanctionsScreeningReviewRecordGet,
  documentRequestScheduleList,
  documentRequestSchedulePost,
  DocumentRequestScheduleListParams,
  DocumentRequestScheduleListResponse,
  DocumentRequestScheduleListResponseDataItem,
  platformFinancialReportSubmissionGet,
  sanctionsScreeningReviewPatch,
  controlEvaluationList,
  ControlEvaluationListParams,
  ControlEvaluationListResponse,
  aCHHistoricalOriginationVolumeForRoutingNumberGet,
  fedwireInboundServiceMessageGet,
  fedwireInboundServiceMessageList,
  FedwireInboundServiceMessageListParams,
  FedwireInboundServiceMessageListResponse,
  fedwireInboundRequestForReversalGet,
  fedwireInboundRequestForReversalList,
  FedwireInboundRequestForReversalListParams,
  FedwireInboundRequestForReversalListResponse,
  transactionAccountingEntryForTraceList,
  entityAddressVerificationResultList,
  taskStatusList,
  TaskStatusListParams,
  TaskStatusListResponse,
  taskStatusPatch,
  transactionsByAccountAndRoutingNumberList,
  TransactionsByAccountAndRoutingNumberListParams,
  TransactionsByAccountAndRoutingNumberListResponse,
  fraudKnownBadSubmissionsSearch,
  externalAccountList,
  ExternalAccountListParams,
  ExternalAccountListResponse,
  externalAccountGet,
  checkTransferPayeeNameAnalysisGet,
  checkTransferPayeeNameAnalysisReviewPost,
  bookkeepingEntrySetGet,
  reserveAccountsOverviewGet,
  microBiltAdvancedPeopleSearchResultGet,
  secretaryOfStateSearchIntentGet,
  sovosResponseGet,
  AccountStatementListParams,
  AccountStatementListResponse,
  accountStatementList,
  customerIdentificationProgramTestingEntryPatch,
  attestationList,
  AttestationListParams,
  AttestationListResponse,
  UnusualActivityReportListParams,
  UnusualActivityReportListResponse,
  unusualActivityReportOperatorFilePost,
  sessionSingleSignOnPost,
  sanctionsScreeningAllowlistTokenList,
  SanctionsScreeningAllowlistTokenListParams,
  SanctionsScreeningAllowlistTokenListResponse,
  sanctionsScreeningAllowlistTokenPost,
  sanctionsScreeningTriggerTokenGet,
  sanctionsScreeningAllowlistTokenGet,
  financialCrimesEnforcementNetworkThreeFourteenAPersonList,
  FinancialCrimesEnforcementNetworkThreeFourteenAPersonListParams,
  FinancialCrimesEnforcementNetworkThreeFourteenAPersonListResponse,
  financialCrimesEnforcementNetworkThreeFourteenAFileList,
  FinancialCrimesEnforcementNetworkThreeFourteenAFileListParams,
  FinancialCrimesEnforcementNetworkThreeFourteenAFileListResponse,
  financialCrimesEnforcementNetworkThreeFourteenAPersonGet,
  financialCrimesEnforcementNetworkThreeFourteenAFileGet,
  financialCrimesEnforcementNetworkThreeFourteenAFilePost,
  financialCrimesEnforcementNetworkThreeFourteenAEntryEntityMatchList,
  FinancialCrimesEnforcementNetworkThreeFourteenAEntryEntityMatchListParams,
  FinancialCrimesEnforcementNetworkThreeFourteenAEntryEntityMatchListResponse,
  financialCrimesEnforcementNetworkThreeFourteenAEntryEntityMatchPatch,
  InboundFedNowMessageListParams,
  InboundFedNowMessageListResponse,
  inboundFedNowMessageList,
  inboundFedNowMessageGet,
  lockboxList,
  LockboxListParams,
  LockboxListResponse,
  lockboxGet,
  magneticInkCharacterExtractionPost,
  financialCrimesEnforcementNetworkThreeFourteenABusinessGet,
  financialCrimesEnforcementNetworkThreeFourteenABusinessList,
  FinancialCrimesEnforcementNetworkThreeFourteenABusinessListParams,
  FinancialCrimesEnforcementNetworkThreeFourteenABusinessListResponse,
  passportMachineReadableZoneExtractionPost,
  aCHTransferGet,
  programInterestRatePost,
  inboundMailItemGet,
  inboundMailItemAllocatePost,
  inboundMailItemRejectPost,
} from '../build/operations';
import {
  limitsList,
  limitsChangeRequestPost,
  limitsChangeRequestConfirmPost,
  limitsChangeRequestCancelPost,
} from '../build/federalreservegateway';
import {
  buildEntireListQueryWrapper,
  buildInfiniteQueryWrapper,
  buildMutationWrapper,
  buildQueriesWrapper,
  buildQueryWrapper,
} from './queryHooks';

const InitialStateQueryKey = 'operations/initial-state';

export const useInitialStateGet = buildQueryWrapper(
  initialStateGet,
  InitialStateQueryKey
);

const OperatorNoteQueryKey = 'operations/operator_notes';

export const useOperatorNoteList = buildQueryWrapper(
  operatorNoteList,
  OperatorNoteQueryKey
);

export const useOperatorNotePost = buildMutationWrapper(
  operatorNotePost,
  OperatorNoteQueryKey
);

const GroupListQueryKey = 'operations/groups';

export const useGroupListInfinite = buildInfiniteQueryWrapper<
  GroupListParams,
  GroupListResponse
>(groupList, GroupListQueryKey);

const GroupQueryKey = 'operations/group';

export const useGroupGet = buildQueryWrapper(groupGet, GroupQueryKey);
export const useGroupPatch = buildMutationWrapper(groupPatch, GroupQueryKey);

const GroupGatesQueryKey = 'operations/group-gates';

export const useGroupGatesList = buildQueryWrapper(
  groupGateList,
  GroupGatesQueryKey
);

export const useGroupGatePost = buildMutationWrapper(
  groupGatePost,
  GroupGatesQueryKey
);
export const useGroupGateDelete = buildMutationWrapper(
  groupGateDelete,
  GroupGatesQueryKey
);

const EntityQueryKey = 'operations/entities';

export const useEntityGet = buildQueryWrapper(entityGet, EntityQueryKey);

export const useEntityPatch = buildMutationWrapper(entityPatch, EntityQueryKey);

const EntityClusterQueryKey = 'operations/entity-clusters';

export const useEntityClusterList = buildQueryWrapper(
  entityClusterList,
  EntityClusterQueryKey
);

export const useEntityClusterListInfinite = buildInfiniteQueryWrapper<
  EntityClusterListParams,
  EntityClusterListResponse
>(entityClusterList, EntityClusterQueryKey);

export const useEntityClusterGet = buildQueryWrapper(
  entityClusterGet,
  EntityClusterQueryKey
);

const SecretaryOfStateSearchIntentQueryKey =
  'operations/secretary_of_state_search_intents';

export const useSecretaryOfSearchIntentGet = buildQueryWrapper(
  secretaryOfStateSearchIntentGet,
  SecretaryOfStateSearchIntentQueryKey
);

const MicroBiltResultQueryKey = 'operations/microbilt_results';

export const useMicroBiltResultGet = buildQueryWrapper(
  microBiltAdvancedPeopleSearchResultGet,
  MicroBiltResultQueryKey
);

const SovosResponseQueryKey = 'operations/sovos_responses';

export const useSovosResponseGet = buildQueryWrapper(
  sovosResponseGet,
  SovosResponseQueryKey
);

export const useEntityClusterReviewPost = buildMutationWrapper(
  entityClusterReviewPost,
  EntityClusterQueryKey,
  OperatorNoteQueryKey
);

const ExternalAccountQueryKey = 'operations/external-accounts';

export const useExternalAccountListInfinite = buildInfiniteQueryWrapper<
  ExternalAccountListParams,
  ExternalAccountListResponse
>(externalAccountList, ExternalAccountQueryKey);

export const useExternalAccountGet = buildQueryWrapper(
  externalAccountGet,
  ExternalAccountQueryKey
);

const EntityReferenceQueryKey = 'operations/entity-references';

export const useEntityReferenceListInfinite = buildInfiniteQueryWrapper<
  EntityReferenceListParams,
  EntityReferenceListResponse
>(entityReferenceList, EntityReferenceQueryKey);

const EntityAddressVerificationQueryKey =
  'operations/entity-address-verifications';

export const useEntityAddressVerificationList = buildInfiniteQueryWrapper(
  entityAddressVerificationResultList,
  EntityAddressVerificationQueryKey
);

const EntityByNameQueryKey = 'operations/entity-by-name';

export const useEntitiesByName = buildQueryWrapper(
  entityByNameList,
  EntityByNameQueryKey
);

const EntitySupplementalDocumentSubmissionEvaluationQueryKey =
  'operations/entity-setup-supplemental-document-submission-evaluation';

export const useEntitySupplementalDocumentSubmissionEvaluationGet =
  buildQueryWrapper(
    entitySupplementalDocumentSubmissionEvaluationGet,
    EntitySupplementalDocumentSubmissionEvaluationQueryKey
  );

const CustomerIdentificationProgramTestingBatchQueryKey =
  'operations/customer-identification-program-testing-batches';

export const useCustomerIdentificationProgramTestingBatchGet =
  buildQueryWrapper(
    customerIdentificationProgramTestingBatchGet,
    CustomerIdentificationProgramTestingBatchQueryKey
  );

export const useCustomerIdentificationProgramTestingBatchListInfinite =
  buildInfiniteQueryWrapper<
    CustomerIdentificationProgramTestingBatchListParams,
    CustomerIdentificationProgramTestingBatchListResponse
  >(
    customerIdentificationProgramTestingBatchList,
    CustomerIdentificationProgramTestingBatchQueryKey
  );

const CustomerIdentificationProgramTestingBatchSummaryQueryKey =
  'operations/customer-identification-program-testing-batch-summaries';

export const useCustomerIdentificationProgramTestingBatchSummaryGet =
  buildQueryWrapper(
    customerIdentificationProgramTestingBatchSummaryGet,
    CustomerIdentificationProgramTestingBatchSummaryQueryKey
  );

const CustomerIdentificationProgramTestingEntryQueryKey =
  'operations/customer-identification-program-testing-entries';

export const useCustomerIdentificationProgramTestingEntryListInfinite =
  buildInfiniteQueryWrapper<
    CustomerIdentificationProgramTestingEntryListParams,
    CustomerIdentificationProgramTestingEntryListResponse
  >(
    customerIdentificationProgramTestingEntryList,
    CustomerIdentificationProgramTestingEntryQueryKey
  );

export const useCustomerIdentificationProgramTestingEntryGet =
  buildQueryWrapper(
    customerIdentificationProgramTestingEntryGet,
    CustomerIdentificationProgramTestingEntryQueryKey
  );

const TransactionsByAccountAndRoutingNumberQueryKey =
  'operations/transactions-by-account-and-routing-number';

export const useTransactionsByAccountAndRoutingNumberList = buildQueryWrapper(
  transactionsByAccountAndRoutingNumberList,
  TransactionsByAccountAndRoutingNumberQueryKey
);

export const useTransactionsByAccountAndRoutingNumberListInfinite =
  buildInfiniteQueryWrapper<
    TransactionsByAccountAndRoutingNumberListParams,
    TransactionsByAccountAndRoutingNumberListResponse
  >(
    transactionsByAccountAndRoutingNumberList,
    TransactionsByAccountAndRoutingNumberQueryKey
  );

const FraudKnownBadSubmissionsQueryKey =
  'operations/fraud-known-bad-submissions';

export const useFraudKnownBadSubmissionsSearch = buildQueryWrapper(
  fraudKnownBadSubmissionsSearch,
  FraudKnownBadSubmissionsQueryKey
);

const TransactionsByACHTraceNumberQueryKey =
  'operations/transactions-by-ach-trace-number';

export const useTransactionsByACHTraceNumberList = buildQueryWrapper(
  transactionsByACHTraceNumberList,
  TransactionsByACHTraceNumberQueryKey
);

const BeneficialOwnerQueryKey = 'operations/beneficial-owners';

export const useBeneficialOwnerGet = buildQueryWrapper(
  beneficialOwnerGet,
  BeneficialOwnerQueryKey
);

const BeneficialOwnerRelationshipQueryKey =
  'operations/beneficial-owner-relationships';

export const useBeneficialOwnerRelationshipListInfinite =
  buildInfiniteQueryWrapper<
    BeneficialOwnerRelationshipListParams,
    BeneficialOwnerRelationshipListResponse
  >(beneficialOwnerRelationshipList, BeneficialOwnerRelationshipQueryKey);

const CompletedTransactionQueryKey = 'operations/completed-transactions';

export const useCompletedTransactionListInfinite = buildInfiniteQueryWrapper<
  CompletedTransactionListParams,
  CompletedTransactionListResponse
>(completedTransactionList, CompletedTransactionQueryKey);

const PendingTransactionListQueryKey = 'operations/pending-transactions-list';

export const usePendingTransactionListInfinite = buildInfiniteQueryWrapper<
  PendingTransactionListParams,
  PendingTransactionListResponse
>(pendingTransactionList, PendingTransactionListQueryKey);

const TransactionQueryKey = 'operations/transactions';

export const useTransactionGet = buildQueryWrapper(
  transactionGet,
  TransactionQueryKey
);

const DeclinedTransactionQueryKey = 'operations/declined_transactions';

export const useDeclinedTransactionGet = buildQueryWrapper(
  declinedTransactionGet,
  DeclinedTransactionQueryKey
);

const PendingTransactionQueryKey = 'operations/pending_transactions';

export const usePendingTransactionGet = buildQueryWrapper(
  pendingTransactionGet,
  PendingTransactionQueryKey
);

const TransferQueryKey = 'operations/transfer';

export const useTransferGet = buildQueryWrapper(transferGet, TransferQueryKey);

const ACHTransferQueryKey = 'operations/ach_transfer';

export const useACHTransferGet = buildQueryWrapper(
  aCHTransferGet,
  ACHTransferQueryKey
);

const ObjectQueryKey = 'operations/object';

export const useObjectGet = buildQueryWrapper(objectGet, ObjectQueryKey);

const FlagsQueryKey = 'operations/object';

export const useFlagsGet = buildQueryWrapper(flagsGet, FlagsQueryKey);

const UserSessionsQueryKey = 'operations/user-sessions';

export const useUserSessionList = buildQueryWrapper(
  userSessionList,
  UserSessionsQueryKey
);

const AccountQueryKey = 'operations/accounts';

export const useAccountListInfinite = buildInfiniteQueryWrapper<
  AccountListParams,
  AccountListResponse
>(accountList, AccountQueryKey);

export const useAccountGet = buildQueryWrapper(accountGet, AccountQueryKey);

const CardDisputeQueryKey = 'operations/card_disputes';

export const useCardDisputeListInfinite = buildInfiniteQueryWrapper<
  CardDisputeListParams,
  CardDisputeListResponse
>(cardDisputeList, CardDisputeQueryKey);

const CardQueryKey = 'operations/cards';

export const useCardListInfinite = buildInfiniteQueryWrapper<
  CardListParams,
  CardListResponse
>(cardList, CardQueryKey);

export const useCardGet = buildQueryWrapper(cardGet, CardQueryKey);

const CardPaymentQueryKey = 'operations/card-payments';

export const useCardPaymentGet = buildQueryWrapper(
  cardPaymentGet,
  CardPaymentQueryKey
);

const CardDeclineQueryKey = 'operations/card-decline';

export const useCardDeclineGet = buildQueryWrapper(
  cardDeclineGet,
  CardDeclineQueryKey
);

const AccountNumberQueryKey = 'operations/account-number';

export const useAccountNumberGet = buildQueryWrapper(
  accountNumberGet,
  AccountNumberQueryKey
);

export const useAccountNumberListInfinite = buildInfiniteQueryWrapper<
  AccountNumberListParams,
  AccountNumberListResponse
>(accountNumberList, AccountNumberQueryKey);

const AccountByAccountNumberGet = '/operations/accounts_by_account_number';

export const useAccountByAccountNumberGet = buildQueryWrapper(
  accountByAccountNumberGet,
  AccountByAccountNumberGet
);

export const useTransfersByCheckNumberList = buildQueryWrapper(
  transfersByCheckNumberList,
  '/operations/transaction_by_check_number'
);

const AccountBalanceQueryKey = 'operations/account_balance';

export const useAccountBalanceGet = buildQueryWrapper(
  accountBalanceGet,
  AccountBalanceQueryKey
);

const RoleQueryKey = 'operations/roles';

export const useRoleListInfinite = buildInfiniteQueryWrapper<
  RoleListParams,
  RoleListResponse
>(roleList, RoleQueryKey);

const UserQueryKey = 'operations/users';

export const useUserListInfinite = buildInfiniteQueryWrapper<
  UserListParams,
  UserListResponse
>(userList, UserQueryKey);

export const useUserGet = buildQueryWrapper(userGet, UserQueryKey);

const OperatorQueryKey = 'operations/operators';

export const useOperatorGet = buildQueryWrapper(operatorGet, OperatorQueryKey);

const OperatorTagQueryKey = 'operations/operator_tags';

export const useOperatorTagList = buildQueryWrapper(
  operatorTagList,
  OperatorTagQueryKey
);

export const useOperatorTagPost = buildMutationWrapper(
  operatorTagPost,
  OperatorTagQueryKey
);

const OperatorFileQueryKey = 'operations/operator_files';

export const useOperatorFileList = buildQueryWrapper(
  operatorFileList,
  OperatorFileQueryKey
);

export const useOperatorFilePost = buildMutationWrapper(
  operatorFilePost,
  OperatorFileQueryKey
);

export const useUnusualActivityReportOperatorFilePost = buildMutationWrapper(
  unusualActivityReportOperatorFilePost,
  OperatorFileQueryKey
);

const OperatorManualQueueItemQueryKey = 'operations/operator_manual_queue_item';

export const useOperatorManualQueueItemListInfinite = buildInfiniteQueryWrapper<
  OperatorManualQueueItemListParams,
  OperatorManualQueueItemListResponse
>(operatorManualQueueItemList, OperatorManualQueueItemQueryKey);

export const useOperatorManualQueueItemPatch = buildMutationWrapper(
  operatorManualQueueItemPatch,
  OperatorManualQueueItemQueryKey
);

const SanctionsScreeningReviewQueryKey =
  'operations/sanctions_screening_reviews';

export const useSanctionsScreeningReviewListInfinite =
  buildInfiniteQueryWrapper<
    SanctionsScreeningReviewListParams,
    SanctionsScreeningReviewListResponse
  >(sanctionsScreeningReviewList, SanctionsScreeningReviewQueryKey);

export const useSanctionsScreeningReviewGet = buildQueryWrapper(
  sanctionsScreeningReviewGet,
  SanctionsScreeningReviewQueryKey
);

const SanctionsScreeningHitQueryKey = 'operations/sanctions_screening_hits';

export const useSanctionsScreeningHitListInfinite = buildInfiniteQueryWrapper<
  SanctionsScreeningHitListParams,
  SanctionsScreeningHitListResponse
>(sanctionsScreeningHitList, SanctionsScreeningHitQueryKey);

export const useSanctionsScreeningHitPatch = buildMutationWrapper(
  sanctionsScreeningHitPatch,
  SanctionsScreeningHitQueryKey,
  SanctionsScreeningReviewQueryKey,
  OperatorNoteQueryKey
);

export const useSanctionsScreeningReviewPatch = buildMutationWrapper(
  sanctionsScreeningReviewPatch,
  SanctionsScreeningReviewQueryKey,
  SanctionsScreeningReviewQueryKey,
  OperatorNoteQueryKey,
  SanctionsScreeningHitQueryKey
);

const SanctionsScreeningReviewRecordQueryKey =
  'operations/sanctions_screening_review_records';

export const useSanctionsScreeningReviewRecordGet = buildQueryWrapper(
  sanctionsScreeningReviewRecordGet,
  SanctionsScreeningReviewRecordQueryKey
);

const ManualTaskQueryKey = 'operations/manual_task';

export const useManualTaskList = buildQueryWrapper(
  manualTaskList,
  ManualTaskQueryKey
);

const ManualTaskRunQueryKey = 'operations/manual_task_run';

export const useManualTaskRunList = buildQueryWrapper(
  manualTaskRunList,
  ManualTaskRunQueryKey
);

export const useManualTaskRunGet = buildQueryWrapper(
  manualTaskRunGet,
  ManualTaskRunQueryKey
);

export const useManualTaskRunPost = buildMutationWrapper(
  manualTaskRunPost,
  ManualTaskRunQueryKey
);

const ManualTaskPreviewQueryKey = 'operations/manual_task_preview';

export const useManualTaskPreviewGet = buildQueryWrapper<
  ManualTaskPreviewGetParams,
  ManualTaskPreviewGetResponse
>(manualTaskPreviewGet, ManualTaskPreviewQueryKey);

const TransferReviewingOverviewQueryKey =
  'operations/transfer_reviewing_overview';

export const useTransferReviewListInfinite = buildInfiniteQueryWrapper<
  TransferReviewListParams,
  TransferReviewListResponse
>(transferReviewList, TransferReviewingOverviewQueryKey);

export const useTransferReviewingReleaseDirectivePost = buildMutationWrapper(
  transferReviewingReleaseDirectivePost,
  TransferReviewingOverviewQueryKey
);

export const useTransferReviewingRejectDirectivePost = buildMutationWrapper(
  transferReviewingRejectDirectivePost,
  TransferReviewingOverviewQueryKey
);

const OutboundACHFilesOverviewQueryKey =
  'operations/outbound-ach-files-overview';

export const useOutboundACHFilesOverviewGet = buildQueryWrapper(
  outboundACHFilesOverviewGet,
  OutboundACHFilesOverviewQueryKey
);

const ACHOriginationVolumeMonitoringQueryKey =
  'operations/ach-origination-volume-monitoring';

export const useACHOriginationVolumeMonitoringGet = buildQueryWrapper(
  aCHOriginationVolumeMonitoringGet,
  ACHOriginationVolumeMonitoringQueryKey
);

const ACHHistoricalOriginationForRoutingNumber =
  'operations/ach-historical-origination-for-routing-number';

export const useACHHistoricalOriginationForRoutingNumber = buildQueryWrapper(
  aCHHistoricalOriginationVolumeForRoutingNumberGet,
  ACHHistoricalOriginationForRoutingNumber
);

const RealTimePaymentsPrefundedPositionQueryKey =
  'operations/real-time-payments-prefunded-position';

export const useRealTimePaymentsPrefundedPositionOverviewGet =
  buildQueryWrapper(
    realTimePaymentsPrefundedPositionOverviewGet,
    RealTimePaymentsPrefundedPositionQueryKey
  );

const CashReconciliationQueryKey = 'operations/cash-reconciliation';

export const useCashReconciliationOverviewGet = buildQueryWrapper(
  cashReconciliationOverviewGet,
  CashReconciliationQueryKey
);

export const useCashReconciliationUnreconciledExpectedCashTransactionTupleList =
  buildQueryWrapper(
    cashReconciliationUnreconciledExpectedCashTransactionTupleList,
    CashReconciliationQueryKey
  );

export const useCashReconciliationUnreconciledExpectedCashTransactionTupleDetailsGet =
  buildQueryWrapper(
    cashReconciliationUnreconciledExpectedCashTransactionTupleDetailsGet,
    CashReconciliationQueryKey
  );

const SessionQueryKey = 'operations/session';

export const useSessionGet = buildQueryWrapper(sessionGet, SessionQueryKey);

export const useSessionPost = buildMutationWrapper(
  sessionPost,
  SessionQueryKey
);

export const useSessionDelete = buildMutationWrapper(
  sessionDelete,
  SessionQueryKey
);

export const useSingleSignOnSessionPost = buildMutationWrapper(
  sessionSingleSignOnPost,
  SessionQueryKey
);

export const useRegisterPost = buildMutationWrapper(
  registerPost,
  SessionQueryKey
);

export const useOperatorConfirmOneTimePasswordPost = buildMutationWrapper(
  operatorConfirmOneTimePasswordPost,
  SessionQueryKey
);

const OneTimePasswordProvisioningURIQueryKey =
  '/operations/OneTimePasswordProvisioningURI';

export const useOperatorOneTimePasswordProvisioningURIGet = buildQueryWrapper(
  operatorOneTimePasswordProvisioningURIGet,
  OneTimePasswordProvisioningURIQueryKey
);

const ResultsQueryKey = 'operations/results';

export const useResultList = buildQueryWrapper(resultList, ResultsQueryKey);

export const useResultSuppressPost = buildMutationWrapper(
  resultSuppressDirectivePost,
  ResultsQueryKey
);

export const useResultRetryPost = buildMutationWrapper(
  resultRetryDirectivePost,
  ResultsQueryKey
);

export const useResultDelete = buildMutationWrapper(
  resultDelete,
  ResultsQueryKey
);

const TaskStatusQueryKey = 'operations/tasks-status';

export const useTaskStatusList = buildInfiniteQueryWrapper<
  TaskStatusListParams,
  TaskStatusListResponse
>(taskStatusList, TaskStatusQueryKey);

export const useTaskStatusPatch = buildMutationWrapper(
  taskStatusPatch,
  TaskStatusQueryKey
);

const ResultStatusQueryKey = 'operations/results-status';

export const useResultStatusList = buildInfiniteQueryWrapper<
  ResultStatusListParams,
  ResultStatusListResponse
>(resultStatusList, ResultStatusQueryKey);

export const useResultStatusGet = buildQueryWrapper(
  resultStatusGet,
  ResultStatusQueryKey
);

const ResultSummaryQueryKey = 'operations/results-summary';

export const useResultSummaryGet = buildQueryWrapper(
  resultSummaryGet,
  ResultSummaryQueryKey
);

const ResultEvaluationQueryKey = 'operations/results-evaluations';

export const useResultEvaluationListInfinite = buildInfiniteQueryWrapper<
  ResultEvaluationListParams,
  ResultEvaluationListResponse
>(resultEvaluationList, ResultEvaluationQueryKey);
const OperatorsQueryKey = 'operations/operators';

export const useOperatorListInfinite = buildInfiniteQueryWrapper<
  OperatorListParams,
  OperatorListResponse
>(operatorList, OperatorsQueryKey);

export const useOperatorDelete = buildMutationWrapper(
  operatorDelete,
  OperatorsQueryKey
);

const PartnerInvitationsQueryKey = 'operations/partner_invitations';

export const usePartnerInvitationListInfinite = buildInfiniteQueryWrapper<
  PartnerInvitationListParams,
  PartnerInvitationListResponse
>(partnerInvitationList, PartnerInvitationsQueryKey);

export const usePartnerInvitationPost = buildMutationWrapper(
  partnerInvitationPost,
  PartnerInvitationsQueryKey
);

const IdentityDocumentQueryKey = 'operations/identity_documents';

export const useIdentityDocumentList = buildQueryWrapper(
  identityDocumentList,
  IdentityDocumentQueryKey
);

export const useIdentityDocumentGet = buildQueryWrapper(
  identityDocumentGet,
  IdentityDocumentQueryKey
);

export const useIdentityDocumentPatch = buildMutationWrapper(
  identityDocumentPatch,
  IdentityDocumentQueryKey
);

export const usePassportMachineReadableZoneExtractionPost =
  buildMutationWrapper(
    passportMachineReadableZoneExtractionPost,
    'operations/passport_machine_readable_zone_extraction'
  );

const InboundCheckQueryKey = 'operations/inbound_check';

export const useInboundCheckGet = buildQueryWrapper(
  inboundCheckGet,
  InboundCheckQueryKey
);

const CheckDepositQueryKey = 'operations/check_deposits';
const CheckDepositContextQueryKey = 'operations/check_deposit_contexts';

export const useCheckDepositGet = buildQueryWrapper(
  checkDepositGet,
  CheckDepositQueryKey
);

export const useCheckDepositList = buildQueryWrapper(
  checkDepositList,
  CheckDepositQueryKey
);

export const useCheckDepositReject = buildMutationWrapper(
  checkDepositRejectDirectivePost,
  CheckDepositContextQueryKey,
  CheckDepositQueryKey
);

export const useCheckDepositApprove = buildMutationWrapper(
  checkDepositApproveDirectivePost,
  CheckDepositContextQueryKey,
  CheckDepositQueryKey
);

export const useCheckDepositContextGet = buildQueryWrapper<
  CheckDepositContextGetParams,
  CheckDepositContextGetResponse
>(checkDepositContextGet, CheckDepositContextQueryKey);

export const useMagneticInkCharacterExtractionPost = buildMutationWrapper(
  magneticInkCharacterExtractionPost,
  'operations/magnetic_ink_character_extraction'
);

const InboundMailItemQueryKey = 'operations/inbound_mail_items';

export const useInboundMailItemList = buildQueryWrapper(
  inboundMailItemList,
  InboundMailItemQueryKey
);

export const useInboundMailItemGet = buildQueryWrapper(
  inboundMailItemGet,
  InboundMailItemQueryKey
);

export const useInboundMailItemAllocatePost = buildMutationWrapper(
  inboundMailItemAllocatePost,
  InboundMailItemQueryKey
);

export const useInboundMailItemRejectPost = buildMutationWrapper(
  inboundMailItemRejectPost,
  InboundMailItemQueryKey
);

const CheckItemAllocationInformationQueryKey =
  'operations/check_item_allocation_information';

export const useCheckItemAllocationInformationGet = buildQueryWrapper(
  checkItemAllocationInformationGet,
  CheckItemAllocationInformationQueryKey
);

const CheckItemAllocationSearchQueryKey =
  'operations/check_item_allocation_search';

export const useCheckItemAllocationSearchGet = buildQueryWrapper(
  checkItemAllocationSearchDirectiveGet,
  CheckItemAllocationSearchQueryKey
);

export const useCheckItemAllocationPost = buildMutationWrapper(
  checkItemAllocationPost,
  CheckItemAllocationInformationQueryKey
);

export const useCheckItemReturnDirectivePost = buildMutationWrapper(
  checkItemAllocationReturnDirectivePost,
  CheckItemAllocationInformationQueryKey
);

const checkTransferPayeeNameAnalysisQueryKey =
  'operations/check_transfer_payee_name_analysis';

export const useCheckTransferPayeeNameAnalysisGet = buildQueryWrapper(
  checkTransferPayeeNameAnalysisGet,
  checkTransferPayeeNameAnalysisQueryKey
);

export const useCheckTransferPayeeNameAnalysisReviewPost = buildMutationWrapper(
  checkTransferPayeeNameAnalysisReviewPost,
  checkTransferPayeeNameAnalysisQueryKey
);

const ProgramQueryKey = 'operations/programs';

export const useProgramListInfinite = buildInfiniteQueryWrapper<
  ProgramListParams,
  ProgramListResponse
>(programList, ProgramQueryKey);

export const useProgramGet = buildQueryWrapper(programGet, ProgramQueryKey);

export const useProgramLimitsPatch = buildMutationWrapper(
  programLimitsPatch,
  ProgramQueryKey
);

const ProgramCreditExposureQueryKey = 'operations/program_credit_exposures';

export const useProgramCreditExposureGet = buildQueryWrapper(
  programCreditExposureGet,
  ProgramCreditExposureQueryKey
);

const DocumentRequestQueryKey = 'operations/document_requests';

export const useDocumentRequestListInfinite = buildInfiniteQueryWrapper<
  DocumentRequestListParams,
  DocumentRequestListResponse
>(documentRequestList, DocumentRequestQueryKey);

const ProgramACHReturnRatesQueryKey = 'operations/program_ach_return_rates';

export const useProgramACHReturnRateListInfinite = buildInfiniteQueryWrapper<
  ProgramACHReturnRateListParams,
  ProgramACHReturnRateListResponse
>(programACHReturnRateList, ProgramACHReturnRatesQueryKey);

export const useProgramACHReturnRateGet = buildQueryWrapper(
  programACHReturnRateGet,
  ProgramACHReturnRatesQueryKey
);

export const usePlatformFinancialReportSubmissionGet = buildQueryWrapper(
  platformFinancialReportSubmissionGet,
  'operations/platform_financial_report_submission'
);

export const usePlatformComplianceMetricsSubmissionGet = buildQueryWrapper(
  platformComplianceMetricsSubmissionGet,
  'operations/platform_compliance_metrics_submission'
);

export const usePlatformComplaintListSubmissionGet = buildQueryWrapper(
  platformComplaintListSubmissionGet,
  'operations/platform_complaint_list_submission'
);

export const usePlatformVendorListSubmissionGet = buildQueryWrapper(
  platformVendorListSubmissionGet,
  'operations/platform_vendor_list_submission'
);

export const usePlatformComplianceMetricsSubmissionListInfinite =
  buildInfiniteQueryWrapper<
    PlatformComplianceMetricsSubmissionListParams,
    PlatformComplianceMetricsSubmissionListResponse
  >(
    platformComplianceMetricsSubmissionList,
    'operations/platform_compliance_metrics_submission_list'
  );

export const usePlatformComplaintListSubmissionListInfinite =
  buildInfiniteQueryWrapper<
    PlatformComplaintListSubmissionListParams,
    PlatformComplaintListSubmissionListResponse
  >(
    platformComplaintListSubmissionList,
    'operations/platform_complaint_list_submission_list'
  );

export const usePlatformVendorListSubmissionListInfinite =
  buildInfiniteQueryWrapper<
    PlatformVendorListSubmissionListParams,
    PlatformVendorListSubmissionListResponse
  >(
    platformVendorListSubmissionList,
    'operations/platform_vendor_list_submission_list'
  );

export const usePlatformComplianceSubmissionProgramListInfinite =
  buildInfiniteQueryWrapper<
    PlatformComplianceSubmissionProgramListParams,
    PlatformComplianceSubmissionProgramListResponse
  >(
    platformComplianceSubmissionProgramList,
    'operations/platform_vendor_list_submission_list'
  );

export const FederalReserveGatewayLimitsQueryKey =
  'federalreservegateway/limits';
export const FederalReserveGatewayRequestLimitsQueryKey =
  'federalreservegateway/limit-requests';
export const FederalReserveGatewayLimitsEventsQueryKey =
  'federalreservegateway/limit-events';

export const useFederalReserveGatewayLimitsList = buildQueryWrapper(
  limitsList,
  FederalReserveGatewayLimitsQueryKey
);

export const useFederalReserveGatewayRequestLimitChange = buildMutationWrapper(
  limitsChangeRequestPost,
  FederalReserveGatewayRequestLimitsQueryKey,
  FederalReserveGatewayLimitsEventsQueryKey
);

export const useFederalReserveGatewayConfirmLimitChange = buildMutationWrapper(
  limitsChangeRequestConfirmPost,
  FederalReserveGatewayRequestLimitsQueryKey,
  FederalReserveGatewayLimitsEventsQueryKey
);

export const useFederalReserveGatewayCancelLimitChange = buildMutationWrapper(
  limitsChangeRequestCancelPost,
  FederalReserveGatewayRequestLimitsQueryKey,
  FederalReserveGatewayLimitsEventsQueryKey
);

const BookkeepingAccountsQueryKey = 'operations/bookkeeping_accounts';
const BookkeepingEntriesQueryKey = 'operations/bookkeeping_entries';

export const useBookkeepingAccountGet = buildQueryWrapper(
  bookkeepingAccountGet,
  BookkeepingAccountsQueryKey
);

export const useBookkeepingAccountListInfinite = buildInfiniteQueryWrapper<
  BookkeepingAccountListParams,
  BookkeepingAccountListResponse
>(bookkeepingAccountList, BookkeepingAccountsQueryKey);

export const useBookkeepingEntryListInfinite = buildInfiniteQueryWrapper<
  BookkeepingEntryListParams,
  BookkeepingEntryListResponse
>(bookkeepingEntryList, BookkeepingEntriesQueryKey);

const BookkeepingEntrySetsQueryKey = 'operations/bookkeeping_entry_sets';

export const useBookkeepingEntrySetGet = buildQueryWrapper(
  bookkeepingEntrySetGet,
  BookkeepingEntrySetsQueryKey
);

const consolidatedScreeningListEntryQueryKey =
  'operations/conslidated_screening_list_entry';

export const useConsolidatedScreeningListEntryGet = buildQueryWrapper(
  consolidatedScreeningListEntryGet,
  consolidatedScreeningListEntryQueryKey
);

const FinancialCrimesEnforcementNetworkThreeFourteenAFileQueryKey =
  'operations/financial_crimes_enforcement_network_three_fourteen_a_files';

export const useFinancialCrimesEnforcementNetworkThreeFourteenAFileGet =
  buildQueryWrapper(
    financialCrimesEnforcementNetworkThreeFourteenAFileGet,
    FinancialCrimesEnforcementNetworkThreeFourteenAFileQueryKey
  );

export const useFinancialCrimesEnforcementNetworkThreeFourteenAFileListInfinite =
  buildInfiniteQueryWrapper<
    FinancialCrimesEnforcementNetworkThreeFourteenAFileListParams,
    FinancialCrimesEnforcementNetworkThreeFourteenAFileListResponse
  >(
    financialCrimesEnforcementNetworkThreeFourteenAFileList,
    FinancialCrimesEnforcementNetworkThreeFourteenAFileQueryKey
  );

export const useFinancialCrimesEnforcementNetworkThreeFourteenAFilePost =
  buildMutationWrapper(
    financialCrimesEnforcementNetworkThreeFourteenAFilePost,
    FinancialCrimesEnforcementNetworkThreeFourteenAFileQueryKey
  );

const FinancialCrimesEnforcementNetworkThreeFourteenAPersonQueryKey =
  'operations/financial_crimes_enforcement_network_three_fourteen_a_persons';

export const useFinancialCrimesEnforcementNetworkThreeFourteenAPersonGet =
  buildQueryWrapper(
    financialCrimesEnforcementNetworkThreeFourteenAPersonGet,
    FinancialCrimesEnforcementNetworkThreeFourteenAPersonQueryKey
  );

export const useFinancialCrimesEnforcementNetworkThreeFourteenAPersonListInfinite =
  buildInfiniteQueryWrapper<
    FinancialCrimesEnforcementNetworkThreeFourteenAPersonListParams,
    FinancialCrimesEnforcementNetworkThreeFourteenAPersonListResponse
  >(
    financialCrimesEnforcementNetworkThreeFourteenAPersonList,
    FinancialCrimesEnforcementNetworkThreeFourteenAPersonQueryKey
  );

const FinancialCrimesEnforcementNetworkThreeFourteenABusinessQueryKey =
  'operations/financial_crimes_enforcement_network_three_fourteen_a_businesses';

export const useFinancialCrimesEnforcementNetworkThreeFourteenABusinessGet =
  buildQueryWrapper(
    financialCrimesEnforcementNetworkThreeFourteenABusinessGet,
    FinancialCrimesEnforcementNetworkThreeFourteenABusinessQueryKey
  );

export const useFinancialCrimesEnforcementNetworkThreeFourteenABusinessListInfinite =
  buildInfiniteQueryWrapper<
    FinancialCrimesEnforcementNetworkThreeFourteenABusinessListParams,
    FinancialCrimesEnforcementNetworkThreeFourteenABusinessListResponse
  >(
    financialCrimesEnforcementNetworkThreeFourteenABusinessList,
    FinancialCrimesEnforcementNetworkThreeFourteenABusinessQueryKey
  );

const FinancialCrimesEnforcementNetworkThreeFourteenAEntryEntityMatchQueryKey =
  'operations/financial_crimes_enforcement_network_three_fourteen_a_entry_entity_matches';

export const useFinancialCrimesEnforcementNetworkThreeFourteenAEntryEntityMatchListInfinite =
  buildInfiniteQueryWrapper<
    FinancialCrimesEnforcementNetworkThreeFourteenAEntryEntityMatchListParams,
    FinancialCrimesEnforcementNetworkThreeFourteenAEntryEntityMatchListResponse
  >(
    financialCrimesEnforcementNetworkThreeFourteenAEntryEntityMatchList,
    FinancialCrimesEnforcementNetworkThreeFourteenAEntryEntityMatchQueryKey
  );

export const useFinancialCrimesEnforcementNetworkThreeFourteenAEntryEntityMatchPatch =
  buildMutationWrapper(
    financialCrimesEnforcementNetworkThreeFourteenAEntryEntityMatchPatch,
    FinancialCrimesEnforcementNetworkThreeFourteenAEntryEntityMatchQueryKey,
    FinancialCrimesEnforcementNetworkThreeFourteenAPersonQueryKey,
    FinancialCrimesEnforcementNetworkThreeFourteenABusinessQueryKey
  );

const TriggerTokensQueryKey = 'operations/trigger_tokens';

export const useSanctionsScreeningTriggerTokensListInfinite =
  buildInfiniteQueryWrapper<
    SanctionsScreeningTriggerTokenListParams,
    SanctionsScreeningTriggerTokenListResponse
  >(sanctionsScreeningTriggerTokenList, TriggerTokensQueryKey);

export const useSanctionsScreeningTriggerTokenGet = buildQueryWrapper(
  sanctionsScreeningTriggerTokenGet,
  TriggerTokensQueryKey
);

export const useSanctionsScreeningTriggerTokensPost = buildMutationWrapper(
  sanctionsScreeningTriggerTokenPost,
  TriggerTokensQueryKey
);

export const useSanctionsScreeningTriggerTokensArchivePost =
  buildMutationWrapper(
    sanctionsScreeningTriggerTokenArchiveDirectivePost,
    TriggerTokensQueryKey
  );

const AllowlistTokensQueryKey = 'operations/allowlist_tokens';

export const useSanctionsScreeningAllowlistTokenListInfinite =
  buildInfiniteQueryWrapper<
    SanctionsScreeningAllowlistTokenListParams,
    SanctionsScreeningAllowlistTokenListResponse
  >(sanctionsScreeningAllowlistTokenList, AllowlistTokensQueryKey);

export const useSanctionsScreeningAllowlistTokenGet = buildQueryWrapper(
  sanctionsScreeningAllowlistTokenGet,
  AllowlistTokensQueryKey
);

export const useSanctionsScreeningAllowlistTokenPost = buildMutationWrapper(
  sanctionsScreeningAllowlistTokenPost,
  AllowlistTokensQueryKey
);

export const useSanctionsScreeningAllowlistTokenArchivePost =
  buildMutationWrapper(
    sanctionsScreeningTriggerTokenArchiveDirectivePost,
    TriggerTokensQueryKey
  );

const DigitalWalletImageFileQueryKey = 'operations/digital_wallet_image_files';
const PhysicalCardImageFileQueryKey = 'operations/physical_card_image_files';

export const useDigitalWalletImageFileList = buildQueryWrapper(
  digitalWalletImageFileList,
  DigitalWalletImageFileQueryKey
);

export const useDigitalWalletImageFileApprove = buildMutationWrapper(
  digitalWalletImageFileApprovePost,
  DigitalWalletImageFileQueryKey
);

export const useValidPhysicalCardImageFileList = buildQueryWrapper(
  validPhysicalCardImageFileList,
  'operations/valid_physical_card_image_files'
);

export const usePhysicalCardImageFileList = buildQueryWrapper(
  physicalCardImageFileList,
  PhysicalCardImageFileQueryKey
);

export const usePhysicalCardImageFileApprove = buildMutationWrapper(
  physicalCardImageFileApprovePost,
  PhysicalCardImageFileQueryKey
);

export const useDigitalWalletImageFileReject = buildMutationWrapper(
  digitalWalletImageFileRejectPost,
  DigitalWalletImageFileQueryKey
);

export const usePhysicalCardImageFileReject = buildMutationWrapper(
  physicalCardImageFileRejectPost,
  PhysicalCardImageFileQueryKey
);

const LateReturnRequestQueryKey = 'operations/late_return_requests';

export const useLateReturnRequestGet = buildQueryWrapper(
  lateReturnRequestGet,
  LateReturnRequestQueryKey
);

export const useLateReturnRequestListInfinite = buildInfiniteQueryWrapper<
  LateReturnRequestListParams,
  LateReturnRequestListResponse
>(lateReturnRequestList, LateReturnRequestQueryKey);

export const useLateReturnRequestPost = buildMutationWrapper(
  lateReturnRequestPost,
  LateReturnRequestQueryKey
);

const ACHCompanyIDLookupQueryKey = 'operations/ach_company_id_lookup';

export const useACHCompanyIDLookupGet = buildQueryWrapper(
  aCHCompanyIDLookupGet,
  ACHCompanyIDLookupQueryKey
);

const FeePlanQueryKey = 'operations/fee_plans';

export const useFeePlanList = buildQueryWrapper(feePlanList, FeePlanQueryKey);

const FeeStatementQueryKey = 'operations/fee_statements';

export const useFeeStatementList = buildQueryWrapper(
  feeStatementList,
  FeeStatementQueryKey
);

const RoutingNumberQueryKey = 'operations/routing_numbers';

export const useRoutingNumberListInfinite = buildInfiniteQueryWrapper(
  routingNumberList,
  RoutingNumberQueryKey
);

export const useRoutingNumberList = buildQueryWrapper(
  routingNumberList,
  RoutingNumberQueryKey
);

export const useManagementInformationSystemBalancesList = buildQueryWrapper(
  managementInformationSystemBalancesList,
  'managementInformationSystem/balances'
);

export const useManagementInformationSystemVolumesList = buildQueryWrapper(
  managementInformationSystemVolumesList,
  'managementInformationSystem/volumes'
);

export const useManagementInformationSystemProgramCountList = buildQueryWrapper(
  managementInformationSystemProgramCountsList,
  'managementInformationSystem/program_counts'
);

export const useProofOfAuthorizationRequestSubmissionPatch =
  buildMutationWrapper(
    proofOfAuthorizationRequestSubmissionPatch,
    DocumentRequestQueryKey,
    LateReturnRequestQueryKey
  );

export const useManagementInformationSystemRiskMetricsList = buildQueryWrapper(
  managementInformationSystemRiskMetricsList,
  'managementInformationSystem/risk_metrics'
);

export const useManagementInformationSystemRevenuesList = buildQueryWrapper(
  managementInformationSystemRevenuesList,
  'managementInformationSystem/revenues'
);

export const useManagementInformationSystemAccountsList = buildQueryWrapper(
  managementInformationSystemAccountsList,
  'managementInformationSystem/accounts'
);

export const useObligationDocumentGet = buildQueryWrapper(
  obligationDocumentGet,
  'operations/obligation_document_get'
);

const AttestationQueryKey = 'operations/attestation';

export const useAttestationGet = buildQueryWrapper(
  attestationGet,
  AttestationQueryKey
);

export const useAttestationPatch = buildMutationWrapper(
  attestationPatch,
  AttestationQueryKey
);

export const useAttestationListInfinite = buildInfiniteQueryWrapper<
  AttestationListParams,
  AttestationListResponse
>(attestationList, AttestationQueryKey);

const UnusualActivityReportsQueryKey = 'operations/unusual_activity_reports';

export const useUnusualActivityReportListInfinite = buildInfiniteQueryWrapper<
  UnusualActivityReportListParams,
  UnusualActivityReportListResponse
>(unusualActivityReportList, UnusualActivityReportsQueryKey);

export const useUnusualActivityReportGet = buildQueryWrapper(
  unusualActivityReportGet,
  'operations/unusual_activity_report_get'
);

export const useFedwireInboundServiceMessagesListInfinite =
  buildInfiniteQueryWrapper<
    FedwireInboundServiceMessageListParams,
    FedwireInboundServiceMessageListResponse
  >(
    fedwireInboundServiceMessageList,
    'operations/fedwire_inbound_service_message_list'
  );

export const useFedwireInboundServiceMessagesGet = buildQueryWrapper(
  fedwireInboundServiceMessageGet,
  'operations/fedwire_inbound_service_message_get'
);

export const useFedwireInboundRequestForReversalsListInfinite =
  buildInfiniteQueryWrapper<
    FedwireInboundRequestForReversalListParams,
    FedwireInboundRequestForReversalListResponse
  >(
    fedwireInboundRequestForReversalList,
    'operations/fedwire_inbound_request_for_reversal_list'
  );

export const useFedwireInboundRequestForReversalsGet = buildQueryWrapper(
  fedwireInboundRequestForReversalGet,
  'operations/fedwire_inbound_request_for_reversal_get'
);

export const useInboundFedNowMessageListInfinite = buildInfiniteQueryWrapper<
  InboundFedNowMessageListParams,
  InboundFedNowMessageListResponse
>(inboundFedNowMessageList, 'operations/inbound_fed_now_message_list');

export const useInboundFedNowMessageGet = buildQueryWrapper(
  inboundFedNowMessageGet,
  'operations/inbound_fed_now_message_get'
);

export const useComplaintEntriesListInfinite = buildInfiniteQueryWrapper<
  ComplaintEntryListParams,
  ComplaintEntryListResponse
>(complaintEntryList, 'operations/complaint_entry_list');

export const usePlatformComplaintListInfinite = buildInfiniteQueryWrapper<
  PlatformComplaintListParams,
  PlatformComplaintListResponse
>(platformComplaintList, 'operations/platform_complaints');

export const usePlatformComplaintGet = buildQueryWrapper(
  platformComplaintGet,
  'operations/platform_complaints'
);

export const usePlatformUnusualActivityReportListInfinite =
  buildInfiniteQueryWrapper<
    PlatformUnusualActivityReportListParams,
    PlatformUnusualActivityReportListResponse
  >(
    platformUnusualActivityReportList,
    'operations/platform_unusual_activity_reports'
  );

export const usePlatformUnusualActivityReportGet = buildQueryWrapper(
  platformUnusualActivityReportGet,
  'operations/platform_unusual_activity_reports'
);

export const useBankProgramListInfinite = buildInfiniteQueryWrapper<
  BankProgramListParams,
  BankProgramListResponse
>(bankProgramList, 'operations/bank_programs');

export const useBankProgramGet = buildQueryWrapper(
  bankProgramGet,
  'operations/bank_programs'
);

export const useRampBusinessAccountOnboardingSurveyListInfinite =
  buildInfiniteQueryWrapper<
    RampBusinessAccountOnboardingSurveyListParams,
    RampBusinessAccountOnboardingSurveyListResponse
  >(
    rampBusinessAccountOnboardingSurveyList,
    'operations/expected_activity_surveys'
  );

export const useRampBusinessAccountOnboardingSurveyGet = buildQueryWrapper(
  rampBusinessAccountOnboardingSurveyGet,
  'operations/expected_activity_surveys'
);

export const useConsumerOnboardingSurveyListInfinite =
  buildInfiniteQueryWrapper<
    ConsumerOnboardingSurveyListParams,
    ConsumerOnboardingSurveyListResponse
  >(
    consumerOnboardingSurveyList,
    'operations/consumer_expected_activity_surveys'
  );

export const useConsumerOnboardingSurveyGet = buildQueryWrapper(
  consumerOnboardingSurveyGet,
  'operations/consumer_expected_activity_surveys'
);

export const useCommercialOnboardingSurveyListInfinite =
  buildInfiniteQueryWrapper<
    CommercialOnboardingSurveyListParams,
    CommercialOnboardingSurveyListResponse
  >(
    commercialOnboardingSurveyList,
    'operations/commercial_expected_activity_surveys'
  );

export const useCommercialOnboardingSurveyGet = buildQueryWrapper(
  commercialOnboardingSurveyGet,
  'operations/commercial_expected_activity_surveys'
);

export const useControlConfigurationListInfinite = buildInfiniteQueryWrapper<
  ControlConfigurationListParams,
  ControlConfigurationListResponse
>(controlConfigurationList, 'operations/control_configurations');

export const useControlConfigurationGet = buildQueryWrapper(
  controlConfigurationGet,
  'operations/control_configurations'
);

export const useControlRecordListInfinite = buildInfiniteQueryWrapper<
  ControlRecordListParams,
  ControlRecordListResponse
>(controlRecordList, 'operations/control_records');

export const useControlRecordGet = buildQueryWrapper(
  controlRecordGet,
  'operations/control_records'
);

export const useControlEvaluationListInfinite = buildInfiniteQueryWrapper<
  ControlEvaluationListParams,
  ControlEvaluationListResponse
>(controlEvaluationList, 'operations/control_evaluations');

export const useBankProgramCurrentFeePlanGet = buildQueryWrapper(
  bankProgramCurrentFeePlanGet,
  'operations/bank_program_current_fee_plan'
);

export const useDocumentRequestCancel = buildMutationWrapper(
  documentRequestCancel,
  DocumentRequestQueryKey
);

export const useDocumentRequestGet = buildQueryWrapper(
  documentRequestGet,
  DocumentRequestQueryKey
);

const DocumentRequestSchedulesQueryKey =
  'operations/document_request_schedules';

export const useDocumentRequestScheduleListEntire = buildEntireListQueryWrapper<
  DocumentRequestScheduleListResponseDataItem,
  DocumentRequestScheduleListParams,
  DocumentRequestScheduleListResponse
>(documentRequestScheduleList, DocumentRequestSchedulesQueryKey);

export const useDocumentRequestSchedulePost = buildMutationWrapper(
  documentRequestSchedulePost,
  DocumentRequestSchedulesQueryKey
);

export const useBankFeeStatementListInfinite = buildInfiniteQueryWrapper<
  BankFeeStatementListParams,
  BankFeeStatementListResponse
>(bankFeeStatementList, 'operations/bank_fee_statements');

export const useBankFeeStatementGet = buildQueryWrapper(
  bankFeeStatementGet,
  'operations/bank_fee_statements'
);

export const useOAuthApplicationListInfinite = buildInfiniteQueryWrapper<
  OAuthApplicationListParams,
  OAuthApplicationListResponse
>(oAuthApplicationList, 'operations/oauth_applications');

export const useOAuthConnectionListInfinite = buildInfiniteQueryWrapper<
  OAuthConnectionListParams,
  OAuthConnectionListResponse
>(oAuthConnectionList, 'operations/oauth_connections');

export const useOAuthApplicationGet = buildQueryWrapper(
  oAuthApplicationGet,
  'operations/oauth_applications'
);

export const usePlatformVendorListInfinite = buildInfiniteQueryWrapper<
  PlatformVendorListParams,
  PlatformVendorListResponse
>(platformVendorList, 'operations/platform_vendors');

export const usePartnerInvitationDelete = buildMutationWrapper(
  partnerInvitationDelete,
  PartnerInvitationsQueryKey
);

export const useBankFeeListInfinite = buildInfiniteQueryWrapper<
  BankFeeListParams,
  BankFeeListResponse
>(bankFeeList, 'operations/bank_fees');

export const useTransactionAccountingEntriesForTraceList = buildQueryWrapper(
  transactionAccountingEntryForTraceList,
  'operations/transaction_accounting_entries_for_trace'
);

export const useTransactionAccountingEntriesForTraceListMultiple =
  buildQueriesWrapper(
    transactionAccountingEntryForTraceList,
    'operations/transaction_accounting_entries_for_trace'
  );

export const useReserveAccountOverviewGet = buildQueryWrapper(
  reserveAccountsOverviewGet,
  'operations/reserve_account_overview'
);

export const useAccountStatementListInfinite = buildInfiniteQueryWrapper<
  AccountStatementListParams,
  AccountStatementListResponse
>(accountStatementList, 'operations/account_statements');

export const useDirectUserDiligenceSubmissionGet = buildQueryWrapper(
  directUserDiligenceSubmissionGet,
  'operations/direct_user_diligence_submission'
);

export const useCustomerIdentificationProgramTestingEntryPatch =
  buildMutationWrapper(
    customerIdentificationProgramTestingEntryPatch,
    CustomerIdentificationProgramTestingEntryQueryKey
  );

const LockboxQueryKey = 'operations/lockboxes';

export const useLockboxListInfinite = buildInfiniteQueryWrapper<
  LockboxListParams,
  LockboxListResponse
>(lockboxList, LockboxQueryKey);

export const useLockboxGet = buildQueryWrapper(lockboxGet, LockboxQueryKey);

export const useLockboxList = buildQueryWrapper(lockboxList, LockboxQueryKey);

export const useProgramInterestRatePost = buildMutationWrapper(
  programInterestRatePost,
  'operations/program_interest_rate',
  ProgramQueryKey
);
