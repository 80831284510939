import { useMemo } from 'react';
import { BadgeColor } from '@increase/shared/components/Badge';
import { IconType } from '@increase/shared/components/Icon';
import { PrestyledTableProps, Table, TableColumns } from '@increase/shared/components/Table';
import { formatISO8601Timestamp, humanize } from '@increase/shared/lib/formatting';
import { EntityReferenceListResponseDataItem } from 'src/build/operations';
import { useOperatorGet } from 'src/hooks/reactQuery';
import { buildPath, ROUTES } from 'src/lib/routes';
type EntitiesTableProps = PrestyledTableProps<EntityReferenceListResponseDataItem> & {
  showGroup: boolean;
};
const defaultRowProps = (datum: EntityReferenceListResponseDataItem) => ({
  href: buildPath(ROUTES.ENTITY, {
    entityId: datum.id
  }),
  className: 'hover:bg-main-hover cursor-pointer transition-all group'
});
const entityStatusBadgeColors: Record<EntityReferenceListResponseDataItem['status'], BadgeColor> = {
  active: 'green',
  disabled: 'red',
  archived: 'yellow'
};
const entityReviewStatusIconTypes: Record<EntityReferenceListResponseDataItem['review_status'], IconType> = {
  approved: 'tick',
  review_not_required: 'tick',
  approved_by_platform: 'tick',
  pending_reviewing: 'edit',
  requires_attention: 'alert',
  returned_to_user: 'arrow_reverse'
};
const entityReviewStatusBadgeColors: Record<EntityReferenceListResponseDataItem['review_status'], BadgeColor> = {
  pending_reviewing: 'gray',
  review_not_required: 'gray',
  approved: 'green',
  approved_by_platform: 'gray',
  returned_to_user: 'yellow',
  requires_attention: 'yellow'
};
export const EntitiesTable = (props: EntitiesTableProps) => {
  const {
    data: operator
  } = useOperatorGet({});
  const {
    getRowProps,
    showGroup,
    ...rest
  } = props;
  const columns = useMemo<TableColumns<EntityReferenceListResponseDataItem>>(() => {
    return [showGroup && {
      header: 'Group',
      contents: request => {
        return {
          text: request.group_id,
          href: buildPath(ROUTES.GROUPS_DETAIL, {
            groupId: request.group_id
          })
        };
      }
    }, {
      header: 'Name',
      expand: 1,
      contents: entity => {
        return {
          text: entity.name,
          textColor: 'emphasis',
          textWeight: 'medium'
        };
      }
    }, {
      header: 'Structure',
      contents: entity => {
        return {
          text: entity.structure
        };
      }
    }, {
      header: 'Status',
      contents: entity => {
        return {
          text: humanize(entity.status),
          badgeColor: entityStatusBadgeColors[entity.status]
        };
      }
    }, operator?.entitlements.includes('internal_read_write') && {
      header: 'Review status',
      contents: entity => {
        return {
          text: humanize(entity.review_status),
          badgeColor: entityReviewStatusBadgeColors[entity.review_status],
          icon: entityReviewStatusIconTypes[entity.review_status]
        };
      }
    }, {
      header: 'Created',
      align: 'right',
      contents: entity => {
        return {
          text: formatISO8601Timestamp(entity.created_at, 'date')
        };
      }
    }];
  }, [showGroup, operator?.entitlements]);
  return <Table {...rest} getRowProps={getRowProps ?? defaultRowProps} columns={columns} data-sentry-element="Table" data-sentry-component="EntitiesTable" data-sentry-source-file="EntitiesTable.tsx" />;
};