import React from 'react';
import { Application } from '../application';
import { ConfirmOneTimePassword } from '../confirm-one-time-password';
import { SignIn } from './sign-in';
import { Register } from '../register';
import { SingleSignOnCallback } from './SingleSignOnCallback';
import { useSessionGet } from '../../hooks/reactQuery';
import { useLocation } from 'react-router-dom';
import { useTypedSearchParams } from 'react-router-typesafe-routes/dom';
import { ROUTES } from '../../lib/routes';
export const Authentication = () => {
  const [queryParams] = useTypedSearchParams(ROUTES.ROOT);
  const location = useLocation();
  const invitationCode = queryParams.i;
  const {
    data: session,
    isLoading
  } = useSessionGet({}, {
    refetchOnWindowFocus: false
  });
  if (isLoading) {
    return <div id="loading" />;
  } else if (session) {
    return session.one_time_password_status === 'confirmed' ? <Application /> : <ConfirmOneTimePassword />;
  } else if (invitationCode) {
    return <Register invitationCode={invitationCode} />;
  } else if (location.pathname === '/authentication_callback') {
    return <SingleSignOnCallback />;
  } else {
    return <SignIn />;
  }
};