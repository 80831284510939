import { Box } from 'shared/components/Box';
import { Caption, Body } from 'shared/components/Text';
const Shortcut = ({
  keys,
  action
}: {
  keys: string[];
  action: string;
}) => {
  return <div className="flex items-center gap-2" data-sentry-component="Shortcut" data-sentry-source-file="shortcut.tsx">
      <Box data-sentry-element="Box" data-sentry-source-file="shortcut.tsx">
        <div className="flex space-x-1">
          {keys.map(k => <div key={k} className="text-subtle border-strong bg-strong rounded-sm border px-1">
              <Caption weight="normal" color="inherit">
                {k}
              </Caption>
            </div>)}
        </div>
      </Box>
      <Body data-sentry-element="Body" data-sentry-source-file="shortcut.tsx">{action}</Body>
    </div>;
};
export { Shortcut };