import { TableColumns, Table, PrestyledTableProps } from '@increase/shared/components/Table';
import { formatISO8601Timestamp } from '@increase/shared/lib/formatting';
import { formatBank } from 'src/lib/formatEnums';
import { useMemo } from 'react';
import { BankProgramGetResponse, BankProgramGetResponseClassification } from 'src/build/operations';
import { IconType } from '@increase/shared/components/Icon';
import { ROUTES, buildPath } from 'src/lib/routes';
type Props = PrestyledTableProps<BankProgramGetResponse>;
export const bankProgramClassificationIconTypes: Record<NonNullable<BankProgramGetResponseClassification>, IconType> = {
  direct_user: 'user',
  money_transmitter: 'arrows_right',
  regulated_user: 'bank',
  unlicensed_lender: 'coins_swap'
};
export const BankProgramsTable = (props: Props) => {
  const {
    getRowProps,
    ...rest
  } = props;
  const columns = useMemo<TableColumns<BankProgramGetResponse>>(() => {
    return [{
      header: 'Name',
      expand: 1,
      contents: program => ({
        text: program.name,
        textWeight: 'medium',
        textColor: 'emphasis'
      })
    } as const, {
      header: 'Bank',
      contents: program => ({
        text: formatBank(program.bank)
      })
    }, {
      header: 'Opened',
      align: 'right',
      contents: program => ({
        text: program.opened_at ? formatISO8601Timestamp(program.opened_at, 'date') : '-'
      })
    }];
  }, []);
  const defaultRowProps = (datum: BankProgramGetResponse) => ({
    href: buildPath(ROUTES.BANK_PROGRAMS_DETAIL, {
      bankProgramId: datum.id
    }),
    className: 'hover:bg-main-hover cursor-pointer transition-all group'
  });
  return <Table<BankProgramGetResponse> columns={columns} getRowProps={getRowProps ?? defaultRowProps} {...rest} data-sentry-element="Table" data-sentry-component="BankProgramsTable" data-sentry-source-file="BankProgramsTable.tsx" />;
};