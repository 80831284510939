import { ROUTES, buildPath } from 'src/lib/routes';
import { PageLayout } from './deprecated/PageLayout';
import { useAttestationListInfinite } from 'src/hooks/reactQuery';
import { AttestationListResponseDataItem } from 'src/build/operations';
import { PrestyledTableProps, Table, TableColumns } from 'shared/components/Table';
import { formatISO8601Timestamp } from 'shared/lib/formatting';
import { TableStateWrapper } from 'shared/components/TableStateWrapper';
const AttestationTable = (props: PrestyledTableProps<AttestationListResponseDataItem>) => {
  const columns: TableColumns<AttestationListResponseDataItem> = [{
    header: 'Attestation',
    contents: row => ({
      text: row.pretty_name
    })
  }, {
    header: 'Status',
    contents: row => ({
      text: row.attested_at ? 'Completed' : 'Pending',
      badgeColor: row.attested_at ? 'green' : 'yellow'
    })
  }, {
    header: 'Attestated at',
    contents: row => ({
      text: row.attested_at ? formatISO8601Timestamp(row.attested_at, 'month-day-year-hour-minute-second') : ''
    })
  }];
  const getRowProps = (datum: AttestationListResponseDataItem) => ({
    href: buildPath(ROUTES.ATTESTATIONS_DETAIL, {
      attestationId: datum.id
    }),
    className: 'hover:bg-main-hover cursor-pointer transition-all group'
  });
  return <Table<AttestationListResponseDataItem> {...props} columns={columns} getRowProps={getRowProps} data-sentry-element="Table" data-sentry-component="AttestationTable" data-sentry-source-file="attestations.tsx" />;
};
export const Attestations = () => {
  const response = useAttestationListInfinite({});
  return <PageLayout headline="My Attestations" data-sentry-element="PageLayout" data-sentry-component="Attestations" data-sentry-source-file="attestations.tsx">
      <TableStateWrapper {...response} table={AttestationTable} style="primary" emptyTitle="No attestations" emptySubtitle="There are no attestations to display" filters={[]} data-sentry-element="TableStateWrapper" data-sentry-source-file="attestations.tsx" />
    </PageLayout>;
};