import Decimal from 'decimal.js';

export type Currency = 'USD' | 'CAD' | 'CHF' | 'EUR' | 'GBP' | 'JPY';

const CURRENCY_TO_DIGITS = {
  USD: 2,
  CAD: 2,
  CHF: 2,
  EUR: 2,
  GBP: 2,
  JPY: 0,
};

function isKnownCurrency(currency: string): currency is Currency {
  return (CURRENCY_TO_DIGITS as Record<string, number>)[currency] != null;
}

export function formatAmount(
  amount: number,
  currency: Currency,
  includeSymbol = true,
  minimumFractionDigits?: number
): string {
  const digits = CURRENCY_TO_DIGITS[currency];
  return (Number(amount) / Math.pow(10, digits)).toLocaleString('en-US', {
    minimumFractionDigits:
      minimumFractionDigits == null ? digits : minimumFractionDigits,
    style: includeSymbol ? 'currency' : undefined,
    currency,
  });
}

export function formatDecimalMinorAmount(
  amount: string,
  currency: Currency,
  includeSymbol = true
): string {
  const digits = CURRENCY_TO_DIGITS[currency];
  return (Number(amount) / Math.pow(10, digits)).toLocaleString('en-US', {
    minimumFractionDigits: digits,
    style: includeSymbol ? 'currency' : undefined,
    currency,
  });
}

export function formatRoundedAmount(
  amount: number,
  currency: Currency,
  includeSymbol = true,
  minimumFractionDigits?: number
): string {
  const digits = CURRENCY_TO_DIGITS[currency];
  const value = Number(amount) / Math.pow(10, digits);
  const roundedDigits =
    minimumFractionDigits == null ? digits : minimumFractionDigits;

  let display = value;
  let suffix = '';

  if (value >= 1e6) {
    display = value / 1e6;
    suffix = 'M';
  } else if (value >= 1e3) {
    display = value / 1e3;
    suffix = 'K';
  }

  display =
    Math.round(display * Math.pow(10, roundedDigits)) /
    Math.pow(10, roundedDigits);

  const formattedValue = display.toLocaleString('en-US', {
    minimumFractionDigits: roundedDigits,
    style: includeSymbol ? 'currency' : undefined,
    currency,
  });

  return formattedValue + suffix;
}

export function formatVeryAccurateAmount(
  amount: number,
  currency: Currency,
  minimumFractionDigits: number,
  includeSymbol = true
): string {
  const digits = CURRENCY_TO_DIGITS[currency];
  return (Number(amount) / Math.pow(10, digits)).toLocaleString('en-US', {
    minimumFractionDigits: minimumFractionDigits,
    style: includeSymbol ? 'currency' : undefined,
    currency,
  });
}

export function dangerousFormatInternationalAmount(
  amount: number,
  currency: string
): string {
  if (isKnownCurrency(currency)) {
    return formatAmount(amount, currency, true);
  }

  try {
    return (amount / 100).toLocaleString('en-US', {
      style: 'currency',
      minimumFractionDigits: 2,
      currency,
    });
  } catch (e) {
    return `${amount} ${currency}`;
  }
}

export function formatAccuratePercentage(
  input: string,
  rounding?: number
): string {
  const initial = new Decimal(input);
  const full = `${initial.times(100)}%`;
  if (!rounding) {
    return full;
  }
  const rounded = `${initial.times(100).toFixed(rounding)}%`;
  return full.length < rounded.length ? full : rounded;
}

export function formatPercentage(amount: number): string {
  return `${(amount * 100).toFixed(2)}%`;
}

export function formatInteger(amount: number): string {
  return amount.toLocaleString('en-US');
}

export type DateFormat =
  | 'time'
  | 'date'
  | 'month-day-hour-minute'
  | 'month-day-hour-minute-second'
  | 'month-day-year-hour-minute'
  | 'month-day-year-hour-minute-second'
  | 'month-year';

const DATE_OPTIONS: Record<DateFormat, Intl.DateTimeFormatOptions> = {
  time: { dateStyle: undefined, timeStyle: 'long' },
  date: { dateStyle: 'medium' },
  'month-day-hour-minute': {
    month: 'short',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
  },
  'month-day-hour-minute-second': {
    month: 'short',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    second: 'numeric',
  },
  'month-day-year-hour-minute': {
    month: 'short',
    day: 'numeric',
    year: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
  },
  'month-day-year-hour-minute-second': {
    month: 'short',
    day: 'numeric',
    year: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    second: 'numeric',
  },
  'month-year': { month: 'short', year: 'numeric' },
};

type SupportedTimeZone = 'UTC' | 'America/New_York';

export const formatISO8601TimestampAsISODate = (
  iso8601Timestamp: string
): string => new Date(iso8601Timestamp).toISOString().substring(0, 10);

export const formatJSDate = (
  date: Date,
  format: DateFormat,
  timeZone: SupportedTimeZone | undefined = undefined
): string =>
  new Intl.DateTimeFormat('en-US', {
    ...DATE_OPTIONS[format],
    timeZone,
  }).format(date);

const DATE_PATTERN = 'YYYY-MM-DD';

export const formatISO8601Timestamp = (
  iso8601Timestamp: string,
  format: DateFormat,
  timeZone: SupportedTimeZone | undefined = undefined
): string => {
  if (iso8601Timestamp.length === DATE_PATTERN.length && timeZone == null) {
    timeZone = 'UTC';
  }
  return formatJSDate(new Date(iso8601Timestamp), format, timeZone);
};

export const formatYearMonth = (args: {
  month: number;
  year: number;
}): string =>
  `${args.month.toString().padStart(2, '0')}/${args.year.toString().slice(-2)}`;

const capitalizeExceptions = ['ACH', 'ID', 'API'];
const lowercasedExceptions = capitalizeExceptions.map((x) => x.toLowerCase());

export const capitalize = (arg: string): string => {
  if (arg === '') {
    return '';
  }

  const index = lowercasedExceptions.indexOf(arg.toLowerCase());
  return index === -1
    ? arg[0].toUpperCase() + arg.substring(1)
    : capitalizeExceptions[index];
};

export function sentenceCase(str: string): string {
  if (str === '') {
    return '';
  }

  const words = str.replace(/[-_]/g, ' ').split(' ');
  return words
    .map((word, index) => {
      if (capitalizeExceptions.includes(word.toUpperCase())) {
        return word.toUpperCase();
      }

      return index === 0
        ? word[0].toUpperCase() + word.slice(1).toLowerCase()
        : word.toLowerCase();
    })
    .join(' ');
}

export const humanize = (arg: string): string =>
  sentenceCase(
    arg
      .split(/[^a-zA-Z0-9]|([0-9]+)/)
      .filter((chunk) => chunk != null && chunk.length > 0)
      .join(' ')
  );

export const humanizeList = (arg: string[]): string => {
  if (arg.length === 1) {
    return arg[0];
  } else {
    return `${arg.slice(0, -1).join(', ')} and ${arg[arg.length - 1]}`;
  }
};

export const summarizeList = (arg: string[]): string => {
  if (arg.length > 4) {
    arg = [...arg.slice(0, 3), `${arg.length - 3} more`];
  }
  return humanizeList(arg);
};

export function formatAddress(
  address:
    | ({
        line1: string | null | undefined;
        line2?: string | null | undefined;
        city: string | null | undefined;
        state: string | null | undefined;
        country?: string | null | undefined;
      } & (
        | { zip: string | null | undefined }
        | { postal_code: string | null | undefined }
      ))
    | {
        address_line1: string | null;
        address_line2: string | null;
        address_city: string | null;
        address_state: string | null;
        address_zip: string | null;
      },
  multiline = false
): string {
  const glue = multiline ? '\n' : ', ';
  if ('line1' in address) {
    const zip = 'zip' in address ? address.zip : address.postal_code;
    const cityStateLine = [
      address.city && `${address.city}, `,
      address.state && `${address.state} `,
      zip,
      address.country && `, ${address.country}`,
    ]
      .filter((e) => e)
      .join('')
      .trim();
    return [address.line1, address.line2, cityStateLine]
      .filter((e) => e)
      .join(glue);
  } else {
    const cityStateLine = [
      address.address_city && `${address.address_city}, `,
      address.address_state && `${address.address_state} `,
      address.address_zip,
    ]
      .filter((e) => e)
      .join('')
      .trim();
    return [address.address_line1, address.address_line2, cityStateLine]
      .filter((e) => e)
      .join(glue);
  }
}

export type TransferType =
  | 'wire_transfer'
  | 'ach_transfer'
  | 'account_transfer'
  | 'check_transfer'
  | 'inbound_ach_transfer'
  | 'inbound_wire_transfer'
  | 'real_time_payments_transfer'
  | 'inbound_real_time_payments_transfer';

export const formatTransferType = (type: TransferType): string =>
  ({
    wire_transfer: 'Wire Transfer',
    ach_transfer: 'ACH Transfer',
    account_transfer: 'Account Transfer',
    check_transfer: 'Check Transfer',
    inbound_ach_transfer: 'Inbound ACH Transfer',
    inbound_wire_transfer: 'Inbound Wire Transfer',
    real_time_payments_transfer: 'RTP Transfer',
    inbound_real_time_payments_transfer: 'Inbound RTP Transfer',
  })[type];

export const parseToUSD = (value: number | undefined): number | undefined =>
  value != null ? Math.round(value * 100) : undefined;

export const formatUSPhoneNumber = (phone: string): string => {
  const match = phone.replace(/[^0-9]/g, '').match(/1?(\d{3})(\d{3})(\d{4})/);
  if (match == null) {
    return phone;
  }
  return `+1 (${match[1]}) ${match[2]}-${match[3]}`;
};
