import { PageLayout } from '../deprecated/PageLayout';
import { TableStateWrapper } from '@increase/shared/components/TableStateWrapper';
import { ROUTES, buildPath } from 'src/lib/routes';
import { SanctionsScreeningReviewListResponseDataItem } from 'src/build/operations';
import { useSanctionsScreeningReviewListInfinite } from 'src/hooks/reactQuery';
import { SanctionsScreeningReviewsTable } from '../tables/SanctionScreeningReviewsTable';
const getRowProps = (datum: SanctionsScreeningReviewListResponseDataItem) => ({
  href: buildPath(ROUTES.SANCTIONS_SCREENING_REVIEW_DETAIL, {
    reviewID: datum.id
  }),
  className: 'hover:bg-main-hover cursor-pointer transition-all group'
});
export const TransferSanctionsScreeningReviewsListPage = () => {
  const listResponse = useSanctionsScreeningReviewListInfinite({
    statuses: ['pending_reviewing'],
    record_types: ['transfer']
  });
  return <PageLayout headline="Pending Transfer Sanctions Screening Reviews" data-sentry-element="PageLayout" data-sentry-component="TransferSanctionsScreeningReviewsListPage" data-sentry-source-file="TransferSanctionsScreeningReviewsListPage.tsx">
      <TableStateWrapper {...listResponse} table={SanctionsScreeningReviewsTable} style="primary" emptyTitle="No items pending review" emptySubtitle="" filters={[]} getRowProps={getRowProps} showRecordId={true} data-sentry-element="TableStateWrapper" data-sentry-source-file="TransferSanctionsScreeningReviewsListPage.tsx" />
    </PageLayout>;
};