import React, { useMemo } from 'react';
import { PageLayout } from '../deprecated/PageLayout';
import { useLockboxListInfinite } from 'src/hooks/reactQuery';
import { TableStateWrapper } from '@increase/shared/components/TableStateWrapper';
import { compact } from 'lodash';
import { TableFilter } from '@increase/shared/components/Table/filters';
import { LockboxGetResponseStatus } from 'src/build/operations';
import { makeStatusFilter, makeGroupFilter, makeAccountFilter, makeKeywordFilter, makeTimeRangeFilter } from 'src/lib/tableFilterHelpers';
import { DateTime } from 'luxon';
import { ROUTES } from 'src/lib/routes';
import { useSearchParamsState } from '@increase/shared/hooks/useTypedSearchParamsState';
import { useTypedCreatedAtRangeParams } from 'src/hooks/timeSearchParameters';
import { LockboxesTable } from '../tables/LockboxesTable';
const lockboxStatuses: Record<LockboxGetResponseStatus, string> = {
  active: 'Active',
  inactive: 'Inactive'
} as const;
export const LockboxListPage = () => {
  const [groupFilter, setGroupFilter] = useSearchParamsState(ROUTES.LOCKBOX_LIST, 'group');
  const [statuses, setStatuses] = useSearchParamsState(ROUTES.LOCKBOX_LIST, 'statuses');
  const [accountFilter, setAccountFilter] = useSearchParamsState(ROUTES.LOCKBOX_LIST, 'account');
  const [keywordFilter, setKeywordFilter] = useSearchParamsState(ROUTES.LOCKBOX_LIST, 'keyword');
  const [{
    startAt,
    endAt
  }, setTimeRange] = useTypedCreatedAtRangeParams(ROUTES.LOCKBOX_LIST);
  const startAtDateTime = startAt ? DateTime.fromISO(startAt, {
    zone: 'utc'
  }) : undefined;
  const endAtDateTime = endAt ? DateTime.fromISO(endAt, {
    zone: 'utc'
  }) : undefined;
  const filters: TableFilter[] = useMemo(() => compact([makeStatusFilter(statuses, setStatuses, lockboxStatuses), makeGroupFilter(groupFilter, setGroupFilter), makeAccountFilter(accountFilter, setAccountFilter), makeKeywordFilter(keywordFilter, setKeywordFilter), makeTimeRangeFilter({
    startAt,
    endAt
  }, setTimeRange, 'Created')]), [accountFilter, endAt, groupFilter, keywordFilter, setAccountFilter, setGroupFilter, setKeywordFilter, setStatuses, setTimeRange, startAt, statuses]);
  const listResult = useLockboxListInfinite({
    statuses: statuses.length > 0 ? statuses : undefined,
    groups: groupFilter.length > 0 ? groupFilter : undefined,
    account_ids: accountFilter.length > 0 ? accountFilter : undefined,
    keyword: keywordFilter ? keywordFilter : undefined,
    created_at_or_after: startAtDateTime?.startOf('day').toISO() ?? undefined,
    created_at_or_before: endAtDateTime?.endOf('day').toISO() ?? undefined
  });
  return <PageLayout headline={'Lockboxs'} data-sentry-element="PageLayout" data-sentry-component="LockboxListPage" data-sentry-source-file="LockboxListPage.tsx">
      <TableStateWrapper table={LockboxesTable} style="primary" {...listResult} emptyTitle="No Lockboxes" emptySubtitle="There are no Lockboxes to display" filters={filters} showGroup showAccount data-sentry-element="TableStateWrapper" data-sentry-source-file="LockboxListPage.tsx" />
    </PageLayout>;
};