import React from 'react';
import { InternalOperatorOnly } from './internal-operator-only';
import { useComplaintEntriesListInfinite } from 'src/hooks/reactQuery';
import { ComplaintEntryListResponseDataItem } from 'src/build/operations';
import { TableStateWrapper } from '@increase/shared/components/TableStateWrapper';
import { PrestyledTableProps, Table, TableColumns } from '@increase/shared/components/Table';
import { formatISO8601Timestamp } from '@increase/shared/lib/formatting';
import { buildPath, ROUTES } from 'src/lib/routes';
const ComplaintEntryTable = (props: PrestyledTableProps<ComplaintEntryListResponseDataItem>) => {
  const columns: TableColumns<ComplaintEntryListResponseDataItem> = [{
    header: 'Month / Year',
    contents: row => {
      return {
        text: formatISO8601Timestamp(row.created_at, 'month-year')
      };
    }
  }, {
    header: 'ID',
    contents: row => ({
      text: row.id
    })
  }, {
    header: 'Received on',
    contents: row => ({
      text: row.received_on ? formatISO8601Timestamp(row.received_on, 'date', 'UTC') : ''
    })
  }, {
    header: 'Resolved on',
    contents: row => ({
      text: row.resolved_on ? formatISO8601Timestamp(row.resolved_on, 'date', 'UTC') : ''
    })
  }, {
    header: 'Group',
    contents: row => ({
      text: row.group_id,
      href: buildPath(ROUTES.GROUPS_DETAIL, {
        groupId: row.group_id
      })
    })
  }, {
    header: 'Type',
    contents: r => ({
      text: r.complaint_type
    })
  }, {
    header: 'Source',
    contents: r => ({
      text: r.source
    })
  }, {
    header: 'Description',
    contents: r => ({
      text: r.description
    })
  }];
  return <Table {...props} columns={columns} data-sentry-element="Table" data-sentry-component="ComplaintEntryTable" data-sentry-source-file="complaint-entries.tsx" />;
};
export const DirectComplaintEntries = () => {
  const listResponse = useComplaintEntriesListInfinite({});
  return <InternalOperatorOnly data-sentry-element="InternalOperatorOnly" data-sentry-component="DirectComplaintEntries" data-sentry-source-file="complaint-entries.tsx">
      <TableStateWrapper {...listResponse} table={ComplaintEntryTable} emptyTitle="Complaints" emptySubtitle="No complaints." style="primary" filters={[]} data-sentry-element="TableStateWrapper" data-sentry-source-file="complaint-entries.tsx" />
    </InternalOperatorOnly>;
};