import { useMemo } from 'react';
import { BadgeColor } from '@increase/shared/components/Badge';
import { PrestyledTableProps, Table, TableColumns } from '@increase/shared/components/Table';
import { formatAmount, formatISO8601Timestamp } from '@increase/shared/lib/formatting';
import { must } from '@increase/shared/lib/must';
import { DocumentRequestListResponseDataItemStatus, LateReturnRequestListResponseDataItem } from 'src/build/operations';
import { buildPath, ROUTES } from 'src/lib/routes';
type LateReturnRequestTableProps = PrestyledTableProps<LateReturnRequestListResponseDataItem>;
export const documentRequestStatusBadgeColors: Record<DocumentRequestListResponseDataItemStatus, BadgeColor> = {
  awaiting_submission: 'blue',
  accepted: 'green',
  canceled: 'gray',
  awaiting_review: 'yellow',
  provisionally_accepted: 'green'
};
const defaultRowProps = (datum: LateReturnRequestListResponseDataItem) => ({
  href: buildPath(ROUTES.LATE_RETURN_REQUESTS_DETAIL, {
    lateReturnRequestId: datum.id
  }),
  className: 'hover:bg-main-hover cursor-pointer transition-all group'
});
export const LateReturnRequestsTable = (props: LateReturnRequestTableProps) => {
  const {
    getRowProps
  } = props;
  const columns = useMemo<TableColumns<LateReturnRequestListResponseDataItem>>(() => {
    return [{
      header: 'Group',
      contents: request => {
        return {
          text: request.group_id,
          href: buildPath(ROUTES.GROUPS_DETAIL, {
            groupId: request.group_id
          })
        };
      }
    }, {
      header: 'Contact',
      contents: request => ({
        text: request.contact_email ?? must(request.contact_fax)
      })
    }, {
      header: 'Status',
      contents: request => {
        return {
          text: request.document_request.status,
          badgeColor: documentRequestStatusBadgeColors[request.document_request.status]
        };
      }
    }, {
      header: 'Count',
      contents: request => ({
        text: request.ach_transfers.length.toString()
      })
    }, {
      header: 'Total Amount',
      contents: request => ({
        text: formatAmount(-request.ach_transfers.reduce((sum, transfer) => sum + transfer.amount, 0), 'USD')
      })
    }, {
      header: 'Created',
      contents: log => ({
        text: formatISO8601Timestamp(log.created_at, 'date')
      })
    }, {
      header: 'Due On',
      align: 'right',
      contents: request => ({
        text: formatISO8601Timestamp(request.due_on, 'date')
      })
    }];
  }, []);
  return <Table {...props} getRowProps={getRowProps ?? defaultRowProps} columns={columns} data-sentry-element="Table" data-sentry-component="LateReturnRequestsTable" data-sentry-source-file="LateReturnRequestsTable.tsx" />;
};