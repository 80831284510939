import React from 'react';
import { useManagementInformationSystemBalancesList, useManagementInformationSystemProgramCountList, useManagementInformationSystemVolumesList, useManagementInformationSystemRevenuesList, useOperatorGet } from 'src/hooks/reactQuery';
import { ManagementInformationSystemBalancesListParams } from 'src/build/operations';
import { ProgramSummary } from './program-summary';
import { ProgramCounts } from './program-counts';
import { ROUTES } from 'src/lib/routes';
import { useSearchParamsState } from '@increase/shared/hooks/useTypedSearchParamsState';
export const OverviewPage = () => {
  const [atTime] = useSearchParamsState(ROUTES.MANAGEMENT_INFORMATION_SYSTEM, 'at_time');
  const [period] = useSearchParamsState(ROUTES.MANAGEMENT_INFORMATION_SYSTEM, 'lookback_period');
  const params: ManagementInformationSystemBalancesListParams = {
    at_time: atTime,
    period: period
  };
  const {
    data: balanceData
  } = useManagementInformationSystemBalancesList(params);
  const {
    data: volumeData
  } = useManagementInformationSystemVolumesList(params);
  const {
    data: programCountData
  } = useManagementInformationSystemProgramCountList(params);
  const {
    data: revenuesData
  } = useManagementInformationSystemRevenuesList(params);
  const {
    data: operator
  } = useOperatorGet({});
  return <>
      {balanceData && revenuesData && volumeData && programCountData ? <div className="flex-col space-y-4">
          <ProgramSummary balances={balanceData.data} volumes={volumeData.data} revenues={revenuesData.data} renderRevenue={!!operator && operator.entitlements.includes('internal_read_write')} currentPeriodDate={atTime} previousPeriodDate={atTime} period={period} renderChart={!!operator && operator.entitlements.includes('internal_read_write')} />
          <ProgramCounts data={programCountData.data} period={period} />
        </div> : <h3>Loading</h3>}
    </>;
};