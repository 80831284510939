import { useBankFeeStatementListInfinite, useBankProgramCurrentFeePlanGet, useBankProgramGet, useOperatorGet, useProgramListInfinite } from 'src/hooks/reactQuery';
import { PageLayout } from '../deprecated/PageLayout';
import { SideBySide } from '@increase/shared/components/SideBySide';
import { Loading } from '@increase/shared/components/Loading';
import { BankProgramPropertyList } from '../property-lists/BankProgramPropertyList';
import { TableStateWrapper } from '@increase/shared/components/TableStateWrapper';
import { ProgramsTable } from '../tables/ProgramsTable';
import { useTypedParams } from 'react-router-typesafe-routes/dom';
import { ROUTES, buildPath } from 'src/lib/routes';
import { BankFeePlanPropertyList } from '../property-lists/BankFeePlanPropertyList';
import { BankFeeStatementsTable } from '../tables/BankFeeStatementsTable';
export const BankProgramDetailPage = () => {
  const {
    bankProgramId
  } = useTypedParams(ROUTES.BANK_PROGRAMS_DETAIL);
  const {
    data: bankProgram
  } = useBankProgramGet(bankProgramId);
  const {
    data: currentFeePlan
  } = useBankProgramCurrentFeePlanGet(bankProgramId);
  const programList = useProgramListInfinite({
    bank_programs: [bankProgramId],
    limit: 5
  });
  const feeStatementList = useBankFeeStatementListInfinite({
    bank_program_id: bankProgramId
  });
  const {
    data: operator
  } = useOperatorGet({});
  if (!bankProgram || !operator) {
    return <Loading />;
  }
  return <PageLayout headline={bankProgram.name} data-sentry-element="PageLayout" data-sentry-component="BankProgramDetailPage" data-sentry-source-file="BankProgramDetailPage.tsx">
      <SideBySide mode="rightDrawer" right={<BankProgramPropertyList bankProgram={bankProgram} title="Summary" />} left={<>
            <TableStateWrapper table={ProgramsTable} {...programList} style="detail" title="Programs" emptyTitle="No Programs" emptySubtitle="There are no programs to display" viewMoreHref={buildPath(ROUTES.PROGRAMS_LIST, {}, {
        bank_program: [bankProgramId]
      })} showGroup />
            {currentFeePlan && <>
                <BankFeePlanPropertyList bankFeePlan={currentFeePlan} title="Current Bank Fee Plan" />
                <TableStateWrapper table={BankFeeStatementsTable} {...feeStatementList} style="detail" title="Fee Statements" emptyTitle="No Fee Statements" emptySubtitle="There are no fee statements to display" viewMoreHref={''} />
              </>}
          </>} data-sentry-element="SideBySide" data-sentry-source-file="BankProgramDetailPage.tsx" />
    </PageLayout>;
};