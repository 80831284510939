import { useMemo } from 'react';
import { PrestyledTableProps, Table, TableColumns } from '@increase/shared/components/Table';
import { formatAmount, formatISO8601Timestamp } from '@increase/shared/lib/formatting';
import { PendingTransactionListResponseDataItem } from 'src/build/operations';
import { ROUTES, buildPath } from 'src/lib/routes';
type PendingTransactionsTableProps = PrestyledTableProps<PendingTransactionListResponseDataItem> & {
  showAccount: boolean;
  showGroup: boolean;
  showRoute: boolean;
};
const defaultRowProps = (datum: PendingTransactionListResponseDataItem) => ({
  href: buildPath(ROUTES.PENDING_TRANSACTION_DETAIL, {
    pendingTransactionID: datum.id
  }),
  className: 'hover:bg-main-hover cursor-pointer transition-all group'
});
const hrefForRoute = (txn: PendingTransactionListResponseDataItem): string | undefined => {
  if (!txn.route) {
    return undefined;
  }
  if (txn.route.type === 'account_number') {
    return buildPath(ROUTES.ACCOUNT_NUMBERS_DETAILS, {
      accountNumberId: txn.route.id
    });
  }
  if (txn.route.type === 'card') {
    return buildPath(ROUTES.CARD, {
      cardID: txn.route.id
    });
  }
  return undefined;
};
export const PendingTransactionsTable = (props: PendingTransactionsTableProps) => {
  const {
    getRowProps
  } = props;
  const columns = useMemo<TableColumns<PendingTransactionListResponseDataItem>>(() => {
    return [{
      header: 'Created',
      mobileHeader: 'Details',
      contents: transaction => ({
        text: formatISO8601Timestamp(transaction.date, 'month-day-year-hour-minute-second', 'UTC')
      }),
      mobileContents: txn => {
        return {
          text: txn.description,
          textWeight: 'bold',
          caption: txn.source_type.replaceAll('API::', '')
        };
      }
    }, {
      header: 'Description',
      expand: 1,
      contents: transaction => ({
        text: transaction.description,
        textWeight: 'medium',
        textColor: 'emphasis'
      })
    }, {
      header: 'Category',
      expand: 1,
      contents: transaction => ({
        text: transaction.source_type.replaceAll('API::', '')
      })
    }, props.showGroup && {
      header: 'Group',
      contents: txn => {
        return {
          text: txn.group_id,
          href: buildPath(ROUTES.GROUPS_DETAIL, {
            groupId: txn.group_id
          })
        };
      }
    }, props.showAccount && {
      header: 'Account',
      contents: txn => ({
        text: txn.account_id,
        href: buildPath(ROUTES.ACCOUNTS_DETAIL, {
          accountId: txn.account_id
        })
      })
    }, props.showRoute && {
      header: 'Route',
      expand: 1,
      contents: txn => ({
        text: txn.route?.id ?? '',
        href: hrefForRoute(txn)
      })
    }, {
      header: 'Amount',
      align: 'right',
      contents: txn => {
        return {
          text: formatAmount(txn.amount, 'USD'),
          textColor: 'emphasis',
          textWeight: 'bold'
        };
      },
      mobileContents: txn => {
        return {
          text: formatAmount(txn.amount, 'USD'),
          textWeight: 'bold',
          caption: formatISO8601Timestamp(txn.date, 'month-day-year-hour-minute-second', 'UTC')
        };
      }
    }];
  }, [props.showAccount, props.showGroup, props.showRoute]);
  return <Table {...props} getRowProps={getRowProps ?? defaultRowProps} columns={columns} data-sentry-element="Table" data-sentry-component="PendingTransactionsTable" data-sentry-source-file="PendingTransactionsTable.tsx" />;
};