import React, { useMemo } from 'react';
import { PageLayout } from '../deprecated/PageLayout';
import { useAccountStatementListInfinite } from 'src/hooks/reactQuery';
import { TableStateWrapper } from '@increase/shared/components/TableStateWrapper';
import { AccountStatementTable } from '../tables/AccountStatementTable';
import { TableFilter } from '@increase/shared/components/Table/filters';
import { makeAccountFilter } from 'src/lib/tableFilterHelpers';
import { ROUTES } from 'src/lib/routes';
import { useSearchParamsState } from '@increase/shared/hooks/useTypedSearchParamsState';
export const AccountStatementListPage = () => {
  const [accounts, setAccounts] = useSearchParamsState(ROUTES.ACCOUNT_STATEMENTS_LIST, 'accounts');
  const listResult = useAccountStatementListInfinite({
    accounts: accounts.length > 0 ? accounts : undefined
  });
  const filters: TableFilter[] = useMemo(() => [makeAccountFilter(accounts, setAccounts)], [accounts, setAccounts]);
  return <PageLayout headline={'Account Statements'} data-sentry-element="PageLayout" data-sentry-component="AccountStatementListPage" data-sentry-source-file="AccountStatementListPage.tsx">
      <TableStateWrapper table={AccountStatementTable} style="primary" {...listResult} emptyTitle="No Statements" emptySubtitle="There are no statements to display" filters={filters} data-sentry-element="TableStateWrapper" data-sentry-source-file="AccountStatementListPage.tsx" />
    </PageLayout>;
};