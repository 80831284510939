import { useBankFeeListInfinite, useBankFeeStatementGet, useBankProgramCurrentFeePlanGet, useBankProgramGet } from 'src/hooks/reactQuery';
import { PageLayout } from '../deprecated/PageLayout';
import { SideBySide } from '@increase/shared/components/SideBySide';
import { Loading } from '@increase/shared/components/Loading';
import { useTypedParams } from 'react-router-typesafe-routes/dom';
import { ROUTES } from 'src/lib/routes';
import { PropertyList } from '@increase/shared/components/PropertyList';
import { formatAmount, formatISO8601Timestamp, humanize } from '@increase/shared/lib/formatting';
import { TableStateWrapper } from '@increase/shared/components/TableStateWrapper';
import { BankFeesTable } from '../tables/BankFeesTable';
import { compact } from 'lodash';
import { useSearchParamsState } from 'shared/hooks/useTypedSearchParamsState';
import { useMemo } from 'react';
import { TableFilter } from 'shared/components/Table/filters';
import { makeFeeTypeFilter } from 'src/lib/tableFilterHelpers';
export const BankFeeStatementDetailPage = () => {
  const {
    bankFeeStatementId
  } = useTypedParams(ROUTES.BANK_FEE_STATEMENTS_DETAIL);
  const [feeTypeFilter, setFeeTypeFilter] = useSearchParamsState(ROUTES.BANK_FEE_STATEMENTS_DETAIL, 'feeType');
  const filters: TableFilter[] = useMemo(() => [makeFeeTypeFilter(feeTypeFilter, setFeeTypeFilter)], [feeTypeFilter, setFeeTypeFilter]);
  const {
    data: bankFeeStatement
  } = useBankFeeStatementGet(bankFeeStatementId);
  const {
    data: bankProgram
  } = useBankProgramGet(bankFeeStatement?.bank_program_id ?? '', {
    enabled: !!bankFeeStatement
  });
  const {
    data: feePlan
  } = useBankProgramCurrentFeePlanGet(bankFeeStatement?.bank_program_id ?? '', {
    enabled: !!bankFeeStatement
  });
  const bankFeeListResult = useBankFeeListInfinite({
    bank_fee_statement_id: bankFeeStatementId,
    fee_type: feeTypeFilter ? feeTypeFilter : undefined
  });
  if (!bankFeeStatement || !feePlan || !bankProgram) {
    return <Loading />;
  }
  const title = bankProgram.name + ' ' + formatISO8601Timestamp(bankFeeStatement.period_start, 'month-year', 'UTC');
  const totalFees = bankFeeStatement.cached_bank_fee_statement_summaries.reduce((acc, summary) => acc + summary.amount, 0);
  return <PageLayout headline={title} data-sentry-element="PageLayout" data-sentry-component="BankFeeStatementDetailPage" data-sentry-source-file="BankFeeStatementDetailPage.tsx">
      <SideBySide mode="rightDrawer" right={<PropertyList title="Summary" items={compact([{
      label: 'Status',
      value: bankFeeStatement.status,
      badgeColor: bankFeeStatement.status === 'open' ? 'blue' : 'gray'
    }, {
      label: 'Total Bank Fees (not including Service Fee)',
      value: formatAmount(totalFees, 'USD')
    }, ...bankFeeStatement.cached_bank_fee_statement_summaries.map(summary => ({
      label: humanize(summary.fee_type),
      value: `${formatAmount(summary.amount, 'USD')} (${summary.count})`
    })), bankFeeStatement.cached_service_fee_total !== null && {
      label: 'Service Fee',
      value: formatAmount(bankFeeStatement.cached_service_fee_total, 'USD')
    }])} />} left={<TableStateWrapper {...bankFeeListResult} style="primary" table={BankFeesTable} emptyTitle="No Fees" emptySubtitle="There are no fees to display" filters={filters} />} data-sentry-element="SideBySide" data-sentry-source-file="BankFeeStatementDetailPage.tsx" />
    </PageLayout>;
};