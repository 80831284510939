import { useState } from 'react';
import { useCustomerIdentificationProgramTestingEntryGet, useCustomerIdentificationProgramTestingEntryPatch, useGroupGet } from 'src/hooks/reactQuery';
import { PageLayout } from '../deprecated/PageLayout';
import { SideBySide } from '@increase/shared/components/SideBySide';
import { Loading } from '@increase/shared/components/Loading';
import { ListItem, PropertyList } from '@increase/shared/components/PropertyList';
import { evaluationColors, testingStatusColors } from '../tables/CustomerIdentificationProgramTestingEntryTable';
import { Button } from '@increase/shared/components/Button';
import { compact } from 'lodash';
import { humanize } from '@increase/shared/lib/formatting';
import { useTypedParams } from 'react-router-typesafe-routes/dom';
import { ROUTES, buildPath } from 'src/lib/routes';
import { Box } from '@increase/shared/components/Box';
import { Select, SelectSection } from '@increase/shared/components/Select';
import { Body, Heading } from '@increase/shared/components/Text';
import { TextArea } from '@increase/shared/components/TextArea';
import { FormRowStyling } from '@increase/shared/components/FormRowStyling';
import { CustomerIdentificationProgramTestingEntryListStatusesItem, CustomerIdentificationProgramTestingEntryGetResponse, CustomerIdentificationProgramTestingEntryPatchParameters } from 'src/build/operations';
import { RequireEntitlements } from '../require-entitlements';
const CustomerIdentificationProgramTestingEntryUpdateForm = ({
  entryId
}: {
  entryId: CustomerIdentificationProgramTestingEntryGetResponse['id'];
}) => {
  const mutation = useCustomerIdentificationProgramTestingEntryPatch();
  const [formState, setFormState] = useState<CustomerIdentificationProgramTestingEntryPatchParameters>({});
  const handleSubmit = async () => {
    await mutation.mutateAsync([entryId, formState]);
    setFormState({});
  };
  const statusSelections: SelectSection<CustomerIdentificationProgramTestingEntryListStatusesItem | null>[] = [{
    options: Object.values(CustomerIdentificationProgramTestingEntryListStatusesItem).map(status => ({
      value: status,
      label: humanize(status)
    }))
  }];
  return <Box data-sentry-element="Box" data-sentry-component="CustomerIdentificationProgramTestingEntryUpdateForm" data-sentry-source-file="CustomerIdentificationProgramTestingEntryDetailPage.tsx">
      <Heading contents="Action this entity" data-sentry-element="Heading" data-sentry-source-file="CustomerIdentificationProgramTestingEntryDetailPage.tsx" />
      <Body contents="Update a customer identification program testing entry during the test." data-sentry-element="Body" data-sentry-source-file="CustomerIdentificationProgramTestingEntryDetailPage.tsx" />
      <FormRowStyling label="Testing Status" labelPlacement="above" field={<Select<CustomerIdentificationProgramTestingEntryListStatusesItem | null> data-testid="UpdateCustomerIdentificationProgramTestingEntry.testing_status_select" id={'UpdateCustomerIdentificationProgramTestingEntry.testing_status'} name="Testing Status" value={formState.testing_status} onUpdate={testingStatus => setFormState({
      ...formState,
      testing_status: testingStatus ?? undefined
    })} placeholder="Select status..." sections={statusSelections} />} data-sentry-element="FormRowStyling" data-sentry-source-file="CustomerIdentificationProgramTestingEntryDetailPage.tsx" />
      <FormRowStyling label="Note" labelPlacement="above" field={<TextArea placeholder="Note" value={formState.note} onChange={e => {
      setFormState({
        ...formState,
        note: e.target.value
      });
    }} />} data-sentry-element="FormRowStyling" data-sentry-source-file="CustomerIdentificationProgramTestingEntryDetailPage.tsx" />
      {mutation.isError && <Box>
          <Body color="red">
            {mutation.error.response?.data.message ?? mutation.error.message}
          </Body>
        </Box>}
      <FormRowStyling field={<Button disabled={mutation.isLoading} onClick={handleSubmit} text={'Update'} />} data-sentry-element="FormRowStyling" data-sentry-source-file="CustomerIdentificationProgramTestingEntryDetailPage.tsx" />
    </Box>;
};
export const CustomerIdentificationProgramTestingEntryDetailPage = () => {
  const {
    entryId
  } = useTypedParams(ROUTES.CUSTOMER_IDENTIFICATION_PROGRAM_TESTING_ENTRIES_DETAILS);
  const {
    data: entry
  } = useCustomerIdentificationProgramTestingEntryGet(entryId);
  const {
    data: group
  } = useGroupGet(entry?.entity_group_id || '', {
    enabled: !!entry
  });
  if (!entry || !group) {
    return <Loading />;
  }
  const operatorReview = ['reviewed_without_remediations', 'reviewed_with_remediations'].includes(entry.testing_status) ? 'valid' : 'indeterminate';
  return <PageLayout headline={entry.entity_name} data-sentry-element="PageLayout" data-sentry-component="CustomerIdentificationProgramTestingEntryDetailPage" data-sentry-source-file="CustomerIdentificationProgramTestingEntryDetailPage.tsx">
      <SideBySide mode="centered" left={<PropertyList title="Information" items={[{
      label: 'Program',
      value: entry.bank_program_name
    }, {
      label: 'Group',
      value: group.name || group.friendly_nickname,
      href: '/groups/' + entry.entity_group_id
    }, {
      label: 'Entity name',
      value: entry.entity_name
    }, {
      label: 'Entity id',
      value: entry.entity_id,
      href: buildPath(ROUTES.ENTITY, {
        entityId: entry.entity_id
      })
    }, {
      label: 'Testing status',
      value: humanize(entry.testing_status),
      badgeColor: testingStatusColors[entry.testing_status]
    }, {
      label: 'Automated evaluation',
      value: humanize(entry.original_automated_evaluation),
      badgeColor: evaluationColors[entry.original_automated_evaluation]
    }]} />} right={<div className="flex flex-col gap-6">
            {entry.testing_status !== 'pending_reviewing' && <PropertyList title="Review results" items={compact<ListItem>([entry.reviewer_email && {
        label: 'Reviewer',
        value: entry.reviewer_email
      }, {
        label: 'Their review',
        value: humanize(operatorReview),
        badgeColor: evaluationColors[operatorReview]
      }, entry.final_automated_evaluation && {
        label: 'Final automated evaluation',
        value: humanize(entry.final_automated_evaluation),
        badgeColor: evaluationColors[entry.original_automated_evaluation]
      }, entry.note && {
        label: 'Note',
        value: entry.note
      }])} />}
            <RequireEntitlements entitlements={['customer_identification_program_testing_entry_write']}>
              <Box>
                <CustomerIdentificationProgramTestingEntryUpdateForm entryId={entryId} />
              </Box>
            </RequireEntitlements>
          </div>} data-sentry-element="SideBySide" data-sentry-source-file="CustomerIdentificationProgramTestingEntryDetailPage.tsx" />
    </PageLayout>;
};