import { CopyButton } from '@increase/shared/components/CopyButton';
import { PrestyledTableProps, Table, TableColumns } from '@increase/shared/components/Table';
import React, { useMemo } from 'react';
import { BadgeColor } from '@increase/shared/components/Badge';
import { AccountNumberListResponseDataItem, AccountNumberListResponseDataItemStatus } from 'src/build/operations';
import { buildPath, ROUTES } from 'src/lib/routes';
type AccountNumbersTableProps = {
  showAccount: boolean;
  showGroup: boolean;
} & PrestyledTableProps<AccountNumberListResponseDataItem>;
const accountNumberStatus = {
  active: 'Active',
  disabled: 'Disabled',
  canceled: 'Canceled'
} as const;
const readableStatus = (status: AccountNumberListResponseDataItemStatus): string => accountNumberStatus[status];
const badgeColor = (accountNumber: AccountNumberListResponseDataItem): BadgeColor => accountNumber.status === 'active' ? 'green' : accountNumber.status === 'canceled' ? 'red' : 'gray';
export const AccountNumbersTable = ({
  showAccount,
  showGroup,
  ...rest
}: AccountNumbersTableProps) => {
  const columns = useMemo<TableColumns<AccountNumberListResponseDataItem>>(() => {
    return [showGroup && {
      header: 'Group',
      contents: accountNumber => ({
        text: accountNumber.group_name ?? accountNumber.group_id,
        href: buildPath(ROUTES.GROUPS_DETAIL, {
          groupId: accountNumber.group_id
        })
      })
    }, showAccount && {
      priority: 3,
      header: 'Account',
      expand: 1,
      contents: accountNumber => ({
        text: accountNumber.account_name,
        href: buildPath(ROUTES.ACCOUNTS_DETAIL, {
          accountId: accountNumber.account_id
        })
      })
    }, {
      expand: 1,
      header: 'Description',
      iconType: 'route',
      iconStyle: badgeColor,
      contents: accountNumber => ({
        text: accountNumber.name,
        textColor: 'emphasis',
        textWeight: 'medium'
      })
    }, {
      priority: 4,
      header: 'Status',
      contents: accountNumber => ({
        text: readableStatus(accountNumber.status),
        badgeColor: badgeColor(accountNumber)
      })
    }, {
      priority: 5,
      header: 'Routing number',
      CellComponent: ({
        datum: accountNumber
      }) => {
        return <CopyButton valueToCopy={accountNumber.routing_number} valueToDisplay={accountNumber.routing_number} tooltipDirection="left" />;
      }
    }, {
      priority: 2,
      minWidth: 150,
      header: 'Account number',
      align: 'right',
      CellComponent: ({
        datum: accountNumber
      }) => {
        return <CopyButton valueToCopy={accountNumber.account_number} valueToDisplay={accountNumber.account_number} tooltipDirection="left" />;
      }
    }];
  }, [showAccount, showGroup]);
  const getRowProps = (datum: AccountNumberListResponseDataItem) => ({
    href: buildPath(ROUTES.ACCOUNT_NUMBERS_DETAILS, {
      accountNumberId: datum.id
    }),
    className: 'hover:bg-main-hover cursor-pointer transition-all group'
  });
  return <Table columns={columns} getRowProps={rest.getRowProps ?? getRowProps} {...rest} data-sentry-element="Table" data-sentry-component="AccountNumbersTable" data-sentry-source-file="AccountNumbersTable.tsx" />;
};