import classNames from 'classnames';
import { useState, ReactNode } from 'react';
import { Icon, IconType } from './Icon';
import { Caption } from './Text';
import { Transition } from '@headlessui/react';
export type TooltipDirection = 'right' | 'left' | 'top' | 'bottom';
export type TooltipSize = 'small' | 'large';
export type Props = {
  direction?: TooltipDirection;
  content?: string | ReactNode;
  children?: ReactNode;
  className?: string;
  icon?: IconType;
  size?: TooltipSize;
};
const DIRECTION_CLASSES_TOOLTIP: Record<TooltipDirection, string> = {
  right: '-right-2 translate-x-full top-1/2 -translate-y-1/2 ',
  left: '-left-2 -translate-x-full top-1/2 -translate-y-1/2',
  top: '-top-2 -translate-y-full left-1/2 -translate-x-1/2',
  bottom: '-bottom-2 translate-y-full left-1/2 -translate-x-1/2'
};
const DIRECTION_CLASSES_ARROW: Record<TooltipDirection, string> = {
  right: '-mr-1 translate-y-1/2 bottom-1/2 right-full rotate-45',
  left: '-ml-1 translate-y-1/2 bottom-1/2 left-full rotate-45',
  bottom: '-mb-1 -ml-1 left-1/2 bottom-full rotate-45',
  top: '-mt-1 -ml-1 left-1/2 top-full rotate-45'
};
const SIZE_CLASSES_WIDTH: Record<TooltipSize, string> = {
  small: 'w-full whitespace-nowrap px-1.5 py-1 items-center',
  large: 'w-48 p-2'
};
const Tooltip = ({
  direction = 'bottom',
  size = 'small',
  content,
  children,
  className,
  icon
}: Props) => {
  const [active, setActive] = useState(false);
  const showTip = () => {
    setActive(true);
  };
  const hideTip = () => {
    setActive(false);
  };
  return <div className={classNames('relative flex w-min', className)}
  // When to show the tooltip
  onMouseEnter={showTip} onMouseLeave={hideTip} onFocus={showTip} onBlur={hideTip} data-sentry-component="Tooltip" data-sentry-source-file="Tooltip.tsx">
      {/* Wrapping */}
      {children}

      <Transition.Root show={active} data-sentry-element="unknown" data-sentry-source-file="Tooltip.tsx">
        <div className={classNames('pointer-events-none absolute z-30', DIRECTION_CLASSES_TOOLTIP[direction])}>
          <Transition.Child enter="duration-200 ease-out" enterFrom="opacity-0 scale-95 -translate-y-1" enterTo="opacity-100 scale-100 translate-y-0" leave="duration-200 ease-out" leaveFrom="opacity-100 scale-100 translate-y-0" leaveTo="opacity-0 scale-95 -translate-y-1" data-sentry-element="unknown" data-sentry-source-file="Tooltip.tsx">
            <div className="bg-inverse dark:border-stronger dark:bg-strong rounded shadow-md dark:border">
              {size === 'large' && <div className={classNames('bg-inverse dark:border-stronger dark:bg-strong absolute h-2 w-2 dark:border', DIRECTION_CLASSES_ARROW[direction])} />}
              {/* Content */}
              {typeof content === 'string' ? <div className={classNames(SIZE_CLASSES_WIDTH[size], 'flex space-x-1')}>
                  {icon && <Icon name={icon} className={'stroke-inverse dark:stroke-strong h-4 w-4 shrink-0'} />}
                  <Caption color="offset" className="dark:text-strong w-full">
                    {content}
                  </Caption>
                </div> : content}
            </div>
          </Transition.Child>
        </div>
      </Transition.Root>
    </div>;
};
export { Tooltip };