type PropertyListItemProps = {
  label: string;
  value: string | React.ReactNode;
} | {
  label: string;
  children: React.ReactNode;
};
export const PropertyListItem = (props: PropertyListItemProps) => {
  const content = 'value' in props ? props.value : props.children;
  return <div className="flex" data-sentry-component="PropertyListItem" data-sentry-source-file="PropertyList.tsx">
      <div className="text-subtle flex w-1/2 max-w-[500px] grow-0 items-center py-2 pl-3">
        {props.label}
      </div>
      <div className="text-strong flex w-1/2 grow items-center py-2 pr-2">
        {content}
      </div>
    </div>;
};
type PropertyListProps = {
  children: React.ReactNode | React.ReactNode[];
};
export const PropertyList = ({
  children
}: PropertyListProps) => {
  return <div className="divide-color-main border-main divide-y rounded border text-sm shadow-sm" data-sentry-component="PropertyList" data-sentry-source-file="PropertyList.tsx">
      {children}
    </div>;
};