import { compact } from 'lodash';
import { ListItem, PropertyList, PropertyListProps } from '@increase/shared/components/PropertyList';
import { FedwireInboundRequestForReversalGetResponse } from 'src/build/operations';
type FedwireRequestForReversalPropertyListProps = {
  fedwireRequestForReversal: FedwireInboundRequestForReversalGetResponse;
} & Omit<PropertyListProps, 'items'>;
export const FedwireRequestForReversalPropertyList = (props: FedwireRequestForReversalPropertyListProps) => {
  const {
    fedwireRequestForReversal,
    ...rest
  } = props;
  return <PropertyList {...rest} items={compact<ListItem>([{
    label: 'ID',
    value: fedwireRequestForReversal.id,
    copyable: fedwireRequestForReversal.id
  }, {
    label: 'Status',
    value: fedwireRequestForReversal.allocation_status
  }, {
    label: 'Received On',
    value: fedwireRequestForReversal.received_on,
    format: 'date'
  }])} data-sentry-element="PropertyList" data-sentry-component="FedwireRequestForReversalPropertyList" data-sentry-source-file="FedwireRequestForReversalPropertyList.tsx" />;
};